const renameCategory = (category: string) => {
    switch (category) {
        case "system":
            return "System";
        case "inputs":
            return "Source";
        case "input_alerts":
            return "Source Alerts";
        case "outputs":
            return "Destinations";
        case "output_alerts":
            return "Destination Alerts";
        case "schedule":
            return "Schedule";
        case "environments":
            return "Environments";
        case "transcoders":
            return "Transcoders";
        case "mvs":
            return "Multi-Viewers";
        case "titanfile":
            return "File Transcoding";
        case "organisation":
            return "Organisation";
        case "billing":
            return "Billing";
        case "unknown":
            return "Unknown";
        case "packager":
            return "Push Packagers";
        case "nea_live":
            return "Pull Packagers";
        case "pull_packager":
            return "Pull Packagers";
        case "own_cloud":
            return "Own Cloud";
        default:
            return category;
    }
};

export default renameCategory;