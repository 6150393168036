import React from "react";

import Content from "components/Layout/Content";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import RoleProvider from "components/RoleProvider";
import { format } from "date-fns";
import { agent, IOrganisationListManager, ManagingOrganisationListResponse, RootStoreContext } from "api";
import useRoleProvider from "hooks/UseRoleProvider";
import { observer } from "mobx-react-lite";
import InfoItem from "components/InfoItem";
import { ChartsTooltip, PiePlot, ResponsiveChartContainer, Gauge } from "@mui/x-charts";
import ChartPalette from "styles/ChartPalette";

// Base interface for common properties across multiple services
interface ICommonServiceData {
    cloud: string;
    size: string;
    hours: number;
    price: number;
    cost: string;
    product_id: number;
    environment_name: string;
    gb_amount_of_data_transferred: number;
    tf_deployment_name: string;
    video_format: string;
    number_of_minutes: number;
    price_per_minute: number;
    effective_price_per_minute: number;
    agreement_reference_number: string;
    signed_at: string;
    expires_at: string;
}

// Specialized interfaces for different services
interface IEnvironmentData extends ICommonServiceData {
    environment_name: string;
}

interface ITranscoderData extends ICommonServiceData {}

interface IPackagerData extends ICommonServiceData {}

interface IEgressData extends ICommonServiceData {
    gb_amount_of_data_transferred: number;
}

interface IStorageData extends ICommonServiceData {}

// Service breakdown structure
interface IServiceBreakdown {
    data: ICommonServiceData[];
    total: string;
}

// Detailed breakdown for each service
interface IServiceDetails {
    customer_environments?: IServiceBreakdown;
    stopped_customer_environments?: IServiceBreakdown;
    running_transcoders?: IServiceBreakdown;
    stopped_transcoders?: IServiceBreakdown;
    running_push_packagers?: IServiceBreakdown;
    running_backup_push_packagers?: IServiceBreakdown;
    stopped_push_packagers?: IServiceBreakdown;
    push_packager_egress?: IServiceBreakdown;
    data_egress?: IServiceBreakdown;
    permanent_scheduled_agreements?: IServiceBreakdown;
    detached_ip_addresses?: IServiceBreakdown;
    running_multiviewers?: IServiceBreakdown;
    stopped_multiviewers?: IServiceBreakdown;
    running_titan_file_deployments?: IServiceBreakdown;
    stopped_titan_file_deployments?: IServiceBreakdown;
    titan_file_file_transcoding?: IServiceBreakdown;
    running_nea_live?: IServiceBreakdown;
    stopped_nea_live?: IServiceBreakdown;
    nea_live_storage?: IServiceBreakdown;
    nea_live_cdn_egress?: IServiceBreakdown;
    running_pull_packager?: IServiceBreakdown;
    running_backup_pull_packager?: IServiceBreakdown;
    stopped_pull_packager?: IServiceBreakdown;
}

// Main report interface
interface IReport {
    organisation_id: number;
    organisation_name: string;
    period_from: string;
    period_to: string;
    currency: string;
    purchase_order_number: string;
    organisation_tags: string;
    total_customer_environments_runtime_cost: string;
    total_stopped_customer_environments_runtime_cost: string;
    total_running_transcoder_runtime_cost: string;
    total_stopped_transcoder_runtime_cost: string;
    total_running_push_packager_runtime_cost: string;
    total_running_backup_push_packager_runtime_cost: string;
    total_stopped_push_packager_runtime_cost: string;
    total_push_packager_egress_cost: string;
    total_egress_cost: string;
    total_permanent_schedule_agreements_cost: string;
    total_detached_ip_address_cost: string;
    total_running_multiviewer_cost: string;
    total_stopped_multiviewer_cost: string;
    total_running_titan_file_cost: string;
    total_stopped_titan_file_cost: string;
    total_titan_file_transcoding_cost: string;
    total_running_nea_live_runtime_cost: string;
    total_stopped_nea_live_runtime_cost: string;
    total_nea_live_storage_cost: string;
    total_running_pull_packager_cost: string;
    total_running_backup_pull_packager_cost: string;
    total_stopped_pull_packager_cost: string;
    total_pull_packager_cdn_egress_cost: string;
    grand_total_cost: string;
    service_breakdown: IServiceDetails;
}

const BillingMonthlyReports: React.FC = () => {
    const theme = useTheme();
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    const canSeeAllOrganisations = useRoleProvider(["is_superuser", "is_accounts_admin", "is_accounts_user"], false);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [organisations, setOrganisations] = React.useState<any[]>();
    const [organisationId, setOrganisationId] = React.useState<number>(user?.active_org || 0);
    const [month, setMonth] = React.useState<string>("");
    const [availableReports, setAvailableReports] = React.useState<any[]>([]);
    const [selectedReport, setSelectedReport] = React.useState<IReport | null>(null);

    const getOrganisations = async () => {
        setLoading(true);
        await agent.Organisation.getManagingOrgListAdmin()
            .then((response: ManagingOrganisationListResponse) => {
                setOrganisations(response.data);
                console.log(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const getAvailableReports = async (organisation_id: number) => {
        setLoading(true);

        await agent.Billing.getAvailableBillingMonthlyReports(organisation_id)
            .then((response) => {
                setAvailableReports(response.data);
            })
            .catch((error) => {
                console.log("Error", error);
            });

        setLoading(false);
    };

    const getReport = async (month: string) => {
        setLoading(true);

        await agent.Billing.getBillingMonthlyReport(organisationId, month)
            .then((response) => {
                setSelectedReport(response.data);
            })
            .catch((error) => {
                console.log("Error", error);
            });

        setLoading(false);
    };

    const onOrganisationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOrganisationId(Number(event.target.value));
        setSelectedReport(null);
        setMonth("");
    };

    const scrollToTop = () => {
        const contentElement = document.getElementById("billing-monthly-reports");
        if (contentElement) {
            contentElement.scrollTop = 0;
        }
    };

    const onMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMonth(event.target.value as string);
        getReport(event.target.value as string);
    };

    const calculateEnvironmentsTotal = (report: IReport) => {
        //running + stopped + data egress + permanent scheduled agreements + detached ip addresses
        return (
            Number(report.total_customer_environments_runtime_cost) +
            Number(report.total_stopped_customer_environments_runtime_cost) +
            Number(report.total_egress_cost) +
            Number(report.total_permanent_schedule_agreements_cost) +
            Number(report.total_detached_ip_address_cost)
        );
    };

    const calculateTranscodersTotal = (report: IReport) => {
        //running + stopped
        return Number(report.total_running_transcoder_runtime_cost) + Number(report.total_stopped_transcoder_runtime_cost);
    };

    const calculatePushPackagersTotal = (report: IReport) => {
        //running + running backup + stopped + data egress
        return (
            Number(report.total_running_push_packager_runtime_cost) +
            Number(report.total_running_backup_push_packager_runtime_cost) +
            Number(report.total_stopped_push_packager_runtime_cost) +
            Number(report.total_push_packager_egress_cost)
        );
    };

    const calculatePullPackagersTotal = (report: IReport) => {
        //running instances + stopped instances + storage + pull packager cdn egress + running transcoders (main) + running transcoders (backup) + stopped transcoders
        return (
            Number(report.total_running_nea_live_runtime_cost) +
            Number(report.total_stopped_nea_live_runtime_cost) +
            Number(report.total_nea_live_storage_cost) +
            Number(report.total_pull_packager_cdn_egress_cost) +
            Number(report.total_running_pull_packager_cost) +
            Number(report.total_running_backup_pull_packager_cost) +
            Number(report.total_stopped_pull_packager_cost)
        );
    };

    const calculateMultiviewersTotal = (report: IReport) => {
        //running + stopped
        return Number(report.total_running_multiviewer_cost) + Number(report.total_stopped_multiviewer_cost);
    };

    const calculateFileTranscodingTotal = (report: IReport) => {
        //running clusters + stopped clusters + jobs
        return (
            Number(report.total_running_titan_file_cost) +
            Number(report.total_stopped_titan_file_cost) +
            Number(report.total_titan_file_transcoding_cost)
        );
    };

    React.useEffect(() => {
        console.log("User", user);
        if (canSeeAllOrganisations) {
            getOrganisations();
        } else {
            setOrganisations([{ id: user?.active_org, name: user?.active_org_name }]);
        }
    }, []);

    React.useEffect(() => {
        if (organisationId !== null) {
            getAvailableReports(organisationId);
        }
    }, [organisationId]);

    React.useEffect(() => {
        scrollToTop();
        if (selectedReport) {
            console.log("Environments Total", calculateEnvironmentsTotal(selectedReport));
        }
    }, [selectedReport]);

    return (
        <Content
            content_id="billing-monthly-reports"
            loading={loading}
            toolbarContent={
                <Stack direction="row" spacing={2} sx={{ py: 1 }}>
                    <RoleProvider roles={["is_superuser", "is_accounts_admin", "is_accounts_user"]}>
                        <TextField
                            label="Organisation"
                            variant="outlined"
                            size="small"
                            value={organisationId}
                            sx={{
                                width: 200
                            }}
                            select
                            onChange={(event) => {
                                onOrganisationChange(event);
                            }}>
                            {organisations?.map((organisation: IOrganisationListManager, index: number) => (
                                <MenuItem key={index} value={organisation.id}>
                                    {organisation.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </RoleProvider>
                    <TextField
                        label="Month"
                        variant="outlined"
                        size="small"
                        value={month}
                        sx={{
                            width: 200
                        }}
                        select
                        onChange={(e: any) => {
                            onMonthChange(e);
                        }}>
                        {availableReports
                        .sort((a, b) => new Date(b.month).getTime() - new Date(a.month).getTime())
                        .map((report, index) => (
                            <MenuItem key={index} value={report.month}>
                                {
                                    //use date-fns to format the date to MMMM yyyy
                                    format(new Date(report.month), "MMMM yyyy")
                                }
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>
            }
            headerContent={
                selectedReport && (
                    <Box
                        sx={{
                            py: 2,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}>
                        <Box>
                            <Typography variant="h3">{selectedReport.organisation_name}</Typography>
                            <Typography variant="subtitle1">
                                {format(new Date(selectedReport.period_from), "dd MMMM yyyy")} -{" "}
                                {format(new Date(selectedReport.period_to), "dd MMMM yyyy")}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5">Total: {selectedReport.grand_total_cost + " " + selectedReport.currency}</Typography>
                        </Box>
                    </Box>
                )
            }>
            <>
                {!selectedReport && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}>
                        <Alert severity="info" sx={{ width: 430 }}>
                            Please select an organisation and a month to view the billing report.
                        </Alert>
                    </Box>
                )}
                {selectedReport && (
                    <>
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        flexWrap: "nowrap"
                                    }}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: 280,
                                            height: 280,
                                            overflow: "hidden",
                                            mr: 2
                                        }}>
                                        <Box
                                            sx={{
                                                top: -35,
                                                left: -35,
                                                width: 350,
                                                flexGrow: 0,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                position: "relative"
                                            }}>
                                            <ResponsiveChartContainer
                                                disableAxisListener
                                                height={350}
                                                series={[
                                                    {
                                                        type: "pie",
                                                        data: [
                                                            {
                                                                value: Number(calculateEnvironmentsTotal(selectedReport).toFixed(2)),
                                                                label: "Environments",
                                                                color: theme.palette.primary.main
                                                            },
                                                            {
                                                                value: Number(calculateTranscodersTotal(selectedReport).toFixed(2)),
                                                                label: "Transcoders",
                                                                color: theme.palette.secondary.main
                                                            },
                                                            {
                                                                value: Number(calculatePushPackagersTotal(selectedReport).toFixed(2)),
                                                                label: "Push Packagers",
                                                                color: theme.palette.tertiary?.main
                                                            },
                                                            {
                                                                value: Number(calculatePullPackagersTotal(selectedReport).toFixed(2)),
                                                                label: "Pull Packagers",
                                                                color: theme.palette.quaternary?.main
                                                            },
                                                            {
                                                                value: Number(calculateMultiviewersTotal(selectedReport).toFixed(2)),
                                                                label: "Multiviewers",
                                                                color: theme.palette.quinary?.main
                                                            },
                                                            {
                                                                value: Number(calculateFileTranscodingTotal(selectedReport).toFixed(2)),
                                                                label: "File Transcoding",
                                                                color: theme.palette.senary?.main
                                                            }
                                                        ],
                                                        innerRadius: 55,
                                                        cornerRadius: 3,
                                                        paddingAngle: 1,
                                                        highlightScope: { faded: "global", highlighted: "item" },
                                                        valueFormatter: (item) => `${selectedReport.currency}${item.value}`
                                                    }
                                                ]}>
                                                <PiePlot skipAnimation colors={ChartPalette} />
                                                <ChartsTooltip trigger="item" />
                                            </ResponsiveChartContainer>
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                <Typography fontSize={13} fontWeight={600} lineHeight={1}>
                                                    Total
                                                </Typography>
                                                <Typography fontSize={16} fontWeight={600}>
                                                    {selectedReport.grand_total_cost}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <InfoItem title="Organisation" value={selectedReport.organisation_name || "-"} />
                                        <InfoItem title="Organisation ID" value={selectedReport.organisation_id || "-"} />
                                        <InfoItem title="Organisation Tags" value={selectedReport.organisation_tags || "-"} />
                                        <InfoItem title="Currency" value={selectedReport.currency || "-"} />
                                        <InfoItem title="Purchase Order Number" value={selectedReport.purchase_order_number || "-"} />
                                        {/* from */}
                                        <InfoItem title="Period From" value={selectedReport.period_from || "-"} />
                                        {/* to */}
                                        <InfoItem title="Period To" value={selectedReport.period_to || "-"} />
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                        <Box
                            sx={{
                                display: "grid",
                                //always 50% width for each card
                                gridTemplateColumns: "repeat(auto-fill, minmax(25%, 1fr))",
                                gap: 2,
                                mt: 2
                            }}>
                            <Card>
                                <CardHeader
                                    title="Environments"
                                    subheader={calculateEnvironmentsTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                    action={
                                        <Gauge
                                            width={80}
                                            height={80}
                                            value={calculateEnvironmentsTotal(selectedReport)}
                                            valueMin={0}
                                            valueMax={Number(selectedReport.grand_total_cost)}
                                            sx={{
                                                ".MuiGauge-valueText": {
                                                    fontSize: "11px"
                                                },
                                                ".MuiGauge-valueArc": {
                                                    fill: theme.palette.primary.main
                                                }
                                            }}
                                            text={
                                                //calculate the percentage of the total cost
                                                (
                                                    (calculateEnvironmentsTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                    100
                                                ).toFixed(2) + "%"
                                            }
                                        />
                                    }
                                />
                                <CardContent>
                                    <InfoItem title="Running" value={selectedReport.total_customer_environments_runtime_cost} />
                                    <InfoItem title="Stopped" value={selectedReport.total_stopped_customer_environments_runtime_cost} />
                                    <InfoItem title="Data Egress" value={selectedReport.total_egress_cost} />
                                    <InfoItem
                                        title="Permanent Scheduled Agreements"
                                        value={selectedReport.total_permanent_schedule_agreements_cost}
                                    />
                                    <InfoItem title="Detached IP Addresses" value={selectedReport.total_detached_ip_address_cost} />
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader
                                    title="Transcoders"
                                    subheader={calculateTranscodersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                    action={
                                        <Gauge
                                            width={80}
                                            height={80}
                                            value={calculateTranscodersTotal(selectedReport)}
                                            valueMin={0}
                                            valueMax={Number(selectedReport.grand_total_cost)}
                                            sx={{
                                                ".MuiGauge-valueText": {
                                                    fontSize: "11px"
                                                },
                                                ".MuiGauge-valueArc": {
                                                    fill: theme.palette.secondary.main
                                                }
                                            }}
                                            text={
                                                (
                                                    (calculateTranscodersTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                    100
                                                ).toFixed(2) + "%"
                                            }
                                        />
                                    }
                                />
                                <CardContent>
                                    <InfoItem title="Running" value={selectedReport.total_running_transcoder_runtime_cost} />
                                    <InfoItem title="Stopped" value={selectedReport.total_stopped_transcoder_runtime_cost} />
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader
                                    title="Push Packagers"
                                    subheader={calculatePushPackagersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                    action={
                                        <Gauge
                                            width={80}
                                            height={80}
                                            value={calculatePushPackagersTotal(selectedReport)}
                                            valueMin={0}
                                            valueMax={Number(selectedReport.grand_total_cost)}
                                            sx={{
                                                ".MuiGauge-valueText": {
                                                    fontSize: "11px"
                                                },
                                                ".MuiGauge-valueArc": {
                                                    fill: theme.palette.tertiary?.main || "#307abb"
                                                }
                                            }}
                                            text={
                                                (
                                                    (calculatePushPackagersTotal(selectedReport) /
                                                        Number(selectedReport.grand_total_cost)) *
                                                    100
                                                ).toFixed(2) + "%"
                                            }
                                        />
                                    }
                                />
                                <CardContent>
                                    <InfoItem title="Running" value={selectedReport.total_running_push_packager_runtime_cost} />
                                    <InfoItem
                                        title="Running Backup"
                                        value={selectedReport.total_running_backup_push_packager_runtime_cost}
                                    />
                                    <InfoItem title="Stopped" value={selectedReport.total_stopped_push_packager_runtime_cost} />
                                    <InfoItem title="Data Egress" value={selectedReport.total_push_packager_egress_cost} />
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader
                                    title="Pull Packagers"
                                    subheader={calculatePullPackagersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                    action={
                                        <Gauge
                                            width={80}
                                            height={80}
                                            value={calculatePullPackagersTotal(selectedReport)}
                                            valueMin={0}
                                            valueMax={Number(selectedReport.grand_total_cost)}
                                            sx={{
                                                ".MuiGauge-valueText": {
                                                    fontSize: "11px"
                                                },
                                                ".MuiGauge-valueArc": {
                                                    fill: theme.palette.quaternary?.main || "#173a59"
                                                }
                                            }}
                                            text={
                                                (
                                                    (calculatePullPackagersTotal(selectedReport) /
                                                        Number(selectedReport.grand_total_cost)) *
                                                    100
                                                ).toFixed(2) + "%"
                                            }
                                        />
                                    }
                                />
                                <CardContent>
                                    <InfoItem title="Running Instances" value={selectedReport.total_running_nea_live_runtime_cost} />
                                    <InfoItem title="Stopped Instances" value={selectedReport.total_stopped_nea_live_runtime_cost} />
                                    <InfoItem title="Storage" value={selectedReport.total_nea_live_storage_cost} />
                                    <InfoItem title="Pull packager CDN egress" value={selectedReport.total_pull_packager_cdn_egress_cost} />
                                    <InfoItem title="Running Transcoders (Main)" value={selectedReport.total_running_pull_packager_cost} />
                                    <InfoItem
                                        title="Running Transcoders (Backup)"
                                        value={selectedReport.total_running_backup_pull_packager_cost}
                                    />
                                    <InfoItem title="Stopped Transcoders" value={selectedReport.total_stopped_pull_packager_cost} />
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader
                                    title="Multiviewers"
                                    subheader={calculateMultiviewersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                    action={
                                        <Gauge
                                            width={80}
                                            height={80}
                                            value={calculateMultiviewersTotal(selectedReport)}
                                            valueMin={0}
                                            valueMax={Number(selectedReport.grand_total_cost)}
                                            sx={{
                                                ".MuiGauge-valueText": {
                                                    fontSize: "11px"
                                                },
                                                ".MuiGauge-valueArc": {
                                                    fill: theme.palette.quinary?.main || "#1e2832"
                                                }
                                            }}
                                            text={
                                                (
                                                    (calculateMultiviewersTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                    100
                                                ).toFixed(2) + "%"
                                            }
                                        />
                                    }
                                />
                                <CardContent>
                                    {/* <Typography variant="h6">Multiviewers</Typography> */}
                                    <InfoItem title="Running" value={selectedReport.total_running_multiviewer_cost} />
                                    <InfoItem title="Stopped" value={selectedReport.total_stopped_multiviewer_cost} />
                                </CardContent>
                            </Card>

                            <Card>
                                <CardHeader
                                    title="File Transcoding"
                                    subheader={calculateFileTranscodingTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                    action={
                                        <Gauge
                                            width={80}
                                            height={80}
                                            value={calculateFileTranscodingTotal(selectedReport)}
                                            valueMin={0}
                                            valueMax={Number(selectedReport.grand_total_cost)}
                                            sx={{
                                                ".MuiGauge-valueText": {
                                                    fontSize: "11px"
                                                },
                                                ".MuiGauge-valueArc": {
                                                    fill: theme.palette.senary?.main || "#36b3a8"
                                                }
                                            }}
                                            text={
                                                (
                                                    (calculateFileTranscodingTotal(selectedReport) /
                                                        Number(selectedReport.grand_total_cost)) *
                                                    100
                                                ).toFixed(2) + "%"
                                            }
                                        />
                                    }
                                />
                                <CardContent>
                                    <InfoItem title="Running Clusters" value={selectedReport.total_running_titan_file_cost} />
                                    <InfoItem title="Stopped Clusters" value={selectedReport.total_stopped_titan_file_cost} />
                                    <InfoItem title="Jobs" value={selectedReport.total_titan_file_transcoding_cost} />
                                </CardContent>
                            </Card>
                        </Box>

                        <Box sx={{ mt: 3, mb: 2 }}>
                            <Typography variant="h5">Service Breakdown</Typography>
                        </Box>

                        <Card sx={{ mb: 2 }}>
                            <CardHeader
                                title="Environments"
                                subheader={calculateEnvironmentsTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                action={
                                    <Gauge
                                        width={80}
                                        height={80}
                                        value={calculateEnvironmentsTotal(selectedReport)}
                                        valueMin={0}
                                        valueMax={Number(selectedReport.grand_total_cost)}
                                        sx={{
                                            ".MuiGauge-valueText": {
                                                fontSize: "11px"
                                            },
                                            ".MuiGauge-valueArc": {
                                                fill: theme.palette.primary.main
                                            }
                                        }}
                                        text={
                                            //calculate the percentage of the total cost
                                            (
                                                (calculateEnvironmentsTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                100
                                            ).toFixed(2) + "%"
                                        }
                                    />
                                }
                            />
                            <CardContent>
                                <Typography variant="h6">Running Customer Environments</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Environment Name</TableCell>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.customer_environments?.data.map(
                                            (environment: IEnvironmentData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{environment.environment_name}</TableCell>
                                                    <TableCell>{environment.cloud}</TableCell>
                                                    <TableCell>{environment.size}</TableCell>
                                                    <TableCell>{environment.hours}</TableCell>
                                                    <TableCell>{environment.price}</TableCell>
                                                    <TableCell>{environment.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Stopped Customer Environments</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Environment Name</TableCell>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.stopped_customer_environments?.data.map(
                                            (environment: IEnvironmentData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{environment.environment_name}</TableCell>
                                                    <TableCell>{environment.cloud}</TableCell>
                                                    <TableCell>{environment.size}</TableCell>
                                                    <TableCell>{environment.hours}</TableCell>
                                                    <TableCell>{environment.price}</TableCell>
                                                    <TableCell>{environment.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Data Egress</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>GB Amount of Data Transferred</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.data_egress?.data.map((egress: IEgressData, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>{egress.cloud}</TableCell>
                                                <TableCell>{egress.gb_amount_of_data_transferred}</TableCell>
                                                <TableCell>{egress.price}</TableCell>
                                                <TableCell>{egress.cost}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Permanent Scheduled Agreements</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Agreement Reference Number</TableCell>
                                        <TableCell>Signed at</TableCell>
                                        <TableCell>Expires at</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.permanent_scheduled_agreements?.data.map(
                                            (agreement: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{agreement.cloud}</TableCell>
                                                    <TableCell>{agreement.agreement_reference_number}</TableCell>
                                                    <TableCell>{agreement.signed_at}</TableCell>
                                                    <TableCell>{agreement.expires_at}</TableCell>
                                                    <TableCell>{agreement.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Detached IP Addresses</Typography>
                                <Table>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.detached_ip_addresses?.data.map(
                                            (ip: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{ip.cloud}</TableCell>
                                                    <TableCell>{ip.size}</TableCell>
                                                    <TableCell>{ip.hours}</TableCell>
                                                    <TableCell>{ip.price}</TableCell>
                                                    <TableCell>{ip.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>

                        <Card sx={{ mb: 2 }}>
                            <CardHeader
                                title="Transcoders"
                                subheader={calculateTranscodersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                action={
                                    <Gauge
                                        width={80}
                                        height={80}
                                        value={calculateTranscodersTotal(selectedReport)}
                                        valueMin={0}
                                        valueMax={Number(selectedReport.grand_total_cost)}
                                        sx={{
                                            ".MuiGauge-valueText": {
                                                fontSize: "11px"
                                            },
                                            ".MuiGauge-valueArc": {
                                                fill: theme.palette.secondary.main
                                            }
                                        }}
                                        text={
                                            (
                                                (calculateTranscodersTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                100
                                            ).toFixed(2) + "%"
                                        }
                                    />
                                }
                            />
                            <CardContent>
                                <Typography variant="h6">Running Transcoders</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_transcoders?.data.map(
                                            (transcoder: ITranscoderData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{transcoder.cloud}</TableCell>
                                                    <TableCell>{transcoder.size}</TableCell>
                                                    <TableCell>{transcoder.hours}</TableCell>
                                                    <TableCell>{transcoder.price}</TableCell>
                                                    <TableCell>{transcoder.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Stopped Transcoders</Typography>
                                <Table>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.stopped_transcoders?.data.map(
                                            (transcoder: ITranscoderData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{transcoder.cloud}</TableCell>
                                                    <TableCell>{transcoder.size}</TableCell>
                                                    <TableCell>{transcoder.hours}</TableCell>
                                                    <TableCell>{transcoder.price}</TableCell>
                                                    <TableCell>{transcoder.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>

                        <Card sx={{ mb: 2 }}>
                            <CardHeader
                                title="Push Packagers"
                                subheader={calculatePushPackagersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                action={
                                    <Gauge
                                        width={80}
                                        height={80}
                                        value={calculatePushPackagersTotal(selectedReport)}
                                        valueMin={0}
                                        valueMax={Number(selectedReport.grand_total_cost)}
                                        sx={{
                                            ".MuiGauge-valueText": {
                                                fontSize: "11px"
                                            },
                                            ".MuiGauge-valueArc": {
                                                fill: theme.palette.tertiary?.main || "#307abb"
                                            }
                                        }}
                                        text={
                                            (
                                                (calculatePushPackagersTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                100
                                            ).toFixed(2) + "%"
                                        }
                                    />
                                }
                            />
                            <CardContent>
                                <Typography variant="h6">Running Push Packagers</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_push_packagers?.data.map(
                                            (packager: IPackagerData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{packager.cloud}</TableCell>
                                                    <TableCell>{packager.size}</TableCell>
                                                    <TableCell>{packager.hours}</TableCell>
                                                    <TableCell>{packager.price}</TableCell>
                                                    <TableCell>{packager.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Running Backup Push Packagers</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_backup_push_packagers?.data.map(
                                            (packager: IPackagerData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{packager.cloud}</TableCell>
                                                    <TableCell>{packager.size}</TableCell>
                                                    <TableCell>{packager.hours}</TableCell>
                                                    <TableCell>{packager.price}</TableCell>
                                                    <TableCell>{packager.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Stopped Push Packagers</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.stopped_push_packagers?.data.map(
                                            (packager: IPackagerData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{packager.cloud}</TableCell>
                                                    <TableCell>{packager.size}</TableCell>
                                                    <TableCell>{packager.hours}</TableCell>
                                                    <TableCell>{packager.price}</TableCell>
                                                    <TableCell>{packager.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Push Packager Egress</Typography>
                                <Table>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>GB Amount of Data Transferred</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.push_packager_egress?.data.map(
                                            (egress: IEgressData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{egress.cloud}</TableCell>
                                                    <TableCell>{egress.gb_amount_of_data_transferred}</TableCell>
                                                    <TableCell>{egress.price}</TableCell>
                                                    <TableCell>{egress.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>

                        <Card sx={{ mb: 2 }}>
                            <CardHeader
                                title="Pull Packagers"
                                subheader={calculatePullPackagersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                action={
                                    <Gauge
                                        width={80}
                                        height={80}
                                        value={calculatePullPackagersTotal(selectedReport)}
                                        valueMin={0}
                                        valueMax={Number(selectedReport.grand_total_cost)}
                                        sx={{
                                            ".MuiGauge-valueText": {
                                                fontSize: "11px"
                                            },
                                            ".MuiGauge-valueArc": {
                                                fill: theme.palette.quaternary?.main || "#173a59"
                                            }
                                        }}
                                        text={
                                            (
                                                (calculatePullPackagersTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                100
                                            ).toFixed(2) + "%"
                                        }
                                    />
                                }
                            />
                            <CardContent>
                                <Typography variant="h6">Running Pull Packager Instances</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_nea_live?.data.map(
                                            (nea_live: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{nea_live.cloud}</TableCell>
                                                    <TableCell>{nea_live.size}</TableCell>
                                                    <TableCell>{nea_live.hours}</TableCell>
                                                    <TableCell>{nea_live.price}</TableCell>
                                                    <TableCell>{nea_live.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Stopped Pull Packager Instances</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.stopped_nea_live?.data.map(
                                            (nea_live: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{nea_live.cloud}</TableCell>
                                                    <TableCell>{nea_live.size}</TableCell>
                                                    <TableCell>{nea_live.hours}</TableCell>
                                                    <TableCell>{nea_live.price}</TableCell>
                                                    <TableCell>{nea_live.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Pull Packagers Storage</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.nea_live_storage?.data.map(
                                            (storage: IStorageData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{storage.cloud}</TableCell>
                                                    <TableCell>{storage.size}</TableCell>
                                                    <TableCell>{storage.price}</TableCell>
                                                    <TableCell>{storage.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Pull Packager CDN Egress</Typography>
                                <Table sx={{ mb: 2 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>GB Amount of Data Transferred</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.nea_live_cdn_egress?.data.map(
                                            (egress: IEgressData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{egress.cloud}</TableCell>
                                                    <TableCell>{egress.gb_amount_of_data_transferred}</TableCell>
                                                    <TableCell>{egress.price}</TableCell>
                                                    <TableCell>{egress.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Running Pull Packager Transcoders</Typography>
                                <Table sx={{ mb: 2 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_pull_packager?.data.map(
                                            (transcoder: ITranscoderData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{transcoder.cloud}</TableCell>
                                                    <TableCell>{transcoder.size}</TableCell>
                                                    <TableCell>{transcoder.hours}</TableCell>
                                                    <TableCell>{transcoder.price}</TableCell>
                                                    <TableCell>{transcoder.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Running Backup Pull Packager Transcoders</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_backup_pull_packager?.data.map(
                                            (transcoder: ITranscoderData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{transcoder.cloud}</TableCell>
                                                    <TableCell>{transcoder.size}</TableCell>
                                                    <TableCell>{transcoder.hours}</TableCell>
                                                    <TableCell>{transcoder.price}</TableCell>
                                                    <TableCell>{transcoder.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Stopped Pull Packager Transcoders</Typography>
                                <Table>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.stopped_pull_packager?.data.map(
                                            (transcoder: ITranscoderData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{transcoder.cloud}</TableCell>
                                                    <TableCell>{transcoder.size}</TableCell>
                                                    <TableCell>{transcoder.hours}</TableCell>
                                                    <TableCell>{transcoder.price}</TableCell>
                                                    <TableCell>{transcoder.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>

                        <Card sx={{ mb: 2 }}>
                            <CardHeader
                                title="Multiviewers"
                                subheader={calculateMultiviewersTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                action={
                                    <Gauge
                                        width={80}
                                        height={80}
                                        value={calculateMultiviewersTotal(selectedReport)}
                                        valueMin={0}
                                        valueMax={Number(selectedReport.grand_total_cost)}
                                        sx={{
                                            ".MuiGauge-valueText": {
                                                fontSize: "11px"
                                            },
                                            ".MuiGauge-valueArc": {
                                                fill: theme.palette.quinary?.main || "#1e2832"
                                            }
                                        }}
                                        text={
                                            (
                                                (calculateMultiviewersTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                100
                                            ).toFixed(2) + "%"
                                        }
                                    />
                                }
                            />
                            <CardContent>
                                <Typography variant="h6">Running Multiviewers</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_multiviewers?.data.map(
                                            (multiviewer: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{multiviewer.cloud}</TableCell>
                                                    <TableCell>{multiviewer.size}</TableCell>
                                                    <TableCell>{multiviewer.hours}</TableCell>
                                                    <TableCell>{multiviewer.price}</TableCell>
                                                    <TableCell>{multiviewer.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Stopped Multiviewers</Typography>
                                <Table>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.stopped_multiviewers?.data.map(
                                            (multiviewer: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{multiviewer.cloud}</TableCell>
                                                    <TableCell>{multiviewer.size}</TableCell>
                                                    <TableCell>{multiviewer.hours}</TableCell>
                                                    <TableCell>{multiviewer.price}</TableCell>
                                                    <TableCell>{multiviewer.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>

                        <Card>
                            <CardHeader
                                title="File Transcoding"
                                subheader={calculateFileTranscodingTotal(selectedReport).toFixed(2) + " " + selectedReport.currency}
                                action={
                                    <Gauge
                                        width={80}
                                        height={80}
                                        value={calculateFileTranscodingTotal(selectedReport)}
                                        valueMin={0}
                                        valueMax={Number(selectedReport.grand_total_cost)}
                                        sx={{
                                            ".MuiGauge-valueText": {
                                                fontSize: "11px"
                                            },
                                            ".MuiGauge-valueArc": {
                                                fill: theme.palette.senary?.main || "#36b3a8"
                                            }
                                        }}
                                        text={
                                            (
                                                (calculateFileTranscodingTotal(selectedReport) / Number(selectedReport.grand_total_cost)) *
                                                100
                                            ).toFixed(2) + "%"
                                        }
                                    />
                                }
                            />
                            <CardContent>
                                <Typography variant="h6">Running Titan File Deployments</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.running_titan_file_deployments?.data.map(
                                            (deployment: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{deployment.tf_deployment_name}</TableCell>
                                                    <TableCell>{deployment.cloud}</TableCell>
                                                    <TableCell>{deployment.hours}</TableCell>
                                                    <TableCell>{deployment.price}</TableCell>
                                                    <TableCell>{deployment.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Typography variant="h6">Stopped Titan File Deployments</Typography>
                                <Table sx={{ mb: 3 }}>
                                    <TableHead>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Hours</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.stopped_titan_file_deployments?.data.map(
                                            (deployment: ICommonServiceData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{deployment.tf_deployment_name}</TableCell>
                                                    <TableCell>{deployment.cloud}</TableCell>
                                                    <TableCell>{deployment.hours}</TableCell>
                                                    <TableCell>{deployment.price}</TableCell>
                                                    <TableCell>{deployment.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>

                                <Typography variant="h6">Titan File Transcoding</Typography>
                                <Table>
                                    <TableHead>
                                        <TableCell>Cloud</TableCell>
                                        <TableCell>Video Format</TableCell>
                                        <TableCell>Number of Minutes</TableCell>
                                        <TableCell>Agreed Price Per Minute</TableCell>
                                        <TableCell>Effective Price Per Minute*</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {selectedReport.service_breakdown.titan_file_file_transcoding?.data.map(
                                            (transcoder: ITranscoderData, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>{transcoder.cloud}</TableCell>
                                                    <TableCell>{transcoder.video_format}</TableCell>
                                                    <TableCell>{transcoder.number_of_minutes}</TableCell>
                                                    <TableCell>{transcoder.price_per_minute}</TableCell>
                                                    <TableCell>{transcoder.effective_price_per_minute}</TableCell>
                                                    <TableCell>{transcoder.cost}</TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                                <Alert severity="info" sx={{ mt: 2 }}>
                                    *The average price per minutes from the different transcoding jobs done
                                </Alert>
                            </CardContent>
                        </Card>
                    </>
                )}
            </>
        </Content>
    );
};

export default observer(BillingMonthlyReports);
