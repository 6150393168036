import React from "react";
import { Button, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const ExportExcel = ({ report, fileName }) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (report, fileName) => {
        const disk_storage_costs = XLSX.utils.json_to_sheet(report.disk_storage_costs);
        const egress_costs = XLSX.utils.json_to_sheet(report.egress_costs);
        const elastic_ip_costs = XLSX.utils.json_to_sheet(report.elastic_ip_costs);
        const multiviewer_costs = XLSX.utils.json_to_sheet(report.multiviewer_costs);
        const environment_costs = XLSX.utils.json_to_sheet(report.running_aws_c_env_costs);
        const titan_file_costs = XLSX.utils.json_to_sheet(report.titan_file_cogs_costs);
        const transcoder_costs = XLSX.utils.json_to_sheet(report.transcoder_costs);
        const wb = {
            Sheets: {
                "Disk Storage": disk_storage_costs,
                "Egress": egress_costs,
                "Elastic IP": elastic_ip_costs,
                "Multiviewer": multiviewer_costs,
                "Environment": environment_costs,
                "Titan File": titan_file_costs,
                "Transcoder": transcoder_costs
            },
            SheetNames: ["Disk Storage", "Egress", "Elastic IP", "Multiviewer", "Environment", "Titan File", "Transcoder"]
        };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <Tooltip title="Export to Excel (.xlsx)">
            <Button variant="contained" color="secondary" startIcon={<FileDownloadIcon />} onClick={(e) => exportToCSV(report, fileName)}>
                Export
            </Button>
        </Tooltip>
    );
};

export default ExportExcel;
