import React, { useEffect, useRef } from "react";
import { agent, IHourlyBreakDownResponse, IHourlyInformation } from "api";
import { Card, Typography } from "@mui/material";
import { BarPlot, ChartsTooltip, ChartsXAxis, ChartsYAxis, ResponsiveChartContainer } from "@mui/x-charts";
import { format, parseISO } from "date-fns";

interface Props {
    chartTitle?: string;
    currencySymbol: string;
}

interface IChartData {
    value: number;
    label: Date;
}

export const BillingSummaryChart: React.FC<Props> = ({ chartTitle, currencySymbol }) => {
    const [chartData, setChartData] = React.useState<number[]>([]);
    const [chartLabels, setChartLabels] = React.useState<Date[]>([]);
    const ref = useRef<HTMLDivElement>(null);

    const [hourlyBreakdown, setHourlyBreakdown] = React.useState<IHourlyBreakDownResponse>();

    const getHourlyBreakdown = async () => {
        try {
            await agent.Billing.serviceConsumptionHourlyBreakdown({
                start_date: new Date().toDateString(),
                end_date: new Date().toDateString(),
                page_nr: 1,
                items_per_page: 24
            }).then((response) => {
                setHourlyBreakdown(response);
                console.log(response);
            });
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getHourlyBreakdown();
    }, []);

    useEffect(() => {
        const newChartData: number[] = [];
        const newChartLabels: Date[] = [];
        if (hourlyBreakdown?.hours) {
            hourlyBreakdown.hours.forEach((breakdownItem: IHourlyInformation) => {
                if (breakdownItem.cost && breakdownItem.time) {
                    newChartData.push(breakdownItem.cost);
                    newChartLabels.push(breakdownItem.time);
                }
            });
            setChartData(newChartData);
            setChartLabels(newChartLabels);
        }
    }, [hourlyBreakdown]);
    return (
        <Card ref={ref} sx={{ width: "100%", height: "100%", pt: 1 }}>
            {chartData.length > 0 ? (
                <>
                    {chartTitle && <Typography sx={{ display: "flex", justifyContent: "center" }}>{chartTitle}</Typography>}

                    <ResponsiveChartContainer
                        disableAxisListener
                        height={250}
                        series={[
                            {
                                type: "bar",
                                data: chartData.slice(),
                                valueFormatter: (item) => `${currencySymbol}${item}`,
                                highlightScope: { faded: "global", highlighted: "item" }
                            }
                        ]}
                        xAxis={[
                            {
                                data: chartLabels.slice(),
                                scaleType: "band",
                                id: "x-axis-id",
                                valueFormatter: (item) => format(parseISO(item), "HH:mm")
                            }
                        ]}>
                        <BarPlot />
                        <ChartsTooltip trigger="item" />
                        <ChartsXAxis label="Time" position="bottom" axisId="x-axis-id" />
                        <ChartsYAxis label={`Cost (${currencySymbol})`} />
                    </ResponsiveChartContainer>
                </>
            ) : (
                <Typography sx={{ display: "flex", justifyContent: "center" }}>No Data to display</Typography>
            )}
        </Card>
    );
};

export default BillingSummaryChart;
