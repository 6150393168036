import { RootStore } from "./rootStore";
import { observable, action, reaction, computed, makeObservable } from "mobx";

export default class CommonStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);

        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem("jwt", token);
                } else {
                    window.localStorage.removeItem("jwt");
                }
            }
        );
    }

    @observable currentTheme = "dark";
    @observable token: string | null = window.localStorage.getItem("jwt");
    @observable appLoaded = false;
    @observable pageInFocus = false;
    @observable pageScrolling = false;
    @observable showHelpPanel = false;
    @observable helpPanelUrl = "https://docs.livelink.video/";

    @computed get axiosParams() {
        const search = window.location.href;
        const params = new URLSearchParams(search);
        const p = { token: params.get("token"), email: params.get("email") };
        return p;
    }

    @action setCurrentTheme = (theme: string) => {
        this.currentTheme = theme;
    };

    @action setPageInFocus = (newValue: boolean): void => {
        this.pageInFocus = newValue;
    };

    @action setPageScrolling = (newValue: boolean) => {
        this.pageScrolling = newValue;
    };

    @action setToken = (token: string | null) => {
        this.token = token;
        if (token) {
            window.localStorage.setItem("jwt", token);
        }
    };

    @action setAppLoaded = (): void => {
        this.appLoaded = true;
    };

    @action setShowHelpPanel = (newValue: boolean): void => {
        this.showHelpPanel = newValue;
    };
}
