import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React from "react";

const LoadingComponent: React.FC<{ inverted?: boolean; content?: string }> = ({ inverted = true, content }) => (
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex", flexDirection: "column" }}
        open>
        <CircularProgress color="primary" />
        {content && <Typography sx={{ marginTop: 2 }}>{content}</Typography>}
    </Backdrop>
);

export default LoadingComponent;
