import View, { ViewBody, ViewHeader } from "components/Layout/View";
import React from "react";
import { RootStoreContext } from "api";
import { ResCollection } from "resclient";
import { Box, Button, List, ListItem, ListItemButton } from "@mui/material";
import PullPackagerItem from "./Components/PullPackagerItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { observer } from "mobx-react-lite";
import Content from "components/Layout/Content";
import { useNavigate } from "react-router-dom";
import Scrambling from "./Scrambling";

const PullPackager: React.FC = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { client } = rootStore.resClientStore;
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [wsData, setWsData] = React.useState<ResCollection | any>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        rootStore.resClientStore.clientConnected &&
            client.get(`nealive.${rootStore.userStore.managingOrganisationId!}`).then((res) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });
                setWsData(res);
                console.log(res);
            });
    }, [client, rootStore.userStore.managingOrganisationId, rootStore.resClientStore.clientConnected]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <View>
            <ViewHeader title="Pull Packagers"></ViewHeader>
            <ViewBody noPadding>
                <Content
                    noPadding
                    leftWidth={200}
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/pull-packager"}
                                    onClick={() => {
                                        navigate("../pull-packager");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Instances</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/pull-packager/scrambling"}
                                    onClick={() => {
                                        navigate("scrambling");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Scrambling Presets</Box>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }>
                    {location.pathname === "/pull-packager" && (
                        <Content
                            headerContent={
                                <Box
                                    sx={{
                                        py: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    <Box></Box>
                                    <Button
                                        variant="contained"
                                        onClick={() => navigate("/pull-packager/create")}
                                        startIcon={<AddCircleIcon />}>
                                        Add New Packager
                                    </Button>
                                </Box>
                            }>
                            {wsData &&
                                Object.entries(wsData.toJSON())
                                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                    .map(([key, value]: [string, any]) => <PullPackagerItem key={key} rid={value.rid} />)}
                        </Content>
                    )}
                    {location.pathname === "/pull-packager/scrambling" && <Scrambling />}
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(PullPackager);
