import React from "react";
import { Tab } from "@mui/material";

interface ISmallTabProps {
    value: string;
    label: string;
    sx?: object;
    [key: string]: any;
}

/**
 * `SmallTab` is a wrapper component for Material-UI's `Tab` component.
 * It applies consistent styling to the `Tab` component, specifically a smaller font size and a minimum width.
 *
 * @component
 * @example
 * ```tsx
 * <SmallTab value="tab1" label="Tab 1" />
 * ```
 * @param {ISmallTabProps} props - The properties that define the `Tab` component.
 * @param {string} props.value - The value prop holds the current value of the component. In the context of a `Tab` component, it is used to hold the current active tab's index.
 * @param {string} props.label - The label prop holds the text that will be displayed on the tab itself.
 * @param {object} props.sx - The style prop allows for styling directly within the component. Styles are written as a JavaScript object.
 * @param {any} props[key: string] - Any other props will be passed directly to the underlying Material-UI `Tab` component.
 */
export const SmallTab: React.FC<ISmallTabProps> = ({ value, label, sx, ...props }) => (
    <Tab
        {...props}
        label={label}
        value={value}
        sx={{
            fontSize: '0.625rem',
            minWidth: '50px !important',
            height: '28px',
            minHeight: '28px',

            '&.Mui-selected': {
                backgroundColor: '#0006'
            },

            ...sx
        }}
    />
)

export default SmallTab;
