import React, { useContext } from "react";

import Content from "components/Layout/Content";
import { Alert, AlertTitle, Box, Button, Card, Divider, Stack, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { agent, RootStoreContext, ServiceConsumptionResponseV2 } from "api";
import { endOfDay, startOfDay, startOfWeek, startOfMonth } from "date-fns";
import BillingSummary from "./Components/BillingSummary";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TodayIcon from "@mui/icons-material/Today";
import CloudProviderBreakdown from "./Components/CloudProviderBreakdown";
import HourlyBreakdown from "./Components/HourlyBreakdown";
import BillingEnvironmentSelect from "./Components/BillingEnvironmentSelect";
import BillingInformationSection from "features/billing/Components/BillingInformationSection";
import { observer } from "mobx-react-lite";
import { useBilling } from "hooks";

export const BillingHome: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { selectedEnvironment, selectedDestination } = rootStore.billingStore;
    const { setSelectedEnvironment } = useBilling();

    const [datesScope, setDatesScope] = React.useState<any>([new Date(), new Date()]);

    const [serviceConsumptionData, setServiceConsumptionData] = React.useState<ServiceConsumptionResponseV2>();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [selectedDateRange, setSelectedDateRange] = React.useState<any>("today");

    const [env, setEnv] = React.useState<string>("");

    const [selectedCloudProvider, setSelectedCloudProvider] = React.useState<string>("");

    const [invalidDateRange, setInvalidDateRange] = React.useState<boolean>(false);
    const [invalidDateRangeMessage, setInvalidDateRangeMessage] = React.useState<string>("");

    const handleDateRangeChange = (dates: any) => {
        validateDates();
        setDatesScope(dates);
        setSelectedDateRange("custom");
    };

    const getBillingData = async () => {
        validateDates();
        setLoading(true);
        !invalidDateRange &&
            (await agent.Billing.serviceConsumption({
                start_date: startOfDay(new Date(datesScope[0])).toDateString(),
                end_date: endOfDay(new Date(datesScope[1])).toDateString(),
                environment_id: selectedEnvironment,
                destination_id: selectedDestination
            })
                .then((response) => {
                    console.log("Billing Data", response);
                    setServiceConsumptionData(response);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                }));

        setLoading(false);
    };

    const getBillingDataNew = async (startDate: Date, endDate: Date) => {
        await agent.Billing.serviceConsumption({
            start_date: startOfDay(startDate).toDateString(),
            end_date: endOfDay(endDate).toDateString(),
            environment_id: selectedEnvironment,
            destination_id: selectedDestination
        })
            .then((response) => {
                setServiceConsumptionData(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const setToday = () => {
        // use date-fns to get the start/end dates of the week
        validateDates();
        const start = startOfDay(new Date());
        const end = endOfDay(new Date());
        setDatesScope([start, end]);
        setSelectedDateRange("today");
    };

    const setYesterday = () => {
        // use date-fns to get startOfDay and endOfDay of yesterday
        validateDates();
        const start = startOfDay(new Date(new Date().setDate(new Date().getDate() - 1)));
        const end = endOfDay(new Date(new Date().setDate(new Date().getDate() - 1)));
        setDatesScope([start, end]);
        setSelectedDateRange("yesterday");
    };

    const setThisWeek = () => {
        // use date-fns to get the start/end dates of the week
        validateDates();
        const start = startOfWeek(new Date(), { weekStartsOn: 1 });
        const end = endOfDay(new Date());
        setDatesScope([start, end]);
        setSelectedDateRange("thisWeek");
    };

    const setThisMonth = () => {
        // use date-fns to get the start/end dates of the week
        validateDates();
        const start = startOfMonth(new Date());
        const end = endOfDay(new Date());
        setDatesScope([start, end]);
        setSelectedDateRange("thisMonth");
    };

    const validateDates = () => {
        if (datesScope[0] > datesScope[1]) {
            console.log("start date is greater than end date");
            //setDatesScope([datesScope[1], datesScope[0]]);
            setInvalidDateRange(true);
            setInvalidDateRangeMessage("Start date is greater than end date");
            return;
        }

        //out of range, start date and end date must be no more than 180 days apart, use date-fns and check that both values exist first
        if (datesScope[0] && datesScope[1]) {
            if (datesScope[1].getTime() - datesScope[0].getTime() > 15552000000) {
                console.log("date range is greater than 180 days");
                setInvalidDateRange(true);
                setInvalidDateRangeMessage("Date range is greater than 180 days");
                return;
            }
        }

        //if start date (datesScope[0]) is in the future display an error
        if (datesScope[0] > new Date()) {
            console.log("start date is in the future");
            setInvalidDateRange(true);
            setInvalidDateRangeMessage("Start date is in the future");
            return;
        }

        setInvalidDateRange(false);
        setInvalidDateRangeMessage("");
    };

    React.useEffect(() => {
        getBillingData();
    }, [selectedEnvironment, selectedDestination, datesScope]);

    React.useEffect(() => {
        return () => {
            setServiceConsumptionData(undefined);
            setSelectedEnvironment("");
        };
    }, []);

    return (
        <Content
            loading={loading}
            headerContent={
                serviceConsumptionData && (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", py: 0.5 }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mr: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateRangePicker
                                    value={datesScope}
                                    onChange={(newValue) => {
                                        handleDateRangeChange(newValue);
                                        newValue[0] && newValue[1] && getBillingDataNew(newValue[0], newValue[1]);
                                    }}
                                    format="dd/MM/yyyy"
                                    calendars={1}
                                    minDate={
                                        //180 days ago
                                        new Date(new Date().setDate(new Date().getDate() - 179))
                                    }
                                    maxDate={new Date()}
                                    disableFuture={true}
                                    slotProps={{
                                        textField: { variant: "outlined", size: "small", sx: { marginBottom: 0, width: 130 } }
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Stack direction="row" spacing={1}>
                            <Button
                                variant={selectedDateRange === "today" ? "contained" : "toolbar"}
                                onClick={() => setToday()}
                                startIcon={<TodayIcon />}>
                                Today
                            </Button>
                            {/* <Button
                                variant={selectedDateRange === "yesterday" ? "contained" : "toolbar"}
                                onClick={() => setYesterday()}
                                startIcon={<TodayIcon />}>
                                Yesterday
                            </Button> */}
                            <Button
                                variant={selectedDateRange === "thisWeek" ? "contained" : "toolbar"}
                                onClick={() => setThisWeek()}
                                startIcon={<CalendarViewWeekIcon />}>
                                This Week
                            </Button>
                            <Button
                                variant={selectedDateRange === "thisMonth" ? "contained" : "toolbar"}
                                onClick={() => setThisMonth()}
                                startIcon={<CalendarMonthIcon />}>
                                This Month
                            </Button>
                        </Stack>
                        {/* <Divider orientation="vertical" flexItem sx={{ mx: 3}} />
                        <Box>
                            <BillingEnvironmentSelect env={env} setEnv={setEnv} setSelectedCloudProvider={setSelectedCloudProvider} />
                        </Box> */}
                    </Box>
                )
            }>
            {invalidDateRange && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    <AlertTitle>Invalid Date Range</AlertTitle>
                    {invalidDateRangeMessage}
                </Alert>
            )}
            {!invalidDateRange && serviceConsumptionData && (
                <>
                    <Typography variant="h5" gutterBottom>
                        Summary
                    </Typography>
                    <BillingInformationSection />
                    {serviceConsumptionData && <BillingSummary datesScope={datesScope} serviceConsumptionData={serviceConsumptionData} />}
                    <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                        Cloud Provider Breakdown
                    </Typography>
                    {/* <Card sx={{ pr: 3, pl: 2, pt: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                        <BillingEnvironmentSelect env={env} setEnv={setEnv} setSelectedCloudProvider={setSelectedCloudProvider} />
                    </Card> */}
                    {serviceConsumptionData && (
                        <CloudProviderBreakdown
                            serviceConsumptionData={serviceConsumptionData}
                            selectedCloudProvider={selectedCloudProvider}
                        />
                    )}
                    <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                        Hourly Breakdown
                    </Typography>
                    {serviceConsumptionData && (
                        <HourlyBreakdown
                            start_date={startOfDay(new Date(datesScope[0]))}
                            end_date={endOfDay(new Date(datesScope[1]))}
                            currency_symbol={serviceConsumptionData.customer_currency_symbol}
                        />
                    )}
                </>
            )}
        </Content>
    );
};

export default observer(BillingHome);
