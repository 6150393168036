import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const SchedulerViewSelect: React.FC = () => {
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value === "day") {
            navigate("/scheduler");
        }
        if (event.target.value === "week") {
            navigate("/scheduler/week");
        }
        if (event.target.value === "month") {
            navigate("/scheduler/month");
        }
        if (event.target.value === "list") {
            navigate("/scheduler/list");
        }
    };

    return (
        <TextField
            label="View"
            variant="outlined"
            size="small"
            select
            value={
                window.location.pathname === "/scheduler"
                    ? "day"
                    : window.location.pathname === "/scheduler/week"
                    ? "week"
                    : window.location.pathname === "/scheduler/month"
                    ? "month"
                    : window.location.pathname === "/scheduler/list"
                    ? "list"
                    : "day"
            }
            sx={{ width: 100 }}
            onChange={handleChange}>
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="list">List</MenuItem>
        </TextField>
    );
};

export default SchedulerViewSelect;
