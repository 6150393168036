import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStoreContext } from "api";
import { observer } from "mobx-react";

type HelpPanelProps = {
    url: string;
};

const HelpPanel: React.FC<HelpPanelProps> = observer(function HelpPanel({ url }) {
    const rootStore = React.useContext(RootStoreContext);

    const { showHelpPanel, setShowHelpPanel } = rootStore.commonStore;

    return (
        <div
            style={{
                display: "flex",
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                width: 420,
                backgroundColor: "#0f1419",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
                zIndex: 1000000,
                transform: showHelpPanel ? "translateX(0)" : "translateX(110%)",
                transition: "transform 0.3s ease-in-out"
            }}>
            {showHelpPanel && <iframe src={url} width={420} title="Help" frameBorder="false" />}
            <div
                style={{
                    position: "absolute",
                    backgroundColor: "#1d2932",
                    color: "#fff",
                    fontSize: 30,
                    width: 50,
                    height: 50,
                    right: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                }}>
                <FontAwesomeIcon icon={["fal", "times"]} onClick={() => setShowHelpPanel(false)} />
            </div>
        </div>
    );
});

export default HelpPanel;
