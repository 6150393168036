import { Alert } from "@mui/material";
import { IEnvironmentFormValues, RootStoreContext } from "api";
import OnChange from "components/FinalFormListener/OnChange";
import { CheckboxField, SelectField, TextField } from "components/Forms";
import { useEnvironment } from "hooks";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

interface ISelectIpAddress {
    values: IEnvironmentFormValues;
}

const SelectIpAddress: React.FC<ISelectIpAddress> = ({ values }) => {
    const rootStore = useContext(RootStoreContext);
    const { savedIpAddresses, setIpAddress, codeChanged } = rootStore.environmentStore;
    const { getEnvironmentsDetails, getIpAddresses } = useEnvironment();

    useEffect(() => {
        if (!savedIpAddresses || codeChanged === true) {
            getIpAddresses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getIpAddresses]);

    useEffect(() => {
        getEnvironmentsDetails();
    }, [getEnvironmentsDetails]);

    const handleChange = (value: string) => {
        setIpAddress(value);
    };

    return (
        <>
            {savedIpAddresses && (
                <>
                    <SelectField
                        label="Select IP Address"
                        options={[
                            { text: "New IP address", value: 0 },
                            ...savedIpAddresses.map((ip) => {
                                return { text: ip.name, value: ip.id };
                            })
                        ]}
                        validate={(value) => {
                            if (!value && value !== 0) {
                                return "Required";
                            }
                        }}
                        name="build_using_stored_ip_id"
                        helperText={(meta) => {
                            return meta.touched && meta.error ? meta.error : "Choose an IP Address for your environment";
                        }}
                        error={(meta) => meta.touched && meta.error}
                    />
                    <OnChange name="build_using_stored_ip_id">
                        {(value) => {
                            handleChange(value);
                        }}
                    </OnChange>
                </>
            )}

            <CheckboxField
                name="preserve_ip_when_deleting_environment"
                label="Would you like to reserve the IP address for use after deletion?"
                sx={{ mb: 2 }}
                helperText={(meta) => {
                    return meta.touched && meta.error ? meta.error : "";
                }}
                error={(meta) => meta.touched && meta.error}
            />

            {values?.preserve_ip_when_deleting_environment && (
                <>
                    <TextField
                        required
                        label="Reserved IP address name"
                        name="preserved_ip_name"
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        error={(meta: any) => {
                            return meta.touched && meta.error ? true : false;
                        }}
                        helperText={(meta) => {
                            return meta.touched && meta.error ? meta.error : "Name your reserved IP address";
                        }}
                    />
                    <Alert severity="info" sx={{ marginTop: 1 }}>
                        Reserved IP addresses have a cost associated when not attached to an environment. Please navigate to settings to
                        release this IP once the enviroment is deleted if no longer required.
                    </Alert>
                </>
            )}
        </>
    );
};

export default observer(SelectIpAddress);
