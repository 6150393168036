import { Button, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { agent, RootStoreContext } from "api";
import ErrorMessages from "components/ErrorMessages";
import { useFileCluster } from "hooks";

const CreateDeploymentConfirmation = observer(function CreateDeploymentConfirmation() {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <form>
            <DialogTitle>Create New Cluster</DialogTitle>
            <DialogContent>
                <p>Your new cluster is now being created. This may take up to 20 minutes to complete.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
        </form>
    );
});

const CreateDeploymentForm = observer(function CreateDeploymentForm() {
    const rootStore = useContext(RootStoreContext);
    const [region, setRegion] = useState("");
    const { regions } = rootStore.fileClusterStore;
    const { getRegionsDropDownValues } = useFileCluster();

    const [errors, setErrors] = useState<any>();

    useEffect(() => {
        if (!regions) {
            getRegionsDropDownValues();
        }
    }, [getRegionsDropDownValues, regions]);

    const { openDialog, closeDialog } = rootStore.dialogStore;

    const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setRegion(event.target.value as string);
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            name: { value: string };
        };

        const name = target.name.value; // typechecks!

        await agent.FileCluster.createDeployment({ cloud_provider: "aws", name: name, region: region })
            .then(() => {
                setErrors(null);
                closeDialog();
                openDialog(<CreateDeploymentConfirmation />);
            })
            .catch((error) => {
                error && setErrors(error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An error occurred");
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle>Create New Cluster</DialogTitle>

            <DialogContent>
                <TextField variant="standard" id="new-deployment-name" name="name" label="Name" required style={{ marginBottom: "20px" }} />

                <FormControl variant="standard">
                    <InputLabel shrink id="region-select-label">
                        Region
                    </InputLabel>

                    <Select
                        variant="standard"
                        labelId="region-select-label"
                        id="region-select"
                        style={{ width: "100%" }}
                        name="region"
                        value={region}
                        onChange={(event: any) => handleChange(event)}
                        required
                        placeholder="Region">
                        {regions?.data.map((region, i) => {
                            return (
                                <MenuItem key={i} value={region.code}>
                                    {region.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <ErrorMessages errors={errors} />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button variant="contained" color="primary" type="submit">
                    Create File Cluster
                </Button>
            </DialogActions>
        </form>
    );
});

export default CreateDeploymentForm;
