import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, IconButton, Stack, Tab, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import DestinationAlerts from "./DestinationAlerts";
import { RootStoreContext } from "api";
import DeleteIcon from "@mui/icons-material/Delete";
import EventIcon from "@mui/icons-material/Event";
import EditIcon from "@mui/icons-material/Edit";
import { ConfirmDelete } from "../destinationDelete/ConfirmDelete";
import RoleProvider from "components/RoleProvider";
import CreateMultiScheduleForm from "features/scheduler/Components/CreateMultiScheduleForm";
import OrganisationRoleProvider from "components/OrganisationRoleProvider";
import DestinationStatistics from "./DestinationStatistics";
import InfoItem from "components/InfoItem";
import DestinationSchedule from "./DestinationSchedule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDestinations } from "hooks";

interface IProps {
    destination_id: string;
    tab?: string;
}

const DestinationDetails: React.FC<IProps> = (props) => {
    const [tabValue, setTabValue] = React.useState(props.tab || "1");
    const navigate = useNavigate();
    const history = useLocation();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const rootStore = useContext(RootStoreContext);
    const { destination } = rootStore.destinationStore;
    const { getCurrentDestinationDetails } = useDestinations();
    const { closeDrawer } = rootStore.drawerStore;

    const { openDialog } = rootStore.dialogStore;

    useEffect(() => {
        getCurrentDestinationDetails(props.destination_id).then(() => {
            //console.log(destination);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.destination_id]);

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", maxHeight: "100%" }}>
            <Box>
                <Box sx={{ padding: 1 }}>
                    <Typography gutterBottom variant="h6" component="div">
                        {destination?.data?.display_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {destination?.data?.description}
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: 4 }}>
                    <OrganisationRoleProvider roles={["admin", "operator"]}>
                        <Stack direction="row" justifyContent="flex-start" spacing={1}>
                            <Tooltip title="Schedule A Source" placement="top">
                                <IconButton
                                    aria-label="schedule"
                                    onClick={() =>
                                        openDialog(
                                            <CreateMultiScheduleForm destination_id={props.destination_id} />,
                                            "Create Schedule",
                                            "lg",
                                            true
                                        )
                                    }>
                                    <EventIcon />
                                </IconButton>
                            </Tooltip>
                            <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                <Tooltip title="Edit Destination" placement="top">
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => {
                                            closeDrawer();
                                            navigate(`/destinations/edit/${props.destination_id}`, {
                                                state: { returnUrl: history.pathname }
                                            });
                                        }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Destination" placement="top">
                                    <IconButton
                                        aria-label="delete"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            openDialog(<ConfirmDelete id={props.destination_id} details={destination!.data} />);
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </RoleProvider>
                        </Stack>
                    </OrganisationRoleProvider>
                </Box>
            </Box>
            <TabContext value={tabValue}>
                <Box>
                    <TabList onChange={handleTabChange} aria-label="source details tabs">
                        <Tab label="Stats" value="1" />
                        <Tab label="Alerts" value="2" />
                        <Tab label="Schedule" value="3" />
                        <Tab label="Info" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                    <DestinationStatistics destination_id={props.destination_id} />
                </TabPanel>
                <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                    <DestinationAlerts destination_id={props.destination_id} />
                </TabPanel>
                <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                    <DestinationSchedule destination_id={props.destination_id} />
                </TabPanel>
                <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                    {destination?.data.technical_details.output_settings.map((setting, i) => (
                        <InfoItem key={i} title={setting.label || ""} value={setting.value} />
                    ))}
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default observer(DestinationDetails);
