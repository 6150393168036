import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import OrganisationPieChart from "./OrganisationPieChart";

interface ICogsItem {
    organisation: any;
    breakdown: any;
    usage_mode?: boolean;
}

export const CogsItem: React.FC<ICogsItem> = ({ organisation, breakdown, usage_mode }) => {
    // if (!organisation || !report) {
    //     return <></>;
    // }

    React.useEffect(() => {
        console.log(breakdown);
    }, [breakdown]);

    return (
        <Grid container spacing={2} sx={{ marginBottom: 6, position: "relative" }}>
            {!usage_mode && (
                <Grid item xs={3}>
                    <Card sx={{ position: "sticky", top: 0 }}>
                        <CardContent>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="h4">{organisation?.organisation_name}</Typography>
                            </Box>
                            {organisation && <OrganisationPieChart organisation_summary={organisation} />}
                        </CardContent>
                    </Card>
                </Grid>
            )}
        {organisation && (
            <Grid item xs={usage_mode ? 12 : 9}>
                <Card sx={{ position: "sticky", top: 0 }}>
                    <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                        <Box sx={{ marginRight: 2 }}>
                            <FontAwesomeIcon icon={["fal", "hard-drive"]} size="3x" />
                        </Box>
                        <Box sx={{ marginRight: 2 }}>
                            <Typography variant="h6" component="div">
                                Disk Storage
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {organisation?.disk_storage_costs?.description}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: "auto",
                                paddingLeft: 3,
                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Hours
                            </Typography>
                            <Typography variant="h5" component="div">
                                {organisation?.disk_storage_costs?.number_of_hours.toFixed(2) || 0}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: 3,
                                paddingLeft: 3,
                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Usage
                            </Typography>
                            <Typography variant="h5" component="div">
                                {organisation.disk_storage_costs.usage_value.toFixed(2)}GB/h
                            </Typography>
                        </Box>
                        {!usage_mode && (
                            <Box
                                sx={{
                                    marginLeft: 3,
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Cost
                                </Typography>
                                <Typography variant="h5" component="div">
                                    ${organisation.disk_storage_costs.cost_usd.toFixed(2)}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Usage</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.disk_storage_costs.length > 0 &&
                            breakdown.disk_storage_costs
                                .filter((row) => row.organisation_id === organisation.organisation_id)
                                .map((row, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <Box>{row.resource_name}</Box>
                                                <Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        UUID: {row.unit_uuid}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Provider: {row.cloud_provider}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Region: {row.region}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{row.product_size}</Box>
                                                <Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Size: {row.disk_size_gb}GB
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Pricing Model: {row.pricing_model}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    {((row.number_of_seconds / 60 / 60) * row.disk_size_gb).toFixed(2)}
                                                    GB/h
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Start: {format(new Date(row.calculation_start_time), "dd/MM/yyyy HH:mm")}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Stop: {format(new Date(row.calculation_stop_time), "dd/MM/yyyy HH:mm")}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                        </TableRow>
                                    );
                                })}
                    </TableBody>
                </Table>
                <Box sx={{ height: 30 }} />
                <Card sx={{ position: "sticky", top: 0 }}>
                    <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                        <Box sx={{ marginRight: 2 }}>
                            <FontAwesomeIcon icon={["fal", "arrow-right-from-arc"]} size="3x" />
                        </Box>
                        <Box>
                            <Typography variant="h6" component="div">
                                Elastic IP
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {organisation.elastic_ip_costs.description}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: "auto",
                                paddingLeft: 3,
                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Hours
                            </Typography>
                            <Typography variant="h5" component="div">
                                {organisation.elastic_ip_costs.usage_value.toFixed(2)}
                            </Typography>
                        </Box>
                        {!usage_mode && (
                            <Box
                                sx={{
                                    marginLeft: 3,
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Cost
                                </Typography>
                                <Typography variant="h5" component="div">
                                    ${organisation.elastic_ip_costs.cost_usd}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.elastic_ip_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>{(row.number_of_seconds / 60 / 60).toFixed(5)} hours</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Start: {format(new Date(row.calculation_start_time), "dd/MM/yyyy HH:mm")}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Stop: {format(new Date(row.calculation_stop_time), "dd/MM/yyyy HH:mm")}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Box sx={{ height: 30 }} />
                <Card sx={{ position: "sticky", top: 0 }}>
                    <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                        <Box sx={{ marginRight: 2 }}>
                            <FontAwesomeIcon icon={["fal", "cloud-arrow-down"]} size="3x" />
                        </Box>
                        <Box>
                            <Typography variant="h6" component="div">
                                Egress
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {organisation.external_egress_costs.description}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: "auto",
                                paddingLeft: 3,
                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Usage (Chargable)
                            </Typography>
                            <Typography variant="h5" component="div">
                                {organisation.external_egress_costs.usage_value / 1000}GB
                            </Typography>
                        </Box>
                        {!usage_mode && (
                            <Box
                                sx={{
                                    marginLeft: 3,
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Cost
                                </Typography>
                                <Typography variant="h5" component="div">
                                    ${organisation.external_egress_costs.cost_usd.toFixed(2)}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.egress_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>{row.data_egress_gb}GB</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Type: {row.egress_type}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Box sx={{ height: 30 }} />
                <Card sx={{ position: "sticky", top: 0 }}>
                    <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                        <Box sx={{ marginRight: 2 }}>
                            <FontAwesomeIcon icon={["fal", "file-video"]} size="3x" />
                        </Box>
                        <Box>
                            <Typography variant="h6" component="div">
                                Titan File
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {organisation.titan_file_cogs.description}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: "auto",
                                paddingLeft: 3,
                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Active Duration
                            </Typography>
                            <Typography variant="h5" component="div">
                                {organisation.titan_file_cogs.total_active_duration}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: "auto",
                                paddingLeft: 3,
                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                CPU Duration
                            </Typography>
                            <Typography variant="h5" component="div">
                                {organisation.titan_file_cogs.total_cpu_duration}
                            </Typography>
                        </Box>
                        {!usage_mode && (
                            <Box
                                sx={{
                                    marginLeft: 3,
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Cost
                                </Typography>
                                <Typography variant="h5" component="div">
                                    ${organisation.titan_file_cogs.cost_usd}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.titan_file_cogs_costs &&
                            breakdown.titan_file_cogs_costs.length > 0 &&
                            breakdown.titan_file_cogs_costs
                                .filter((row) => row.organisation_id === organisation.organisation_id)
                                .map((row, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <Box>{row.resource_name}</Box>
                                                <Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Deployment: {row.deployment_id}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Provider: {row.cloud_provider}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Region: {row.region}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Active Duration: {row.total_active_duration}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        CPU Duration: {row.total_cpu_duration}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                        </TableRow>
                                    );
                                })}
                    </TableBody>
                </Table>
                <Box sx={{ height: 30 }} />

                <Card sx={{ position: "sticky", top: 0 }}>
                    <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                        <Box sx={{ marginRight: 2 }}>
                            <FontAwesomeIcon icon={["fal", "server"]} size="3x" />
                        </Box>
                        <Box>
                            <Typography variant="h6" component="div">
                                Virtual Machine
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {organisation.virtual_machine_run_time.description}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: "auto",
                                paddingLeft: 3,
                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Hours
                            </Typography>
                            <Typography variant="h5" component="div">
                                {organisation.virtual_machine_run_time.usage_value}
                            </Typography>
                        </Box>
                        {!usage_mode && (
                            <Box
                                sx={{
                                    marginLeft: 3,
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Cost
                                </Typography>
                                <Typography variant="h5" component="div">
                                    ${organisation.virtual_machine_run_time.cost_usd}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <div style={{ fontSize: 17 }}>Running Environment</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage (seconds)</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.running_aws_c_env_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{row.number_of_seconds}</TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <div style={{ fontSize: 17 }}>Transcoder</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage (seconds)</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.transcoder_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{row.number_of_seconds}</TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <div style={{ fontSize: 17 }}>Multiviewer</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage (seconds)</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.multiviewer_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Tiles: {row.nr_of_tiles}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{row.number_of_seconds}</TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <div style={{ fontSize: 17 }}>Push Packager</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage (seconds)</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.push_packager_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{row.number_of_seconds}</TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <div style={{ fontSize: 17 }}>Pull Packager Transcoder</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage (seconds)</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.pull_packager_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{row.number_of_seconds}</TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <div style={{ fontSize: 17 }}>Pull Packager Instance</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage (seconds)</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.nea_live_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{row.number_of_seconds}</TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                {organisation.nea_live_cdn_egress_costs && (
                    <Card sx={{ position: "sticky", top: 0 }}>
                        <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                            <Box sx={{ marginRight: 2 }}>
                                <FontAwesomeIcon icon={["fal", "arrows-to-circle"]} size="3x" />
                            </Box>
                            <Box>
                                <Typography variant="h6" component="div">
                                    Pull Packager CDN Egress
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {organisation.nea_live_cdn_egress_costs.description}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginLeft: "auto",
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    MBs
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {organisation.nea_live_cdn_egress_costs.usage_value}
                                </Typography>
                            </Box>
                            {!usage_mode && (
                                <Box
                                    sx={{
                                        marginLeft: 3,
                                        paddingLeft: 3,
                                        borderLeft: "1px solid rgba(160,160,160,0.2)"
                                    }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Cost
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        ${organisation.nea_live_cdn_egress_costs.cost_usd}
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Usage</TableCell>
                            {!usage_mode && <TableCell align="right">Cost (USD)</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breakdown.nea_live_cdn_egress_costs
                            .filter((row) => row.organisation_id === organisation.organisation_id)
                            .map((row, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Box>{row.resource_name}</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    UUID: {row.unit_uuid}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Provider: {row.cloud_provider}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Region: {row.region}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box>{row.data_egress_gb}GB</Box>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Type: {row.egress_type}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        {!usage_mode && <TableCell align="right">${row.cost_usd}</TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                {organisation.tag_licence_tile_costs && (
                    <Card sx={{ position: "sticky", top: 0, mb: 2 }}>
                        <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                            <Box sx={{ marginRight: 2 }}>
                                <FontAwesomeIcon icon={["fal", "table-cells-large"]} size="3x" />
                            </Box>
                            <Box>
                                <Typography variant="h6" component="div">
                                    Tag Licence Tile
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {organisation.tag_licence_tile_costs.description}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginLeft: "auto",
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Tile Hours
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {organisation.tag_licence_tile_costs.usage_value}
                                </Typography>
                            </Box>
                            {!usage_mode && (
                                <Box
                                    sx={{
                                        marginLeft: 3,
                                        paddingLeft: 3,
                                        borderLeft: "1px solid rgba(160,160,160,0.2)"
                                    }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Cost
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        ${organisation.tag_licence_tile_costs.cost_usd}
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
                {organisation.external_zixi_licence_egress_costs && (
                    <Card sx={{ position: "sticky", top: 0, mb: 2 }}>
                        <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                            <Box sx={{ marginRight: 2 }}>
                                <FontAwesomeIcon icon={["fal", "video-arrow-up-right"]} size="3x" />
                            </Box>
                            <Box>
                                <Typography variant="h6" component="div">
                                    External Zixi Licence Egress
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {organisation.external_zixi_licence_egress_costs.description}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginLeft: "auto",
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    MBs
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {organisation.external_zixi_licence_egress_costs.usage_value}
                                </Typography>
                            </Box>
                            {!usage_mode && (
                                <Box
                                    sx={{
                                        marginLeft: 3,
                                        paddingLeft: 3,
                                        borderLeft: "1px solid rgba(160,160,160,0.2)"
                                    }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Cost
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        ${organisation.external_zixi_licence_egress_costs.cost_usd}
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
                {organisation.internal_zixi_licence_egress_costs && (
                    <Card sx={{ position: "sticky", top: 0, mb: 2 }}>
                        <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                            <Box sx={{ marginRight: 2 }}>
                                <FontAwesomeIcon icon={["fal", "video-arrow-down-left"]} size="3x" />
                            </Box>
                            <Box>
                                <Typography variant="h6" component="div">
                                    Internal Zixi Licence Egress
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {organisation.internal_zixi_licence_egress_costs.description}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginLeft: "auto",
                                    paddingLeft: 3,
                                    borderLeft: "1px solid rgba(160,160,160,0.2)"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    MBs
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {organisation.internal_zixi_licence_egress_costs.usage_value}
                                </Typography>
                            </Box>
                            {!usage_mode && (
                                <Box
                                    sx={{
                                        marginLeft: 3,
                                        paddingLeft: 3,
                                        borderLeft: "1px solid rgba(160,160,160,0.2)"
                                    }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Cost
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        ${organisation.internal_zixi_licence_egress_costs.cost_usd}
                                    </Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}

                <Box sx={{ height: 30 }} />
                {/* <Card sx={{ position: "sticky", top: 0 }}>
                <CardContent sx={{ display: "flex", alignItems: "center", height: 105 }}>
                    <Box sx={{ marginRight: 2 }}>
                        <FontAwesomeIcon icon={["fal", "server"]} size="3x" />
                    </Box>
                    <Box>
                        <Typography variant="h6" component="div">
                            Push Packagers Costs
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {organisation.virtual_machine_run_time.description}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginLeft: "auto",
                            paddingLeft: 3,
                            borderLeft: "1px solid rgba(160,160,160,0.2)"
                        }}>
                        <Typography variant="body2" color="text.secondary">
                            Hours
                        </Typography>
                        <Typography variant="h5" component="div">
                            {organisation.virtual_machine_run_time.usage_value}
                        </Typography>
                    </Box>
                    <Box sx={{ marginLeft: 3, paddingLeft: 3, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                        <Typography variant="body2" color="text.secondary">
                            Cost
                        </Typography>
                        <Typography variant="h5" component="div">
                            ${organisation.virtual_machine_run_time.cost_usd}
                        </Typography>
                    </Box>
                </CardContent>
            </Card> */}
            </Grid>
        )}
        </Grid>
    );
};

export default observer(CogsItem);
