import React, { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { TextField } from "mui-rff";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "app/common/form/ErrorMessage";
import { IUserFormValues, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import { Alert, AlertTitle, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const validate = combineValidators({
    totp_code: isRequired("totp_code")
});

const LoginTwoFactor: React.FC = observer(function LoginTwoFactor() {
    const rootStore = useContext(RootStoreContext);
    const { loginTwoFactor } = rootStore.userStore;
    const { closeDialog } = rootStore.dialogStore;
    const { logout } = rootStore.userStore;

    return (
        <div>
            <FinalForm
                onSubmit={(values: IUserFormValues) =>
                    loginTwoFactor(values)
                        .then(() => {
                            closeDialog();
                        })
                        .catch((error) => ({ [FORM_ERROR]: error }))
                }
                validate={validate}
                render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>2FA Verification</DialogTitle>
                        <DialogContent>
                            <Alert severity="info" sx={{ mb: 2 }}>
                                <AlertTitle>Two Factor Authentication is enabled on your account.</AlertTitle>
                                Please enter the 6 digit code from your Google Authenticator app.
                            </Alert>
                            <TextField
                                fullWidth
                                required
                                name="totp_code"
                                id="totp_code"
                                placeholder="Google Verification Code (2FA)"
                                label="Google Verification Code (2FA)"
                                type="text"
                            />
                            {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    logout;
                                    closeDialog();
                                }}>
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                loading={submitting}
                                endIcon={<LoginIcon />}>
                                Submit
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            />
        </div>
    );
});

export default LoginTwoFactor;
