import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {
    agent,
    IMtrGroupsListResponse,
    IMtrReportRequest,
    IMtrReportTicketResponse,
    IEnvironments
} from "api";
import ErrorMessages from "components/ErrorMessages";
import { ExpandMore } from "@mui/icons-material";

type GenerateMtrReportFormProps = {
    id: string;
    group_mode: boolean;
    host_mode: boolean;
    mtr_host_group_id?: number;
    mtr_host_id?: number;
    onLoading: (loading: boolean) => void;
    onSuccess?: () => void;
    onError?: () => void;
};

const GenerateMtrReportForm: React.FC<GenerateMtrReportFormProps> = (props) => {
    const [errors, setErrors] = React.useState<any>();
    const [environments, setEnvironments] = React.useState<IEnvironments>();

    const [environment, setEnvironment] = React.useState("");
    const [host] = React.useState<number>();
    const [hostname, setHostname] = React.useState<string>();
    const [saveToHosts, setSaveToHosts] = React.useState(false);
    const [addToGroup, setAddToGroup] = React.useState(false);
    const [newHostname, setNewHostname] = React.useState<string>();
    const [groups, setGroups] = React.useState<IMtrGroupsListResponse>();
    const [group, setGroup] = React.useState<number>();
    const [newGroupName, setNewGroupName] = React.useState<string>();

    const getEnvironments = async () => {
        await agent.Environment.getEnvironments().then((value: IEnvironments) => {
            setEnvironments(value);
        });
    };

    const getGroups = async () => {
        await agent.Reporting.getMtrGroupsList().then((value: IMtrGroupsListResponse) => {
            setGroups(value);
        });
    };

    const handleEnvironmentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setEnvironment(event.target.value as string);
    };

    const handleHostnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHostname(event.target.value as string);
    };

    const handleSaveToHostsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveToHosts(event.target.checked);
    };

    const handleNewHostnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            setNewHostname(event.target.value as string);
        }
    };

    const handleNewGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            setNewGroupName(event.target.value as string);
        }
    };

    const handleGroupChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setGroup(event.target.value as number);
    };

    const handleAddToGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddToGroup(event.target.checked);
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        props.onLoading(true);
        console.log(hostname);

        const target = e.target as typeof e.target & {
            hostname: { value: string };
            udp: { value: boolean; checked: boolean };
            tcp: { value: boolean; checked: boolean };
            nodns: { value: boolean; checked: boolean };
            showips: { value: boolean; checked: boolean };
            aslookup: { value: boolean; checked: boolean };
            firstttl: { value: any };
            maxttl: { value: any };
            maxunknown: { value: any };
            port: { value: any };
            psize: { value: any };
            interval: { value: any };
            gracetime: { value: any };
            timeout: { value: any };
            reportcycles: { value: any };
        };

        const request: IMtrReportRequest = props.group_mode
            ? {
                  mtr_host_group_id: props.mtr_host_group_id,
                  geolocate: true,
                  options: {
                      udp: target.udp.checked || undefined,
                      tcp: target.tcp.checked || undefined,
                      "no-dns": target.nodns.checked || undefined,
                      "show-ips": target.showips.checked || undefined,
                      aslookup: target.aslookup.checked || undefined,
                      "first-ttl": target.firstttl.value || undefined,
                      "max-ttl": target.maxttl.value || undefined,
                      "max-unknown": target.maxunknown.value || undefined,
                      port: target.port.value || undefined,
                      psize: target.psize.value || undefined,
                      interval: target.interval.value || undefined,
                      gracetime: target.gracetime.value || undefined,
                      timeout: target.timeout.value || undefined,
                      "report-cycles": target.reportcycles.value || undefined
                  }
              }
            : props.host_mode
            ? {
                  mtr_host_id: props.mtr_host_id,
                  geolocate: true,
                  options: {
                      udp: target.udp.checked || undefined,
                      tcp: target.tcp.checked || undefined,
                      "no-dns": target.nodns.checked || undefined,
                      "show-ips": target.showips.checked || undefined,
                      aslookup: target.aslookup.checked || undefined,
                      "first-ttl": target.firstttl.value || undefined,
                      "max-ttl": target.maxttl.value || undefined,
                      "max-unknown": target.maxunknown.value || undefined,
                      port: target.port.value || undefined,
                      psize: target.psize.value || undefined,
                      interval: target.interval.value || undefined,
                      gracetime: target.gracetime.value || undefined,
                      timeout: target.timeout.value || undefined,
                      "report-cycles": target.reportcycles.value || undefined
                  }
              }
            : {
                  hostname: target.hostname.value,
                  create_new_mtr_host_name: newHostname,
                  add_to_existing_host_group_id: group,
                  create_new_mtr_host_group_name: newGroupName,
                  geolocate: true,
                  options: {
                      udp: target.udp.checked || undefined,
                      tcp: target.tcp.checked || undefined,
                      "no-dns": target.nodns.checked || undefined,
                      "show-ips": target.showips.checked || undefined,
                      aslookup: target.aslookup.checked || undefined,
                      "first-ttl": target.firstttl.value || undefined,
                      "max-ttl": target.maxttl.value || undefined,
                      "max-unknown": target.maxunknown.value || undefined,
                      port: target.port.value || undefined,
                      psize: target.psize.value || undefined,
                      interval: target.interval.value || undefined,
                      gracetime: target.gracetime.value || undefined,
                      timeout: target.timeout.value || undefined,
                      "report-cycles": target.reportcycles.value || undefined
                  }
              };
        await agent.Reporting.generateMtrReport(environment, request)
            .then((value: IMtrReportTicketResponse) => {
                console.log(value);
                setEnvironment("");
                target.udp.checked = false;
                target.tcp.checked = false;
                target.nodns.checked = false;
                target.showips.checked = false;
                target.aslookup.checked = false;
                target.firstttl.value = undefined;
                target.maxttl.value = undefined;
                target.maxunknown.value = undefined;
                target.port.value = undefined;
                target.psize.value = undefined;
                target.interval.value = undefined;
                target.gracetime.value = undefined;
                target.timeout.value = undefined;
                target.reportcycles.value = undefined;

                props.onSuccess && props.onSuccess();

                if (!props.group_mode && !props.host_mode) {
                    target.hostname.value = "";
                }
                setErrors(undefined);
            })
            .catch((error) => {
                console.log(error);
                setErrors(error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred");
                props.onError && props.onError();
            });
    };

    React.useEffect(() => {
        getEnvironments();
        if (!props.group_mode) {
            //getHosts();
            getGroups();
        }
    }, [props.group_mode]);

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "stretch" }}>
            <form id="mtr-request-form" onSubmit={handleSubmit}>
                <FormControl variant="standard">
                    <InputLabel id="environment-label">Environment</InputLabel>
                    <Select
                        variant="standard"
                        labelId="environment-label"
                        id="environment"
                        name="environment"
                        value={environment}
                        onChange={(event: any) => handleEnvironmentChange(event)}
                        required>
                        {environments &&
                            environments.data
                                .filter((environment) => environment.status === "running")
                                .map((environment: any) => {
                                    return (
                                        <MenuItem key={environment.id} value={environment.id}>
                                            {environment.name}
                                        </MenuItem>
                                    );
                                })}
                    </Select>
                </FormControl>

                {!props.group_mode && !props.host_mode && !host && (
                    <>
                        <TextField
                            variant="standard"
                            id="hostname"
                            name="hostname"
                            label="DNS / IP Address"
                            required
                            style={{ marginBottom: "10px" }}
                            onChange={handleHostnameChange}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={saveToHosts} onChange={handleSaveToHostsChange} name="saveToHosts" color="primary" />
                            }
                            label="Save"
                        />
                        {saveToHosts && (
                            <>
                                <TextField
                                    variant="standard"
                                    id="newHostname"
                                    name="newHostname"
                                    label="Endpoint Name"
                                    required
                                    style={{ marginBottom: "10px" }}
                                    onChange={handleNewHostnameChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={addToGroup}
                                            onChange={handleAddToGroupChange}
                                            name="addToGroup"
                                            color="primary"
                                        />
                                    }
                                    label="Add endpoint to group"
                                />

                                {addToGroup && (
                                    <>
                                        {!newGroupName && (
                                            <FormControl variant="standard">
                                                <InputLabel id="group-label">Select Existing Group</InputLabel>
                                                <Select
                                                    variant="standard"
                                                    labelId="group-label"
                                                    id="group"
                                                    name="group"
                                                    value={group || ""}
                                                    onChange={(event: any) => handleGroupChange(event)}>
                                                    <MenuItem key="empty" value="">
                                                        &nbsp;
                                                    </MenuItem>
                                                    {groups &&
                                                        groups.data
                                                            .filter((group: any) => !!group.group_id)
                                                            .map((group: any) => {
                                                                return (
                                                                    <MenuItem key={group.group_id} value={group.group_id || ""}>
                                                                        {group.group_name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {!newGroupName && !group && <div>or</div>}

                                        {!group && (
                                            <TextField
                                                variant="standard"
                                                id="newGroupName"
                                                name="newGroupName"
                                                label="Create New Group"
                                                required
                                                style={{ marginBottom: "10px" }}
                                                onChange={handleNewGroupNameChange}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                <div style={{ height: 10 }}></div>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        Advanced Options
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={() => {
                                            console.log("change");
                                        }}
                                        name="udp"
                                        color="primary"
                                    />
                                }
                                label="Use UDP instead of ICMP echo"
                            />
                            <br />
                            <FormControlLabel control={<Checkbox name="tcp" color="primary" />} label="Use TCP instead of ICMP echo" />
                            <br />
                            <FormControlLabel control={<Checkbox name="nodns" color="primary" />} label="No DNS" />
                            <br />
                            <FormControlLabel control={<Checkbox name="showips" color="primary" />} label="Show IPs" />
                            <br />
                            <FormControlLabel control={<Checkbox name="aslookup" color="primary" />} label="AS Lookup" />

                            <br />
                            <TextField variant="standard" id="firstttl" name="firstttl" label="First TTL" type="number" />
                            <TextField variant="standard" id="maxttl" name="maxttl" label="Max TTL" type="number" />
                            <TextField variant="standard" id="maxunknown" name="maxunknown" label="Max Unknown" type="number" />
                            <TextField variant="standard" id="port" name="port" label="Port" type="number" />
                            <TextField variant="standard" id="psize" name="psize" label="Packet Size" type="number" />
                            <TextField variant="standard" id="interval" name="interval" label="Interval" type="number" />
                            <TextField variant="standard" id="gracetime" name="gracetime" label="Grace Time" type="number" />
                            <TextField variant="standard" id="timeout" name="timeout" label="Timeout" type="number" />
                            <TextField variant="standard" id="reportcycles" name="reportcycles" label="Report Cycles" type="number" />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <ErrorMessages errors={errors} />
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                    <Button variant="contained" color="primary" type="submit">
                        {saveToHosts ? "Save & Generate" : "Generate"}
                    </Button>
                </div>
            </form>
        </div>
    );
};
export default GenerateMtrReportForm;
