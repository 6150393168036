import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import * as PermissionTypes from "app/common/options/PermissionTypes";
import { EnvironmentPermissionsEntry, RootStoreContext } from "api";
import ErrorMessage from "app/common/form/ErrorMessage";
import { MenuItem, Select } from "@mui/material";
import { useSourcePermissions } from "hooks";

interface IValues {
    environment_id: string | undefined;
    max_destinations: number | undefined;
}

const MaxDestinations = (props: { token: string | undefined; env: EnvironmentPermissionsEntry }) => {
    const { token, env } = props;
    const rootStore = useContext(RootStoreContext);
    const { setMaxDestinations, loadSourcePermissions } = useSourcePermissions();
    const { openDialog } = rootStore.dialogStore;
    const [values, setValues] = useState<IValues>({
        environment_id: "",
        max_destinations: -1
    });
    const [hasLoaded, setHasLoaded] = useState(false);

    const handleOnchange = async (e: any, data: any) => {
        e.preventDefault();

        setValues({
            ...values,
            max_destinations: data.props.value
        });
    };

    const submitData = async () => {
        try {
            await setMaxDestinations(values, token!);
            setTimeout(() => {
                loadSourcePermissions();
            }, 1000);
        } catch (error: any) {
            openDialog(<ErrorMessage error={error} />);
        }
    };

    useEffect(() => {
        if (!hasLoaded) {
            return;
        }

        submitData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    useEffect(() => {
        if (hasLoaded) {
            return;
        }

        setValues({
            environment_id: env.environment_id,
            max_destinations: env.max_destinations
        });

        setHasLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Select value={values.max_destinations} name="max_destinations" onChange={(e, data) => handleOnchange(e, data)}>
                {PermissionTypes.max_destinations.map((destination) => (
                    <MenuItem key={destination.value} value={destination.value}>
                        {destination.text}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default observer(MaxDestinations);
