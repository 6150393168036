import {
    IKnowledgeHubArticleShort,
    RootStoreContext,
    agent,
    KnowledgeHubSelectorType,
    KnowledgeHubItemStatus,
    KnowledgeHubType
} from "api";
import useConstants from "./UseConstants";
import { useCallback, useContext, useState } from "react";
import useRoleProvider from "./UseRoleProvider";
import { useLoading } from "./UseLoading";
import { Category } from "@mui/icons-material";
import { formatLabel } from "features/knowledgeHub/Components";

const hasItemAccess = (item: IKnowledgeHubArticleShort, user: any) => {
    let hasClientAccess = true;
    let hasRoleAccess = true;
    let hasModuleAccess = true;

    if (item?.client) {
        if (item?.client !== process.env.REACT_APP_CLIENT_NAME) {
            hasClientAccess = false;
        }
    }

    if (user) {
        if (item?.roles?.length) {
            hasRoleAccess = item.roles.some((role) => user[role]);
        }

        if (item?.moduleAccess && user.modules_available && user.modules_available[item?.moduleAccess] !== undefined) {
            hasModuleAccess = user.modules_available[item?.moduleAccess];
        }
    }

    return hasClientAccess && hasRoleAccess && hasModuleAccess;
};

const filterPublishedItems = (articles: IKnowledgeHubArticleShort[], user: any): IKnowledgeHubArticleShort[] =>
    articles
        .filter((item) => {
            return item.status === KnowledgeHubItemStatus.Published;
        })
        .map((item) => (hasItemAccess(item, user) ? item : null))
        .filter((item): item is IKnowledgeHubArticleShort => item !== null);

const sortItems = (items: IKnowledgeHubArticleShort[], user: any) => {
    return filterPublishedItems(
        items.slice().sort((a, b) => {
            const orderA = a.order || 10;
            const orderB = b.order || 10;

            if (orderA !== orderB) {
                return orderA - orderB;
            }
            return a.title.localeCompare(b.title);
        }),
        user
    );
};

export const useKnowledgeHub = () => {
    const { constants } = useConstants();
    const rootStore = useContext(RootStoreContext);
    const {
        categories,
        archivedCategories,
        items,
        indexedItems,
        listingItems,
        hiddenItems,
        unIndexedItems,
        archivedItems,
        setCategories,
        setArchivedCategories,
        setItems,
        setIndexedItems,
        setListingItems,
        setHiddenItems,
        setUnIndexedItems,
        setArchivedItems,
        setTags,
        setDraftItems
    } = rootStore.knowledgeHubStore;
    const { user } = rootStore.userStore;

    /**
     * State
     * - isLoading, isSubmitting
     */
    const { isLoading, withLoading } = useLoading();
    const [isSubmitting, setIsSubmitting] = useState(false);

    /**
     * Helper Functions
     * - handleErrors
     */
    const handleErrors = useCallback((error, action) => {
        console.error(`useKnowledgeHub - ${action} error: `, error);
        setIsSubmitting(false);
    }, []);

    /**
     * Global Functions
     * - getKnowledgeHubInit
     */
    const getKnowledgeHubInit = useCallback(() => {
        withLoading(async () => {
            try {
                const [categoryData, itemsData, hiddenData] = await Promise.all([
                    agent.KnowledgeHub.getAllByCategory("kb_category", constants.data.KnowledgeHub.searchFields),
                    agent.KnowledgeHub.getAllByCategory("kb_cat-*", constants.data.KnowledgeHub.allFields),
                    agent.KnowledgeHub.getAllByCategory("hidden", constants.data.KnowledgeHub.allFields)
                ]);

                assignCategories(
                    categoryData.articles,
                    categoryData.articles.filter((category) => category.status === KnowledgeHubItemStatus.Archived)
                );
                assignItems([...itemsData.articles, ...hiddenData.articles]);
                setHiddenItems(hiddenData.articles);
            } catch (error) {
                handleErrors(error, "getKnowledgeHubData");
            }
        });
    }, []);

    /**
     * Tag Functions
     * - addTags
     */
    const addTags = useCallback(
        (newItems) => {
            const uniqueTagsMap = new Map();

            newItems.forEach((newItem) => {
                newItem.tags?.forEach((tag) => {
                    if (!uniqueTagsMap.has(tag)) {
                        const formattedTag = {
                            title: formatLabel(tag, true),
                            value: tag
                        };
                        uniqueTagsMap.set(tag, formattedTag);
                    }
                });
            });

            setTags(Array.from(uniqueTagsMap.values()));
        },
        [setTags]
    );

    /**
     * Category Functions
     * - assignCategories, addCategory, removeCategory, createCategory, updateCategory, deleteCategory
     */
    const assignCategories = useCallback(
        (newCategories, newArchivedCategories) => {
            const categories = sortItems(newCategories, user);
            setCategories(categories);
            setArchivedCategories(newArchivedCategories);
        },
        [user, setCategories, setArchivedCategories]
    );

    const addCategory = useCallback(
        (category) => {
            if (!hasItemAccess(category, user)) {
                return;
            }

            let newCategories = [...categories];
            const exists = categories.some((c) => c.id === category.id);
            newCategories = exists ? newCategories.map((c) => (c.id === category.id ? category : c)) : [...newCategories, category];

            let newArchivedCategories = [...archivedCategories];
            if (category.status === KnowledgeHubItemStatus.Archived) {
                const archivedExists = archivedCategories.some((c) => c.id === category.id);
                newArchivedCategories = archivedExists
                    ? newArchivedCategories.map((c) => (c.id === category.id ? category : c))
                    : [...newArchivedCategories, category];
            }

            return assignCategories(newCategories, newArchivedCategories);
        },
        [archivedCategories, assignCategories, categories, user]
    );

    const removeCategory = useCallback(
        (id) => {
            setCategories(categories.filter((category) => category.id !== id));
            setArchivedCategories(archivedCategories.filter((category) => category.id !== id));
        },
        [archivedCategories, categories, setArchivedCategories, setCategories]
    );

    const getCategory = useCallback(
        async (id: string, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.getArticle(id);
            thenFunc && thenFunc(data);
            addCategory(data);
            return data;
        },
        [addCategory]
    );

    const createCategory = useCallback(
        async (category: IKnowledgeHubArticleShort, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.createArticle(category);
            thenFunc && thenFunc(data);
            addCategory(category);
            return data;
        },
        [addCategory]
    );

    const updateCategory = useCallback(
        async (category: IKnowledgeHubArticleShort, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.updateArticle(category.id, category);
            thenFunc && thenFunc(data);
            addCategory(category);
            return data;
        },
        [addCategory]
    );

    const deleteCategory = useCallback(
        async (id: string, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.deleteArticle(id);
            thenFunc && thenFunc(data);

            let category = [...categories, ...archivedCategories].find((category) => category.id === id);

            if (!category) {
                return data;
            }

            removeCategory(category.id);
            return data;
        },
        [archivedCategories, categories, removeCategory]
    );

    /**
     * Item Functions
     * - assignItems, addItem, removeItem, createItem, updateItem, deleteItem
     */
    const assignItems = useCallback(
        (newItems) => {
            let filteredItems = sortItems(newItems, user);
            const items = newItems.filter((item) => item?.type === KnowledgeHubType.Article);

            setItems(items);
            setIndexedItems(filteredItems.filter((item) => item?.isIndexable));
            setListingItems(filteredItems.filter((item) => item?.showInCategory));
            setHiddenItems(items.filter((item) => item?.category === "hidden"));
            setUnIndexedItems(items.filter((item) => !item?.isIndexable));
            setArchivedItems(items.filter((item) => item?.status === KnowledgeHubItemStatus.Archived));
            setDraftItems(items.filter((item) => item?.status === KnowledgeHubItemStatus.Draft));
            addTags(newItems);
        },
        [user, setItems, setIndexedItems, setListingItems, setHiddenItems, setUnIndexedItems, setArchivedItems, setDraftItems, addTags]
    );

    const addItem = useCallback(
        (item) => {
            if (!hasItemAccess(item, user)) {
                return;
            }

            let newItems = [...items];
            const exists = items.some((c) => c.id === item.id);
            newItems = exists ? newItems.map((c) => (c.id === item.id ? item : c)) : [...newItems, item];

            return assignItems(newItems);
        },
        [assignItems, items, user]
    );

    const removeItem = useCallback(
        (id) => {
            setItems(items.filter((item) => item.id !== id));
            setIndexedItems(indexedItems.filter((item) => item.id !== id));
            setListingItems(listingItems.filter((item) => item.id !== id));
            setHiddenItems(hiddenItems.filter((item) => item.id !== id));
            setUnIndexedItems(unIndexedItems.filter((item) => item.id !== id));
            setArchivedItems(archivedItems.filter((item) => item.id !== id));
        },
        [
            archivedItems,
            hiddenItems,
            indexedItems,
            items,
            listingItems,
            setArchivedItems,
            setHiddenItems,
            setIndexedItems,
            setItems,
            setListingItems,
            setUnIndexedItems,
            unIndexedItems
        ]
    );

    const getItem = useCallback(
        async (id: string, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.getArticle(id);
            thenFunc && thenFunc(data);
            addItem(data);
            return data;
        },
        [addItem]
    );

    const createItem = useCallback(
        async (item: IKnowledgeHubArticleShort, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.createArticle(item);
            thenFunc && thenFunc(data);
            addItem(item);
            return data;
        },
        [addItem]
    );

    const updateItem = useCallback(
        async (item: IKnowledgeHubArticleShort, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.updateArticle(item.id, item);
            thenFunc && thenFunc(data);
            addItem(item);
            return data;
        },
        [addItem]
    );

    const deleteItem = useCallback(
        async (id: string, thenFunc?: (data: any) => void) => {
            const data = await agent.KnowledgeHub.deleteArticle(id);
            thenFunc && thenFunc(data);

            let item = items.find((item) => item.id === id);

            if (!item) {
                return data;
            }

            removeItem(item.id);
            return data;
        },
        [items, removeItem]
    );

    /**
     * Developer Functions
     * - updateItemsWithNewField, deleteFieldFromItems
     */
    const updateItemsWithNewField = useCallback(
        async (itemsToUpdate, newField) => {
            setIsSubmitting(true);
            let numberOfUpdatedItems = 0;
            let numberOfFailedItems = 0;

            try {
                for (const item of itemsToUpdate) {
                    const newValues = { ...item, [newField.fieldName]: newField.value };
                    try {
                        await agent.KnowledgeHub.updateArticle(item.id, newValues);
                        numberOfUpdatedItems++;
                    } catch (error) {
                        handleErrors(error, "updateItemsWithNewField");
                        numberOfFailedItems++;
                    }
                }
            } finally {
                setIsSubmitting(false);
                console.log(`Update items completed. Updated: ${numberOfUpdatedItems}, Failed: ${numberOfFailedItems}`);
            }
        },
        [handleErrors]
    );

    // !BETA - This is untested
    const deleteFieldFromItems = useCallback(
        async (itemsToDelete, fieldName) => {
            setIsSubmitting(true);
            try {
                for (const item of itemsToDelete) {
                    if (item[fieldName]) {
                        const { [fieldName]: _, ...newValues } = item;
                        try {
                            await agent.KnowledgeHub.updateArticle(item.id, newValues);
                        } catch (error) {
                            handleErrors(error, "deleteFieldFromItems");
                        }
                    } else {
                        console.log(`Field '${fieldName}' not present or has no value in item id: ${item.id}`);
                    }
                }
            } finally {
                setIsSubmitting(false);
            }
        },
        [handleErrors]
    );

    /**
     * Return
     */
    return {
        /**
         * State
         */
        isLoading,
        isSubmitting,

        /**
         * Global Functions
         */
        getKnowledgeHubInit,

        /**
         * Tag Functions
         */
        addTags,

        /**
         * Category Functions
         */
        addCategory,
        removeCategory,
        getCategory,
        createCategory,
        updateCategory,
        deleteCategory,

        /**
         * Item Functions
         */
        addItem,
        removeItem,
        getItem,
        createItem,
        updateItem,
        deleteItem,

        /**
         * Developer Functions
         */
        updateItemsWithNewField,
        deleteFieldFromItems
    };
};

export default useKnowledgeHub;
