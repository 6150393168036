import { observer } from "mobx-react-lite";
import React from "react";
import Drawer from "@mui/material/Drawer";
import DialogTitle from "@mui/material/DialogTitle";
import { RootStoreContext } from "api";
import { Typography } from "@mui/material";

type GlobalDrawerProps = {
    children?: React.ReactNode;
    title?: string;
};

const GlobalDrawer: React.FC<GlobalDrawerProps> = observer(function GlobalDialog({ children, title }) {
    const rootStore = React.useContext(RootStoreContext);

    const { drawer, closeDrawer } = rootStore.drawerStore;

    return (
        <Drawer open={drawer.open} onClose={closeDrawer} anchor="right">
            {drawer.title && (
                <DialogTitle>
                    <Typography variant="h5">{drawer.title}</Typography>
                </DialogTitle>
            )}
            {drawer.content}
        </Drawer>
    );
});

export default GlobalDrawer;
