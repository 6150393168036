import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import { SelectField, TextField } from "components/Forms";
import { observer } from "mobx-react-lite";
import React from "react";
import * as transcodingOptions from "app/common/options/transcodingOptions";
import { FieldArray } from "react-final-form-arrays";
import { AddCircle, Delete } from "@mui/icons-material";

interface IVideoTrackProps {
    values: any;
    enable_motion_interpolation: boolean;
}

const VideoTrack: React.FC<IVideoTrackProps> = ({ values, enable_motion_interpolation }) => {
    return (
        <>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" mb={2}>
                        Video Track 1
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {/* video_bitrate: 1 to 30000 (this is in kilobits - in titan we multiply this by 1000) */}
                            <TextField
                                name="video_bitrate"
                                label="Bitrate (kbps)"
                                type="number"
                                required
                                parse
                                helperText={(meta) => meta.touched && meta.error}
                                error={(meta) => meta.touched && meta.error}
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                    if (value < 1 || value > 30000) {
                                        return "Bitrate must be between 1 and 30000";
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                name="video_frame_size"
                                label="Frame Size"
                                options={
                                    enable_motion_interpolation
                                        ? transcodingOptions.videoFrameSizeMIFRC &&
                                          transcodingOptions.videoFrameSizeMIFRC.map((option: any) => {
                                              return {
                                                  text: option.text,
                                                  value: option.value
                                              };
                                          })
                                        : transcodingOptions.videoFrameSize &&
                                          transcodingOptions.videoFrameSize.map((option: any) => {
                                              return {
                                                  text: option.text,
                                                  value: option.value
                                              };
                                          })
                                }
                                required
                                helperText={(meta) => meta.touched && meta.error}
                                error={(meta) => meta.touched && meta.error}
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="first_video_program_number"
                                label="Program Number"
                                type="number"
                                required
                                initialValue={values?.first_video_program_number ? values?.first_video_program_number : 1}
                                parse
                                helperText={(meta) => meta.touched && meta.error}
                                error={(meta) => meta.touched && meta.error}
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                    if (value < 1 || value > 65535) {
                                        return "Program Number must be between 1 and 65535";
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="first_video_pcr_pid"
                                label="PCR PID"
                                type="number"
                                required
                                initialValue={values?.first_video_pcr_pid ? values?.first_video_pcr_pid : 0}
                                parse
                                helperText={(meta) => meta.touched && meta.error}
                                error={(meta) => meta.touched && meta.error}
                                validate={(value: any) => {
                                    if (!value && value !== 0) {
                                        return "Required";
                                    }
                                    if (value < 0 || value > 8189) {
                                        return "PCR PID must be between 0 and 8189";
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="first_video_pmt_pid"
                                label="PMT PID"
                                type="number"
                                initialValue={values?.first_video_pmt_pid ? values?.first_video_pmt_pid : 0}
                                required
                                parse
                                helperText={(meta) => meta.touched && meta.error}
                                error={(meta) => meta.touched && meta.error}
                                validate={(value: any) => {
                                    if (!value && value !== 0) {
                                        return "Required";
                                    }
                                    if (value < 0 || value > 8189) {
                                        return "PMT PID must be between 0 and 8189";
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* extra_video_tracks - video_track_number 0 is reserved for the "base" entry. Up to 5 extra video tracks (so the total is 6) */}
            {/* Inside extra video tracks:
-> "program_number": 1 - 65535
-> "pcr_pid": 0, 32 - 8189
-> "pmt_pid": 0, 32 - 8189
-> video_bitrate: 1 to 30000 and must be in descending or equal order (starting from "video_bitrate" that is in the main payload). For example 8000, 5000, 5000 is valid, but 5000, 5000, 8000 is not. */}
            {/* ExtraVideoTrackEntry {
video_track_number (integer),
video_bitrate (integer),
video_frame_size (string),
program_number (integer, optional),
pcr_pid (integer, optional),
pmt_pid (integer, optional)
} */}
            <FieldArray name="extra_video_tracks">
                {({ fields }) => (
                    <>
                        {fields.map((name, index) => (
                            <Card key={index} sx={{ mb: 2 }}>
                                <CardContent>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                        <Typography variant="h6" mb={2}>
                                            Video Track {index + 2}
                                        </Typography>
                                        <IconButton
                                            onClick={() => {
                                                fields.remove(index);
                                            }}>
                                            <Delete />
                                        </IconButton>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box
                                                sx={{
                                                    display: "none"
                                                }}>
                                                <TextField
                                                    label="Video Track Number"
                                                    name={`${name}.video_track_number`}
                                                    type="hidden"
                                                    initialValue={index + 1}
                                                />
                                            </Box>

                                            {/* video_bitrate: 1 to 30000 (this is in kilobits - in titan we multiply this by 1000) */}
                                            <TextField
                                                name={`${name}.video_bitrate`}
                                                label="Bitrate (kbps)"
                                                type="number"
                                                required
                                                parse
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                                validate={(value: any) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                    if (value < 1 || value > 30000) {
                                                        return "Bitrate must be between 1 and 30000";
                                                    }
                                                    //if index = 0, then check if this is less than the root video_bitrate
                                                    //if index > 0, then check if this is less than the previous video_bitrate
                                                    if (index === 0) {
                                                        if (value > values?.video_bitrate) {
                                                            return "Bitrate must be less than or equal to the previous video bitrate";
                                                        }
                                                    }
                                                    if (index > 0) {
                                                        if (value > values?.extra_video_tracks[index - 1].video_bitrate) {
                                                            return "Bitrate must be less than or equal to the previous video bitrate";
                                                        }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectField
                                                name={`${name}.video_frame_size`}
                                                label="Frame Size"
                                                options={
                                                    transcodingOptions.videoFrameSize &&
                                                    transcodingOptions.videoFrameSize.map((option: any) => {
                                                        return {
                                                            text: option.text,
                                                            value: option.value
                                                        };
                                                    })
                                                }
                                                required
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                                validate={(value: any) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                name={`${name}.program_number`}
                                                label="Program Number"
                                                type="number"
                                                required
                                                initialValue={
                                                    values?.extra_video_tracks[index].program_number
                                                        ? values?.extra_video_tracks[index].program_number
                                                        : 1
                                                }
                                                parse
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                                validate={(value: any) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                    if (value < 1 || value > 65535) {
                                                        return "Program Number must be between 1 and 65535";
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                name={`${name}.pcr_pid`}
                                                label="PCR PID"
                                                type="number"
                                                required
                                                initialValue={
                                                    values?.extra_video_tracks[index].pcr_pid
                                                        ? values?.extra_video_tracks[index].pcr_pid
                                                        : 0
                                                }
                                                parse
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                                validate={(value: any) => {
                                                    if (!value && value !== 0) {
                                                        return "Required";
                                                    }
                                                    if (value < 0 || value > 8189) {
                                                        return "PCR PID must be between 0 and 8189";
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                name={`${name}.pmt_pid`}
                                                label="PMT PID"
                                                type="number"
                                                initialValue={
                                                    values?.extra_video_tracks[index].pmt_pid
                                                        ? values?.extra_video_tracks[index].pmt_pid
                                                        : 0
                                                }
                                                required
                                                parse
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                                validate={(value: any) => {
                                                    if (!value && value !== 0) {
                                                        return "Required";
                                                    }
                                                    if (value < 0 || value > 8189) {
                                                        return "PMT PID must be between 0 and 8189";
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                        {!enable_motion_interpolation && (
                            <Box mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={fields.length && fields.length >= 5 ? true : false}
                                    startIcon={<AddCircle />}
                                    onClick={() => {
                                        fields.push({});
                                    }}>
                                    Add Video Track
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </FieldArray>
        </>
    );
};

export default observer(VideoTrack);
