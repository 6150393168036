import { Alert, Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Typography } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext } from "react";

interface ConfirmEnableOwnCloudProps {
    cloud_provider: string;
    organisation_id: number;
    organisation_name: string;
    onSuccessfulEnable?: () => void;
}

const ConfirmEnableOwnCloud: React.FC<ConfirmEnableOwnCloudProps> = ({ cloud_provider, organisation_id, organisation_name, onSuccessfulEnable }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const [confirmNDA, setConfirmNDA] = React.useState(false);
    const [error, setError] = React.useState<any>(null);

    const handleEnableOwnCloud = async () => {
        await agent.OwnCloud.adminEnableOwnCloud({ organisation_id: organisation_id, cloud_provider: cloud_provider })
            .then(() => {
                //close dialog
                onSuccessfulEnable && onSuccessfulEnable();
                closeDialog();
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    You are about to enable Own Cloud for {organisation_name} on cloud provider <span style={{textTransform: "uppercase"}}>{cloud_provider}</span>. Please confirm that an NDA has been signed with {organisation_name}.
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={confirmNDA}
                            onChange={(e) => setConfirmNDA(e.target.checked)}
                            color="primary"
                            title={`I confirm that an NDA has been signed with ${organisation_name}.`}
                        />
                    }
                    label={`I confirm that an NDA has been signed with ${organisation_name}.`}
                />
                {error && <Alert severity="error" sx={{mt: 2}}>{error.data.message}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleEnableOwnCloud} disabled={!confirmNDA}>
                    Enable Own Cloud
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmEnableOwnCloud;
