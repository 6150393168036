import { Box, Typography, useTheme } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import React from "react";

interface BitrateSparklineProps {
    value: number;
    width: number;
    forceUpdate: number;
    color?: string;
}

/**
 * The sparkline chart is used to show the trend of the bitrate value over time.
 * The chart displays the last 10 values in a line chart.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {number} props.value - The bitrate value to display.
 * @returns {JSX.Element} - A JSX element representing the component.
 */

const BitrateSparkline: React.FC<BitrateSparklineProps> = ({ value, width, color, forceUpdate }) => {
    const theme = useTheme();

    const [values, setValues] = React.useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    React.useEffect(() => {
        setValues((prev) => {
            const newValues = [...prev];
            newValues.shift();
            newValues.push(value);
            return newValues;
        });
    }, [forceUpdate]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                //width: width,
                height: 80
            }}>
            <Box sx={{ width: width }}>
                <SparkLineChart
                    data={values}
                    width={width}
                    height={80}
                    showHighlight
                    colors={[color || theme.palette.primary.light]}
                    showTooltip
                    valueFormatter={(value) => {
                        return value + " Kbps";
                    }}
                />
            </Box>
            <Typography variant="caption" align="center" sx={{ whiteSpace: "nowrap", ml: 1, width: 60 }}>
                {(value || 0) + " Kbps"}
            </Typography>
        </Box>
    );
};

export default BitrateSparkline;
