import { AccessTime, CellTower, Videocam } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Button, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { RootStoreContext, agent } from "api";
import { useSources } from "hooks";
import React, { useContext, useEffect } from "react";
interface BasicEditScheduleFormProps {
    item: any;
    schedule_id: string;
    destination_id?: string;
}

const BasicEditScheduleForm: React.FC<BasicEditScheduleFormProps> = ({ item, destination_id, schedule_id }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const active_environment = rootStore.userStore.activeEnvironment;
    const { getInputsDropdownList } = useSources();
    const [loading, setLoading] = React.useState(false);
    const [sources, setSources] = React.useState<any[]>([]);
    const [destinations, setDestinations] = React.useState<any[]>([]);
    const [sourceId, setSourceId] = React.useState<string>(item.source_id);
    const [destinationId, setDestinationId] = React.useState<string>(destination_id || item.destination_id);
    const [startDate, setStartDate] = React.useState<Date>(new Date(item.start_time));
    const [endDate, setEndDate] = React.useState<Date>(new Date(item.end_time));
    const [error, setError] = React.useState<string>();
    const [errors, setErrors] = React.useState<any>({});

    //Fetch Sources and Destinations --------------------------------
    const fetchSources = async (environment_id) => {
        await getInputsDropdownList(environment_id)
            .then((response) => {
                setSources(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const fetchDestinations = async (environment_id) => {
        await agent.Destination.getDestinationsList(environment_id)
            .then((response) => {
                const destinations = response.data.filter((destination) => destination.is_permanent_schedule === false);
                setDestinations(destinations);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        fetchSources(active_environment);
        fetchDestinations(active_environment);
    }, [active_environment]);
    //--------------------------------------------------------------

    const handleSubmit = async () => {
        const request = {
            start_time: startDate.toISOString(),
            end_time: endDate.toISOString(),
            destination_id: destinationId,
            source_id: sourceId
        };

        await agent.Scheduler.updateSchedule(request, schedule_id)
            .then(() => {
                closeDialog();
            })
            .catch((error) => {
                setError(error.data?.message);
                setErrors(error.data?.errors);
            });
    };
    return (
        <>
            <DialogTitle>Edit Schedule</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container>
                        <Grid item xs={1} display="flex" alignItems="center">
                            <Videocam />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="Source"
                                margin="none"
                                size="small"
                                required
                                placeholder="Select Source"
                                select
                                value={sourceId}
                                onChange={(event) => {
                                    setSourceId(event.target.value);
                                }}>
                                {sources?.map((source: any) => (
                                    <MenuItem key={source.id} value={source.id}>
                                        {source.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container mb={3}>
                        <Grid item xs={1} display="flex" alignItems="center">
                            <CellTower />
                        </Grid>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                label="Destination"
                                margin="none"
                                size="small"
                                required
                                placeholder="Select Destination"
                                select
                                value={destinationId}
                                onChange={(event) => {
                                    setDestinationId(event.target.value);
                                }}>
                                {destinations?.map((destination: any) => (
                                    <MenuItem key={destination.id} value={destination.id}>
                                        {destination.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container mb={3}>
                        <Grid item xs={1} pt={2}>
                            <AccessTime />
                        </Grid>
                        <Grid item xs={11}>
                            <Stack direction="row" spacing={3} justifyContent="flex-start" mb={1}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(value) => {
                                        setStartDate(value as Date);
                                    }}
                                    disablePast
                                    sx={{ width: 200 }}
                                    format="dd/MM/yyyy"
                                />
                                <TimePicker
                                    label="Start Time"
                                    value={startDate}
                                    views={["hours", "minutes", "seconds"]}
                                    onChange={(value) => {
                                        setStartDate(value as Date);
                                    }}
                                    sx={{ width: 120 }}
                                />
                            </Stack>
                            <Stack direction="row" spacing={3} justifyContent="flex-start">
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    disablePast
                                    minDate={startDate}
                                    onChange={(value) => {
                                        setEndDate(value as Date);
                                    }}
                                    sx={{ width: 200 }}
                                    format="dd/MM/yyyy"
                                />
                                <TimePicker
                                    label="End Time"
                                    value={endDate}
                                    views={["hours", "minutes", "seconds"]}
                                    onChange={(value) => {
                                        setEndDate(value as Date);
                                    }}
                                    sx={{ width: 120 }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 1, marginBottom: 2 }}>
                        <AlertTitle>{error}</AlertTitle>
                        {errors && errors.start_time && <div>Start Time: {errors.start_time}</div>}
                        {errors && errors.end_time && <div>End Time: {errors.end_time}</div>}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <LoadingButton loading={loading} onClick={handleSubmit} variant="contained" color="primary">
                    Update Schedule
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default BasicEditScheduleForm;
