import { Box, Chip, FormLabel, Grid, Stack } from "@mui/material";
import { IEnvironment, RootStoreContext, agent } from "api";
import InfoItemVertical from "components/InfoItemVertical";
import React from "react";
import { format } from "date-fns";
import { ResCollection, ResModel } from "resclient";
import ProgressChip from "components/ProgressChip";
import ClientProvider from "components/ClientProvider";

interface EnvironmentDetailsProps {
    environment: IEnvironment;
}

const EnvironmentDetails: React.FC<EnvironmentDetailsProps> = ({ environment }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_org_id = rootStore.userStore.activeOrganisation;

    const rid = `environments_v2.environments.${managing_org_id}.${active_org_id}.environment.${environment.id}`;

    const [wsData, setWsData] = React.useState<ResModel | ResCollection | any>({});

    const [environmentDetails, setEnvironmentDetails] = React.useState<any>();

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const getEnvironmentDetails = async (environment: IEnvironment) => {
        await agent.Environment.getEnvironmentDetails(environment.id)
            .then((response) => {
                console.log("environmentDetails", response);
                setEnvironmentDetails(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getEnvironmentDetails(environment);
        clientConnected &&
            client
                .get(rid)
                .then((res) => {
                    setWsData(res);
                    console.log("wsData", res);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setWsData(res);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [client, clientConnected, rid, environment]);

    React.useEffect(() => {}, [updateDate]);

    React.useEffect(() => {
        //if wsData.status === creating or upgrading, get environment details every 2 seconds
        if (wsData.status === "creating" || wsData.status === "upgrading") {
            const interval = setInterval(() => {
                getEnvironmentDetails(environment);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [wsData]);

    return (
        <Grid container>
            <Grid item xs={4}>
                <Stack spacing={2}>
                    <InfoItemVertical title="Name" value={environment?.name} />
                    <InfoItemVertical title="Ref Code" value={environment?.ref_code} />
                    <Box>
                        <FormLabel sx={{ fontSize: 11 }}>Status</FormLabel>
                        <Box>
                            {wsData.status === "creating" ? (
                                <ProgressChip
                                    label={
                                        wsData.status +
                                        ": Step " +
                                        (environmentDetails?.details?.current_step || "0") +
                                        " of " +
                                        (environmentDetails?.details?.number_of_steps || "0")
                                    }
                                />
                            ) : wsData.status === "upgrading" ? (
                                <ProgressChip
                                    label={
                                        wsData.status +
                                        ": Step " +
                                        (environmentDetails?.details?.current_step || "0") +
                                        " of " +
                                        (environmentDetails?.details?.number_of_steps || "0")
                                    }
                                />
                            ) : wsData.status === "stopping" || wsData.status === "starting" || wsData.status === "deleting" || wsData.status === "restarting" ? (
                                <ProgressChip label={wsData.status} />
                            ) : (
                                <Chip
                                    sx={{ textTransform: "capitalize" }}
                                    label={wsData.status}
                                    color={wsData.status === "stopped" ? "error" : wsData.status === "running" ? "success" : undefined}
                                    size="small"
                                />
                            )}
                        </Box>
                    </Box>
                    <InfoItemVertical title="Provider" value={environment?.cloud_provider_human_name} />
                    <InfoItemVertical title="Region" value={environment?.region} />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack spacing={2}>
                    <InfoItemVertical title="DNS Name" value={wsData?.dns_name || "N/A"} value_type="copy" />
                    <InfoItemVertical title="IP Address" value={wsData?.public_ip || "N/A"} />
                    <ClientProvider client="default">
                        <InfoItemVertical title="Instance Type" value={environmentDetails?.details?.instance_size} />
                    </ClientProvider>
                    <InfoItemVertical title="Size (Mbps In/Out)" value={environment.instance_size_commercial} />
                    <InfoItemVertical title="Environment Version" value={environment?.environment_version} />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack spacing={2}>
                    <InfoItemVertical
                        title="Update Status"
                        value={environment.is_environment_outdated ? "Update Available" : "Up to date"}
                    />
                    <InfoItemVertical title="Update Scheduled" value={environment.sched_upgrade_status === "pending" ? "Yes" : "No"} />
                    <InfoItemVertical
                        title="Update Start Time"
                        value={
                            environment.sched_upgrade_start_time
                                ? format(new Date(environment.sched_upgrade_start_time), "dd/MM/yyyy HH:mm:ss")
                                : "N/A"
                        }
                    />
                    <InfoItemVertical
                        title="Update Scheduled Type"
                        value={
                            environment.sched_upgrade_scheduling_type
                                ? environment.sched_upgrade_scheduling_type === "system_scheduled"
                                    ? "System Scheduled"
                                    : "User Scheduled"
                                : "N/A"
                        }
                    />
                    <InfoItemVertical title="Scheduled By User" value={environment.sched_upgrade_updated_by_user_email || "N/A"} />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default EnvironmentDetails;
