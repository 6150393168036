import React from "react";
import { observer } from "mobx-react-lite";
import { ResCollection, ResModel } from "resclient";
import { Box, Card, CardContent, Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import SourceThumbnail from "./SourceThumbnail";
import { useTheme } from "@mui/material/styles";
import { RootStoreContext } from "api";

interface Props {
    rid: string;
}

const TranscoderListItem: React.FC<Props> = ({ rid }) => {
    const theme = useTheme();

    const rootStore = React.useContext(RootStoreContext);

    const { client, clientConnected } = rootStore.resClientStore;

    const [wsData, setWsData] = React.useState<ResModel | ResCollection | any>({});

    const [loading, setLoading] = React.useState<boolean>(true);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const onUpdate = () => {
        setUpdateDate(new Date());
        setLoading(false);
    };

    React.useEffect(() => {
        setLoading(true);

        let data: ResModel;

        clientConnected &&
            client
                .get(rid)
                .then((res: ResModel | ResCollection | any) => {
                    data = res;
                    data.on("change", () => {
                        onUpdate();
                    });
                    data.on("subscribe", (event: any) => {
                        console.log("subscribe", event);
                        onUpdate();
                    });

                    setWsData(data);
                    onUpdate();
                    console.log(res);
                })
                .catch((err) => {
                    console.log("Error fetching transcoders", err.message);
                });
        console.log("Item Mounted", rid);

        setLoading(false);
        onUpdate();

        return () => {
            setLoading(false);
            setWsData({});
        };
    }, [clientConnected]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <TableRow>
            <TableCell sx={{ border: "unset", p: 0 }}>
                <Card sx={{ marginBottom: 1, backgroundColor: theme.palette.primary.dark }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ width: 150 }}>
                            <SourceThumbnail item_id={wsData.id} />
                        </Box>
                        <CardContent>
                            {loading === false && wsData.name ? (
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                    {wsData.name}
                                </Typography>
                            ) : (
                                <Skeleton>
                                    <Typography variant="h6" component="div">
                                        Card Title Source Name
                                    </Typography>
                                </Skeleton>
                            )}
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                component="div"
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    textTransform: "capitalize"
                                }}>
                                Status: {wsData.transcoder_status}
                            </Typography>
                        </CardContent>
                    </Box>
                </Card>
            </TableCell>
        </TableRow>
    );
};

export default observer(TranscoderListItem);
