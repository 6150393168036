import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export const UpdateSuccessMessage = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <>
            <DialogTitle>Transcoder Updated</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Your Transcoder has been updated successfully.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </DialogActions>
        </>
    );
};
