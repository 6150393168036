// Enum for the loudness control mode
export enum LoudnessControlMode {
    EBU_R_128 = "EBU_R 128",
    ATSC_A85 = "ATSC_A85"
}

export interface ILoudnessControl {
    loudness_normalization_leveler: boolean;
    mode: LoudnessControlMode;
    target: number;
    true_peak_limiter: boolean;
    maximum_permitted_level: number;
}
