import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { agent, RootStoreContext } from "api";
import RegisterTwoFactorA from "../user/Registration/RegistrationFormItems/RegisterTwoFactor/RegisterTwoFactorA";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ConfirmationDialog from "components/ConfirmationDialog";

const ConfirmSkip2FA = observer(function ConfirmSkip2FA() {
    return (
        <Box>
            <DialogTitle>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Confirm Skip 2FA
                </Typography>
            </DialogTitle>
            <DialogContent></DialogContent>
        </Box>
    );
});

const SetUp2FA = observer(function SetUp2FA() {
    const rootStore = useContext(RootStoreContext);
    const { user, getUser } = rootStore.userStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;

    const pageDisabled = !user!.email_verified;

    return (
        <Box>
            <DialogTitle>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Enable or Skip 2 Factor Authentication (2FA)
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ mb: 2 }}>
                    We highly recommend setting up 2 factor authentication, since it provides the highest level of protection against your
                    user credentials being compromised.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={pageDisabled}
                    onClick={() => {
                        openDialog(<RegisterTwoFactorA />);
                    }}>
                    Enable 2FA
                </Button>
                <Button
                    variant="contained"
                    disabled={pageDisabled}
                    onClick={() => {
                        openDialog(
                            <ConfirmationDialog
                                title="Confirm Skip 2FA"
                                message=""
                                additionalContent={
                                    <>
                                        <Typography sx={{ fontSize: "1.2rem", mb: 2 }}>
                                            Are you sure you want skip enabling 2FA authentication?
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.2rem" }}>
                                            We strongly suggest enabling 2FA to keep you account secure. Please confirm you wish to skip
                                            this step and by doing so you acknowledge that this user will have admin privileges without the
                                            security of 2FA. You can enable 2FA later and we strongly suggest that 2FA is enabled for all
                                            users.
                                        </Typography>
                                    </>
                                }
                                onConfirm={async () => {
                                    return agent.getCleanInstance().put("/skip-enabling-2fa");
                                }}
                                onSuccess={() => {
                                    getUser();
                                    openDialog(
                                        <>
                                            <DialogTitle>Success!</DialogTitle>
                                            <DialogContent>
                                                <Typography>2FA has been skipped. You can enable it later at any point.</Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => {
                                                        closeDialog();
                                                    }}>
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </>
                                    );
                                }}
                            />
                        );
                    }}>
                    Skip 2FA
                </Button>
            </DialogActions>
        </Box>
    );
});

export default SetUp2FA;
