import React, { useContext, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { IAddUserToYourOrgRequest, RootStoreContext } from "api";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    Grid,
    Stack,
    IconButton,
    Container,
    Typography
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { SelectField, TextField } from "components/Forms";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Types } from "app/common/options/PermissionTypes";
import { PermissionInformationGrid } from "./permissionInformation/PermissionInformationGrid";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useAdmin, useOrganisation } from "hooks";

export const OrganisationInvite = observer(function OrganisationInvite() {
    const rootStore = useContext(RootStoreContext);
    const { addUserToOrganisation } = useOrganisation();
    const { closeDialog, openDialog } = rootStore.dialogStore;
    const [open, setOpen] = useState(false);
    const { user } = rootStore.userStore;
    const { loadUsers } = useAdmin();

    return (
        <FinalForm
            onSubmit={(values: IAddUserToYourOrgRequest) =>
                addUserToOrganisation(values)
                    .then((response) => {
                        loadUsers(user!.managing_organisation_id!).then(() => {
                            closeDialog();
                            openDialog(
                                <ConfirmationDialog
                                    title="User added to your organisation"
                                    message="User has been added to your organisation."
                                    onConfirm={async () => closeDialog()}
                                    hideCancelButton
                                    confirmButtonText="OK"
                                    additionalContent={
                                        <>
                                            {response.password ? (
                                                <>
                                                    <Typography>New user {values.email} has been added to your organisation.</Typography>
                                                    <Typography sx={{ mt: 2 }}>User's password:</Typography>

                                                    <Typography
                                                        sx={{
                                                            borderBottom: "1px solid",
                                                            fontWeight: "bold",
                                                            mb: 2
                                                        }}>
                                                        {response.password}
                                                    </Typography>

                                                    <Typography>
                                                        Please copy this password and send it to the user in a secure manner.
                                                    </Typography>

                                                    <Typography>
                                                        If you forget to do this, please suggest them to use the forgot password
                                                        functionality that comes up on login.
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography sx={{ mb: 2 }}>
                                                        Existing user {values.email} has been added to your organisation.
                                                    </Typography>
                                                    <Typography sx={{ mb: 2 }}>
                                                        Please let the user know that they have been granted access to your organisation.
                                                    </Typography>
                                                    <Typography>
                                                        If, for whatever reason, the user doesn't have the password, please suggest to use
                                                        the forgot password functionality that comes up on login.
                                                    </Typography>
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            );
                        });
                    })
                    .catch((error) => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Add user to your organisation</DialogTitle>

                    <DialogContent>
                        <TextField
                            name="first_name"
                            label="First Name"
                            required
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />

                        <TextField
                            name="last_name"
                            label="Last Name"
                            required
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />

                        <TextField
                            name="email"
                            label="Email Address"
                            required
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />

                        <Stack direction="row" spacing={1} alignItems="flex-end">
                            <IconButton
                                title="Permission information"
                                onClick={() => {
                                    setOpen(!open);
                                }}
                                sx={{ marginBottom: "-6px !important" }}>
                                <InfoOutlinedIcon sx={{ fontSize: "2rem", opacity: 0.3 }} />
                            </IconButton>

                            <SelectField
                                label="Permission Level"
                                required
                                options={Types}
                                name="permission_level"
                                placeholder="Select Permission Type"
                                helperText={(meta) => meta.touched && meta.error}
                                error={(meta) => meta.touched && meta.error}
                            />
                        </Stack>

                        {open && (
                            <Container
                                style={{
                                    padding: "2em",
                                    width: "auto",
                                    top: "35%",
                                    borderRadius: "3px",
                                    position: "absolute",
                                    background: "#2e3e4d",
                                    zIndex: 200
                                }}>
                                <PermissionInformationGrid setOpen={setOpen} open={open} />
                            </Container>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => closeDialog()}>Cancel</Button>

                        <LoadingButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            loading={submitting}
                            startIcon={<PersonAddIcon />}>
                            Add User
                        </LoadingButton>
                    </DialogActions>
                    {/* <Box sx={{ padding: 4 }}>
                        <Grid>
                            <Grid.Column width={16}>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mb: 1 }}>
                                    <Typography variant="h4">Add user to your organisation</Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    required
                                    name="first_name"
                                    placeholder="First Name"
                                    label="First Name"
                                    type="text"
                                />
                                <TextField fullWidth required name="last_name" placeholder="Last Name" label="Last Name" type="text" />

                                <Box>
                                    <FormControl variant="standard" error fullWidth>
                                        <Grid style={{ marginTop: 10, position: "relative" }}>
                                            <Grid.Column width={2} style={{ padding: "6px 0 0 12px", margin: 0 }}>
                                                <IconButton
                                                    title={"Permission information"}
                                                    onClick={() => {
                                                        setOpen(!open);
                                                    }}>
                                                    <InfoOutlinedIcon sx={{ fontSize: "2rem", opacity: 0.3 }} />
                                                </IconButton>
                                            </Grid.Column>
                                            <Grid.Column width={14} style={{ paddingLeft: 1 }}>
                                                <SelectField
                                                    label="Permission Level"
                                                    required
                                                    options={Types}
                                                    name="permission_level"
                                                    placeholder="Select Permission Type"
                                                    helperText={(meta) => meta.touched && meta.error}
                                                    error={(meta) => meta.touched && meta.error}
                                                />
                                            </Grid.Column>
                                            {open && (
                                                <Container
                                                    style={{
                                                        padding: "2em",
                                                        width: "auto",
                                                        top: "35%",
                                                        borderRadius: "3px",
                                                        position: "absolute",
                                                        background: "#2e3e4d",
                                                        zIndex: 200,
                                                    }}
                                                >
                                                    <PermissionInformationGrid setOpen={setOpen} open={open} />
                                                </Container>
                                            )}
                                        </Grid>
                                    </FormControl>
                                </Box>

                                <TextField
                                    fullWidth
                                    required
                                    name="email"
                                    placeholder="Email Address"
                                    label="Email Address"
                                    type="text"
                                />
                            </Grid.Column>
                        </Grid>
                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} text="" />}
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingTop: "20px" }}>
                            <Stack direction="row" spacing={2}>
                                <Button onClick={closeDialog}>Cancel</Button>
                                <LoadingButton
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PersonAddIcon />}>
                                    Add User
                                </LoadingButton>
                            </Stack>
                        </Box>
                    </Box> */}
                </form>
            )}
        />
    );
});

export default OrganisationInvite;
