import { Box, Divider, Typography, useTheme } from "@mui/material";
import { agent } from "api";
import InfoItem from "components/InfoItem";
import React, { useEffect } from "react";

interface TranscoderInfoProps {
    api_data: any;
    live_data: any;
    ws_data: any;
}

const TranscoderInfo: React.FC<TranscoderInfoProps> = ({ api_data, live_data, ws_data }) => {
    const [environmentName, setEnvironmentName] = React.useState("N/A");
    const [sourceName, setSourceName] = React.useState("N/A");

    const getEnvironmentName = (environment_id: string) => {
        agent.Environment.getEnvironmentDetails(environment_id)
            .then((response) => {
                setEnvironmentName(response.details.name);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getSourceName = (source_id: string) => {
        agent.Channel.getInput(source_id)
            .then((response) => {
                console.log("Source", response);
                setSourceName(response.data.id);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (api_data.transcode_parameters?.input_feed_source === "environment") {
            getEnvironmentName(api_data.transcode_parameters.environment_id);
        }
        if (api_data.transcode_parameters?.make_permanent_destination === true) {
            getSourceName(api_data.transcode_parameters.permanent_destination_input_id);
        }
    }, [api_data.transcode_parameters]);

    return (
        <Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
                General
            </Typography>
            <InfoItem title="Name" value={api_data.name ? api_data.name : ""} />
            <InfoItem
                title="Status"
                value_type="chip"
                value={
                    // capitalize the first letter of the status and replace underscores with spaces
                    api_data.ui_status ? api_data.ui_status.charAt(0).toUpperCase() + api_data.ui_status.slice(1).replace(/_/g, " ") : ""
                }
                chip_color={api_data.ui_status === "running" ? "success" : api_data.ui_status === "stopped" ? "error" : undefined}
            />
            <InfoItem title="DNS Name" value={ws_data.dns_name} value_type="copy" />
            <InfoItem title="IP Address" value={ws_data.public_ip} value_type="copy" />
            <InfoItem title="Cloud Provider" value={api_data.cloud_provider.toUpperCase()} />
            <InfoItem title="Region" value={api_data.region} />
            <InfoItem title="Product Size" value={api_data.product_size} />
            <InfoItem
                title="Input Type"
                value={
                    api_data.transcode_parameters?.input_feed_source
                        ? api_data.transcode_parameters?.input_feed_source.charAt(0).toUpperCase() +
                          api_data.transcode_parameters?.input_feed_source.slice(1)
                        : undefined
                }
            />
            <Divider sx={{ my: 2 }} />
            {api_data.transcode_parameters?.input_feed_source === "environment" && (
                <Box>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        Environment Input
                    </Typography>
                    <InfoItem title="Environment" value={environmentName} />
                    {api_data.transcode_parameters?.make_permanent_destination && (
                        <InfoItem title="Permanent Destination Source" value={sourceName} />
                    )}
                    <Divider sx={{ my: 2 }} />
                </Box>
            )}
            {api_data.transcode_parameters?.input_feed_source === "direct" && (
                <Box>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        Direct Input
                    </Typography>
                    <InfoItem
                        title="Type"
                        value={
                            api_data.transcode_parameters?.direct_feed_input_details?.input_type === "srt_listener"
                                ? "SRT Listener"
                                : api_data.transcode_parameters?.direct_feed_input_details?.input_type === "srt_caller"
                                ? "SRT Caller"
                                : api_data.transcode_parameters?.direct_feed_input_details?.input_type === "zixi_pull"
                                ? "Zixi Pull"
                                : api_data.transcode_parameters?.direct_feed_input_details?.input_type === "zixi_push"
                                ? "Zixi Push"
                                : "N/A"
                        }
                    />
                    <Divider sx={{ my: 2 }} />
                </Box>
            )}
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Global Track Configuration
            </Typography>
            <InfoItem title="Latency Mode" value={api_data.transcode_parameters?.global_track_configuration.latency_mode} />
            <InfoItem title="Chunk Period" value={api_data.transcode_parameters?.global_track_configuration.chunk_period} />
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Video Tracks
            </Typography>
            {api_data.transcode_parameters?.individual_track_configuration?.video_tracks?.map((track: any, index: number) => (
                <Box key={index}>
                    <InfoItem title={`Track ${index + 1} - Bitrate`} value={track.bitrate} />
                    <InfoItem title={`Track ${index + 1} - Codec`} value={track.codec} />
                    <InfoItem title={`Track ${index + 1} - Framerate`} value={track.frame_rate} />
                    <InfoItem title={`Track ${index + 1} - Resolution`} value={track.frame_size} />
                    <InfoItem title={`Track ${index + 1} - Profile`} value={track.profile} />
                    <InfoItem title={`Track ${index + 1} - GOP`} value={track.gop_size} />
                    <Divider sx={{ my: 2 }} />
                </Box>
            ))}
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Audio Tracks
            </Typography>
            {api_data.transcode_parameters?.individual_track_configuration?.audio_tracks?.map((track: any, index: number) => (
                <Box key={index}>
                    <InfoItem title={`Track ${index + 1} - Codec`} value={track.codec} />
                    <InfoItem title={`Track ${index + 1} - Layout`} value={track.encoding_mode_or_layout} />
                    {/* map the .variants which contain sample_rate and bitrate */}
                    {track.variants?.map((variant: any, v_index: number) => (
                        <Box key={v_index}>
                            <InfoItem title={`Track ${index + 1} - Variant ${v_index + 1} - Bitrate`} value={variant.bitrate} />
                            <InfoItem title={`Track ${index + 1} - Variant ${v_index + 1} - Sample Rate`} value={variant.sample_rate} />
                        </Box>
                    ))}
                    <Divider sx={{ my: 2 }} />
                </Box>
            ))}
        </Box>
    );
};

export default TranscoderInfo;
