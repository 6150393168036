/* eslint-disable mobx/missing-observer */
import { Box } from "@mui/material";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { RootStoreContext } from "api";
import CreateMultiScheduleForm from "../../Components/CreateMultiScheduleForm";

interface ScheduleRowHourProps {
    zoom: number;
    hour: number;
    destination_id: string;
    is_permanent: boolean;
}

/**
 * A single hour block of a schedule row.
 * @param {ScheduleRowHourProps} props - The props for the ScheduleRowHour component.
 * @param {number} props.zoom - The zoom factor for the ScheduleRowHour component.
 * @param {number} props.hour - The hour the ScheduleRowHour represents.
 * @param {string} props.destination_id - The id of the destination the ScheduleRowHour represents.
 * @param {boolean} props.is_permanent - Determines if the ScheduleRowHour represents permanent schedules.
 * @returns {React.FC<ScheduleRowHourProps>} - Returns a ScheduleRowHour component.
 * @example
 * <ScheduleRowHour zoom={1} hour={0} destination_id={"123"} is_permanent={false} />
 */

const ScheduleRowHour: React.FC<ScheduleRowHourProps> = (props) => {
    const { date } = React.useContext(RootStoreContext).schedulerStore;
    const { openDialog } = React.useContext(RootStoreContext).dialogStore;
    const [minute, setMinute] = React.useState<number>(0);

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        const parentRect = event.currentTarget.getBoundingClientRect();
        setMinute(Math.ceil((event.clientX - parentRect.left) / props.zoom));
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6
                  }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const isPastDateTime = (hour: number, minute: number): boolean => {
        const selectedDateTime = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate(), hour, minute);
        return selectedDateTime < new Date();
    };

    return (
        <>
            <Box
                onContextMenu={handleContextMenu}
                onClick={handleContextMenu}
                sx={{
                    height: "100%",
                    width: 60 * props.zoom,
                    borderRight: "1px dashed rgba(160,160,160,0.1)",
                    flexShrink: 0,
                    padding: 1,
                    backgroundColor: contextMenu !== null ? "rgba(160,160,160,0.1)" : "transparent",
                    transition: "background-color 0.2s ease-in-out"
                }}></Box>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <MenuItem
                    disabled={isPastDateTime(props.hour, minute) || props.is_permanent}
                    onClick={() => {
                        handleClose();
                        const startTime = new Date(
                            new Date(date).getFullYear(),
                            new Date(date).getMonth(),
                            new Date(date).getDate(),
                            props.hour,
                            minute
                        );
                        const endTime = new Date(
                            new Date(date).getFullYear(),
                            new Date(date).getMonth(),
                            new Date(date).getDate(),
                            props.hour,
                            minute
                        );
                        // add 1 hour to the end_time
                        endTime.setHours(endTime.getHours() + 1);

                        openDialog(
                            <CreateMultiScheduleForm destination_id={props.destination_id} start={startTime} end={endTime} />,
                            "Create Schedule",
                            "lg",
                            true
                        );
                    }}>
                    Add Schedule ({props.hour.toString().padStart(2, "0")}:{minute.toString().padStart(2, "0")}
                    )...
                </MenuItem>
            </Menu>
        </>
    );
};

export default ScheduleRowHour;
