import { Box, CircularProgress, List } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { RootStoreContext } from "api";
import EnvironmentsListItem from "./EnvironmentsListItem";

interface IProps {
    onMouseOut?: () => void;
}

const EnvironmentsList: React.FC<IProps> = (props) => {
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected, initialize } = rootStore.resClientStore;
    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_org_id = rootStore.userStore.activeOrganisation
    const [data, setData] = React.useState<any>([]);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        const fetchData = () => {
            client && client
                .get(`environments_v2.environments.${managing_org_id}.${active_org_id}`)
                .then((res) => {
                    //console.log(res.toJSON());
                    setData(res.toJSON());
                    setLoading(false);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setData(res.toJSON());
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        };

        if (client && clientConnected) {
            fetchData();
        } else {
            const intervalId = setInterval(() => {
                if (clientConnected) {
                    clearInterval(intervalId);
                    fetchData();
                }
            }, 1000);
        }

        //client && !clientConnected && initialize(rootStore.commonStore.token!);
    }, [client, clientConnected, managing_org_id, rootStore.commonStore.token]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <List sx={{ position: "relative"}}>
            {data &&
                Object.entries(data)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort entries by key
                    .map(([key, environment]: [string, any]) => (
                        <EnvironmentsListItem key={key} rid={environment.rid} onMouseOut={props.onMouseOut} />
                    ))}
            {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <CircularProgress />
                </Box>
            )}
        </List>
    );
};

export default observer(EnvironmentsList);
