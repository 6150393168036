import { RootStore } from "./rootStore";
import { makeAutoObservable, observable } from "mobx";

export default class NotificationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    @observable unreadNotificationsLoaded = false;
    @observable unreadNotificationsCountsLoaded = false;
    @observable unreadNoneCriticalNotificationsCount;
    @observable unreadCriticalNotificationsCount;

    setUnreadNotificationsLoaded = (value: boolean) => {
        this.unreadNotificationsLoaded = value;
    };

    setUnreadNotificationsCountsLoaded = (value: boolean) => {
        this.unreadNotificationsCountsLoaded = value;
    };

    setUnreadNoneCriticalNotificationsCount = (value: number) => {
        this.unreadNoneCriticalNotificationsCount = value;
    };

    setUnreadCriticalNotificationsCount = (value: number) => {
        this.unreadCriticalNotificationsCount = value;
    };
}
