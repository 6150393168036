import React, { useContext, useState } from "react";
import { RootStoreContext } from "api";
import { RestartConfirmed } from "./RestartConfirmed";
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSources } from "hooks";

interface IProps {
    transcoder_id: string;
    name: string;
}

export const ConfirmRestart: React.FC<IProps> = ({ transcoder_id, name }) => {
    const rootStore = useContext(RootStoreContext);
    const { restartTranscoder } = useSources();
    const { closeDialog, changeDialogBody } = rootStore.dialogStore;
    const [errorMsg, setErrorMsg] = useState("");
    const [restarting, setRestarting] = useState(false);
    return (
        <>
            <DialogTitle>Confirm Restart Transcoder</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Restart transcoder: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{name}</span>
                </Typography>
                {errorMsg && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {errorMsg}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={restarting}
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <LoadingButton
                    disabled={restarting}
                    loading={restarting}
                    variant="contained"
                    color="primary"
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                        setRestarting(true);
                        restartTranscoder({ transcoder_id: transcoder_id })
                            .then(() => {
                                changeDialogBody(<RestartConfirmed name={name} />);
                            })
                            .catch((error) => {
                                setErrorMsg(error.data.message);
                                setRestarting(false);
                            });
                    }}>
                    Restart
                </LoadingButton>
            </DialogActions>
        </>
    );
};
