import React from 'react';
import { AxiosResponse } from 'axios';
import { Alert, AlertTitle } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

interface IProps {
    error: AxiosResponse;
    text?: string;
}

/**
 * Displays an error message with details about the error.
 * @param {Object} props - The component props.
 * @param {AxiosResponse} props.error - The AxiosResponse object containing information about the error.
 * @param {string} [props.text] - Optional text to display along with the error message.
 */
const ErrorMessage: React.FC<IProps> = ({ error, text }) => {
    return (
        <Alert severity="error" icon={<ErrorIcon fontSize="inherit" />}>
            <AlertTitle>{error.statusText}</AlertTitle>
            {error.data && Object.keys(error.data.message).length > 0 && (
                <div>{Object.values(error.data.message)}</div>
            )}
            {text && <div>{text}</div>}
        </Alert>
    );
};

export default ErrorMessage;