import { Alert, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { agent, IValidateCredentialsRequest, RootStoreContext } from "api";
import React, { useContext } from "react";

interface ConfirmValidateCredentialsProps {
    values: IValidateCredentialsRequest;
    cloudProvider: string;
}

const ConfirmValidateCredentials: React.FC<ConfirmValidateCredentialsProps> = ({ values, cloudProvider }) => {
    const [error, setError] = React.useState<any>(null);

    const rootStore = useContext(RootStoreContext);
    const { closeDialog, changeDialogBody } = rootStore.dialogStore;

    const validateCredentials = async () => {
        return agent.OwnCloud.validateCredentials(values, cloudProvider)
        .then((response) => {
            console.log('response', response);
            changeDialogBody(
                <>
                    <DialogTitle>
                        Validate Credentials
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you validate the credentials?
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Credentials have been successfully validated
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => {
                                closeDialog();
                            }}>
                            Close
                        </Button>
                    </DialogActions>
                </>
            )
        })
        .catch((error) => {
            const errors = error?.data?.errors;
            if (!errors) {
                return setError(error?.data?.message || 'An error occurred');
            }
        });
    };

    return (
        <>
            <DialogTitle>
                Validate Credentials
            </DialogTitle>
            <DialogContent>
                Are you sure you validate the credentials?
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        validateCredentials();
                    }}>
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmValidateCredentials;
