import React from "react";

const useClientProvider = (client: string) => {
    if (!client || client === process.env.REACT_APP_CLIENT_NAME) {
        return true;
    }

    return false;
}

export default useClientProvider;
