import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Box, Button, Card, Typography } from "@mui/material";

interface IProps {
    message: string;
    title: string;
}

export const MultiChannelEditedMessage: React.FC<IProps> = (props) => {
    const { message, title } = props;
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <Card sx={{ py: 4 }}>
            <Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
                {title}
            </Typography>

            <Typography sx={{ textAlign: "center", mb: 3 }}>{message}</Typography>
            <Box sx={{ textAlign: "center", width: "100%" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </Box>
        </Card>
    );
};
