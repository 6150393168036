import { Box, Card, useTheme } from "@mui/material";
import React from "react";
import { Handle, NodeProps, Position, useReactFlow } from "reactflow";
import NodeHeader from "../NodeHeader";

interface Props extends NodeProps {
    data: {
        type: string;
        onDragOver?: (event: React.DragEvent<HTMLDivElement>, element: any) => void;
        onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
        onDragLeave?: (event: React.DragEvent<HTMLDivElement>) => void;
        onClick?: (element: React.ReactNode) => void;
        label: string;
        hideTarget?: boolean;
        hideSource?: boolean;
        width?: number;
        height?: number;
        isDroppable: boolean;
        color?: string;
        menuItems?: any[];
    };
}

export const GroupNode: React.FC<Props> = (props) => {


    const reactflow = useReactFlow();

    const theme = useTheme();
    const [droppable, setDroppable] = React.useState(false);
    const groupNodeRef = React.useRef(null);

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        props.data.onDragOver && props.data.onDragOver(event, groupNodeRef);
        if (props.data.isDroppable) {
            setDroppable(true);
        }
    };

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        setDroppable(false);
        props.data.onDrop && props.data.onDrop(event);
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        setDroppable(false);
        props.data.onDragLeave && props.data.onDragLeave(event);
    };


    return (
        <Card
            ref={groupNodeRef}
            id={props.id}
            onDragEnter={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (props.data.isDroppable) {
                    setDroppable(true);
                }
            }
            }
            onDragOver={(event) => {
                onDragOver(event);
            }}
            onDragLeave={(event) => {
                onDragLeave(event);
            }}
            onDrop={(event) => {
                onDrop(event);
            }}
            onClick={() => {
                props.data.onClick && props.data.onClick(groupNodeRef.current);
            }}
            sx={{
                width: props.data.width || 300,
                height: props.data.height || 300,
                backgroundColor: droppable ? theme.palette.action.selected : props.selected ? theme.palette.action.focus : theme.palette.action.hover,
                border: props.data.isDroppable === true ? `2px dashed ${theme.palette.primary.main}` : `2px dashed rgba(160,160,160,0)`,
            }}>
            {!props.data.hideTarget && <Handle type="target" isConnectable={false} position={props.targetPosition || Position.Left} />}
            <NodeHeader label={props.data.label} id={props.id + "_header"} color={props.data.color} menuItems={props.data.menuItems} />
            {!props.data.hideSource && <Handle type="source" isConnectable={false} position={props.sourcePosition || Position.Right} />}
        </Card>
    );
};

export default GroupNode;
