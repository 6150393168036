export const aesTypes = [
  { key: 'aes128', text: 'AES-128', value: 'aes128' },
  { key: 'aes192', text: 'AES-192', value: 'aes192'},
  { key: 'aes256', text: 'AES-256', value: 'aes256'},
];


export const SRTEncTypes = [
  { key: 0, text: 'None', value: 0 },
  { key: 1, text: 'AES-128', value: 1},
  { key: 2, text: 'AES-192', value: 2},
  { key: 3, text: 'AES-256', value: 3},
];