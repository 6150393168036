import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { agent, IUserInOrg, RootStoreContext } from "api";
import { UsersDeleteConfirm } from "./UsersDeleteConfirm";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, Card, IconButton, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import MailLockIcon from "@mui/icons-material/MailLock";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useAdmin, useNotification } from "hooks";

const Users: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { users } = rootStore.adminStore;
    const { loadUsers, updateUserPermissions } = useAdmin();
    const { activeorganisationId, user, getUser } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const { muteUserCategories } = useNotification();
    const [userId, setUserId] = React.useState<number>(0);

    const makeRoot = (chosenUser: IUserInOrg) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Change Root User"
                message=""
                onConfirm={async () => {
                    agent
                        .getCleanInstance()
                        .put("/organisations/change-root-user", { user_id: chosenUser.id })
                        .then(() => {
                            getUser().then(() => {
                                loadUsers(user!.managing_organisation_id!);
                            });
                        });
                }}
                additionalContent={
                    <>
                        <Typography sx={{ color: "red", mb: 1 }}>
                            Are you sure you want to mark user <b>{chosenUser.email}</b> as the new root user?
                        </Typography>
                        <Typography>
                            When done, you will lose the root user permission and only the new root user will be able to give it back to
                            you, since there can be only one root user per organisation.
                        </Typography>
                    </>
                }
            />
        );
    };

    const sendResetToken = (chosenUser: IUserInOrg) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Password Reset"
                message={`Are you sure you want to send a password reset token to user ${chosenUser.email}?`}
                onConfirm={async () => {
                    await agent.getCleanInstance().put(`/reset-password/user/${chosenUser.id}`);
                }}
                additionalContent={
                    <Typography sx={{ mt: 1 }}>
                        When done, they will receive an email with a password reset token that allows them to change their password.
                    </Typography>
                }
            />
        );
    };

    useEffect(() => {
        loadUsers(activeorganisationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card sx={{ p: 2, height: "100%" }}>
            <Typography variant="h5">Users</Typography>
            <Box height={"100%"}>
                {users && (
                    <DataGridPro
                        sx={{
                            border: "none",
                            pb: 2
                        }}
                        columns={[
                            {
                                field: "fullName",
                                headerName: "Name",
                                flex: 2,
                                valueGetter(params) {
                                    return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
                                },
                                renderCell: (params) => {
                                    return (
                                        <Typography sx={{ color: user?.user_id === params.row.id ? "#307abb" : undefined }}>{`${
                                            params.row.first_name || ""
                                        } ${params.row.last_name || ""}`}</Typography>
                                    );
                                }
                            },
                            {
                                field: "email",
                                headerName: "Email",
                                type: "string",
                                flex: 3,
                                renderCell: (params) => {
                                    return (
                                        <Typography sx={{ color: user?.user_id === params.row.id ? "#307abb" : undefined }}>
                                            {params.value}
                                        </Typography>
                                    );
                                }
                            },
                            {
                                field: "locked_out_until",
                                headerName: "Locked Until",
                                description:
                                    "7 failed login attempts will cause the account to become locked until the date shown. Subsequent failed attempts will delay the automatic unlock further. Administrators can manually unlock an account using the button on the right.",
                                type: "dateTime",
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <Typography sx={{ color: user?.user_id === params.row.id ? "#307abb" : undefined }}>
                                            {params.value ? format(new Date(params.value), "dd/MM/yyyy HH:mm:ss") : ""}
                                        </Typography>
                                    );
                                }
                            },
                            {
                                field: "permission_type",
                                headerName: "Permission",
                                type: "string",
                                flex: 1,
                                renderCell: (params) => {
                                    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
                                        setAnchorEl(event.currentTarget);
                                        setUserId(params.row.id);
                                    };

                                    const handleClose = () => {
                                        setAnchorEl(null);
                                    };

                                    const handleChange = (e: React.MouseEvent<HTMLElement>) => {
                                        setAnchorEl(null);
                                        const { myValue } = e.currentTarget.dataset;
                                        updateUserPermissions({
                                            organisation_id: user!.managing_organisation_id!,
                                            user_id: userId,
                                            permission_type: myValue!,
                                            enabled: true
                                        }).then(() => {
                                            loadUsers(user!.managing_organisation_id!);
                                        });
                                    };

                                    if (user?.user_id !== params.row.id) {
                                        return (
                                            <>
                                                <Button
                                                    aria-controls="fade-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                    style={{
                                                        textAlign: "left",
                                                        color: undefined,
                                                        backgroundColor: "transparent",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                    {params.row.is_admin
                                                        ? "Admin"
                                                        : params.row.is_monitor
                                                        ? "Monitor"
                                                        : params.row.is_operator
                                                        ? "Operator"
                                                        : null}
                                                    <KeyboardArrowDownIcon style={{ color: undefined }} />
                                                </Button>
                                                <Menu
                                                    id="fade-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={openMenu}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}>
                                                    <MenuItem
                                                        data-my-value={"admin"}
                                                        onClick={(e) => {
                                                            handleChange(e);
                                                        }}>
                                                        <Tooltip
                                                            style={{ fontSize: "1rem" }}
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            placement="left"
                                                            arrow
                                                            title={
                                                                <div>
                                                                    <p>Admin Permission</p>{" "}
                                                                    <p>All configuration and operation of member organisation.</p>
                                                                </div>
                                                            }>
                                                            <p style={{ margin: 0 }}>Admin</p>
                                                        </Tooltip>
                                                    </MenuItem>
                                                    <MenuItem
                                                        data-my-value={"operator"}
                                                        onClick={(e) => {
                                                            handleChange(e);
                                                        }}>
                                                        <Tooltip
                                                            style={{ fontSize: "1rem" }}
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            placement="left"
                                                            arrow
                                                            title={
                                                                <div>
                                                                    <p>Operator Permission</p>{" "}
                                                                    <p>
                                                                        Operation of the platform, transcoder configuration, source /
                                                                        destination configuration and scheduling. View only for settings
                                                                        menu, no configuration privileges.
                                                                    </p>
                                                                </div>
                                                            }>
                                                            <p style={{ margin: 0 }}>Operator</p>
                                                        </Tooltip>
                                                    </MenuItem>
                                                    <MenuItem
                                                        data-my-value={"monitor"}
                                                        onClick={(e) => {
                                                            handleChange(e);
                                                        }}>
                                                        <Tooltip
                                                            style={{ fontSize: "1rem" }}
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            placement="left"
                                                            arrow
                                                            title={
                                                                <div>
                                                                    <p>Monitor Permission</p>{" "}
                                                                    <p>Monitoring only, no configuration or operation of the platform.</p>
                                                                </div>
                                                            }>
                                                            <p style={{ margin: 0 }}>Monitor</p>
                                                        </Tooltip>
                                                    </MenuItem>
                                                </Menu>
                                            </>
                                        );
                                    }
                                    return (
                                        <Button style={{ backgroundColor: "transparent", color: "#307abb" }}>
                                            {params.row.is_admin
                                                ? "Admin"
                                                : params.row.is_monitor
                                                ? "Monitor"
                                                : params.row.is_operator
                                                ? "Operator"
                                                : null}
                                        </Button>
                                    );
                                }
                            },
                            {
                                field: "is_root_user",
                                headerName: "Is root?",
                                type: "string",
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <Typography sx={{ color: user?.user_id === params.row.id ? "#307abb" : undefined }}>
                                            {params.value ? "Yes" : "No"}
                                        </Typography>
                                    );
                                }
                            },
                            {
                                field: "actions",
                                headerName: "",
                                sortable: false,
                                flex: 1,
                                align: "right",
                                renderCell: (params) => {
                                    const handleDelete = () => {
                                        openDialog(<UsersDeleteConfirm user={params.row} />);
                                    };

                                    return (
                                        <Stack direction="row" spacing={1} sx={{ my: 0.5 }}>
                                            {user?.user_id === params.row.id && user.muted_notification_categories?.includes("ALL") && (
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="bottom"
                                                    arrow
                                                    title="Enable Notifications">
                                                    <Box>
                                                        <IconButton
                                                            onClick={() => {
                                                                muteUserCategories({ categories: [] }).then(() => {
                                                                    getUser().then(() => {
                                                                        loadUsers(user!.managing_organisation_id!);
                                                                    });
                                                                });
                                                            }}
                                                            aria-label="Enable Notifications">
                                                            <NotificationsIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            )}
                                            {user?.user_id === params.row.id && (
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="bottom"
                                                    arrow
                                                    title="Disable Notifications">
                                                    <Box>
                                                        <IconButton
                                                            onClick={() => {
                                                                openDialog(
                                                                    <ConfirmationDialog
                                                                        title="Mute all notifications?"
                                                                        message="When muted, you will no longer receive / see any notifications, however notifications are still going to be generated for the organisation."
                                                                        onConfirm={async () => {
                                                                            agent
                                                                                .getCleanInstance()
                                                                                .put("/user/mute-notification-categories", {
                                                                                    categories: ["ALL"]
                                                                                })
                                                                                .then(() => {
                                                                                    getUser().then(() => {
                                                                                        loadUsers(user!.managing_organisation_id!);
                                                                                    });
                                                                                });
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                            aria-label="Disable Notifications">
                                                            <NotificationsOffIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            )}

                                            {user?.managing_org_permission === "admin" && user.user_id !== params.row.id && (
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="bottom"
                                                    arrow
                                                    title="Send Reset Token">
                                                    <Box>
                                                        <IconButton
                                                            onClick={() => {
                                                                sendResetToken(params.row);
                                                            }}
                                                            aria-label="Send Reset Token">
                                                            <MailLockIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            )}
                                            {user?.user_id !== params.row.id &&
                                                !params.row.is_root_user &&
                                                params.row.is_admin &&
                                                user?.is_managing_org_root && (
                                                    <Tooltip
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{ timeout: 600 }}
                                                        placement="bottom"
                                                        arrow
                                                        title="Make Root">
                                                        <Box>
                                                            <IconButton
                                                                onClick={() => {
                                                                    makeRoot(params.row);
                                                                }}
                                                                aria-label="Make Root">
                                                                <AdminPanelSettingsIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                            {user?.user_id !== params.row.id && !params.row.is_root_user && (
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="bottom"
                                                    arrow
                                                    title="Delete User">
                                                    <Box>
                                                        <IconButton onClick={() => handleDelete()} aria-label="Delete User">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            )}
                                            {user?.managing_org_permission === "admin" && params.row.locked_out_until && (
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="bottom"
                                                    arrow
                                                    title="Unlock User Account">
                                                    <Box>
                                                        <IconButton
                                                            onClick={() =>
                                                                agent
                                                                    .getCleanInstance()
                                                                    .put(`/user/reset-lockout/${params.row.id}`)
                                                                    .then(() => {
                                                                        loadUsers(user!.managing_organisation_id!);
                                                                    })
                                                            }
                                                            aria-label="Unlock User Account">
                                                            <LockOpenIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </Stack>
                                    );
                                }
                            }
                        ]}
                        rows={users}
                        getRowHeight={() => "auto"}
                        hideFooter
                    />
                )}
            </Box>
        </Card>
    );
};

export default observer(Users);
