import { Alert, Button, DialogActions, DialogContent } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext } from "react";

interface ConfirmEnableOwnCloudProps {
    cloud_provider: string;
    setHasChanged: (hasChanged: boolean) => void;
}

const ConfirmEnableOwnCloud: React.FC<ConfirmEnableOwnCloudProps> = ({ cloud_provider, setHasChanged }) => {
    const [error, setError] = React.useState<any>(null);

    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const enableOwnCloud = async () => {
        await agent.OwnCloud.enableOwnCloud(cloud_provider)
            .then((response) => {
                console.log(response);
                closeDialog();
                setHasChanged(true);
            })
            .catch((error) => {
                console.log(error);
                setError(error);
            });
    };

    return (
        <>
            <DialogContent>
                Are you sure you want to enable Own Cloud ?
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error.data?.message}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    color="success"
                    variant="contained"
                    onClick={() => {
                        enableOwnCloud();
                    }}>
                    Enable
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmEnableOwnCloud;
