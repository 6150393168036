import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
    Alert,
    Avatar,
    Box,
    CardHeader,
    Chip,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Icon,
    IconButton,
    Skeleton,
    Stack,
    Tab,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react";
import SourceThumbnail from "./SourceThumbnail";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import { agent, IInput, RootStoreContext } from "api";
import ChannelAlerts from "./ChannelAlerts";
import TranscoderList from "./TranscoderList";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import MultiChannelConfigEditForm from "../../channels/createMultiChanl/MultiChannelConfigEditForm";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import RoleProvider from "components/RoleProvider";
import CreateMultiScheduleForm from "features/scheduler/Components/CreateMultiScheduleForm";
import useSource from "../useSource";
import InfoItem from "components/InfoItem";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/ConfirmationDialog";
import SourceStats from "./SourceStats";
import SourceAnalysis from "./SourceAnalysis";
import { useSources } from "hooks";

interface Props {
    item_id: string;
    tab?: string;
}

const SourceDetails: React.FC<Props> = (props) => {
    const [loading] = React.useState<boolean>(false);
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { openDrawer, closeDrawer } = rootStore.drawerStore;
    const [tabValue, setTabValue] = React.useState(props.tab || "1");
    const { client } = rootStore.resClientStore;
    const [wsData, setWsData] = React.useState<any>({});
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [multiViewerInfo, setMultiViewerInfo] = React.useState<any>({});
    const [multViewerSources, setMultiViewerSources] = React.useState<any>([]);
    const { getMultiViewerInfo, deleteTranscoder } = useSources();
    const history = useLocation();

    const navigate = useNavigate();

    const [source, setSource] = React.useState<IInput>();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const getSource = async (input_id: string) => {
        await agent.Channel.getSource(input_id)
            .then((source) => {
                setSource(source.data);

                if (source.data.multi_viewer_id) {
                    getMultiViewerData(source.data.multi_viewer_id);
                }
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

    const getMultiViewerTileSources = async (inputs: { input_id: string }[]) => {
        try {
            const inputIds = inputs.map((input) => input.input_id).filter(Boolean); // Filter out falsy values (like undefined or null)
            if (inputIds.length === 0) {
                return;
            }

            const promises = inputIds.map((input_id) => agent.Channel.getSource(input_id));
            const responses = await Promise.all(promises);
            const sources: IInput[] = responses.map((response) => response.data);

            setMultiViewerSources(sources);
        } catch (err) {
            console.log("getMultiViewerTileSources Error:", err);
        }
    };

    const getMultiViewerData = async (id: string) => {
        try {
            const res = await getMultiViewerInfo(id);
            setMultiViewerInfo(res);

            if (res.tile_inputs) {
                getMultiViewerTileSources(res.tile_inputs);
            } else {
                console.log("No tile_inputs found in the response.");
            }
        } catch (err) {
            console.log("getMultiViewerData Error:", err);
        }
    };

    const getWsData = async (input_id: string) => {
        const rid = `environments.sources.${rootStore.userStore.managingOrganisationId}.${rootStore.userStore.activeEnvironment}.${rootStore.userStore.activeorganisationId}.source.${input_id}`;
        await client
            .get(rid)
            .then((res) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });
                setWsData(res);
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

    React.useEffect(() => {
        getSource(props.item_id);
        getWsData(props.item_id);
        return () => {
            setTabValue("1");
        };
    }, [props.item_id]);

    React.useEffect(() => {}, [updateDate]);

    const { getTypeText, getStatusText, getStatusColor, getStatusIcon } = useSource(wsData);

    const isStopped = () => {
        if (wsData.transcoder_id) {
            if (
                wsData.transcoder_status === "stopping" ||
                wsData.transcoder_status === "stopped" ||
                wsData.transcoder_status === "creating"
            ) {
                return true;
            }
        }

        if (wsData.multiviewer_id) {
            if (
                wsData.multiviewer_status === "stopping" ||
                wsData.multiviewer_status === "stopped" ||
                wsData.multiviewer_status === "creating"
            ) {
                return true;
            }
        }

        return false;
    };

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardHeader
                sx={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
                title={
                    loading === false && wsData.connection_status ? (
                        <span
                            style={{
                                display: "block",
                                width: "100%",
                                textTransform: "capitalize",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                            {getStatusText()}
                        </span>
                    ) : (
                        <Skeleton>
                            <span>Connection Status</span>
                        </Skeleton>
                    )
                }
                subheader={
                    loading === false && wsData.type ? (
                        <span style={{ textTransform: "capitalize" }}>{getTypeText()}</span>
                    ) : (
                        <Skeleton>
                            <span>Source Type</span>
                        </Skeleton>
                    )
                }
                avatar={
                    loading === false ? (
                        <Avatar
                            sx={{
                                bgcolor: getStatusColor(),
                                color: "#fff"
                            }}
                            title="Source"
                            aria-label="recipe">
                            <Icon component={getStatusIcon()} />
                        </Avatar>
                    ) : (
                        <Skeleton variant="circular">
                            <Avatar />
                        </Skeleton>
                    )
                }
                action={
                    <Stack direction="row" spacing={1} sx={{ marginTop: 2, marginRight: 1 }}>
                        <Tooltip title="Active Alerts" placement="top">
                            <Chip
                                size="small"
                                icon={<NotificationsActiveIcon />}
                                label={wsData.active_alerts}
                                className={wsData.active_alerts > 0 ? "pulse" : undefined}
                                onClick={() => setTabValue("3")}
                            />
                        </Tooltip>
                        {wsData.is_analysis_on && (
                            <Tooltip title="Analysis" placement="top">
                                <Chip
                                    size="small"
                                    icon={<TroubleshootIcon />}
                                    className={
                                        (wsData.raise_p1_alert && wsData.p1_ok === false) ||
                                        (wsData.raise_p2_alert && wsData.p2_ok === false)
                                            ? "pulse"
                                            : undefined
                                    }
                                    label={
                                        <div>
                                            {wsData.raise_p1_alert && wsData.p1_ok === false && <span>P1</span>}
                                            {wsData.raise_p1_alert &&
                                                wsData.raise_p2_alert &&
                                                wsData.p1_ok === false &&
                                                wsData.p2_ok === false && <span>&nbsp;|&nbsp;</span>}
                                            {wsData.raise_p2_alert && wsData.p2_ok === false && <span>P2</span>}
                                        </div>
                                    }
                                    onClick={() => setTabValue("2")}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title="Transcoders" placement="top">
                            <Chip size="small" icon={<SwapHorizIcon />} label={wsData.transcoder_count} onClick={() => setTabValue("4")} />
                        </Tooltip>
                        <Tooltip title="Scheduled | Permanent" placement="top">
                            <Chip
                                size="small"
                                icon={<EventIcon />}
                                label={wsData.scheduled_to_destinations + " | " + wsData.permanently_scheduled_to_destinations}
                            />
                        </Tooltip>
                    </Stack>
                }
            />
            <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={5}>
                    <Box sx={{ borderRadius: "4px", overflow: "hidden" }}>
                        <SourceThumbnail item_id={props.item_id} enableFullScreen />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                        <Box>
                            <Typography gutterBottom variant="h6" component="div">
                                {source?.display_name}
                            </Typography>
                            {wsData.transcoder_input_name &&
                            wsData?.transcoder_progress_status !== "Complete" &&
                            wsData?.transcoder_progress_status !== "Stopped" &&
                            wsData?.transcoder_progress_status !== "Running" ? (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    component="div"
                                    sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                    {wsData?.transcoder_progress_status !== "Stopped" && <CircularProgress size={8} sx={{ mr: 0.5 }} />}
                                    {wsData?.transcoder_progress_status}...
                                </Typography>
                            ) : null}
                            <Typography variant="body2" color="text.secondary">
                                {source?.description}
                            </Typography>
                        </Box>

                        <Stack direction="row" justifyContent="flex-start" spacing={1}>
                            <Tooltip title="Schedule Source" placement="top">
                                <IconButton
                                    aria-label="schedule"
                                    onClick={() =>
                                        openDialog(<CreateMultiScheduleForm source_id={props.item_id} />, "Create Schedule", "lg", true)
                                    }>
                                    <EventIcon />
                                </IconButton>
                            </Tooltip>
                            <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                {!wsData.transcoder_id && (
                                    <Tooltip title="Edit Source" placement="top">
                                        <span>
                                            <IconButton
                                                aria-label="edit"
                                                disabled={isStopped()}
                                                onClick={() => {
                                                    if (wsData.multiviewer_id) {
                                                        setTabValue("6");
                                                    } else {
                                                        closeDrawer();
                                                        navigate(`/sources/edit/${wsData.id}`, { state: { returnUrl: history.pathname } });
                                                    }
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                                {wsData.transcoder_id && !wsData.is_motion_interpolation_framerate_converter && (
                                    <Tooltip title="Edit Transcoder" placement="top">
                                        <span>
                                            <IconButton
                                                aria-label="edit"
                                                disabled={wsData.transcoder_status !== "running"}
                                                onClick={() => {
                                                    closeDrawer();
                                                    navigate(`/transcoders/edit/${wsData.transcoder_id}`);
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                                {wsData.transcoder_id && wsData.is_motion_interpolation_framerate_converter && (
                                    <Tooltip title="Edit MIFC Transcoder" placement="top">
                                        <span>
                                            <IconButton
                                                aria-label="edit"
                                                disabled={
                                                    wsData.transcoder_id &&
                                                    (wsData.transcoder_status === "stopping" || wsData.transcoder_status === "stopped")
                                                }
                                                onClick={() => {
                                                    closeDrawer();
                                                    navigate(`/transcoders/edit-mi/${wsData.transcoder_id}`);
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                                {!wsData.transcoder_id && !wsData.multiviewer_id && (
                                    <Tooltip title="Create Transcoder" placement="top">
                                        <IconButton
                                            aria-label="create transcoder"
                                            onClick={() => {
                                                closeDrawer();
                                                navigate(`/transcoders/create/${wsData.id}`);
                                            }}>
                                            <SwapHorizontalCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {!wsData.multiviewer_id ? (
                                    <Tooltip title={"Delete " + (wsData.transcoder_id ? "Transcoder" : "Source")} placement="top">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                if (wsData.transcoder_id) {
                                                    openDialog(
                                                        <ConfirmationDialog
                                                            title="Delete Transcoder"
                                                            message={`Are you sure you want to delete transcoder "${wsData.name}"?`}
                                                            confirmButtonText="Delete Transcoder"
                                                            confirmButtonColor="error"
                                                            additionalContent={
                                                                <Alert severity="warning" sx={{ mt: 2 }}>
                                                                    Deleting this transcoder will remove it for all users and cannot be
                                                                    undone.
                                                                </Alert>
                                                            }
                                                            onConfirm={async () => {
                                                                await deleteTranscoder(wsData.transcoder_id);
                                                            }}
                                                            onSuccess={() => {
                                                                closeDrawer();
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    openDialog(
                                                        <ConfirmationDialog
                                                            title="Delete Source"
                                                            message={`Are you sure you want to delete source "${wsData.name}"?`}
                                                            confirmButtonText="Delete Source"
                                                            confirmButtonColor="error"
                                                            additionalContent={
                                                                <Alert severity="warning" sx={{ mt: 2 }}>
                                                                    Deleting this source will remove it for all users and cannot be undone.
                                                                </Alert>
                                                            }
                                                            onConfirm={async () => {
                                                                await agent.Channel.deleteInput(wsData.id);
                                                            }}
                                                            onSuccess={() => {
                                                                closeDrawer();
                                                            }}
                                                        />
                                                    );
                                                }
                                            }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Delete Multiviewer" placement="top">
                                        <IconButton
                                            aria-label="delete"
                                            disabled={isStopped()}
                                            onClick={() => {
                                                openDialog(
                                                    <ConfirmationDialog
                                                        title="Confirm Delete Multiviewer"
                                                        message=""
                                                        additionalContent={
                                                            <>
                                                                <Typography>
                                                                    Are you sure you want to delete multiviewer "{wsData.name}" ?<br />
                                                                    Deletion can not be un-done and it will be removed from all users.
                                                                </Typography>
                                                                <Alert severity="warning" sx={{ mt: 2 }}>
                                                                    Deleting this multiviewer will remove it for all users and cannot be
                                                                    undone.
                                                                </Alert>
                                                            </>
                                                        }
                                                        onConfirm={async () => {
                                                            return agent
                                                                .getCleanInstance()
                                                                .delete(`/multi-viewers/${wsData.multiviewer_id}`);
                                                        }}
                                                        onSuccess={() => {
                                                            closeDrawer();
                                                        }}
                                                    />
                                                );
                                            }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </RoleProvider>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            {source && (
                <TabContext value={tabValue}>
                    <Box>
                        <TabList onChange={handleTabChange} aria-label="source details tabs">
                            <Tab label="Stats" value="1" />
                            <Tab label="Analysis" value="2" />
                            <Tab label="Alerts" value="3" />
                            <Tab label="Transcoders" value="4" />
                            <Tab label="Info" value="5" />
                            {wsData.multiviewer_id && !isStopped() && <Tab label="Settings" value="6" />}
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        <SourceStats input_details={source} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        <SourceAnalysis
                            input_details={source}
                            p1_alerts_enabled={wsData.raise_p1_alert}
                            p2_alerts_enabled={wsData.raise_p2_alert}
                            showAlerts
                        />
                    </TabPanel>
                    <TabPanel value="3" sx={{ flexGrow: 1 }}>
                        <ChannelAlerts channel_id={props.item_id} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ flexGrow: 1 }}>
                        <TranscoderList source_id={props.item_id} environment_id={source.environment_id} />
                    </TabPanel>
                    <TabPanel value="5" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        {source && source.is_multi_viewer && (
                            <Stack>
                                <RoleProvider roles={["is_superuser", "is_accounts_admin"]}>
                                    <InfoItem title="Multi viewer public IP" value={multiViewerInfo.public_ip} />
                                </RoleProvider>
                                <InfoItem title="Number of Tiles" value={multiViewerInfo.number_of_tiles} />

                                {multiViewerInfo.tile_inputs &&
                                    multiViewerInfo.tile_inputs.map((tile: any, index: number) => {
                                        let tileSource = multViewerSources.find((source: any) => source.id === tile.input_id);
                                        return <InfoItem title={`${tile.title} Source`} value={tileSource?.display_name} key={index} />;
                                    })}

                                <InfoItem
                                    title="Max Allowed Bitrate (Kbps)"
                                    value={multiViewerInfo?.encoder_details?.max_allowed_bitrate_kbps}
                                />
                                <InfoItem
                                    title="Encoder UHD Tech Type ID"
                                    value={multiViewerInfo?.encoder_details?.encoder_uhd_tech_type_id}
                                />
                                <InfoItem title="Is Interlaced" value={multiViewerInfo?.encoder_details?.is_interlaced ? "yes" : "no"} />
                                <InfoItem title="Encoder GOP Size ID" value={multiViewerInfo?.encoder_details?.encoder_gop_size_id} />
                                <InfoItem title="Encoder Frame Rate ID" value={multiViewerInfo?.encoder_details?.encoder_frame_rate_id} />
                                <InfoItem
                                    title="Encoder GOP Size Mode ID"
                                    value={multiViewerInfo?.encoder_details?.encoder_gop_size_mode_id}
                                />
                                <InfoItem title="Program Number" value={multiViewerInfo?.encoder_details?.program_number} />
                                <InfoItem title="PMT PID" value={multiViewerInfo?.encoder_details?.pmt_pid} />
                                <InfoItem title="Video PID" value={multiViewerInfo?.encoder_details?.video_pid} />
                                <InfoItem title="Transcoded Audio PID" value={multiViewerInfo?.encoder_details?.transcoded_audio_pid} />
                                <InfoItem title="Passthrough Audio PID" value={multiViewerInfo?.encoder_details?.passthrough_audio_pid} />

                                <Divider sx={{ my: 2 }} />
                            </Stack>
                        )}

                        {source && source.technical_details
                            ? source.technical_details.input_settings.map((setting, i) => {
                                  return <InfoItem key={i} title={setting.data.label} value={setting.data.value} />;
                              })
                            : null}
                    </TabPanel>
                    {wsData.multiviewer_id && (
                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                            <TabPanel value="6" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                <MultiChannelConfigEditForm id={wsData.multiviewer_id} name={wsData.name} />
                            </TabPanel>
                        </RoleProvider>
                    )}
                </TabContext>
            )}
        </Box>
    );
};

export default SourceDetails;
