import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import { agent, RootStoreContext } from "api";
import { Table, TableCell, TableHead, TableBody, TableRow, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RetryMessageRow from "./Components/RetryMessageRow";

const AdminSystem = observer(function AdminSystem() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue] = React.useState<string | null>(null);

    const handleClose = () => {
        setOpen(false);
    };

    const rootStore = useContext(RootStoreContext);

    const { setCurrentTheme } = rootStore.commonStore;

    const [retryMessages, setRetryMessages] = React.useState<any>();

    const getRetryMessages = async () => {
        await agent.Admin.getRetryMessages()
            .then((response: any) => {
                console.log("Retry Messages", response);
                setRetryMessages(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    function renderTextWithNewLines(str) {
        const lines = str.split("\n");
        return lines.map((line, index) => (
            <div key={index} style={{ marginBottom: 5 }}>
                {line}
            </div>
        ));
    }

    useEffect(() => {
        setCurrentTheme("light");
        const getRetryMessages = async () => {
            await agent.Admin.getRetryMessages()
                .then((response: any) => {
                    console.log("Retry Messages", response);
                    setCurrentTheme("light");
                    setRetryMessages(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        getRetryMessages();

        return () => {
            setCurrentTheme("dark");
            setRetryMessages(undefined);
        };
    }, [setCurrentTheme]);

    return (
        <View>
            <ViewHeader title="System" subtitle="Admin">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div></div>
                    <div></div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <Content>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Received At</TableCell>
                                <TableCell>Queue</TableCell>
                                <TableCell>Retried At</TableCell>
                                <TableCell>Retried By</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {retryMessages &&
                                retryMessages.data.map((retryMessage: any, i: number) => (
                                    <RetryMessageRow key={i} retryMessage={retryMessage} onAction={() => getRetryMessages()} />
                                ))}
                        </TableBody>
                    </Table>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll="paper"
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                        fullWidth={true}
                        maxWidth="lg">
                        <DialogTitle id="scroll-dialog-title">Error Message</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                                {selectedValue && renderTextWithNewLines(selectedValue)}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Content>
            </ViewBody>
        </View>
    );
});

export default AdminSystem;
