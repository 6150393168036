interface LivelinkArgs {
  latency: number;
  host?: string;
  backup_url: string;
  bitrate: number;
  reconnect: number;
  rtmp_stream: string;
  url: string;
  user: string;
  password?: string;
  enc_type?: string;
  encryption_type?: string;
  enc_key: string;
  local_port?: number;
  fec?: number;
  rows_d?: number;
  columns_l?: number;
  ttl?: number;
  smoothing?: number;
  send_time_code: boolean;
  ignore_tls_certificate_errors: boolean;
  agreement_id?: number;
  auto_start: boolean;
  auto_stop: boolean;
  enable_rtp?: boolean;
  smoothing_ms?: number;
}

interface values {
  label?: string;
  value?: string;
  name?: string;
}

export interface IOutputSettings {
  data?: values;
  label?: string;
  value?: string;
  name?: string;
  type?: string;
  port?: number;
  alias?: string;
  link?: string;
  session?: string;
  latency?: number;
  stream?: string;
  source_id?: string;
  remote_id: string;
}

export interface IOutputTechnicalDetailsEntry {
  protocol: string;
  port: number;
  public_ip: string;
  dns_name: string;
  output_settings: IOutputSettings[];
  livelink_args: LivelinkArgs;
  remote_id: string;
}



export interface IOutputLiveStatistics {
  data: values[];
}

interface Arq_recv {
  canceled: number;
  dropped: number;
  duplicates: number;
  recovered: number;
  requests: number;
  packets: number;
}

interface Fec_recv {
  recovered: number;
  packets: number;
}

interface Net_recv {
  bitrate: number;
  burst_loss: number;
  dropped: number;
  jitter: number;
  latency: number;
  loss_millipercent: number;
  monotonic_dropped: number;
  packet_rate: number;
  packets: number;
}

interface Net_send {
  rtt: number;
}

export interface IDestinationFilters {
  name?: [""];
  type?: [""];
}

export interface IOutput {
  data?: any;
  id: string;
  source_id?: string;
  host?: string;
  url?: string;
  backup_url?: string;
  latency?: number;
  rtmp_stream: string;
  port: number;
  local_port?: number;
  fec?: number;
  ttl?: number;
  smoothing?: number;
  user?: string;
  bitrate: number;
  reconnect: number;
  type: string;
  enc_type?: string;
  enc_key?: string;
  display_name: string;
  sid?: string;
  environment_id: string;
  organisation_id?: string;
  environment_human_name: string;
  description: string;
  technical_details: IOutputTechnicalDetailsEntry;
  showingContent: boolean;
  isActive: boolean;
  rist_remote_port?: number;
  source: string;
  stream_id: string;
  output_id: string;
  hasPassword: boolean;
  hasEncryption: boolean;
  ignore_tls_certificate_errors: boolean;
  send_time_code: boolean;
  rows_d: number;
  columns_l: number;
  agreement_id?: number;
  is_permanently_scheduled: boolean;
  permanent_schedule_input_id: string;
  permanent_schedule_input_name: string;
  status: string | null;
  has_alerts: boolean;
  auto_start: boolean;
  auto_stop: boolean;
}

export interface IOutputs {
  data: IOutput[];
}

export interface IOutputFormValues extends Partial<IOutput> {
  time?: Date;
  pull_port?: string;
  password?: string;
  p_a_s_s_wd?: string;
  encryption_type?: number;
  enable_rtp?: boolean;
}

export class OutputFormValues implements IOutputFormValues {
  id?: string = "";
  source_id?: string = "";
  latency?: number = undefined;
  host?: string = "";
  url?: string = "";
  backup_url?: string = "";
  rtmp_stream?: string = "";
  port?: number = undefined;
  local_port?: number = undefined;
  fec?: number = undefined;
  ttl?: number = undefined;
  smoothing?: number = undefined;
  user?: string = "";
  bitrate?: number = undefined;
  reconnect?: number = undefined;
  type?: string = "";
  enc_type?: string = "";
  enc_key?: string = "";
  display_name?: string = "";
  stream_id?: string = "";
  description?: string = "";
  technical_details?: IOutputTechnicalDetailsEntry = undefined;
  remote_id?: string = "";
  output_id?: string = "";
  hasPassword: boolean = false;
  hasEncryption: boolean = false;
  ignore_tls_certificate_errors: boolean = false;
  send_time_code: boolean = false;
  password?: string = "";
  rows_d?: number = undefined;
  columns_l?: number = undefined;
  agreement_id?: number = undefined;
  agreement_ref_number?: string = "";
  auto_start: boolean = false;
  auto_stop: boolean = false;

  constructor(init?: IOutputFormValues) {
    Object.assign(this, init);
  }
}

export interface ISchedulesStatus {
  schedule_id: string;
  ui_status: string;
}

export interface IOutputStatusData {
  stream_status: string;
  is_input_connected_to_output: boolean;
  current_bitrate_kbps: number;
  schedules?: ISchedulesStatus[];
}

export interface IOutputStatusResponse {
  data: IOutputStatusData;
}

export enum DestinationTypes {
  udpRist = "RIST",
}

export interface IResSchedule {
  description: string;
  destination_name: string;
  end_time: string;
  environment_id: string;
  schedule_id: string;
  source_name: string;
  start_time: string;
  status: string;
  ui_status: string;
  wont_start: boolean;
}

export interface IResModel {
  connection_status: string;
  description: string;
  destination_id: string;
  environment_id: string;
  environment_human_name: string;
  is_permanently_scheduled: boolean;
  name: string;
  permanent_schedule_input_id: string;
  permanent_schedule_input_name: string;
  schedules: IResSchedule[];
  showingContent: boolean;
  isActive: boolean;
  type: string;
  rid: string;
}

export interface IResModels {
  _list: IResModel[];
}

export interface IDestOutputSettings {
  label?: string;
  value?: string;
}

export interface IDestLivelinkArgs {
  id?: string;
  latency: number;
  host?: string;
  backup_url: string;
  bitrate: number;
  reconnect: number;
  rtmp_stream: string;
  url: string;
  user: string;
  password: string;
  enc_type: string;
  enc_key: string;
  local_port?: number;
  fec?: number;
  rows_d?: number;
  columns_l?: number;
  ttl?: number;
  smoothing?: number;
  send_time_code: boolean;
  ignore_tls_certificate_errors: boolean;
  agreement_id?: number;
}

export interface IDestTechnicalDetails {
  protocol: string;
  port: number;
  public_ip: string;
  dns_name: string;
  output_settings: IDestOutputSettings[];
  livelink_args: IDestLivelinkArgs;
  remote_id: string;
}

export interface IDestData {
  id: string;
  status: boolean | null;
  type: string;
  human_type: string;
  display_name: string;
  sid: string;
  environment_id: string;
  organisation_id: number;
  environment_human_name: string;
  description: string | null;
  technical_details: IDestTechnicalDetails;
  has_alerts: boolean;
  stream_id: string;
  hasPassword: boolean;
  hasEncryption: boolean;
  is_permanently_scheduled: boolean;
  permanent_schedule_input_id: string | null;
  permanent_schedule_input_name: string | null;
}

export interface IDestination {
  status: string;
  showingContent: boolean;
  isActive: boolean;
  data: IDestData;
}

export interface IOutputLiveStatistic {
  time: Date;
  name: string;
  status: string;
  total_uptime: number;
  type: string;
  arq_recv?: Arq_recv;
  fec_recv?: Fec_recv;
  net_recv?: Net_recv;
  net_send?: Net_send;
}


export interface IOutputsStateEntry {
  id: string;
  name: string;
  connection_status: string;
  destination_type: string;
  bitrate_kbps: number;
  current_uptime: string;
  current_channel_name: string;
  current_schedule_end: string;
  current_schedule_id: string;
  is_permanently_scheduled: boolean;
  permanent_schedule_input_id: string;
  permanent_schedule_input_name: string;
}

export interface IOutputsState {
  status: string;
  message: string;
  data: IOutputsStateEntry[];
}

export interface IDestinationsListResponse {
  status: string;
  data: IDestinationListItem[];
}

export interface IDestinationListItem {
  id: string;
  name: string;
  is_permanent_schedule: boolean;
}