import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";
import { IKnowledgeHubArticleShort, IKnowledgeHubTag } from "api";

export default class KnowledgeHubStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    /**
     * Stores
     */
    @observable categories = [] as IKnowledgeHubArticleShort[];
    @observable archivedCategories = [] as IKnowledgeHubArticleShort[];
    @observable items = [] as IKnowledgeHubArticleShort[];
    @observable indexedItems = [] as IKnowledgeHubArticleShort[];
    @observable listingItems = [] as IKnowledgeHubArticleShort[];
    @observable hiddenItems = [] as IKnowledgeHubArticleShort[];
    @observable draftItems = [] as IKnowledgeHubArticleShort[];
    @observable unIndexedItems = [] as IKnowledgeHubArticleShort[];
    @observable archivedItems = [] as IKnowledgeHubArticleShort[];
    @observable tags = [] as IKnowledgeHubTag[];

    /**
     * Set Functions
     */
    @action setCategories = (newCategories: IKnowledgeHubArticleShort[]) => {
        this.categories = newCategories;
    };

    @action setArchivedCategories = (newCategories: IKnowledgeHubArticleShort[]) => {
        this.archivedCategories = newCategories;
    };

    @action setItems = (newItems: IKnowledgeHubArticleShort[]) => {
        this.items = newItems;
    };

    @action setIndexedItems = (newItems: IKnowledgeHubArticleShort[]) => {
        this.indexedItems = newItems;
    };

    @action setListingItems = (newItems: IKnowledgeHubArticleShort[]) => {
        this.listingItems = newItems;
    };

    @action setHiddenItems = (newItems: IKnowledgeHubArticleShort[]) => {
        this.hiddenItems = newItems;
    };

    @action setDraftItems = (newItems: IKnowledgeHubArticleShort[]) => {
        this.draftItems = newItems;
    };

    @action setUnIndexedItems = (newItems: IKnowledgeHubArticleShort[]) => {
        this.unIndexedItems = newItems;
    };

    @action setArchivedItems = (newItems: IKnowledgeHubArticleShort[]) => {
        this.archivedItems = newItems;
    };

    @action setTags = (newTags: IKnowledgeHubTag[]) => {
        this.tags = newTags;
    };
}
