import React, { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "app/common/form/ErrorMessage";
import { IUserTwoFactor, RootStoreContext } from "api";
import { TextField } from "mui-rff";
import { ConfirmTwoFactorDisabled } from "./ConfirmTwoFactorDisabled";
import { Alert, AlertTitle, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export const DisableTwoFactor = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { disableTwoFactor } = rootStore.userStore;
    const { setToken } = rootStore.commonStore;

    return (
        <FinalForm
            onSubmit={(values: IUserTwoFactor) =>
                disableTwoFactor(values)
                    .then((response) => {
                        // changing JWT triggers a user refresh in app.
                        setToken(response.jwt_token);
                        closeDialog();
                        openDialog(<ConfirmTwoFactorDisabled />);
                    })
                    .catch((error) => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Disable Two-Factor Authentication</DialogTitle>
                    <DialogContent>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            <AlertTitle>Are you sure you want to disable Two-Factor Authentication?</AlertTitle>
                            We strongly urge you to reconsider disabling 2FA, as this is the best form of protection we offer at the level
                            of user clearance.
                        </Alert>
                        <TextField fullWidth required name="totp_code" type="text" label="Code" style={{ marginBottom: 10 }} />
                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                closeDialog();
                            }}>
                            Cancel
                        </Button>

                        <LoadingButton
                            type="submit"
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            loading={submitting}
                            variant="contained"
                            color="error">
                            Disable 2FA
                        </LoadingButton>
                    </DialogActions>
                </form>
            )}
        />
    );
};
