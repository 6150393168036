import { Add, CalendarToday, ContentPaste } from "@mui/icons-material";
import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { RootStoreContext } from "api";
import { addHours, addMinutes, format, isBefore, setMinutes } from "date-fns";
import CreateRepeatScheduleForm from "features/scheduler/Components/CreateRepeatScheduleForm";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

interface HourCellProps {
    hour: number;
    date: Date;
    destination_id: string;
    copiedItem?: any;
}

const HourCell: React.FC<HourCellProps> = (props) => {
    const navigate = useNavigate();

    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { setDate } = rootStore.schedulerStore;

    const [selectedDate, setSelectedDate] = React.useState<Date>(setMinutes(props.date, 0));

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        const parentRect = event.currentTarget.getBoundingClientRect();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6
                  }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "60px",
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: (theme) => theme.palette.action.hover,
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.action.hover
                    },
                    position: "relative"
                }}>
                <Box sx={{ width: "100%", height: "100%" }}>
                    <Box
                        onClick={(event) => {
                            setSelectedDate(setMinutes(props.date, 0));
                            handleContextMenu(event);
                        }}
                        onContextMenu={(event) => {
                            setSelectedDate(setMinutes(props.date, 0));
                            handleContextMenu(event);
                        }}
                        sx={{
                            width: "100%",
                            height: "25%",
                            backgroundColor: (theme) =>
                                contextMenu !== null && selectedDate.getTime() === setMinutes(props.date, 0).getTime()
                                    ? theme.palette.action.selected
                                    : undefined,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.action.hover
                            }
                        }}></Box>
                    <Box
                        onClick={(event) => {
                            setSelectedDate(setMinutes(props.date, 15));
                            handleContextMenu(event);
                        }}
                        onContextMenu={(event) => {
                            setSelectedDate(setMinutes(props.date, 15));
                            handleContextMenu(event);
                        }}
                        sx={{
                            width: "100%",
                            height: "25%",
                            backgroundColor: (theme) =>
                                contextMenu !== null && selectedDate.getTime() === setMinutes(props.date, 15).getTime()
                                    ? theme.palette.action.selected
                                    : undefined,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.action.hover
                            }
                        }}></Box>
                    <Box
                        onClick={(event) => {
                            setSelectedDate(setMinutes(props.date, 30));
                            handleContextMenu(event);
                        }}
                        onContextMenu={(event) => {
                            setSelectedDate(setMinutes(props.date, 30));
                            handleContextMenu(event);
                        }}
                        sx={{
                            width: "100%",
                            height: "25%",
                            backgroundColor: (theme) =>
                                contextMenu !== null && selectedDate.getTime() === setMinutes(props.date, 30).getTime()
                                    ? theme.palette.action.selected
                                    : undefined,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.action.hover
                            }
                        }}></Box>
                    <Box
                        onClick={(event) => {
                            setSelectedDate(setMinutes(props.date, 45));
                            handleContextMenu(event);
                        }}
                        onContextMenu={(event) => {
                            setSelectedDate(setMinutes(props.date, 45));
                            handleContextMenu(event);
                        }}
                        sx={{
                            width: "100%",
                            height: "25%",
                            backgroundColor: (theme) =>
                                contextMenu !== null && selectedDate.getTime() === setMinutes(props.date, 45).getTime()
                                    ? theme.palette.action.selected
                                    : undefined,
                            "&:hover": {
                                backgroundColor: (theme) => theme.palette.action.hover
                            }
                        }}></Box>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: (theme) => theme.palette.action.hover,
                        position: "absolute",
                        top: "50%",
                        opacity: 0.5
                    }}></Box>
                <Box
                    sx={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: (theme) => theme.palette.action.hover,
                        position: "absolute",
                        top: "25%",
                        opacity: 0.2
                    }}></Box>
                <Box
                    sx={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: (theme) => theme.palette.action.hover,
                        position: "absolute",
                        top: "75%",
                        opacity: 0.2
                    }}></Box>
            </Box>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <Box
                    sx={{
                        pt: 0,
                        pr: 2,
                        pb: 1,
                        pl: 2,
                        fontWeight: "bold"
                    }}>
                    {format(selectedDate, "EEEE, MMMM d, yyyy, h:mm a")}
                </Box>
                <Divider />
                <MenuItem
                    disabled={isBefore(selectedDate, new Date())}
                    onClick={() => {
                        handleClose();
                        const startTime = selectedDate;
                        const endTime = addHours(selectedDate, 1);
                        // add 1 hour to the end_time
                        //endTime.setHours(endTime.getHours() + 1);

                        openDialog(
                            <CreateRepeatScheduleForm start_date={startTime} end_date={endTime} destination_id={props.destination_id} />
                        );
                    }}>
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText>Add Schedule</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={isBefore(selectedDate, new Date()) || !props.copiedItem}
                    onClick={() => {
                        handleClose();
                        // start_time takes the day from the cell and the time from the copied item
                        const startTime = selectedDate;

                        const calculateCopiedItemDuration = () => {
                            // caluclate the duration of the copied item in minutes
                            // bear in mind that items could cross over to the next day or beyond
                            const start = new Date(props.copiedItem.start_time);
                            const end = new Date(props.copiedItem.end_time);
                            return (end.getTime() - start.getTime()) / 1000 / 60;
                        }

                        const endTime = addMinutes(selectedDate, calculateCopiedItemDuration());

                        openDialog(
                            <CreateRepeatScheduleForm
                                source_id={props.copiedItem.source_id}
                                destination_id={props.destination_id}
                                start_date={startTime}
                                end_date={endTime}
                            />
                        );
                    }}>
                    <ListItemIcon>
                        <ContentPaste />
                    </ListItemIcon>
                    <ListItemText>Paste</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setDate(props.date);
                        navigate("/scheduler");
                    }}>
                    <ListItemIcon>
                        <CalendarToday />
                    </ListItemIcon>
                    <ListItemText>View Day</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default HourCell;
