import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface IIface {
    onChangeAction(selectedValue: string): any;
    showMute?: boolean;
    showUnMute?: boolean;
    showDelete?: boolean;
}

const AlertActionSelector: React.FC<IIface> = ({ onChangeAction, showMute = true, showUnMute = true, showDelete = true }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    endIcon={<ArrowDropDownIcon />}>
                    Please select an action
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {showMute && (
                                        <MenuItem
                                            onClick={() => {
                                                onChangeAction("mute");
                                            }}>
                                            Mute selected
                                        </MenuItem>
                                    )}
                                    {showUnMute && (
                                        <MenuItem
                                            onClick={() => {
                                                onChangeAction("un_mute");
                                            }}>
                                            Un-mute selected
                                        </MenuItem>
                                    )}
                                    {showDelete && (
                                        <MenuItem
                                            onClick={() => {
                                                onChangeAction("delete");
                                            }}>
                                            Delete selected
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};
export default AlertActionSelector;
