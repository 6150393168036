import { Box, Typography } from "@mui/material";
import React from "react";
import { CheckboxField, RadioGroupField, SliderField, TextField } from "components/Forms";

interface Props {
    values: any;
    submitError: any;
    onFieldChange: (field: string, value: any) => void;
}

const ZixiPullOptionsForm: React.FC<Props> = ({ values, submitError, onFieldChange }) => {
    React.useEffect(() => {}, [
        values?.direct_feed_input_details?.zixi_pull_options?.enable_ts_input_smoothing,
        values?.direct_feed_input_details?.zixi_pull_options?.encryption_mode
    ]);

    return (
        <Box sx={{ p: 2, backgroundColor: "rgba(160,160,160,0.05)" }}>
            <Typography variant="h6" gutterBottom component="div">
                Zixi Pull Options
            </Typography>

            <TextField
                name="direct_feed_input_details.zixi_pull_options.input_label"
                label="Input Label"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value.length < 1 || value.length > 150) {
                        return "Name must be between 1 and 150 characters";
                    }
                    if (!/^[a-zA-Z0-9._-\s]*$/.test(value)) {
                        return "Name must contain only valid characters of: a-z, A-Z, 0-9, ., _, -, and space";
                    }
                }}
            />

            <CheckboxField
                name="direct_feed_input_details.zixi_pull_options.enable_ts_input_smoothing"
                label="Enable TS Input Smoothing"
                initialValue={values?.direct_feed_input_details?.zixi_pull_options?.enable_ts_input_smoothing ? true : false}
            />
            <Box sx={{ my: 2 }}></Box>

            {/* Only show the next field if the previous field is true*/}
            {values?.direct_feed_input_details?.zixi_pull_options?.enable_ts_input_smoothing && (
                <>
                    <SliderField
                        name="direct_feed_input_details.zixi_pull_options.input_smoothing_extra_delay"
                        label="Input Smoothing Extra Delay"
                        min={0}
                        max={1250}
                        step={10}
                        required
                        initialValue={
                            values?.direct_feed_input_details?.zixi_pull_options?.input_smoothing_extra_delay
                                ? values?.direct_feed_input_details?.zixi_pull_options?.input_smoothing_extra_delay
                                : 0
                        }
                        validate={(value) => {
                            if (!value && value !== 0) {
                                return "Required";
                            }
                            if (value < 0 || value > 1250) {
                                return "Input Smoothing Extra Delay must be between 0 and 1250";
                            }
                        }}
                    />
                </>
            )}
            <TextField
                name="direct_feed_input_details.zixi_pull_options.input_url"
                label="Input URL"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    if (!/^udp:\/\/(?:[0-9]{1,3}\.){3}[0-9]{1,3}:[0-9]+$/.test(value)) {
                        return "Input URL must start with udp://, followed by a valid IP address, and end with a port number";
                    }
                }}
            />

            {/* if values?.enable_backup then show the backup_input_url field */}
            {values?.enable_backup && (
                <>
                    <TextField
                        name="direct_feed_input_details.zixi_pull_options.backup_input_url"
                        label="Backup Input URL"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            if (!/^udp:\/\/(?:[0-9]{1,3}\.){3}[0-9]{1,3}:[0-9]+$/.test(value)) {
                                return "Backup Input URL must start with udp://, followed by a valid IP address, and end with a port number";
                            }
                            if (value === values?.direct_feed_input_details?.zixi_pull_options?.input_url) {
                                return "Backup Input URL must be different from Input URL";
                            }
                        }}
                    />
                </>
            )}

            {/* channel_name */}

            <TextField
                name="direct_feed_input_details.zixi_pull_options.channel_name"
                label="Channel Name"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value.length < 1 || value.length > 63) {
                        return "Channel Name must be between 1 and 63 characters";
                    }
                    if (!/^[a-zA-Z0-9._-\s]*$/.test(value)) {
                        return "Channel Name must contain only valid characters of: a-z, A-Z, 0-9, ., _, -, and space";
                    }
                }}
            />
            {/* password */}
            <TextField
                name="direct_feed_input_details.zixi_pull_options.password"
                label="Password"
                validate={(value) => {
                    if (value && (value.length < 1 || value.length > 63)) {
                        return "Password must be between 1 and 63 characters";
                    }
                }}
                helperText={() => {
                    return "Optional";
                }}
            />
            <RadioGroupField
                name="direct_feed_input_details.zixi_pull_options.encryption_mode"
                label="Encryption Mode"
                row
                required
                initialValue={
                    values?.direct_feed_input_details?.zixi_pull_options?.encryption_mode
                        ? values?.direct_feed_input_details?.zixi_pull_options?.encryption_mode
                        : "None"
                }
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}
                options={[
                    { label: "None", value: "None" },
                    { label: "AES 128", value: "AES 128" },
                    { label: "AES 192", value: "AES 192" },
                    { label: "AES 256", value: "AES 256" }
                ]}
            />
            {/* Only show the next field if the previous field is not "none"*/}
            {values?.direct_feed_input_details?.zixi_pull_options?.encryption_mode !== "None" && (
                <>
                    <TextField
                        name="direct_feed_input_details.zixi_pull_options.encryption_key"
                        label="Encryption Key"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            if (value.length < 1 || value.length > 64) {
                                return "Encryption Key must be between 1 and 64 characters";
                            }
                        }}
                    />
                </>
            )}
            {/* max_latency */}
            <TextField
                name="direct_feed_input_details.zixi_pull_options.max_latency"
                label="Max Latency"
                type="number"
                required
                initialValue={
                    values?.direct_feed_input_details?.zixi_pull_options?.max_latency
                        ? values?.direct_feed_input_details?.zixi_pull_options?.max_latency
                        : 6000
                }
                validate={(value) => {
                    if (!value && value !== 0) {
                        return "Required";
                    }
                    if (value < 0) {
                        return "Max Latency must be 0 or greater";
                    }
                }}
                parse
            />
            {/* remote_id */}
            <TextField
                name="direct_feed_input_details.zixi_pull_options.remote_id"
                label="Remote ID"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value.length < 1 || value.length > 128) {
                        return "Remote ID must be between 1 and 128 characters";
                    }
                }}
            />
        </Box>
    );
};

export default ZixiPullOptionsForm;
