import React from "react";
import { Stack, IconButton, Tooltip, Button } from "@mui/material";
import { observer } from "mobx-react";
import { EmailProvider, EnvironmentProvider } from "components";
import { Archive, Delete, Edit } from "@mui/icons-material";
import { useConstants } from "hooks";
import { KnowledgeHubType } from "api";

export interface IKnowledgeHubItemActions {
    item: any;
    editText?: string;
    editItem?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, type: KnowledgeHubType) => void;
    deleteText?: string;
    deleteItem?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string, isCategory?: boolean) => void;
    archiveText?: string;
    archiveItem?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string, isCategory?: boolean) => void;
}

/**
 * `KnowledgeHubItemActions` renders action buttons for editing and deleting a knowledge hub item.
 * It conditionally displays edit and delete buttons based on the provided callbacks.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.item - The item object containing details like id and title.
 * @param {Function} [props.editItem] - Optional callback function to be called when the edit button is clicked.
 * Takes an event and the item's id as arguments.
 * @param {Function} [props.deleteItem] - Optional callback function to be called when the delete button is clicked.
 * Takes an event, the item's id, and the item's title as arguments.
 * @param {string} [props.editText] - Optional text to display on the edit button.
 * @param {string} [props.deleteText] - Optional text to display on the delete button.
 * @param {string} [props.archiveText] - Optional text to display on the archive button.
 * @param {Function} [props.archiveItem] - Optional callback function to be called when the archive button is clicked.
 * Takes an event, the item's id, and the item's title as arguments.
 * @returns {React.ReactElement | null} A React Element containing the action buttons, or null if no actions are provided.
 */
export const KnowledgeHubItemActions: React.FC<IKnowledgeHubItemActions> = observer(function KnowledgeHubItemActions({
    item,
    editText,
    editItem,
    deleteText,
    deleteItem,
    archiveText,
    archiveItem
}) {
    const { constants } = useConstants();

    if (!editItem && !deleteItem && !archiveItem) {
        return <></>;
    }

    return (
        <EnvironmentProvider env="staging">
            <EmailProvider
                acceptedEmails={[...constants.cerberus.emails.frontend, constants.cerberus.emails.Brad, constants.cerberus.emails.Chris]}>
                <Stack spacing={1} direction="row" alignItems="center">
                    {editItem && (
                        <>
                            {editText ? (
                                <Button
                                    startIcon={<Edit />}
                                    variant="contained"
                                    onClick={(e) => editItem && editItem(e, item.id, item.type)}>
                                    {editText}
                                </Button>
                            ) : (
                                <Tooltip title="Edit">
                                    <IconButton onClick={(e) => editItem && editItem(e, item.id, item.type)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {deleteItem && (
                        <>
                            {deleteText ? (
                                <Button
                                    startIcon={<Delete />}
                                    variant="contained"
                                    onClick={(e) =>
                                        deleteItem && deleteItem(e, item.id, item.title, item.type === KnowledgeHubType.Category)
                                    }>
                                    {deleteText}
                                </Button>
                            ) : (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={(e) =>
                                            deleteItem && deleteItem(e, item.id, item.title, item.type === KnowledgeHubType.Category)
                                        }>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {archiveItem && (
                        <>
                            {deleteText ? (
                                <Button
                                    startIcon={<Archive />}
                                    variant="contained"
                                    onClick={(e) =>
                                        archiveItem && archiveItem(e, item.id, item.title, item.type === KnowledgeHubType.Category)
                                    }>
                                    {archiveText}
                                </Button>
                            ) : (
                                <Tooltip title="Archive">
                                    <IconButton
                                        onClick={(e) =>
                                            archiveItem && archiveItem(e, item.id, item.title, item.type === KnowledgeHubType.Category)
                                        }>
                                        <Archive />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                </Stack>
            </EmailProvider>
        </EnvironmentProvider>
    );
});

export default KnowledgeHubItemActions;
