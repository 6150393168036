import { Switch } from "@mui/material";
import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";

export const SilentNotificationsSwitch: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, updateUserPreferences } = rootStore.userStore;

    return (
        <Switch
            edge="end"
            size="small"
            checked={user?.user_preferences?.silent_notifications}
            onChange={(event) => {
                user?.user_preferences?.silent_notifications
                    ? updateUserPreferences({ silent_notifications: false })
                    : updateUserPreferences({ silent_notifications: true });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }}
        />
    );
};

export default observer(SilentNotificationsSwitch);
