import React, { useContext, useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { Box } from "@mui/material";
import { agent, RootStoreContext } from "api";

interface Props {
    multiviewer_id: string;
}

const VideoPlayer: React.FC<Props> = ({ multiviewer_id }) => {

    const rootStore = useContext(RootStoreContext);
    const jwtToken = rootStore.commonStore.token;
    const apiUrl = process.env.REACT_APP_API_URL;

    const videoUrl = `${apiUrl}/multi-viewers/${multiviewer_id}/stream/enc_1.m3u8`;
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (Hls.isSupported() && videoRef.current) {
            const hls = new Hls({
                xhrSetup: (xhr, url) => {
                    xhr.setRequestHeader("access-token", `${jwtToken}`);
                }
            });

            hls.loadSource(videoUrl);
            hls.attachMedia(videoRef.current);

            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.play();
                }
                }, 100);
            });

            return () => {
                hls.destroy();
            };
        }
    }, [videoUrl, jwtToken]);

    return (
        <Box
            sx={{
                aspectRatio: "16/9",
                position: "relative",
                overflow: "hidden",
                "& video": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                }
            }}
        >
            <video ref={videoRef} controls autoPlay={false} />
        </Box>
    );
};

export default VideoPlayer;
