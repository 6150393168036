import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import VideocamIcon from "@mui/icons-material/Videocam";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import ErrorIcon from "@mui/icons-material/Error";
import { agent, RootStoreContext } from "api";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { format } from "date-fns";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import useOnScreen from "../../../hooks/UseOnScreen";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SrtListenerDetails from "./SrtListenerDetails";
import SrtCallerDetails from "./SrtCallerDetails";
import RoleProvider from "components/RoleProvider";
import { useNavigate } from "react-router-dom";
import { ResModel } from "resclient";
import LinkIcon from "@mui/icons-material/Link";
import { observer } from "mobx-react-lite";
import InfoItemVertical from "components/InfoItemVertical";
import ConfirmationDialog from "components/ConfirmationDialog";
import { Autorenew, Info } from "@mui/icons-material";

interface Props {
    rid: string;
    display_search?: string;
    display_type?: string;
    display_cloud_provider?: string;
    display_status?: string;
}

const BackupInstanceDetails: React.FC<Props> = ({ rid, display_search, display_type, display_cloud_provider, display_status }) => {
    return (
        <PackagerItem
            rid={rid}
            display_search={display_search}
            display_type={display_type}
            display_cloud_provider={display_cloud_provider}
            display_status={display_status}
        />
    );
};

export const PackagerItem: React.FC<Props> = ({ rid, display_search, display_type, display_cloud_provider, display_status }) => {
    const navigate = useNavigate();
    const ref = React.useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const [stateVisible, setStateVisible] = React.useState<boolean>(true);

    const theme = useTheme();
    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [wsData, setWsData] = React.useState<any>(null);
    const [apiData, setApiData] = React.useState<any>(null);
    const [thumbnailData, setThumbnailData] = React.useState<any>(null);
    const [liveData, setLiveData] = React.useState<any>(null);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [idCopied, setIdCopied] = React.useState<boolean>(false);
    const [dnsCopied, setDnsCopied] = React.useState<boolean>(false);
    const [sourceTabValue, setSourceTabValue] = React.useState("1");
    const [encodingTabValue, setEncodingTabValue] = React.useState("1");
    const [outputTabValue, setOutputTabValue] = React.useState("1");

    const handleSourceTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setSourceTabValue(newValue);
    };
    const handleEncodingTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setEncodingTabValue(newValue);
    };
    const handleOutputTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setOutputTabValue(newValue);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const calculateIsHidden = () => {
        const isNameMatch = !display_search || wsData.name.toLowerCase().includes(display_search.toLowerCase());
        const isInputFeedSourceMatch = display_type === "all" || display_type === wsData?.input_feed_source;
        const isCloudProviderMatch = display_cloud_provider === "all" || display_cloud_provider === wsData?.cloud_provider;
        const isStatusMatch = display_status === "all" || display_status === wsData?.status;

        return !(isNameMatch && isInputFeedSourceMatch && isCloudProviderMatch && isStatusMatch);
    };

    const cloudProviderName = (cloudProvider: string) => {
        switch (cloudProvider) {
            case "aws":
                return "Amazon Web Services";
            case "azure":
                return "Microsoft Azure";
            case "gcp":
                return "Google Cloud Platform";
            case "do":
                return "Digital Ocean";
            default:
                return cloudProvider;
        }
    };

    const toCapitalize = (str: string) => {
        if (!str) {
            return "";
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const getThumbnail = async (id: string) => {
        await agent
            .getCleanInstance()
            .get(`source_transcoder/${id}/thumbnail`)
            .then((data: any) => {
                //console.log(data.data);
                setLiveData(data.data);
                setThumbnailData(data.data.thumbnail);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const ConfirmSuccessDialog = ({ message }) => {
        return (
            <>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="text.secondary">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog()}>Close</Button>
                </DialogActions>
            </>
        );
    };

    const ConfirmStopDialog = () => {
        const [error, setError] = React.useState<any>();
        const [submitting, setSubmitting] = React.useState<boolean>(false);

        return (
            <>
                <DialogTitle>Confirm Stop</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="text.secondary">
                        Are you sure you want to stop this packager?
                    </Typography>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error.data?.message}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog()}>Cancel</Button>
                    <LoadingButton
                        variant="contained"
                        color="error"
                        loading={submitting}
                        onClick={() => {
                            setSubmitting(true);
                            agent.Packager.stopPushPackager(wsData?.id)
                                .then(() => {
                                    setSubmitting(false);
                                    openDialog(<ConfirmSuccessDialog message="Package is now stopping. This may take a few minutes." />);
                                })
                                .catch((err) => {
                                    setSubmitting(false);
                                    console.log(err);
                                    setError(err);
                                });
                        }}>
                        Stop
                    </LoadingButton>
                </DialogActions>
            </>
        );
    };

    const ConfirmStartDialog = () => {
        const [error, setError] = React.useState<any>();
        const [submitting, setSubmitting] = React.useState<boolean>(false);

        return (
            <>
                <DialogTitle>Confirm Start</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="text.secondary">
                        Are you sure you want to start this packager?
                    </Typography>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error.data?.message}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog()}>Cancel</Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={submitting}
                        onClick={() => {
                            setSubmitting(true);
                            agent.Packager.startPushPackager(wsData?.id)
                                .then(() => {
                                    setSubmitting(false);
                                    openDialog(<ConfirmSuccessDialog message="Package is now starting. This may take a few minutes." />);
                                })
                                .catch((err) => {
                                    setSubmitting(false);
                                    console.log(err);
                                    setError(err);
                                });
                        }}>
                        Start
                    </LoadingButton>
                </DialogActions>
            </>
        );
    };

    const ConfirmDeleteDialog = () => {
        const [error, setError] = React.useState<any>();
        const [submitting, setSubmitting] = React.useState<boolean>(false);

        return (
            <>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="text.secondary">
                        Are you sure you want to delete this packager?
                    </Typography>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error.data?.message}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog()}>Cancel</Button>
                    <LoadingButton
                        variant="contained"
                        color="error"
                        loading={submitting}
                        onClick={() => {
                            setSubmitting(true);
                            agent.Packager.deletePushPackager(wsData?.id)
                                .then(() => {
                                    setSubmitting(false);
                                    openDialog(<ConfirmSuccessDialog message="Package is now deleting. This may take a few minutes." />);
                                })
                                .catch((err) => {
                                    setSubmitting(false);
                                    console.log(err);
                                    setError(err);
                                });
                        }}>
                        Delete
                    </LoadingButton>
                </DialogActions>
            </>
        );
    };

    React.useEffect(() => {
        let intervalId: NodeJS.Timeout;
        console.log(rid);
        stateVisible &&
            rootStore.resClientStore.clientConnected &&
            rootStore.resClientStore.client.get(rid).then((res: ResModel | any) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });
                console.log(res.toJSON());
                agent
                    .getCleanInstance()
                    .get(`push_packagers/${res.id}`)
                    .then((data) => {
                        //console.log(data.data.data);
                        setApiData(data.data.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                !thumbnailData && res.status === "running" && getThumbnail(res.id);
                intervalId = setInterval(() => {
                    if (res.status === "running" && stateVisible) {
                        getThumbnail(res.id);
                    } else {
                        clearInterval(intervalId);
                    }
                }, 2000);
                setWsData(res);
                //console.log(res);
            });
        return () => {
            clearInterval(intervalId);
            setThumbnailData(null);
            //setWsData(null);
        };
    }, [rootStore.resClientStore.client, rootStore.resClientStore.clientConnected, rid]);

    React.useEffect(() => {}, [updateDate]);

    React.useEffect(() => {
        if (isVisible) {
            //console.log("visible");
            setStateVisible(true);
        }
        if (!isVisible) {
            //console.log("not visible");
            setStateVisible(false);
        }
    }, [isVisible]);

    return (
        <>
            {wsData && (
                <>
                    <Accordion
                        ref={ref}
                        sx={{
                            mb: 2,
                            mt: wsData?.main_instance_id ? -2 : 0,
                            //mx: wsData?.main_instance_id ? 2 : 0,
                            backgroundImage: "none !important",
                            opacity: wsData?.main_instance_id ? 0.9 : 1,
                            //border: wsData?.main_instance_id ? "4px solid " + theme.palette.action.selected : "none",
                            ":before": { display: "none !important" }
                        }}
                        hidden={calculateIsHidden()}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            sx={{
                                backgroundColor: wsData?.main_instance_id ? theme.palette.action.selected : undefined,
                                backgroundImage: "none !important",
                                overflowY: "visible"
                            }}>
                            {wsData?.main_instance_id && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "3px",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}>
                                    <LinkIcon />
                                </Box>
                            )}
                            <Box sx={{ width: "100%" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%"
                                    }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            width: "100%"
                                        }}>
                                        <Avatar
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                mr: 1,
                                                backgroundColor:
                                                    liveData?.alarms?.filter((alarm: any) => alarm.is_open && alarm.level === "critical")
                                                        .length > 0
                                                        ? theme.palette.error.main
                                                        : liveData?.alarms?.filter(
                                                              (alarm: any) => alarm.is_open && alarm.level === "warning"
                                                          ).length > 0
                                                        ? theme.palette.warning.main
                                                        : wsData?.status === "running"
                                                        ? theme.palette.success.main
                                                        : wsData?.status === "crashed"
                                                        ? theme.palette.error.main
                                                        : undefined,
                                                color: "white"
                                            }}>
                                            {liveData?.alarms?.filter((alarm: any) => alarm.is_open).length > 0 ? (
                                                <NotificationsActiveIcon />
                                            ) : wsData?.status === "running" ? (
                                                <PlayArrowIcon />
                                            ) : wsData?.status === "stopped" ? (
                                                <StopIcon />
                                            ) : wsData?.status === "crashed" ? (
                                                <ErrorIcon />
                                            ) : (
                                                <HourglassFullIcon />
                                            )}
                                        </Avatar>
                                        <Box>
                                            <Box
                                                sx={{ display: "flex", alignItems: "center" }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}>
                                                <Typography fontSize={"1.1rem"} lineHeight="1.15rem">
                                                    {wsData?.name}
                                                    <CopyToClipboard
                                                        text={wsData?.id}
                                                        onCopy={(event) => {
                                                            console.log(event);
                                                            setIdCopied(true);
                                                            setTimeout(() => {
                                                                setIdCopied(false);
                                                            }, 2000);
                                                        }}>
                                                        <span title="Copy ID to clipboard">
                                                            <ContentCopyIcon
                                                                sx={{
                                                                    cursor: "pointer",
                                                                    fontSize: "11px",
                                                                    marginLeft: "5px",
                                                                    marginTop: "6px",
                                                                    opacity: 0.7,
                                                                    ":hover": { opacity: 1 }
                                                                }}
                                                            />
                                                        </span>
                                                    </CopyToClipboard>
                                                </Typography>

                                                <Tooltip open={idCopied} title="ID Copied" placement="right">
                                                    <Box></Box>
                                                </Tooltip>
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                <span style={{ textTransform: "capitalize" }}>{wsData?.status}</span>
                                                {wsData?.status === "running" && " (Service " + wsData?.service_status + ") "}
                                                {liveData?.alarms?.filter((alarm: any) => alarm.is_open).length > 0 &&
                                                    " - " + liveData?.alarms?.filter((alarm: any) => alarm.is_open).length + " alarms"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            aria-label="settings"
                                            id="demo-customized-button"
                                            aria-controls={open ? "demo-customized-menu" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? "true" : undefined}
                                            onClick={(e) => handleClick(e)}
                                            disabled={wsData?.main_instance_id ? true : false}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                "aria-labelledby": "demo-customized-button"
                                            }}
                                            sx={{ minWidth: 300 }}
                                            anchorEl={anchorEl}
                                            elevation={0}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right"
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right"
                                            }}
                                            open={open}
                                            onClose={handleClose}>
                                            <RoleProvider roles={["is_dev"]}>
                                                <MenuItem
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleClose();
                                                        navigate("/packager/" + wsData?.id);
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <Info />
                                                    </ListItemIcon>
                                                    Details
                                                </MenuItem>
                                            </RoleProvider>

                                            <MenuItem
                                                disabled={wsData?.status !== "running"}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleClose();
                                                    navigate("/packager/edit/" + wsData?.id);
                                                }}
                                                disableRipple>
                                                <ListItemIcon>
                                                    <EditIcon />
                                                </ListItemIcon>
                                                Edit
                                            </MenuItem>

                                            {wsData?.status !== "stopped" && (
                                                <MenuItem
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        openDialog(<ConfirmStopDialog />);
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <StopIcon />
                                                    </ListItemIcon>
                                                    Stop
                                                </MenuItem>
                                            )}
                                            {wsData?.status === "stopped" && (
                                                <MenuItem
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        openDialog(<ConfirmStartDialog />);
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <PlayArrowIcon />
                                                    </ListItemIcon>
                                                    Start
                                                </MenuItem>
                                            )}
                                            <Divider />
                                            {/* Restart */}
                                            <MenuItem
                                                disabled={wsData?.status !== "running"}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    openDialog(
                                                        <ConfirmationDialog
                                                            title="Restart Push Packager"
                                                            message="Are you sure you want to restart this push packager?"
                                                            confirmButtonText="Restart"
                                                            onConfirm={async () => {
                                                                await agent.NeaLive.restartTranscoder(wsData?.id);
                                                            }}
                                                        />
                                                    );
                                                    handleClose();
                                                }}
                                                disableRipple>
                                                <ListItemIcon>
                                                    <Autorenew />
                                                </ListItemIcon>
                                                Restart Service
                                            </MenuItem>
                                            {wsData.service_status === "stopped" && (
                                                <MenuItem
                                                    disabled={wsData?.status !== "running"}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                onConfirm={async () => {
                                                                    await agent.Packager.startService(wsData?.id);
                                                                }}
                                                                title="Start Service"
                                                                message="Are you sure you want to start the service?"
                                                                confirmButtonText="Start"
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <PlayArrowIcon />
                                                    </ListItemIcon>
                                                    Start Service
                                                </MenuItem>
                                            )}
                                            {wsData.service_status === "running" && (
                                                <MenuItem
                                                    disabled={wsData?.status !== "running"}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                onConfirm={async () => {
                                                                    await agent.Packager.stopService(wsData?.id);
                                                                }}
                                                                title="Stop Service"
                                                                message="Are you sure you want to stop the service?"
                                                                confirmButtonText="Stop"
                                                                confirmButtonColor="error"
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <StopIcon />
                                                    </ListItemIcon>
                                                    Stop Service
                                                </MenuItem>
                                            )}
                                            <Divider />
                                            <MenuItem
                                                disableRipple
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    openDialog(<ConfirmDeleteDialog />);
                                                    handleClose();
                                                }}>
                                                <ListItemIcon>
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Box>
                                <Divider sx={{ my: 2 }} />
                                <Box>
                                    <Stack direction="row" spacing={2} sx={{ mt: "6px", alignItems: "center" }}>
                                        <Box
                                            sx={{
                                                width: 160,
                                                aspectRatio: "16/9",
                                                flexShrink: 0,
                                                marginRight: 1,
                                                backgroundColor: "rgba(160,160,160,0.1)",
                                                borderRadius: 1,
                                                overflow: "hidden"
                                            }}>
                                            {thumbnailData && <img src={thumbnailData} style={{ width: "100%", height: "100%" }} />}
                                        </Box>
                                        <Box
                                            sx={{
                                                width: 200,
                                                marginLeft: 3,
                                                paddingLeft: 2,
                                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                                            }}>
                                            <InfoItemVertical
                                                title="Instance"
                                                value={wsData?.main_instance_id ? "Backup" : "Main"}
                                                containerSx={{ mb: 1 }}
                                            />
                                            <InfoItemVertical
                                                title={wsData?.main_instance_id ? "Main Instance" : "Backup Instance"}
                                                value={
                                                    wsData?.main_instance_id
                                                        ? wsData?.main_instance_name
                                                        : wsData?.backup_instance_id
                                                        ? wsData?.backup_instance_name
                                                        : "-"
                                                }
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                width: 160,
                                                marginLeft: 3,
                                                paddingLeft: 2,
                                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                                            }}>
                                            <InfoItemVertical
                                                title="Source Type"
                                                value={toCapitalize(wsData?.input_feed_source)}
                                                containerSx={{ mb: 1 }}
                                            />
                                            <InfoItemVertical
                                                title="Cloud Provider"
                                                value={wsData?.cloud_provider.toUpperCase() + " (" + wsData?.region + ")"}
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                width: 250,
                                                marginLeft: 3,
                                                paddingLeft: 2,
                                                borderLeft: "1px solid rgba(160,160,160,0.2)"
                                            }}>
                                            <InfoItemVertical
                                                title="DNS Name"
                                                value={
                                                    !wsData.dns_name
                                                        ? "-"
                                                        : process.env.REACT_APP_CLIENT_NAME === "ateme"
                                                        ? wsData?.dns_name?.replace("livelink.video", "ateme.plus")
                                                        : wsData?.dns_name
                                                }
                                                value_type="copy"
                                                containerSx={{ mb: 1 }}
                                            />
                                            <InfoItemVertical
                                                title="Public IP Address"
                                                value={wsData?.public_ip ? wsData.public_ip : "-"}
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ px: 0 }}>
                            <CardContent sx={{ paddingTop: 0 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} sx={{ display: "flex", alignItems: "stretch" }}>
                                        <Card
                                            sx={{
                                                backgroundColor: "rgba(160,160,160,0.1)",
                                                width: "100%",
                                                borderTopColor:
                                                    liveData?.alarms?.filter(
                                                        (alarm) => alarm.section === "input" && alarm.level === "critical" && alarm.is_open
                                                    ).length > 0
                                                        ? theme.palette.error.main
                                                        : liveData?.alarms?.filter(
                                                              (alarm) =>
                                                                  alarm.section === "input" && alarm.level === "warning" && alarm.is_open
                                                          ).length > 0
                                                        ? theme.palette.warning.main
                                                        : "rgba(160,160,160,0)",
                                                borderTopWidth: "4px",
                                                borderTopStyle: "solid"
                                            }}>
                                            <CardHeader
                                                title="Source"
                                                subheader={
                                                    <span style={{ textTransform: "capitalize" }}>
                                                        {liveData?.input?.status_ok ? "Status OK" : "Status Not OK"}
                                                    </span>
                                                }
                                                avatar={
                                                    <Avatar
                                                        sx={{
                                                            color: "white",
                                                            backgroundColor:
                                                                liveData?.alarms?.filter(
                                                                    (alarm) =>
                                                                        alarm.section === "input" &&
                                                                        alarm.level === "critical" &&
                                                                        alarm.is_open
                                                                ).length > 0
                                                                    ? theme.palette.error.main
                                                                    : liveData?.alarms?.filter(
                                                                          (alarm) =>
                                                                              alarm.section === "input" &&
                                                                              alarm.level === "warning" &&
                                                                              alarm.is_open
                                                                      ).length > 0
                                                                    ? theme.palette.warning.main
                                                                    : liveData?.input?.status_ok === true
                                                                    ? theme.palette.success.main
                                                                    : undefined
                                                        }}>
                                                        <VideocamIcon />
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton
                                                        onClick={() => {
                                                            navigate("/packager/edit/" + wsData?.id);
                                                        }}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                }
                                            />
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    height: "calc(100% - 72px)",
                                                    minHeight: 350
                                                }}>
                                                <TabContext value={sourceTabValue}>
                                                    <Box>
                                                        <TabList onChange={handleSourceTabChange} aria-label="source details tabs">
                                                            <Tab label="Info" value="1" />
                                                            <Tab label="Analysis" value="2" />
                                                            <Tab label="Statistics" value="3" />
                                                            <Tab
                                                                label={
                                                                    "Alarms (" +
                                                                    (liveData?.alarms?.filter(
                                                                        (alarm) => alarm.section === "input" && alarm.is_open
                                                                    ).length || "0") +
                                                                    ")"
                                                                }
                                                                value="4"
                                                            />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">Source Type</Typography>
                                                            <Typography sx={{ textTransform: "capitalize" }}>
                                                                {apiData?.transcode_parameters?.input_feed_source}
                                                            </Typography>
                                                        </Box>
                                                        {apiData?.transcode_parameters.input_feed_source === "direct" && (
                                                            <>
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                                    <Typography fontWeight="bold">Direct Source Type</Typography>
                                                                    <Typography>
                                                                        {apiData?.transcode_parameters.direct_feed_input_details.input_type
                                                                            ?.split("_")
                                                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                            .join(" ")}
                                                                    </Typography>
                                                                </Box>
                                                                {apiData.transcode_parameters.direct_feed_input_details.input_type ===
                                                                    "srt_listener" && (
                                                                    <SrtListenerDetails
                                                                        data={
                                                                            apiData.transcode_parameters.direct_feed_input_details
                                                                                .srt_listener_options
                                                                        }
                                                                    />
                                                                )}
                                                                {apiData.transcode_parameters.direct_feed_input_details.input_type ===
                                                                    "srt_caller" && (
                                                                    <SrtCallerDetails
                                                                        data={
                                                                            apiData.transcode_parameters.direct_feed_input_details
                                                                                .srt_caller_options
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </TabPanel>
                                                    <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">Current Continuity Count Errors</Typography>
                                                            <Typography>
                                                                {liveData?.input?.etr_statistics.continuity_count_errors}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">Current Continuity Count Failover</Typography>
                                                            <Typography>
                                                                {liveData?.input?.etr_statistics.continuity_count_failover}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">Total Continuity Count Errors</Typography>
                                                            <Typography>
                                                                {liveData?.input?.etr_statistics.total_continuity_count_errors}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">PAT Missing Errors</Typography>
                                                            <Typography>{liveData?.input?.etr_statistics.pat_missing_errors}</Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">PMT Missing Errors</Typography>
                                                            <Typography>{liveData?.input?.etr_statistics.pmt_missing_errors}</Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">Sync Byte Loss Errors</Typography>
                                                            <Typography>{liveData?.input?.etr_statistics.sync_byte_loss_errors}</Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                                                            <Typography fontWeight="bold">TS Sync Loss Errors</Typography>
                                                            <Typography>{liveData?.input?.etr_statistics.ts_sync_losserrors}</Typography>
                                                        </Box>
                                                    </TabPanel>
                                                    <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <>
                                                            {liveData?.input?.statistics?.ack_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Ack Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.ack_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.arq_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Arq Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.arq_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.drop_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Drop Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.drop_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.fec_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Fec Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.fec_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.fec_recovered !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Fec Recovered</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.fec_recovered}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.input_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Input Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.input_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.latency !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Latency</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.latency}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.loss_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Loss Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.loss_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.nack_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Nack Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.nack_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.rate !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Rate</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.rate}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.rcv_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Rcv Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.rcv_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.retrans_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Retrans Packets</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.retrans_packets}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.rtt !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Rtt</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.rtt}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.ts_packet_per_ip_packet !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Ts Packet Per Ip Packet</Typography>
                                                                    <Typography>
                                                                        {liveData?.input?.statistics?.ts_packet_per_ip_packet}
                                                                    </Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.ts_packet_size !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Ts Packet Size</Typography>
                                                                    <Typography>{liveData?.input?.statistics?.ts_packet_size}</Typography>
                                                                </Box>
                                                            )}

                                                            {liveData?.input?.statistics?.undecrypt_packets !== null && (
                                                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <Typography fontWeight="bold">Undecrypt Packets</Typography>
                                                                    <Typography>
                                                                        {liveData?.input?.statistics?.undecrypt_packets}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </>
                                                    </TabPanel>
                                                    <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {liveData?.alarms?.filter((alarm) => alarm.section === "input" && alarm.is_open)
                                                            .length > 0 &&
                                                            liveData?.alarms
                                                                ?.filter((alarm) => alarm.section === "input" && alarm.is_open)
                                                                .map((alarm: any, index: any) => {
                                                                    return (
                                                                        <Box
                                                                            key={index}
                                                                            sx={{
                                                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                                                borderRadius: 1,
                                                                                padding: 1,
                                                                                marginBottom: 2,
                                                                                borderLeftWidth: "6px",
                                                                                borderLeftStyle: "solid",
                                                                                borderLeftColor:
                                                                                    alarm.level === "critical"
                                                                                        ? theme.palette.error.main
                                                                                        : theme.palette.warning.main
                                                                            }}>
                                                                            <Typography fontSize={12}>
                                                                                {format(new Date(alarm.start_time), "dd/MM/yyyy HH:mm:ss")}
                                                                            </Typography>
                                                                            <Typography fontWeight="bold">
                                                                                {alarm.name} -{" "}
                                                                                <span style={{ textTransform: "capitalize" }}>
                                                                                    {alarm.level}
                                                                                </span>
                                                                            </Typography>
                                                                            <Typography fontSize={12}>{alarm.description}</Typography>
                                                                        </Box>
                                                                    );
                                                                })}
                                                    </TabPanel>
                                                </TabContext>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: "flex", alignItems: "stretch" }}>
                                        <Card
                                            sx={{
                                                backgroundColor: "rgba(160,160,160,0.1)",
                                                width: "100%",
                                                borderTopColor:
                                                    liveData?.alarms?.filter(
                                                        (alarm) =>
                                                            alarm.section === "transcoding" && alarm.level === "critical" && alarm.is_open
                                                    ).length > 0
                                                        ? theme.palette.error.main
                                                        : liveData?.alarms?.filter(
                                                              (alarm) =>
                                                                  alarm.section === "transcoding" &&
                                                                  alarm.level === "warning" &&
                                                                  alarm.is_open
                                                          ).length > 0
                                                        ? theme.palette.warning.main
                                                        : "rgba(160,160,160,0)",
                                                borderTopWidth: "4px",
                                                borderTopStyle: "solid"
                                            }}>
                                            <CardHeader
                                                title="Encoding"
                                                subheader={
                                                    <span style={{ textTransform: "capitalize" }}>
                                                        {liveData?.transcoding?.status_ok ? "Status OK" : "Status Not OK"}
                                                    </span>
                                                }
                                                avatar={
                                                    <Avatar
                                                        sx={{
                                                            color: "white",
                                                            backgroundColor:
                                                                liveData?.alarms?.filter(
                                                                    (alarm) =>
                                                                        alarm.section === "transcoding" &&
                                                                        alarm.level === "critical" &&
                                                                        alarm.is_open
                                                                ).length > 0
                                                                    ? theme.palette.error.main
                                                                    : liveData?.alarms?.filter(
                                                                          (alarm) =>
                                                                              alarm.section === "transcoding" &&
                                                                              alarm.level === "warning" &&
                                                                              alarm.is_open
                                                                      ).length > 0
                                                                    ? theme.palette.warning.main
                                                                    : liveData?.transcoding?.status_ok === true
                                                                    ? theme.palette.success.main
                                                                    : undefined
                                                        }}>
                                                        <AccountTreeIcon />
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton
                                                        onClick={() => {
                                                            navigate("/packager/edit/" + wsData?.id);
                                                        }}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                }
                                            />
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    height: "calc(100% - 72px)",
                                                    minHeight: 350
                                                }}>
                                                <TabContext value={encodingTabValue}>
                                                    <Box>
                                                        <TabList onChange={handleEncodingTabChange} aria-label="source details tabs">
                                                            <Tab label="Info" value="1" />
                                                            <Tab label="Tracks" value="2" />
                                                            <Tab
                                                                label={
                                                                    "Alarms (" +
                                                                    (liveData?.alarms?.filter(
                                                                        (alarm: any) => alarm.section === "transcoding" && alarm.is_open
                                                                    ).length || "0") +
                                                                    ")"
                                                                }
                                                                value="3"
                                                            />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <Box>
                                                            <Box
                                                                sx={{
                                                                    width: 80,
                                                                    height: 45,
                                                                    marginBottom: 4,
                                                                    marginRight: 2,
                                                                    flexShrink: 0
                                                                }}>
                                                                <img src={thumbnailData} style={{ width: "100%", height: "100%" }} />
                                                            </Box>
                                                            <Box sx={{ width: "100%" }}>
                                                                {liveData?.encoding_status && (
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <Typography fontWeight="bold">Encoding Status</Typography>
                                                                        <Typography>{liveData.encoding_status}</Typography>
                                                                    </Box>
                                                                )}
                                                                {apiData?.transcode_parameters?.global_track_configuration
                                                                    ?.chunk_period && (
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <Typography fontWeight="bold">Chunk Period:&nbsp;</Typography>
                                                                        <Typography>
                                                                            {
                                                                                apiData?.transcode_parameters?.global_track_configuration
                                                                                    ?.chunk_period
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                {apiData?.transcode_parameters?.global_track_configuration
                                                                    ?.latency_mode && (
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <Typography fontWeight="bold">Latency Mode:&nbsp;</Typography>
                                                                        <Typography>
                                                                            {
                                                                                apiData?.transcode_parameters?.global_track_configuration
                                                                                    ?.latency_mode
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                {wsData?.service_config_version && (
                                                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <Typography fontWeight="bold">
                                                                            Service Config Version:&nbsp;
                                                                        </Typography>
                                                                        <Typography>{wsData?.service_config_version}</Typography>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </TabPanel>
                                                    <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <Box>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    sx={{ backgroundColor: "rgba(160,160,160,0.0)" }}
                                                                    expandIcon={<ExpandMoreIcon />}>
                                                                    Video Tracks (
                                                                    {
                                                                        apiData?.transcode_parameters?.individual_track_configuration
                                                                            ?.video_tracks?.length
                                                                    }
                                                                    )
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <>
                                                                        {apiData?.transcode_parameters?.individual_track_configuration?.video_tracks?.map(
                                                                            (track: any, index: any) => (
                                                                                <div key={index}>
                                                                                    {track.codec} - {track.frame_size} - {track.frame_rate}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    sx={{ backgroundColor: "rgba(160,160,160,0.0)" }}
                                                                    expandIcon={<ExpandMoreIcon />}>
                                                                    Audio Tracks (
                                                                    {
                                                                        apiData?.transcode_parameters?.individual_track_configuration
                                                                            ?.audio_tracks?.length
                                                                    }
                                                                    )
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <>
                                                                        {apiData?.transcode_parameters?.individual_track_configuration?.audio_tracks?.map(
                                                                            (track: any, index: any) => (
                                                                                <div key={index}>
                                                                                    <Typography fontWeight="bold">{track.codec}</Typography>
                                                                                    {track.variants.map((variant: any, index: any) => (
                                                                                        <div key={index}>
                                                                                            {variant.bitrate} - {variant.sample_rate}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    sx={{ backgroundColor: "rgba(160,160,160,0.0)" }}
                                                                    expandIcon={<ExpandMoreIcon />}>
                                                                    Data Tracks (
                                                                    {
                                                                        apiData?.transcode_parameters?.individual_track_configuration
                                                                            ?.data_tracks?.length
                                                                    }
                                                                    )
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <>
                                                                        {apiData?.transcode_parameters?.individual_track_configuration?.data_tracks?.map(
                                                                            (track: any, index: any) => (
                                                                                <div key={index}>{track.data_type}</div>
                                                                            )
                                                                        )}
                                                                    </>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Box>
                                                    </TabPanel>
                                                    <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {liveData?.alarms?.filter(
                                                            (alarm) => alarm.section === "transcoding" && alarm.is_open
                                                        ).length > 0 &&
                                                            liveData?.alarms
                                                                ?.filter((alarm) => alarm.section === "transcoding" && alarm.is_open)
                                                                .map((alarm: any, index: any) => {
                                                                    return (
                                                                        <Box
                                                                            key={index}
                                                                            sx={{
                                                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                                                borderRadius: 1,
                                                                                padding: 1,
                                                                                marginBottom: 2,
                                                                                borderLeftWidth: "6px",
                                                                                borderLeftStyle: "solid",
                                                                                borderLeftColor:
                                                                                    alarm.level === "critical"
                                                                                        ? theme.palette.error.main
                                                                                        : theme.palette.warning.main
                                                                            }}>
                                                                            <Typography fontSize={12}>
                                                                                {format(new Date(alarm.start_time), "dd/MM/yyyy HH:mm:ss")}
                                                                            </Typography>
                                                                            <Typography fontWeight="bold">
                                                                                {alarm.name} -{" "}
                                                                                <span style={{ textTransform: "capitalize" }}>
                                                                                    {alarm.level}
                                                                                </span>
                                                                            </Typography>
                                                                            <Typography fontSize={12}>{alarm.description}</Typography>
                                                                        </Box>
                                                                    );
                                                                })}
                                                    </TabPanel>
                                                </TabContext>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4} sx={{ display: "flex", alignItems: "stretch" }}>
                                        <Card
                                            sx={{
                                                backgroundColor: "rgba(160,160,160,0.1)",
                                                width: "100%",
                                                borderTopColor:
                                                    liveData?.alarms?.filter(
                                                        (alarm) => alarm.section === "output" && alarm.level === "critical" && alarm.is_open
                                                    ).length > 0
                                                        ? theme.palette.error.main
                                                        : liveData?.alarms?.filter(
                                                              (alarm) =>
                                                                  alarm.section === "output" && alarm.level === "warning" && alarm.is_open
                                                          ).length > 0
                                                        ? theme.palette.warning.main
                                                        : "rgba(160,160,160,0)",
                                                borderTopWidth: "4px",
                                                borderTopStyle: "solid"
                                            }}>
                                            <CardHeader
                                                title="Output"
                                                subheader={
                                                    <span style={{ textTransform: "capitalize" }}>
                                                        {liveData?.output?.status_ok ? "Status OK" : "Status Not OK"}
                                                    </span>
                                                }
                                                avatar={
                                                    <Avatar
                                                        sx={{
                                                            color: "white",
                                                            backgroundColor:
                                                                liveData?.alarms?.filter(
                                                                    (alarm) =>
                                                                        alarm.section === "output" &&
                                                                        alarm.level === "critical" &&
                                                                        alarm.is_open
                                                                ).length > 0
                                                                    ? theme.palette.error.main
                                                                    : liveData?.alarms?.filter(
                                                                          (alarm) =>
                                                                              alarm.section === "output" &&
                                                                              alarm.level === "warning" &&
                                                                              alarm.is_open
                                                                      ).length > 0
                                                                    ? theme.palette.warning.main
                                                                    : liveData?.output?.status_ok === true
                                                                    ? theme.palette.success.main
                                                                    : undefined
                                                        }}>
                                                        <PlayArrowIcon />
                                                    </Avatar>
                                                }
                                                action={
                                                    <IconButton
                                                        onClick={() => {
                                                            navigate("/packager/edit/" + wsData?.id);
                                                        }}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                }
                                            />
                                            <CardContent
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    height: "calc(100% - 72px)",
                                                    minHeight: 350
                                                }}>
                                                <TabContext value={outputTabValue}>
                                                    <Box>
                                                        <TabList onChange={handleOutputTabChange} aria-label="source details tabs">
                                                            <Tab label="Info" value="1" />
                                                            <Tab label="Groups" value="2" />
                                                            <Tab
                                                                label={
                                                                    "Alarms (" +
                                                                    (liveData?.alarms?.filter(
                                                                        (alarm: any) => alarm.section === "output" && alarm.is_open
                                                                    ).length || "0") +
                                                                    ")"
                                                                }
                                                                value="3"
                                                            />
                                                        </TabList>
                                                    </Box>
                                                    <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {liveData?.output_latency !== null && (
                                                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                <Typography fontWeight="bold">Output Latency</Typography>
                                                                <Typography>{liveData?.output_latency || "-"}</Typography>
                                                            </Box>
                                                        )}
                                                        {wsData?.software_version && (
                                                            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
                                                                <Typography fontWeight="bold">Software Version</Typography>
                                                                <Typography>{wsData?.software_version}</Typography>
                                                            </Box>
                                                        )}
                                                    </TabPanel>
                                                    <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {apiData?.transcode_parameters?.output_groups?.map((group: any, index: any) => (
                                                            <Accordion key={index}>
                                                                <AccordionSummary
                                                                    sx={{ backgroundColor: "rgba(160,160,160,0.0)" }}
                                                                    expandIcon={<ExpandMoreIcon />}>
                                                                    {index} - {group.type}
                                                                </AccordionSummary>

                                                                <AccordionDetails>
                                                                    {liveData?.video_encoding_qualities?.[index] && (
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                mb: 1
                                                                            }}>
                                                                            <Typography fontWeight="bold">
                                                                                Video Encoding Quality
                                                                            </Typography>
                                                                            <Typography>
                                                                                {liveData?.video_encoding_qualities[index]}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                    <Typography fontWeight="bold">Output URL</Typography>
                                                                    {group.output_url}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))}
                                                    </TabPanel>
                                                    <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {liveData?.alarms?.filter((alarm) => alarm.section === "output" && alarm.is_open)
                                                            .length > 0 &&
                                                            liveData?.alarms
                                                                ?.filter((alarm) => alarm.section === "output" && alarm.is_open)
                                                                .map((alarm: any, index: any) => {
                                                                    return (
                                                                        <Box
                                                                            key={index}
                                                                            sx={{
                                                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                                                borderRadius: 1,
                                                                                padding: 1,
                                                                                marginBottom: 2,
                                                                                borderLeftWidth: "6px",
                                                                                borderLeftStyle: "solid",
                                                                                borderLeftColor:
                                                                                    alarm.level === "critical"
                                                                                        ? theme.palette.error.main
                                                                                        : theme.palette.warning.main
                                                                            }}>
                                                                            <Typography fontSize={12}>
                                                                                {format(new Date(alarm.start_time), "dd/MM/yyyy HH:mm:ss")}
                                                                            </Typography>
                                                                            <Typography fontWeight="bold">
                                                                                {alarm.name} -{" "}
                                                                                <span style={{ textTransform: "capitalize" }}>
                                                                                    {alarm.level}
                                                                                </span>
                                                                            </Typography>
                                                                            <Typography fontSize={12}>{alarm.description}</Typography>
                                                                        </Box>
                                                                    );
                                                                })}
                                                    </TabPanel>
                                                </TabContext>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Box sx={{ mt: 2 }}>
                                    <Accordion sx={{ mt: 2, backgroundColor: "rgba(0,0,0,0.1)" }}>
                                        <AccordionSummary sx={{ backgroundColor: "rgba(160,160,160,0.0)" }} expandIcon={<ExpandMoreIcon />}>
                                            Alarm History {liveData?.alarms?.length > 0 && `(${liveData?.alarms?.length || "0"})`}
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ backgroundColor: "rgba(160,160,160,0.0)" }}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                {liveData?.alarms?.length > 0 && (
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<DeleteIcon />}
                                                        onClick={() => {
                                                            agent.Packager.resetAlarms(wsData?.id)
                                                                .then((data) => {
                                                                    openDialog(<ConfirmSuccessDialog message="Alarms are now cleared." />);
                                                                })
                                                                .catch((err) => {
                                                                    console.log(err);
                                                                });
                                                        }}>
                                                        Clear Alarms
                                                    </Button>
                                                )}
                                            </Box>
                                            {liveData?.alarms?.length > 0 && (
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Start Date</TableCell>
                                                            <TableCell>End Date</TableCell>
                                                            <TableCell>Level</TableCell>
                                                            <TableCell>Section</TableCell>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>Description</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {liveData?.alarms?.map((alarm: any, index: any) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    {format(new Date(alarm.start_time), "dd/MM/yyyy hh:mm")}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {alarm.end_time
                                                                        ? format(new Date(alarm.end_time), "dd/MM/yyyy hh:mm")
                                                                        : "-"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {alarm.level === "critical" ? (
                                                                        <Chip label="Critical" color="error" size="small" />
                                                                    ) : alarm.level === "warning" ? (
                                                                        <Chip label="Warning" color="warning" size="small" />
                                                                    ) : (
                                                                        <Chip
                                                                            sx={{ textTransform: "capitalize" }}
                                                                            label={alarm.level}
                                                                            size="small"
                                                                        />
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>{alarm.section}</TableCell>
                                                                <TableCell>{alarm.name}</TableCell>
                                                                <TableCell>{alarm.description}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </CardContent>
                        </AccordionDetails>
                    </Accordion>
                    {wsData?.backup_instance_rid && (
                        <BackupInstanceDetails
                            rid={wsData?.backup_instance_rid}
                            display_cloud_provider={display_cloud_provider}
                            display_search={display_search}
                            display_status={display_status}
                            display_type={display_type}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default observer(PackagerItem);
