import React, { useContext } from "react";
import { Switch } from "@mui/material";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";

export const DesktopNotificationsSwitch: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, updateUserPreferences } = rootStore.userStore;

    return (
        <Switch
            edge="end"
            size="small"
            checked={user?.user_preferences?.desktop_notifications}
            onChange={(event) => {
                user?.user_preferences?.desktop_notifications
                    ? updateUserPreferences({ desktop_notifications: false })
                    : updateUserPreferences({ desktop_notifications: true });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }}
        />
    );
};

export default observer(DesktopNotificationsSwitch);