import React from "react";
import {
    Box,
    Checkbox,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { IAutocompleteOption as IAutocompleteOptionItem } from "api";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

interface IAutocompleteOption {
    option: IAutocompleteOptionItem;
    selected?: boolean;
    multiple?: boolean;
}
  
export const AutocompleteOption: React.FC<IAutocompleteOption> = ({
    option,
    selected = false,
    multiple = false,
    ...props
}) => {
    return (
        <MenuItem {...props} key={option.key || option.value} value={option.value} disabled={option.disabled ? true : false}>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ width: '100%' }}>
                {multiple && (
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        checked={selected}
                    />
                )}

                <Box>
                    {option.title}
                    {option.description && (
                        <Typography component="div" variant="caption" color="textSecondary" id="description">
                            {option.description}
                        </Typography>
                    )}
                </Box>
            </Stack>
        </MenuItem>
    );
};

export default AutocompleteOption;