import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "api";
import { useKnowledgeHub } from "hooks";

interface IGlobalKnowledgeHub {}

/**
 * `GlobalKnowledgeHub` is a React functional component that is responsible for fetching
 * all necessary data related to the Knowledge Hub when the application loads. It utilizes
 * the `useContext` hook to access the global store and the `useEffect` hook to trigger
 * data fetching based on the user's presence.
 *
 * This component should be included in `App.tsx` or a similar top-level component to ensure
 * that the Knowledge Hub data is fetched as soon as the application loads and the user is
 * authenticated.
 *
 * @component
 * @example
 * ```tsx
 * import React from 'react';
 * import { GlobalKnowledgeHub } from './GlobalKnowledgeHub';
 *
 * function App() {
 *   return (
 *     <div>
 *       <GlobalKnowledgeHub />
 *     </div>
 *   );
 * }
 *
 * export default App;
 * ```
 */
export const GlobalKnowledgeHub: React.FC<IGlobalKnowledgeHub> = () => {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const { getKnowledgeHubInit } = useKnowledgeHub();

    useEffect(() => {
        if (!user) {
            return;
        }

        getKnowledgeHubInit();
    }, [user]);

    return null;
};
