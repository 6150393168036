import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, Grid, Paper, Typography } from "@mui/material";

interface IUsageHeader {
    breakdown: any;
    id: string | number;
    startDate: Date;
    endDate: Date;
    orgSummary: any;
}

export const UsageHeader: React.FC<IUsageHeader> = ({ orgSummary, breakdown, id, startDate, endDate }) => {
    

    useEffect(() => {
        console.log("Org Summary", orgSummary);
    }, [orgSummary]);

    if (!orgSummary[0]) {
        return <></>;
    }

    return (
        <Grid container columns={{xs: 5}} sx={{ alignItems: "center", width: "100%" }} spacing={2} mt={0} mb={3}>
            <Grid item xs={5}>
                <Typography variant="h5" fontWeight="bold">
                    Summary
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Disk Storage</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].disk_storage_costs?.usage_value || "0"}GB/h
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Elastic IP</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].elastic_ip_costs?.usage_value} Hours
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Egress</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].external_egress_costs?.usage_value}MB
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Titan File</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].titan_file_cogs?.usage_value}s
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Virtual Machine</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].virtual_machine_run_time?.usage_value} Hours
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Pull Packager CDN Egress</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].nea_live_cdn_egress_costs?.usage_value}MB
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Tag Licence Tile</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].tag_licence_tile_costs?.usage_value} Tile Hours
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">External Zixi Licence Egress</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].external_zixi_licence_egress_costs?.usage_value}MB
                        </Typography>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={1}>
                <Card>
                    <Box p={2}>
                        <Typography variant="body1" fontWeight="bold">Internal Zixi Licence Egress</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {orgSummary[0].internal_zixi_licence_egress_costs?.usage_value}MB
                        </Typography>
                    </Box>
                </Card>
            </Grid>

        </Grid>
    );
};

export default observer(UsageHeader);
