import {
    Grid,
    Box,
    Stack,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useMediaQuery,
    useTheme,
    Button
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import SourceCard from "./Components/SourceCard";
import { RootStoreContext } from "api";
import { useNavigate } from "react-router-dom";
import SourcesMenu, { SourceMenuArea } from "./Components/SourcesMenu";
import { ArrowOutward } from "@mui/icons-material";

const Sources: React.FC = observer(function Sources() {
    const theme = useTheme();
    const [loading, setLoading] = React.useState<boolean>(false);
    const rootStore = React.useContext(RootStoreContext);
    const { user, activeEnvironment, updateUserPreferences } = rootStore.userStore;
    const { currentEnvRunning, environment } = rootStore.environmentStore;
    const { token } = rootStore.commonStore;
    const [data, setData] = React.useState<any>([]);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [columns, setColumns] = React.useState<number>(
        user?.user_preferences?.sources_item_size ? user.user_preferences.sources_item_size : 10
    );
    const [displayStatus, setDisplayStatus] = React.useState<string>("all");
    const [displayType, setDisplayType] = React.useState<string>("all");
    const [displayAlertStatus, setDisplayAlertStatus] = React.useState<string>("all");
    const navigate = useNavigate();

    const { client, clientConnected } = rootStore.resClientStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const activeorganisationId = rootStore.userStore.activeorganisationId;

    React.useEffect(() => {
        setLoading(true);

        let resData: any = {};

        clientConnected &&
            client
                .get(`environments.sources.${managing_org_id}.${activeEnvironment}.${activeorganisationId}`)
                .then((res) => {
                    resData = res;
                    const jsonObject = res.toJSON();
                    console.log("jsonObject", jsonObject);
                    setData(jsonObject);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setData(res.toJSON());
                        setLoading(false);
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            setData([]);
        };
    }, [managing_org_id, activeorganisationId, clientConnected, currentEnvRunning]);

    return (
        <View loading={loading}>
            <ViewHeader title="Sources" subtitle={environment?.name || undefined} />
            <ViewBody noPadding>
                <Content
                    toolbarContent={
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <FormControl sx={{ width: 120, marginRight: 1 }}>
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <Select
                                        labelId="type-label"
                                        id="type-select"
                                        value={displayType}
                                        label="Type"
                                        onChange={(e) => {
                                            setDisplayType(e.target.value as string);
                                        }}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Please Select"
                                        sx={{ width: 120 }}>
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="source">Source</MenuItem>
                                        <MenuItem value="transcoder">Transcoder</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ width: 120, marginRight: 1 }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={displayStatus}
                                        label="Status"
                                        onChange={(e) => {
                                            setDisplayStatus(e.target.value as string);
                                        }}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Please Select"
                                        sx={{ width: 120 }}>
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="connected">Connected</MenuItem>
                                        <MenuItem value="connecting">Connecting</MenuItem>
                                        <MenuItem value="stopped">Stopped</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ width: 120 }}>
                                    <InputLabel id="alert-status-label">Alert Status</InputLabel>
                                    <Select
                                        labelId="alert-status-label"
                                        id="demo-simple-select"
                                        value={displayAlertStatus}
                                        label="Alert Status"
                                        onChange={(e) => {
                                            setDisplayAlertStatus(e.target.value as string);
                                        }}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Please Select"
                                        sx={{ width: 120 }}>
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="alerting">Alerting</MenuItem>
                                    </Select>
                                </FormControl>

                                <Box sx={{ width: "2px", backgroundColor: "rgba(160,160,160,0.6)", height: "20px", margin: 2 }}></Box>
                                <FormControl sx={{ width: 100 }}>
                                    <InputLabel id="demo-simple-select-label">Item Size</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={columns}
                                        label="Item Size"
                                        onChange={(e) => {
                                            setColumns(Number(e.target.value));
                                            updateUserPreferences({ sources_item_size: Number(e.target.value) });
                                        }}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Please Select"
                                        sx={{ minWidth: 100 }}>
                                        <MenuItem value={12}>Small</MenuItem>
                                        <MenuItem value={10}>Medium</MenuItem>
                                        <MenuItem value={8}>Large</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Stack spacing={1} direction="row" alignItems="center">
                                <Button
                                    onClick={() => {
                                        navigate("/multiviewers");
                                    }}
                                    startIcon={<ArrowOutward />}
                                    variant="text"
                                    color="primary">
                                    View Multiviewers
                                </Button>
                                <Box
                                    sx={{
                                        marginTop: "0 !important",
                                        marginLeft: "0.5rem !important"
                                    }}>
                                    <SourcesMenu />
                                </Box>
                            </Stack>
                        </Box>
                    }>
                    <Grid container spacing={3} columns={useMediaQuery(theme.breakpoints.down("sm")) ? 1 : columns}>
                        {currentEnvRunning &&
                            data &&
                            Object.entries(data)
                                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort entries by key
                                .map(([key, source]: [string, any]) => (
                                    <SourceCard
                                        key={key}
                                        rid={source.rid}
                                        client={client}
                                        display_status={displayStatus}
                                        display_type={displayType}
                                        display_alert_status={displayAlertStatus}
                                    />
                                ))}
                    </Grid>
                    {currentEnvRunning && data && Object.entries(data).length === 0 && (
                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <Alert severity="info">
                                <AlertTitle>No Sources Found</AlertTitle>
                                No sources were found on this environment. Please click on the <strong>Add Source</strong> button to add a
                                new source.
                            </Alert>
                        </Box>
                    )}
                    {!currentEnvRunning && (
                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <Alert severity="info" sx={{ width: "37rem" }}>
                                <AlertTitle>Environment Not Running</AlertTitle>
                                The active environment is not running. Please start the environment to view sources.
                            </Alert>
                        </Box>
                    )}
                </Content>
            </ViewBody>
        </View>
    );
});

export default Sources;
