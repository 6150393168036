import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormApi } from "final-form";
import SaveAsPreset from "../SaveAsPreset";
import RoleProvider from "components/RoleProvider";
import { Editor } from "@monaco-editor/react";
import { useFormState } from "react-final-form";

interface IReviewProps {
    values: any;
    form: FormApi<any, Partial<any>>;
}

const Review: React.FC<IReviewProps> = ({ values, form }) => {
    const { submitErrors } = useFormState();

    return (
        <Box>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} xl={6}>
                    <Card sx={{ mb: 2, height: "100%" }}>
                        <CardContent sx={{ my: 1 }}>
                            <Typography variant="h6" gutterBottom>
                                Settings
                            </Typography>
                            <Stack direction={"row"} spacing={1}>
                                <Typography>Name:</Typography>
                                <Typography fontWeight="bold">{values.name}</Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1}>
                                <Typography>Packager Redundancy Enabled:</Typography>
                                <Typography fontWeight="bold">{values?.enable_backup?.toString() ?? "false"}</Typography>
                            </Stack>
                            <Box>{submitErrors?.name && <Typography color="error">{submitErrors.name}</Typography>}</Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} xl={6}>
                    <Card sx={{ mb: 2, height: "100%" }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Source
                            </Typography>
                            <Box sx={{ mb: 1 }}>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography>Source Type:</Typography>
                                    <Typography fontWeight="bold" textTransform="capitalize">
                                        {values.input_feed_source}
                                    </Typography>
                                </Stack>
                                {submitErrors?.input_feed_source && <Typography color="error">{submitErrors.input_feed_source}</Typography>}
                            </Box>
                            {/* cloud_provider */}
                            {values.cloud_provider && (
                                <Box sx={{ mb: 1 }}>
                                    <Stack direction={"row"} spacing={1}>
                                        <Typography>Cloud Provider:</Typography>
                                        <Typography fontWeight="bold">
                                            {values.cloud_provider === "aws"
                                                ? "Amazon Web Services"
                                                : values.cloud_provider === "gcp"
                                                ? "Google Cloud Platform"
                                                : "Digital Ocean"}
                                        </Typography>
                                    </Stack>
                                    {submitErrors?.cloud_provider && <Typography color="error">{submitErrors.cloud_provider}</Typography>}
                                </Box>
                            )}
                            {/* region */}
                            {values.region && (
                                <Box sx={{ mb: 1 }}>
                                    <Stack direction={"row"} spacing={1}>
                                        <Typography>Region:</Typography>
                                        <Typography fontWeight="bold">{values.region}</Typography>
                                    </Stack>
                                    {submitErrors?.region && <Typography color="error">{submitErrors.region}</Typography>}
                                </Box>
                            )}
                            {/* direct_feed_input_details.input_type (remove _ and capitalize) */}
                            {values.direct_feed_input_details && values.direct_feed_input_details.input_type && (
                                <Box sx={{ mb: 1 }}>
                                    <Stack direction={"row"} spacing={1}>
                                        <Typography>Input Type:</Typography>
                                        <Typography fontWeight="bold">
                                            {values.direct_feed_input_details.input_type
                                                .replace(/_/g, " ")
                                                .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))}
                                        </Typography>
                                    </Stack>
                                    {submitErrors?.direct_feed_input_details?.input_type && (
                                        <Typography color="error">{submitErrors.direct_feed_input_details.input_type}</Typography>
                                    )}
                                </Box>
                            )}
                            {/* direct_feed_input_details.input_url */}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} xl={6}>
                    <Card sx={{ mb: 2, height: "100%" }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Global Track Configuration
                            </Typography>
                            <Box sx={{ mb: 1 }}>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography>Latency Mode:</Typography>
                                    <Typography fontWeight="bold">{values?.global_track_configuration?.latency_mode}</Typography>
                                </Stack>
                                {submitErrors?.global_track_configuration?.latency_mode && (
                                    <Typography color="error">{submitErrors.global_track_configuration.latency_mode}</Typography>
                                )}
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography>Chunk Period</Typography>
                                    <Typography fontWeight="bold">{values?.global_track_configuration?.chunk_period}</Typography>
                                </Stack>
                                {submitErrors?.global_track_configuration?.chunk_period && (
                                    <Typography color="error">{submitErrors.global_track_configuration.chunk_period}</Typography>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                {values?.individual_track_configuration?.video_tracks && values.individual_track_configuration.video_tracks.length > 0 && (
                    <Grid item xs={12} xl={6}>
                        <Card sx={{ mb: 2, height: "100%" }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Video Tracks
                                </Typography>
                                {/* video_tracks */}
                                {values.individual_track_configuration.video_tracks.map((track, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            mb: 1,
                                            backgroundColor: "rgba(160,160,160,0.05)",
                                            borderRadius: 1,
                                            padding: 1
                                        }}>
                                        <Typography fontWeight={600} fontSize={16} marginBottom={1}>
                                            Video Track {index + 1}
                                        </Typography>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Bitrate</Typography>
                                            <Typography fontWeight="bold">{track.bitrate}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.video_tracks?.[index]?.bitrate ===
                                            "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.video_tracks[index].bitrate}
                                            </Typography>
                                        )}
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Gop Size</Typography>
                                            <Typography fontWeight="bold">{track.gop_size}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.video_tracks?.[index]?.gop_size ===
                                            "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.video_tracks[index].gop_size}
                                            </Typography>
                                        )}
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Codec</Typography>
                                            <Typography fontWeight="bold">{track.codec}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.video_tracks?.[index]?.codec === "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.video_tracks[index].codec}
                                            </Typography>
                                        )}
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Profile</Typography>
                                            <Typography fontWeight="bold">{track.profile}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.video_tracks?.[index]?.profile ===
                                            "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.video_tracks[index].profile}
                                            </Typography>
                                        )}
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Frame Size</Typography>
                                            <Typography fontWeight="bold">{track.frame_size}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.video_tracks?.[index]?.frame_size ===
                                            "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.video_tracks[index].frame_size}
                                            </Typography>
                                        )}
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                <Grid item xs={12} xl={6}>
                    <Card sx={{ mb: 2, height: "100%" }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Advanced Video Settings
                            </Typography>

                            <Box sx={{ mb: 1 }}>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography>Color Enhancement:</Typography>
                                    <Typography fontWeight="bold">{values.color_enhancement?.enabled ? "Enabled" : "Disabled"}</Typography>
                                </Stack>
                                {typeof submitErrors?.color_enhancement?.enabled === "string" && (
                                    <Typography color="error">{submitErrors.color_enhancement.enabled}</Typography>
                                )}
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography>Dolby Vision:</Typography>
                                    <Typography fontWeight="bold">
                                        {values?.output_colorimetry_parameters?.enable_dolby_vision ? "Enabled" : "Disabled"}
                                    </Typography>
                                </Stack>
                                {typeof submitErrors?.output_colorimetry_parameters?.enable_dolby_vision === "string" && (
                                    <Typography color="error">{submitErrors.output_colorimetry_parameters.enable_dolby_vision}</Typography>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                {values?.individual_track_configuration?.audio_tracks && values.individual_track_configuration.audio_tracks.length > 0 && (
                    <Grid item xs={12} xl={6}>
                        <Card sx={{ mb: 2, height: "100%" }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Audio Tracks
                                </Typography>
                                {values.individual_track_configuration.audio_tracks.map((track, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            mb: 1,
                                            backgroundColor: "rgba(160,160,160,0.05)",
                                            borderRadius: 1,
                                            padding: 1
                                        }}>
                                        <Typography fontWeight={600} fontSize={16} marginBottom={1}>
                                            Audio Track {index + 1}
                                        </Typography>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Codec</Typography>
                                            <Typography fontWeight="bold">{track.codec}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.audio_tracks?.[index]?.codec === "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.audio_tracks[index].codec}
                                            </Typography>
                                        )}
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Layout</Typography>
                                            <Typography fontWeight="bold">{track.encoding_mode_or_layout}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.audio_tracks?.[index]
                                            ?.encoding_mode_or_layout === "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.audio_tracks[index].encoding_mode_or_layout}
                                            </Typography>
                                        )}
                                        {track?.variants?.map((variant, index2) => (
                                            <Box
                                                key={index2}
                                                sx={{
                                                    mt: 2,
                                                    mb: 1
                                                }}>
                                                <Typography fontWeight={600} fontSize={15} marginBottom={0.5}>
                                                    Variant {index2 + 1}
                                                </Typography>
                                                <Stack direction={"row"} spacing={1}>
                                                    <Typography>Sample Rate</Typography>
                                                    <Typography fontWeight="bold">{variant.sample_rate}</Typography>
                                                </Stack>
                                                {typeof submitErrors?.individual_track_configuration?.audio_tracks?.[index]?.variants?.[
                                                    index2
                                                ]?.sample_rate === "string" && (
                                                    <Typography color="error">
                                                        {
                                                            submitErrors.individual_track_configuration.audio_tracks[index]?.variants?.[
                                                                index2
                                                            ].sample_rate
                                                        }
                                                    </Typography>
                                                )}
                                                <Stack direction={"row"} spacing={1}>
                                                    <Typography>Bitrate</Typography>
                                                    <Typography fontWeight="bold">{variant.bitrate}</Typography>
                                                </Stack>
                                                {typeof submitErrors?.individual_track_configuration?.audio_tracks?.[index]?.variants?.[
                                                    index2
                                                ]?.bitrate === "string" && (
                                                    <Typography color="error">
                                                        {
                                                            submitErrors.individual_track_configuration.audio_tracks[index]?.variants?.[
                                                                index2
                                                            ].bitrate
                                                        }
                                                    </Typography>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {values?.individual_track_configuration?.data_tracks && values.individual_track_configuration.data_tracks.length > 0 && (
                    <Grid item xs={12} xl={6}>
                        <Card sx={{ mb: 2, height: "100%" }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Data Tracks
                                </Typography>
                                {/* data_tracks */}

                                {values.individual_track_configuration.data_tracks.map((track, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            mb: 1,
                                            backgroundColor: "rgba(160,160,160,0.05)",
                                            borderRadius: 1,
                                            padding: 1
                                        }}>
                                        <Typography fontWeight={600} fontSize={16} marginBottom={1}>
                                            Data Track {index + 1}
                                        </Typography>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Data Type</Typography>
                                            <Typography fontWeight="bold">{track.data_type}</Typography>
                                        </Stack>
                                        {typeof submitErrors?.individual_track_configuration?.data_tracks?.[index] === "string" && (
                                            <Typography color="error">
                                                {submitErrors.individual_track_configuration.data_tracks[index]}
                                            </Typography>
                                        )}
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                <Grid item xs={12} xl={6}>
                    <Card sx={{ mb: 2, height: "100%" }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Output Groups
                            </Typography>
                            {values?.output_groups?.map((output_group, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        mb: 1,
                                        backgroundColor: "rgba(160,160,160,0.05)",
                                        borderRadius: 1,
                                        padding: 1
                                    }}>
                                    <Typography fontWeight={600} fontSize={16} marginBottom={1}>
                                        Output Group {index + 1}
                                    </Typography>
                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Type</Typography>
                                            <Typography fontWeight="bold">{output_group.type}</Typography>
                                        </Stack>
                                        {submitErrors?.output_groups?.[index]?.type && (
                                            <Typography color="error">{submitErrors.output_groups[index].type}</Typography>
                                        )}
                                    </Box>
                                    {/* number_of_segments_in_playlist */}
                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Number of Segments in Playlist</Typography>
                                            <Typography fontWeight="bold">{output_group.number_of_segments_in_playlist}</Typography>
                                        </Stack>
                                        {submitErrors?.output_groups?.[index]?.number_of_segments_in_playlist && (
                                            <Typography color="error">
                                                {submitErrors.output_groups[index].number_of_segments_in_playlist}
                                            </Typography>
                                        )}
                                    </Box>
                                    {/* segment_duration */}
                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Segment Duration</Typography>
                                            <Typography fontWeight="bold">{output_group.segment_duration}</Typography>
                                        </Stack>
                                        {submitErrors?.output_groups?.[index]?.segment_duration && (
                                            <Typography color="error">{submitErrors.output_groups[index].segment_duration}</Typography>
                                        )}
                                    </Box>

                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography>Output URL</Typography>
                                            <Typography fontWeight="bold">{output_group.output_url}</Typography>
                                        </Stack>
                                        {submitErrors?.output_groups?.[index]?.output_url && (
                                            <Typography color="error">{submitErrors.output_groups[index].output_url}</Typography>
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Card sx={{ mb: 2, height: "100%" }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Presets
                            </Typography>

                            <SaveAsPreset values={values} form={form} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <RoleProvider roles={["is_dev"]}>
                <Editor
                    value={JSON.stringify(values, null, 2)}
                    defaultLanguage="json"
                    height={"25rem"}
                    theme="my-dark"
                    options={{
                        autoIndent: "full",
                        automaticLayout: true,
                        minimap: {
                            enabled: false
                        },
                        wordWrap: "on",
                        formatOnPaste: true,
                        formatOnType: true
                    }}
                    onChange={(value) => {
                        //set the form values to the value of the editor
                        form.change("", JSON.parse(value!));
                    }}
                />
            </RoleProvider>
        </Box>
    );
};

export default observer(Review);
