import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, DialogContent } from "@mui/material";
import { agent, CreateStreamAdaptationRequest, RootStoreContext } from "api";
import { TextField } from "components/Forms";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";

interface Props {
    pull_packager_uuid: string;
    onSubmitted: (values: CreateStreamAdaptationRequest) => void;
}

const StreamAdaptationFamilyForm: React.FC<Props> = (props) => {
    const rootStore = React.useContext(RootStoreContext);

    const { closeDialog } = rootStore.dialogStore;

    return (
        <Form
            onSubmit={(values: CreateStreamAdaptationRequest) => {
                console.log("values", values);
                return agent.NeaLive.createStreamAdaptationFamily({ pull_packager_uuid: props.pull_packager_uuid, ...values })
                    .then((response) => {
                        console.log("response", response);
                        props.onSubmitted(values);
                        closeDialog();
                    })
                    .catch((error) => {
                        console.log("error", error);
                        const errors = error.data?.errors || {};
                        return { [FORM_ERROR]: error, ...errors };
                    });
            }}
            render={({ handleSubmit, submitting, invalid, submitError, dirtySinceLastSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <TextField
                                name="name"
                                label="Name"
                                required
                                validate={(value: string) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                    //^[a-zA-Z0-9-_]+$ - only letters, numbers, underscores and dashes, no spaces
                                    if (!/^[a-zA-Z0-9-_]+$/.test(value)) {
                                        return "Invalid characters. Only letters, numbers, dashes and underscores are allowed. No spaces.";
                                    }
                                    //max length 64 characters
                                    if (value.length > 64) {
                                        return "Maximum length is 64 characters";
                                    }
                                }}
                            />
                            {submitError && <Alert severity="error">{submitError.data?.message}</Alert>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Cancel</Button>
                            <LoadingButton
                                loading={submitting}
                                type="submit"
                                disabled={submitting || (invalid && !dirtySinceLastSubmit)}
                                color="primary"
                                variant="contained">
                                Create
                            </LoadingButton>
                        </DialogActions>
                    </form>
                );
            }}
        />
    );
};

export default StreamAdaptationFamilyForm;
