import React, { useContext, useState } from "react";
import { RootStoreContext } from "api";
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import { useDestinations } from "hooks";

interface IProps {
    destination_id: string;
    destination_name: string;
}

export const ConfirmDestinationStop: React.FC<IProps> = ({ destination_id, destination_name }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { setDestinationStart } = rootStore.destinationStore;
    const { stopDestination } = useDestinations();
    const [error, setError] = useState("");

    const stopItem = () => {
        stopDestination(destination_id!)
            .then(() => {
                closeDialog();
                closeDialog();
                setDestinationStart();
            })
            .catch((error) => {
                setError(error.data.message);
            });
    };

    return (
        <>
            <DialogTitle>{`Stop ${destination_name}`}</DialogTitle>
            <DialogContent>
                <Typography marginBottom={2}>
                    Are you sure you want to stop destination: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{destination_name}</span>
                    ?
                </Typography>
                <Typography>
                    This will put the destination in to a stopped state. For Zixi push, RIST and RTMP this will stop the platform sending
                    the streams, for Zixi pull and SRT listener this will stop the destination device connecting back in.
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    startIcon={<StopIcon />}
                    onClick={() => {
                        stopItem();
                    }}>
                    Stop
                </Button>
            </DialogActions>
        </>
    );
};
