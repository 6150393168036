import React from "react";
import { agent, IDestData } from "api";

type ItemInfoProps = {
    item_id: string;
    type: string;
};

interface ISourceInfo {
    human_type: string;
    id: string;
    is_analysis_on: boolean;
    latency: number;
    latitude: number;
    longitude: number;
    max_allowed_bitrate_kbps: number;
    port: number;
    user_description: string;
}

const ItemInfo: React.FC<ItemInfoProps> = ({ item_id, type }) => {
    const [itemInfo, setItemInfo] = React.useState<ISourceInfo | IDestData | any | undefined>();

    const getSourceInfo = async (input_id: string) => {
        await agent.Channel.getInput(input_id)
            .then((res) => {
                setItemInfo(res.data);
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

    const getDestinationInfo = async (output_id: string) => {
        await agent.Destination.getIndividualDestination(output_id)
            .then((res) => {
                setItemInfo(res.data);
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

    React.useEffect(() => {
        if (type !== "destination") {
            getSourceInfo(item_id);
        } else {
            getDestinationInfo(item_id);
        }
        return () => {
            setItemInfo(undefined);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (type === "destination") {
        return (
            <div id={item_id} style={{ display: "flex", flexDirection: "column", minHeight: "352px" }}>
                {itemInfo?.display_name && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Name</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.display_name}</div>
                    </div>
                )}
                {itemInfo?.human_type && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Type</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.human_type}</div>
                    </div>
                )}
                {itemInfo?.technical_details?.port && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Port</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.technical_details.port}</div>
                    </div>
                )}
                {itemInfo?.technical_details?.protocol && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Protocol</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.technical_details.protocol}</div>
                    </div>
                )}
                {itemInfo?.technical_details?.public_ip && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Public IP</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.technical_details.public_ip}</div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div id={item_id} style={{ display: "flex", flexDirection: "column", minHeight: "352px" }}>
                {itemInfo?.id && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Name</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.id}</div>
                    </div>
                )}
                {itemInfo?.human_type && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Type</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.human_type}</div>
                    </div>
                )}
                {itemInfo?.latency && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Latency</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.latency}</div>
                    </div>
                )}
                {itemInfo?.max_allowed_bitrate_kbps && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Max Bitrate (kbps)</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.max_allowed_bitrate_kbps}</div>
                    </div>
                )}
                {itemInfo?.port && (
                    <div style={{ marginBottom: 2, justifyContent: "space-between", display: "flex" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>Port</div>
                        <div style={{ fontSize: 12 }}>{itemInfo.port}</div>
                    </div>
                )}
            </div>
        );
    }
};

export default ItemInfo;
