import { Box, Divider, IconButton, ListItem, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { agent, RootStoreContext} from "api";
import { format } from "date-fns";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { ResModel } from "resclient";
import renameCategory from "../renameCategory";

interface IProps {
    id: number;
    value: any;
    onChange?: () => void;
}

const NotificationsListItem: React.FC<IProps> = ({ value, id, onChange }) => {
    const theme = useTheme();
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;
    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const { user } = rootStore.userStore;

    const [readDisabled, setReadDisabled] = React.useState(false);
    const [deleteDisabled, setDeleteDisabled] = React.useState(false);

    const rid = "notifications." + managing_org_id + ".notification." + id;

    const handleRead = async (id: Number) => {
        setReadDisabled(true);
        await agent
            .getCleanInstance()
            .put(`/notifications/${id}`)
            .then(() => {
                setReadDisabled(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleDelete = async (id: Number) => {
        setDeleteDisabled(true);
        await agent
            .getCleanInstance()
            .delete(`/notifications/${id}`)
            .then(() => {
                setDeleteDisabled(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        let resdata: ResModel | any = {};

        const fetchData = () => {
            client
                .get(rid)
                .then((res) => {
                    resdata = res;
                    res.on("change", () => {
                        onChange && onChange();
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        if (clientConnected) {
            fetchData();
        } else {
            const intervalId = setInterval(() => {
                if (clientConnected) {
                    clearInterval(intervalId);
                    fetchData();
                }
            }, 1000);
        }

        !clientConnected && rootStore.resClientStore.initialize(rootStore.commonStore.token!);

        return () => {
            if (resdata instanceof ResModel) {
                // resdata.off("change", () => {
                //     console.log("unsubscribed");
                // });
            }
        };
    }, [client, clientConnected, onChange, rid, rootStore.commonStore.token, value]);

    return (
        <>
            <ListItem
                sx={{
                    backgroundColor: (value as { is_critical: boolean }).is_critical ? theme.palette.error.dark : undefined,
                    color: (value as { is_critical: boolean }).is_critical ? theme.palette.error.contrastText : undefined
                }}>
                <Box sx={{ flex: 1, py: 1 }}>
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                        {(value as { created_on?: Date }).created_on &&
                            format(new Date((value as { created_on: Date }).created_on), "dd/MM/yyyy @ HH:mm:ss")}
                    </Typography>
                    {!user?.user_preferences?.group_notifications && (
                        <Typography variant="body1" fontWeight={600}>
                            {renameCategory((value as { category: string }).category)}
                        </Typography>
                    )}
                    <Typography fontSize={"13px"}>{(value as { notification: string }).notification}</Typography>
                    {
                        //if the environment_name is not null, display it inside a Typography
                        (value as { environment_name: string }).environment_name && (
                            <Typography variant="body2" sx={{ opacity: 0.7 }}>
                                {(value as { environment_name: string }).environment_name}
                            </Typography>
                        )
                    }
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        {(value as { is_alerting: boolean }).is_alerting === true && (
                            <Tooltip title="Alerting" placement="top">
                                <IconButton size="small" onClick={() => {}}>
                                    <NotificationsActiveIcon fontSize="small" className="pulse" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Mark as read" placement="top">
                            <IconButton
                                size="small"
                                disabled={readDisabled}
                                onClick={() => {
                                    handleRead((value as { id: number }).id);
                                }}>
                                <CheckIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                            <IconButton
                                size="small"
                                disabled={deleteDisabled}
                                onClick={() => {
                                    handleDelete((value as { id: number }).id);
                                }}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
            </ListItem>
            <Divider />
        </>
    );
};

export default NotificationsListItem;
