import { Avatar, useTheme } from "@mui/material";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

interface UserAvatarProps {
    size: number;
    mr?: number;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ size, mr }) => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    function stringAvatar(name: string) {
        if (!name) {
            return {
                children: undefined
            };
        }
        return {
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        };
    }
    return (
        <Avatar
            {...stringAvatar(user?.first_name?.trimEnd() + " " + user?.last_name)}
            sx={{
                width: size,
                height: size,
                fontSize: size / 2,
                mr: mr,
                backgroundColor:
                    user?.managing_org_permission === "admin"
                        ? theme.palette.tertiary?.main
                        : user?.managing_org_permission === "monitor"
                        ? theme.palette.quinary?.main
                        : theme.palette.secondary?.main
            }}
            src={user?.user_preferences?.avatar ? ("../../assets/img/avatar/" + user?.user_preferences?.avatar + ".png") : undefined}
        />
    );
};

export default observer(UserAvatar);
