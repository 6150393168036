import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import CurrentTimeIndicator from "./CurrentTimeIndicator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { RootStoreContext } from "api";
import { isEqual, startOfDay } from "date-fns";
import AdjustIcon from "@mui/icons-material/Adjust";

interface ScheduleHeaderProps {
    children?: React.ReactNode;
    zoom: number;
    ref?: React.RefObject<HTMLDivElement>;
    onScroll: (amount: number) => void;
}

const ScheduleHeader: React.FC<ScheduleHeaderProps> = observer(function ScheduleHeader(props) {

    const theme = useTheme();



    const hours = [...Array(24).keys()]; // create an array with numbers from 0 to 23 for the hours
    const rootStore = React.useContext(RootStoreContext);
    const { date, setDate } = rootStore.schedulerStore;
    const [scrollX, setScrollX] = React.useState(0);

    const isToday = () => {
        const startDate = startOfDay(new Date(date));
        const today = startOfDay(new Date());

        return isEqual(startDate, today);
    };

    const handleAddDayClick = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
    };

    const handleMinusDayClick = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        setDate(newDate);
    };

    const handleMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
        window.addEventListener("wheel", (event) => handleWheel(event), { passive: false });
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        window.removeEventListener("wheel", (event) => console.log("Mouse leave"));
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        // Set the cursor to "grabbing"
        (event.currentTarget as HTMLDivElement).style.cursor = "grabbing";
    };

    const handleMouseUp = (event: any) => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        event.currentTarget.style.cursor = "grab";
    };

    const handleMouseMove = (event: MouseEvent) => {
        props.onScroll(event.movementX);
    };

    const handleWheel = (event) => {
        event.preventDefault();
        console.log("Mouse wheel scrolled!");
    };

    useEffect(() => {}, [date]);

    return (
        <Box
            id="schedule-header"
            sx={{
                position: "sticky",
                top: 0,
                left: 0,
                right: 0,
                backgroundColor: theme.palette.scheduleRowStart?.main,
                height: "60px",
                width: 1440 * props.zoom + 400,
                maxWidth: 1440 * props.zoom + 400,
                zIndex: 3,
                display: "flex",
                flexDirection: "row",
                //overflowY: "visible",
                cursor: "grab"
            }}
            onMouseDown={(event) => {
                handleMouseDown(event);
            }}
            onMouseUp={(event) => {
                handleMouseUp(event);
            }}>
            <Box
                sx={{
                    height: "100%",
                    width: "300px",
                    position: "sticky",
                    left: 0,
                    flexShrink: 0,
                    flexGrow: 0,
                    flexBasis: "300px",
                    backgroundColor: theme.palette.scheduleRowStart?.dark,
                    zIndex: 3,
                    padding: 1,
                    display: "flex",
                    alignItems: "center"
                }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date"
                        value={date}
                        format="dd/MM/yyyy"
                        onChange={(newValue: any) => {
                            setDate(new Date(newValue));
                        }}
                        slotProps={{
                            textField: { variant: "outlined", size: "small", sx: { marginBottom: 0 } }
                        }}
                    />
                    <Tooltip title="Go To Live">
                        <IconButton
                            sx={{ marginLeft: 1 }}
                            onClick={() => {
                                setDate(new Date());
                            }}>
                            <AdjustIcon color="error" />
                        </IconButton>
                    </Tooltip>
                </LocalizationProvider>
            </Box>
            <Box
                sx={{
                    height: "100%",
                    width: 50,
                    flexShrink: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "sticky",
                    left: 300,
                    zIndex: 1
                }}>
                <IconButton
                    onClick={handleMinusDayClick}
                    sx={{
                        backgroundColor: theme.palette.scheduleRowStart?.dark
                    }}>
                    <ArrowBackIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    height: "100%",
                    width: 0,
                    flexShrink: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRight: "1px dashed rgba(160,160,160,0.1)"
                }}></Box>
            {hours.map((hour) => (
                <Box
                    key={hour}
                    sx={{
                        height: "100%",
                        width: 60 * props.zoom,
                        maxWidth: 60 * props.zoom,
                        borderRight: "1px dashed rgba(160,160,160,0.1)",
                        flexShrink: 0,
                        padding: 1,
                        //transition: "all 0.2s ease-in-out",
                        userSelect: "none",
                        position: "relative"
                    }}>
                    {hour}:00
                    <Box
                        sx={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: "10px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-end"
                        }}>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.0)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "10px", backgroundColor: "rgba(160,160,160,0.4)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "10px", backgroundColor: "rgba(160,160,160,0.4)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "10px", backgroundColor: "rgba(160,160,160,0.4)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.2)" }}></Box>
                        <Box sx={{ width: "1px", height: "5px", backgroundColor: "rgba(160,160,160,0.0)" }}></Box>
                    </Box>
                </Box>
            ))}
            <Box
                sx={{
                    height: "100%",
                    width: 50,
                    flexShrink: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "sticky",
                    right: 0
                }}>
                <IconButton
                    id="add-day"
                    onClick={handleAddDayClick}
                    sx={{
                        backgroundColor: theme.palette.scheduleRowStart?.dark
                    }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
            {props.children}
            {isToday() && <CurrentTimeIndicator zoom={props.zoom} header />}
        </Box>
    );
});

export default ScheduleHeader;
