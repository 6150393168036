import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IMyGuestOrganisationsEntry, RootStoreContext } from "api";
import { ConfirmLeaveOrganisation } from "./ConfirmLeaveOrganisation";
import { OrganisationContactDetails } from "./OrganisationContactDetails";
import GuestOrgTableBody from "./GuestOrgTableBody";
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Fade,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import InfoItem from "components/InfoItem";
import CopyToClipboard from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";

const GuestOrganisationTable = (props: { org: IMyGuestOrganisationsEntry }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { nOfActiveDestOrgs } = rootStore.sourcePermissionsStore;
    const { org } = props;
    const [open, setOpen] = useState(false);
    const [emailCopied, setEmailCopied] = useState(false);
    const [phoneCopied, setPhoneCopied] = useState(false);

    const onLeaveOrg = () => {
        openDialog(<ConfirmLeaveOrganisation org={org} />);
    };

    const strArr = org.status.split(" ");
    for (let i = 0; i < strArr.length; i++) {
        strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
    }

    return (
        <>
            <TableRow className={open ? "active" : undefined}>
                <TableCell align="left">
                    {nOfActiveDestOrgs > 0 && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell component="td" scope="row" onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
                    {`${org.organisation_name}`}
                </TableCell>
                <TableCell align="left">{`${strArr}`}</TableCell>
                <TableCell align="left">{`${org.billing_source_ui}`}</TableCell>
                <TableCell align="right">
                    <Button variant="contained" onClick={() => onLeaveOrg()}>
                        Leave
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        padding: 0,
                        borderTop: "none"
                    }}
                    colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Card>
                            <CardContent>
                                <Stack spacing={2} direction="column">
                                    <Box>
                                        <Box>
                                            <Typography variant="h4">Organisation Contact Details</Typography>
                                        </Box>

                                        <Box sx={{ mt: 2 }}>
                                            <Table style={{ marginTop: 30 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Email</TableCell>
                                                        <TableCell>Phone</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody
                                                    sx={{
                                                        ".input-source-access-row": {
                                                            ".source-table-container:last-child": {
                                                                td: {
                                                                    borderBottomWidth: 0
                                                                }
                                                            }
                                                        }
                                                    }}>
                                                    {org.shared_contacts.map((contact, i) => {
                                                        return (
                                                            <>
                                                                <TableCell>
                                                                    <Typography>{contact.name}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box>
                                                                        <Stack spacing={1} direction="row" alignItems="center">
                                                                            <Typography>{contact.email}</Typography>
                                                                            <CopyToClipboard
                                                                                text={contact.email}
                                                                                onCopy={() => {
                                                                                    setEmailCopied(true);
                                                                                    setPhoneCopied(false);
                                                                                }}>
                                                                                <Tooltip
                                                                                    TransitionComponent={Fade}
                                                                                    TransitionProps={{
                                                                                        timeout: 600
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title="Copy to clipboard">
                                                                                    <Box alignItems={"center"} sx={{ display: "flex" }}>
                                                                                        <IconButton aria-label="Copy to clipboard">
                                                                                            <ContentCopy />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            </CopyToClipboard>
                                                                        </Stack>
                                                                        {emailCopied ? (
                                                                            <Box alignItems={"center"} sx={{ display: "flex" }}>
                                                                                <Typography sx={{ opacity: 0.5 }}>Copied</Typography>
                                                                            </Box>
                                                                        ) : null}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box>
                                                                        <Stack spacing={1} direction="row" alignItems="center">
                                                                            <Typography>{contact.phone}</Typography>
                                                                            <CopyToClipboard
                                                                                text={contact.phone}
                                                                                onCopy={() => {
                                                                                    setPhoneCopied(true);
                                                                                    setEmailCopied(false);
                                                                                }}>
                                                                                <Tooltip
                                                                                    TransitionComponent={Fade}
                                                                                    TransitionProps={{
                                                                                        timeout: 600
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title="Copy to clipboard">
                                                                                    <Box alignItems={"center"} sx={{ display: "flex" }}>
                                                                                        <IconButton aria-label="Copy to clipboard">
                                                                                            <ContentCopy />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            </CopyToClipboard>
                                                                        </Stack>
                                                                        {phoneCopied ? (
                                                                            <Box alignItems={"center"} sx={{ display: "flex" }}>
                                                                                <Typography sx={{ opacity: 0.5 }}>Copied</Typography>
                                                                            </Box>
                                                                        ) : null}
                                                                    </Box>
                                                                </TableCell>
                                                            </>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Box>

                                        <Box sx={{ mt: 2 }}>
                                            <Table size="small" style={{ marginTop: 30 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={65} />
                                                        <TableCell align="center" width="50%">
                                                            Environments
                                                        </TableCell>
                                                        <TableCell align="center" width="50%">
                                                            Max Destinations
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {org.channels_in_environments.map((env, i) => {
                                                        return <GuestOrgTableBody env={env} key={i} />;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default observer(GuestOrganisationTable);
