import React, { useContext, useState, useEffect } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { TextField } from "mui-rff";
import { IMultiChanDetails, ITileInputs, RootStoreContext } from "api";
import { MultiChannelEditedMessage } from "./MultiChannelEditedMessage";
import { Alert, AlertTitle, Box, Button, Stack, Typography } from "@mui/material";
import { SelectField } from "components/Forms";
import { useSources } from "hooks";

interface IProps {
    id: string;
    name: string;
}
interface IChanDropdownEntry {
    key: string;
    text: string;
    value: string;
}

const MVTilesEditForm: React.FC<IProps> = ({ id, name }) => {
    const rootStore = useContext(RootStoreContext);
    const { editMultiChannelSources, getMultiViewerInfo, getInputsDropdownList } = useSources();
    const { closeDrawer } = rootStore.drawerStore;
    const { openDialog } = rootStore.dialogStore;
    const [loading, setLoading] = useState(true);
    const [multiViewerDetails, setMultiViewerDetails] = useState<IMultiChanDetails | null>(null);
    const [channelsDropdown, setChannelsDropdown] = useState<IChanDropdownEntry[] | null>(null);
    const [initialValues, setInitialValues] = useState<ITileInputs | null>(null);

    useEffect(() => {
        getMultiViewerInfo(id).then((values) => {
            setMultiViewerDetails(values);
            setLoading(false);

            let initialVars: ITileInputs = { tile_inputs: values.tile_inputs };
            initialVars.tile_inputs.forEach((item) => {
                if (item.input_id === null) {
                    item.input_id = "-1";
                }
            });
            setInitialValues(initialVars);
        });

        getInputsDropdownList().then((values) => {
            let dropdownValues: IChanDropdownEntry[] = [
                {
                    key: "-1",
                    text: "- no source -",
                    value: "-1"
                }
            ];
            if (values) {
                values.data
                    .filter((entry) => !entry.is_multi_viewer)
                    .forEach((entry) => {
                        dropdownValues.push({
                            key: entry.id,
                            text: entry.name,
                            value: entry.id
                        });
                    });
            }
            setChannelsDropdown(dropdownValues);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <FinalForm
                onSubmit={(values: ITileInputs) =>
                    editMultiChannelSources(id, values)
                        .then(() => {
                            openDialog(
                                <MultiChannelEditedMessage
                                    title="Tiles Updated"
                                    message="The tile setup on your multiviewer has been successfully updated."
                                />,
                                undefined,
                                "xs"
                            );
                            closeDrawer();
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error
                        }))
                }
                initialValues={initialValues}
                render={({ handleSubmit, submitting, submitError, invalid, dirtySinceLastSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ p: 2 }}>
                            <div style={{ width: "75px", margin: "0 20px 20px auto" }}>
                                <div style={{ color: "#ffffff", fontSize: "12px", textAlign: "left" }}>Powered by</div>
                                <img
                                    src="/assets/img/tag_500px.png"
                                    width="75"
                                    style={{ margin: "0", padding: "0" }}
                                    alt="Powered by TAG"
                                    title="Powered by TAG"
                                />
                            </div>
                            <Typography variant={"h4"} sx={{ mb: 1 }}>
                                Update Multiviewer Tiles
                            </Typography>

                            <Typography variant={"h6"} sx={{ mb: 3 }}>
                                Name: {name}
                            </Typography>

                            <Stack spacing={2} sx={{ mb: 3 }}>
                                {Array(multiViewerDetails?.number_of_tiles)
                                    .fill(0)
                                    .map((_, index) => {
                                        const tile_number = index + 1;
                                        const field_name = `tile_inputs[${index}]`;
                                        return (
                                            <Stack key={tile_number} spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    name={`${field_name}.title`}
                                                    type="text"
                                                    label={`Tile ${tile_number} title`}
                                                />

                                                {channelsDropdown && (
                                                    <SelectField
                                                        name={`${field_name}.input_id`}
                                                        label={`Tile ${tile_number} source`}
                                                        placeholder={`Select input for tile ${tile_number}`}
                                                        options={channelsDropdown}
                                                        helperText={(meta) => meta.touched && meta.error}
                                                        error={(meta) => meta.touched && meta.error}
                                                    />
                                                )}
                                            </Stack>
                                        );
                                    })}
                            </Stack>

                            {submitError && !dirtySinceLastSubmit && (
                                <Box sx={{ width: "100%", px: 2, mb: 3 }}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {submitError}
                                    </Alert>
                                </Box>
                            )}

                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 2, mb: 4 }}>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        closeDrawer();
                                    }}
                                    sx={{ margin: 0, width: 130 }}>
                                    Cancel
                                </Button>

                                <Button variant="contained" type="submit" disabled={invalid} sx={{ margin: 0, width: 130 }}>
                                    Update
                                </Button>
                            </Stack>
                        </Box>
                    </form>
                )}
            />
        </div>
    );
};
export default MVTilesEditForm;
