export const pruneEmptyErrors = (errors) => {
    if (!errors) {
        console.log("No errors to prune");
        return {};
    }

    const isObject = (val) => val && typeof val === "object" && !Array.isArray(val);
    const visited = new WeakSet();

    // Recursively prune objects and arrays
    const prune = (obj) => {
        if (visited.has(obj)) {
            return undefined;
        }

        if (Array.isArray(obj)) {
            visited.add(obj);
            const newArray = obj
                .map((item) => (isObject(item) || Array.isArray(item) ? prune(item) : item))
                .filter(
                    (item) =>
                        item !== null && item !== undefined && !(isObject(item) && isEmpty(item)) && !(Array.isArray(item) && !item.length)
                );
            return newArray.length > 0 ? newArray : undefined;
        } else if (isObject(obj)) {
            visited.add(obj);
            Object.keys(obj).forEach((key) => {
                const pruned = prune(obj[key]);
                if (
                    pruned === undefined ||
                    pruned === null ||
                    (isObject(pruned) && isEmpty(pruned)) ||
                    (Array.isArray(pruned) && !pruned.length)
                ) {
                    delete obj[key];
                } else {
                    obj[key] = pruned;
                }
            });
            return Object.keys(obj).length > 0 ? obj : undefined;
        }
        return obj;
    };

    // Check if object is empty
    const isEmpty = (obj) => {
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                return false;
            }
        }
        return true;
    };

    return prune(errors) || {};
};
