import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const PreventDeletion = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>Can not delete alert method</DialogTitle>
            <DialogContent>This method has alerts associated with it and can not be deleted.</DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => closeDialog()}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};
