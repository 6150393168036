import { AccessTime, AddCircle, ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Stack,
    Typography
} from "@mui/material";
import { RootStoreContext, agent } from "api";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import {
    addDays,
    addHours,
    addWeeks,
    endOfDay,
    endOfWeek,
    format,
    startOfDay,
    startOfWeek,
    subWeeks
} from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import SchedulerViewSelect from "../Components/SchedulerViewSelect";
import CreateRepeatScheduleForm from "../Components/CreateRepeatScheduleForm";
import CurrentTimeIndicator from "./Components/CurrentTimeIndicator";
import HourCell from "./Components/HourCell";
import ScheduleItem from "./Components/ScheduleItem";
import ClipboardMenu from "../Month/Components/ClipboardMenu";
import RowStart from "./Components/RowStart";
import CreateButton from "../Components/CreateButton";

const SchedulerWeek: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { date, setDate } = rootStore.schedulerStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_env_id = rootStore.userStore.activeEnvironment;

    const { environment, currentEnvRunning } = rootStore.environmentStore;
    const active_environment = rootStore.userStore.activeEnvironment;
    const { token } = rootStore.commonStore;

    const [destinations, setDestinations] = useState<any[]>([]);
    //const [activeDestination, setActiveDestination] = useState<any>();
    const { activeDestination, setActiveDestination } = rootStore.schedulerStore;

    const handleDestinationClick = (destination: any) => {
        console.log("Destination Clicked", destination);
        setActiveDestination(destination);
    };

    const { openDialog } = rootStore.dialogStore;

    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(new Date());
    const [schedule, setSchedule] = useState<any[]>([]);

    const { client, clientConnected } = rootStore.resClientStore;

    const [copiedItem, setCopiedItem] = useState<any>();

    const fetchSchedule = async () => {
        await agent.Scheduler.getSchedules()
            .then((response) => {
                setSchedule(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchSchedule();
    }, []);

    const onUpdate = () => {
        setUpdate(new Date());
    };

    useEffect(() => {
        setLoading(true);

        clientConnected &&
            client
                .get(`environments.outputs.${managing_org_id}.${active_env_id}`)
                .then((res: any) => {
                    res.on("add", () => {
                        onUpdate();

                        fetchSchedule();
                    });

                    res.on("remove", () => {
                        onUpdate();
                        fetchSchedule();
                    });
                    Array.from(res).map((destination: any) => {
                        destination.on("change", () => {
                            onUpdate();
                            fetchSchedule();
                        });
                    });
                    setDestinations(res);
                    setLoading(false);
                    onUpdate();
                    //console.log("Scheduler Data", res);
                })
                .catch((err) => {
                    console.error("Scheduler Error", err);
                    if (err.message === "No auth info provided") {
                        client.disconnect();
                        rootStore.resClientStore.initialize(token!);
                    }
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            //setData([]);
        };
    }, [managing_org_id, rootStore.commonStore.token, clientConnected, client, active_env_id, token, currentEnvRunning]);

    useEffect(() => {}, [update, activeDestination]);

    const renderDays = () => {
        const dateFormat = "EEEE do";
        const days: any = [];

        let startDate = startOfWeek(date, { weekStartsOn: 0 });

        for (let i = 0; i < 7; i++) {
            days.push(
                <Grid item key={i} xs={1} textAlign="center" padding={1}>
                    {format(addDays(startDate, i), dateFormat)}
                </Grid>
            );
        }

        return (
            <Grid container columns={7} width={"100%"}>
                {days}
            </Grid>
        );
    };

    const renderColumns = () => {
        const dateFormat = "EEEE";
        const days: any = [];

        let startDate = startOfWeek(date, { weekStartsOn: 0 });

        for (let add_days = 0; add_days < 7; add_days++) {
            const this_column_date = addDays(startDate, add_days);
            //const is_today: boolean = startOfDay(new Date()) === startOfDay(this_column_date);
            let is_today: boolean = false;
            if (format(new Date(), "yyyMMdd") === format(this_column_date, "yyyMMdd")) {
                is_today = true;
            }

            days.push(
                <Grid
                    item
                    key={add_days}
                    xs={1}
                    textAlign="center"
                    padding={0}
                    sx={{
                        borderRightWidth: 1,
                        borderRightStyle: "solid",
                        borderRightColor: (theme) => theme.palette.divider,
                        height: 1440,
                        position: "relative"
                    }}>
                    {Array.from({ length: 24 }).map((_, index) => (
                        <HourCell
                            key={index}
                            hour={index}
                            date={addHours(startOfDay(this_column_date), index)}
                            destination_id={activeDestination?.destination_id}
                            copiedItem={copiedItem}
                        />
                    ))}
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            px: 1,
                            pointerEvents: "none"
                        }}>
                        {activeDestination?.schedules
                            .sort((a: any, b: any) => {
                                return new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
                            })
                            .filter((schedule: any) => {
                                const start = startOfDay(new Date(schedule.start_time));
                                const end = endOfDay(new Date(schedule.end_time));
                                // Check if the schedule is within the current day
                                return start <= this_column_date && end >= this_column_date;
                            })
                            .map((schedule: any, s_index: number) => {
                                return (
                                    <ScheduleItem
                                        key={s_index}
                                        item={schedule}
                                        this_column_date={this_column_date}
                                        destination_id={activeDestination?.destination_id}
                                        onCopy={() => {
                                            setCopiedItem(schedule);
                                        }}
                                    />
                                );
                            })}
                    </Box>
                    {is_today && <CurrentTimeIndicator />}
                </Grid>
            );
        }

        return (
            <Grid container columns={7} width={"100%"}>
                {days}
            </Grid>
        );
    };

    return (
        <View>
            <ViewHeader title="Scheduler" subtitle={"Week" + (activeDestination !== null ? (" - " + activeDestination.name) : "") } />
            <ViewBody noPadding>
                <Content
                    noPadding
                    leftContent={
                        <List disablePadding>
                            <ListSubheader>Destinations</ListSubheader>
                            {destinations &&
                                Array.from(destinations)
                                    .filter((destination) => !destination.is_permanently_scheduled)
                                    .map((destination: any, i: number) => {
                                        return (
                                            <ListItem key={i} disablePadding>
                                                <ListItemButton
                                                    sx={{ pl: 1 }}
                                                    selected={activeDestination?.destination_id === destination.destination_id}
                                                    onClick={() => {
                                                        handleDestinationClick(destination);
                                                    }}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            checked={activeDestination?.destination_id === destination.destination_id}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography variant="body1" fontWeight="bold">
                                                            {destination.name}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" textTransform="capitalize">
                                                            {destination.connection_status}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                        </List>
                    }
                    toolbarContent={
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button variant="toolbar" onClick={() => setDate(new Date())}>
                                    This Week
                                </Button>
                                <Stack direction="row" spacing={0} alignItems="center">
                                    <IconButton onClick={() => setDate(subWeeks(date, 1))} size="small">
                                        <ChevronLeft />
                                    </IconButton>
                                    <IconButton onClick={() => setDate(addWeeks(date, 1))} size="small">
                                        <ChevronRight />
                                    </IconButton>
                                </Stack>
                                <Typography variant="h5">
                                    Week {format(startOfWeek(date), "w: do MMMM yyyy")} - {format(endOfWeek(date), "do MMMM yyyy")}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <SchedulerViewSelect />
                                <ClipboardMenu
                                    selectedDate={date}
                                    copiedItem={copiedItem}
                                    onPaste={() => {
                                        const startTime = new Date(
                                            new Date(date).getFullYear(),
                                            new Date(date).getMonth(),
                                            new Date(date).getDate(),
                                            new Date(copiedItem.start_time).getHours(),
                                            new Date(copiedItem.start_time).getMinutes()
                                        );
                                        const endTime = new Date(
                                            new Date(date).getFullYear(),
                                            new Date(date).getMonth(),
                                            new Date(date).getDate(),
                                            new Date(copiedItem.end_time).getHours(),
                                            new Date(copiedItem.end_time).getMinutes()
                                        );
                                        openDialog(
                                            <CreateRepeatScheduleForm
                                                source_id={copiedItem.source_id}
                                                destination_id={copiedItem.destination_id}
                                                start_date={startTime}
                                                end_date={endTime}
                                            />
                                        );
                                    }}
                                    onClear={() => {
                                        setCopiedItem(null);
                                    }}
                                />
                                <CreateButton start_date={date} end_date={addHours(date, 1)} />
                            </Stack>
                        </Box>
                    }
                    headerContent={
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                pr: "10px",
                                borderLeftWidth: 1,
                                borderLeftStyle: "solid",
                                borderLeftColor: (theme) => theme.palette.divider
                            }}>
                            <Box
                                sx={{
                                    width: 60,
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    padding: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRightWidth: 1,
                                    borderRightStyle: "solid",
                                    borderRightColor: (theme) => theme.palette.divider,
                                    backgroundColor: "rgba(0,0,0,0.1)"
                                }}>
                                <AccessTime />
                            </Box>
                            {renderDays()}
                        </Box>
                    }>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            borderLeftWidth: 1,
                            borderLeftStyle: "solid",
                            borderLeftColor: (theme) => theme.palette.divider
                        }}>
                        <Box
                            sx={{
                                width: 60,
                                flexGrow: 0,
                                flexShrink: 0,
                                borderRightWidth: 1,
                                borderRightStyle: "solid",
                                borderRightColor: (theme) => theme.palette.divider,
                                backgroundColor: "rgba(0,0,0,0.1)"
                            }}>
                            {Array.from({ length: 24 }).map((_, i) => (
                                <RowStart hour={i} key={i} />
                            ))}
                        </Box>
                        {renderColumns()}
                    </Box>
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(SchedulerWeek);
