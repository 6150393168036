import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddCircle, ArrowDropDown, GridView, SwapHorizontalCircle, Videocam } from "@mui/icons-material";
import { Button, ButtonGroup, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { RootStoreContext } from "api";
import useModuleAccess from "hooks/UseModuleAccess";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

export enum SourceMenuArea {
    SOURCES = "sources",
    TRANSCODERS = "transcoders",
    MULTIVIEWERS = "multiviewers"
}

export interface ISourcesMenu {
    area?: SourceMenuArea;
}

const SourcesMenu: React.FC<ISourcesMenu> = ({ area = SourceMenuArea.SOURCES }) => {
    const rootStore = useContext(RootStoreContext);
    const { currentEnvRunning, environment, currentEnvCloudProvider } = rootStore.environmentStore;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };
    const hasTag = useModuleAccess("tag") && currentEnvCloudProvider === "aws";

    const setCreateLink = () => {
        switch (area) {
            case SourceMenuArea.TRANSCODERS:
                return "/transcoders/create";
            case SourceMenuArea.MULTIVIEWERS:
                return "/multiviewers/create";
            default:
                return "/sources/create";
        }
    };

    return (
        <>
            <ButtonGroup variant="contained" color="primary" disabled={!currentEnvRunning}>
                <Button
                    onClick={() => {
                        navigate(setCreateLink());
                    }}
                    startIcon={<AddCircle />}>
                    Create
                </Button>
                <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="create-button"
                    aria-haspopup="menu"
                    sx={{ minWidth: "32px !important", width: 32 }}
                    onClick={handleClick}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Menu
                id="create-menu"
                MenuListProps={{
                    "aria-labelledby": "create-button"
                }}
                sx={{
                    minWidth: 300,
                    p: 0,
                    ".MuiList-root": {
                        display: "flex",
                        flexDirection: "column"
                    }
                }}
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={open}
                onClose={(e) => {
                    handleClose(e);
                }}>
                <MenuItem
                    onClick={() => {
                        navigate("/sources/create");
                    }}
                    sx={{ order: area === SourceMenuArea.SOURCES ? 0 : 1 }}>
                    <ListItemIcon>
                        <Videocam />
                    </ListItemIcon>
                    <ListItemText primary="Create Source" />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate("/transcoders/create");
                    }}
                    sx={{ order: area === SourceMenuArea.TRANSCODERS ? 0 : 1 }}>
                    <ListItemIcon>
                        <SwapHorizontalCircle />
                    </ListItemIcon>
                    <ListItemText primary="Create Transcoder" />
                </MenuItem>
                {hasTag && (
                    <MenuItem
                        onClick={() => {
                            navigate("/multiviewers/create");
                        }}
                        sx={{ order: area === SourceMenuArea.MULTIVIEWERS ? 0 : 1 }}>
                        <ListItemIcon>
                            <GridView />
                        </ListItemIcon>
                        <ListItemText primary="Create Multiviewer" />
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default SourcesMenu;
