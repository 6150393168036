import React, { useContext, useState, useEffect } from "react";
import { IMultiChanAudio, IMVAudioTrack, RootStoreContext } from "api";
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Refresh, VolumeMute, VolumeUp } from "@mui/icons-material";
import LoadingComponent from "app/layout/LoadingComponent";
import { useSources } from "hooks";

interface IProps {
    name: string;
    id: string;
}

interface ITableParams {
    rows: number;
    columns: number;
    tileWidth: string;
    tileHeight: string;
}

export const MVAudioSwitch: React.FC<IProps> = ({ name, id }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { closeDrawer } = rootStore.drawerStore;
    const { getMultiViewerInfo, listMultiViewerAudioTracks, switchMVActiveAudioTrack } = useSources();
    const [mvInfoLoaded, setMvInfoLoaded] = useState(false);
    const [audioTracksLoaded, setAudioTracksLoaded] = useState(false);
    const [audioTracks, setAudioTracks] = useState<IMultiChanAudio | null>(null);
    const [tableParams, setTableParams] = useState<ITableParams | null>(null);
    const [allMuted, setAllMuted] = useState<boolean | null>(null);
    const [isMvRunning, setIsMvRunning] = useState<boolean | null>(null);

    const loadTracks = (with_timeout: boolean = false) => {
        listMultiViewerAudioTracks(id).then((values) => {
            if (with_timeout) {
                setTimeout(() => {
                    setAudioTracks(values);
                    setAllMuted(values.all_muted);
                    setAudioTracksLoaded(true);
                }, 500);
            } else {
                setAudioTracks(values);
                setAllMuted(values.all_muted);
                setAudioTracksLoaded(true);
            }
        });
    };

    useEffect(() => {
        getMultiViewerInfo(id).then((values) => {
            // setMultiViewerDetails(values);
            const mvRunning = values.is_running;
            setIsMvRunning(mvRunning);
            setMvInfoLoaded(true);

            if (mvRunning) {
                switch (values.number_of_tiles) {
                    case 4:
                        setTableParams({ columns: 2, rows: 2, tileWidth: "50%", tileHeight: "265px" });
                        break;
                    case 6:
                        setTableParams({ columns: 3, rows: 2, tileWidth: "33%", tileHeight: "265px" });
                        break;
                    case 9:
                        setTableParams({ columns: 3, rows: 3, tileWidth: "33%", tileHeight: "170px" });
                        break;
                    case 12:
                        setTableParams({ columns: 4, rows: 3, tileWidth: "25%", tileHeight: "170px" });
                        break;
                    case 16:
                        setTableParams({ columns: 4, rows: 4, tileWidth: "25%", tileHeight: "150px" });
                        break;
                    default:
                        throw Error(`Unexpected tile count ${values.number_of_tiles}`);
                }
                loadTracks();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let current_tile_number = 0;

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%"
                }}>
                <Box style={{ marginBottom: 10 }}>
                    <Typography variant="h5">Switch active audio</Typography>
                    <Typography variant="h6">Name: {name}</Typography>
                </Box>
                <Box>
                    <Typography fontSize={11}>Powered by</Typography>
                    <img
                        src="/assets/img/tag_500px.png"
                        width="75"
                        style={{ margin: "0", padding: "0" }}
                        alt="Powered by TAG"
                        title="Powered by TAG"
                    />
                </Box>
            </Box>

            <div style={{ padding: 30 }}>
                {!mvInfoLoaded || !audioTracksLoaded || isMvRunning === false ? (
                    <>
                        {isMvRunning === false ? (
                            <div
                                style={{
                                    color: "red",
                                    textAlign: "center",
                                    fontSize: "1.3em",
                                    fontWeight: "bold",
                                    padding: "40px 0 60px 0"
                                }}>
                                Multiviewer is not running yet.
                                <br />
                                Please wait for it to start and try again.
                            </div>
                        ) : (
                            <div style={{}}>
                                <LoadingComponent />
                            </div>
                        )}
                    </>
                ) : (
                    <div style={{ marginBottom: 30 }}>
                        <Table
                            sx={{
                                width: "100%",
                                borderCollapse: "separate",
                                borderSpacing: "10px"
                            }}>
                            <TableBody>
                                {Array(tableParams!.rows)
                                    .fill(0)
                                    .map((_, rowIndex) => {
                                        return (
                                            <TableRow key={rowIndex}>
                                                {Array(tableParams!.columns)
                                                    .fill(0)
                                                    .map(() => {
                                                        current_tile_number++;
                                                        let audioInTileOn = false;
                                                        let tileTracks: IMVAudioTrack[] = [];
                                                        let tileTitle: string | null = null;
                                                        audioTracks!.data.forEach((entry) => {
                                                            if (entry.tile_nr === current_tile_number) {
                                                                tileTitle = entry.tile_title;
                                                                entry.audio_tracks.forEach((track) => {
                                                                    if (track.is_currently_on) {
                                                                        audioInTileOn = true;
                                                                    }
                                                                });
                                                                tileTracks = entry.audio_tracks;
                                                            }
                                                        });

                                                        return (
                                                            <TableCell
                                                                key={current_tile_number}
                                                                sx={{
                                                                    border: audioInTileOn ? "1px solid red" : "1px solid #6299c8",
                                                                    p: 2,
                                                                    color: "white",
                                                                    fontSize: "14px",
                                                                    verticalAlign: "top",
                                                                    width: tableParams!.tileWidth,
                                                                    height: tableParams!.tileHeight
                                                                }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: "#6299c8",
                                                                        marginBottom: 1,
                                                                        fontWeight: "bold",
                                                                        fontSize: "16px"
                                                                    }}>
                                                                    {tileTitle}
                                                                </Typography>
                                                                <Table
                                                                    sx={{
                                                                        width: "100%",
                                                                        borderCollapse: "collapse",
                                                                        borderSpacing: "0 !important",
                                                                        border: "none"
                                                                    }}>
                                                                    <TableHead
                                                                        sx={{
                                                                            fontWeight: "bold",

                                                                            height: "auto",
                                                                            color: "#999999",
                                                                            borderBottom: "1px solid #383838"
                                                                        }}>
                                                                        <TableRow>
                                                                            <TableCell sx={{ p: 1, width: "25%" }}>PID</TableCell>
                                                                            <TableCell sx={{ p: 1, width: "35%" }}>Codec</TableCell>
                                                                            <TableCell sx={{ p: 1, width: "35%" }}>Language</TableCell>
                                                                            <TableCell></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody
                                                                        sx={{
                                                                            "& tr": {
                                                                                "& td": {
                                                                                    border: "1px solid #383838",
                                                                                    borderRight: "none",
                                                                                    borderBottom: "none",
                                                                                    borderLeft: "none",
                                                                                    padding: "3px 0 3px 0",
                                                                                    height: "auto"
                                                                                },
                                                                                "& td:nth-last-child(1)": {
                                                                                    textAlign: "right"
                                                                                }
                                                                            },
                                                                            "& tr.activeAudio td": {
                                                                                color: "red"
                                                                            }
                                                                        }}>
                                                                        {tileTracks.map((track) => {
                                                                            return (
                                                                                <tr key={track.id}>
                                                                                    <td>{track.listen_to_payload.pid}</td>
                                                                                    <td>{track.codec}</td>
                                                                                    <td>{track.language}</td>
                                                                                    <td>
                                                                                        {track.is_currently_on ? (
                                                                                            <VolumeUp sx={{ color: "red", fontSize: 16 }} />
                                                                                        ) : (
                                                                                            <a
                                                                                                href="/"
                                                                                                onClick={(event) => {
                                                                                                    event.preventDefault();
                                                                                                    switchMVActiveAudioTrack(
                                                                                                        id,
                                                                                                        track.listen_to_payload
                                                                                                    ).then(() => {
                                                                                                        setTimeout(() => {
                                                                                                            loadTracks();
                                                                                                        }, 100);
                                                                                                    });
                                                                                                }}>
                                                                                                listen
                                                                                            </a>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        );
                                                    })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                )}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "30px"
                    }}>
                    <Button
                        onClick={() => {
                            closeDialog();
                            closeDrawer();
                        }}>
                        Close
                    </Button>

                    <Stack spacing={2} direction="row">
                        <Button
                            onClick={() => {
                                setAudioTracksLoaded(false);
                                loadTracks(true);
                            }}
                            startIcon={<Refresh />}
                            variant="contained"
                            color="secondary"
                            disabled={!audioTracksLoaded || !mvInfoLoaded}>
                            Refresh Audio
                        </Button>

                        <Button
                            onClick={() => {
                                switchMVActiveAudioTrack(id, { turn_active_input_off: true }).then(() => {
                                    setTimeout(() => {
                                        loadTracks();
                                    }, 100);
                                });
                            }}
                            startIcon={<VolumeMute />}
                            variant="contained"
                            color="primary"
                            disabled={!audioTracksLoaded || !mvInfoLoaded || allMuted === true}>
                            Mute Active Audio
                        </Button>
                    </Stack>
                </Box>
            </div>
        </Box>
    );
};
