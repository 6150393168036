import React from "react";
import {
    Avatar,
    Box,
    Card,
    CardHeader,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import ErrorIcon from "@mui/icons-material/Error";
import { RootStoreContext } from "api";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import ConfirmStopDialog from "./ConfirmStopDialog";
import ConfirmStartDialog from "./ConfirmStartDialog";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import InfoItemVertical from "components/InfoItemVertical";
import RenameInstanceForm from "./RenameInstanceForm";
import { DriveFileRenameOutline } from "@mui/icons-material";

interface Props {
    rid: string;
    display_search?: string;
    display_type?: string;
    display_cloud_provider?: string;
    display_status?: string;
}

export const PullPackagerItem: React.FC<Props> = ({ rid, display_search, display_type, display_cloud_provider, display_status }) => {
    const rootStore = React.useContext(RootStoreContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [wsData, setWsData] = React.useState<any>(null);
    const [idCopied, setIdCopied] = React.useState<boolean>(false);
    const [dnsCopied, setDnsCopied] = React.useState<boolean>(false);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    React.useEffect(() => {
        let intervalId: NodeJS.Timeout;

        rootStore.resClientStore.clientConnected &&
        rootStore.resClientStore.client.get(rid).then((res: any) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });

                setWsData(res);
                console.log(res);
            });
        return () => {
            clearInterval(intervalId);
            setWsData(null);
        };
    }, [rootStore.resClientStore.client, rootStore.resClientStore.clientConnected, rid]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <Card
            sx={{ mb: 1 }}
            onClick={() => {
                navigate(`/pull-packager/${wsData?.id}`);
            }}>
            <CardHeader
                sx={{
                    flexWrap: "wrap",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    ".MuiCardHeader-title p": {
                        fontSize: "1rem"
                    },
                    "> .MuiCardHeader-content": {
                        marginRight: "16px",
                        flex: "0 1 auto"
                    },
                    "> .MuiCardHeader-action": {
                        flex: "1",
                        "> .MuiStack-root": {
                            "> .MuiBox-root": {
                                width: "100%",
                                maxWidth: "16rem",

                                "&:last-of-type": {
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    maxWidth: "unset"
                                }
                            },
                            "@media (max-width: 1100px)": {
                                "> .MuiCardHeader-content": {
                                    flex: "1 1 calc(50% - 16px)"
                                }
                            }
                        }
                    }
                }}
                title={
                    <Box
                        sx={{ display: "flex", alignItems: "center", maxWidth: "16rem" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                        <Typography noWrap fontSize={"1.15rem"}>
                            {wsData?.name}
                        </Typography>
                        <CopyToClipboard
                            text={wsData?.id}
                            onCopy={(event) => {
                                console.log(event);
                                setIdCopied(true);
                                setTimeout(() => {
                                    setIdCopied(false);
                                }, 2000);
                            }}>
                            <Box title="Copy ID to clipboard">
                                <ContentCopyIcon
                                    sx={{
                                        cursor: "pointer",
                                        fontSize: "11px",
                                        marginLeft: "5px",
                                        marginTop: "6px",
                                        opacity: 0.7,
                                        ":hover": { opacity: 1 }
                                    }}
                                />
                            </Box>
                        </CopyToClipboard>
                        <Tooltip open={idCopied} title="ID Copied" placement="right">
                            <Box></Box>
                        </Tooltip>
                    </Box>
                }
                subheader={<span style={{ textTransform: "capitalize" }}>{wsData?.status}</span>}
                avatar={
                    <Avatar
                        sx={{
                            backgroundColor:
                                wsData?.status === "running"
                                    ? theme.palette.success.main
                                    : wsData?.status === "crashed"
                                    ? theme.palette.error.main
                                    : undefined,
                            color: "white"
                        }}>
                        {wsData?.status === "running" ? (
                            <PlayArrowIcon />
                        ) : wsData?.status === "stopped" ? (
                            <StopIcon />
                        ) : wsData?.status === "crashed" ? (
                            <ErrorIcon />
                        ) : (
                            <HourglassFullIcon />
                        )}
                    </Avatar>
                }
                action={
                    <Stack direction="row" spacing={2} sx={{ mt: "6px", alignItems: "center" }}>
                        <InfoItemVertical title="Product Size" value={wsData?.nea_live?.product?.product_description} />
                        <InfoItemVertical title="DNS Name" value={wsData?.nea_live?.domain_name ? wsData?.nea_live?.domain_name : "-"} />
                        <Box>
                            <IconButton
                                aria-label="settings"
                                id="demo-customized-button"
                                aria-controls={open ? "demo-customized-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e)}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    "aria-labelledby": "demo-customized-button"
                                }}
                                sx={{ minWidth: 300 }}
                                anchorEl={anchorEl}
                                elevation={0}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                open={open}
                                onClose={handleClose}>
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleClose(event);
                                        navigate("/pull-packager/" + wsData?.id);
                                    }}
                                    disableRipple>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        openDialog(<RenameInstanceForm id={wsData?.id} />);
                                        handleClose(event);
                                    }}
                                    disableRipple>
                                    <ListItemIcon>
                                        <DriveFileRenameOutline />
                                    </ListItemIcon>
                                    Rename
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        openDialog(<ConfirmDeleteDialog id={wsData?.id} />);
                                        handleClose(event);
                                    }}>
                                    <ListItemIcon>
                                        <DeleteIcon />
                                    </ListItemIcon>
                                    Delete
                                </MenuItem>
                                <MenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        openDialog(<ConfirmStopDialog id={wsData?.id} />);
                                        handleClose(event);
                                    }}
                                    disabled={wsData?.status === "stopped"}
                                    disableRipple>
                                    <ListItemIcon>
                                        <StopIcon />
                                    </ListItemIcon>
                                    Stop
                                </MenuItem>
                                {/* startPushPackager */}
                                {wsData?.status === "stopped" && (
                                    <MenuItem
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            openDialog(<ConfirmStartDialog id={wsData?.id} />);
                                            handleClose(event);
                                        }}
                                        disableRipple>
                                        <ListItemIcon>
                                            <PlayArrowIcon />
                                        </ListItemIcon>
                                        Start
                                    </MenuItem>
                                )}
                            </Menu>
                        </Box>
                    </Stack>
                }
            />
        </Card>
    );
};

export default PullPackagerItem;
