import React from "react";

import { Alert, Box, Button, DialogActions, DialogContent } from "@mui/material";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { RadioGroupField, TextField } from "components/Forms";
import { agent, CreateScramblingPresetRequest, RootStoreContext } from "api";

interface CreatePresetFormProps {
    mode: "create" | "edit";
    id?: number;
    onSuccess: () => void;
}

const CreatePresetForm: React.FC<CreatePresetFormProps> = ({ mode, id, onSuccess }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const [initialValues, setInitialValues] = React.useState<CreateScramblingPresetRequest | undefined>(undefined);


    const getInitialValues = async () => {
        if (mode === "edit" && id) {
            agent.NeaLive.getScramblingPreset(id)
                .then((response) => {
                    console.log("response", response);
                    setInitialValues(response.preset);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    }

    React.useEffect(() => {
        getInitialValues();
    }, []);


    return (
        <>
            <Form
                onSubmit={(values: CreateScramblingPresetRequest) => {
                    if (mode === "edit" && id) {
                        return agent.NeaLive.updateScramblingPreset(id, values)
                            .then((response) => {
                                console.log("response", response);
                                onSuccess();
                                closeDialog();
                            })
                            .catch((error) => {
                                console.log("error", error);
                                const errors = error.data.errors;
                                return Promise.resolve({ [FORM_ERROR]: error, ...errors });
                            });
                    }
                    return agent.NeaLive.createScramblingPreset(values)
                        .then((response) => {
                            console.log("response", response);
                            onSuccess();
                            closeDialog();
                        })
                        .catch((error) => {
                            console.log("error", error);
                            const errors = error.data.errors;
                            return Promise.resolve({ [FORM_ERROR]: error, ...errors });
                        });
                }}
                initialValues={initialValues}
                render={({
                    submitError,
                    handleSubmit,
                    submitting,
                    values,
                    invalid,
                    form,
                    submitSucceeded,
                    submitErrors,
                    dirty,
                    dirtyFieldsSinceLastSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Box>
                                <TextField
                                    name="name"
                                    label="Name"
                                    required
                                    disabled={mode === "edit"}
                                    validate={(value) => {
                                        if (!value) {
                                            return "Name is required";
                                        }
                                    }}
                                />
                                <RadioGroupField
                                    row
                                    name="type"
                                    label="Type"
                                    options={[
                                        { value: "HLS", label: "HLS" },
                                        { value: "DASH", label: "DASH" }
                                    ]}
                                    required
                                    validate={(value) => {
                                        if (!value) {
                                            return "Type is required";
                                        }
                                    }}
                                    onChange={() => {
                                        //delete parameters_hls and parameters_dash
                                        delete values.parameters_hls;
                                        delete values.parameters_dash;
                                    }
                                    }
                                />

                                {values.type === "HLS" && (
                                    <>
                                        <TextField
                                            name="parameters_hls.key"
                                            label="Key"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Key is required";
                                                }
                                            }}
                                        />
                                        <TextField
                                            name="parameters_hls.license_server_url"
                                            label="License Server URL"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return "License Server URL is required";
                                                }
                                            }}
                                        />
                                        <TextField
                                            name="parameters_hls.key_iv"
                                            label="Key IV"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Key IV is required";
                                                }
                                            }}
                                        />
                                        <TextField
                                            name="parameters_hls.key_id"
                                            label="Key ID"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Key ID is required";
                                                }
                                            }}
                                        />
                                    </>
                                )}

                                {values.type === "DASH" && (
                                    <>
                                        <TextField
                                            name="parameters_dash.key"
                                            label="Key"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Key is required";
                                                }
                                            }}
                                        />
                                        <TextField
                                            name="parameters_dash.key_id"
                                            label="Key ID"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Key ID is required";
                                                }
                                            }}
                                        />
                                    </>
                                )}

                                {submitErrors && (
                                    <Alert severity="error" sx={{ mt: 2}}>
                                        {submitError.data?.message}
                                    </Alert>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={() => {
                                    closeDialog();
                                }}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!dirtyFieldsSinceLastSubmit && (submitting || invalid || (submitSucceeded && !dirty))}>
                                {mode === "create" ? "Create" : "Update"}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            />
        </>
    );
};

export default CreatePresetForm;
