import React, { useContext, useEffect, useState, useCallback } from "react";
import { ISingleTranscoderResponse, ITrasncoderAudioTrack, IUpdateTranscoderAudioTracks, RootStoreContext } from "api";
import LoadingComponent from "app/layout/LoadingComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import TranscoderAudioTrack from "./TranscoderAudioTrack";
import { UpdateSuccessMessage } from "./updateTranscoderMessage/UpdateSuccessMessage";
import { FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import { Field, Form as FinalForm } from "react-final-form";
import ErrorMessage from "app/common/form/ErrorMessage";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import { Alert, Box, IconButton, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import Content from "components/Layout/Content";
import { useSources } from "hooks";
import { ArrowDownward, ArrowUpward, Info, Refresh } from "@mui/icons-material";
import { FieldArray } from "react-final-form-arrays";
import { CheckboxField, TextField } from "components/Forms";

interface IAudioTrackParams {
    transcoder_id: string;
}

const UpdateTranscoderAudioTracks: React.FC<IAudioTrackParams> = observer(function UpdateTranscoderAudioTracks({ transcoder_id }) {
    const rootStore = useContext(RootStoreContext);
    const { getSingleTranscoderData, updateTranscodersPidList, probeTranscoderAudioTracks } = useSources();
    const { closeDrawer } = rootStore.drawerStore;
    const { openDialog } = rootStore.dialogStore;
    const [loading, setLoading] = useState(true);
    const [transcoderRunning, setTranscoderRunning] = useState<null | boolean>(null);
    const [tracksLoaded, setTracksLoaded] = useState<null | boolean>(null);
    const [refreshButtonVisible, setRefreshButtonVisible] = useState(false);
    const [audioTracks, setAudioTracks] = useState<ITrasncoderAudioTrack[]>([]);
    const [transcoderData, setTranscoderData] = useState<ISingleTranscoderResponse | null>(null);
    const [initialValues, setInitialValues] = useState<{ audioTracks: ITrasncoderAudioTrack[] }>({
        audioTracks: []
    });

    const loadAll = (transcoder_id: string) => {
        setLoading(true);
        setTracksLoaded(false);
        setRefreshButtonVisible(false);

        getSingleTranscoderData(transcoder_id).then((values: ISingleTranscoderResponse) => {
            // If transcoder is running, do the probe and show a refresh button after 5 seconds (that is to prevent from smashing titan, which doesn't like the probe button smashed)
            // If transcoder is not running - give the ability to refresh, but don't probe.
            setTranscoderData(values);
            if (values.data.transcoder_status === "running") {
                probeTranscoderAudioTracks(transcoder_id).then((response) => {
                    setAudioTracks(response.pid_list);
                    setInitialValues({ audioTracks: response.pid_list });
                    setTracksLoaded(true);
                    setTranscoderRunning(true);
                    setLoading(false);
                    setTimeout(() => {
                        setRefreshButtonVisible(true);
                    }, 3000);
                });
            } else {
                setLoading(false);
                setTranscoderRunning(false);
                setRefreshButtonVisible(true);
            }
        });
    };

    useEffect(() => {
        loadAll(transcoder_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const boxSx = {
        display: "flex",
        alignItems: "center",
        gridColumn: "span 3",
        textAlign: "left"
    };

    return (
        <Box>
            {loading && <LoadingComponent inverted={false} />}

            {!loading && !transcoderRunning && (
                <Alert severity="warning" sx={{ marginTop: 2 }}>
                    Transcoder is not running at the moment. Please wait till it starts and then press the refresh button.
                </Alert>
            )}

            {!loading && tracksLoaded && transcoderData && (
                <>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            mb: 2
                        }}>
                        <Box>
                            <Typography fontSize={11}>Powered by</Typography>
                            <img
                                src={require("../../../assets/img/titan-white.png")}
                                width="75"
                                style={{ margin: "0", padding: "0" }}
                                alt="Powered by TITAN"
                                title="Powered by TITAN"
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 2
                        }}>
                        <Box>
                            <Typography variant="h5">{transcoderData.data.transcoded_input_source_name}</Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                Input source: {transcoderData.data.input_source_name}
                            </Typography>
                        </Box>
                        {refreshButtonVisible && !loading && (
                            <IconButton
                                onClick={() => {
                                    // setNumberOfTimesFormChanged(0);
                                    loadAll(transcoder_id);
                                }}
                                title="Reload audio tracks"
                                size="large">
                                <Refresh />
                            </IconButton>
                        )}
                    </Box>

                    <Alert severity="info" sx={{ mb: 3 }}>
                        You can reorder audio tracks by using the arrows
                    </Alert>

                    <FinalForm
                        onSubmit={(values) => {
                            let update_payload: IUpdateTranscoderAudioTracks = {
                                audio_track_pid_list: [],
                                audio_pid_overrides: []
                            };

                            values.audioTracks.forEach((track) => {
                                if (track.IsUsed) {
                                    update_payload.audio_track_pid_list.push(track.PID);
                                    update_payload.audio_pid_overrides.push(track.OverridePid);
                                }
                            });

                            return updateTranscodersPidList(transcoder_id, update_payload)
                                .then(() => {
                                    setRefreshButtonVisible(false);
                                    setTimeout(() => {
                                        setRefreshButtonVisible(true);
                                    }, 3000);
                                    // setNumberOfTimesFormChanged(1);
                                    openDialog(<UpdateSuccessMessage />);
                                    closeDrawer();
                                })
                                .catch((error) => ({
                                    [FORM_ERROR]: error,
                                    presetName: error.data.errors ? error.data.errors.preset_name : null
                                }));
                        }}
                        initialValues={initialValues}
                        mutators={{ ...arrayMutators }}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            values,
                            invalid,
                            submitError,
                            submitErrors,
                            submitSucceeded,
                            submitFailed,
                            hasSubmitErrors,
                            dirtySinceLastSubmit,
                            dirty
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            margin: "0 auto",
                                            color: "white",
                                            textAlign: "left",
                                            borderBottom: "1px solid #307abb",
                                            paddingBottom: 1
                                        }}>
                                        <Typography variant="h6" mb={3}>
                                            Audio PIDs and order
                                        </Typography>

                                        <Box
                                            sx={{
                                                display: "grid",
                                                gridTemplateColumns: "repeat(30, 1fr)",
                                                alignItems: "center",
                                                gap: 1
                                            }}>
                                            <Tooltip
                                                style={{ fontSize: "1rem" }}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement="top"
                                                arrow
                                                title="Position of audio track on the transcoded output. Drag and drop the boxes to re-order.">
                                                <span style={{ gridColumn: "span 5" }}>
                                                    Pos
                                                    <Info sx={{ fontSize: 12, ml: 0.2 }} />
                                                </span>
                                            </Tooltip>
                                            <Tooltip
                                                style={{ fontSize: "1rem" }}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement="top"
                                                arrow
                                                title="Source Audio Track PID">
                                                <span style={{ gridColumn: "span 3" }}>
                                                    PID
                                                    <Info sx={{ fontSize: 12, ml: 0.2 }} />
                                                </span>
                                            </Tooltip>
                                            <span style={{ gridColumn: "span 3" }}>Lang</span>
                                            <span style={{ gridColumn: "span 6" }}>Codec</span>
                                            <Tooltip
                                                style={{ fontSize: "1rem" }}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement="top"
                                                arrow
                                                title="Audio Track Sample Rate">
                                                <span style={{ gridColumn: "span 4" }}>Rate</span>
                                            </Tooltip>
                                            <Tooltip
                                                style={{ fontSize: "1rem" }}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                placement="top"
                                                arrow
                                                title="Overridden PID value for transcoded audio track. 0 = Transcoder auto set, for passthrough set PID out to match source PID.">
                                                <span style={{ gridColumn: "span 6" }}>
                                                    PID out
                                                    <Info sx={{ fontSize: 12, ml: 0.2 }} />
                                                </span>
                                            </Tooltip>
                                            <span style={{ gridColumn: "span 3" }}>On</span>
                                            {/*<span></span>*/} {/* Placeholder for the IconButtons */}
                                        </Box>
                                    </Box>
                                    <FieldArray
                                        name="audioTracks"
                                        defaultValue={[
                                            {
                                                name: undefined,
                                                content: undefined
                                            }
                                        ]}
                                        validate={(value) => {
                                            if (!value) {
                                                return "Required";
                                            }
                                        }}>
                                        {({ fields }) => (
                                            <Box>
                                                {fields.map((name, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            display: "grid",
                                                            gridTemplateColumns: "repeat(30, 1fr)",
                                                            gap: 1
                                                        }}>
                                                        <Stack
                                                            direction="row"
                                                            sx={{
                                                                gridColumn: "span 5"
                                                            }}>
                                                            <IconButton
                                                                disabled={index === 0}
                                                                aria-label="move up"
                                                                onClick={() => {
                                                                    fields.swap(index, index - 1);
                                                                }}>
                                                                <ArrowUpward />
                                                            </IconButton>
                                                            <IconButton
                                                                disabled={!!fields && !!fields.length && index === fields.length - 1}
                                                                aria-label="move down"
                                                                onClick={() => {
                                                                    fields.swap(index, index + 1);
                                                                }}>
                                                                <ArrowDownward />
                                                            </IconButton>
                                                        </Stack>
                                                        <Box
                                                            sx={{
                                                                ...boxSx,
                                                                color: values.audioTracks[index].IsUsed ? "white" : "#3e3e3e"
                                                            }}>
                                                            {values.audioTracks[index].PID}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                ...boxSx,
                                                                color: values.audioTracks[index].IsUsed ? "white" : "#3e3e3e"
                                                            }}>
                                                            {values.audioTracks[index].Language}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                ...boxSx,
                                                                gridColumn: "span 6",
                                                                color: values.audioTracks[index].IsUsed ? "white" : "#3e3e3e"
                                                            }}>
                                                            {values.audioTracks[index].Codec}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                ...boxSx,
                                                                gridColumn: "span 4",
                                                                color: values.audioTracks[index].IsUsed ? "white" : "#3e3e3e"
                                                            }}>
                                                            {values.audioTracks[index].SampleRate}
                                                        </Box>
                                                        <Box sx={{ gridColumn: "span 6" }}>
                                                            <TextField
                                                                name={`${name}.OverridePid`}
                                                                label=""
                                                                type="text"
                                                                disabled={!values.audioTracks[index].IsUsed}
                                                                parse
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                ...boxSx,
                                                                gridColumn: "span 3",
                                                                color: values.audioTracks[index].IsUsed ? "white" : "#3e3e3e",

                                                                ".MuiFormControlLabel-root": {
                                                                    marginLeft: 0,
                                                                    marginRight: 0
                                                                }
                                                            }}>
                                                            <CheckboxField name={`${name}.IsUsed`} label="" />
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </FieldArray>
                                </Box>

                                {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                                <Box style={{ marginTop: 10, textAlign: "center" }}>
                                    <LoadingButton
                                        disabled={invalid || values.audioTracks.filter((entry) => entry.IsUsed).length === 0 || !dirty}
                                        loading={submitting}
                                        variant="contained"
                                        color="primary"
                                        type="submit">
                                        Save Changes
                                    </LoadingButton>
                                </Box>
                            </form>
                        )}
                    />
                </>
            )}
        </Box>
    );
});

export default UpdateTranscoderAudioTracks;
