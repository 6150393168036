import {
    agent,
    IDropDownSelectors,
    IEnvironment,
    IEnvironmentsData,
    IEnvironmentUpgradeBody,
    ISchedulerFormValues,
    RootStoreContext,
    SwitchEnvironment
} from "api";
import { useState, useCallback, useContext } from "react";

export const usePermanentSchedule = () => {
    const rootStore = useContext(RootStoreContext);
    const { setDropDownValues, setCommitmentValues, setPermCommitments } = rootStore.permanentScheduleStore;
    const [isLoading, setIsLoading] = useState(false);

    const getDropDownValues = useCallback(async () => {
        try {
            const drValues = await agent.PermanentSchedules.getDropDownValues();
            setDropDownValues(drValues);
        } catch (error) {
            throw error;
        }
    }, []);

    const getPermanentScheduleTerms = useCallback(async (data: IDropDownSelectors) => {
        try {
            const prices = await agent.PermanentSchedules.getPermanentScheduleTerms(data);
            setCommitmentValues(prices);
        } catch (error) {
            throw error;
        }
    }, []);

    const purchaseScheduleAgreement = useCallback(async (production_id: number, number_of_agreements: number) => {
        try {
            await agent.PermanentSchedules.submitAgreement(production_id, number_of_agreements);
        } catch (error) {
            throw error;
        }
    }, []);

    const getPermanentScheduleCommitments = useCallback(async () => {
        try {
            const commitments = await agent.PermanentSchedules.getPermanentScheduleCommitments();
            setPermCommitments(commitments);
        } catch (error) {
            throw error;
        }
    }, []);

    return {
        isLoading,
        getDropDownValues,
        getPermanentScheduleTerms,
        purchaseScheduleAgreement,
        getPermanentScheduleCommitments
    };
};
