import React, { useContext, useEffect } from "react";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import { agent, RootStoreContext } from "api";
import { Table, TableCell, TableHead, TableBody, TableRow, Button, Chip, Stack, IconButton, Box, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { format } from "date-fns";
import { Cancel, Check, Visibility } from "@mui/icons-material";
import { Editor, useMonaco, DiffEditor } from "@monaco-editor/react";

const TfReleaseCandidates: React.FC = () => {
    const [data, setData] = React.useState<any[]>([]);

    const rootStore = useContext(RootStoreContext);

    const { setCurrentTheme } = rootStore.commonStore;

    const { user } = rootStore.userStore;

    const { openDialog, closeDialog } = rootStore.dialogStore;

    const getData = async () => {
        await agent.Admin.getTfReleaseCandidates()
            .then((response) => {
                console.log("response", response);
                setData(response.data);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        setCurrentTheme("light");
        getData();

        return () => {
            if (user?.user_preferences?.theme) {
                setCurrentTheme(user.user_preferences.theme);
            } else {
                setCurrentTheme("dark");
            }
        };
    }, []);

    return (
        <View>
            <ViewHeader title="Titan File Release Candidates" subtitle="Admin">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div></div>
                    <div></div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <Content>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Change Detected</TableCell>
                                <TableCell>Approved At</TableCell>
                                <TableCell>Merged At</TableCell>
                                <TableCell>New Tag</TableCell>
                                <TableCell>Previous Tag</TableCell>
                                <TableCell>Product Version</TableCell>
                                <TableCell>Stack Version</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.length > 0 &&
                                data.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={item.status}
                                                    color={item.status === "new" ? "success" : undefined}
                                                    size="small"
                                                    style={{ textTransform: "capitalize" }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap" }}>
                                                {format(new Date(item.change_detected_at), "dd/MM/yy @ HH:mm")}
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap" }}>
                                                <Box>{item.approved_at ? format(new Date(item.approved_at), "dd/MM/yy @ HH:mm") : ""}</Box>
                                                {item.approved_by_user_email && <Box>
                                                    <Typography variant="body2">by {item.approved_by_user_email}</Typography>
                                                </Box>}
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap" }}>
                                                {item.merged_at ? format(new Date(item.merged_at), "dd/MM/yy @ HH:mm") : ""}
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap" }}>{item.new_tag}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap" }}>{item.previous_tag}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap" }}>{item.product_version}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap" }}>{item.stack_version}</TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={1} width={"100%"} justifyContent="flex-end">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            openDialog(
                                                                <>
                                                                    <DialogContent>
                                                                        <DialogContentText sx={{ fontSize: 12}}>
                                                                            {/* <pre>{item.diff}</pre> */}
                                                                            <Editor
                                                                                height="700px"
                                                                                defaultLanguage="diff"
                                                                                defaultValue={item.diff}
                                                                                options={{
                                                                                    readOnly: true,
                                                                                    minimap: {
                                                                                        enabled: false
                                                                                    },
                                                                                    inDiffEditor: true
                                                                                }}
                                                                            />
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={closeDialog}>Close</Button>
                                                                    </DialogActions>
                                                                </>,
                                                                'Diff',
                                                                "xl",
                                                                false
                                                            );
                                                        }}>
                                                        <Visibility />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        disabled={item.status !== "new"}
                                                        onClick={() => {
                                                            openDialog(
                                                                <>
                                                                    <DialogContent>
                                                                        <DialogContentText>
                                                                            Are you sure you want to approve this release candidate?
                                                                        </DialogContentText>
                                                                        <DialogContentText>
                                                                            {item.new_tag} will be merged.
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={closeDialog}>Cancel</Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => {
                                                                                agent.Admin.approveTfReleaseCandidate(item.id)
                                                                                    .then((response) => {
                                                                                        console.log("response", response);
                                                                                        getData();
                                                                                        closeDialog();
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.log("error", error);
                                                                                    });
                                                                            }}
                                                                            autoFocus>
                                                                            Approve
                                                                        </Button>
                                                                    </DialogActions>
                                                                </>
                                                            );
                                                        }}>
                                                        <Check />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        disabled={item.status !== "new"}
                                                        onClick={() => {
                                                            openDialog(
                                                                <>
                                                                    <DialogContent>
                                                                        <DialogContentText>
                                                                            Are you sure you want to reject this release candidate?
                                                                        </DialogContentText>
                                                                        <DialogContentText>
                                                                            {item.new_tag} will not be merged.
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={closeDialog}>Cancel</Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => {
                                                                                agent.Admin.rejectTfReleaseCandidate(item.id)
                                                                                    .then((response) => {
                                                                                        console.log("response", response);
                                                                                        getData();
                                                                                        closeDialog();
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.log("error", error);
                                                                                    });
                                                                            }}
                                                                            autoFocus>
                                                                            Reject
                                                                        </Button>
                                                                    </DialogActions>
                                                                </>
                                                            );
                                                        }}>
                                                        <Cancel />
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </Content>
            </ViewBody>
        </View>
    );
};

export default TfReleaseCandidates;
