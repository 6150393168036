import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/material";

interface SearchInputProps {
    name: string;
    label: string;
    value: string | undefined;
    onChange: (value: string) => void;
    onClear: () => void;
    marginBottom?: number | string;
    sx?: SxProps;
    size?: "small" | "medium";
    fullWidth?: boolean;
}

/**
 * A reusable search component that renders a TextField with search and clear functionality.
 * @param {string} value - The current value of the search input.
 * @param {function} onChange - A function that is called when the search input value changes.
 * @param {function} onClear - A function that is called when the clear button is clicked.
 * @returns {JSX.Element} - A TextField component with search and clear functionality.
 */

const SearchInput: React.FC<SearchInputProps> = ({ name, label, value, onChange, onClear, marginBottom, sx, size, fullWidth }) => {
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const clearSearch = () => {
        onClear();
    };

    return (
        <TextField
            id={name}
            label={label}
            variant="outlined"
            size={size ? size : "small"}
            fullWidth={fullWidth}
            value={value}
            onChange={handleSearch}
            sx={{ width: 280, maxWidth: "100%", mb: marginBottom ? marginBottom : undefined, ...sx}}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: value ? (
                    <InputAdornment position="end">
                        <IconButton onClick={clearSearch} size="small">
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
            }}
        />
    );
};

export default SearchInput;
