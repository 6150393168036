import { Button, ButtonGroup, Grid, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { IEnvironmentMetrics, agent } from "api";
import React, { useEffect, useState } from "react";

interface EnvironmentMetricsProps {
    environment_id: string;
}

/**
 * `EnvironmentMetrics` is a React component that displays the metrics of an environment.
 *
 * @component
 * @param {Object} props - The properties that define the EnvironmentMetrics component.
 * @param {string} props.environment_id - The ID of the environment.
 *
 * @example
 * // To use this component, import it and use it in your JSX like this:
 * import EnvironmentMetrics from './EnvironmentMetrics';
 *
 * <EnvironmentMetrics
 *   environment_id="12345"
 * />
 */

const EnvironmentMetrics: React.FC<EnvironmentMetricsProps> = ({ environment_id }) => {
    const theme = useTheme();
    const [metrics, setMetrics] = useState<IEnvironmentMetrics[]>([]);

    const [hours, setHours] = useState<number>(2);

    const getEnvironmentMetrics = async (hours: number) => {
        let fromDate: any = new Date();
        fromDate.setHours(fromDate.getHours() - hours);
        fromDate.setMinutes(Math.floor(fromDate.getMinutes() / 5) * 5);
        fromDate.setSeconds(0);
        fromDate.setMilliseconds(0);
        fromDate = new Date(fromDate).toISOString();

        const toDate: any = new Date().toISOString();

        await agent.Environment.getMetrics(environment_id, {
            time_from: fromDate,
            time_to: toDate
        })
            .then((response) => {
                setMetrics(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getEnvironmentMetrics(hours);
    }, [environment_id, hours]);

    return (
        <Grid container>
            <Grid item xs={12} sx={{ pb: 3 }}>
                <ToggleButtonGroup value={hours} size="small">
                    <ToggleButton value={1} onClick={() => setHours(1)}>
                        1 Hour
                    </ToggleButton>
                    <ToggleButton value={2} onClick={() => setHours(2)}>
                        2 Hours
                    </ToggleButton>
                    <ToggleButton value={6} onClick={() => setHours(6)}>
                        6 Hours
                    </ToggleButton>
                    <ToggleButton value={12} onClick={() => setHours(12)}>
                        12 Hours
                    </ToggleButton>
                    <ToggleButton value={24} onClick={() => setHours(24)}>
                        1 Day
                    </ToggleButton>
                    <ToggleButton value={48} onClick={() => setHours(48)}>
                        2 Days
                    </ToggleButton>
                    <ToggleButton value={168} onClick={() => setHours(168)}>
                        1 Week
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h6">Egress (MB)</Typography>
                <LineChart
                    series={[
                        {
                            data: metrics.map((metric) => metric.network_egress_mb),
                            valueFormatter: (value) => `${value} MB`,
                            showMark: false,
                            curve: "linear",
                            color: theme.palette.primary.main
                        }
                    ]}
                    xAxis={[
                        {
                            data: metrics.map((metric) => new Date(metric.time)),
                            scaleType: "time"
                        }
                    ]}
                    height={200}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h6">Average CPU Usage (%)</Typography>
                <LineChart
                    series={[
                        {
                            data: metrics.map((metric) => metric.average_cpu_percent),
                            valueFormatter: (value) => `${value}%`,
                            showMark: false,
                            curve: "linear",
                            color: theme.palette.secondary.main
                        }
                    ]}
                    xAxis={[
                        {
                            data: metrics.map((metric) => new Date(metric.time)),
                            scaleType: "time"
                        }
                    ]}
                    height={200}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h6">Max CPU Usage (%)</Typography>
                <LineChart
                    series={[
                        {
                            data: metrics.map((metric) => metric.max_cpu_percent),
                            valueFormatter: (value) => `${value}%`,
                            showMark: false,
                            curve: "linear",
                            color: theme.palette.tertiary?.main
                        }
                    ]}
                    xAxis={[
                        {
                            data: metrics.map((metric) => new Date(metric.time)),
                            scaleType: "time"
                        }
                    ]}
                    height={200}
                />
            </Grid>
        </Grid>
    );
};

export default EnvironmentMetrics;
