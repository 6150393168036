import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import { RootStoreContext } from "api";

const AdminActiveCloudServices = observer(function AdminActiveCloudServices() {
    const rootStore = useContext(RootStoreContext);

    const { setCurrentTheme } = rootStore.commonStore;

    useEffect(() => {
        setCurrentTheme("light");

        return () => {
            setCurrentTheme("dark");
        };
    }, [setCurrentTheme]);
    return (
        <View>
            <ViewHeader title="Active Cloud Services" subtitle="Admin">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div></div>
                    <div></div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row"
                    }}></div>
            </ViewBody>
        </View>
    );
});

export default AdminActiveCloudServices;
