import React from "react";
import AddTermsTable from "./AddTermsTable";
import { Typography } from "@mui/material";

export const AddTerm = () => {
    return (
        <>
            <Typography variant="h5">Add Term</Typography>
            <Typography sx={{ my: 1 }}>
                Please select the required commitment length, bitrate and desired cloud provider values below. Once you have committed the
                values and agreed with the Terms and Conditions the commitment will show in the table below. You can then apply this to any
                destination in your control within the selected cloud platform. This will replace the current egress cost with the rates
                shown for the length of term selected.
            </Typography>
            <AddTermsTable />
        </>
    );
};
