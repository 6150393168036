import { Typography } from "@mui/material";

import React from "react";

interface ISettingsInfoProps {
    mode: "create" | "edit";
}
const SettingsInfo: React.FC<ISettingsInfoProps> = ({ mode }) => {
    return (
        <>
            <Typography sx={{ mb: 1 }}>
                <b>Name</b> - This is will be the name that the new transcoder is saved under.
            </Typography>

            {mode === "create" && (
                <Typography sx={{ mb: 1 }}>
                    <b>Preset</b> - If you have previously saved your transcoder settings as a Preset, these can be accessed under the
                    Preset dropdown. Additional presets can be saved on the final step of the creation process.
                </Typography>
            )}

            <Typography sx={{ mb: 1 }}>
                <b>Redundancy</b> - Enabling redundancy will utilise multiple redundant packagers for the same stream to
                ensure continuous availability. Please note that it carries additional charges.
            </Typography>
        </>
    );
};

export default SettingsInfo;
