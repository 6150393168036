import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";
import { TextField } from "mui-rff";
import { IBillingCustomerInformation, IBillingCustomerResponse, RootStoreContext } from "api";
import CheckoutForm from "./Checkout";
import { combineValidators, composeValidators, createValidator, isRequired } from "revalidate";
import { countries } from "app/common/options/countryOptions";
import { SelectField } from "components/Forms";
import { Box, Button, Stack, Typography } from "@mui/material";
import CreditQuantityForm from "./CreditQuantityForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useBilling } from "hooks";

const isValidNumber = createValidator(
    (message) => (value) => {
        if (value && !/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/.test(value)) {
            return message;
        }
    },
    "Needs to be a valid number"
);

const validate = combineValidators({
    company_name: isRequired("Company name"),
    first_name: isRequired("First name"),
    last_name: isRequired("Last name"),
    phone: composeValidators(isValidNumber, isRequired("phone number"))(),
    email: isRequired("Email"),
    address_line1: isRequired("Address"),
    address_city: isRequired("City"),
    address_postal_code: isRequired("Post code")
});

const BillingInformationForm = () => {
    const rootStore = useContext(RootStoreContext);
    const { getBillingInformaiton, updateBillingInfo } = useBilling();
    const { changeDrawerContent, closeDrawer } = rootStore.drawerStore;
    const { isLoggedIn, user } = rootStore.userStore;

    const [billing, setBilling] = useState<IBillingCustomerResponse | null>(null);

    useEffect(() => {
        getBillingInformaiton().then((value) => {
            setBilling(value);
        });
    }, [getBillingInformaiton]);

    const handleClose = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        changeDrawerContent(<CreditQuantityForm />);
    };

    const initialValues = {
        currency: "gbp",
        switch_org_billing_to_account: false
    };

    const stripePromise: any = React.useRef(null);

    useEffect(() => {
        if (
            stripePromise.current === null &&
            isLoggedIn &&
            user &&
            user.signup_stage === "finished" &&
            user.managing_organisation_id &&
            window.location.href.includes("billing")
        ) {
            const stripe_key: string = process.env.REACT_APP_STRIPE_KEY!;
            stripePromise.current = loadStripe(stripe_key);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, user, window.location.href]);

    return (
        <Box sx={{ width: 580, padding: 3 }}>
            <FinalForm
                onSubmit={(values: IBillingCustomerInformation) =>
                    updateBillingInfo(values)
                        .then(() => {
                            changeDrawerContent(
                                <Elements stripe={stripePromise.current}>
                                    <CheckoutForm />
                                </Elements>
                            );
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                            id: error.data.errors && error.data.errors.id,
                            currency: error.data.errors && error.data.errors.currency,
                            company_name: error.data.errors && error.data.errors.company_name,
                            first_name: error.data.errors && error.data.errors.first_name,
                            last_name: error.data.errors && error.data.errors.last_name,
                            phone: error.data.errors && error.data.errors.phone,
                            email: error.data.errors && error.data.errors.email,
                            address_line1: error.data.errors && error.data.errors.address_line1,
                            address_line2: error.data.errors && error.data.errors.address_line2,
                            address_city: error.data.errors && error.data.errors.address_city,
                            address_state: error.data.errors && error.data.errors.address_state,
                            address_postal_code: error.data.errors && error.data.errors.address_postal_code,
                            address_country_code: error.data.errors && error.data.errors.address_country_code,
                            org_reg_number: error.data.errors && error.data.errors.org_reg_number,
                            org_vat_number: error.data.errors && error.data.errors.org_vat_number,
                            switch_org_billing_to_account: error.data.errors && error.data.errors.switch_org_billing_to_account
                        }))
                }
                validate={validate}
                initialValues={billing?.has_billing_details ? billing.billing_details : initialValues}
                render={({ handleSubmit, submitting, submitError, invalid, dirtySinceLastSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ padding: 2 }}>
                            <Typography variant="h4" sx={{ mb: 2 }}>
                                Billing Information
                            </Typography>
                            <Stack spacing={1}>
                                <TextField
                                    fullWidth
                                    required
                                    name="company_name"
                                    placeholder="Enter your Companies name"
                                    label="Company Name"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    required
                                    name="first_name"
                                    placeholder="Enter your first name"
                                    label="First Name"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    required
                                    name="last_name"
                                    placeholder="Enter your last name"
                                    label="Last Name"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    name="phone"
                                    placeholder="Phone number is optional"
                                    label="Phone number"
                                    required
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    required
                                    name="email"
                                    placeholder="Enter your email"
                                    label="Email Address"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    required
                                    name="address_line1"
                                    placeholder="Address line One"
                                    label="Address Line 1"
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    name="address_line2"
                                    placeholder="Address line Two"
                                    label="Address Line 2"
                                    type="text"
                                />
                                <TextField fullWidth required name="address_city" placeholder="Address City" label="City" type="text" />
                                <TextField
                                    fullWidth
                                    name="address_state"
                                    placeholder="Address State"
                                    label="State/county"
                                    required
                                    type="text"
                                />
                                <TextField
                                    fullWidth
                                    required
                                    name="address_postal_code"
                                    placeholder="Postal Code"
                                    label="Postal Code"
                                    type="text"
                                />
                                <SelectField
                                    required
                                    label="Country"
                                    options={countries}
                                    name="address_country_code"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                />
                                <TextField
                                    fullWidth
                                    name="org_reg_number"
                                    placeholder="Organisation Registration Number"
                                    label="Company Registration Number"
                                    type="text"
                                    fieldProps={{
                                        validate: isRequired("Organisation Registration number")
                                    }}
                                />
                                {values.address_country_code === "GB" || values.address_country_code === "EU" ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="org_vat_number"
                                        placeholder="Organisation VAT Number"
                                        label="VAT number"
                                        type="text"
                                        fieldProps={{
                                            validate: isRequired("VAT number")
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        fullWidth
                                        name="org_vat_number"
                                        placeholder="Organisation VAT Number"
                                        label="VAT number"
                                        type="text"
                                    />
                                )}
                            </Stack>

                            {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mt: 2 }}>
                                <Button
                                    onClick={(e) => {
                                        handleClose(e);
                                    }}>
                                    Back
                                </Button>
                                <Button variant="contained" type="submit" disabled={invalid && !dirtySinceLastSubmit}>
                                    Continue to Payment
                                </Button>
                            </Stack>
                        </Box>
                    </form>
                )}
            />
        </Box>
    );
};

export default observer(BillingInformationForm);
