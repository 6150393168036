import { Box, Typography } from "@mui/material";
import React from "react";

interface SrtCallerDetailsProps {
    data: any;
}

const SrtCallerDetails: React.FC<SrtCallerDetailsProps> = ({ data }) => {
    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Source Label</Typography>
                <Typography sx={{ textTransform: "capitalize" }}>{data.input_label}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5  }}>
                <Typography fontWeight="bold">Encryption</Typography>
                <Typography sx={{ textTransform: "capitalize" }}>{data.encryption_mode}</Typography>
            </Box>
            {/* port */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5  }}>
                <Typography fontWeight="bold">Port</Typography>
                <Typography sx={{ textTransform: "capitalize" }}>{data.port}</Typography>
            </Box>
            {/* latency */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5  }}>
                <Typography fontWeight="bold">Latency</Typography>
                <Typography sx={{ textTransform: "capitalize" }}>{data.latency}</Typography>
            </Box>
            {/* timeout */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5  }}>
                <Typography fontWeight="bold">Timeout</Typography>
                <Typography sx={{ textTransform: "capitalize" }}>{data.timeout}</Typography>
            </Box>
        </>
    );
};

export default SrtCallerDetails;
