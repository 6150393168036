import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, Card, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

// eslint-disable-next-line mobx/missing-observer
export const ThankyouPassword = (): JSX.Element => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <Card sx={{ p: 2 }}>
            <DialogTitle>Thank you</DialogTitle>
            <DialogContent>
                <Typography sx={{ mb: 1 }}>Your password has been successfully changed.</Typography>
                <Typography>Please close this window and log in using the newly set password.</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </DialogActions>
        </Card>
    );
};
