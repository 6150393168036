import { Box } from "@mui/material";
import InfoItem from "components/InfoItem";
import React from "react";

interface TranscoderStatisticsProps {
    data: { [key: string]: any };
}

const TranscoderStatistics: React.FC<TranscoderStatisticsProps> = ({ data }) => {
    return (
        <Box>
            {Object.entries(data).map(([key, value]) => (
                <InfoItem
                    key={key}
                    title={key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())}
                    value={value === null ? "N/A" : value}
                />
            ))}
        </Box>
    );
};

export default TranscoderStatistics;
