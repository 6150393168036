import { Box, Button, ClickAwayListener, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext, useEffect } from "react";
import FolderTree from "./FolderTree";
import { InsertDriveFile } from "@mui/icons-material";

interface FileNode {
    name: string;
    type: "file";
    path: string;
}

interface FolderNode {
    name: string;
    type: "folder";
    children?: Array<FolderNode | FileNode>;
}

type TreeNode = FolderNode | FileNode;

interface MediaGalleryProps {
    filter?: "image" | "video" | "audio" | "file";
    onSelected: (value: any) => void;
}

const MediaGallery: React.FC<MediaGalleryProps> = ({ onSelected, filter }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const [selected, setSelected] = React.useState<string>();
    const [media, setMedia] = React.useState<any>();
    const [activePath, setActivePath] = React.useState<string>("");

    const handlePathSelect = (path: string) => {
        //strip the first part of the path "knowledge-base."
        path = path.replace("knowledge-base.", "");
        setActivePath(path);
    };

    const getCurrentFiles = (node: FolderNode, path: string): FileNode[] => {
        const parts = path.split(".");
        let currentNode: TreeNode = node;

        let files: FileNode[] = [];

        parts.forEach((part) => {
            if ((currentNode as FolderNode).children) {
                const folder = (currentNode as FolderNode).children?.find((child) => child.name === part);
                if (folder) {
                    currentNode = folder;
                }
            }
        });

        if ((currentNode as FolderNode).children) {
            files = (currentNode as FolderNode).children?.filter((child) => child.type === "file") as FileNode[];
        }

        return files;
    };

    const click = () => {
        onSelected(selected);
        closeDialog();
    };

    const isImage = (path: string) => {
        return path.match(/\.(jpeg|jpg|gif|png|bmp|tiff|tif|svg|webp|ico)$/) != null;
    };
    
    const isVideo = (path: string) => {
        return path.match(/\.(mp4|webm|ogg|ogv|avi|mkv|mov|wmv|flv|m4v)$/) != null;
    };

    const isAudio = (path: string) => {
        return path.match(/\.(mp3|wav|ogg|m4a|flac|aac|wma|alac|aiff|opus)$/) != null;
    };

    useEffect(() => {
        agent.KnowledgeHub.listMedia().then((response) => {
            setMedia(response);
            console.log(response);
        });
    }, []);

    return (
        <>
            <DialogTitle>Media Gallery</DialogTitle>
            <DialogContent
                sx={{
                    borderTop: "1px solid",
                    borderTopColor: "divider",
                    borderBottom: "1px solid",
                    borderBottomColor: "divider",
                    p: 0
                }}>
                <Box
                    sx={{
                        display: "flex",
                        height: 500
                    }}>
                    <Box
                        sx={{
                            width: 244,
                            flexShrink: 0,
                            borderRight: "1px solid",
                            borderRightColor: "divider",
                            flexDirection: "column",
                            p: 1
                        }}>
                        {media && media.data && (
                            <FolderTree
                                data={media.data}
                                onPathSelect={handlePathSelect}
                                activePath={activePath.replace("knowledge-base.", "")}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            p: 2,
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(170px, 170px))",
                            gridAutoRows: "170px",
                            gap: 2,
                            height: "100%",
                            width: "100%",
                            overflowY: "auto"
                        }}>
                        {media &&
                            media.data &&
                            getCurrentFiles(media.data, activePath).map((item, index) => (
                                <ClickAwayListener key={index} onClickAway={() => setSelected(undefined)}>
                                    <Box
                                        key={index}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setSelected("https://livelink.video/" + item.path);
                                        }}
                                        sx={{
                                            position: "relative",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "1px solid",
                                            borderColor: (theme) =>
                                                selected === "https://livelink.video/" + item.path
                                                    ? theme.palette.primary.main
                                                    : theme.palette.action.hover,
                                            backgroundColor: (theme) =>
                                                selected === "https://livelink.video/" + item.path
                                                    ? theme.palette.action.hover
                                                    : "transparent",
                                            borderRadius: 1,
                                            p: 1,
                                            cursor: "pointer",
                                            "&:hover": {
                                                borderColor: (theme) =>
                                                    selected === "https://livelink.video/" + item.path
                                                        ? theme.palette.primary.main
                                                        : theme.palette.action.selected
                                            }
                                        }}>
                                        {isImage(item.path) && (
                                            <img
                                                src={"https://livelink.video/" + item.path}
                                                alt="Image"
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                            />
                                        )}
                                        {isVideo(item.path) && (
                                            <video
                                                //controls
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                src={"https://livelink.video/" + item.path}>
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                        {isAudio(item.path) && (
                                            <audio
                                                controls
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                src={"https://livelink.video/" + item.path}>
                                                Your browser does not support the audio tag.
                                            </audio>
                                        )}
                                        {!isImage(item.path) && !isVideo(item.path) && !isAudio(item.path) && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                <InsertDriveFile sx={{ fontSize: 40, color: "text.secondary", mb: 0.5}} />
                                                <Typography variant="caption" sx={{ width: "100%", textAlign: "center", wordBreak: "break-all"}}>{item.name}</Typography>
                                            </Box>
                                        )}
                                        {/* <img
                                            src={"https://livelink.video/" + item.path}
                                            alt="Image"
                                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                                        /> */}
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                                color: "white",
                                                padding: 0.5,
                                                borderRadius: 1,
                                                maxWidth: "100%",
                                                wordBreak: "break-all"
                                            }}>
                                            <Typography variant="caption">{item.name}</Typography>
                                        </Box>
                                    </Box>
                                </ClickAwayListener>
                            ))}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={click} variant="contained" disabled={!selected}>
                    Insert
                </Button>
            </DialogActions>
        </>
    );
};

export default MediaGallery;
