import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { RootStoreContext } from "api";
import React from "react";
import DestinationDetails from "./DestinationDetails";

export const DestinationsList: React.FC = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { date } = rootStore.schedulerStore;
    const { openDialog } = rootStore.dialogStore;
    const { openDrawer } = rootStore.drawerStore;
    const { token } = rootStore.commonStore;
    const { environment, currentEnvRunning } = rootStore.environmentStore;

    const [update, setUpdate] = React.useState<Date>(new Date());
    const [loading, setLoading] = React.useState<boolean>(false);

    const [zoom, setZoom] = React.useState(2);

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_env_id = rootStore.userStore.activeenvironment;

    const [data, setData] = React.useState<any>([]);

    const { client, clientConnected } = rootStore.resClientStore;

    const onUpdate = () => {
        setUpdate(new Date());
    };

    React.useEffect(() => {
        setLoading(true);

        clientConnected &&
            client
                .get(`environments.outputs.${managing_org_id}.${active_env_id}`)
                .then((res: any) => {
                    console.log(res);
                    res.on("add", () => {
                        onUpdate();
                    });

                    res.on("remove", () => {
                        onUpdate();
                    });
                    Array.from(res).map((destination: any) => {
                        destination.on("change", () => {
                            onUpdate();
                        });
                    });
                    setData(res);
                    setLoading(false);
                    onUpdate();
                })
                .catch((err) => {
                    console.error("Scheduler Error", err);
                    if (err.message === "No auth info provided") {
                        client.disconnect();
                        rootStore.resClientStore.initialize(token!);
                    }
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            setData([]);
        };
    }, [managing_org_id, rootStore.commonStore.token, clientConnected, client, active_env_id, token, currentEnvRunning]);

    React.useEffect(() => {}, [update]);

    return (
        <List sx={{ p: 0 }}>
            {currentEnvRunning &&
                data &&
                Array.from(data).map((destination: any) => {
                    return (
                        <ListItem
                            key={destination.destination_id}
                            disablePadding
                            sx={{
                                borderLeftWidth: 4,
                                borderLeftColor: destination.connection_status === "connected" ? "success.main" : "warning.main",
                                borderLeftStyle: "solid",
                                mb: "1px"
                            }}>
                            <ListItemButton
                                onClick={() =>
                                    openDrawer(
                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                            <DestinationDetails destination_id={destination.destination_id} />
                                        </Box>
                                    )
                                }>
                                <Box>
                                    <Typography fontWeight="bold">{destination.name}</Typography>
                                    <Typography variant="body2" color="text.secondary" textTransform="capitalize">
                                        {destination.connection_status}
                                    </Typography>
                                </Box>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
        </List>
    );
};

export default DestinationsList;
