import { Box, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import OnChange from "components/FinalFormListener/OnChange";

interface Props {
    values: any;
    submitError: any;
    onFieldChange: (field: string, value: any) => void;
}

const ZixiPushOptionsForm: React.FC<Props> = ({ values, submitError, onFieldChange }) => {
    React.useEffect(() => {}, [
        values?.direct_feed_input_details?.zixi_push_options?.enable_ts_input_smoothing,
        values?.direct_feed_input_details?.zixi_push_options?.encryption_mode
    ]);

    return (
        <Box sx={{ p: 2, backgroundColor: "rgba(160,160,160,0.05)" }}>
            <Typography variant="h6" gutterBottom component="div">
                Zixi Push Options
            </Typography>
            <Field
                name="direct_feed_input_details.zixi_push_options.input_label"
                component="input"
                type="text"
                placeholder="Input Label"
                validate={(value) => {
                    //String. 1 to 150 characters long.
                    if (!value) {
                        return "Required";
                    }
                    if (value.length < 1 || value.length > 150) {
                        return "Name must be between 1 and 150 characters";
                    }
                    if (!/^[a-zA-Z0-9._-\s]*$/.test(value)) {
                        return "Name must contain only valid characters of: a-z, A-Z, 0-9, ., _, -, and space";
                    }
                }}>
                {({ input, meta }) => (
                    <TextField
                        fullWidth
                        required
                        error={
                            (meta.touched && meta.error) ||
                            (!meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.input_label)
                                ? true
                                : false
                        }
                        helperText={
                            meta.touched && meta.error
                                ? meta.error
                                : !meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.input_label
                                ? submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.input_label
                                : null
                        }
                        label="Input Label"
                        type="text"
                        {...input}
                    />
                )}
            </Field>
            <OnChange name="direct_feed_input_details.zixi_push_options.input_label">
                {(value) => {
                    if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.input_label) {
                        onFieldChange("direct_feed_input_details.zixi_push_options.input_label", value);
                    }
                }}
            </OnChange>
            <Field
                name="direct_feed_input_details.zixi_push_options.enable_ts_input_smoothing"
                component="input"
                type="checkbox"
                placeholder="Enable TS Input Smoothing"
                defaultValue={false}>
                {({ input }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...input}
                                checked={values.direct_feed_input_details?.zixi_push_options?.enable_ts_input_smoothing}
                                onChange={input.onChange}
                            />
                        }
                        label="Enable TS Input Smoothing"
                    />
                )}
            </Field>
            <OnChange name="direct_feed_input_details.zixi_push_options.enable_ts_input_smoothing">
                {(value) => {
                    if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.enable_ts_input_smoothing) {
                        onFieldChange("direct_feed_input_details.zixi_push_options.enable_ts_input_smoothing", value);
                    }
                }}
            </OnChange>

            {/* Only show the next field if the previous field is true*/}
            {values?.direct_feed_input_details?.zixi_push_options?.enable_ts_input_smoothing && (
                <>
                    <Field
                        name="direct_feed_input_details.zixi_push_options.input_smoothing_extra_delay"
                        component="input"
                        type="number"
                        placeholder="Input Smoothing Extra Delay"
                        defaultValue={0}
                        validate={(value) => {
                            //Integer in range 0 to 1250. Used only if enable_ts_input_smoothing is true. Default 0.
                            if (value < 0 || value > 1250) {
                                return "Input Smoothing Extra Delay must be between 0 and 1250";
                            }
                        }}
                        parse={(value: any) => {
                            return value ? parseInt(value) : 0;
                        }}>
                        {({ input, meta }) => (
                            <TextField
                                fullWidth
                                required
                                label="Input Smoothing Extra Delay"
                                type="number"
                                error={
                                    (meta.touched && meta.error) ||
                                    (!meta.touched &&
                                        submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options
                                            ?.input_smoothing_extra_delay)
                                        ? true
                                        : false
                                }
                                helperText={
                                    meta.touched && meta.error
                                        ? meta.error
                                        : !meta.touched &&
                                          submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options
                                              ?.input_smoothing_extra_delay
                                        ? submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options
                                              ?.input_smoothing_extra_delay
                                        : null
                                }
                                {...input}
                            />
                        )}
                    </Field>
                    <OnChange name="direct_feed_input_details.zixi_push_options.input_smoothing_extra_delay">
                        {(value) => {
                            if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.input_smoothing_extra_delay) {
                                onFieldChange("direct_feed_input_details.zixi_push_options.input_smoothing_extra_delay", value);
                            }
                        }}
                    </OnChange>
                </>
            )}
            <Field
                name="direct_feed_input_details.zixi_push_options.port"
                component="input"
                type="number"
                placeholder="Port"
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value < 1024 || value > 65535) {
                        return "Port must be between 1024 and 65535";
                    }
                }}
                parse={(value) => {
                    return parseInt(value);
                }}>
                {({ input, meta }) => (
                    <TextField
                        fullWidth
                        required
                        label="Port"
                        type="number"
                        error={
                            (meta.touched && meta.error) ||
                            (!meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.port)
                                ? true
                                : false
                        }
                        helperText={
                            meta.touched && meta.error
                                ? meta.error
                                : !meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.port
                                ? submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.port
                                : null
                        }
                        {...input}
                    />
                )}
            </Field>
            <OnChange name="direct_feed_input_details.zixi_push_options.port">
                {(value) => {
                    if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.port) {
                        onFieldChange("direct_feed_input_details.zixi_push_options.port", value);
                    }
                }}
            </OnChange>
            { values?.enable_backup && (
                <>
                    <Field
                        name="direct_feed_input_details.zixi_push_options.backup_port"
                        component="input"
                        type="number"
                        placeholder="Backup Port"
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            if (value < 1024 || value > 65535) {
                                return "Backup Port must be between 1024 and 65535";
                            }
                        }}
                        parse={(value: any) => {
                            return parseInt(value);
                        }}>
                        {({ input, meta }) => (
                            <TextField
                                fullWidth
                                required
                                label="Backup Port"
                                type="number"
                                error={
                                    (meta.touched && meta.error) ||
                                    (!meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.backup_port)
                                        ? true
                                        : false
                                }
                                helperText={
                                    meta.touched && meta.error
                                        ? meta.error
                                        : !meta.touched &&
                                          submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.backup_port
                                        ? submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.backup_port
                                        : null
                                }
                                {...input}
                            />
                        )}
                    </Field>
                    <OnChange name="direct_feed_input_details.zixi_push_options.backup_port">
                        {(value) => {
                            if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.backup_port) {
                                onFieldChange("direct_feed_input_details.zixi_push_options.backup_port", value);
                            }
                        }}
                    </OnChange>
                </>
            )}
            <Field
                name="direct_feed_input_details.zixi_push_options.timeout"
                component="input"
                type="number"
                placeholder="Timeout"
                defaultValue={1000}
                parse={(value: any) => {
                    return value ? parseInt(value) : undefined;
                }}
                validate={(value) => {
                    //"timeout": Integer. 150 to 5000. Default 1000.
                    if (!value && value !== 0) {
                        return "Required";
                    }
                    if (value < 150 || value > 5000) {
                        return "Timeout must be between 150 and 5000";
                    }
                }}>
                {({ input, meta }) => (
                    <TextField
                        fullWidth
                        required
                        label="Timeout"
                        type="number"
                        error={
                            (meta.touched && meta.error) ||
                            (!meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.timeout)
                                ? true
                                : false
                        }
                        helperText={
                            meta.touched && meta.error
                                ? meta.error
                                : !meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.timeout
                                ? submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.timeout
                                : null
                        }
                        {...input}
                    />
                )}
            </Field>
            <OnChange name="direct_feed_input_details.zixi_push_options.timeout">
                {(value) => {
                    if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.timeout) {
                        onFieldChange("direct_feed_input_details.zixi_push_options.timeout", value);
                    }
                }}
            </OnChange>
            <Field
                name="direct_feed_input_details.zixi_push_options.encryption_mode"
                defaultValue={"None"}
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        variant="standard"
                        label="Encryption Mode"
                        error={
                            (meta.touched && meta.error) ||
                            (!meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_mode)
                                ? true
                                : false
                        }
                        helperText={
                            meta.touched && meta.error
                                ? meta.error
                                : !meta.touched && submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_mode
                                ? submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_mode
                                : null
                        }
                        required
                        select>
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="AES 128">AES 128</MenuItem>
                        <MenuItem value="AES 192">AES 192</MenuItem>
                        <MenuItem value="AES 256">AES 256</MenuItem>
                    </TextField>
                )}
            </Field>
            <OnChange name="direct_feed_input_details.zixi_push_options.encryption_mode">
                {(value) => {
                    if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_mode) {
                        onFieldChange("direct_feed_input_details.zixi_push_options.encryption_mode", value);
                    }
                }}
            </OnChange>
            {/* Only show the next field if the previous field is not "none"*/}
            {values?.direct_feed_input_details?.zixi_push_options?.encryption_mode !== "None" && (
                <>
                    <Field
                        name="direct_feed_input_details.zixi_push_options.encryption_key"
                        component="input"
                        type="text"
                        placeholder="Encryption Key"
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            if (value && (value.length < 1 || value.length > 64)) {
                                return "Encryption Key must be between 1 and 64 characters";
                            }
                        }}>
                        {({ input, meta }) => (
                            <TextField
                                fullWidth
                                required
                                label="Encryption Key"
                                type="text"
                                error={
                                    (meta.touched && meta.error) ||
                                    (!meta.touched &&
                                        submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_key)
                                        ? true
                                        : false
                                }
                                helperText={
                                    meta.touched && meta.error
                                        ? meta.error
                                        : !meta.touched &&
                                          submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_key
                                        ? submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_key
                                        : null
                                }
                                {...input}
                            />
                        )}
                    </Field>
                    <OnChange name="direct_feed_input_details.zixi_push_options.encryption_key">
                        {(value) => {
                            if (submitError?.data?.errors?.direct_feed_input_details?.zixi_push_options?.encryption_key) {
                                onFieldChange("direct_feed_input_details.zixi_push_options.encryption_key", value);
                            }
                        }}
                    </OnChange>
                </>
            )}
        </Box>
    );
};

export default ZixiPushOptionsForm;
