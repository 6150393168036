import React, { SyntheticEvent, useEffect } from "react";
import StepperForm from "components/Forms/StepperForm";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { CheckboxField, RadioGroupField, SliderField, TextField } from "components/Forms";
import { useNavigate, useParams } from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    TextField as MuiTextField,
    Select,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import ColorEnhancementForm from "features/packager/Components/ColorEnhancementForm";
import ColorimetryOptionsForm from "features/packager/Components/ColorimetryOptionsForm";
import OnChange from "components/FinalFormListener/OnChange";

import { AddCircle, Delete, MoreVert } from "@mui/icons-material";
import { agent, GetPushPackagerResponse, IRegions, RootStoreContext } from "api";
import AudioTrackForm from "features/packager/Components/AudioTrackForm";
import AudioTrackVariant from "features/packager/Components/AudioTrackVariant";
import EnvironmentSource from "features/packager/Components/EnvironmentSource";
import VideoTrackForm from "features/packager/Components/VideoTrackForm";
import DirectSource from "features/packager/Components/DirectSource";
import SrtCallerOptionsForm from "features/packager/Components/SrtCallerOptionsForm";
import SrtListenerOptionsForm from "features/packager/Components/SrtListenerOptionsForm";
import ZixiPushOptionsForm from "features/packager/Components/ZixiPushOptionsForm";
import ZixiPullOptionsForm from "features/packager/Components/ZixiPullOptionsForm";
import DataTrackForm from "features/packager/Components/DataTrackForm";
import { Editor, useMonaco } from "@monaco-editor/react";
import SaveAsPreset from "features/packager/Components/SaveAsPreset";
import { pruneEmptyErrors } from "features/common/PruneEmptyErrors";
import SettingsInfo from "features/packager/Components/SettingsInfo";
import SourceInfo from "features/packager/Components/SourceInfo";
import GlobalConfigInfo from "features/packager/Components/GlobalConfigInfo";
import RoleProvider from "components/RoleProvider";
import { DeletePackagerPreset } from "../Components/DeletePackagerPreset";
import DeleteIcon from "@mui/icons-material/Delete";
import { set } from "date-fns";
import Review from "features/pullPackager/Components/Review";
import CreatePackagerSuccess from "./Components/CreatePackagerSuccess";
import { useSources } from "hooks";

interface CreatePullPackagerProps {
    mode: "create" | "edit";
}

const CreatePullPackagerSource: React.FC<CreatePullPackagerProps> = ({ mode }) => {
    const monaco = useMonaco();

    monaco?.editor.defineTheme("my-dark", {
        base: "vs-dark",
        inherit: true,
        rules: [],
        colors: {
            "editor.background": "#0F1419"
        }
    });

    const theme = useTheme();
    const navigate = useNavigate();
    const { id, sourceId } = useParams();
    const package_id = id;
    const source_id = sourceId;
    const { getInputsDropdownList } = useSources();

    const rootStore = React.useContext(RootStoreContext);

    const { openDialog, closeDialog } = rootStore.dialogStore;

    const [initialValues, setInitialValues] = React.useState<GetPushPackagerResponse | any>({
        individual_track_configuration: {
            audio_tracks: []
        },
        video_delay_ms: 0
    });
    const [presets, setPresets] = React.useState<any>();
    const [sources, setSources] = React.useState<any>();
    const [cloudRegions, setCloudRegions] = React.useState<IRegions | any>();
    const [chosenPreset, setChosenPreset] = React.useState<number>();
    const [newPackagerId, setNewPackagerId] = React.useState<string>("");
    const [hasRun, setHasRun] = React.useState(false);

    const getInitialValues = async (sourceId: string) => {
        await agent
            .getCleanInstance()
            .get(`pull_packager/transcoders/${sourceId}`)
            .then((response) => {
                setInitialValues({
                    video_delay_ms: 0,
                    ...response.data.data.transcode_parameters
                });
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const getSourceList = async (environmentId: string) => {
        const response = await getInputsDropdownList(environmentId);
        setSources(response);
    };

    const getCloudRegions = async (cloudCode: string) => {
        const response = await agent.Environment.getRegions(cloudCode);
        setCloudRegions(response);
    };

    const getPresets = async () => {
        await agent.Packager.getPackagerPresets("pull").then((response) => {
            console.log("Presets", response);
            setPresets(response);
        });
    };

    const loadPreset = (name: string, preset: any) => {
        const pull_packager_uuid = id;

        if (preset?.make_permanent_destination) {
            getSourceList(preset.environment_id);
        }

        if (preset?.cloud_provider) {
            getCloudRegions(preset.cloud_provider);
        }
        setInitialValues({ video_delay_ms: 0, ...preset, pull_packager_uuid: pull_packager_uuid, name: name });
    };

    useEffect(() => {
        if (mode === "edit" && source_id) {
            if ((!initialValues || !initialValues.individual_track_configuration.audio_tracks.length) && !hasRun) {
                setHasRun(true);
                getInitialValues(source_id);
            }
            if (initialValues && initialValues.make_permanent_destination && initialValues.environment_id) {
                //wait for the initialValues to be set, then get the source list

                getSourceList(initialValues.environment_id);
            }
        }
    }, [getSourceList, initialValues, mode, source_id]);

    useEffect(() => {
        getPresets();
    }, []);

    const handlePresetDelete = (e: SyntheticEvent, id: number) => {
        agent.Packager.deletePackagerPreset("pull", id)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                const errors = error.data.errors;
                return Promise.resolve({ [FORM_ERROR]: error, ...errors });
            })
            .finally(() => {
                closeDialog();
                closeDialog();
                getPresets();
            });
    };

    return (
        <Form
            onSubmit={(values: any) => {
                console.log("values", values);
                console.log("submit", values);

                if (mode === "edit" && source_id) {
                    values = { ...values, id: source_id };
                    return agent.NeaLive.updatePullPackagerSource(source_id, values)
                        .then((res) => {
                            console.log("res", res);
                            setNewPackagerId(source_id);
                        })
                        .catch((error) => {
                            console.log("error", error);
                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                } else {
                    return agent.NeaLive.createPullPackagerSource(values)
                        .then((res) => {
                            console.log("res", res);
                            setNewPackagerId(res.packager_id);
                        })
                        .catch((error) => {
                            console.log("error", error);

                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                }
            }}
            mutators={{
                ...arrayMutators,
                setSubmitErrors: (args, state, utils) => {
                    utils.changeValue(state, "submitErrors", () => args[0]);
                }
            }}
            initialValues={initialValues}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <StepperForm
                            steps={[
                                {
                                    label: "Settings",
                                    description: "Enter a name for the new transcoder and select a preset (optional)",
                                    infoContent: <SettingsInfo mode={mode} />,
                                    hasError: Boolean(submitErrors?.name),
                                    content: (
                                        <Box>
                                            <Box sx={{ display: "none" }}>
                                                <TextField name="pull_packager_uuid" label="NEA Live Instance" initialValue={id} />
                                            </Box>
                                            <TextField
                                                name="name"
                                                label="Name"
                                                helperText={() => {
                                                    return "Name must be between 1 and 50 characters";
                                                }}
                                                disabled={mode === "edit"}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                    if (value.length < 1 || value.length > 50) {
                                                        return "Name must be between 1 and 50 characters";
                                                    }
                                                    if (!/^[a-zA-Z0-9._-\s]*$/.test(value)) {
                                                        return "Name must contain only valid characters of: a-z, A-Z, 0-9, ., _, -, and space";
                                                    }
                                                }}
                                            />
                                            {/* <OnChange name="name">
                                                {() => {
                                                    if (submitErrors?.name) {
                                                        delete submitErrors.name;
                                                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                                                    }
                                                }}
                                            </OnChange> */}
                                            {mode !== "edit" && presets && presets.data && presets.data.length > 0 && (
                                                <Box>
                                                    <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                                                        Preset
                                                    </Typography>
                                                    <Select name="preset" placeholder="Preset" label="Preset" fullWidth>
                                                        <MenuItem value={0}>No Preset</MenuItem>
                                                        {presets?.data.map((preset) => (
                                                            <MenuItem
                                                                key={preset.id}
                                                                value={preset.id}
                                                                onClick={(e) => {
                                                                    loadPreset(values.name, preset.parameters);
                                                                    setChosenPreset(preset.id);
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center"
                                                                    }}>
                                                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                        <Typography>{preset.name}</Typography>
                                                                        {preset.service_config_version && (
                                                                            <Tooltip title="Service Config Version">
                                                                                <Chip
                                                                                    size="small"
                                                                                    label={"v" + preset.service_config_version}
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </Stack>
                                                                    <Box
                                                                        sx={{
                                                                            justifyContent: "flex-end",
                                                                            display: chosenPreset === preset.id ? "none" : ""
                                                                        }}>
                                                                        <Tooltip title="Delete preset">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    openDialog(
                                                                                        <DeletePackagerPreset
                                                                                            id={preset.id}
                                                                                            handleDelete={handlePresetDelete}
                                                                                        />
                                                                                    );
                                                                                }}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            )}
                                            <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                                                Packager Redundancy
                                            </Typography>
                                            <CheckboxField
                                                name="enable_backup"
                                                label="Enable Packager Redundancy"
                                                // helperText={() => {
                                                //     return "Enabling packager redundancy will incur additional costs.";
                                                // }}
                                            />
                                            {values.enable_backup && (
                                                <Alert severity="info" sx={{ mt: 2 }}>
                                                    Enabling packager redundancy will incur additional costs.
                                                </Alert>
                                            )}
                                        </Box>
                                    )
                                },
                                {
                                    label: "Source",
                                    description: "Select the input source for the pull packager",
                                    infoContent: <SourceInfo values={values} />,
                                    hasError: Boolean(
                                        submitErrors?.input_feed_source ||
                                            submitErrors?.cloud_provider ||
                                            submitErrors?.region ||
                                            submitErrors?.direct_feed_input_details
                                    ),
                                    content: (
                                        <Box>
                                            <RadioGroupField
                                                name="input_feed_source"
                                                label="Source Type"
                                                required
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                                row
                                                disabled={mode === "edit"}
                                                options={[
                                                    { label: "Direct", value: "direct" },
                                                    { label: "Environment", value: "environment" }
                                                ]}
                                            />
                                            {values.input_feed_source === "environment" && (
                                                <Box>
                                                    <EnvironmentSource nea_instance_id={id} mode={mode} />
                                                </Box>
                                            )}
                                            {values.input_feed_source === "direct" && (
                                                <Box>
                                                    <DirectSource nea_mode />
                                                    <Box sx={{ mt: 2 }}>
                                                        {values.direct_feed_input_details?.input_type === "srt_caller" && (
                                                            <SrtCallerOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                        {values.direct_feed_input_details?.input_type === "srt_listener" && (
                                                            <SrtListenerOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                        {values.direct_feed_input_details?.input_type === "zixi_push" && (
                                                            <ZixiPushOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                        {values.direct_feed_input_details?.input_type === "zixi_pull" && (
                                                            <ZixiPullOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string, value: any) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                },
                                {
                                    label: "Global Track Configuration",
                                    description: "Set global track configuration settings",
                                    infoContent: <GlobalConfigInfo />,
                                    hasError: Boolean(submitErrors?.global_track_configuration),
                                    content: (
                                        <Box>
                                            <RadioGroupField
                                                name="global_track_configuration.latency_mode"
                                                label="Latency Mode"
                                                required
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                                row
                                                initialValue={
                                                    values?.global_track_configuration?.latency_mode
                                                        ? values.global_track_configuration.latency_mode
                                                        : "High Quality"
                                                }
                                                options={[
                                                    { label: "Ultra Low Latency", value: "Ultra Low Latency" },
                                                    { label: "Low Latency", value: "Low Latency" },
                                                    { label: "Reduced Latency", value: "Reduced Latency" },
                                                    { label: "High Quality", value: "High Quality" },
                                                    { label: "Best Quality", value: "Best quality" }
                                                ]}
                                            />

                                            <Divider sx={{ my: 2 }} />

                                            <RadioGroupField
                                                name="global_track_configuration.chunk_period"
                                                label="Chunk Period"
                                                required
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                                row
                                                options={[
                                                    { label: "2s", value: "2s" },
                                                    { label: "4s", value: "4s" },
                                                    { label: "6s", value: "6s" },
                                                    { label: "8s", value: "8s" },
                                                    { label: "10s", value: "10s" }
                                                ]}
                                            />

                                            <Divider sx={{ my: 2 }} />

                                            <CheckboxField
                                                name="enable_multiple_instances_synchronization"
                                                label="Enable Multiple Instances Synchronization"
                                                initialValue={
                                                    values?.enable_backup || values?.enable_multiple_instances_synchronization
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    if (submitErrors?.enable_multiple_instances_synchronization) {
                                                        delete submitErrors.enable_multiple_instances_synchronization;
                                                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                                                    }
                                                }}
                                            />

                                            <Divider sx={{ my: 2 }} />

                                            <SliderField name="video_delay_ms" label="Video Delay (ms)" min={0} max={2000} step={50} />

                                            <OnChange name="global_track_configuration.chunk_period">
                                                {() => {
                                                    if (
                                                        submitErrors &&
                                                        submitErrors.global_track_configuration &&
                                                        submitErrors.global_track_configuration.chunk_period
                                                    ) {
                                                        delete submitErrors.global_track_configuration.chunk_period;
                                                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                                                    }
                                                }}
                                            </OnChange>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Video Tracks",
                                    description: "Select individual video tracks and set their parameters",
                                    infoContent: (
                                        <>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Codec</b> - This is the method used to compress and decompress the video data. Different
                                                codecs have different characteristics in terms of quality, compression ratio, and
                                                computational requirements.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Frame Size</b> - This is the dimensions of the video frame, typically expressed as width
                                                x height in pixels. Common frame sizes include 1920x1080 (for Full HD) and 3840x2160 (for
                                                4K).
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Frame Rate</b> - This is the number of frames displayed per second. A higher frame rate
                                                can make the video appear smoother, but it also requires more data. Common frame rates
                                                include 24, 30, and 60 frames per second.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Bitrate</b> - This is the amount of data used to encode one second of video. A higher
                                                bitrate can result in higher quality, but it also requires more bandwidth.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>GOP Size</b> - GOP stands for Group of Pictures. It's a group of successive pictures
                                                within a coded video stream where the first picture is a I-frame and the following pictures
                                                are P- or B-frames. A larger GOP size can provide more efficient compression, but it may
                                                also increase the latency and decrease the error resilience.
                                            </Typography>
                                            {values?.individual_track_configuration?.video_tracks?.[0].codec === "MPEG-4" ? (
                                                <>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Baseline</b> - This profile is primarily for lower-cost applications with limited
                                                        computing resources. It's used widely in videoconferencing and mobile applications.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Main</b> - This profile is primarily for lower-cost applications with limited
                                                        computing resources. It's used widely in videoconferencing and mobile applications.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Main</b> - This profile is used for standard-definition digital TV broadcasts
                                                        that use the MPEG-4 format. It balances between video quality and system resources.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>High</b> - This profile is used for broadcast and disc storage applications,
                                                        particularly for high-definition television applications (for example, HD DVD and
                                                        Blu-ray Disc).
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>High 4:2:2 10</b> - This profile extends the High Profile to support 10-bit video
                                                        and 4:2:2 chroma subsampling. It's used in professional applications where higher
                                                        color fidelity is needed.
                                                    </Typography>
                                                </>
                                            ) : null}

                                            {values?.individual_track_configuration?.video_tracks?.[0].codec === "HEVC" ? (
                                                <>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Main</b> - This profile is designed for a broad range of applications from mobile
                                                        to high-end 4K broadcasting and premium entertainment content. It supports 8-bit
                                                        color depth and 4:2:0 chroma subsampling.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>High</b> - This profile extends the Main Profile to support 10-bit color depth.
                                                        It's used in professional applications and high-end broadcasting that require high
                                                        color fidelity.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>High 4:2:2 10</b> - This profile extends the High Profile to support 4:2:2 chroma
                                                        subsampling. It's used in professional applications where higher color fidelity and
                                                        chroma subsampling are needed, such as professional film post-production and
                                                        high-end video conferencing.
                                                    </Typography>
                                                </>
                                            ) : null}
                                        </>
                                    ),
                                    hasError: Boolean(submitErrors?.individual_track_configuration?.video_tracks),
                                    content: (
                                        <Box>
                                            {submitErrors &&
                                                submitErrors.individual_track_configuration &&
                                                submitErrors.individual_track_configuration.video_tracks &&
                                                typeof submitErrors.individual_track_configuration.video_tracks === "string" && (
                                                    <Alert severity="error" sx={{ mb: 2 }}>
                                                        {submitErrors.individual_track_configuration.video_tracks}
                                                    </Alert>
                                                )}
                                            <FieldArray
                                                name="individual_track_configuration.video_tracks"
                                                defaultValue={[{}]}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}>
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, index) => (
                                                            <Card key={index} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Video Track ${index + 1}`}
                                                                    action={
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                fields.remove(index);
                                                                            }}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                                <CardContent>
                                                                    <VideoTrackForm form={form} videoTrackIndex={index} />
                                                                    {submitErrors &&
                                                                        submitErrors.individual_track_configuration &&
                                                                        submitErrors.individual_track_configuration.video_tracks &&
                                                                        typeof submitErrors.individual_track_configuration.video_tracks ===
                                                                            "object" &&
                                                                        submitErrors.individual_track_configuration.video_tracks?.[index] &&
                                                                        typeof submitErrors.individual_track_configuration.video_tracks?.[
                                                                            index
                                                                        ] === "string" && (
                                                                            <Alert severity="error" sx={{ mb: 2 }}>
                                                                                {
                                                                                    submitErrors.individual_track_configuration
                                                                                        .video_tracks?.[index]
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddCircle />}
                                                            onClick={() =>
                                                                fields.push({
                                                                    profile: "Main"
                                                                })
                                                            }>
                                                            Add Video Track
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Advanced Video Settings",
                                    description: "Configure advanced video settings such as Dolby Vision and Color Enhancement",
                                    infoContent: (
                                        <Box>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Output Color Enhancement</b> - Adjusts video color properties such as hue, saturation,
                                                brightness, and contrast to enhance the visual output.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Output Colorimetry</b> - Settings related to the color space and dynamic range of the
                                                video, including options for HDR (High Dynamic Range).
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Enable Dolby Vision</b> - Turns on Dolby Vision encoding, which can provide enhanced HDR
                                                video playback on compatible devices.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Dynamic Range</b> - Selects the desired standard for dynamic range, like BT.2020 for HDR
                                                content.
                                            </Typography>
                                            {values?.output_colorimetry_parameters?.enable_dolby_vision ? (
                                                <>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Content Type</b> - Defines the type of content (e.g., cinema/movies), which can
                                                        affect color grading and dynamic range handling.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Intended White Point (k)</b> - Specifies the target white point value for the
                                                        video content, setting the reference for color balance in the image.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Profile</b> - Refers to the Dolby Vision profile to be used, affecting
                                                        compatibility and quality.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>HDR Conformance</b> - Selects the HDR standard for the video stream, like DVB
                                                        HDR10.
                                                    </Typography>
                                                </>
                                            ) : null}
                                            {values?.output_colorimetry_parameters?.hdr_conformance === "DVB HDR10" ||
                                            values?.output_colorimetry_parameters?.hdr_conformance === "ATSC HDR10" ? (
                                                <>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Max Frame Average Light Level (Max FALL)</b> - Sets the maximum average
                                                        brightness level for frames in the video.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Max Content Light Level</b> - Determines the peak brightness level for any given
                                                        frame in the
                                                    </Typography>
                                                    video.
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Enable HDR 10+</b> - Enables HDR 10+ encoding for enhanced scene-by-scene color
                                                        and brightness levels.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Mastering Display Color Volume Settings</b> - Adjusts the color volume settings
                                                        of the mastering display, including minimum and maximum luminance values, which
                                                        define the brightness range for HDR content.
                                                    </Typography>
                                                </>
                                            ) : null}
                                        </Box>
                                    ),
                                    hasError: Boolean(submitErrors?.output_colorimetry_parameters),
                                    content: (
                                        <Box>
                                            <Box sx={{ mb: 3 }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Output Color Enhancement
                                                </Typography>
                                                <ColorEnhancementForm form={form} values={values} />
                                            </Box>
                                            <Divider sx={{ my: 3 }} />
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Output Colorimetry
                                                </Typography>
                                                <ColorimetryOptionsForm
                                                    form={form}
                                                    values={values}
                                                    submitError={submitError}
                                                    onFieldChange={(fieldName: string) => {
                                                        console.log("fieldName", fieldName);
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Audio Tracks",
                                    description: "Select individual audio tracks and set their parameters",
                                    infoContent: (
                                        <>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Codec</b> - This is the method used to compress and decompress the audio data. Different
                                                codecs have different characteristics in terms of quality, compression ratio, and
                                                computational requirements.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Layout</b> - This refers to the arrangement of audio channels in the track. For example,
                                                "mono" means there is only one channel, "stereo" means there are two channels (left and
                                                right), and "Surround 5.1" means there are six channels (front left, front right, center,
                                                low-frequency effects, surround left, and surround right).
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Loudness Control</b> - This is a feature that adjusts the volume of the audio track to
                                                maintain a consistent perceived loudness. It can be useful for preventing sudden changes in
                                                volume, especially in content with a wide dynamic range.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Loudness Leveler Mode</b> - This setting determines how the loudness control feature
                                                operates. Different modes may use different algorithms or parameters to adjust the volume.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Sample Rate</b> - This is the number of samples of audio carried per second, measured in
                                                kHz. A higher sample rate can provide higher audio quality, but it also requires more data.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Bitrate</b> - This is the amount of data used to encode one second of audio. A higher
                                                bitrate can result in higher quality, but it also requires more bandwidth.
                                            </Typography>
                                        </>
                                    ),
                                    hasError: Boolean(submitErrors?.individual_track_configuration?.audio_tracks),
                                    content: (
                                        <Box>
                                            {submitErrors &&
                                                submitErrors.individual_track_configuration &&
                                                submitErrors.individual_track_configuration.audio_tracks &&
                                                typeof submitErrors.individual_track_configuration.audio_tracks === "string" && (
                                                    <Alert severity="error" sx={{ mb: 2 }}>
                                                        {submitErrors.individual_track_configuration.audio_tracks}
                                                    </Alert>
                                                )}
                                            <FieldArray
                                                name="individual_track_configuration.audio_tracks"
                                                defaultValue={[{ codec: "MPEG AAC" }]}>
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, trackindex) => (
                                                            <Card key={trackindex} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Audio Track ${trackindex + 1}`}
                                                                    action={
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                fields.remove(trackindex);
                                                                            }}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                                <CardContent>
                                                                    <AudioTrackForm
                                                                        audioTrackIndex={trackindex}
                                                                        values={values}
                                                                        submitError={submitError}
                                                                        onFieldChange={(fieldName: string, value: any) => {
                                                                            console.log("fieldName", fieldName);
                                                                            console.log("value", value);
                                                                        }}
                                                                        form={form}
                                                                    />
                                                                    <FieldArray
                                                                        name={`individual_track_configuration.audio_tracks[${trackindex}].variants`}
                                                                        defaultValue={[{}]}
                                                                        validate={(value) => {
                                                                            if (!value) {
                                                                                return "Required";
                                                                            }
                                                                        }}>
                                                                        {({ fields }) => (
                                                                            <>
                                                                                {fields.map((name, index) => (
                                                                                    <Box
                                                                                        key={name}
                                                                                        sx={{
                                                                                            backgroundColor: theme.palette.action.hover,
                                                                                            borderRadius: 1,
                                                                                            padding: 1,
                                                                                            marginBottom: 2
                                                                                        }}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center"
                                                                                            }}>
                                                                                            <Typography fontWeight="bold" fontSize={15}>
                                                                                                Variant {index + 1}
                                                                                            </Typography>
                                                                                            <IconButton
                                                                                                aria-label="delete"
                                                                                                onClick={() => {
                                                                                                    fields.remove(index);
                                                                                                }}>
                                                                                                <Delete />
                                                                                            </IconButton>
                                                                                        </Box>
                                                                                        <Divider sx={{ my: 1 }} />
                                                                                        <AudioTrackVariant
                                                                                            audioTrackIndex={trackindex}
                                                                                            index={index}
                                                                                            name={name}
                                                                                            onFieldChange={(
                                                                                                fieldName: string,
                                                                                                value: any
                                                                                            ) => {
                                                                                                console.log("fieldName", fieldName);
                                                                                                console.log("value", value);
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                ))}
                                                                                <Button
                                                                                    variant="contained"
                                                                                    startIcon={<AddCircle />}
                                                                                    onClick={() => fields.push({})}>
                                                                                    Add Variant
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </FieldArray>
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddCircle />}
                                                            onClick={() => fields.push({ codec: "MPEG AAC" })}>
                                                            Add Audio Track
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Data Tracks",
                                    description: "Select individual data tracks and set their parameters",
                                    infoContent: (
                                        <>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>Closed Captions</b> - These are a type of subtitling that displays spoken dialogues and
                                                non-speech information as text on the screen. Closed captions are typically used for
                                                accessibility purposes, such as by viewers who are deaf or hard of hearing. They can be
                                                turned on or off by the viewer.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>DVB Subtitles</b> - DVB (Digital Video Broadcasting) subtitles are a type of subtitle
                                                that is commonly used in European digital television broadcasts. Unlike closed captions, DVB
                                                subtitles are typically graphical rather than textual, which allows for more complex
                                                formatting and styling.
                                            </Typography>
                                            <Typography sx={{ mb: 1 }}>
                                                <b>SCTE-35 Pass-through</b> - SCTE-35 is a standard used in the broadcasting industry for
                                                signaling ad insertion points and other events in a video stream. A pass-through means that
                                                these signals are not modified or decoded, but simply passed through to the output stream.
                                                This can be useful for maintaining compatibility with downstream systems that rely on these
                                                signals.
                                            </Typography>
                                        </>
                                    ),
                                    hasError: Boolean(submitErrors?.individual_track_configuration?.data_tracks),
                                    content: (
                                        <Box>
                                            <FieldArray name="individual_track_configuration.data_tracks">
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, index) => (
                                                            <Card key={index} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Data Track ${index + 1}`}
                                                                    action={
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                fields.remove(index);
                                                                            }}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                                <CardContent>
                                                                    <DataTrackForm
                                                                        dataTrackIndex={index}
                                                                        values={values}
                                                                        onFieldChange={form.change}
                                                                    />
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddCircle />}
                                                            onClick={() => fields.push({})}>
                                                            Add Data Track
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Review",
                                    description: "Review your push packager configuration",
                                    infoContent: (
                                        <>
                                            <Typography sx={{ mb: 1 }}>
                                                Review your pull packager configuration and click create.
                                            </Typography>
                                            <Typography>
                                                You also have the option to save this configuration as a prest to reuse it in the future.
                                            </Typography>
                                        </>
                                    ),
                                    hasError: false,
                                    content: <Review values={values} form={form} />
                                }
                            ]}
                            formTitle={
                                mode === "create" ? "Create Pull Packager Transcoder" : "Edit Pull Packager Transcoder: " + values.name
                            }
                            values={values}
                            submitError={submitError}
                            invalid={invalid}
                            submitting={submitting}
                            submitButtonText={mode === "create" ? "Create" : "Update"}
                            successStep={<CreatePackagerSuccess mode={mode} values={values} packager_id={newPackagerId} />}
                            successTabContent={
                                <>
                                    <Typography sx={{ mb: 3 }}>Your Pull Packager is now building.</Typography>
                                    <Typography sx={{ mb: 3 }}>This can take up to 15 minutes.</Typography>
                                    <Typography>Once complete, you will receive a notification</Typography>
                                </>
                            }
                            showSuccessStep={submitSucceeded}
                            onFinish={() => {
                                navigate(`/pull-packager/${id}?tab=3`);
                            }}
                            onCancel={() => {
                                openDialog(
                                    <>
                                        <DialogTitle>Are you sure?</DialogTitle>
                                        <DialogContent>You have unsaved changes. Are you sure you want to leave this page?</DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    closeDialog();
                                                }}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    closeDialog();
                                                    navigate(`/pull-packager/${id}?tab=3`);
                                                }}>
                                                Leave
                                            </Button>
                                        </DialogActions>
                                    </>
                                );
                            }}
                            mode={mode === "edit" || submitFailed ? "edit" : "create"}
                        />
                    </form>
                );
            }}
        />
    );
};

export default CreatePullPackagerSource;
