import React from "react";
import { agent } from "api";
import InfoItem from "components/InfoItem";

type ItemStatsProps = {
    item_id: string;
    type: string;
};

const ItemStats: React.FC<ItemStatsProps> = ({ item_id, type }) => {
    const [itemStats, setItemStats] = React.useState<any>();

    const getSourceStats = async (input_id: string) => {
        const stats = await agent.Channel.getInputLiveStats(input_id);
        setItemStats(stats);
    };

    const getDestinationStats = async (output_id: string) => {
        const stats = await agent.Destination.getDestinationLiveStats(output_id);
        setItemStats(stats);
    };

    React.useEffect(() => {
        if (type !== "destination") {
            getSourceStats(item_id);
        } else {
            getDestinationStats(item_id);
        }
        const interval = setInterval(() => {
            if (type !== "destination") {
                getSourceStats(item_id);
            } else {
                getDestinationStats(item_id);
            }
        }, 1500);

        return () => {
            setItemStats(null);
            clearInterval(interval);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id={item_id} style={{ display: "flex", flexDirection: "column", minHeight: "352px" }}>
            {itemStats &&
                itemStats.data.map((item: any, i: number) => (
                    <div
                        key={i}
                        style={{ marginBottom: 2, display: item.label.length > 0 ? "flex" : "none", justifyContent: "space-between" }}>
                        <div style={{ fontSize: 10, opacity: 0.8 }}>{item.label}</div>
                        <div style={{ fontSize: 12 }}>{item.value}</div>
                    </div>
                ))}
        </div>
    );
};

export default ItemStats;
