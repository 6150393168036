import { agent, IMuteNotificationsRequest } from "api";
import { useState, useCallback } from "react";

export const useNotification = () => {
    const [isLoading, setIsLoading] = useState(false);

    const muteUserCategories = useCallback(async (request: IMuteNotificationsRequest) => {
        return await agent.Notification.muteUserCategories(request);
    }, []);

    return {
        isLoading,
        muteUserCategories
    };
};
