import React from "react";
import { Field, useForm } from "react-final-form";
import { TextField as MuiTextField, SxProps } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";

interface Props {
    name: string;
    label: string;
    initialValue?: string | number;
    required?: boolean;
    type?: string;
    parse?: boolean;
    disabled?: boolean;
    sx?: SxProps;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    id?: string;
    validate?: (value: any) => string | undefined;
    onChange?: (field: string, value?: any, previous?: any) => void;
    // validateFields?: string[];
}

/**
 * TextField is a React Functional Component that renders a text field with a label.
 * It uses the Material-UI library for styling and layout.
 *
 * @component
 * @param {string} props.name - The name of the text field.
 * @param {string} props.label - The label of the text field.
 * @param {string} [props.initialValue] - The initial value of the text field.
 * @param {boolean} [props.required=false] - Whether the text field is required for form submission.
 * @param {string} [props.type="text"] - The type of the text field. Defaults to "text".
 * @param {boolean} [props.parse=false] - Whether to parse the value as a number.
 * @param {boolean} [props.disabled=false] - Whether the text field is disabled.
 * @param {SxProps} [props.sx] - The sx prop from Material-UI.
 * @param {function} props.helperText - A function that returns the helper text.
 * @param {function} props.error - A function that returns a boolean indicating if there is an error.
 * @param {string} [props.id] - The id of the text field.
 * @param {function} [props.validate] - A function that validates the value of the text field.
 * @param {function} [props.onChange] - A function that is called when the value of the text field changes.
 *
 * @example
 * <TextField name="name" label="Name" helperText={meta => meta.touched && meta.error} error={meta => meta.touched && meta.error} />
 *
 * @returns {ReactElement} The TextField component
 */

export const TextField: React.FC<Props> = ({
    name,
    label,
    initialValue,
    required,
    type,
    parse,
    disabled,
    sx,
    helperText,
    error,
    validate,
    onChange,
}) => {
    const parseNumber = (value) => (isNaN(Number(value)) ? value : Number(value));
    const form = useForm();
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    return (
        <>
            <Field
                key={name + updateDate.toISOString()}
                name={name}
                initialValue={initialValue}
                type={type}
                onChange={() => setUpdateDate(new Date())}
                parse={parse ? parseNumber : undefined}
                validate={(value: any) => {
                    if (validate) {
                        return validate(value);
                    }
                }}
            >
                {({ input, meta }) => (
                    <MuiTextField
                        sx={sx}
                        disabled={disabled ?? false}
                        required={required}
                        {...input}
                        label={label}
                        fullWidth
                        value={input.value}
                        onChange={input.onChange}
                        error={Boolean(
                            ((meta.dirty || meta.touched) && meta.error) || meta.submitError || (meta.touched && meta.invalid) || (error && error(meta))
                        )}
                        helperText={((meta.dirty || meta.touched) && meta.error) || meta.submitError || (helperText && helperText(meta))}
                        variant="standard"
                    />
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {
                    function removeEmpty(obj: any) {
                        Object.keys(obj).forEach((key) => {
                            if (obj[key] && typeof obj[key] === "object") {
                                removeEmpty(obj[key]);
                            }
                            if (obj[key] && Array.isArray(obj[key]) && !obj[key].length) {
                                delete obj[key];
                            }
                            if (obj[key] && typeof obj[key] === "object" && !Object.keys(obj[key]).length) {
                                delete obj[key];
                            }
                        });
                        return obj;
                    }

                    if (form.getState().submitErrors) {
                        const keys = name.split(".");
                        let submitErrors = form.getState().submitErrors;

                        for (let key of keys) {
                            if (submitErrors && typeof submitErrors === "object" && key in submitErrors) {
                                submitErrors = submitErrors[key];
                            } else {
                                submitErrors = undefined;
                                break;
                            }
                        }

                        if (submitErrors) {
                            console.log("Submit error on field", submitErrors);
                            // Deleting the error from the original object is a bit more complex because you need to navigate to the parent object
                            let parentObject = form.getState().submitErrors;
                            let grandParentObject;
                            let grandParentKey;
                            for (let i = 0; i < keys.length - 1; i++) {
                                if (parentObject && typeof parentObject === "object" && keys[i] in parentObject) {
                                    grandParentObject = parentObject;
                                    grandParentKey = keys[i];
                                    parentObject = parentObject[keys[i]];
                                } else {
                                    parentObject = undefined;
                                    break;
                                }
                            }
                            if (parentObject && typeof parentObject === "object") {
                                delete parentObject[keys[keys.length - 1]];
                                // Check if parentObject is now empty
                                if (Object.keys(parentObject).length === 0 && grandParentObject && grandParentKey) {
                                    delete grandParentObject[grandParentKey];
                                }
                            }
                        }

                        console.log(removeEmpty(form.getState().submitErrors));
                        //form.mutators.setSubmitErrors(removeEmpty(form.getState().submitErrors));
                    }

                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </>
    );
};

export default TextField;
