import React, { useEffect, useRef, useState } from "react";
import Hls, { Level, MediaPlaylist } from "hls.js";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import InfoItem from "components/InfoItem";

interface HlsVideoPlayerProps {
    src: string; // URL of the HLS video stream
    name: string; // Name of the video
}

const HlsVideoPlayer: React.FC<HlsVideoPlayerProps> = ({ src, name }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [hls, setHls] = useState<Hls | null>(null);
    const [tracks, setTracks] = useState<Level[]>([]);
    const [audioTracks, setAudioTracks] = useState<MediaPlaylist[]>([]);
    const [currentTrack, setCurrentTrack] = useState<number>(0);
    const [currentAudioTrack, setCurrentAudioTrack] = useState<number>(0);
    const [subtitleTracks, setSubtitleTracks] = useState<MediaPlaylist[]>([]);
    const [currentSubtitleTrack, setCurrentSubtitleTrack] = useState<number>(-1); // -1 indicates no subtitles selected

    const [downloadSpeed, setDownloadSpeed] = useState<number>(0);
    const [latency, setLatency] = useState<number>(0);

    useEffect(() => {
        let hlsInstance: Hls;

        if (Hls.isSupported() && !videoRef.current?.canPlayType("application/vnd.apple.mpegURL")) {
            hlsInstance = new Hls();
            hlsInstance.loadSource(src);
            hlsInstance.attachMedia(videoRef.current as HTMLMediaElement);

            hlsInstance.on(Hls.Events.MANIFEST_PARSED, function () {
                //hlsInstance.currentLevel = 0;
                //hlsInstance.audioTrack = 0;
                console.log("Video Tracks", hlsInstance.levels);
                console.log("Audio Tracks", hlsInstance.audioTracks);

                setTracks(hlsInstance.levels);
                setAudioTracks(hlsInstance.allAudioTracks);
                // Retrieve and set subtitle tracks
                setSubtitleTracks(hlsInstance.subtitleTracks);
                console.log("Audio Tracks", hlsInstance.allAudioTracks);
                console.log("Manifest Parsed", hlsInstance);

                if (videoRef.current) {
                    try {
                        videoRef.current.play();
                    } catch (error) {
                        console.error(error);
                    }
                }
                setHls(hlsInstance);
            });

            hlsInstance.on(Hls.Events.LEVEL_UPDATED, function (event, data) {
                const currentLevel = hlsInstance.currentLevel;
                setCurrentTrack(currentLevel);

                const currentAudioTrack = hlsInstance.audioTrack;
                setCurrentAudioTrack(currentAudioTrack);

                if (data.details.live) {
                    const levelDetails = data.details;
                    const playheadPosition = videoRef.current?.currentTime;

                    // Calculate latency using the end of the last fragment in the level
                    if (playheadPosition !== undefined && levelDetails.fragments.length > 0) {
                        const lastFragment = levelDetails.fragments[levelDetails.fragments.length - 1];
                        const liveEdge = lastFragment.start + lastFragment.duration;
                        const currentLatency = liveEdge - playheadPosition;

                        setLatency(currentLatency);
                    }
                }
            });

            hlsInstance.on(Hls.Events.FRAG_LOADED, (event, data) => {
                const speed = (data.frag.stats.loaded * 8) / (data.frag.duration * 1000);
                setDownloadSpeed(speed);
            });

            hlsInstance.on(Hls.Events.DESTROYING, function () {
                console.log("DESTROYING");
            });

            // Monitor when the audio track is being switched
            hlsInstance.on(Hls.Events.AUDIO_TRACK_SWITCHED, function (event, data) {
                console.log("Audio track has been switched to:", data.groupId);
                console.log("Current audio track details:", hlsInstance.audioTracks[hlsInstance.audioTrack]);
                setCurrentAudioTrack(
                    hlsInstance.audioTracks.findIndex((track) => track.groupId === data.groupId)
                );
            });

            //setHls(hlsInstance);
        } else if (videoRef.current && videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
            videoRef.current.src = src;
            videoRef.current.addEventListener("loadedmetadata", function () {
                videoRef.current?.play();
            });
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeAttribute("src");
            }
            if (hlsInstance) {
                hlsInstance.off(Hls.Events.FRAG_LOADED);
                hlsInstance.detachMedia();
                hlsInstance.destroy();
            }
            setHls(null);
            setCurrentAudioTrack(0);
            setCurrentTrack(0);
        };
    }, [src]);

    useEffect(() => {}, [src]);

    const handleTrackChange = (index: number) => {
        if (hls) {
            hls.currentLevel = index;
            setCurrentTrack(index);
        }
    };

    const handleAudioTrackChange = (event: React.ChangeEvent<{ value: any }>) => {
        console.log("Audio Track Change", event.target.value);
        const trackId = event.target.value as number;

        if (hls) {
            hls.audioTrack = trackId;
            setCurrentAudioTrack(trackId);
        }
    };

    const handleSubtitleTrackChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const trackId = event.target.value as number;
        if (hls) {
            hls.subtitleTrack = trackId;
            setCurrentSubtitleTrack(trackId);
        }
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", mb: 3 }}>
            <Box sx={{ width: 360, flexGrow: 0, flexShrink: 0, mr: 2, borderRadius: 1, overflow: "hidden" }}>
                <video ref={videoRef} id={new Date().toDateString()} controls style={{ width: "100%" }} muted />
            </Box>
            <Box
                sx={{
                    width: "100%",
                    flexGrow: 1
                }}>
                <Typography variant="body1" fontWeight="bold" mb={2}>
                    {name}
                </Typography>
                <TextField
                    label="Video Track"
                    variant="outlined"
                    value={currentTrack}
                    select
                    size="small"
                    onChange={(e) => handleTrackChange(Number(e.target.value))}>
                    {tracks.map((track, index) => (
                        <MenuItem key={index} value={index}>
                            Track {index + 1}: {track.width} x {track.height} / {track.bitrate / 1000} kbps
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Audio Track"
                    variant="outlined"
                    value={currentAudioTrack}
                    select
                    size="small"
                    onChange={handleAudioTrackChange}>
                    {audioTracks.map((track, index) => (
                        <MenuItem key={index} value={index}>
                            {track.name} / {track.groupId}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Data Track"
                    variant="outlined"
                    value={currentSubtitleTrack}
                    select
                    size="small"
                    onChange={handleSubtitleTrackChange}>
                    {subtitleTracks.map((track, index) => (
                        <MenuItem key={index} value={index}>
                            {track.name} / {track.lang}
                        </MenuItem>
                    ))}
                    <MenuItem value={-1}>None</MenuItem>
                </TextField>
                <Box sx={{ mt: 1 }}>
                    <InfoItem title="URL" value={src} value_type="copy" />
                    <InfoItem title="Download Speed" value={downloadSpeed.toFixed(2)} value_suffix=" KB/s" />
                    {/* <InfoItem title="Latency" value={latency.toFixed(2)} value_suffix=" s" /> */}
                </Box>
            </Box>
        </Box>
    );
};

export default HlsVideoPlayer;
