import { RootStoreContext } from "api";
import React from "react";

interface AlertWebsocketMonitorProps {
    onChange: () => void;
    type: "channel" | "destination";
    type_id: string;
    environment_id: string;
    environment_provider: string;
    environment_region: string;
    alert_id: string;
}

const AlertWebsocketMonitor: React.FC<AlertWebsocketMonitorProps> = ({
    onChange,
    type,
    type_id,
    environment_id,
    environment_provider,
    environment_region,
    alert_id
}) => {
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;

    const managing_organisation_id = rootStore.userStore.user?.managing_organisation_id;
    const constructRid = () => {
        return `env_dashboard.${managing_organisation_id}.region.${environment_provider}-${environment_region}.env.${environment_id}.${type}.${type_id}.alert.${alert_id}`;
    };

    React.useEffect(() => {
        clientConnected &&
            client
                .get(constructRid())
                .then((res) => {
                    res.on("change", () => {
                        onChange();
                    });
                })
                .catch((err) => {
                    console.warn(err);
                });
    }, [client, clientConnected]);

    return <></>;
};

export default AlertWebsocketMonitor;
