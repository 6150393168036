import React, { useContext, useEffect } from "react";
import { Form as FinalForm } from "react-final-form";
import { TextField } from "mui-rff";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired, createValidator, composeValidators } from "revalidate";
import { IOrganisationConnection, RootStoreContext } from "api";
import GuestOrganisations from "./GuestOrganisations";
import { SourcePermissionTokenSuccessful } from "../sourcePermissionTokenAccept/SourcePermissionTokenSuccessful";
import { Alert, Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useOrganisation, useSourcePermissions } from "hooks";

// TODO - THIS IS PRETTY BAD CODE - THERE ARE 2 diffferent forms being used here. That needs to go.
// but it does work at the moment.

const isValidToken = createValidator(
    (message) => (value) => {
        if (value && /[`~,.<>;':"/[\]|{}()=_+]/.test(value)) {
            return message;
        }
    },
    "Invalid token type - the token will only contain numbers and letters"
);

const validate = combineValidators({
    token: composeValidators(isValidToken, isRequired("Token"))()
});

export const Entitlements = () => {
    const rootStore = useContext(RootStoreContext);
    const { acceptOrgInvite } = useOrganisation();
    const { loadSourcePermissions, loadMyGuestOrgs } = useSourcePermissions();
    const { activeOrganisation } = rootStore.organisationStore;
    const { openDialog } = rootStore.dialogStore;

    useEffect(() => {
        loadSourcePermissions().catch((error) => console.log(error));
    }, [loadSourcePermissions, activeOrganisation]);

    return (
        <Box
            sx={{
                p: 3
            }}>
            <Typography variant="h5" gutterBottom sx={{ mt: "8px !important" }}>
                Organisation Invitation Token
            </Typography>
            <Typography>
                If you have received an invitation token to join an organisation, please enter the token below and click join.
            </Typography>
            <Box sx={{ my: 2 }}>
                <FinalForm
                    onSubmit={(values: IOrganisationConnection) =>
                        acceptOrgInvite(values)
                            .then(() => loadMyGuestOrgs())
                            .then(() => {
                                openDialog(<SourcePermissionTokenSuccessful />);
                            })
                            .catch((error) => ({
                                [FORM_ERROR]: error
                            }))
                    }
                    validate={validate}
                    render={({ handleSubmit, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Box width={"30%"}>
                                    <TextField fullWidth required name="token" label="Token" id="token" />
                                </Box>
                                <Button variant="contained" type="submit" disabled={(invalid && !dirtySinceLastSubmit) || pristine}>
                                    Join Organisation
                                </Button>
                            </Stack>
                            {submitError && !dirtySinceLastSubmit && <Alert severity="error">{submitError?.data?.message}</Alert>}
                        </form>
                    )}
                />
            </Box>

            {/* <Divider /> */}
            <GuestOrganisations />
        </Box>
    );
};
