import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SourceThumbnail from "../../sources/Components/SourceThumbnail";
import { TextField, Select, MenuItem, Button, Tab } from "@mui/material";
import { agent, IDashboardGroup, IDashboardGroups } from "api";
import ItemStats from "./ItemStats";
import ErrorMessages from "components/ErrorMessages";
import ItemInfo from "./ItemInfo";
import { TabContext, TabList } from "@mui/lab";
import { SmallTab, SmallTabList, TabPanel } from "components";

type SourceItemProps = {
    id: string;
    group_id?: string;
    group_name?: string;
    lat: any;
    lng: any;
    environmentLat: number;
    environmentLng: number;
    name: string;
    description?: string;
    type: string;
    alerts: any;
    onClick?: () => void;
    onUpdateGroup?: () => void;
    onUpdateGeo?: () => void;
    onError?: () => void;
    active?: boolean;
    hidden?: boolean;
    onHideClick: () => void;
};

const SourceItem: React.FC<SourceItemProps> = ({
    id,
    group_id,
    group_name,
    lat,
    lng,
    type,
    name,
    description,
    alerts,
    active,
    onClick,
    onUpdateGroup,
    onUpdateGeo,
    onError,
    hidden,
    onHideClick
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [tabValue, setTabValue] = React.useState<string>("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    interface UpdateLocationFormProps {
        item_id: string;
        button_label: string;
    }

    const UpdateLocationForm: React.FC<UpdateLocationFormProps> = (props) => {
        const [newLat, setNewLat] = React.useState<any>(undefined);
        const [newLng, setNewLng] = React.useState<any>(undefined);
        const [errors, setErrors] = React.useState<any>();
        const [hasChanged, setHasChanged] = React.useState<boolean>(false);

        const handleLatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const decimalRegex = /^-?\d*\.?\d*$/;
            if (decimalRegex.test(event.target.value)) {
                setNewLat(event.target.value);
                setHasChanged(true);
            }
        };

        const handleLngChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const decimalRegex = /^-?\d*\.?\d*$/;
            if (decimalRegex.test(event.target.value)) {
                setNewLng(event.target.value);
                setHasChanged(true);
            }
        };

        const handleRemoveFromMap = async (e: React.SyntheticEvent) => {
            e.preventDefault();
            if (type === "destination") {
                await agent.Destination.updateLatLng(props.item_id, null, null)
                    .then(() => {
                        setErrors(undefined);
                        onUpdateGeo && onUpdateGeo();
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrors(
                            error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                        );
                        onError && onError();
                    });
            } else {
                await agent.Channel.updateLatLng(props.item_id, null, null)
                    .then(() => {
                        setErrors(undefined);
                        onUpdateGeo && onUpdateGeo();
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrors(
                            error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                        );
                        onError && onError();
                    });
            }
        };

        const handleSubmit = async (e: React.SyntheticEvent) => {
            e.preventDefault();
            const target = e.target as typeof e.target & {
                latitude: { value: number | null };
                longitude: { value: number | null };
            };
            if (type === "destination") {
                await agent.Destination.updateLatLng(props.item_id, target.latitude.value, target.longitude.value)
                    .then((value) => {
                        setErrors(undefined);
                        onUpdateGeo && onUpdateGeo();
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrors(
                            error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                        );
                        onError && onError();
                    });
            } else {
                await agent.Channel.updateLatLng(props.item_id, target.latitude.value, target.longitude.value)
                    .then((value) => {
                        setErrors(undefined);
                        onUpdateGeo && onUpdateGeo();
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrors(
                            error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                        );
                        onError && onError();
                    });
            }
        };

        React.useEffect(() => {
            setNewLat(lat);
            setNewLng(lng);

            return () => {
                setNewLat(undefined);
                setNewLng(undefined);
                setHasChanged(false);
            };
        }, []);

        return (
            <form onSubmit={handleSubmit}>
                <div style={{ fontSize: "11px", marginBottom: 10 }}>Update positional data below to move location on the map.</div>
                <TextField
                    variant="standard"
                    id="latitude"
                    name="latitude"
                    label="Latitude"
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleLatChange(e);
                    }}
                    style={{ marginBottom: "10px" }}
                    value={newLat || ""}
                />
                <TextField
                    variant="standard"
                    id="longitude"
                    name="longitude"
                    label="Longitude"
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleLngChange(e);
                    }}
                    style={{ marginBottom: "10px" }}
                    value={newLng || ""}
                />
                <ErrorMessages errors={errors} />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                    {lat && (
                        <Button color="error" variant="contained" size="small" onClick={handleRemoveFromMap}>
                            Remove from map
                        </Button>
                    )}
                    <div></div>
                    <Button color="primary" variant="contained" type="submit" size="small" disabled={!hasChanged}>
                        {props.button_label}
                    </Button>
                </div>
            </form>
        );
    };

    interface MoveToGroupFormProps {
        item_id: string;
    }

    const MoveToGroupForm: React.FC<MoveToGroupFormProps> = (props) => {
        const [groups, setGroups] = React.useState<IDashboardGroups>();
        const [group, setGroup] = React.useState<string>(group_id || "");
        const [errors, setErrors] = React.useState<any>();
        const [hasChanged, setHasChanged] = React.useState<boolean>(false);

        React.useEffect(() => {
            const getGroups = async () => {
                const groups = await agent.Environment.getGroups();
                setGroups(groups);
            };
            getGroups();

            //return () => setGroups(undefined);
        }, []);

        React.useEffect(() => {}, [groups, group, hasChanged]);

        const handleGroupChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
            setGroup(event.target.value as string);
            setHasChanged(true);
        };

        const handleSubmit = async (e: React.SyntheticEvent) => {
            e.preventDefault();

            if (type === "destination") {
                await agent.Destination.updateGroup(id, group)
                    .then(() => {
                        setHasChanged(false);
                        onUpdateGroup && onUpdateGroup();
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrors(
                            error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                        );
                        onError && onError();
                    });
            } else {
                await agent.Channel.updateGroup(id, group)
                    .then(() => {
                        setHasChanged(false);
                        onUpdateGroup && onUpdateGroup();
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrors(
                            error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                        );
                        onError && onError();
                    });
            }
        };
        return (
            <form onSubmit={handleSubmit}>
                <Select
                    variant="standard"
                    labelId="group-select-label"
                    id="group-select"
                    style={{ width: "100%", zIndex: 30000, marginBottom: 10 }}
                    value={group || ""}
                    onChange={(event: any) => handleGroupChange(event)}
                    placeholder="Group">
                    <MenuItem key="no-group" value={""} selected={group === "" || !group}>
                        <div style={{ opacity: 0.8, fontStyle: "italic" }}>- Remove from Group</div>
                    </MenuItem>
                    {groups &&
                        groups.data
                            .filter((group: IDashboardGroup) => group.group_type === type)
                            .map((group: IDashboardGroup) => {
                                return (
                                    <MenuItem key={group.id} value={group.id} selected={group.id === group_id}>
                                        {group.name}
                                    </MenuItem>
                                );
                            })}
                </Select>
                <ErrorMessages errors={errors} />
                <Button color="primary" variant="contained" type="submit" disabled={!hasChanged} size="small">
                    Move
                </Button>
            </form>
        );
    };

    // React.useEffect(() => {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [active, expanded]);

    React.useEffect(() => {
        setActiveTab(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const SourceItemThumbnailContainerStyles = {
        flexShrink: 0,
        flex: "0 0 80px",
        marginRight: 10,
        width: 80,
        //height: 45,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        borderRadius: 2,
        backgroundColor: "rgba(160,160,160,0.1)"
    };

    return (
        <div
            className={
                alerts.filter((alert: any) => alert.is_alerting).length > 0
                    ? "data-panel-item alerting"
                    : active
                    ? "data-panel-item active"
                    : "data-panel-item"
            }
            key={id}>
            <div className={"data-panel-item-header"}>
                <div className="data-panel-item-header-body">
                    {type !== "destination" && (expanded || active) && (
                        <div style={SourceItemThumbnailContainerStyles} onClick={onClick}>
                            <SourceThumbnail item_id={id} />
                        </div>
                    )}

                    <div style={{ flex: "1 1 100%" }}>
                        <div style={{ fontSize: "12px" }} title={name} onClick={onClick}>
                            <b>{name}</b>
                        </div>
                        {(expanded || active) && (
                            <div style={{ fontSize: 11 }}>
                                {group_id ? (
                                    <div>
                                        <FontAwesomeIcon icon={["fal", "folder"]} />
                                        &nbsp;{group_name ? group_name : group_id}
                                    </div>
                                ) : lat ? (
                                    <div>
                                        Lat: {lat}, Lng: {lng}
                                    </div>
                                ) : (
                                    <div>
                                        <span onClick={() => setActiveTab(3)} style={{ color: "#307abb" }}>
                                            Add location
                                        </span>
                                        &nbsp;or&nbsp;
                                        <span onClick={() => setActiveTab(4)} style={{ color: "#307abb" }}>
                                            Move to a group
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", whiteSpace: "nowrap" }}>
                    <FontAwesomeIcon
                        title={hidden ? "Show on map" : "Hide on map"}
                        icon={["fal", hidden ? "eye-slash" : "eye"]}
                        onClick={() => onHideClick()}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={["fal", expanded ? "chevron-up" : "chevron-down"]} onClick={() => setExpanded(!expanded)} />
                </div>
            </div>

            {(expanded || active) && (
                <div className="data-panel-item-body">
                    <TabContext value={tabValue}>
                        <SmallTabList
                            onChange={handleTabChange}
                            aria-label="Overview - Source Item Tabs"
                        >
                            <SmallTab label="Statistics" value="1" />
                            <SmallTab label="Info" value="2" />
                            <SmallTab label="Alerts" value="3" />
                            <SmallTab label="Location" value="4" />
                            <SmallTab label="Group" value="5" />
                        </SmallTabList>

                        <TabPanel value="1">
                            <ItemStats item_id={id} type={type} />
                        </TabPanel>
                        <TabPanel value="2">
                            <div>
                                {description && <div style={{ marginBottom: 10, fontSize: "13px" }}>{description}</div>}
                                <ItemInfo item_id={id} type={type} />
                            </div>
                        </TabPanel>
                        <TabPanel value="3">
                            <div>
                                {alerts.map((alert: any, i: number) => (
                                    <div
                                        style={{
                                            backgroundColor: alert.is_alerting ? "crimson" : "rgba(160,160,160,0.05)",
                                            color: "#fff",
                                            padding: "10px",
                                            borderRadius: 4,
                                            marginTop: 4,
                                            display: alert.is_alerting ? "block" : "block"
                                        }}
                                        key={i}>
                                        {alert.is_alerting && (
                                            <FontAwesomeIcon
                                                style={{ color: "yellow", marginRight: 5 }}
                                                icon={["fal", "exclamation-triangle"]}
                                            />
                                        )}
                                        {alert.condition}
                                    </div>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value="4">
                            <UpdateLocationForm item_id={id} button_label="Update" />
                        </TabPanel>
                        <TabPanel value="5">
                            <MoveToGroupForm item_id={id} />
                        </TabPanel>
                    </TabContext>
                </div>
            )}
        </div>
    );
};

export default SourceItem;
