export const inputports = [
  { key: 2088, text: '2088', value: 2088 },
  { key: 2089, text: '2089', value: 2089 },
  { key: 2090, text: '2090', value: 2090 },
  { key: 2091, text: '2091', value: 2091 },
  { key: 2092, text: '2092', value: 2092 },
  { key: 2093, text: '2093', value: 2093 },
  { key: 2094, text: '2094', value: 2094 },
  { key: 2095, text: '2095', value: 2095 },
  { key: 2096, text: '2096', value: 2096 },
  { key: 2097, text: '2097', value: 2097 },
  { key: 2098, text: '2098', value: 2098 },
  { key: 2099, text: '2099', value: 2099 },
  { key: 2100, text: '2100', value: 2100 },
  { key: 2101, text: '2101', value: 2101 },
  { key: 2102, text: '2102', value: 2102 },
  { key: 2103, text: '2103', value: 2103 },
  { key: 2104, text: '2104', value: 2104 },
  { key: 2105, text: '2105', value: 2105 },
  { key: 2106, text: '2106', value: 2106 },
  { key: 2107, text: '2107', value: 2107 },
  { key: 2108, text: '2108', value: 2108 },
  { key: 2109, text: '2109', value: 2109 },
  { key: 2110, text: '2110', value: 2110 }
];

export const outputports = [
  { key: 2057, text: '2057', value: 2057 },
  { key: 2058, text: '2058', value: 2058 },
  { key: 2059, text: '2059', value: 2059 },
  { key: 2060, text: '2060', value: 2060 },
  { key: 2061, text: '2061', value: 2061 },
  { key: 2062, text: '2062', value: 2062 },
  { key: 2063, text: '2063', value: 2063 },
  { key: 2064, text: '2064', value: 2064 },
  { key: 2065, text: '2065', value: 2065 },
  { key: 2066, text: '2066', value: 2066 },
  { key: 2067, text: '2067', value: 2067 },
  { key: 2068, text: '2068', value: 2068 },
  { key: 2069, text: '2069', value: 2069 },
  { key: 2070, text: '2070', value: 2070 },
  { key: 2071, text: '2071', value: 2071 },
  { key: 2072, text: '2072', value: 2072 },
  { key: 2073, text: '2073', value: 2073 },
  { key: 2074, text: '2074', value: 2074 },
  { key: 2075, text: '2075', value: 2075 },
  { key: 2076, text: '2076', value: 2076 },
  { key: 2077, text: '2077', value: 2077 },
  { key: 2088, text: '2088', value: 2088 },
];