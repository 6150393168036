import React, { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { IUserPasswordChange, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import IconButton from "@mui/material/IconButton";
import { ConfirmPasswordChange } from "./ConfirmPasswordChange";
import { Alert, Box, Button, DialogActions, DialogContent, Stack, Typography, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ValidationIcon from "components/Forms/Validation/ValidationIcon";
import PasswordStrengthBar from "react-password-strength-bar";
import VisibilityIcon from "components/Forms/VisibilityIcon";
import usePasswordReset from "hooks/UsePasswordReset";
import { TextField } from "components/Forms";

const PasswordChangeForm = observer(function PasswordChangeForm() {
    const rootStore = useContext(RootStoreContext);
    const { changeDialogBody, closeDialog } = rootStore.dialogStore;
    const {
        passwordValidationRegex,
        showPassword,
        togglePassword,
        changeUserPassword,
        validate
    } = usePasswordReset('new_password', 'confirmpassword', 'old_password');
    const theme = useTheme();

    return (
        <FinalForm
            onSubmit={(values: IUserPasswordChange) =>
                changeUserPassword(values)
                    .then(() => {
                        changeDialogBody(<ConfirmPasswordChange />);
                    })
                    .catch((error) => ({
                        [FORM_ERROR]: error
                    }))
            }
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent sx={{ padding: 4, pt: 2 }}>
                        <Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Box sx={{flex: 1}}>
                                    <TextField
                                        name="old_password"
                                        label="Current Password"
                                        helperText={() => {
                                            return "Enter your current password";
                                        }}
                                        type={showPassword.old ? "text" : "password"}
                                    />
                                </Box>
                                
                                <IconButton
                                    onClick={() => {
                                        togglePassword("old");
                                    }}
                                >
                                    <VisibilityIcon isVisible={!showPassword.old} />
                                </IconButton>
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center">
                                <Box sx={{flex: 1}}>
                                    <TextField
                                        name="new_password"
                                        label="New Password"
                                        helperText={() => {
                                            return "Enter your new password";
                                        }}
                                        type={showPassword.password ? "text" : "password"}
                                    />
                                </Box>

                                
                                <IconButton
                                    onClick={() => {
                                        togglePassword("password");
                                    }}
                                >
                                    <VisibilityIcon isVisible={!showPassword.password} />
                                </IconButton>
                            </Stack>

                            <PasswordStrengthBar
                                password={values.new_password}
                                scoreWords={['Very Weak', 'Weak', 'Okay', 'Good', 'Strong']}
                                minLength={8}
                                shortScoreWord="Min 8 characters"
                                barColors={[theme.palette.grey[400], theme.palette.error.main, theme.palette.warning.main, theme.palette.success.light, theme.palette.success.main]}
                            />

                            <Stack direction="row" spacing={1} alignItems="center">
                                <Box sx={{flex: 1}}>
                                    <TextField
                                        name="confirmpassword"
                                        label="Confirm New Password"
                                        helperText={() => {
                                            return "This must match your new password";
                                        }}
                                        type={showPassword.confirm ? "text" : "password"}
                                    />
                                </Box>
                                
                                <IconButton
                                    onClick={() => {
                                        togglePassword("confirm");
                                    }}
                                >
                                    <VisibilityIcon isVisible={!showPassword.confirm} />
                                </IconButton>
                            </Stack>

                            <Alert severity="info">
                                <Box>
                                    <Typography variant="body1">
                                        Create a password that contains at least:
                                    </Typography>
                                    <Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <ValidationIcon isValid={passwordValidationRegex.length.regex.test(values.new_password) && passwordValidationRegex.uppercase.regex.test(values.new_password)} />
                                            <Typography variant="body2">8 Characters (min 1 uppercase)</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <ValidationIcon isValid={passwordValidationRegex.number.regex.test(values.new_password)} />
                                            <Typography variant="body2">1 Number</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <ValidationIcon isValid={passwordValidationRegex.special.regex.test(values.new_password)} />
                                            <Typography variant="body2">1 Special Character (e.g. !, @, £, $, %)</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Alert>
                        </Stack>

                        {submitError && !dirtySinceLastSubmit && <Alert severity="error">{submitError.data.message}</Alert>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <LoadingButton
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            loading={submitting}
                            id="changePasswords"
                            onClick={handleSubmit}
                            type="submit"
                            variant="contained"
                            color="secondary">
                            Change Password
                        </LoadingButton>
                    </DialogActions>
                </form>
            )}
        />
    );
});

export default PasswordChangeForm;
