import React from "react";
import { TabPanel as MUITabPanel } from "@mui/lab";

interface ITabPanelProps {
    value: string;
    sx?: object;
    children: React.ReactNode;
    hasBackground?: boolean;
    [key: string]: any;
}

/**
 * `TabPanel` is a wrapper component for Material-UI's `TabPanel` component.
 * It provides a container for tab content. Each child of `TabPanel` will be considered a separate tab.
 *
 * @component
 * @example
 * ```tsx
 * <TabPanel value="tab1">
 *   <div>Tab 1 Content</div>
 * </TabPanel>
 * ```
 * @param {ITabPanelProps} props - The properties that define the `TabPanel` component.
 * @param {string} props.value - The value prop holds the identifier for the tab content. It should be unique amongst its siblings.
 * @param {object} props.sx - The style prop allows for styling directly within the component. Styles are written as a JavaScript object.
 * @param {React.ReactNode} props.children - The children prop holds the content of the tab.
 * @param {boolean} props.hasBackground - The hasBackground prop determines whether the tab content should have a background color. Default is `true`.
 * @param {any} props[key: string] - Any other props will be passed directly to the underlying Material-UI `TabPanel` component.
 */
export const TabPanel: React.FC<ITabPanelProps> = ({ value, sx, children, hasBackground = true, ...props }) => (
    <MUITabPanel value={value} {...props} sx={{ flexGrow: 1, overflowY: "scroll", backgroundColor: hasBackground ? '#0006' : 'transparent', ...sx }}>
        {children}
    </MUITabPanel>
)

export default TabPanel;
