import { useState, useEffect, useMemo } from "react";
import Fuse from "fuse.js";

// Type definition for the search configuration
interface SearchField {
    field: string;
    weight: number;
}

const useSearch = (searchTerm: string, data: any[], searchFields: SearchField[], resultLimit: number, threshold?: number) => {
    const [results, setResults] = useState<any[]>([]);

    const fuse = useMemo(() => {
        const options = {
            keys: searchFields.map((field) => ({
                name: field.field,
                weight: field.weight
            })),
            threshold: threshold || 0.4, // Adjust the threshold for fuzzy matching
            includeMatches: true, // Include matches in the result
            minMatchCharLength: 2, // Minimum character length for a match
            findAllMatches: true, // Find all matches in the search term
            isCaseSensitive: false, // Don't care about case sensitivity
            shouldSort: true, // Sort the results by score
            location: 0, // Location of the match
            distance: 100, // Distance from the location
            //ignoreLocation: true, // Ignore the location of the match
            //useExtendedSearch: true, // Use the extended search
        };
        return new Fuse(data, options);
    }, [data, searchFields, threshold]);

    useEffect(() => {
        if (searchTerm.trim() === "") {
            setResults([]);
            return;
        }

        const searchResults = fuse
            .search(searchTerm) 
            .slice(0, resultLimit)
            .map((result) => {
                return {
                    item: result.item,
                    matches: result.matches
                };
            });
        setResults(searchResults);
    }, [searchTerm, fuse, resultLimit]);

    return results;
};

export default useSearch;


