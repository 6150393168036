import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import Button from "@mui/material/Button";
import arrayMutators from "final-form-arrays";
import {
    Badge,
    Box,
    Chip,
    FormLabel,
    Grid,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ReactFlow, {
    Background,
    Controls,
    Edge,
    MarkerType,
    Node,
    ReactFlowProvider,
    ViewportHelperFunctions,
    addEdge,
    updateEdge,
    useEdgesState,
    useNodesState
} from "reactflow";
import "reactflow/dist/style.css";
import { useParams } from "react-router-dom";
import CustomNode from "features/developer/CustomNode";
import SourcesNode from "features/developer/SourcesNode";
import { DetailsNode, GroupNode, ListNode, NodeHeader } from "components/Nodes";
import { agent, RootStoreContext } from "api";
import PackagerSourceNode from "components/Nodes/PackagerSourceNode";
import { Form, FormSpy } from "react-final-form";
import GlobalTrackConfigurationForm from "./Components/GlobalTrackConfigurationForm";
import SidebarTabs, { SidebarTab, SidebarTabPanel } from "components/SidebarTabs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import { useConstructNodes } from "./useConstructNodes";
import { format } from "date-fns";
import AudioTrackForm from "./Components/AudioTrackForm";
import HlsFmp4OutputOptions from "./Components/HlsFmp4OutputOptions";
import HlsOutputOptions from "./Components/HlsOutputOptions";
import AudioTrackVariant from "./Components/AudioTrackVariant";
import VideoTrackForm from "./Components/VideoTrackForm";
import { OutputGroupForm } from "./Components/OutputGroupForm";
import DashOutputOptions from "./Components/DashOutputOptions";

const nodeTypes = {
    details: DetailsNode,
    custom: CustomNode,
    sources: SourcesNode,
    customgroup: GroupNode,
    source: PackagerSourceNode,
    list: ListNode
};

const PackagerDetails = observer(function PackagerDetails() {
    const { id } = useParams();

    const constructRid = () => {
        return `pushpackagers.${rootStore.userStore.managingOrganisationId!}.packager.${id}`;
    };

    const mode = () => {
        if (id === "create") {
            return "create";
        }
        return "edit";
    };

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const [apiData, setApiData] = React.useState<any>(null);
    const [liveData, setLiveData] = React.useState<any>(null);
    const [thumbnailData, setThumbnailData] = React.useState<any>(null);
    const [wsData, setWsData] = React.useState<any>(null);

    const [formValues, setFormValues] = React.useState<any>(null);
    const theme = useTheme();

    const getThumbnail = async (id: string) => {
        await agent
            .getCleanInstance()
            .get(`source_transcoder/${id}/thumbnail`)
            .then((data: any) => {
                //console.log(data.data);
                setLiveData(data.data);
                setThumbnailData(data.data.thumbnail);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [footerHeight, setFooterHeight] = React.useState<number>(300);
    const [collapseFooter, setCollapseFooter] = React.useState<boolean>(false);
    const [collapseSidebar, setCollapseSidebar] = React.useState<boolean>(false);

    const [activeSidebarTab, setActiveSidebarTab] = React.useState("1");
    const [activeSidebarPanel, setActiveSidebarPanel] = React.useState("input");

    const [activePanel, setActivePanel] = React.useState("output_colorimetry_parameters");
    const [activePanelTitle, setActivePanelTitle] = React.useState("Output Colorimetry Parameters");
    const [activeTab, setActiveTab] = React.useState("1");

    const [activeFormPath, setActiveFormPath] = React.useState("packagers.0.source.input");
    const [activeAudioTrackVariantIndex, setActiveAudioTrackVariantIndex] = React.useState<number>(0);

    const [droppableElementType, setDroppableElementType] = React.useState<any>(null);
    const [droppableElementId, setDroppableElementId] = React.useState<any>(null);

    const [droppableType, setDroppableType] = React.useState<string>("");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    const rootStore = useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;

    const { currentTheme, setCurrentTheme } = rootStore.commonStore;

    // Always work in multiples of 15 as that is the default grid size
    const gridSnap = 15;

    const initialNodes: Node[] = [];

    const initialEdges: Edge[] = [];

    const [insideDropArea, setInsideDropArea] = React.useState<boolean>(false);

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState<ViewportHelperFunctions>();

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)), []);

    const onMenuItemClick = () => {
        setNodes(getNodes());
        setEdges(getEdges());
        setValuesState(valuesState);
    };
    const [valuesState, setValuesState] = React.useState<any>(apiData?.transcode_parameters);
    const { getNodes, getEdges } = useConstructNodes({
        values: valuesState,
        setActiveSidebarPanel,
        setActiveSidebarTab,
        setCollapseSidebar,
        setActiveFormPath,
        setActiveAudioTrackVariantIndex,
        droppableType,
        setDroppableElementType,
        setDroppableElementId,
        onMenuItemClick
    });

    const reactFlowWrapper = useRef(null);

    const onDragOver = useCallback((event) => {
        //console.log(event);
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
        setInsideDropArea(true);
    }, []);

    const onDrop = useCallback((event) => {}, [reactFlowInstance]);

    useEffect(() => {
        id &&
            agent
                .getCleanInstance()
                .get(`push_packagers/${id}`)
                .then((data) => {
                    console.log(data.data.data);
                    setApiData(data.data.data);
                    setFormValues(data.data.data.transcode_parameters);
                    setValuesState(data.data.data.transcode_parameters);
                })

                .catch((err) => {
                    console.log(err);
                });
    }, [id]);

    React.useEffect(() => {
        let intervalId: NodeJS.Timeout;
        id &&
            mode() === "edit" &&
            clientConnected &&
            client.get(constructRid()).then((res: any) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });

                !thumbnailData && res.status === "running" && getThumbnail(res.id);
                intervalId = setInterval(() => {
                    if (res.status === "running") {
                        getThumbnail(res.id);
                    } else {
                        clearInterval(intervalId);
                    }
                }, 2000);

                setWsData(res);
                console.log(res);
            });
        return () => {
            intervalId && clearInterval(intervalId);
            setWsData(null);
        };
    }, [clientConnected, id, rootStore.userStore.managingOrganisationId]);

    React.useEffect(() => {}, [updateDate]);

    React.useEffect(() => {
        setNodes(getNodes());
        getEdges();
    }, [valuesState]);

    return (
        <View>
            <ViewHeader title={apiData ? apiData.name : "No ID"} subtitle="Push Packagers" preview>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div></div>
                    <div></div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <Form
                    onSubmit={(values) => {
                        setValuesState(values);
                        setNodes(getNodes());
                        setEdges(getEdges());
                    }}
                    initialValues={valuesState}
                    mutators={{
                        ...arrayMutators
                    }}
                    render={({ form, submitError, handleSubmit, submitting, values, invalid, dirty }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate style={{ height: "100%", width: "100%" }}>
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={(state) => {
                                        //setValuesState(state.values);
                                        //setNodes(getNodes());
                                    }}></FormSpy>
                                <ReactFlowProvider>
                                    <Content
                                        noPadding
                                        rightWidth={collapseSidebar ? 51 : 335}
                                        rightContent={
                                            <SidebarTabs
                                                tabs={
                                                    <>
                                                        <SidebarTab
                                                            label="Stream Adaptation Family Templates"
                                                            icon={<GroupsOutlinedIcon />}
                                                            active={activeSidebarTab === "1"}
                                                            onClick={() => {
                                                                setActiveSidebarTab("1");
                                                                setCollapseSidebar(false);
                                                            }}
                                                        />
                                                        <SidebarTab
                                                            label="Add New"
                                                            icon={<AddBoxOutlinedIcon />}
                                                            active={activeSidebarTab === "2"}
                                                            onClick={() => {
                                                                setActiveSidebarTab("2");
                                                                setCollapseSidebar(false);
                                                            }}
                                                        />
                                                        <SidebarTab
                                                            label="Video Tracks"
                                                            icon={<VideocamOutlinedIcon />}
                                                            active={activeSidebarTab === "3"}
                                                            onClick={() => {
                                                                setActiveSidebarTab("3");
                                                                setCollapseSidebar(false);
                                                            }}
                                                        />
                                                        <SidebarTab
                                                            label="Edit"
                                                            icon={<EditNoteOutlinedIcon />}
                                                            active={activeSidebarTab === "4"}
                                                            onClick={() => {
                                                                setActiveSidebarTab("4");
                                                                setCollapseSidebar(false);
                                                            }}
                                                        />
                                                    </>
                                                }
                                                panels={
                                                    <>
                                                        <SidebarTabPanel active={activeSidebarTab === "1"}>
                                                            <Box
                                                                sx={{
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "stretch"
                                                                }}>
                                                                <NodeHeader label="Stream Adaptation Family" />
                                                                <Box sx={{ mb: 2, p: 2 }}></Box>
                                                                <NodeHeader label="Stream Adaptation Family" />
                                                            </Box>
                                                        </SidebarTabPanel>
                                                        <SidebarTabPanel active={activeSidebarTab === "2"}>
                                                            Hello I'm the second panel
                                                        </SidebarTabPanel>
                                                        <SidebarTabPanel active={activeSidebarTab === "3"}>
                                                            Hello I'm the third panel
                                                        </SidebarTabPanel>
                                                        <SidebarTabPanel active={activeSidebarTab === "4"}>
                                                            <Box
                                                                sx={{
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "stretch"
                                                                }}>
                                                                {activeSidebarPanel === "global_track_configuration" && (
                                                                    <>
                                                                        <NodeHeader label="Global Track Configuration" />
                                                                        <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
                                                                            <GlobalTrackConfigurationForm
                                                                                values={values}
                                                                                submitError={submitError}
                                                                                onFieldChange={form.change}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                {activeSidebarPanel === "video_track" && (
                                                                    <>
                                                                        <NodeHeader label={"Video Track " + (Number(activeFormPath) + 1)} />
                                                                        <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
                                                                            <VideoTrackForm
                                                                                form={form}
                                                                                videoTrackIndex={Number(activeFormPath)}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                {
                                                                    //switch / case to determine which panel to show using  switch (activeSidebarPanel)

                                                                    activeSidebarPanel === "audio_track" && (
                                                                        <>
                                                                            <NodeHeader
                                                                                label={"Audio Track " + (Number(activeFormPath) + 1)}
                                                                            />
                                                                            <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
                                                                                <AudioTrackForm
                                                                                    values={values}
                                                                                    submitError={submitError}
                                                                                    onFieldChange={form.change}
                                                                                    audioTrackIndex={Number(activeFormPath)}
                                                                                    form={form}
                                                                                />
                                                                            </Box>
                                                                        </>
                                                                    )
                                                                }
                                                                {activeSidebarPanel === "audio_track_variant" && (
                                                                    <>
                                                                        <NodeHeader
                                                                            label={
                                                                                "Audio Track " +
                                                                                (Number(activeFormPath) + 1) +
                                                                                ": Variant " +
                                                                                Number(activeAudioTrackVariantIndex + 1)
                                                                            }
                                                                        />
                                                                        <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
                                                                            <AudioTrackVariant
                                                                                onFieldChange={form.change}
                                                                                audioTrackIndex={Number(activeFormPath)}
                                                                                index={0}
                                                                                name={`individual_track_configuration.audio_tracks[${Number(
                                                                                    activeFormPath
                                                                                )}].variants[${activeAudioTrackVariantIndex}]`}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                {activeSidebarPanel === "output_group_hls-fmp4" && (
                                                                    <>
                                                                        <NodeHeader
                                                                            label={`Output Group ${Number(activeFormPath) + 1} - HLS-FMP4`}
                                                                        />
                                                                        <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
                                                                            <OutputGroupForm
                                                                                form={form}
                                                                                outputGroupIndex={Number(activeFormPath)}
                                                                                values={values}
                                                                            />
                                                                            <HlsFmp4OutputOptions
                                                                                values={values}
                                                                                onFieldChange={form.change}
                                                                                outputGroupIndex={Number(activeFormPath)}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                {activeSidebarPanel === "output_group_hls" && (
                                                                    <>
                                                                        <NodeHeader
                                                                            label={`Output Group ${Number(activeFormPath) + 1} - HLS`}
                                                                        />
                                                                        <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
                                                                            <OutputGroupForm
                                                                                form={form}
                                                                                outputGroupIndex={Number(activeFormPath)}
                                                                                values={values}
                                                                            />
                                                                            <HlsOutputOptions
                                                                                values={values}
                                                                                onFieldChange={form.change}
                                                                                outputGroupIndex={Number(activeFormPath)}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                {activeSidebarPanel === "output_group_dash" && (
                                                                    <>
                                                                        <NodeHeader
                                                                            label={`Output Group ${Number(activeFormPath) + 1} - DASH`}
                                                                        />
                                                                        <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
                                                                            <OutputGroupForm
                                                                                form={form}
                                                                                outputGroupIndex={Number(activeFormPath)}
                                                                                values={values}
                                                                            />
                                                                            <DashOutputOptions
                                                                                values={values}
                                                                                onFieldChange={form.change}
                                                                                outputGroupIndex={Number(activeFormPath)}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}
                                                                <Box sx={{ p: 2, flexGrow: 0 }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        type="submit"
                                                                        fullWidth
                                                                        disabled={submitting || invalid || !dirty}>
                                                                        Save Changes
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </SidebarTabPanel>
                                                    </>
                                                }
                                                collapsible={true}
                                                collapsed={collapseSidebar}
                                                onCollapse={() => setCollapseSidebar(!collapseSidebar)}
                                            />
                                        }
                                        footerContent={
                                            <Box
                                                sx={{
                                                    height: collapseFooter ? 46 : footerHeight,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    position: "relative",
                                                    overflow: "hidden"
                                                }}>
                                                <TabContext value={activeTab}>
                                                    <TabList onChange={handleTabChange}>
                                                        <Tab label="Details" value="1" />
                                                        <Tab
                                                            label={
                                                                <>
                                                                    Alarms
                                                                    <Badge
                                                                        badgeContent={liveData?.alarms?.length}
                                                                        color="error"
                                                                        sx={{ ml: 2 }}
                                                                    />
                                                                </>
                                                            }
                                                            value="2"
                                                        />
                                                        <Tab label="Edges" value="3" />
                                                        <Tab label="Settings" value="4" />
                                                    </TabList>
                                                    <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Name</FormLabel>
                                                                    <Typography variant="body1">{wsData?.name}</Typography>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Status</FormLabel>
                                                                    <Box>
                                                                        <Chip
                                                                            sx={{ textTransform: "capitalize" }}
                                                                            label={wsData?.status}
                                                                            size="small"
                                                                            color="success"
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>DNS Name</FormLabel>
                                                                    <Typography variant="body1">{wsData?.dns_name}</Typography>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Public IP</FormLabel>
                                                                    <Typography variant="body1">{wsData?.public_ip}</Typography>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Instance Size</FormLabel>
                                                                    <Typography variant="body1">
                                                                        {wsData?.commercial_instance_size}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Name</FormLabel>
                                                                    <Typography variant="body1">{wsData?.name}</Typography>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Name</FormLabel>
                                                                    <Typography variant="body1">{wsData?.name}</Typography>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Status</FormLabel>
                                                                    <Box>
                                                                        <Chip label={wsData?.status} size="small" color="success" />
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>DNS Name</FormLabel>
                                                                    <Typography variant="body1">{wsData?.dns_name}</Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {liveData?.alarms?.length > 0 && (
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Start Date</TableCell>
                                                                        <TableCell>End Date</TableCell>
                                                                        <TableCell>Level</TableCell>
                                                                        <TableCell>Section</TableCell>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>Description</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {liveData?.alarms?.map((alarm: any, index: any) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>
                                                                                {format(new Date(alarm.start_time), "dd/MM/yyyy hh:mm")}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {alarm.end_time
                                                                                    ? format(new Date(alarm.end_time), "dd/MM/yyyy hh:mm")
                                                                                    : "-"}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {alarm.level === "critical" ? (
                                                                                    <Chip label="Critical" color="error" size="small" />
                                                                                ) : alarm.level === "warning" ? (
                                                                                    <Chip label="Warning" color="warning" size="small" />
                                                                                ) : (
                                                                                    <Chip
                                                                                        sx={{ textTransform: "capitalize" }}
                                                                                        label={alarm.level}
                                                                                        size="small"
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>{alarm.section}</TableCell>
                                                                            <TableCell>{alarm.name}</TableCell>
                                                                            <TableCell>{alarm.description}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        )}
                                                    </TabPanel>
                                                    <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {edges && <pre>{JSON.stringify(edges, null, 2)}</pre>}
                                                    </TabPanel>
                                                    <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {values && <pre>{JSON.stringify(values, null, 2)}</pre>}
                                                    </TabPanel>
                                                </TabContext>
                                                <IconButton
                                                    onClick={() => setCollapseFooter(!collapseFooter)}
                                                    sx={{ position: "absolute", right: 10, top: 5 }}>
                                                    <ExpandMoreIcon
                                                        sx={{ transform: collapseFooter ? "rotate(180deg)" : "rotate(0deg)" }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        }>
                                        <Box
                                            id="flow-wrapper"
                                            ref={reactFlowWrapper}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "stretch",
                                                border: insideDropArea
                                                    ? "2px dashed rgba(160,160,160,0.2)"
                                                    : "2px dashed rgba(160,160,160,0)"
                                            }}>
                                            {/* <Box sx={{ width: 300, backgroundColor: "rgba(0,0,0,0.1)" }}>Drag sidebar</Box> */}

                                            <ReactFlow
                                                nodes={nodes}
                                                //nodes={getNodes()}
                                                nodeTypes={nodeTypes}
                                                edges={getEdges() || edges}
                                                //edges={getEdges()}
                                                //ref={dropArea}
                                                defaultEdgeOptions={{
                                                    type: "step",
                                                    style: { stroke: theme.palette.primary.main, strokeWidth: 2, strokeDasharray: 3 },
                                                    updatable: true,
                                                    markerEnd: {
                                                        type: MarkerType.ArrowClosed,
                                                        width: 10,
                                                        height: 10,
                                                        color: theme.palette.secondary.main
                                                    },
                                                    animated: true
                                                }}
                                                //fitView={true}
                                                snapToGrid={true}
                                                onInit={(instance) => {
                                                    //constructNodes(values, mode() === "create" ? true : false);
                                                    setNodes(getNodes());
                                                    setEdges(getEdges());
                                                    setTimeout(() => {
                                                        setEdges(getEdges());
                                                    }, 1000);
                                                    setReactFlowInstance(instance);
                                                }}
                                                onNodesChange={onNodesChange}
                                                onEdgesChange={onEdgesChange}
                                                onEdgeUpdate={onEdgeUpdate}
                                                onConnect={onConnect}
                                                onDrop={onDrop}
                                                onDragOver={onDragOver}
                                                onDragLeave={() => {
                                                    setInsideDropArea(false);
                                                }}>
                                                <Controls position="top-right" />
                                                <Background gap={gridSnap} size={1} />
                                            </ReactFlow>
                                        </Box>
                                    </Content>
                                </ReactFlowProvider>
                            </form>
                        );
                    }}
                />
            </ViewBody>
        </View>
    );
});

export default PackagerDetails;
