import { Delete } from "@mui/icons-material";
import { Chip, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { ISavedIPAddresses, RootStoreContext, agent } from "api";
import { ConfirmDeleteIPAddress } from "features/settings/environment/IPAddress/ConfirmDeleteIPAddress";
import React, { useContext } from "react";

const IpAddressTable: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;

    const [ipAddresses, setIpAddresses] = React.useState<ISavedIPAddresses>();

    const [sortBy, setSortBy] = React.useState("name:asc");

    const getIpAddresses = async () => {
        await agent.Environment.getIpAddresses()
            .then((response) => {
                setIpAddresses(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getIpAddresses();
    }, []);

    return (
        <Table>
            <TableHead>
                <TableCell sortDirection={sortBy === "name:asc" ? "asc" : sortBy === "name:desc" ? "desc" : false}>
                    <TableSortLabel
                        active={sortBy === "name:asc" || sortBy === "name:desc"}
                        direction={sortBy === "name:asc" ? "asc" : sortBy === "name:desc" ? "desc" : "asc"}
                        onClick={() => {
                            setSortBy(sortBy === "name:asc" ? "name:desc" : "name:asc");
                        }}>
                        Name
                    </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "status:asc" ? "asc" : sortBy === "status:desc" ? "desc" : false}>
                    <TableSortLabel
                        active={sortBy === "status:asc" || sortBy === "status:desc"}
                        direction={sortBy === "status:asc" ? "asc" : sortBy === "status:desc" ? "desc" : "asc"}
                        onClick={() => {
                            setSortBy(sortBy === "status:asc" ? "status:desc" : "status:asc");
                        }}>
                        Status
                    </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "address:asc" ? "asc" : sortBy === "address:desc" ? "desc" : false}>
                    <TableSortLabel
                        active={sortBy === "address:asc" || sortBy === "address:desc"}
                        direction={sortBy === "address:asc" ? "asc" : sortBy === "address:desc" ? "desc" : "asc"}
                        onClick={() => {
                            setSortBy(sortBy === "address:asc" ? "address:desc" : "address:asc");
                        }}>
                        IP Address
                    </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "cloud_provider:asc" ? "asc" : sortBy === "cloud_provider:desc" ? "desc" : false}>
                    <TableSortLabel
                        active={sortBy === "cloud_provider:asc" || sortBy === "cloud_provider:desc"}
                        direction={sortBy === "cloud_provider:asc" ? "asc" : sortBy === "cloud_provider:desc" ? "desc" : "asc"}
                        onClick={() => {
                            setSortBy(sortBy === "cloud_provider:asc" ? "cloud_provider:desc" : "cloud_provider:asc");
                        }}>
                        Provider
                    </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "region:asc" ? "asc" : sortBy === "region:desc" ? "desc" : false} width={150}>
                    <TableSortLabel
                        active={sortBy === "region:asc" || sortBy === "region:desc"}
                        direction={sortBy === "region:asc" ? "asc" : sortBy === "region:desc" ? "desc" : "asc"}
                        onClick={() => {
                            setSortBy(sortBy === "region:asc" ? "region:desc" : "region:asc");
                        }}>
                        Region
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    sortDirection={
                        sortBy === "environment_human_name:asc" ? "asc" : sortBy === "environment_human_name:desc" ? "desc" : false
                    }>
                    <TableSortLabel
                        active={sortBy === "environment_human_name:asc" || sortBy === "environment_human_name:desc"}
                        direction={
                            sortBy === "environment_human_name:asc" ? "asc" : sortBy === "environment_human_name:desc" ? "desc" : "asc"
                        }
                        onClick={() => {
                            setSortBy(
                                sortBy === "environment_human_name:asc" ? "environment_human_name:desc" : "environment_human_name:asc"
                            );
                        }}>
                        Environment
                    </TableSortLabel>
                </TableCell>
                <TableCell width={39}></TableCell>
            </TableHead>
            <TableBody>
                {ipAddresses &&
                    ipAddresses.ip_addresses
                        ?.sort((a: any, b: any) => {
                            const [field, order] = sortBy.split(":");
                            if (a[field] === null && b[field] !== null) {
                                return order === "asc" ? -1 : 1;
                            } else if (b[field] === null && a[field] !== null) {
                                return order === "asc" ? 1 : -1;
                            } else if (a[field] === null && b[field] === null) {
                                return 0;
                            } else {
                                if (order === "asc") {
                                    return a[field].localeCompare(b[field]);
                                } else {
                                    return b[field].localeCompare(a[field]);
                                }
                            }
                        })
                        .map((ip) => (
                            <TableRow key={ip.id}>
                                <TableCell>{ip.name}</TableCell>
                                <TableCell>
                                    <Chip
                                        size="small"
                                        sx={{ textTransform: "capitalize" }}
                                        label={ip.status}
                                        color={ip.status === "attached" ? "success" : undefined}
                                    />
                                </TableCell>
                                <TableCell>{ip.address}</TableCell>
                                <TableCell>{ip.cloud_provider}</TableCell>
                                <TableCell>{ip.region}</TableCell>
                                <TableCell>{ip.environment_human_name}</TableCell>
                                <TableCell>
                                    <Tooltip title="Release IP address">
                                        <span>
                                            <IconButton
                                                disabled={ip.status !== "detached"}
                                                aria-label="Release IP address"
                                                onClick={() => {
                                                    openDialog(<ConfirmDeleteIPAddress name={ip.name} id={ip.id} />);
                                                }}>
                                                <Delete />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
            </TableBody>
        </Table>
    );
};

export default IpAddressTable;
