export const initialValues = {
    packagers: [
        {
            source: {
                environment: {
                    type: "Titan",
                    cloud_provider: "aws",
                    region: "us-east-1"
                },
                input: {
                    name: "Input 1",
                    type: "SRT Listener",
                    port: 1234
                }
            },
            streamAdaptationFamily: {
                streamAdaptations: [
                    {
                        name: "Adaptation 1",
                        type: "Apple HLS",
                        output: {
                            url: "s3://bucket/path/to/file",
                        }
                    },
                    {
                        name: "Adaptation 1",
                        type: "Apple HLS",
                        output: {
                            url: "s3://bucket/path/to/file",
                        }
                    },
                    {
                        name: "Adaptation 1",
                        type: "Apple HLS",
                        output: {
                            url: "s3://bucket/path/to/file",
                        }
                    },
                    {
                        name: "Adaptation 1",
                        type: "Apple HLS",
                        output: {
                            url: "s3://bucket/path/to/file",
                        }
                    }

                ]
            }
        },
    ]
};