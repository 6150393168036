import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
    Alert,
    Box,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tab,
    Typography,
    useTheme
} from "@mui/material";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import { DetailsNode, GroupNode, NodeHeader } from "components/Nodes";
import { observer } from "mobx-react-lite";
import React, { useCallback, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
    addEdge,
    useEdgesState,
    useNodesState,
    Node,
    Edge,
    Controls,
    Background,
    MarkerType,
    ReactFlowProvider,
    ViewportHelperFunctions,
    updateEdge
} from "reactflow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SidebarTabs, { SidebarTab, SidebarTabPanel } from "components/SidebarTabs";
import { initialValues } from "./demoInitialValues";
import { useConstructNodes } from "./useConstructNodes";
import Sources from "./Components/Sources";
import { agent, NeaLive, StreamAdaptationFamily, RootStoreContext } from "api";
import { Add, AddCircle, Delete, DragHandle, Dvr, Edit, MoreVert } from "@mui/icons-material";
import StreamAdaptationForm from "./Components/StreamAdaptationForm";
import StreamAdaptationFamilyForm from "./Components/StreamAdaptationFamilyForm";
import { Resizable } from "react-resizable";
import Alarms from "./Components/Alarms";
import Stats from "./Components/Stats";
import TranscoderDetails from "./Components/TranscoderDetails";
import VideoPlayers from "./Components/VideoPlayers";

const nodeTypes = {
    details: DetailsNode,
    customgroup: GroupNode
};

const PullPackagerDetails = observer(function PackagerDetails() {
    
    //get the tab value from the url. e.g. ?tab=2 not using useParams as it is not a route parameter
    const tab = new URLSearchParams(window.location.search).get("tab")?.toString();

    
    
    const navigate = useNavigate();
    const { id } = useParams();
    const pull_packager_uuid = id;
    const rootStore = React.useContext(RootStoreContext);

    const { openDialog, closeDialog, changeDialogBody } = rootStore.dialogStore;
    const { openDrawer } = rootStore.drawerStore;
    const theme = useTheme();
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [data, setData] = React.useState<NeaLive>(); //data from the api
    const [wsData, setWsData] = React.useState<any>(null); //data from the websocket
    const [activeTab, setActiveTab] = React.useState("1");
    const [activeSidebarTab, setActiveSidebarTab] = React.useState(tab ? tab : "1");
    const [activeSidebarPanel, setActiveSidebarPanel] = React.useState("input");
    const [activeFormPath, setActiveFormPath] = React.useState("packagers.0.source.input");
    const [activeFamily, setActiveFamily] = React.useState<number>(0);
    const [activeFamilyId, setActiveFamilyId] = React.useState<number>(0);
    const [footerHeight, setFooterHeight] = React.useState<number>(300);
    const [initialHeight, setInitialHeight] = React.useState<number>(300);
    const [initialY, setInitialY] = React.useState(null);
    const [collapseFooter, setCollapseFooter] = React.useState<boolean>(false);
    const [collapseSidebar, setCollapseSidebar] = React.useState<boolean>(false);
    const [draggedStreamAdaptation, setDraggedStreamAdaptation] = React.useState<any>(null);
    const [draggedSource, setDraggedSource] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [outputs, setOutputs] = React.useState<any>([]);

    const [value, setValue] = React.useState("1");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const [droppableElementType, setDroppableElementType] = React.useState<any>(null);
    const [droppableElementId, setDroppableElementId] = React.useState<any>(null);

    const [droppableType, setDroppableType] = React.useState<string>("");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
        setCollapseFooter(false);
    };

    // Always work in multiples of 15 as that is the default grid size
    const gridSnap = 15;

    const dropArea = React.useRef<any>(null);

    const [insideDropArea, setInsideDropArea] = React.useState<boolean>(false);

    const initialNodes: Node[] = [];

    const initialEdges: Edge[] = [];

    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [reactFlowInstance, setReactFlowInstance] = useState<ViewportHelperFunctions>();

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)), []);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    const onDrop = useCallback((event) => {}, [reactFlowInstance]);

    const mode = () => {
        if (id === "create") {
            return "create";
        }
        return "edit";
    };

    const constructRid = () => {
        return `nealive.${rootStore.userStore.managingOrganisationId!}.instance.${id}`;
    };

    const onMenuItemClick = () => {
        setNodes(getNodes());
        setEdges(getEdges());
        setValuesState(valuesState);
    };
    const [valuesState, setValuesState] = React.useState<any>(initialValues);
    const { getNodes, getEdges } = useConstructNodes({
        values: data!,
        setActiveSidebarPanel,
        setActiveSidebarTab,
        setActiveTab,
        setCollapseSidebar,
        setActiveFormPath,
        setActiveFamily,
        setActiveFamilyId,
        setOutputs,
        droppableType,
        setDroppableElementType,
        setDroppableElementId,
        onMenuItemClick,
        onDeleteFamily: (index: number) => {
            setData((data: any) => {
                const newStreamAdaptationFamilies = data.stream_adaptation_families.filter((family: StreamAdaptationFamily, i: number) => {
                    if (i !== index) {
                        return family;
                    }
                });

                return {
                    ...data,
                    stream_adaptation_families: newStreamAdaptationFamilies
                };
            });
        },
        onRemoveStreamAdaptation(family_id, stream_adaptation_id) {
            setLoading(true);
            agent.NeaLive.removeStreamAdaptationFromFamily(family_id, stream_adaptation_id)
                .then(() => {
                    setUpdateDate(new Date());
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    openDialog(
                        <>
                            <DialogContent>{error.data.message}</DialogContent>
                            <DialogActions>
                                <Button onClick={() => closeDialog()} color="primary" variant="contained" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </>,
                        "Error"
                    );
                    console.log("Error removing Stream Adaptation from family", error);
                });
        },
        onRemoveTranscoder(family_id, transcoder_id) {
            setLoading(true);
            agent.NeaLive.removeSourceFromFamily(family_id, transcoder_id)
                .then(() => {
                    setUpdateDate(new Date());
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    openDialog(
                        <>
                            <DialogContent>{error.data.message}</DialogContent>
                            <DialogActions>
                                <Button onClick={() => closeDialog()} color="primary" variant="contained" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </>,
                        "Error"
                    );
                    console.log("Error removing Transcoder from family", error);
                });
        },
        onTranscoderClick(id, rid) {
            openDrawer(<TranscoderDetails id={id} rid={rid} pull_packager_uuid={pull_packager_uuid!} />);
        }
    });

    //Right Click Menu
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    React.useEffect(() => {
        id &&
            mode() === "edit" &&
            rootStore.resClientStore.clientConnected &&
            rootStore.resClientStore.client.get(constructRid()).then((res: any) => {
                res.on("change", () => {
                    //getData(id);
                    setWsData(res);
                    setData(res.nea_live);
                    setUpdateDate(new Date());
                });
                //getData(id);
                setData(res.nea_live);
                setWsData(res);
                console.log(res);
            });

            tab && console.log("tab", tab);
        return () => {
            setWsData(null);
        };
    }, [rootStore.resClientStore.clientConnected, id, rootStore.userStore.managingOrganisationId]);

    React.useEffect(() => {}, [updateDate, droppableType]);

    React.useEffect(() => {
        setNodes(getNodes());
        setEdges(getEdges());
    }, [valuesState, data]);

    const addAdaptationNode = (parentIndex: number) => {
        setLoading(true);
        droppableElementType === "family" &&
            agent.NeaLive.addStreamAdaptationToFamily(activeFamilyId, draggedStreamAdaptation.id)
                .then((response) => {
                    //getData(id!);
                    setUpdateDate(new Date());
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    openDialog(
                        <>
                            <DialogContent>
                                <Typography variant="body1" color="text.primary">
                                    {error.data.message}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        closeDialog();
                                    }}
                                    color="primary"
                                    autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </>,
                        "Error"
                    );
                    setLoading(false);
                });
        setLoading(false);
    };

    const addSourceNode = async (parentIndex: number, name: string) => {
        setLoading(true);
        droppableElementType === "family" &&
            (await agent.NeaLive.addSourceToFamily(activeFamilyId, draggedSource.id)
                .then((response) => {
                    //getData(id!);
                    setUpdateDate(new Date());
                    setLoading(false);
                })
                .catch((error) => {
                    openDialog(
                        <>
                            <DialogContent>{error.data.message}</DialogContent>
                            <DialogActions>
                                <Button onClick={() => closeDialog()} color="primary" variant="contained" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </>,
                        "Error"
                    );
                    console.log("Error adding Transcoder to family", error);
                    setLoading(false);
                }));
        setLoading(false);
    };

    const [streamAdaptations, setStreamAdaptations] = React.useState<any>([]);

    const getStreamAdaptations = async () => {
        await agent.NeaLive.getStreamAdaptations().then((response) => {
            console.log("Stream Adaptations", response);
            setStreamAdaptations(response);
        });
    };

    React.useEffect(() => {
        getStreamAdaptations();
    }, [updateDate]);

    interface StreamAdaptationMenuProps {
        adaptation_id: number;
        mode: "live" | "preset";
    }

    const StreamAdaptationMenu: React.FC<StreamAdaptationMenuProps> = ({ adaptation_id, mode }) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const [availableFamilies, setAvailableFamilies] = React.useState<any>([]);

        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (e) => {
            e.stopPropagation();
            setAnchorEl(null);
        };

        React.useEffect(() => {
            if (wsData.nea_live?.stream_adaptation_families) {
                setAvailableFamilies(wsData.nea_live?.stream_adaptation_families);
            }
        }, [wsData]);

        return (
            <>
                <IconButton
                    aria-label="settings"
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => handleClick(e)}>
                    <MoreVert />
                </IconButton>
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button"
                    }}
                    sx={{ minWidth: 300 }}
                    anchorEl={anchorEl}
                    elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={open}
                    onClose={handleClose}>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                            openDialog(
                                <>
                                    <StreamAdaptationForm
                                        mode="edit"
                                        initialValues={streamAdaptations.adaptations.find((a: any) => a.id === adaptation_id)}
                                        onSubmitted={(values) => getStreamAdaptations()}
                                    />
                                </>,
                                "Edit Stream Adaptation"
                            );
                        }}>
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        Edit
                    </MenuItem>
                    {mode === "preset" && (
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClose(e);
                                openDialog(
                                    <>
                                        <DialogContent>Are you sure you want to delete this Stream Adaptation?</DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    closeDialog();
                                                }}
                                                color="primary">
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    agent.NeaLive.deleteStreamAdaptation(adaptation_id)
                                                        .then(() => {
                                                            setUpdateDate(new Date());
                                                            closeDialog();
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            changeDialogBody(
                                                                <>
                                                                    <DialogContent>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            {error.data?.message}
                                                                        </Typography>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button
                                                                            onClick={() => {
                                                                                closeDialog();
                                                                            }}
                                                                            color="primary"
                                                                            autoFocus>
                                                                            Close
                                                                        </Button>
                                                                    </DialogActions>
                                                                </>,
                                                                "Error"
                                                            );
                                                        });
                                                }}
                                                color="error"
                                                variant="contained">
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </>,
                                    "Delete Stream Adaptation"
                                );
                            }}>
                            <ListItemIcon>
                                <Delete />
                            </ListItemIcon>
                            Delete
                        </MenuItem>
                    )}
                    <Divider />
                    {availableFamilies &&
                        availableFamilies.length > 0 &&
                        availableFamilies.map((family: any, i: number) => {
                            return (
                                <MenuItem
                                    key={i}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClose(e);
                                        agent.NeaLive.addStreamAdaptationToFamily(family.id, adaptation_id)
                                            .then(() => {
                                                setUpdateDate(new Date());
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                openDialog(
                                                    <>
                                                        <DialogContent>
                                                            <Typography variant="body1" color="text.primary">
                                                                {error.data.message}
                                                            </Typography>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button
                                                                onClick={() => {
                                                                    closeDialog();
                                                                }}
                                                                color="primary"
                                                                autoFocus>
                                                                Close
                                                            </Button>
                                                        </DialogActions>
                                                    </>,
                                                    "Error"
                                                );
                                            });
                                    }}>
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    Add to {family.name}
                                </MenuItem>
                            );
                        })}
                </Menu>
            </>
        );
    };

    return (
        <View>
            <ViewHeader title={wsData?.name} />
            <ViewBody noPadding>
                <Form
                    onSubmit={(values) => {
                        console.log(values);
                    }}
                    initialValues={valuesState}
                    render={({ handleSubmit, submitting, values, invalid, dirty }) => (
                        <form onSubmit={handleSubmit} noValidate style={{ height: "100%", width: "100%" }}>
                            <ReactFlowProvider>
                                <Content
                                    noPadding
                                    loading={loading}
                                    rightWidth={collapseSidebar ? 51 : 335}
                                    rightContent={
                                        <SidebarTabs
                                            tabs={
                                                <>
                                                    <SidebarTab
                                                        label="Stream Adaptation Family"
                                                        icon={<GroupsOutlinedIcon />}
                                                        active={activeSidebarTab === "1"}
                                                        onClick={() => {
                                                            setActiveSidebarTab("1");
                                                            setCollapseSidebar(false);
                                                        }}
                                                    />
                                                    <SidebarTab
                                                        label="Stream Adaptations"
                                                        icon={<Dvr />}
                                                        active={activeSidebarTab === "2"}
                                                        onClick={() => {
                                                            setActiveSidebarTab("2");
                                                            setCollapseSidebar(false);
                                                        }}
                                                    />
                                                    <SidebarTab
                                                        label="Transcoders"
                                                        icon={<VideocamOutlinedIcon />}
                                                        active={activeSidebarTab === "3"}
                                                        onClick={() => {
                                                            setActiveSidebarTab("3");
                                                            setCollapseSidebar(false);
                                                        }}
                                                    />
                                                </>
                                            }
                                            panels={
                                                <>
                                                    <SidebarTabPanel active={activeSidebarTab === "1"}>
                                                        <Box
                                                            sx={{
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignItems: "stretch"
                                                            }}>
                                                            <NodeHeader label="Stream Adaptation Family" />
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <List>
                                                                    {data?.stream_adaptation_families.map(
                                                                        (family: StreamAdaptationFamily, i: number) => {
                                                                            return (
                                                                                <ListItem
                                                                                    key={i}
                                                                                    draggable={wsData?.status === "running" ? true : false}>
                                                                                    <ListItemIcon>
                                                                                        <GroupsOutlinedIcon />
                                                                                    </ListItemIcon>
                                                                                    <Box>
                                                                                        <Typography variant="body1" fontWeight={"bold"}>
                                                                                            {family.name}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" color="text.secondary">
                                                                                            {family.stream_adaptations.length || 0} Stream
                                                                                            Adaptation
                                                                                            {family.stream_adaptations?.length > 1
                                                                                                ? "s"
                                                                                                : ""}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </ListItem>
                                                                            );
                                                                        }
                                                                    )}
                                                                </List>
                                                            </Box>
                                                            <Box sx={{ p: 2 }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    startIcon={<AddCircle />}
                                                                    fullWidth
                                                                    disabled={wsData?.status !== "running"}
                                                                    onClick={() => {
                                                                        openDialog(
                                                                            <>
                                                                                <StreamAdaptationFamilyForm
                                                                                    pull_packager_uuid={id!}
                                                                                    onSubmitted={(values) => console.log(values)}
                                                                                />
                                                                            </>,
                                                                            "New Stream Adaptation Family"
                                                                        );
                                                                    }}>
                                                                    Add New
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </SidebarTabPanel>
                                                    <SidebarTabPanel active={activeSidebarTab === "2"}>
                                                        <Box
                                                            sx={{
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignItems: "stretch"
                                                                //maxHeight: "calc(100vh - 200px)"
                                                            }}>
                                                            <NodeHeader label="Stream Adaptations" />
                                                            <TabContext value={value}>
                                                                <Box>
                                                                    <TabList
                                                                        onChange={handleChange}
                                                                        sx={{
                                                                            backgroundColor: "rgba(160,160,160,0.05)",
                                                                            minHeight: 36,
                                                                            height: 36,
                                                                            "& .MuiTab-root": {
                                                                                minHeight: 36,
                                                                                height: 36,
                                                                                padding: "0 16px",
                                                                                fontSize: 12,
                                                                                textTransform: "uppercase",
                                                                                letterSpacing: 1
                                                                            }
                                                                        }}>
                                                                        <Tab label="Live" value="1" />
                                                                        <Tab label="Presets" value="2" />
                                                                    </TabList>
                                                                </Box>
                                                                <TabPanel
                                                                    value="1"
                                                                    sx={{
                                                                        padding: 0,
                                                                        //height: "100%",
                                                                        flexGrow: 1,
                                                                        flexShrink: 1
                                                                    }}>
                                                                    <Box
                                                                        sx={{
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "flex-start",
                                                                            alignItems: "stretch"
                                                                        }}>
                                                                        <Box
                                                                            sx={{
                                                                                flexGrow: 1,
                                                                                overflowY: "auto"
                                                                            }}>
                                                                            <List sx={{ p: 1 }}>
                                                                                {streamAdaptations &&
                                                                                    streamAdaptations.adaptations &&
                                                                                    Object.values(
                                                                                        streamAdaptations.adaptations
                                                                                            .filter(
                                                                                                //filter out the stream adaptations where saf_id is not null or undefined and pull_packager_uuid is equal to the id
                                                                                                (streamAdaptation: any) => {
                                                                                                    return (
                                                                                                        streamAdaptation.family_id !==
                                                                                                            null &&
                                                                                                        streamAdaptation.pull_packager_uuid ===
                                                                                                            id
                                                                                                    );
                                                                                                }
                                                                                            )
                                                                                            .reduce(
                                                                                                (unique: any, streamAdaptation: any) => {
                                                                                                    // Use streamAdaptation.name as the key, if it's already in the unique object, it will be overwritten, thus removing duplicates
                                                                                                    unique[streamAdaptation.name] =
                                                                                                        streamAdaptation;
                                                                                                    return unique;
                                                                                                },
                                                                                                {}
                                                                                            )
                                                                                    ).map((streamAdaptation: any) => (
                                                                                        <ListItem
                                                                                            sx={{
                                                                                                cursor: "grab",
                                                                                                backgroundColor: theme.palette.action.hover,
                                                                                                borderRadius: 1,
                                                                                                mb: 1,
                                                                                                pr: 0,
                                                                                                "&:hover": {
                                                                                                    backgroundColor:
                                                                                                        theme.palette.action.selected
                                                                                                }
                                                                                            }}
                                                                                            key={streamAdaptation.id}
                                                                                            draggable={
                                                                                                wsData?.status === "running" ? true : false
                                                                                            }
                                                                                            onClick={() => {
                                                                                                openDialog(
                                                                                                    <>
                                                                                                        <StreamAdaptationForm
                                                                                                            mode="edit"
                                                                                                            initialValues={streamAdaptation}
                                                                                                            onSubmitted={(values) =>
                                                                                                                getStreamAdaptations()
                                                                                                            }
                                                                                                        />
                                                                                                    </>,
                                                                                                    "Edit Stream Adaptation"
                                                                                                );
                                                                                            }}
                                                                                            onDragStart={() => {
                                                                                                setDraggedStreamAdaptation(
                                                                                                    streamAdaptation
                                                                                                );
                                                                                            }}
                                                                                            onDragEnd={() => {
                                                                                                activeFamilyId !== 0 &&
                                                                                                    addAdaptationNode(activeFamily);
                                                                                            }}>
                                                                                            <ListItemIcon sx={{ minWidth: 40 }}>
                                                                                                <Dvr />
                                                                                            </ListItemIcon>
                                                                                            <ListItemText>
                                                                                                <Typography
                                                                                                    variant="body1"
                                                                                                    sx={{ fontWeight: "bold" }}>
                                                                                                    {streamAdaptation.name}
                                                                                                </Typography>
                                                                                                <Typography
                                                                                                    variant="body2"
                                                                                                    component={"div"}>
                                                                                                    Type: {streamAdaptation.parameters.type}
                                                                                                </Typography>
                                                                                            </ListItemText>
                                                                                            <StreamAdaptationMenu
                                                                                                adaptation_id={streamAdaptation.id}
                                                                                                mode="live"
                                                                                            />
                                                                                        </ListItem>
                                                                                    ))}
                                                                            </List>
                                                                        </Box>
                                                                    </Box>
                                                                </TabPanel>
                                                                <TabPanel
                                                                    value="2"
                                                                    sx={{
                                                                        padding: 0,
                                                                        flexGrow: 1,
                                                                        flexShrink: 1,
                                                                        overflowY: "auto"
                                                                    }}>
                                                                    <List sx={{ p: 1 }}>
                                                                        {streamAdaptations &&
                                                                            streamAdaptations.adaptations &&
                                                                            streamAdaptations.adaptations
                                                                                .filter(
                                                                                    //filter out the stream adaptations where saf_id is not null or undefined
                                                                                    (streamAdaptation: any) => {
                                                                                        return streamAdaptation.family_id === null;
                                                                                    }
                                                                                )
                                                                                .map((streamAdaptation: any) => (
                                                                                    <ListItem
                                                                                        sx={{
                                                                                            cursor: "grab",
                                                                                            backgroundColor: theme.palette.action.hover,
                                                                                            borderRadius: 1,
                                                                                            mb: 1,
                                                                                            pr: 0,
                                                                                            "&:hover": {
                                                                                                backgroundColor:
                                                                                                    theme.palette.action.selected
                                                                                            }
                                                                                        }}
                                                                                        key={streamAdaptation.id}
                                                                                        draggable={
                                                                                            wsData?.status === "running" ? true : false
                                                                                        }
                                                                                        onClick={() => {
                                                                                            openDialog(
                                                                                                <>
                                                                                                    <StreamAdaptationForm
                                                                                                        mode="edit"
                                                                                                        initialValues={streamAdaptation}
                                                                                                        onSubmitted={(values) =>
                                                                                                            getStreamAdaptations()
                                                                                                        }
                                                                                                    />
                                                                                                </>,
                                                                                                "Edit Stream Adaptation"
                                                                                            );
                                                                                        }}
                                                                                        onDragStart={() => {
                                                                                            setDraggedStreamAdaptation(streamAdaptation);
                                                                                        }}
                                                                                        onDragEnd={() => {
                                                                                            activeFamilyId !== 0 &&
                                                                                                addAdaptationNode(activeFamily);
                                                                                        }}>
                                                                                        <ListItemIcon sx={{ minWidth: 40 }}>
                                                                                            <Dvr />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                sx={{ fontWeight: "bold" }}>
                                                                                                {streamAdaptation.name}
                                                                                            </Typography>
                                                                                            <Typography variant="body2" component={"div"}>
                                                                                                Type: {streamAdaptation.parameters.type}
                                                                                            </Typography>
                                                                                        </ListItemText>
                                                                                        <StreamAdaptationMenu
                                                                                            adaptation_id={streamAdaptation.id}
                                                                                            mode="preset"
                                                                                        />
                                                                                    </ListItem>
                                                                                ))}
                                                                    </List>
                                                                </TabPanel>
                                                            </TabContext>
                                                            <Box sx={{ p: 2 }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    startIcon={<AddCircle />}
                                                                    fullWidth
                                                                    disabled={wsData?.status !== "running"}
                                                                    onClick={() => {
                                                                        openDialog(
                                                                            <>
                                                                                <StreamAdaptationForm
                                                                                    mode="create"
                                                                                    onSubmitted={(values) => getStreamAdaptations()}
                                                                                />
                                                                            </>,
                                                                            "New Stream Adaptation"
                                                                        );
                                                                    }}>
                                                                    Create New Adaptation
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </SidebarTabPanel>
                                                    <SidebarTabPanel active={activeSidebarTab === "3"}>
                                                        <Box
                                                            sx={{
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignItems: "stretch"
                                                            }}>
                                                            <Sources
                                                                updateDate={updateDate}
                                                                nea_instance_uuid={id!}
                                                                nea_instance_status={wsData?.status}
                                                                setDraggedSource={setDraggedSource}
                                                                onDragEnd={(e, name) => {
                                                                    addSourceNode(activeFamily, name);
                                                                }}
                                                                transcoders={wsData?.nea_live?.transcoders}
                                                            />
                                                            <Box sx={{ p: 2 }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={wsData?.status !== "running"}
                                                                    startIcon={<AddCircle />}
                                                                    fullWidth
                                                                    onClick={() => {
                                                                        navigate(`/pull-packager/${id}/create-source`);
                                                                    }}>
                                                                    Create New Transcoder
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </SidebarTabPanel>
                                                </>
                                            }
                                            collapsible={true}
                                            collapsed={collapseSidebar}
                                            onCollapse={() => setCollapseSidebar(!collapseSidebar)}
                                        />
                                    }
                                    footerContent={
                                        <Resizable
                                            height={footerHeight}
                                            width={0}
                                            handle={
                                                <Box
                                                    className="handle-n"
                                                    sx={{
                                                        height: 10,
                                                        width: "100%",
                                                        cursor: "row-resize",
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        display: collapseFooter ? "none" : "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        overflow: "hidden",
                                                        backgroundColor: "rgba(0,0,0,0.1)",
                                                        "&:hover": {
                                                            backgroundColor: theme.palette.action.hover
                                                        }
                                                    }}>
                                                    <DragHandle sx={{ fontSize: 16 }} />
                                                </Box>
                                            }
                                            handleAxis="y"
                                            onResizeStart={(e) => {
                                                setInitialY(e.clientY);
                                                setInitialHeight(footerHeight);
                                            }}
                                            onResize={(e, data) => {
                                                const currentY = e.clientY;
                                                const differenceY = initialY! - currentY;
                                                const newHeight = initialHeight! + differenceY;

                                                setFooterHeight(newHeight);
                                            }}>
                                            <Box
                                                sx={{
                                                    height: collapseFooter ? 56 : footerHeight,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: "100%",
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    pt: "14px"
                                                }}>
                                                <TabContext value={activeTab}>
                                                    <TabList onChange={handleTabChange}>
                                                        <Tab label="Details" value="1" />
                                                        <Tab label="Stats" value="2" />
                                                        <Tab label="Notifications" value="3" />
                                                        {/* <Tab label="Settings" value="4" /> */}
                                                        <Tab label="Player" value="5" />
                                                    </TabList>
                                                    <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Name</FormLabel>
                                                                    <Typography variant="body1">{wsData?.name}</Typography>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Status</FormLabel>
                                                                    <Box>
                                                                        <Chip
                                                                            sx={{ textTransform: "capitalize" }}
                                                                            label={wsData?.status}
                                                                            size="small"
                                                                            color={wsData?.status === "running" ? "success" : undefined}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                {/* TODO: Should we add a transcoder count on this screen? */}
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Transcoders</FormLabel>
                                                                    <Typography variant="body1">
                                                                        {wsData?.nea_live?.transcoders_count}
                                                                    </Typography>
                                                                </Box>
                                                                {/* <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>DNS Name</FormLabel>
                                                                    <Typography variant="body1">{wsData?.nea_live?.domain_name}</Typography>
                                                                </Box> */}
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                {/* <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Public IP</FormLabel>
                                                                    <Typography variant="body1">{wsData?.nea_live?.created_on}</Typography>
                                                                </Box> */}
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Product Size</FormLabel>
                                                                    <Typography variant="body1">
                                                                        {wsData?.nea_live?.product?.product_description}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Cloud Provider</FormLabel>
                                                                    <Typography variant="body1">{data?.cloud_provider}</Typography>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Region</FormLabel>
                                                                    <Typography variant="body1">{data?.region}</Typography>
                                                                </Box>
                                                                {/* <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>Ref Code</FormLabel>
                                                                    <Typography variant="body1">{data?.ref_code}</Typography>
                                                                </Box> */}
                                                                <Box sx={{ mb: 2 }}>
                                                                    <FormLabel sx={{ fontSize: 11 }}>ID</FormLabel>
                                                                    <Typography variant="body1">{data?.id}</Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {
                                                            //if the status is running then show the stats
                                                            wsData?.status === "running" ? (
                                                                <Stats nea_live_uuid={id!} transcoders={wsData.nea_live?.transcoders} />
                                                            ) : (
                                                                <Alert severity="info">Please start the packager to view the stats</Alert>
                                                            )
                                                        }
                                                    </TabPanel>
                                                    <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {
                                                            //if the status is running then show the alarms
                                                            wsData?.status === "running" ? (
                                                                <Alarms nea_live_uuid={id!} />
                                                            ) : (
                                                                <Alert severity="info">Please start the packager to view the alarms</Alert>
                                                            )
                                                        }
                                                    </TabPanel>
                                                    <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        {values && <pre>{JSON.stringify(values, null, 2)}</pre>}
                                                    </TabPanel>
                                                    <TabPanel value="5" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <VideoPlayers outputs={outputs} />
                                                    </TabPanel>
                                                </TabContext>
                                                <IconButton
                                                    onClick={() => setCollapseFooter(!collapseFooter)}
                                                    sx={{ position: "absolute", right: 10, top: 16 }}>
                                                    <ExpandMoreIcon
                                                        sx={{ transform: collapseFooter ? "rotate(180deg)" : "rotate(0deg)" }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Resizable>
                                    }>
                                    <Box
                                        id="flow-wrapper"
                                        ref={reactFlowWrapper}
                                        onContextMenu={handleContextMenu}
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "stretch",
                                            border: insideDropArea ? "2px dashed rgba(160,160,160,0.2)" : "2px dashed rgba(160,160,160,0)"
                                        }}>
                                        {data && wsData && (
                                            <ReactFlow
                                                nodes={nodes}
                                                nodeTypes={nodeTypes}
                                                edges={edges}
                                                defaultEdgeOptions={{
                                                    style: { stroke: theme.palette.primary.main, strokeWidth: 2, strokeDasharray: 3 },
                                                    updatable: true,
                                                    markerEnd: {
                                                        type: MarkerType.ArrowClosed,
                                                        width: 10,
                                                        height: 10,
                                                        color: theme.palette.secondary.main
                                                    },
                                                    animated: true
                                                }}
                                                snapToGrid={true}
                                                onInit={(instance) => {
                                                    setNodes(getNodes());
                                                    setReactFlowInstance(instance);
                                                }}
                                                fitView={true}
                                                onNodesChange={onNodesChange}
                                                onEdgesChange={onEdgesChange}
                                                onEdgeUpdate={onEdgeUpdate}
                                                onConnect={onConnect}
                                                onDrop={onDrop}
                                                //onDragOver={onDragOver}
                                                onDragLeave={() => {
                                                    setInsideDropArea(false);
                                                }}>
                                                <Controls position="top-right" />
                                                <Background gap={gridSnap} size={1} />
                                            </ReactFlow>
                                        )}
                                        <Menu
                                            open={contextMenu !== null}
                                            onClose={handleClose}
                                            anchorReference="anchorPosition"
                                            anchorPosition={
                                                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                                            }>
                                            <MenuItem
                                                onClick={() => {
                                                    openDialog(
                                                        <>
                                                            <StreamAdaptationFamilyForm
                                                                pull_packager_uuid={id!}
                                                                onSubmitted={(values) => console.log(values)}
                                                            />
                                                        </>,
                                                        "New Stream Adaptation Family"
                                                    );
                                                    handleClose();
                                                }}>
                                                Add New Family
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Content>
                            </ReactFlowProvider>
                        </form>
                    )}
                />
            </ViewBody>
        </View>
    );
});

export default PullPackagerDetails;
