import { agent, RootStoreContext } from "api";
import OnChange from "components/FinalFormListener/OnChange";
import { CheckboxField, SelectField } from "components/Forms";
import { useSources } from "hooks";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useFormState } from "react-final-form";

interface EnvironmentSourceProps {
    nea_instance_id?: string;
    mode: "edit" | "create";
}

const EnvironmentSource: React.FC<EnvironmentSourceProps> = ({ nea_instance_id, mode }) => {
    const rootStore = useContext(RootStoreContext);
    const { environments, setEnvironments } = rootStore.environmentStore;
    const [neaEnvironments, setNeaEnvironments] = React.useState<any>();
    const [sources, setSources] = React.useState<any>();
    const [backupSources, setBackupSources] = React.useState<any>();
    const { values } = useFormState();
    const { getInputsDropdownList } = useSources();
    const [hasFetched, setHasFetched] = React.useState(false);

    const getSourceList = async (environmentId: string) => {
        const response = await getInputsDropdownList(environmentId);
        setSources(response);
    };

    const getBackupSourceList = async (environmentId: string) => {
        const response = await getInputsDropdownList(environmentId);
        setBackupSources(response);
    };

    const mainEnvironmentRegion = (id) => {
        const environment = environments?.data.find((environment) => environment.id === id);
        return environment?.region;
    };

    React.useEffect(() => {
        if (values.environment_id) {
            getSourceList(values.environment_id);
        }
    }, [values.environment_id]);

    React.useEffect(() => {
        if (nea_instance_id) {
            const getNeaEnvironments = async () => {
                const response = await agent.NeaLive.getAvailablePullPackagerEnvironments(nea_instance_id);
                setNeaEnvironments(response.data);
            };
            getNeaEnvironments();
        }
    }, [nea_instance_id]);

    const getEnvironments = async () => {
        await agent.Environment.getEnvironments()
            .then((response) => {
                setEnvironments(response);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setHasFetched(true);
            });
    };

    React.useEffect(() => {
        if (!environments && !hasFetched) {
            getEnvironments();
        } else {
            console.log("Environments", environments);
        }
    }, [environments, hasFetched]);

    return (
        <>
            {environments?.data && !nea_instance_id && (
                <SelectField
                    name="environment_id"
                    label="Environment"
                    disabled={!environments?.data.length && hasFetched}
                    required
                    validate={(value) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                    options={environments?.data
                        .filter((environment) => environment.status === "running")
                        .map((environment) => ({
                            text: environment.name,
                            value: environment.id
                        }))}
                    helperText={(meta) =>
                        (meta.touched && meta.error) || (!environments?.data.length && hasFetched) ? "No environments available" : ""
                    }
                />
            )}

            {neaEnvironments && nea_instance_id && (
                <SelectField
                    name="environment_id"
                    label="Environment"
                    required
                    disabled={!environments?.data.length && hasFetched}
                    validate={(value) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                    options={neaEnvironments.map((environment: any) => ({
                        text: environment.name,
                        value: environment.id
                    }))}
                    helperText={(meta) =>
                        (meta.touched && meta.error) || (!environments?.data.length && hasFetched) ? "No environments available" : ""
                    }
                />
            )}

            <OnChange name="environment_id">
                {(value) => {
                    if (value) {
                        getSourceList(value);
                    }
                }}
            </OnChange>

            {values.environment_id && (
                <CheckboxField
                    disabled={mode === "edit"}
                    name="make_permanent_destination"
                    label="Make Permanent Destination"
                    sx={{ mb: 2 }}
                />
            )}

            {sources && values.make_permanent_destination === true && (
                <SelectField
                    name="permanent_destination_input_id"
                    label="Permanent Destination Source"
                    required
                    validate={(value) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                    options={sources?.data.map((source: any) => ({
                        text: source.name,
                        value: source.id
                    }))}
                />
            )}

            {values.enable_backup === true && values.environment_id && (
                <>
                    <SelectField
                        name="backup_environment_id"
                        label="Backup Environment"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        options={
                            environments
                                ? environments?.data
                                      .filter(
                                          (environment) =>
                                              environment.region === mainEnvironmentRegion(values.environment_id) &&
                                              environment.status === "running" &&
                                              environment.id !== values.environment_id
                                      )
                                      .map((environment) => ({
                                          key: environment.id,
                                          text: environment.name,
                                          value: environment.id
                                      }))
                                : []
                        }
                    />
                    <OnChange name="backup_environment_id">
                        {(value) => {
                            getBackupSourceList(value);
                        }}
                    </OnChange>
                    {values.backup_environment_id && values.make_permanent_destination && (
                        <SelectField
                            name="backup_permanent_destination_input_id"
                            label="Backup Permanent Destination Source"
                            required
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                            options={
                                backupSources
                                    ? backupSources.data.map((source: any) => ({
                                          key: source.id,
                                          text: source.name,
                                          value: source.id
                                      }))
                                    : []
                            }
                        />
                    )}
                </>
            )}
        </>
    );
};

export default observer(EnvironmentSource);
