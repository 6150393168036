import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Popover,
    Skeleton,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import React from "react";
import SourceDetails from "features/sources/Components/SourceDetails";
import InfoIcon from "@mui/icons-material/Info";
import { RootStoreContext } from "api";
import { ResCollection, ResModel } from "resclient";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import SourceThumbnail from "features/sources/Components/SourceThumbnail";
import { observer } from "mobx-react-lite";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

interface PermanentScheduleItemProps {
    source_id: string;
    source_name: string;
}

const PermanentScheduleItem: React.FC<PermanentScheduleItemProps> = (props) => {
    const theme = useTheme();

    const rootStore = React.useContext(RootStoreContext);
    const [sourceWsData, setSourceWsData] = React.useState<ResModel | ResCollection | any>({});

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const { openDrawer } = React.useContext(RootStoreContext).drawerStore;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    React.useEffect(() => {
        setSourceWsData({});

        const rid = `environments.sources.${rootStore.userStore.managingOrganisationId}.${rootStore.userStore.activeEnvironment}.${rootStore.userStore.activeorganisationId}.source.${props.source_id}`;

        props.source_id &&
            rootStore.resClientStore.clientConnected &&
            rootStore.resClientStore.client.get(rid).then((response) => {
                response.on("change", (event) => {
                    //setSourceWsData(response);
                    console.log("Source WS Data Changed", event);
                    setUpdateDate(new Date());
                });
                setSourceWsData(response);
            });

        //setUpdateDate(new Date());

        return () => {
            setSourceWsData({});
        };
    }, [
        props.source_id,
        rootStore.userStore.activeEnvironment,
        rootStore.userStore.activeorganisationId,
        rootStore.userStore.managingOrganisationId
    ]);

    // React.useEffect(() => {
    //     setUpdateDate(new Date());
    // }, [props.source_id]);

    React.useEffect(() => {}, [updateDate, props.source_id, props.source_name, sourceWsData]);

    return (
        <Box
            sx={{
                position: "absolute",
                opacity: 0.6,
                left: 0,
                right: 0,
                top: 10,
                bottom: 10,
                height: "calc(100% - 20px)",
                backgroundColor: "#307abb",
                width: "100%",
                flexShrink: 0
            }}>
            <Card sx={{ borderRadius: 0, height: "100%", backgroundColor: "transparent" }}>
                <CardActionArea sx={{ height: "100%" }} onClick={handleClick}></CardActionArea>
            </Card>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                sx={{ marginTop: "5px", marginLeft: "370px" }}>
                <Box sx={{ width: 300 }}>
                    <CardHeader
                        title={
                            sourceWsData.connection_status ? (
                                <span
                                    style={{
                                        display: "block",
                                        width: "100%",
                                        textTransform: "capitalize",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}>
                                    {sourceWsData.is_input_cutout
                                        ? "Stopped"
                                        : sourceWsData.connection_status === "no-thumb-avail"
                                        ? "Thumbnail Unavailable"
                                        : sourceWsData.connection_status}
                                </span>
                            ) : (
                                <Skeleton>
                                    <span>Connection Status</span>
                                </Skeleton>
                            )
                        }
                        subheader={
                            sourceWsData.type ? (
                                <span style={{ textTransform: "capitalize" }}>
                                    {sourceWsData.transcoder_id
                                        ? "Transcoder " + sourceWsData.transcoder_status
                                        : sourceWsData.multiviewer_id
                                        ? "Multiviewer " + sourceWsData.multiviewer_status
                                        : sourceWsData.human_type}
                                </span>
                            ) : (
                                <Skeleton>
                                    <span>Source Type</span>
                                </Skeleton>
                            )
                        }
                        avatar={
                            <Avatar
                                sx={{
                                    bgcolor:
                                        sourceWsData.connection_status === "connected" ||
                                        sourceWsData.connection_status === "no-thumb-avail"
                                            ? theme.palette.success.main
                                            : sourceWsData.connection_status === "connecting"
                                            ? theme.palette.warning.main
                                            : theme.palette.error.main,
                                    color: "#fff"
                                }}
                                //title={props.source.is_transcoded_input ? "Transcoded Source" : "Source"}
                                title="Source"
                                aria-label="recipe">
                                {sourceWsData.active_alerts > 0 ? (
                                    <NotificationsActiveIcon />
                                ) : sourceWsData.connection_status === "offline" ? (
                                    <VideocamOffIcon />
                                ) : sourceWsData.connection_status === "not-connected" || sourceWsData.connection_status === "no-video" ? (
                                    <VideocamOffIcon />
                                ) : sourceWsData.connection_status === "connected" ? (
                                    <VideocamIcon />
                                ) : sourceWsData.connection_status === "no-thumb-avail" ? (
                                    <ImageNotSupportedIcon />
                                ) : (
                                    <HourglassFullIcon />
                                )}
                            </Avatar>
                        }
                    />
                    <CardActionArea
                        sx={{
                            backgroundColor: sourceWsData.transcoder_id
                                ? theme.palette.primary.dark
                                : sourceWsData.multiviewer_id
                                ? theme.palette.secondary.dark
                                : undefined
                        }}
                        onClick={() => {
                            handleClose();
                            openDrawer(
                                <Box sx={{ width: 680, height: "100%", padding: 3 }}>
                                    <SourceDetails item_id={props.source_id} />
                                </Box>
                            );
                        }}>
                        <CardContent sx={{ padding: 0 }}>
                            {sourceWsData.connection_status === "connected" ? (
                                <SourceThumbnail item_id={props.source_id} enableFullScreen />
                            ) : (
                                <Skeleton variant="rectangular" animation={false} width="100%" sx={{ paddingBottom: "56.25%" }} />
                            )}
                        </CardContent>
                        <CardContent sx={{ paddingY: sourceWsData.transcoder_input_name ? "11.5px" : undefined }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                component="div"
                                sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                Permanent Schedule
                            </Typography>
                            {sourceWsData.name ? (
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{ width: "100%", marginBottom: sourceWsData.transcoder_input_name ? -1 : undefined }}>
                                    {props.source_name}
                                </Typography>
                            ) : (
                                <Skeleton>
                                    <Typography variant="h6" component="div">
                                        Card Title Source Name
                                    </Typography>
                                </Skeleton>
                            )}
                            {sourceWsData.transcoder_input_name ? (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    component="div"
                                    sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                    Source: {sourceWsData.transcoder_input_name}
                                </Typography>
                            ) : null}
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Tooltip title="Source Information" placement="bottom">
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    setAnchorEl(null);
                                    openDrawer(
                                        <Box sx={{ padding: 2, width: 680, maxWidth: 680, height: "100%" }}>
                                            <SourceDetails item_id={props.source_id} />
                                        </Box>
                                    );
                                }}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </CardActions>
                </Box>
            </Popover>
        </Box>
    );
};

export default observer(PermanentScheduleItem);
