import React, { useContext, useEffect, useState } from "react";
import ErrorMessage from "app/common/form/ErrorMessage";
import { IEnvironmentsData, IProvider, IRegion, RootStoreContext } from "api";
import { Form as FinalForm } from "react-final-form";
import { isRequired } from "revalidate";
import { observer } from "mobx-react-lite";
import { EnvironmentResize } from "./EnvironmentResize";
import Button from "@mui/material/Button";
import OnChange from "components/FinalFormListener/OnChange";
import { Alert, Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SelectField } from "components/Forms";
import { useEnvironment } from "hooks";

interface ParentCompProps {
    environment: IEnvironmentsData;
}

const EnvironmentSelectProvider: React.FC<ParentCompProps> = observer(function EnvironmentSelectProvider({ environment }) {
    const rootStore = useContext(RootStoreContext);
    const { getProviders, getUpdateRegions, getUpdateSizes, getEnvironmentUpgradeSizes, getEnviromentUpgradeData } = useEnvironment();
    const [providers, setProviders] = useState<IProvider[]>();
    const [regionsSet, setRegions] = useState<IRegion[]>();
    const { closeDialog, changeDialogBody } = rootStore.dialogStore;

    useEffect(() => {
        getEnviromentUpgradeData().then(() => {
            getEnvironmentUpgradeSizes(environment.id)
                .then(() => {})
                .catch((error) => console.log(error));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getProviders().then((value) => {
            setProviders(value);
        });
    }, [getProviders]);

    useEffect(() => {
        getUpdateRegions(environment.cloud_provider).then((value) => {
            setRegions(value);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUpdateRegions]);

    const handleFinalFormSubmit = (values: any) => {
        const body = {
            product_id: values.cloud_provider,
            region: values.region,
            upgrade_now: true
        };

        if (environment) {
            changeDialogBody(<EnvironmentResize environment={environment} body={body} />);
        }
    };

    const handleProviderChange = (cp: string) => {
        getUpdateRegions(cp)
            .then((value) => {
                setRegions(value);
            })
            .catch((error) => console.log(error));
    };

    const handleRegionChange = (rg: string) => {
        getUpdateSizes(rg)
            .then(() => {})
            .catch((error) => console.log(error));
    };

    const notification =
        "Warning! Changing cloud provider or region will change the IP address of the enviroment, " +
        "the DNS name will remain the same. You will need to repoint any source or destination devices " +
        "to the new IP address —if being used. Also, any transcoders will need to be destroyed and rebuilt " +
        "once the new environment is up and running.";

    const providerOptions = providers?.map((option) => ({
        ...option,
        key: option.code,
        text: option.name,
        value: option.code
    }));

    const regionOptions = regionsSet?.map((option) => ({
        ...option,
        key: option.code,
        text: option.name,
        value: option.code
    }));

    return (
        <>
            <DialogTitle>Would you like to change provider or region?</DialogTitle>
            <DialogContent>
                <FinalForm
                    onSubmit={(values: IEnvironmentsData) => handleFinalFormSubmit(values)}
                    render={({ handleSubmit, submitError, dirtySinceLastSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Box>
                                {providers && providerOptions && (
                                    <SelectField
                                        options={providerOptions}
                                        required
                                        label="Cloud Provider"
                                        name="cloud_provider"
                                        validate={isRequired("Cloud Provider")}
                                        initialValue={environment.cloud_provider}
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                    />
                                )}
                                {providers && (
                                    <OnChange name="cloud_provider">
                                        {(value) => {
                                            handleProviderChange(value);
                                        }}
                                    </OnChange>
                                )}
                                {regionsSet && regionOptions && (
                                    <SelectField
                                        options={regionOptions}
                                        required
                                        label="Cloud Region"
                                        name="region"
                                        placeholder="Select Type"
                                        validate={isRequired("Cloud Region")}
                                        initialValue={environment.region}
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                    />
                                )}
                                {regionsSet && (
                                    <OnChange name="region">
                                        {(value) => {
                                            handleRegionChange(value);
                                        }}
                                    </OnChange>
                                )}
                                <Alert severity="info" style={{ marginTop: 20 }}>
                                    {notification}
                                </Alert>
                                {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                            </Box>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        closeDialog();
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={environment.cloud_provider !== values.cloud_provider && values.region === environment.region}>
                                    {environment.cloud_provider !== values.cloud_provider || environment.region !== values.region
                                        ? "Modify"
                                        : "Don't Modify"}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </DialogContent>
        </>
    );
});

export default EnvironmentSelectProvider;
