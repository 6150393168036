import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";

export default class DrawerStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable.shallow drawer = {
        open: false,
        content: undefined,
        title: undefined
    };

    @action openDrawer = (content: any, title?: any): void => {
        this.drawer.open = true;
        this.drawer.content = content;
        this.drawer.title = title;
    };

    @action closeDrawer = () => {
        this.drawer.open = false;
    };

    @action changeDrawerContent = (content: any) => {
        this.drawer.open = true;
        this.drawer.content = content;
    };
}
