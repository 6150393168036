/* eslint-disable mobx/missing-observer */
import { Box } from "@mui/material";
import React from "react";

const CurrentTimeIndicator: React.FC = () => {
    const today = new Date();
    const minutesOfDay = today.getHours() * 60 + today.getMinutes();
    const [currentMinute, setCurrentMinute] = React.useState<number>(Number(minutesOfDay));

    React.useEffect(() => {
        const interval = setInterval(() => {
            const today = new Date();
            const minutesOfDay = today.getHours() * 60 + today.getMinutes();
            setCurrentMinute(Number(minutesOfDay));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            sx={{
                width: "100%",
                height: "1px",
                position: "absolute",
                top: currentMinute,
                left: 0,
                backgroundColor: (theme) => theme.palette.error.main,
                opacity: 0.8,
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -3,
                    left: 0,
                    width: 7,
                    height: 7,
                    backgroundColor: (theme) => theme.palette.error.main
                }
            }}></Box>
    );
};

export default CurrentTimeIndicator;
