import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Fade, Stack, TextField, Tooltip, Typography } from "@mui/material";
import GuestOrganisationsTable from "./GuestOrganisationsTable";
import { useSourcePermissions } from "hooks";

const GuestOrganisations = () => {
    const rootStore = useContext(RootStoreContext);
    const [query, setQuery] = useState("");
    const { nOfActiveDestOrgs, myGuestOrganisationsData } = rootStore.sourcePermissionsStore;
    const { loadMyGuestOrgs } = useSourcePermissions();

    const filtered =
        myGuestOrganisationsData && myGuestOrganisationsData?.data!.filter((x) => x.organisation_name?.toLowerCase().includes(query));

    useEffect(() => {
        loadMyGuestOrgs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Stack spacing={1}>
                <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" gutterBottom sx={{ mt: "8px !important" }}>
                        {nOfActiveDestOrgs > 0 ? `My Guest Organisations (${nOfActiveDestOrgs})` : "My Guest Organisations"}
                    </Typography>
                    <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{
                            timeout: 600
                        }}
                        placement="bottom"
                        title="Search Guest Organisations">
                        <TextField
                            variant="standard"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            label="Search"
                            sx={{ mb: "8px !important", width: 150 }}
                        />
                    </Tooltip>
                </Stack>
                <Table sx={{ mb: 4 }} stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                width={65}
                                sx={{
                                    backgroundColor: (theme) => theme.palette.background.paper
                                }}></TableCell>
                            <TableCell
                                width="30%"
                                sx={{
                                    backgroundColor: (theme) => theme.palette.background.paper
                                }}>
                                <Box>Name</Box>
                            </TableCell>
                            <TableCell
                                sx={{
                                    backgroundColor: (theme) => theme.palette.background.paper
                                }}>
                                <Box>Status</Box>
                            </TableCell>
                            <TableCell
                                width={220}
                                sx={{
                                    backgroundColor: (theme) => theme.palette.background.paper
                                }}>
                                <Box>Billing Source</Box>
                            </TableCell>
                            <TableCell
                                width={110}
                                sx={{
                                    backgroundColor: (theme) => theme.palette.background.paper
                                }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filtered && filtered.length ? (
                            filtered.map((org, index) => <GuestOrganisationsTable org={org} key={index} />)
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>No guest organisations found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
};

export default observer(GuestOrganisations);
