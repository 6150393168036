import React, { useContext, useState, useRef } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    agent,
    RootStoreContext,
    IInput,
    IInputAnalisysDataGroup1Entry,
    IInputAnalysisDataGroup1,
    IInputAnalysisDataGroup2,
    IInputAnalysisPrograms,
    InputAnalysisDataGroup2Entry
} from "api";
import { useEffect } from "react";
import {
    FormControlLabel,
    Checkbox,
    Box,
    Button,
    Tooltip,
    Fade,
    Typography,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSources } from "hooks";

interface IProps {
    input_details: IInput;
    p1_alerts_enabled?: boolean;
    p2_alerts_enabled?: boolean;
    showAlerts?: boolean;
}

const SourceAnalysis: React.FC<IProps> = ({ input_details, p1_alerts_enabled, p2_alerts_enabled, showAlerts }) => {
    const rootStore = useContext(RootStoreContext);
    const { resetInputAnalysis, loadInputClean, getInputAnalysisData } = useSources();
    const [openStatuses, setOpenStatuses] = useState({});
    const [isPageLoaded, setPageLoaded] = useState(false);
    let dataPullTimeout: any = null;
    const isMountedRef = useRef(false);
    const [dataGroup1, setGroup1Data] = useState<IInputAnalysisDataGroup1[]>();
    const [dataGroup2, setGroup2Data] = useState<IInputAnalysisDataGroup2[]>();
    const [gotErrors, setGotErrors] = useState<boolean>(false);
    const isAnalysisOnRef = useRef(false);

    const startPollingAnalysis = (setupNextCall: boolean = true) => {
        getInputAnalysisData(input_details.id).then((response) => {
            if (isMountedRef.current && isAnalysisOnRef.current) {
                if (response!.http_status_code === 200) {
                    // Now update the state. State is where I'm generating the tables from.
                    setGroup1Data(response!.data_group_1);
                    setGroup2Data(response!.data_group_2);
                    setGotErrors(response!.got_errors);
                }
                if (setupNextCall) {
                    dataPullTimeout = setTimeout(startPollingAnalysis, 5000);
                }
            }
        });
    };

    const clearDataPullTimeout = () => {
        if (dataPullTimeout) {
            clearTimeout(dataPullTimeout);
        }
    };

    useEffect(() => {
        isMountedRef.current = true;
        // Load the detailf from API. Can not rely on input details being up-to-date.
        loadInputClean(input_details.id)
            .then((input) => {
                if (input.is_analysis_on) {
                    isAnalysisOnRef.current = true;
                    startPollingAnalysis();
                }
            })
            .finally(() => {
                setPageLoaded(true);
            });
        return () => {
            isMountedRef.current = false;
            clearDataPullTimeout();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input_details.id]);

    const toggleIsOpen = (key: string) => {
        let newStatuses = openStatuses;
        // @ts-ignore
        let currentlyOpen = openStatuses.hasOwnProperty(key) ? openStatuses[key] : false;
        // @ts-ignore
        newStatuses[key] = !currentlyOpen;
        setOpenStatuses(newStatuses);
        startPollingAnalysis(false);
    };

    const isOpen = (key: string) => {
        // @ts-ignore
        return openStatuses.hasOwnProperty(key) ? openStatuses[key] : false;
    };

    let turnOnSubmitting = false;
    const turnAnalysisOn = (input_id: string, body?: any) => {
        turnOnSubmitting = true;
        agent.Channel.startAnalysis(input_id, body ? body : {}).then((response) => {
            if (response.status === "ok") {
                isAnalysisOnRef.current = true;
                startPollingAnalysis();
            } else {
                alert(`Failed starting analysis. Error message: ${response.message}`);
            }
        });
        turnOnSubmitting = false;
    };

    const turnAnalysisOff = (input_id: string) => {
        agent.Channel.stopAnalysis(input_id).then((response) => {
            if (response.status === "ok") {
                isAnalysisOnRef.current = false;
                clearDataPullTimeout();
            } else {
                alert(`Failed stopping analysis. Error message: ${response.message}`);
            }
        });
    };

    return (
        <Box>
            {isPageLoaded && isAnalysisOnRef.current === false && (
                <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            //name="turn_on_analysis"
                            onClick={(e) => {
                                e.preventDefault();
                                turnAnalysisOn(input_details.id);
                            }}
                            disabled={turnOnSubmitting}
                            loading={turnOnSubmitting}
                            //alt="Turn on input analysis"
                        >
                            Enable
                        </LoadingButton>
                    </div>
                    <Typography variant="h4" sx={{ textAlign: "center", my: 1.5 }}>
                        Stream analysis currently is off
                    </Typography>
                    <Typography sx={{ textAlign: "center" }}>Please turn it on by pressing the button above.</Typography>
                </>
            )}

            {isPageLoaded && isAnalysisOnRef.current && (
                <>
                    <div style={{ clear: "both", height: 65 }}>
                        {gotErrors && (
                            <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{
                                    timeout: 600
                                }}
                                placement="bottom"
                                title="There are some transport errors">
                                <NotificationsIcon sx={{ fontSize: "2.5rem", color: "red" }} />
                            </Tooltip>
                        )}

                        <Button
                            variant="contained"
                            name="turn_off_analysis"
                            onClick={(e) => {
                                e.preventDefault();
                                turnAnalysisOff(input_details.id);
                            }}
                            title="Turn off input analysis"
                            style={{ float: "right" }}>
                            Disable
                        </Button>

                        <Button
                            variant="toolbar"
                            onClick={() => resetInputAnalysis(input_details.id)}
                            style={{ float: "right", marginRight: 10 }}
                            startIcon={<RefreshIcon />}>
                            Reset Analysis Data
                        </Button>
                    </div>

                    {dataGroup2?.map((item: IInputAnalysisDataGroup2, i: number) => (
                        <div key={item.id} style={{ paddingBottom: "25px" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="h4" sx={{ my: 1 }}>{`Errors ${item.title}`}</Typography>
                                {showAlerts && i === 0 && (
                                    <FormControlLabel
                                        sx={{ width: 200 }}
                                        control={
                                            <Checkbox
                                                defaultChecked={p1_alerts_enabled}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        turnAnalysisOn(input_details.id, { raise_p1_alert: true });
                                                    } else {
                                                        turnAnalysisOn(input_details.id, { raise_p1_alert: false });
                                                    }
                                                }}
                                            />
                                        }
                                        label={"Enable P1 Alerts"}
                                    />
                                )}
                                {showAlerts && i === 1 && (
                                    <FormControlLabel
                                        sx={{ width: 200 }}
                                        control={
                                            <Checkbox
                                                defaultChecked={p2_alerts_enabled}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        turnAnalysisOn(input_details.id, { raise_p2_alert: true });
                                                    } else {
                                                        turnAnalysisOn(input_details.id, { raise_p2_alert: false });
                                                    }
                                                }}
                                            />
                                        }
                                        label={"Enable P2 Alerts"}
                                    />
                                )}
                            </Box>

                            <TableContainer component={Paper} className="liveLink" style={{ width: "99%" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="25%">Indicator</TableCell>
                                            <TableCell width="20%">Error count</TableCell>
                                            <TableCell width="15%">Last error time</TableCell>
                                            <TableCell>Error description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {item.table_rows.map((row: InputAnalysisDataGroup2Entry) => {
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell>{row.indicator}</TableCell>
                                                    <TableCell>{row.error_count}</TableCell>
                                                    <TableCell>{row.last_error_time}</TableCell>
                                                    <TableCell>{row.error_description}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    ))}

                    <div style={{ borderTop: "1px solid rgb(48, 122, 187)", height: 11, marginTop: 20 }}> </div>

                    <Typography variant="h4" sx={{ mt: 2 }}>
                        PID Information
                    </Typography>

                    {dataGroup1?.map((item: IInputAnalysisDataGroup1) => (
                        <div key={item.section_title} style={{ paddingBottom: "25px" }}>
                            {/* PROGRAM */}
                            <Box
                                sx={{ width: "100%", cursor: "pointer", display: "flex", alignItems: "center", mt: 1 }}
                                onClick={() => {
                                    toggleIsOpen(item.section_title);
                                }}>
                                {isOpen(item.section_title) ? (
                                    <KeyboardArrowUpIcon style={{ marginRight: 10 }} />
                                ) : (
                                    <KeyboardArrowDownIcon style={{ marginRight: 10 }} />
                                )}
                                <Typography variant="h5" fontWeight={500}>
                                    {item.section_title}
                                </Typography>
                            </Box>
                            <Collapse in={isOpen(item.section_title)} timeout="auto" unmountOnExit>
                                {item.entries.map((program_item: IInputAnalysisPrograms) => (
                                    <div key={program_item.id} id={program_item.id} style={{ paddingLeft: 26 }}>
                                        {/* SUB ITEM OF PROGRAM - LIKE "PMT PID: 5018 (0X139A)" */}

                                        <Box
                                            sx={{ width: "100%", cursor: "pointer", display: "flex", alignItems: "center", mt: 0.5 }}
                                            onClick={() => {
                                                toggleIsOpen(program_item.id);
                                            }}>
                                            {isOpen(program_item.id) ? (
                                                <KeyboardArrowUpIcon style={{ marginRight: 10 }} />
                                            ) : (
                                                <KeyboardArrowDownIcon style={{ marginRight: 10 }} />
                                            )}
                                            <Typography variant="h6">{program_item.section_title}</Typography>
                                        </Box>
                                        <Collapse in={isOpen(program_item.id)} timeout="auto" unmountOnExit>
                                            <TableContainer component={Paper} className="liveLink">
                                                <Table style={{ width: "450px", marginBottom: 30 }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Item</TableCell>
                                                            <TableCell>Value</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {program_item.entries.map((row: IInputAnalisysDataGroup1Entry) => (
                                                            <TableRow key={row.id}>
                                                                <TableCell>{row.title}</TableCell>
                                                                <TableCell>{row.value}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Collapse>
                                    </div>
                                ))}
                            </Collapse>
                        </div>
                    ))}
                </>
            )}
        </Box>
    );
};

export default SourceAnalysis;
