import React, { useContext } from "react";
import { IEnvironmentsData, RootStoreContext } from "api";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface IProps {
    environment: IEnvironmentsData;
}

export const EnvironmentUpgraded: React.FC<IProps> = ({ environment }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <>
            <DialogTitle>Environment upgrading</DialogTitle>
            <DialogContent>
                <Typography>Environment: {environment.name} has started updating.</Typography>
                <Typography>Please be patient, the system will notify you once the update is complete.</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeDialog();
                    }}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};
