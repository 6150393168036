import React, { useContext, useEffect } from "react";
import { Alert, AlertTitle, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Select } from "mui-rff";
import ErrorMessage from "app/common/form/ErrorMessage";
import { IChangeManagingOrganisationRequest, RootStoreContext } from "api";
import LoadingButton from "@mui/lab/LoadingButton";
import { useOrganisation } from "hooks";

const ManagingOrganisationForm = observer(function ManagingOrganisationForm() {
    const rootStore = useContext(RootStoreContext);
    const { getUser } = rootStore.userStore;
    const { managingOrgList } = rootStore.organisationStore;
    const { getManagingOrgList, setManagingOrganisation } = useOrganisation();

    useEffect(() => {
        if (!managingOrgList) {
            getManagingOrgList();
        }
    }, [getManagingOrgList, managingOrgList]);

    return (
        <FinalForm
            onSubmit={(values: IChangeManagingOrganisationRequest) =>
                setManagingOrganisation(values)
                    .then(() => {
                        getUser();
                    })
                    .catch((error) => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Select Organisation</DialogTitle>
                    <DialogContent>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            <AlertTitle>You are a member of multiple organisations.</AlertTitle>
                            Please select the organisation you wish to manage from the list below.
                        </Alert>
                        <Select
                            fullWidth
                            variant="standard"
                            name="organisation_id"
                            placeholder="Organisation"
                            label="Organisation"
                            required>
                            {managingOrgList?.data.map((orgList) => (
                                <MenuItem key={orgList.id} value={orgList.id}>
                                    {orgList.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            loading={submitting}
                            color="secondary"
                            variant="contained"
                            type="submit">
                            Manage
                            {
                                // add the label of the selected value to the button
                                managingOrgList?.data.map((orgList) => {
                                    if (orgList.id === values.organisation_id) {
                                        return " " + orgList.name;
                                    }
                                })
                            }
                        </LoadingButton>
                    </DialogActions>
                </form>
            )}
        />
    );
});

export default ManagingOrganisationForm;
