import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Stack,
    Switch,
    Box,
    Typography,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    Alert,
    IconButton,
    FormControlLabel
} from "@mui/material";
import { Form } from "react-final-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckboxField, MultilineTextField, RadioGroupField, SelectField, TextField } from "components/Forms";
import StepperForm from "components/Forms/StepperForm";
import { RenderGlossary } from "components";
import { FORM_ERROR } from "final-form";
import useGlossary from "hooks/UseGlossary";
import { outputTypes } from "app/common/options/zixiType";
import { SRTEncTypes, aesTypes } from "app/common/options/zixiAes";
import KeyIcon from "@mui/icons-material/Key";
import { agent, ITrasncoderReturnData, RootStoreContext, OutputFormValues } from "api";
import { toJS } from "mobx";
import { useEnvironment, usePermanentSchedule, useSources } from "hooks";
import { makeRandomKey } from "helpers";

export function getKeyFromString(input: string): string {
    try {
        if (typeof input !== "string") {
            throw new Error("Invalid input: Input should be a string");
        }

        const selectedType = outputTypes.find((type) => type.text === input);
        return selectedType ? selectedType.value : "";
    } catch (error) {
        console.error("Error in getKeyFromString:", error);
        return "";
    }
}

interface ICreateDestination {
    mode?: "create" | "edit" | "duplicate";
}

const CreateDestination: React.FC<ICreateDestination> = ({ mode = "create" }) => {
    const navigate = useNavigate();
    let location = useLocation();
    let { returnUrl } = location.state || { returnUrl: "/destinations/list-view" };
    const { id } = useParams();
    const package_id = id;
    const rootStore = useContext(RootStoreContext);
    const { activeEnvironment, getGeoInformation, geoData } = rootStore.userStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { isNotGuest } = rootStore.organisationStore;
    const { environment } = rootStore.environmentStore;
    const { permCommitments } = rootStore.permanentScheduleStore;
    const { getPermanentScheduleCommitments } = usePermanentSchedule();
    const { transcoderData } = rootStore.sourcesStore;
    const { getActiveEnvironment } = useEnvironment();
    const { getTranscoderData } = useSources();
    const { glossary } = useGlossary();
    const [state, setState] = useState({
        password: false,
        encryption: false,
        permanent: false
    });
    const [currency, setCurrency] = useState<string>("");
    const [initialValues, setInitialValues] = useState({
        latency: undefined,
        rtmp_stream: "",
        url: "",
        backup_url: "",
        reconnect: 5,
        bitrate: 0,
        ignore_tls_certificate_errors: false,
        send_time_code: false,
        host: "",
        auto_start: true,
        auto_stop: false,
        encryption_type: 0,
        type: ""
    });

    const getCurrency = () => {
        getGeoInformation().then(() => {
            if (geoData?.countryCode === "GB") {
                setCurrency("£");
            } else if (geoData?.countryCode === "US") {
                setCurrency("$");
            } else if (geoData?.countryCode === "EUR") {
                setCurrency("€");
            }
        });
    };

    useEffect(() => {
        getPermanentScheduleCommitments();

        if (activeEnvironment) {
            getActiveEnvironment(activeEnvironment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getGeoInformation().then((data) => {
            setTimeout(() => {
                if (data.countryCode === "GB") {
                    setCurrency("£");
                } else if (data.countryCode === "US") {
                    setCurrency("$");
                } else if (data.countryCode === "EUR") {
                    setCurrency("€");
                } else {
                    getCurrency();
                }
            }, 2000);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const agreements = permCommitments?.data
        .filter(
            (cp) => cp.cloud_provider === environment?.cloud_provider.toUpperCase() && cp.is_assigned !== true && cp.status === "active"
        )
        .map((agr) => {
            return {
                value: agr.agreement_id,
                text: `Reference: ${agr.agreement_ref_number}, Minimum Term: ${agr.minimum_commitment_days}days, Daily Cost: ${currency}${agr.daily_cost}`,
                key: agr.agreement_id
            };
        });

    const assignedAgreements = permCommitments?.data
        .filter((cp) => cp.cloud_provider === environment?.cloud_provider.toUpperCase() && cp.is_assigned === true)
        .map((agr) => {
            return {
                value: agr.agreement_id,
                text: `Reference: ${agr.agreement_ref_number}, Minimum Term: ${agr.minimum_commitment_days}days, Daily Cost: ${currency}${agr.daily_cost}`,
                key: agr.agreement_id
            };
        });

    const getInitialValues = async (id: string) => {
        if (activeEnvironment) {
            await agent.Destination.getIndividualDestination(id)
                .then((response) => {
                    // @ts-ignore
                    setInitialValues(new OutputFormValues({
                        ...response?.data?.technical_details?.livelink_args!,
                        id: response?.data?.technical_details?.livelink_args?.id && mode === "duplicate" ? "Copy of " + response?.data?.technical_details?.livelink_args?.id : response?.data?.technical_details?.livelink_args?.id ? response?.data?.technical_details?.livelink_args?.id : response?.data?.display_name,
                        type: getKeyFromString(response?.data?.human_type ? response?.data?.human_type : ""),
                    }));

                    setState({
                        ...state,
                        password: response?.data?.technical_details?.livelink_args?.password ? true : false,
                        encryption: response?.data?.technical_details?.livelink_args?.enc_key ? true : false,
                        permanent: response?.data?.is_permanently_scheduled ? true : false
                    });
                })
                .catch((error) => {
                    console.log("getInitialValues error: ", error);
                });
        }
    };

    // Fetch initial data
    useEffect(() => {
        if ((mode === "edit" || mode === "duplicate") && package_id) {
            getInitialValues(package_id);
        }
    }, [mode, package_id]);

    useEffect(() => {
        getTranscoderData();
    }, [getTranscoderData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const checkType = (selectedType: string, acceptedValues: string[]) => {
        return acceptedValues.includes(selectedType);
    };

    let associatedTranscoders: ITrasncoderReturnData[] = [];

    if (transcoderData && mode === "edit") {
        const unfilteredTranscoders = toJS(transcoderData).data;
        if (unfilteredTranscoders) {
            associatedTranscoders = unfilteredTranscoders.filter((transcoder: ITrasncoderReturnData) => transcoder.input_source_id === id);
        }
    }

    return (
        <Form
            onSubmit={async (values) => {
                if (mode === "edit") {
                    values.output_id = package_id;
                    return await agent.Destination.editDestination(values)
                        .then(() => {})
                        .catch((error) => {
                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                } else {
                    return await agent.Destination.createOutput(values)
                        .then(() => {})
                        .catch((error) => {
                            const errors = error.data.errors ? error.data.errors : undefined;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                }
            }}
            initialValues={initialValues}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors,
                dirty
            }) => {
                const initSteps = [
                    {
                        label: glossary.features.destinations.form.steps.name.title,
                        description: glossary.features.destinations.form.steps.name.description,
                        hasError: Boolean(submitErrors?.id || submitErrors?.user_description),
                        infoContent: (
                            <Stack spacing={2}>
                                <Typography>
                                    <b>Name</b> - <RenderGlossary term={glossary.features.destinations.form.fields?.name} />
                                </Typography>
                                <Typography>
                                    <b>Description</b> - <RenderGlossary term={glossary.features.destinations.form.fields?.description} />
                                </Typography>
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                <TextField
                                    name="id"
                                    label="Destination Name "
                                    disabled={mode === "edit"}
                                    required
                                    validate={(value) => {
                                        if (!value) {
                                            return "Name is required";
                                        }

                                        if (value.length > 128) {
                                            return "Name can not be above 128 characters";
                                        }
                                    }}
                                />
                                <MultilineTextField
                                    name="user_description"
                                    label="Destination Description"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                    rows={4}
                                    maxRows={8}
                                    validate={(value) => {
                                        if (value && value.length > 255) {
                                            return "Source Description must be less than 255 characters";
                                        }
                                    }}
                                />
                            </Stack>
                        )
                    },
                    {
                        label: glossary.features.sources.form.steps.type.title,
                        description: glossary.features.sources.form.steps.type.description,
                        hasError: Boolean(
                            submitErrors?.type ||
                                submitErrors?.port ||
                                submitErrors?.host ||
                                submitErrors?.latency ||
                                submitErrors?.stream_id ||
                                submitErrors?.remote_id ||
                                submitErrors?.local_port ||
                                submitErrors?.ttl ||
                                submitErrors?.smoothing ||
                                submitErrors?.send_time_code ||
                                submitErrors?.ignore_tls_certificate_errors ||
                                submitErrors?.url ||
                                submitErrors?.backup_url ||
                                submitErrors?.rtmp_stream ||
                                submitErrors?.user ||
                                submitErrors?.bitrate ||
                                submitErrors?.reconnect ||
                                submitErrors?.smoothing_ms ||
                                submitErrors?.enable_rtp ||
                                submitErrors?.agreement_id ||
                                submitErrors?.auto_start ||
                                submitErrors?.auto_stop
                        ),
                        infoContent: (
                            <Stack spacing={2}>
                                <Typography>
                                    <b>Type</b> - <RenderGlossary term={glossary.features.sources.form.fields?.type} />
                                </Typography>

                                {checkType(values.type, ["push"]) && (
                                    <Typography>
                                        <b>Port</b> - Zixi Push default is port 2088, set to the Zixi broadcaster or cloud environment push
                                        input port.
                                    </Typography>
                                )}

                                {checkType(values.type, ["pull"]) && (
                                    <Typography>
                                        <b>Port</b> - Zixi Pull default is port 2077, ensure the sending Zixi enabled device is able to call
                                        to a different port number before changing from default.
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp-rist", "udp"]) && (
                                    <Typography>
                                        <b>Port</b> - Remote port set on the receiving device.
                                    </Typography>
                                )}

                                {checkType(values.type, ["srt-push", "srt-pull"]) && (
                                    <Typography>
                                        <b>Port</b> - Local port in the platform to send the stream into from the sending device. Note*
                                        ports 2057 to 2077 and 2088 to 2110 are reserved for Zixi services only, please use a port outside
                                        of these ranges.
                                    </Typography>
                                )}

                                {checkType(values.type, ["push"]) && (
                                    <Typography>
                                        <b>Host</b> - The host IP or DNS name of the remote Zixi Broadcaster or cloud environment.
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp-rist", "srt-push", "udp"]) && (
                                    <Typography>
                                        <b>Host</b> - Remote IP or DNS name of the receiving device
                                    </Typography>
                                )}

                                {checkType(values.type, ["push", "pull", "srt-push", "srt-pull"]) && (
                                    <Typography>
                                        <b>Latency</b> - The end to end latency in miliseconds (ms). As a minimum the recommended value is
                                        3x the round trip time, the more time allowed the greater chance for packet recovery.
                                    </Typography>
                                )}

                                {checkType(values.type, ["push"]) && (
                                    <Typography>
                                        <b>Stream Id</b> - The Stream ID for the Destination.
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp-rist"]) && (
                                    <Typography>
                                        <b>Local Port</b> - Specify the local port used to source the stream, leave blank if not required.
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp-rist"]) && (
                                    <Typography>
                                        <b>TTl</b> - Specify the time-to-live, leave blank to allow the system to set.
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp-rist"]) && (
                                    <Typography>
                                        <b>Smoothing</b> - Enables transmission of the output at the correct rate. Required when the
                                        receiving device is sensitive and can’t lock on to the stream (in such a case, the recommended
                                        latency is 100 - 1000 ms)
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>Send Time Code</b> - Converts the MPEG-TS SEI section to RTMP ONFI command (pass the encoder
                                        timecodes to the RTMP server)
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>Ignore TLS Certificate Errors</b> - When selected the TLS certificate errors will be ignored and
                                        the platform will transmit the stream to its destination. Use only when streaming to a trusted
                                        server.
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>URL</b> - Specify the destination Stream URL according to the following format:
                                        rtmp://host:[port]/app. You can define RTMPS clients with the proven security of a secure socket
                                        layer (SSL), by using the rtmps:// url.
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>Backup URL</b> - Enter a backup URL (fallback mode) – will be used in case the primary server is
                                        not responsive.
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>Stream Name</b> - Also known as a stream key, the unique name for the stream that the RTMP server
                                        at the other end of the connection expects.
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>User Name</b> - The Username to authenticate on the remote RTMP server.
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>Bitrate (Kbps)</b> - The actual bitrate in Kbps or the maximum stream bitrate in case of a VBR
                                        stream, set to 0 for auto.
                                    </Typography>
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <Typography>
                                        <b>Reconnect</b> - The value of time between reconnection attempts (in seconds). Default: 5.
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp"]) && (
                                    <Typography>
                                        <b>Smoothing</b> - Enables transmission of the output at the correct rate. Required when the
                                        receiving device is sensitive and can’t lock on to the stream (in such a case, the recommended
                                        latency is 100 - 1000 ms)
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp"]) && (
                                    <Typography>
                                        <b>Enable RTP</b> - Would activate the Real-time Transport Protocol for streaming media, such as
                                        audio or video, from a source to a destination over IP networks.
                                    </Typography>
                                )}

                                {isNotGuest && (
                                    <Typography>
                                        <b>Permanent Destination</b> - Make this a permanent destination for 24/7 delivery.
                                    </Typography>
                                )}

                                {state.permanent && (
                                    <Typography>
                                        <b>Available Commitments</b> - Available commitments for permanent delivery.
                                    </Typography>
                                )}

                                {!state.permanent && (
                                    <>
                                        <Typography>
                                            <b>Auto Start</b> - Auto start ensures that the destination is started/turned on at the point
                                            when schedule starts.
                                        </Typography>

                                        <Typography>
                                            <b>Auto Stop</b> - Auto stop ensures that the destination is stopped/turned off at the point
                                            when schedule ends.
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                <RadioGroupField
                                    name="type"
                                    label="Select the Type"
                                    required
                                    row
                                    disabled={mode === "edit"}
                                    parse
                                    options={
                                        outputTypes?.map((item) => ({
                                            label: item.text,
                                            value: item.value
                                        })) || []
                                    }
                                    validate={(value) => {
                                        if (!value) {
                                            return "Type is required";
                                        }
                                    }}
                                    onChange={() => {
                                        values.latency = initialValues.latency;
                                        values.rtmp_stream = initialValues.rtmp_stream;
                                        values.url = initialValues.url;
                                        values.backup_url = initialValues.backup_url;
                                        values.reconnect = initialValues.reconnect;
                                        values.bitrate = initialValues.bitrate;
                                        values.ignore_tls_certificate_errors = initialValues.ignore_tls_certificate_errors;
                                        values.send_time_code = initialValues.send_time_code;
                                        values.host = initialValues.host;
                                        values.auto_start = initialValues.auto_start;
                                        values.auto_stop = initialValues.auto_stop;
                                        values.encryption_type = initialValues.encryption_type;
                                        values.local_port = undefined;
                                        values.ttl = undefined;
                                        values.smoothing = undefined;
                                        values.user = undefined;
                                    }}
                                />

                                {checkType(values.type, ["push", "pull", "udp-rist", "srt-push", "srt-pull", "udp"]) && (
                                    <TextField
                                        name="port"
                                        label="Port"
                                        parse
                                        required
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                        validate={(value) => {
                                            if (!value && value !== 0) {
                                                return "Port is required";
                                            }

                                            if (values.type === "pull" && !(value >= 2057 && value <= 2077) && value !== 2088) {
                                                return "Port must be between 2057 and 2077 or 2088";
                                            }

                                            if (
                                                (values.type === "udp-rist" || values.type === "srt-push" || values.type === "srt-pull") &&
                                                (!(value >= 1024 && value <= 65535) ||
                                                    (value >= 2057 && value <= 2077) ||
                                                    (value >= 2088 && value <= 2110))
                                            ) {
                                                return "Port must be between 1024 and 65535 and not between 2057 and 2077 or 2088 and 2110";
                                            }

                                            if (value < 1 || value > 65535) {
                                                return "Port must be between 1 and 65535";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["push", "udp-rist", "srt-push", "udp"]) && (
                                    <TextField
                                        required
                                        name="host"
                                        label="Host"
                                        validate={(value) => {
                                            if (!value) {
                                                return "Host is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["push", "pull", "srt-push", "srt-pull"]) && (
                                    <TextField
                                        required={values.type !== "pull"}
                                        name="latency"
                                        label="Latency"
                                        parse
                                        validate={(value) => {
                                            if (values.type !== "pull" && !value && value !== 0) {
                                                return "Latency is required";
                                            }

                                            if (
                                                value &&
                                                (values.type === "srt-pull" || values.type === "srt-push") &&
                                                (value < 0 || value > 600000)
                                            ) {
                                                return "Latency must be a positive number less than 600000";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["push", "pull"]) && (
                                    <TextField
                                        required
                                        name="stream_id"
                                        label="Stream Id"
                                        validate={(value) => {
                                            if (!value) {
                                                return "Stream Id is required";
                                            }

                                            if (value.length > 100) {
                                                return "Stream Id can not be above 100 characters";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["pull"]) && (
                                    <TextField
                                        required
                                        name="remote_id"
                                        label="Remote Id"
                                        validate={(value) => {
                                            if (!value) {
                                                return "Remote Id is required";
                                            }

                                            if (value.length > 100) {
                                                return "Remote Id can not be above 100 characters";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["udp-rist"]) && (
                                    <TextField
                                        name="local_port"
                                        label="Local Port"
                                        parse
                                        validate={(value) => {
                                            if (value && !(value >= 1024 && value <= 65535)) {
                                                return "Local Port must be between 1024 and 65535";
                                            }

                                            if (value && ((value >= 2057 && value <= 2077) || value === 2088)) {
                                                return "Local Port must not be between 2057 and 2077 or 2088";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["udp-rist"]) && (
                                    <TextField
                                        name="ttl"
                                        label="TTL"
                                        parse
                                        validate={(value) => {
                                            if (value && !(value >= 0 && value <= 225)) {
                                                return "TTL must be between 0 and 225";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["udp-rist"]) && <TextField name="smoothing" label="Smoothing" parse />}

                                {checkType(values.type, ["rtmp"]) && <CheckboxField name="send_time_code" label="Send Time Code" />}

                                {checkType(values.type, ["rtmp"]) && (
                                    <CheckboxField name="ignore_tls_certificate_errors" label="Ignore TLS Certificate Errors" />
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <TextField
                                        required
                                        name="url"
                                        label="URL"
                                        validate={(value) => {
                                            if (!value) {
                                                return "URL is required";
                                            }

                                            if (!value.startsWith("rtmps://") && !value.startsWith("rtmp://")) {
                                                return "URL must start with rtmp:// or rtmps://";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <TextField
                                        name="backup_url"
                                        label="Backup URL"
                                        validate={(value) => {
                                            if (value && !value.startsWith("rtmps://") && !value.startsWith("rtmp://")) {
                                                return "Backup URL must start with rtmp:// or rtmps://";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <TextField
                                        required
                                        name="rtmp_stream"
                                        label="Stream Name"
                                        validate={(value) => {
                                            if (!value) {
                                                return "Stream Name is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["rtmp"]) && <TextField name="user" label="User Name" />}

                                {checkType(values.type, ["rtmp"]) && (
                                    <TextField
                                        required
                                        name="bitrate"
                                        label="Bitrate (Kbps)"
                                        parse
                                        validate={(value) => {
                                            if (!value && value !== 0) {
                                                return "Bitrate is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["rtmp"]) && (
                                    <TextField
                                        required
                                        name="reconnect"
                                        label="Reconnect"
                                        parse
                                        validate={(value) => {
                                            if (!value && value !== 0) {
                                                return "Reconnect is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["udp"]) && (
                                    <TextField
                                        name="smoothing_ms"
                                        label="Smoothing"
                                        parse
                                        validate={(value) => {
                                            if (value && !(value >= 0)) {
                                                return "Smoothing must be greater or equal to 0";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["udp"]) && <CheckboxField name="enable_rtp" label="Enable RTP" />}

                                {isNotGuest && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.permanent}
                                                size="small"
                                                color="primary"
                                                name="permanent"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                inputProps={{
                                                    "aria-label": "primary checkbox"
                                                }}
                                            />
                                        }
                                        label="Make this a permanent destination for 24/7 delivery"
                                    />
                                )}

                                {state.permanent && (
                                    <Stack sx={{ mx: 4 }} spacing={2}>
                                        <Alert severity="warning">
                                            You have selected this to be a permanent destination which means the permanent scheduling of an
                                            individual source to a destination for the period outlined in your agreement selection below.{" "}
                                            <br />
                                            <br />
                                            You will not be able to control source, start or stop date/times as you can normally with
                                            destinations. <br />
                                            <br />
                                            Once created as a permanent destination you cannot change this setting, instead you will need to
                                            delete the destination and recreate it. <br />
                                            <br />
                                            To release or change the commitment you will also have to delete and recreate the destination.
                                        </Alert>
                                        {agreements && agreements.length > 0 && (
                                            <SelectField
                                                name="agreement_id"
                                                label="Available Commitments"
                                                required
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                                initialValue={
                                                    assignedAgreements?.find((agr) => agr.value === values.agreement_id)?.value || undefined
                                                }
                                                options={[
                                                    ...agreements,
                                                    ...(mode === "edit" && Array.isArray(assignedAgreements)
                                                        ? assignedAgreements.filter((item) => item.value === values.agreement_id)
                                                        : [])
                                                ]}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Agreement is required";
                                                    }
                                                }}
                                            />
                                        )}
                                    </Stack>
                                )}

                                {!state.permanent && (
                                    <>
                                        <CheckboxField name="auto_start" label="Auto Start" />

                                        <CheckboxField name="auto_stop" label="Auto Stop" />
                                    </>
                                )}
                            </Stack>
                        )
                    }
                ];

                if (checkType(values.type, ["push", "pull", "rtmp", "srt-push", "srt-pull"])) {
                    initSteps.push({
                        label: glossary.features.sources.form.steps.encryption.title,
                        description: glossary.features.sources.form.steps.encryption.description,
                        hasError: Boolean(submitErrors?.password || submitErrors?.enc_type || submitErrors?.enc_key),
                        infoContent: (
                            <Stack spacing={1}>
                                {checkType(values.type, ["push", "pull", "rtmp"]) && (
                                    <Typography>
                                        <b>Enable Password</b> -{" "}
                                        <RenderGlossary term={glossary.features.sources.form.fields?.enablePassword} />
                                    </Typography>
                                )}

                                {checkType(values.type, ["push", "pull", "srt-push", "srt-pull"]) && (
                                    <>
                                        <Typography>
                                            <b>Enable Encryption</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.enableDecryption} />
                                        </Typography>
                                        <Typography>
                                            <b>Encryption Type</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.decryptionType} />
                                        </Typography>
                                        <Typography>
                                            <b>Encryption Key</b> - Create an encryption key for the destination. This key is used to
                                            decrypt the destination.
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                {checkType(values.type, ["push", "pull", "rtmp"]) && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={state.password}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                    color="primary"
                                                    name="password"
                                                    inputProps={{
                                                        "aria-label": "primary checkbox"
                                                    }}
                                                />
                                            }
                                            label="Enable Password"
                                        />

                                        {state.password && values.type !== "udp-rist" && (
                                            <TextField
                                                name="password"
                                                label="Password"
                                                // validate={(value) => {
                                                //     if (value && (value.length < 10 || value.length > 80)) {
                                                //         return "Password must be between 10 and 80 characters";
                                                //     }
                                                // }}
                                            />
                                        )}
                                    </>
                                )}

                                {checkType(values.type, ["push", "pull", "srt-push", "srt-pull"]) && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={state.encryption}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (state.encryption) {
                                                            form.change("enc_type", undefined);
                                                            form.change("enc_key", undefined);
                                                        }
                                                    }}
                                                    color="primary"
                                                    name="encryption"
                                                    inputProps={{
                                                        "aria-label": "primary checkbox"
                                                    }}
                                                />
                                            }
                                            label="Enable Encryption"
                                        />

                                        {state.encryption && values.type !== "udp-rist" && (
                                            <>
                                                <SelectField
                                                    label="Encryption type"
                                                    required={state.encryption}
                                                    options={
                                                        values.type === "srt-pull" || values.type === "srt-push" ? SRTEncTypes : aesTypes
                                                    }
                                                    initialValue={
                                                        values.type === "srt-pull" || values.type === "srt-push"
                                                            ? SRTEncTypes[0].value
                                                            : aesTypes[0].value
                                                    }
                                                    name={
                                                        values.type === "srt-pull" || values.type === "srt-push"
                                                            ? "encryption_type"
                                                            : "enc_type"
                                                    }
                                                    placeholder="Select Encryption Type"
                                                    helperText={(meta) => meta.touched && meta.error}
                                                    error={(meta) => meta.touched && meta.error}
                                                    validate={(value) => {
                                                        if (!value) {
                                                            return "Encryption type is required";
                                                        }
                                                    }}
                                                />

                                                <Box sx={{ display: "flex" }}>
                                                    <TextField
                                                        name={
                                                            values.type === "srt-pull" || values.type === "srt-push"
                                                                ? "password"
                                                                : "enc_key"
                                                        }
                                                        label="Encryption Key"
                                                    />
                                                    <IconButton
                                                        disabled={!values.enc_type && !values.encryption_type}
                                                        onClick={(e: any) => {
                                                            e.preventDefault();
                                                            // TODO: Test random key
                                                            if (values.enc_type === "aes128" || values.encryption_type === 1) {
                                                                const key = makeRandomKey(32);
                                                                form.change(
                                                                    values.type === "srt-pull" || values.type === "srt-push"
                                                                        ? "password"
                                                                        : "enc_key",
                                                                    key
                                                                );
                                                            } else if (values.enc_type === "aes192" || values.encryption_type === 2) {
                                                                const key = makeRandomKey(48);
                                                                form.change(
                                                                    values.type === "srt-pull" || values.type === "srt-push"
                                                                        ? "password"
                                                                        : "enc_key",
                                                                    key
                                                                );
                                                            } else if (values.enc_type === "aes256" || values.encryption_type === 3) {
                                                                const key = makeRandomKey(64);
                                                                form.change(
                                                                    values.type === "srt-pull" || values.type === "srt-push"
                                                                        ? "password"
                                                                        : "enc_key",
                                                                    key
                                                                );
                                                            } else {
                                                                (document.getElementById("enc_type") as HTMLInputElement).style.display =
                                                                    "block";
                                                            }
                                                        }}
                                                        aria-label="Refresh Alerts">
                                                        <KeyIcon sx={{ fontSize: "2rem" }} />
                                                    </IconButton>
                                                </Box>
                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>
                        )
                    });
                }

                return (
                    <form onSubmit={handleSubmit}>
                        <StepperForm
                            formTitle={`${mode === "create" ? "Create" : mode === "edit" ? "Edit" : "Duplicate"} Destination`}
                            submitButtonText={`${mode === "create" ? "Create" : mode === "edit" ? "Edit" : "Duplicate"} Destination`}
                            steps={initSteps}
                            values={values}
                            invalid={invalid}
                            submitError={submitError}
                            showSuccessStep={submitSucceeded}
                            successStep={
                                <Stack spacing={1}>
                                    {mode === "edit" ? (
                                        <Typography>Your Destination has now been updated.</Typography>
                                    ) : (
                                        <Typography>Your Destination has now been created.</Typography>
                                    )}
                                </Stack>
                            }
                            onCancel={() => {
                                openDialog(
                                    <>
                                        <DialogTitle>Are you sure?</DialogTitle>
                                        <DialogContent>You have unsaved changes. Are you sure you want to leave this page?</DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    closeDialog();
                                                }}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    closeDialog();
                                                    navigate(returnUrl);
                                                }}>
                                                Leave
                                            </Button>
                                        </DialogActions>
                                    </>
                                );
                            }}
                            onFinish={() => navigate(returnUrl)}
                        />
                    </form>
                );
            }}
        />
    );
};
export default observer(CreateDestination);
