import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import ScheduleHeader from "./ScheduleHeader";
import { RootStoreContext } from "api";
import { isAfter, isEqual, startOfDay } from "date-fns";

interface ScheduleContainerProps {
    children: React.ReactNode;
    zoom: number;
}

const ScheduleContainer: React.FC<ScheduleContainerProps> = observer(function ScheduleContainer(props) {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const previousZoom = React.useRef<number>(props.zoom);
    const { date } = React.useContext(RootStoreContext).schedulerStore;

    const dateRef = React.useRef<Date>(date);

    const isToday = () => {
        const startDate = startOfDay(new Date(date));
        const today = startOfDay(new Date());

        return isEqual(startDate, today);
    };

    React.useEffect(() => {
        const selectedDate = startOfDay(new Date(date));
        const previousDate = startOfDay(new Date(dateRef.current));

        if (isToday()) {
            const today = new Date();
            const documentWidth = containerRef.current!.clientWidth;
            const minutesOfDay = today.getHours() * 60 + today.getMinutes();
            containerRef.current!.scrollLeft = minutesOfDay * props.zoom - documentWidth / 2 + 300;
        } else if (isAfter(selectedDate, previousDate)) {
            containerRef.current!.scrollLeft = 0;
            dateRef.current = date;
        } else if (isAfter(previousDate, selectedDate)) {
            containerRef.current!.scrollLeft = 1440 * props.zoom;
            dateRef.current = date;
        }
    }, [date]);

    React.useEffect(() => {
        //get the original scroll position at a scale of 1
        const scrollContainer = containerRef.current!;
        const originalScrollLeft = scrollContainer.scrollLeft;

        //adjust the scroll position to account for the change in scale
        const scrollAdjustment = previousZoom.current > props.zoom ? -200 : previousZoom.current < props.zoom ? 200 : 0;

        //get the original scroll position at the new scale
        const newScrollLeft = (originalScrollLeft / previousZoom.current) * props.zoom;

        //set the scroll position to the new scroll position
        scrollContainer.scrollLeft = newScrollLeft + scrollAdjustment;
        previousZoom.current = props.zoom;
    }, [props.zoom]);

    return (
        <Box ref={containerRef} sx={{ width: "100%", height: "100%", overflowY: "scroll" }}>
            <ScheduleHeader
                zoom={props.zoom}
                onScroll={(amount) => {
                    if (containerRef.current) {
                        containerRef.current.scrollLeft += -amount;
                    }
                }}
            />
            {props.children}
        </Box>
    );
});

export default ScheduleContainer;
