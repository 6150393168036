import { Box, LinearProgress, Typography } from "@mui/material";
import { RootStoreContext } from "api";
import React, { useContext, useEffect } from "react";

interface CreateTranscoderSuccessProps {
    source_id: string;
    mode: "create" | "edit";
    enable_motion_interpolation?: boolean;
    values: any;
}

const CreateTranscoderSuccess: React.FC<CreateTranscoderSuccessProps> = ({ source_id, mode, enable_motion_interpolation, values }) => {
    const rootStore = useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;

    const [wsData, setWsData] = React.useState<any>(null);
    const [updateDate, setUpdateDate] = React.useState<Date | null>(null);
    const [retries, setRetries] = React.useState<number>(0);

    const calculateProgress = (currentStep: number, totalSteps: number) => {
        if (currentStep === 0) {
            return 0;
        }
        return Math.floor((currentStep / totalSteps) * 100);
    };

    const getWsData = async (input_id: string) => {
        const rid = `environments.sources.${rootStore.userStore.managingOrganisationId}.${rootStore.userStore.activeEnvironment}.${rootStore.userStore.activeorganisationId}.source.${input_id}`;
        await client
            .get(rid)
            .then((res) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });
                setWsData(res);
            })
            .catch((err) => {
                console.log("Error", err);
                if (retries < 5) {
                    setTimeout(() => {
                        setRetries(retries + 1);
                        getWsData(input_id);
                    }, 1000);
                }
            });
    };

    useEffect(() => {
        getWsData(source_id);
    }, [source_id]);

    return (
        <Box>
            <Box>
                <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                    {mode === "create"
                        ? `Your ${enable_motion_interpolation ? "converter" : "transcoder"} is now being created`
                        : `Your ${enable_motion_interpolation ? "converter" : "transcoder"} is now updating`}
                </Typography>
                <Typography variant="h6" sx={{ mb: 3 }}>{`${enable_motion_interpolation ? "Converter" : "Transcoder"} Name: ${
                    values?.transcoded_input_name
                }`}</Typography>
                {mode === "create" && (
                    <Box mb={3}>
                        <LinearProgress
                            sx={{ borderRadius: 1, height: 10 }}
                            variant="determinate"
                            color={
                                wsData?.transcoder_number_of_steps > 0 &&
                                wsData?.transcoder_current_step === wsData?.transcoder_number_of_steps
                                    ? "success"
                                    : "primary"
                            }
                            value={calculateProgress(wsData?.transcoder_current_step || 0, wsData?.transcoder_number_of_steps || 0)}
                        />
                        <Typography variant="body2" mt={1}>
                            {wsData?.transcoder_progress_status} -{" "}
                            {calculateProgress(wsData?.transcoder_current_step || 0, wsData?.transcoder_number_of_steps || 0)}%
                        </Typography>
                    </Box>
                )}

                <Typography sx={{ mb: 1 }}>
                    {mode === "create"
                        ? `The new ${
                              enable_motion_interpolation ? "converter" : "transcoder"
                          } is being created with the required settings. Please be patient as this process may take a while.`
                        : `The ${
                              enable_motion_interpolation ? "converter" : "transcoder"
                          } is being updated with the required settings. Please be patient as this process may take a while.`}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    You can close this window and continue using the platform, you will be notified when the{" "}
                    {enable_motion_interpolation ? "converter" : "transcoder"} is ready.
                </Typography>
                <Typography>
                    You can make a change to the settings of the {enable_motion_interpolation ? "converter" : "transcoder"} when the
                    creation is complete and you have been notified that it is running.
                </Typography>
            </Box>
        </Box>
    );
};

export default CreateTranscoderSuccess;
