import { Alert, Box, CircularProgress, List, ListSubheader, useTheme } from "@mui/material";
import React from "react";
import NotificationsListItem from "./NotificationsListItem";
import { RootStoreContext } from "api";
import renameCategory from "../renameCategory";

const NotificationsList: React.FC = () => {
    const theme = useTheme();
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const { client, clientConnected } = rootStore.resClientStore;
    const managing_org_id = rootStore.userStore.managingOrganisationId;

    const [data, setData] = React.useState<any>([]);

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [loading, setLoading] = React.useState<boolean>(false);

    const groupedData = Object.entries(data).reduce((acc, [key, value]) => {
        const category = (value as { category: string }).category;
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push({ key, value });
        return acc;
    }, {});

    React.useEffect(() => {
        setLoading(true);
        const fetchData = () => {
            client
                .get(`unreadnotifications.${managing_org_id}`)
                .then((res: any) => {
                    setData(res.toJSON());
                    setLoading(false);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setData(res.toJSON());
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        };

        if (clientConnected) {
            fetchData();
        } else {
            const intervalId = setInterval(() => {
                if (clientConnected) {
                    clearInterval(intervalId);
                    fetchData();
                }
            }, 1000);
        }

        !clientConnected && rootStore.resClientStore.initialize(rootStore.commonStore.token!);
    }, [client, clientConnected, managing_org_id, rootStore.commonStore.token, updateDate]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <Box sx={{ position: "relative", minHeight: "100%" }}>
            {Object.entries(groupedData).length === 0 && (
                <Alert severity="info" sx={{ marginBottom: 0 }}>
                    No notifications
                </Alert>
            )}
            {!loading &&
                user?.user_preferences?.group_notifications &&
                Object.entries(groupedData)
                    .filter(([, items]) => {
                        // filter out items where "read" is true
                        return (items as Array<{ key: string; value: unknown }>).some(({ value }) => !(value as { read: boolean }).read);
                    })
                    .map(([category, items]) => (
                        <Box key={category}>
                            <List>
                                <ListSubheader
                                    sx={{
                                        backgroundColor: theme.palette.sidebarPopup?.main
                                    }}>
                                    {renameCategory(category)}
                                </ListSubheader>
                                {(items as Array<{ key: string; value: unknown }>)
                                    .filter(({ value }) => !(value as { read: boolean }).read)
                                    .sort((a, b) => {
                                        const aDate = new Date((a.value as { created_on: Date }).created_on);
                                        const bDate = new Date((b.value as { created_on: Date }).created_on);
                                        return bDate.getTime() - aDate.getTime();
                                    })
                                    .map(({ key, value }) => (
                                        <NotificationsListItem
                                            key={key}
                                            value={value}
                                            id={(value as { id: number }).id}
                                            onChange={() => {
                                                setUpdateDate(new Date());
                                            }}
                                        />
                                    ))}
                            </List>
                        </Box>
                    ))}

            {!loading &&
                !user?.user_preferences?.group_notifications &&
                Object.entries(data)
                    .filter(([, value]) => {
                        // filter out items where "read" is true
                        return !(value as { read: boolean }).read;
                    })
                    .sort(([, a], [, b]) => {
                        const aDate = new Date((a as { created_on: Date }).created_on);
                        const bDate = new Date((b as { created_on: Date }).created_on);
                        return bDate.getTime() - aDate.getTime();
                    })
                    .map(([key, value]) => (
                        <NotificationsListItem
                            key={key}
                            value={value}
                            id={(value as { id: number }).id}
                            onChange={() => {
                                setUpdateDate(new Date());
                            }}
                        />
                    ))}

            {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default NotificationsList;
