import { ExpandMore } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

interface FileNode {
    name: string;
    type: "file";
    path: string;
}

interface FolderNode {
    name: string;
    type: "folder";
    children?: Array<FolderNode | FileNode>;
}

type TreeNode = FolderNode | FileNode;

interface FolderProps {
    node: FolderNode;
    onPathSelect: (path: string) => void;
    currentPath: string;
    activePath: string;
}

const Folder: React.FC<FolderProps> = ({ node, onPathSelect, currentPath, activePath }) => {
    const [isOpen, setIsOpen] = useState(false);

    const hasSubFolders = node.children && node.children.some((child) => child.type === "folder");

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        onPathSelect(newPath); // Notify parent about the selected path
    };

    const newPath = currentPath ? `${currentPath}.${node.name}` : node.name;
    const isActive = "knowledge-base." + activePath === newPath.replace("knowledge-base.", "");

    return (
        <>
            <Box
                onClick={toggleOpen}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderRadius: 1,
                    backgroundColor: isActive ? (theme) => theme.palette.action.selected : "transparent",
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.action.hover
                    }
                }}>
                {hasSubFolders && (
                    <Box sx={{ width: "20px", height: "40px", display: "flex", alignItems: "center" }}>
                        <ExpandMore
                            sx={{
                                transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                                transition: "transform 0.1s"
                            }}
                        />
                    </Box>
                )}
                <Box>
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        {node.name}
                    </Typography>
                </Box>
            </Box>
            {isOpen && (
                <Box sx={{ pl: "20px" }}>
                    {node.children
                        ?.filter((child) => child.type === "folder")
                        .map((child) => (
                            <Folder
                                key={child.name}
                                node={child as FolderNode}
                                onPathSelect={onPathSelect}
                                currentPath={newPath}
                                activePath={activePath}
                            />
                        ))}
                </Box>
            )}
        </>
    );
};

interface FolderTreeProps {
    data: FolderNode;
    onPathSelect: (path: string) => void;
    activePath: string;
}

const FolderTree: React.FC<FolderTreeProps> = ({ data, onPathSelect, activePath }) => {
    return <Folder node={data} onPathSelect={onPathSelect} currentPath={data.name} activePath={activePath} />;
};

export default FolderTree;
