export const MPEGAAC = [
    "16 kbit/s",
    "24 kbit/s",
    "32 kbit/s",
    "40 kbit/s",
    "48 kbit/s",
    "56 kbit/s",
    "64 kbit/s",
    "72 kbit/s",
    "80 kbit/s",
    "88 kbit/s",
    "96 kbit/s",
    "104 kbit/s",
    "112 kbit/s",
    "120 kbit/s",
    "128 kbit/s",
    "136 kbit/s",
    "144 kbit/s",
    "152 kbit/s",
    "160 kbit/s",
    "168 kbit/s",
    "176 kbit/s",
    "184 kbit/s",
    "192 kbit/s",
    "200 kbit/s",
    "208 kbit/s",
    "216 kbit/s",
    "224 kbit/s",
    "232 kbit/s",
    "240 kbit/s",
    "248 kbit/s",
    "256 kbit/s",
    "264 kbit/s",
    "272 kbit/s",
    "280 kbit/s",
    "288 kbit/s",
    "296 kbit/s",
    "304 kbit/s",
    "312 kbit/s",
    "320 kbit/s",
    "328 kbit/s",
    "336 kbit/s",
    "344 kbit/s",
    "352 kbit/s",
    "360 kbit/s",
    "368 kbit/s",
    "376 kbit/s",
    "384 kbit/s",
    "392 kbit/s",
    "400 kbit/s",
    "408 kbit/s",
    "416 kbit/s",
    "424 kbit/s",
    "432 kbit/s",
    "440 kbit/s",
    "448 kbit/s",
    "456 kbit/s",
    "464 kbit/s",
    "472 kbit/s",
    "480 kbit/s",
    "488 kbit/s",
    "496 kbit/s",
    "504 kbit/s",
    "512 kbit/s"
];

export const MPEGLayer2Mono = ["32 kbit/s", "64 kbit/s", "96 kbit/s", "128 kbit/s", "160 kbit/s", "192 kbit/s"];

export const MPEGLayer2Stereo = [
    "64 kbit/s",
    "96 kbit/s",
    "128 kbit/s",
    "160 kbit/s",
    "192 kbit/s",
    "224 kbit/s",
    "256 kbit/s",
    "320 kbit/s",
    "384 kbit/s"
];

export const DolbyDigital1 = ["56 kbit/s", "64 kbit/s", "96 kbit/s", "192 kbit/s"];

export const DolbyDigital2 = ["96 kbit/s", "192 kbit/s", "640 kbit/s"];

export const DolbyDigital51 = ["256 kbit/s", "384 kbit/s", "640 kbit/s"];

export const DolbyDigitalPlus1 = ["32 kbit/s", "64 kbit/s", "96 kbit/s", "192 kbit/s"];

export const DolbyDigitalPlus2 = ["96 kbit/s", "128 kbit/s", "192 kbit/s", "768 kbit/s"];

export const DolbyDigitalPlus51 = ["192 kbit/s", "384 kbit/s", "768 kbit/s"];

export const DolbyDigitalPlus514 = ["384 kbit/s", "448 kbit/s", "768 kbit/s"];

export const DolbyDigitalPlus714 = ["384 kbit/s", "448 kbit/s", "768 kbit/s"];

export const DolbyAC4Stereo = [
    "48 kbit/s",
    "64 kbit/s",
    "96 kbit/s",
    "128 kbit/s",
    "144 kbit/s",
    "192 kbit/s",
    "256 kbit/s",
    "288 kbit/s",
    "320 kbit/s",
    "384 kbit/s",
    "448 kbit/s",
    "512 kbit/s",
    "768 kbit/s",
    "1024 kbit/s"
];

export const DolbyAC4Surround51 = [
    "48 kbit/s",
    "64 kbit/s",
    "96 kbit/s",
    "128 kbit/s",
    "144 kbit/s",
    "192 kbit/s",
    "256 kbit/s",
    "288 kbit/s",
    "320 kbit/s",
    "384 kbit/s",
    "448 kbit/s",
    "512 kbit/s",
    "768 kbit/s",
    "1024 kbit/s"
];
