import { Box, FormControlLabel, MenuItem, Switch } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { FormApi } from "final-form";
import { Select } from "mui-rff";
import { TextField } from "components/Forms";
import { agent, IPackagerPresetsResult } from "api";

interface ISaveAsPresetProps {
    values: any;
    form: FormApi<any, Partial<any>>;
}

const SaveAsPreset: React.FC<ISaveAsPresetProps> = ({ values, form }) => {
    const [presets, setPresets] = useState<IPackagerPresetsResult>();

    const [state, setState] = useState({ save: values.store_preset ?? false });

    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        form.change("store_preset", event.target.checked);
    };

    const getPresets = async () => {
        await agent.Packager.getPackagerPresets("pull")
            .then((response) => {
                console.log(response);
                if (response) {
                    setPresets(response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getPresets();
    }, []);

    return (
        <>
            <Box
                sx={{
                    marginTop: 4,
                    marginBottom: 2
                }}>
                <FormControlLabel
                    control={
                        <Switch
                            name="save"
                            checked={state.save}
                            onChange={(e) => {
                                handleStateChange(e);
                                if (!state.save) {
                                    form.change("preset_id", null);
                                    form.change("preset_name", null);
                                }
                            }}
                        />
                    }
                    label="Save as Preset"
                />
            </Box>

            {/* {state.save === false ? form.change("store_preset", false) : form.change("store_preset", true)} */}
            {state.save && (
                <>
                    <Box sx={{ mb: 2 }}>
                        <Select
                            name="preset_id"
                            label="Update an existing preset"
                            variant="standard"
                            placeholder="Select Preset to update"
                            renderValue={(value: any) => presets && presets.data.find((pre) => pre.id === value)?.name}>
                            <MenuItem
                                value=""
                                onClick={() => {
                                    form.change("preset_id", null);
                                    form.change("preset_name", null);
                                }}>
                                New Preset
                            </MenuItem>
                            {presets?.data.map((preset) => (
                                <MenuItem
                                    key={preset.id}
                                    value={preset.id}
                                    onClick={() => {
                                        form.change("preset_name", preset.name);
                                    }}>
                                    {preset.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <TextField
                        name="preset_name"
                        label="Save as a new preset"
                        initialValue={"Name your preset"}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        error={(meta: any) => {
                            return meta.touched && meta.error ? true : false;
                        }}
                        helperText={(meta) => {
                            return meta.touched && meta.error ? meta.error : "";
                        }}
                    />
                </>
            )}
        </>
    );
};

export default observer(SaveAsPreset);
