import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

//import { TextField } from "@mui/material";
//import { agent, IDasboardGroupUpdRequest, IDasboardGroupUpdResponse } from 'api';

type MtrGroupAccordionProps = {
    id: string;
    icon: IconName;
    name: string;
    children?: JSX.Element;
    defaultExpanded: boolean;
    active?: boolean;

    item_count: number;
    onDeleteClick?: () => void;
    hidden?: boolean;
    onHideClick?: () => void;
    disableActions?: boolean;
};

const MtrGroupAccordion: React.FC<MtrGroupAccordionProps> = ({
    id,
    icon,
    name,
    children,
    defaultExpanded,
    active,
    item_count,
    onDeleteClick,
    hidden,
    onHideClick,
    disableActions
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    const GroupAccordionStyles = {
        borderBottom: "3px solid rgba(160,160,160,0.02)"
    };

    const GroupAccordionHeaderStyles = {
        fontWeight: 400,
        padding: "5px 10px 5px 15px",
        backgroundColor: "rgba(160,160,160,0.02)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "11px",
        cursor: "pointer"
    };

    const GroupAccordionBodyStyles = {
        overflow: "hidden",
        height: expanded ? "auto" : "0px",
        fontSize: "12px"
    };

    return (
        <div style={GroupAccordionStyles} className={active ? "group-accordion active" : "group-accordion"}>
            <div style={GroupAccordionHeaderStyles} onClick={() => setExpanded(!expanded)}>
                <div>
                    <FontAwesomeIcon icon={["fal", expanded || active ? "folder-open" : "folder"]} />
                    &nbsp;&nbsp;{name} ({item_count})
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                    {!disableActions && item_count === 0 && (
                        <div style={{ marginRight: 10 }} onClick={onDeleteClick}>
                            <FontAwesomeIcon icon={["fal", "trash"]} />
                        </div>
                    )}
                    {!disableActions && (
                        <div style={{ marginRight: 10 }}>
                            <FontAwesomeIcon
                                title={hidden ? "Show on map" : "Hide on map"}
                                icon={["fal", hidden ? "eye-slash" : "eye"]}
                                onClick={() => (onHideClick ? onHideClick() : null)}
                            />
                        </div>
                    )}

                    <FontAwesomeIcon icon={["fal", expanded ? "chevron-up" : "chevron-down"]} onClick={() => setExpanded(!expanded)} />
                </div>
            </div>
            <div style={GroupAccordionBodyStyles}>{children}</div>
        </div>
    );
};

export default MtrGroupAccordion;
