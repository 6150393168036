import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";

interface IProps  {
    children: any;
    admin?: boolean;
    dev?: boolean;
    org_admin?: boolean;
    fallback?: React.ReactNode;
}

/**
 * A component that renders a private route based on user authentication and authorization.
 * @param {Object} props - The component props.
 * @param {React.ComponentType<RouteComponentProps<any>>} props.component - The component to render for the private route.
 * @param {boolean} [props.admin] - A flag indicating whether the user must be an admin to access the route.
 * @param {boolean} [props.dev] - A flag indicating whether the user must be a developer to access the route.
 * @param {boolean} [props.org_admin] - A flag indicating whether the user must be an organisation admin to access the route.
 * @param {React.ReactNode} [props.fallback] - The fallback component to render if the user is not authorized to access the route.
 * @returns {JSX.Element} The private route component.
 */

const PrivateRoute: React.FC<IProps> = observer(function PrivateRoute({ children, admin, dev, org_admin, fallback }) {
    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn, user } = rootStore.userStore;

    let isAuthorized = isLoggedIn;

    if (admin) {
        isAuthorized = isAuthorized && (user?.is_superuser || user?.is_accounts_admin || false);
    } 
    else if (org_admin) {
        isAuthorized = isAuthorized && (user?.managing_org_permission === "admin" || false);
    }
    else if (dev) {
        isAuthorized = isAuthorized && (user?.is_dev || false);
    }

    return isAuthorized ? children : fallback ? fallback : <></>;

});

export default PrivateRoute;
