import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import SilentNotificationsSwitch from "components/SilentNotificationsSwitch";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import DesktopNotificationsSwitch from "components/DesktopNotificationsSwitch";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import GroupNotificationsSwitch from "components/GroupNotificationsSwitch";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

interface Props {
    onClose?: () => void;
}

const NotificationsSettingsMenu: React.FC<Props> = ({ onClose }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        onClose && onClose();
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                size="small"
                onClick={(event) => {
                    handleClick(event);
                }}>
                <SettingsIcon fontSize="small" />
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
                sx={{
                    p: "0px !important",
                    "& .MuiMenu-paper": {
                        width: 300,
                        //p: "0px !important"
                    },
                    "& .MuiList-root": {
                        //pt: "0px !important"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    onMouseLeave: () => setAnchorEl(null)
                }}>
                <MenuItem>
                    <ListItemIcon>
                        <NotificationsPausedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Silence Notifications" />
                    <SilentNotificationsSwitch />
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                    <MarkUnreadChatAltIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Desktop Notifications" />
                    <DesktopNotificationsSwitch />
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <AutoAwesomeMotionIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Group By Type" />
                    <GroupNotificationsSwitch />
                </MenuItem>
            </Menu>
        </>
    );
};

export default NotificationsSettingsMenu;
