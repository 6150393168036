import React, { useEffect, useState } from "react";
import { agent } from "api";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, CircularProgress, Popover, Typography } from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideoPlayer from "features/multiviewers/Components/VideoPlayer";

type SourceThumbnailProps = {
    item_id: string;
    refresh?: boolean;
    enableFullScreen?: boolean;
    disableLoading?: boolean;
    multiviewerId?: string;
};

const SourceThumbnailStyles = {
    width: "auto",
    height: "100%",
    position: "absolute"
};

/**
 * A component that displays the thumbnail image in a 16:9 aspect ratio for a given item ID.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.item_id - The unique identifier for the item to display.
 * @param {boolean} [props.refresh] - Whether to refresh the thumbnail periodically.
 * @param {boolean} [props.enableFullScreen] - Whether to enable full screen mode for the thumbnail.
 * @param {boolean} [props.disableLoading] - Whether to disable the loading spinner.
 * @returns {JSX.Element} - A JSX element representing the component.
 */

const SourceThumbnail: React.FC<SourceThumbnailProps> = ({ item_id, refresh, enableFullScreen, disableLoading, multiviewerId }) => {
    const noThumbnailImage = require("../../../assets/img/no-thumbnail.png");
    const [thumbnailData, setThumbnailData] = useState<string>(noThumbnailImage);
    const [error, setError] = useState<string>("");
    const [streamStatus, setStreamStatus] = useState<string>("");

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        //e.stopPropagation();
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const MAX_RETRIES = 5;

    useEffect(() => {
        // This variable is used to keep track of whether the component is mounted or not
        let isMounted = true;

        // This function retrieves the thumbnail image for the given item ID
        const getThumbnail = async () => {
            try {
                // Call the API to get the thumbnail image data
                const response = await agent.Channel.getInputImage(item_id);
                // Convert the image data to a string and set it as the thumbnail data
                const encodedData = response.image_data ? response.image_data.toString() : noThumbnailImage;
                setThumbnailData(encodedData);
                // Clear any previous error messages
                setError("");
                // Set the stream status (if available)
                setStreamStatus(response.stream_status);

                // If the component is still mounted, schedule another call to getThumbnail after 5 seconds
                if (isMounted) {
                    setTimeout(() => {
                        getThumbnail();
                    }, 5000);
                }
            } catch (error: any) {
                // If there was an error getting the thumbnail image data, set the error message
                setError(error.data?.status ? error.data.status : "Error getting thumbnail image data");
                let retries = 0;
                // This function retries getting the thumbnail image data up to MAX_RETRIES times
                const retry = async () => {
                    retries++;
                    if (retries > MAX_RETRIES) {
                        return;
                    }
                    try {
                        // Call the API to get the thumbnail image data again
                        const response = await agent.Channel.getInputImage(item_id);
                        // Convert the image data to a string and set it as the thumbnail data
                        const encodedData = response.image_data ? response.image_data.toString() : noThumbnailImage;
                        setThumbnailData(encodedData);
                        // Clear any previous error messages
                        setError("");
                        // Set the stream status (if available)
                        setStreamStatus(response.stream_status);
                        // If the component is still mounted, schedule another call to getThumbnail after 5 seconds
                        if (isMounted) {
                            setTimeout(() => {
                                getThumbnail();
                            }, 5000);
                        }
                    } catch (error: any) {
                        // If there was an error getting the thumbnail image data again, set the error message and retry again after a delay
                        setError(error.data?.status ? error.data.status : "Error getting thumbnail image data");
                        setTimeout(retry, retries * 5000);
                    }
                };
                // Start the first retry after 10 seconds
                setTimeout(retry, 10000);
            }
        };

        // Call getThumbnail when the component mounts or when the item ID, noThumbnailImage, or refresh prop changes
        // first make sure the item_id is not null and not undefined
        if (item_id && item_id !== "null" && item_id !== "undefined") {
            getThumbnail();
        }
        //getThumbnail();

        // This function is called when the component unmounts to clear the thumbnail data and set isMounted to false
        return () => {
            isMounted = false;
            setThumbnailData("");
        };
    }, [item_id, noThumbnailImage, refresh]);

    return (
        <div
            style={{
                width: "100%",
                height: 0,
                paddingBottom: "56.25%",
                overflow: "hidden",
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                backgroundColor: "rgba(160,160,160,0.2)"
            }}>
            <Box component="img" src={thumbnailData} sx={SourceThumbnailStyles} />
            {enableFullScreen && streamStatus === "CONNECTED" && (
                <Box sx={{ position: "absolute", top: 10, left: 10 }}>
                    <Box
                        sx={{ width: 20, height: 20 }}
                        onMouseOver={(e) => {
                            e.stopPropagation();
                            handleClick(e);
                        }}>
                        <FullscreenIcon />
                    </Box>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        onMouseOut={handleClose}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose();
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        sx={{ marginTop: "5px", marginLeft: "5px" }}>
                        {multiviewerId && (
                            <Box 
                                sx={{
                                    width: 800,
                                    aspectRatio: "16/9",
                                    position: "relative",
                                }}
                            >
                                <VideoPlayer multiviewer_id={multiviewerId} />
                            </Box>
                        )}
                        {

                        !multiviewerId && (
                            <Box>
                                <Box component="img" src={thumbnailData} />
                            </Box>
                        )}
                    </Popover>
                </Box>
            )}
            {error === "not_yet_available" && !disableLoading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "translateY(-50%)"
                    }}>
                    <CircularProgress sx={{ marginBottom: 1 }} />
                    <Typography variant="caption" display="block">
                        Thumbnail generation started...
                    </Typography>
                </Box>
            )}
            {streamStatus && streamStatus === "NO-THUMBNAIL" && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "translateY(-50%)",
                        opacity: 0.5
                    }}>
                    <ImageNotSupportedIcon fontSize="large" sx={{ marginBottom: 1 }} />
                    <Typography variant="caption" display="block">
                        Thumbnail Unavailable
                    </Typography>
                </Box>
            )}
            {streamStatus && streamStatus === "NO-VIDEO" && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: "translateY(-50%)",
                        opacity: 0.5
                    }}>
                    <VideocamOffIcon fontSize="large" sx={{ marginBottom: 1 }} />
                    <Typography variant="caption" display="block">
                        No Video
                    </Typography>
                </Box>
            )}
        </div>
    );
};

export default SourceThumbnail;
