import React from "react";
import { Box, Button } from "@mui/material";
import { FileUploadRounded } from "@mui/icons-material";

export interface IFileUpload {
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileUpload: React.FC<IFileUpload> = ({ handleFileChange }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <input id="file" type="file" onChange={handleFileChange} accept="text/xml" style={{ display: "none" }} />
            <label htmlFor="file">
                <Button variant="contained" component="span" startIcon={<FileUploadRounded />}>
                    Choose a file
                </Button>
            </label>
        </Box>
    );
};

export default FileUpload;
