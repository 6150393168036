import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

interface Props {
    isValid?: boolean;
}

/**
 * Validation Icon is a React Functional Component that renders a validation icon (tick or cross).
 * It uses Material-UI icons.
 *
 * @component
 * @param {boolean} [props.isValid] - Whether to render the tick or cross icon.
 * @example
 * <ValidationIcon isValid={true} />
 *
 * @returns {ReactElement} The ValidationIcon component
 */

export const ValidationIcon: React.FC<Props> = ({
    isValid = false
}) => {

    if (isValid) {
        return <DoneIcon sx={{ color: '#00FF00'}} />
    }

    return <CloseIcon sx={{ color: '#FF0000'}} />
};

export default ValidationIcon;
