import { RadioGroupField, SelectField, TextField } from "components/Forms";
import { FormApi } from "final-form";
import React from "react";
import { useFormState } from "react-final-form";
import { pruneEmptyErrors } from "../../common/PruneEmptyErrors";

interface Props {
    outputGroupIndex: number;
    values: any;
    form: FormApi;
}

export const OutputGroupForm: React.FC<Props> = ({ outputGroupIndex, values, form }) => {
    const { submitErrors } = useFormState();

    return (
        <>
            <RadioGroupField
                name={`output_groups.${outputGroupIndex}.type`}
                label="Output Type"
                row
                options={[
                    { label: "DASH", value: "dash" },
                    { label: "HLS", value: "hls" },
                    { label: "HLS File", value: "hls-file" },
                    { label: "HLS-FMP4", value: "hls-fmp4" }
                ]}
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}
            />

            <TextField
                name={`output_groups[${outputGroupIndex}].number_of_segments_in_playlist`}
                label="Number of Segments in Playlist"
                type="number"
                required
                parse
                validate={(value) => {
                    if (!value && value !== 0) {
                        return "Required";
                    }
                }}
                onChange={() => {
                    if (submitErrors?.output_groups?.[outputGroupIndex]?.number_of_segments_in_playlist) {
                        delete submitErrors.output_groups[outputGroupIndex].number_of_segments_in_playlist;
                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                    }
                }}
            />

            <SelectField
                name={`output_groups[${outputGroupIndex}].segment_duration`}
                label="Segment Duration"
                options={Array.from({ length: 100 }, (_, i) => i + 1)
                    .filter((value) => value % Number(values?.global_track_configuration?.chunk_period.split("s")[0]) === 0)
                    .map((value) => ({
                        text: `${value}s`,
                        value: `${value}s`
                    }))}
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}
                onChange={() => {
                    if (submitErrors?.output_groups?.[outputGroupIndex]?.segment_duration) {
                        delete submitErrors.output_groups[outputGroupIndex].segment_duration;
                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                    }
                }}
            />
        </>
    );
};
