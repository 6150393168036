import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { agent, IFileClusterJob, IFileClusterJobs, RootStoreContext } from "api";
import {
    Box,
    Button as MuiButton,
    Checkbox,
    Chip,
    Fade,
    IconButton,
    Tooltip,
    Pagination,
    Table,
    TableHead,
    TableCell,
    TableSortLabel,
    TableBody,
    TableFooter,
    TableRow,
    DialogContent,
    DialogActions,
    Stack
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { format } from "date-fns";
import SearchInput from "components/SearchInput";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useFileCluster } from "hooks";

type FailedJobsProps = {
    deployment_id: string;
    pageSize: number;
};

const FailedJobs: React.FC<FailedJobsProps> = observer(function FailedJobs(props) {
    const rootStore = React.useContext(RootStoreContext);
    const { activeDeploymentStatus, failedJobsRequest, setFailedJobsSort, setFailedJobsOffset, setFailedJobsSearch } =
        rootStore.fileClusterStore;
    const { deleteJob } = useFileCluster();

    const [jobs, setJobs] = React.useState<IFileClusterJobs>();
    const defaultList = [0];
    const [selectedJobs, setSelectedJobs] = useState(defaultList);
    const { openDialog, closeDialog } = rootStore.dialogStore;

    const handleSelectAllFailedJobs = (e: any, data: any) => {
        setSelectedJobs(defaultList);
        const currentItems = new Set(selectedJobs);
        if (data === true && jobs) {
            jobs.data.forEach((item) => {
                currentItems.add(item.id);
            });
            setSelectedJobs(Array.from(currentItems));
        } else {
            setSelectedJobs(defaultList);
        }
        getJobs();
    };

    const handleRowSelect = (e: any, data: any, job_id: any) => {
        const currentItems = new Set(selectedJobs);
        if (data === true) {
            currentItems.add(job_id);
        } else {
            currentItems.delete(job_id);
        }
        setSelectedJobs(Array.from(currentItems));
    };

    const handleRestartJob = async (job_id: number) => {
        await agent.FileCluster.restartJob(props.deployment_id, job_id)
            .then(() => {
                //getPendingJobs(activeDeploymentId);
            })
            .catch((error) => {
                if (error.data?.message) {
                    openDialog(
                        <>
                            <DialogContent>{error.data.message}</DialogContent>
                            <DialogActions>
                                <MuiButton onClick={() => closeDialog()} color="primary">
                                    Close
                                </MuiButton>
                            </DialogActions>
                        </>,
                        "Error"
                    );
                }
            });
    };

    const getJobs = async () => {
        await agent.FileCluster.getJobsByDeployment(
            props.deployment_id,
            failedJobsRequest.offset,
            props.pageSize,
            failedJobsRequest.sort,
            failedJobsRequest.category,
            failedJobsRequest.source_filename
        ).then((response) => {
            setJobs(response);
        });
    };

    const handleSearch = (searchTerm: string) => {
        setFailedJobsSearch(searchTerm);
        getJobs();
    };

    const clearSearch = () => {
        setFailedJobsSearch("");
        getJobs();
    };

    React.useEffect(() => {}, [props.deployment_id, failedJobsRequest, setFailedJobsSort, setFailedJobsOffset, jobs, setFailedJobsSearch]);

    React.useEffect(() => {
        getJobs();

        const interval = setInterval(() => {
            getJobs();
        }, 5000);
        return () => {
            setFailedJobsOffset(0);
            setFailedJobsSort("id:desc");
            clearSearch();
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box className="tab-toolbar">
                <SearchInput
                    name="search"
                    label="Search Source Files..."
                    value={failedJobsRequest.source_filename}
                    onChange={handleSearch}
                    onClear={clearSearch}
                />
                <Box className="tab-toolbar-actions">
                    <h4>Selected Jobs ({selectedJobs.length - 1}): </h4>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Restart Selected Jobs">
                            <span>
                                <IconButton
                                    disabled={selectedJobs.length < 2 ? true : false}
                                    aria-label="restart"
                                    onClick={() => {
                                        openDialog(
                                            <ConfirmationDialog
                                                title="Restart Selected Jobs"
                                                confirmButtonColor="primary"
                                                confirmButtonText="Restart Jobs"
                                                message="Are you sure you want to restart the selected jobs?"
                                                onConfirm={async () => {
                                                    selectedJobs.forEach((item) => {
                                                        if (item > 0) {
                                                            handleRestartJob(item);
                                                        }
                                                    });
                                                    setSelectedJobs(defaultList);
                                                }}
                                            />
                                        );
                                    }}>
                                    <PlayArrowIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Selected Jobs">
                            <span>
                                <IconButton
                                    disabled={selectedJobs.length < 2 ? true : false}
                                    aria-label="delete"
                                    onClick={() => {
                                        openDialog(
                                            <ConfirmationDialog
                                                title="Delete Selected Jobs"
                                                confirmButtonColor="error"
                                                confirmButtonText="Delete Jobs"
                                                message="Are you sure you want to delete the selected jobs?"
                                                onConfirm={async () => {
                                                    selectedJobs.forEach((item) => {
                                                        if (item > 0) {
                                                            deleteJob(props.deployment_id, item);
                                                        }
                                                    });
                                                    setSelectedJobs(defaultList);
                                                }}
                                            />
                                        );
                                    }}>
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </Box>
            </Box>
            <Table>
                <TableHead>
                    <TableCell padding="checkbox">
                        <Checkbox
                            onChange={(e, data) => handleSelectAllFailedJobs(e, data)}
                            checked={selectedJobs.length - 1 === jobs?.data.length}
                        />
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={failedJobsRequest.sort === "name:asc" || failedJobsRequest.sort === "name:desc"}
                            direction={failedJobsRequest.sort === "name:asc" ? "asc" : "desc"}
                            onClick={() => {
                                failedJobsRequest.sort === "name:asc" ? setFailedJobsSort("name:desc") : setFailedJobsSort("name:asc");
                                getJobs();
                            }}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={failedJobsRequest.sort === "id:asc" || failedJobsRequest.sort === "id:desc"}
                            direction={failedJobsRequest.sort === "id:asc" ? "asc" : "desc"}
                            onClick={() => {
                                failedJobsRequest.sort === "id:asc" ? setFailedJobsSort("id:desc") : setFailedJobsSort("id:asc");
                                getJobs();
                            }}>
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell width={150}>Sent to Titan File at</TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={failedJobsRequest.sort === "start_time:asc" || failedJobsRequest.sort === "start_time:desc"}
                            direction={failedJobsRequest.sort === "start_time:asc" ? "asc" : "desc"}
                            onClick={() => {
                                failedJobsRequest.sort === "start_time:asc"
                                    ? setFailedJobsSort("start_time:desc")
                                    : setFailedJobsSort("start_time:asc");
                                getJobs();
                            }}>
                            Start Time
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Cores</TableCell>
                    <TableCell width={600}>Error</TableCell>
                    <TableCell></TableCell>
                </TableHead>
                <TableBody>
                    {jobs?.data.map((job: IFileClusterJob, i: number) => (
                        <TableRow key={i} selected={selectedJobs.includes(job.id)}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedJobs.includes(job.id)}
                                    //id={job.id}
                                    onChange={(e, data) => handleRowSelect(e, data, job.id)}
                                />
                            </TableCell>
                            <TableCell>
                                <div style={{ wordBreak: "break-all" }}>{job.name}</div>
                                <div
                                    style={{
                                        fontSize: "10px",
                                        opacity: "0.7"
                                    }}>
                                    Source: {job.source_filename}
                                </div>
                            </TableCell>
                            <TableCell>{job.id}</TableCell>
                            <TableCell>{job.sent_to_tf_at ? format(new Date(job.sent_to_tf_at), "dd/MM/yyyy @ HH:mm") : null}</TableCell>
                            <TableCell>{job.start_time ? format(new Date(job.start_time), "dd/MM/yyyy @ HH:mm") : null}</TableCell>
                            <TableCell>
                                <Chip
                                    label={job.status}
                                    size="small"
                                    style={{
                                        textTransform: "capitalize"
                                    }}
                                />
                            </TableCell>
                            <TableCell>{job.cores}</TableCell>
                            <TableCell style={{ fontSize: "12px", wordBreak: "break-word" }}>{job.errors}</TableCell>
                            <TableCell align="right">
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={1}>
                                        <Tooltip title="Restart Job">
                                            <IconButton
                                                disabled={props.deployment_id && activeDeploymentStatus === "running" ? false : true}
                                                aria-label="restart"
                                                onClick={() => {
                                                    handleRestartJob(job.id);
                                                }}>
                                                <PlayArrowIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete Job">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    openDialog(
                                                        <ConfirmationDialog
                                                            title="Delete Job"
                                                            confirmButtonColor="error"
                                                            confirmButtonText="Delete Job"
                                                            message={`Are you sure you want to delete job '${job.name}'?`}
                                                            onConfirm={async () => {
                                                                deleteJob(props.deployment_id, job.id);
                                                            }}
                                                        />
                                                    );
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {jobs && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={9}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <Pagination
                                        shape="rounded"
                                        count={Math.ceil(jobs?.totals.failed / props.pageSize)}
                                        page={failedJobsRequest.offset / props.pageSize + 1}
                                        onChange={(event: any, page: number) => {
                                            setFailedJobsOffset((page - 1) * props.pageSize);
                                            getJobs();
                                        }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
});

export default FailedJobs;
