import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "api";
import { TextField, Checkboxes } from "mui-rff";
import { observer } from "mobx-react-lite";
import { Alert, DialogContent, DialogTitle, Typography } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { useEnvironment } from "hooks";

interface IProps {
    name: string;
    id: string;
    setShowIP: (value: boolean) => void;
    showIp: boolean;
}

const ReserveIpContent: React.FC<IProps> = observer(function ReserveIpContent({ name, setShowIP, showIp }) {
    const rootStore = useContext(RootStoreContext);
    const { setIPName, allEnvironments } = rootStore.environmentStore;
    const { getEnvironmentsDetails } = useEnvironment();
    const handleChange = (name: string) => {
        setIPName(name);
    };

    const savedIPAdd = allEnvironments?.map((ipAdd) => ipAdd.store_public_ip === true);

    useEffect(() => {
        getEnvironmentsDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DialogTitle>Confirm Environment Deletion</DialogTitle>

            <DialogContent>
                <Typography>
                    Environment: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{name.toUpperCase()}</span>
                </Typography>
                <Alert severity="warning" style={{ marginTop: 10, marginBottom: 30 }}>
                    <Typography gutterBottom>Deleting this environment is permanent, you cannot recover your settings.</Typography>
                    <Typography>
                        The environment will be destroyed any channels or destination equipment will require repointing to a new IP/DNS
                        address.
                    </Typography>
                </Alert>

                {savedIPAdd && (
                    <>
                        <p>The IP/DNS name will also be released, check the box below to save IP.</p>

                        <>
                            <Checkboxes
                                name="preserve_ip_when_deleting_environment"
                                color="primary"
                                onClick={() => {
                                    setShowIP(!showIp);
                                }}
                                data={{ label: "Reserve IP address after deletion", value: undefined }}
                            />
                        </>
                        {showIp && (
                            <>
                                <TextField
                                    fullWidth
                                    required
                                    name="preserved_ip_name"
                                    id="name"
                                    placeholder="Reserved IP address name"
                                    label="Reserved IP address name"
                                    color="primary"
                                    type="text"
                                    variant="standard"
                                    fieldProps={{ variant: "standard" }}
                                />
                                <OnChange name="preserved_ip_name">
                                    {(value) => {
                                        handleChange(value);
                                    }}
                                </OnChange>
                                <div style={{ color: "#ffffff" }}>
                                    Note* Reserved IP addresses have a cost associated when not attached to an enviroment. Please navigate
                                    to settings to release this IP once the enviroment is deleted if no longer required.
                                </div>
                            </>
                        )}
                    </>
                )}
            </DialogContent>
        </>
    );
});

export default ReserveIpContent;
