import React, { useContext } from "react";
import SourcePermissions from "./sourcePermissions/SourcePermissions";
import Organisations from "./organisations/Organisations";
import OrganisationsToolbar from "./organisations/OrganisationsToolbar";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import Content from "components/Layout/Content";
import { Box } from "@mui/material";

export const SettingsDashboard = observer(function SettingsDashboard() {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const location = useLocation();

    const base_url_string = document.location.href.substring(document.location.href.lastIndexOf("/") + 1);

    const renderSwitch = (param: any) => {
        switch (param) {
            case "sourcepermissions":
                return <SourcePermissions />;
            case "organisations":
                return <Organisations />;
            default:
                return <Organisations />;
        }
    };

    const renderTitle = (param: any) => {
        switch (param) {
            case "filecluster":
                return "File Cluster";
            case "sourcepermissions":
                return "Source Permissions";
            case "organisations":
                return "My Organisation";
            case "alertsmanagement":
                return "Alerts Management";
            default:
                return "Alerts Management";
        }
    };

    const renderSubtitle = (param: any) => {
        switch (param) {
            case "organisations":
                return user?.active_org_name;
            default:
                return undefined;
        }
    };

    const renderToolbar = (param: any) => {
        switch (param) {
            case "organisations":
                return <OrganisationsToolbar />;
            default:
                return null;
        }
    };

    React.useEffect(() => {}, [location]);

    return (
        <View>
            <ViewHeader title={renderTitle(base_url_string)} subtitle={renderSubtitle(base_url_string)}></ViewHeader>
            <ViewBody noPadding>
                <Content
                    headerContent={
                        renderToolbar(base_url_string) ? <Box sx={{ px: 2, py: 1 }}>{renderToolbar(base_url_string)}</Box> : null
                    }
                    noPadding={base_url_string === "environments"}>
                    {renderSwitch(base_url_string)}
                </Content>
            </ViewBody>
        </View>
    );
});

export default SettingsDashboard;
