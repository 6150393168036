import { Alert, Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext } from "react";

interface ConfirmUpgradeProps {
    cluster_id: string;
    cluster_name: string;
}

const ConfirmUpgrade: React.FC<ConfirmUpgradeProps> = ({ cluster_id, cluster_name }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const [error, setError] = React.useState<any>();

    const handleUpgrade = async () => {
        await agent.FileCluster.upgradeDeployment(cluster_id, {})
            .then((response) => {
                console.log(response);
                closeDialog();
            })
            .catch((error) => {
                console.log(error);
                setError(error);
            });
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>Are you sure you want to upgrade {cluster_name} to the latest version? This process could take more than 40 minutes.</DialogContentText>
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error.data?.message}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleUpgrade();
                    }}
                    color="secondary"
                    variant="contained">
                    Upgrade
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmUpgrade;
