import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    Divider,
    Fade,
    IconButton,
    Stack,
    Step,
    StepButton,
    StepContent,
    StepLabel,
    Stepper,
    Tooltip,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import SidebarTabs, { SidebarTab, SidebarTabPanel } from "components/SidebarTabs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import DataObjectIcon from "@mui/icons-material/DataObject";
import BugReportIcon from "@mui/icons-material/BugReport";
import RoleProvider from "../../RoleProvider";
import { useForm } from "react-final-form";
import MDEditor from "@uiw/react-md-editor";
import { KnowledgeHubMarkdown } from "features/knowledgeHub/Components";
import { IGlossaryLookupTerm, useConstants, useGlossary } from "hooks";
import { ContentCopy, DocumentScanner, ExpandMore } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";
import EmailProvider from "components/EmailProvider";

interface StepperFormProps {
    formTitle: string;
    values: any; //react final form
    invalid: boolean; //react final form
    submitting?: boolean; //react final form
    submitError: any; //react final form
    steps: FormStep[];
    successStep: React.ReactNode;
    showSuccessStep: boolean;
    successTabContent?: React.ReactNode;
    successTabTitle?: string;
    submitButtonText?: string;
    nextButtonText?: string;
    onFinish: () => void;
    onCancel?: () => void;
    onBack?: () => void;
    onNext?: () => void;
    mode?: "create" | "edit";
    includeGlossary?: boolean;
}

interface FormStep {
    label: string;
    description: string;
    content: React.ReactNode;
    infoContent: React.ReactNode;
    hasError?: boolean;
    hidden?: boolean;
    helpText?: string;
}

/**
 * `StepperForm` is a React component that renders a multi-step form using Material-UI components. 
 * It supports dynamic form steps, navigation between steps, handling form submission, and displaying success or error messages. 
 * It integrates with `react-final-form` for form state management and validation. The component also features a sidebar for additional information 
 * and actions specific to each step. Developers can customize the form steps, submission button text, and actions for finish, cancel, back, 
 * and next events. The component is designed to be flexible for both creation and editing modes.

 * @param {Object} props The props for the StepperForm component.
 * @param {string} props.formTitle Title of the form.
 * @param {Object} props.values Values of the form fields, managed by react-final-form.
 * @param {boolean} props.invalid Boolean flag indicating if the form is invalid.
 * @param {boolean} [props.submitting] Boolean flag indicating if the form submission is in progress.
 * @param {Object} props.submitError Error object for submission errors.
 * @param {Array<Object>} props.steps Array of step objects that define the label, description, content, and error state for each step.
 * @param {React.ReactNode} props.successStep React node displayed upon successful submission.
 * @param {boolean} props.showSuccessStep Boolean flag to display the success step.
 * @param {React.ReactNode} [props.successTabContent] Content to display in the success tab of the sidebar.
 * @param {string} [props.successTabTitle] Title of the success tab in the sidebar.
 * @param {string} [props.submitButtonText] Text for the submit button.
 * @param {string} [props.nextButtonText] Text for the next button.
 * @param {Function} props.onFinish Callback function called when the form is successfully submitted.
 * @param {Function} [props.onCancel] Callback function called when the cancel button is clicked.
 * @param {Function} [props.onBack] Callback function called when the back button is clicked.
 * @param {Function} [props.onNext] Callback function called when the next button is clicked.
 * @param {"create" | "edit"} [props.mode] Mode of the form, allowing for different behaviors in creation or editing scenarios.
 * @param {boolean} [props.includeGlossary] Boolean flag to include a glossary in the sidebar.

 * @example
 * ```
 * const formSteps = [
 *   {
 *     label: "Step 1",
 *     description: "Description of Step 1",
 *     content: <div>Step 1 Content</div>,
 *     infoContent: <div>Additional Info for Step 1</div>,
 *   },
 *   {
 *     label: "Step 2",
 *     description: "Description of Step 2",
 *     content: <div>Step 2 Content</div>,
 *     infoContent: <div>Additional Info for Step 2</div>,
 *   }
 * ];
 *
 * <StepperForm
 *   formTitle="Example Form"
 *   values={{}}
 *   invalid={false}
 *   submitting={false}
 *   submitError={null}
 *   steps={formSteps}
 *   successStep={<div>Success!</div>}
 *   showSuccessStep={false}
 *   onFinish={() => console.log("Form Submitted")}
 *   onCancel={() => console.log("Cancelled")}
 *   onBack={() => console.log("Back")}
 *   onNext={() => console.log("Next")}
 *   mode="create"
 * />
 * ```
 */

const StepperForm: React.FC<StepperFormProps> = ({
    formTitle,
    values,
    invalid,
    submitting,
    submitError,
    steps,
    successStep,
    showSuccessStep,
    successTabContent,
    successTabTitle,
    submitButtonText,
    nextButtonText,
    onFinish,
    onCancel,
    onBack,
    onNext,
    mode,
    includeGlossary = false
}) => {
    const form = useForm();
    const [activeStep, setActiveStep] = React.useState(0);
    const [collapseSidebar, setCollapseSidebar] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [glossaryData, setGlossaryData] = React.useState<string[]>([]);
    const { glossary, getFields } = useGlossary();
    const [fields, setFields] = React.useState<IGlossaryLookupTerm[]>([]);
    const { constants } = useConstants();

    useEffect(() => {
        const fields = getFields();
        setFields(fields);
    }, []);

    const scrollToTop = () => {
        const contentElement = document.getElementById("content");
        if (contentElement) {
            contentElement.scrollTop = 0;
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        onNext && onNext();
        scrollToTop();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        onBack && onBack();
        scrollToTop();
    };

    const handleReset = () => {
        setActiveStep(0);
        scrollToTop();
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Enter" && !event.shiftKey && (event.target as HTMLElement).localName !== "textarea") {
                event.preventDefault();
                if (invalid && !submitError) {
                    return;
                } else if (activeStep === steps.length - 1) {
                    if (!showSuccessStep) {
                        form.submit();
                    }
                } else {
                    handleNext();
                }
            }
        };

        document.body.addEventListener("keydown", handleKeyDown);

        return () => {
            document.body.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleNext, invalid, submitError]);

    //useEffect for submitError
    React.useEffect(() => {}, [submitError]);
    return (
        <View>
            <ViewHeader title={formTitle} subtitle={"Step " + (activeStep + 1) + " of " + steps.length + " - " + steps[activeStep].label} />
            <ViewBody noPadding>
                <Content
                    leftContent={
                        <Box sx={{ p: 2 }}>
                            <Stepper activeStep={activeStep} orientation="vertical" nonLinear={mode === "edit"}>
                                {steps
                                    .filter((step) => !step.hidden)
                                    .map((step, index) => (
                                        <Step key={index}>
                                            {mode === "edit" ? (
                                                step.hasError ? (
                                                    <StepLabel sx={{ cursor: "pointer" }} error onClick={() => setActiveStep(index)}>
                                                        {step.label}
                                                    </StepLabel>
                                                ) : (
                                                    <StepButton onClick={() => setActiveStep(index)}>
                                                        <StepLabel
                                                            StepIconProps={
                                                                {
                                                                    //completed: true
                                                                }
                                                            }>
                                                            {step.label}
                                                        </StepLabel>
                                                    </StepButton>
                                                )
                                            ) : (
                                                <StepLabel error={step.hasError}>{step.label}</StepLabel>
                                            )}
                                            <StepContent>
                                                <Typography>{step.description}</Typography>
                                            </StepContent>
                                        </Step>
                                    ))}
                                {showSuccessStep && (
                                    <Step completed>
                                        <StepLabel>Finished</StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                )}
                                {submitError && (
                                    <Step>
                                        <StepLabel error>Error</StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                )}
                            </Stepper>
                        </Box>
                    }
                    rightContent={
                        <>
                            <SidebarTabs
                                tabs={
                                    <>
                                        <SidebarTab
                                            label="Information"
                                            icon={<InfoIcon />}
                                            active={!collapseSidebar && activeTab === 0}
                                            onClick={() => {
                                                setActiveTab(0);
                                                setCollapseSidebar(false);
                                            }}
                                        />
                                        <RoleProvider roles={["is_dev"]}>
                                            <>
                                                <SidebarTab
                                                    label="Values"
                                                    icon={<DataObjectIcon />}
                                                    active={!collapseSidebar && activeTab === 1}
                                                    onClick={() => {
                                                        setActiveTab(1);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                                <SidebarTab
                                                    label="Submit Errors"
                                                    icon={<BugReportIcon />}
                                                    active={!collapseSidebar && activeTab === 2}
                                                    onClick={() => {
                                                        setActiveTab(2);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                            </>
                                        </RoleProvider>
                                        <EmailProvider acceptedEmails={constants.cerberus.emails.frontend}>
                                            {/* TESTING: Move out of EmailProvider once ready */}
                                            {includeGlossary && (
                                                <SidebarTab
                                                    label="Glossary"
                                                    icon={<DocumentScanner />}
                                                    active={!collapseSidebar && activeTab === 4}
                                                    onClick={() => {
                                                        setActiveTab(4);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                            )}
                                        </EmailProvider>
                                    </>
                                }
                                panels={
                                    <>
                                        <SidebarTabPanel active={activeTab === 0}>
                                            {!showSuccessStep && (
                                                <Box sx={{ p: 2, height: "100%", maxWidth: 283, overflow: "auto" }}>
                                                    <Typography variant="h5">
                                                        Step {activeStep + 1}: {steps[activeStep].label}
                                                    </Typography>
                                                    <Divider sx={{ my: 2 }} />
                                                    {steps[activeStep].infoContent}
                                                </Box>
                                            )}
                                            {showSuccessStep && successTabContent && (
                                                <Box sx={{ p: 2, height: "100%", maxWidth: 283, overflow: "auto" }}>
                                                    <Typography variant="h5">{successTabTitle ? successTabTitle : "Success"}</Typography>
                                                    <Divider sx={{ my: 2 }} />
                                                    {successTabContent}
                                                </Box>
                                            )}
                                        </SidebarTabPanel>
                                        <RoleProvider roles={["is_dev"]}>
                                            <>
                                                <SidebarTabPanel active={activeTab === 1}>
                                                    <Box sx={{ p: 2, height: "100%", maxWidth: 283, overflow: "auto" }}>
                                                        <Typography variant="h5">Form Values</Typography>
                                                        <Divider sx={{ my: 2 }} />
                                                        <pre style={{ fontSize: 12 }}>{JSON.stringify(values, null, 2)}</pre>
                                                    </Box>
                                                </SidebarTabPanel>
                                                <SidebarTabPanel active={activeTab === 2}>
                                                    <Box sx={{ p: 2, height: "100%", maxWidth: 283, overflow: "auto" }}>
                                                        <Typography variant="h5">Submit Errors</Typography>
                                                        <Divider sx={{ my: 2 }} />
                                                        {
                                                            //@ts-ignore
                                                            submitError?.data?.message && (
                                                                <Alert severity="error" sx={{ mb: 2, mt: 4 }}>
                                                                    <AlertTitle>An Error Occurred</AlertTitle>
                                                                    {submitError?.data?.message}
                                                                </Alert>
                                                            )
                                                        }
                                                        <pre style={{ fontSize: 12 }}>
                                                            {JSON.stringify(submitError?.data?.errors, null, 2)}
                                                        </pre>
                                                        <pre style={{ fontSize: 12 }}>
                                                            {JSON.stringify(form.getState().submitErrors, null, 2)}
                                                        </pre>
                                                    </Box>
                                                </SidebarTabPanel>
                                            </>
                                        </RoleProvider>
                                        <EmailProvider acceptedEmails={constants.cerberus.emails.frontend}>
                                            {includeGlossary && (
                                                <SidebarTabPanel active={activeTab === 4}>
                                                    <Box sx={{ p: 2, height: "100%", maxWidth: 283, overflow: "auto" }}>
                                                        <Typography variant="h5">Glossary</Typography>
                                                        <Divider sx={{ my: 2 }} />
                                                        <Stack spacing={1}>
                                                            {fields.map((field, index) => (
                                                                <Accordion key={index}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMore />}
                                                                        aria-controls={`panel${index}-content`}
                                                                        id={`panel${index}-header`}>
                                                                        {/* <CopyToClipboard
                                                                            text={'contact.email'}
                                                                            // onCopy={() => {
                                                                            //     setEmailCopied(true);
                                                                            // }}
                                                                        >
                                                                            <Tooltip
                                                                                TransitionComponent={Fade}
                                                                                TransitionProps={{
                                                                                    timeout: 600
                                                                                }}
                                                                                placement="bottom"
                                                                                title="Copy to clipboard">
                                                                                <Box alignItems={"center"} sx={{ display: "flex" }}>
                                                                                    <IconButton aria-label="Copy to clipboard">
                                                                                        <ContentCopy />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </CopyToClipboard> */}
                                                                        {field.label}
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>{field.value}</AccordionDetails>
                                                                </Accordion>
                                                            ))}
                                                        </Stack>
                                                    </Box>
                                                </SidebarTabPanel>
                                            )}
                                        </EmailProvider>
                                    </>
                                }
                                collapsible={true}
                                collapsed={collapseSidebar}
                                onCollapse={() => setCollapseSidebar(!collapseSidebar)}
                            />
                        </>
                    }
                    rightWidth={collapseSidebar ? 51 : 335}
                    footerContent={
                        <Box sx={{ p: 0, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {!showSuccessStep && onCancel && <Button onClick={onCancel}>Cancel</Button>}
                            <Box></Box>
                            <Stack direction="row" spacing={2}>
                                {!showSuccessStep && activeStep !== 0 && <Button onClick={handleBack}>Back</Button>}
                                {!showSuccessStep && activeStep !== steps.length - 1 && (
                                    <Button disabled={invalid && !submitError} variant="contained" onClick={handleNext}>
                                        {nextButtonText ? nextButtonText : "Next"}
                                    </Button>
                                )}
                                {!showSuccessStep && activeStep === steps.length - 1 && (
                                    <LoadingButton
                                        loading={submitting}
                                        type="submit"
                                        disabled={invalid && !submitError}
                                        variant="contained">
                                        {submitButtonText ? submitButtonText : "Submit"}
                                    </LoadingButton>
                                )}
                                {showSuccessStep && (
                                    <Button variant="contained" onClick={onFinish}>
                                        Finished
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                    }>
                    <>
                        {!showSuccessStep && (
                            <Box id="header">
                                <Box sx={{ mb: 4 }}>
                                    <Typography variant="h3">{steps[activeStep].label}</Typography>
                                    <Typography>
                                        Step {activeStep + 1} of {steps.length}
                                    </Typography>
                                </Box>
                                {steps[activeStep].content}
                            </Box>
                        )}
                        {showSuccessStep && successStep && <Box>{successStep}</Box>}
                        {submitError && activeStep === steps.length - 1 && (
                            <Box>
                                {submitError.data.message && (
                                    <Alert severity="error" sx={{ mb: 2, mt: 4 }}>
                                        <AlertTitle>An Error Occurred</AlertTitle>
                                        <Box sx={{ whiteSpace: "pre-line" }}>{submitError.data.message}</Box>
                                    </Alert>
                                )}
                            </Box>
                        )}
                    </>
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(StepperForm);
