import { agent, ISchedulerFormValues, RootStoreContext } from "api";
import { useState, useCallback, useContext } from "react";

export const useScheduler = () => {
    const rootStore = useContext(RootStoreContext);
    const { setAlertMethods, alertMethods } = rootStore.schedulerStore;
    const [isLoading, setIsLoading] = useState(false);

    const updateAlert = useCallback(async (id: string, body: any) => {
        setIsLoading(true);

        try {
            await agent.Scheduler.updateAlert(id, body);
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const createAlert = useCallback(async (body: any) => {
        setIsLoading(true);

        try {
            await agent.Scheduler.createAlert(body);
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getOrganisationAlertMethods = useCallback(async () => {
        const alertMethods = await agent.Scheduler.getAlertMethods();
        setAlertMethods(alertMethods);
        return alertMethods;
    }, []);

    const updateOrganisationAlertMethod = useCallback(async (body: any, id: string) => {
        try {
            const alertMethod = await agent.Scheduler.updateAlertMethod(id, body);
            const newAlertMethods = await getOrganisationAlertMethods();
            setAlertMethods(newAlertMethods);
            return alertMethod;
        } catch (error) {
            throw error;
        }
    }, []);

    const createOrganisationAlertMethod = useCallback(async (body: any) => {
        try {
            const alertMethod = await agent.Scheduler.createAlertMethod(body);
            const newAlertMethods = await getOrganisationAlertMethods();
            setAlertMethods(newAlertMethods);
            return alertMethod;
        } catch (error) {
            throw error;
        }
    }, []);

    const getOutputAlerts = useCallback(async (id: string) => {
        const outputsAlerts = await agent.Scheduler.getOutputAlerts(id);
        return outputsAlerts;
    }, []);

    const getInputAlerts = useCallback(async (id: string) => {
        const inputsAlerts = await agent.Scheduler.getInputAlerts(id);
        return inputsAlerts;
    }, []);

    const updateSchedule = useCallback(async (values: any, id: string) => {
        try {
            const updateResponse = await agent.Scheduler.updateSchedule(values, id);
            return updateResponse.status;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }, []);

    const deleteSchedule = useCallback(async (id: string) => {
        try {
            const deleteResponse = await agent.Scheduler.deleteSchedule(id);
            return deleteResponse.status;
        } catch (error) {
            throw error;
        }
    }, []);

    const createPermanentSchedule = useCallback(async (values: ISchedulerFormValues, id: string) => {
        try {
            const createPermanentSchedule = await agent.Scheduler.createPermanentSchedule(values, id);
            return createPermanentSchedule.status;
        } catch (error) {
            throw error;
        }
    }, []);

    // const getAlertMethods = useCallback(() => {
    //     return alertMethods;
    // }, [alertMethods]);

    return {
        isLoading,
        updateAlert,
        createAlert,
        getOrganisationAlertMethods,
        updateOrganisationAlertMethod,
        createOrganisationAlertMethod,
        getOutputAlerts,
        getInputAlerts,
        updateSchedule,
        deleteSchedule,
        createPermanentSchedule
        // getAlertMethods
    };
};
