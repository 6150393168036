import { ContentCopy } from "@mui/icons-material";
import { Box, Chip, Tooltip as MuiTooltip, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler
} from "chart.js";
import { Line } from "react-chartjs-2";

interface InfoItemProps {
    title: string;
    value: any;
    value_type?: "copy" | "chip" | "line-chart" | "field";
    value_prefix?: string;
    value_suffix?: string;
    chip_color?: "primary" | "secondary" | "error" | "warning" | "info" | "success";
    line_chart_color?: "primary" | "secondary";
    line_chart_max_items?: number;
    line_chart_interval?: number;
}

/**
 * `InfoItem` is a React component that displays information based on the provided props.
 *
 * @component
 * @param {Object} props - The properties that define the InfoItem component.
 * @param {string} props.title - The title of the information item.
 * @param {any} props.value - The value of the information item.
 * @param {("copy"|"chip"|"line-chart")} [props.value_type] - The type of the value to be displayed.
 * @param {("primary"|"secondary"|"error"|"warning"|"info"|"success")} [props.chip_color] - The color of the chip, if `value_type` is "chip".
 * @param {("primary"|"secondary")} [props.line_chart_color] - The color of the line chart, if `value_type` is "line-chart".
 * @param {number} [props.line_chart_max_items] - The maximum number of items to be displayed in the line chart, if `value_type` is "line-chart".
 *
 * @example
 * // To use this component, import it and use it in your JSX like this:
 * import InfoItem from './InfoItem';
 *
 * <InfoItem
 *   title="Example Title"
 *   value={12345}
 *   value_type="chip"
 *   chip_color="primary"
 * />
 */

const InfoItem: React.FC<InfoItemProps> = ({
    title,
    value,
    value_type,
    value_prefix,
    value_suffix,
    chip_color,
    line_chart_color,
    line_chart_max_items,
    line_chart_interval
}) => {
    if (value_type === "line-chart") {
        ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler);
    }

    const [valueList, setValueList] = useState<{ label: string; value: number | string }[]>([
        { label: "Initial", value: value ? value.toString().replace(/[^0-9.]/g, "") : 0 }
    ]);
    const [valueCopied, setValueCopied] = useState(false);
    const theme = useTheme();

    React.useEffect(() => {
        if (value && value_type === "line-chart" && valueList.length > 0) {
            const interval = setInterval(() => {
                const valueStripped = value ? value.toString().replace(/[^0-9.]/g, "") : 0;
                if (valueList.length > (line_chart_max_items || 49)) {
                    const newValueList = valueList.slice(1).concat({ label: new Date().toString(), value: valueStripped });
                    setValueList(newValueList);
                } else {
                    const newValueList = valueList.concat({ label: new Date().toString(), value: valueStripped });
                    setValueList(newValueList);
                }
            }, line_chart_interval || 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [valueList]);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                borderRadius: 1,
                "&:hover": {
                    backgroundColor: theme.palette.action.hover
                }
            }}>
            {value_type !== "line-chart" && (
                <Typography variant="body1" color="text.secondary" mr={1}>
                    {title}
                </Typography>
            )}
            {!value_type && (
                <Typography variant="body1" fontWeight="bold">
                    {value_prefix ? value_prefix : ""}
                    {value}
                    {value_suffix ? value_suffix : ""}
                </Typography>
            )}
            {value_type === "chip" && <Chip label={value} color={chip_color ? chip_color : undefined} size="small" />}
            {value_type === "copy" && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end"
                    }}>
                    <Typography
                        component="div"
                        variant="body1"
                        fontWeight="bold"
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flexShrink: 1,
                            width: "100%",
                            textAlign: "right",
                            maxWidth: "350px"
                        }}>
                        {value_prefix ? value_prefix : ""}
                        {value}
                        {value_suffix ? value_suffix : ""}
                    </Typography>
                    <CopyToClipboard
                        text={value}
                        onCopy={() => {
                            setValueCopied(true);
                            setTimeout(() => {
                                setValueCopied(false);
                            }, 2000);
                        }}>
                        <Box title={"Copy " + title + " to clipboard"}>
                            <MuiTooltip open={valueCopied} title={title + " Copied"} placement="top-end">
                                <ContentCopy
                                    sx={{
                                        cursor: "pointer",
                                        fontSize: "11px",
                                        marginLeft: "5px",
                                        marginTop: "6px",
                                        opacity: 0.7,
                                        ":hover": { opacity: 1 }
                                    }}
                                />
                            </MuiTooltip>
                        </Box>
                    </CopyToClipboard>
                </Box>
            )}
            {value_type === "line-chart" && (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        <Typography variant="body1" color="text.secondary" mr={1}>
                            {title}
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                            {value_prefix ? value_prefix : ""}
                            {value}
                            {value_suffix ? value_suffix : ""}
                        </Typography>
                    </Box>
                    <Box sx={{ width: "100%", height: "100px" }}>
                        <Line
                            data={{
                                datasets: [
                                    {
                                        label: title,
                                        data: valueList.map((bitrate) => ({ x: bitrate.label, y: bitrate.value })),
                                        backgroundColor:
                                            line_chart_color === "secondary" ? theme.palette.secondary.dark : theme.palette.primary.dark,
                                        borderColor:
                                            line_chart_color === "secondary" ? theme.palette.secondary.main : theme.palette.primary.main,
                                        pointStyle: false,
                                        fill: true
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                animation: false,
                                elements: {
                                    line: {
                                        tension: 0.2,
                                        borderWidth: 2
                                    }
                                },
                                scales: {
                                    x: {
                                        display: false
                                    },
                                    y: {
                                        display: true,
                                        position: "right"
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            )}
            {value_type === "field" && <Box>{value}</Box>}
        </Box>
    );
};

export default InfoItem;
