import { Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { RootStoreContext } from "api";
import Content from "components/Layout/Content";
import React, { useContext, useEffect } from "react";
import ConfirmDisableOwnCloud from "./Components/ConfirmDisableOwnCloud";
import ConfirmEnableOwnCloud from "./Components/ConfirmEnableOwnCloud";

const EnableOwnCloud: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, getUser } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;
    const [awsStatus, setAwsStatus] = React.useState<string>(user?.own_cloud_status?.aws || "unavailable");
    const [hasChanged, setHasChanged] = React.useState<boolean>(false);
    const [userInterval, setUserInterval] = React.useState<any>(null);

    useEffect(() => {
        if (!hasChanged) {
            return
        }

        const intervalId = setInterval(() => {
            getUser();
        }, 500);
        setUserInterval(intervalId);
    }, [getUser, hasChanged]);

    useEffect(() => {
        if (!user?.own_cloud_status?.aws || user?.own_cloud_status?.aws === awsStatus) {
            return
        }

        setAwsStatus(user?.own_cloud_status?.aws);
        clearInterval(userInterval);
        setHasChanged(false);
    }, [awsStatus, user, userInterval]);

    return (
        <Content>
            {awsStatus !== "unavailable" && (
                <Card>
                    <CardHeader title="AWS" />
                    <CardContent>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Current status:{" "}
                            <strong style={{ textTransform: "capitalize" }}>
                                {awsStatus === "enabled" ? "Available To Enable" : awsStatus}
                            </strong>
                        </Typography>
                        {awsStatus === "enabled" ? (
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => openDialog(<ConfirmEnableOwnCloud cloud_provider="aws" setHasChanged={setHasChanged} />, "Enable Own Cloud on AWS")}
                                disabled={hasChanged}
                            >
                                Enable AWS Own Cloud
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => openDialog(<ConfirmDisableOwnCloud cloud_provider="aws" setHasChanged={setHasChanged} />, "Disable Own Cloud on AWS")}
                                disabled={hasChanged}
                            >
                                Disable AWS Own Cloud
                            </Button>
                        )}
                    </CardContent>
                </Card>
            )}
        </Content>
    );
};

export default EnableOwnCloud;
