import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "api";
import { CurrentCommitmentsTable } from "./CurrentCommitmentsTable";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import { usePermanentSchedule } from "hooks";

const Commitments = () => {
    const rootStore = useContext(RootStoreContext);
    const { permCommitments } = rootStore.permanentScheduleStore;
    const { getPermanentScheduleCommitments } = usePermanentSchedule();

    useEffect(() => {
        getPermanentScheduleCommitments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const expiredData = permCommitments?.data.filter((cmt) => cmt.status === "expired");
    const activeData = permCommitments?.data.filter((cmt) => cmt.status === "active");
    const endOfTermData = permCommitments?.data.filter((cmt) => cmt.status === "expired_but_attached");

    return (
        <>
            {activeData && activeData!.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        Current Commitments
                    </Typography>
                    <CurrentCommitmentsTable data={activeData!} />
                </Box>
            )}
            {endOfTermData && endOfTermData!.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        End of term but still running
                    </Typography>
                    <CurrentCommitmentsTable data={endOfTermData!} />
                </Box>
            )}
            {expiredData && expiredData!.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        Expired Commitments
                    </Typography>
                    <CurrentCommitmentsTable data={expiredData!} />
                </Box>
            )}
        </>
    );
};

export default observer(Commitments);
