import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { EnvironmentUpgraded } from "../EnvironmentUpgraded";
import { IEnvironmentsData, IEnvironmentUpgradeTypes, RootStoreContext } from "api";
import { EnvironmentResize } from "./EnvironmentResize";
import { Alert, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEnvironment } from "hooks";

interface IPropz {
    environment: IEnvironmentsData;
    environmentupgradeOptions: IEnvironmentUpgradeTypes[] | null;
    body: any;
    size: string;
}

export const ConfirmEnvironmentResizeNow: React.FC<IPropz> = ({ environment, body, size }) => {
    const rootStore = useContext(RootStoreContext);
    const { resetEnvInfo } = rootStore.environmentStore;
    const { getEnvironmentsDetails, upgradeEnvironment } = useEnvironment();
    const { changeDialogBody, closeDialog, openDialog } = rootStore.dialogStore;
    const [error, setError] = useState("");

    const handleConfirm = () => {
        upgradeEnvironment(environment.sched_upgrade_id.toString(), body)
            .then(() => {
                getEnvironmentsDetails();
                resetEnvInfo();
                changeDialogBody(<EnvironmentUpgraded environment={environment} />);
            })
            .catch((error) => {
                setError(error.data.message);
            });
    };

    return (
        <>
            <DialogTitle>Confirm environment upgrade</DialogTitle>
            <DialogContent>
                <Typography>Environment: {environment.name}</Typography>
                <Typography>Current size: {environment.sched_upgrade_instance_size_human_name}</Typography>
                <Typography>New size: {size}</Typography>
                <Typography>
                    Click upgrade to start the process, it will take several minutes and you will be notified once complete.
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ my: 2 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                        openDialog(<EnvironmentResize environment={environment} body={body} size={body.product_id} />);
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleConfirm();
                    }}>
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
};
