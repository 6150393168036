import { List } from "@mui/material";
import { RootStoreContext } from "api";
import React from "react";
import SourceListItem from "./SourceListItem";

interface Props {
    limitTo?: number;
    draggableItems?: boolean;
    onItemDragEnd?: (event: React.DragEvent<HTMLElement>) => void;
}

const SourceList: React.FC<Props> = ({ limitTo, draggableItems, onItemDragEnd }) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const rootStore = React.useContext(RootStoreContext);
    const { activeEnvironment } = rootStore.userStore;
    const { currentEnvRunning } = rootStore.environmentStore;
    const { token } = rootStore.commonStore;
    const [data, setData] = React.useState<any>([]);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const { client, clientConnected } = rootStore.resClientStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const activeorganisationId = rootStore.userStore.activeorganisationId;

    React.useEffect(() => {
        setLoading(true);

        let resData: any = {};

        clientConnected &&
            client
                .get(`environments.sources.${managing_org_id}.${activeEnvironment}.${activeorganisationId}`)
                .then((res) => {
                    resData = res;
                    const jsonObject = res.toJSON();
                    setData(jsonObject);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setData(res.toJSON());
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            setData([]);
        };
    }, [managing_org_id, activeorganisationId, clientConnected, currentEnvRunning]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <List sx={{ width: "100%", padding: 0 }}>
            {currentEnvRunning &&
                data &&
                Object.entries(data)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort entries by key
                    .filter(
                        // if the prop "limitTo" is set, only show the first "limitTo" entries
                        ([key, source]: [string, any], index) => !limitTo || index < limitTo
                    )
                    .map(([key, source]: [string, any]) => (
                        <SourceListItem
                            key={key}
                            rid={source.rid}
                            client={client}
                            draggable={draggableItems}
                            onDragEnd={(e) => onItemDragEnd && onItemDragEnd(e)}
                        />
                    ))}
        </List>
    );
};

export default SourceList;
