import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import { agent, RootStoreContext } from "api";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import EditDefaultPriceForm from "./Components/EditDefaultPriceForm";

const AdminDefaultPrice = observer(function AdminDefaultPrice() {
    const rootStore = useContext(RootStoreContext);

    const { setCurrentTheme } = rootStore.commonStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;

    const [defaultPrices, setDefaultPrices] = React.useState<any>([]);

    const renderSwitch = (param) => {
        switch (param) {
            case "AWS":
                return "Amazon Web Services";
            case "DO":
                return "Digital Ocean";
            case "GCP":
                return "Google Cloud Platform";
            default:
                return param;
        }
    };

    const getDefaultPrices = async () => {
        await agent.Billing.getDefaultBillingPrices()
            .then((response) => {
                console.log(response);
                setDefaultPrices(response.prices);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getDefaultPrices();
        setCurrentTheme("light");

        return () => {
            setCurrentTheme("dark");
        };
    }, [setCurrentTheme]);

    return (
        <View>
            <ViewHeader title="Default Price" subtitle="Admin">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div></div>
                    <div></div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <Content>
                    <Box
                        sx={{
                            backgroundColor: "background.default",
                            height: 20,
                            position: "fixed",
                            top: 65,
                            left: 70,
                            right: 20,
                            zIndex: 1
                        }}></Box>
                    {defaultPrices &&
                        defaultPrices.map((price: any) => {
                            return (
                                <Paper key={price.section_type} elevation={0} sx={{ mb: 2 }}>
                                    <Accordion key={price.section_type}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            sx={{ textTransform: "capitalize", position: "sticky", top: 0, zIndex: 1 }}>
                                            {price.section_title}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer component={Box}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>{price.size_column_title}</TableCell>
                                                            <TableCell>Provider</TableCell>
                                                            <TableCell>Updated</TableCell>
                                                            <TableCell>Price USD / OC Price USD</TableCell>
                                                            <TableCell>Price GBP / OC Price GBP</TableCell>
                                                            <TableCell>Price EUR / OC Price EUR</TableCell>
                                                            <TableCell>Notes</TableCell>
                                                            <TableCell width={50} align="right"></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {price.entries.map((entry: any) =>
                                                            entry.prices
                                                                .filter((price) => price.product_id !== null)
                                                                .map((priceitem: any, i: number) => (
                                                                    <TableRow key={i}>
                                                                        <TableCell>{entry.size}</TableCell>
                                                                        <TableCell sx={{ fontWeight: 600 }}>
                                                                            {renderSwitch(priceitem.cloud_provider)}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {priceitem.updated_on
                                                                                ? format(
                                                                                      new Date(priceitem.updated_on),
                                                                                      "dd/MM/yyyy @ HH:mm"
                                                                                  )
                                                                                : ""}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {priceitem.usd} / {priceitem.own_cloud_price_usd}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {priceitem.gbp} / {priceitem.own_cloud_price_gbp}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {priceitem.eur} / {priceitem.own_cloud_price_eur}
                                                                        </TableCell>
                                                                        <TableCell>{priceitem.notes}</TableCell>
                                                                        <TableCell>
                                                                            <Tooltip title="Update Price" placement="top">
                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    onClick={() =>
                                                                                        openDialog(
                                                                                            <EditDefaultPriceForm
                                                                                                onSubmitted={() => {
                                                                                                    getDefaultPrices();
                                                                                                    closeDialog();
                                                                                                }}
                                                                                                cloud_provider={priceitem.cloud_provider}
                                                                                                eur={priceitem.eur}
                                                                                                gbp={priceitem.gbp}
                                                                                                last_updated={priceitem.updated_on}
                                                                                                notes={priceitem.notes}
                                                                                                product_id={priceitem.product_id}
                                                                                                section={price.section_title}
                                                                                                size={entry.size}
                                                                                                usd={priceitem.usd}
                                                                                                own_cloud_price_usd={priceitem.own_cloud_price_usd}
                                                                                                own_cloud_price_gbp={priceitem.own_cloud_price_gbp}
                                                                                                own_cloud_price_eur={priceitem.own_cloud_price_eur}
                                                                                            />,
                                                                                            "Create Custom Price"
                                                                                        )
                                                                                    }>
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Paper>
                            );
                        })}
                </Content>
            </ViewBody>
        </View>
    );
});

export default AdminDefaultPrice;
