import { Alert, AlertTitle, Box, Button, Checkbox, FormControlLabel, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import OnChange from "components/FinalFormListener/OnChange";
import CheckboxField from "components/Forms/CheckboxField";
import { SelectField, SliderField, TextField } from "components/Forms";

interface Props {
    values: any;
    onFieldChange: (field: string, value: any) => void;
    outputGroupIndex: number;
}

const HlsFmp4OutputOptions: React.FC<Props> = ({ values, onFieldChange, outputGroupIndex }) => {
    const [enablePlaybackURLs, setEnablePlaybackURLs] = React.useState<boolean>(false);
    const { submitErrors } = useFormState();
    React.useEffect(() => {
        if (
            (submitErrors?.output_groups?.[outputGroupIndex] &&
                (submitErrors.output_groups[outputGroupIndex].playback_url_1 ||
                    submitErrors.output_groups[outputGroupIndex].playback_url_2)) ||
            values?.output_groups?.[outputGroupIndex].playback_url_1 ||
            values?.output_groups?.[outputGroupIndex].playback_url_2
        ) {
            setEnablePlaybackURLs(true);
        }
    }, [submitErrors, outputGroupIndex, values?.output_groups]);

    return (
        <Box>
            {/* -> "cmaf_ingest": bool. Default = False.
                -> "cmaf_chunk_gen_mode": Either "Low Latency" or "Segment". Default = Segment.
                -> "low_latency_chunk_duration": Integer, value in milliseconds. Only applicable if cmaf_chunk_gen_mode = 'Low Latency'. Default = 1000.
                -> "scte35_manifest_decoration": Integer, 0 <= value <= 7. Chooses Mode 1-8 for SCTE35 Manifest Decoration. Default = 0. */}

            <Box sx={{ my: 1 }}>
                <CheckboxField
                    name={`output_groups[${outputGroupIndex}].cmaf_ingest`}
                    label="CMAF Ingest"
                    helperText={() => {
                        if (submitErrors?.output_groups?.[outputGroupIndex]?.cmaf_ingest) {
                            return submitErrors.output_groups[outputGroupIndex].cmaf_ingest;
                        }
                        return null;
                    }}
                    error={() => (submitErrors?.output_groups?.[outputGroupIndex]?.cmaf_ingest ? true : false)}
                />
                <OnChange name={`output_groups[${outputGroupIndex}].cmaf_ingest`}>
                    {() => {
                        if (submitErrors?.output_groups?.[outputGroupIndex]?.cmaf_ingest) {
                            onFieldChange(`output_groups.${outputGroupIndex}.cmaf_ingest`, undefined);
                        }
                    }}
                </OnChange>
            </Box>
            {values?.output_groups?.[outputGroupIndex]?.cmaf_ingest && (
                <SelectField
                    name={`output_groups[${outputGroupIndex}].cmaf_chunk_gen_mode`}
                    validate={(value) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                    label="CMAF Chunk Gen Mode"
                    required
                    options={[
                        { value: "Segment", text: "Segment" },
                        { value: "Low Latency", text: "Low Latency" }
                    ]}></SelectField>
            )}
            <OnChange name={`output_groups[${outputGroupIndex}].cmaf_chunk_gen_mode`}>
                {() => {
                    if (submitErrors?.output_groups?.[outputGroupIndex]?.cmaf_chunk_gen_mode) {
                        onFieldChange(`output_groups.${outputGroupIndex}.cmaf_chunk_gen_mode`, undefined);
                    }
                }}
            </OnChange>
            {values?.output_groups?.[outputGroupIndex]?.cmaf_chunk_gen_mode === "Low Latency" && (
                <SliderField
                    name={`output_groups[${outputGroupIndex}].low_latency_chunk_duration`}
                    validate={(value) => {
                        if (!value && value !== 0) {
                            return "Required";
                        }
                    }}
                    label="Low Latency Chunk Duration"
                    min={1000}
                    max={100000}
                    step={500}
                    initialValue={1000}
                />
            )}
            <OnChange name={`output_groups[${outputGroupIndex}].low_latency_chunk_duration`}>
                {() => {
                    if (submitErrors?.output_groups?.[outputGroupIndex]?.low_latency_chunk_duration) {
                        onFieldChange(`output_groups.${outputGroupIndex}.low_latency_chunk_duration`, undefined);
                    }
                }}
            </OnChange>
            {values?.individual_track_configuration?.data_tracks &&
                values.individual_track_configuration.data_tracks.some((item) => item.data_type === "SCTE-35 pass-through") && (
                    <Box sx={{ mt: 1 }}>
                        <SelectField
                            name={`output_groups[${outputGroupIndex}].scte35_manifest_decoration`}
                            initialValue={values?.output_groups?.[outputGroupIndex]?.scte35_manifest_decoration || 0}
                            validate={(value) => {
                                if (!value && value !== 0) {
                                    return "Required";
                                }
                                if (value < 0 || value > 7) {
                                    return "SCTE35 Manifest Decoration must be between 0 and 7";
                                }
                            }}
                            label="SCTE35 Manifest Decoration"
                            options={[
                                { value: 0, text: "Mode 1" },
                                { value: 1, text: "Mode 2" },
                                { value: 2, text: "Mode 3" },
                                { value: 3, text: "Mode 4" },
                                { value: 4, text: "Mode 5" },
                                { value: 5, text: "Mode 6" },
                                { value: 6, text: "Mode 7" },
                                { value: 7, text: "Mode 8" }
                            ]}
                            required></SelectField>
                        <OnChange name={`output_groups[${outputGroupIndex}].scte35_manifest_decoration`}>
                            {() => {
                                if (submitErrors?.output_groups?.[outputGroupIndex]?.scte35_manifest_decoration) {
                                    onFieldChange(`output_groups.${outputGroupIndex}.scte35_manifest_decoration`, undefined);
                                }
                            }}
                        </OnChange>
                    </Box>
                )}
            <Box sx={{ mt: 4 }}>
                {values.individual_track_configuration.video_tracks && values.individual_track_configuration.video_tracks.length > 0 && (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Video Tracks
                        </Typography>
                        <FieldArray name={`output_groups[${outputGroupIndex}].video_track_indexes`} defaultValue={[undefined]}>
                            {({ fields }) => (
                                <Box>
                                    {fields.map((name, idx) => (
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            key={idx}
                                            sx={{
                                                mb: 1,
                                                backgroundColor: "rgba(160,160,160,0.05)",
                                                borderRadius: 1,
                                                padding: 1
                                            }}>
                                            <SelectField
                                                name={name}
                                                label={`Video Track Index ${idx + 1}`}
                                                required
                                                options={
                                                    values?.individual_track_configuration?.video_tracks
                                                        ? values?.individual_track_configuration?.video_tracks?.map((track, index) => ({
                                                              value: index,
                                                              text: `${index}. ${track.frame_size} / ${track.frame_rate} / ${track.bitrate}`
                                                          }))
                                                        : []
                                                }
                                            />
                                            <OnChange name={name}>
                                                {() => {
                                                    if (submitErrors?.output_groups?.[outputGroupIndex]?.video_track_indexes?.[idx]) {
                                                        onFieldChange(name, undefined);
                                                    }
                                                    if (submitErrors?.output_groups?.[outputGroupIndex]?.video_track_indexes) {
                                                        onFieldChange(`output_groups.${outputGroupIndex}.video_track_indexes`, undefined);
                                                    }
                                                }}
                                            </OnChange>
                                            <IconButton onClick={() => fields.remove(idx)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Stack>
                                    ))}
                                    <Button onClick={() => fields.push(undefined)} startIcon={<AddCircleIcon />}>
                                        Add Video Track
                                    </Button>
                                </Box>
                            )}
                        </FieldArray>
                        <OnChange name={`output_groups[${outputGroupIndex}].video_track_indexes`}>
                            {() => {
                                if (submitErrors?.output_groups?.[outputGroupIndex]?.video_track_indexes) {
                                    try {
                                        onFieldChange(`output_groups.${outputGroupIndex}.video_track_indexes`, undefined);
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }
                            }}
                        </OnChange>
                        {submitErrors?.output_groups?.[outputGroupIndex] &&
                            submitErrors.output_groups[outputGroupIndex].video_track_indexes && (
                                <Box sx={{ mt: 2 }}>
                                    <Alert severity="error">
                                        <AlertTitle>{submitErrors.output_groups[outputGroupIndex].video_track_indexes}</AlertTitle>
                                    </Alert>
                                </Box>
                            )}
                    </>
                )}

                {values?.individual_track_configuration?.audio_tracks && values.individual_track_configuration.audio_tracks.length > 0 && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Audio Tracks
                        </Typography>
                        <FieldArray name={`output_groups[${outputGroupIndex}].audio_track_indexes`} defaultValue={[{}]}>
                            {({ fields }) => (
                                <Box>
                                    {fields.map((name, idx) => (
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            key={idx}
                                            sx={{
                                                mb: 2,
                                                backgroundColor: "rgba(160,160,160,0.05)",
                                                borderRadius: 1,
                                                padding: 1
                                            }}>
                                            <Box sx={{ flex: 1 }}>
                                                <SelectField
                                                    name={`${name}.track_index`}
                                                    label={`Audio Track Index ${idx + 1}`}
                                                    required
                                                    validate={(value) => {
                                                        if (value === undefined) {
                                                            return "Required";
                                                        }
                                                    }}
                                                    options={
                                                        values?.individual_track_configuration?.audio_tracks
                                                            ? values.individual_track_configuration.audio_tracks?.map((track, index) => ({
                                                                  value: index,
                                                                  text: `${index}. ${track.codec}`
                                                              }))
                                                            : []
                                                    }
                                                />

                                                {/* if the previuos field is valid, then show the next field */}
                                                {values.output_groups?.[outputGroupIndex]?.audio_track_indexes?.[idx]?.track_index !==
                                                    undefined && (
                                                    <>
                                                        <SelectField
                                                            name={`${name}.variant_index`}
                                                            label={`Variant Index ${idx + 1}`}
                                                            required
                                                            options={values.individual_track_configuration.audio_tracks[
                                                                values.output_groups?.[outputGroupIndex]?.audio_track_indexes?.[idx]
                                                                    ?.track_index!
                                                            ].variants?.map((variant, index) => ({
                                                                value: index,
                                                                text: `${index}. ${variant.bitrate}`
                                                            }))}
                                                            validate={(value) => {
                                                                if (value === undefined) {
                                                                    return "Required";
                                                                }
                                                            }}
                                                        />
                                                        <OnChange name={`${name}.variant_index`}>
                                                            {() => {
                                                                if (
                                                                    submitErrors?.output_groups?.[outputGroupIndex]?.audio_track_indexes?.[
                                                                        idx
                                                                    ]?.variant_index
                                                                ) {
                                                                    onFieldChange(
                                                                        `output_groups.${outputGroupIndex}.audio_track_indexes.${idx}.variant_index`,
                                                                        undefined
                                                                    );
                                                                }
                                                            }}
                                                        </OnChange>
                                                    </>
                                                )}
                                            </Box>
                                            <IconButton onClick={() => fields.remove(idx)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Stack>
                                    ))}
                                    <Button startIcon={<AddCircleIcon />} onClick={() => fields.push({})}>
                                        Add Audio Track
                                    </Button>
                                </Box>
                            )}
                        </FieldArray>
                        <OnChange name={`output_groups[${outputGroupIndex}].audio_track_indexes`}>
                            {() => {
                                if (submitErrors?.output_groups[outputGroupIndex]?.audio_track_indexes) {
                                    onFieldChange(`output_groups.${outputGroupIndex}.audio_track_indexes`, undefined);
                                }
                            }}
                        </OnChange>
                        {submitErrors?.output_groups?.[outputGroupIndex] &&
                            submitErrors.output_groups[outputGroupIndex].audio_track_indexes && (
                                <Box sx={{ mt: 2 }}>
                                    <Alert severity="error">
                                        <AlertTitle>{submitErrors.output_groups[outputGroupIndex].audio_track_indexes}</AlertTitle>
                                    </Alert>
                                </Box>
                            )}
                    </Box>
                )}

                {values?.individual_track_configuration?.data_tracks && values.individual_track_configuration.data_tracks.length > 0 && (
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Typography variant="h6">Data Tracks</Typography>

                        <FieldArray name={`output_groups[${outputGroupIndex}].data_track_indexes`}>
                            {({ fields }) => (
                                <>
                                    {fields.map((name, idx) => (
                                        <Box
                                            key={idx}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                mb: 2,
                                                backgroundColor: "rgba(160,160,160,0.05)",
                                                borderRadius: 1,
                                                padding: 1
                                            }}>
                                            <SelectField
                                                name={name}
                                                label={`Data Track Index ${idx + 1}`}
                                                options={
                                                    values?.individual_track_configuration?.data_tracks
                                                        ? values?.individual_track_configuration?.data_tracks?.map((track, index) => ({
                                                              value: index,
                                                              text: `${index}. ${track.data_type} ${
                                                                  track.data_type === "DVB Subtitles" ? " (Not Supported)" : ""
                                                              }`,
                                                              disabled: Boolean(track.data_type === "DVB Subtitles")
                                                          }))
                                                        : []
                                                }
                                                required
                                                validate={(value) => {
                                                    if (value === undefined) {
                                                        return "Required";
                                                    }
                                                }}
                                            />
                                            <IconButton onClick={() => fields.remove(idx)} sx={{ ml: 2 }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    ))}
                                    <Button onClick={() => fields.push(undefined)} startIcon={<AddCircleIcon />}>
                                        Add Data Track
                                    </Button>
                                </>
                            )}
                        </FieldArray>
                        <OnChange name={`output_groups[${outputGroupIndex}].data_track_indexes`}>
                            {() => {
                                if (submitErrors?.output_groups?.[outputGroupIndex]?.data_track_indexes) {
                                    onFieldChange(`output_groups.${outputGroupIndex}.data_track_indexes`, undefined);
                                }
                            }}
                        </OnChange>
                        {submitErrors?.output_groups?.[outputGroupIndex] &&
                            submitErrors.output_groups[outputGroupIndex].data_track_indexes && (
                                <Box sx={{ mt: 2 }}>
                                    <Alert severity="error">
                                        <AlertTitle>{submitErrors.output_groups[outputGroupIndex].data_track_indexes}</AlertTitle>
                                    </Alert>
                                </Box>
                            )}
                    </Box>
                )}

                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                    Output URL
                </Typography>

                <TextField
                    name={`output_groups[${outputGroupIndex}].output_url`}
                    validate={(value) => {
                        if (!value) {
                            return "Required";
                        }
                        //must start with https:// or http://
                        if (!value.startsWith("https://") && !value.startsWith("http://")) {
                            return "Output URL must start with https:// or http://";
                        }
                        if (values.output_groups[outputGroupIndex].cmaf_ingest && !value.endsWith("/")) {
                            return "Output URL must end with '/' for CMAF ingest";
                        }
                        if (!values.output_groups[outputGroupIndex].cmaf_ingest && !value.endsWith(".m3u8") && !value.endsWith("/")) {
                            return "Output URL must end with '.m3u8' or '/' for non-CMAF ingest FMP4";
                        }
                    }}
                    type="text"
                    label="Output URL"
                    required
                />

                <OnChange name={`output_groups[${outputGroupIndex}].output_url`}>
                    {() => {
                        if (submitErrors?.output_groups?.[outputGroupIndex]?.output_url) {
                            onFieldChange(`output_groups.${outputGroupIndex}.output_url`, undefined);
                        }
                    }}
                </OnChange>
                {/* backup_output_url */}
                {values?.enable_backup && (
                    <>
                        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                            Backup Output URL
                        </Typography>

                        <TextField
                            type="text"
                            label="Backup Output URL"
                            name={`output_groups[${outputGroupIndex}].backup_output_url`}
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                                //must start with https:// or http://
                                if (!value.startsWith("https://") && !value.startsWith("http://")) {
                                    return "Backup Output URL must start with https:// or http://";
                                }
                                if (values.output_groups[outputGroupIndex].cmaf_ingest && !value.endsWith("/")) {
                                    return "Backup Output URL must end with '/' for CMAF ingest";
                                }
                                if (
                                    !values.output_groups[outputGroupIndex].cmaf_ingest &&
                                    !value.endsWith(".m3u8") &&
                                    !value.endsWith(".mpd") &&
                                    !value.endsWith("/")
                                ) {
                                    return "Backup Output URL must end with '.m3u8' or '.mpd' or '/' for non-CMAF ingest FMP4";
                                }
                            }}
                            required
                        />

                        <OnChange name={`output_groups[${outputGroupIndex}].backup_output_url`}>
                            {() => {
                                if (submitErrors?.output_groups?.[outputGroupIndex]?.backup_output_url) {
                                    onFieldChange(`output_groups.${outputGroupIndex}.backup_output_url`, undefined);
                                }
                            }}
                        </OnChange>
                    </>
                )}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enablePlaybackURLs}
                            onChange={(event) => setEnablePlaybackURLs(event.target.checked)}
                            color="primary"
                        />
                    }
                    label="Enable Playback URLs"
                    labelPlacement="end"
                    sx={{ mt: 2 }}
                />
                {enablePlaybackURLs && (
                    <>
                        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                            Main Playback URL
                        </Typography>

                        <TextField
                            type="text"
                            label="Main Playback URL"
                            name={`output_groups[${outputGroupIndex}].playback_url_1`}
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                                //must start with https:// or http://
                                if (value && !value.startsWith("https://") && !value.startsWith("http://")) {
                                    return "URL must start with https:// or http://";
                                }

                                if (value && !value.endsWith(".m3u8") && !value.endsWith("/")) {
                                    return "URL must end with '.m3u8' or '/'";
                                }
                            }}
                        />

                        <OnChange name={`output_groups[${outputGroupIndex}].playback_url_1`}>
                            {() => {
                                if (submitErrors?.output_groups?.[outputGroupIndex]?.playback_url_1) {
                                    onFieldChange(`output_groups.${outputGroupIndex}.playback_url_1`, undefined);
                                }
                            }}
                        </OnChange>
                        {values?.enable_backup && (
                            <>
                                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                                    Backup Playback URL
                                </Typography>

                                <TextField
                                    name={`output_groups[${outputGroupIndex}].playback_url_2`}
                                    validate={(value) => {
                                        if (!value) {
                                            return "Required";
                                        }
                                        //must start with https:// or http://
                                        if (value && !value.startsWith("https://") && !value.startsWith("http://")) {
                                            return "URL must start with https:// or http://";
                                        }

                                        if (value && !value.endsWith(".m3u8") && !value.endsWith("/")) {
                                            return "URL must end with '.m3u8' or '/'";
                                        }
                                    }}
                                    type="text"
                                    label="Backup Playback URL"
                                />

                                <OnChange name={`output_groups[${outputGroupIndex}].playback_url_2`}>
                                    {() => {
                                        if (submitErrors?.output_groups?.[outputGroupIndex]?.playback_url_2) {
                                            onFieldChange(`output_groups.${outputGroupIndex}.playback_url_2`, undefined);
                                        }
                                    }}
                                </OnChange>
                            </>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default HlsFmp4OutputOptions;
