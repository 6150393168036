import React from "react";
import { TabList } from "@mui/lab";

interface ISmallTabListProps {
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
    sx?: object;
    children: React.ReactNode;
    [key: string]: any;
}

export const SmallTabList: React.FC<ISmallTabListProps> = ({ onChange, sx, children, ...props }) => (
    <TabList
        onChange={onChange}
        aria-label="Overview - Source Item Tabs"
        sx={{
            height: '30px',
            minHeight: '30px',

            '.MuiTabs-indicator': {
                display: 'none',
            },
    
            ...sx
        }}
        {...props}
    >
        {children}
    </TabList>
)

export default SmallTabList;