import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "api";
import ConfirmEmail from "./ConfirmEmail";
import SetUp2FA from "./SetUp2FA";
import CreateOrg from "./CreateOrg";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import { Box, Typography } from "@mui/material";

const SetupHome = observer(function SetupHome() {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    return (
        <View>
            <ViewHeader title="Setup"></ViewHeader>
            <ViewBody>
                {user && !user.email_verified && (
                    <Box>
                        <ConfirmEmail />
                    </Box>
                )}

                {user && !user.two_factor_auth_step_complete && (
                    <Box>
                        <SetUp2FA />
                    </Box>
                )}

                {user && !user.has_org_step_complete && (
                    <Box>
                        <CreateOrg />
                    </Box>
                )}
                {user && user.email_verified && user.two_factor_auth_step_complete && user.has_org_step_complete && (
                    <Box>
                        <Typography variant="h3" gutterBottom>
                            Setup Complete!
                        </Typography>
                        <Typography>
                            You have successfully completed the setup. <br />
                            You can now close this window and start using the platform. Thank you for your patience and good luck!
                        </Typography>
                        
                    </Box>
                )}
            </ViewBody>
        </View>
    );
});

export default SetupHome;
