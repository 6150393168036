import React, { useContext, useEffect } from "react";
import { Field, useForm } from "react-final-form";
import {
    Box,
    Button,
    ButtonBase,
    DialogActions,
    DialogContent,
    FormControl,
    FormLabel,
    TextField as MuiTextField,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme
} from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import MDEditor, { CommandOrchestrator, commands, EditorContext, ICommand } from "@uiw/react-md-editor";
import { FormatBold, FormatItalic, PermMedia } from "@mui/icons-material";
import { RootStoreContext } from "api";
import MediaGallery from "components/MediaGallery";

interface IWysiwygField {
    name: string;
    label?: string;
    initialValue?: string;
    rows?: number;
    maxRows?: number;
    validate?: (value: any) => string | undefined;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    onChange?: (field: string, value?: any, previous?: any) => void;
    required?: boolean;
    description?: string;
    height?: number;
}

export const WysiwygField: React.FC<IWysiwygField> = ({
    name,
    label,
    initialValue,
    rows,
    maxRows,
    validate,
    helperText,
    error,
    onChange,
    required,
    description,
    height = 200
}) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const form = useForm();
    const theme = useTheme();

    const handleMediaGallery: ICommand = {
        name: "media-gallery",
        keyCommand: "media-gallery",
        icon: <PermMedia sx={{ fontSize: 12 }} />,
        buttonProps: { "aria-label": "Insert help" },

        render: (command, disabled, executeCommand, index) => {
            return (
                <button
                    type="button"
                    key={index}
                    //disabled={disabled}
                    title="Media Gallery"
                    onClick={() =>
                        openDialog(
                            <MediaGallery
                                onSelected={(value) => {
                                    command.value = value;
                                    executeCommand(command);
                                }}
                            />,
                            undefined,
                            "lg",
                            false
                        )
                    }>
                    {command.icon}
                </button>
            );
        },
        execute: (state, api, dispatch, executeCommandState, shortcuts) => {
            console.log("Execute Media Gallery", state, api, dispatch, executeCommandState, shortcuts);
            api.replaceSelection("![](" + state.command.value + ")");
        }
    };

    //append a css class to the document
    useEffect(() => {
        const style = document.createElement("style");
        style.innerHTML = `
            .wysiwyg-editor {
                background-color: ${theme.palette.background.default};
                background-color: transparent;
                border-radius: ${theme.shape.borderRadius};
                color: ${theme.palette.text.primary};
                border-width: 1px;
                border-style: solid;
                border-color: ${theme.palette.divider} !important;
                box-shadow: none !important;
            }
            .w-md-editor-toolbar {
                background-color: ${theme.palette.background.default};
                background-color: transparent;
                border-radius: ${theme.shape.borderRadius};
                color: ${theme.palette.text.primary};
                border-color: ${theme.palette.divider} !important;
                border-width: 0px;
                padding: 0.5rem;
            }
            .w-md-editor-toolbar ul li button {
                width: 24px;
                height: 24px;
            }
            .w-md-editor-toolbar ul li button svg {
                width: 14px;
                height: 14px;
                color: ${theme.palette.text.primary} !important;
                opacity: 0.7;
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, [theme]);

    return (
        <>
            <Field
                name={name}
                initialValue={initialValue}
                validate={(value: any) => {
                    if (validate) {
                        return validate(value);
                    }
                }}>
                {({ input, meta }) => (
                    <FormControl
                        sx={{ ml: 1, mb: 1 }}
                        component="fieldset"
                        error={Boolean(
                            ((meta.touched || meta.dirty) && meta.error) ||
                                meta.submitError ||
                                (meta.touched && meta.invalid) ||
                                (error && error(meta))
                        )}>
                        {label && (
                            <FormLabel component="legend" required={required} sx={{ mb: 1 }}>
                                {label}
                            </FormLabel>
                        )}

                        {description && (
                            <Typography sx={{ mt: -1, mb: 1 }} variant="caption">
                                {description}
                            </Typography>
                        )}

                        <MDEditor
                            className="wysiwyg-editor"
                            value={input.value}
                            onChange={(value, _) => form.change(name, value)}
                            height="100%"
                            minHeight={height}
                            //maxHeight={400}
                            preview="edit"
                            commands={[...commands.getCommands(), handleMediaGallery]}
                            // style={{
                            //     backgroundColor: theme.palette.background.paper,
                            //     borderRadius: theme.shape.borderRadius,
                            //     color: theme.palette.text.primary,
                            // }}
                            textareaProps={{
                                placeholder: "Type here...",
                                style: {
                                    color: theme.palette.text.primary,
                                },
                            
                            }}
                        />

                        <Typography
                            variant="caption"
                            color={((meta.touched || meta.dirty) && meta.error) || meta.submitError ? "error" : undefined}>
                            {(meta.touched || meta.dirty) && meta.error
                                ? meta.error
                                : meta.submitError
                                ? meta.submitError
                                : helperText && helperText(meta)}
                        </Typography>
                    </FormControl>
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {
                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </>
    );
};

export default WysiwygField;
