import React from "react";
import { RootStoreContext } from "api";

/**
 * Custom hook to check if the current user has the required roles.
 *
 * @param {string[]} roles - The roles to check against the current user's roles.
 * @param {boolean} matchAll - If true, the user must have all the roles. If false, the user must have at least one of the roles.
 *
 * @returns {boolean} Returns true if the user has the required roles based on the matchAll parameter, otherwise returns false.
 *
 * @example
 * // To check if the user has either 'admin' or 'editor' role
 * const hasRequiredRoles = useRoleProvider(['admin', 'editor'], false);
 *
 * @example
 * // To check if the user has both 'admin' and 'editor' roles
 * const hasRequiredRoles = useRoleProvider(['admin', 'editor'], true);
 */
const useRoleProvider = (roles: string[], matchAll: boolean) => {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    if (!roles || !roles.length) {
        return true;
    }

    if (user) {
        if (matchAll) {
            return roles.every((role) => user[role]);
        } else {
            return roles.some((role) => user[role]);
        }
    }

    return false;
}

export default useRoleProvider;
