import React from "react";
import { IMTRReportHubEntry } from "api";
import { Tooltip } from "@mui/material";

type RouteMarkerProps = {
    count: number;
    text: string;
    lat?: any;
    lng?: any;
    prevLat?: number;
    prevLng?: number;
    data: IMTRReportHubEntry;
    map: google.maps.Map;
};

const RouteMarkerStyles = {
    width: 12,
    height: 12,
    marginTop: -6,
    marginLeft: -6,
    backgroundColor: "#307abb",
    color: "#fff",
    border: "0px solid #307abb",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 8,
    cursor: "default"
};

const RouteMarker: React.FC<RouteMarkerProps> = ({ count, text, lat, lng, prevLat, prevLng, data, map }) => {
    const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 2
    };

    const [pathState, setPathState] = React.useState<google.maps.Polyline>(
        new google.maps.Polyline({
            path: [
                { lat: prevLat || lat, lng: prevLng || lng },
                { lat: lat, lng: lng }
            ],
            geodesic: false,
            strokeColor: "#307abb",
            strokeOpacity: 1,
            strokeWeight: 0,
            icons: [
                {
                    icon: lineSymbol,
                    offset: "0",
                    repeat: "15px"
                }
            ]
        })
    );

    React.useEffect(() => {
        pathState.setPath([
            { lat: prevLat || lat, lng: prevLng || lng },
            { lat: lat, lng: lng }
        ]);
        pathState.setMap(map);

        return () => {
            pathState.setMap(null);
            setPathState(new google.maps.Polyline());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);

    React.useEffect(() => {
        if (pathState) {
            pathState.setMap(map);
        }
    }, [pathState, map]);

    return (
        <Tooltip
            placement="top"
            title={
                <div>
                    <div style={{ fontSize: 12 }}>
                        <div>{text}</div>
                        <div>
                            <b>Host: </b>
                            {data.host}
                        </div>
                        <div>
                            <b>Loss %: </b>
                            {data["Loss%"]}
                        </div>
                        <div>
                            <b>Snt: </b>
                            {data.Snt}
                        </div>
                        <div>
                            <b>Last: </b>
                            {data.Last}
                        </div>
                        <div>
                            <b>Avg: </b>
                            {data.Avg}
                        </div>
                        <div>
                            <b>Best: </b>
                            {data.Best}
                        </div>
                    </div>
                </div>
            }>
            <div style={RouteMarkerStyles}>{count}</div>
        </Tooltip>
    );
};

export default RouteMarker;
