import { useContext } from "react";
import { RootStoreContext } from "api";
import { isEmailAccepted } from "helpers";

/**
 * Custom React hook to determine if the current user's email is accepted based on a list of allowed emails or domains.
 *
 * This hook utilizes the `RootStoreContext` to access the current user's information and then checks if the user's email
 * matches any of the specified emails or domains in the `acceptedEmails` array. The `isEmailAccepted` helper function is used
 * to perform the actual matching logic.
 *
 * @param {string[]} acceptedEmails - An array of strings representing the accepted emails or domains. Each string can be a full email address (e.g., "user@example.com") or a domain (e.g., "@example.com").
 *
 * @returns {boolean} - Returns `true` if the current user's email is in the list of accepted emails or domains, `false` otherwise. If `acceptedEmails` is not provided or is empty, it defaults to `true`, assuming no restriction.
 *
 * @example
 * // Assuming the current user's email is "user@google.com"
 * const isAccepted = useEmailProvider(["@google.com", "user@example.com"]);
 * console.log(isAccepted); // Output: true
 *
 * @example
 * // Assuming no current user or `acceptedEmails` is empty
 * const isAccepted = useEmailProvider([]);
 * console.log(isAccepted); // Output: true
 */
export const useEmailProvider = (acceptedEmails: string[]) => {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    if (!acceptedEmails) {
        return true;
    }

    if (user) {
        return isEmailAccepted(user.email, acceptedEmails);
    }

    return false;
};

export default useEmailProvider;
