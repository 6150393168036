import React from "react";
import { Button, TextField } from "@mui/material";
import { agent, ICreateMTRHostGroupResponse, RootStoreContext } from "api";
import ErrorMessages from "components/ErrorMessages";
import ConfirmationDialog from "components/ConfirmationDialog";

interface EditHostGroupFormProps {
    id: string;
    onLoading: (loading: boolean) => void;
    onSuccess: () => void;
    onError: () => void;
    group_id: number;
    current_group_name: string;
}

const EditHostGroupForm: React.FC<EditHostGroupFormProps> = ({ group_id, current_group_name, onLoading, onSuccess, onError }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const [group_name, setGroupName] = React.useState(current_group_name);
    const [error, setError] = React.useState<any>();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onLoading(true);

        await agent.Reporting.editMtrHostGroup(group_id, { name: group_name })
            .then((value: ICreateMTRHostGroupResponse) => {
                onSuccess();
            })
            .catch((error) => {
                console.log(error);
                setError(error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred");
                onError();
            });
    };

    const handleDeleteMtrGroup = (group_id: number) => {
        onLoading(true);
        agent.Reporting.deleteMtrHostGroup(group_id)
            .then(() => {
                onSuccess();
            })
            .catch((error) => {
                console.log(error);
                setError(error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred");
                onError();
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                variant="standard"
                label="Group Name"
                value={group_name}
                onChange={(event) => setGroupName(event.target.value)}
                fullWidth
                style={{ marginBottom: 10 }}
            />
            <ErrorMessages errors={error} />
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                <Button
                    style={{ marginBottom: "10px" }}
                    color="error"
                    size="small"
                    variant="contained"
                    onClick={() => {
                        openDialog(
                            <ConfirmationDialog
                                title="Delete Group"
                                message="Are you sure you want to delete this group?"
                                onConfirm={async () => {
                                    await agent.Reporting.deleteMtrHostGroup(group_id);
                                }}
                                onSuccess={onSuccess}
                            />
                        );
                    }}>
                    Delete Group
                </Button>
                <Button color="primary" variant="contained" size="small" type="submit" style={{ marginBottom: 10 }}>
                    Save Changes
                </Button>
            </div>
        </form>
    );
};

export default EditHostGroupForm;
