import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResCollection, ResModel } from 'resclient';

type RegionMarkerProps = {
    count: number;
    lat: any;
    lng: any;
    hasError?: boolean;
    region_name: string;
    provider: string;
    environments: ResModel | ResCollection | any;
    active: boolean;
    onClick?: () => void;
}


const RegionMarker: React.FC<RegionMarkerProps> = ({ count, lat, lng, hasError, region_name, provider, environments, active, onClick }) => {

  


    React.useEffect(() => {

    }, [active])

    return (
        <div onClick={onClick} className={active ? "region-marker active" : hasError ? "region-marker has-error" : "region-marker"}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} title={region_name + " (" + environments.filter((environment: any) => (environment.status === 'running')).length + "/" + count + " Environments Running)"}>
                <div><FontAwesomeIcon icon={["fal", "server"]} /></div>
                <div style={{ fontSize: 9 }}>{environments.filter((environment: any) => (environment.status === 'running')).length}/{count}</div>
            </div>
            <div className="region-marker-popup">
            <div style={{ paddingBottom: 10, marginBottom: 10, borderBottom: '1px solid rgba(160,160,160,0.2)' }}>
                    <div style={{ fontSize: '14px', fontWeight: 600, whiteSpace: 'nowrap' }}>{region_name}</div>
                    <div style={{ fontSize: '10px', fontWeight: 600, opacity: 0.8, whiteSpace: 'nowrap' }}>{provider}&nbsp;&bull;&nbsp;{lat},&nbsp;{lng}</div>
                </div>
                {environments.length > 0 &&
                    <div>
                        <div style={{ fontSize: '13px', fontWeight: 600, whiteSpace: 'nowrap' }}>Environments</div>
                        {environments.map((environment: any) => (
                            <div key={environment.id} style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
                                <span style={{ display: 'inline-block', width: 8, height: 8, borderRadius: 4, backgroundColor: environment.status === 'running' ? '#33BD4E' : '#666666' }}></span>
                                &nbsp;&nbsp;
                                <span style={{opacity: 0.8, whiteSpace: 'nowrap'}}>{environment.environment_name} ({Array.from(environment.items).length})</span>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>)
}

export default RegionMarker;