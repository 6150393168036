import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PanelProps = {
    id: string;
    children?: JSX.Element;
    minHeight: string;
    maxHeight: string;
    title: string;
    defaultCollapsed: boolean;
    closable?: boolean;
    onCloseClick?: () => void;
    draggable?: boolean;
};

const Panel: React.FC<PanelProps> = ({
    id,
    children,
    minHeight,
    maxHeight,
    title,
    defaultCollapsed,
    closable,
    onCloseClick,
    draggable
}) => {
    const [minimize, setMinimize] = React.useState<boolean>(defaultCollapsed);

    React.useEffect(() => {
        setMinimize(defaultCollapsed);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCollapsed]);

    return (
        <div
            id={id}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginBottom: 10,
                backgroundColor: "#0f1419",
                minHeight: minimize ? 24 : minHeight,
                maxHeight: maxHeight,
                overflow: "hidden",
                borderRadius: "4px",
                position: "relative",
                opacity: 0.92,
                border: "1px solid rgba(0,0,0,0.3)"
            }}>
            <div
                className="panel-header"
                style={{
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                    height: "24px",
                    flexShrink: 0,
                    backgroundColor: "#0f1419",
                    color: "#fff",
                    padding: "2px 10px",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "12px",
                    cursor: draggable ? "move" : "auto"
                }}>
                <div>{title}</div>
                <div style={{ fontSize: 10 }}>
                    <FontAwesomeIcon
                        icon={["fal", minimize ? "window-restore" : "window-minimize"]}
                        onClick={() => setMinimize(!minimize)}
                        style={{ cursor: "pointer" }}
                    />
                    {closable && (
                        <FontAwesomeIcon
                            icon={["fal", "window-close"]}
                            onClick={onCloseClick}
                            style={{ marginLeft: 8, cursor: "pointer" }}
                        />
                    )}
                </div>
            </div>
            <div style={{ overflowY: "scroll", display: minimize ? "none" : "block", position: "relative", top: 0, color: "#fff" }}>
                {children}
            </div>
        </div>
    );
};

export default Panel;
