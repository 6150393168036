import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { agent, IAlertMethod, ICreateAlertMethod, RootStoreContext } from "api";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { PreventDeletion } from "features/settings/organisations/organisation-management/PreventDeletion";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import AlertsMethodsForm from "./Components/AlertMethodsForm";
import { useScheduler } from "hooks";

const AlertMethodTableRow = observer(function AlertMethodTableRow(props: { alertMethod: IAlertMethod; notificationValue: string }) {
    const { alertMethod, notificationValue } = props;
    const rootStore = useContext(RootStoreContext);
    const { getOrganisationAlertMethods, updateOrganisationAlertMethod } = useScheduler();
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { getUser } = rootStore.userStore;
    const { openDrawer } = rootStore.drawerStore;
    const [error, setError] = useState("");

    const onUpdateAlertMethod = (body: ICreateAlertMethod, id: string) => {
        updateOrganisationAlertMethod(body, id).catch(Error);
    };

    const deleteAlertMethod = (alert_method_id: number, alert_method_name: string) => {
        openDialog(
            <>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Box>Are you sure you want to delete alert notification method "{alert_method_name}" ?</Box>
                    {error && (
                        <Alert severity="error" sx={{ marginTop: 2 }}>
                            <AlertTitle>{error}</AlertTitle>
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            agent
                                .getCleanInstance()
                                .delete(`/organisation/alert-methods/${alert_method_id}`)
                                .then((apiResult: any) => {
                                    if (apiResult.status === 200) {
                                        getOrganisationAlertMethods();
                                        getUser();
                                        closeDialog();
                                    } else {
                                        if (apiResult.data && apiResult.data.hasOwnProperty("message")) {
                                            setError(apiResult.data.message);
                                        } else {
                                            setError(`Unexpected result from API with status code ${apiResult.status}`);
                                        }
                                    }
                                })
                        }>
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };

    return (
        <TableRow key={alertMethod.id}>
            <TableCell>{alertMethod.name}</TableCell>
            <TableCell>{notificationValue}</TableCell>
            <TableCell>{alertMethod.has_alerts ? "Yes" : "No"}</TableCell>
            <TableCell>{alertMethod.is_system_alert ? "Yes" : "No"}</TableCell>
            <TableCell>
                <Stack spacing={1} direction="row-reverse">
                    <Tooltip title="Edit">
                        <IconButton
                            aria-label="edit"
                            onClick={() =>
                                openDrawer(
                                    <Box sx={{ width: 680, padding: 3 }}>
                                        <AlertsMethodsForm edit={true} alertValues={alertMethod} handleAction={onUpdateAlertMethod} />
                                    </Box>
                                )
                            }>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                alertMethod.has_alerts
                                    ? openDialog(<PreventDeletion />)
                                    : deleteAlertMethod(alertMethod.id, alertMethod.name);
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
});

const NotificationMethods = observer(function NotificationMethods() {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const theme = useTheme();
    const { alertMethods } = rootStore.schedulerStore;
    const { getOrganisationAlertMethods, createOrganisationAlertMethod } = useScheduler();
    const { openDrawer } = rootStore.drawerStore;

    useEffect(() => {
        getOrganisationAlertMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreateAlertMethod = (body: ICreateAlertMethod) => {
        createOrganisationAlertMethod(body).catch(Error);
    };

    const emailAlertMethods = alertMethods ? alertMethods.data.filter((row: IAlertMethod) => row.alert_type === "email") : [];
    const slackAlertMethods = alertMethods ? alertMethods.data.filter((row: IAlertMethod) => row.alert_type === "slack") : [];

    return (
        <>
            {user?.missing_system_alerts === true && (
                <Card sx={{ p: 2, m: 2, color: "red" }}>
                    <Stack direction={"row"} sx={{ flexWrap: "wrap", alignItems: "center", mb: 1 }}>
                        <ErrorOutlinedIcon sx={{ fontSize: "2.5rem", mr: 1 }} />
                        <Typography variant="h5" gutterBottom>
                            Missing System Alert Notification Method
                        </Typography>
                    </Stack>
                    <Typography>
                        Currently there are no alert notification methods marked for receiving system notifications. System notifications
                        like exceeded maximum source bitrate warnings or issues with environments will not be sent. If you would like to
                        receive these messages by a dedicated alert notification method, please either mark an existing one for system
                        alerts or a create a new one.
                    </Typography>
                </Card>
            )}

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    m: 2
                }}>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ height: "2.5rem", width: "14rem" }}
                    onClick={() =>
                        openDrawer(
                            <Box sx={{ width: 680, padding: 3 }}>
                                <AlertsMethodsForm edit={false} handleAction={onCreateAlertMethod} />
                            </Box>
                        )
                    }
                    startIcon={<AddCircleIcon />}>
                    Create Alert Method
                </Button>
            </Box>
            {emailAlertMethods.length > 0 && (
                <Card sx={{ p: 2, m: 2 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Stack direction={"row"} sx={{ flexWrap: "wrap", alignItems: "center" }}>
                                            <EmailOutlinedIcon sx={{ fontSize: "2.5rem", mr: 1 }} />
                                            <Typography variant="h5" gutterBottom>
                                                Email Notification Methods
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="20%">Name</TableCell>
                                    <TableCell sx={{ minWidth: "44rem", width: "45%" }}>Email</TableCell>
                                    <TableCell width="10%">Is used?</TableCell>
                                    <TableCell width="10%">Is system?</TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {emailAlertMethods.map((row: IAlertMethod) => (
                                    <AlertMethodTableRow
                                        alertMethod={row}
                                        notificationValue={row.notification_parameters.email.join(", ")}
                                        key={row.id}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            )}
            {slackAlertMethods.length > 0 && (
                <Card sx={{ p: 2, m: 2 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Stack direction={"row"} sx={{ flexWrap: "wrap", alignItems: "center", mb: 0.5 }}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: "28px",
                                                    width: "106px",
                                                    mr: 1
                                                }}
                                                alt="Slack logo"
                                                src={require("assets/img/slack-logo-white.png")}
                                            />
                                            <Typography variant="h5" gutterBottom>
                                                Notification Methods
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="20%">Name</TableCell>
                                    <TableCell sx={{ minWidth: "44rem", width: "45%" }}>Slack Webhook</TableCell>
                                    <TableCell width="10%">Is used?</TableCell>
                                    <TableCell width="10%">Is system?</TableCell>
                                    <TableCell width="10%"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {slackAlertMethods.map((row: IAlertMethod) => (
                                    <AlertMethodTableRow
                                        alertMethod={row}
                                        notificationValue={row.notification_parameters.slack.join(", ")}
                                        key={row.id}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            )}
        </>
    );
});

export default NotificationMethods;
