/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from "react";
import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    MenuItem,
    Stack,
    Switch,
    Tab,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import { observer } from "mobx-react-lite";
import "../titanFile/styles.scss";
import JobCreator from "./JobCreator";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import { Button, ButtonGroup, Checkbox } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import { agent, IFileClusterDeployment, IFileClusterDeployments, RootStoreContext } from "api";
import FinishedJobs from "./Components/FinishedJobs";
import TranscodingJobs from "./Components/TranscodingJobs";
import AbortedJobs from "./Components/AbortedJobs";
import PendingJobs from "./Components/PendingJobs";
import PausedJobs from "./Components/PausedJobs";
import FailedJobs from "./Components/FailedJobs";
import DeletedJobs from "./Components/DeletedJobs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ProgressChip from "components/ProgressChip";
import Content from "components/Layout/Content";
import { Add, Check, Delete, DriveFileRenameOutline, Lock, PlayArrow, Stop, Upgrade, Warning } from "@mui/icons-material";
import CreateDeploymentForm from "features/titanFile/Components/CreateDeploymentForm";
import SidebarTabs, { SidebarTab, SidebarTabPanel } from "components/SidebarTabs";
import ConfirmDeleteCluster from "./Components/ConfirmDeleteCluster";
import ConfirmStopCluster from "./Components/ConfirmStopCluster";
import ConfirmStartCluster from "./Components/ConfirmStartCluster";
import { NodeHeader } from "components/Nodes";
import CreateCredentialsForm from "./Components/CreateCredentialsForm";
import CredentialsList from "./Components/CredentialsList";
import ConfirmUpgrade from "./Components/ConfirmUpgrade";
import { useFileCluster } from "hooks";
import RenameClusterForm from "./Components/RenameClusterForm";

export const TitanFile = observer(function TitanFile() {
    // SIDEBAR MENU FUNCTIONS ===================================================
    const [collapseSidebar, setCollapseSidebar] = React.useState<boolean>(true);
    const [activeSidebarTab, setActiveSidebarTab] = React.useState<number | null>();

    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState<Date>(new Date());
    const [activeTab, setActiveTab] = useState(0);

    //DEPLOYMENTS =================================================================
    const [deployments, setDeployments] = useState<IFileClusterDeployments>();
    const [deletedDeployments, setDeletedDeployments] = useState<IFileClusterDeployments>();
    const [activeDeploymentId, setActiveDeploymentId] = useState<string>("");
    const [activeDeployment, setActiveDeployment] = useState<IFileClusterDeployment>({} as IFileClusterDeployment);
    const [showDeletedClusters, setShowDeletedClusters] = useState(false);

    const getDeployments = async () => {
        await agent.FileCluster.getCurrentDeployments().then((response) => {
            setDeployments(response);
        });
    };

    const getDeletedDeployments = async () => {
        if (!deletedDeployments) {
            setLoading(true);
        }
        await agent.FileCluster.getDeletedDeployments().then((response) => {
            setDeletedDeployments(response);
            setLoading(false);
        });
    };

    useEffect(() => {
        getDeployments();
        if (showDeletedClusters) {
            getDeletedDeployments();
        }
    }, [update, activeTab, showDeletedClusters]);

    const { setActiveDeploymentName, setActiveDeploymentStatus } = rootStore.fileClusterStore;

    const getActiveDeployment = async () => {
        await agent.FileCluster.getDeployment(activeDeploymentId).then((response) => {
            setActiveDeployment(response.data);
            setUpdate(new Date());
        });
    };

    useEffect(() => {
        if (activeDeploymentId) {
            getActiveDeployment();
        }
    }, [activeDeploymentId]);

    const defaultList = [0];
    const [selectedJobs, setSelectedJobs] = useState(defaultList);

    const handleSelectDeployment = (id: string, name: string, status: string, deployment: IFileClusterDeployment) => {
        setActiveTab(6);
        setActiveDeployment(deployment);
        setActiveDeploymentId(id);
        setActiveDeploymentName(name);
        setActiveDeploymentStatus(status);
        setPendingJobsOffset(0);
        setFinishedJobsOffset(0);
        setTranscodingJobsOffset(0);
        setStoppedJobsOffset(0);
        setFailedJobsOffset(0);
        setAbortedJobsOffset(0);
        //timeout
        setTimeout(() => {
            setActiveTab(0);
        }, 100);
    };

    //DEPLOYMENTS =================================================================

    //JOBS ========================================================================
    const [invalidJson, setInvalidJson] = useState<boolean>();

    const {
        newJob,
        setTranscodingJobsOffset,
        setPendingJobsOffset,
        setFailedJobsOffset,
        setFinishedJobsOffset,
        setStoppedJobsOffset,
        setAbortedJobsOffset,
        transcodingJobsOffset,
        transcodingJobsSort,
        pendingJobsOffset,
        pendingJobsSort,
        failedJobsOffset,
        failedJobsSort,
        finishedJobsOffset,
        finishedJobsSort,
        stoppedJobsOffset,
        stoppedJobsSort,
        abortedJobsOffset,
        abortedJobsSort,
        searchTerm
    } = rootStore.fileClusterStore;
    const { getFailedJobs, getAbortedJobs, getStoppedJobs, getPendingJobs, startJob, stopJob, deleteJob, setJobPriority } =
        useFileCluster();

    useEffect(() => {}, [activeDeploymentId, setTranscodingJobsOffset, transcodingJobsOffset, transcodingJobsSort, getPendingJobs, setPendingJobsOffset, pendingJobsOffset, pendingJobsSort, getFailedJobs, setFailedJobsOffset, failedJobsOffset, failedJobsSort, setFinishedJobsOffset, finishedJobsOffset, finishedJobsSort, getStoppedJobs, setStoppedJobsOffset, stoppedJobsOffset, stoppedJobsSort, getAbortedJobs, setAbortedJobsOffset, abortedJobsOffset, abortedJobsSort, startJob, stopJob, deleteJob, setJobPriority, newJob, searchTerm, selectedJobs, setSelectedJobs, invalidJson, setInvalidJson]);

    useEffect(() => {
        const interval = setInterval(() => {
            getDeployments();
            //setUpdate(new Date());
        }, 10000);
        const interval2 = setInterval(() => {
            if (activeDeploymentId) {
                getActiveDeployment();
                setUpdate(new Date());
            }
        }, 2000);
        return () => {
            clearInterval(interval);
            clearInterval(interval2);
        };
    }, [activeDeploymentId]);

    useEffect(() => {}, [activeDeploymentId]);

    const handleTabChange = (event: any, data: any) => {
        setActiveTab(data);
    };

    useEffect(() => {}, [activeTab]);

    //JOBS ========================================================================

    const { openDialog } = rootStore.dialogStore;

    const calculateActiveJobsCount = (activeDeployment: IFileClusterDeployment) => {
        let activeJobs = 0;
        if (activeDeployment) {
            activeJobs =
                activeDeployment.transcoding_jobs +
                activeDeployment.pending_jobs +
                activeDeployment.stopped_jobs +
                activeDeployment.failed_jobs +
                activeDeployment.aborted_jobs;
        }

        return activeJobs;
    };

    return (
        <View loading={loading}>
            <ViewHeader title="File Transcoding" />
            <ViewBody noPadding>
                <Content
                    leftWidth={260}
                    leftContent={
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "stretch",
                                height: "100%"
                            }}>
                            <ListSubheader>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    <Box>Clusters</Box>
                                    <Box>
                                        <IconButton
                                            onClick={() => {
                                                openDialog(<CreateDeploymentForm />);
                                            }}>
                                            <Add />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </ListSubheader>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    overflowY: "auto"
                                }}>
                                {deployments && deployments.data.length === 0 && !showDeletedClusters && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            height: "100%",
                                            p: 2
                                        }}>
                                        <Alert severity="info">
                                            <AlertTitle>No Active Clusters</AlertTitle>
                                            <Box sx={{ mb: 2 }}>To get started, create a new cluster or show deleted clusters</Box>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    openDialog(<CreateDeploymentForm />);
                                                }}>
                                                Create Cluster
                                            </Button>
                                        </Alert>
                                    </Box>
                                )}
                                {deployments && (
                                    <>
                                        <List sx={{ py: 0 }}>
                                            {deployments?.data
                                                .filter((deploymentItem) => deploymentItem.status !== "deleted")
                                                .map((deploymentItem, i) => {
                                                    return (
                                                        <ListItem
                                                            key={i}
                                                            disablePadding
                                                            sx={{
                                                                mb: "2px",
                                                                borderLeftWidth: "4px",
                                                                borderLeftStyle: "solid",
                                                                borderLeftColor:
                                                                    deploymentItem.status === "running"
                                                                        ? theme.palette.success.main
                                                                        : deploymentItem.status === "stopped"
                                                                        ? theme.palette.error.main
                                                                        : "warning.main"
                                                            }}>
                                                            <ListItemButton
                                                                selected={deploymentItem.id === activeDeploymentId}
                                                                onClick={() =>
                                                                    handleSelectDeployment(
                                                                        deploymentItem.id,
                                                                        deploymentItem.name,
                                                                        deploymentItem.status,
                                                                        deploymentItem
                                                                    )
                                                                }>
                                                                <Box sx={{ flexGrow: 1, width: "100%" }}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        fontWeight="bold"
                                                                        noWrap
                                                                        textOverflow="ellipsis">
                                                                        {deploymentItem.name}
                                                                    </Typography>
                                                                    <Typography variant="body2" noWrap textTransform="capitalize">
                                                                        Status: {deploymentItem.status}{" "}
                                                                        {deploymentItem.status === "creating" &&
                                                                            " - Step " +
                                                                                deploymentItem.current_step +
                                                                                "/" +
                                                                                deploymentItem.total_steps}
                                                                    </Typography>
                                                                    <Typography variant="body2" noWrap>
                                                                        Job Queue:{" "}
                                                                        {deploymentItem.pending_jobs + deploymentItem.transcoding_jobs}
                                                                    </Typography>
                                                                    <Typography variant="body2" noWrap>
                                                                        Uptime: {deploymentItem.uptime || "Offline"}
                                                                    </Typography>
                                                                </Box>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                                })}
                                        </List>
                                        <List sx={{ py: 0 }}>
                                            {showDeletedClusters &&
                                                deletedDeployments?.data
                                                    .filter((deploymentItem) => deploymentItem.status === "deleted")
                                                    .map((deploymentItem, i) => {
                                                        return (
                                                            <ListItem
                                                                key={i}
                                                                disablePadding
                                                                sx={{
                                                                    mb: "2px",
                                                                    borderLeftWidth: "4px",
                                                                    borderLeftStyle: "solid",
                                                                    borderLeftColor: theme.palette.action.hover,
                                                                    opacity: 0.8
                                                                }}>
                                                                <ListItemButton
                                                                    selected={deploymentItem.id === activeDeploymentId}
                                                                    onClick={() =>
                                                                        handleSelectDeployment(
                                                                            deploymentItem.id,
                                                                            deploymentItem.name,
                                                                            deploymentItem.status,
                                                                            deploymentItem
                                                                        )
                                                                    }>
                                                                    <Box sx={{ flexGrow: 1, width: "100%" }}>
                                                                        <Typography
                                                                            variant="body1"
                                                                            fontWeight="bold"
                                                                            noWrap
                                                                            textOverflow="ellipsis">
                                                                            {deploymentItem.name}
                                                                        </Typography>
                                                                        <Typography variant="body2" noWrap textTransform="capitalize">
                                                                            Status: {deploymentItem.status}
                                                                        </Typography>
                                                                        <Typography variant="body2" noWrap>
                                                                            Job Queue:{" "}
                                                                            {deploymentItem.pending_jobs + deploymentItem.transcoding_jobs}
                                                                        </Typography>
                                                                        <Typography variant="body2" noWrap>
                                                                            Uptime: {deploymentItem.uptime || "Offline"}
                                                                        </Typography>
                                                                    </Box>
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    })}
                                        </List>
                                    </>
                                )}
                            </Box>
                            <MenuItem
                                onClick={() => setShowDeletedClusters(!showDeletedClusters)}
                                sx={{ backgroundColor: theme.palette.action.hover, mt: "1px" }}>
                                {/* <ListItemIcon>
                                    <DeleteSweep />
                                </ListItemIcon> */}
                                <ListItemText secondary="Show Deleted Clusters" />
                                <Switch
                                    sx={{ ml: 3 }}
                                    size="small"
                                    checked={showDeletedClusters}
                                    onChange={() => setShowDeletedClusters(!showDeletedClusters)}
                                />
                            </MenuItem>
                        </Box>
                    }
                    headerContent={
                        activeDeployment &&
                        activeDeployment.name && (
                            <Box
                                sx={{
                                    py: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                    <Typography variant="h5" sx={{ mr: 1.5 }}>
                                        {activeDeployment.name}
                                    </Typography>
                                    {(() => {
                                        switch (activeDeployment.ui_status) {
                                            case "starting":
                                                return <ProgressChip label={activeDeployment.ui_status} />;
                                            case "running":
                                                return (
                                                    <Chip
                                                        sx={{ textTransform: "capitalize" }}
                                                        label={activeDeployment.ui_status}
                                                        size="small"
                                                        color="success"
                                                    />
                                                );
                                            case "stopping":
                                                return <ProgressChip color="warning" label={activeDeployment.ui_status} />;
                                            case "creating":
                                                return (
                                                    <ProgressChip
                                                        label={
                                                            activeDeployment.ui_status +
                                                            " - Step " +
                                                            activeDeployment.current_step +
                                                            "/" +
                                                            activeDeployment.total_steps
                                                        }
                                                    />
                                                );
                                            case "stopped":
                                                return (
                                                    <Chip
                                                        label={activeDeployment.ui_status}
                                                        size="small"
                                                        color="error"
                                                        sx={{ textTransform: "capitalize" }}
                                                    />
                                                );
                                            case "deleting":
                                                return <ProgressChip label={activeDeployment.ui_status} />;
                                            case "deleted":
                                                return (
                                                    <Chip
                                                        sx={{ textTransform: "capitalize" }}
                                                        disabled
                                                        label={activeDeployment.ui_status}
                                                        size="small"
                                                    />
                                                );
                                            case "upgrading":
                                                return <ProgressChip label={activeDeployment.ui_status} />;
                                            default:
                                                return (
                                                    <Chip
                                                        sx={{ textTransform: "capitalize" }}
                                                        label={activeDeployment.ui_status}
                                                        size="small"
                                                    />
                                                );
                                        }
                                    })()}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center"
                                    }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{ marginRight: "10px" }}
                                        disabled={activeDeployment.status !== "running" && activeDeployment.status !== "upgrading"}
                                        onClick={() =>
                                            openDialog(
                                                <JobCreator
                                                    deployment_id={activeDeploymentId}
                                                    onJobCreated={() => {
                                                        getPendingJobs(activeDeploymentId);
                                                        setActiveTab(1);
                                                    }}
                                                />,
                                                undefined,
                                                "lg"
                                            )
                                        }
                                        startIcon={<AddCircleIcon />}>
                                        Create Job
                                    </Button>
                                    <ButtonGroup size="large" variant="outlined" sx={{ height: 38.5 }}>
                                        {activeDeployment.status === "stopped" && (
                                            <Button
                                                onClick={() => {
                                                    openDialog(
                                                        <ConfirmStartCluster
                                                            cluster_id={activeDeployment.id}
                                                            cluster_name={activeDeployment.name}
                                                            onSuccess={() => {
                                                                getActiveDeployment();
                                                            }}
                                                        />,
                                                        "Start Cluster " + activeDeployment.name
                                                    );
                                                }}>
                                                <PlayArrow />
                                            </Button>
                                        )}
                                        {activeDeployment.status === "running" && calculateActiveJobsCount(activeDeployment) === 0 && (
                                            <Button
                                                onClick={() => {
                                                    openDialog(
                                                        <ConfirmStopCluster
                                                            cluster_id={activeDeployment.id}
                                                            cluster_name={activeDeployment.name}
                                                        />,
                                                        "Stop Cluster " + activeDeployment.name
                                                    );
                                                }}>
                                                <Stop />
                                            </Button>
                                        )}
                                        {activeDeployment.status !== "deleted" &&
                                            activeDeployment.status !== "deleting" &&
                                            calculateActiveJobsCount(activeDeployment) === 0 && (
                                                <Button
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmDeleteCluster
                                                                cluster_id={activeDeployment.id}
                                                                cluster_name={activeDeployment.name}
                                                            />,
                                                            "Delete Cluster " + activeDeployment.name
                                                        );
                                                    }}>
                                                    <Delete />
                                                </Button>
                                            )}
                                    </ButtonGroup>
                                </Box>
                            </Box>
                        )
                    }
                    //rightWidth={collapseSidebar ? 51 : 335}
                    rightWidth={51}
                    rightContent={
                        activeDeployment &&
                        activeDeployment.name && (
                            <ClickAwayListener
                                onClickAway={() => {
                                    setCollapseSidebar(true);
                                    setActiveSidebarTab(null);
                                }}>
                                <Box
                                    sx={{
                                        position: "fixed",
                                        top: 65,
                                        right: 0,
                                        bottom: 0,
                                        width: collapseSidebar ? 51 : 335,
                                        overflow: "visible",
                                        zIndex: 1,
                                        backgroundColor: theme.palette.contentSidebar?.main
                                    }}>
                                    <SidebarTabs
                                        tabs={
                                            <>
                                                <SidebarTab
                                                    label="Cluster Settings"
                                                    icon={<SettingsIcon />}
                                                    active={!collapseSidebar && activeSidebarTab === 1}
                                                    onClick={() => {
                                                        setActiveSidebarTab(1);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                                <SidebarTab
                                                    label="Cluster Credentials"
                                                    icon={<Lock />}
                                                    active={!collapseSidebar && activeSidebarTab === 2}
                                                    onClick={() => {
                                                        setActiveSidebarTab(2);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                            </>
                                        }
                                        panels={
                                            <>
                                                <SidebarTabPanel active={!collapseSidebar && activeSidebarTab === 1}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "flex-start",
                                                            alignItems: "stretch",
                                                            height: "100%"
                                                        }}>
                                                        <NodeHeader label="Cluster Settings" />
                                                        <Box
                                                            sx={{
                                                                flexGrow: 1,
                                                                overflowY: "auto"
                                                            }}>
                                                            <Stack spacing={2} p={2}>
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Name
                                                                    </Typography>
                                                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                                                        <Typography variant="h5" sx={{ wordBreak: "break-word" }}>
                                                                            {activeDeployment.name}
                                                                        </Typography>
                                                                        <Tooltip title="Rename Cluster">
                                                                            <IconButton
                                                                                sx={{ ml: 1 }}
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    openDialog(
                                                                                        <RenameClusterForm id={activeDeployment.id} />
                                                                                    );
                                                                                }}>
                                                                                <DriveFileRenameOutline fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>

                                                                {activeDeployment.status === "running" && (
                                                                    <>
                                                                        <Divider />
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                alignItems: "center"
                                                                            }}>
                                                                            <Avatar
                                                                                sx={{
                                                                                    backgroundColor:
                                                                                        activeDeployment.cluster_health_problems
                                                                                            ? theme.palette.warning.main
                                                                                            : theme.palette.success.main,
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    mr: 2
                                                                                }}>
                                                                                {activeDeployment.cluster_health_problems ? (
                                                                                    <Warning sx={{ fontSize: 16 }} />
                                                                                ) : (
                                                                                    <Check sx={{ fontSize: 16 }} />
                                                                                )}
                                                                            </Avatar>
                                                                            <Box>
                                                                                <Typography variant="body2" color="text.secondary">
                                                                                    Cluster Health
                                                                                </Typography>
                                                                                <Typography variant="body1">
                                                                                    {activeDeployment.cluster_health_problems
                                                                                        ? activeDeployment.cluster_health_problems
                                                                                        : "No Issues Found"}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </>
                                                                )}

                                                                <Divider />
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Status
                                                                    </Typography>
                                                                    <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
                                                                        {activeDeployment.status}
                                                                    </Typography>
                                                                </Box>
                                                                {activeDeployment.status === "running" && (
                                                                    <>
                                                                        <Box>
                                                                            <Typography variant="body2" color="text.secondary">
                                                                                Uptime
                                                                            </Typography>
                                                                            <Typography variant="body1">
                                                                                {activeDeployment.uptime}
                                                                            </Typography>
                                                                        </Box>
                                                                    </>
                                                                )}

                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Active Jobs
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {calculateActiveJobsCount(activeDeployment)}
                                                                    </Typography>
                                                                </Box>

                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Cluster Name
                                                                    </Typography>
                                                                    <Typography variant="body1">{activeDeployment.cluster_name}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        id
                                                                    </Typography>
                                                                    <Typography variant="body1">{activeDeployment.id}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Cloud Provider
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {activeDeployment.cloud_provider_human_name}
                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Region
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {activeDeployment.region_human_name}
                                                                    </Typography>
                                                                </Box>
                                                                {/* Product Version */}
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Product Version
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {activeDeployment.product_version}
                                                                    </Typography>
                                                                    {activeDeployment.product_version === "outdated" && (
                                                                        <Button
                                                                            sx={{ mt: 0.5 }}
                                                                            size="small"
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            disabled={activeDeployment.status === "upgrading"}
                                                                            startIcon={<Upgrade />}
                                                                            onClick={() => {
                                                                                openDialog(
                                                                                    <ConfirmUpgrade
                                                                                        cluster_id={activeDeployment.id}
                                                                                        cluster_name={activeDeployment.name}
                                                                                    />,
                                                                                    "Upgrade Cluster " + activeDeployment.name
                                                                                );
                                                                            }}>
                                                                            Upgrade
                                                                        </Button>
                                                                    )}
                                                                </Box>
                                                                {/* Stack Version */}
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Stack Version
                                                                    </Typography>
                                                                    <Typography variant="body1">
                                                                        {activeDeployment.stack_version}
                                                                    </Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Box sx={{ p: 2 }}>
                                                            <Stack spacing={1} alignItems="center">
                                                                {activeDeployment.status === "stopped" && (
                                                                    <Button
                                                                        onClick={() => {
                                                                            openDialog(
                                                                                <ConfirmStartCluster
                                                                                    cluster_id={activeDeployment.id}
                                                                                    cluster_name={activeDeployment.name}
                                                                                    onSuccess={() => {
                                                                                        getActiveDeployment();
                                                                                    }}
                                                                                />,
                                                                                "Start Cluster " + activeDeployment.name
                                                                            );
                                                                        }}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        fullWidth
                                                                        startIcon={<PlayArrow />}>
                                                                        Start Cluster
                                                                    </Button>
                                                                )}
                                                                {activeDeployment.status === "running" && (
                                                                    <Button
                                                                        onClick={() => {
                                                                            openDialog(
                                                                                <ConfirmStopCluster
                                                                                    cluster_id={activeDeployment.id}
                                                                                    cluster_name={activeDeployment.name}
                                                                                />,
                                                                                "Stop Cluster " + activeDeployment.name
                                                                            );
                                                                        }}
                                                                        disabled={calculateActiveJobsCount(activeDeployment) > 0}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        fullWidth
                                                                        startIcon={<Stop />}>
                                                                        Stop Cluster
                                                                    </Button>
                                                                )}
                                                                {activeDeployment.status !== "deleted" && (
                                                                    <Button
                                                                        onClick={() => {
                                                                            openDialog(
                                                                                <ConfirmDeleteCluster
                                                                                    cluster_id={activeDeployment.id}
                                                                                    cluster_name={activeDeployment.name}
                                                                                />,
                                                                                "Delete Cluster " + activeDeployment.name
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            calculateActiveJobsCount(activeDeployment) > 0 ||
                                                                            activeDeployment.status === "deleting"
                                                                        }
                                                                        variant="contained"
                                                                        color="error"
                                                                        fullWidth
                                                                        startIcon={<Delete />}>
                                                                        Delete Cluster
                                                                    </Button>
                                                                )}
                                                            </Stack>
                                                        </Box>
                                                    </Box>
                                                </SidebarTabPanel>
                                                <SidebarTabPanel active={!collapseSidebar && activeSidebarTab === 2}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "flex-start",
                                                            alignItems: "stretch",
                                                            height: "100%"
                                                        }}>
                                                        <NodeHeader label="Cluster Credentials" />
                                                        <Box
                                                            sx={{
                                                                flexGrow: 1,
                                                                overflowY: "auto"
                                                            }}>
                                                            <Stack spacing={2} p={2}>
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        OIDC ID
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                                                                        {activeDeployment.oidc_id}
                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        OIDC URL
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                                                                        {activeDeployment.oidc_url}
                                                                    </Typography>
                                                                </Box>
                                                                <Divider />
                                                            </Stack>
                                                            <CredentialsList cluster_id={activeDeployment.id} />
                                                        </Box>
                                                        <Box sx={{ p: 2 }}>
                                                            <Button
                                                                onClick={() => {
                                                                    openDialog(
                                                                        <CreateCredentialsForm
                                                                            deployment_id={activeDeployment.id}
                                                                            oidc={activeDeployment.oidc_id}
                                                                            oidc_url={activeDeployment.oidc_url}
                                                                        />
                                                                    );
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth
                                                                startIcon={<Add />}>
                                                                Add Credentials
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </SidebarTabPanel>
                                            </>
                                        }
                                        collapsible={true}
                                        collapsed={collapseSidebar}
                                        onCollapse={() => setCollapseSidebar(!collapseSidebar)}
                                    />
                                </Box>
                            </ClickAwayListener>
                        )
                    }>
                    {!activeDeploymentId && (
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <Alert severity="info">
                                <Box>Please select a cluster to view jobs and settings.</Box>
                            </Alert>
                        </Box>
                    )}

                    {activeDeploymentId && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "stretch",
                                height: "100%"
                            }}>
                            <TabContext value={activeTab.toString()}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                    <Tab label={"Transcoding (" + activeDeployment.transcoding_jobs + ")"} value="0" />
                                    <Tab label={"Pending (" + activeDeployment.pending_jobs + ")"} value="1" />
                                    <Tab label={"Aborted (" + activeDeployment.aborted_jobs + ")"} value="2" />
                                    <Tab label={"Paused (" + activeDeployment.stopped_jobs + ")"} value="3" />
                                    <Tab label={"Failed (" + activeDeployment.failed_jobs + ")"} value="4" />
                                    <Tab label={"Finished (" + activeDeployment.finished_jobs + ")"} value="5" />
                                    <Tab label={"Deleted (" + activeDeployment.deleted_jobs + ")"} value="6" />
                                </TabList>
                                <TabPanel value="0" sx={{ flexGrow: 1, overflowY: "auto" }}>
                                    <TranscodingJobs pageSize={50} deployment_id={activeDeployment.id} />
                                </TabPanel>
                                <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "auto" }}>
                                    <PendingJobs pageSize={50} deployment_id={activeDeployment.id} />
                                </TabPanel>
                                <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "auto" }}>
                                    <AbortedJobs pageSize={50} deployment_id={activeDeployment.id} />
                                </TabPanel>
                                <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "auto" }}>
                                    <PausedJobs pageSize={50} deployment_id={activeDeployment.id} />
                                </TabPanel>
                                <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "auto" }}>
                                    <FailedJobs pageSize={50} deployment_id={activeDeployment.id} />
                                </TabPanel>
                                <TabPanel value="5" sx={{ flexGrow: 1, overflowY: "auto" }}>
                                    <FinishedJobs pageSize={50} deployment_id={activeDeployment.id} />
                                </TabPanel>
                                <TabPanel value="6" sx={{ flexGrow: 1, overflowY: "auto" }}>
                                    <DeletedJobs pageSize={50} deployment_id={activeDeployment.id} />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    )}
                </Content>
            </ViewBody>
        </View>
    );
});

export default TitanFile;
