import { Box, FormControlLabel, Switch, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { RootStoreContext } from "api";
import Content from "components/Layout/Content";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

const UserPreferences: React.FC = () => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { user, updateUserPreferences, getUser } = rootStore.userStore;

    useEffect(() => {
        if (user && !user.user_preferences?.homepage) {
            updateUserPreferences({ homepage: "dashboard" });
        }
    }, [user]);

    return (
        <Content>
            <Box sx={{ mb: 5, maxWidth: 600 }}>
                <Typography variant="h5">Theme</Typography>
                <Typography variant="body2" color="text.secondary" mb={3}>
                    {/* Describe the advantages of dark mode and light mode */}
                    Dark mode is easier on the eyes and can help reduce eye strain. Light mode can help improve focus and productivity
                </Typography>
                <ToggleButtonGroup
                    value={user?.user_preferences?.theme}
                    exclusive
                    onChange={(e, value) => updateUserPreferences({ theme: value })}>
                    <ToggleButton value="light">Light Mode</ToggleButton>
                    <ToggleButton value="dark">Dark Mode</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box sx={{ mb: 5, maxWidth: 600 }}>
                <Typography variant="h5" mb={2}>
                    Notifications
                </Typography>
                <Typography variant="h6">Silence Notifications</Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                    {/* Describe the advantages of silencing notifications */}
                    Silencing notifications can help reduce distractions and improve focus. Notifcations will still be available but you
                    will not be alerted.
                </Typography>
                <FormControlLabel
                    sx={{ mb: 3 }}
                    control={
                        <Switch
                            color="warning"
                            checked={user?.user_preferences?.silent_notifications}
                            onChange={(e) => updateUserPreferences({ silent_notifications: e.target.checked })}
                        />
                    }
                    label="Silence Notifications"
                />
                <Typography variant="h6">Desktop Notifications</Typography>
                <Typography variant="body2" color="text.secondary" mb={1}>
                    {/* Describe the advantages of desktop notifications */}
                    Desktop notifications can help you stay up to date with important information and updates. You will recieve a
                    notification on your desktop even when you are not actively using the application.
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={user?.user_preferences?.desktop_notifications}
                            onChange={(e) => updateUserPreferences({ desktop_notifications: e.target.checked })}
                        />
                    }
                    label="Enable Desktop Notifications"
                />
            </Box>
            <Box sx={{ mb: 4, maxWidth: 600 }}>
                <Typography variant="h5">Homepage</Typography>
                <Typography variant="body2" color="text.secondary" mb={3}>
                    {/* Describe the advantages of setting a homepage */}
                    Set your homepage to quickly access the page you use most often. This can help you save time and improve your
                    productivity.
                </Typography>
                <ToggleButtonGroup
                    value={user?.user_preferences?.homepage}
                    exclusive
                    onChange={(e, value) => updateUserPreferences({ homepage: value })}>
                    <ToggleButton value="dashboard">Dashboard</ToggleButton>
                    <ToggleButton value="sources">Sources</ToggleButton>
                    <ToggleButton value="destinations">Destinations</ToggleButton>
                    <ToggleButton value="scheduler">Scheduler</ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Content>
    );
};

export default observer(UserPreferences);
