import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import ExportExcel from "./ExportExcel";

interface ICogsHeader {
    breakdown: any;
    id: string | number;
    startDate: Date;
    endDate: Date;
    orgSummary: any;
    total_total_tag_licence_tile_costs: number;
    total_external_zixi_licence_egress_costs: number;
    total_internal_zixi_licence_egress_costs: number;
}

export const CogsHeader: React.FC<ICogsHeader> = ({
    orgSummary,
    breakdown,
    id,
    startDate,
    endDate,
    total_total_tag_licence_tile_costs,
    total_external_zixi_licence_egress_costs,
    total_internal_zixi_licence_egress_costs
}) => {
    if (!breakdown) {
        return <></>;
    }

    return (
        <Grid container columns={{ xs: 16 }} sx={{ alignItems: "center" }} spacing={2}>
            <Grid item xs={2}>
                <Box sx={{ alignItems: "center" }}>
                    <Typography variant="body2" color="text.secondary">
                        Report ID
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                        {id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Start Date
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                        {format(new Date(startDate), "dd-MM-yyyy")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        End Date
                    </Typography>
                    <Typography variant="h6" component="div">
                        {format(new Date(endDate), "dd-MM-yyyy")}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={9}>
                <Grid container columns={{ xs: 5 }}>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderBottom: "1px solid rgba(160,160,160,0.2)",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Disk Storage
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.disk_storage_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                borderBottom: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Elastic IP
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.elastic_ip_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                borderBottom: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Egress
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.egress_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                borderBottom: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Titan File
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.titan_file_cogs_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderBottom: "1px solid rgba(160,160,160,0.2)",
                                borderLeft: { xs: "none", lg: "1px solid rgba(160,160,160,0.2)" },
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Environments
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.running_aws_c_env_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: { xs: "1px solid rgba(160,160,160,0.2)" },
                                borderBottom: { xs: "1px solid rgba(160,160,160,0.2)" },
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Transcoders
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.transcoder_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderBottom: "1px solid rgba(160,160,160,0.2)",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Multiviewers
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.multiviewer_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                borderBottom: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Push Packagers
                            </Typography>
                            <Typography variant="h6" component="div">
                                $
                                {breakdown.push_packager_costs
                                    .map((item) => item.cost_usd)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    {breakdown?.pull_packager_costs && (
                        <Grid item xs={1}>
                            <Box
                                sx={{
                                    px: 2,
                                    py: 1,
                                    alignItems: "flex-start",
                                    height: "100%",
                                    borderLeft: "1px solid rgba(160,160,160,0.2)",
                                    borderBottom: "1px solid rgba(160,160,160,0.2)",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Pull Packager Transcoders
                                </Typography>
                                <Typography variant="h6" component="div">
                                    $
                                    {breakdown.pull_packager_costs
                                        .map((item) => item.cost_usd)
                                        .reduce((a, b) => a + b, 0)
                                        .toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {breakdown.nea_live_costs && (
                        <Grid item xs={1}>
                            <Box
                                sx={{
                                    px: 2,
                                    py: 1,
                                    alignItems: "flex-start",
                                    height: "100%",
                                    borderLeft: "1px solid rgba(160,160,160,0.2)",
                                    borderBottom: "1px solid rgba(160,160,160,0.2)",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Pull Packager Instances
                                </Typography>
                                <Typography variant="h6" component="div">
                                    $
                                    {breakdown.nea_live_costs
                                        .map((item) => item.cost_usd)
                                        .reduce((a, b) => a + b, 0)
                                        .toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {breakdown?.nea_live_cdn_egress_costs && (
                        <Grid item xs={1}>
                            <Box
                                sx={{
                                    px: 2,
                                    py: 1,
                                    alignItems: "flex-start",
                                    height: "100%",
                                    borderLeft: "1px solid rgba(160,160,160,0.2)",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between"
                                }}>
                                <Typography variant="body2" color="text.secondary">
                                    Pull Packager CDN
                                </Typography>
                                <Typography variant="h6" component="div">
                                    $
                                    {breakdown.nea_live_cdn_egress_costs
                                        .map((item) => item.cost_usd)
                                        .reduce((a, b) => a + b, 0)
                                        .toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Tag Licence Tiles
                            </Typography>
                            <Typography variant="h6" component="div">
                                ${total_total_tag_licence_tile_costs?.toFixed(2) || 0}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                External Zixi Licence Egress
                            </Typography>
                            <Typography variant="h6" component="div">
                                ${total_external_zixi_licence_egress_costs?.toFixed(2) || 0}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            sx={{
                                px: 2,
                                py: 1,
                                alignItems: "flex-start",
                                height: "100%",
                                borderLeft: "1px solid rgba(160,160,160,0.2)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Typography variant="body2" color="text.secondary">
                                Internal Zixi Licence Egress
                            </Typography>
                            <Typography variant="h6" component="div">
                                ${total_internal_zixi_licence_egress_costs?.toFixed(2) || 0}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Box
                    sx={{
                        px: 2,
                        py: 1,
                        alignItems: "center",
                        height: "100%",
                        borderLeft: "1px solid rgba(160,160,160,0.2)"
                    }}>
                    <Typography variant="body2" color="text.secondary">
                        Total Cost
                    </Typography>
                    <Typography variant="h5" component="div">
                        $
                        {orgSummary
                            .map((item) => item.total_cost_usd)
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box
                    sx={{
                        px: 2,
                        py: 1,
                        alignItems: "center",
                        height: "100%",
                        borderLeft: "1px solid rgba(160,160,160,0.2)"
                    }}>
                    <ExportExcel report={breakdown} fileName={id} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default observer(CogsHeader);
