import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, Card, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export const ResetConfirmation = observer(function ResetConfirmation(): JSX.Element {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <Card sx={{ p: 2 }}>
            <DialogTitle>Thank you</DialogTitle>
            <DialogContent>
                <Typography>
                    An email has been sent to you with a verification link confirming your password reset request, please click the
                    verification link to continue.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </DialogActions>
        </Card>
    );
});
