import React, { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import { IContactFormValues, RootStoreContext, agent } from "api";
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { TextField } from "components/Forms";
import { useSourcePermissions } from "hooks";

const AddContactForm = (): JSX.Element => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { getContacts } = useSourcePermissions();

    return (
        <FinalForm
            onSubmit={async (values: IContactFormValues) =>
                await agent.SourcePermissions.addContact(values)
                    .then(() => getContacts())
                    .then(() => {
                        closeDialog();
                    })
                    .catch((error) => {
                        const errors = error.data.errors;
                        return { [FORM_ERROR]: error, ...errors };
                    })
            }
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Add Contact</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <TextField
                                name="name"
                                label="Name"
                                required
                                validate={(value) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                            />

                            <TextField
                                name="email"
                                label="Email"
                                required
                                validate={(value) => {
                                    if (!value) {
                                        return "Required";
                                    }

                                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9._-]+\.[A-Z]{2,64}$/i.test(value)) {
                                        return "Invalid format";
                                    }
                                }}
                            />

                            <TextField
                                name="phone"
                                label="Phone Number"
                                required
                                validate={(value) => {
                                    if (!value) {
                                        return "Required";
                                    }

                                    if (!/^[0-9 +]/i.test(value)) {
                                        return "Invalid format";
                                    }
                                }}
                            />

                            {submitError && !dirtySinceLastSubmit && <Alert severity="error">{submitError?.data?.message}</Alert>}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeDialog()}>Cancel</Button>

                        <Button variant="contained" color="primary" type="submit" disabled={(invalid && !dirtySinceLastSubmit) || pristine}>
                            Add
                        </Button>
                    </DialogActions>
                </form>
            )}
        />
    );
};

export default observer(AddContactForm);
