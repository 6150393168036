import { Box, Button, ButtonBase, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";

interface Props {
    title: string;
    description: string;
    icon: IconName;
    navigateTo: string;
}

export const QuickAccessButton: React.FC<Props> = ({ title, description, icon, navigateTo }) => {
    const navigate = useNavigate();
    return (
        <ButtonBase sx={{ width: "100%" }}
            onClick={() => {
                navigate(navigateTo);
            }}
        >
            <Card sx={{ width: "100%",
                "&:hover": {
                    backgroundColor: theme => theme.palette.action.hover
                }
             }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        p: 1
                    }}>
                    <Box
                        sx={{
                            flex: "0 0 auto",
                            width: 70,
                            height: 70,
                            fontSize: 32,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                        <FontAwesomeIcon icon={["fal", icon]} />
                    </Box>
                    <Box sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}>
                        <Typography variant="h6" lineHeight={1.1}>{title}</Typography>
                        <Typography variant="body2" color="text.secondary">{description}</Typography>
                    </Box>
                </Box>
            </Card>
        </ButtonBase>
    );
};

export default QuickAccessButton;
