import React from "react";

import { Box, Button, Typography } from "@mui/material";

const AccessDeniedFallback: React.FC = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <Typography variant="h3">Access Denied</Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                You do not have permission to access this page.
            </Typography>
            <Typography variant="body1" color="text.secondary">
                Please contact your system administrator for assistance.
            </Typography>
            <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={() => window.history.back()}>
                Go Back
            </Button>
        </Box>
    );
};

export default AccessDeniedFallback;
