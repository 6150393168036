import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";

const theme = createTheme({
    shape: {
        borderRadius: 4
    },
    typography: {
        fontFamily: ["proxima-nova"].join(","),
        fontSize: 12.5,
        h1: {
            fontWeight: 600,
            fontSize: 48
        },
        h2: {
            fontWeight: 600,
            fontSize: 36
        },
        h3: {
            fontWeight: 600,
            fontSize: 28
        },
        h4: {
            fontWeight: 600,
            fontSize: 24
        },
        h5: {
            fontWeight: 600
        }
    },
    palette: {
        mode: "dark",
        sidebar: {
            main: "#0f1419",
            light: "#1E2832",
            contrastText: "#fff"
        },
        sidebarPopup: {
            main: "#182029",
            light: "#1E2832",
            contrastText: "#fff"
        },
        contentSidebar: {
            main: "#171E26",
            light: "#0000001A",
            contrastText: "#fff"
        },
        contentToolbar: {
            main: "#141921"
        },
        contentHeader: {
            main: "#1b222d"
        },
        topbar: {
            main: "#0f1419",
            light: "#13181d",
            contrastText: "#fff"
        },
        scheduleRowStart: {
            main: "#182029",
            dark: "#12191f",
            light: "#13181d",
            contrastText: "#fff"
        },
        body: {
            main: "#161e25"
        },
        background: {
            default: "#1e2832",
            paper: "#0f1419"
        },
        text: {
            primary: "#fff"
        },
        primary: {
            main: "#307abb",
            dark: "#173a59"
        },
        secondary: {
            main: "#36b3a8",
            contrastText: "#fff"
        },
        tertiary: {
            main: "#9e2fb2"
        },
        quaternary: {
            main: "#c0326e"
        },
        quinary: {
            main: "#f9ac17"
        },
        senary: {
            main: "#b3462f"
        },
        septenary: {
            main: "#623be4"
        },
        success: {
            main: "#2eb242"
        },
        error: {
            main: "#ed143d",
            contrastText: "#fff"
        },
        headerBox: {
            main: "#1e2832"
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#0f1419",
                    backgroundImage: "none"
                }
            }
        },

        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    backgroundColor: "#0f1419",
                    borderRadius: 4
                },
                content: {
                    fontWeight: 600,
                    fontSize: 15
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {}
            }
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    "& .MuiBadge-badge": {
                        paddingTop: 1
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    backgroundColor: "#161e25"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    lineHeight: 1
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: 600,
                        fontSize: 14
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#0f1419",
                    backgroundImage: "none"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: 20
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "12px 24px"
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#0f1419",
                    backgroundImage: "none"
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard"
            },
            styleOverrides: {
                root: {
                    marginBottom: "10px",
                    "& .MuiInput-underline:before": {
                        // style for the underline before it's active (e.g., on hover)
                        borderBottom: "1px solid #FFFFFF88"
                    },
                    "& .MuiInput-underline:after": {
                        // style for the underline when it's active (e.g., on focus)
                        borderBottom: "2px solid #307abb"
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        // style for the underline when hovered
                        borderBottom: "2px solid #FFFFFF88"
                    },
                    "& .MuiInput-underline.Mui-focused:after": {
                        // style for the underline when it's in focus
                        borderBottom: "2px solid #307abb"
                    }
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard"
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: 14,
                    padding: "7px 20px"
                },
                startIcon: {
                    fontSize: 16,
                    opacity: 0.8,
                    svg: {
                        fontSize: "17px !important"
                    }
                },
                endIcon: {
                    fontSize: 16,
                    svg: {
                        fontSize: "17px !important"
                    }
                }
            },
            variants: [
                {
                    props: { variant: "toolbar" },
                    style: {
                        backgroundColor: "#1E2832",
                        color: "#fff"
                    }
                },
                {
                    props: { size: "small" },
                    style: {
                        padding: "1.5px 15px"
                    }
                }
            ]
        },
        MuiButtonGroup: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                grouped: {
                    padding: "7px 20px"
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 13
                }
            },
            defaultProps: {
                arrow: true
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: "#1E2832",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#1E2832",
                            color: "#fff"
                        }
                    }
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    //backgroundColor: "#141b22",
                    textTransform: "uppercase",
                    fontWeight: 600,
                    letterSpacing: "1.28px"
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: 14
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    "&.MuiTableCell-head": {
                        fontWeight: 600,
                        fontSize: 14
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#6299c8",
                    fontSize: 14
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: 15,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    minHeight: 48,
                    "&.Mui-selected": {
                        color: "#fff",
                        backgroundColor: "#161e25",
                        "&:hover": {
                            backgroundColor: "#161e25",
                            color: "#fff"
                        }
                    }
                }
            },
            defaultProps: {
                iconPosition: "start"
            }
        },
        MuiTabs: {
            defaultProps: {
                TabIndicatorProps: {
                    style: {
                        height: 4,
                        top: 0,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4
                    }
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    backgroundColor: "#161e25",
                    borderRadius: 4
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#182029",
                    backgroundImage: "none"
                }
            }
        }
    }
});

declare module "@mui/material/styles" {
    interface Palette {
        sidebar?: Palette["primary"];
        sidebarPopup?: Palette["primary"];
        contentSidebar?: Palette["primary"];
        scheduleRowStart?: Palette["primary"];
        topbar?: Palette["primary"];
        body?: Palette["primary"];
        headerBox?: Palette["primary"];
        tertiary?: Palette["primary"];
        quaternary?: Palette["primary"];
        quinary?: Palette["primary"];
        senary?: Palette["primary"];
        septenary?: Palette["primary"];
        contentToolbar?: Palette["primary"];
        contentHeader?: Palette["primary"];
    }

    interface PaletteOptions {
        sidebar?: PaletteOptions["primary"];
        sidebarPopup?: PaletteOptions["primary"];
        contentSidebar?: PaletteOptions["primary"];
        scheduleRowStart?: PaletteOptions["primary"];
        topbar?: PaletteOptions["primary"];
        body?: PaletteOptions["primary"];
        headerBox?: PaletteOptions["primary"];
        tertiary?: PaletteOptions["primary"];
        quaternary?: PaletteOptions["primary"];
        quinary?: PaletteOptions["primary"];
        senary?: PaletteOptions["primary"];
        septenary?: PaletteOptions["primary"];
        contentToolbar?: PaletteOptions["primary"];
        contentHeader?: PaletteOptions["primary"];
    }
}

export default theme;
