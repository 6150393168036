import { Alert, Box, Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import OrganisationRoleProvider from "components/OrganisationRoleProvider";
import { useScheduler, useSources } from "hooks";

interface IProps {
    source_id: string;
    destination_id: string;
    schedule_id: string;
    startDate: Date;
    endDate: Date;
    startMinute: number;
    endMinute: number;
    previousEndDate?: Date;
    nextStartDate?: Date;
    zoom: number;
    disable_start_editing?: boolean;
    onReset: () => void;
    onSave: () => void;
    onSourceChange: (source_id: string) => void;
    onStartEdit: (startMinute: number) => void;
    onEndEdit: (endMinute: number) => void;
}

const EditScheduleForm: React.FC<IProps> = ({
    source_id,
    destination_id,
    schedule_id,
    startDate,
    endDate,
    startMinute,
    endMinute,
    previousEndDate,
    nextStartDate,
    disable_start_editing,
    onReset,
    onSave,
    onSourceChange,
    onStartEdit,
    onEndEdit
}) => {
    const { updateSchedule } = useScheduler();
    const { getInputsDropdownList } = useSources();
    const [sources, setSources] = React.useState<any>();
    const [source, setSource] = React.useState<string>(source_id);
    const [error, setError] = React.useState<any>();

    const getStartDateTime = () => {
        const startDateTime = new Date(startDate);
        startDateTime.setHours(Math.floor(startMinute / 60), startMinute % 60);
        return startDateTime;
    };

    const getEndDateTime = () => {
        const endDateTime = new Date(endDate);
        endDateTime.setHours(Math.floor(endMinute / 60), endMinute % 60);
        return endDateTime;
    };

    const [newStartDate, setNewStartDate] = React.useState<Date>(getStartDateTime());
    const [newEndDate, setNewEndDate] = React.useState<Date>(getEndDateTime());

    React.useEffect(() => {
        getInputsDropdownList().then((data) => {
            setSources(data.data);
        });
    }, [getInputsDropdownList]);

    const handleUpdateSchedule = async () => {
        const request = {
            start_time: newStartDate.toISOString(),
            end_time: newEndDate.toISOString(),
            destination_id: destination_id,
            source_id: source
        };

        await updateSchedule(request, schedule_id)
            .then(() => {
                onSave();
            })
            .catch((error) => {
                setError(error.data?.message);
            });
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Edit Schedule
            </Typography>
            {sources && (
                <TextField
                    variant="standard"
                    name="source"
                    id="source"
                    label="Source"
                    onChange={(e) => {
                        setSource(e.target.value);
                        onSourceChange(e.target.value);
                    }}
                    value={source}
                    select>
                    {sources.map((source: any) => (
                        <MenuItem key={source.id} value={source.id}>
                            {source.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label="Start"
                    value={newStartDate}
                    format="dd/MM/yyyy HH:mm:ss"
                    timeSteps={{ minutes: 1, seconds: 1 }}
                    minDateTime={previousEndDate && previousEndDate > new Date() ? previousEndDate : new Date()}
                    views={["day", "hours", "minutes", "seconds"]}
                    disabled={disable_start_editing}
                    slotProps={{
                        textField: {
                            helperText:
                                previousEndDate &&
                                previousEndDate > new Date(newStartDate) &&
                                "The earliest you can start this schedule is " + previousEndDate?.toLocaleTimeString()
                        }
                    }}
                    onChange={(newValue) => {
                        if (newValue && newValue > new Date()) {
                            setNewStartDate(newValue as Date);
                            onStartEdit(newValue!.getHours() * 60 + newValue!.getMinutes());
                        }
                    }}
                />
                <DateTimePicker
                    label="End"
                    value={newEndDate}
                    format="dd/MM/yyyy HH:mm:ss"
                    timeSteps={{ minutes: 1, seconds: 1 }}
                    maxDateTime={nextStartDate ? nextStartDate : undefined}
                    views={["day", "hours", "minutes", "seconds"]}
                    slotProps={{
                        textField: {
                            helperText:
                                nextStartDate &&
                                nextStartDate < new Date(newEndDate) &&
                                "The latest you can end this schedule is " + nextStartDate?.toLocaleTimeString()
                        }
                    }}
                    onChange={(newValue) => {
                        if (newValue && newValue > new Date()) {
                            setNewEndDate(newValue as Date);
                            onEndEdit(newValue!.getHours() * 60 + newValue!.getMinutes());
                        }
                    }}
                />
            </LocalizationProvider>
            {error && (
                <Alert severity="error" sx={{ marginTop: 1, marginBottom: 2 }}>
                    {error}
                </Alert>
            )}
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ marginTop: 1 }}>
                <Button
                    onClick={() => {
                        onReset();
                    }}>
                    Reset
                </Button>
                <OrganisationRoleProvider roles={["admin", "operator"]}>
                    <Button
                        variant="contained"
                        onClick={handleUpdateSchedule}
                        disabled={
                            (previousEndDate && previousEndDate > new Date(newStartDate)) ||
                            (nextStartDate && nextStartDate < new Date(newEndDate))
                                ? true
                                : false
                        }>
                        Save
                    </Button>
                </OrganisationRoleProvider>
            </Stack>
        </Box>
    );
};

export default observer(EditScheduleForm);
