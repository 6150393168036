import { useState } from "react";

interface ISortingConfig {
    sortingOrder: string;
    sortingColumn: string;
}

export const useSortableData = () => {
    const [sortingConfig, setSortingConfig] = useState<ISortingConfig | null>(null);

    const changeSortConfig = (columnName: string, setStateMethod: any, inputList: any[]) => {
        // setStateMethod is for updating the list after the sorting
        let sortingOrder = "asc";
        if (sortingConfig?.sortingColumn === columnName) {
            sortingOrder = sortingConfig.sortingOrder === "asc" ? "desc" : "asc";
        }
        const newConfig = { sortingOrder: sortingOrder, sortingColumn: columnName };
        setSortingConfig(newConfig);

        // Sort the list after changing sort order
        const sortedList = sortList(inputList, columnName, sortingOrder);
        setStateMethod(sortedList);
        return newConfig;
    };

    const sortList = (inputList: any[], sortingColumn?: string, sortingOrder?: string) => {
        let sortedAlerts = [...inputList];
        if (sortingColumn && sortingOrder) {
            sortedAlerts.sort((a, b) => {
                let varA = a[sortingColumn];
                if (typeof varA === "string") {
                    varA = varA.toLowerCase();
                } else if (typeof varA === "boolean") {
                    // Changing "trues" to show first when ordering ascending
                    varA = varA ? 0 : 1;
                }

                let varB = b[sortingColumn];
                if (typeof varB === "string") {
                    varB = varB.toLowerCase();
                } else if (typeof varB === "boolean") {
                    // Changing "trues" to show first when ordering ascending
                    varB = varB ? 0 : 1;
                }

                // @ts-ignore
                if (varA < varB) {
                    return sortingOrder === "asc" ? -1 : 1;
                }
                // @ts-ignore
                if (varA > varB) {
                    return sortingOrder === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedAlerts;
    };

    return { sortingConfig: sortingConfig, changeSortConfig: changeSortConfig, sortList: sortList, setSortingConfig: setSortingConfig };
};
