import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Cancel, Check, CheckCircle, Delete, Edit, Email, Notifications, Visibility, VolumeOff, VolumeUp } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    ButtonBase,
    Checkbox,
    Chip,
    Collapse,
    Divider,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { agent, IEnvironment, RootStoreContext } from "api";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import React, { useEffect, useRef } from "react";
import AlertWebsocketMonitor from "./Components/AlertWebsocketMonitor";
import NotificationMethods from "features/alertsManagement/NotificationMethods";
import SourceDetails from "features/sources/Components/SourceDetails";
import DestinationDetails from "features/destinations/Components/DestinationDetails";
import { set } from "date-fns";
import SearchInput from "components/SearchInput";
import AlertsFormEdit from "features/alertsManagement/Components/AlertsFormEdit";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import SelectedItemsMenu from "./Components/SelectedItemsMenu";

interface AlertsItem {
    id: string;
    environment_id: string;
    environment_name: string;
    environment_region: string;
    environment_provider: string;
    environment_status: string;
    type: "source" | "destination";
    type_id: string;
    type_name: string;
    alert_name: string;
    alert_condition_string: string;
    alert_status: string;
    alert_method_name: string;
    alert_is_alerting: boolean;
    alert_data: any;
}

const Alerts: React.FC = () => {
    const navigate = useNavigate();
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { openDrawer, closeDrawer } = rootStore.drawerStore;
    const [alerts, setAlerts] = React.useState<any[]>([]);
    const [alertItems, setAlertItems] = React.useState<AlertsItem[]>([]);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [sort, setSort] = React.useState<{ key: string; direction: "asc" | "desc" }>({
        key: "environment_name",
        direction: "asc"
    });
    const [search, setSearch] = React.useState<string>("");
    const [showEnvironmentId, setShowEnvironmentId] = React.useState<string>("all");
    const [showSources, setShowSources] = React.useState<boolean>(true);
    const [showDestinations, setShowDestinations] = React.useState<boolean>(true);
    const [showMuted, setShowMuted] = React.useState<boolean>(true);
    const [showActive, setShowActive] = React.useState<boolean>(true);
    const [onlyAlerting, setOnlyAlerting] = React.useState<boolean>(false);
    const getAlerts = async () => {
        await agent.Alerts.getAlertsOverview()
            .then((response) => {
                buildAlerts(response.alerts);
                setAlerts(response.alerts);
                setUpdateDate(new Date());
            })
            .catch((error) => {
                console.warn("Error", error);
            });
    };

    const unMuteUponRecovery = useRef(false);
    const setUnMuteUponRecovery = (newValue: boolean) => {
        unMuteUponRecovery.current = newValue;
    };

    const UnMuteOnRecoveryCheckbox: React.FC = () => {
        return (
            <Box mt={2}>
                <FormControlLabel
                    control={<Checkbox onChange={(e) => setUnMuteUponRecovery(e.target.checked)} />}
                    label={
                        <Box pl={1}>
                            <Typography>Unmute upon recovery</Typography>
                            <Typography variant="body2" color="textSecondary">
                                Unmute this alert when it recovers from the alerting state.
                            </Typography>
                        </Box>
                    }
                />
            </Box>
        );
    };

    const getEnvironmentRegionAndProvider = async (environment_id: string) => {
        let environment_region: string = "";
        let environment_provider: string = "";

        await agent.Environment.getEnvironmentDetails(environment_id)
            .then((response) => {
                environment_region = response.details.region;
                environment_provider = response.details.cloud_provider;
                setUpdateDate(new Date());
            })
            .catch((error) => {
                console.log(error);
            });
        return { region: environment_region, provider: environment_provider };
    };

    const buildAlerts = (alerts: any) => {
        let alertItems: AlertsItem[] = [];
        alerts.forEach((environment: any) => {
            let region = "";
            let provider = "";

            getEnvironmentRegionAndProvider(environment.environment_id).then((response) => {
                region = response.region;
                provider = response.provider;

                environment.channel_alerts.forEach((channel: any) => {
                    channel.alerts.forEach((alert: any) => {
                        alertItems.push({
                            id: alert.id,
                            environment_id: environment.environment_id,
                            environment_name: environment.environment_human_name,
                            environment_region: region,
                            environment_provider: provider,
                            environment_status: environment.environment_status,
                            type: "source",
                            type_id: channel.src_ref_code,
                            type_name: channel.src_display_name,
                            alert_name: alert.alert_name,
                            alert_condition_string: alert.condition_string,
                            alert_status: alert.status,
                            alert_method_name: alert.alert_method_name,
                            alert_is_alerting: alert.is_alerting,
                            alert_data: alert
                        });
                    });
                });
                environment.destination_alerts.forEach((destination: any) => {
                    destination.alerts.forEach((alert: any) => {
                        alertItems.push({
                            id: alert.id,
                            environment_id: environment.environment_id,
                            environment_name: environment.environment_human_name,
                            environment_region: region,
                            environment_provider: provider,
                            environment_status: environment.environment_status,
                            type: "destination",
                            type_id: destination.dst_ref_code,
                            type_name: destination.dst_display_name,
                            alert_name: alert.alert_name,
                            alert_condition_string: alert.condition_string,
                            alert_status: alert.status,
                            alert_method_name: alert.alert_method_name,
                            alert_is_alerting: alert.is_alerting,
                            alert_data: alert
                        });
                    });
                });
            });
        });
        //console.log("alertItems", alertItems);
        setAlertItems(alertItems);
    };

    useEffect(() => {
        getAlerts();
    }, []);

    useEffect(() => {
        setSelected([]);
    }, [onlyAlerting, showEnvironmentId, showSources, showDestinations, showMuted, showActive]);

    useEffect(() => {}, [updateDate]);

    return (
        <View>
            <ViewHeader title="Alerts Management" />
            <ViewBody noPadding>
                <Content
                    noPadding
                    toolbarContent={
                        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                            <Box width={244} height="100%" display="flex" alignItems="center" justifyContent="center" pt={2}>
                                <SearchInput
                                    name="Search"
                                    label="Search"
                                    value={search}
                                    onChange={(value) => setSearch(value)}
                                    onClear={() => {
                                        setSearch("");
                                    }}
                                />
                            </Box>
                            <Stack direction="row" spacing={1}>
                                <SelectedItemsMenu
                                    selectedItems={selected}
                                    onAction={() => {
                                        setSelected([]);
                                        getAlerts();
                                    }}
                                />
                                <Button variant="toolbar" onClick={() => navigate("/alerts/notification-methods")} startIcon={<Email />}>
                                    Notification Settings
                                </Button>
                            </Stack>
                        </Stack>
                    }
                    leftContent={
                        <Stack p={3} spacing={1}>
                            <TextField
                                select
                                label="Environment"
                                title="Environment"
                                value={showEnvironmentId}
                                sx={{ pb: 2 }}
                                onChange={(e) => setShowEnvironmentId(e.target.value)}>
                                <MenuItem value="all">All</MenuItem>
                                {alerts.map((environment, index) => (
                                    <MenuItem key={index} value={environment.environment_id}>
                                        {environment.environment_human_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Divider />
                            <FormControlLabel
                                control={<Checkbox checked={onlyAlerting} onChange={(e) => setOnlyAlerting(e.target.checked)} />}
                                label="Show Alerting Only"
                            />
                            <Divider />
                            <FormControlLabel
                                control={<Checkbox checked={showSources} onChange={(e) => setShowSources(e.target.checked)} />}
                                label="Show Sources"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showDestinations} onChange={(e) => setShowDestinations(e.target.checked)} />}
                                label="Show Destinations"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showMuted} onChange={(e) => setShowMuted(e.target.checked)} />}
                                label="Show Muted"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showActive} onChange={(e) => setShowActive(e.target.checked)} />}
                                label="Show Active"
                            />
                        </Stack>
                    }>
                    <Table stickyHeader sx={{ width: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={
                                            selected.length ===
                                            alertItems
                                                .filter((alert) => {
                                                    if (onlyAlerting && alert.alert_is_alerting) {
                                                        return true;
                                                    }
                                                    if (!onlyAlerting) {
                                                        return true;
                                                    }
                                                    return false;
                                                })
                                                .filter((alert) => {
                                                    if (showEnvironmentId === "all") {
                                                        return true;
                                                    }
                                                    if (showEnvironmentId === alert.environment_id) {
                                                        return true;
                                                    }
                                                    return false;
                                                })
                                                .filter((alert) => {
                                                    if (showSources && alert.type === "source") {
                                                        return true;
                                                    }
                                                    if (showDestinations && alert.type === "destination") {
                                                        return true;
                                                    }
                                                    return false;
                                                })
                                                .filter((alert) => {
                                                    if (showMuted && alert.alert_status === "inactive") {
                                                        return true;
                                                    }
                                                    if (showActive && alert.alert_status === "active") {
                                                        return true;
                                                    }
                                                    return false;
                                                }).length
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                //set only the visible alerts to selected
                                                setSelected(
                                                    alertItems
                                                        .filter((alert) => {
                                                            if (onlyAlerting && alert.alert_is_alerting) {
                                                                return true;
                                                            }
                                                            if (!onlyAlerting) {
                                                                return true;
                                                            }
                                                            return false;
                                                        })
                                                        .filter((alert) => {
                                                            if (showEnvironmentId === "all") {
                                                                return true;
                                                            }
                                                            if (showEnvironmentId === alert.environment_id) {
                                                                return true;
                                                            }
                                                            return false;
                                                        })
                                                        .filter((alert) => {
                                                            if (showSources && alert.type === "source") {
                                                                return true;
                                                            }
                                                            if (showDestinations && alert.type === "destination") {
                                                                return true;
                                                            }
                                                            return false;
                                                        })
                                                        .filter((alert) => {
                                                            if (showMuted && alert.alert_status === "inactive") {
                                                                return true;
                                                            }
                                                            if (showActive && alert.alert_status === "active") {
                                                                return true;
                                                            }
                                                            return false;
                                                        })
                                                        .map((alert) => alert.id)
                                                );
                                            } else {
                                                setSelected([]);
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell sortDirection={sort.key === "environment_name" ? sort.direction : false}>
                                    <TableSortLabel
                                        active={sort.key === "environment_name"}
                                        direction={sort.key === "environment_name" ? sort.direction : "asc"}
                                        onClick={() =>
                                            setSort({ key: "environment_name", direction: sort.direction === "asc" ? "desc" : "asc" })
                                        }>
                                        Environment
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sort.key === "type_name" ? sort.direction : false}>
                                    <TableSortLabel
                                        active={sort.key === "type_name"}
                                        direction={sort.key === "type_name" ? sort.direction : "asc"}
                                        onClick={() => setSort({ key: "type_name", direction: sort.direction === "asc" ? "desc" : "asc" })}>
                                        Item
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={sort.key === "alert_name" ? sort.direction : false}
                                    onClick={() => setSort({ key: "alert_name", direction: sort.direction === "asc" ? "desc" : "asc" })}>
                                    <TableSortLabel
                                        active={sort.key === "alert_name"}
                                        direction={sort.key === "alert_name" ? sort.direction : "asc"}>
                                        Alert
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sort.key === "alert_status" ? sort.direction : false}>
                                    <TableSortLabel
                                        active={sort.key === "alert_status"}
                                        direction={sort.key === "alert_status" ? sort.direction : "asc"}
                                        onClick={() =>
                                            setSort({ key: "alert_status", direction: sort.direction === "asc" ? "desc" : "asc" })
                                        }>
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Unmute</TableCell>
                                <TableCell sortDirection={sort.key === "alert_method_name" ? sort.direction : false}>
                                    <TableSortLabel
                                        active={sort.key === "alert_method_name"}
                                        direction={sort.key === "alert_method_name" ? sort.direction : "asc"}
                                        onClick={() =>
                                            setSort({ key: "alert_method_name", direction: sort.direction === "asc" ? "desc" : "asc" })
                                        }>
                                        Notification Method
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alertItems
                                .filter(
                                    (alert) =>
                                        alert.environment_name.toLowerCase().includes(search.toLowerCase()) ||
                                        alert.type_name.toLowerCase().includes(search.toLowerCase()) ||
                                        alert.alert_name.toLowerCase().includes(search.toLowerCase())
                                )
                                .filter((alert) => {
                                    if (onlyAlerting && alert.alert_is_alerting) {
                                        return true;
                                    }
                                    if (!onlyAlerting) {
                                        return true;
                                    }
                                    return false;
                                })
                                .filter((alert) => {
                                    if (showEnvironmentId === "all") {
                                        return true;
                                    }
                                    if (showEnvironmentId === alert.environment_id) {
                                        return true;
                                    }
                                    return false;
                                })
                                .filter((alert) => {
                                    if (showSources && alert.type === "source") {
                                        return true;
                                    }
                                    if (showDestinations && alert.type === "destination") {
                                        return true;
                                    }
                                    return false;
                                })
                                .filter((alert) => {
                                    if (showMuted && alert.alert_status === "inactive") {
                                        return true;
                                    }
                                    if (showActive && alert.alert_status === "active") {
                                        return true;
                                    }
                                    return false;
                                })

                                .sort((a, b) => {
                                    if (sort.direction === "asc") {
                                        return a[sort.key] > b[sort.key] ? 1 : -1;
                                    } else {
                                        return a[sort.key] < b[sort.key] ? 1 : -1;
                                    }
                                })
                                .map((alert, index) => (
                                    <TableRow
                                        key={index}
                                        selected={selected.indexOf(alert.id) !== -1}
                                        sx={{
                                            backgroundColor: (theme) =>
                                                alert.alert_is_alerting && alert.alert_status === "active"
                                                    ? theme.palette.error.dark
                                                    : "inherit"
                                        }}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selected.indexOf(alert.id) !== -1}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelected([...selected, alert.id]);
                                                    } else {
                                                        setSelected(selected.filter((id) => id !== alert.id));
                                                    }
                                                }}
                                            />
                                            <AlertWebsocketMonitor
                                                onChange={() => getAlerts()}
                                                type={alert.type === "source" ? "channel" : "destination"}
                                                type_id={alert.type_id}
                                                environment_id={alert.environment_id}
                                                environment_provider={alert.environment_provider}
                                                environment_region={alert.environment_region}
                                                alert_id={alert.id}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{alert.environment_name}</Typography>
                                            <Typography variant="body2" color="textSecondary" textTransform="capitalize">
                                                {alert.environment_status}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{alert.type_name}</Typography>
                                            <Typography variant="body2" color="textSecondary" textTransform="capitalize">
                                                {alert.type}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography fontWeight="bold">{alert.alert_name}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {alert.alert_condition_string}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {alert.alert_is_alerting && alert.alert_status === "active" ? (
                                                <Chip
                                                    label="Alerting"
                                                    color="error"
                                                    size="small"
                                                    avatar={<Notifications className="pulse" />}
                                                />
                                            ) : alert.alert_status === "inactive" ? (
                                                <Chip label="Muted" size="small" avatar={<VolumeOff />} />
                                            ) : (
                                                <Chip
                                                    label={alert.alert_status}
                                                    sx={{ textTransform: "capitalize" }}
                                                    size="small"
                                                    color="primary"
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ paddingTop: alert.alert_status === "active" ? undefined : "4px" }}>
                                                {alert.alert_status === "active" ? (
                                                    <Typography>-</Typography>
                                                ) : alert.alert_data.unmute_upon_recovery ? (
                                                    <Tooltip title="Alert will automatically unmute upon recovery" placement="top">
                                                        <CheckCircle color="success" />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Alert will not automatically unmute upon recovery" placement="top">
                                                        <Cancel sx={{ color: "text.secondary" }} />
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell>{alert.alert_method_name}</TableCell>
                                        <TableCell padding="checkbox">
                                            <Stack direction="row" spacing={1} mr={1}>
                                                <Tooltip title={"View " + alert.type_name} placement="top">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            if (alert.type === "source") {
                                                                openDrawer(
                                                                    <Box sx={{ width: 680, height: "100vh", p: 2 }}>
                                                                        <SourceDetails item_id={alert.type_id} />
                                                                    </Box>
                                                                );
                                                            } else {
                                                                openDrawer(
                                                                    <Box sx={{ width: 680, height: "100vh", p: 2 }}>
                                                                        <DestinationDetails destination_id={alert.type_id} />
                                                                    </Box>
                                                                );
                                                            }
                                                        }}>
                                                        <Visibility />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit Alert" placement="top">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            openDialog(
                                                                <>
                                                                    <AlertsFormEdit
                                                                        source={alert.alert_data}
                                                                        successCallback={() => {
                                                                            getAlerts();
                                                                        }}
                                                                    />
                                                                </>,
                                                                "Edit Alert"
                                                            )
                                                        }>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                {alert.alert_status === "active" ? (
                                                    <Tooltip title="Mute Alert" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                openDialog(
                                                                    <ConfirmationDialog
                                                                        title={"Mute Alert " + alert.alert_name}
                                                                        message={
                                                                            "Are you sure you want to mute this alert " +
                                                                            alert.alert_name +
                                                                            "?"
                                                                        }
                                                                        additionalContent={<UnMuteOnRecoveryCheckbox />}
                                                                        onConfirm={async () => {
                                                                            await agent.Alerts.muteAlert(
                                                                                alert.id,
                                                                                unMuteUponRecovery.current
                                                                            ).then(() => {
                                                                                setUnMuteUponRecovery(false);
                                                                                getAlerts();
                                                                                closeDialog();
                                                                            });
                                                                        }}
                                                                        onCancel={() => {
                                                                            setUnMuteUponRecovery(false);
                                                                        }}
                                                                    />
                                                                );
                                                            }}>
                                                            <VolumeOff />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Unmute Alert" placement="top">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                openDialog(
                                                                    <ConfirmationDialog
                                                                        title={"Unmute Alert " + alert.alert_name}
                                                                        message={
                                                                            "Are you sure you want to unmute this alert " +
                                                                            alert.alert_name +
                                                                            "?"
                                                                        }
                                                                        onConfirm={async () => {
                                                                            await agent.Alerts.unMuteAlert(alert.id).then(() => {
                                                                                getAlerts();
                                                                                closeDialog();
                                                                            });
                                                                        }}
                                                                    />
                                                                );
                                                            }}>
                                                            <VolumeUp />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title="Delete Alert" placement="top">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            openDialog(
                                                                <ConfirmationDialog
                                                                    title={"Delete Alert " + alert.alert_name}
                                                                    message={
                                                                        "Are you sure you want to delete this alert " +
                                                                        alert.alert_name +
                                                                        "?"
                                                                    }
                                                                    onConfirm={async () => {
                                                                        await agent.Alerts.deleteAlert(alert.id).then(() => {
                                                                            getAlerts();
                                                                            closeDialog();
                                                                        });
                                                                    }}
                                                                />
                                                            );
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <Box p={2}>
                        <Typography variant="caption">
                            Last Updated: {updateDate.toLocaleString()},{" "}
                            <ButtonBase
                                sx={{ color: "primary.main", fontSize: "0.6696428571428572rem", lineHeight: 1.66, display: "inline-block" }}
                                onClick={() => {
                                    getAlerts();
                                }}>
                                Refresh
                            </ButtonBase>
                        </Typography>
                    </Box>
                </Content>
            </ViewBody>
        </View>
    );
};

export default Alerts;
