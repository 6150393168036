import { RootStoreContext } from "api";
import { isEmailAccepted } from "helpers";
import React from "react";

interface Props {
    acceptedEmails: string[];
    children: React.ReactNode;
}

/**
 * A React component that conditionally renders its children based on the acceptance of the current user's email.
 * It uses a list of accepted emails or domains to determine if the children should be rendered.
 *
 * The component utilizes the `RootStoreContext` to access the current user's information from a global state management (e.g., MobX, Redux).
 * The `isEmailAccepted` function is used to check if the user's email matches any of the specified emails or domains in the `acceptedEmails` prop.
 *
 * @param {Object} props - The component props.
 * @param {string[]} props.acceptedEmails - An array of strings representing the accepted emails or domains. Each string can be a full email address (e.g., "user@example.com") or a domain (e.g., "@example.com").
 * @param {React.ReactNode} props.children - The children to be rendered if the user's email is accepted.
 *
 * @returns {React.ReactNode | null} - Returns the children if the user's email is accepted, otherwise returns `null`.
 *
 * @example
 * // Usage example
 * <EmailProvider acceptedEmails={[constants.cerberus.emails.general]}>
 *   <div>Content visible only to users with accepted emails</div>
 * </EmailProvider>
 */
export const EmailProvider: React.FC<Props> = ({ acceptedEmails, children }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    if (!acceptedEmails) {
        return <>{children}</>;
    }

    if (user) {
        if (isEmailAccepted(user.email, acceptedEmails)) {
            return <>{children}</>;
        }
    }

    return null;
};

export default EmailProvider;
