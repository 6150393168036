import { Box, Typography } from "@mui/material";
import { ChartsTooltip, PiePlot, ResponsiveChartContainer } from "@mui/x-charts";
import { ICogsReportDataSummaryPerOrganisation } from "api";
import React, { useEffect } from "react";
import { ChartPalette } from "styles/ChartPalette";

interface OrganisationPieChartProps {
    organisation_summary: ICogsReportDataSummaryPerOrganisation;
}

interface IChartData {
    value: number;
    label: string;
}

export const OrganisationPieChart: React.FC<OrganisationPieChartProps> = ({ organisation_summary }) => {
    const [chartData, setChartData] = React.useState<IChartData[]>([]);
    const currencySymbol = "$";

    useEffect(() => {
        const newChartData: IChartData[] = [];
        if (organisation_summary) {
            //disk_storage_costs
            //elastic_ip_costs
            //external_egress_costs
            //internal_egress_costs
            //nea_live_cdn_egress_costs
            //titan_file_cogs
            //virtual_machine_run_time
            if (organisation_summary.disk_storage_costs.cost_usd > 0) {
                newChartData.push({
                    value: organisation_summary.disk_storage_costs.cost_usd,
                    label: "Disk Storage"
                });
            }
            if (organisation_summary.elastic_ip_costs.cost_usd > 0) {
                newChartData.push({
                    value: organisation_summary.elastic_ip_costs.cost_usd,
                    label: "Elastic IP"
                });
            }
            if (organisation_summary.external_egress_costs.cost_usd > 0) {
                newChartData.push({
                    value: organisation_summary.external_egress_costs.cost_usd,
                    label: "External Egress"
                });
            }
            if (organisation_summary.internal_egress_costs.cost_usd > 0) {
                newChartData.push({
                    value: organisation_summary.internal_egress_costs.cost_usd,
                    label: "Internal Egress"
                });
            }
            if (organisation_summary.nea_live_cdn_egress_costs.cost_usd > 0) {
                newChartData.push({
                    value: organisation_summary.nea_live_cdn_egress_costs.cost_usd,
                    label: "NEA Live CDN Egress"
                });
            }
            if (organisation_summary.titan_file_cogs.cost_usd > 0) {
                newChartData.push({
                    value: organisation_summary.titan_file_cogs.cost_usd,
                    label: "Titan File COGS"
                });
            }
            if (organisation_summary.virtual_machine_run_time.cost_usd > 0) {
                newChartData.push({
                    value: organisation_summary.virtual_machine_run_time.cost_usd,
                    label: "Virtual Machine Run Time"
                });
            }
            setChartData(newChartData);
        }
    }, [organisation_summary]);

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                aspectRatio: "1/1"
            }}
        >
            <ResponsiveChartContainer
                disableAxisListener
                series={[
                    {
                        type: "pie",
                        data: chartData,
                        innerRadius: 55,
                        cornerRadius: 3,
                        paddingAngle: 1,
                        highlightScope: { faded: "global", highlighted: "item" },
                        //faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
                        valueFormatter: (item) => `${currencySymbol}${item.value}`
                    }
                ]}>
                <PiePlot skipAnimation colors={ChartPalette} />
                <ChartsTooltip trigger="item" />
            </ResponsiveChartContainer>
            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}
            >
                <Typography variant="body2" color="text.secondary">
                    Total Cost
                </Typography>
                <Typography variant="h5" color="text.primary">
                    {currencySymbol + organisation_summary.total_cost_usd.toFixed(2)}
                </Typography>
            </Box>
        </Box>
    );
};

export default OrganisationPieChart;
