import React, { useContext, useEffect, useState } from "react";

import Content from "components/Layout/Content";
import { Box, Button, Card, Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { agent, IMultiChanLayout, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { useSources } from "hooks";
import { AddCircle, Delete, Edit, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/ConfirmationDialog";
import { CreateMultiViewerLayout } from "./Create";

interface IMultiviewersLayouts {
    envProvider: string | null;
}

export const MultiviewersLayouts: React.FC<IMultiviewersLayouts> = ({ envProvider }) => {
    // Hooks
    const theme = useTheme();
    const navigate = useNavigate();
    const { loadMultiViewerLayouts } = useSources();

    // Global State
    const rootStore = useContext(RootStoreContext);
    const { currentEnvRunning } = rootStore.environmentStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;

    // Local State
    const [systemLayouts, setSystemLayouts] = useState<IMultiChanLayout[]>([]);
    const [customLayouts, setCustomLayouts] = useState<IMultiChanLayout[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuIndex, setMenuIndex] = useState(null);
    const [updateDate, setUpdateDate] = useState<Date>(new Date());

    const getData = async () => {
        await loadMultiViewerLayouts()
            .then((response) => {
                setSystemLayouts(response.data.filter((layout) => layout.is_system_default === true));
                setCustomLayouts(
                    response.data.filter((layout) => layout.is_system_default !== true).sort((a, b) => a.name.localeCompare(b.name))
                );
            })
            .catch((error) => {
                console.log("Error loading layouts", error);
            })
            .finally(() => {
                setUpdateDate(new Date());
            });
    };

    const handleClick = (e, index) => {
        setAnchorEl(e.currentTarget);
        setMenuIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuIndex(null);
    };

    const open = Boolean(anchorEl);

    const deleteLayout = async (layout: IMultiChanLayout) => {
        openDialog(
            <ConfirmationDialog
                title={`Confirm Delete Layout`}
                message={`Are you sure you want to delete the layout "${layout.name}"?`}
                onConfirm={async () => {
                    await agent.Multiviewers.deleteLayout(layout.id).then(() => {
                        getData();
                        closeDialog();
                    });
                }}
            />
        );
    };

    useEffect(() => {}, [updateDate]);

    useEffect(() => {
        getData();
    }, []);

    if (envProvider !== "aws") {
        return <></>;
    }

    return (
        <Content
            // loading={loading}
            headerContent={
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", minHeight: "52px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        {/* <FormControl sx={{ width: 120, marginRight: 1 }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={displayStatus}
                                label="Status"
                                onChange={(e) => {
                                    setDisplayStatus(e.target.value as string);
                                }}
                                variant="outlined"
                                size="small"
                                placeholder="Please Select"
                                sx={{ width: 120 }}>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="disabled">Disabled</MenuItem>
                            </Select>
                        </FormControl> */}
                    </Box>

                    <Box>
                        <Button
                            onClick={() => {
                                // navigate("/multiviewers/layouts/create");
                                openDialog(<CreateMultiViewerLayout getData={getData} />);
                            }}
                            startIcon={<AddCircle />}
                            variant="contained"
                            color="primary"
                            disabled={!currentEnvRunning}>
                            Import
                        </Button>
                    </Box>
                </Box>
            }>
            {systemLayouts.length ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            System Layouts
                        </Typography>
                    </Grid>
                    {systemLayouts &&
                        systemLayouts.map((layout, index) => (
                            <Grid item xs={6} key={index}>
                                <Card sx={{ width: "100%", padding: 2 }}>
                                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <Typography variant="h6">{`${layout.name} (${layout.width}x${layout.height})`}</Typography>
                                            <Typography variant="body2">{`Total Tiles: ${layout.total_number_of_tiles}`}</Typography>
                                            <Typography variant="body2">{`Video Tiles: ${layout.number_of_video_tiles}`}</Typography>
                                        </Box>
                                        <Box>
                                            {/* <IconButton
                                                aria-label="settings"
                                                id="demo-customized-button"
                                                aria-controls={open ? "multiviewer-layout-item-options" : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? "true" : undefined}
                                                disabled
                                                onClick={(e) => handleClick(e)}>
                                                <MoreVert />
                                            </IconButton> */}
                                            {/* <Menu
                                                id="multiviewer-layout-item-options"
                                                MenuListProps={{
                                                    "aria-labelledby": "demo-customized-button"
                                                }}
                                                sx={{ minWidth: 300 }}
                                                anchorEl={anchorEl}
                                                elevation={0}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right"
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right"
                                                }}
                                                open={open}
                                                onClose={handleClose}>
                                                <MenuItem
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleClose(event);
                                                        // navigate("/pull-packager/" + wsData?.id);
                                                        console.log("edit");
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <Edit />
                                                    </ListItemIcon>
                                                    Edit
                                                </MenuItem>
                                                <MenuItem
                                                    disableRipple
                                                    onClick={(e) => {
                                                        handleClose(e);
                                                        deleteLayout(layout);
                                                    }}>
                                                    <ListItemIcon>
                                                        <Delete />
                                                    </ListItemIcon>
                                                    Delete
                                                </MenuItem>
                                            </Menu> */}
                                        </Box>
                                    </Stack>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            ) : (
                <></>
            )}

            {customLayouts.length ? (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            Custom Layouts
                        </Typography>
                    </Grid>
                    {customLayouts.map((layout, index) => (
                        <Grid item xs={6} key={index}>
                            <Card sx={{ width: "100%", height: "100%", padding: 2 }}>
                                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Typography variant="h6">{`${layout.name} (${layout.width}x${layout.height})`}</Typography>
                                        <Typography variant="body2">{`Total Tiles: ${layout.total_number_of_tiles}`}</Typography>
                                        <Typography variant="body2">{`Video Tiles: ${layout.number_of_video_tiles}`}</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleClose();
                                                handleClick(e, index);
                                            }}>
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            id={`menu-${index}`}
                                            anchorEl={anchorEl}
                                            open={open && menuIndex === index}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                "aria-labelledby": `menu-button-${index}`
                                            }}>
                                            <MenuItem
                                                onClick={(e) => {
                                                    console.log("1", e);
                                                }}
                                                disabled>
                                                <ListItemIcon>
                                                    <Edit />
                                                </ListItemIcon>
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleClose();
                                                    deleteLayout(layout);
                                                }}>
                                                <ListItemIcon>
                                                    <Delete />
                                                </ListItemIcon>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Stack>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <></>
            )}
        </Content>
    );
};

export default observer(MultiviewersLayouts);
