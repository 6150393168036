import React, { useEffect, useMemo } from "react";
import { Grid, Stack, Typography, Button, ButtonBase, Card, Box } from "@mui/material";
import { observer } from "mobx-react";
import { IKnowledgeHubHistory, KnowledgeHubSelectorType, RootStoreContext } from "api";
import SearchInput from "components/SearchInput";
import { useConstants, useEmailProvider, useKnowledgeHub } from "hooks";
import KnowledgeHubListItem from "../KnowledgeHubListItem";
import useSearch from "hooks/UseSearch";
import { EmailProvider } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Content from "components/Layout/Content";
import { useNavigate } from "react-router-dom";
import { AddCircle } from "@mui/icons-material";

export interface IKnowledgeHubHomepage {
    isSidebar?: boolean;
    history: IKnowledgeHubHistory[];
    setHistory: React.Dispatch<React.SetStateAction<IKnowledgeHubHistory[]>>;
    goToSelector: (id: string, type: KnowledgeHubSelectorType) => void;
    search: string;
    handleSearch: (searchTerm: string) => void;
    clearSearch: () => void;
}

/**
 * `KnowledgeHubHomepage` is a React functional component that serves as the main entry point
 * to the Knowledge Hub. It displays a search input, a list of popular topics, and either a list
 * of search results or all topics depending on the user's interaction. This component integrates
 * with the application's knowledge hub store to fetch and display categories and items, and it
 * provides functionality for searching through the knowledge hub content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.isSidebar=false] - Indicates if the component is being rendered in a sidebar context.
 * @param {IKnowledgeHubHistory[]} props.history - An array of history objects for navigation purposes.
 * @param {React.Dispatch<React.SetStateAction<IKnowledgeHubHistory[]>>} props.setHistory - Function to update the navigation history.
 * @param {function(string, KnowledgeHubSelectorType): void} props.goToSelector - Function to navigate to a selected item or category.
 * @param {string} props.search - The current search term.
 * @param {function(string): void} props.handleSearch - Function to handle changes to the search input.
 * @param {function(): void} props.clearSearch - Function to clear the current search.
 * @returns {React.ReactElement} A React element representing the knowledge hub homepage.
 *
 * @example
 * ```tsx
 * <KnowledgeHubHomepage
 *   isSidebar={false}
 *   history={history}
 *   setHistory={setHistory}
 *   goToSelector={goToSelector}
 *   search=""
 *   handleSearch={(searchTerm) => console.log(`Search for: ${searchTerm}`)}
 *   clearSearch={() => console.log('Search cleared')}
 * />
 * ```
 */
export const KnowledgeHubHomepage: React.FC<IKnowledgeHubHomepage> = observer(function KnowledgeHubHomepage({
    isSidebar,
    goToSelector,
    search,
    handleSearch,
    clearSearch
}) {
    const rootStore = React.useContext(RootStoreContext);
    const { categories, archivedCategories, indexedItems } = rootStore.knowledgeHubStore;
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { constants } = useConstants();

    const searchFieldsArticles = useMemo(
        () => [
            { field: "title", weight: 2 },
            { field: "description", weight: 1 },
            { field: "tags", weight: 3 }
        ],
        []
    );

    const searchArticles = useSearch(search, indexedItems, searchFieldsArticles, 4, 0.3);

    const navigate = useNavigate();

    // const createNewArticle = useCallback(() => {
    //     navigate("/knowledge-hub/createv2");
    // }, [navigate]);

    // const createNewCategory = useCallback(() => {
    //     navigate("/knowledge-hub/create");
    // }, [navigate]);

    // const getData = async () => {
    //     getAllByCategory("kb_category", setIsLoading);
    // };

    const isAdministrator = useEmailProvider([
        ...constants.cerberus.emails.frontend,
        constants.cerberus.emails.Brad,
        constants.cerberus.emails.Chris
    ]);

    // useEffect(() => {
    // if (categories.length) {
    //     getData();
    //     return;
    // }

    // setIsLoading(true);
    // getData();
    // }, []);

    useEffect(() => {
        if (categories.length) {
            setIsLoading(false);
            return;
        }
    }, [categories]);

    if (isLoading) {
        return <></>;
    }

    return (
        <Content
            noPadding
            toolbarContent={
                !isSidebar && isAdministrator ? (
                    <Stack width="100%" spacing={2} direction="row" justifyContent="flex-end">
                        <Button
                            variant="toolbar"
                            onClick={() => {
                                navigate("/knowledge-hub/createv2");
                            }}
                            startIcon={<AddCircle />}>
                            New Article
                        </Button>
                        <Button
                            variant="toolbar"
                            onClick={() => {
                                navigate("/knowledge-hub/create");
                            }}
                            startIcon={<AddCircle />}>
                            New Category
                        </Button>
                    </Stack>
                ) : undefined
            }
            headerContent={
                <Stack justifyContent="center" alignItems="center" p={4}>
                    <Stack spacing={4} justifyContent="center" alignItems="center" sx={{ maxWidth: "50rem" }}>
                        <Typography variant={isSidebar ? "h4" : "h2"}>Knowledge Hub</Typography>

                        <SearchInput
                            name="aborted-jobs-search"
                            label="What do you need help with?"
                            value={search}
                            onChange={handleSearch}
                            onClear={clearSearch}
                            sx={{
                                width: isSidebar ? "100%" : "80%"
                            }}
                        />
                        {!isSidebar && (
                            <Stack spacing={3} direction={isSidebar ? "column" : "row"} alignItems="center">
                                <Typography variant="body1">Popular Topics</Typography>

                                <Stack spacing={1} direction={"row"}>
                                    <Button color="primary" onClick={() => goToSelector("kb_cat-1", KnowledgeHubSelectorType.Category)}>
                                        Demo Topic
                                    </Button>

                                    <Button color="primary" onClick={() => goToSelector("kb_cat-2", KnowledgeHubSelectorType.Category)}>
                                        General
                                    </Button>

                                    <Button color="primary" onClick={() => goToSelector("kb_cat-3", KnowledgeHubSelectorType.Category)}>
                                        Sources
                                    </Button>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            }>
            <Stack spacing={2} justifyContent="center" sx={{ width: "100%" }} alignItems="center" p={2}>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                    {search.length >= 2 ? `Search Results (${searchArticles.length})` : "Topics"}
                </Typography>

                <Grid container spacing={3} sx={{ width: isSidebar ? "100%" : "80%" }}>
                    {!isLoading && search.length >= 2 && (
                        <>
                            {searchArticles.length ? (
                                searchArticles?.map((result) => (
                                    <KnowledgeHubListItem
                                        key={result.item.id}
                                        isSidebar={isSidebar}
                                        item={result.item}
                                        onClick={() => goToSelector(result.item.id, KnowledgeHubSelectorType.Item)}
                                    />
                                ))
                            ) : (
                                <Grid xs={12}>
                                    <Typography variant="body1" align="center">
                                        Sorry, no items match your search. Try broadening your search criteria.
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    )}

                    {!isLoading &&
                        search.length < 2 &&
                        categories?.map((category) => {
                            return (
                                <KnowledgeHubListItem
                                    key={category.id}
                                    isSidebar={isSidebar}
                                    item={category}
                                    onClick={() => goToSelector(category.id, KnowledgeHubSelectorType.Category)}
                                />
                            );
                        })}
                </Grid>
            </Stack>

            {!isLoading && search.length < 2 && (
                <EmailProvider
                    acceptedEmails={[
                        ...constants.cerberus.emails.frontend,
                        constants.cerberus.emails.Brad,
                        constants.cerberus.emails.Chris
                    ]}>
                    <Stack spacing={2} justifyContent="center" sx={{ width: "100%", mt: 4 }} alignItems="center" p={2}>
                        <Typography variant="h3" sx={{ textAlign: "center" }}>
                            Cerberus Tech Only
                        </Typography>

                        <Grid container spacing={3} sx={{ width: isSidebar ? "100%" : "80%" }}>
                            {!isLoading && search.length < 2 && (
                                <>
                                    <Grid item xs={12} sm={isSidebar ? 12 : 6}>
                                        <ButtonBase
                                            onClick={() => goToSelector("hidden", KnowledgeHubSelectorType.Category)}
                                            sx={{ width: "100%" }}>
                                            <Card
                                                sx={{
                                                    width: "100%",
                                                    "&:hover": {
                                                        backgroundColor: (theme) => theme.palette.action.hover
                                                    }
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        height: "100%",
                                                        p: 1
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            flex: "0 0 auto",
                                                            width: 70,
                                                            height: 70,
                                                            fontSize: 32,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                        <FontAwesomeIcon icon={["fal", "eye-slash"]} />
                                                    </Box>
                                                    <Box sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}>
                                                        <Typography variant="h6" lineHeight={1.1}>
                                                            Hidden
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Cerberus Tech Only: Items not assigned to a category
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm={isSidebar ? 12 : 6}>
                                        <ButtonBase
                                            onClick={() => goToSelector("unIndexed", KnowledgeHubSelectorType.Category)}
                                            sx={{ width: "100%" }}>
                                            <Card
                                                sx={{
                                                    width: "100%",
                                                    "&:hover": {
                                                        backgroundColor: (theme) => theme.palette.action.hover
                                                    }
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        height: "100%",
                                                        p: 1
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            flex: "0 0 auto",
                                                            width: 70,
                                                            height: 70,
                                                            fontSize: 32,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                        <FontAwesomeIcon icon={["fal", "eye-slash"]} />
                                                    </Box>
                                                    <Box sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}>
                                                        <Typography variant="h6" lineHeight={1.1}>
                                                            Un-indexed
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Cerberus Tech Only: Items not listed in the search
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm={isSidebar ? 12 : 6}>
                                        <ButtonBase
                                            onClick={() => goToSelector("archived", KnowledgeHubSelectorType.Category)}
                                            sx={{ width: "100%" }}>
                                            <Card
                                                sx={{
                                                    width: "100%",
                                                    "&:hover": {
                                                        backgroundColor: (theme) => theme.palette.action.hover
                                                    }
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        height: "100%",
                                                        p: 1
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            flex: "0 0 auto",
                                                            width: 70,
                                                            height: 70,
                                                            fontSize: 32,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                        <FontAwesomeIcon icon={["fal", "eye-slash"]} />
                                                    </Box>
                                                    <Box sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}>
                                                        <Typography variant="h6" lineHeight={1.1}>
                                                            Archived
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Cerberus Tech Only: Items not with the status Archived
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item xs={12} sm={isSidebar ? 12 : 6}>
                                        <ButtonBase
                                            onClick={() => goToSelector("draft", KnowledgeHubSelectorType.Category)}
                                            sx={{ width: "100%" }}>
                                            <Card
                                                sx={{
                                                    width: "100%",
                                                    "&:hover": {
                                                        backgroundColor: (theme) => theme.palette.action.hover
                                                    }
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        height: "100%",
                                                        p: 1
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            flex: "0 0 auto",
                                                            width: 70,
                                                            height: 70,
                                                            fontSize: 32,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                        <FontAwesomeIcon icon={["fal", "eye-slash"]} />
                                                    </Box>
                                                    <Box sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}>
                                                        <Typography variant="h6" lineHeight={1.1}>
                                                            Draft Items
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Cerberus Tech Only: Items in draft
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </ButtonBase>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Stack>
                    {archivedCategories.length > 0 && (
                        <Stack spacing={2} justifyContent="center" sx={{ width: "100%", mt: 4 }} alignItems="center" p={2}>
                            <Typography variant="h3" sx={{ textAlign: "center" }}>
                                Archived Categories
                            </Typography>

                            <Grid container spacing={3} sx={{ width: isSidebar ? "100%" : "80%" }}>
                                {archivedCategories?.map((category) => (
                                    <Grid item xs={12} sm={isSidebar ? 12 : 6} key={category.id}>
                                        <ButtonBase
                                            onClick={() => goToSelector(category.id, KnowledgeHubSelectorType.Category)}
                                            sx={{ width: "100%" }}>
                                            <Card
                                                sx={{
                                                    width: "100%",
                                                    "&:hover": {
                                                        backgroundColor: (theme) => theme.palette.action.hover
                                                    }
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        height: "100%",
                                                        p: 1
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            flex: "0 0 auto",
                                                            width: 70,
                                                            height: 70,
                                                            fontSize: 32,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}>
                                                        {category.icon && <FontAwesomeIcon icon={["fal", category.icon]} />}
                                                    </Box>
                                                    <Box sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}>
                                                        <Typography variant="h6" lineHeight={1.1}>
                                                            {category.title}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {category.description}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </ButtonBase>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    )}
                </EmailProvider>
            )}
        </Content>
    );
});

export default KnowledgeHubHomepage;
