import React, { useContext, useState } from "react";
import { RootStoreContext } from "api";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useEnvironment } from "hooks";

interface IProps {
    name: string;
    id: number;
}

export const ReConfirmIPAddressDelete: React.FC<IProps> = ({ name, id }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { deleteIPAddress, getIpAddresses } = useEnvironment();
    const [, setErrorMsg] = useState("");

    return (
        <>
            <DialogTitle>Confirm Release</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography>Release IP: {name.toUpperCase()}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        deleteIPAddress(id)
                            .then(() => {
                                setTimeout(() => getIpAddresses(), 2000);
                                closeDialog();
                            })
                            .catch((error) => setErrorMsg(error.data.message));
                    }}>
                    Release
                </Button>
            </DialogActions>
        </>
    );
};
