import React, { SyntheticEvent, useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { combineValidators, composeValidators, createValidator, isRequired } from "revalidate";
import ErrorMessage from "app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";
import { TextField } from "mui-rff";
import { IBillingQuantity, RootStoreContext } from "api";
import BillingInformationForm from "./BillingInformationForm";
import { parse } from "app/common/util/parse";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
const CreditQuantityForm = () => {
    const rootStore = useContext(RootStoreContext);
    const { setQuantity } = rootStore.billingStore;
    const { closeDrawer, changeDrawerContent } = rootStore.drawerStore;

    const handleClose = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        closeDrawer();
    };

    const isValidAmount = createValidator(
        (message) => (value) => {
            if (value < 100) {
                return message;
            }
        },
        "Minimum credits you must purchase is 100"
    );

    const validate = combineValidators({
        quantity: composeValidators(isValidAmount, isRequired("Quantity"))()
    });

    return (
        <Box sx={{ width: 580, padding: 3 }}>
            <FinalForm
                onSubmit={(values: IBillingQuantity) => {
                    setQuantity(values);
                    changeDrawerContent(<BillingInformationForm />);
                }}
                validate={validate}
                render={({ handleSubmit, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ padding: 2 }}>
                            <Typography variant="h4">Purchase credits</Typography>
                            <Alert
                                severity="info"
                                sx={{
                                    my: 2
                                }}>
                                Please enter the amount of credits you require for your organisation in the field below.
                            </Alert>

                            <TextField
                                fullWidth
                                required
                                name="quantity"
                                placeholder="Specifiy the amount of credits you wish to purchase"
                                label="Credits Amount"
                                type="number"
                                fieldProps={{
                                    parse: parse
                                }}
                            />

                            {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mt: 2 }}>
                                <Button
                                    onClick={(e) => {
                                        handleClose(e);
                                    }}>
                                    Close
                                </Button>
                                <Button variant="contained" type="submit" disabled={(invalid && !dirtySinceLastSubmit) || pristine}>
                                    Next
                                </Button>
                            </Stack>
                        </Box>
                    </form>
                )}
            />
        </Box>
    );
};

export default observer(CreditQuantityForm);
