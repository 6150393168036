import React, { useContext, SyntheticEvent } from "react";
import { Button, Card, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { RootStoreContext } from "api";

interface IProps {
    id: number;
    handleDelete: (e: SyntheticEvent, id: number) => void;
}

export const DeletePackagerPreset: React.FC<IProps> = ({ id, handleDelete }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <Card sx={{ p: 2 }}>
            <DialogTitle>
                <Typography variant="h4">Confirm Delete</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ mb: 1 }}>Are you sure you want to delete this Preset?</Typography>
                <Typography>
                    Deleting this Preset will remove it from your preset dropdown completely, for you and other users within your
                    environment.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={(e) => {
                        handleDelete(e, id);
                    }}>
                    Delete
                </Button>
            </DialogActions>
        </Card>
    );
};
