import { Alert, Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { agent, IGetLimitsResponseItem, RootStoreContext } from "api";
import React, { useContext, useEffect } from "react";

interface ConfirmUpdateBatchLimitsProps {
    limits: IGetLimitsResponseItem[];
}

const ConfirmUpdateBatchLimits: React.FC<ConfirmUpdateBatchLimitsProps> = ({ limits }) => {
    const [error, setError] = React.useState<any>(null);
    const [limitValue, setLimitValue] = React.useState<number>(-1);
    const [startWarningsAt, setStartWarningsAt] = React.useState<number>(70);
    const [submitting, setSubmitting] = React.useState(false);

    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const editLimit = async () => {
        if (submitting) {
            return;
        }

        setSubmitting(true);

        let regions = limits.map(limit => limit.region);
        agent.OwnCloud.setLimits({
            cloud_provider: limits[0].cloud_provider,
            regions: regions,
            limit_name: limits[0].limit_name,
            start_warnings_at: startWarningsAt
        })
        .then((response) => {
            console.log(response);
            setSubmitting(false);
            setError(null);
            // reload page
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
            setError(error?.data?.message || 'An error occurred');
            setSubmitting(false);
        });
    }

    return (
        <>
            <DialogTitle>Update Limit</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    {error && (
                        <Alert severity="error">
                            {error}
                        </Alert>
                    )}

                    {/* <TextField
                        variant="standard"
                        label="Limit Value"
                        value={limitValue}
                        onChange={(e) => setLimitValue(parseInt(e.target.value))}
                        type="number"
                    /> */}

                    <TextField
                        variant="standard"
                        label="Start Warnings At"
                        value={startWarningsAt}
                        onChange={(e) => setStartWarningsAt(parseInt(e.target.value))}
                        type="number"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={submitting}
                    onClick={() => {
                        editLimit();
                    }}>
                    Update limit
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmUpdateBatchLimits;
