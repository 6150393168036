import { Alert, Box, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import OnChange from "components/FinalFormListener/OnChange";

interface Props {
    values: any;
    submitError: any;
    onFieldChange: (field: string, value: any) => void;
}

const GlobalTrackConfigurationForm: React.FC<Props> = ({ values, submitError, onFieldChange }) => {
    return (
        <Box>
            <Field
                name="global_track_configuration.latency_mode"
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        variant="standard"
                        name="global_track_configuration.latency_mode"
                        label="Latency Mode"
                        error={
                            (meta.touched && meta.error) ||
                            (!meta.touched && submitError?.data?.errors?.global_track_configuration?.latency_mode)
                                ? true
                                : false
                        }
                        helperText={
                            meta.touched && meta.error
                                ? meta.error
                                : !meta.touched && submitError?.data?.errors?.global_track_configuration?.latency_mode
                                ? submitError.data.errors.global_track_configuration.latency_mode
                                : null
                        }
                        required
                        select>
                        <MenuItem value="Ultra Low Latency">Ultra Low Latency</MenuItem>
                        <MenuItem value="Low Latency">Low Latency</MenuItem>
                        <MenuItem value="Reduced Latency">Reduced Latency</MenuItem>
                        <MenuItem value="High Quality">High Quality</MenuItem>
                        <MenuItem value="Best quality">Best Quality</MenuItem>
                    </TextField>
                )}
            </Field>
            <OnChange name="global_track_configuration.latency_mode">
                {() => {
                    if (submitError?.data?.errors?.global_track_configuration?.latency_mode) {
                        onFieldChange("global_track_configuration.latency_mode", values.global_track_configuration.latency_mode);
                    }
                    if (submitError?.data?.errors?.global_track_configuration) {
                        onFieldChange("global_track_configuration", values.global_track_configuration);
                    }
                }}
            </OnChange>
            <Field
                name="global_track_configuration.chunk_period"
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}>
                {({ input, meta }) => (
                    <TextField
                        {...input}
                        variant="standard"
                        name="global_track_configuration.chunk_period"
                        label="Chunk Period"
                        error={
                            (meta.touched && meta.error) ||
                            (!meta.touched && submitError?.data?.errors?.global_track_configuration?.chunk_period)
                                ? true
                                : false
                        }
                        helperText={
                            meta.touched && meta.error
                                ? meta.error
                                : !meta.touched && submitError?.data?.errors?.global_track_configuration?.chunk_period
                                ? submitError.data.errors.global_track_configuration.chunk_period
                                : null
                        }
                        required
                        select>
                        <MenuItem value="1s">1s</MenuItem>
                        <MenuItem value="2s">2s</MenuItem>
                        <MenuItem value="4s">4s</MenuItem>
                        <MenuItem value="6s">6s</MenuItem>
                    </TextField>
                )}
            </Field>

            <OnChange name="global_track_configuration.chunk_period">
                {() => {
                    if (submitError?.data?.errors?.global_track_configuration?.chunk_period) {
                        onFieldChange("global_track_configuration.chunk_period", values.global_track_configuration.chunk_period);
                    }
                    if (submitError?.data?.errors?.global_track_configuration) {
                        onFieldChange("global_track_configuration", values.global_track_configuration);
                    }
                }}
            </OnChange>

            {typeof submitError?.data?.errors?.global_track_configuration === "string" && (
                <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                    {submitError?.data?.errors?.global_track_configuration}
                </Alert>
            )}
        </Box>
    );
};

export default GlobalTrackConfigurationForm;
