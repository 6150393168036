import { Chip, CircularProgress } from "@mui/material";
import React from "react";

interface IProgressChipProps {
    label: string;
    color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

const ProgressChip: React.FC<IProgressChipProps> = (props) => {
    return <Chip color={props.color || undefined} label={props.label} size="small" avatar={<CircularProgress size={"1rem"} />} sx={{ textTransform: "capitalize" }} />;
};

export default ProgressChip;
