import { ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import React from "react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocation, useNavigate } from "react-router-dom";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DnsIcon from "@mui/icons-material/Dns";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { CloudDone } from "@mui/icons-material";
import RoleProvider from "components/RoleProvider";
import ClientProvider from "components/ClientProvider";

interface Props {
    menuPosition?: string;
    onClose?: () => void;
}

const AdminMenu: React.FC<Props> = ({ menuPosition, onClose }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        onClose && onClose();
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <MenuItem
                selected={location.pathname.startsWith("/admin")}
                sx={{
                    backgroundColor: open ? theme.palette.action.hover : undefined
                }}
                onClick={(event: any) => {
                    handleClick(event);
                }}>
                <ListItemIcon>
                    <AdminPanelSettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Admin" />
                <ChevronRightIcon />
            </MenuItem>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={menuPosition === "right" ? { vertical: "top", horizontal: "right" } : { vertical: "top", horizontal: "left" }}
                transformOrigin={
                    menuPosition === "right" ? { vertical: "top", horizontal: "left" } : { vertical: "top", horizontal: "right" }
                }
                open={open}
                onClose={handleClose}
                sx={{
                    p: "0px !important",
                    "& .MuiMenu-paper": {
                        width: 250,
                        p: "0px !important"
                    },
                    "& .MuiList-root": {
                        pt: "0px !important"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    onMouseLeave: () => setAnchorEl(null)
                }}>
                <MenuItem
                    selected={location.pathname === "/admin/cogs"}
                    onClick={() => {
                        handleClose();
                        navigate("/admin/cogs");
                    }}>
                    <ListItemIcon>
                        <PointOfSaleIcon fontSize="small" />
                    </ListItemIcon>
                    COGS
                </MenuItem>
                <MenuItem
                    selected={location.pathname === "/admin/default-price"}
                    onClick={() => {
                        handleClose();
                        navigate("/admin/default-price");
                    }}>
                    <ListItemIcon>
                        <PriceChangeIcon fontSize="small" />
                    </ListItemIcon>
                    Default Price
                </MenuItem>
                <MenuItem
                    selected={location.pathname === "/admin/organisations"}
                    onClick={() => {
                        handleClose();
                        navigate("/admin/organisations");
                    }}>
                    <ListItemIcon>
                        <GroupAddIcon fontSize="small" />
                    </ListItemIcon>
                    Organisations
                </MenuItem>
                <MenuItem
                    selected={location.pathname === "/admin/system"}
                    onClick={() => {
                        handleClose();
                        navigate("/admin/system");
                    }}>
                    <ListItemIcon>
                        <DnsIcon fontSize="small" />
                    </ListItemIcon>
                    System
                </MenuItem>
                <ClientProvider client="default">
                    <RoleProvider roles={["is_dev"]}>
                        <MenuItem
                            selected={location.pathname === "/admin/tf-release-candidates"}
                            onClick={() => {
                                handleClose();
                                navigate("/admin/tf-release-candidates");
                            }}>
                            <ListItemIcon>
                                <CloudDone fontSize="small" />
                            </ListItemIcon>
                            Titan File Release Candidates
                        </MenuItem>
                    </RoleProvider>
                </ClientProvider>
            </Menu>
        </React.Fragment>
    );
};

export default AdminMenu;
