import React from "react";
import { agent, IFileClusterJob, IFileClusterJobs } from "api";
import { Box, Chip, Pagination, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { format, intervalToDuration } from "date-fns";
import SearchInput from "components/SearchInput";

type DeletedJobsProps = {
    deployment_id: string;
    pageSize: number;
};

interface JobsRequest {
    deployment_id: string;
    offset: number;
    limit: number;
    sort: string;
    category: string;
    source_filename?: string;
}

const DeletedJobs: React.FC<DeletedJobsProps> = (props) => {
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const initialJobsRequest = {
        deployment_id: props.deployment_id,
        offset: 0,
        limit: props.pageSize,
        sort: "id:asc",
        category: "deleted",
        source_filename: undefined
    };

    const [jobsRequest, setJobsRequest] = React.useState<JobsRequest>(initialJobsRequest);

    const [jobs, setJobs] = React.useState<IFileClusterJobs>();

    const getJobs = async (request: JobsRequest) => {
        await agent.FileCluster.getJobsByDeployment(
            request.deployment_id,
            request.offset,
            request.limit,
            request.sort,
            request.category,
            request.source_filename
        ).then((response) => {
            setJobs(response);
        });
    };

    const handleSearch = (searchTerm: string) => {
        setJobsRequest({ ...jobsRequest, source_filename: searchTerm });
        getJobs(jobsRequest);
    };

    const clearSearch = () => {
        setJobsRequest({ ...jobsRequest, source_filename: undefined });
        getJobs(jobsRequest);
    };

    React.useEffect(() => {
        getJobs(jobsRequest);
        setUpdateDate(new Date());
    }, [props.deployment_id, jobsRequest]);

    React.useEffect(() => {}, [updateDate]);

    React.useEffect(() => {
        //getJobs(jobsRequest);

        const interval = setInterval(() => {
            getJobs(jobsRequest);
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [jobsRequest]);

    return (
        <div>
            <Box className="tab-toolbar">
                <SearchInput
                    name="search"
                    label="Search Source Files..."
                    value={jobsRequest.source_filename}
                    onChange={(value: string) => handleSearch(value)}
                    onClear={() => clearSearch()}
                />
                <Box className="tab-toolbar-actions"></Box>
            </Box>
            <Table>
                <TableHead>
                    <TableCell>
                        <TableSortLabel
                            active={jobsRequest.sort === "name:asc" || jobsRequest.sort === "name:desc"}
                            direction={jobsRequest.sort === "name:asc" ? "asc" : jobsRequest.sort === "name:desc" ? "desc" : undefined}
                            onClick={() =>
                                jobsRequest.sort === "name:asc"
                                    ? setJobsRequest({ ...jobsRequest, sort: "name:desc" })
                                    : setJobsRequest({ ...jobsRequest, sort: "name:asc" })
                            }>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={jobsRequest.sort === "id:asc" || jobsRequest.sort === "id:desc"}
                            direction={jobsRequest.sort === "id:asc" ? "asc" : jobsRequest.sort === "id:desc" ? "desc" : undefined}
                            onClick={() =>
                                jobsRequest.sort === "id:asc"
                                    ? setJobsRequest({ ...jobsRequest, sort: "id:desc" })
                                    : setJobsRequest({ ...jobsRequest, sort: "id:asc" })
                            }>
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={jobsRequest.sort === "start_time:asc" || jobsRequest.sort === "start_time:desc"}
                            direction={
                                jobsRequest.sort === "start_time:asc" ? "asc" : jobsRequest.sort === "start_time:desc" ? "desc" : undefined
                            }
                            onClick={() =>
                                jobsRequest.sort === "start_time:asc"
                                    ? setJobsRequest({ ...jobsRequest, sort: "start_time:desc" })
                                    : setJobsRequest({ ...jobsRequest, sort: "start_time:asc" })
                            }>
                            Start Time
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={jobsRequest.sort === "end_time:asc" || jobsRequest.sort === "end_time:desc"}
                            direction={
                                jobsRequest.sort === "end_time:asc" ? "asc" : jobsRequest.sort === "end_time:desc" ? "desc" : undefined
                            }
                            onClick={() =>
                                jobsRequest.sort === "end_time:asc"
                                    ? setJobsRequest({ ...jobsRequest, sort: "end_time:desc" })
                                    : setJobsRequest({ ...jobsRequest, sort: "end_time:asc" })
                            }>
                            End Time
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Active Duration</TableCell>
                    <TableCell>Cost</TableCell>
                </TableHead>
                <TableBody>
                    {jobs?.data.map((job: IFileClusterJob, i: number) => (
                        <TableRow key={i}>
                            <TableCell>
                                <div style={{ wordBreak: "break-all" }}>{job.name}</div>
                                <div
                                    style={{
                                        fontSize: "10px",
                                        opacity: "0.7"
                                    }}>
                                    Source: {job.source_filename}
                                </div>
                            </TableCell>
                            <TableCell>{job.id}</TableCell>
                            <TableCell>{job.start_time ? format(new Date(job.start_time), "dd/MM/yyyy @ HH:mm") : null}</TableCell>
                            <TableCell>{job.end_time ? format(new Date(job.end_time), "dd/MM/yyyy @ HH:mm") : null}</TableCell>
                            <TableCell>
                                <Chip
                                    label={job.status}
                                    size="small"
                                    style={{
                                        textTransform: "capitalize"
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                {(intervalToDuration({ start: 0, end: job.active_duration * 1000 }).hours || 0) +
                                    "h " +
                                    (intervalToDuration({ start: 0, end: job.active_duration * 1000 }).minutes || 0) +
                                    "m " +
                                    (intervalToDuration({ start: 0, end: job.active_duration * 1000 }).seconds || 0) +
                                    "s"}
                            </TableCell>
                            <TableCell>
                                {job.currency === "gbp" ? "£" : ""}
                                {job.currency === "eur" ? "€" : ""}
                                {job.currency === "usd" ? "$" : ""}
                                {job.cost_calculated ? job.cost_calculated : ""}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {jobs && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={9}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <Pagination
                                        shape="rounded"
                                        count={Math.ceil(jobs.totals.deleted / props.pageSize)}
                                        page={jobsRequest.offset / props.pageSize + 1}
                                        onChange={(event: any, page: number) => {
                                            setJobsRequest({ ...jobsRequest, offset: (page - 1) * props.pageSize });
                                            getJobs(jobsRequest);
                                        }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
};

export default DeletedJobs;
