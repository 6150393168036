import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField } from "@mui/material";
import { agent, IDasboardGroupUpdRequest, IDasboardGroupUpdResponse } from "api";
import ErrorMessages from "components/ErrorMessages";
import { TabContext } from "@mui/lab";
import { SmallTab, SmallTabList, TabPanel } from "components";

type GroupAccordionProps = {
    id: string;
    name: string;
    description: string;
    children?: JSX.Element;
    defaultExpanded: boolean;
    active?: boolean;
    lat: number;
    lng: number;
    item_count: number;
    onDeleteClick?: () => void;
    hidden?: boolean;
    onHideClick?: () => void;
    disableActions?: boolean;
};

const GroupAccordion: React.FC<GroupAccordionProps> = ({
    id,
    name,
    description,
    children,
    defaultExpanded,
    active,
    lat,
    lng,
    item_count,
    onDeleteClick,
    hidden,
    onHideClick,
    disableActions
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);
    const [tabValue, setTabValue] = React.useState<string>("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    interface UpdateGroupFormProps {
        id?: string;
    }

    const UpdateGroupForm: React.FC<UpdateGroupFormProps> = (props) => {
        const [newLat, setNewLat] = React.useState<any>(undefined);
        const [newLng, setNewLng] = React.useState<any>(undefined);
        const [errors, setErrors] = React.useState<any>();
        const [hasChanged, setHasChanged] = React.useState<boolean>(false);

        const handleLatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const decimalRegex = /^-?\d*\.?\d*$/;
            if (decimalRegex.test(event.target.value)) {
                setNewLat(event.target.value);
                setHasChanged(true);
            }
        };

        const handleLngChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const decimalRegex = /^-?\d*\.?\d*$/;
            if (decimalRegex.test(event.target.value)) {
                setNewLng(event.target.value);
                setHasChanged(true);
            }
        };

        const handleSubmit = async (e: React.SyntheticEvent) => {
            e.preventDefault();

            const target = e.target as typeof e.target & {
                hostname: { value: string };
                description: { value: string };
                latitude: { value: number };
                longitude: { value: number };
            };

            const request: IDasboardGroupUpdRequest = {
                name: target.hostname.value,
                description: target.description.value,
                latitude: target.latitude.value,
                longitude: target.longitude.value
            };
            await agent.Environment.updateGroup(id, request)
                .then((value: IDasboardGroupUpdResponse) => {
                    console.log(value);
                    setErrors(undefined);
                    setHasChanged(false);
                })
                .catch((error) => {
                    setHasChanged(false);
                    setErrors(
                        error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                    );
                });
        };

        React.useEffect(() => {
            setNewLat(lat);
            setNewLng(lng);

            return () => {
                setNewLat(undefined);
                setNewLng(undefined);
                setHasChanged(false);
            };
        }, []);

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="standard"
                        id="hostname"
                        name="hostname"
                        label="Group Name"
                        required
                        defaultValue={name}
                        style={{ marginBottom: "10px" }}
                        onChange={() => setHasChanged(true)}
                    />
                    <TextField
                        variant="standard"
                        id="description"
                        name="description"
                        label="Group Description"
                        multiline
                        rows={2}
                        defaultValue={description}
                        style={{ marginBottom: "10px" }}
                        onChange={() => setHasChanged(true)}
                    />
                    <TextField
                        variant="standard"
                        id="latitude"
                        name="latitude"
                        label="Latitude"
                        required
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleLatChange(e);
                        }}
                        style={{ marginBottom: "10px" }}
                        value={newLat || ""}
                    />
                    <TextField
                        variant="standard"
                        id="longitude"
                        name="longitude"
                        label="Longitude"
                        required
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleLngChange(e);
                        }}
                        style={{ marginBottom: "10px" }}
                        value={newLng || ""}
                    />
                    <ErrorMessages errors={errors} />
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                        <Button color="primary" variant="contained" type="submit" disabled={!hasChanged}>
                            Save
                        </Button>
                    </div>
                </form>
            </div>
        );
    };

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    const GroupAccordionStyles = {
        borderBottom: "3px solid rgba(160,160,160,0.02)"
    };

    const GroupAccordionHeaderStyles = {
        fontWeight: 400,
        padding: "5px 10px 5px 15px",
        backgroundColor: "rgba(160,160,160,0.02)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "11px",
        cursor: "pointer"
    };

    const GroupAccordionBodyStyles = {
        overflow: "hidden",
        height: expanded ? "auto" : "0px"
    };

    return (
        <div style={GroupAccordionStyles} className={active ? "group-accordion active" : "group-accordion"}>
            <div style={GroupAccordionHeaderStyles} title={description}>
                <div style={{ flex: 1 }} onClick={() => setExpanded(!expanded)}>
                    <FontAwesomeIcon icon={["fal", expanded || active ? "folder-open" : "folder"]} />
                    &nbsp;&nbsp;{name} ({item_count})
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                    {!disableActions && (
                        <div style={{ marginRight: 10 }} onClick={onDeleteClick} title="Delete Group">
                            <FontAwesomeIcon icon={["fal", "trash"]} />
                        </div>
                    )}
                    {!disableActions && (
                        <div style={{ marginRight: 10 }}>
                            <FontAwesomeIcon
                                title={hidden ? "Show on map" : "Hide on map"}
                                icon={["fal", hidden ? "eye-slash" : "eye"]}
                                onClick={() => (onHideClick ? onHideClick() : null)}
                            />
                        </div>
                    )}

                    <FontAwesomeIcon icon={["fal", expanded ? "chevron-up" : "chevron-down"]} onClick={() => setExpanded(!expanded)} />
                </div>
            </div>
            <div style={GroupAccordionBodyStyles}>
                <TabContext value={tabValue}>
                    <SmallTabList
                        onChange={handleTabChange}
                        aria-label="Overview - Source Item Tabs"
                    >
                        <SmallTab label="Items" value="1" />
                        <SmallTab label="Settings" value="2" />
                    </SmallTabList>

                    <TabPanel value="1">
                        <div style={{ width: "calc(100% + 20px)", margin: "0 -10px" }}>{children}</div>
                    </TabPanel>
                    <TabPanel value="2">
                        <UpdateGroupForm />
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
};

export default GroupAccordion;
