import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import SourceList from "features/sources/Components/SourceList";
import React, { memo } from "react";
import { Handle, useReactFlow, useStoreApi, Position } from "reactflow";


function SourcesNode({ id, data }) {
    return (
        <Card sx={{ width: 300 }}>
            <Handle type="target" position={Position.Left} />
            <CardHeader subheader="Sources" />
                
            <Box sx={{ height: 300, overflowY: "auto"}}>
                <SourceList />
            </Box>
            <Handle type="source" position={Position.Right} />
        </Card>
    );
}

export default memo(SourcesNode);
