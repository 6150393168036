import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { agent, IUpdateOrganisationRequest, RootStoreContext } from "api";

interface Props {
    organisationId: number;
    name: string;
    currency: string;
    poNumber: string;
    organisationTags: string;
    calculationMode: string;
    marginPercentage: number;
    onSubmitted: () => void;
}

const EditOrganisationForm: React.FC<Props> = observer(function EditOrganisationForm(props) {
    const rootStore = React.useContext(RootStoreContext);

    const { closeDialog } = rootStore.dialogStore;

    const [errors, setErrors] = React.useState<any>();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            name: { value: string };
            currency: { value: string };
            purchase_order_number: { value: string };
            organisation_tags: { value: string };
            tf_job_cost_calculation_mode: { value: string };
            tf_margin_percentage: { value: string };
        };

        const request: IUpdateOrganisationRequest = {
            name: target.name.value,
            currency: target.currency.value,
            purchase_order_number: target.purchase_order_number.value,
            organisation_tags: target.organisation_tags.value,
            tf_job_cost_calculation_mode: target.tf_job_cost_calculation_mode.value,
            tf_margin_percentage: target.tf_margin_percentage.value
        };

        await agent.Admin.updateOrganisation(props.organisationId, request)
            .then(() => {
                console.log("Organisation updated successfully");
                props.onSubmitted();
            })
            .catch((error) => {
                console.log(error);
                setErrors(error);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <TextField name="name" id="name" label="Name" defaultValue={props.name} required />
                <FormControl fullWidth variant="standard" required sx={{ marginBottom: 1 }}>
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select name="currency" labelId="currency-label" id="currency" label="Currency" defaultValue={props.currency}>
                        <MenuItem value="usd">USD</MenuItem>
                        <MenuItem value="gbp">GBP</MenuItem>
                        <MenuItem value="eur">EUR</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="purchase_order_number"
                    name="purchase_order_number"
                    label="Purchase Order Number"
                    required
                    defaultValue={props.poNumber}
                />
                <TextField
                    id="organisation_tags"
                    name="organisation_tags"
                    label="Organisation Tags"
                    defaultValue={props.organisationTags}
                />
                <FormControl fullWidth variant="standard" required sx={{ marginBottom: 1 }}>
                    <InputLabel id="tf_job_cost_calculation_mode-label">Titan File Job Cost Calculation Mode</InputLabel>
                    <Select
                        name="tf_job_cost_calculation_mode"
                        labelId="tf_job_cost_calculation_mode-label"
                        id="tf_job_cost_calculation_mode"
                        label="Titan File Job Cost Calculation Mode"
                        defaultValue={props.calculationMode ? props.calculationMode : "low_volumes"}>
                        <MenuItem value="low_volumes">On Demand</MenuItem>
                        <MenuItem value="effective">Subscription</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id="tf_margin_percentage"
                    name="tf_margin_percentage"
                    label="Titan File Margin Percentage"
                    defaultValue={props.marginPercentage}
                />
                {errors && (
                    <Alert variant="filled" severity="error">
                        <Typography fontWeight="bold">{errors.data?.message}</Typography>
                        {errors.data.errors &&
                            Object.entries(errors.data.errors).map(([key, value]) => (
                                <Typography key={key}>
                                    <>&bull;&nbsp;{value}</>
                                </Typography>
                            ))}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button type="submit" variant="contained" color="primary">
                    Update Organisation
                </Button>
            </DialogActions>
        </form>
    );
});

export default EditOrganisationForm;
