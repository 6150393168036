import React from "react";
import { Grid, Typography, Paper, IconButton, ButtonBase, Card, Box } from "@mui/material";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KnowledgeHubItemActions from "../KnowledgeHubItemActions";
import { IKnowledgeHubArticleShort, KnowledgeHubItemStatus, KnowledgeHubType } from "api";

const getSummary = (description?: string, length = 50) => {
    if (!description) {
        return "";
    }

    return `${description.slice(0, length)}${description.length > length ? "..." : ""}`;
};

export interface IKnowledgeHubListItem {
    isSidebar?: boolean;
    item: IKnowledgeHubArticleShort;
    onClick: () => void;
    editItem?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, type: KnowledgeHubType) => void;
    deleteItem?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string) => void;
    archiveItem?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string) => void;
}

/**
 * `KnowledgeHubListItem` is a React functional component that renders a single item in the Knowledge Hub list.
 * It displays the item's title, a summary of its description, and optionally an icon. If provided, edit and delete
 * action buttons are also displayed, but only for users with emails accepted by the `EmailProvider`.
 *
 * This component is designed to be used within a list, where each item represents a piece of content in the Knowledge Hub.
 * Clicking on the item triggers a callback function, typically used for navigation. The edit and delete actions are
 * optional and can be controlled through props, allowing for flexible use cases depending on the user's permissions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.isSidebar=false] - Indicates if the component is being rendered in a sidebar context.
 * @param {Object} props.item - The item to display, containing `title`, `description`, `icon`, and `id`.
 * @param {function(): void} props.onClick - Callback function to be called when the item is clicked.
 * @param {function(React.MouseEvent<HTMLButtonElement, MouseEvent>, string): void} [props.editItem] - Optional callback function for editing the item. Receives the click event and item ID.
 * @param {function(React.MouseEvent<HTMLButtonElement, MouseEvent>, string, string): void} [props.deleteItem] - Optional callback function for deleting the item. Receives the click event, item ID, and item title.
 * @returns {React.ReactElement} A React element representing a single item in the Knowledge Hub list.
 *
 * @example
 * ```tsx
 * <KnowledgeHubListItem
 *   isSidebar={true}
 *   item={{ title: 'Introduction to React', description: 'Learn the basics of React.', icon: 'book-open', id: '1' }}
 *   onClick={() => console.log('Item clicked')}
 *   editItem={(e, id) => console.log(`Edit item ${id}`)}
 *   deleteItem={(e, id, title) => console.log(`Delete item ${id}, titled ${title}`)}
 * />
 * ```
 */
export const KnowledgeHubListItem: React.FC<IKnowledgeHubListItem> = observer(function KnowledgeHubListItem({
    isSidebar,
    item,
    onClick,
    editItem,
    deleteItem,
    archiveItem
}) {
    return (
        <Grid item xs={12} sm={isSidebar ? 12 : 6}>
            <ButtonBase onClick={onClick} sx={{ width: "100%" }}>
                <Card
                    sx={{
                        width: "100%",
                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.action.hover
                        }
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            p: 1
                        }}>
                        <Box
                            sx={{
                                flex: "0 0 auto",
                                width: 70,
                                height: 70,
                                fontSize: 32,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <FontAwesomeIcon icon={["fal", item.icon || "book"]} />
                        </Box>
                        <Box sx={{ width: "100%", justifyContent: "flex-start", textAlign: "left" }}>
                            <Typography variant="h6" lineHeight={1.1}>
                                {item.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {item.description}
                            </Typography>
                        </Box>
                        <KnowledgeHubItemActions
                            item={item}
                            editItem={editItem}
                            deleteItem={item?.status === KnowledgeHubItemStatus.Archived ? deleteItem : undefined}
                            archiveItem={item?.status === KnowledgeHubItemStatus.Archived ? undefined : archiveItem}
                        />
                    </Box>
                </Card>
            </ButtonBase>
        </Grid>
    );
});

export default KnowledgeHubListItem;
