import {
    AddInputSourcePermissionRequest,
    agent,
    IChannelVisibility,
    IEnvironment,
    IEnvironmentsData,
    IEnvironmentUpgradeBody,
    IMaxDestinations,
    ISchedulerFormValues,
    ISourcePermission,
    RootStoreContext,
    SwitchEnvironment
} from "api";
import { useState, useCallback, useContext } from "react";

export const useSourcePermissions = () => {
    const rootStore = useContext(RootStoreContext);
    const { setSourcePermissions, setMyGuestOrganisationsData, setNOfActiveDestOrgs, setSourcePermission, setContacts } =
        rootStore.sourcePermissionsStore;
    const [isLoading, setIsLoading] = useState(false);

    const leaveOrganistion = useCallback(async (token?: string) => {
        try {
            if (token) {
                await agent.SourcePermissions.deleteOrgInvitation(token);
            }
        } catch (error) {
            throw error;
        }
    }, []);

    const setMaxDestinations = useCallback(async (values: IMaxDestinations, token: string) => {
        try {
            await agent.SourcePermissions.updateMaxDestinations(values, token);
        } catch (error) {
            throw error;
        }
    }, []);

    const setChannelVisibility = useCallback(async (values: IChannelVisibility, token?: string) => {
        try {
            if (token) {
                await agent.SourcePermissions.updateChannelVisibility(values, token);
            }
        } catch (error) {
            throw error;
        }
    }, []);

    const grantChannelAccess = useCallback(async (values: AddInputSourcePermissionRequest, token: string) => {
        await agent.SourcePermissions.addInputSourcePermission(values, token);
    }, []);

    const removeChannelAccess = async (token: string, sourceId: string) => {
        await agent.SourcePermissions.removeInputSourcePermission(token, sourceId);
    };

    const loadSourcePermissions = useCallback(async () => {
        const permissions = await agent.SourcePermissions.getSourcePermissions();
        setSourcePermissions(permissions);
    }, []);

    const loadMyGuestOrgs = useCallback(async () => {
        const res = await agent.SourcePermissions.getMyGuestOrgs();
        setMyGuestOrganisationsData(res);
        setNOfActiveDestOrgs(res.nr_of_active_destination_organisations ? res.nr_of_active_destination_organisations : 0);
    }, []);

    const getContacts = useCallback(async () => {
        try {
            const res = await agent.SourcePermissions.getContacts();
            setContacts(res);
        } catch (error) {
            throw error;
        }
    }, []);

    const updateSourcePermission = useCallback(async (values: ISourcePermission) => {
        const permission = await agent.SourcePermissions.updateSourcePermission(values);
        setSourcePermission(permission);
    }, []);

    return {
        isLoading,
        leaveOrganistion,
        setMaxDestinations,
        setChannelVisibility,
        grantChannelAccess,
        removeChannelAccess,
        loadSourcePermissions,
        loadMyGuestOrgs,
        getContacts,
        updateSourcePermission
    };
};
