// Enums for standardized options to ensure type safety and avoid invalid values.

export enum DynamicRange {
    None = "None",
    PassThrough = "Pass-through",
    BT2020HDR = "BT-2020 (HDR)"
}

export enum HDRConformance {
    BT2020SDR = "BT2020 SDR",
    ARIBSTDB67 = "ARIB STD-B67",
    DVB_PQ10 = "DVB PQ10",
    DVB_HDR10 = "DVB HDR10",
    DVB_HLG = "DVB HLG",
    ATSC_HDR10 = "ATSC HDR10",
    ATSC_HLG = "ATSC HLG"
}

export enum MasteringDisplayColorVolumeSettings {
    ITUR_BT2020 = "ITU-R BT2020",
    ITUR_BT709 = "ITU-R BT709",
    DCI_P3_D65 = "DCI P3 with D65 white",
    Unknown = "Unknown",
    Custom = "Custom"
}

// Interface for the custom color settings, used if the MasteringDisplayColorVolumeSettings is "Custom".

export interface CustomColorSettings {
    white_x: number;
    white_y: number;
    red_x: number;
    red_y: number;
    green_x: number;
    green_y: number;
    blue_x: number;
    blue_y: number;
}

// Interface for the conditional HDR parameters based on hdr_conformance.

export interface HDRParameters {
    content_light_level_information?: {
        max_fall: number;
        max_cll: number;
    };
    hdr_10_plus?: {
        enable: boolean;
    };
    mastering_display_color_volume?: {
        settings: MasteringDisplayColorVolumeSettings;
        luminance_min: number;
        luminance_max: number;
        customColorSettings?: CustomColorSettings; // This becomes mandatory if "settings" is "Custom".
    };
}

// Main interface that includes all the potential parameters, with each field being optional based on certain conditions.

export interface OutputColorimetryParameters {
    dynamic_range?: DynamicRange;
    hdr_conformance?: HDRConformance;
    hdr_parameters?: HDRParameters; // Conditional: exists based on hdr_conformance values.
}