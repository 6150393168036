import React from "react";
import PasswordChangeForm from "../../settings/myAccount/personalDetails/PasswordChangeForm";
import { DialogTitle } from "@mui/material";

// eslint-disable-next-line mobx/missing-observer
export const ChangePasswordModal = (): JSX.Element => (
    <>
        <DialogTitle>Change Password</DialogTitle>
        <PasswordChangeForm />
    </>
);
