import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Box, Button, Card, Typography } from "@mui/material";

export const MultiChanEditedMessage = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <Card sx={{ py: 4 }}>
            <Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
                Multiviewer Updated
            </Typography>

            <Typography sx={{ textAlign: "center", mb: 3 }}>{"Your multiviewer settings have been updated."}</Typography>
            <Box sx={{ textAlign: "center", width: "100%" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </Box>
        </Card>
    );
};
