import React, { SyntheticEvent, useEffect } from "react";
import StepperForm from "components/Forms/StepperForm";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { CheckboxField, RadioGroupField, SliderField, TextField } from "components/Forms";
import { useNavigate, useParams } from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import ColorEnhancementForm from "features/packager/Components/ColorEnhancementForm";
import ColorimetryOptionsForm from "features/packager/Components/ColorimetryOptionsForm";
import OnChange from "components/FinalFormListener/OnChange";

import { AddCircle, Delete } from "@mui/icons-material";
import {
    agent,
    CreatePushPackagerRequest,
    GetPushPackagerResponse,
    UpdatePushPackagerRequest,
    IRegions,
    RootStoreContext,
    KnowledgeHubSelectorType
} from "api";
import AudioTrackForm from "../Components/AudioTrackForm";
import AudioTrackVariant from "../Components/AudioTrackVariant";
import EnvironmentSource from "../Components/EnvironmentSource";
import VideoTrackForm from "../Components/VideoTrackForm";
import DirectSource from "../Components/DirectSource";
import SrtCallerOptionsForm from "../Components/SrtCallerOptionsForm";
import SrtListenerOptionsForm from "../Components/SrtListenerOptionsForm";
import ZixiPushOptionsForm from "../Components/ZixiPushOptionsForm";
import ZixiPullOptionsForm from "../Components/ZixiPullOptionsForm";
import DataTrackForm from "../Components/DataTrackForm";
import { OutputGroupForm } from "../Components/OutputGroupForm";
import HlsOutputOptions from "../Components/HlsOutputOptions";
import HlsFmp4OutputOptions from "../Components/HlsFmp4OutputOptions";
import DashOutputOptions from "../Components/DashOutputOptions";
import { useMonaco } from "@monaco-editor/react";
import { pruneEmptyErrors } from "features/common/PruneEmptyErrors";
import SettingsInfo from "../Components/SettingsInfo";
import SourceInfo from "../Components/SourceInfo";
import GlobalConfigInfo from "../Components/GlobalConfigInfo";
import Review from "../Components/Review";
import { DeletePackagerPreset } from "features/pullPackager/Components/DeletePackagerPreset";
import PresetSelect from "../Components/PresetSelect";
import { KnowledgeHubItem } from "features/knowledgeHub/Components";
import KnowledgeHub from "features/knowledgeHub";
import CreateTranscoderSuccess from "features/transcoders/Create/Components/CreateTranscoderSuccess";
import CreatePackagerSuccess from "./Components/CreatePackagerSuccess";
import { useSources } from "hooks";

interface CreatePushPackagerProps {
    mode: "create" | "edit";
}

const CreatePushPackager: React.FC<CreatePushPackagerProps> = ({ mode }) => {
    const monaco = useMonaco();

    monaco?.editor.defineTheme("my-dark", {
        base: "vs-dark",
        inherit: true,
        rules: [],
        colors: {
            "editor.background": "#0F1419"
        }
    });

    const theme = useTheme();
    const navigate = useNavigate();
    const { id } = useParams();
    const package_id = id;
    const { getInputsDropdownList } = useSources();

    const rootStore = React.useContext(RootStoreContext);

    const { openDialog, closeDialog } = rootStore.dialogStore;

    const [initialValues, setInitialValues] = React.useState<GetPushPackagerResponse | any>({
        individual_track_configuration: {
            audio_tracks: []
        },
        video_delay_ms: 0
    });
    const [sources, setSources] = React.useState<any>();
    const [cloudRegions, setCloudRegions] = React.useState<IRegions | any>();
    const [newPackagerId, setNewPackagerId] = React.useState<string>("");

    const getInitialValues = async (packageId: string) => {
        await agent
            .getCleanInstance()
            .get(`push_packagers/${packageId}`)
            .then((response) => {
                setInitialValues({
                    video_delay_ms: 0,
                    ...response.data.data.transcode_parameters
                });
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const getSourceList = async (environmentId: string) => {
        const response = await getInputsDropdownList(environmentId);
        setSources(response);
    };

    const getCloudRegions = async (cloudCode: string) => {
        const response = await agent.Environment.getRegions(cloudCode);
        setCloudRegions(response);
    };

    const loadPreset = (name: string, preset: any) => {
        //setInitialValues to preset and set name to name using ...spread

        if (preset?.make_permanent_destination) {
            getSourceList(preset.environment_id);
        }

        if (preset?.cloud_provider) {
            getCloudRegions(preset.cloud_provider);
        }

        setInitialValues({ video_delay_ms: 0, ...preset, name: name });
    };

    useEffect(() => {
        if (mode === "edit" && package_id) {
            if (!initialValues || !initialValues.individual_track_configuration.audio_tracks.length) {
                getInitialValues(package_id);
            }
            if (initialValues && initialValues.make_permanent_destination && initialValues.environment_id) {
                //wait for the initialValues to be set, then get the source list

                getSourceList(initialValues.environment_id);
            }
        }
    }, [initialValues, mode, package_id]);

    return (
        <Form
            onSubmit={(values: any) => {
                console.log("values", values);
                console.log("submit", values);

                if (mode === "edit" && package_id) {
                    values = { ...values, id: package_id };
                    return agent.Packager.updatePushPackager(package_id, values)
                        .then((res) => {
                            console.log("res", res);
                            setNewPackagerId(package_id);
                        })
                        .catch((error) => {
                            console.log("error", error);
                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                } else {
                    return agent.Packager.createPushPackager(values)
                        .then((res) => {
                            console.log("res", res);
                            setNewPackagerId(res.packager_id);
                        })
                        .catch((error) => {
                            console.log("error", error);

                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                }
            }}
            mutators={{
                ...arrayMutators,
                setSubmitErrors: (args, state, utils) => {
                    utils.changeValue(state, "submitErrors", () => args[0]);
                }
            }}
            initialValues={initialValues}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <StepperForm
                            steps={[
                                {
                                    label: "Settings",
                                    description: "Enter a name for the new push packager and select a preset (optional)",
                                    infoContent: (
                                        <KnowledgeHub
                                            key="kb_item-1721122493459"
                                            entry={KnowledgeHubSelectorType.Item}
                                            isSidebar
                                            entryId="kb_item-1721122493459"
                                            hideTitle
                                            onNavigate={(history, setHistory) => {
                                                console.log("test...", history);
                                            }}
                                        />
                                    ),
                                    hasError: Boolean(submitErrors?.name),
                                    content: (
                                        <Box>
                                            <TextField
                                                name="name"
                                                label="Name"
                                                helperText={() => {
                                                    return "Name must be between 1 and 50 characters";
                                                }}
                                                disabled={mode === "edit"}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                    if (value.length < 1 || value.length > 50) {
                                                        return "Name must be between 1 and 50 characters";
                                                    }
                                                    if (!/^[a-zA-Z0-9._-\s]*$/.test(value)) {
                                                        return "Name must contain only valid characters of: a-z, A-Z, 0-9, ., _, -, and space";
                                                    }
                                                }}
                                            />
                                            {/* <OnChange name="name">
                                                {() => {
                                                    if (submitErrors?.name) {
                                                        delete submitErrors.name;
                                                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                                                    }
                                                }}
                                            </OnChange> */}
                                            {mode !== "edit" && <PresetSelect loadPreset={loadPreset} values={values} />}
                                            <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                                                Packager Redundancy
                                            </Typography>
                                            <CheckboxField name="enable_backup" label="Enable Packager Redundancy" />
                                            {values.enable_backup && (
                                                <Alert severity="info" sx={{ mt: 2 }}>
                                                    Enabling packager redundancy will incur additional costs.
                                                </Alert>
                                            )}
                                        </Box>
                                    )
                                },
                                {
                                    label: "Source",
                                    description: "Select the input source for the push packager",
                                    infoContent: (
                                        <KnowledgeHub
                                            key="kb_item-1721123015268"
                                            entry={KnowledgeHubSelectorType.Item}
                                            isSidebar
                                            entryId="kb_item-1721123015268"
                                            hideTitle
                                            onNavigate={(history, setHistory) => {
                                                console.log("test...", history);
                                            }}
                                        />
                                    ),
                                    hasError: Boolean(
                                        submitErrors?.input_feed_source ||
                                            submitErrors?.cloud_provider ||
                                            submitErrors?.region ||
                                            submitErrors?.direct_feed_input_details ||
                                            submitErrors?.environment_id
                                    ),
                                    content: (
                                        <Box>
                                            <RadioGroupField
                                                name="input_feed_source"
                                                label="Source Type"
                                                required
                                                disabled={mode === "edit"}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                                row
                                                options={[
                                                    { label: "Direct", value: "direct" },
                                                    { label: "Environment", value: "environment" }
                                                ]}
                                            />
                                            {values.input_feed_source === "environment" && (
                                                <Box>
                                                    <EnvironmentSource mode={mode} />
                                                </Box>
                                            )}
                                            {values.input_feed_source === "direct" && (
                                                <Box>
                                                    <DirectSource />
                                                    <Box sx={{ mt: 2 }}>
                                                        {values.direct_feed_input_details?.input_type === "srt_caller" && (
                                                            <SrtCallerOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                        {values.direct_feed_input_details?.input_type === "srt_listener" && (
                                                            <SrtListenerOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                        {values.direct_feed_input_details?.input_type === "zixi_push" && (
                                                            <ZixiPushOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                        {values.direct_feed_input_details?.input_type === "zixi_pull" && (
                                                            <ZixiPullOptionsForm
                                                                values={values}
                                                                submitError={submitError}
                                                                onFieldChange={(fieldName: string, value: any) => {
                                                                    console.log("fieldName", fieldName);
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                },
                                {
                                    label: "Global Track Configuration",
                                    description: "Set global track configuration settings",
                                    infoContent: (
                                        <KnowledgeHub
                                            key="kb_item-1720695412088"
                                            entry={KnowledgeHubSelectorType.Item}
                                            isSidebar
                                            entryId="kb_item-1720695412088"
                                            hideTitle
                                            onNavigate={(history, setHistory) => {
                                                console.log("test...", history);
                                            }}
                                        />
                                    ),
                                    hasError: Boolean(submitErrors?.global_track_configuration),
                                    content: (
                                        <Box>
                                            <RadioGroupField
                                                name="global_track_configuration.latency_mode"
                                                label="Latency Mode"
                                                required
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                                row
                                                options={[
                                                    { label: "Ultra Low Latency", value: "Ultra Low Latency" },
                                                    { label: "Low Latency", value: "Low Latency" },
                                                    { label: "Reduced Latency", value: "Reduced Latency" },
                                                    { label: "High Quality", value: "High Quality" },
                                                    { label: "Best Quality", value: "Best Quality" }
                                                ]}
                                            />

                                            <Divider sx={{ my: 2 }} />

                                            <RadioGroupField
                                                name="global_track_configuration.chunk_period"
                                                label="Chunk Period"
                                                required
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                                row
                                                options={[
                                                    { label: "1s", value: "1s" },
                                                    { label: "2s", value: "2s" },
                                                    { label: "4s", value: "4s" },
                                                    { label: "6s", value: "6s" }
                                                ]}
                                            />

                                            <Divider sx={{ my: 2 }} />

                                            <CheckboxField
                                                name="enable_multiple_instances_synchronization"
                                                label="Enable Multiple Instances Synchronization"
                                                initialValue={
                                                    values?.enable_backup || values?.enable_multiple_instances_synchronization
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    if (submitErrors?.enable_multiple_instances_synchronization) {
                                                        delete submitErrors.enable_multiple_instances_synchronization;
                                                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                                                    }
                                                }}
                                            />

                                            <Divider sx={{ my: 2 }} />

                                            <SliderField name="video_delay_ms" label="Video Delay (ms)" min={0} max={2000} step={50} />

                                            <OnChange name="global_track_configuration.chunk_period">
                                                {() => {
                                                    if (
                                                        submitErrors &&
                                                        submitErrors.global_track_configuration &&
                                                        submitErrors.global_track_configuration.chunk_period
                                                    ) {
                                                        delete submitErrors.global_track_configuration.chunk_period;
                                                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                                                    }
                                                }}
                                            </OnChange>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Video Tracks",
                                    description: "Select individual video tracks and set their parameters",
                                    infoContent: (
                                        <>
                                            <KnowledgeHub
                                                key="kb_item-1720694068497"
                                                entry={KnowledgeHubSelectorType.Item}
                                                isSidebar
                                                entryId="kb_item-1720694068497"
                                                hideTitle
                                                onNavigate={(history, setHistory) => {
                                                    console.log("test...", history);
                                                }}
                                            />
                                        </>
                                    ),
                                    hasError: Boolean(submitErrors?.individual_track_configuration?.video_tracks),
                                    content: (
                                        <Box>
                                            <FieldArray
                                                name="individual_track_configuration.video_tracks"
                                                defaultValue={[{}]}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}>
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, index) => (
                                                            <Card key={index} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Video Track ${index + 1}`}
                                                                    action={
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                fields.remove(index);
                                                                            }}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                                <CardContent>
                                                                    <VideoTrackForm form={form} videoTrackIndex={index} />
                                                                    {submitErrors &&
                                                                        submitErrors.individual_track_configuration &&
                                                                        submitErrors.individual_track_configuration.video_tracks &&
                                                                        typeof submitErrors.individual_track_configuration.video_tracks[
                                                                            index
                                                                        ] === "string" && (
                                                                            <Alert severity="error" sx={{ mt: 2 }}>
                                                                                {
                                                                                    submitErrors.individual_track_configuration
                                                                                        .video_tracks[index]
                                                                                }
                                                                            </Alert>
                                                                        )}
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddCircle />}
                                                            onClick={() =>
                                                                fields.push({
                                                                    profile: "Main"
                                                                })
                                                            }>
                                                            Add Video Track
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Advanced Video Settings",
                                    description: "Configure advanced video settings such as Dolby Vision and Color Enhancement",
                                    infoContent: (
                                        <Box>
                                            <KnowledgeHub
                                                key="kb_item-1720693109793"
                                                entry={KnowledgeHubSelectorType.Item}
                                                isSidebar
                                                entryId="kb_item-1720693109793"
                                                hideTitle
                                                onNavigate={(history, setHistory) => {
                                                    console.log("test...", history);
                                                }}
                                            />
                                            {values?.output_colorimetry_parameters?.enable_dolby_vision ? (
                                                <>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Content Type</b> - Defines the type of content (e.g., cinema/movies), which can
                                                        affect color grading and dynamic range handling.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Intended White Point (k)</b> - Specifies the target white point value for the
                                                        video content, setting the reference for color balance in the image.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Profile</b> - Refers to the Dolby Vision profile to be used, affecting
                                                        compatibility and quality.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>HDR Conformance</b> - Selects the HDR standard for the video stream, like DVB
                                                        HDR10.
                                                    </Typography>
                                                </>
                                            ) : null}
                                            {values?.output_colorimetry_parameters?.hdr_conformance === "DVB HDR10" ||
                                            values?.output_colorimetry_parameters?.hdr_conformance === "ATSC HDR10" ? (
                                                <>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Max Frame Average Light Level (Max FALL)</b> - Sets the maximum average
                                                        brightness level for frames in the video.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Max Content Light Level</b> - Determines the peak brightness level for any given
                                                        frame in the video.
                                                    </Typography>

                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Enable HDR 10+</b> - Enables HDR 10+ encoding for enhanced scene-by-scene color
                                                        and brightness levels.
                                                    </Typography>
                                                    <Typography sx={{ mb: 1 }}>
                                                        <b>Mastering Display Color Volume Settings</b> - Adjusts the color volume settings
                                                        of the mastering display, including minimum and maximum luminance values, which
                                                        define the brightness range for HDR content.
                                                    </Typography>
                                                </>
                                            ) : null}
                                        </Box>
                                    ),
                                    hasError: Boolean(submitErrors?.output_colorimetry_parameters || submitErrors?.color_enhancement),
                                    content: (
                                        <Box>
                                            <Box sx={{ mb: 3 }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Output Color Enhancement
                                                </Typography>
                                                <ColorEnhancementForm form={form} values={values} />
                                            </Box>
                                            <Divider sx={{ my: 3 }} />
                                            <Box>
                                                <Typography variant="h6" gutterBottom>
                                                    Output Colorimetry
                                                </Typography>
                                                <ColorimetryOptionsForm
                                                    form={form}
                                                    values={values}
                                                    submitError={submitError}
                                                    onFieldChange={(fieldName: string) => {
                                                        console.log("fieldName", fieldName);
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Audio Tracks",
                                    description: "Select individual audio tracks and set their parameters",
                                    infoContent: (
                                        <>
                                            <KnowledgeHub
                                                key="kb_item-1720692529087"
                                                entry={KnowledgeHubSelectorType.Item}
                                                isSidebar
                                                entryId="kb_item-1720692529087"
                                                hideTitle
                                                onNavigate={(history, setHistory) => {
                                                    console.log("test...", history);
                                                }}
                                            />
                                        </>
                                    ),
                                    hasError: Boolean(submitErrors?.individual_track_configuration?.audio_tracks),
                                    content: (
                                        <Box>
                                            <FieldArray
                                                name="individual_track_configuration.audio_tracks"
                                                defaultValue={[{ codec: "MPEG AAC" }]}>
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, trackindex) => (
                                                            <Card key={trackindex} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Audio Track ${trackindex + 1}`}
                                                                    action={
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                fields.remove(trackindex);
                                                                            }}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                                <CardContent>
                                                                    <AudioTrackForm
                                                                        audioTrackIndex={trackindex}
                                                                        values={values}
                                                                        submitError={submitError}
                                                                        onFieldChange={(fieldName: string, value: any) => {
                                                                            console.log("fieldName", fieldName);
                                                                            console.log("value", value);
                                                                        }}
                                                                        form={form}
                                                                    />
                                                                    <FieldArray
                                                                        name={`individual_track_configuration.audio_tracks[${trackindex}].variants`}
                                                                        defaultValue={[{}]}
                                                                        validate={(value) => {
                                                                            if (!value) {
                                                                                return "Required";
                                                                            }
                                                                        }}>
                                                                        {({ fields }) => (
                                                                            <>
                                                                                {fields.map((name, index) => (
                                                                                    <Box
                                                                                        key={name}
                                                                                        sx={{
                                                                                            backgroundColor: theme.palette.action.hover,
                                                                                            borderRadius: 1,
                                                                                            padding: 1,
                                                                                            marginBottom: 2
                                                                                        }}>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display: "flex",
                                                                                                flexDirection: "row",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center"
                                                                                            }}>
                                                                                            <Typography fontWeight="bold" fontSize={15}>
                                                                                                Variant {index + 1}
                                                                                            </Typography>
                                                                                            <IconButton
                                                                                                aria-label="delete"
                                                                                                onClick={() => {
                                                                                                    fields.remove(index);
                                                                                                }}>
                                                                                                <Delete />
                                                                                            </IconButton>
                                                                                        </Box>
                                                                                        <Divider sx={{ my: 1 }} />
                                                                                        <AudioTrackVariant
                                                                                            audioTrackIndex={trackindex}
                                                                                            index={index}
                                                                                            name={name}
                                                                                            onFieldChange={(
                                                                                                fieldName: string,
                                                                                                value: any
                                                                                            ) => {
                                                                                                console.log("fieldName", fieldName);
                                                                                                console.log("value", value);
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                ))}
                                                                                <Button
                                                                                    variant="contained"
                                                                                    startIcon={<AddCircle />}
                                                                                    onClick={() => fields.push({})}>
                                                                                    Add Variant
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </FieldArray>
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddCircle />}
                                                            onClick={() => fields.push({ codec: "MPEG AAC" })}>
                                                            Add Audio Track
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Data Tracks",
                                    description: "Select individual data tracks and set their parameters",
                                    infoContent: (
                                        <>
                                            <KnowledgeHub
                                                key="kb_item-1720600005169"
                                                entry={KnowledgeHubSelectorType.Item}
                                                isSidebar
                                                entryId="kb_item-1720600005169"
                                                hideTitle
                                                onNavigate={(history, setHistory) => {
                                                    console.log("test...", history);
                                                }}
                                            />
                                        </>
                                    ),
                                    hasError: Boolean(submitErrors?.individual_track_configuration?.data_tracks),
                                    content: (
                                        <Box>
                                            <FieldArray name="individual_track_configuration.data_tracks">
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, index) => (
                                                            <Card key={index} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Data Track ${index + 1}`}
                                                                    action={
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                fields.remove(index);
                                                                            }}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                                <CardContent>
                                                                    <DataTrackForm
                                                                        dataTrackIndex={index}
                                                                        values={values}
                                                                        onFieldChange={form.change}
                                                                    />
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddCircle />}
                                                            onClick={() => fields.push({})}>
                                                            Add Data Track
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Output Groups",
                                    description: "Select individual output groups and set their parameters",
                                    infoContent: (
                                        <>
                                            <KnowledgeHub
                                                key="kb_item-1720523895397"
                                                entry={KnowledgeHubSelectorType.Item}
                                                isSidebar
                                                entryId="kb_item-1720523895397"
                                                hideTitle
                                                onNavigate={(history, setHistory) => {
                                                    console.log("test...", history);
                                                }}
                                            />
                                        </>
                                    ),
                                    hasError: Boolean(submitErrors?.output_groups),
                                    content: (
                                        <Box>
                                            <FieldArray
                                                name="output_groups"
                                                initialValue={
                                                    values?.output_groups
                                                        ? values.output_groups
                                                        : ([
                                                              {
                                                                  type: "dash",
                                                                  video_track_indexes: [0],
                                                                  audio_track_indexes: [{ track_index: 0 }]
                                                              }
                                                          ] as CreatePushPackagerRequest[] | UpdatePushPackagerRequest[] | any)
                                                }>
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, index) => (
                                                            <Card key={index} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Output Group ${index + 1}`}
                                                                    action={
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                fields.remove(index);
                                                                            }}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    }
                                                                />
                                                                <CardContent>
                                                                    <OutputGroupForm form={form} outputGroupIndex={index} values={values} />
                                                                    {values?.output_groups?.[index]?.type === "hls" && (
                                                                        <>
                                                                            <HlsOutputOptions
                                                                                values={values}
                                                                                outputGroupIndex={index}
                                                                                onFieldChange={(fieldName: string) => {
                                                                                    //deleteErrorProperty(submitError.data.errors, fieldName);
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}

                                                                    {values?.output_groups?.[index]?.type === "hls-file" && (
                                                                        <>
                                                                            <HlsOutputOptions
                                                                                values={values}
                                                                                outputGroupIndex={index}
                                                                                fileMode
                                                                                onFieldChange={(fieldName: string) => {
                                                                                    //deleteErrorProperty(submitError.data.errors, fieldName);
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}

                                                                    {values?.output_groups?.[index]?.type === "hls-fmp4" && (
                                                                        <>
                                                                            <HlsFmp4OutputOptions
                                                                                values={values}
                                                                                outputGroupIndex={index}
                                                                                onFieldChange={(fieldName: string) => {
                                                                                    //deleteErrorProperty(submitError?.data?.errors, fieldName);
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}

                                                                    {values?.output_groups?.[index]?.type === "dash" && (
                                                                        <>
                                                                            <DashOutputOptions
                                                                                values={values}
                                                                                outputGroupIndex={index}
                                                                                onFieldChange={(fieldName: string) => {
                                                                                    //deleteErrorProperty(submitError.data.errors, fieldName);
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<AddCircle />}
                                                            onClick={() => fields.push({})}>
                                                            Add Output Group
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    )
                                },
                                {
                                    label: "Review",
                                    description: "Review your push packager configuration",
                                    infoContent: (
                                        <>
                                            <Typography sx={{ mb: 1 }}>
                                                Review your push packager configuration and click create.
                                            </Typography>
                                            <Typography>
                                                You also have the option to save this configuration as a prest to reuse it in the future.
                                            </Typography>
                                        </>
                                    ),
                                    hasError: false,
                                    content: <Review values={values} form={form} />
                                }
                            ]}
                            formTitle={mode === "create" ? "Create Push Packager" : "Edit Push Packager: " + values.name}
                            values={values}
                            submitError={submitError}
                            invalid={invalid}
                            submitting={submitting}
                            submitButtonText={mode === "create" ? "Create Push Packager" : "Update Push Packager"}
                            successStep={<CreatePackagerSuccess packager_id={newPackagerId} mode={mode} values={values} />}
                            successTabContent={
                                <>
                                    <Typography sx={{ mb: 3 }}>Your Push Packager is now building.</Typography>
                                    <Typography sx={{ mb: 3 }}>This can take up to 15 minutes.</Typography>
                                    <Typography>Once complete, you will receive a notification</Typography>
                                </>
                            }
                            showSuccessStep={submitSucceeded}
                            onFinish={() => {
                                navigate("/packager");
                            }}
                            onCancel={() => {
                                openDialog(
                                    <>
                                        <DialogTitle>Are you sure?</DialogTitle>
                                        <DialogContent>You have unsaved changes. Are you sure you want to leave this page?</DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    closeDialog();
                                                }}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    closeDialog();
                                                    navigate("/packager");
                                                }}>
                                                Leave
                                            </Button>
                                        </DialogActions>
                                    </>
                                );
                            }}
                            mode={mode === "edit" || submitFailed ? "edit" : "create"}
                        />
                    </form>
                );
            }}
        />
    );
};

export default CreatePushPackager;
