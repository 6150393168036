import React, { useContext, useEffect, Fragment, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "app/common/form/ErrorMessage";
import { ISchedulerFormValues, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { PermanentScheduleSuccessMessage } from "./PermanentScheduleSuccessMessage";
import { Button, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Select } from "mui-rff";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useScheduler, useSources } from "hooks";

interface IProps {
    permanent_schedule_input_id: string;
    permanent_schedule_input_name: string;
    id: string;
}

interface IDropdownValues {
    key: string;
    text: string;
    value: string;
}

const PermanentScheduleForm: React.FC<IProps> = ({ permanent_schedule_input_id, id }) => {
    const validate = combineValidators({
        source_id: isRequired("Source")
    });

    const rootStore = useContext(RootStoreContext);
    const { activeenvironment } = rootStore.userStore;
    const { destinations } = rootStore.destinationStore;
    const { getInputsDropdownList } = useSources();
    const { openDialog } = rootStore.dialogStore;
    const { closeDialog } = rootStore.dialogStore;
    const { closeDrawer } = rootStore.drawerStore;
    const { setCachedNull } = rootStore.schedulerStore;
    const { createPermanentSchedule } = useScheduler();
    const [channels, setChannels] = useState<IDropdownValues[]>();
    const [channelsLoaded, setChannelsLoaded] = useState(false);

    useEffect(() => {
        if (activeenvironment) {
            // Load channels for dropdown
            getInputsDropdownList().then((values) => {
                setChannels(
                    values.data.map((item) => {
                        return { key: item.id, text: item.name, value: item.id };
                    })
                );
                setChannelsLoaded(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeenvironment]);

    const initialValues = {
        source_id: permanent_schedule_input_id
    };

    return (
        <>
            {channelsLoaded && (
                <FinalForm
                    onSubmit={(values: ISchedulerFormValues) =>
                        createPermanentSchedule(values, id)
                            .then(() => {
                                closeDialog();
                                closeDrawer();
                                const switchedToChannelEntry = channels?.find((entry) => entry.value === values.source_id);
                                openDialog(
                                    <PermanentScheduleSuccessMessage
                                        body={
                                            <span>
                                                Destination has been switched to broadcast source <b>"{switchedToChannelEntry!.text}"</b>
                                            </span>
                                        }
                                        header={"Destination Updated"}
                                    />
                                );
                            })
                            .catch((error) => ({
                                [FORM_ERROR]: error
                            }))
                    }
                    validate={validate}
                    initialValues={initialValues}
                    render={({ handleSubmit, submitError, invalid, dirtySinceLastSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle>Switch Permanently Scheduled Source</DialogTitle>
                            <DialogContent>
                                {destinations ? (
                                    <Fragment>
                                        <label className="livelink-formlabel livelink-formlabelControl" style={{ margin: "0 0 5px 0" }}>
                                            Source
                                        </label>
                                        <Select name="source_id" formControlProps={{ fullWidth: true }} required={true}>
                                            {channels?.map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.text}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Fragment>
                                ) : null}
                                {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        closeDialog();
                                        closeDrawer();
                                        setCachedNull();
                                    }}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    startIcon={<SwapHorizIcon />}>
                                    Switch Source
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    )}
                />
            )}
        </>
    );
};

export default observer(PermanentScheduleForm);
