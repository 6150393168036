import React, { useContext, useEffect, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { TextField, Checkboxes } from "mui-rff";
import { combineValidators, isRequired, createValidator, composeValidators } from "revalidate";
import ErrorMessage from "app/common/form/ErrorMessage";
import * as alertOptions from "app/common/options/alertsOptions";
import arrayMutators from "final-form-arrays";
import { observer } from "mobx-react-lite";
import { IAlertsFormValue, IAlertMethod, RootStoreContext } from "api";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Theme,
    Typography,
    useTheme
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { FORM_ERROR } from "final-form";
import { InputsOutputsListEntry } from "api";
import LoadingButton from "@mui/lab/LoadingButton";
import OnChange from "components/FinalFormListener/OnChange";
import { SelectField } from "components/Forms";
import DestinationDetails from "features/destinations/Components/DestinationDetails";
import { TextField as MuiTextField } from "@mui/material";
import { useAlert, useScheduler } from "hooks";

const Condition = ({ when, is, not = false, children }: { when: string; is: string; not?: boolean; children: React.ReactNode }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => {
            const values = is.split(",").map((item: string) => item.trim());
            if (!not) {
                return values.includes(value) ? children : null;
            }
            return !values.includes(value) ? children : null;
        }}
    </Field>
);

interface IAlertFormProps {
    item_id: string;
    type: "input" | "output";
    successCallback?: () => any;
}

const AlertsForm: React.FC<IAlertFormProps> = observer(function AlertsForm({ item_id, type, successCallback }) {
    const rootStore = useContext(RootStoreContext);
    const { openDrawer, closeDrawer } = rootStore.drawerStore;
    const { closeDialog } = rootStore.dialogStore;
    const { alertMethods } = rootStore.schedulerStore;
    const { createAlert, getOrganisationAlertMethods } = useScheduler();
    const { inputAndOutputs } = rootStore.alertStore;
    const { getAlertsOverview, getInputsAndOutputs } = useAlert();
    const [queryValues, setQueryValues] = useState<any>();
    const [showMultiples, setShowMultiples] = useState<boolean>(false);
    const [selectedDestinations, setSelectedDestinations] = React.useState<string[]>([]);

    useEffect(() => {
        getOrganisationAlertMethods();
        getAlertsOverview();
        getInputsAndOutputs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChipLabel = (value: string) => {
        if (inputAndOutputs) {
            let array = type === "input" ? inputAndOutputs.inputs : inputAndOutputs.outputs;
            const item = array?.find((input) => input.id === value);
            return item?.name;
        }
        return "";
    };

    const queryValuehandler = (value: any, values: any) => {
        if (values.query_param === "gt") {
            if (values.alert_on_query === "is_connected") {
                setQueryValues([0]);
            } else {
                setQueryValues([value]);
            }
        } else if (values.query_param === "lt") {
            if (values.alert_on_query === "is_connected") {
                setQueryValues([1]);
            } else {
                setQueryValues([value]);
            }
        } else {
            setQueryValues([values.query_value_1, values.query_value_2]);
        }
    };

    const isNotNegative = createValidator(
        (message) => (value) => {
            if (value && value < 0) {
                return message;
            }
        },
        "Value can not be below 0"
    );

    const parse = (value: any) => (isNaN(parseFloat(value)) ? "" : parseFloat(value));

    const validate = combineValidators({
        alert_name: isRequired("name"),
        alert_method_id: isRequired("alert method"),
        alert_on_query: isRequired("alert query"),
        query_param: isRequired("parameters"),
        query_time_range: isRequired("time range"),
        alert_check_frequency: composeValidators(isNotNegative, isRequired("frequency"))(),
        alert_after_time: composeValidators(isNotNegative, isRequired("time"))(),
        alert_repeat: isRequired("repeat")
    });

    const inputValues: IAlertsFormValue = {
        alert_name: "",
        alert_method_id: "",
        alert_on_query: "",
        query_param: "",
        query_value: 0,
        query_time_range: "10s",
        alert_check_frequency: "",
        alert_check_frequency_unit: "s",
        alert_after_time: 0,
        alert_after_time_unit: "s",
        alert_repeat: "",
        alert_only_when_schedule_on: false,
        input_ids: undefined,
        output_ids: undefined
    };

    return (
        <>
            <FinalForm
                onSubmit={(values: IAlertsFormValue) => {
                    // Concat source together with the multi selection (avoiding duplicates)
                    let input_ids: string[] = type === "input" ? selectedDestinations : [];
                    let output_ids: string[] = type !== "input" ? selectedDestinations : [];
                    if (type === "input") {
                        if (values.input_ids) {
                            input_ids = input_ids.concat(values.input_ids);
                        }
                        if (input_ids.indexOf(item_id) === -1) {
                            input_ids.push(item_id);
                        }
                    }
                    if (type === "output") {
                        if (values.output_ids) {
                            output_ids = output_ids.concat(values.output_ids);
                        }
                        if (output_ids.indexOf(item_id) === -1) {
                            output_ids.push(item_id);
                        }
                    }
                    const payload = {
                        alert_name: values.alert_name,
                        alert_method_id: values.alert_method_id,
                        alert_on_query: values.alert_on_query,
                        query_param: values.query_param,
                        query_value: queryValues ? queryValues : [0],
                        query_time_range: values.query_time_range,
                        alert_after_time: values.alert_after_time + values.alert_after_time_unit,
                        alert_check_frequency: values.alert_check_frequency + values.alert_check_frequency_unit,
                        alert_repeat: values.alert_repeat,
                        alert_only_when_schedule_on: values.alert_only_when_schedule_on,
                        output_ids: output_ids,
                        input_ids: input_ids
                    };

                    createAlert(payload)
                        .then(() => {
                            successCallback && successCallback();
                            closeDialog();
                        })
                        .then(() =>
                            type === "input"
                                ? closeDrawer()
                                : openDrawer(
                                      <Box sx={{ width: 680, padding: 3 }}>
                                          <DestinationDetails destination_id={item_id!} tab={"2"} />
                                      </Box>
                                  )
                        )
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                            presetName: error.data.errors ? error.data.errors.preset_name : null
                        }));
                }}
                mutators={{
                    ...arrayMutators
                }}
                validate={validate}
                initialValues={inputValues}
                render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Tooltip
                                placement="left"
                                arrow
                                title="Alert name will appear in the alerts list. It needs to be unique and can be changed later.">
                                <div>
                                    <TextField
                                        required
                                        label="Name"
                                        name="alert_name"
                                        placeholder="Name alert here"
                                        type="text"
                                        variant="standard"
                                        fieldProps={{ variant: "standard" }}
                                    />
                                </div>
                            </Tooltip>

                            <Tooltip
                                placement="left"
                                arrow
                                title="Select one of the configured alert methods, if there is no available alert method please create one first in Settings -> Alert Management -> Alter Notification Methods.">
                                <div>
                                    <SelectField
                                        required
                                        name="alert_method_id"
                                        label="Alert method"
                                        options={
                                            alertMethods
                                                ? alertMethods?.data.map((method: IAlertMethod) => ({
                                                      key: method.id,
                                                      text: method.name,
                                                      value: method.id
                                                  }))
                                                : []
                                        }
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                    />
                                </div>
                            </Tooltip>

                            <Tooltip placement="left" arrow title="Select a query for the alert.">
                                <div>
                                    <SelectField
                                        required
                                        name="alert_on_query"
                                        label="Alert metric"
                                        options={alertOptions.alert_on_query}
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                    />
                                </div>
                            </Tooltip>
                            <Condition when="alert_on_query" is="is_connected">
                                <Tooltip placement="left" arrow title="Select the parameter for the alert query.">
                                    <div>
                                        <SelectField
                                            required
                                            name="query_param"
                                            label="Alert query parameters"
                                            options={alertOptions.query_param_connection_status}
                                            helperText={(meta) => meta.touched && meta.error}
                                            error={(meta) => meta.touched && meta.error}
                                        />
                                        <OnChange name="query_param">
                                            {(value) => {
                                                queryValuehandler(value, values);
                                            }}
                                        </OnChange>
                                    </div>
                                </Tooltip>
                            </Condition>
                            <Condition when="alert_on_query" is="is_connected" not={true}>
                                <Tooltip placement="left" arrow title="Select the parameter for the alert query.">
                                    <div>
                                        <SelectField
                                            required
                                            name="query_param"
                                            label="Alert query parameters"
                                            options={alertOptions.query_param}
                                            helperText={(meta) => meta.touched && meta.error}
                                            error={(meta) => meta.touched && meta.error}
                                        />
                                    </div>
                                </Tooltip>
                                <Condition when="query_param" is="outside_range, within_range" not={true}>
                                    <Tooltip
                                        placement="left"
                                        arrow
                                        title="Set the value thresholds based on the query selected, i.e. Bitrate in Kbps">
                                        <div>
                                            <TextField
                                                required
                                                label="Query values"
                                                name="query_value"
                                                placeholder="Query values"
                                                type="number"
                                                fieldProps={{
                                                    parse: parse
                                                }}
                                            />
                                            <OnChange name="query_value">
                                                {(value) => {
                                                    queryValuehandler(value, values);
                                                }}
                                            </OnChange>
                                        </div>
                                    </Tooltip>
                                </Condition>
                                <Condition when="query_param" is="outside_range, within_range">
                                    <TextField
                                        required
                                        label="Value 1"
                                        name="query_value_1"
                                        placeholder="Query value 1"
                                        type="number"
                                        fieldProps={{
                                            parse: parse
                                        }}
                                    />
                                    <OnChange name="query_value_1">
                                        {(value) => {
                                            queryValuehandler(value, values);
                                        }}
                                    </OnChange>
                                    <TextField
                                        required
                                        label="Value 2"
                                        name="query_value_2"
                                        placeholder="Query value 2"
                                        type="number"
                                        fieldProps={{
                                            parse: parse
                                        }}
                                    />
                                    <OnChange name="query_value_2">
                                        {(value) => {
                                            queryValuehandler(value, values);
                                        }}
                                    </OnChange>
                                </Condition>
                            </Condition>

                            <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                                <Box width="50%">
                                    <Tooltip
                                        placement="left"
                                        arrow
                                        title="Specify how often the system should evaluate the alert, i.e. if set to 30s the system will trigger an evaluation every 30s, the value should be set in conjunction with the evaluation period.">
                                        <div>
                                            <TextField
                                                required
                                                label="Evaluation Interval"
                                                name="alert_check_frequency"
                                                placeholder="Evaluation Interval"
                                                type="number"
                                                fieldProps={{
                                                    parse: parse
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </Box>
                                <Box width="50%">
                                    <Tooltip placement="left" arrow title="Set the unit of time for the evaluation interval.">
                                        <div>
                                            <SelectField
                                                required
                                                name="alert_check_frequency_unit"
                                                label="Time unit"
                                                options={alertOptions.time_types}
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                            />
                                        </div>
                                    </Tooltip>
                                </Box>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                                <Box width="50%">
                                    <Tooltip
                                        placement="left"
                                        arrow
                                        title="Specify the period the query needs to violate the configured thresholds for, before the alert notification triggers. This needs to be less than the evaluation interval.">
                                        <div>
                                            <TextField
                                                required
                                                label="Evaluation period"
                                                name="alert_after_time"
                                                placeholder="Evaluation period"
                                                type="number"
                                                fieldProps={{
                                                    parse: parse
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </Box>
                                <Box width="50%">
                                    <Tooltip placement="left" arrow title="Set the unit of time for the evaluation interval.">
                                        <div>
                                            <SelectField
                                                required
                                                name="alert_after_time_unit"
                                                label="Time unit*"
                                                options={alertOptions.time_types}
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                            />
                                        </div>
                                    </Tooltip>
                                </Box>
                            </Stack>
                            <Tooltip
                                placement="left"
                                arrow
                                title="Specify how often a notification is sent after the initial notification while the alert condition remains.">
                                <div>
                                    <SelectField
                                        required
                                        name="alert_repeat"
                                        label="Alert repeat time"
                                        options={alertOptions.alert_repeat}
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                    />
                                </div>
                            </Tooltip>
                            <FormGroup sx={{ padding: 2 }}>
                                <FormControlLabel
                                    label="Alert only when schedule is on"
                                    control={
                                        <Tooltip
                                            placement="left"
                                            arrow
                                            title="Trigger the alert only when a the channel is live to a destination.">
                                            <div>
                                                <Checkboxes
                                                    name="alert_only_when_schedule_on"
                                                    color="primary"
                                                    data={{
                                                        label: "",
                                                        value: undefined
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                />
                                <FormControlLabel
                                    label={`Select multiple
                      ${type === "input" ? "channels" : "destinations"}`}
                                    control={
                                        <Tooltip
                                            placement="left"
                                            arrow
                                            title={`Apply this alert to multiple${type === "input" ? "channels" : "destinations"}`}>
                                            <div>
                                                <Checkboxes
                                                    name={`Select multiple ${type === "input" ? "channels" : "destinations"}`}
                                                    color="primary"
                                                    data={{
                                                        label: "",
                                                        value: undefined
                                                    }}
                                                    onClick={() => setShowMultiples(!showMultiples)}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                />
                            </FormGroup>

                            {showMultiples && inputAndOutputs && (
                                <Select
                                    labelId="select-destinations-label"
                                    id="select-destinations"
                                    name="select-destinations"
                                    multiple
                                    size="small"
                                    value={selectedDestinations}
                                    onChange={(event: any) => {
                                        const {
                                            target: { value }
                                        } = event;
                                        setSelectedDestinations(typeof value === "string" ? value.split(",") : value);
                                    }}
                                    input={
                                        <MuiTextField
                                            variant="outlined"
                                            select
                                            id="select-destinations-chip"
                                            label={`Multiple ${type === "input" ? "channels" : "destinations"}`}
                                        />
                                    }
                                    renderValue={(selected) => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected?.map((value) => (
                                                <Chip key={value} label={getChipLabel(value)} size="small" />
                                            ))}
                                        </Box>
                                    )}>
                                    {inputAndOutputs &&
                                        (type === "input" ? inputAndOutputs?.inputs! : inputAndOutputs?.outputs!).map(
                                            (opt: InputsOutputsListEntry) => (
                                                <MenuItem key={opt.id} value={opt.id}>
                                                    <Checkbox size="small" checked={selectedDestinations.indexOf(opt.id) > -1} />
                                                    <ListItemText primary={opt.name} />
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                            )}
                            {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => closeDialog()}>Cancel</Button>
                            <LoadingButton
                                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                loading={submitting}
                                type="submit"
                                variant="contained"
                                color="primary">
                                Create
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            />
        </>
    );
});

export default AlertsForm;
