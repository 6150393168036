import { observable, action, computed, makeObservable } from "mobx";
import { IOrganisations, ManagingOrganisationListResponse } from "api";
import { RootStore } from "./rootStore";

export default class OrganisationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable organisations: IOrganisations | null = null;
    @observable managingOrgList: ManagingOrganisationListResponse | null = null;
    @observable loading = false;
    @observable isNotGuest = true;

    @action setOrganisations = (organisations: IOrganisations) => {
        this.organisations = organisations;
    };

    @action setManagingOrgList = (managingOrgList: ManagingOrganisationListResponse) => {
        this.managingOrgList = managingOrgList;
    };

    @action setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    @action setIsNotGuest = (isNotGuest: boolean) => {
        this.isNotGuest = isNotGuest;
    };

    @computed
    get activeOrganisation(): number | undefined {
        return this.rootStore.userStore.user?.active_org;
    }
}
