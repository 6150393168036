import { Check } from "@mui/icons-material";
import { Avatar, Box, Checkbox, Chip, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { IEnvironment, RootStoreContext, agent } from "api";
import ProgressChip from "components/ProgressChip";
import { observer } from "mobx-react-lite";
import React from "react";
import { ResCollection, ResModel } from "resclient";
import { format } from "date-fns";

interface EnvironmentsItemProps {
    environment: IEnvironment;
    is_active: boolean;
    onClick: (environment: IEnvironment) => void;
    onUpdate?: () => void;
}

const EnvironmentsItem: React.FC<EnvironmentsItemProps> = ({ environment, is_active, onClick, onUpdate }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_org_id = rootStore.userStore.activeOrganisation;

    const rid = `environments_v2.environments.${managing_org_id}.${active_org_id}.environment.${environment.id}`;

    //const rid = `environments.environments.${managing_org_id}.environment.${environment.id}`;

    const [wsData, setWsData] = React.useState<ResModel | ResCollection | any>({});

    const [environmentDetails, setEnvironmentDetails] = React.useState<any>();

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const getEnvironmentDetails = async (environment: IEnvironment) => {
        await agent.Environment.getEnvironmentDetails(environment.id)
            .then((response) => {
                setEnvironmentDetails(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getEnvironmentDetails(environment);
        clientConnected &&
            client
                .get(rid)
                .then((res) => {
                    setWsData(res);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setWsData(res);
                        onUpdate && onUpdate();
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [client, clientConnected, rid, environment]);

    React.useEffect(() => {}, [updateDate]);

    React.useEffect(() => {
        //if wsData.status === creating or upgrading, get environment details every 2 seconds
        if (wsData.status === "creating" || wsData.status === "upgrading") {
            const interval = setInterval(() => {
                getEnvironmentDetails(environment);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [wsData]);

    return (
        <TableRow
            sx={{
                cursor: "pointer"
            }}
            selected={is_active}
            hover
            key={environment.id}
            onClick={() => {
                onClick(environment);
            }}>
            <TableCell>
                <Checkbox checked={is_active} />
            </TableCell>
            <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {environment.is_active_environment && (
                        <Tooltip title="Active Environment" placement="top">
                            <Avatar
                                sx={{
                                    width: 14,
                                    height: 14,
                                    marginRight: 1.5,
                                    backgroundColor: (theme) => theme.palette.primary.main
                                }}>
                                <Check sx={{ fontSize: 8 }} />
                            </Avatar>
                        </Tooltip>
                    )}
                    <Box>
                        <Typography fontWeight="bold" lineHeight="1rem">
                            {environment.name}
                        </Typography>
                        {environment.is_environment_outdated && (
                            <Typography variant="body2" color="textSecondary">
                                Update Available
                            </Typography>
                        )}
                    </Box>
                </Box>
            </TableCell>
            <TableCell>
                {wsData.status === "creating" ? (
                    <ProgressChip
                        label={
                            wsData.status +
                            ": Step " +
                            (environmentDetails?.details?.current_step || "0") +
                            " of " +
                            (environmentDetails?.details?.number_of_steps || "0")
                        }
                    />
                ) : wsData.status === "stopping" ||
                  wsData.status === "starting" ||
                  wsData.status === "deleting" ||
                  wsData.status === "upgrading" ||
                  wsData.status === "restarting" ? (
                    <ProgressChip label={wsData.status} />
                ) : (
                    <Chip
                        sx={{ textTransform: "capitalize" }}
                        label={wsData.status}
                        color={wsData.status === "stopped" ? "error" : wsData.status === "running" ? "success" : undefined}
                        size="small"
                    />
                )}
            </TableCell>
            <TableCell>{environment.cloud_provider_human_name}</TableCell>
            <TableCell>{environment.region}</TableCell>
            <TableCell>{environment.instance_size_commercial}</TableCell>
            <TableCell>
                {
                    //if environment.fully_up_since exists format the date using date-fns else return "-"
                    environment.fully_up_since ? format(new Date(environment.fully_up_since), "dd-MM-yy HH:mm:ss") : "-"
                }
            </TableCell>
        </TableRow>
    );
};

export default observer(EnvironmentsItem);
