import React, { useContext, useState } from "react";
import { RootStoreContext } from "api";
import { DialogActions, Button, DialogContent, DialogTitle, Typography, Alert } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useDestinations } from "hooks";

interface IProps {
    destination_id: string;
    destination_name: string;
}

export const ConfirmDestinationStart: React.FC<IProps> = ({ destination_id, destination_name }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { setDestinationStart } = rootStore.destinationStore;
    const { startDestination } = useDestinations();
    const [error, setError] = useState("");

    const startItem = () => {
        startDestination(destination_id)
            .then(() => {
                closeDialog();
                closeDialog();
                setDestinationStart();
            })
            .catch((error) => {
                setError(error.data.message);
            });
    };

    return (
        <>
            <DialogTitle>{`Start ${destination_name}`}</DialogTitle>
            <DialogContent>
                <Typography marginBottom={2}>
                    Are you sure you want to start destination:{" "}
                    <span style={{ fontWeight: 600, fontSize: "1rem" }}>{destination_name}</span>?
                </Typography>
                <Typography>
                    This will put the destination in to a live state allowing a connection to be established. Please note that some
                    destination types i.e. RTMP may require a source stream to be sent before they show as 'connected'.
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        startItem();
                    }}
                    startIcon={<PlayArrowIcon />}
                    autoFocus>
                    Start
                </Button>
            </DialogActions>
        </>
    );
};
