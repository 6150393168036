import { RootStore } from "./rootStore";
import { action, observable, runInAction, makeObservable } from "mobx";

export default class SchedulerStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable environmentUpgrade = "";
    @observable cachedStart: any;
    @observable cachedEnd: any;
    @observable alertMethods: any | null = null;
    @observable date: Date = new Date();
    @observable activeDestination: any = null;

    @action setAlertMethods = (alertMethods: any) => {
        this.alertMethods = alertMethods;
    };

    @action setDate = (date: Date) => {
        runInAction(() => {
            this.date = date;
        });
    };

    @action setActiveDestination = (destination: any) => {
        runInAction(() => {
            this.activeDestination = destination;
        });
    };

    @action setCachedNull = () => {
        runInAction(() => {
            this.cachedStart = null;
            this.cachedEnd = null;
        });
    };

    @action setEnvironmentUpgradeDate = (date: string) => {
        this.environmentUpgrade = date;
    };
}
