import React, { useContext } from "react";
import { IEnvironmentsData, RootStoreContext } from "api";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { format } from "date-fns";

interface IProps {
    environment: IEnvironmentsData;
    upgradeDate: string;
}

export const EnvironmentScheduledUpgrade: React.FC<IProps> = ({ environment, upgradeDate }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <>
            <DialogTitle>Environment upgrading</DialogTitle>
            <DialogContent>
                <Typography>
                    Environment: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{environment.name}</span> has been scheduled for
                    update.
                </Typography>
                <Typography>Your environment has been scheduled for update on {format(new Date(upgradeDate), "dd-MM-yyyy @ HH:mm:ss")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};
