import { LoadingButton } from "@mui/lab";
import { Alert, Button, Checkbox, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material";
import { RootStoreContext } from "api";
import React, { useContext, useState } from "react";

interface ConfirmationDialogProps {
    title: string;
    message: string;
    additionalContent?: React.ReactNode;
    confirmationCheckbox?: boolean;
    confirmationCheckboxLabel?: string;
    confirmButtonText?: string;
    confirmButtonColor?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
    onConfirm: () => Promise<void>;
    onSuccess?: () => void;
    onCancel?: () => void;
    hideCancelButton?: boolean;
}

/**
 * `ConfirmationDialog` is a reusable React component that displays a confirmation dialog to the user.
 * It uses Material UI components for the dialog and buttons.
 *
 * @component
 * @param {object} props - The properties that define the dialog.
 * @param {string} props.title - The title of the dialog.
 * @param {string} props.message - The message to display in the dialog.
 * @param {React.ReactNode} [props.additionalContent] - Additional content to display in the dialog.
 * @param {boolean} [props.confirmationCheckbox] - Whether to display a checkbox that the user must check before confirming the action.
 * @param {string} [props.confirmationCheckboxLabel] - The label to display next to the confirmation checkbox.
 * @param {string} [props.confirmButtonText] - The text to display on the confirm button. Defaults to "Confirm".
 * @param {"primary" | "secondary" | "error" | "info" | "success" | "warning"} [props.confirmButtonColor] - The color of the confirm button. Defaults to "primary".
 * @param {() => Promise<void>} props.onConfirm - The function to call when the confirm button is clicked. This function should return a Promise, allowing for asynchronous actions to be performed and awaited upon confirmation.
 * @param {() => void} [props.onSuccess] - The function to call after the confirmation action has been successfully completed.
 * @param {() => void} [props.onCancel] - The function to call when the cancel button is clicked.
 * @param {boolean} [props.hideCancelButton] - Whether to hide the cancel button. Defaults to false.
 *
 * @example
 * <ConfirmationDialog
 *   title="Confirm Action"
 *   message="Are you sure you want to perform this action?"
 *   onConfirm={async () => {
 *     // Perform the action here
 *     // This could be a call to an API to delete an item
 *     await api.deleteItem(itemId);
 *   }}
 * />
 */

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    title,
    message,
    additionalContent,
    confirmationCheckbox,
    confirmationCheckboxLabel,
    confirmButtonText,
    confirmButtonColor,
    onConfirm,
    onSuccess,
    onCancel,
    hideCancelButton = false
}) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const [error, setError] = useState<any>(null);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const handleCancel = () => {
        onCancel && onCancel();
        closeDialog();
    };

    // const handleConfirm = () => {
    //     onConfirm()
    //         .then(() => {
    //             //closeDialog();
    //             onSuccess && onSuccess();
    //         })
    //         .catch((error) => {
    //             setError(error);
    //             console.log(error);
    //         });
    // };

    const handleConfirm = async () => {
        try {
            setSubmitting(true);
            await onConfirm();
            closeDialog();
            onSuccess && onSuccess();
            setSubmitting(false);
        } catch (error) {
            setError(error);
            console.log(error);
            setSubmitting(false);
        }
    };

    return (
        <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                {additionalContent && additionalContent}
                {confirmationCheckbox && (
                    <FormControlLabel
                        sx={{ mt: 2 }}
                        control={<Checkbox checked={confirm} onChange={() => setConfirm(!confirm)} />}
                        label={confirmationCheckboxLabel || "I confirm that I want to perform this action"}
                    />
                )}
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error.data?.message || "An error occurred"}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                {!hideCancelButton && (
                    <Button onClick={handleCancel}>Cancel</Button>
                )}
                <LoadingButton
                    variant="contained"
                    loading={submitting}
                    color={confirmButtonColor ? confirmButtonColor : "primary"}
                    disabled={confirmationCheckbox && !confirm}
                    onClick={handleConfirm}>
                    {confirmButtonText ? confirmButtonText : "Confirm"}
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default ConfirmationDialog;
