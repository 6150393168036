import { useState, useEffect, RefObject } from "react";

interface Args extends IntersectionObserverInit {
    freezeOnceVisible?: boolean;
}

/**
 * A hook that returns a boolean indicating whether the specified element is currently on the screen within the viewport
 *
 * @template T - HTMLElement or its descendant interface type that implements `HTMLElement`
 * @param {RefObject<T>} ref - Ref object pointing to the HTMLElement in question
 * @param {object} [{ threshold = 0.1, rootMargin = "0px", freezeOnceVisible = false }={}] - Options passed to IntersectionObserver constructor (threshold and rootMargin) and additional flags (freezeOnceVisible)
 * @param {number} [threshold=0.1] - Amount of intersection between the target element and its root container at which to trigger a callback
 * @param {string} [rootMargin="0px"] - Margin around the root container
 * @param {boolean} [freezeOnceVisible=false] - If true, intersection observer will disconnect when the element becomes visible once
 * @returns {boolean} - A boolean indicating whether the element is currently on screen within the viewport
 */


function useOnScreen<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    { threshold = 0.1, rootMargin = "0px", freezeOnceVisible = false }: Args = {}
): boolean {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);

                if (entry.isIntersecting && freezeOnceVisible) {
                    observer.disconnect();
                }
            },
            { threshold, rootMargin }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [freezeOnceVisible, threshold, rootMargin, ref]);

    return isIntersecting;
}

export default useOnScreen;
