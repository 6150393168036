import { Box, List, ListItem, ListItemButton } from "@mui/material";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import OrganisationRoleProvider from "components/OrganisationRoleProvider";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

export const Billing: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <View>
            <ViewHeader title="Billing" />
            <ViewBody noPadding>
                <Content
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/billing"}
                                    onClick={() => {
                                        navigate("");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Home</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/billing/permanent-commitment"}
                                    onClick={() => {
                                        navigate("permanent-commitment");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Permanent Commitment</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/billing/price-list"}
                                    onClick={() => {
                                        navigate("price-list");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Price List</Box>
                                </ListItemButton>
                            </ListItem>
                            <OrganisationRoleProvider roles={["admin"]}>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        selected={location.pathname === "/billing/monthly-reports"}
                                        onClick={() => {
                                            navigate("monthly-reports");
                                        }}>
                                        <Box sx={{ padding: "5px 0" }}>Monthly Reports</Box>
                                    </ListItemButton>
                                </ListItem>
                            </OrganisationRoleProvider>
                            <OrganisationRoleProvider roles={["admin"]}>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        selected={location.pathname === "/billing/usage-reports"}
                                        onClick={() => {
                                            navigate("usage-reports");
                                        }}>
                                        <Box sx={{ padding: "5px 0" }}>Usage Reports</Box>
                                    </ListItemButton>
                                </ListItem>
                            </OrganisationRoleProvider>
                        </List>
                    }
                    leftWidth={220}
                    noPadding>
                    <Outlet />
                </Content>
            </ViewBody>
        </View>
    );
};

export default Billing;
