import React, { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { TextField } from "mui-rff";
import { IUserFormValues, RootStoreContext } from "api";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "app/common/form/ErrorMessage";
import { ResetConfirmation } from "./Registration/RegistrationFormItems/ResetConfirmation";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EmailIcon from '@mui/icons-material/Email';

const validate = combineValidators({
    email: isRequired("email")
});

export const ForgottenPassword = (): JSX.Element => {
    const rootStore = useContext(RootStoreContext);
    const { resetPasswordRequest } = rootStore.userStore;
    const { changeDialogBody, closeDialog } = rootStore.dialogStore;

    return (
        <FinalForm
            onSubmit={(values: IUserFormValues) =>
                resetPasswordRequest(values)
                    .then(() => {
                        changeDialogBody(<ResetConfirmation />);
                    })
                    .catch((error) => ({
                        [FORM_ERROR]: error
                    }))
            }
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent>

                        <Stack direction="row" alignItems="center">
                        <EmailIcon sx={{ mr: 2, opacity: 0.5 }} />
                        <TextField fullWidth required name="email" placeholder="Email" label="Email address" type="email" />
                        </Stack>

                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeDialog()}>Cancel</Button>
                    <LoadingButton
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            loading={submitting}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            
                        >
                            Reset Password
                        </LoadingButton>
                    </DialogActions>
                </form>
            )}
        />
    );
};
