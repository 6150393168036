import { AccessTime, CalendarToday, CellTower, CheckCircle, Delete, Error, Repeat, Videocam } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
    toggleButtonGroupClasses
} from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { RootStoreContext, agent } from "api";
import { addDays, addHours, addMonths, addWeeks, addYears, format, set, setSeconds } from "date-fns";
import { useSources } from "hooks";
import React, { useContext, useEffect } from "react";

interface CreateRepeatScheduleFormProps {
    source_id?: string;
    destination_id?: string;
    start_date?: Date;
    end_date?: Date;
}

interface IScheduleRow {
    id: string;
    startNow: boolean;
    start_date: Date;
    end_date: Date;
    source_id: string | undefined;
    destination_id: string | undefined;
    submitting: boolean;
    success: boolean;
    isValid: boolean;
    errorMessage: string | undefined;
    errors: any;
}

const CreateRepeatScheduleForm: React.FC<CreateRepeatScheduleFormProps> = ({ source_id, destination_id, start_date, end_date }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const active_environment = rootStore.userStore.activeEnvironment;
    const { getInputsDropdownList } = useSources();

    const [sources, setSources] = React.useState<any>();
    const [destinations, setDestinations] = React.useState<any>();

    const [step, setStep] = React.useState<number>(1);

    //Form Values ---------------------------------------------------
    const [sourceId, setSourceId] = React.useState<string>(source_id ? source_id : "");
    const [destinationId, setDestinationId] = React.useState<string>(destination_id ? destination_id : "");
    const [startDate, setStartDate] = React.useState<Date>(start_date ? start_date : setSeconds(new Date(), 0));
    const [endDate, setEndDate] = React.useState<Date>(end_date ? end_date : setSeconds(addHours(new Date(), 1), 0));
    const [repeat, setRepeat] = React.useState<boolean>(false);
    const [repeatEvery, setRepeatEvery] = React.useState<number>(1);
    const [repeatEveryType, setRepeatEveryType] = React.useState<string>();
    const [repeatOnDays, setRepeatOnDays] = React.useState<string[]>([]);
    const [endType, setEndType] = React.useState<string>("on");
    const [endOnDate, setEndOnDate] = React.useState<Date>();
    const [endAfterOccurances, setEndAfterOccurances] = React.useState<number>(1);
    const [isValid, setIsValid] = React.useState<boolean>(false);
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [finished, setFinished] = React.useState<boolean>(false);
    //--------------------------------------------------------------

    //Schedules -----------------------------------------------------
    const [schedule, setSchedule] = React.useState<IScheduleRow[]>([]);
    const generateSchedules = () => {
        let schedules: IScheduleRow[] = [];
        if (repeat && endType === "on" && endOnDate && repeatEvery > 0) {
            //Repeat Schedules
            //for example, repeat every 1 week on Monday and Wednesday until endOnDate

            //calculate the next start and end date
            //use date-fns addDays, addWeeks, addMonths, addYears functions

            //add to schedules array

            schedules.push({
                id: "",
                startNow: false,
                start_date: startDate,
                end_date: endDate,
                source_id: sourceId,
                destination_id: destinationId,
                submitting: false,
                success: false,
                isValid: true,
                errorMessage: undefined,
                errors: {}
            });

            if (repeatOnDays.length > 0) {
                console.log("Repeat on days", repeatOnDays);
                repeatOnDays.forEach((day) => {
                    let nextStartDate: Date = startDate;
                    let nextEndDate: Date = endDate;
                    let nextDay: number = 0;
                    switch (day) {
                        case "sunday":
                            nextDay = 0;
                            break;
                        case "monday":
                            nextDay = 1;
                            break;
                        case "tuesday":
                            nextDay = 2;
                            break;
                        case "wednesday":
                            nextDay = 3;
                            break;
                        case "thursday":
                            nextDay = 4;
                            break;
                        case "friday":
                            nextDay = 5;
                            break;
                        case "saturday":
                            nextDay = 6;
                            break;
                    }
                    while (nextStartDate < endOnDate) {
                        nextStartDate = addDays(nextStartDate, 1);
                        nextEndDate = addDays(nextEndDate, 1);
                        if (nextStartDate.getDay() === nextDay) {
                            schedules.push({
                                id: "",
                                startNow: false,
                                start_date: nextStartDate,
                                end_date: nextEndDate,
                                source_id: sourceId,
                                destination_id: destinationId,
                                submitting: false,
                                success: false,
                                isValid: true,
                                errorMessage: undefined,
                                errors: {}
                            });
                        }
                    }
                });
            }
        } else if (repeat && endType === "after" && endAfterOccurances > 0 && repeatEvery > 0) {
            //Repeat Schedules
            //for example, repeat every 2 weeks on Monday, Wednesday and Friday until endAfterOccurances
            //each time calculate the next start and end date
            //use date-fns addDays, addWeeks, addMonths, addYears functions

            schedules.push({
                id: "",
                startNow: false,
                start_date: startDate,
                end_date: endDate,
                source_id: sourceId,
                destination_id: destinationId,
                submitting: false,
                success: false,
                isValid: true,
                errorMessage: undefined,
                errors: {}
            });

            if (repeatOnDays.length > 0) {
                console.log("Repeat on days", repeatOnDays);
                repeatOnDays.forEach((day) => {
                    let nextStartDate: Date = startDate;
                    let nextEndDate: Date = endDate;
                    let nextDay: number = 0;
                    switch (day) {
                        case "sunday":
                            nextDay = 0;
                            break;
                        case "monday":
                            nextDay = 1;
                            break;
                        case "tuesday":
                            nextDay = 2;
                            break;
                        case "wednesday":
                            nextDay = 3;
                            break;
                        case "thursday":
                            nextDay = 4;
                            break;
                        case "friday":
                            nextDay = 5;
                            break;
                        case "saturday":
                            nextDay = 6;
                            break;
                    }
                    let occurances = 0;
                    while (occurances < endAfterOccurances) {
                        nextStartDate = addDays(nextStartDate, 1);
                        nextEndDate = addDays(nextEndDate, 1);
                        if (nextStartDate.getDay() === nextDay) {
                            schedules.push({
                                id: "",
                                startNow: false,
                                start_date: nextStartDate,
                                end_date: nextEndDate,
                                source_id: sourceId,
                                destination_id: destinationId,
                                submitting: false,
                                success: false,
                                isValid: true,
                                errorMessage: undefined,
                                errors: {}
                            });
                            occurances++;
                        }
                    }
                });
            }
        } else {
            schedules.push({
                id: "",
                startNow: false,
                start_date: startDate,
                end_date: endDate,
                source_id: sourceId,
                destination_id: destinationId,
                submitting: false,
                success: false,
                isValid: true,
                errorMessage: undefined,
                errors: {}
            });
        }
        console.log("Schedules", schedules);
        //sort schedules by start_date
        schedules.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));

        setSchedule(schedules);
        setStep(2);
    };

    //Add a function to loop through the scheduleRows and create a schedule for each one
    const createSchedules = async () => {
        setSubmitting(true);
        for (let i = 0; i < schedule.length; i++) {
            const row = schedule[i];
            if (!row.success) {
                //
                setSchedule((prevRows) => {
                    const newRows = [...prevRows];
                    newRows[i].submitting = true;
                    return newRows;
                });
                try {
                    await agent.Scheduler.createSchedule({
                        source_id: row.source_id,
                        destination_id: row.destination_id,
                        start_date: row.startNow ? "NOW" : row.start_date.toISOString(),
                        end_date: row.end_date.toISOString(),
                        start_time: row.startNow ? "NOW" : row.start_date.toISOString(),
                        end_time: row.end_date.toISOString()
                    });
                    setSchedule((prevRows) => {
                        const newRows = [...prevRows];
                        newRows[i].success = true;
                        newRows[i].submitting = false;
                        newRows[i].isValid = true;
                        newRows[i].errorMessage = undefined;
                        newRows[i].errors = undefined;
                        return newRows;
                    });
                } catch (error: any) {
                    console.log(error);
                    setSchedule((prevRows) => {
                        const newRows = [...prevRows];
                        newRows[i].errorMessage = error.data.message;
                        newRows[i].errors = error.data.errors;
                        newRows[i].submitting = false;
                        newRows[i].isValid = false;
                        return newRows;
                    });
                }
                await new Promise((resolve) => setTimeout(resolve, 500));
            }
        }
        setSubmitting(false);
        setFinished(true);
    };
    //---------------------------------------------------------------

    //Fetch Sources and Destinations --------------------------------
    const fetchSources = async (environment_id) => {
        await getInputsDropdownList(environment_id)
            .then((response) => {
                setSources(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const fetchDestinations = async (environment_id) => {
        await agent.Destination.getDestinationsList(environment_id)
            .then((response) => {
                console.log(response.data);
                const destinations = response.data.filter((destination) => destination.is_permanent_schedule === false);
                setDestinations(destinations);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        fetchSources(active_environment);
        fetchDestinations(active_environment);
    }, [active_environment]);
    //--------------------------------------------------------------

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        [`& .${toggleButtonGroupClasses.grouped}`]: {
            margin: theme.spacing(1),
            border: 0,
            borderRadius: "50%",
            width: 30,
            height: 30,
            "&.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "&:hover": {
                    backgroundColor: theme.palette.primary.dark
                }
            },
            [`&.${toggleButtonGroupClasses.disabled}`]: {
                border: 0
            }
        },
        [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
            marginLeft: -1,
            borderLeft: "1px solid transparent"
        }
    }));

    useEffect(() => {
        //Validation
        if (sourceId && destinationId && startDate && endDate && endDate > startDate) {
            if (repeat && endType === "on" && endOnDate && repeatEvery > 0 && repeatOnDays.length > 0) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
            if (repeat && endType === "after" && endAfterOccurances > 0 && repeatOnDays.length > 0) {
                setIsValid(true);
            }
            if (!repeat) {
                setIsValid(true);
            }
        } else {
            setIsValid(false);
        }
    }, [sourceId, destinationId, startDate, endDate, repeat, endType, endOnDate, repeatEvery, repeatOnDays, endAfterOccurances]);

    return (
        <>
            <DialogTitle>Create Schedule</DialogTitle>
            <DialogContent
                sx={{
                    maxHeight: 590,
                    overflowY: "auto"
                }}>
                {step === 1 && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container>
                            <Grid item xs={1} display="flex" alignItems="center">
                                <Videocam />
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    fullWidth
                                    label="Source"
                                    margin="none"
                                    size="small"
                                    required
                                    placeholder="Select Source"
                                    select
                                    value={sourceId}
                                    onChange={(event) => {
                                        setSourceId(event.target.value);
                                    }}>
                                    {sources?.map((source: any) => (
                                        <MenuItem key={source.id} value={source.id}>
                                            {source.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container mb={3}>
                            <Grid item xs={1} display="flex" alignItems="center">
                                <CellTower />
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    fullWidth
                                    label="Destination"
                                    margin="none"
                                    size="small"
                                    required
                                    placeholder="Select Destination"
                                    select
                                    value={destinationId}
                                    onChange={(event) => {
                                        setDestinationId(event.target.value);
                                    }}>
                                    {destinations?.map((destination: any) => (
                                        <MenuItem key={destination.id} value={destination.id}>
                                            {destination.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container mb={3}>
                            <Grid item xs={1} pt={2}>
                                <AccessTime />
                            </Grid>
                            <Grid item xs={11}>
                                <Stack direction="row" spacing={3} justifyContent="flex-start" mb={1}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startDate}
                                        onChange={(value) => {
                                            setStartDate(value as Date);
                                        }}
                                        disablePast
                                        sx={{ width: 200 }}
                                        format="dd/MM/yyyy"
                                    />
                                    <TimePicker
                                        label="Start Time"
                                        views={["hours", "minutes", "seconds"]}
                                        value={startDate}
                                        onChange={(value) => {
                                            setStartDate(value as Date);
                                        }}
                                        sx={{ width: 120 }}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={3} justifyContent="flex-start">
                                    <DatePicker
                                        label="End Date"
                                        value={endDate}
                                        disablePast
                                        minDate={startDate}
                                        onChange={(value) => {
                                            setEndDate(value as Date);
                                        }}
                                        sx={{ width: 200 }}
                                        format="dd/MM/yyyy"
                                    />
                                    <TimePicker
                                        label="End Time"
                                        views={["hours", "minutes", "seconds"]}
                                        value={endDate}
                                        onChange={(value) => {
                                            setEndDate(value as Date);
                                        }}
                                        sx={{ width: 120 }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container mb={2}>
                            <Grid item xs={1} pt={1}>
                                <Repeat />
                            </Grid>
                            <Grid item xs={11}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={repeat}
                                            onChange={(event, value) => {
                                                setRepeat(value);
                                            }}
                                        />
                                    }
                                    label="Repeat"
                                />
                                {repeat && (
                                    <>
                                        {/* <Stack direction="row" spacing={2} mt={1} mb={2}>
                                        <Typography
                                            sx={{
                                                whiteSpace: "nowrap"
                                            }}>
                                            Repeat every
                                        </Typography>
                                        <TextField
                                            margin="none"
                                            size="small"
                                            required
                                            autoFocus
                                            type="number"
                                            defaultValue={1}
                                            value={repeatEvery}
                                            onChange={(event) => {
                                                setRepeatEvery(parseInt(event.target.value));
                                            }}
                                            sx={{
                                                width: "50px"
                                            }}
                                        />
                                        <TextField
                                            margin="none"
                                            size="small"
                                            required
                                            autoFocus
                                            select
                                            defaultValue="week"
                                            value={repeatEveryType}
                                            onChange={(event) => {
                                                setRepeatEveryType(event.target.value);
                                            }}
                                            sx={{
                                                width: "100px"
                                            }}>
                                            <MenuItem value="week">week(s)</MenuItem>
                                            <MenuItem value="month">month(s)</MenuItem>
                                            <MenuItem value="year">year(s)</MenuItem>
                                        </TextField>
                                    </Stack> */}
                                        <Box mb={2} mt={2}>
                                            <Typography mb={0.5}>Repeat on</Typography>
                                            <StyledToggleButtonGroup
                                                value={repeatOnDays}
                                                onChange={(event, value) => {
                                                    setRepeatOnDays(value);
                                                    console.log(value);
                                                }}
                                                aria-label="text formatting">
                                                <ToggleButton value="sunday" aria-label="bold">
                                                    S
                                                </ToggleButton>
                                                <ToggleButton value="monday" aria-label="italic">
                                                    M
                                                </ToggleButton>
                                                <ToggleButton value="tuesday" aria-label="underlined">
                                                    T
                                                </ToggleButton>
                                                <ToggleButton value="wednesday" aria-label="color">
                                                    W
                                                </ToggleButton>
                                                <ToggleButton value="thursday" aria-label="color">
                                                    T
                                                </ToggleButton>
                                                <ToggleButton value="friday" aria-label="color">
                                                    F
                                                </ToggleButton>
                                                <ToggleButton value="saturday" aria-label="color">
                                                    S
                                                </ToggleButton>
                                            </StyledToggleButtonGroup>
                                        </Box>
                                        <FormControl>
                                            <Typography>Ends</Typography>
                                            <RadioGroup
                                                defaultValue="on"
                                                name="ends-type"
                                                value={endType}
                                                onChange={(event, value) => {
                                                    console.log(value);
                                                    setEndType(value);
                                                }}>
                                                <Stack direction="row" alignItems="center" justifyContent="flex-start">
                                                    <FormControlLabel value="on" control={<Radio />} label="On" sx={{ width: 100 }} />
                                                    <DatePicker
                                                        sx={{ width: 200 }}
                                                        label="End Date"
                                                        value={endOnDate}
                                                        onChange={(value) => {
                                                            setEndOnDate(value as Date);
                                                        }}
                                                        format="dd/MM/yyyy"
                                                        disabled={endType !== "on"}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center">
                                                    <FormControlLabel value="after" control={<Radio />} label="After" sx={{ width: 100 }} />
                                                    <FormControl variant="standard" sx={{ width: 120 }}>
                                                        <Input
                                                            id="end-occurances"
                                                            name="end_occurances"
                                                            type="number"
                                                            endAdornment={<InputAdornment position="end">occurances</InputAdornment>}
                                                            disabled={endType !== "after"}
                                                            value={endAfterOccurances}
                                                            onChange={(event) => {
                                                                if (parseInt(event.target.value) > 0) {
                                                                    setEndAfterOccurances(parseInt(event.target.value));
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                )}
                {step === 2 && (
                    <Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                Source:{" "}
                                {
                                    //find the source name from the sources array
                                    sources?.find((source) => source.id === sourceId)?.name
                                }
                            </Typography>
                            <Typography variant="body1">
                                Destination:{" "}
                                {
                                    //find the destination name from the destinations array
                                    destinations?.find((destination) => destination.id === destinationId)?.display_name
                                }
                            </Typography>
                        </Box>
                        {schedule &&
                            schedule.length > 0 &&
                            schedule.map((item) => (
                                <Box
                                    key={item.id}
                                    sx={{
                                        mb: 1,
                                        backgroundColor: (theme) => theme.palette.action.hover,
                                        borderRadius: 1,
                                        p: 1,
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mr: 2
                                        }}>
                                        {!item.submitting && !item.errorMessage && !item.success && <CalendarToday />}
                                        {item.submitting && <CircularProgress size={20} color="primary" />}
                                        {item.errorMessage && <Error color="error" />}
                                        {item.success && <CheckCircle color="success" />}
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography>Start: {format(item.start_date, "dd/MM/yyyy HH:mm")}</Typography>
                                        <Typography>End: {format(item.end_date, "dd/MM/yyyy HH:mm")}</Typography>
                                        {item.errorMessage && (
                                            <Alert sx={{ mt: 1 }} severity="error">
                                                <AlertTitle>{item.errorMessage}</AlertTitle>
                                                {item.errors && item.errors.start_time && (
                                                    <Typography variant="body2">Start Time: {item.errors.start_time}</Typography>
                                                )}
                                                {item.errors && item.errors.end_time && (
                                                    <Typography variant="body2">End Time: {item.errors.end_time}</Typography>
                                                )}
                                            </Alert>
                                        )}
                                    </Box>
                                    {!item.success && (
                                        <IconButton
                                            sx={{ ml: 4 }}
                                            onClick={() => {
                                                setSchedule((prevRows) => {
                                                    const newRows = [...prevRows];
                                                    newRows.splice(newRows.indexOf(item), 1);
                                                    return newRows;
                                                });
                                            }}>
                                            <Delete />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {step === 1 && (
                    <>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button variant="contained" color="primary" disabled={!isValid} onClick={generateSchedules}>
                            Next
                        </Button>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button
                            onClick={() => {
                                setStep(1);
                                setFinished(false);
                            }}>
                            Back
                        </Button>
                        {!finished && (
                            <LoadingButton variant="contained" color="primary" onClick={createSchedules} loading={submitting}>
                                Create Schedules
                            </LoadingButton>
                        )}
                        {finished && (
                            <Button variant="contained" onClick={closeDialog}>
                                Finished
                            </Button>
                        )}
                    </>
                )}
            </DialogActions>
        </>
    );
};

export default CreateRepeatScheduleForm;
