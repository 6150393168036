import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    IconButton,
    Tooltip,
    CardActionArea,
    Skeleton,
    CardHeader,
    Avatar,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Icon
} from "@mui/material";
import { format, isAfter, startOfDay, isBefore, isEqual, endOfDay } from "date-fns";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import SourceThumbnail from "features/sources/Components/SourceThumbnail";
import SourceDetails from "features/sources/Components/SourceDetails";
import { RootStoreContext } from "api";
import { ResCollection, ResModel } from "resclient";
import EditScheduleForm from "./EditScheduleForm";
import WarningIcon from "@mui/icons-material/Warning";
import useSource from "features/sources/useSource";
import { useScheduler } from "hooks";

type ScheduleItemProps = {
    schedule_id: string;
    destination_id: string;
    startDate: Date;
    endDate: Date;
    startMinute: number;
    endMinute: number;
    previousEndMinute?: number;
    nextStartMinute?: number;
    previousEndDate?: Date;
    nextStartDate?: Date;
    zoom: number;
    live?: boolean;
    finished?: boolean;
    name: string;
    source_id: string;
    wont_start?: boolean;
};

export const ScheduleItem: React.FC<ScheduleItemProps> = observer(function ScheduleItem(props) {
    const theme = useTheme();

    const rootStore = React.useContext(RootStoreContext);

    const { date } = rootStore.schedulerStore;
    const { deleteSchedule } = useScheduler();
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;

    const [startMinute, setStartMinute] = useState(props.startMinute);
    const [endMinute, setEndMinute] = useState(props.endMinute);
    const [, setWidth] = useState((props.endMinute - props.startMinute) * props.zoom);

    const today = new Date();
    const minutesOfDay = today.getHours() * 60 + today.getMinutes();
    const [currentMinute, setCurrentMinute] = React.useState<number>(Number(minutesOfDay));

    const [dirty, setDirty] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [sourceWsData, setSourceWsData] = React.useState<ResModel | ResCollection | any>({});
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const [, setNewSource] = React.useState<string>(props.source_id);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        //setAnchorEl(anchorEl ? null : event.currentTarget);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isToday = () => {
        const startDate = startOfDay(new Date(date));
        const today = startOfDay(new Date());

        return isEqual(startDate, today);
    };

    const endsFutureDay = () => {
        const endDateTime = new Date(props.endDate);
        const endDate = startOfDay(endDateTime);
        const today = endOfDay(date);

        return isAfter(endDate, today);
    };

    const startsPastDay = () => {
        const startDateTime = new Date(props.startDate);
        const startDate = startOfDay(startDateTime);
        const today = startOfDay(date);

        return isBefore(startDate, today);
    };

    const endTimeInPast = () => {
        const endDateTime = new Date(props.endDate);
        const today = new Date();
        return endDateTime < today;
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const onResize = (event, { size }) => {
        event.stopPropagation();
        setWidth(size.width);
        if (startsPastDay()) {
            setEndMinute(size.width / props.zoom - 50 / props.zoom);
        } else {
            setEndMinute(startMinute + size.width / props.zoom);
        }

        setDirty(true);
    };

    const calculateTime = (minute: number) => {
        const date = new Date();
        date.setHours(Math.floor(minute / 60), minute % 60);

        return format(date, "HH:mm");
    };

    const calculateWidth = () => {
        if (startsPastDay() && endsFutureDay()) {
            return 1440 * props.zoom + 100;
        } else if (endsFutureDay() && !startsPastDay()) {
            return 1440 * props.zoom - startMinute * props.zoom + 50;
        } else if (startsPastDay() && !endsFutureDay()) {
            return endMinute * props.zoom + 50;
        } else {
            return (endMinute - startMinute) * props.zoom;
        }
    };

    const calculateLeft = () => {
        if (startsPastDay() && endsFutureDay()) {
            return -50;
        } else if (endsFutureDay() && !startsPastDay()) {
            return startMinute * props.zoom;
        } else if (startsPastDay() && !endsFutureDay()) {
            return -50;
        } else {
            return startMinute * props.zoom;
        }
    };

    const calculateBoundsLeft = () => {
        if (props.previousEndMinute && props.previousEndMinute !== null) {
            if (isToday() && props.previousEndMinute * props.zoom < currentMinute * props.zoom) {
                return currentMinute * props.zoom;
            } else {
                return props.previousEndMinute * props.zoom;
            }
        } else if (isToday()) {
            return currentMinute * props.zoom;
        } else {
            return 0;
        }
    };

    const calculateBoundsRight = () => {
        if (props.nextStartMinute && props.nextStartMinute !== null) {
            return props.nextStartMinute * props.zoom - calculateWidth();
        } else {
            return 1440 * props.zoom - (endMinute - startMinute) * props.zoom + 50;
        }
    };

    const calculateMaxWidth = () => {
        if (props.nextStartMinute && props.nextStartMinute !== null && !startsPastDay()) {
            return (props.nextStartMinute - endMinute + (endMinute - startMinute)) * props.zoom;
        } else if (props.nextStartMinute && props.nextStartMinute !== null && startsPastDay()) {
            return props.nextStartMinute * props.zoom + 50;
        } else {
            return 1440 * props.zoom;
        }
    };

    const [maxWidth, setMaxWidth] = useState(calculateMaxWidth());

    const handleUpdateSchedule = () => {
        setDirty(false);
        setAnchorEl(null);
        setUpdateDate(new Date());
    };

    React.useEffect(() => {
        setWidth((props.endMinute - props.startMinute) * props.zoom);
    }, [props.endMinute, props.startMinute, props.zoom, currentMinute]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            const today = new Date();
            const minutesOfDay = today.getHours() * 60 + today.getMinutes();
            setCurrentMinute(Number(minutesOfDay));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        setStartMinute(props.startMinute);
        setEndMinute(props.endMinute);
        setDirty(false);
    }, [props.startMinute, props.endMinute]);

    React.useEffect(() => {
        const rid = `environments.sources.${rootStore.userStore.managingOrganisationId}.${rootStore.userStore.activeEnvironment}.${rootStore.userStore.activeorganisationId}.source.${props.source_id}`;

        rootStore.resClientStore.clientConnected
            ? rootStore.resClientStore.client.get(rid).then((response) => {
                  response.on("change", () => {
                      setSourceWsData(response);
                      setUpdateDate(new Date());
                  });
                  setSourceWsData(response);
              })
            : null;

        return () => {
            //setSourceWsData(null);
        };
    }, [rootStore.resClientStore.clientConnected, props.source_id]);

    React.useEffect(() => {}, [updateDate]);

    const { getTypeText, getStatusText, getStatusColor, getStatusIcon } = useSource(sourceWsData);

    return (
        <Draggable
            axis="x"
            grid={[props.zoom, props.zoom]}
            bounds={{ left: calculateBoundsLeft(), right: calculateBoundsRight() }}
            onDrag={(e, data) => {
                setStartMinute(startMinute + data.deltaX / props.zoom);
                if (!endsFutureDay()) {
                    setEndMinute(endMinute + data.deltaX / props.zoom);
                }
                setDirty(true);
            }}
            onStop={() => {
                if (dirty) {
                    calculateBoundsLeft();
                    calculateBoundsRight();
                    setMaxWidth(calculateMaxWidth());
                }
            }}
            handle=".mainbody"
            scale={1}
            disabled={props.live || props.finished}
            position={{ x: calculateLeft(), y: 0 }}
            positionOffset={{ x: "350px", y: "0px" }}>
            <Resizable
                axis="x"
                width={calculateWidth()}
                height={180}
                draggableOpts={{ grid: [props.zoom, props.zoom], scale: 1, bounds: "parent" }}
                maxConstraints={[maxWidth, 180]}
                handle={(handleAxis, ref) => (
                    <Box
                        id="resize-handle"
                        onClick={handleClick}
                        ref={ref}
                        sx={{
                            display: !endsFutureDay() && !props.finished && !endTimeInPast() ? "flex" : "none",
                            justifyContent: "center",
                            alignItems: "center",
                            borderLeft: "1px solid rgba(160,160,160,0.1)",
                            cursor: "col-resize",
                            maxWidth: "10px",
                            width: "10px",
                            flexShrink: 0,
                            flexGrow: 0,
                            flexBasis: "10px",
                            height: "100%",
                            position: "absolute",
                            right: 0
                        }}>
                        <MoreVertIcon fontSize="small" sx={{ opacity: 0.8 }} />
                    </Box>
                )}
                onResize={onResize}>
                <Card
                    sx={{
                        position: "absolute",
                        width: calculateWidth() + "px",
                        height: "calc(100% - 20px)",
                        margin: "10px 0",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                        border: "1px solid rgba(160,160,160,0.1)",
                        borderTopRightRadius: endsFutureDay() ? "0px" : "4px",
                        borderBottomRightRadius: endsFutureDay() ? "0px" : "4px",
                        borderTopLeftRadius: startsPastDay() ? "0px" : "4px",
                        borderBottomLeftRadius: startsPastDay() ? "0px" : "4px",
                        backgroundColor:
                            dirty && startMinute < currentMinute && !props.finished && !props.live && isToday()
                                ? theme.palette.error.main
                                : dirty
                                ? theme.palette.warning.main
                                : props.live
                                ? theme.palette.success.main
                                : props.wont_start && !endTimeInPast()
                                ? "#9e2fb2"
                                : props.finished || endTimeInPast()
                                ? undefined
                                : theme.palette.primary.main,
                        elevation: 0
                    }}>
                    {!props.live && !props.finished && !startsPastDay() && !endTimeInPast() && (
                        <Box
                            id="drag-handle"
                            className="mainbody"
                            onClick={handleClick}
                            //onMouseLeave={dirty && !anchorEl ? handleClick : undefined}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRight: "1px solid rgba(160,160,160,0.1)",
                                cursor: "move",
                                maxWidth: "14px",
                                width: "14px",
                                flexShrink: 0,
                                flexGrow: 0,
                                flexBasis: "14px",
                                height: "100%"
                            }}>
                            <DragIndicatorIcon fontSize="small" sx={{ opacity: 0.8 }} />
                        </Box>
                    )}
                    <CardActionArea>
                        <CardContent
                            sx={{ flexGrow: 1, flexShrink: 1, padding: 1, height: "100%", marginRight: "5px" }}
                            onClick={handleClick}>
                            {calculateWidth() > 50 && (
                                <>
                                    <Typography
                                        component={Box}
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginRight: "15px" }}>
                                        {calculateTime(startMinute)} - {calculateTime(endMinute)}
                                    </Typography>
                                    <Typography
                                        component={Box}
                                        sx={{
                                            fontWeight: 600,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            marginRight: "15px"
                                        }}>
                                        {sourceWsData && (
                                            <>
                                                <span
                                                    title={sourceWsData.connection_status}
                                                    style={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: "50%",
                                                        border: "1px solid #fff",
                                                        // backgroundColor:
                                                        //     sourceWsData.connection_status === "connected" ||
                                                        //     sourceWsData.connection_status === "no-thumb-avail"
                                                        //         ? theme.palette.success.light
                                                        //         : getStatusColor(),
                                                        backgroundColor: getStatusColor(),
                                                        display: "inline-block"
                                                    }}></span>
                                                &nbsp;
                                            </>
                                        )}
                                        {props.name}
                                    </Typography>
                                    {props.finished && !props.wont_start && (
                                        <SportsScoreIcon fontSize="small" sx={{ marginTop: 1, opacity: 0.8 }} />
                                    )}
                                    {props.live && !props.wont_start && (
                                        <PlayArrowIcon fontSize="small" sx={{ marginTop: 1, opacity: 0.8 }} />
                                    )}
                                    {dirty && <EditIcon fontSize="small" sx={{ marginTop: 1 }} />}
                                    {!dirty && !props.live && !props.finished && !props.wont_start && (
                                        <EventIcon fontSize="small" sx={{ marginTop: 1, opacity: 0.8 }} />
                                    )}
                                    {props.wont_start && (
                                        <WarningIcon className="pulse" fontSize="small" sx={{ marginTop: 1, opacity: 0.8 }} />
                                    )}
                                </>
                            )}
                        </CardContent>
                    </CardActionArea>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        sx={{ marginTop: "5px", marginLeft: "5px" }}>
                        <Box sx={{ width: 300 }}>
                            {!dirty && (
                                <CardHeader
                                    title={
                                        sourceWsData.connection_status ? (
                                            <span
                                                style={{
                                                    display: "block",
                                                    width: "100%",
                                                    textTransform: "capitalize",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}>
                                                {getStatusText()}
                                            </span>
                                        ) : (
                                            <Skeleton>
                                                <span>Connection Status</span>
                                            </Skeleton>
                                        )
                                    }
                                    subheader={
                                        sourceWsData.type ? (
                                            <span style={{ textTransform: "capitalize" }}>{getTypeText()}</span>
                                        ) : (
                                            <Skeleton>
                                                <span>Source Type</span>
                                            </Skeleton>
                                        )
                                    }
                                    avatar={
                                        <Avatar
                                            sx={{
                                                bgcolor: getStatusColor(),
                                                color: "#fff"
                                            }}
                                            //title={props.source.is_transcoded_input ? "Transcoded Source" : "Source"}
                                            title="Source"
                                            aria-label="recipe">
                                            <Icon component={getStatusIcon()} />
                                        </Avatar>
                                    }
                                />
                            )}
                            {!dirty && (
                                <CardActionArea
                                    sx={{
                                        backgroundColor: sourceWsData.transcoder_id
                                            ? theme.palette.primary.dark
                                            : sourceWsData.multiviewer_id
                                            ? theme.palette.secondary.dark
                                            : undefined
                                    }}
                                    onClick={() => {
                                        handleClose();
                                        openDrawer(
                                            <Box sx={{ width: 680, height: "100%", padding: 3 }}>
                                                <SourceDetails item_id={props.source_id} />
                                            </Box>
                                        );
                                    }}>
                                    {!dirty && (
                                        <CardContent sx={{ padding: 0 }}>
                                            {sourceWsData.connection_status === "connected" ? (
                                                <SourceThumbnail item_id={props.source_id} enableFullScreen />
                                            ) : (
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation={false}
                                                    width="100%"
                                                    sx={{ paddingBottom: "56.25%" }}
                                                />
                                            )}
                                        </CardContent>
                                    )}
                                    <CardContent sx={{ paddingY: sourceWsData.transcoder_input_name ? "11.5px" : undefined }}>
                                        {sourceWsData.name ? (
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                sx={{ width: "100%", marginBottom: sourceWsData.transcoder_input_name ? -1 : undefined }}>
                                                {sourceWsData.name}
                                            </Typography>
                                        ) : (
                                            <Skeleton>
                                                <Typography variant="h6" component="div">
                                                    Card Title Source Name
                                                </Typography>
                                            </Skeleton>
                                        )}
                                        {sourceWsData.transcoder_input_name ? (
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                component="div"
                                                sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                Source: {sourceWsData.transcoder_input_name}
                                            </Typography>
                                        ) : null}

                                        <Typography color="text.secondary" component="div" sx={{ display: "flex", alignItems: "center" }}>
                                            <PlayArrowIcon fontSize="small" />
                                            &nbsp;{format(new Date(props.startDate), "dd/MM/yyyy @ HH:mm:ss")}
                                        </Typography>
                                        <Typography color="text.secondary" component="div" sx={{ display: "flex", alignItems: "center" }}>
                                            <SportsScoreIcon fontSize="small" />
                                            &nbsp;{format(new Date(props.endDate), "dd/MM/yyyy @ HH:mm:ss")}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            )}
                            {dirty && (
                                <CardContent>
                                    <EditScheduleForm
                                        source_id={props.source_id}
                                        destination_id={props.destination_id}
                                        schedule_id={props.schedule_id}
                                        startDate={props.startDate}
                                        endDate={props.endDate}
                                        startMinute={startMinute}
                                        endMinute={endMinute}
                                        previousEndDate={props.previousEndDate}
                                        nextStartDate={props.nextStartDate}
                                        disable_start_editing={props.live}
                                        zoom={props.zoom}
                                        onReset={() => {
                                            setDirty(false);
                                            setStartMinute(props.startMinute);
                                            setEndMinute(props.endMinute);
                                            setAnchorEl(null);
                                            setNewSource(props.source_id);
                                        }}
                                        onSave={handleUpdateSchedule}
                                        onSourceChange={(source_id) => setNewSource(source_id)}
                                        onStartEdit={(startMinute) => {
                                            setStartMinute(startMinute);
                                        }}
                                        onEndEdit={(endMinute) => {
                                            setEndMinute(endMinute);
                                        }}
                                    />
                                </CardContent>
                            )}
                            {!dirty && (
                                <CardActions>
                                    {/* <Tooltip title="Schedule Source" placement="bottom">
                                        <IconButton aria-label="schedule" onClick={() => setAnchorEl(null)}>
                                            <EventIcon />
                                        </IconButton>
                                    </Tooltip> */}
                                    {props.live && (
                                        <Tooltip title="End Schedule Now" placement="bottom">
                                            <IconButton
                                                aria-label="edit"
                                                onClick={() => {
                                                    openDialog(
                                                        <>
                                                            <DialogTitle>End Schedule?</DialogTitle>
                                                            <DialogContent>
                                                                Are you sure you want to end the schedule now? This action can not be
                                                                undone.
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={() => closeDialog()}>Cancel</Button>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        closeDialog();
                                                                        deleteSchedule(props.schedule_id);
                                                                    }}
                                                                    autoFocus>
                                                                    End Schedule
                                                                </Button>
                                                            </DialogActions>
                                                        </>
                                                    );
                                                    setAnchorEl(null);
                                                }}>
                                                <EventBusyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {!props.finished && (
                                        <Tooltip title="Edit Schedule" placement="bottom">
                                            <IconButton aria-label="edit" onClick={() => setDirty(true)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {!props.live && !props.finished && (
                                        <Tooltip title="Delete Schedule Item" placement="bottom">
                                            <IconButton
                                                aria-label="share"
                                                onClick={() => {
                                                    setAnchorEl(null);
                                                    deleteSchedule(props.schedule_id);
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Source Information" placement="bottom">
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => {
                                                setAnchorEl(null);
                                                openDrawer(
                                                    <Box sx={{ padding: 2, width: 680, maxWidth: 680, height: "100%" }}>
                                                        <SourceDetails item_id={props.source_id} />
                                                    </Box>
                                                );
                                            }}>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </CardActions>
                            )}
                        </Box>
                    </Popover>
                </Card>
            </Resizable>
        </Draggable>
    );
});
