import React from "react";
import { Form, Field } from "react-final-form";

// Enum and interface imported from your type definitions
import { LoudnessControlMode, ILoudnessControl } from "api"; // update with your actual file path
import { Box, Checkbox, FormControlLabel, MenuItem, Slider, TextField } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { CheckboxField, SelectField, SliderField } from "components/Forms";

interface Props {
    values: any;
    submitError: any;
    audioTrackIndex: number;
    onFieldChange: (field: string, value: any) => void;
}

// Component for the loudness control form
const LoudnessControlForm: React.FC<Props> = ({ values, submitError, audioTrackIndex, onFieldChange }) => {
    // Form submission handler
    const onSubmit = (values: ILoudnessControl) => {
        console.log(values);
        // Handle form submission, e.g., send data to the server
    };

    // Validator function for required fields
    const required = (value: any) => (value ? undefined : "Required");

    // Validator function for number range
    const inRange = (min: number, max: number) => (value: number) =>
        isNaN(value) || value < min || value > max ? `Must be between ${min} and ${max}` : undefined;

    React.useEffect(() => {}, [values, submitError]);

    return (
        <Box>
            {/* "individual_track_configuration" -> "audio_tracks" -> "loudness_control":
"enable": Boolean.
"loudness_leveler_mode": String. Options: "Enable", "Disable", "Auto"
"loudness_regulation_type": String. Options: "EBU_R 128", "ATSC_A85", "ARIB", "FREETV", "CUSTOM". ! Disabled, if loudness_leveler_mode == "Disable"
"target_level_db": Integer in range of -31 to -10. Enabled / active only if the "loudness_regulation_type" == "CUSTOM".
"dialogue_intelligence": Boolean. Enabled / active only if the "loudness_regulation_type" == "CUSTOM".
"peak_limit_dbtp": Float/Decimal in range of -8 to -0.1. Enabled / active only if the "loudness_regulation_type" == "CUSTOM". */}

            <Box sx={{ mb: 2 }}>
                <CheckboxField
                    name={`individual_track_configuration.audio_tracks.${audioTrackIndex}.loudness_control.enable`}
                    label="Enable Loudness Control"
                />

                {values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby AC-4" && (
                    <CheckboxField
                        name={`individual_track_configuration.audio_tracks.${audioTrackIndex}.enable_dolby_atmos`}
                        label="Enable Dolby Atmos"
                    />
                )}
            </Box>

            {values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.loudness_control?.enable && (
                <>
                    <SelectField
                        name={`individual_track_configuration.audio_tracks.${audioTrackIndex}.loudness_control.loudness_leveler_mode`}
                        options={[
                            { key: "enable", text: "Enable", value: "Enable" },
                            { key: "disable", text: "Disable", value: "Disable" },
                            { key: "auto", text: "Auto", value: "Auto" }
                        ]}
                        label="Loudness Leveler Mode"
                        required
                        error={(meta: any) =>
                            !meta.touched &&
                            submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.loudness_control
                                ?.loudness_leveler_mode
                                ? true
                                : false
                        }
                        helperText={(meta: any) =>
                            !meta.touched &&
                            submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.loudness_control
                                ?.loudness_leveler_mode
                                ? submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                      ?.loudness_control?.loudness_leveler_mode
                                : null
                        }
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    {values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_leveler_mode &&
                        values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_leveler_mode !==
                            "Disable" && (
                            <SelectField
                                name={`individual_track_configuration.audio_tracks.${audioTrackIndex}.loudness_control.loudness_regulation_type`}
                                options={[
                                    { key: "EBU_R 128", text: "EBU_R 128", value: "EBU_R 128" },
                                    { key: "ATSC_A85", text: "ATSC_A85", value: "ATSC_A85" },
                                    { key: "ARIB", text: "ARIB", value: "ARIB" },
                                    { key: "FREETV", text: "FREETV", value: "FREETV" },
                                    { key: "CUSTOM", text: "CUSTOM", value: "CUSTOM" }
                                ]}
                                label="Loudness Regulation Type"
                                required
                                error={(meta: any) =>
                                    !meta.touched &&
                                    submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                        ?.loudness_control?.loudness_regulation_type
                                        ? true
                                        : false
                                }
                                helperText={(meta: any) =>
                                    !meta.touched &&
                                    submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                        ?.loudness_control?.loudness_regulation_type
                                        ? submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                              ?.loudness_control?.loudness_regulation_type
                                        : null
                                }
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                            />
                        )}
                    {values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_regulation_type ===
                        "CUSTOM" &&
                        values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_leveler_mode !==
                            "Disable" && (
                            <SliderField
                                name={`individual_track_configuration.audio_tracks.${audioTrackIndex}.loudness_control.target_level_db`}
                                label="Target Level"
                                min={-31}
                                max={-10}
                                step={1}
                                initialValue={
                                    values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.target_level_db
                                        ? values.individual_track_configuration.audio_tracks[audioTrackIndex].loudness_control
                                              .target_level_db
                                        : -23
                                }
                                onChange={(field: string, value: any) => {
                                    if (
                                        submitError?.data?.errors?.individual_track_configuration?.audio_tracks[audioTrackIndex]
                                            ?.loudness_control?.target_level_db
                                    ) {
                                        onFieldChange(field, value);
                                    }
                                }}
                                validate={inRange(-31, -10)}
                                error={(meta: any) => {
                                    return (meta.touched && meta.error) ||
                                        submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                            ?.loudness_control?.target_level_db
                                        ? true
                                        : false;
                                }}
                                helperText={(meta: any) => {
                                    if (meta.error && meta.touched) {
                                        return meta.error;
                                    }
                                    if (
                                        submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                            ?.loudness_control?.target_level_db
                                    ) {
                                        return submitError?.data?.errors?.individual_track_configuration?.audio_tracks[audioTrackIndex]
                                            ?.loudness_control?.target_level_db;
                                    }
                                }}
                            />
                        )}
                    {values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_regulation_type ===
                        "CUSTOM" &&
                        values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_leveler_mode !==
                            "Disable" && (
                            <div>
                                <Field
                                    name={`individual_track_configuration.audio_tracks.${audioTrackIndex}.loudness_control.dialogue_intelligence`}
                                    initialValue={
                                        values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control
                                            ?.dialogue_intelligence
                                            ? true
                                            : false
                                    }
                                    component="input"
                                    type="checkbox">
                                    {({ input, meta }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...input} />}
                                            label="Enable Dialogue Intelligence"
                                            sx={{ mb: 1 }}
                                        />
                                    )}
                                </Field>
                            </div>
                        )}
                    {values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_regulation_type ===
                        "CUSTOM" &&
                        values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.loudness_control?.loudness_leveler_mode !==
                            "Disable" && (
                            <SliderField
                                name={`individual_track_configuration.audio_tracks.${audioTrackIndex}.loudness_control.peak_limit_dbtp`}
                                label="Peak Limit"
                                min={-8}
                                max={-0.1}
                                step={0.1}
                                initialValue={
                                    values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.loudness_control
                                        ?.peak_limit_dbtp
                                        ? values.individual_track_configuration.audio_tracks[audioTrackIndex].loudness_control
                                              .peak_limit_dbtp
                                        : -1
                                }
                                onChange={(field: string, value: any) => {
                                    if (
                                        submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                            ?.loudness_control?.peak_limit_dbtp
                                    ) {
                                        onFieldChange(field, value);
                                    }
                                }}
                                validate={inRange(-8, -0.1)}
                                error={(meta: any) => {
                                    return (meta.touched && meta.error) ||
                                        submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                            ?.loudness_control?.peak_limit_dbtp
                                        ? true
                                        : false;
                                }}
                                helperText={(meta: any) => {
                                    if (meta.error && meta.touched) {
                                        return meta.error;
                                    }
                                    if (
                                        submitError?.data?.errors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]
                                            ?.loudness_control?.peak_limit_dbtp
                                    ) {
                                        return submitError?.data?.errors?.individual_track_configuration?.audio_tracks[audioTrackIndex]
                                            ?.loudness_control?.peak_limit_dbtp;
                                    }
                                }}
                            />
                        )}
                </>
            )}

            {values.loudness_control?.loudness_normalization_leveler && (
                <>
                    <Field name="loudness_control.mode" component="select" validate={required}>
                        {({ input, meta }) => (
                            <TextField {...input} variant="standard" name="loudness_control.mode" label="Mode" select>
                                {Object.values(LoudnessControlMode).map((mode) => (
                                    <MenuItem key={mode} value={mode}>
                                        {mode}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    </Field>
                    <OnChange name="loudness_control.loudness_normalization_leveler">
                        {() => {
                            if (submitError?.data?.errors?.loudness_control?.loudness_normalization_leveler) {
                                onFieldChange("loudness_control.loudness_normalization_leveler", undefined);
                            }
                        }}
                    </OnChange>

                    {/* Target → Operator input must be between -10 and -40. If EBU_R_18 is selected, default value is -23. If ATSC_A85 is selected, default value is -24. */}
                    <SliderField
                        name="loudness_control.target"
                        label="Target"
                        min={-40}
                        max={-10}
                        step={1}
                        initialValue={
                            values.loudness_control?.target
                                ? values.loudness_control.target
                                : values.loudness_control?.mode === LoudnessControlMode.EBU_R_128
                                ? -23
                                : -24
                        }
                        onChange={(field: string, value: any) => {
                            if (submitError?.data?.errors?.loudness_control?.target) {
                                onFieldChange(field, value);
                            }
                        }}
                        validate={inRange(-40, -10)}
                        error={(meta: any) => {
                            return (meta.touched && meta.error) || submitError?.data?.errors?.loudness_control?.target ? true : false;
                        }}
                        helperText={(meta: any) => {
                            if (meta.error && meta.touched) {
                                return meta.error;
                            }
                            if (submitError?.data?.errors?.loudness_control?.target) {
                                return submitError?.data?.errors?.loudness_control?.target;
                            }
                        }}
                    />

                    <div>
                        <Field
                            name="loudness_control.true_peak_limiter"
                            initialValue={values.loudness_control?.true_peak_limiter ? true : false}
                            component="input"
                            type="checkbox">
                            {({ input, meta }) => (
                                <FormControlLabel control={<Checkbox {...input} />} label="Enable True Peak Limiter" sx={{ mb: 1 }} />
                            )}
                        </Field>
                        <OnChange name="loudness_control.true_peak_limiter">
                            {() => {
                                if (submitError?.data?.errors?.loudness_control?.true_peak_limiter) {
                                    onFieldChange("loudness_control.true_peak_limiter", undefined);
                                }
                            }}
                        </OnChange>
                    </div>
                    {/* If True Peak Limiter is selected by the operator, the filed “Maximum Permitted Level (dBTP)” 
                    should be displayed → Operator input must be between -20 and 0. Default is -1 for EBU_R_128 and -2 for ATSC_A85 */}
                    {values.loudness_control?.true_peak_limiter && (
                        <div>
                            <SliderField
                                name="loudness_control.maximum_permitted_level"
                                label="Maximum Permitted Level"
                                min={-8}
                                max={-0.1}
                                step={0.1}
                                initialValue={
                                    values.loudness_control?.maximum_permitted_level
                                        ? values.loudness_control.maximum_permitted_level
                                        : values.loudness_control?.mode === LoudnessControlMode.EBU_R_128
                                        ? -1
                                        : -2
                                }
                                onChange={(field: string, value: any) => {
                                    if (submitError?.data?.errors?.loudness_control?.maximum_permitted_level) {
                                        onFieldChange(field, value);
                                    }
                                }}
                                validate={inRange(-20, 0)}
                                error={(meta: any) => {
                                    return (meta.touched && meta.error) ||
                                        submitError?.data?.errors?.loudness_control?.maximum_permitted_level
                                        ? true
                                        : false;
                                }}
                                helperText={(meta: any) => {
                                    if (meta.error && meta.touched) {
                                        return meta.error;
                                    }
                                    if (submitError?.data?.errors?.loudness_control?.maximum_permitted_level) {
                                        return submitError?.data?.errors?.loudness_control?.maximum_permitted_level;
                                    }
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </Box>
    );
};

export default LoudnessControlForm;
