export const videoCodec = ["HEVC", "MPEG-4"].map((value) => ({
    key: value,
    text: value,
    value
}));

// For Progressive:
export const videoFrameSize = ["720x480", "720x576", "1280x720", "1920x1080", "3840x2160"].map((value) => ({
    key: value,
    text: value,
    value
}));

// For Progressive:
export const videoFrameSmallSize = ["720x480", "720x576", "1280x720", "1920x1080"].map((value) => ({
    key: value,
    text: value,
    value
}));

// For Interlaced
export const videoFrameIntSize = ["720x480", "720x576", "854x480", "1920x1080"].map((value) => ({
    key: value,
    text: value,
    value
}));

// For Motion Interpolated Frame Rate Conversion:
export const videoFrameSizeMIFRC = ["720x480", "720x576", "1280x720", "1920x1080"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const videoFrameRate = ["23.98", "24", "25", "29.97", "30", "50", "59.94", "60"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const videoIntFrameRate = ["25", "29.97"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const mpeg4AvcEncoderProfileValues = ["Baseline", "Main", "High", "High 4:2:2 10"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const videoFrameType = ["Progressive", "Interlaced"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const audioCodec = ["MPEG AAC", "MPEG Layer2"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const audioSampleRateMpegAac = ["44.1 kHz", "48 kHz"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const audioSampleRateMpegLayerTwo = ["44.1 kHz", "48 kHz"].map((value) => ({
    key: value,
    text: value,
    value
}));

export const audioBitrateMpegAac = ["64Kbps", "128Kbps", "192Kbps", "256Kbps"].map((value) => ({
    key: value,
    text: `${value}`,
    value
}));

export const audioBitrateMpegLayerTwo = [
    "64Kbps",
    "96Kbps",
    "128Kbps",
    "160Kbps",
    "192Kbps",
    "224Kbps",
    "256Kbps",
    "320Kbps",
    "384Kbps"
].map((value) => ({
    key: value,
    text: value,
    value
}));

export const transcoderSize = ["small", "big"].map((value) => ({
    key: value,
    text: value,
    value
}));
