import React, { useContext, useEffect } from "react";
import { RootStoreContext, ServiceConsumptionResponseV2, TotalsBreakdownItem } from "api";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import BillingSummaryPie from "./BillingSummaryPie";
import BillingSummaryChart from "./BillingSummaryChart";
import { observer } from "mobx-react-lite";

interface Props {
    serviceConsumptionData: ServiceConsumptionResponseV2;
    datesScope: any;
}

export const BillingSummary: React.FC<Props> = ({ serviceConsumptionData, datesScope }) => {

    const rootStore = useContext(RootStoreContext);

    const { selectedEnvironment } = rootStore.billingStore;

    useEffect(() => {
    }, [serviceConsumptionData, datesScope, selectedEnvironment]);

    return (
        <>
            <Grid container spacing={1} sx={{ mb: 1 }}>
                <Grid item xs={4}>
                    <BillingSummaryPie datesScope={datesScope} />
                </Grid>
                <Grid item xs={8}>
                    <BillingSummaryChart chartTitle="Today's Spending" currencySymbol={serviceConsumptionData.customer_currency_symbol} />
                </Grid>
            </Grid>
            <Card>
                <CardContent>
                    <Grid container columns={{ xs: 12, xl: 11 }} spacing={2}>
                        {serviceConsumptionData.totals_breakdown.map((item: TotalsBreakdownItem, index) => {
                            return (
                                <Grid
                                    item
                                    xs={2}
                                    xl={1}
                                    key={index}
                                    sx={{
                                        borderLeft: {
                                            xs: index > 0 && index !== 6 ? "1px solid rgba(160,160,160,0.2)" : undefined,
                                            xl: index > 0 ? "1px solid rgba(160,160,160,0.2)" : undefined
                                        },

                                        px: 2
                                    }}>
                                    <Box>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            {item.item}
                                        </Typography>
                                        <Typography variant="h6" component="div">
                                            {serviceConsumptionData.customer_currency_symbol + item.total}
                                        </Typography>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Box
                        sx={{
                            borderTop: "1px solid rgba(160,160,160,0.2)",
                            mt: 2,
                            pt: 2,
                            mb: -1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start"
                        }}>
                        <Typography variant="h5" gutterBottom>
                            Total: {serviceConsumptionData.customer_currency_symbol + "" + serviceConsumptionData.grand_total_cost}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default observer(BillingSummary);
