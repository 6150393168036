import { Box } from "@mui/material";
import { agent } from "api";
import React from "react";

interface AlarmsProps {
    nea_live_uuid: string;
}

const Alarms: React.FC<AlarmsProps> = ({ nea_live_uuid }) => {
    const [alarms, setAlarms] = React.useState([]);

    const getAlarms = async () => {
        await agent.NeaLive.getNeaLiveStats(nea_live_uuid)
            .then((response) => {
                setAlarms(response.data.alarms);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getAlarms();
    }, []);

    return (
        <Box>
            {alarms &&
                alarms.length > 0 &&
                alarms.map((alarm: any, index: number) => {
                    return <Box key={index}>{alarm}</Box>;
                })}
        </Box>
    );
};

export default Alarms;
