import { Alert, Box, Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { agent, ICreateModule, RootStoreContext } from "api";
import { Form as FinalForm } from "react-final-form";
import TextField from "components/Forms/TextField";
import CheckboxField from "components/Forms/CheckboxField";

interface ICreateModuleFormProps {
    getAvailableModules: () => Promise<void>;
}

const CreateModuleForm: React.FC<ICreateModuleFormProps> = observer(function CreateModuleForm({ getAvailableModules }) {
    const rootStore = React.useContext(RootStoreContext);

    const { closeDialog } = rootStore.dialogStore;

    const [errors, setErrors] = React.useState<any>();

    const handleFinalFormSubmit = async (values: { module_code: string; module_name: string; system_default: boolean }) => {
        const request: ICreateModule = {
            module_code: values.module_code,
            module_name: values.module_name,
            system_default: values.system_default
        };
        await agent.Admin.createModule(request)
            .then(() => {
                closeDialog();
                getAvailableModules();
            })
            .catch((error) => {
                console.log(error.data);
                setErrors(error.data);
            });
    };

    return (
        <FinalForm
            onSubmit={(values: { module_code: string; module_name: string; system_default: boolean }) => handleFinalFormSubmit(values)}
            render={({ handleSubmit, submitError, invalid, pristine, dirtySinceLastSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent sx={{ pt: 0, pb: 0.5 }}>
                        <Box sx={{ mb: 1.5 }}>
                            <TextField
                                name="module_code"
                                label="Module Code"
                                helperText={() => {
                                    if (errors?.errors?.module_code) {
                                        return errors?.errors?.module_code;
                                    }
                                }}
                                error={() => {
                                    return errors?.errors?.module_code ? true : false;
                                }}></TextField>
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <TextField
                                name="module_name"
                                label="Module Name"
                                helperText={() => {
                                    if (errors?.errors?.module_name) {
                                        return errors?.errors?.module_name;
                                    }
                                }}
                                error={() => {
                                    return errors?.errors?.module_name ? true : false;
                                }}></TextField>
                        </Box>

                        <CheckboxField
                            name="system_default"
                            label="System Default"
                            initialValue={false}
                            helperText={() => {
                                if (errors?.errors?.system_default) {
                                    return errors?.errors?.system_default;
                                }
                            }}
                            error={() => {
                                return errors?.errors?.system_default ? true : false;
                            }}></CheckboxField>
                        {submitError && (
                            <Alert variant="filled" severity="error">
                                <Typography fontWeight="bold">{errors.data?.message}</Typography>
                                {errors.data.errors &&
                                    Object.entries(errors.data.errors).map(([key, value]) => (
                                        <Typography key={key}>
                                            <>&bull;&nbsp;{value}</>
                                        </Typography>
                                    ))}
                            </Alert>
                        )}
                    </DialogContent>
                    {errors?.message && (
                        <Box sx={{ px: 3 }}>
                            <Alert severity="error">{errors.message}</Alert>
                        </Box>
                    )}
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Create Module
                        </Button>
                    </DialogActions>
                </form>
            )}
        />
    );
});

export default CreateModuleForm;
