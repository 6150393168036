import React, { useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import QRCode from "qrcode.react";
import { combineValidators, isRequired } from "revalidate";
import { IUserFormValues, RootStoreContext } from "api";
import { TextField } from "mui-rff";
import ErrorMessage from "app/common/form/ErrorMessage";
import base32 from "hi-base32";
import { observer } from "mobx-react-lite";
import LoadingButton from "@mui/lab/LoadingButton";
import { Avatar, Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { makeRandomKey } from "helpers";

function generateSecret(length: number) {
    const randomBuffer = new Uint8Array(length);
    window.crypto.getRandomValues(randomBuffer);
    return base32.encode(randomBuffer).replace(/=/g, "");
}

const validate = combineValidators({
    totp_code: isRequired("totp_code")
});

const TwoFactorDoneMessage = observer(function TwoFactorDoneMessage() {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <>
            <DialogTitle>2FA Setup Successful</DialogTitle>
            <DialogContent>You have successfully enabled 2 factor authentication on your account.</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </DialogActions>
        </>
    );
});

const RegisterTwoFactorA = observer(function RegisterTwoFactorA() {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog, openDialog } = rootStore.dialogStore;
    const { sendGoogleToken, enable2FA, user } = rootStore.userStore;
    const { setToken } = rootStore.commonStore;
    const [secrethasvalue, setsecrethasvalue] = useState(false);
    const [otpath, setotpath] = useState("");
    const [userSecret, setUserSecret] = useState("");

    useEffect(() => {
        const gtoken = generateSecret(20);
        if (gtoken) {
            setUserSecret(gtoken);
            sendGoogleToken(gtoken);
        }
        setsecrethasvalue(true);
        const otpauth =
            process.env.REACT_APP_CLIENT_NAME === "ateme"
                ? `otpauth://totp/${user?.email}?secret=${gtoken}&issuer=Ateme+`
                : `otpauth://totp/${user?.email}?secret=${gtoken}&issuer=LiveLink`;
        setotpath(otpauth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendGoogleToken, makeRandomKey]);

    return (
        <>
            <DialogTitle>Enable Two-Factor Authentication</DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Box sx={{ marginBottom: 1 }}>
                            <Avatar sx={{ width: 35, height: 35, bgcolor: "primary.main", color: "#ffffff" }}>1</Avatar>
                        </Box>
                        <Typography sx={{ textAlign: "left", mb: 2 }}>
                            Install <strong>Google Authenticator</strong> on your phone.
                        </Typography>
                        <Box
                            component="img"
                            alt="Download Google Authenticator from the App Store or Google Play"
                            maxWidth={"100%"}
                            src="./assets/BrandImages/AppStore_googleplay.png"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{ marginBottom: 1 }}>
                            <Avatar sx={{ width: 35, height: 35, bgcolor: "primary.main", color: "#ffffff" }}>2</Avatar>
                        </Box>
                        <Typography sx={{ textAlign: "left", mb: 1 }}>
                            Open <strong>Google Authenticator</strong> {""} and input code manually:
                            <br />
                            <span
                                style={{
                                    color: "#307abb",
                                    wordBreak: "break-all",
                                    fontWeight: 600
                                }}>
                                {userSecret}
                            </span>
                            <br />
                            Or scan the QR code below. You can write down the code for back up.
                        </Typography>
                        {secrethasvalue ? <QRCode bgColor="#fff" value={otpath} level={"H"} includeMargin={true} size={200} /> : null}
                    </Grid>
                    <Grid item xs={4}>
                        <FinalForm
                            onSubmit={(values: IUserFormValues) =>
                                enable2FA(values)
                                    .then((response) => {
                                        // Changing jwt triggers user to be reloaded.
                                        setToken(response.jwt_token);
                                        closeDialog();
                                        openDialog(<TwoFactorDoneMessage />);
                                    })
                                    .catch((error) => ({
                                        [FORM_ERROR]: error
                                    }))
                            }
                            validate={validate}
                            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <Box sx={{ mb: 1 }}>
                                        <Avatar sx={{ width: 35, height: 35, bgcolor: "primary.main", color: "#ffffff" }}>3</Avatar>
                                    </Box>

                                    <Typography sx={{ textAlign: "left" }}>Input the code you receive into the box below</Typography>
                                    <TextField fullWidth required name="totp_code" type="text" label="Code" sx={{ my: 2 }} />
                                    {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                                    <LoadingButton
                                        disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                        loading={submitting}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary">
                                        Submit
                                    </LoadingButton>
                                </form>
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
});

export default RegisterTwoFactorA;
