import React from "react";
import ResClient, { ResCollection, ResModel } from "resclient";
import SourceThumbnail from "./SourceThumbnail";
import { Box, ListItem, ListItemButton, Typography } from "@mui/material";
import { RootStoreContext } from "api";
import SourceDetails from "./SourceDetails";
import useSource from "../useSource";

interface Props {
    //source: IInput;
    //onClick: () => void;
    rid: string;
    client: ResClient;
    draggable?: boolean;
    onDragEnd?: (event: React.DragEvent<HTMLElement>) => void;
}
export const SourceListItem: React.FC<Props> = (props) => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const [loading, setLoading] = React.useState<boolean>(false);
    const [wsData, setWsData] = React.useState<any>({});
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [type, setType] = React.useState<string>("");

    const onUpdate = () => {
        setUpdateDate(new Date());
    };

    React.useEffect(() => {
        setLoading(true);

        let data: any;

        //!wsData.connection_status &&
        props.rid &&
            props.client.get(props.rid).then((res: ResModel | ResCollection | any) => {
                data = res;
                if (data.transcoder_id) {
                    setType("transcoder");
                } else if (data.multiviewer_id) {
                    setType("multiviewer");
                } else {
                    setType("source");
                }

                data.on("change", () => {
                    onUpdate();
                });
                setWsData(data);
                onUpdate();
            });

        setLoading(false);
        onUpdate();

        return () => {
            setLoading(false);
            setWsData({});
        };
    }, [props.client, props.rid]);

    React.useEffect(() => {}, [updateDate]);


    const { getTypeText, getStatusText, getStatusColor, getStatusIcon } = useSource(wsData);

    return (
        <ListItem
            key={wsData.id}
            draggable={props.draggable}
            onDragEnd={(e) => props.onDragEnd && props.onDragEnd(e)}
            disablePadding
            sx={{
                borderLeftWidth: 4,
                borderLeftStyle: "solid",
                borderLeftColor: getStatusColor(),
                mb: "1px",
                width: "100%",
            }}>
            <ListItemButton
                sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}
                onClick={() => {
                    openDrawer(
                        <Box sx={{ width: 680, height: "100%", padding: 3 }}>
                            <SourceDetails item_id={wsData.id} tab="1" />
                        </Box>
                    );
                }}>
                <Box sx={{ flex: "0 0 80px", width: 80, mr: 1, overflow: "hidden", borderRadius: 1 }}>
                    <SourceThumbnail item_id={wsData.id} disableLoading />
                </Box>
                <Box
                    sx={
                        {
                            flex: "1 1 auto",
                            //width: "100%",
                        }
                    }>
                    <Box sx={{ fontWeight: 600, lineHeight: 1.1}}>
                        {wsData.name}
                    </Box>
                    <Box>
                        {/* caption */}
                        <Typography
                            variant="body2"
                            sx={{
                                textTransform: "capitalize",
                                color: "text.secondary",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>
                            {getStatusText()}
                        </Typography>
                    </Box>
                </Box>
            </ListItemButton>
        </ListItem>
    );
};

export default SourceListItem;
