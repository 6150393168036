import { Box, Card, Tooltip, useTheme } from "@mui/material";
import React from "react";
import { Handle, NodeProps, Position } from "reactflow";
import NodeHeader from "../NodeHeader";
import { Field } from "react-final-form";

interface Props extends NodeProps {
    // sourcePosition?: Position;
    // targetPosition?: Position;
    data: {
        label: string;
        sourcePosition: string;
        targetPosition: string;
        items: any[];
        itemsInTooltip?: boolean;
        component?: React.ReactNode;
        onClick?: () => void;
        width?: number;
        height?: number;
        hideSource?: boolean;
        hideTarget?: boolean;
        menuItems?: any[];
        hasChildren?: boolean;
        color?: string;
    };
}

export const DetailsNode: React.FC<Props> = (props) => {
    const theme = useTheme();
    return (
        <Tooltip
            title={
                props.data.itemsInTooltip ? (
                    <Box>
                        {props.data.items.map((item: any, i: number) => {
                            return (
                                <Box
                                    key={i}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: 12,
                                        width: "100%",
                                        my: 0.5
                                    }}>
                                    <Box sx={{ opacity: 0.7, fontSize: 11, lineHeight: "11px", flex: "0 0 auto" }}>{item.label}</Box>
                                    <Box
                                        sx={{
                                            flex: "1 1 auto",
                                            textAlign: "right",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            ml: 1,
                                            fontSize: 12
                                        }}>
                                        {item.name ? (
                                            <Field name={item.name} component="input">
                                                {({ input }) => <span>{input.value && input.value}</span>}
                                            </Field>
                                        ) : (
                                            item.value
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                ) : undefined
            }
            placement="left">
            <Card
                elevation={props.selected ? 4 : 0}
                sx={{
                    height: props.data.height || 255,
                    width: props.data.width || 255,
                    overflow: "visible",
                    backgroundColor: props.selected
                        ? theme.palette.primary.dark
                        : props.data.hasChildren
                        ? theme.palette.action.hover
                        : undefined,
                    color: props.selected ? theme.palette.getContrastText(theme.palette.primary.dark) : undefined
                }}
                onClick={props.data.onClick && props.data.onClick}>
                {!props.data.hideTarget && <Handle type="target" position={props.targetPosition || Position.Left} />}
                <NodeHeader label={props.data.label} menuItems={props.data.menuItems} color={props.data.color ? props.data.color : undefined} />
                <Box sx={{ px: 2 }}>
                    {!props.data.itemsInTooltip &&
                        props.data?.items?.map((item: any, i: number) => {
                            return (
                                <Box
                                    key={i}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        height: 30,
                                        width: "100%"
                                    }}>
                                    <Box sx={{ opacity: 0.7, fontSize: 11, lineHeight: "11px", flex: "0 0 auto" }}>{item.label}</Box>
                                    <Box
                                        sx={{
                                            flex: "1 1 auto",
                                            textAlign: "right",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            ml: 1
                                        }}>
                                        {item.name ? (
                                            <Field name={item.name} component="input">
                                                {({ input }) => <span>{input.value && input.value}</span>}
                                            </Field>
                                        ) : (
                                            item.value
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
                {props.data.component && (
                    <Box sx={{ px: 2, py: 1 }}>{props.data.component}</Box>
                )}
                {!props.data.hideSource && <Handle isConnectable={false} type="source" position={props.sourcePosition || Position.Right} />}
            </Card>
        </Tooltip>
    );
};

export default DetailsNode;
