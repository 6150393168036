import React, { useContext, useEffect, useState } from "react";
import { agent, ISourcePermission, RootStoreContext } from "api";
import AddContactForm from "./contacts/AddContactForm";
import { observer } from "mobx-react";
import ContactHeaders from "./contacts/ContactsHeaders";
import AddDestinationOrganisation from "../AddOrganisationtoOrganisation/AddDestinationOrganisation";
import OrganisationPermissionTable from "./organisationPermissions/OrganisationPermissionTable";
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Divider,
    Fade,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { useSourcePermissions } from "hooks";
import EditContactForm from "./contacts/EditContactForm";
import {
    ContentCopy,
    Delete,
    Edit,
    KeyboardArrowDown,
    KeyboardArrowRightRounded,
    KeyboardArrowUp,
    PlayArrow,
    Stop
} from "@mui/icons-material";
import ConfirmationDialog from "components/ConfirmationDialog";
import RowStart from "features/scheduler/Week/Components/RowStart";
import CopyToClipboard from "react-copy-to-clipboard";
import GlobalMaxDest from "./organisationPermissions/GlobalMaxDest";
import EnvironmentPermissionsTable from "./organisationPermissions/EnvironmentPermissionsTable";
import { billingSources } from "app/common/options/billingSources";
import SourcePermissions from "../SourcePermissions";
import InfoItemVertical from "components/InfoItemVertical";
import InfoItem from "components/InfoItem";

const ContentPermissions = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { user } = rootStore.userStore;
    const { sourcePermissions } = rootStore.sourcePermissionsStore;
    const { updateSourcePermission, loadSourcePermissions } = useSourcePermissions();
    const { activeOrganisation } = rootStore.organisationStore;
    const { contacts } = rootStore.sourcePermissionsStore;
    const { getContacts } = useSourcePermissions();
    const [query, setQuery] = useState("");
    const filtered = sourcePermissions?.data.filter((x) => x.name?.toLowerCase().includes(query));

    useEffect(() => {
        getContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadSourcePermissions().catch((error) => console.log(error));
    }, [loadSourcePermissions, activeOrganisation]);

    const activationHandler = (action: string, row: any) => {
        let values;
        if (action === "SUSPEND") {
            values = {
                registered: false,
                is_pending: false,
                is_suspended: true,
                token: row.token
            };
            return updateSourcePermission(values).finally(() => loadSourcePermissions());
        } else if (action === "ACTIVATE") {
            values = {
                registered: true,
                is_pending: false,
                is_suspended: false,
                token: row.token
            };
            return updateSourcePermission(values).finally(() => loadSourcePermissions());
        }
    };

    const deleteContact = (id: number, name: string) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Delete Contact"
                message={`Delete Contact: ${name.toUpperCase()}`}
                onConfirm={async () => {
                    await agent.SourcePermissions.deleteContact(id).then(() => {
                        getContacts();
                    });
                }}
            />
        );
    };

    return (
        <>
            <Stack spacing={3} sx={{ p: 3 }}>
                <Stack spacing={1}>
                    <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                            My Contact Details
                        </Typography>
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{
                                timeout: 600
                            }}
                            placement="bottom"
                            title="Add a contact that will be shared with the invited organisations">
                            <Button
                                variant="contained"
                                disabled={!user?.is_member_of_active_org}
                                onClick={() => openDialog(<AddContactForm />)}>
                                Add Contact
                            </Button>
                        </Tooltip>
                    </Stack>
                    <Table sx={{ mb: 4 }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}>
                                    <Box>Name</Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}>
                                    <Box>Email</Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}>
                                    <Box>Phone Number</Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contacts && contacts?.data.length ? (
                                contacts.data.map((contact) => (
                                    <TableRow hover key={contact.id}>
                                        <TableCell>{contact.name}</TableCell>
                                        <TableCell>{contact.email}</TableCell>
                                        <TableCell>{contact.phone}</TableCell>
                                        <TableCell align="right" style={{ padding: "7px 5px 3px 0" }}>
                                            <Stack direction="row" spacing={2} justifyContent={"end"}>
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600
                                                    }}
                                                    placement="bottom"
                                                    title="Update">
                                                    <IconButton onClick={() => openDialog(<EditContactForm contact={contact} />)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600
                                                    }}
                                                    placement="bottom"
                                                    title="Delete">
                                                    <IconButton onClick={() => deleteContact(contact.id!, contact.name)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4}>No contacts found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Stack>

                <Stack spacing={1}>
                    <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" gutterBottom sx={{ mt: "8px !important" }}>
                            Organisation Permissions
                        </Typography>
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{
                                timeout: 600
                            }}
                            placement="bottom"
                            title="Search Organisation Permissions">
                            <TextField
                                variant="standard"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                label="Search"
                                sx={{ mb: "8px !important", width: 150 }}
                            />
                        </Tooltip>
                    </Stack>
                    <Table sx={{ mb: 4 }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={65}
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}></TableCell>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}>
                                    <Box>Organisation Name</Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}>
                                    <Box>Status</Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.background.paper
                                    }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filtered && filtered.length ? (
                                filtered.map((row, index) => <OrganisationRow key={index} row={row} index={index} />)
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4}>No organisations found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Stack>
            </Stack>
        </>
    );
};

export default observer(ContentPermissions);

interface IRow {
    row: ISourcePermission;
    index: number;
}

export const OrganisationRow: React.FC<IRow> = observer(function OrganisationRow({ row, index }) {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { updateSourcePermission, loadSourcePermissions } = useSourcePermissions();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({
        checkedB: row.registered,
        ShowEvents: row.show_all_events,
        ShowallChannels: row.show_all_channels,
        Registered: row.registered
        // checkedA: !row.is_admin,
    });
    const { ShowEvents, ShowallChannels, Registered } = state;
    const [billingName, setBillingName] = useState(row.billing_source);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [copied, setCopied] = useState(false);

    const handleDropDownChange = (event: SelectChangeEvent) => {
        setBillingName(event.target.value);
        setLoadingInitial(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setLoadingInitial(false);
    };

    const activationHandler = (action: string, row: any) => {
        let values;
        if (action === "SUSPEND") {
            values = {
                registered: false,
                is_pending: false,
                is_suspended: true,
                token: row.token
            };
            return updateSourcePermission(values).finally(() => loadSourcePermissions());
        } else if (action === "ACTIVATE") {
            values = {
                registered: true,
                is_pending: false,
                is_suspended: false,
                token: row.token
            };
            return updateSourcePermission(values).finally(() => loadSourcePermissions());
        }
    };

    useEffect(() => {
        const values = {
            show_all_channels: ShowallChannels,
            show_all_events: ShowEvents,
            billing_source: billingName,
            registered: Registered,
            token: row.token
        };
        if (loadingInitial === false) {
            updateSourcePermission(values).finally(() => loadSourcePermissions());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ShowallChannels, ShowEvents, billingName, row.token, updateSourcePermission, loadSourcePermissions, SourcePermissions, loadingInitial, Registered]);

    return (
        <>
            <TableRow
                hover
                key={index}
                onClick={() => setOpen(!open)}
                sx={{ cursor: "pointer", "& > *": { borderBottom: "unset !important" } }}>
                <TableCell align="left">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDown /> : <KeyboardArrowRightRounded />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                    {row.is_pending && "Pending"}
                    {row.is_suspended && "Suspended"}
                    {row.registered && "Active"}
                </TableCell>
                <TableCell style={{ textAlign: "right", padding: "7px 5px 7px 0" }}>
                    <Stack direction="row" spacing={2} justifyContent={"end"}>
                        {!row.is_pending &&
                            (row.is_suspended ? (
                                <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{
                                        timeout: 600
                                    }}
                                    placement="bottom"
                                    title="Activate">
                                    <IconButton onClick={() => activationHandler("ACTIVATE", row)}>
                                        <PlayArrow />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{
                                        timeout: 600
                                    }}
                                    placement="bottom"
                                    title="Suspend">
                                    <IconButton onClick={() => activationHandler("SUSPEND", row)}>
                                        <Stop />
                                    </IconButton>
                                </Tooltip>
                            ))}

                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{
                                timeout: 600
                            }}
                            placement="bottom"
                            title="Delete">
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    openDialog(
                                        <ConfirmationDialog
                                            title="Confirm Remove Permissions"
                                            message="Removing an organisation cannot be undone. To share content again you will need to re-invite the organisation."
                                            onConfirm={async () => {
                                                await agent.SourcePermissions.deleteOrgInvitation(row?.token!).then(() => {
                                                    loadSourcePermissions();
                                                });
                                            }}
                                        />
                                    );
                                }}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>

            <TableRow style={{ borderTop: "none" }}>
                <TableCell
                    style={{
                        padding: 0,
                        borderTop: "none"
                    }}
                    colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Card>
                            <CardContent>
                                <Stack spacing={2} direction="column">
                                    <Box>
                                        <Box>
                                            <Typography variant="h4">Invitation Token</Typography>
                                        </Box>

                                        <Box sx={{ mt: 2 }}>
                                            <InfoItem title="Source invitation token" value={row.token} value_type="copy" />
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Box>
                                            <Typography variant="h4">Permissions</Typography>
                                        </Box>

                                        <Box sx={{ mt: 2 }}>
                                            <InfoItem
                                                title="Billing Source"
                                                value={
                                                    <Select
                                                        onChange={handleDropDownChange}
                                                        defaultValue={row.billing_source}
                                                        style={{
                                                            margin: 0,
                                                            paddingLeft: 0
                                                        }}>
                                                        {billingSources.map((source) => (
                                                            <MenuItem key={source.value} value={source.value}>
                                                                {source.text}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                }
                                                value_type="field"
                                            />
                                            <InfoItem
                                                title="Show all channels on all environments"
                                                value={
                                                    <Switch
                                                        checked={state.ShowallChannels}
                                                        onChange={handleChange}
                                                        color="primary"
                                                        name="ShowallChannels"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                    />
                                                }
                                                value_type="field"
                                            />
                                            {state.ShowallChannels && (
                                                <InfoItem
                                                    title="Max number of destinations per environment"
                                                    value={<GlobalMaxDest token={row.token} globMax={row.global_max_destinations} />}
                                                    value_type="field"
                                                />
                                            )}
                                        </Box>
                                    </Box>

                                    {!state.ShowallChannels &&
                                        Array.isArray(row.environment_permissions) &&
                                        row.environment_permissions.length > 0 && (
                                            <Box>
                                                <Box>
                                                    <Typography variant="h4">Environments</Typography>
                                                </Box>

                                                <Box sx={{ mt: 2 }}>
                                                    {!state.ShowallChannels && (
                                                        <Table style={{ marginTop: 30 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Environments</TableCell>
                                                                    <TableCell align="center" width="15%">
                                                                        Max Destinations
                                                                    </TableCell>
                                                                    <TableCell align="center" width="15%">
                                                                        Delete All Destinations
                                                                    </TableCell>
                                                                    <TableCell width="15%"> </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody
                                                                sx={{
                                                                    ".input-source-access-row": {
                                                                        ".source-table-container:last-child": {
                                                                            td: {
                                                                                borderBottomWidth: 0
                                                                            }
                                                                        }
                                                                    }
                                                                }}>
                                                                {row.environment_permissions?.map((env, i) => {
                                                                    return <EnvironmentPermissionsTable key={i} env={env} row={row} />;
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
});
