import React, { useContext, useState, useEffect, useRef } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { agent, IAlertsEntry, RootStoreContext } from "api";
import Button from "@mui/material/Button";
import AlertActionSelector from "../../alertsManagement/Components/AlertActionSelector";
import AlertsForm from "../../alertsManagement/Components/AlertsForm";
import AlertsFormEdit from "../../alertsManagement/Components/AlertsFormEdit";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
    ConfirmDeleteMultipleAlerts,
    ConfirmDeleteSingleAlert,
    ConfirmMuteMultipleAlerts,
    ConfirmMuteSingleAlert,
    ConfirmUnMuteMultipleAlerts,
    ConfirmUnMuteSingleAlert
} from "../../alertsManagement/Components/AlertConfirmationQuestions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, Checkbox, Chip, Fade, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import OrganisationRoleProvider from "components/OrganisationRoleProvider";
import SourceDetails from "./SourceDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useScheduler } from "hooks";
import ConfirmationDialog from "components/ConfirmationDialog";

interface IChannelAlertsProps {
    channel_id: string;
    returnTo?: () => void;
}

const ChannelAlerts: React.FC<IChannelAlertsProps> = ({ channel_id }) => {
    const rootStore = useContext(RootStoreContext);
    const { getInputAlerts } = useScheduler();
    const { openDialog } = rootStore.dialogStore;
    const [alerts, setAlerts] = useState<IAlertsEntry[]>();
    const [alertsSelected, setAlertsSelected] = useState<string[]>([]);
    const [selectAllTicked, setSelectAllTicked] = useState(false);

    const unMuteUponRecovery = useRef(false);
    const setUnMuteUponRecovery = (newValue: boolean) => {
        unMuteUponRecovery.current = newValue;
    };

    const loadAlerts = () => {
        getInputAlerts(channel_id).then((response_data) => {
            let rows: IAlertsEntry[] = response_data.data;
            setAlerts(rows);
        });
    };

    useEffect(() => {
        loadAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTickAlert = (alert_id: string) => {
        // shallow clone of the array. Super important. Pointing to the same variable does not create a new reference
        let newState = [...alertsSelected];

        const item_index = newState.indexOf(alert_id);
        if (item_index > -1) {
            newState.splice(item_index, 1);
        } else {
            newState.push(alert_id);
        }
        setAlertsSelected(newState);
    };

    const handleSelectAll = () => {
        if (!selectAllTicked) {
            const newState = alerts!.map((value) => {
                return value.id;
            });
            setAlertsSelected(newState);
            setSelectAllTicked(true);
        } else {
            setAlertsSelected([]);
            setSelectAllTicked(false);
        }
    };

    const muteAlert = (alert_id: string, alert_name: string, is_alerting: boolean) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Mute Alert"
                message={`Are you sure you want to mute the alert "${alert_name}"?`}
                onConfirm={() => {
                    return agent.getCleanInstance().put(`/environments/alerts/${alert_id}/state`, {
                        action: "deactivate",
                        unmute_upon_recovery: unMuteUponRecovery.current
                    });
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        );
    };

    const unMuteAlert = (alert_id: string, alert_name: string) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Un-Mute Alert"
                message={`Are you sure you want to un-mute the alert "${alert_name}"?`}
                onConfirm={() => {
                    return agent.getCleanInstance().put(`/environments/alerts/${alert_id}/state`, { action: "activate" });
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        );
    };

    const deleteSingleAlert = (alert_id: string, alert_name: string) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Delete Alert"
                message={`Are you sure you want to delete the alert "${alert_name}"?`}
                onConfirm={() => {
                    return agent.getCleanInstance().delete(`/environments/alerts/${alert_id}`);
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        );
    };

    const getSelectedAlertNames = () => {
        let alert_names: string[] = [];
        alerts!.forEach((alert) => {
            if (alertsSelected.indexOf(alert.id) > -1) {
                alert_names.push(`"${alert.alert_name}"`);
            }
        });
        return alert_names;
    };

    const applyChangeToSelected = (action: string) => {
        if (action === "delete") {
            openDialog(
                <ConfirmationDialog
                    title="Confirm Delete Alerts"
                    message="Are you sure you want to delete the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent.getCleanInstance().delete("/environments/alerts/delete-multiple", {
                            data: { alert_ids: alertsSelected }
                        });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                        setAlertsSelected([]);
                    }}
                />
            );
        } else if (action === "mute") {
            let numberOfAlertsInOkState = 0;
            alertsSelected.forEach((alertId) => {
                alerts!.forEach((alert) => {
                    if (alert.id === alertId && alert.is_alerting === false) {
                        numberOfAlertsInOkState++;
                    }
                });
            });

            openDialog(
                <ConfirmationDialog
                    title="Confirm Mute Alerts"
                    message="Are you sure you want to mute the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent.getCleanInstance().put("/environments/alerts/mute-multiple", {
                            alert_ids: alertsSelected,
                            unmute_upon_recovery: unMuteUponRecovery.current
                        });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                    }}
                />
            );
        } else if (action === "un_mute") {
            openDialog(
                <ConfirmationDialog
                    title="Confirm Un-Mute Alerts"
                    message="Are you sure you want to un-mute the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent.getCleanInstance().put("/environments/alerts/unmute-multiple", { alert_ids: alertsSelected });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                    }}
                />
            );
        }
    };

    return (
        <>
            <Box>
                <Box sx={{ margin: 0, padding: "0 0 10px 0" }}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box style={{ padding: "2px 0 0 5px" }}>
                            {alertsSelected.length > 0 && (
                                // Show action selector if something has been selected
                                <AlertActionSelector
                                    onChangeAction={(action) => {
                                        applyChangeToSelected(action);
                                    }}
                                />
                            )}
                        </Box>
                        <Box style={{ padding: 0, textAlign: "right" }}>
                            <Stack direction="row" spacing={2} width="100%" justifyContent="flex-end">
                                <Button startIcon={<CachedIcon />} onClick={loadAlerts}>
                                    Refresh
                                </Button>
                                <OrganisationRoleProvider roles={["admin", "operator"]}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            openDialog(
                                                <>
                                                    <AlertsForm
                                                        item_id={channel_id}
                                                        type="input"
                                                        successCallback={() => {
                                                            loadAlerts();
                                                        }}
                                                    />
                                                </>,
                                                "New Alert"
                                            );
                                        }}
                                        startIcon={<AddCircleIcon />}>
                                        New Alert
                                    </Button>
                                </OrganisationRoleProvider>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Box>

            {alerts && alerts.length > 0 && (
                <Box sx={{ height: "300px" }}>
                    <TableContainer style={{ height: 300 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={50}>
                                        <Checkbox
                                            checked={selectAllTicked}
                                            onChange={() => {
                                                handleSelectAll();
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>Alert</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {alerts?.map((row: IAlertsEntry) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={row.id}
                                            selected={alertsSelected.indexOf(row.id) > -1}
                                            sx={{
                                                backgroundColor: (theme) =>
                                                    row.is_alerting && row.status === "active" ? theme.palette.error.dark : undefined,
                                                opacity: row.status === "inactive" ? 0.7 : 1
                                            }}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={alertsSelected.indexOf(row.id) > -1}
                                                    onChange={() => {
                                                        handleTickAlert(row.id);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{row.alert_name}</Typography>
                                                <Typography variant="body2">{row.condition_string}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {row.is_alerting && row.status === "active" ? (
                                                    <Chip
                                                        label="Alerting"
                                                        color="error"
                                                        size="small"
                                                        avatar={<NotificationsIcon className="pulse" />}
                                                    />
                                                ) : row.status === "inactive" ? (
                                                    <Chip label="Muted" size="small" avatar={<VolumeOffIcon />} />
                                                ) : (
                                                    <Chip
                                                        label={row.status}
                                                        sx={{ textTransform: "capitalize" }}
                                                        size="small"
                                                        color="primary"
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                                                    <Tooltip title="Edit Alert">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                openDialog(
                                                                    <>
                                                                        <AlertsFormEdit
                                                                            source={row}
                                                                            successCallback={() => {
                                                                                loadAlerts();
                                                                            }}
                                                                        />
                                                                    </>,
                                                                    "Edit Alert"
                                                                )
                                                            }
                                                            aria-label="Edit">
                                                            <CreateIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={row.status === "active" ? "Mute" : "Unmute"}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                if (row.status === "active") {
                                                                    muteAlert(row.id, row.alert_name, row.is_alerting);
                                                                } else {
                                                                    unMuteAlert(row.id, row.alert_name);
                                                                }
                                                            }}
                                                            aria-label={row.status === "active" ? "Mute" : "Unmute"}>
                                                            {row.status === "active" ? (
                                                                <VolumeOffIcon fontSize="small" />
                                                            ) : (
                                                                <VolumeUpIcon fontSize="small" />
                                                            )}
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => deleteSingleAlert(row.id, row.alert_name)}
                                                            aria-label="Delete">
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    );
};

export default ChannelAlerts;
