import React, { useContext, useEffect, useState } from "react";
import { IScheduleOverviewEntry, RootStoreContext, agent } from "api";
import { Box, Chip, Divider, Fade, Grid, List, ListItem, ListItemButton, ListItemText, Tooltip, Typography } from "@mui/material";
import { useSortableData } from "hooks/UseSortableData";
import { useNavigate } from "react-router";
import { format, parseISO, startOfDay, addHours } from "date-fns";

const ScheduleOverviewWidget: React.FC = () => {
    const startDate = startOfDay(new Date());
    const endDate = addHours(startOfDay(new Date()), 12);

    const [dateRange, setDateRange] = useState<Date[]>([startDate, endDate]);

    const rootStore = useContext(RootStoreContext);
    const { activeEnvironment } = rootStore.userStore;
    //const { getScheduleOverview } = rootStore.schedulerStore;
    const { sortingConfig, sortList } = useSortableData();
    const navigate = useNavigate();

    const [schedules, setSchedules] = useState<IScheduleOverviewEntry[]>([]);
    const loadSchedules = () => {
        if (dateRange[0] && dateRange[1]) {
            agent.Scheduler.getScheduleOverview(startOfDay(dateRange[0]), dateRange[1])
                .then((response) => {
                    // Sort the list after every load (if sort order defined)
                    const sortedList = sortList(response.results, sortingConfig?.sortingColumn, sortingConfig?.sortingOrder);
                    setSchedules(sortedList);
                    //setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    //setLoading(false);
                });
        }
    };

    useEffect(() => {
        loadSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeEnvironment]);

    // Sortable Table

    return (
        <>
            {schedules && (
                <Box sx={{ width: "100%" }}>
                    <nav aria-label="secondary mailbox folders">
                        <List>
                            <Divider sx={{ color: "text.primary" }} />
                            {schedules.map((item, i) => (
                                <ListItem key={i} disablePadding divider>
                                    <ListItemButton onClick={() => navigate("/scheduler")}>
                                        <Grid container>
                                            <Grid container xs={item.status === "Live" ? 10 : 12}>
                                                <Grid item xs={6}>
                                                    <Tooltip
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{
                                                            timeout: 600
                                                        }}
                                                        placement="bottom"
                                                        arrow
                                                        key={i}
                                                        title="Start time">
                                                        <Typography
                                                            sx={{
                                                                opacity: 0.6,
                                                                fontSize: "0.85rem"
                                                            }}>
                                                            {format(parseISO(item.start_time), "dd/LL/yyyy @ HH:mm:ss ")}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Tooltip
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{
                                                            timeout: 600
                                                        }}
                                                        placement="bottom"
                                                        arrow
                                                        key={i}
                                                        title="End time">
                                                        <Typography
                                                            sx={{
                                                                opacity: 0.6,
                                                                fontSize: "0.85rem"
                                                            }}>
                                                            {format(parseISO(item.end_time), "dd/LL/yyyy @ HH:mm:ss ")}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <ListItemText primary={item.from_channel} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <ListItemText primary={item.to_destination} />
                                                </Grid>
                                            </Grid>
                                            {item.status === "Live" ? (
                                                <Grid container xs={2} sx={{ alignContent: "center", justifyContent: "end" }}>
                                                    <Chip label={item.status} sx={{ backgroundColor: "success.main" }} size="small" />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </nav>
                </Box>
            )}
        </>
    );
};

export default ScheduleOverviewWidget;
