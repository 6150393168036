import { Box, Chip, MenuItem, TextField } from "@mui/material";
import { RootStoreContext } from "api";
import { useOrganisation } from "hooks";
import React, { useContext } from "react";

const OrganisationSwitch: React.FC = () => {
    const rootStore = useContext(RootStoreContext);

    const { user, activeOrganisation } = rootStore.userStore;
    const { organisations } = rootStore.organisationStore;
    const { changeActiveOrganisation } = useOrganisation();

    const handleChange = (value: number) => {
        console.log("event", event);
        changeActiveOrganisation({ organisation_id: value }).then(() => {
            console.log("activeOrganisation", activeOrganisation);
            console.log("user", user);
            //window.location.reload();
            //loadEnvironments().finally(() => {
            // if (activeEnvironment) {
            //     loadInputs(activeEnvironment);
            // }
            //});
        });
    };

    if (organisations) {
        return (
            <TextField
                id="outlined-basic"
                fullWidth
                label="Organisation"
                variant="outlined"
                size="small"
                defaultValue={activeOrganisation}
                onChange={(e) => handleChange(Number(e.target.value))}
                select>
                {organisations?.data.map((organisation) => (
                    <MenuItem key={organisation.id} value={organisation.id}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <Box>{organisation.name}</Box>
                            <Chip label={organisation.is_member_of_active_org ? "Member" : "Guest"} size="small" />
                        </Box>
                    </MenuItem>
                ))}
            </TextField>
        );
    }
    return null;
};

export default OrganisationSwitch;
