import React, { Component, ErrorInfo, ReactNode } from "react";
import { RootStore, ErrorStore } from "api";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    errorStore: ErrorStore;
    constructor(errorStore: any) {
        super(errorStore);
        this.errorStore = new ErrorStore(new RootStore());
    }

    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.errorStore.submitUiErrors(error.name, error.message);
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Dialog open={true} sx={{ backgroundColor: "#1e2832" }}>
                    <Box sx={{ p: 2, backgroundColor: "#161e25" }}>
                        <DialogTitle color={"white"}>An unexpected error occurred</DialogTitle>
                        <DialogContent>
                            <Typography color={"white"}>
                                We're sorry, there was an unexpected error. Please refresh the page to continue
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => window.location.reload()}>
                                Refresh Application
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
