import { convertToHex } from "./convertToHex";

export const makeRandomKey = (length: number) => {
    let result = "";
    const characters = "abcdefABCDEF0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    const convertedresult = convertToHex(result);
    return convertedresult;
};
