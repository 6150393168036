import { RootStore } from "./rootStore";
import { action, makeObservable, observable } from "mobx";
import { IAlertsListResponse, IChanDestResponse } from "api";

export default class AlertStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable alertsOverview: IAlertsListResponse | null = null;
    @observable inputAndOutputs: IChanDestResponse | null = null;

    @action setAlertsOverview = (alertsOverview: IAlertsListResponse) => {
        this.alertsOverview = alertsOverview;
    };

    @action setInputAndOutputs = (inputAndOutputs: IChanDestResponse) => {
        this.inputAndOutputs = inputAndOutputs;
    };
}
