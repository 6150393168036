import React, { FC } from "react";

interface ErrorMessagesProps {
    errors: { [key: string]: string } | string | undefined;
    hideTitle?: boolean;
}

const ErrorMessages: FC<ErrorMessagesProps> = ({ errors, hideTitle }) => {
    if (!errors) {
        return null;
    }

    let errorList: string[];

    if (typeof errors === "string") {
        errorList = [errors];
    } else {
        errorList = Object.values(errors);
    }

    if (errorList.length === 0) {
        return null;
    }

    return (
        <div
            style={{
                padding: 15,
                backgroundColor: "rgba(159, 58, 56, 0.9)",
                border: "1px solid rgba(159, 58, 56, 1)",
                borderRadius: 4,
                color: "#fff"
            }}>
            {!hideTitle && <div style={{ fontWeight: 600, fontSize: "15px", marginBottom: 10 }}>An Error Occurred</div>}
            <ul style={{ fontSize: "13px", margin: 0, paddingLeft: "15px" }}>
                {errorList.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>
        </div>
    );
};

export default ErrorMessages;
