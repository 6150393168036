import { SelectField } from "components/Forms";
import React from "react";
import * as Bitrates from "./AudioBitrates";
import * as SampleRates from "./AudioSampleRates";
import { useFormState } from "react-final-form";

interface AudioTrackVariantProps {
    audioTrackIndex: number;
    index: number;
    name: string;
    onFieldChange: (field: string, value: any) => void;
}

const AudioTrackVariant: React.FC<AudioTrackVariantProps> = ({ audioTrackIndex, index, name, onFieldChange }) => {
    const { values } = useFormState();

    return (
        <>
            <SelectField
                name={`${name}.sample_rate`}
                label="Sample Rate"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}
                options={(function () {
                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "MPEG AAC") {
                        return SampleRates.MPEGAAC.map((sampleRate) => ({
                            text: sampleRate,
                            value: sampleRate
                        }));
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "MPEG Layer2") {
                        return SampleRates.MPEGLayer2.map((sampleRate) => ({
                            text: sampleRate,
                            value: sampleRate
                        }));
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby Digital") {
                        return SampleRates.DolbyDigital.map((sampleRate) => ({
                            text: sampleRate,
                            value: sampleRate
                        }));
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby Digital Plus") {
                        return SampleRates.DolbyDigital.map((sampleRate) => ({
                            text: sampleRate,
                            value: sampleRate
                        }));
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby AC-4") {
                        return SampleRates.DolbyDigital.map((sampleRate) => ({
                            text: sampleRate,
                            value: sampleRate
                        }));
                    }

                    return [];
                })()}
                onChange={(field, value) => {
                    onFieldChange(field, value);
                }}
            />

            <SelectField
                name={`${name}.bitrate`}
                label="Bitrate"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}
                onChange={(field, value) => {
                    onFieldChange(field, value);
                }}
                options={(function () {
                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "MPEG AAC") {
                        return Bitrates.MPEGAAC.map((bitrate) => ({
                            text: bitrate,
                            value: bitrate
                        }));
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "MPEG Layer2") {
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "Mono") {
                            return Bitrates.MPEGLayer2Mono.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "Stereo") {
                            return Bitrates.MPEGLayer2Stereo.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby Digital") {
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "1.0") {
                            return Bitrates.DolbyDigital1.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "2.0") {
                            return Bitrates.DolbyDigital2.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "5.1") {
                            return Bitrates.DolbyDigital51.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby Digital Plus") {
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "1.0") {
                            return Bitrates.DolbyDigitalPlus1.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "2.0") {
                            return Bitrates.DolbyDigitalPlus2.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "5.1") {
                            return Bitrates.DolbyDigitalPlus51.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "5.1.4") {
                            return Bitrates.DolbyDigitalPlus514.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "7.1.4") {
                            return Bitrates.DolbyDigitalPlus714.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                    }

                    if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby AC-4") {
                        if (values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "Stereo") {
                            return Bitrates.DolbyAC4Stereo.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                        if (
                            values.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout === "Surround 5.1"
                        ) {
                            return Bitrates.DolbyAC4Surround51.map((bitrate) => ({
                                text: bitrate,
                                value: bitrate
                            }));
                        }
                    }

                    return [];
                })()}
            />
        </>
    );
};

export default AudioTrackVariant;
