import React from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "api";
import { Table, TableBody } from "@mui/material";
import TranscoderListItem from "./TranscoderListItem";

interface Props {
    source_id: string;
    environment_id: string;
}

const TranscoderList: React.FC<Props> = ({ source_id, environment_id }) => {
    const rootStore = React.useContext(RootStoreContext);

    const { user } = rootStore.userStore;

    const { client } = rootStore.resClientStore;

    const [data, setData] = React.useState<any>([]);

    const [, setLoading] = React.useState<boolean>(false);

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    React.useEffect(() => {
        let resData: any = {};

        client.connected &&
            client
                .get(`environments.source_transcoders.${user?.managing_organisation_id}.${environment_id}.${user?.active_org}.${source_id}`)
                .then((res) => {
                    console.log("Transcoders", res);

                    resData = res;

                    res.on("change", (data) => {
                        console.log("change", data, res);
                        setUpdateDate(new Date());
                        setData(res.toJSON());
                    });

                    const jsonObject = res.toJSON();
                    setData(jsonObject);

                    setLoading(false);
                })
                .catch((err) => {
                    console.log("Error fetching transcoders", err.message);
                });

        return () => {
            setData([]);
        };
    }, [client]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <Table>
            <TableBody>
                {data &&
                    Object.entries(data)
                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort entries by key
                        .map(([key, source]: [string, any]) => <TranscoderListItem key={key} rid={source.rid} />)}
            </TableBody>
        </Table>
    );
};

export default observer(TranscoderList);
