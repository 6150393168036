import { RootStore } from "./rootStore";
import { action, observable, makeObservable } from "mobx";
import { IInputs, IInputImageData, IInputLiveStatic, IInputFormValues, TranscoderPresetsResult } from "api";

export default class SourcesStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable Inputs: IInputs | null = null;
    @observable input: IInputFormValues | null = null;
    @observable EnvironmentInputs: IInputs | null = null;
    @observable currentImageData: IInputImageData | null = null;
    @observable currentLiveStats: IInputLiveStatic | null = null;
    @observable inputRegistry = new Map();
    @observable submitting = false;
    @observable transcoder: any = null;
    @observable transcoderData: any = null;
    @observable presets: TranscoderPresetsResult | null = null;

    @action setTranscoder = (transcoder: any) => {
        this.transcoder = transcoder;
    };

    @action setTranscoderData = (transcoderData: any) => {
        this.transcoderData = transcoderData;
    };

    @action setPresets = (presets: TranscoderPresetsResult) => {
        this.presets = presets;
    };

    @action updateInputRegistry = (input: IInputFormValues) => {
        this.inputRegistry.set(input.id, input);
    };

    @action setInputs = (inputs: IInputs) => {
        this.Inputs = inputs;
    };

    @action setEnvironmentInputs = (inputs: IInputs) => {
        this.EnvironmentInputs = inputs;
    };

    @action setCurrentImageData = (imageData: IInputImageData) => {
        this.currentImageData = imageData;
    };

    @action setInputAnalysisData = (inputAnalysis: any) => {
        this.currentLiveStats = inputAnalysis;
    };

    @action setInput = (input: IInputFormValues) => {
        this.input = input;
    };
}
