import { IconName } from "@fortawesome/pro-light-svg-icons";
import { AlertColor } from "@mui/material";

export interface IKnowledgeHubArticleShort {
    id: string;
    category: string;
    title: string;
    icon?: IconName;
    permissions?: string[];
    client?: string;
    description?: string;
    dateCreated?: string;
    dateUpdated?: string;
    type?: KnowledgeHubType;
    sections?: any[];
    tags?: string[];
    order?: number;
    roles?: string[];
    status?: KnowledgeHubItemStatus;
    isIndexable?: boolean;
    lastModifiedBy?: string;
    moduleAccess?: string;
    showInCategory?: boolean;
}

export interface IGetKnowledgeHubResponse {
    message: string;
    status: string;
    articles: IKnowledgeHubArticleShort[];
}

export interface IKnowledgeAccordion {
    title: string;
    content: string;
}

export interface IKnowledgeRelatedArticlesItem {
    id: string;
    order: string;
}

export interface IKnowledgeRelatedArticles {
    title?: string;
    items: IKnowledgeRelatedArticlesItem[];
}

export interface IKnowledgeSectionAlert {
    title?: string;
    content?: string;
    type: AlertColor;
}

export interface IKnowledgeSection {
    title: string;
    description?: string;
    content: string;
    image?: string;
    video?: string;
    accordions?: IKnowledgeAccordion[];
    relatedArticles?: IKnowledgeRelatedArticles;
    alerts?: IKnowledgeSectionAlert[];
}

export const enum KnowledgeHubSelectorType {
    Homepage = "Homepage",
    Category = "Category",
    Item = "Item"
}

export enum KnowledgeHubType {
    Article = "article",
    Category = "category"
}

export enum KnowledgeHubItemStatus {
    Published = "published",
    Draft = "draft",
    Archived = "archived"
}

export enum ClientType {
    Default = "default",
    Ateme = "ateme"
}

export interface IKnowledgeHubTag {
    title: string;
    value: string;
}

export interface IKnowledgeItem {
    id: string;
    title: string;
    description?: string;
    icon?: IconName;
    image?: string;
    video?: string;
    tags?: string[];
    isNumbered?: boolean;
    dateCreated: Date;
    dateUpdated: Date;
    type: KnowledgeHubType;
    sections: IKnowledgeSection[];
    permissions?: string[];
    client?: ClientType;
    category?: string;
}

export interface IKnowledgeItemTopic {
    id: string;
    title: string;
    description?: string;
    icon?: string;
    permissions?: string[];
    client?: ClientType;
    items?: IKnowledgeItem[];
}

export interface IKnowledgeHubHistory {
    id: string;
    type: KnowledgeHubSelectorType;
}
