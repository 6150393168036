import { Box } from "@mui/material";
import { SliderField } from "components/Forms";
import React from "react";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import ContrastIcon from "@mui/icons-material/Contrast";
import PaletteIcon from "@mui/icons-material/Palette";
import CheckboxField from "components/Forms/CheckboxField";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import { useFormState } from "react-final-form";
import OnChange from "components/FinalFormListener/OnChange";
import { FormApi } from "final-form";
import { pruneEmptyErrors } from "features/common/PruneEmptyErrors";

interface Props {
    values: any;
    form: FormApi;
}

export const ColorEnhancementForm: React.FC<Props> = ({ values, form }) => {
    const { submitErrors } = useFormState();
    return (
        <Box>
            <CheckboxField
                name="color_enhancement.enabled"
                label="Enable Color Enhancement"
                sx={{ mb: 2 }}
                initialValue={values.color_enhancement?.enabled ? true : false}
            />
            <OnChange name={"color_enhancement.enabled"}>
                {() => {
                    if (submitErrors?.color_enhancement?.enabled) {
                        delete submitErrors.color_enhancement.enabled;
                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                    }
                }}
            </OnChange>
            {values.color_enhancement?.enabled && (
                <>
                    <SliderField
                        name="color_enhancement.hue"
                        label="Hue"
                        min={-100}
                        max={100}
                        step={1}
                        initialValue={values.color_enhancement?.hue || 0}
                        icon={<PaletteIcon />}
                        validate={(value: any) => {
                            if (!value && value !== 0) {
                                return "Required";
                            }
                            if (value < -100 || value > 100) {
                                return "Value must be an integer between -100 and 100";
                            }
                        }}
                    />
                    <SliderField
                        name="color_enhancement.saturation"
                        label="Saturation"
                        min={-100}
                        max={100}
                        step={1}
                        initialValue={values.color_enhancement?.saturation || 0}
                        icon={<InvertColorsIcon />}
                        validate={(value: any) => {
                            if (!value && value !== 0) {
                                return "Required";
                            }
                            if (value < -100 || value > 101) {
                                return "Value must be an integer between -100 and 100";
                            }
                        }}
                    />
                    <SliderField
                        name="color_enhancement.brightness"
                        label="Brightness"
                        min={-100}
                        max={100}
                        step={1}
                        initialValue={values.color_enhancement?.brightness || 0}
                        icon={<Brightness6Icon />}
                        validate={(value: any) => {
                            if (!value && value !== 0) {
                                return "Required";
                            }
                            if (value < -100 || value > 100) {
                                return "Value must be an integer between -100 and 100";
                            }
                        }}
                    />
                    <SliderField
                        name="color_enhancement.contrast"
                        label="Contrast"
                        min={-100}
                        max={100}
                        step={1}
                        initialValue={values.color_enhancement?.contrast || 0}
                        icon={<ContrastIcon />}
                        validate={(value: any) => {
                            if (!value && value !== 0) {
                                return "Required";
                            }
                            if (value < -100 || value > 100) {
                                return "Value must be an integer between -100 and 100";
                            }
                        }}
                    />
                </>
            )}
        </Box>
    );
};

export default ColorEnhancementForm;
