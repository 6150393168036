import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface IProps {
    header: string;
    body: any;
}

export const PermanentScheduleSuccessMessage: React.FC<IProps> = ({ header, body }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>{header}</DialogTitle>
            <DialogContent>
                <Typography>{body}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};
