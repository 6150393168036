import React from "react";
import { Alert, Typography } from "@mui/material";

export const CommitmentTermSettings = () => {
    return (
        <>
            <Typography variant="h5">Commitment Term Settings</Typography>
            <Alert severity="info" sx={{ my: 1 }}>
                To access 24/7 permanently scheduled services please apply to pay by invoice or contact your account manger.
            </Alert>
        </>
    );
};
