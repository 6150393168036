import { Alert, Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Typography } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext } from "react";

interface ConfirmDisableOwnCloudProps {
    cloud_provider: string;
    organisation_id: number;
    organisation_name: string;
    onSuccessfulDisable?: () => void;
}

const ConfirmDisableOwnCloud: React.FC<ConfirmDisableOwnCloudProps> = ({ cloud_provider, organisation_id, organisation_name, onSuccessfulDisable }) => {

    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const [error, setError] = React.useState<any>(null);

    const handleDisableOwnCloud = async () => {
        await agent.OwnCloud.adminDisableOwnCloud({ organisation_id: organisation_id, cloud_provider: cloud_provider })
            .then(() => {
                //close dialog
                onSuccessfulDisable && onSuccessfulDisable();
                closeDialog();
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    You are about to disable Own Cloud for {organisation_name} on cloud provider <span style={{textTransform: "uppercase"}}>{cloud_provider}</span>. Are you sure you want to proceed?
                </Typography>
                {error && <Alert severity="error" sx={{mt: 2}}>{error.data.message}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button variant="contained" color="error" onClick={handleDisableOwnCloud}>
                    Disable Own Cloud
                </Button>
            </DialogActions>
        </>
    );
}

export default ConfirmDisableOwnCloud;