import { Box, Card, CardActionArea, CardActions, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import StopIcon from "@mui/icons-material/Stop";
import AddTaskIcon from "@mui/icons-material/AddTask";
import InfoIcon from "@mui/icons-material/Info";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { ConfirmDestinationStop } from "features/destinations/destinationStart-Stop/ConfirmDestinationStop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ConfirmDestinationStart } from "features/destinations/destinationStart-Stop/ConfirmDestinationStart";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import PermanentScheduleForm from "features/destinations/destinationScheduler/destinationScheduleForm/PermanentScheduleForm";
import { RootStoreContext } from "api";
import { ResCollection, ResModel } from "resclient";
import DestinationDetails from "features/destinations/Components/DestinationDetails";
import CreateMultiScheduleForm from "../../Components/CreateMultiScheduleForm";
import OrganisationRoleProvider from "components/OrganisationRoleProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { Difference } from "@mui/icons-material";

interface ScheduleRowStartProps {
    zoom: number;
    hour: number;
    destination_id: string;
    destination_name: string;
    isPermanent?: boolean;
    connection_status: string;
    permanent_schedule_input_id?: string;
    permanent_schedule_input_name?: string;
    active_alerts?: number;
}

/**
 * Details and controls for a single destination in a schedule row.
 * @param {ScheduleRowStartProps} props - The props for the ScheduleRowStart component.
 * @param {number} props.zoom - The zoom factor for the ScheduleRowStart component.
 * @param {number} props.hour - The hour the ScheduleRowStart represents.
 * @param {string} props.destination_id - The id of the destination the ScheduleRowStart represents.
 * @param {string} props.destination_name - The name of the destination the ScheduleRowStart represents.
 * @param {boolean} [props.isPermanent] - Optional. Determines if the ScheduleRowStart represents permanent schedules.
 * @param {string} props.connection_status - The status of the connection the ScheduleRowStart represents.
 * @param {string} [props.permanent_schedule_input_id] - Optional. The id of the input representing permanent schedules.
 * @param {string} [props.permanent_schedule_input_name] - Optional. The name of the input representing permanent schedules.
 * @param {number} [props.active_alerts] - Optional. The number of active alerts for the destination.
 * @returns {React.FC<ScheduleRowStartProps>} - Returns a ScheduleRowStart component.
 * @example
 * <ScheduleRowStart zoom={1} hour={0} destination_id={"123"} destination_name={"123"} connection_status={"connected"}>
 */

const ScheduleRowStart: React.FC<ScheduleRowStartProps> = (props) => {
    const theme = useTheme();
    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog } = rootStore.dialogStore;
    const [sourceWsData, setSourceWsData] = React.useState<ResModel | ResCollection | any>({});
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const navigate = useNavigate();
    const history = useLocation();

    React.useEffect(() => {
        const rid = `environments.sources.${rootStore.userStore.managingOrganisationId}.${rootStore.userStore.activeEnvironment}.${rootStore.userStore.activeorganisationId}.source.${props.permanent_schedule_input_id}`;

        props.permanent_schedule_input_id && rootStore.resClientStore.clientConnected
            ? rootStore.resClientStore.client.get(rid).then((response) => {
                  response.on("change", () => {
                      setSourceWsData(response);
                      setUpdateDate(new Date());
                  });
                  setSourceWsData(response);
              })
            : null;

        return () => {
            setSourceWsData({});
        };
    }, [
        props.permanent_schedule_input_id,
        rootStore.userStore.activeEnvironment,
        rootStore.userStore.activeorganisationId,
        rootStore.userStore.managingOrganisationId
    ]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <Box
            id={props.destination_id}
            sx={{
                height: "100%",
                width: "300px",
                backgroundColor: theme.palette.scheduleRowStart?.main,
                position: "sticky",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "stretch",
                left: 0,
                flexShrink: 0,
                flexGrow: 0,
                flexBasis: "300px",
                zIndex: 2,
                borderLeftWidth: "4px",
                borderLeftStyle: "solid",
                borderLeftColor:
                    props.connection_status === "connecting"
                        ? theme.palette.warning.main
                        : props.connection_status === "stopped"
                        ? theme.palette.error.main
                        : props.connection_status === "connected"
                        ? theme.palette.success.main
                        : "transparent"
            }}>
            <Card
                sx={{
                    backgroundColor: props.active_alerts && props.active_alerts > 0 ? theme.palette.error.dark : "transparent",
                    borderRadius: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column"
                }}>
                <CardActionArea
                    sx={{
                        padding: 1,
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        flexShrink: 0,
                        flexBasis: "auto"
                    }}
                    onClick={() => {
                        openDrawer(
                            <Box sx={{ width: 680, padding: 3, height: "100%" }}>
                                <DestinationDetails destination_id={props.destination_id} />
                            </Box>
                        );
                    }}>
                    <Typography
                        component={Box}
                        sx={{ fontWeight: 600, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {props.destination_name}
                    </Typography>
                    <Typography
                        component={Box}
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "capitalize",
                            marginBottom: "20px"
                        }}>
                        {props.connection_status}
                    </Typography>
                </CardActionArea>
                <CardActions>
                    <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexGrow: 0,
                            flexShrink: 1,
                            flexBasis: "auto",
                            opacity: 0.7
                        }}>
                        {props.active_alerts && props.active_alerts > 0 ? (
                            <Tooltip title={props.active_alerts + " Active Alert(s)"} placement="bottom">
                                <IconButton
                                    size="small"
                                    aria-label="alerts"
                                    onClick={() => {
                                        openDrawer(
                                            <Box sx={{ width: 680, height: "100%", padding: 3 }}>
                                                <DestinationDetails destination_id={props.destination_id} tab="2" />
                                            </Box>
                                        );
                                    }}>
                                    <NotificationsActiveIcon fontSize="small" className="pulse" />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                        <OrganisationRoleProvider roles={["admin", "operator"]}>
                            {!props.isPermanent ? (
                                <Tooltip title="Schedule Source" placement="bottom">
                                    <IconButton
                                        size="small"
                                        aria-label="schedule"
                                        onClick={() => {
                                            openDialog(
                                                <CreateMultiScheduleForm destination_id={props.destination_id} />,
                                                "Create Schedule",
                                                "lg",
                                                true
                                            );
                                        }}>
                                        <AddTaskIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Change Source" placement="bottom">
                                    <IconButton
                                        size="small"
                                        aria-label="schedule"
                                        onClick={() => {
                                            openDialog(
                                                <Box>
                                                    <PermanentScheduleForm
                                                        id={props.destination_id}
                                                        permanent_schedule_input_id={props.permanent_schedule_input_id!}
                                                        permanent_schedule_input_name={props.permanent_schedule_input_name!}
                                                    />
                                                </Box>
                                            );
                                        }}>
                                        <SwitchVideoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.connection_status !== "stopped" ? (
                                <Tooltip title="Stop Destination" placement="bottom">
                                    <IconButton
                                        size="small"
                                        aria-label="schedule"
                                        onClick={() =>
                                            openDialog(
                                                <Box>
                                                    <ConfirmDestinationStop
                                                        destination_id={props.destination_id}
                                                        destination_name={props.destination_name}
                                                    />
                                                </Box>
                                            )
                                        }>
                                        <StopIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Start Destination" placement="bottom">
                                    <IconButton
                                        size="small"
                                        aria-label="schedule"
                                        onClick={() =>
                                            openDialog(
                                                <Box>
                                                    <ConfirmDestinationStart
                                                        destination_id={props.destination_id}
                                                        destination_name={props.destination_name}
                                                    />
                                                </Box>
                                            )
                                        }>
                                        <PlayArrowIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Edit Destination" placement="bottom">
                                <IconButton
                                    size="small"
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(`/destinations/edit/${props.destination_id}`, { state: { returnUrl: history.pathname } })
                                    }}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </OrganisationRoleProvider>
                        <Tooltip title="Duplicate Destination" placement="bottom">
                            <IconButton
                                size="small"
                                aria-label="edit"
                                onClick={() => {
                                    navigate(`/destinations/duplicate/${props.destination_id}`, { state: { returnUrl: history.pathname } })
                                }}>
                                <Difference fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Destination Information" placement="bottom">
                            <IconButton
                                size="small"
                                aria-label="edit"
                                onClick={() => {
                                    openDrawer(
                                        <Box sx={{ width: 680, padding: 3, height: "100%" }}>
                                            <DestinationDetails destination_id={props.destination_id} />
                                        </Box>
                                    );
                                }}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </CardActions>
            </Card>

            {props.isPermanent && (
                <Box
                    sx={{
                        position: "absolute",
                        height: "100%",
                        top: 0,
                        left: "100%",
                        paddingTop: "18px",
                        paddingLeft: 1,
                        pointerEvents: "none"
                    }}>
                    <Typography
                        component={Box}
                        variant="body2"
                        color="text.secondary"
                        sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        Permanent Schedule
                    </Typography>
                    <Typography
                        component={Box}
                        sx={{
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            marginRight: "15px"
                        }}>
                        {sourceWsData && (
                            <>
                                <span
                                    title={sourceWsData.connection_status}
                                    style={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: "50%",
                                        backgroundColor:
                                            sourceWsData.connection_status === "connected" ||
                                            sourceWsData.connection_status === "no-thumb-avail"
                                                ? theme.palette.success.light
                                                : sourceWsData.connection_status === "connecting"
                                                ? theme.palette.warning.main
                                                : theme.palette.error.main,
                                        display: "inline-block"
                                    }}></span>
                                &nbsp;
                            </>
                        )}
                        {props.permanent_schedule_input_name}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ScheduleRowStart;
