import { Alert, Box, Button, Card, CardContent, FormControlLabel, FormLabel, Grid, Stack, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { IEnvironment, RootStoreContext, agent } from "api";
import ConfirmationDialog from "components/ConfirmationDialog";
import InfoItemVertical from "components/InfoItemVertical";
import { format } from "date-fns";
import React from "react";

interface EnvironmentUpdateProps {
    environment: IEnvironment;
    onUpdate: () => void;
}

const EnvironmentUpdate: React.FC<EnvironmentUpdateProps> = ({ environment, onUpdate }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date(environment.sched_upgrade_start_time!));
    return (
        <Grid container>
            <Grid item xs={12} pb={4}>
                <Typography variant="body2">
                    Update your environment to the latest version. You can schedule an update or update immediately.
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}>
                    <Typography variant="h6" mb={3}>
                        Current Schedule
                    </Typography>
                    <FormLabel sx={{ fontSize: 11 }}>Update Start Time</FormLabel>
                    <Typography mb={1}>
                        {environment.sched_upgrade_start_time
                            ? format(new Date(environment.sched_upgrade_start_time), "dd/MM/yyyy HH:mm:ss")
                            : "N/A"}
                    </Typography>
                    <FormLabel sx={{ fontSize: 11 }}>Update Scheduled Type</FormLabel>
                    <Typography mb={1}>
                        {environment.sched_upgrade_scheduling_type
                            ? environment.sched_upgrade_scheduling_type === "system_scheduled"
                                ? "System Scheduled"
                                : "User Scheduled"
                            : "N/A"}
                    </Typography>
                    <FormLabel sx={{ fontSize: 11 }}>Scheduled By User</FormLabel>
                    <Typography>{environment.sched_upgrade_updated_by_user_email || "N/A"}</Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}>
                    <Typography variant="h6">Schedule Update</Typography>
                    <Typography variant="body2" color="textSecondary" mb={3}>
                        Schedule an update to the latest version.
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            sx={{ width: 200, mb: 3 }}
                            label="Schedule Update"
                            onChange={(date) => setUpdateDate(date as Date)}
                            value={updateDate}
                            format="dd/MM/yyyy HH:mm"
                        />
                    </LocalizationProvider>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            openDialog(
                                <ConfirmationDialog
                                    title="Schedule Update"
                                    message={`Are you sure you want to schedule an update for environment ${environment.name} on ${format(
                                        updateDate,
                                        "dd/MM/yyyy @ HH:mm"
                                    )}?
                                        `}
                                    confirmButtonText="Schedule Update"
                                    onConfirm={async () => {
                                        await agent.Environment.upgradeEnviroment(String(environment.sched_upgrade_id), {
                                            product_id: environment.sched_upgrade_product_id,
                                            upgrade_now: false,
                                            upgrade_start_time: updateDate.toISOString(),
                                            region: environment.region
                                        });
                                    }}
                                    onSuccess={() => onUpdate()}
                                />
                            );
                        }}>
                        Schedule Update
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}></Box>
            </Grid>
            <Grid item xs={4}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}>
                    <Typography variant="h6">Update Now</Typography>
                    <Typography variant="body2" color="textSecondary" mb={3}>
                        Update your environment to the latest version immediately.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            openDialog(
                                <ConfirmationDialog
                                    title="Update Environment"
                                    message={`Are you sure you want to update environment ${environment.name} now?`}
                                    additionalContent={
                                        <Alert severity="info" sx={{ mt: 2 }}>
                                            Note this may take several minutes to update and service interruption is likely. Please be
                                            patient, you will be notified once the update is complete.
                                        </Alert>
                                    }
                                    confirmButtonText="Update Environment"
                                    onConfirm={async () => {
                                        await agent.Environment.upgradeEnviroment(String(environment.sched_upgrade_id), {
                                            product_id: environment.sched_upgrade_product_id,
                                            upgrade_now: true,
                                            region: environment.region
                                        });
                                    }}
                                />
                            );
                        }}>
                        Update Now
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default EnvironmentUpdate;
