import { Button } from "@mui/material";
import React, { useCallback } from "react";

interface Props {
    data: object;
}

const DownloadButton: React.FC<Props> = ({ data }) => {
    const handleClick = useCallback(() => {
        const jsonData = JSON.stringify(data, null, 2); // Adding the second argument to JSON.stringify to format the JSON.
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(jsonData);
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "tracereport.json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }, [data]);

    return (
        <Button onClick={handleClick} variant="contained">
            Download Report
        </Button>
    );
};

export default DownloadButton;
