import { Alert, Button, DialogActions, DialogContent } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext } from "react";

interface ConfirmUpdateRegionsProps {
    cloud_provider: string;
    selectedRegions: string[];
}

const ConfirmUpdateRegions: React.FC<ConfirmUpdateRegionsProps> = ({ cloud_provider, selectedRegions }) => {
    const [error, setError] = React.useState<any>(null);

    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const updateRegions = async () => {
        await agent.OwnCloud.setRegions({
            aws: selectedRegions
        }, cloud_provider)
            .then((response) => {
                console.log(response);
                setError(null);
                //reload page
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                return setError(error?.data?.message || 'An error occurred');
            });
    };

    return (
        <>
            <DialogContent>
                Are you sure you want to update the regions?
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        updateRegions();
                    }}>
                    Update regions
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmUpdateRegions;
