import { Delete, ManageSearch } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Checkbox, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { agent, IGetRegionsResponse, RootStoreContext } from "api";
import Content from "components/Layout/Content";
import React, { useContext, useEffect } from "react";
import ConfirmUpdateRegions from "./Components/ConfirmUpdateRegions";

const SetOwnCloudRegions: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const [regions, setRegions] = React.useState<IGetRegionsResponse[]>([]);
    const [selectedRegions, setSelectedRegions] = React.useState<string[]>([]);
    const [initSelectedRegions, setInitSelectedRegions] = React.useState<string[]>([]);
    const [submitting, setSubmitting] = React.useState(false);
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const getRegions = async () => {
        await agent.OwnCloud.getRegions('aws')
            .then((response) => {
                setRegions(response.resources);
                
                let initRegions: string[] = [];
                for (let key in response.resources) {
                    if (response.resources.hasOwnProperty(key)) {
                        if (response.resources[key].own_cloud_enabled) {
                            initRegions.push(key);
                        }
                    }
                }

                setSelectedRegions(initRegions);
                setInitSelectedRegions(initRegions);
            })
            .catch((error) => {
                console.log(error);
                return setError(error?.data?.message || 'An error occurred');
            });
    };

    useEffect(() => {
        getRegions();
    }, []);

    const updateRegions = async () => {
        openDialog(<ConfirmUpdateRegions cloud_provider="aws" selectedRegions={selectedRegions} />);
    };

    useEffect(() => {
        const initSelectedRegionsString = JSON.stringify(initSelectedRegions);
        const selectedRegionsString = JSON.stringify(selectedRegions);

        if (initSelectedRegionsString !== selectedRegionsString) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [initSelectedRegions, selectedRegions]);

    return (
        <Content
            footerContent={
                <Stack spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
                    <LoadingButton
                        loading={submitting}
                        disabled={!canSubmit}
                        variant="contained"
                        onClick={updateRegions}
                    >
                        Save Changes
                    </LoadingButton>
                </Stack>
            }
        >
            {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )}

            <Table>
                <TableHead>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>Customer Environment</TableCell>
                    <TableCell>Transcoder</TableCell>
                    <TableCell>Push Packager</TableCell>
                    <TableCell>Pull Packager</TableCell>
                    <TableCell>Multi Viewers</TableCell>
                    <TableCell>Nea Live</TableCell>
                    <TableCell>TF Deployments</TableCell>
                    {/* <TableCell></TableCell> */}
                </TableHead>
                <TableBody>
                    {regions &&
                        Object.keys(regions).map((regionKey) => (
                            <TableRow
                                key={regionKey}
                                sx={{
                                    backgroundColor: regions[regionKey].own_cloud_enabled ? theme => theme.palette.action.selected : 'transparent'
                                }}
                            >
                                <TableCell>
                                    <Checkbox
                                        checked={selectedRegions.includes(regionKey)}
                                        disabled={regions[regionKey].has_resources && selectedRegions.includes(regionKey)}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelectedRegions([...selectedRegions, regionKey]);
                                            } else {
                                                setSelectedRegions(selectedRegions.filter((region) => region !== regionKey));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell>{regionKey}</TableCell>
                                <TableCell>{regions[regionKey].CustomerEnvironment}</TableCell>
                                <TableCell>{regions[regionKey].Transcoder}</TableCell>
                                <TableCell>{regions[regionKey].PushPackager}</TableCell>
                                <TableCell>{regions[regionKey].PullPackager}</TableCell>
                                <TableCell>{regions[regionKey].MultiViewers}</TableCell>
                                <TableCell>{regions[regionKey].NeaLive}</TableCell>
                                <TableCell>{regions[regionKey].TFDeployments}</TableCell>
                                {/* <TableCell>
                                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                                        <Tooltip title="View Metrics">
                                            <IconButton>
                                                <ManageSearch />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell> */}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Content>
    );
};

export default SetOwnCloudRegions;