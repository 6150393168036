import {
    Box,
    Button,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography
} from "@mui/material";
import { IEnvironment, IEnvironments, RootStoreContext, agent } from "api";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Resizable } from "react-resizable";
import { AddCircle, DragHandle, Lan } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EnvironmentMetrics from "./Components/EnvironmentMetrics";
import EnvironmentsItem from "./Components/EnvironmentsItem";
import { observer } from "mobx-react-lite";
import EnvironmentDetails from "./Components/EnvironmentDetails";
import EnvironmentActions from "./Components/EnvironmentActions";
import EnvironmentUpdate from "./Components/EnvironmentUpdate";
import IpAddressTable from "./Components/IpAddressTable";
import EnvironmentActivity from "./Components/EnvironmentActivity";
import EnvironmentResources from "./Components/EnvironmentResources";

const Environments: React.FC = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected, initialize } = rootStore.resClientStore;
    const { openDialog } = rootStore.dialogStore;

    const userActiveEnvironment = rootStore.userStore.activeEnvironment;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_org_id = rootStore.userStore.activeOrganisation

    const [environments, setEnvironments] = React.useState<IEnvironments>();
    const [activeEnvironment, setActiveEnvironment] = React.useState<IEnvironment>();

    const [footerHeight, setFooterHeight] = React.useState(480);
    const [initialHeight, setInitialHeight] = React.useState<number>(480);
    const [initialY, setInitialY] = React.useState(null);
    const [collapseFooter, setCollapseFooter] = React.useState<boolean>(false);

    const [activeTab, setActiveTab] = React.useState("1");
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
        setCollapseFooter(false);
    };

    const [sortBy, setSortBy] = React.useState("name:asc");

    const [updateDate, setUpdateDate] = React.useState<Date>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const getEnvironments = async () => {
        await agent.Environment.getEnvironments()
            .then((response) => {
                setEnvironments(response);

                // if (activeEnvironment) {
                //     const active = response.data.find((env) => env.id === activeEnvironment.id);
                //     setActiveEnvironment(active);
                // }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getEnvironments();
    }, [userActiveEnvironment]);

    React.useEffect(() => {
        setLoading(true);
        const fetchData = () => {
            client
                .get(`environments_v2.environments.${managing_org_id}.${active_org_id}`)
                .then((res) => {
                    //console.log("wsData", res.toJSON());
                    //setData(res.toJSON());
                    setLoading(false);
                    res.on("change", () => {
                        getEnvironments();
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        };

        if (clientConnected) {
            fetchData();
        } else {
            const intervalId = setInterval(() => {
                if (clientConnected) {
                    clearInterval(intervalId);
                    fetchData();
                }
            }, 1000);
        }

        !clientConnected && initialize(rootStore.commonStore.token!);
    }, [client, clientConnected, managing_org_id, rootStore.commonStore.token]);

    React.useEffect(() => {}, [updateDate]);

    React.useEffect(() => {
        //When environments changes, check if activeEnvironment is still in the list, if not, set activeEnvironment to undefined. if it is, update the activeEnvironment
        if (activeEnvironment) {
            const active = environments?.data.find((env) => env.id === activeEnvironment.id);
            if (!active) {
                setActiveEnvironment(undefined);
            } else {
                setActiveEnvironment(active);
            }
        }
    }, [environments]);

    React.useEffect(() => {
        if (id && !activeEnvironment) {
            const active = environments?.data.find((env) => env.id === id);
            if (active) {
                setActiveEnvironment(active);
                setActiveTab("1");
            }
        }
    }, [id, environments]);


    return (
        <View>
            <ViewHeader title="Environments" />
            <ViewBody noPadding>
                <Content
                    loading={loading}
                    noPadding
                    overflowX="auto"
                    toolbarContent={
                        <Stack width="100%" direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" onClick={() => navigate("/environments/create")} startIcon={<AddCircle />}>
                                Create Environment
                            </Button>
                            <Tooltip title="Manage Reserved IP Addresses">
                                <Button
                                    variant="toolbar"
                                    color="secondary"
                                    startIcon={<Lan />}
                                    onClick={() => {
                                        openDialog(<IpAddressTable />, "IP Addresses", "md", false);
                                    }}>
                                    IP Addresses
                                </Button>
                            </Tooltip>
                        </Stack>
                    }
                    footerContent={
                        activeEnvironment && (
                            <Resizable
                                height={footerHeight}
                                width={0}
                                handle={
                                    <Box
                                        className="handle-n"
                                        sx={{
                                            height: 10,
                                            width: "100%",
                                            cursor: "row-resize",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            display: collapseFooter ? "none" : "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "hidden",
                                            backgroundColor: "rgba(0,0,0,0.1)",
                                            "&:hover": {
                                                backgroundColor: (theme) => theme.palette.action.hover
                                            }
                                        }}>
                                        <DragHandle sx={{ fontSize: 16 }} />
                                    </Box>
                                }
                                handleAxis="y"
                                onResizeStart={(e) => {
                                    setInitialY(e.clientY);
                                    setInitialHeight(footerHeight);
                                }}
                                onResize={(e, data) => {
                                    const currentY = e.clientY;
                                    const differenceY = initialY! - currentY;
                                    const newHeight = initialHeight! + differenceY;

                                    setFooterHeight(newHeight);
                                }}>
                                <Box
                                    sx={{
                                        height: footerHeight,
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        position: "relative",
                                        overflow: "hidden",
                                        pt: "14px"
                                    }}>
                                    <Box
                                        sx={{
                                            px: 2,
                                            py: 2,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                        <Box>
                                            <Typography variant="h5">{activeEnvironment.name}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {activeEnvironment.cloud_provider_human_name} - {activeEnvironment.region}
                                            </Typography>
                                        </Box>
                                        <EnvironmentActions environment={activeEnvironment} onUpdate={() => getEnvironments()} />
                                    </Box>
                                    <TabContext value={activeTab}>
                                        <TabList onChange={handleTabChange}>
                                            <Tab label="Details" value="1" />
                                            {activeEnvironment.cloud_provider === "aws" && (
                                            <Tab label="Monitoring" value="2" />
                                            )}
                                            <Tab label="Activity" value="3" />
                                            {/* <Tab label="Resources" value="4" /> */}
                                            <Tab label="Update" value="5" disabled={!activeEnvironment.is_environment_outdated} />
                                        </TabList>
                                        <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                            <EnvironmentDetails environment={activeEnvironment} />
                                        </TabPanel>
                                        <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                            <Box>
                                                <EnvironmentMetrics environment_id={activeEnvironment?.id} />
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                            <EnvironmentActivity environment={activeEnvironment} />
                                        </TabPanel>
                                        <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                            <EnvironmentResources environment={activeEnvironment} />
                                        </TabPanel>
                                        <TabPanel value="5" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                            <EnvironmentUpdate environment={activeEnvironment} onUpdate={() => getEnvironments()} />
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Resizable>
                        )
                    }>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={39}></TableCell>
                                <TableCell sortDirection={sortBy === "name:asc" ? "asc" : sortBy === "name:desc" ? "desc" : false}>
                                    <TableSortLabel
                                        active={sortBy === "name:asc" || sortBy === "name:desc"}
                                        direction={sortBy === "name:asc" ? "asc" : sortBy === "name:desc" ? "desc" : "asc"}
                                        onClick={() => {
                                            setSortBy(sortBy === "name:asc" ? "name:desc" : "name:asc");
                                        }}>
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortBy === "status:asc" ? "asc" : sortBy === "status:desc" ? "desc" : false}>
                                    <TableSortLabel
                                        active={sortBy === "status:asc" || sortBy === "status:desc"}
                                        direction={sortBy === "status:asc" ? "asc" : sortBy === "status:desc" ? "desc" : "asc"}
                                        onClick={() => {
                                            setSortBy(sortBy === "status:asc" ? "status:desc" : "status:asc");
                                        }}>
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={
                                        sortBy === "cloud_provider_human_name:asc"
                                            ? "asc"
                                            : sortBy === "cloud_provider_human_name:desc"
                                            ? "desc"
                                            : false
                                    }>
                                    <TableSortLabel
                                        active={sortBy === "cloud_provider_human_name:asc" || sortBy === "cloud_provider_human_name:desc"}
                                        direction={
                                            sortBy === "cloud_provider_human_name:asc"
                                                ? "asc"
                                                : sortBy === "cloud_provider_human_name:desc"
                                                ? "desc"
                                                : "asc"
                                        }
                                        onClick={() => {
                                            setSortBy(
                                                sortBy === "cloud_provider_human_name:asc"
                                                    ? "cloud_provider_human_name:desc"
                                                    : "cloud_provider_human_name:asc"
                                            );
                                        }}>
                                        Provider
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortBy === "region:asc" ? "asc" : sortBy === "region:desc" ? "desc" : false}>
                                    <TableSortLabel
                                        active={sortBy === "region:asc" || sortBy === "region:desc"}
                                        direction={sortBy === "region:asc" ? "asc" : sortBy === "region:desc" ? "desc" : "asc"}
                                        onClick={() => {
                                            setSortBy(sortBy === "region:asc" ? "region:desc" : "region:asc");
                                        }}>
                                        Region
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={
                                        sortBy === "instance_size_commercial:asc" ? "asc" : sortBy === "instance_size_commercial:desc" ? "desc" : false
                                    }>
                                    <TableSortLabel
                                        active={sortBy === "instance_size_commercial:asc" || sortBy === "instance_size_commercial:desc"}
                                        direction={
                                            sortBy === "instance_size_commercial:asc" ? "asc" : sortBy === "instance_size_commercial:desc" ? "desc" : "asc"
                                        }
                                        onClick={() => {
                                            setSortBy(sortBy === "instance_size_commercial:asc" ? "instance_size_commercial:desc" : "instance_size_commercial:asc");
                                        }}>
                                        Size (Mbps In/Out)
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={
                                        sortBy === "fully_up_since:asc" ? "asc" : sortBy === "fully_up_since:desc" ? "desc" : false
                                    }>
                                    <TableSortLabel
                                        active={sortBy === "fully_up_since:asc" || sortBy === "fully_up_since:desc"}
                                        direction={
                                            sortBy === "fully_up_since:asc" ? "asc" : sortBy === "fully_up_since:desc" ? "desc" : "asc"
                                        }
                                        onClick={() => {
                                            setSortBy(sortBy === "fully_up_since:asc" ? "fully_up_since:desc" : "fully_up_since:asc");
                                        }}>
                                        Up Since
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {environments?.data
                                ?.sort((a: any, b: any) => {
                                    const [field, order] = sortBy.split(":");
                                    if (a[field] === null && b[field] !== null) {
                                        return order === "asc" ? -1 : 1;
                                    } else if (b[field] === null && a[field] !== null) {
                                        return order === "asc" ? 1 : -1;
                                    } else if (a[field] === null && b[field] === null) {
                                        return 0;
                                    } else {
                                        if (order === "asc") {
                                            return a[field].localeCompare(b[field]);
                                        } else {
                                            return b[field].localeCompare(a[field]);
                                        }
                                    }
                                })
                                .map((environment) => (
                                    <EnvironmentsItem
                                        key={environment.id}
                                        environment={environment}
                                        is_active={activeEnvironment?.id === environment.id}
                                        onClick={(environment) => {
                                            activeEnvironment?.id === environment.id
                                                ? setActiveEnvironment(undefined)
                                                : setActiveEnvironment(environment);
                                            setActiveTab("1");
                                        }}
                                        onUpdate={() => {
                                            getEnvironments();
                                        }}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(Environments);
