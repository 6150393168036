import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SourceThumbnail from "../../sources/Components/SourceThumbnail";
import { ResModel, ResCollection } from "resclient";

type SourceMarkerProps = {
    id: string;
    lat: any;
    lng: any;
    environmentLat: number;
    environmentLng: number;
    hasError?: boolean;
    map: google.maps.Map;
    name: string;
    active?: boolean;
    onClick?: () => void;
    onLineClick?: () => void;
    alerts?: ResModel | ResCollection | any;
    type: string;
}



const SourceMarker: React.FC<SourceMarkerProps> = ({ id, lat, lng, environmentLat, environmentLng, hasError, map, name, active, onClick, onLineClick, type }) => {

    const [pathState, setPathState] = React.useState<google.maps.Polyline>(new google.maps.Polyline({
        path: [{ "lat": environmentLat, "lng": environmentLng }, { "lat": lat, "lng": lng }],
        geodesic: true,
        strokeColor: hasError ? 'crimson' : '#33BD4E',
        strokeOpacity: 1,
        strokeWeight: 2
    }))



    //const activeColor = '#20527e'
    const activeColor = '#307abb'

    React.useEffect(() => {

        pathState.setPath([{ "lat": environmentLat, "lng": environmentLng }, { "lat": lat, "lng": lng }])
        pathState.setOptions({ geodesic: true, strokeColor: active ? activeColor : hasError ? 'crimson' : '#33BD4E', strokeOpacity: 1, strokeWeight: 2 })
        pathState.setMap(map)



        return () => {
            pathState.setMap(null)
            setPathState(new google.maps.Polyline())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng, hasError]);

    React.useEffect(() => {
        if (pathState) {
            pathState.setOptions({ geodesic: true, strokeColor: active ? activeColor : hasError ? 'crimson' : '#33BD4E', strokeOpacity: 1, strokeWeight: 2 })
            pathState.addListener('click', onLineClick ? onLineClick : () => { console.log('no click') });
            pathState.setMap(map)
        }
    }, [pathState, map, hasError, active, onLineClick])



    return (

        <div onClick={onClick} className={active ? "item-marker active" : hasError ? "item-marker has-error" : "item-marker"} title={name}>
            <FontAwesomeIcon icon={["fal", type === 'channel' ? "video" : "broadcast-tower"]} />
            <div className='item-marker-popup'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {type === 'channel' && active && <div style={{ width: '60px', height: '33.75px', borderRadius: '2px', overflow: 'hidden', marginRight: 10 }}>
                        <SourceThumbnail item_id={id} />
                    </div>}

                    <div>
                        <div style={{ fontSize: '12px', fontWeight: 600, whiteSpace: 'nowrap' }}>{name}</div>
                        <div style={{ fontSize: '11px', opacity: "0.8", whiteSpace: 'nowrap' }}>{lat}, {lng}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SourceMarker;