import { Alert } from "@mui/material";
import { RootStoreContext } from "api";
import OnChange from "components/FinalFormListener/OnChange";
import { RadioGroupField } from "components/Forms";
import { useEnvironment } from "hooks";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SelectEnvironmentSize: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const navigate = useNavigate();
    const { setInstanceCode, setContentLoaded, sizes, sizeInfo } = rootStore.environmentStore;
    const { getSizes } = useEnvironment();

    useEffect(() => {
        getSizes()
            .then((response) => {
                console.log(sizes);
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setContentLoaded();
            });
    }, [getSizes, setContentLoaded]);

    const handleChange = (value: any) => {
        setInstanceCode(value);
    };

    return (
        <>
            {sizes && (
                <>
                    <RadioGroupField
                        name="product_id"
                        label="Select Environment Size"
                        hideLabel={true}
                        options={sizes.map((size) => {
                            return { label: size.name, value: size.product_id };
                        })}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        parse
                        error={(meta) => meta.touched && meta.error}
                        helperText={(meta) => (meta.touched && meta.error ? meta.error : "")}
                    />
                    <OnChange name="product_id">
                        {(value) => {
                            handleChange(value);
                        }}
                    </OnChange>
                </>
            )}
            {sizeInfo?.organisation_payment_method === "free_credits" && (
                <Alert severity="info" sx={{ marginTop: 3 }}>
                    You are using free credits, to select a bigger size enviroment please navigate to&nbsp;
                    <a
                        href="/billing"
                        onClick={() => {
                            navigate("/billing");
                        }}
                        style={{ cursor: "pointer" }}>
                        billing
                    </a>
                    &nbsp;to purchase credits or apply for your organisation to pay on account.
                </Alert>
            )}
        </>
    );
};

export default observer(SelectEnvironmentSize);
