import { Delete, ManageSearch, Edit } from "@mui/icons-material";
import { Box, Checkbox, IconButton, Stack, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { agent, IGetLimitsResponseItem, RootStoreContext } from "api";
import Content from "components/Layout/Content";
import React, { useContext, useEffect } from "react";
import ConfirmUpdateLimit from "./Components/ConfirmUpdateLimit";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import ConfirmUpdateBatchLimits from "./Components/ConfirmUpdateBatchLimits";
import ViewLimitMetrics from "./Components/ViewLimitMetrics";

const OwnCloudLimits: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const [limits, setLimits] = React.useState<IGetLimitsResponseItem[]>([]);
    const [selectedLimits, setSelectedLimits] = React.useState<IGetLimitsResponseItem[]>([]);
    const [tabValue, setTabValue] = React.useState("elastic_ips");
    const [securityGroups, setSecurityGroups] = React.useState<IGetLimitsResponseItem[]>([]);
    const [elasticIps, setElasticIps] = React.useState<IGetLimitsResponseItem[]>([]);
    const [internetGateways, setInternetGateways] = React.useState<IGetLimitsResponseItem[]>([]);
    const [ec2Instances, setEc2Instances] = React.useState<IGetLimitsResponseItem[]>([]);

    const getLimits = async () => {
        const request = {};

        await agent.OwnCloud.getLimits(request)
            .then((response) => {
                console.log(response);

                setLimits(response.limits);
                setSecurityGroups(response.limits.filter(limit => limit.limit_name === "security_groups"));
                setElasticIps(response.limits.filter(limit => limit.limit_name === "elastic_ips"));
                setInternetGateways(response.limits.filter(limit => limit.limit_name === "internet_gateways"));
                setEc2Instances(response.limits.filter(limit => limit.limit_name === "ec2_instances"));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getLimitTypes = async () => {
        const request = {};

        await agent.OwnCloud.getLimitTypes()
            .then((response) => {
                console.log('getLimitTypes:', response);
            })
            .catch((error) => {
                console.log('getLimitTypes:', error);
            });
    };

    useEffect(() => {
        getLimits();
        // getLimitTypes();
    }, []);

    const editLimit = async (limit: IGetLimitsResponseItem) => {
        openDialog(<ConfirmUpdateLimit limit={limit} />);
    }

    const batchEditLimits = async () => {
        openDialog(<ConfirmUpdateBatchLimits limits={selectedLimits} />);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <Content
            footerContent={
                <Stack spacing={1} direction="row" justifyContent="flex-end">
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={false}
                        disabled={selectedLimits.length === 0}
                        onClick={batchEditLimits}
                    >
                        Update Selected
                    </LoadingButton>
                </Stack>
            }
        >
            <TabContext value={tabValue}>
                    <Box>
                        <TabList onChange={handleTabChange} aria-label="source details tabs">
                            <Tab label="Elastic IPS" value="elastic_ips" disabled={!elasticIps.length} />
                            <Tab label="EC2 Instances" value="ec2_instances" disabled={!ec2Instances.length} />
                            <Tab label="Internet Gateways" value="internet_gateways" disabled={!internetGateways.length} />
                            <Tab label="Security Groups" value="security_groups" disabled={!securityGroups.length} />
                        </TabList>
                    </Box>
                    <TabPanel value="elastic_ips" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        <OwnCloudLimitsTable
                            selectedLimits={selectedLimits}
                            data={elasticIps}
                            setSelectedLimits={setSelectedLimits}
                            editLimit={editLimit}
                        />
                    </TabPanel>
                    <TabPanel value="ec2_instances" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        <OwnCloudLimitsTable
                            selectedLimits={selectedLimits}
                            data={ec2Instances}
                            setSelectedLimits={setSelectedLimits}
                            editLimit={editLimit}
                        />
                    </TabPanel>
                    <TabPanel value="internet_gateways" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        <OwnCloudLimitsTable
                            selectedLimits={selectedLimits}
                            data={internetGateways}
                            setSelectedLimits={setSelectedLimits}
                            editLimit={editLimit}
                        />
                    </TabPanel>
                    <TabPanel value="security_groups" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        <OwnCloudLimitsTable
                            selectedLimits={selectedLimits}
                            data={securityGroups}
                            setSelectedLimits={setSelectedLimits}
                            editLimit={editLimit}
                        />
                    </TabPanel>
            </TabContext>
        </Content>
    );
};

interface IOwnCloudLimits {
    selectedLimits: IGetLimitsResponseItem[];
    data: IGetLimitsResponseItem[];
    setSelectedLimits: (limits: IGetLimitsResponseItem[]) => void;
    editLimit: (limit: IGetLimitsResponseItem) => void;
}

const OwnCloudLimitsTable: React.FC<IOwnCloudLimits> = ({ selectedLimits, data, setSelectedLimits, editLimit }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;

    const openMetricsDialog = (limit: IGetLimitsResponseItem) => {
        openDialog(
            <ViewLimitMetrics limit={limit} />,
            `View Metrics: ${limit.region} - ${limit.limit_name}`,
            "xl"
        )
    }

    return (
        <Table>
            <TableHead>
                <TableCell>
                    <Checkbox
                        checked={selectedLimits.length === data.length}
                        onChange={(event) => {
                            if (event.target.checked) {
                                setSelectedLimits(data);
                            } else {
                                setSelectedLimits([]);
                            }
                        }}
                    />
                </TableCell>
                <TableCell>Org</TableCell>
                <TableCell>Cloud Provider</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Start Warnings At</TableCell>
                <TableCell></TableCell>
            </TableHead>
            <TableBody>
                {data &&
                    data.map((limit) => (
                        <TableRow key={limit.id}>
                            <TableCell>
                                <Checkbox
                                    checked={selectedLimits.some((item) => item.id === limit.id)}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            setSelectedLimits([...selectedLimits, limit]);
                                        } else {
                                            setSelectedLimits(selectedLimits.filter((item) => item.id !== limit.id));
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell>{limit.organisation_id}</TableCell>
                            <TableCell>{limit.cloud_provider}</TableCell>
                            <TableCell>{limit.region}</TableCell>
                            <TableCell>{limit.start_warnings_at}</TableCell>
                            <TableCell>
                                <Stack direction="row" spacing={1} justifyContent="flex-end">
                                    <Tooltip title="View Metrics">
                                        <IconButton onClick={() => openMetricsDialog(limit)}>
                                            <ManageSearch />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => editLimit(limit)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip title="Delete">
                                        <IconButton>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip> */}
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

export default OwnCloudLimits;
