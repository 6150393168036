import { Card, CardContent } from "@mui/material";
import EnvironmentsList from "features/environments/Components/EnvironmentsList";
import React, { memo } from "react";
import { Handle, useReactFlow, useStoreApi, Position } from "reactflow";

function CustomNode({ id, data }) {
    return (
        <Card>
            <Handle type="target" position={Position.Left} />

            <EnvironmentsList />
            <Handle type="source" position={Position.Right} />
        </Card>
    );
}

export default memo(CustomNode);
