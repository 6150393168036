import {
    Alert,
    AlertTitle,
    Box,
    ButtonBase,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import AdminMenu from "./AdminMenu";
import ClientProvider from "components/ClientProvider";
import GroupsIcon from "@mui/icons-material/Groups";
import HelpIcon from "@mui/icons-material/Help";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import OrganisationSwitch from "components/OrganisationSwitch";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import React, { useContext } from "react";
import RoleProvider from "components/RoleProvider";
import VerifiedIcon from "@mui/icons-material/Verified";
import { RootStoreContext, KnowledgeHubSelectorType } from "api";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import ManagingOrganisationSwitch from "components/ManagingOrganisationSwitch";
import { AccountBox, DisplaySettings } from "@mui/icons-material";
import UserAvatar from "features/user/Components/UserAvatar";
import { useConstants } from "hooks";

interface Props {
    menuPosition?: string;
}

const UserMenu: React.FC<Props> = ({ menuPosition }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const rootStore = useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { user, logout } = rootStore.userStore;
    const { billingInformation } = rootStore.billingStore;
    const { setShowHelpPanel } = rootStore.commonStore;
    const { constants } = useConstants();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <UserAvatar size={36} />
            </IconButton>

            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={
                    menuPosition === "right" ? { vertical: "bottom", horizontal: "left" } : { vertical: "top", horizontal: "right" }
                }
                open={open}
                onClose={handleClose}
                sx={{
                    p: "0px !important",
                    "& .MuiMenu-paper": {
                        minWidth: 320,
                        p: "0px !important"
                    },
                    "& .MuiList-root": {
                        pt: "0px !important"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}>
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: theme.palette.action.hover,
                        display: "flex",
                        alignItems: "center",
                        mb: 2
                    }}>
                    <UserAvatar size={50} mr={2} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body1" noWrap component="div" fontWeight={600} lineHeight={1}>
                            {user?.first_name + " " + user?.last_name}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                            <Typography variant="body2" noWrap component="div" sx={{ opacity: 0.8 }}>
                                {user?.email}
                            </Typography>
                            {user?.email_verified && (
                                <Tooltip title="Email verified">
                                    <VerifiedIcon sx={{ fontSize: 12, mb: 0.2, ml: 0.5, color: theme.palette.primary.main }} />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
                {!user?.email_verified && (
                    <Box sx={{ width: "100%", maxWidth: 320, px: 2 }}>
                        <Alert severity="error" sx={{ mb: 2 }} title="ere">
                            <AlertTitle>Your account is not verified.</AlertTitle>
                            Please check your email and follow the instructions to verify your account.
                        </Alert>
                    </Box>
                )}
                {billingInformation && billingInformation.is_on_credits && (
                    <Box sx={{ width: "100%", maxWidth: 320, px: 2 }}>
                        <Alert severity="info" sx={{ mb: 2 }} title="ere">
                            <AlertTitle>{billingInformation.credits_available} Credits Available</AlertTitle>
                            Please speak to a member of the Livelink team to increase the available credits.
                        </Alert>
                    </Box>
                )}
                <RoleProvider roles={["is_dev"]}>
                    <Box sx={{ mb: 1, px: 2 }}>
                        <ManagingOrganisationSwitch />
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                </RoleProvider>
                <Box
                    sx={{
                        px: 2
                    }}>
                    <OrganisationSwitch />
                </Box>
                <Divider sx={{ mb: 1 }} />

                <MenuItem
                    onClick={() => {
                        handleClose();
                        navigate("/user");
                    }}>
                    <ListItemIcon>
                        <AccountBox fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        navigate("/user/security");
                    }}>
                    <ListItemIcon>
                        <LockIcon fontSize="small" />
                    </ListItemIcon>
                    Security
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        navigate("/user/preferences");
                    }}>
                    <ListItemIcon>
                        <DisplaySettings fontSize="small" />
                    </ListItemIcon>
                    Preferences
                </MenuItem>
                {user?.signup_stage === "finished" && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate(`/user-activity/${user.email}`);
                        }}>
                        <ListItemIcon>
                            <QueryStatsIcon fontSize="small" />
                        </ListItemIcon>
                        My Activity
                    </MenuItem>
                )}
                {user?.signup_stage === "finished" && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate("/settings/organisations");
                        }}>
                        <ListItemIcon>
                            <GroupsIcon fontSize="small" />
                        </ListItemIcon>
                        My Organisation
                    </MenuItem>
                )}

                {process.env.REACT_APP_CLIENT_NAME === "ateme" ? (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            window.open("https://docs.ateme.com/", "_blank");
                        }}>
                        <ListItemIcon>
                            <HelpIcon fontSize="small" />
                        </ListItemIcon>
                        Help
                    </MenuItem>
                ) : (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            setShowHelpPanel(true);
                        }}>
                        <ListItemIcon>
                            <HelpIcon fontSize="small" />
                        </ListItemIcon>
                        Help
                    </MenuItem>
                )}
                <RoleProvider roles={["is_superuser", "is_accounts_admin"]}>
                    <Divider />
                    <AdminMenu menuPosition={menuPosition} onClose={handleClose} />
                </RoleProvider>
                <Divider />
                <MenuItem
                    onClick={() => {
                        handleClose();
                        logout();
                    }}
                    sx={{ mb: 1 }}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
                <ClientProvider client="default">
                    <Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                opacity: 0.8,
                                py: 1,
                                mt: 1
                            }}>
                            <ButtonBase
                                sx={{
                                    px: 1,
                                    py: 0.5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mr: 1,
                                    fontSize: 12,
                                    "&:hover": {
                                        backgroundColor: theme.palette.action.hover
                                    },
                                    borderRadius: 1
                                }}
                                onClick={() => {
                                    handleClose();
                                    window.open("https://www.cerberus.tech/privacy-policy", "_blank");
                                }}>
                                <Box>Privacy Policy</Box>
                            </ButtonBase>
                            <Box>&bull;</Box>
                            <ButtonBase
                                sx={{
                                    px: 1,
                                    py: 0.5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    ml: 1,
                                    fontSize: 12,
                                    "&:hover": {
                                        backgroundColor: theme.palette.action.hover
                                    },
                                    borderRadius: 1
                                }}
                                onClick={() => {
                                    handleClose();
                                    navigate("/termsofservice");
                                }}>
                                <Box>Terms of Service</Box>
                            </ButtonBase>
                        </Box>
                    </Box>
                </ClientProvider>
            </Menu>
        </>
    );
};

export default observer(UserMenu);
