import {
    Button,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { NodeHeader } from "components/Nodes";
import { ContentCopy, Delete, Edit, Info, MoreVert, PlayArrow, RestartAlt, Stop } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { SourceDetails } from "./SourceDetails";
import TranscoderDetails from "./TranscoderDetails";
import CopyToClipboard from "react-copy-to-clipboard";

interface TranscoderItem {
    id: string;
    name: string;
    status: string;
    family_id: number;
    input_feed_source: string;
    dns_name: string;
    public_ip: string;
    rid: string;
}

interface Props {
    onDragEnd: (event: React.DragEvent<HTMLElement>, name: string) => void;
    nea_instance_uuid: string;
    nea_instance_status: string;
    setDraggedSource: React.Dispatch<React.SetStateAction<any>>;
    updateDate: Date;
    transcoders: TranscoderItem[];
}

interface SourceItemMenuProps {
    source: TranscoderItem;
    nea_instance_uuid: string;
    onItemMenuClick: (event: React.MouseEvent<HTMLElement>, source: any) => void;
}

const SourceItemMenu: React.FC<SourceItemMenuProps> = ({ source, nea_instance_uuid, onItemMenuClick }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                aria-label="settings"
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleClick(e)}>
                <MoreVert />
            </IconButton>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button"
                }}
                sx={{ minWidth: 300 }}
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={open}
                onClose={handleClose}>
                <MenuItem
                    onClick={(event) => {
                        openDrawer(<TranscoderDetails id={source.id} rid={source.rid} pull_packager_uuid={nea_instance_uuid} />);
                        event.stopPropagation();
                        handleClose(event);
                    }}
                    disableRipple>
                    <ListItemIcon>
                        <Info />
                    </ListItemIcon>
                    Details
                </MenuItem>
                <MenuItem
                    disabled={source.status !== "running"}
                    onClick={(event) => {
                        event.stopPropagation();
                        navigate(`/pull-packager/${nea_instance_uuid}/edit-source/${source.id}`);
                        handleClose(event);
                    }}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        agent.NeaLive.startPullPackagerSource(source.id)
                            .then((response) => {
                                onItemMenuClick && onItemMenuClick(event, source);
                            })
                            .catch((error) => {
                                console.log(error);
                                openDialog(
                                    <>
                                        <DialogContent>{error.data.message || error.message || "An error occurred"}</DialogContent>
                                        <DialogActions>
                                            <Button onClick={closeDialog}>Close</Button>
                                        </DialogActions>
                                    </>,
                                    "Error Starting Transcoder"
                                );
                            });
                        handleClose(event);
                    }}
                    disabled={source.status === "running" ? true : false}
                    disableRipple>
                    <ListItemIcon>
                        <PlayArrow />
                    </ListItemIcon>
                    Start
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        agent.NeaLive.stopPullPackagerSource(source.id)
                            .then((response) => {
                                onItemMenuClick && onItemMenuClick(event, source);
                            })
                            .catch((error) => {
                                console.log(error);
                                openDialog(
                                    <>
                                        <DialogContent>{error.data.message || error.message || "An error occurred"}</DialogContent>
                                        <DialogActions>
                                            <Button onClick={closeDialog}>Close</Button>
                                        </DialogActions>
                                    </>,
                                    "Error Stopping Transcoder"
                                );
                            });
                        handleClose(event);
                    }}
                    disabled={source.status === "stopped" ? true : false}
                    disableRipple>
                    <ListItemIcon>
                        <Stop />
                    </ListItemIcon>
                    Stop
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        agent.NeaLive.restartTranscoder(source.id)
                            .then((response) => {
                                onItemMenuClick && onItemMenuClick(event, source);
                            })
                            .catch((error) => {
                                console.log(error);
                                openDialog(
                                    <>
                                        <DialogContent>{error.data.message || error.message || "An error occurred"}</DialogContent>
                                        <DialogActions>
                                            <Button onClick={closeDialog}>Close</Button>
                                        </DialogActions>
                                    </>,
                                    "Error Restarting Transcoder"
                                );
                            });
                        handleClose(event);
                    }}
                    disableRipple>
                    <ListItemIcon>
                        <RestartAlt />
                    </ListItemIcon>
                    Restart
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();

                        openDialog(
                            <>
                                <DialogContent>Are you sure you want to delete this transcoder?</DialogContent>
                                <DialogActions>
                                    <Button onClick={closeDialog} color="primary">
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            agent.NeaLive.deletePullPackagerSource(source.id)
                                                .then(() => {
                                                    onItemMenuClick && onItemMenuClick(event, source);
                                                    closeDialog();
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    openDialog(
                                                        <>
                                                            <DialogContent>
                                                                {err.data?.message || err.message || "Unknown Error"}
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={closeDialog} color="primary" variant="contained">
                                                                    OK
                                                                </Button>
                                                            </DialogActions>
                                                        </>,
                                                        "Error Deleting Transcoder"
                                                    );
                                                });
                                        }}>
                                        Delete
                                    </Button>
                                </DialogActions>
                            </>,
                            "Delete Transcoder"
                        );

                        handleClose(event);
                    }}
                    disableRipple>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
};

const Sources: React.FC<Props> = (props) => {
    const theme = useTheme();

    const [value, setValue] = React.useState("1");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        console.log("Transcoders", props.transcoders);
    }, [props.transcoders]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
            }}>
            <Box sx={{ height: "50%", flex: "0 1 50%", display: "flex", flexDirection: "column", paddingTop: 1 }}>
                <NodeHeader label="In Use Transcoders" />
                <Box
                    sx={{
                        overflowY: "scroll",
                        flexGrow: 1
                    }}>
                    <List>
                        {props.transcoders &&
                            props.transcoders
                                .filter((transcoder) => transcoder.family_id)
                                .map((transcoder) => (
                                    <ListItem
                                        key={transcoder.id}
                                        sx={{
                                            pr: 0,
                                            borderLeft: "4px solid",
                                            borderColor:
                                                transcoder.status === "running" ? theme.palette.success.main : theme.palette.action.disabled
                                        }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                {transcoder.name}
                                            </Typography>
                                            <Typography variant="body2" component={"div"} textTransform="capitalize">
                                                Status: {transcoder.status}
                                            </Typography>
                                            <Typography variant="body2" component={"div"}>
                                                DNS: {transcoder.dns_name}
                                            </Typography>
                                            <Typography variant="body2" component={"div"}>
                                                IP: {transcoder.public_ip}
                                            </Typography>
                                        </Box>
                                        <SourceItemMenu
                                            source={transcoder}
                                            nea_instance_uuid={props.nea_instance_uuid}
                                            onItemMenuClick={() => console.log("In Use Transcoder Menu Clicked")}
                                        />
                                    </ListItem>
                                ))}
                    </List>
                </Box>
            </Box>
            <Box sx={{ height: "50%", flex: "0 0 50%", display: "flex", flexDirection: "column", paddingTop: 1 }}>
                <NodeHeader label="Available Transcoders" />
                <Box
                    sx={{
                        overflowY: "scroll",
                        flexGrow: 1
                    }}>
                    <List>
                        {props.transcoders &&
                            props.transcoders
                                .filter((transcoder) => !transcoder.family_id)
                                .map((transcoder) => (
                                    <ListItem
                                        key={transcoder.id}
                                        draggable={transcoder.status === "running" ? true : false}
                                        sx={{
                                            pr: 0,
                                            "&:hover": {
                                                backgroundColor:
                                                    transcoder.status === "running" ? theme.palette.action.hover : "transparent"
                                            },
                                            cursor: transcoder.status === "running" ? "grab" : "not-allowed",
                                            borderLeft: "4px solid",
                                            borderColor:
                                                transcoder.status === "running" ? theme.palette.success.main : theme.palette.action.disabled
                                        }}
                                        onDragStart={(e) => {
                                            e.dataTransfer.setData("text/plain", transcoder.name);
                                            console.log("dragging", transcoder);
                                            props.setDraggedSource(transcoder);
                                        }}
                                        onDragEnd={(e) => props.onDragEnd && props.onDragEnd(e, transcoder.name)}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                {transcoder.name}
                                            </Typography>
                                            <Typography variant="body2" component={"div"} textTransform="capitalize">
                                                Status: {transcoder.status}
                                            </Typography>
                                            <Typography variant="body2" component={"div"}>
                                                <Stack spacing={1} direction="row" alignItems="center">
                                                    DNS: {transcoder.dns_name}
                                                    <CopyToClipboard
                                                        text={transcoder.dns_name}
                                                        onCopy={() => {
                                                            console.log("Copied");
                                                        }}>
                                                        <Box title="Copy DNS to clipboard">
                                                            <ContentCopy
                                                                sx={{
                                                                    cursor: "pointer",
                                                                    fontSize: "11px",
                                                                    marginLeft: "5px",
                                                                    marginTop: "6px",
                                                                    opacity: 0.7,
                                                                    ":hover": { opacity: 1 }
                                                                }}
                                                            />
                                                        </Box>
                                                    </CopyToClipboard>
                                                </Stack>
                                            </Typography>
                                            <Typography variant="body2" component={"div"}>
                                                IP: {transcoder.public_ip}
                                            </Typography>
                                        </Box>
                                        <SourceItemMenu
                                            source={transcoder}
                                            nea_instance_uuid={props.nea_instance_uuid}
                                            onItemMenuClick={() => console.log("Available Transcoder Menu Clicked")}
                                        />
                                    </ListItem>
                                ))}
                    </List>
                </Box>
            </Box>
        </Box>
    );
};

export default Sources;
