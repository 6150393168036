import React, { useContext, useEffect, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { TextField, Checkboxes } from "mui-rff";
import { combineValidators, isRequired, createValidator, composeValidators } from "revalidate";
import ErrorMessage from "app/common/form/ErrorMessage";
import * as alertOptions from "app/common/options/alertsOptions";
import arrayMutators from "final-form-arrays";
import { observer } from "mobx-react-lite";
import { IAlertsFormValue, IAlertMethod, RootStoreContext } from "api";
import { Box, Button, DialogActions, DialogContent, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { FORM_ERROR } from "final-form";
import OnChange from "components/FinalFormListener/OnChange";
import { SelectField } from "components/Forms";
import { LoadingButton } from "@mui/lab";
import { useScheduler } from "hooks";

const Condition = ({ when, is, not = false, children }: { when: string; is: string; not?: boolean; children: React.ReactNode }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => {
            const values = is?.split(",").map((item: string) => item.trim());
            if (!not) {
                return values.includes(value) ? children : null;
            }
            return !values.includes(value) ? children : null;
        }}
    </Field>
);

interface IAlertFormProps {
    source: any;
    successCallback?: () => any;
}

const AlertsFormEdit: React.FC<IAlertFormProps> = ({ source, successCallback }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { alertMethods } = rootStore.schedulerStore;
    const { updateAlert, getOrganisationAlertMethods } = useScheduler();
    const [queryValues, setQueryValues] = useState<number[]>(source.query_value);

    useEffect(() => {
        getOrganisationAlertMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const queryValueHandler = (values: any) => {
        if (values.alert_on_query === "is_connected") {
            // Connection established = connection status > 0 otherwise connection status less than 1
            setQueryValues([values.query_param === "gt" ? 0 : 1]);
        } else {
            if (values.query_param === "outside_range" || values.query_param === "within_range") {
                setQueryValues([values.query_value_1, values.query_value_2]);
            } else {
                setQueryValues(values.query_value);
            }
        }
    };

    const isNotNegative = createValidator(
        (message) => (value) => {
            if (value && value < 0) {
                return message;
            }
        },
        "Value can not be below 0"
    );

    const parse = (value: any) => (isNaN(parseFloat(value)) ? "" : parseFloat(value));

    const validate = combineValidators({
        alert_name: isRequired("name"),
        alert_method_id: isRequired("alert method"),
        alert_on_query: isRequired("alert query"),
        query_param: isRequired("parameters"),
        query_time_range: isRequired("time range"),
        alert_check_frequency: composeValidators(isNotNegative, isRequired("frequency"))(),
        alert_after_time: composeValidators(isNotNegative, isRequired("time"))(),
        alert_repeat: isRequired("repeat")
    });

    const inputInitialValues: IAlertsFormValue = {
        alert_name: source.alert_name,
        alert_only_when_schedule_on: source.alert_only_when_schedule_on,
        alert_method_id: source.alert_method_id,
        alert_on_query: source.alert_on_query,
        query_param: source.query_param,
        query_value: source.query_value,
        query_time_range: source.query_time_range,
        alert_check_frequency: source.alert_check_frequency.slice(0, -1),
        alert_check_frequency_unit: source.alert_check_frequency.slice(-1),
        alert_after_time: source.alert_after_time.slice(0, -1),
        alert_after_time_unit: source.alert_after_time.slice(-1),
        alert_repeat: source.alert_resend_rate
    };

    if (source.query_value && source.query_value.length > 1) {
        inputInitialValues.query_value_1 = source.query_value[0];
        inputInitialValues.query_value_2 = source.query_value[1];
    } else {
        inputInitialValues.query_value = source.query_value;
    }

    return (
        <div>
            <FinalForm
                onSubmit={(values: IAlertsFormValue) =>
                    updateAlert(source.id, {
                        alert_name: values.alert_name,
                        alert_method_id: values.alert_method_id,
                        alert_on_query: values.alert_on_query,
                        query_param: values.query_param,
                        query_value: queryValues,
                        query_time_range: values.query_time_range,
                        alert_after_time: values.alert_after_time + values.alert_after_time_unit,
                        alert_check_frequency: values.alert_check_frequency + values.alert_check_frequency_unit,
                        alert_repeat: values.alert_repeat,
                        alert_only_when_schedule_on: values.alert_only_when_schedule_on
                    })
                        .then(() => {
                            successCallback && successCallback();
                            closeDialog();
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                            presetName: error.data.errors ? error.data.errors.preset_name : null
                        }))
                }
                mutators={{
                    ...arrayMutators
                }}
                validate={validate}
                initialValues={inputInitialValues}
                render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Tooltip
                                placement="left"
                                arrow
                                title="Alert name will appear in the alerts list. It needs to be unique and can be changed later.">
                                <div>
                                    <TextField required label="Name" name="alert_name" placeholder="Name alert here" type="text" />
                                </div>
                            </Tooltip>

                            <Box width={"100%"}>
                                <Tooltip
                                    placement="left"
                                    arrow
                                    sx={{ width: "100%" }}
                                    title="Select one of the configured alert methods, if there is no available alert method please create one first in Settings -> Alert Management -> Alter Notification Methods.">
                                    <div>
                                        {alertMethods && alertMethods.data && alertMethods.data.length > 0 && (
                                            <SelectField
                                                label="Alert method"
                                                required
                                                options={alertMethods?.data?.map((method: IAlertMethod) => ({
                                                    key: method.id,
                                                    text: method.name,
                                                    value: method.id
                                                }))}
                                                name="alert_method_id"
                                                placeholder="Select an alert method"
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                            />
                                        )}
                                    </div>
                                </Tooltip>
                            </Box>

                            <Tooltip placement="left" arrow title="Select a query for the alert.">
                                <div>
                                    <SelectField
                                        label="Alert metric"
                                        required
                                        options={alertOptions.alert_on_query}
                                        name="alert_on_query"
                                        placeholder="Select alert metric"
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                    />

                                    <OnChange name="alert_on_query">
                                        {() => {
                                            queryValueHandler(values);
                                        }}
                                    </OnChange>
                                </div>
                            </Tooltip>
                            <Condition when="alert_on_query" is="is_connected">
                                <Tooltip placement="left" arrow title="Select the parameter for the alert query.">
                                    <div>
                                        <SelectField
                                            required
                                            label="Alert query parameters"
                                            options={alertOptions.query_param_connection_status}
                                            name="query_param"
                                            placeholder="Select query parameters"
                                            helperText={(meta) => meta.touched && meta.error}
                                            error={(meta) => meta.touched && meta.error}
                                        />
                                        <OnChange name="query_param">
                                            {() => {
                                                queryValueHandler(values);
                                            }}
                                        </OnChange>
                                    </div>
                                </Tooltip>
                            </Condition>
                            <Condition when="alert_on_query" is="is_connected" not={true}>
                                <Tooltip
                                    style={{ fontSize: "1rem" }}
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    placement="left"
                                    arrow
                                    title="Select the parameter for the alert query.">
                                    <div>
                                        <SelectField
                                            required
                                            label="Alert query parameters"
                                            options={alertOptions.query_param}
                                            name="query_param"
                                            placeholder="Select query parameters"
                                            helperText={(meta) => meta.touched && meta.error}
                                            error={(meta) => meta.touched && meta.error}
                                        />
                                        <OnChange name="query_param">
                                            {() => {
                                                queryValueHandler(values);
                                            }}
                                        </OnChange>
                                    </div>
                                </Tooltip>
                                <Condition when="query_param" is="outside_range, within_range" not={true}>
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        placement="left"
                                        arrow
                                        title="Set the value thresholds based on the query selected, i.e. Bitrate in Kbps">
                                        <div>
                                            <TextField
                                                sx={{ mt: 2 }}
                                                required
                                                label="Query values"
                                                name="query_value"
                                                placeholder="Query values"
                                                type="number"
                                                fieldProps={{
                                                    parse: parse
                                                }}
                                            />
                                            <OnChange name="query_value">
                                                {() => {
                                                    queryValueHandler(values);
                                                }}
                                            </OnChange>
                                        </div>
                                    </Tooltip>
                                </Condition>
                                <Condition when="query_param" is="outside_range, within_range">
                                    <TextField
                                        sx={{ mt: 2 }}
                                        required
                                        label="Value 1"
                                        name="query_value_1"
                                        placeholder="Query value 1"
                                        type="number"
                                        fieldProps={{
                                            parse: parse
                                        }}
                                    />
                                    <OnChange name="query_value_1">
                                        {() => {
                                            queryValueHandler(values);
                                        }}
                                    </OnChange>
                                    <TextField
                                        sx={{ mt: 2 }}
                                        required
                                        label="Value 2"
                                        name="query_value_2"
                                        placeholder="Query value 2"
                                        type="number"
                                        fieldProps={{
                                            parse: parse
                                        }}
                                    />
                                    <OnChange name="query_value_2">
                                        {() => {
                                            queryValueHandler(values);
                                        }}
                                    </OnChange>
                                </Condition>
                            </Condition>
                            <Stack direction="row" width={"100%"} justifyContent={"space-between"} sx={{ mt: 2 }}>
                                <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    placement="left"
                                    arrow
                                    title="Specify how often the system should evaluate the alert, i.e. if set to 30s the system will trigger an evaluation every 30s, the value should be set in conjunction with the evaluation period.">
                                    <div>
                                        <TextField
                                            required
                                            label="Evaluation Interval"
                                            name="alert_check_frequency"
                                            placeholder="Evaluation Interval"
                                            type="number"
                                            fieldProps={{
                                                parse: parse
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                                <Box width={"50%"}>
                                    <Tooltip
                                        style={{ fontSize: "1rem" }}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        placement="left"
                                        arrow
                                        title="Set the unit of time for the evaluation interval.">
                                        <div>
                                            <SelectField
                                                required
                                                label="Time unit"
                                                options={alertOptions.time_types}
                                                name="alert_check_frequency_unit"
                                                placeholder="time unit"
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                            />
                                        </div>
                                    </Tooltip>
                                </Box>
                            </Stack>
                            <Stack direction="row" width={"100%"} justifyContent={"space-between"} sx={{ mt: 2 }}>
                                <Tooltip
                                    style={{ fontSize: "1rem" }}
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    placement="left"
                                    arrow
                                    title="Specify the period the query needs to violate the configured thresholds for, before the alert notification triggers. This needs to be less than the evaluation interval.">
                                    <div>
                                        <TextField
                                            required
                                            label="Evaluation period"
                                            name="alert_after_time"
                                            placeholder="Evaluation period"
                                            type="number"
                                            fieldProps={{
                                                parse: parse
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                                <Box width={"50%"}>
                                    <Tooltip
                                        style={{ fontSize: "1rem" }}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        placement="left"
                                        arrow
                                        title="Set the unit of time for the evaluation interval.">
                                        <div>
                                            <SelectField
                                                required
                                                label="Time unit"
                                                options={alertOptions.time_types}
                                                name="alert_after_time_unit"
                                                placeholder="time unit"
                                                helperText={(meta) => meta.touched && meta.error}
                                                error={(meta) => meta.touched && meta.error}
                                            />
                                        </div>
                                    </Tooltip>
                                </Box>
                            </Stack>

                            <Tooltip
                                style={{ fontSize: "1rem" }}
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                placement="left"
                                arrow
                                title="Specify how often a notification is sent after the initial notification while the alert condition remains.">
                                <div>
                                    <SelectField
                                        required
                                        label="Alert repeat time"
                                        options={alertOptions.alert_repeat}
                                        name="alert_repeat"
                                        placeholder="Resend alert after time"
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                    />
                                </div>
                            </Tooltip>
                            <FormGroup
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: 1
                                }}>
                                <FormControlLabel
                                    sx={{
                                        m: 1,
                                        py: 0.5,
                                        px: 1,
                                        background: "#1e2832",
                                        color: "#ffffff"
                                    }}
                                    label="Alert only when schedule is on"
                                    control={
                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Trigger the alert only when a source is live to a destination.">
                                            <div>
                                                <Checkboxes
                                                    name="alert_only_when_schedule_on"
                                                    color="primary"
                                                    data={{
                                                        label: "",
                                                        value: undefined
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    }
                                />
                            </FormGroup>
                            {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                loading={submitting}
                                disabled={(invalid && !dirtySinceLastSubmit) || pristine}>
                                Save
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            />
        </div>
    );
};

export default observer(AlertsFormEdit);
