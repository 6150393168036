/* eslint-disable no-useless-catch */
import { RootStore } from "./rootStore";
import { action, observable, makeObservable } from "mobx";
import { IOutputFormValues, IOutput, IResModels, IDestination } from "api";

export default class DestinationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable destinations: IResModels | null = { _list: [] };
    @observable destination: IDestination | null = null;
    @observable outputs: IOutput[] | null = null;
    @observable output: IOutputFormValues | null = null;
    @observable editOutput: IOutput | null = null;
    @observable filteredOutputs: IResModels | null = null;
    @observable loading = false;
    @observable destinationStart = true;
    @observable stopping = false;
    @observable starting = false;
    @observable setId = "";
    @observable destinationDeleted = false;

    @action setDestinationStart = () => {
        this.destinationStart = !this.destinationStart;
    };

    @action setOutput = (newOutput: IOutputFormValues) => {
        this.output = newOutput;
    };

    @action setEditOutput = (newEditOutput: IOutput) => {
        this.editOutput = newEditOutput;
    };

    @action setDestination = (newDestination: IDestination) => {
        this.destination = newDestination;
    };

    @action setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    @action setStarting = (starting: boolean) => {
        this.starting = starting;
    };

    @action setStopping = (stopping: boolean) => {
        this.stopping = stopping;
    };

    @action setSetId = (id: string) => {
        this.setId = id;
    };

    @action setDestinationDeleted = (deleted: boolean) => {
        this.destinationDeleted = deleted;
    };
}
