import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface IProps {
    name: string;
}

export const RestartConfirmed: React.FC<IProps> = ({ name }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>Transcoder Restarting</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Transcoder: <strong>{name}</strong> is now restarting.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </DialogActions>
        </>
    );
};
