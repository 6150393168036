import { agent, RootStoreContext } from "api";
import { useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";

export const useFileCluster = () => {
    const rootStore = useContext(RootStoreContext);
    const {
        setRegions,
        failedJobsOffset,
        failedJobsSort,
        searchTerm,
        setFailedJobs,
        abortedJobsOffset,
        abortedJobsSort,
        setAbortedJobs,
        stoppedJobsOffset,
        stoppedJobsSort,
        setStoppedJobs,
        pendingJobsOffset,
        pendingJobsSort,
        setPendingJobs,
        activeDeploymentId
    } = rootStore.fileClusterStore;
    const [isLoading, setIsLoading] = useState(false);

    const getRegionsDropDownValues = useCallback(async () => {
        await agent.FileCluster.getRegions()
            .then((values) => {
                setRegions(values);
            })
            .catch((error) => {
                console.log(error);
                throw error;
            });
    }, []);

    const getFailedJobs = useCallback(async (deployment_id: string) => {
        await agent.FileCluster.getJobsByDeployment(deployment_id, failedJobsOffset, 50, failedJobsSort, "failed", searchTerm)
            .then((values) => {
                setFailedJobs(values);
            })
            .catch((error) => {
                throw error;
            });
    }, []);

    const getAbortedJobs = useCallback(async (deployment_id: string) => {
        await agent.FileCluster.getJobsByDeployment(deployment_id, abortedJobsOffset, 50, abortedJobsSort, "aborted", searchTerm)
            .then((values) => {
                setAbortedJobs(values);
            })
            .catch((error) => {
                throw error;
            });
    }, []);

    const getStoppedJobs = useCallback(async (deployment_id: string) => {
        await agent.FileCluster.getJobsByDeployment(deployment_id, stoppedJobsOffset, 50, stoppedJobsSort, "stopped", searchTerm)
            .then((stoppedJobs) => {
                setStoppedJobs(stoppedJobs);
            })
            .catch((error) => {
                throw error;
            });
    }, []);

    const getPendingJobs = useCallback(async (deployment_id: string) => {
        await agent.FileCluster.getJobsByDeployment(deployment_id, pendingJobsOffset, 50, pendingJobsSort, "pending", searchTerm)
            .then((pendingJobs) => {
                setPendingJobs(pendingJobs);
            })
            .catch((error) => {
                throw error;
            });
    }, []);

    const startJob = useCallback(async (deployment_id: string, job_id: number) => {
        await agent.FileCluster.startJob(deployment_id, job_id).catch((error) => {
            const errorMessage: any = error;
            toast.error(errorMessage.data.message, { position: toast.POSITION.BOTTOM_LEFT });
            throw error;
        });
    }, []);

    const stopJob = useCallback(async (deployment_id: string, job_id: number) => {
        await agent.FileCluster.stopJob(deployment_id, job_id).catch((error) => {
            const errorMessage: any = error;
            toast.error(errorMessage.data.message, { position: toast.POSITION.BOTTOM_LEFT });
            throw error;
        });
    }, []);

    const deleteJob = useCallback(async (deployment_id: string, job_id: number) => {
        await agent.FileCluster.deleteJob(deployment_id, job_id).catch((error) => {
            const errorMessage: any = error;
            toast.error(errorMessage.data.message, { position: toast.POSITION.BOTTOM_LEFT });
            throw error;
        });
    }, []);

    const setJobPriority = useCallback(async (job_id: number, priority: any) => {
        await agent.FileCluster.setJobPriority(job_id, activeDeploymentId, {
            new_priority: priority
        }).catch((error) => {
            const errorMessage: any = error;
            toast.error(errorMessage.data.message);
            throw error;
        });
    }, []);

    return {
        isLoading,
        getRegionsDropDownValues,
        getFailedJobs,
        getAbortedJobs,
        getStoppedJobs,
        getPendingJobs,
        startJob,
        stopJob,
        deleteJob,
        setJobPriority
    };
};
