import React, { useContext, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "api";
import * as PermissionTypes from "app/common/options/PermissionTypes";
import ErrorMessage from "app/common/form/ErrorMessage";
import { MenuItem, Select } from "@mui/material";
import { useSourcePermissions } from "hooks";

const MaxDestinations = (props: { token: string | undefined; globMax: number | undefined }) => {
    const { token, globMax } = props;
    const rootStore = useContext(RootStoreContext);
    const { sourcePermissions } = rootStore.sourcePermissionsStore;
    const { loadSourcePermissions, updateSourcePermission } = useSourcePermissions();
    const { openDialog } = rootStore.dialogStore;
    const initialValues = {};
    const [globmaxDest, setGlobMaxDest] = useState<number | undefined>(undefined);
    const nothing = () => {
        return;
    };

    const handleOnchange = async (e: any, data: any) => {
        e.preventDefault();
        const values = {
            global_max_destinations: data.value,
            token: token!
        };
        try {
            await updateSourcePermission(values);
            setTimeout(() => {
                loadSourcePermissions();
            }, 1000);
        } catch (error: any) {
            openDialog(<ErrorMessage error={error} />);
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setGlobMaxDest(globMax);
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourcePermissions]);

    return (
        <div>
            <FinalForm
                onSubmit={() => nothing()}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Select defaultValue={globmaxDest} name="max_destinations" onChange={(e, data) => handleOnchange(e, data)}>
                            {PermissionTypes.max_destinations.map((destination) => (
                                <MenuItem key={destination.value} value={destination.value}>
                                    {destination.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </form>
                )}
            />
        </div>
    );
};

export default observer(MaxDestinations);
