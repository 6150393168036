import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { RootStoreContext } from "api";
import { AxiosResponse } from "axios";
import { TermsAndCommitments } from "../termsAndCommitments/TermsAndCommitments";
import { AgreementConfirmed } from "./AgreementConfirmed";
import { Alert, AlertTitle, Box, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { usePermanentSchedule } from "hooks";

interface IProps {
    productId: number;
    currency: string;
    totalPerDayCost: (string | undefined)[] | undefined;
    totalPerTermCost: (string | undefined)[] | undefined;
    agreementQty: number;
}

export const ConfirmAgreement: React.FC<IProps> = ({ productId, currency, totalPerDayCost, totalPerTermCost, agreementQty }) => {
    const rootStore = useContext(RootStoreContext);
    const { purchaseScheduleAgreement } = usePermanentSchedule();
    const { closeDialog, openDialog, changeDialogBody } = rootStore.dialogStore;
    const [error, setError] = useState<AxiosResponse | null>(null);

    const onSubmit = () => {
        purchaseScheduleAgreement(productId, agreementQty)
            .then(() => {
                changeDialogBody(<AgreementConfirmed />);
            })
            .catch((error) => setError(error));
    };

    return (
        <>
            <DialogTitle>Confirm Agreement</DialogTitle>
            <DialogContent>
                <Typography mb={1}>
                    Once commited you enter a minimum commitment of the period selected, you cannot change or delete this commitment until
                    the minumum term is finished.
                </Typography>
                <Typography>Are you sure you want to commit to this agreement?</Typography>
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        <AlertTitle>{error.data.status ? error.data.status : "Error"}</AlertTitle>
                        {error.data && Object.keys(error.data.message).length > 0 && (
                            <Box>
                                <Typography>{Object.values(error.data.message)}</Typography>
                            </Box>
                        )}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                        openDialog(
                            <TermsAndCommitments
                                productId={productId}
                                currency={currency}
                                totalPerTermCost={totalPerTermCost}
                                totalPerDayCost={totalPerDayCost}
                                agreementQty={agreementQty}
                            />
                        );
                    }}>
                    Back
                </Button>
                <Button variant="contained" onClick={onSubmit} disabled={error ? true : false}>
                    Commit
                </Button>
            </DialogActions>
        </>
    );
};
