import { CalendarToday, PlayArrow, SportsScore } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

interface ItemTooltipProps {
    source_id: string;
    source_name: string;
    destination_id: string;
    destination_name: string;
    start_time: Date;
    end_time: Date;
    status: string;
}

const ItemTooltip: React.FC<ItemTooltipProps> = ({ source_id, source_name, destination_id, destination_name, start_time, end_time, status }) => {
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1
                }}>
                {status === "finished" ? <SportsScore sx={{ fontSize: 16, mr: "4px" }} /> : null}
                {status === "on" ? <PlayArrow sx={{ fontSize: 16, mr: "4px" }} /> : null}
                {status === "scheduled" ? <CalendarToday sx={{ fontSize: 16, mr: "4px" }} /> : null}
                <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
                    {status === "on" ? "Live" : status}
                </Typography>
            </Box>
            <Typography variant="body2">Start: {format(new Date(start_time), "dd/MM/yyyy @ HH:mm")}</Typography>
            <Typography variant="body2">End: {format(new Date(end_time), "dd/MM/yyyy @ HH:mm")}</Typography>
            <Typography variant="body2">Source: {source_name}</Typography>
            <Typography variant="body2">Destination: {destination_name}</Typography>
        </Box>
    );
};

export default ItemTooltip;
