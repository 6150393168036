import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "../Sidebar/styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStoreContext } from "api";
import { useTheme } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";
import ClientProvider from "components/ClientProvider";
import { SidebarMenu } from "components/MainMenu";

const Sidebar = observer(function Sidebar() {
    const rootStore = useContext(RootStoreContext);
    const { setSidebarExpanded } = rootStore.sidebarStore;
    const { user } = rootStore.userStore;

    const theme = useTheme();

    return (
        <Box style={{ backgroundColor: theme.palette.sidebar?.main }} className="sidebar-container">
            <div className="sidebar-top">
                <div className="sidebar-logo" onClick={() => setSidebarExpanded(true)}>
                    <ClientProvider client="default">
                        <Box
                            component="img"
                            sx={{ width: "64px", height: "64px" }}
                            title="Livelink by Cerberus.tech"
                            src={require("../../../client/default/assets/logo/icon-logo.png")}
                        />
                    </ClientProvider>
                    <ClientProvider client="ateme">
                        <Box
                            component="img"
                            sx={{
                                position: "absolute",
                                width: "60px",
                                height: "60px",
                                left: "7px"
                            }}
                            title="Ateme+"
                            src={require("../../../client/ateme/assets/logo/icon-logo.png")}
                        />
                    </ClientProvider>
                </div>
                <div className="sidebar-logo-full" onClick={() => setSidebarExpanded(true)}>
                    {process.env.REACT_APP_CLIENT_NAME === "default" ? (
                        <Box
                            component="img"
                            title="Livelink by Cerberus.tech"
                            src={require("../../../client/default/assets/logo/full-logo.png")}
                        />
                    ) : (
                        <Box
                            component="img"
                            title="Ateme+"
                            src={require("../../../client/" + process.env.REACT_APP_CLIENT_NAME + "/assets/logo/full-logo.png")}
                            style={{ width: "179px", marginLeft: "19px" }}
                        />
                    )}
                </div>

                {user && user?.signup_stage !== "finished" && (
                    <Tooltip title="Setup" placement="right">
                        <NavLink to="/setup" className="sidebar-item">
                            <div className="sidebar-item-icon">
                                <FontAwesomeIcon icon={["fal", "exclamation-triangle"]} style={{ color: "yellow" }} />
                            </div>
                            <div className="sidebar-item-label">Setup</div>
                        </NavLink>
                    </Tooltip>
                )}

                {user?.signup_stage === "finished" && (
                    <>
                        <SidebarMenu />
                    </>
                )}
            </div>
            <div className="sidebar-middle" onClick={() => setSidebarExpanded(true)}></div>
        </Box>
    );
});

export default Sidebar;
