import { useTheme } from "@mui/material";
import React from "react";
import { Edge, Node, Position } from "reactflow";

interface useConstructNodesProps {
    values: any;
    setActiveSidebarPanel: React.Dispatch<React.SetStateAction<string>>;
    setActiveSidebarTab: React.Dispatch<React.SetStateAction<string>>;
    setCollapseSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveFormPath: React.Dispatch<React.SetStateAction<string>>;
    setActiveAudioTrackVariantIndex: React.Dispatch<React.SetStateAction<number>>;
    droppableType: string;
    setDroppableElementType: React.Dispatch<React.SetStateAction<string>>;
    setDroppableElementId: React.Dispatch<React.SetStateAction<string>>;
    onMenuItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export const useConstructNodes = ({
    values,
    setActiveSidebarPanel,
    setActiveSidebarTab,
    setCollapseSidebar,
    setActiveFormPath,
    setActiveAudioTrackVariantIndex,
    droppableType,
    setDroppableElementType,
    setDroppableElementId,
    onMenuItemClick
}: useConstructNodesProps) => {
    const theme = useTheme();
    const gridSize = 15;

    //console.log("values", JSON.stringify(values));

    let nodes: Node[] = [];
    let edges: Edge[] = [];

    values?.input_feed_source === "environment" &&
        nodes.push({
            id: `environment`,
            type: "details",
            sourcePosition: Position.Bottom,
            targetPosition: Position.Top,
            position: { x: gridSize * 1, y: gridSize * 1 },
            width: gridSize * 17,
            height: 120,
            draggable: false,
            data: {
                label: "Source",
                type: "input",
                width: gridSize * 17,
                height: 120,
                hideTarget: true,
                items: [
                    { label: "Type", value: "", name: `input_feed_source` },
                    { label: "Provider", value: "", name: `cloud_provider` },
                    { label: "Perm Dest", value: "", name: `make_permanent_destination` }
                ],
                onClick: () => {
                    setActiveSidebarPanel("input");
                    setActiveSidebarTab("4");
                    setCollapseSidebar(false);
                    setActiveFormPath(`source.input`);
                }
            }
        });

    values?.input_feed_source === "direct" &&
        nodes.push({
            id: `direct`,
            type: "details",
            sourcePosition: Position.Bottom,
            targetPosition: Position.Top,
            position: { x: gridSize * 1, y: gridSize * 1 },
            width: gridSize * 17,
            height: 90,
            draggable: false,
            data: {
                label: "Source",
                type: "input",
                width: gridSize * 17,
                height: 90,
                hideTarget: true,
                items: [
                    { label: "Type", value: "", name: `input_feed_source` },
                    { label: "Input Type", value: "", name: `direct_feed_input_details.input_type` }
                ],
                onClick: () => {
                    setActiveSidebarPanel("input");
                    setActiveSidebarTab("4");
                    setCollapseSidebar(false);
                    setActiveFormPath(`source.input`);
                }
            }
        });

    values?.global_track_configuration &&
        nodes.push({
            id: `global_track_configuration`,
            type: "details",
            sourcePosition: Position.Right,
            targetPosition: Position.Top,
            position: { x: gridSize * 1, y: gridSize * 13 },
            width: gridSize * 17,
            height: 90,
            draggable: false,
            data: {
                label: "Global Track Confirguration",
                type: "input",
                width: gridSize * 17,
                height: 90,
                items: [
                    { label: "Latency Mode", value: "", name: `global_track_configuration.latency_mode` },
                    { label: "Chunk Period", value: "", name: `global_track_configuration.chunk_period` }
                ],
                onClick: () => {
                    setActiveSidebarPanel("global_track_configuration");
                    setActiveSidebarTab("4");
                    setCollapseSidebar(false);
                    setActiveFormPath(`global_track_configuration`);
                }
            }
        });

    // Add the video tracks group
    nodes.push({
        id: `individual_track_configuration.video_tracks`,
        type: "customgroup",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        position: { x: gridSize * 25, y: 15 },
        width: gridSize * 19,
        height: 45,
        draggable: false,
        data: {
            hideSource: true,
            label: "Video Tracks",
            type: "video_tracks",
            width: gridSize * 19,
            height: 45,
            color: theme.palette.primary.main
        }
    });

    // For each video track, add a node and make it a child of the video tracks group
    values?.individual_track_configuration?.video_tracks?.map((video_track: any, index: number) => {
        nodes.push({
            id: `video_track_${index}`,
            type: "details",
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            position: {
                x: 15,
                //each video track is 210px high, so we need to add 210px plus a 15px gap for each video track
                //the first tracks y should be set to 45px
                y: 45 + index * 45
            },
            width: gridSize * 17,
            height: 30,
            draggable: false,
            data: {
                label:
                    values?.individual_track_configuration?.video_tracks[index]?.codec +
                        " / " +
                        values?.individual_track_configuration?.video_tracks[index]?.bitrate +
                        " / " +
                        values?.individual_track_configuration?.video_tracks[index]?.frame_rate +
                        " / " +
                        values?.individual_track_configuration?.video_tracks[index]?.frame_size || `Video Track ${index + 1}`,
                type: "video",
                width: gridSize * 17,
                hideTarget: true,
                height: 30,
                items: [
                    { label: "Codec", value: "", name: `individual_track_configuration.video_tracks.${index}.codec` },
                    { label: "Bitrate", value: "", name: `individual_track_configuration.video_tracks.${index}.bitrate` },
                    { label: "Frame Rate", value: "", name: `individual_track_configuration.video_tracks.${index}.frame_rate` },
                    { label: "Frame Size", value: "", name: `individual_track_configuration.video_tracks.${index}.frame_size` },
                    //gop_size
                    { label: "GOP Size", value: "", name: `individual_track_configuration.video_tracks.${index}.gop_size` },
                    //profile
                    { label: "Profile", value: "", name: `individual_track_configuration.video_tracks.${index}.profile` },
                ],
                itemsInTooltip: true,
                onClick: () => {
                    setActiveSidebarPanel("video_track");
                    setActiveSidebarTab("4");
                    setCollapseSidebar(false);
                    setActiveFormPath(`${index}`);
                }
            },
            parentNode: `individual_track_configuration.video_tracks`
        });

        // increate the height of the video tracks group by 225px
        nodes[2].data.height += 45;
    });

    const videoTracksHeight = nodes.find((node) => node.id === "individual_track_configuration.video_tracks")?.data?.height || 0;

    // Add the audio tracks group
    nodes.push({
        id: `individual_track_configuration.audio_tracks`,
        type: "customgroup",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        position: {
            x: gridSize * 25,
            //the y position of the audio tracks group should be the height of the video tracks group plus 30px
            y: videoTracksHeight + 30 || 15
        },
        width: gridSize * 19,
        height: 45,
        draggable: false,
        data: {
            hideSource: true,
            label: "Audio Tracks",
            type: "audio_tracks",
            width: gridSize * 19,
            height: 45,
            color: theme.palette.secondary.main
        }
    });

    // For each audio track, add a node and make it a child of the audio tracks group
    // Increase the height of the audio tracks group by 225px for each audio track

    values?.individual_track_configuration?.audio_tracks?.map((audio_track: any, index: number) => {
        nodes.push({
            id: `audio_track_${index}`,
            type: "customgroup",
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            position: {
                x: 15,
                //each audio track is 60px high, so we need to add 60px plus a 15px gap for each audio track
                //get the y and the height of the previous audio track if it exists
                y:
                    nodes.find((node) => node.id === `audio_track_${index - 1}`)?.position?.y +
                        nodes.find((node) => node.id === `audio_track_${index - 1}`)?.data?.height +
                        15 || 45
            },
            width: gridSize * 17,
            height: values?.individual_track_configuration?.audio_tracks[index]?.variants?.length * 45 + 15 + 60 || 75,
            draggable: false,
            data: {
                label: index + 1 + ". " + values?.individual_track_configuration?.audio_tracks[index]?.codec || `Audio Track ${index + 1}`,
                type: "audio",
                width: gridSize * 17,
                hideTarget: true,
                hideSource: true,
                height: values?.individual_track_configuration?.audio_tracks[index]?.variants?.length * 45 + 45 || 75,
                items: [{ label: "Codec", value: "", name: `individual_track_configuration.audio_tracks.${index}.codec` }],
                onClick: () => {
                    setActiveSidebarPanel("audio_track");
                    setActiveSidebarTab("4");
                    setCollapseSidebar(false);
                    setActiveFormPath(`${index}`);
                }
            },
            parentNode: `individual_track_configuration.audio_tracks`
        });

        // increate the height of the audio tracks group
        const audioTracksNode = nodes.find((node) => node.id === "individual_track_configuration.audio_tracks");
        audioTracksNode && (audioTracksNode.data.height += 90);
    });

    // for each values?.individual_track_configuration?.audio_tracks.variants, add a node and make it a child of the audio track
    // Increase the height of the audio track by 60px for each variant
    // Increase the height of the audio tracks group by 60px for each variant

    values?.individual_track_configuration?.audio_tracks?.map((audio_track: any, pindex: number) => {
        audio_track?.variants?.map((variant: any, index: number) => {
            nodes.push({
                id: `audio_track_${pindex}_variant_${index}`,
                type: "details",
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                position: {
                    x: 15,
                    //each variant is 30px high, so we need to add 30px plus a 15px gap for each variant
                    //the first variant y should be set to 45px
                    //values are relative to the parent node only
                    y: 45 + index * 45
                },
                width: gridSize * 15,
                height: 30,
                draggable: false,
                data: {
                    label:
                        values?.individual_track_configuration?.audio_tracks[pindex]?.variants[index]?.bitrate +
                            " / " +
                            values?.individual_track_configuration?.audio_tracks[pindex]?.variants[index]?.sample_rate ||
                        `Variant ${index + 1}`,
                    type: "audio_variant",
                    width: gridSize * 15,
                    hideTarget: true,
                    height: 30,
                    items: [],
                    onClick: () => {
                        setActiveSidebarPanel("audio_track_variant");
                        setActiveSidebarTab("4");
                        setCollapseSidebar(false);
                        setActiveFormPath(`${pindex}`);
                    }
                },
                parentNode: `audio_track_${pindex}`
            });

            // increate the height of the audio track (pindex) by 30px

            // // increate the height of the audio tracks group by 30px
            const audioTracksNode = nodes.find((node) => node.id === "individual_track_configuration.audio_tracks");
            audioTracksNode && (audioTracksNode.data.height += 30);
        });
    });

    // Add the data tracks group
    //the y position of the data tracks group should be the height of the video tracks group plus the height of the audio tracks group plus 15px
    //find the other nodes hight using the id of the node, e.g. id: `individual_track_configuration.video_tracks`,

    const audioTracksHeight = nodes.find((node) => node.id === "individual_track_configuration.audio_tracks")?.data?.height || 0;

    nodes.push({
        id: `individual_track_configuration.data_tracks`,
        type: "customgroup",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        position: { x: gridSize * 25, y: videoTracksHeight + audioTracksHeight + 45 },
        width: gridSize * 19,
        height: 45,
        draggable: false,
        data: {
            label: "Data Tracks",
            type: "data_tracks",
            width: gridSize * 19,
            height: 75,
            hideSource: true,
            color: theme.palette.tertiary?.main
        }
    });

    // For each data track, add a node and make it a child of the data tracks group
    // Increase the height of the data tracks group by 45px for each data track
    values?.individual_track_configuration?.data_tracks?.map((data_track: any, index: number) => {
        nodes.push({
            id: `data_track_${index}`,
            type: "details",
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            position: {
                x: 15,
                //each data track is 45px high, so we need to add 45px plus a 15px gap for each data track
                //get the y and the height of the previous data track if it exists
                y:
                    nodes.find((node) => node.id === `data_track_${index - 1}`)?.position?.y +
                        nodes.find((node) => node.id === `data_track_${index - 1}`)?.data?.height +
                        15 || 45
            },
            width: gridSize * 17,
            height: 30,
            draggable: false,
            data: {
                label: values?.individual_track_configuration?.data_tracks[index]?.data_type || `Data Track ${index + 1}`,
                type: "data",
                width: gridSize * 17,
                hideTarget: true,
                height: 30,
                items: [],
                onClick: () => {
                    setActiveSidebarPanel("data");
                    setActiveSidebarTab("4");
                    setCollapseSidebar(false);
                    setActiveFormPath(`data_track_${index}`);
                }
            },
            parentNode: `individual_track_configuration.data_tracks`
        });

        // increate the height of the data tracks group
        const dataTracksNode = nodes.find((node) => node.id === "individual_track_configuration.data_tracks");
        dataTracksNode && (dataTracksNode.data.height += 30);
    });

    const calculateOutputGroupY = (output_group: any, index: number) => {
        //the y of the first output group should be 15px
        //the y of the following output groups should be the y of previous group plus the height of the previous group plus 15px

        //get the y and the height of the previous output group if it exists
        const previousOutputGroupY = nodes.find((node) => node.id === `output_group_${index - 1}`)?.position?.y || 15;
        const previousOutputGroupHeight = nodes.find((node) => node.id === `output_group_${index - 1}`)?.data?.height || 0;

        return previousOutputGroupY + previousOutputGroupHeight + 15;
    };

    values?.output_groups?.map((output_group: any, index: number) => {

        nodes.push({
            id: `output_group_${index}`,
            type: "details",
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            position: {
                x: gridSize * 51,
                //each output group is 150px high, so we need to add 150px plus a 30px gap for each output group
                y: calculateOutputGroupY(output_group, index)
            },
            width: gridSize * 21,
            height: 150,
            draggable: true,
            data: {
                label: `Output Group ${index + 1}`,
                type: "output",
                width: gridSize * 21,
                height: output_group?.type === "hls" ? 165 : 150,
                hasChildren: output_group?.type === "hls" ? true : false,
                hideTarget: output_group?.type === "hls" ? true : false,
                items: [
                    { label: "Type", value: "", name: `output_groups.${index}.type` },
                    { label: "Segments", value: "", name: `output_groups.${index}.number_of_segments_in_playlist` },
                    { label: "Segment Duration", value: "", name: `output_groups.${index}.segment_duration` },
                    { label: "URL", value: "", name: `output_groups.${index}.output_url` }
                ],
                onClick: () => {
                    setActiveSidebarPanel(
                        output_group?.type === "hls" ? "output_group_hls" : output_group?.type === "dash" ? "output_group_dash" : "output_group_hls-fmp4"
                    );
                    setActiveSidebarTab("4");
                    setCollapseSidebar(false);
                    setActiveFormPath(`${index}`);
                }
            }
        });
    });

    // for each output_group.hls_muxer_playlists, add a node and make it a child of the output group if the type is HLS
    // Increase the height of the output group by 30px for each HLS muxer playlist

    values?.output_groups?.map((output_group: any, pindex: number) => {
        output_group?.type === "hls" &&
            output_group?.hls_muxer_playlists?.map((hls_muxer_playlist: any, index: number) => {
                nodes.push({
                    id: `output_group_${pindex}_hls_muxer_playlist_${index}`,
                    type: "details",
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    position: {
                        x: 15,
                        //each hls_muxer_playlist is 30px high, so we need to add 30px plus a 30px gap for each hls_muxer_playlist
                        //the first hls_muxer_playlist y should be set to 150px
                        y: 165 + index * 45
                    },
                    width: gridSize * 19,
                    height: 30,
                    draggable: false,
                    data: {
                        label: `HLS Muxer Playlist ${index + 1}`,
                        type: "hls_muxer_playlist",
                        width: gridSize * 19,
                        hideSource: true,
                        height: 30,
                        items: [],
                        onClick: () => {
                            setActiveSidebarPanel("output");
                            setActiveSidebarTab("4");
                            setCollapseSidebar(false);
                            setActiveFormPath(`output_group_${pindex}_hls_muxer_playlist_${index}`);
                        }
                    },
                    parentNode: `output_group_${pindex}`
                });

                // increate the height of the output group (pindex) by 150px
                nodes.find((node) => node.id === `output_group_${pindex}`)?.data?.height &&
                    (nodes.find((node) => node.id === `output_group_${pindex}`)!.data.height += 45);
            });
    });

    //recalculate the y position of the output groups
    values?.output_groups?.map((output_group: any, index: number) => {
        nodes.find((node) => node.id === `output_group_${index}`)!.position.y = calculateOutputGroupY(output_group, index);
    });



    // create the edges
    edges.push({
        id: `environment_to_global_track_configuration`,
        source: `environment`,
        sourceHandle: "a",
        target: `global_track_configuration`,
        targetHandle: "a",
        type: "default",
        animated: true
    });

    //direct to global track configuration
    values?.input_feed_source === "direct" &&
        edges.push({
            id: `direct_to_global_track_configuration`,
            source: `direct`,
            sourceHandle: "a",
            target: `global_track_configuration`,
            targetHandle: "a",
            type: "default",
            animated: true
        });

    //global track configuration to video tracks, audio tracks and data tracks
    edges.push({
        id: `global_track_configuration_to_video_tracks`,
        source: `global_track_configuration`,
        sourceHandle: "a",
        target: `individual_track_configuration.video_tracks`,
        targetHandle: "a",
        type: "default",
        animated: true
    });

    edges.push({
        id: `global_track_configuration_to_audio_tracks`,
        source: `global_track_configuration`,
        sourceHandle: "a",
        target: `individual_track_configuration.audio_tracks`,
        targetHandle: "a",
        type: "default",
        animated: true,
        style: { stroke: theme.palette.secondary.main, strokeWidth: 2, strokeDasharray: 3 }
    });

    edges.push({
        id: `global_track_configuration_to_data_tracks`,
        source: `global_track_configuration`,
        sourceHandle: "a",
        target: `individual_track_configuration.data_tracks`,
        targetHandle: "a",
        type: "default",
        animated: true,
        style: { stroke: theme.palette.tertiary?.main, strokeWidth: 2, strokeDasharray: 3 }
    });

    // none HLS data looks like this
    //  {
    //     "type": "dash",
    //     "number_of_segments_in_playlist": 1,
    //     "segment_duration": "1s",
    //     "video_track_indexes": [
    //       0,
    //       1
    //     ],
    //     "output_url": "http://ddaaa.daa.com/blaa.mpd",
    //     "audio_track_indexes": [
    //       {
    //         "track_index": 0,
    //         "variant_index": 0
    //       },
    //       {
    //         "track_index": 1,
    //         "variant_index": 1
    //       }
    //     ],
    //     "data_track_indexes": [
    //       0
    //     ]
    //   },

    // for each output_group.video_track_indexs, add an edge from the video track to the output group
    values?.output_groups?.map((output_group: any, index: number) => {
        output_group?.type !== "hls" &&
            output_group?.video_track_indexes?.map((video_track_index: any) => {
                edges.push({
                    id: `video_track_${video_track_index}_to_output_group_${index}`,
                    source: `video_track_${video_track_index}`,
                    sourceHandle: "a",
                    target: `output_group_${index}`,
                    targetHandle: "a",
                    type: "default",
                    animated: true
                });
            });

        //map the audio track variants to the output group
        output_group?.audio_track_indexes?.map((audio_track_index: any) => {
            edges.push({
                id: `audio_track_${audio_track_index.track_index}_variant_${audio_track_index.variant_index}_to_output_group_${index}`,
                source: `audio_track_${audio_track_index.track_index}_variant_${audio_track_index.variant_index}`,
                sourceHandle: "a",
                target: `output_group_${index}`,
                targetHandle: "a",
                type: "default",
                animated: true,
                style: { stroke: theme.palette.secondary.main, strokeWidth: 2, strokeDasharray: 3 }
            });
        });

        //map the data tracks to the output group
        output_group?.data_track_indexes?.map((data_track_index: any) => {
            edges.push({
                id: `data_track_${data_track_index}_to_output_group_${index}`,
                source: `data_track_${data_track_index}`,
                sourceHandle: "a",
                target: `output_group_${index}`,
                targetHandle: "a",
                type: "default",
                animated: true,
                style: { stroke: theme.palette.tertiary?.main, strokeWidth: 2, strokeDasharray: 3 }
            });
        });
    });

    //HLS data looks like this
    // {
    //     "type": "hls",
    //     "hls_muxer_playlists": [
    //       {
    //         "audio_track_indexes": [
    //           {
    //             "track_index": 0,
    //             "variant_index": 1
    //           },
    //           {
    //             "track_index": 1,
    //             "variant_index": 1
    //           }
    //         ],
    //         "video_track_index": 1,
    //         "data_track_indexes": []
    //       },
    //       {
    //         "audio_track_indexes": [
    //           {
    //             "track_index": 1,
    //             "variant_index": 0
    //           }
    //         ],
    //         "video_track_index": 0,
    //         "data_track_indexes": [1]
    //       }
    //     ],
    //     "number_of_segments_in_playlist": 1,
    //     "segment_duration": "1s",
    //     "output_url": "http://checkmeout.m3u8"
    //   }

    // for each output_group.hls_muxer_playlists, add an edge from the video track to the correct hls playlist within the output group
    // for example if the hls playlist has a video_track_index of 1, then the edge should be from video_track_1 to output_group_0_hls_muxer_playlist_0
    //output_group_${pindex}_hls_muxer_playlist_${index}
    values?.output_groups?.map((output_group: any, pindex: number) => {
        output_group?.type === "hls" &&
            output_group?.hls_muxer_playlists?.map((hls_muxer_playlist: any, index) => {
                hls_muxer_playlist?.video_track_index !== undefined &&
                    edges.push({
                        id: `video_track_${hls_muxer_playlist.video_track_index}_to_output_group_${pindex}_playlist_${index}`,
                        source: `video_track_${hls_muxer_playlist.video_track_index}`,
                        sourceHandle: "a",
                        target: `output_group_${pindex}_hls_muxer_playlist_${hls_muxer_playlist.video_track_index}`,
                        targetHandle: "a",
                        type: "default",
                        animated: true
                    });

                // map the audio track variants to the target: `output_group_${index}_hls_muxer_playlist_${hls_muxer_playlist}`
                hls_muxer_playlist?.audio_track_indexes?.map((audio_track_index: any) => {
                    edges.push({
                        id: `audio_track_${audio_track_index.track_index}_variant_${audio_track_index.variant_index}_to_output_group_${pindex}_playlist_${index}`,
                        source: `audio_track_${audio_track_index.track_index}_variant_${audio_track_index.variant_index}`,
                        sourceHandle: "a",
                        target: `output_group_${pindex}_hls_muxer_playlist_${index}`,
                        targetHandle: "a",
                        type: "default",
                        animated: true,
                        selected: false,
                        style: { stroke: theme.palette.secondary.main, strokeWidth: 2, strokeDasharray: 
                            //if this edge is selected, then make the stroke solid
                            edges.find((edge) => edge.id === `audio_track_${audio_track_index.track_index}_variant_${audio_track_index.variant_index}_to_output_group_${pindex}_playlist_${index}`)?.selected ? 0 : 3
                         }
                    });
                });

                //finally map the data tracks in the same way
                hls_muxer_playlist?.data_track_indexes?.map((data_track_index: any) => {
                    edges.push({
                        id: `data_track_${data_track_index}_to_output_group_${pindex}`,
                        source: `data_track_${data_track_index}`,
                        sourceHandle: "a",
                        target: `output_group_${pindex}_hls_muxer_playlist_${index}`,
                        targetHandle: "a",
                        type: "default",
                        animated: true,
                        style: { stroke: theme.palette.tertiary?.main, strokeWidth: 2, strokeDasharray: 3 }
                    });
                });


            });
    });

    const getNodes = () => {
        return nodes;
    };

    const getEdges = () => {
        return edges;
    };

    return {
        getNodes,
        getEdges
    };
};
