import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import {
    agent,
    IOrganisationListManager,
    IUpdateOrgUserPermissions,
    ManagingOrganisationListResponse,
    IUserInOrg,
    IUsersInOrg,
    IEnvironment,
    IEnvironments,
    RootStoreContext
} from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { toast } from "react-toastify";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloudIcon from "@mui/icons-material/Cloud";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { useTheme } from "@mui/material/styles";
import EditOrganisationForm from "./Components/EditOrganisationForm";
import CreateCustomPriceForm from "./Components/CreateCustomPriceForm";
import CreateOrganisationForm from "./Components/CreateOrganisationForm";
import OrganisationBilling from "./Components/OrganisationBilling";
import ConfirmSwitchToAccount from "./Components/ConfirmSwitchToAccount";
import UserDetails from "./Components/UserDetails";
import CreateModuleForm from "./Components/CreateModuleForm";
import RoleProvider from "components/RoleProvider";
import { DeleteModule } from "./Components/DeleteModule";
import DeleteIcon from "@mui/icons-material/Delete";
import { Cloud, Dns } from "@mui/icons-material";
import ConfirmEnableOwnCloud from "./Components/ConfirmEnableOwnCloud";
import ConfirmDisableOwnCloud from "./Components/ConfirmDisableOwnCloud";

const AdminOrganisations = observer(function AdminOrganisations() {
    const rootStore = useContext(RootStoreContext);

    const { setCurrentTheme } = rootStore.commonStore;

    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { openDrawer } = rootStore.drawerStore;

    const { user } = rootStore.userStore;

    const theme = useTheme();

    const [loading, setLoading] = useState(false);

    const [tabValue, setTabValue] = React.useState("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const [organisations, setOrganisations] = useState<IOrganisationListManager[]>();

    const [environments, setEnvironments] = useState<IEnvironments>({} as IEnvironments);
    const [environmentsLength, setEnvironmentsLength] = useState<Number>(0);
    const [transcoders, setTranscoders] = useState<any>();
    const [transcodersLength, setTranscodersLength] = useState<Number>(0);

    const [activeOrganisation, setActiveOrganisation] = useState<IOrganisationListManager>({} as IOrganisationListManager);
    const [activeOrganisationDetails, setActiveOrganisationDetails] = useState<any>();
    const [organisationUsers, setOrganisationUsers] = useState<any>();
    const [organisationUsersLength, setOrganisationUsersLength] = useState<Number>(0);
    const [availableModules, setAvailableModules] = useState<any>();

    const [billing, setBilling] = useState<any>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderSwitch = (param) => {
        switch (param) {
            case "AWS":
                return "Amazon Web Services";
            case "DO":
                return "Digital Ocean";
            case "GCP":
                return "Google Cloud Platform";
            default:
                return param;
        }
    };

    const getOrganisations = async () => {
        setLoading(true);
        await agent.Organisation.getManagingOrgListAdmin()
            .then((response: ManagingOrganisationListResponse) => {
                setOrganisations(response.data);
                console.log(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                toast.error("Error getting organisations");
            });
    };

    const getActiveOrganisationDetails = async (organisationId: number) => {
        await agent.Admin.getOrganisation(organisationId)
            .then((response: any) => {
                setActiveOrganisationDetails(response);
                console.log("Active Organisation Details", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getOrganisationUsers = async (organisationId: number) => {
        await agent.Admin.getUsersByOrganisation(organisationId)
            .then((response: IUsersInOrg) => {
                setOrganisationUsersLength(response.users.length);
                setOrganisationUsers(response.users);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSetActiveOrganisation = (organisation: IOrganisationListManager) => {
        setActiveOrganisation(organisation);
        getActiveOrganisationDetails(organisation.id);
        getOrganisationUsers(organisation.id);
        getEnvironments(organisation.id);
        getTranscoders(organisation.id);
        getBilling(organisation.id);
        //getMonthlyReport(organisation.id);
        getRetryMessages();
    };

    const getEnvironments = async (organisationId: number) => {
        await agent.Organisation.getOrgEnvironments(organisationId)
            .then((response: IEnvironments) => {
                setEnvironments(response);
                setEnvironmentsLength(response.data.length);
                console.log("Environments:", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getTranscoders = async (organisationId: number) => {
        await agent.Organisation.getOrgTranscoders(organisationId)
            .then((response: any) => {
                setTranscoders(response);
                setTranscodersLength(response.data.length);
                console.log("Transcoders", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getBilling = async (organisationId: number) => {
        await agent.Billing.getBillingByOrg(organisationId)
            .then((response: any) => {
                setBilling(response);
                console.log("Billing", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getMonthlyReport = async (organisationId: number) => {
        await agent.Organisation.getMonthlyReport(organisationId, "2023-02-01")
            .then((response: any) => {
                console.log("Monthly Report", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getRetryMessages = async () => {
        await agent.Admin.getRetryMessages()
            .then((response: any) => {
                console.log("Retry Messages", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const addRemoveOrganisationUser = async (permission: string, remove?: boolean) => {
        const payload: IUpdateOrgUserPermissions = remove
            ? {
                  organisation_id: activeOrganisation.id,
                  user_id: user!.user_id,
                  enabled: false
              }
            : {
                  organisation_id: activeOrganisation.id,
                  user_id: user!.user_id,
                  permission_type: permission,
                  enabled: true
              };
        await agent.Admin.updateUserPermissions(payload)
            .then((response: any) => {
                console.log(response);
                getOrganisationUsers(activeOrganisation.id);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const toggleMonthlyReporting = async (enable: boolean) => {
        await agent.Organisation.toggleMonthlyReporting(activeOrganisation.id, enable)
            .then((response: any) => {
                console.log(response.message);
                getActiveOrganisationDetails(activeOrganisation.id);
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    };

    const getAvailableModules = async () => {
        await agent.Admin.listModules()
            .then((response: any) => {
                setAvailableModules(response);
                console.log("Available Modules", response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const isModuleEnabled = (organization: any, moduleCode: string): boolean => {
        return Boolean(organization && organization.modules_available && organization.modules_available[moduleCode]);
    };

    const toggleModuleAccess = async (moduleCode: string, enable: boolean) => {
        await agent
            .getCleanInstance()
            .put("/organisations/" + activeOrganisationDetails.data.id, {
                modules_available: {
                    [moduleCode]: enable
                }
            })
            .then((response: any) => {
                console.log(response);
                getActiveOrganisationDetails(activeOrganisationDetails.data.id);
                getAvailableModules();
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    useEffect(() => {
        setCurrentTheme("light"); //why the fuck would you take this out Alex you dick. I do not miss you in the slightest.
        getOrganisations();
        getAvailableModules();

        return () => {
            setCurrentTheme("dark");
            closeDialog();
            setActiveOrganisation({} as IOrganisationListManager);
            setActiveOrganisationDetails({});
        };
    }, [closeDialog, setCurrentTheme]);

    useEffect(() => {
        setCurrentTheme("light");
    }, []);

    return (
        <View loading={loading}>
            <ViewHeader title="Organisations" subtitle="Admin">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                openDialog(
                                    <CreateOrganisationForm
                                        onSubmitted={() => {
                                            getOrganisations();
                                            closeDialog();
                                        }}
                                    />,
                                    "Add Organisation"
                                );
                            }}>
                            Add Organisation
                        </Button>
                    </div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <Content
                    leftContent={
                        <List sx={{ mt: 1 }} subheader={<ListSubheader>Organisations</ListSubheader>}>
                            {organisations?.map((organisation) => (
                                <ListItem key={organisation.id} disablePadding>
                                    <ListItemButton
                                        onClick={() => handleSetActiveOrganisation(organisation)}
                                        selected={organisation.id === activeOrganisation.id}>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>
                                            <ListItemText primary={organisation.name} />
                                            <div>
                                                {organisation.is_accounts_org && (
                                                    <div title="Accounts Organisation">
                                                        <AccountBalanceIcon sx={{ opacity: 0.7, fontSize: 14 }} />
                                                    </div>
                                                )}
                                            </div>
                                        </Box>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    }
                    leftWidth={280}
                    headerContent={
                        activeOrganisationDetails?.data && (
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", opacity: 0.8 }}>
                                        <Typography variant="h5" sx={{ marginRight: "10px" }}>
                                            {activeOrganisationDetails.data.name}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            aria-label="delete"
                                            onClick={() =>
                                                openDialog(
                                                    <EditOrganisationForm
                                                        organisationId={activeOrganisationDetails.data.id}
                                                        name={activeOrganisationDetails.data.name}
                                                        currency={activeOrganisationDetails.data.currency}
                                                        poNumber={activeOrganisationDetails.data.purchase_order_number}
                                                        organisationTags={activeOrganisationDetails.data.organisation_tags}
                                                        calculationMode={activeOrganisationDetails.data.tf_job_cost_calculation_mode}
                                                        marginPercentage={activeOrganisationDetails.data.tf_margin_percentage}
                                                        onSubmitted={() => {
                                                            getOrganisations();
                                                            getActiveOrganisationDetails(activeOrganisationDetails.data.id);
                                                            closeDialog();
                                                        }}
                                                    />,
                                                    "Edit " + activeOrganisation.name
                                                )
                                            }>
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", opacity: 0.8 }}>
                                        <div>
                                            <div style={{ marginRight: 10 }}>
                                                ID: <b>{activeOrganisationDetails.data.id}</b>&nbsp;&bull;&nbsp;Current Payment Method:{" "}
                                                <b>
                                                    {activeOrganisationDetails.data.payment_method === "free_credits"
                                                        ? "Free Credits"
                                                        : "Account"}
                                                </b>
                                                &nbsp;&bull;&nbsp;Account Currency:{" "}
                                                <b style={{ textTransform: "uppercase" }}>{activeOrganisationDetails.data.currency}</b>
                                                {activeOrganisationDetails.data.purchase_order_number && (
                                                    <>
                                                        &nbsp;&bull;&nbsp;PO Number:{" "}
                                                        <b style={{ textTransform: "uppercase" }}>
                                                            {activeOrganisationDetails.data.purchase_order_number}
                                                        </b>
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                Tags: <b>{activeOrganisationDetails.data.organisation_tags}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                    {tabValue === "6" && (
                                        <RoleProvider roles={["is_dev"]}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    openDialog(<CreateModuleForm getAvailableModules={getAvailableModules} />, "Add Module")
                                                }>
                                                Add Module
                                            </Button>
                                        </RoleProvider>
                                    )}
                                    {!activeOrganisation.is_accounts_org && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<AccountBalanceIcon />}
                                            onClick={() =>
                                                openDialog(
                                                    <ConfirmSwitchToAccount
                                                        organisation_id={activeOrganisation.id}
                                                        onSubmitted={() => {
                                                            getOrganisations();
                                                            getActiveOrganisationDetails(activeOrganisationDetails.data.id);
                                                        }}
                                                    />
                                                )
                                            }>
                                            Switch To Account
                                        </Button>
                                    )}
                                    <Button
                                        id="demo-customized-button"
                                        aria-controls={menuOpen ? "demo-customized-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={menuOpen ? "true" : undefined}
                                        variant="contained"
                                        color="secondary"
                                        disableElevation
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDownIcon />}>
                                        Add/Remove Myself
                                    </Button>
                                    <Menu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "demo-customized-button"
                                        }}
                                        sx={{ minWidth: 300 }}
                                        anchorEl={anchorEl}
                                        elevation={0}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right"
                                        }}
                                        open={menuOpen}
                                        onClose={handleMenuClose}>
                                        <MenuItem
                                            disableRipple
                                            onClick={() => {
                                                addRemoveOrganisationUser("admin");
                                                handleMenuClose();
                                            }}>
                                            Add myself as an admin
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={() => {
                                                addRemoveOrganisationUser("operator");
                                                handleMenuClose();
                                            }}>
                                            Add myself as an operator
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={() => {
                                                addRemoveOrganisationUser("monitor");
                                                handleMenuClose();
                                            }}>
                                            Add myself as a monitor
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={() => {
                                                addRemoveOrganisationUser("admin", true);
                                                handleMenuClose();
                                            }}>
                                            Remove myself
                                        </MenuItem>
                                    </Menu>
                                </Stack>
                            </Box>
                        )
                    }>
                    {activeOrganisation.name && (
                        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                            <TabContext value={tabValue}>
                                <Box>
                                    <TabList onChange={handleTabChange} aria-label="organisation-tabs">
                                        <Tab label={"Users (" + organisationUsersLength + ")"} value="1" />
                                        <Tab label={"Environments (" + environmentsLength + ")"} value="2" />
                                        <Tab label={"Transcoders (" + transcodersLength + ")"} value="3" />
                                        <Tab label="Pricing" value="4" />
                                        <Tab label="Billing" value="5" />
                                        <Tab label="Modules" value="6" />
                                        <Tab label="Own Cloud" value="7" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" sx={{ flex: 1, overflowY: "auto" }}>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {organisationUsers?.map((user: IUserInOrg) => (
                                            <Box
                                                key={user.id}
                                                onClick={() => {
                                                    openDrawer(<UserDetails data={user} />);
                                                }}
                                                sx={{
                                                    marginRight: 1,
                                                    marginBottom: 1,
                                                    backgroundColor: "rgba(160,160,160, 0.1)",
                                                    borderRadius: 1,
                                                    padding: "5px 10px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        backgroundColor: "rgba(160,160,160, 0.2)"
                                                    }
                                                }}>
                                                {user.is_root_user && (
                                                    <div style={{ fontSize: 20, marginRight: 10 }}>
                                                        <FontAwesomeIcon icon={["fal", "user-crown"]} title="Organisation Owner" />
                                                    </div>
                                                )}
                                                <div>
                                                    <div style={{ fontWeight: 600 }}>
                                                        {user.first_name || user.last_name ? (
                                                            <>
                                                                {user.first_name} {user.last_name}&nbsp;
                                                            </>
                                                        ) : (
                                                            <>No Name</>
                                                        )}
                                                    </div>
                                                    <div>{user.email}</div>
                                                </div>
                                            </Box>
                                        ))}
                                    </div>
                                </TabPanel>
                                <TabPanel value="2" sx={{ flex: 1, overflowY: "auto" }}>
                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Provider</TableCell>
                                                    <TableCell>Region</TableCell>
                                                    <TableCell>DNS Name</TableCell>
                                                    <TableCell>IP Address</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>mbps-in/out</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {environments?.data?.map((environment: IEnvironment) => (
                                                    <TableRow key={environment.id}>
                                                        <TableCell sx={{ fontWeight: 500 }}>{environment.name}</TableCell>
                                                        <TableCell>{environment.cloud_provider_human_name}</TableCell>
                                                        <TableCell>{environment.region}</TableCell>
                                                        <TableCell>{environment.dns_name}</TableCell>
                                                        <TableCell>{environment.public_ip}</TableCell>
                                                        <TableCell>
                                                            {environment.status === "stopped" ? (
                                                                <Chip
                                                                    label={environment.status}
                                                                    color="error"
                                                                    size="small"
                                                                    sx={{ textTransform: "capitalize" }}
                                                                />
                                                            ) : environment.status === "running" ? (
                                                                <Chip
                                                                    label={environment.status}
                                                                    color="success"
                                                                    size="small"
                                                                    sx={{ textTransform: "capitalize" }}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    label={environment.status}
                                                                    size="small"
                                                                    sx={{ textTransform: "capitalize" }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell>{environment.instance_size_commercial}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value="3" sx={{ flex: 1, overflowY: "auto" }}>
                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Created Date</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Cloud Provider</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {transcoders?.data?.map((transcoder: any, i: number) => (
                                                    <TableRow key={i}>
                                                        <TableCell style={{ fontWeight: 600 }}>{transcoder.id}</TableCell>
                                                        <TableCell>
                                                            {format(new Date(transcoder.created_on), "dd/MM/yyyy @ HH:mm")}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                <Chip
                                                                    sx={{ textTransform: "capitalize" }}
                                                                    size="small"
                                                                    label={transcoder.transcoder_status}
                                                                    color={
                                                                        transcoder.transcoder_status === "running" ? "success" : undefined
                                                                    }
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell>{transcoder.cloud_provider}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel
                                    value="4"
                                    sx={{
                                        flex: 1,
                                        overflowY: "auto",
                                        position: "relative",
                                        paddingTop: "0px !important",
                                        borderTop: "20px solid " + (theme.palette.mode === "dark" ? "#161e25" : "#f5f5f5")
                                    }}>
                                    {billing &&
                                        billing.prices.map((price: any) => {
                                            return (
                                                <Paper key={price.section_type} elevation={0} sx={{ mb: 2 }}>
                                                    <Accordion key={price.section_type}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1-content"
                                                            id="panel1-header"
                                                            sx={{ textTransform: "capitalize", position: "sticky", top: 0, zIndex: 1 }}>
                                                            {price.section_title}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TableContainer component={Box}>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell></TableCell>
                                                                            <TableCell>{price.size_column_title}</TableCell>
                                                                            <TableCell>Provider</TableCell>
                                                                            <TableCell>Updated</TableCell>
                                                                            <TableCell>Price USD</TableCell>
                                                                            <TableCell>Price GBP</TableCell>
                                                                            <TableCell>Price EUR</TableCell>
                                                                            <TableCell>Notes</TableCell>
                                                                            <TableCell width={50} align="right"></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {price.entries.map((entry: any) =>
                                                                            entry.prices
                                                                                .filter((price) => price.product_id !== null)
                                                                                .map((priceitem: any, i: number) => (
                                                                                    <TableRow key={i}>
                                                                                        <TableCell>
                                                                                            {priceitem.is_custom_price ? (
                                                                                                <Tooltip
                                                                                                    title={"Custom Price"}
                                                                                                    placement="top">
                                                                                                    <Box
                                                                                                        sx={{
                                                                                                            width: 10,
                                                                                                            height: 10,
                                                                                                            borderRadius: "5px",
                                                                                                            backgroundColor:
                                                                                                                theme.palette.warning.main
                                                                                                        }}></Box>
                                                                                                </Tooltip>
                                                                                            ) : undefined}
                                                                                        </TableCell>
                                                                                        <TableCell>{entry.size}</TableCell>
                                                                                        <TableCell sx={{ fontWeight: 600 }}>
                                                                                            {renderSwitch(priceitem.cloud_provider)}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {priceitem.updated_on
                                                                                                ? format(
                                                                                                      new Date(priceitem.updated_on),
                                                                                                      "dd/MM/yyyy @ HH:mm"
                                                                                                  )
                                                                                                : ""}
                                                                                        </TableCell>
                                                                                        <TableCell>{priceitem.usd}</TableCell>
                                                                                        <TableCell>{priceitem.gbp}</TableCell>
                                                                                        <TableCell>{priceitem.eur}</TableCell>
                                                                                        <TableCell>{priceitem.notes}</TableCell>
                                                                                        <TableCell>
                                                                                            <Tooltip title="Update Price" placement="top">
                                                                                                <IconButton
                                                                                                    aria-label="delete"
                                                                                                    onClick={() =>
                                                                                                        openDialog(
                                                                                                            <CreateCustomPriceForm
                                                                                                                organisationId={
                                                                                                                    activeOrganisationDetails
                                                                                                                        .data.id
                                                                                                                }
                                                                                                                onSubmitted={() => {
                                                                                                                    getBilling(
                                                                                                                        activeOrganisationDetails
                                                                                                                            .data.id
                                                                                                                    );
                                                                                                                    closeDialog();
                                                                                                                }}
                                                                                                                cloud_provider={
                                                                                                                    priceitem.cloud_provider
                                                                                                                }
                                                                                                                eur={priceitem.eur}
                                                                                                                gbp={priceitem.gbp}
                                                                                                                last_updated={
                                                                                                                    priceitem.updated_on
                                                                                                                }
                                                                                                                notes={priceitem.notes}
                                                                                                                product_id={
                                                                                                                    priceitem.product_id
                                                                                                                }
                                                                                                                section={
                                                                                                                    price.section_title
                                                                                                                }
                                                                                                                size={entry.size}
                                                                                                                usd={priceitem.usd}
                                                                                                            />,
                                                                                                            "Create Custom Price"
                                                                                                        )
                                                                                                    }>
                                                                                                    <EditIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Paper>
                                            );
                                        })}
                                </TabPanel>
                                <TabPanel value="5" sx={{ flex: 1, overflowY: "auto" }}>
                                    <OrganisationBilling organisation_id={activeOrganisation.id} />
                                    {activeOrganisation.is_accounts_org && (
                                        <Box sx={{ marginBottom: 3, marginTop: 3 }}>
                                            <Typography variant="h5" gutterBottom component="div">
                                                Monthly Report Generation
                                            </Typography>
                                            <Typography variant="body2" gutterBottom component="div">
                                                Monthly report generation is currently{" "}
                                                {activeOrganisationDetails && activeOrganisationDetails.data?.monthly_report_on
                                                    ? "Enabled"
                                                    : "Disabled"}
                                                .
                                            </Typography>
                                            {activeOrganisationDetails && activeOrganisationDetails.data?.monthly_report_on ? (
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    sx={{ mt: 2 }}
                                                    onClick={() => toggleMonthlyReporting(false)}>
                                                    Disable Monthly Report Generation
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ mt: 2 }}
                                                    onClick={() => toggleMonthlyReporting(true)}>
                                                    Enable Monthly Report Generation
                                                </Button>
                                            )}
                                        </Box>
                                    )}
                                </TabPanel>
                                <TabPanel value="6" sx={{ flex: 1, overflowY: "auto" }}>
                                    {activeOrganisationDetails &&
                                        availableModules?.data?.map((module: any) => (
                                            <Stack key={module.id} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Box
                                                    sx={{
                                                        mb: 2
                                                    }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isModuleEnabled(
                                                                    activeOrganisationDetails.data,
                                                                    module.module_code
                                                                )}
                                                                onClick={() => {
                                                                    //toggle module access
                                                                    console.log(
                                                                        "Toggle Module Access",
                                                                        !isModuleEnabled(activeOrganisationDetails.data, module.module_code)
                                                                    );

                                                                    toggleModuleAccess(
                                                                        module.module_code,
                                                                        !isModuleEnabled(activeOrganisationDetails.data, module.module_code)
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        label={module.module_name + " (" + module.module_code + ")"}
                                                    />
                                                </Box>
                                                <Tooltip title="Delete module">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            openDialog(
                                                                <DeleteModule id={module.id} getAvailableModules={getAvailableModules} />
                                                            );
                                                        }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Stack>
                                        ))}
                                </TabPanel>
                                <TabPanel value="7" sx={{ flex: 1, overflowY: "auto" }}>
                                    <Box>
                                        <Box>
                                            <Typography variant="h5" gutterBottom>
                                                AWS Own Cloud:{" "}
                                                {activeOrganisationDetails?.data?.own_cloud_aws_enabled ? "Enabled" : "Not Enabled"}
                                            </Typography>
                                            {activeOrganisationDetails?.data?.own_cloud_aws_enabled ? (
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmDisableOwnCloud
                                                                organisation_id={activeOrganisation.id}
                                                                cloud_provider="aws"
                                                                organisation_name={activeOrganisation.name}
                                                                onSuccessfulDisable={() => {
                                                                    getActiveOrganisationDetails(activeOrganisation.id);
                                                                }}
                                                            />,
                                                            "Disable Own Cloud"
                                                        );
                                                    }}>
                                                    Disable Own Cloud
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmEnableOwnCloud
                                                                organisation_id={activeOrganisation.id}
                                                                cloud_provider="aws"
                                                                organisation_name={activeOrganisation.name}
                                                                onSuccessfulEnable={() => {
                                                                    getActiveOrganisationDetails(activeOrganisation.id);
                                                                }}
                                                            />,
                                                            "Enable Own Cloud"
                                                        );
                                                    }}>
                                                    Enable Own Cloud
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </TabPanel>
                            </TabContext>
                        </div>
                    )}
                </Content>
            </ViewBody>
        </View>
    );
});

export default AdminOrganisations;
