import { RootStore } from "./rootStore";
import { action, makeObservable, observable } from "mobx";
import { IBillingQuantity, ICreditResponse, IBillingCreditsReport, IHourlyBreakDownResponse } from "api";

export default class BillingStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable quantity: IBillingQuantity | null = null;
    @observable billingInformation: ICreditResponse | null = null;
    @observable billingData: IBillingCreditsReport | null = null;
    @observable convertedStart = "";
    @observable convertedEnd = "";
    @observable selectedEnvironment: string | undefined = undefined;
    @observable selectedDestination: string | undefined = undefined;
    @observable hourlyBreakdownData: IHourlyBreakDownResponse | null = null;

    @action setQuantity = (quantity: IBillingQuantity) => {
        this.quantity = quantity;
    };

    @action setBillingInformation = (information: ICreditResponse) => {
        this.billingInformation = information;
    };

    @action setBillingData = (data: IBillingCreditsReport) => {
        this.billingData = data;
    };

    @action setConvertedStart = (date: string) => {
        this.convertedStart = date;
    };

    @action setConvertedEnd = (date: string) => {
        this.convertedEnd = date;
    };

    @action setSelectedEnvironment = (environment: string | undefined) => {
        this.selectedEnvironment = environment;
    };

    @action setSelectedDestination = (destination: string) => {
        this.selectedDestination = destination;
    };

    @action setHourlyBreakdownData = (data: IHourlyBreakDownResponse) => {
        this.hourlyBreakdownData = data;
    };
}
