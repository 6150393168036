import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import ScheduleContainer from "./Components/ScheduleContainer";
import ScheduleRow from "./Components/ScheduleRow";
import { ScheduleItem } from "./Components/ScheduleItem";
import {
    Fab,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Box,
    Button,
    Alert,
    AlertTitle,
    Stack,
    Typography,
    IconButton
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { addDays, addHours, differenceInMinutes, format, startOfDay, subDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PermanentScheduleItem from "./Components/PermanentScheduleItem";
import { RootStoreContext } from "api";
import CreateMultiScheduleForm from "../Components/CreateMultiScheduleForm";
import OrganisationRoleProvider from "components/OrganisationRoleProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Content from "components/Layout/Content";
import SchedulerViewSelect from "../Components/SchedulerViewSelect";
import CreateButton from "../Components/CreateButton";

const SchedulerDay: React.FC = observer(function Scheduler() {
    const navigate = useNavigate();
    const history = useLocation();
    const rootStore = React.useContext(RootStoreContext);
    const { date, setDate } = rootStore.schedulerStore;
    const { openDialog } = rootStore.dialogStore;
    const { openDrawer } = rootStore.drawerStore;
    const { token } = rootStore.commonStore;
    const { environment, currentEnvRunning } = rootStore.environmentStore;

    const [update, setUpdate] = React.useState<Date>(new Date());
    const [loading, setLoading] = React.useState<boolean>(false);

    const [zoom, setZoom] = React.useState(2);

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_env_id = rootStore.userStore.activeenvironment;

    const [data, setData] = React.useState<any>([]);

    const { client, clientConnected } = rootStore.resClientStore;

    const onUpdate = () => {
        setUpdate(new Date());
    };

    React.useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "+") {
                const currentZoom = zoom;
                const newZoom = currentZoom + 1;
                setZoom(newZoom);
            }
            if (event.key === "-" && zoom > 1) {
                const currentZoom = zoom;
                const newZoom = currentZoom - 1;
                setZoom(newZoom);
            }
        };
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [zoom]);

    useEffect(() => {
        setLoading(true);

        clientConnected &&
            client
                .get(`environments.outputs.${managing_org_id}.${active_env_id}`)
                .then((res: any) => {
                    res.on("add", () => {
                        onUpdate();
                    });

                    res.on("remove", () => {
                        onUpdate();
                    });
                    Array.from(res).map((destination: any) => {
                        destination.on("change", () => {
                            onUpdate();
                        });
                    });
                    setData(res);
                    setLoading(false);
                    onUpdate();
                    //console.log("Scheduler Data", res);
                })
                .catch((err) => {
                    console.error("Scheduler Error", err);
                    if (err.message === "No auth info provided") {
                        client.disconnect();
                        rootStore.resClientStore.initialize(token!);
                    }
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            setData([]);
        };
    }, [managing_org_id, rootStore.commonStore.token, clientConnected, client, active_env_id, token, currentEnvRunning]);

    useEffect(() => {}, [update]);

    return (
        <View loading={loading}>
            <ViewHeader title="Scheduler" subtitle={environment?.name || undefined} />
            <ViewBody noPadding>
                <Content
                    noPadding
                    toolbarContent={
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button variant="toolbar" onClick={() => {
                                    setDate(new Date());
                                }}>
                                    Today
                                </Button>
                                <Stack direction="row" spacing={0} alignItems="center">
                                    <IconButton
                                        onClick={() => setDate(subDays(date, 1))}
                                        size="small">
                                        <ChevronLeft />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => setDate(addDays(date, 1))}
                                        size="small">
                                        <ChevronRight />
                                    </IconButton>
                                </Stack>
                                <Typography variant="h5">{format(date, "EEEE do MMMM yyyy")}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <SchedulerViewSelect />
                                <CreateButton start_date={date} end_date={addHours(date, 1)} />
                            </Stack>
                        </Box>
                    }>
                    <ScheduleContainer zoom={zoom}>
                        {currentEnvRunning &&
                            data &&
                            Array.from(data).map((destination: any) => {
                                return (
                                    <ScheduleRow
                                        key={destination.destination_id}
                                        zoom={zoom}
                                        destination={destination.name}
                                        destination_id={destination.destination_id}
                                        connection_status={destination.connection_status}
                                        isPermanent={destination.is_permanently_scheduled}
                                        permanent_schedule_input_id={destination.permanent_schedule_input_id}
                                        permanent_schedule_input_name={destination.permanent_schedule_input_name}
                                        active_alerts={destination.active_alerts}>
                                        {destination &&
                                            !destination.is_permanently_scheduled &&
                                            destination.schedules
                                                .filter((scheduleItem: any) => {
                                                    const startTime = startOfDay(new Date(scheduleItem.start_time));
                                                    const endTime = startOfDay(new Date(scheduleItem.end_time));
                                                    const dateOnly = startOfDay(new Date(date));

                                                    return startTime <= dateOnly && endTime >= dateOnly;
                                                })
                                                .map((scheduleItem: any, i: number, arr: any[]) => {
                                                    let startMinute = differenceInMinutes(
                                                        new Date(scheduleItem.start_time),
                                                        startOfDay(new Date(scheduleItem.start_time))
                                                    );
                                                    let endMinute = differenceInMinutes(
                                                        new Date(scheduleItem.end_time),
                                                        startOfDay(new Date(scheduleItem.end_time))
                                                    );
                                                    let previousEndMinute: any = null; // initialize to null
                                                    let previousEndDate: any = null; // initialize to null

                                                    // check if there is a previous item in the array
                                                    if (i > 0) {
                                                        const previousItem = arr[i - 1];
                                                        previousEndMinute = differenceInMinutes(
                                                            new Date(previousItem.end_time),
                                                            startOfDay(new Date(previousItem.end_time))
                                                        );
                                                        previousEndDate = new Date(previousItem.end_time);
                                                    }

                                                    let nextStartMinute: any = null; // initialize to null
                                                    let nextStartDate: any = null; // initialize to null

                                                    // check if there is a next item in the array
                                                    if (i < arr.length - 1) {
                                                        const nextItem = arr[i + 1];
                                                        nextStartMinute = differenceInMinutes(
                                                            new Date(nextItem.start_time),
                                                            startOfDay(new Date(nextItem.start_time))
                                                        );
                                                        nextStartDate = new Date(nextItem.start_time);
                                                    }

                                                    return (
                                                        <ScheduleItem
                                                            key={scheduleItem.schedule_id}
                                                            schedule_id={scheduleItem.schedule_id}
                                                            destination_id={destination.destination_id}
                                                            name={scheduleItem.source_name}
                                                            startDate={scheduleItem.start_time}
                                                            endDate={scheduleItem.end_time}
                                                            startMinute={startMinute}
                                                            endMinute={endMinute}
                                                            previousEndMinute={previousEndMinute}
                                                            previousEndDate={previousEndDate}
                                                            nextStartMinute={nextStartMinute}
                                                            nextStartDate={nextStartDate}
                                                            zoom={zoom}
                                                            live={scheduleItem.ui_status === "on"}
                                                            finished={scheduleItem.ui_status === "finished"}
                                                            source_id={scheduleItem.source_id}
                                                            wont_start={scheduleItem.wont_start}
                                                        />
                                                    );
                                                })}
                                        {destination && destination.is_permanently_scheduled && (
                                            <PermanentScheduleItem
                                                source_id={destination.permanent_schedule_input_id}
                                                source_name={destination.permanent_schedule_input_name}
                                            />
                                        )}
                                    </ScheduleRow>
                                );
                            })}
                        {currentEnvRunning && data && data.length === 0 && !loading && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translateX(-50%)"
                                }}>
                                <Alert severity="info">
                                    <AlertTitle>No Destinations Found</AlertTitle>
                                    No destinations were found on this environment. Please click on{" "}
                                    <strong>+ &gt; Create Destination</strong> to add a new destination.
                                </Alert>
                            </Box>
                        )}
                        {!currentEnvRunning && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translateX(-50%)"
                                }}>
                                <Alert severity="info" sx={{ width: "37rem" }}>
                                    <AlertTitle>Environment Not Running</AlertTitle>
                                    The active environment is not running. Please start the environment to view destinations.
                                </Alert>
                            </Box>
                        )}
                    </ScheduleContainer>
                    <OrganisationRoleProvider roles={["admin", "operator"]}>
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{ position: "absolute", bottom: 43, right: 50 }}
                            icon={<SpeedDialIcon />}>
                            <SpeedDialAction
                                icon={<FontAwesomeIcon icon={["fal", "calendar-week"]} />}
                                tooltipTitle="Create Schedule"
                                onClick={() => openDialog(<CreateMultiScheduleForm />, "Create Schedule", "lg", true)}
                            />
                            <SpeedDialAction
                                icon={<FontAwesomeIcon icon={["fal", "video"]} />}
                                tooltipTitle="Create Source"
                                onClick={() => navigate(`/sources/create`, { state: { returnUrl: history.pathname } })}
                            />
                            <SpeedDialAction
                                icon={<FontAwesomeIcon icon={["fal", "broadcast-tower"]} />}
                                tooltipTitle="Create Destination"
                                onClick={() => navigate(`/destinations/create`, { state: { returnUrl: history.pathname } })}
                            />
                        </SpeedDial>
                    </OrganisationRoleProvider>
                    <Fab
                        size="small"
                        aria-label="add"
                        sx={{ position: "absolute", bottom: 50, right: 170, zIndex: 100 }}
                        onClick={() => setZoom(zoom - 1)}
                        disabled={zoom < 2}>
                        <ZoomOutIcon />
                    </Fab>
                    <Fab
                        size="small"
                        aria-label="add"
                        sx={{ position: "absolute", bottom: 50, right: 120, zIndex: 100 }}
                        onClick={() => setZoom(zoom + 1)}>
                        <ZoomInIcon />
                    </Fab>
                </Content>
            </ViewBody>
        </View>
    );
});

export default SchedulerDay;
