import { Box, Typography } from "@mui/material";
import React from "react";
import { IUserInOrg } from "api";
import { format } from "date-fns";

interface UserDetailsData {
    active_organisation_id: number;
    created_on: string;
    email: string;
    environment_id: string;
    first_name: string;
    id: number;
    is_admin: boolean;
    is_monitor: boolean;
    is_operator: boolean;
    is_root_user: boolean;
    is_superuser: boolean;
    last_name: string;
    locked_out_until: Date | null;
    permission_type: string;
    two_factor_enabled: boolean;
}

interface UserDetailsProps {
    data: IUserInOrg;
}

const UserDetails: React.FC<UserDetailsProps> = ({ data }) => {
    return (
        <Box sx={{ width: 500, padding: 3 }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h5">{data.first_name + " " + data.last_name}</Typography>
                <Typography fontSize={16}>{data.email}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Created On</Typography>
                <Typography>{format(new Date(data.created_on), "dd MMM yyyy HH:mm:ss")}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Permission Type</Typography>
                <Typography>{data.permission_type}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Environment ID</Typography>
                <Typography>{data.environment_id || "-"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Active Organisation ID</Typography>
                <Typography>{data.active_organisation_id || "-"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Is Admin</Typography>
                <Typography>{data.is_admin ? "Yes" : "No"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Is Monitor</Typography>
                <Typography>{data.is_monitor ? "Yes" : "No"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Is Operator</Typography>
                <Typography>{data.is_operator ? "Yes" : "No"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Is Root User</Typography>
                <Typography>{data.is_root_user ? "Yes" : "No"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Is Super User</Typography>
                <Typography>{data.is_superuser ? "Yes" : "No"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Locked Out Until</Typography>
                <Typography>{data.locked_out_until ? format(new Date(data.locked_out_until), "dd MMM yyyy HH:mm:ss") : "-"}</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                <Typography fontWeight="bold">Two Factor Enabled</Typography>
                <Typography>{data.two_factor_enabled ? "Yes" : "No"}</Typography>
            </Box>
        </Box>
    );
};

export default UserDetails;
