import { agent, IRegions } from "api";
import { SelectField } from "components/Forms";
import React from "react";
import { useFormState } from "react-final-form";

interface DirectSourceProps {
    nea_mode?: boolean;
}

const DirectSource: React.FC<DirectSourceProps> = ({ nea_mode }) => {
    const { values } = useFormState();

    const getCloudRegions = async (cloudCode: string) => {
        await agent.Environment.getRegions(cloudCode)
            .then((response: IRegions) => {
                setCloudRegions(response);
            })
            .catch((error) => {
                console.log("Invalid Provider", error);
            });
    };

    const [cloudRegions, setCloudRegions] = React.useState<IRegions | any>();

    React.useEffect(() => {
        if (values.cloud_provider) {
            getCloudRegions(values.cloud_provider);
        }
    }, [values.cloud_provider]);

    React.useEffect(() => {}, [values.cloud_provider, cloudRegions]);

    return (
        <>
            {!nea_mode && (
                <SelectField
                    name="cloud_provider"
                    label="Cloud Provider"
                    required
                    validate={(value) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                    options={
                        process.env.REACT_APP_CLIENT_NAME === "ateme"
                            ? [{ key: "aws", text: "Amazon Web Services", value: "aws" }]
                            : [
                                  { key: "aws", text: "Amazon Web Services", value: "aws" },
                                  { key: "gcp", text: "Google Cloud Platform", value: "gcp" },
                                  { key: "do", text: "Digital Ocean", value: "do" }
                              ]
                    }
                    onChange={(field, value) => {
                        getCloudRegions(value);
                    }}
                />
            )}
            {!nea_mode && values.cloud_provider && cloudRegions && (
                <>
                    <SelectField
                        name="region"
                        label="Region"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        options={cloudRegions.regions.map((region: any) => {
                            return { key: region.code, text: region.name, value: region.code };
                        })}
                    />
                </>
            )}
            {(values.region || nea_mode) && (
                <>
                    <SelectField
                        name="direct_feed_input_details.input_type"
                        label="Diect Source Type"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        options={[
                            { key: "srt_caller", text: "SRT Caller", value: "srt_caller" },
                            { key: "srt_listener", text: "SRT Listener", value: "srt_listener" },
                            { key: "zixi_pull", text: "Zixi Pull", value: "zixi_pull" }
                        ]}
                    />
                </>
            )}
        </>
    );
};

export default DirectSource;
