import { GppMaybe, Password, Verified } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { IUpdateUserRequest, RootStoreContext, agent } from "api";
import { TextField } from "components/Forms";
import Content from "components/Layout/Content";
import RoleProvider from "components/RoleProvider";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Form } from "react-final-form";
import { ChangePasswordModal } from "../profile/ChangePasswordModal";
import { DisableTwoFactor } from "features/settings/users/DisableTwoFactor";
import RegisterTwoFactorA from "../Registration/RegistrationFormItems/RegisterTwoFactor/RegisterTwoFactorA";

const UserSecurity: React.FC = () => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { user, updateUserPreferences, getUser } = rootStore.userStore;
    function stringAvatar(name: string) {
        return {
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        };
    }

    return (
        <Content>
            <Form
                onSubmit={(values: IUpdateUserRequest) =>
                    agent.User.updateUser(values)
                        .then((response) => {
                            console.log(response);
                            getUser();
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error
                        }))
                }
                render={({ submitError, handleSubmit, values, invalid, submitting, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <Typography variant="h5" gutterBottom>
                            <Password sx={{ mb: -0.4 }} />
                            &nbsp; Password
                        </Typography>
                        <Box mb={4} maxWidth={600}>
                            <Typography variant="body2" color="text.secondary" mb={3}>
                                {/* Write a description on what a secure password is and that the user should change their password regularlary */}
                                {/* Password must be at least 8 characters long */}
                                {/* Password must contain at least one number */}
                                {/* Password must contain at least one special character */}
                                {/* Password must contain at least one uppercase letter */}
                                You should change your password regularly to keep your account secure. Passwords must be at least 8
                                characters long and contain at least one number, one special character, and one uppercase letter.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    openDialog(<ChangePasswordModal />, undefined, "xs");
                                }}>
                                Change Password
                            </Button>
                        </Box>
                        <Typography variant="h5" gutterBottom>
                            <GppMaybe color="warning" sx={{ mb: -0.4 }} />&nbsp;
                            Two Factor Authentication (2FA)
                        </Typography>
                        <Box mb={2} maxWidth={600}>
                            <Typography variant="body2" color="text.secondary" mb={3}>
                                {/* Write a description on what two factor authentication is and how it can help secure the user's account */}
                                Two-factor authentication adds an extra layer of security to your account. When you log in, you'll need to
                                enter a code sent to your phone or email.
                            </Typography>
                            {!user?.two_factor_enabled ? (
                                <Typography variant="body2" color="text.secondary" mb={3}>
                                    Two-factor authentication is currently disabled.
                                </Typography>
                            ) : (
                                <Typography variant="body2" color="text.secondary" mb={3}>
                                    Two-factor authentication is currently enabled.
                                </Typography>
                            )}
                            {!user?.two_factor_enabled ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        openDialog(
                                            <Box sx={{ width: "100%" }}>
                                                <RegisterTwoFactorA />
                                            </Box>,
                                            undefined,
                                            "md"
                                        );
                                    }}>
                                    Enable Two Factor Authentication
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        openDialog(<DisableTwoFactor />);
                                    }}>
                                    Disable Two Factor Authentication
                                </Button>
                            )}
                        </Box>
                    </form>
                )}
            />
        </Content>
    );
};

export default observer(UserSecurity);
