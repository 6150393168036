import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { BillingInformationButton } from "./BillingInformationButton";
import { Box } from "@mui/material";

const BillingInformationSection = observer(function BillingInformationSection() {
    const rootStore = useContext(RootStoreContext);
    const { billingInformation } = rootStore.billingStore;

    return (
        <>
            {billingInformation && billingInformation.is_on_credits && <BillingInformationButton />}
            <Box sx={{ my: 2 }}>
                Current billing method:
                <span style={{ fontWeight: "bold", textTransform: "capitalize", display: "inline-block", marginLeft: 10 }}>
                    {billingInformation?.organisation_payment_method_ui}
                </span>
            </Box>
        </>
    );
});

export default BillingInformationSection;
