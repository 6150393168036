import React from "react";
import MUIVisibilityIcon from '@mui/icons-material/Visibility';
import MUIVisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface Props {
    isVisible?: boolean;
}

/**
 * Validation Icon is a React Functional Component that renders a validation icon (tick or cross).
 * It uses Material-UI icons.
 *
 * @component
 * @param {boolean} [props.isVisible] - Whether to render the visibility or visibilityOff icon.
 * @example
 * <VisibilityIcon isVisible={true} />
 *
 * @returns {ReactElement} The VisibilityIcon component
 */

export const VisibilityIcon: React.FC<Props> = ({
    isVisible = false
}) => {

    if (isVisible) {
        return <MUIVisibilityIcon />
    }

    return <MUIVisibilityOffIcon />
};

export default VisibilityIcon;
