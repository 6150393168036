import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import { agent, TFJobCostsListResponse, TFJobCostFilter, IFileClusterRegion, IFileClusterRegions } from "api";
import { format, intervalToDuration } from "date-fns";
import Card from "components/Card";
import { Pagination, SortDirection, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const Reporting = observer(function Reporting() {
    const [filter, setFilter] = React.useState<TFJobCostFilter>({
        created_at: {
            gt: format(new Date().setMonth(new Date().getMonth() - 1), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
            lt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'")
        },
        source_filename: "",
        destination_filename: "",
        sort: {
            field: "job_id",
            direction: "desc"
        },
        limit: 50,
        offset: 0
    });

    const handlePagination = (event: React.MouseEvent<HTMLAnchorElement>, page: number) => {
        setFilter({ ...filter, offset: (page - 1) * filter.limit });
    };

    const handleSort = (field: string) => {
        if (filter?.sort?.direction === "desc") {
            setFilter({ ...filter, sort: { field: field, direction: "asc" } });
        } else {
            setFilter({ ...filter, sort: { field: field, direction: "desc" } });
        }
        return null;
    };

    const handleCreatedDateGtChange = (date: any) => {
        const formattedDate = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        setFilter({
            ...filter,
            created_at: { gt: formattedDate, lt: filter.created_at.lt },
            offset: 0
        });
    };

    const handleCreatedDateLtChange = (date: any) => {
        const formattedDate = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        setFilter({
            ...filter,
            created_at: { gt: filter.created_at.gt, lt: formattedDate },
            offset: 0
        });
    };

    const handleCostGtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            cost_calculated: { gt: Number(event.target.value) },
            offset: 0
        });
    };

    const handleCostLtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            cost_calculated: { lt: Number(event.target.value) },
            offset: 0
        });
    };

    const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) {
            setFilter((filter: any) => {
                const copy = { ...filter };
                delete copy["id"];
                return copy;
            });
        } else {
            setFilter({ ...filter, id: event.target.value, offset: 0 });
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setFilter((filter: any) => {
                const copy = { ...filter };
                delete copy["name"];
                return copy;
            });
        } else {
            setFilter({ ...filter, name: event.target.value, offset: 0 });
        }
    };

    const handleDeplotmentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setFilter((filter: any) => {
                const copy = { ...filter };
                delete copy["deployment_name"];
                return copy;
            });
        } else {
            setFilter({ ...filter, deployment_name: event.target.value, offset: 0 });
        }
    };

    const handleSourceFilenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setFilter((filter: any) => {
                const copy = { ...filter };
                delete copy["source_filename"];
                return copy;
            });
        } else {
            setFilter({ ...filter, source_filename: event.target.value, offset: 0 });
        }
    };

    const handleDestinationFilenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setFilter({ ...filter, destination_filename: "", offset: 0 });
        } else {
            setFilter({
                ...filter,
                destination_filename: event.target.value,
                offset: 0
            });
        }
    };

    const [statusValue, setStatusValue] = useState("any");
    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatusValue(event.target.value);
        if (event.target.value === "any") {
            setFilter({ ...filter, status: "", offset: 0 });
        } else {
            setFilter({ ...filter, status: event.target.value, offset: 0 });
        }
    };

    const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "any") {
            setFilter({ ...filter, region: "", offset: 0 });
        } else {
            setFilter({ ...filter, region: event.target.value, offset: 0 });
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setFilter({ ...filter, type: undefined, offset: 0 });
        } else {
            setFilter({ ...filter, type: event.target.value, offset: 0 });
        }
    };

    const [regions, setRegions] = useState<IFileClusterRegion[]>();
    const getRegions = async () => {
        await agent.FileCluster.getRegions()
            .then((values) => {
                setRegions(values.data);
            })
            .catch((err) => {
                const message: any = err;
                console.log(message.data.message);
            });
    };

    const [jobsReport, setJobsReport] = useState<TFJobCostsListResponse>();
    const getJobsReport = async () => {
        await agent.Reporting.getJobsReport(filter)
            .then((values) => {
                setJobsReport(values);
            })
            .catch((err) => {
                const message: any = err;
                console.log(message.data.message);
            });
    };

    useEffect(() => {
        if (!jobsReport) {
            getJobsReport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobsReport]);

    useEffect(() => {
        getJobsReport();
        if (!regions) {
            getRegions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, setFilter]);

    return (
        <View>
            <ViewHeader title="Reporting" subtitle="File Reporting">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <div></div>
                    <div>
                        <Pagination
                            count={Math.ceil((jobsReport?.totals?.count || 0) / filter.limit)}
                            page={filter.offset / filter.limit + 1}
                            shape="rounded"
                            onChange={(event: any, page: number) => {
                                handlePagination(event, page);
                            }}
                        />
                    </div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row"
                    }}>
                    <div
                        style={{
                            height: "100%",
                            width: 280,
                            backgroundColor: "rgba(0,0,0,0.2)",
                            padding: 20,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    //disableToolbar
                                    //variant="inline"
                                    format="dd/MM/yyyy"
                                    //id="created-date-from"
                                    label="Created Date From"
                                    value={new Date(filter.created_at.gt)}
                                    onChange={handleCreatedDateGtChange}
                                    disableFuture={true}
                                    className="testtest"
                                />
                                <DatePicker
                                    //disableToolbar
                                    //variant="inline"
                                    format="dd/MM/yyyy"
                                    //id="created-date-to"
                                    label="Created Date To"
                                    value={new Date(filter.created_at.lt)}
                                    onChange={handleCreatedDateLtChange}
                                />
                            </LocalizationProvider>

                            <TextField
                                variant="standard"
                                name="job-id"
                                id="job-id"
                                label="Job ID"
                                onChange={handleIdChange}
                                defaultValue={filter.id}
                            />

                            <TextField variant="standard" name="name" id="job-name" label="Job Name" onChange={handleNameChange} />

                            <TextField
                                variant="standard"
                                name="deployment-name"
                                id="deployment-name"
                                label="Deployment Name"
                                onChange={handleDeplotmentNameChange}
                            />

                            <TextField
                                variant="standard"
                                name="source-filename"
                                id="source-filename"
                                label="Source Filename"
                                onChange={handleSourceFilenameChange}
                            />

                            <TextField
                                variant="standard"
                                name="destination-filename"
                                id="destination-filename"
                                label="Destination Filename"
                                onChange={handleDestinationFilenameChange}
                            />

                            <TextField
                                variant="standard"
                                name="status"
                                id="job-status"
                                label="Status"
                                onChange={handleStatusChange}
                                value={statusValue}
                                select>
                                <MenuItem value="any" selected>
                                    Any
                                </MenuItem>
                                <MenuItem value="finished">Finished</MenuItem>
                                <MenuItem value="aborted">Aborted</MenuItem>
                                <MenuItem value="crashed">Crashed</MenuItem>
                            </TextField>

                            <TextField
                                variant="standard"
                                name="region"
                                id="region"
                                label="Region"
                                onChange={handleRegionChange}
                                value={filter.region || "any"}
                                select>
                                <MenuItem value="any" selected>
                                    Any
                                </MenuItem>
                                {regions?.map((region, i) => {
                                    return (
                                        <MenuItem key={i} value={region.code} selected>
                                            {region.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>

                            <TextField variant="standard" name="job_type" id="job_type" label="Job Type" onChange={handleTypeChange} />

                            <TextField
                                variant="standard"
                                name="cost_gt"
                                id="cost_gt"
                                label="Cost Greater Than"
                                onChange={handleCostGtChange}
                            />

                            <TextField
                                variant="standard"
                                name="cost_lt"
                                id="cost_lt"
                                label="Cost Less Than"
                                onChange={handleCostLtChange}
                            />
                        </div>
                        {/* <Button onClick={handleResetFilters} style={{ width: '100%' }}>Reset Filters</Button> */}
                    </div>
                    <div style={{ flexGrow: 1, padding: "20px", overflowY: "scroll" }}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}>
                            <Card style={{ width: "25%", marginRight: 5, marginBottom: 5 }}>
                                <div style={{ fontSize: 30, fontWeight: 600, marginBottom: 5 }}>{jobsReport?.totals.count}</div>
                                <div>Results</div>
                            </Card>
                            <Card
                                style={{
                                    width: "25%",
                                    marginLeft: 5,
                                    marginRight: 5,
                                    marginBottom: 5
                                }}>
                                <div style={{ fontSize: 30, fontWeight: 600, marginBottom: 5 }}>
                                    ${jobsReport?.totals.sum.usd.toFixed(2)}
                                </div>
                                <div>Total Price USD</div>
                            </Card>
                            <Card
                                style={{
                                    width: "25%",
                                    marginLeft: 5,
                                    marginRight: 5,
                                    marginBottom: 5
                                }}>
                                <div style={{ fontSize: 30, fontWeight: 600, marginBottom: 5 }}>
                                    £{jobsReport?.totals.sum.gbp.toFixed(2)}
                                </div>
                                <div>Total Price GBP</div>
                            </Card>
                            <Card style={{ width: "25%", marginLeft: 5, marginBottom: 5 }}>
                                <div style={{ fontSize: 30, fontWeight: 600, marginBottom: 5 }}>
                                    €{jobsReport?.totals.sum.eur.toFixed(2)}
                                </div>
                                <div>Total Price EUR</div>
                            </Card>
                        </div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "job_id" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "job_id"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("job_id")}>
                                            ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "job_name" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "job_name"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("job_name")}>
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "job_type" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "job_type"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("job_type")}>
                                            Type
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "deployment_name" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "deployment_name"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("deployment_name")}>
                                            Deployment
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "region" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "region"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("region")}>
                                            Region
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "cost_calculated" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "cost_calculated"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("cost_calculated")}>
                                            Cost
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "created_at" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "created_at"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("created_at")}>
                                            Created Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "status" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "status"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("status")}>
                                            Status
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "job_start_time" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "job_start_time"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("job_start_time")}>
                                            Times
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "duration" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "duration"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("duration")}>
                                            Duration
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={filter?.sort?.field === "source_filename" && (filter?.sort?.direction! as SortDirection)}>
                                        <TableSortLabel
                                            sx={{ fontSize: 11 }}
                                            active={filter?.sort?.field === "source_filename"}
                                            direction={filter?.sort?.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort("source_filename")}>
                                            Files
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {jobsReport?.data.map((job, i) => (
                                    <TableRow hover key={i}>
                                        <TableCell sx={{ fontSize: 11 }}>{job.job_id}</TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>{job.job_name}</TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>{job.job_type}</TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>{job.deployment_name}</TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>{job.region}</TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>
                                            {job.cost_calculated} {job.currency}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>{format(new Date(job.created_at), "dd/MM/yyyy @ HH:mm")}</TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>{job.status}</TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>
                                            S: {format(new Date(job.job_start_time), "dd/MM/yyyy @ HH:mm")}
                                            <br />
                                            F: {format(new Date(job.job_finish_time), "dd/MM/yyyy @ HH:mm")}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>
                                            {intervalToDuration({
                                                start: 0,
                                                end: job.duration * 1000
                                            }).hours +
                                                "h " +
                                                intervalToDuration({
                                                    start: 0,
                                                    end: job.duration * 1000
                                                }).minutes +
                                                "m " +
                                                intervalToDuration({
                                                    start: 0,
                                                    end: job.duration * 1000
                                                }).seconds +
                                                "s"}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 11 }}>
                                            <>
                                                S: {job.source_filename}
                                                <br />
                                                D: {job.destination_filename}
                                            </>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </ViewBody>
        </View>
    );
});

export default Reporting;
