import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from '@fortawesome/fontawesome-svg-core';


type ItemAccordionProps = {
    id: string;
    icon: IconName;
    name: string;
    children?: JSX.Element;
    defaultExpanded: boolean;
}



const ItemAccordion: React.FC<ItemAccordionProps> = ({ id, icon, name, children, defaultExpanded }) => {

    const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

    React.useEffect(() => {
        setExpanded(defaultExpanded)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultExpanded
    ]);

    // const ItemAccordionStyles = {
    // }

    const ItemAccordionHeaderStyles = {
        fontWeight: 600,
        padding: '5px 10px 5px 15px',
        backgroundColor: 'rgba(160,160,160,0.05)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '12px',
        cursor: 'pointer'
    }

    const ItemAccordionBodyStyles = {
        overflow: 'hidden',
        height: expanded ? 'auto' : '0px'
    }

    return (
        <div>
            <div style={ItemAccordionHeaderStyles} onClick={() => setExpanded(!expanded)}>
                <div><FontAwesomeIcon icon={["fal", icon]} />&nbsp;&nbsp;{name}</div>
                <div><FontAwesomeIcon icon={["fal", expanded ? "chevron-up" : "chevron-down"]} /></div>
            </div>
            <div style={ItemAccordionBodyStyles}>
                {(expanded || defaultExpanded) && children}
            </div>
        </div>
    )
}

export default ItemAccordion;