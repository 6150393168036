import {
    Alert,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Grid,
    ListSubheader,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import React, { useContext } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QuickAccessButton from "./Components/QuickAccessButton";
import { Link, useNavigate } from "react-router-dom";
import Content from "components/Layout/Content";
import NotificationsList from "features/notifications/Components/NotificationsList";
import SourceList from "features/sources/Components/SourceList";
import EnvironmentsList from "features/environments/Components/EnvironmentsList";
import { RootStoreContext, KnowledgeHubSelectorType } from "api";
import DestinationsList from "features/destinations/Components/DestinationsList";
import { endOfDay, startOfDay, startOfWeek, startOfMonth, set } from "date-fns";
import BillingSummaryPie from "features/billing/Components/BillingSummaryPie";
import ScheduleOverviewWidget from "features/scheduler/Widgets/ScheduleOverview";
import UserActivityWidget from "features/userActivity/Widgets/UserActivity";
import ModuleAccessProvider from "components/ModuleAccessProvider";
import { version } from "data/version";
import SidebarTabs, { SidebarTab, SidebarTabPanel } from "components/SidebarTabs";
import { HelpOutlined, InfoOutlined, Newspaper, NotificationImportant } from "@mui/icons-material";
import KnowledgeHub from "features/knowledgeHub";
import useRoleProvider from "hooks/UseRoleProvider";
import { is } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import { EmailProvider } from "components";
import { useConstants, useEmailProvider } from "hooks";

export const Dashboard: React.FC = () => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const { environment, currentEnvRunning } = rootStore.environmentStore;
    const navigate = useNavigate();
    const [collapseSidebar, setCollapseSidebar] = React.useState<boolean>(true);
    const [activeSidebarTab, setActiveSidebarTab] = React.useState<number | null>(null);
    const { constants } = useConstants();

    const is_developer: Boolean = useRoleProvider(["is_dev"], false);
    const isFrontend: Boolean = useEmailProvider(constants.cerberus.emails.frontend);

    const [selectedBillingRange, setSelectedBillingRange] = React.useState<string>("today"); // ["today", "week", "month"
    const [datesScope, setDatesScope] = React.useState<any>([new Date(), new Date()]);

    const setToday = () => {
        // use date-fns to get the start/end dates of the week
        const start = startOfDay(new Date());
        const end = endOfDay(new Date());
        setDatesScope([start, end]);
        setSelectedBillingRange("today");
    };

    const setThisWeek = () => {
        // use date-fns to get the start/end dates of the week
        const start = startOfWeek(new Date(), { weekStartsOn: 1 });
        const end = endOfDay(new Date());
        setDatesScope([start, end]);
        setSelectedBillingRange("week");
    };

    const setThisMonth = () => {
        // use date-fns to get the start/end dates of the week
        const start = startOfMonth(new Date());
        const end = endOfDay(new Date());
        setDatesScope([start, end]);
        setSelectedBillingRange("month");
    };

    return (
        <View>
            <ViewHeader title="Dashboard" />
            <ViewBody noPadding>
                <Content
                    rightWidth={isFrontend ? 51 : 300}
                    rightContent={
                        isFrontend ? (
                            <ClickAwayListener
                                onClickAway={() => {
                                    setCollapseSidebar(true);
                                    setActiveSidebarTab(null);
                                }}>
                                <Box
                                    sx={{
                                        position: "fixed",
                                        top: 65,
                                        right: 0,
                                        bottom: 0,
                                        width: collapseSidebar ? 51 : 500,
                                        overflow: "visible",
                                        zIndex: 1,
                                        backgroundColor: theme.palette.contentSidebar?.main
                                    }}>
                                    <SidebarTabs
                                        tabs={
                                            <>
                                                <SidebarTab
                                                    label="News / Updates"
                                                    icon={<Newspaper />}
                                                    active={!collapseSidebar && activeSidebarTab === 1}
                                                    onClick={() => {
                                                        setActiveSidebarTab(1);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                                <SidebarTab
                                                    label="Knowledge Hub"
                                                    icon={<HelpOutlined />}
                                                    active={!collapseSidebar && activeSidebarTab === 2}
                                                    onClick={() => {
                                                        setActiveSidebarTab(2);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                                <SidebarTab
                                                    label="Notifications"
                                                    icon={<NotificationImportant />}
                                                    active={!collapseSidebar && activeSidebarTab === 3}
                                                    onClick={() => {
                                                        setActiveSidebarTab(3);
                                                        setCollapseSidebar(false);
                                                    }}
                                                />
                                            </>
                                        }
                                        panels={
                                            <>
                                                <SidebarTabPanel active={!collapseSidebar && activeSidebarTab === 1}>
                                                    <Box sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <ListSubheader>News / Updates</ListSubheader>
                                                        {/* TODO: Add the correct content here */}
                                                        <Box sx={{ p: 2 }}>Lorem ipsum...</Box>
                                                    </Box>
                                                </SidebarTabPanel>
                                                <SidebarTabPanel active={!collapseSidebar && activeSidebarTab === 2}>
                                                    <Box sx={{ flexGrow: 1, overflowY: "scroll", p: 2 }}>
                                                        <KnowledgeHub entry={KnowledgeHubSelectorType.Homepage} isSidebar />
                                                    </Box>
                                                </SidebarTabPanel>
                                                <SidebarTabPanel active={!collapseSidebar && activeSidebarTab === 3}>
                                                    <Box sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                                        <ListSubheader>Notifications</ListSubheader>
                                                        <NotificationsList />
                                                    </Box>
                                                </SidebarTabPanel>
                                            </>
                                        }
                                        collapsible={true}
                                        collapsed={collapseSidebar}
                                        onCollapse={() => setCollapseSidebar(!collapseSidebar)}
                                    />
                                </Box>
                            </ClickAwayListener>
                        ) : (
                            <Box>
                                <ListSubheader>Notifications</ListSubheader>
                                <NotificationsList />
                            </Box>
                        )
                    }
                    footerContent={
                        is_developer && (
                            <Box>
                                <Typography>
                                    {version} |{" "}
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: theme.palette.primary.main
                                        }}
                                        to="/release-notes">
                                        Release Notes
                                    </Link>
                                </Typography>
                            </Box>
                        )
                    }>
                    <Box
                        sx={{
                            width: "100%",
                            p: 2
                        }}>
                        <Box sx={{ mb: 6 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mb: 2
                                }}>
                                <Box sx={{ width: 50, height: 50, mr: 1 }}>
                                    {process.env.REACT_APP_CLIENT_NAME === "default" ? (
                                        <img
                                            src={require("assets/logo/icon-logo.png")}
                                            alt="placeholder"
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    ) : (
                                        <img
                                            src={require("client/" + process.env.REACT_APP_CLIENT_NAME + "/assets/logo/icon-logo.png")}
                                            alt="placeholder"
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    )}
                                </Box>
                                <Typography variant="h3">Welcome</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                    You are working in{" "}
                                    <Link
                                        style={{
                                            color: theme.palette.primary.main,
                                            textDecoration: "none"
                                        }}
                                        to="/settings/organisations">
                                        {user!.active_org_name}
                                    </Link>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body1">{`Organisation: ${user!.active_org_name}`}</Typography>
                            </Box>
                            <Box>
                                {environment?.name ? (
                                    <Typography variant="body1">{`Active Environment: ${environment.name}${
                                        !currentEnvRunning ? " (Not running)" : ""
                                    }`}</Typography>
                                ) : (
                                    <Typography variant="body1">{`No environments active`}</Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ mb: 8 }}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => navigate("/environments/create")}>
                                    Create an Environment
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => {
                                        navigate("/sources/create");
                                    }}>
                                    Create a Source
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => {
                                        navigate("/destinations/create");
                                    }}>
                                    Create a Destination
                                </Button>
                            </Stack>
                        </Box>

                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Quick Access
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <QuickAccessButton
                                        title="Environments"
                                        description="Manage your cloud environments"
                                        icon="server"
                                        navigateTo="/environments"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <QuickAccessButton
                                        title="Sources"
                                        description="Manage sources and transcoders"
                                        icon="video"
                                        navigateTo="/sources"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <QuickAccessButton
                                        title="Destinations"
                                        description="Manage destinations"
                                        icon="broadcast-tower"
                                        navigateTo="/destinations"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <QuickAccessButton
                                        title="Scheduler"
                                        description="Schedule sources on destinations"
                                        icon="calendar-week"
                                        navigateTo="/scheduler"
                                    />
                                </Grid>
                                <ModuleAccessProvider module="push_packager">
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <QuickAccessButton
                                            title="Push Packager"
                                            description="Manage push packagers"
                                            icon="arrow-right-long-to-line"
                                            navigateTo="/packager"
                                        />
                                    </Grid>
                                </ModuleAccessProvider>
                                <ModuleAccessProvider module="nealive">
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <QuickAccessButton
                                            title="Pull Packager"
                                            description="Manage pull packagers"
                                            icon="arrow-left-long-to-line"
                                            navigateTo="/pull-packager"
                                        />
                                    </Grid>
                                </ModuleAccessProvider>
                                <ModuleAccessProvider module="file_transcoding">
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <QuickAccessButton
                                            title="File Transcoding"
                                            description="Manage file transcoding"
                                            icon="file-video"
                                            navigateTo="/file"
                                        />
                                    </Grid>
                                </ModuleAccessProvider>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <QuickAccessButton
                                        title="Billing"
                                        description="Access billing and pricing information"
                                        icon="dollar-sign"
                                        navigateTo="/billing"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <QuickAccessButton
                                        title="Notifications"
                                        description="View all notifications"
                                        icon="bell"
                                        navigateTo="/notifications"
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ mt: 6 }}>
                            <Typography variant="h5" gutterBottom>
                                System Overview
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={6} xl={4}>
                                    <Card>
                                        <CardHeader title="Sources" />
                                        <CardContent
                                            sx={{
                                                height: 300,
                                                overflowY: "auto",
                                                p: 0,
                                                "&.MuiCardContent-root:last-child": { padding: 0 }
                                            }}>
                                            {currentEnvRunning ? (
                                                <SourceList />
                                            ) : (
                                                <Box sx={{ p: 2 }}>
                                                    <Alert severity="info">
                                                        <AlertTitle>Environment Not Running</AlertTitle>
                                                        The active environment is not running. Please start the environment to view sources.
                                                    </Alert>
                                                </Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={12} lg={6} xl={4}>
                                    <Card>
                                        <CardHeader title="Destinations" />
                                        <CardContent
                                            sx={{
                                                height: 300,
                                                overflowY: "auto",
                                                p: 0,
                                                "&.MuiCardContent-root:last-child": { padding: 0 }
                                            }}>
                                            {currentEnvRunning ? (
                                                <DestinationsList />
                                            ) : (
                                                <Box sx={{ p: 2 }}>
                                                    <Alert severity="info">
                                                        <AlertTitle>Environment Not Running</AlertTitle>
                                                        The active environment is not running. Please start the environment to view
                                                        destinations.
                                                    </Alert>
                                                </Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={12} lg={6} xl={4}>
                                    <Card>
                                        <CardHeader title="Schedule" />
                                        <CardContent
                                            sx={{
                                                height: 300,
                                                overflowY: "auto",
                                                p: 0,
                                                "&.MuiCardContent-root:last-child": { padding: 0 }
                                            }}>
                                            {currentEnvRunning ? (
                                                <ScheduleOverviewWidget />
                                            ) : (
                                                <Box sx={{ p: 2 }}>
                                                    <Alert severity="info">
                                                        <AlertTitle>Environment Not Running</AlertTitle>
                                                        The active environment is not running. Please start the environment to view the
                                                        schedule.
                                                    </Alert>
                                                </Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item md={12} lg={6} xl={4}>
                                    <Card>
                                        <CardHeader title="Environments" />
                                        <CardContent sx={{ height: 300, overflowY: "auto", p: 0 }}>
                                            <EnvironmentsList />
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item md={12} lg={6} xl={4}>
                                    <Card>
                                        <CardHeader title="Billing" />
                                        <CardContent
                                            sx={{
                                                height: 300,
                                                overflowY: "auto",
                                                p: 0,
                                                "&.MuiCardContent-root:last-child": { padding: 0 }
                                            }}>
                                            <ButtonGroup
                                                variant="outlined"
                                                aria-label="outlined button group"
                                                sx={{ width: "100%", justifyContent: "center" }}>
                                                <Button
                                                    onClick={() => setToday()}
                                                    variant={selectedBillingRange === "today" ? "contained" : "outlined"}>
                                                    Today
                                                </Button>
                                                <Button
                                                    onClick={() => setThisWeek()}
                                                    variant={selectedBillingRange === "week" ? "contained" : "outlined"}>
                                                    This Week
                                                </Button>
                                                <Button
                                                    onClick={() => setThisMonth()}
                                                    variant={selectedBillingRange === "month" ? "contained" : "outlined"}>
                                                    This Month
                                                </Button>
                                            </ButtonGroup>
                                            <Box sx={{ width: "100%", height: "80%", mt: 1 }}>
                                                <BillingSummaryPie datesScope={datesScope} />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item md={12} lg={6} xl={4}>
                                    <Card>
                                        <CardHeader title="User Activity" />
                                        <CardContent
                                            sx={{
                                                height: 300,
                                                overflowY: "auto",
                                                p: 0,
                                                "&.MuiCardContent-root:last-child": { padding: 0 }
                                            }}>
                                            <UserActivityWidget />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(Dashboard);
