import { Delete } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme
} from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext, useEffect, useState } from "react";

interface ConfirmDeleteCredentialsProps {
    id: string;
    name: string;
}

interface CredentialsListProps {
    cluster_id: string;
}

const CredentialsList: React.FC<CredentialsListProps> = ({ cluster_id }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const theme = useTheme();
    const [credentials, setCredentials] = useState<any[]>([]);

    const fetchCredentials = async () => {
        await agent.FileCluster.getCredentials(cluster_id)
            .then((response) => {
                setCredentials(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const ConfirmDeleteCredentials: React.FC<ConfirmDeleteCredentialsProps> = ({ id, name }) => {
        const [error, setError] = useState<any>();

        const handleDelete = async () => {
            await agent.FileCluster.deleteCredentials(id, name)
                .then((response) => {
                    console.log(response);
                    fetchCredentials();
                    closeDialog();
                })
                .catch((error) => {
                    console.log(error);
                    setError(error);
                });
        };

        return (
            <>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete credentials: {name}?</DialogContentText>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error.data?.message}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={handleDelete} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };

    useEffect(() => {
        fetchCredentials();
    }, []);

    return (
        <List>
            {credentials.map((credential) => (
                <ListItem
                    key={credential.id}
                    sx={{
                        "&:hover": {
                            backgroundColor: theme.palette.action.hover
                        }
                    }}>
                    <ListItemText>
                        <Typography variant="body1" fontWeight="bold" component="div">
                            {credential.name}
                        </Typography>
                        <Typography variant="body2" component="div">
                            Protocol: {credential.protocol}
                        </Typography>
                    </ListItemText>
                    <IconButton
                        onClick={() =>
                            openDialog(<ConfirmDeleteCredentials id={cluster_id} name={credential.name} />, "Delete Credentials")
                        }>
                        <Delete />
                    </IconButton>
                </ListItem>
            ))}
        </List>
    );
};

export default CredentialsList;
