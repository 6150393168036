import React, { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { AppBar, Box, Chip, Divider, Stack, Toolbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import UserMenu from "components/UserMenu";
import NotificationsMenu from "components/NotificationsMenu";
import MainMenu from "components/MainMenu";
import EnvironmentsMenu from "components/EnvironmentsMenu";
import SearchMenu from "features/search/Components/SearchMenu";

interface ViewProps {
    id?: string;
    className?: string;
    loading?: boolean;
    loadingText?: string;
    onLoadingClick?: () => void;
    children?: React.ReactNode;
}

const View: React.FC<ViewProps> = observer(function View(props) {
    return (
        <>
            <div
                className={props.className}
                style={{
                    flex: "1 1 auto",
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start"
                }}>
                {props.children}
            </div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex", flexDirection: "column" }}
                open={props.loading || false}
                onClick={props.onLoadingClick}>
                <CircularProgress color="primary" />
                {props.loadingText && <Typography sx={{ marginTop: 2 }}>{props.loadingText}</Typography>}
            </Backdrop>
        </>
    );
});

interface ViewHeaderProps {
    id?: string;
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    preview?: boolean;
}

export const ViewHeader: React.FC<ViewHeaderProps> = observer(function ViewHeader(props) {
    const theme = useTheme();
    return (
        <AppBar
            position="static"
            elevation={2}
            sx={{ backgroundColor: theme.palette.topbar?.light + "!important", color: theme.palette.topbar?.contrastText, zIndex: 20 }}>
            <Toolbar>
                <Box mr={2}>
                    <MainMenu />
                </Box>

                {/* <IconButton edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }} onClick={() => setSidebarExpanded()}>
                    <MenuIcon />
                </IconButton> */}

                {/* <Box
                    component="img"
                    sx={{ width: "64px", height: "64px", mr: 1, ml: -2 }}
                    onClick={() => setSidebarExpanded()}
                    title="Livelink by Cerberus.tech"
                    src={logo}
                /> */}
                {props.title && (
                    <Box sx={{ mr: 2 }}>
                        <Typography variant="h6" noWrap component="div" fontWeight={600} lineHeight={1}>
                            {props.title}
                        </Typography>
                        {props.subtitle && (
                            <Typography variant="body2" noWrap component="div" sx={{ opacity: 0.8 }}>
                                {props.subtitle}
                            </Typography>
                        )}
                    </Box>
                )}
                {props.preview && <Chip label="Preview" size="small" color="primary" sx={{ mr: 2 }} />}
                <Box
                    sx={{
                        marginLeft: "20px",
                        flex: "1 1 auto",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end"
                    }}>
                    {props.children}
                </Box>
                <>
                    <SearchMenu />
                    <Divider orientation="vertical" sx={{ height: 24, ml: 2 }} />
                    <Stack spacing={1} direction="row" alignItems="center" sx={{ ml: 2 }}>
                        <EnvironmentsMenu />
                        <NotificationsMenu />
                        <UserMenu />
                    </Stack>
                </>
            </Toolbar>
        </AppBar>
    );
});

interface ViewBodyProps {
    id?: string;
    noPadding?: boolean;
    children?: React.ReactNode;
}

export const ViewBody: React.FC<ViewBodyProps> = observer(function ViewBody(props) {
    return (
        <div style={{ flex: "1 1 auto", padding: props.noPadding ? "0" : "20px 50px", overflowX: "hidden", overflowY: "auto" }}>
            {props.children}
        </div>
    );
});

export default View;
