import { Alert, AlertTitle, Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { agent, RootStoreContext } from "api";

interface Props {
    onSubmitted?: () => void;
    organisation_id: number;
}

const ConfirmSwitchToAccount: React.FC<Props> = observer(function ConfirmSwitchToAccount(props) {
    const rootStore = React.useContext(RootStoreContext);

    const { closeDialog } = rootStore.dialogStore;

    const [errors, setErrors] = React.useState<any>();

    const handleSubmit = async () => {
        await agent.Organisation.switchToAccount(props.organisation_id)
            .then(() => {
                closeDialog();
                if (props.onSubmitted) {
                    props.onSubmitted();
                }
            })
            .catch((error) => {
                setErrors(error);
            });
    };

    return (
        <>
            <DialogTitle>Switch To Account</DialogTitle>
            <DialogContent>
                <Box>Are you sure?</Box>
                {errors && errors.data && errors.data.message && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        <AlertTitle>{errors.data.message}</AlertTitle>
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Switch To Account
                </Button>
            </DialogActions>
        </>
    );
});

export default ConfirmSwitchToAccount;
