import React, { useContext, SyntheticEvent } from "react";
import { Alert, Box, Button, Card, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { agent, RootStoreContext } from "api";

interface IProps {
    id: number;
    getAvailableModules: () => Promise<void>;
}

export const DeleteModule: React.FC<IProps> = ({ id, getAvailableModules }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const [errors, setErrors] = React.useState<any>();

    const handleDelete = async (id: number) => {
        await agent.Admin.deleteModule(id)
            .then(() => {
                closeDialog();
                getAvailableModules();
            })
            .catch((error) => {
                console.log(error.data);
                setErrors(error.data);
            });
    };
    return (
        <Card sx={{ p: 2 }}>
            <DialogTitle>
                <Typography variant="h4">Confirm Delete</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ mb: 1 }}>Are you sure you want to delete this module?</Typography>
                <Typography>Deleting this module will remove access to the feature for this organisation.</Typography>
            </DialogContent>
            {errors?.message && (
                <Box sx={{ px: 3 }}>
                    <Alert severity="error">{errors.message}</Alert>
                </Box>
            )}
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        handleDelete(id);
                    }}>
                    Delete
                </Button>
            </DialogActions>
        </Card>
    );
};
