import { Delete, Edit, PlayArrow, Refresh, Stop } from "@mui/icons-material";
import { Alert, Button, ButtonGroup } from "@mui/material";
import { IEnvironment, RootStoreContext, agent } from "api";
import ConfirmationDialog from "components/ConfirmationDialog";
import ConfirmDelete from "features/settings/environment/ConfirmDelete";
import EnvironmentSelectProvider from "features/settings/environment/environmentUpgrade/Resize/EnvironmentSelectProvider";
import React, { useContext } from "react";

interface EnvironmentActionsProps {
    environment: IEnvironment;
    onUpdate?: () => void;
}

const EnvironmentActions: React.FC<EnvironmentActionsProps> = ({ environment, onUpdate }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    return (
        <ButtonGroup variant="contained" color="primary">
            {environment.status === "running" && (
                <Button onClick={() => openDialog(<EnvironmentSelectProvider environment={environment as any} />)}>
                    <Edit />
                </Button>
            )}
            {environment.status === "stopped" && (
                <Button
                    onClick={() => {
                        openDialog(
                            <ConfirmationDialog
                                title="Start Environment"
                                message={`Are you sure you want to start environment ${environment.name}?`}
                                confirmButtonText="Start Environment"
                                onConfirm={async () => {
                                    await agent.Environment.startEnvironment(environment.id);
                                }}
                            />
                        );
                    }}>
                    <PlayArrow />
                </Button>
            )}
            {environment.status === "running" && (
                <Button
                    onClick={() => {
                        openDialog(
                            <ConfirmationDialog
                                title="Stop Environment"
                                message={`Are you sure you want to stop environment ${environment.name}?`}
                                confirmButtonText="Stop Environment"
                                confirmButtonColor="error"
                                onConfirm={async () => {
                                    await agent.Environment.stopEnvironment(environment.id);
                                }}
                            />
                        );
                    }}>
                    <Stop />
                </Button>
            )}
            {environment.status === "running" && (
                <Button
                    onClick={() => {
                        openDialog(
                            <ConfirmationDialog
                                title="Restart Environment"
                                message={`Are you sure you want to restart environment ${environment.name}?`}
                                confirmButtonText="Restart Environment"
                                additionalContent={
                                    <Alert severity="info" style={{ marginTop: 20 }}>
                                        <p>
                                            Restarting will cause all services on the environment to be temporarily interrupted for a period
                                            of up to 40 seconds.
                                        </p>
                                        <p>
                                            Restarting is only intended to be used if experiencing issues with the environment such as
                                            unexpected connection issues with streaming protocols.
                                        </p>
                                        <p>
                                            Further support is available at{" "}
                                            <a href="https://docs.livelink.video/" target="_blank" rel="noopener noreferrer">
                                                docs.livelink.video
                                            </a>
                                        </p>
                                    </Alert>
                                }
                                onConfirm={async () => {
                                    await agent.Environment.restartEnvironment(environment.id);
                                }}
                            />
                        );
                    }}>
                    <Refresh />
                </Button>
            )}
            {environment.status === "running" || environment.status === "stopped" ? (
                <Button
                    onClick={() => {
                        openDialog(<ConfirmDelete id={environment.id} name={environment.name} />);
                    }}>
                    <Delete />
                </Button>
            ) : undefined}
        </ButtonGroup>
    );
};

export default EnvironmentActions;
