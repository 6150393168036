import React, { useContext, useState } from "react";
import { IUserFormValues, RootStoreContext } from "api";
import { FORM_ERROR } from "final-form";
import { Form as FinalForm } from "react-final-form";
import { TextField } from "mui-rff";
import ErrorMessage from "app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";
import { combineValidators, isRequired } from "revalidate";
import { Box, Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const EmailVerifiedMessage = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <>
            <DialogContent>
                <Typography>You have successfully verified your email.</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        closeDialog();
                    }}>
                    OK
                </Button>
            </DialogActions>
        </>
    );
};

const ConfirmEmail = () => {
    const rootStore = useContext(RootStoreContext);
    const { verifyEmail, resendEmail, resendEmailAlternative, user, getUser } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;
    const [resend1Sent, setResend1Sent] = useState(false);
    const [resend1Sending, setResend1Sending] = useState(false);
    const [resend2Sent, setResend2Sent] = useState(false);
    const [resend2Sending, setResend2Sending] = useState(false);

    const validate = combineValidators({
        token: isRequired("Email verification code")
    });

    return (
        <Box>
            <Typography variant="h3" gutterBottom>
                Verify your email
            </Typography>
            <Typography>
                To verify that you are the owner of this email address, we've sent you a verification email.
                <br />
                Please paste the verification code below.
                <br />
                Verification expires in 20 minutes.
            </Typography>

            <FinalForm
                onSubmit={(values: IUserFormValues) =>
                    verifyEmail(values)
                        .then(() => {
                            // Refresh the user so that the first step gets marked complete
                            getUser().then(() => {
                                openDialog(<EmailVerifiedMessage />, "Email Verified");
                            });
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error
                        }))
                }
                validate={validate}
                render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Stack spacing={4} sx={{ mt: 3 }} direction="row" width={500} alignItems="center">
                            <TextField fullWidth required name="token" type="text" label="Email verification code" />
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                sx={{ whiteSpace: "nowrap" }}
                                disabled={(invalid && !dirtySinceLastSubmit) || pristine || submitting}
                                loading={submitting}
                                type="submit">
                                Verify email
                            </LoadingButton>
                        </Stack>
                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                    </form>
                )}
            />

            <Typography sx={{ my: 3 }}>
                If you haven't received the confirmation email, please press one of the buttons below. <br />
                We use 2 different email providers just in case the confirmation email gets filtered out by your ISP.
            </Typography>

            <Stack spacing={2} direction="row" alignItems="center">
                {resend1Sent ? (
                    <div
                        style={{
                            padding: "10px",
                            color: "white",
                            background: "#205b59",
                            width: 205,
                            borderRadius: "5px",
                            textAlign: "center"
                        }}>
                        <i className="send icon" style={{ fontSize: "14px", marginRight: "10px" }} />
                        Confirmation code sent!
                    </div>
                ) : (
                    <LoadingButton
                        id="ResendEmail"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setResend1Sending(true);
                            resendEmail(user!.email).then(() => {
                                setResend1Sent(true);
                                setResend1Sending(false);
                            });
                        }}
                        loading={resend1Sending}
                        disabled={resend1Sending}>
                        Resend verification code (Provider 1)
                    </LoadingButton>
                )}
                {resend2Sent ? (
                    <div
                        style={{
                            padding: "10px",
                            color: "white",
                            background: "#205b59",
                            width: 205,
                            borderRadius: "5px",
                            textAlign: "center"
                        }}>
                        <i className="send icon" style={{ fontSize: "14px", marginRight: "10px" }} />
                        Confirmation code sent!
                    </div>
                ) : (
                    <LoadingButton
                        id="ResendEmail2"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setResend2Sending(true);
                            resendEmailAlternative(user!.email).then(() => {
                                setResend2Sent(true);
                                setResend2Sending(true);
                            });
                        }}
                        loading={resend2Sending}
                        disabled={resend2Sending}>
                        Resend verification code (Provider 2)
                    </LoadingButton>
                )}
            </Stack>
            <Typography sx={{ mt: 3 }}>
                If you still haven't received the confirmation emails after trying the re-send buttons,
                <br />
                please contact support at support@cerberus.tech using the sign-up email and we will confirm it for you.
            </Typography>
        </Box>
    );
};

export default observer(ConfirmEmail);
