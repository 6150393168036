import { Box, Card, CardHeader, List, ListItem, Typography } from "@mui/material";
import ColorEnhancementForm from "features/packager/Components/ColorEnhancementForm";
import React from "react";
import { Form } from "react-final-form";
import { Handle, Node, Position } from "reactflow";
import NodeHeader from "../NodeHeader";

interface Props {
    data: any;
}

export const ListNode: React.FC<Props> = (props) => {
    console.log(props);
    return (
        <Card sx={{ width: 255, overflow: "visible" }}>
            <Handle type="target" position={Position.Left} />
            <NodeHeader label={props.data.label} />
            <Box sx={{ p:0  }}>
                <List disablePadding>
                    {props.data?.listItems?.map((item: any, i: number) => {
                        return (
                            <ListItem key={i}>
                                {item.codec && item.codec}
                                {item.data_type && item.data_type}
                                <Handle type="source" position={Position.Right} id={`ls-${i}`} />
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
            {/* <Box sx={{ px: 2, py: 1, borderTop: "1px solid rgba(160,160,160,0.2)" }}></Box> */}
        </Card>
    );
};

export default ListNode;
