import { RootStoreContext } from "api";
import OnChange from "components/FinalFormListener/OnChange";
import { RadioGroupField, SelectField } from "components/Forms";
import { useEnvironment } from "hooks";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";

const SelectProvider: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { providers, setCloudCode } = rootStore.environmentStore;
    const { getProviders } = useEnvironment();

    React.useEffect(() => {
        getProviders();
    }, []);

    const handleChange = (value) => {
        const code = value;
        setCloudCode(code);
    };

    return (
        <>
            {providers && (
                <>
                    <RadioGroupField
                        name="cloud_provider"
                        label="Hosting Provider"
                        hideLabel={true}
                        options={providers.map((provider) => {
                            return { label: provider.name, value: provider.code };
                        })}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        error={(meta) => meta.touched && meta.error}
                        helperText={(meta) => (meta.touched && meta.error ? meta.error : "")}
                    />
                    <OnChange name="cloud_provider">
                        {(value) => {
                            handleChange(value);
                        }}
                    </OnChange>
                </>
            )}
        </>
    );
};

export default observer(SelectProvider);
