import React from 'react';
import { ResModel, ResCollection } from "resclient";

type GroupMarkerItemProps = {
    id: string;
    lat: any;
    lng: any;
    environmentLat: number;
    environmentLng: number;
    environmentName: string;
    hasError?: boolean;
    map: google.maps.Map;
    name: string;
    active?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onLineClick?: () => void;
    alerts?: ResModel | ResCollection | any;
    type: string;
    index: number;
}



const GroupMarkerItem: React.FC<GroupMarkerItemProps> = ({ id, lat, lng, environmentLat, environmentLng, environmentName, hasError, map, name, active, onClick, onLineClick, type, index }) => {

    const [pathState, setPathState] = React.useState<google.maps.Polyline>(new google.maps.Polyline({
        path: [{ "lat": environmentLat, "lng": environmentLng }, { "lat": lat, "lng": lng }],
        geodesic: true,
        strokeColor: hasError ? 'crimson' : '#33BD4E',
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 1
    }))

    const activeColor = '#307abb';

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (onClick) {
            onClick(event);
        }
    }

    React.useEffect(() => {

        pathState.setPath([{ "lat": environmentLat, "lng": environmentLng }, { "lat": lat, "lng": lng }])
        pathState.setOptions({ geodesic: true, strokeColor: active ? activeColor : hasError ? 'crimson' : '#33BD4E', strokeOpacity: (index === 0 || active || hasError) ? 1 : 0, strokeWeight: 2, zIndex: active ? 2 : hasError ? 2 : 1 })
        pathState.setMap(map)

        return () => {
            pathState.setMap(null)
            setPathState(new google.maps.Polyline())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng, hasError, active]);

    React.useEffect(() => {
        if (pathState) {
            pathState.setOptions({ geodesic: true, strokeColor: active ? activeColor : hasError ? 'crimson' : '#33BD4E', strokeOpacity: (index === 0 || active || hasError) ? 1 : 0, strokeWeight: 2, zIndex: active ? 2 : hasError ? 2 : 1 })
            pathState.addListener('click', onLineClick ? onLineClick : () => {console.log('no click')});
            pathState.setMap(map)
        }
    }, [pathState, map, hasError, active, index, onLineClick])



    return (
        <div className={active ? 'group-marker-item active' : hasError ? 'group-marker-item alerting' : 'group-marker-item'}>
            <div onClick={
                handleClick
            }>
                <div style={{fontSize: '12px', lineHeight: '16px', fontWeight: 600, whiteSpace: 'nowrap'}}>{name}</div>
                <div style={{fontSize: '10px', lineHeight: '14px', opacity: 0.8, whiteSpace: 'nowrap'}}>{environmentName}</div>
            </div>
        </div>
    )
}

export default GroupMarkerItem;