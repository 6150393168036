import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { agent, IFileClusterJob, IFileClusterJobs, RootStoreContext } from "api";
import {
    Box,
    Checkbox,
    Chip,
    IconButton,
    Tooltip,
    Pagination,
    Table,
    TableHead,
    TableCell,
    TableSortLabel,
    TableBody,
    TableRow,
    TableFooter,
    DialogContent,
    DialogActions,
    Button as MuiButton,
    LinearProgress,
    useTheme,
    CircularProgress
} from "@mui/material";
import { format } from "date-fns";
import { ClearIcon } from "@mui/x-date-pickers";
import SearchInput from "components/SearchInput";
import { Clear } from "@mui/icons-material";
import { useFileCluster } from "hooks";

type TranscodingJobsProps = {
    deployment_id: string;
    pageSize: number;
};

const TranscodingJobs: React.FC<TranscodingJobsProps> = observer(function TranscodingJobs(props) {
    const theme = useTheme();
    const rootStore = React.useContext(RootStoreContext);
    const {
        //activeDeploymentId,
        transcodingJobsRequest,
        setTranscodingJobsSort,
        setTranscodingJobsOffset,
        setTranscodingJobsSearch
    } = rootStore.fileClusterStore;
    const { stopJob } = useFileCluster();

    const { openDialog, closeDialog } = rootStore.dialogStore;

    const [jobs, setJobs] = React.useState<IFileClusterJobs>();
    const defaultList = [0];
    const [selectedJobs, setSelectedJobs] = useState(defaultList);
    const [openPopup, setOpenPopup] = useState("");

    const handleSelectAllTranscodingJobs = (e: any, data: any) => {
        setSelectedJobs(defaultList);
        const currentItems = selectedJobs;
        if (data === true && jobs) {
            jobs.data.forEach((item) => {
                currentItems.push(item.id);
            });
            setSelectedJobs(currentItems);
        } else {
            setSelectedJobs(defaultList);
        }

        getJobs();
    };

    const handleStopMultiple = () => {
        selectedJobs.forEach((item) => {
            if (item > 0) {
                stopJob(props.deployment_id, item);
            }
        });
        setSelectedJobs(defaultList);
        setOpenPopup("");
        getJobs();
    };

    const handleRowSelect = (e: any, data: any, job_id: any) => {
        const currentItems = selectedJobs;
        if (data === true) {
            currentItems.push(job_id);
        } else {
            currentItems.splice(currentItems.indexOf(job_id), 1);
        }
        setSelectedJobs(currentItems);
        getJobs();
    };

    const getJobs = async () => {
        await agent.FileCluster.getJobsByDeployment(
            props.deployment_id,
            transcodingJobsRequest.offset,
            props.pageSize,
            transcodingJobsRequest.sort,
            transcodingJobsRequest.category,
            transcodingJobsRequest.source_filename
        ).then((response) => {
            setJobs(response);
        });
    };

    const handleSearch = (searchTerm: string) => {
        setTranscodingJobsSearch(searchTerm);
        getJobs();
    };

    const clearSearch = () => {
        setTranscodingJobsSearch("");
        getJobs();
    };

    React.useEffect(() => {}, [
        props.deployment_id,
        transcodingJobsRequest,
        setTranscodingJobsSort,
        setTranscodingJobsOffset,
        jobs,
        setTranscodingJobsSearch
    ]);

    React.useEffect(() => {
        getJobs();

        const interval = setInterval(() => {
            getJobs();
        }, 3000);
        return () => {
            setTranscodingJobsOffset(0);
            setTranscodingJobsSort("id:desc");
            clearSearch();
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const demoJobs: IFileClusterJob[] = [
        {
            id: 1,
            created_at: new Date(),
            tf_job_id: "1",
            deployment_id: "1",
            livelink_priority: 1,
            priority: 1,
            name: "Demo Job 1",
            status: "finished",
            status_index: 1,
            state: "running",
            errors: "",
            progress: 100,
            start_time: new Date(),
            end_time: new Date(),
            active_duration: 1,
            cpu_duration: 1,
            source_filename: "demo.mp4",
            cores: 1,
            currency: "USD",
            cost_calculated: 1,
            livelink_job_id: 1,
            configuration: {
                using: "ffmpeg"
            },
            sent_to_tf_at: new Date()
        },
        {
            id: 11,
            created_at: new Date(),
            tf_job_id: "1",
            deployment_id: "1",
            livelink_priority: 1,
            priority: 1,
            name: "Demo Job 1",
            status: "transcoding",
            status_index: 1,
            state: "running",
            errors: "",
            progress: 95,
            start_time: new Date(),
            end_time: new Date(),
            active_duration: 1,
            cpu_duration: 1,
            source_filename: "demo.mp4",
            cores: 1,
            currency: "USD",
            cost_calculated: 1,
            livelink_job_id: 1,
            configuration: {
                using: "ffmpeg"
            },
            sent_to_tf_at: new Date()
        },
        {
            id: 2,
            created_at: new Date(),
            tf_job_id: "1",
            deployment_id: "1",
            livelink_priority: 1,
            priority: 1,
            name: "Demo Job 1",
            status: "transcoding",
            status_index: 1,
            state: "running",
            errors: "",
            progress: 80,
            start_time: new Date(),
            end_time: new Date(),
            active_duration: 1,
            cpu_duration: 1,
            source_filename: "demo.mp4",
            cores: 1,
            currency: "USD",
            cost_calculated: 1,
            livelink_job_id: 1,
            configuration: {
                using: "ffmpeg"
            },
            sent_to_tf_at: new Date()
        },
        {
            id: 23,
            created_at: new Date(),
            tf_job_id: "2",
            deployment_id: "1",
            livelink_priority: 1,
            priority: 1,
            name: "Demo Job 2",
            status: "transcoding",
            status_index: 1,
            state: "running",
            errors: "",
            progress: 69,
            start_time: new Date(),
            end_time: new Date(),
            active_duration: 1,
            cpu_duration: 1,
            source_filename: "demo.mp4",
            cores: 1,
            currency: "USD",
            cost_calculated: 1,
            livelink_job_id: 1,
            configuration: {
                using: "ffmpeg"
            },
            sent_to_tf_at: new Date()
        },
        {
            id: 3,
            created_at: new Date(),
            tf_job_id: "2",
            deployment_id: "1",
            livelink_priority: 1,
            priority: 1,
            name: "Demo Job 2",
            status: "transcoding",
            status_index: 1,
            state: "running",
            errors: "",
            progress: 50,
            start_time: new Date(),
            end_time: new Date(),
            active_duration: 1,
            cpu_duration: 1,
            source_filename: "demo.mp4",
            cores: 1,
            currency: "USD",
            cost_calculated: 1,
            livelink_job_id: 1,
            configuration: {
                using: "ffmpeg"
            },
            sent_to_tf_at: new Date()
        },
        {
            id: 4,
            created_at: new Date(),
            tf_job_id: "2",
            deployment_id: "1",
            livelink_priority: 1,
            priority: 1,
            name: "Demo Job 2",
            status: "transcoding",
            status_index: 1,
            state: "running",
            errors: "",
            progress: 32,
            start_time: new Date(),
            end_time: new Date(),
            active_duration: 1,
            cpu_duration: 1,
            source_filename: "demo.mp4",
            cores: 1,
            currency: "USD",
            cost_calculated: 1,
            livelink_job_id: 1,
            configuration: {
                using: "ffmpeg"
            },
            sent_to_tf_at: new Date()
        },
        {
            id: 5,
            created_at: new Date(),
            tf_job_id: "2",
            deployment_id: "1",
            livelink_priority: 1,
            priority: 1,
            name: "Demo Job 2",
            status: "transcoding",
            status_index: 1,
            state: "running",
            errors: "",
            progress: 22,
            start_time: new Date(),
            end_time: new Date(),
            active_duration: 1,
            cpu_duration: 1,
            source_filename: "demo.mp4",
            cores: 1,
            currency: "USD",
            cost_calculated: 1,
            livelink_job_id: 1,
            configuration: {
                using: "ffmpeg"
            },
            sent_to_tf_at: new Date()
        }
    ];

    return (
        <div>
            <Box className="tab-toolbar">
                <SearchInput
                    name="search"
                    label="Search Source Files..."
                    value={transcodingJobsRequest.source_filename}
                    onChange={handleSearch}
                    onClear={clearSearch}
                />
                <Box className="tab-toolbar-actions">
                    <h4>Selected Jobs ({selectedJobs.length - 1}): </h4>
                    <IconButton
                        disabled={selectedJobs.length < 2 ? true : false}
                        onClick={() => {
                            openDialog(
                                <>
                                    <DialogContent>
                                        Are you sure you want to abort the selected jobs? This action can not be undone and you will still
                                        be charged.
                                    </DialogContent>
                                    <DialogActions>
                                        <MuiButton onClick={() => closeDialog()}>Cancel</MuiButton>
                                        <MuiButton
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                handleStopMultiple();
                                                closeDialog();
                                            }}>
                                            Abort Jobs
                                        </MuiButton>
                                    </DialogActions>
                                </>,
                                "Confirm Abort Jobs"
                            );
                        }}>
                        <Clear />
                    </IconButton>
                </Box>
            </Box>

            <Table>
                <TableHead>
                    <TableCell padding="checkbox">
                        <Checkbox
                            onChange={(e, data) => handleSelectAllTranscodingJobs(e, data)}
                            checked={jobs?.data && jobs.data.length > 0 && selectedJobs.length - 1 === jobs?.data.length}
                        />
                    </TableCell>

                    <TableCell>
                        <TableSortLabel
                            active={transcodingJobsRequest.sort === "name:asc" || transcodingJobsRequest.sort === "name:desc"}
                            direction={transcodingJobsRequest.sort === "name:asc" ? "asc" : "desc"}
                            onClick={() => {
                                transcodingJobsRequest.sort === "name:asc"
                                    ? setTranscodingJobsSort("name:desc")
                                    : setTranscodingJobsSort("name:asc");
                                getJobs();
                            }}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={transcodingJobsRequest.sort === "id:asc" || transcodingJobsRequest.sort === "id:desc"}
                            direction={transcodingJobsRequest.sort === "id:asc" ? "asc" : "desc"}
                            onClick={() => {
                                transcodingJobsRequest.sort === "id:asc"
                                    ? setTranscodingJobsSort("id:desc")
                                    : setTranscodingJobsSort("id:asc");
                                getJobs();
                            }}>
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={transcodingJobsRequest.sort === "start_time:asc" || transcodingJobsRequest.sort === "start_time:desc"}
                            direction={transcodingJobsRequest.sort === "start_time:asc" ? "asc" : "desc"}
                            onClick={() => {
                                transcodingJobsRequest.sort === "start_time:asc"
                                    ? setTranscodingJobsSort("start_time:desc")
                                    : setTranscodingJobsSort("start_time:asc");
                                getJobs();
                            }}>
                            Start Time
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell width={300}>Progress</TableCell>
                    <TableCell align="right"></TableCell>
                </TableHead>
                <TableBody>
                    {jobs &&
                        jobs.data?.map((job: IFileClusterJob, i: number) => (
                            <TableRow key={i} selected={selectedJobs.includes(job.id) ? true : false}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedJobs.includes(job.id)}
                                        onChange={(e, data) => handleRowSelect(e, data, job.id)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <div style={{ wordBreak: "break-all" }}>{job.name}</div>
                                    <div
                                        style={{
                                            fontSize: "10px",
                                            opacity: "0.7"
                                        }}>
                                        Source: {job.source_filename}
                                    </div>
                                </TableCell>
                                <TableCell>{job.id}</TableCell>
                                <TableCell>{job.start_time ? format(new Date(job.start_time), "dd/MM/yyyy @ HH:mm") : null}</TableCell>
                                <TableCell>
                                    {(() => {
                                        switch (job.status) {
                                            case "transcoding":
                                                return (
                                                    <Chip
                                                        style={{
                                                            textTransform: "capitalize"
                                                        }}
                                                        label={job.status}
                                                        size="small"
                                                        avatar={<CircularProgress size={"1rem"} />}
                                                    />
                                                );
                                            default:
                                                return (
                                                    <Chip
                                                        style={{
                                                            textTransform: "capitalize"
                                                        }}
                                                        label={job.status}
                                                        size="small"
                                                    />
                                                );
                                        }
                                    })()}
                                </TableCell>
                                <TableCell>
                                    {!job.errors && (
                                        <>
                                            <Tooltip title={`${job.progress}%`} placement="top" arrow>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={job.progress}
                                                    sx={{
                                                        height: 10,
                                                        borderRadius: 5,
                                                        "& .MuiLinearProgress-bar": {
                                                            overflow: "hidden",
                                                            borderRadius: 5,
                                                            backgroundImage: () => {
                                                                if (job && job.progress) {
                                                                    if (Number(job.progress) < 30) {
                                                                        return `linear-gradient(${theme.palette.error.main}, ${theme.palette.error.main})`;
                                                                    } else if (job.progress < 60) {
                                                                        return `linear-gradient(${theme.palette.warning.main}, ${theme.palette.warning.main})`;
                                                                    } else if (job.progress < 100) {
                                                                        return `linear-gradient(${theme.palette.success.main}, ${theme.palette.success.main})`;
                                                                    }
                                                                    return `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`;
                                                                } else {
                                                                    return `linear-gradient(${theme.palette.error.main}, ${theme.palette.error.main})`;
                                                                }
                                                            }
                                                            // backgroundImage: `linear-gradient(90deg, ${theme.palette.error.main} 0%, ${theme.palette.warning.main} 40%, ${theme.palette.warning.main} 60%, ${theme.palette.success.main} 80%)`,
                                                            // backgroundSize: "268px 100%",
                                                            // backgroundPosition: `${268 - (268 * (job.progress || 0)) / 100}px 0`
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    )}
                                    {job.errors && (
                                        <div
                                            style={{
                                                fontSize: "10px",
                                                color: "#ff0000"
                                            }}>
                                            {job.errors}
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    {job.status !== "stopped" && job.status !== "crashed" && (
                                        <>
                                            <Tooltip placement="right" arrow title="Abort job">
                                                <Box>
                                                    <IconButton
                                                        aria-label="abort"
                                                        onClick={() => {
                                                            openDialog(
                                                                <>
                                                                    <DialogContent>
                                                                        Are you sure you want to abort job '{job.name}'? This action can not
                                                                        be undone and you will still be charged.
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <MuiButton onClick={() => closeDialog()}>Cancel</MuiButton>
                                                                        <MuiButton
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                stopJob(props.deployment_id, job.id);
                                                                                closeDialog();
                                                                            }}>
                                                                            Abort Job
                                                                        </MuiButton>
                                                                    </DialogActions>
                                                                </>,
                                                                "Confirm Abort Job"
                                                            );
                                                        }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>

                {jobs && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <Pagination
                                        shape="rounded"
                                        count={Math.ceil(jobs.totals.transcoding / props.pageSize)}
                                        page={transcodingJobsRequest.offset / props.pageSize + 1}
                                        onChange={(event: any, page: number) => {
                                            setTranscodingJobsOffset((page - 1) * props.pageSize);
                                            getJobs();
                                        }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
});

export default TranscodingJobs;
