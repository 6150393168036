import React from "react";
import { RootStoreContext } from "api";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

const ActiveEnvironmentMonitor = () => {
    const rootStore = React.useContext(RootStoreContext);

    const { client } = rootStore.resClientStore;

    const { activeEnvironment, managingOrganisationId } = rootStore.userStore;
    const active_org_id = rootStore.userStore.activeOrganisation;

    const { setCurrentEnvironmentRunning, setCurrentEnvStatus } = rootStore.environmentStore;

    const { openDialog, closeDialog } = rootStore.dialogStore;

    const [, setWsData] = React.useState<any>([]);

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const location = useLocation();

    const onChange = (res: any) => {
        setCurrentEnvStatus(res.toJSON().status);
        if (res.toJSON().status === "running") {
            //console.log("Running");
            setCurrentEnvironmentRunning(true);
        } else {
            setCurrentEnvironmentRunning(false);
            closeDialog();
            if (
                location.pathname.includes("scheduler") ||
                location.pathname.includes("sources") ||
                location.pathname.includes("destinations")
            ) {
                openDialog(
                    <Box>
                        <DialogTitle>Active Environment Status</DialogTitle>
                        <DialogContent>
                            Your active environment is currently {res.toJSON().status}. You can not make changes to sources or destinations
                            while the environment is in this state.
                            <br />
                            <br />
                            Please switch to another environment or restart the active one.
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => closeDialog()}>Continue Anyway</Button>
                        </DialogActions>
                    </Box>,
                    undefined,
                    undefined,
                    true
                );
            }
        }
    };

    React.useEffect(() => {
        //let rid = `environments.environments.${managingOrganisationId}.environment.${activeEnvironment}`;
        let rid = `environments_v2.environments.${managingOrganisationId}.${active_org_id}.environment.${activeEnvironment}`;

        //!client.connected && resClientStore.initialize(rootStore.commonStore.token!);

        setTimeout(() => {
            client.connected &&
                activeEnvironment &&
                client
                    .get(rid)
                    .then((res) => {
                        res.on("change", () => {
                            onChange(res);
                            setUpdateDate(new Date());
                        });
                        setWsData(res);
                        setCurrentEnvStatus(res.toJSON().status);
                        //console.log(res.toJSON().status);
                        if (res.toJSON().status === "running") {
                            //console.log("Running");
                        } else {
                            closeDialog();
                            if (
                                location.pathname.includes("scheduler") ||
                                location.pathname.includes("sources") ||
                                location.pathname.includes("destinations")
                            ) {
                                openDialog(
                                    <Box>
                                        <DialogTitle>Active Environment Status</DialogTitle>
                                        <DialogContent>Your active environment is currently {res.toJSON().status}.</DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => closeDialog()}>Continue Anyway</Button>
                                        </DialogActions>
                                    </Box>,
                                    undefined,
                                    undefined,
                                    true
                                );
                            }
                        }
                        setUpdateDate(new Date());
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        }, 1000);

        return () => {
            closeDialog();
        };
    }, [client.connected, activeEnvironment, rootStore.commonStore.token, managingOrganisationId, active_org_id]);

    React.useEffect(() => {}, [updateDate]);

    return <></>;
};

export default observer(ActiveEnvironmentMonitor);
