import { IKnowledgeHubArticleShort, IKnowledgeItemTopic } from "api";

// Function to get tags on items
export function getKnowledgeHubTags(data: IKnowledgeItemTopic[]): string[] {
    let tags: string[] = [];

    data.forEach((topic) => {
        topic.items?.forEach((item) => {
            if (item.tags) {
                tags = [...tags, ...item.tags];
            }
        });
    });

    return [...new Set(tags)];
}

// Function to find unique items from two arrays
export const getDiffFromArrays = (arr1: any[], arr2: any[]) => {
    const added = arr2.filter((item) => !arr1.includes(item));
    const removed = arr1.filter((item) => !arr2.includes(item));

    return {
        added,
        removed
    };
};

// Function to check if the value is an object
export const isObject = (value: any): value is object => {
    return value && typeof value === "object" && !Array.isArray(value);
};

// Function to make camel/pascal strings to labels
export const formatLabel = (input: string, addSpace = false) => {
    if (addSpace) {
        const words = input.split("-");
        const formattedLabel = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");

        return formattedLabel;
    }

    return input.replace(/([A-Z])/g, " $1").replace(/^./, (match) => match.toUpperCase());
};

// Function to sort items
export const sortItems = (items: IKnowledgeHubArticleShort[]) => {
    return items.slice().sort((a, b) => {
        const orderA = a.order || 10;
        const orderB = b.order || 10;

        if (orderA !== orderB) {
            return orderA - orderB;
        }
        return a.title.localeCompare(b.title);
    });
};
