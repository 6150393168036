import { Alert, Box, Button, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { agent, IGetLimitsResponseItem, RootStoreContext } from "api";
import { endOfDay, format } from "date-fns";
import React, { useContext, useEffect } from "react";

interface ViewLimitMetricsProps {
    limit: IGetLimitsResponseItem;
}

const ViewLimitMetrics: React.FC<ViewLimitMetricsProps> = ({ limit }) => {
    const [error, setError] = React.useState<any>(null);
    const [limitValue, setLimitValue] = React.useState<number>(limit.limit_value);
    const [startWarningsAt, setStartWarningsAt] = React.useState<number>(limit.start_warnings_at);
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    // const [dateRange, setDateRange] = React.useState<any>([null, null]);
    const [data, setData] = React.useState<any>([]);
    const [selectedDateRange, setSelectedDateRange] = React.useState<string>('3days');

    const rootStore = useContext(RootStoreContext);
    const { closeDialog, changeDialogBody } = rootStore.dialogStore;

    const fetchMetrics = (range = '3days') => {
        let dateRange = [new Date(), new Date()];

       switch (range) {
            case '1hour':
                dateRange[0].setHours(dateRange[0].getHours() - 1);
                setSelectedDateRange('1hour');
                break;
            case '3hours':
                dateRange[0].setHours(dateRange[0].getHours() - 3);
                setSelectedDateRange('3hours');
                break;
            case '12hours':
                dateRange[0].setHours(dateRange[0].getHours() - 12);
                setSelectedDateRange('12hours');
                break;
            case '1day':
                dateRange[0].setDate(dateRange[0].getDate() - 1);
                setSelectedDateRange('1day');
                break;
            default:
                dateRange[0].setDate(dateRange[0].getDate() - 3);
                setSelectedDateRange('3days');
                break;
        }

        agent.OwnCloud.getLimitsMetrics({
            cloud_provider: limit.cloud_provider,
            region: limit.region,
            limit_name: limit.limit_name,
            start_date: dateRange[0],
            end_date: dateRange[1]
        })
        .then((response) => {
            let data = response?.data?.aws?.[limit.region]?.[limit.limit_name];

            if (!data) {
                setError("No data found for the selected date range.");
                return;
            }

            setData(data);
        })
        .catch((error) => {
            console.log('getLimitsMetrics:', error);
        })
    }

    // Get init data
    useEffect(() => {
        fetchMetrics();
    }, []);

    return (
        <>
            <DialogContent>
                <Stack spacing={1}>
                    <Stack spacing={1} sx={{ py: 2 }} direction="row" alignItems="center">
                        <Button
                            variant={selectedDateRange === "1hour" ? "contained" : "toolbar"}
                            onClick={() => {
                                fetchMetrics('1hour');
                            }}
                        >
                            1 Hour
                        </Button>
                        <Button
                            variant={selectedDateRange === "3hours" ? "contained" : "toolbar"}
                            onClick={() => {
                                fetchMetrics('3hours');
                            }}
                        >
                            3 Hours
                        </Button>
                        <Button
                            variant={selectedDateRange === "12hours" ? "contained" : "toolbar"}
                            onClick={() => {
                                fetchMetrics('12hours');
                            }}
                        >
                            12 Hours
                        </Button>
                        <Button
                            variant={selectedDateRange === "1day" ? "contained" : "toolbar"}
                            onClick={() => {
                                fetchMetrics('1day');
                            }}
                        >
                            1 Day
                        </Button>
                        <Button
                            variant={selectedDateRange === "3days" ? "contained" : "toolbar"}
                            onClick={() => {
                                fetchMetrics('3days');
                            }}
                        >
                            3 Days
                        </Button>
                    </Stack>

                    {error ? (
                        <Alert severity="error">
                            {error}
                        </Alert>
                    ) : (
                        <Box sx={{ height: '60vh', width: '100%' }}>
                            <DataGridPro
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'datetime', sort: 'desc' }],
                                    },
                                }}
                                rows={data.map((item: any, index: number) => {
                                    return {
                                        id: index,
                                        datetime: format(new Date(item.datetime), 'dd/MM/yyyy @ HH:mm:ss'),
                                        value: item.value,
                                        limit: item.limit,
                                        is_breach: item.is_breach,
                                        start_warnings_at: item.start_warnings_at
                                    };
                                })}
                                columns={[
                                    { field: "datetime", headerName: "Date / Time", flex: 1 },
                                    { field: "value", headerName: "Value", flex: 1 },
                                    { field: "limit", headerName: "Limit", flex: 1 },
                                    { field: "is_breach", headerName: "Breach", flex: 1 },
                                    { field: "start_warnings_at", headerName: "Start Warnings At", flex: 1 }
                                ]}
                                disableSelectionOnClick
                                hideFooterPagination

                            />
                        </Box>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export default ViewLimitMetrics;
