import { Box, Button, Chip, IconButton, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { agent, TranscoderPresetsResult, RootStoreContext } from "api";
import { DeleteTranscoderPreset } from "features/channels/createTranscoder/DeleteTranscoderPreset";
import React, { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface PresetSelectProps {
    onSelect: (preset: any) => void;
    enable_motion_interpolation: boolean;
}

export const PresetSelect: React.FC<PresetSelectProps> = ({ onSelect, enable_motion_interpolation }) => {
    const [presets, setPresets] = useState<TranscoderPresetsResult>();
    const rootStore = useContext(RootStoreContext);
    const [chosenPreset, setChosenPreset] = useState<number>();

    const { openDialog } = rootStore.dialogStore;

    const getPresets = async () => {
        await agent.Channel.getPresets()
            .then((response) => {
                console.log(response);
                if (response) {
                    if (enable_motion_interpolation) {
                        response.data = response.data.filter(
                            (preset) => preset.parameters.framerate_conversion_mode === "Motion interpolation"
                        );
                    } else {
                        response.data = response.data.filter(
                            (preset) => preset.parameters.framerate_conversion_mode !== "Motion interpolation"
                        );
                    }
                    setPresets(response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getPresets();
    }, []);

    return (
        <>
            <TextField id="preset-select" select label="Select Preset" helperText="Optional" fullWidth>
                <MenuItem value={0}>No Preset</MenuItem>
                {presets?.data.map((preset) => (
                    <MenuItem
                        key={preset.id}
                        value={preset.id}
                        onClick={() => {
                            onSelect(preset);
                            setChosenPreset(preset.id);
                        }}>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <Typography>{preset.name}</Typography>
                                {preset.service_config_version && (
                                    <Tooltip title="Service Config Version">
                                        <Chip size="small" label={"v" + preset.service_config_version} />
                                    </Tooltip>
                                )}
                            </Stack>
                            <Box sx={{ justifyContent: "flex-end", display: chosenPreset === preset.id ? "none" : "" }}>
                                <Tooltip title="Delete preset">
                                    <IconButton
                                        aria-label="delete"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openDialog(<DeleteTranscoderPreset id={preset.id} />);
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

export const PresetSave: React.FC = () => {
    return <></>;
};

export default PresetSelect;
