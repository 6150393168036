import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, useTheme } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { pruneEmptyErrors } from "features/common/PruneEmptyErrors";

type RadioGroupFieldOption = {
    label: string | React.ReactNode;
    value: string | number;
    disabled?: boolean;
};

interface RadioGroupFieldProps {
    name: string;
    label: string;
    hideLabel?: boolean;
    description?: string;
    options: RadioGroupFieldOption[];
    required?: boolean;
    disabled?: boolean;
    row?: boolean;
    parse?: boolean;
    initialValue?: string | number;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    validate?: (value: any) => string | undefined;
    onChange?: (field: string, value?: any, previous?: any) => void;
    parseBool?: boolean;
}

/**
 * RadioGroupField is a React Functional Component that renders a group of radio buttons with a label.
 * It leverages Material-UI components for styling and layout, and integrates with react-final-form for form handling.
 *
 * The component allows users to select a single option from a group, making it ideal for options that are mutually exclusive.
 *
 * @component
 * @param {string} name - The name of the radio group, used for form submission and linking with react-final-form.
 * @param {string} label - The label displayed above the radio group, describing its purpose.
 * @param {boolean} [hideLabel=false] - If true, hides the label above the radio group.
 * @param {RadioGroupFieldOption[]} options - An array of options for the radio group, where each option has a label and a value.
 * @param {boolean} [required=false] - Whether the radio group is required for form submission.
 * @param {boolean} [disabled=false] - If true, disables all radio buttons in the group.
 * @param {boolean} [row=false] - If true, displays the radio buttons in a row instead of a column.
 * @param {(meta: any) => string | undefined} [helperText] - A function returning a string to display as helper text below the group, usually for validation messages.
 * @param {(meta: any) => boolean} [error] - A function that returns a boolean indicating if the group has an error, used for validation.
 * @param {(value: any) => string | undefined} [validate] - A validation function for the radio group. It should return an error message if validation fails.
 * @param {(field: string, value?: any, previous?: any) => void} [onChange] - A callback function that is called when the value of the radio group changes.
 * @param {boolean} [parse=false] - If true, parses the value as a number before storing it in the form state.
 *
 * @example
 * <RadioGroupField
 *   name="gender"
 *   label="Gender"
 *   options={[
 *     { label: 'Male', value: 'male' },
 *     { label: 'Female', value: 'female' },
 *     { label: 'Other', value: 'other' },
 *   ]}
 *   error={meta => meta.touched && meta.error}
 *   helperText={meta => meta.touched && meta.error ? meta.error : ''}
 * />
 *
 * @returns {ReactElement} The RadioGroupField component.
 */

export const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
    name,
    label,
    hideLabel,
    description,
    options,
    required,
    disabled,
    row,
    parse,
    initialValue,
    helperText,
    error,
    validate,
    onChange,
    parseBool
}) => {
    const theme = useTheme();
    const { values } = useFormState();
    const form = useForm();

    const getNestedValue = (obj: any, name: string) => {
        return name.split(".").reduce((o, k) => (o || {})[k], obj);
    };

    //const isSelected = (optionValue: string | number) => String(getNestedValue(values, name)) === String(optionValue);

    const isSelected = (optionValue: any) => {
        const value = getNestedValue(values, name);

        if (parseBool) {
            return value === (optionValue === 'true');
        } else {
            if (typeof value === 'number' && typeof optionValue === 'number') {
                return value === optionValue;
            } else if (typeof value === 'string' && typeof optionValue === 'string') {
                return value === optionValue;
            } else {
                return String(value) === String(optionValue);
            }
        }
      };

    const parseNumber = (value) => (isNaN(Number(value)) ? value : Number(value));
    const parseBoolean = (value) => (value === 'true');

    return (
        <>
            <Field
                name={name}
                validate={(value: any) => {
                    if (validate) {
                        return validate(value);
                    }
                }}
                initialValue={initialValue}
                parse={parseBool ? parseBoolean : parse ? parseNumber : undefined}
                type="radio">
                {({ input, meta }) => (
                    <FormControl
                        sx={{ ml: 1, mb: 1 }}
                        component="fieldset"
                        error={Boolean(
                            ((meta.touched || meta.dirty) && meta.error) || meta.submitError || (meta.touched && meta.invalid) || (error && error(meta))
                        )}>
                        {!hideLabel && (
                            <FormLabel component="legend" required={required} sx={{ ml: -1, mb: 1 }}>
                                {label}
                            </FormLabel>
                        )}
                        {description && (
                            <Typography sx={{ mt: -1, ml: -1, mb: 1 }} variant="caption">
                                {description}
                            </Typography>
                        )}
                        <RadioGroup {...input} row={row}>
                            {options.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={parseBool ? String(option.value === 'true') : option.value}
                                    control={<Radio disabled={disabled} checked={isSelected(option.value)} />}
                                    label={option.label}
                                    disabled={disabled || option.disabled}
                                    sx={{
                                        border: isSelected(option.value)
                                            ? `1px solid ${theme.palette.primary.main}`
                                            : "1px solid transparent",
                                        backgroundColor: isSelected(option.value) ? theme.palette.primary.dark : "transparent",
                                        borderRadius: 1,
                                        color: isSelected(option.value) ? theme.palette.primary.contrastText : undefined,
                                        py: theme.spacing(0.5),
                                        pr: theme.spacing(2),
                                        mb: theme.spacing(1),
                                        mr: row ? undefined : 1,
                                        "&:hover": {
                                            borderColor: isSelected(option.value) ? theme.palette.primary.main : theme.palette.action.hover
                                        }
                                    }}
                                />
                            ))}
                        </RadioGroup>
                        <Typography
                            sx={{ ml: -1 }}
                            variant="caption"
                            color={((meta.touched || meta.dirty) && meta.error) || meta.submitError ? "error" : undefined}>
                            {(meta.touched || meta.dirty) && meta.error ? meta.error : meta.submitError ? meta.submitError : helperText && helperText(meta)}
                        </Typography>
                    </FormControl>
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {

                    function removeEmpty(obj: any) {
                        Object.keys(obj).forEach((key) => {
                            if (obj[key] && typeof obj[key] === "object") {
                                removeEmpty(obj[key]);
                            }
                            if (obj[key] && Array.isArray(obj[key]) && !obj[key].length) {
                                delete obj[key];
                            }
                            if (obj[key] && typeof obj[key] === "object" && !Object.keys(obj[key]).length) {delete obj[key];}
                        });
                        return obj;
                    }

                    if (form.getState().submitErrors) {
                        const keys = name.split(".");
                        let submitErrors = form.getState().submitErrors;

                        for (let key of keys) {
                            if (submitErrors && typeof submitErrors === "object" && key in submitErrors) {
                                submitErrors = submitErrors[key];
                            } else {
                                submitErrors = undefined;
                                break;
                            }
                        }

                        if (submitErrors) {
                            console.log("Submit error on field", submitErrors);
                            // Deleting the error from the original object is a bit more complex because you need to navigate to the parent object
                            let parentObject = form.getState().submitErrors;
                            let grandParentObject;
                            let grandParentKey;
                            for (let i = 0; i < keys.length - 1; i++) {
                                if (parentObject && typeof parentObject === "object" && keys[i] in parentObject) {
                                    grandParentObject = parentObject;
                                    grandParentKey = keys[i];
                                    parentObject = parentObject[keys[i]];
                                } else {
                                    parentObject = undefined;
                                    break;
                                }
                            }
                            if (parentObject && typeof parentObject === "object") {
                                delete parentObject[keys[keys.length - 1]];
                                // Check if parentObject is now empty
                                if (Object.keys(parentObject).length === 0 && grandParentObject && grandParentKey) {
                                    delete grandParentObject[grandParentKey];
                                }
                            }
                        }

                        console.log(removeEmpty(form.getState().submitErrors));
                        //form.mutators.setSubmitErrors(removeEmpty(form.getState().submitErrors));
                    }

                    //form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));

                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </>
    );
};

export default RadioGroupField;
