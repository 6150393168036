import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Stack,
    Box,
    Typography,
    DialogTitle,
    DialogContent,
    Button,
    Tooltip,
    DialogActions,
    Alert,
    Checkbox,
    InputBase,
    useTheme
} from "@mui/material";
import { FileUpload } from "components/Forms";
import { agent, IConfirmImportEntryExtended, RootStoreContext } from "api";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";

const returnUrl = "/multiviewers/layouts";

interface ICreateMultiViewerLayout {
    getData: () => void;
}

export const CreateMultiViewerLayout: React.FC<ICreateMultiViewerLayout> = ({ getData }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const navigate = useNavigate();
    const theme = useTheme();
    const [error, setError] = useState<any>("");
    const [file, setFile] = useState<File | null>(null);
    const [items, setItems] = useState<IConfirmImportEntryExtended[]>([]);
    const [step, setStep] = useState<number>(1);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setItems([]);
            setError("");
        }
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, item: IConfirmImportEntryExtended) => {
        const { checked } = e.target;
        setItems((prev) => prev.map((entry) => (entry.id === item.id ? { ...entry, import: checked } : entry)));
    };

    const handleUpload = async () => {
        if (file) {
            try {
                const result = await agent.Channel.uploadMultiViewerConfig(file);
                setItems(
                    result?.data.confirm_import?.map((entry: any) => ({
                        ...entry,
                        import: true
                    })) || []
                );
                console.log("handleUpload result:", result);
                setStep(2);
            } catch (error) {
                console.error("handleUpload error:", error);
                setError((error as any)?.data?.message || "An error occurred while uploading the file");
            } finally {
                setFile(null);
            }
        }
    };

    const confirmUpload = async () => {
        const itemsToImport = items
            .filter((item) => item.import)
            .map((item) => ({ id: item.id || 0, name: item.name || "", import: item.import }));

        if (!itemsToImport.length) {
            setError("Please select at least one layout to import");
            return;
        }

        try {
            const result = await agent.Channel.confirmMultiViewerLayoutImport({
                confirm_entries: itemsToImport
            });
            console.log("confirmUpload result:", result);
        } catch (error) {
            console.error("confirmUpload error:", error);
        } finally {
            setItems([]);
            getData && getData();
            navigate(returnUrl);
        }
    };

    if (step === 1) {
        return (
            <>
                <DialogTitle>Import Layout</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <FileUpload handleFileChange={handleFileChange} />

                            {file && (
                                <Stack spacing={1}>
                                    <Typography>Selected file: {file.name}</Typography>
                                </Stack>
                            )}
                        </Stack>

                        {error && (
                            <Alert severity="error" style={{ marginBottom: "1rem" }}>
                                {error}
                            </Alert>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeDialog();
                        }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleUpload} disabled={!file}>
                        Next
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <DialogTitle>Import the following layouts:</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {error && (
                        <Alert severity="error" style={{ marginBottom: "1rem" }}>
                            {error}
                        </Alert>
                    )}

                    {items.length > 0 && (
                        <Stack spacing={1}>
                            {items.map((entry) => (
                                <Stack key={entry.id}>
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <Tooltip placement="right" title="Import this layout">
                                            <Checkbox
                                                color="primary"
                                                onChange={(e) => handleCheckboxChange(e, entry)}
                                                checked={entry.import}
                                            />
                                        </Tooltip>

                                        <Box sx={{ width: "100%" }}>
                                            <Tooltip title="Set the title for the layout">
                                                <InputBase
                                                    onChange={(e) =>
                                                        setItems((prev) =>
                                                            prev.map((item) =>
                                                                item.id === entry.id ? { ...item, name: e.target.value } : item
                                                            )
                                                        )
                                                    }
                                                    placeholder="Title*"
                                                    fullWidth
                                                    value={entry.name}
                                                    disabled={!entry.import}
                                                    required
                                                    sx={{
                                                        px: 1,
                                                        fontSize: "1rem",
                                                        lineHeight: 1,
                                                        fontWeight: 600,
                                                        borderRadius: 1,
                                                        border: "1px dashed",
                                                        // borderColor: "transparent",
                                                        borderColor: theme.palette.action.hover,
                                                        "&:hover": {
                                                            borderColor: (theme) => theme.palette.action.hover
                                                        },
                                                        "&.Mui-focused": {
                                                            borderColor: (theme) => theme.palette.action.focus
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                            <Box sx={{ marginLeft: "10px", marginTop: 1 }}>
                                                <Typography variant="body2">{`Total Tiles: ${entry.total_number_of_tiles}`}</Typography>
                                                <Typography variant="body2">{`Video Tiles: ${entry.number_of_video_tiles}`}</Typography>
                                            </Box>
                                        </Box>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setStep(1);
                    }}>
                    Go back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        openDialog(
                            <ConfirmationDialog
                                title={"Import layouts"}
                                message={"Are you sure you want to import these layouts?"}
                                additionalContent={
                                    <>
                                        {items.filter((item) => item.import)?.length > 0 && (
                                            <Box>
                                                <Typography variant="body2">
                                                    <ul>
                                                        {items
                                                            .filter((item) => item.import)
                                                            .map((item) => (
                                                                <li key={item.id}>{item.name}</li>
                                                            ))}
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                }
                                onConfirm={confirmUpload}
                            />
                        );
                    }}
                    disabled={!items.filter((item) => item.import).length}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};

export default observer(CreateMultiViewerLayout);
