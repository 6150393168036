import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
    FormControl,
    Stack,
    Switch,
    Box,
    Typography,
    DialogTitle,
    DialogContent,
    Button,
    Tooltip,
    DialogActions,
    Alert,
    IconButton,
    Card,
    CardHeader,
    CardContent,
    FormControlLabel
} from "@mui/material";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { CheckboxField, RadioGroupField, SliderField, TextField } from "components/Forms";
import StepperForm from "components/Forms/StepperForm";
import { agent, IMultiChanLayouts, MVPresetEntry, RootStoreContext } from "api";
import { SelectField } from "components/Forms";
import * as multiChannelOptions from "app/common/options/multiChannelOptions";
import OnChange from "components/FinalFormListener/OnChange";
import MultiViewerLayoutGraphic from "../Components/MultiViewerLayoutGraphic";
import { RenderGlossary } from "components";
import DeleteIcon from "@mui/icons-material/Delete";
import { FORM_ERROR } from "final-form";
import useGlossary from "hooks/UseGlossary";
import { useSources } from "hooks";
import ConfirmationDialog from "components/ConfirmationDialog";

interface IChanDropdownEntry {
    key: string;
    text: string;
    value: string;
}

interface ISelectDropdownEntry {
    key: number;
    text: string;
    value: number;
}

const returnUrl = "/multiviewers";

const nameRegex = {
    length: {
        regex: new RegExp("^[a-z0-9_ -]+$", "si"),
        message: "Name must not contain special characters"
    }
};

const validateName = (value: string, isRequired = true) => {
    if (!value && isRequired) {
        return "Name is required";
    }

    for (const key in nameRegex) {
        if (!nameRegex[key].regex.test(value)) {
            return nameRegex[key].message;
        }
    }

    return undefined;
};

interface ICreateMultiViewer {
    mode?: "create" | "edit";
}

const CreateMultiViewer: React.FC<ICreateMultiViewer> = ({ mode = "create" }) => {
    const navigate = useNavigate();
    const rootStore = useContext(RootStoreContext);
    const { createMultiChannel, loadMultiViewerLayouts, getInputsDropdownList, getMultiViewerPresetsClean } = useSources();
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [layout_id, setLayout_id] = useState<number>(-1);
    const [numberOfTilesChosen, setNumberOfTilesChosen] = useState<number>(4);
    const [error, setError] = useState<any>("");
    const [multiViewerLayouts, setMultiViewerLayouts] = useState<IMultiChanLayouts | null>(null);
    const [channelsDropdown, setChannelsDropdown] = useState<IChanDropdownEntry[] | null>(null);
    const [presets, setPresets] = useState<MVPresetEntry[] | null>(null);
    const [hasPresets, setHasPresets] = useState<boolean | null>(null);
    const [encoderFrameRateIdDropdown, setEncoderDropdown] = useState<ISelectDropdownEntry[]>(
        multiChannelOptions.nonInterlacedEncoderFrameRate
    );
    const [name, setName] = useState<boolean>(true);
    const { glossary } = useGlossary();
    const [state, setState] = useState({
        isInterlaced: false,
        store_preset: false
    });
    const initialValues = {
        program_number: 1,
        pmt_pid: 100,
        video_pid: 101,
        transcoded_audio_pid: 102,
        is_interlaced: false
    };

    const loadPresets = () => {
        getMultiViewerPresetsClean().then((values) => {
            setPresets(values.data);
            setHasPresets(values.data.length > 0);
        });
    };

    const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setState({ ...state, [e.target.name]: e.target.checked });
    };

    const handleInterlacedChange = (is_interlaced: boolean) => {
        setState({ ...state, isInterlaced: is_interlaced });
        if (is_interlaced) {
            setEncoderDropdown(multiChannelOptions.interlacedEncoderFrameRate);
        } else {
            setEncoderDropdown(multiChannelOptions.nonInterlacedEncoderFrameRate);
        }
    };

    useEffect(() => {
        loadMultiViewerLayouts().then((values) => {
            setMultiViewerLayouts(values);
        });

        getInputsDropdownList().then((values) => {
            let dropdownValues: IChanDropdownEntry[] = [
                {
                    key: "-1",
                    text: "- no source -",
                    value: "-1"
                }
            ];
            if (values) {
                values.data
                    .filter((entry) => !entry.is_multi_viewer)
                    .forEach((entry) => {
                        dropdownValues.push({
                            key: entry.id,
                            text: entry.name,
                            value: entry.id
                        });
                    });
            }
            setChannelsDropdown(dropdownValues);
        });

        loadPresets();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form
            onSubmit={(values) => {
                return createMultiChannel({
                    ...values,
                    passthrough_audio_pid: 0,
                    store_preset: state.store_preset
                })
                    .then(() => {})
                    .catch((error) => {
                        const errors = error.data.errors;
                        return { [FORM_ERROR]: error, ...errors };
                    });
            }}
            initialValues={initialValues}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors,
                dirty
            }) => (
                <form onSubmit={handleSubmit}>
                    <StepperForm
                        formTitle={`${mode === "create" ? "Create" : "Edit"} Multiviewer`}
                        steps={[
                            {
                                label: glossary.features.multiviewer.form.steps.settings.title,
                                description: glossary.features.multiviewer.form.steps.settings.description,
                                hasError: Boolean(submitErrors?.name),
                                infoContent: (
                                    <Stack spacing={1}>
                                        <Typography>
                                            <b>Name</b> - <RenderGlossary term={glossary.features.multiviewer.form.fields?.name} />
                                        </Typography>

                                        <Typography>
                                            <b>Layout</b> - <RenderGlossary term={glossary.features.multiviewer.form.fields?.layout} />
                                        </Typography>
                                        <img
                                            src="/assets/img/tag_500px.png"
                                            width="75"
                                            style={{ marginTop: "2rem" }}
                                            alt="Powered by TAG"
                                            title="Powered by TAG"
                                        />
                                    </Stack>
                                ),
                                content: (
                                    <Stack spacing={2}>
                                        {error && (
                                            <Alert severity="error" style={{ marginBottom: "1rem" }}>
                                                {error}
                                            </Alert>
                                        )}
                                        <Box>
                                            <TextField
                                                name="resulting_input_name"
                                                label="Name "
                                                required
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Name is required";
                                                    }
                                                    return validateName(value);
                                                }}
                                            />
                                        </Box>

                                        <Stack spacing={2} alignItems="flex-start">
                                            <Box sx={{ paddingLeft: 0.25 }}>
                                                <RadioGroupField
                                                    name="layout_id"
                                                    label="Select the Multiviewer Layout"
                                                    disabled={false}
                                                    row
                                                    parse
                                                    required
                                                    initialValue={
                                                        layout_id !== -1
                                                            ? layout_id
                                                            : multiViewerLayouts?.data
                                                            ? multiViewerLayouts.data[0].id
                                                            : 0
                                                    }
                                                    options={
                                                        multiViewerLayouts?.data?.map((layout) => ({
                                                            label: `${layout.name} (${layout.total_number_of_tiles} total tiles, ${layout.number_of_video_tiles} video tiles)`,
                                                            value: layout.id
                                                        })) || []
                                                    }
                                                    onChange={(field, value) => {
                                                        setLayout_id(value);
                                                        setNumberOfTilesChosen(
                                                            multiViewerLayouts?.data?.find((layout) => layout.id === Number(value))
                                                                ?.number_of_tiles || 4
                                                        );
                                                    }}
                                                />
                                            </Box>

                                            {layout_id && multiViewerLayouts && (
                                                <MultiViewerLayoutGraphic
                                                    item={multiViewerLayouts?.data.find((layout) => layout.id === layout_id)}
                                                />
                                            )}
                                        </Stack>
                                    </Stack>
                                )
                            },
                            {
                                label: glossary.features.multiviewer.form.steps.tileSources.title,
                                description: glossary.features.multiviewer.form.steps.tileSources.description,
                                infoContent: (
                                    <Stack spacing={1}>
                                        <Typography>
                                            <b>Title</b> - <RenderGlossary term={glossary.features.multiviewer.form.fields?.title} />
                                        </Typography>

                                        <Typography>
                                            <b>Source</b> - <RenderGlossary term={glossary.features.multiviewer.form.fields?.source} />
                                        </Typography>
                                    </Stack>
                                ),
                                content: (
                                    <Stack>
                                        {error && (
                                            <Alert severity="error" style={{ marginBottom: "1rem" }}>
                                                {error}
                                            </Alert>
                                        )}

                                        <Box sx={{ mb: 4 }}>
                                            <CheckboxField
                                                name="double_cpu"
                                                disabled={mode === "edit"}
                                                label="Double CPU"
                                                helperText={(meta) =>
                                                    meta.touched && meta.error
                                                        ? meta.error
                                                        : "Select if one or more inputs are/or will be any of the following; HEVC encoded, Progressive 60 Frames or High Bitrate (30Mbps)"
                                                }
                                            />
                                        </Box>

                                        {Array(numberOfTilesChosen)
                                            .fill(0)
                                            .map((_, index) => {
                                                const tile_number = index + 1;
                                                const field_name = `tile_inputs[${index}]`;
                                                return (
                                                    <Card key={index} sx={{ mb: 2 }}>
                                                        <CardHeader title={`Tile ${index + 1}`} />

                                                        <CardContent>
                                                            <Stack spacing={3} direction="row" justifyContent="space-between">
                                                                <Box sx={{ flex: 2 }}>
                                                                    <TextField
                                                                        name={`${field_name}.tile_nr`}
                                                                        sx={{ display: "none" }}
                                                                        type="number"
                                                                        initialValue={tile_number}
                                                                        label=""
                                                                    />
                                                                    <TextField
                                                                        name={`${field_name}.title`}
                                                                        type="text"
                                                                        label={`Tile ${tile_number} Title`}
                                                                        validate={(value) => {
                                                                            return validateName(value, false);
                                                                        }}
                                                                    />
                                                                    {channelsDropdown && (
                                                                        <SelectField
                                                                            name={`${field_name}.input_id`}
                                                                            label={`Tile ${tile_number} Source`}
                                                                            options={channelsDropdown}
                                                                            helperText={(meta) => meta.touched && meta.error}
                                                                            error={(meta) => meta.touched && meta.error}
                                                                        />
                                                                    )}
                                                                </Box>
                                                                <Box sx={{ flex: 1 }}>
                                                                    <MultiViewerLayoutGraphic
                                                                        highlightedIndex={tile_number}
                                                                        item={multiViewerLayouts?.data.find(
                                                                            (layout) => layout.id === values.layout_id
                                                                        )}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        </CardContent>
                                                    </Card>
                                                );
                                            })}
                                        {/* <FieldArray
                                                name="tile_inputs"
                                                defaultValue={[{}]}
                                                validate={(value) => {
                                                    if (!value) {
                                                        return "Required";
                                                    }
                                                }}
                                            >
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, index) => (
                                                            <Card key={index} sx={{ mb: 2 }}>
                                                                <CardHeader
                                                                    title={`Tile ${index + 1}`}
                                                                />
                                                                <CardContent>
                                                                    <TextField
                                                                        name={`tile_inputs[${index}].tile_nr`}
                                                                        sx={{ display: "none" }}
                                                                        type="number"
                                                                        initialValue={index + 1}
                                                                        label=""
                                                                    />
                                                                    <TextField
                                                                        name={`tile_inputs[${index}].title`}
                                                                        type="text"
                                                                        label={`Tile ${index + 1} Title`}
                                                                        validate={(value) => {
                                                                            return validateName(value, false);
                                                                        }}
                                                                    />
                                                                    {channelsDropdown && (
                                                                        <SelectField
                                                                            name={`tile_inputs[${index}].input_id`}
                                                                            label={`Tile ${index + 1} Source`}
                                                                            options={channelsDropdown}
                                                                            helperText={(meta) => meta.touched && meta.error}
                                                                            error={(meta) => meta.touched && meta.error}
                                                                        />
                                                                    )}
                                                                </CardContent>
                                                            </Card>
                                                        ))}
                                                    </>
                                                )}
                                            </FieldArray> */}
                                    </Stack>
                                )
                            },
                            {
                                label: glossary.features.multiviewer.form.steps.output.title,
                                description: glossary.features.multiviewer.form.steps.output.description,
                                hasError: Boolean(
                                    submitErrors?.preset ||
                                        submitErrors?.max_allowed_bitrate_kbps ||
                                        submitErrors?.is_interlaced ||
                                        submitErrors?.encoder_frame_rate_id ||
                                        submitErrors?.encoder_gop_size_id ||
                                        submitErrors?.encoder_gop_size_mode_id ||
                                        submitErrors?.program_number ||
                                        submitErrors?.pmt_pid ||
                                        submitErrors?.video_pid ||
                                        submitErrors?.transcoded_audio_pid
                                ),
                                infoContent: (
                                    <Stack spacing={1}>
                                        <Typography>
                                            <b>Load a Preset</b> - <RenderGlossary term={glossary.fieldValues.loadPreset} />
                                        </Typography>

                                        <Typography>
                                            <b>Max Bitrate</b> - <RenderGlossary term={glossary.fieldValues.maxBitrate} />
                                        </Typography>

                                        <Typography>
                                            <b>Video Frame Type</b> - <RenderGlossary term={glossary.fieldValues.videoFrameType} />
                                        </Typography>

                                        <Typography>
                                            <b>Video Frame Rate</b> - <RenderGlossary term={glossary.fieldValues.videoFramerate} />
                                        </Typography>

                                        <Typography>
                                            <b>GOP Size</b> - <RenderGlossary term={glossary.fieldValues.GOPSize} />
                                        </Typography>

                                        <Typography>
                                            <b>GOP Size Mode</b> - <RenderGlossary term={glossary.fieldValues.GOPSizeMode} />
                                        </Typography>

                                        <Typography>
                                            <b>Program Number</b> - <RenderGlossary term={glossary.fieldValues.programNumber} />
                                        </Typography>

                                        <Typography>
                                            <b>PMT PID</b> - <RenderGlossary term={glossary.fieldValues.PMTPID} />
                                        </Typography>

                                        <Typography>
                                            <b>Video PID</b> - <RenderGlossary term={glossary.fieldValues.videoPID} />
                                        </Typography>

                                        <Typography>
                                            <b>AAC Audio PID</b> - <RenderGlossary term={glossary.fieldValues.AACAudioPID} />
                                        </Typography>

                                        <Typography>
                                            <b>Save as Preset</b> - <RenderGlossary term={glossary.fieldValues.savePreset} />
                                        </Typography>

                                        <Typography>
                                            <b>Update an Existing Preset</b> - <RenderGlossary term={glossary.fieldValues.updatePreset} />
                                        </Typography>

                                        <Typography>
                                            <b>Save as a New Preset</b> - <RenderGlossary term={glossary.fieldValues.saveNewPreset} />
                                        </Typography>
                                    </Stack>
                                ),
                                content: (
                                    <Stack spacing={2}>
                                        {error && (
                                            <Alert severity="error" style={{ marginBottom: "1rem" }}>
                                                {error}
                                            </Alert>
                                        )}
                                        {presets && hasPresets && (
                                            <Box
                                                sx={{
                                                    mb: 2
                                                }}>
                                                <Stack spacing={2}>
                                                    <Box sx={{ position: "relative" }}>
                                                        <FormControl
                                                            sx={{
                                                                position: "unset"
                                                            }}>
                                                            <SelectField
                                                                label="Load a Preset"
                                                                name="preset"
                                                                options={[
                                                                    {
                                                                        id: 0,
                                                                        name: "No Preset",
                                                                        parameters: undefined
                                                                    },
                                                                    ...presets
                                                                ].map((preset) => ({
                                                                    key: preset.id,
                                                                    value: preset.id,
                                                                    text: (
                                                                        <Stack
                                                                            key={preset.id}
                                                                            direction="row"
                                                                            justifyContent="space-between"
                                                                            alignItems="center"
                                                                            spacing={2}>
                                                                            <Box>{preset.name}</Box>

                                                                            <Box
                                                                                sx={{
                                                                                    justifyContent: "flex-end",
                                                                                    display:
                                                                                        values.preset === preset.id || preset.id === 0
                                                                                            ? "none"
                                                                                            : ""
                                                                                }}>
                                                                                <Tooltip title="Delete preset">
                                                                                    <IconButton
                                                                                        aria-label="delete"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            // e.stopPropagation();

                                                                                            openDialog(
                                                                                                <ConfirmationDialog
                                                                                                    title="Confirm Delete Preset"
                                                                                                    message={`Are you sure you want to delete the preset "${preset.name}"?`}
                                                                                                    onConfirm={async () => {
                                                                                                        return agent
                                                                                                            .getCleanInstance()
                                                                                                            .delete(
                                                                                                                `/multi-viewers/presets/${preset.id}`
                                                                                                            );
                                                                                                    }}
                                                                                                    onSuccess={() => {
                                                                                                        loadPresets();
                                                                                                        openDialog(
                                                                                                            <>
                                                                                                                <DialogTitle>
                                                                                                                    Success!
                                                                                                                </DialogTitle>
                                                                                                                <DialogContent>
                                                                                                                    <Typography>
                                                                                                                        The preset has been
                                                                                                                        successfully
                                                                                                                        deleted.
                                                                                                                    </Typography>
                                                                                                                </DialogContent>
                                                                                                                <DialogActions>
                                                                                                                    <Button
                                                                                                                        onClick={() => {
                                                                                                                            closeDialog();
                                                                                                                        }}>
                                                                                                                        Close
                                                                                                                    </Button>
                                                                                                                </DialogActions>
                                                                                                            </>
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            );
                                                                                        }}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </Stack>
                                                                    )
                                                                }))}
                                                            />
                                                        </FormControl>
                                                    </Box>

                                                    <OnChange name="preset">
                                                        {(value) => {
                                                            if (value > 0) {
                                                                let newVal = presets.find((p) => p.id === value)!.parameters;
                                                                form.change("max_allowed_bitrate_kbps", newVal.max_allowed_bitrate_kbps);
                                                                form.change("is_interlaced", newVal.is_interlaced);
                                                                form.change("encoder_frame_rate_id", newVal.encoder_frame_rate_id);
                                                                form.change("encoder_gop_size_id", newVal.encoder_gop_size_id);
                                                                form.change("encoder_gop_size_mode_id", newVal.encoder_gop_size_mode_id);
                                                                form.change("program_number", newVal.program_number);
                                                                form.change("pmt_pid", newVal.pmt_pid);
                                                                form.change("video_pid", newVal.video_pid);
                                                                form.change("transcoded_audio_pid", newVal.transcoded_audio_pid);
                                                            }
                                                        }}
                                                    </OnChange>
                                                </Stack>
                                            </Box>
                                        )}

                                        <SliderField
                                            name="max_allowed_bitrate_kbps"
                                            label="Max Bitrate (Kbps)"
                                            min={500}
                                            max={30000}
                                            step={100}
                                            validate={(value) => {
                                                if (value < 500 || value > 30000) {
                                                    return "Max bitrate must be between 500 and 30000";
                                                }
                                            }}
                                            initialValue={5000}
                                        />

                                        <RadioGroupField
                                            name="is_interlaced"
                                            label="Video Frame Type"
                                            row
                                            parseBool
                                            options={
                                                multiChannelOptions.videoFrameType?.map((type) => ({
                                                    label: `${type.text}`,
                                                    value: type.value
                                                })) || []
                                            }
                                            onChange={(field, value) => {
                                                handleInterlacedChange(value);
                                            }}
                                        />

                                        <RadioGroupField
                                            name="encoder_frame_rate_id"
                                            label="Video Frame Rate"
                                            required
                                            parse
                                            row
                                            options={
                                                encoderFrameRateIdDropdown?.map((type) => ({
                                                    label: `${type.text}`,
                                                    value: type.value
                                                })) || []
                                            }
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Video Frame Rate is required";
                                                }
                                            }}
                                        />

                                        <RadioGroupField
                                            name="encoder_gop_size_id"
                                            label="GOP Size"
                                            parse
                                            row
                                            required
                                            options={
                                                multiChannelOptions.encoderGOPSize?.map((type) => ({
                                                    label: `${type.text}`,
                                                    value: type.value
                                                })) || []
                                            }
                                            validate={(value) => {
                                                if (!value) {
                                                    return "GOP Size is required";
                                                }
                                            }}
                                        />

                                        <RadioGroupField
                                            name="encoder_gop_size_mode_id"
                                            label="GOP Size Mode"
                                            parse
                                            row
                                            required
                                            options={
                                                multiChannelOptions.encoderGOPSizeMode?.map((type) => ({
                                                    label: `${type.text}`,
                                                    value: type.value
                                                })) || []
                                            }
                                            validate={(value) => {
                                                if (!value) {
                                                    return "GOP Size Mode is required";
                                                }
                                            }}
                                        />

                                        <TextField
                                            required
                                            name="program_number"
                                            type="text"
                                            label="Program Number"
                                            helperText={(meta) => {
                                                return meta.touched && meta.error
                                                    ? meta.error
                                                    : "Program number must be between 1 and 65535";
                                            }}
                                            parse
                                            validate={(value) => {
                                                if (value < 1 || value > 65535) {
                                                    return "Program number must be between 1 and 65535";
                                                }
                                            }}
                                        />

                                        <TextField
                                            required
                                            name="pmt_pid"
                                            type="text"
                                            label="PMT PID"
                                            parse
                                            helperText={(meta) => {
                                                return meta.touched && meta.error
                                                    ? meta.error
                                                    : "PMT PID must be between 33 and 8190. Also it must be unique from Video PID and AAC Audio PID.";
                                            }}
                                            validate={(value) => {
                                                if (value < 33 || value > 8190) {
                                                    return "PMT PID must be between 33 and 8190";
                                                }
                                                if (value === values.video_pid) {
                                                    return "This pid number is used also in Video PID field. Please choose another PID number to make them unique.";
                                                }
                                                if (value === values.transcoded_audio_pid) {
                                                    return "This pid number is used also in AAC Audio PID field. Please choose another PID number to make them unique.";
                                                }
                                            }}
                                        />

                                        <TextField
                                            required
                                            name="video_pid"
                                            type="text"
                                            label="Video PID"
                                            parse
                                            helperText={(meta) => {
                                                return meta.touched && meta.error
                                                    ? meta.error
                                                    : "Video PID must be between 33 and 8190. Also it must be unique from PMT PID and AAC Audio PID.";
                                            }}
                                            validate={(value) => {
                                                if (value < 33 || value > 8190) {
                                                    return "Video PID must be between 33 and 8190";
                                                }
                                                if (value === values.pmt_pid) {
                                                    return "This pid number is used also in PMT PID field. Please choose another PID number to make them unique.";
                                                }
                                                if (value === values.transcoded_audio_pid) {
                                                    return "This pid number is used also in AAC Audio PID field. Please choose another PID number to make them unique.";
                                                }
                                            }}
                                        />

                                        <TextField
                                            required
                                            name="transcoded_audio_pid"
                                            type="text"
                                            label="AAC Audio PID"
                                            parse
                                            helperText={(meta) => {
                                                return meta.touched && meta.error
                                                    ? meta.error
                                                    : "AAC Audio PID must be between 33 and 8190. Also it must be unique from PMT PID and Video PID.";
                                            }}
                                            validate={(value) => {
                                                if (value < 33 || value > 8190) {
                                                    return "AAC Audio PID must be between 33 and 8190";
                                                }
                                                if (value === values.pmt_pid) {
                                                    return "This pid number is used also in PMT PID field. Please choose another PID number to make them unique.";
                                                }
                                                if (value === values.video_pid) {
                                                    return "This pid number is used also in Video PID field. Please choose another PID number to make them unique.";
                                                }
                                            }}
                                        />
                                        <Box></Box>

                                        <Card>
                                            <CardContent>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={state.store_preset}
                                                            size="small"
                                                            onChange={(e) => {
                                                                handleSwitch(e);
                                                            }}
                                                            color="primary"
                                                            name="store_preset"
                                                            inputProps={{
                                                                "aria-label": "primary checkbox"
                                                            }}
                                                        />
                                                    }
                                                    label="Save as Preset"
                                                />

                                                {state.store_preset && (
                                                    <Stack>
                                                        <Stack>
                                                            <SelectField
                                                                name="preset_id"
                                                                label="Update an Existing Preset"
                                                                options={
                                                                    [
                                                                        {
                                                                            id: 0,
                                                                            name: "New Preset",
                                                                            parameters: undefined
                                                                        },
                                                                        ...(presets || [])
                                                                    ].map((preset) => ({
                                                                        key: preset.id,
                                                                        value: preset.id,
                                                                        text: preset.name
                                                                    })) || []
                                                                }
                                                            />

                                                            <OnChange name={"preset_id"}>
                                                                {(value) => {
                                                                    value === null || value === undefined || value === 0
                                                                        ? setName(true)
                                                                        : setName(false);
                                                                }}
                                                            </OnChange>
                                                        </Stack>

                                                        <Box>
                                                            {name && (
                                                                <>
                                                                    {presets && <Typography>Or</Typography>}
                                                                    <TextField
                                                                        name="preset_name"
                                                                        label="Save as a New Preset"
                                                                        // placeholder="Name your preset"
                                                                        // required={name && state.store_preset ? true : false}
                                                                        // validate={(value) => {
                                                                        //     if (name && state.store_preset && !value) {
                                                                        //         return "Name is required";
                                                                        //     }
                                                                        // }}
                                                                    />
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Stack>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Stack>
                                )
                            }
                        ]}
                        values={values}
                        invalid={invalid}
                        submitError={submitError}
                        showSuccessStep={submitSucceeded}
                        successStep={
                            <Stack spacing={1}>
                                <Typography>Request successful, your multiviewer is being built.</Typography>
                                <Typography>Depending on multiviewer size selected it takes up to 7 minutes to start.</Typography>
                            </Stack>
                        }
                        onCancel={() => {
                            dirty
                                ? openDialog(
                                      <>
                                          <DialogTitle>Are you sure?</DialogTitle>
                                          <DialogContent>You have unsaved changes. Are you sure you want to leave this page?</DialogContent>
                                          <DialogActions>
                                              <Button
                                                  onClick={() => {
                                                      closeDialog();
                                                  }}>
                                                  Cancel
                                              </Button>
                                              <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() => {
                                                      closeDialog();
                                                      navigate(returnUrl);
                                                  }}>
                                                  Leave
                                              </Button>
                                          </DialogActions>
                                      </>
                                  )
                                : navigate(returnUrl);
                        }}
                        onFinish={() => navigate(returnUrl)}
                    />
                </form>
            )}
        />
    );
};
export default observer(CreateMultiViewer);
