import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faGlobeEurope,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faRadar,
  faCalendarExclamation,
  faRandom,
  faDoorOpen,
  faSeedling,
  faHeadset,
  faMousePointer,
  faArrowLeft,
  faArrowRight,
  faExpandArrows,
  faCreditCardFront,
  faHandsHelping,
  faBallot,
  faMonitorHeartRate,
  faToggleOn,
  faUserUnlock,
  faHandHoldingSeedling,
} from "@fortawesome/pro-light-svg-icons";
import { faWaveformPath } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
export default library.add(
  fab,
  fal,
  fas,
  faCheckSquare,
  faRadar,
  faCalendarExclamation,
  faRandom,
  faDoorOpen,
  faSeedling,
  faHeadset,
  faMousePointer,
  faArrowLeft,
  faArrowRight,
  faWaveformPath,
  faExpandArrows,
  faCreditCardFront,
  faHandsHelping,
  faBallot,
  faMonitorHeartRate,
  faToggleOn,
  faGlobeEurope,
  faUserUnlock,
  faHandHoldingSeedling,
  faShare
);
