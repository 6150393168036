import {
    agent,
    IAddUserToYourOrgRequest,
    IChangeManagingOrganisationRequest,
    IEnvironment,
    IEnvironmentsData,
    IEnvironmentUpgradeBody,
    IOrganisationConnection,
    IOrganisationFormValues,
    ISchedulerFormValues,
    RootStoreContext,
    SwitchEnvironment,
    SwitchOrganisation
} from "api";
import { useState, useCallback, useContext } from "react";
import { useEnvironment } from "./UseEnvironment";

export const useOrganisation = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeModal } = rootStore.modalStore;
    const { setActiveorganisationId } = rootStore.userStore;
    const { setToken } = rootStore.commonStore;
    const { loadEnvironments } = useEnvironment();
    const { setLoading, setOrganisations, setManagingOrgList } = rootStore.organisationStore;
    const [isLoading, setIsLoading] = useState(false);

    const changeActiveOrganisation = useCallback(async (organisation: SwitchOrganisation) => {
        setIsLoading(true);
        const newActiveOrg = await agent.Organisation.switchActiveOrganisation(organisation);
        setActiveorganisationId(newActiveOrg.active_org);
        setToken(newActiveOrg.jwt_token);
        setIsLoading(false);
        loadEnvironments();
        return newActiveOrg;
    }, []);

    const addUserToOrganisation = useCallback(async (values: IAddUserToYourOrgRequest) => {
        return await agent.Organisation.addUserToOrganisation(values);
    }, []);

    const acceptOrgInvite = useCallback(async (token: IOrganisationConnection) => {
        const response = await agent.Organisation.joinOrganisation(token);
        rootStore.userStore.getUserPermissions();
        return response;
    }, []);

    const createOrganisation = useCallback(async (values: IOrganisationFormValues) => {
        const user = await agent.Organisation.createOrganisation(values);
        setToken(user.jwt_token);
        closeModal();
        rootStore.userStore.user = user;
        rootStore.userStore.getUserPermissions();
    }, []);

    const loadOrganisations = useCallback(async () => {
        setIsLoading(true);
        setLoading(true);
        const organisationsEnvelope = await agent.Organisation.getOrgansisations();
        setOrganisations(organisationsEnvelope);
        setIsLoading(false);
        setLoading(false);
        return organisationsEnvelope;
    }, []);

    const getManagingOrgList = useCallback(async () => {
        try {
            const response = await agent.Organisation.getManagingOrgList();
            setManagingOrgList(response);
            return response;
        } catch (error) {
            throw error;
        }
    }, []);

    const setManagingOrganisation = useCallback(async (value: IChangeManagingOrganisationRequest) => {
        try {
            const response = await agent.Organisation.setManagingOrg(value);
            rootStore.commonStore.setToken(response.jwt_token);
        } catch (error) {
            throw error;
        }
    }, []);

    return {
        isLoading,
        changeActiveOrganisation,
        addUserToOrganisation,
        acceptOrgInvite,
        createOrganisation,
        loadOrganisations,
        getManagingOrgList,
        setManagingOrganisation
    };
};
