import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type RegionAccordionProps = {
    id: string;
    human_name: string;
    region: string;
    provider: string;
    lng: number;
    lat: number;
    children?: JSX.Element;
    defaultExpanded: boolean;
    hidden?: boolean;
    onHideClick: () => void;
};

const RegionAccordion: React.FC<RegionAccordionProps> = ({
    human_name,
    provider,
    lat,
    lng,
    children,
    defaultExpanded,
    hidden,
    onHideClick
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const ItemAccordionStyles = {
    // }

    const RegionAccordionHeaderStyles = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: 14,
        fontWeight: 600,
        backgroundColor: "rgba(160,160,160,0.2)",
        padding: "10px"
    };

    const RegionAccordionBodyStyles = {
        overflow: "hidden",
        height: expanded ? "auto" : "0px"
    };

    return (
        <div className="region-accordion" style={{ backgroundColor: "#0f1419", marginBottom: "10px", overflow: "hidden" }}>
            <div className="region-accordion-header" style={RegionAccordionHeaderStyles}>
                <div>
                    <div>{human_name}</div>
                    <div style={{ fontSize: 10, opacity: 0.8 }}>
                        {provider} &bull; {lat}, {lng}
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon
                        title={hidden ? "Show on map" : "Hide on map"}
                        icon={["fal", hidden ? "eye-slash" : "eye"]}
                        onClick={() => onHideClick()}
                    />
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={["fal", expanded ? "chevron-up" : "chevron-down"]} onClick={() => setExpanded(!expanded)} />
                </div>
            </div>
            <div style={RegionAccordionBodyStyles}>{children}</div>
        </div>
    );
};

export default RegionAccordion;
