import { observable, action, makeAutoObservable, runInAction } from "mobx";
import ResClient from "resclient";
import ResError from "resclient/types/class/ResError";
import { RootStore } from "./rootStore";

export default class ResClientStore {
    rootStore: RootStore;
    client: ResClient = new ResClient(process.env.REACT_APP_WEBSOCKET_URL as string);
    clientConnected = false;
    clientConnecting = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            client: observable,
            clientConnected: observable,
            clientConnecting: observable,
            setClientConnected: action,
            initialize: action.bound,
            destroy: action.bound,
        });
    }

    @action
    setClientConnected(connected: boolean) {
        this.clientConnected = connected;
    }

    @action
    setClientConnecting(connecting: boolean) {
        this.clientConnecting = connecting;
    }

    @action
    async initialize(jwt: string) {
        if (!process.env.REACT_APP_WEBSOCKET_URL) {
            throw new Error("WebSocket URL is not defined");
        }

        // if (this.clientConnected || this.clientConnecting) {
        //     console.log("ResClient already initialized or connecting");
        //     return;
        // }

        this.setClientConnecting(true);

        await runInAction(() => {
            this.client
                .authenticate("auth", "jwtHeader", { "jwt-token": jwt })
                .then(() => {
                    console.log("ResClient authenticated");
                    runInAction(() => {
                        this.setClientConnected(true);
                    });
                })
                .catch((err: ResError) => {
                    console.warn("Error authenticating with ResClient", err);
                    if (err.message === "JWT expired") {
                        console.warn("JWT expired, logging out");
                        this.rootStore.userStore.logout();
                    }
                });

            this.client.on("disconnect", () => {
                runInAction(() => {
                    this.setClientConnected(false);
                });
            });

            this.client.on("system.accessDenied", (event) => {
                console.warn("Access Denied", event);
            });

            this.client.on("system.invalidParams", (event) => {
                console.warn("System Invalid Params", event);
            });
        });
    }

    @action
    destroy() {
        this.client.disconnect();
        console.warn("ResClient destroyed");
        runInAction(() => {
            this.clientConnected = false;
        });
    }
}
