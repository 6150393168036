import { agent, RootStoreContext } from "api";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import React, { useContext, useEffect } from "react";
import useModuleAccess from "hooks/UseModuleAccess";
import useRoleProvider from "hooks/UseRoleProvider";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import { Delete, VolumeOff } from "@mui/icons-material";
import NotificationItem from "./Components/NotificationItem";
import ConfirmationDialog from "components/ConfirmationDialog";

interface NotificationCategory {
    category: string;
    title: string;
    unreadCount: number;
    muted?: boolean;
    disabled?: boolean;
}

export const Notifications: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const managingOrgId = rootStore.userStore.managingOrganisationId;
    const { client, clientConnected } = rootStore.resClientStore;
    const [wsData, setWsData] = React.useState<any[]>([]);
    const [activeCategory, setActiveCategory] = React.useState<any>();
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [selected, setSelected] = React.useState<string[]>([]);

    const [categories, setCategories] = React.useState<NotificationCategory[]>([]);
    const [mutedCategories, setMutedCategories] = React.useState<string[]>([]);

    const countUnreadNotificationsInCategory = (category: string) => {
        if (!wsData) {
            return 0;
        }
        return Object.values(wsData).filter(
            (value) => (value as { category: string }).category === category && !(value as { read: boolean }).read
        ).length;
    };

    const fetchData = async () => {
        await client
            .get(`notifications.${managingOrgId}`)
            .then((res) => {
                setUpdateDate(new Date());
                res.on("change", () => {
                    setWsData(res.toJSON());
                    buildCategoriesList();
                    setUpdateDate(new Date());
                });
                setWsData(res.toJSON());
                buildCategoriesList();
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    const getMutedCategories = async () => {
        await agent.Notification.getMutedCategories()
            .then((res) => {
                setMutedCategories(res.muted_categories);
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    useEffect(() => {
        getMutedCategories();
        if (client && clientConnected) {
            fetchData();
        } else {
            const intervalId = setInterval(() => {
                if (clientConnected) {
                    clearInterval(intervalId);
                    fetchData();
                }
            }, 1000);
        }
    }, [client, clientConnected, managingOrgId, rootStore.commonStore.token]);

    useEffect(() => {}, [updateDate, wsData]);

    let notification_categories = [
        { category: "system", title: "System" },
        { category: "inputs", title: "Sources" },
        { category: "input_alerts", title: "Source Alerts" },
        { category: "outputs", title: "Destinations" },
        { category: "output_alerts", title: "Destination Alerts" },
        { category: "schedule", title: "Schedule" },
        { category: "environments", title: "Environments" },
        { category: "transcoders", title: "Transcoders" },
        {
            category: "mvs",
            title: "Multiviewers",
            disabled: !useModuleAccess("tag")
        },
        {
            category: "titanfile",
            title: "TitanFile",
            disabled: !useModuleAccess("file_transcoding")
        },
        { category: "organisation", title: "Organisation" },
        { category: "billing", title: "Billing" },
        {
            category: "packager",
            title: "Push Packager",
            disabled: !useModuleAccess("push_packager")
        },
        {
            category: "pull_packager",
            title: "Pull Packager",
            disabled: !useModuleAccess("nealive")
        },
        {
            category: "own_cloud",
            title: "Own Cloud",
            disabled: !useRoleProvider(["has_any_own_cloud_available"], false)
        },
        { category: "unknown", title: "Unknown" }
    ];

    const buildCategoriesList = () => {
        let cats: NotificationCategory[] = [];
        //if muted categories contains the category.category, then set muted to true
        notification_categories.forEach((category) => {
            cats.push({
                category: category.category,
                title: category.title,
                unreadCount: countUnreadNotificationsInCategory(category.category),
                muted: mutedCategories.includes(category.category),
                disabled: category.disabled
            });
        });
        setCategories(cats);
    };

    useEffect(() => {
        buildCategoriesList();
    }, [wsData, mutedCategories]);

    const updateMutedCategories = async (category: string, mute: boolean) => {
        let mutedCats = [...mutedCategories];
        if (mute) {
            mutedCats.push(category);
        } else {
            mutedCats = mutedCats.filter((cat) => cat !== category);
        }
        await agent.Notification.muteCategories({ categories: mutedCats })
            .then(() => {
                setMutedCategories(mutedCats);
                buildCategoriesList();
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    const deleteMultiple = async (ids: string[]) => {
        //convert ids to numbers
        const idNumbers = ids.map((id) => parseInt(id));
        await agent.Notification.deleteMultipleNotifications(idNumbers)
            .then(() => {
                fetchData();
                setSelected([]);
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    const markMultipleAsRead = async (ids: string[]) => {
        //convert ids to numbers
        const idNumbers = ids.map((id) => parseInt(id));
        await agent.Notification.markMultipleAsRead(idNumbers)
            .then(() => {
                fetchData();
                setSelected([]);
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    return (
        <View>
            <ViewHeader title="Notifications" subtitle="View and manage notifications" />
            <ViewBody noPadding>
                <Content
                    noPadding
                    leftContent={
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%"
                            }}>
                            <Box
                                sx={{
                                    overflowY: "auto"
                                }}>
                                <List>
                                    {wsData &&
                                        categories
                                            .filter((category) => !category.disabled)
                                            .map((category) => (
                                                <ListItem key={category.category} disablePadding>
                                                    <ListItemButton
                                                        selected={activeCategory?.category === category.category}
                                                        onClick={() => {
                                                            setActiveCategory(category);
                                                            setSelected([]);
                                                        }}
                                                        disabled={category.disabled}>
                                                        <ListItemText>
                                                            {category.title}
                                                            {category.muted && (
                                                                <Tooltip title="Muted">
                                                                    <VolumeOff color="disabled" fontSize="small" sx={{ ml: 1, mb: -0.5 }} />
                                                                </Tooltip>
                                                            )}
                                                        </ListItemText>

                                                        {category.unreadCount > 0 && (
                                                            <Chip
                                                                size="small"
                                                                color="error"
                                                                label={category.unreadCount}
                                                                sx={{ minWidth: "24px" }}
                                                            />
                                                        )}
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}
                                </List>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    px: 2,
                                    py: 1
                                }}>
                                <Button
                                    variant="toolbar"
                                    fullWidth
                                    startIcon={<Delete />}
                                    onClick={() => {
                                        openDialog(
                                            <ConfirmationDialog
                                                title="Delete All Notifications"
                                                message="Are you sure you want to delete all notifications?"
                                                confirmButtonColor="error"
                                                confirmButtonText="Delete All"
                                                confirmationCheckbox
                                                confirmationCheckboxLabel="I understand this action cannot be undone"
                                                onConfirm={async () => {
                                                    await agent.Notification.deleteAllNotifications();
                                                }}
                                            />
                                        );
                                    }}>
                                    Delete All Notifications
                                </Button>
                            </Box>
                        </Box>
                    }
                    headerContent={
                        activeCategory && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    px: 2,
                                    py: 1
                                }}>
                                <Typography variant="h5">{activeCategory.title}</Typography>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        disabled={selected.length === 0}
                                        onClick={() => deleteMultiple(selected)}>
                                        Delete Selected
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={selected.length === 0}
                                        onClick={() => markMultipleAsRead(selected)}>
                                        Mark as Read
                                    </Button>
                                    <Divider orientation="vertical" flexItem />
                                    <Button
                                        variant="contained"
                                        color={activeCategory.muted ? "primary" : "error"}
                                        onClick={() => {
                                            updateMutedCategories(activeCategory.category, !activeCategory.muted);
                                            setActiveCategory({ ...activeCategory, muted: !activeCategory.muted });
                                        }}>
                                        {activeCategory.muted ? "Unmute" : "Mute"} Category
                                    </Button>
                                </Stack>
                            </Box>
                        )
                    }>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={
                                            selected.length > 0 &&
                                            selected.length ===
                                                Object.keys(wsData).filter((key) => {
                                                    return (wsData[key] as { category: string }).category === activeCategory.category;
                                                }).length
                                        }
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelected(
                                                    Object.keys(wsData).filter((key) => {
                                                        return (wsData[key] as { category: string }).category === activeCategory.category;
                                                    })
                                                );
                                            } else {
                                                setSelected([]);
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell width={180}>Created On</TableCell>
                                <TableCell width={24}></TableCell>
                                <TableCell>Notification</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activeCategory &&
                                Object.entries(wsData)
                                    .filter(([, value]) => {
                                        // only show items where category matches the active category
                                        return (value as { category: string }).category === activeCategory.category;
                                    })
                                    .sort(([, a], [, b]) => {
                                        const aDate = new Date((a as { created_on: Date }).created_on);
                                        const bDate = new Date((b as { created_on: Date }).created_on);
                                        return bDate.getTime() - aDate.getTime();
                                    })
                                    .map(([key, value]) => (
                                        <NotificationItem
                                            key={key}
                                            id={parseInt(key)}
                                            selected={selected.indexOf(key) !== -1}
                                            onCheckboxClick={(event) => {
                                                event.stopPropagation();
                                                if (selected.indexOf(key) !== -1) {
                                                    setSelected(selected.filter((a) => a !== key));
                                                } else {
                                                    setSelected([...selected, key]);
                                                }
                                            }}
                                            onChange={() => {
                                                fetchData();
                                            }}
                                        />
                                    ))}
                        </TableBody>
                    </Table>
                </Content>
            </ViewBody>
        </View>
    );
};

export default Notifications;
