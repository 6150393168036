import React, { useContext } from "react";
import { RootStoreContext } from "api";
import Button from "@mui/material/Button";
import { ReConfirmIPAddressDelete } from "./ReConfirmIPAddressDelete";
import { Alert, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";

interface IProps {
    name: string;
    id: number;
}

export const ConfirmDeleteIPAddress: React.FC<IProps> = ({ name, id }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog, changeDialogBody } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>Confirm Release IP Address</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography>Release IP: {name.toUpperCase()}</Typography>

                    <Alert severity="info" style={{ marginTop: 20 }}>
                        Releasing the IP will remove it perminantly, this cannot be undone and once released this IP address is not
                        recoverable.
                    </Alert>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        changeDialogBody(<ReConfirmIPAddressDelete name={name} id={id} />);
                    }}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};
