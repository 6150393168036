import {
    agent,
    IEnvironment,
    IEnvironmentsData,
    IEnvironmentUpgradeBody,
    ISchedulerFormValues,
    RootStoreContext,
    SwitchEnvironment
} from "api";
import { useState, useCallback, useContext } from "react";

export const useEnvironment = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeModal } = rootStore.modalStore;
    const {
        setLoadingInitial,
        setAllEnvironments,
        setProviders,
        setRegions,
        setCloudCode,
        setCodeChanged,
        setSavedIpAddresses,
        setSizeInfo,
        setSizes,
        setUpgradeEnv,
        setRegionCode,
        setEnvironmentStatus,
        setCurrentEnvCloudProvider,
        setCurrentEnvRunning,
        setEnvironmentUpgradeOptions,
        setEnvironmentUpgradeData,
        setSizeUpgradeInfo,
        setEnvironment,
        cloud_code,
        region_code,
        environmentUpgradeData,
        environment_status,
        setEnvironmentsList
    } = rootStore.environmentStore;
    const [isLoading, setIsLoading] = useState(false);

    const deleteEnvironment = useCallback(async (id: string, values: IEnvironment) => {
        try {
            await agent.Environment.deleteEnvironment(id, values);
            setLoadingInitial(false);
            closeModal();
        } catch (error) {
            throw error;
        }
    }, []);

    const getEnvironmentsDetails = useCallback(async () => {
        const environments = await agent.Environment.getEnvironmentsDetails();
        setAllEnvironments(environments.data);
    }, []);

    const getProviders = useCallback(async () => {
        try {
            const providers = await agent.Environment.getProviders();

            setLoadingInitial(true);
            setProviders(providers.providers);
            setLoadingInitial(false);

            return providers.providers;
        } catch (error) {
            throw error;
        }
    }, []);

    const getRegions = useCallback(async (code: string) => {
        try {
            setLoadingInitial(true);
            const regions = await agent.Environment.getRegions(code);
            setLoadingInitial(false);
            setRegions(regions.regions);
            return regions.regions;
        } catch (error) {
            setLoadingInitial(false);
            throw error;
        }
    }, []);

    const getUpdateRegions = useCallback(async (code: string) => {
        try {
            setLoadingInitial(true);
            const regions = await agent.Environment.getRegions(code);
            setCloudCode(code);
            setRegions(regions.regions);
            setLoadingInitial(false);
            return regions.regions;
        } catch (error) {
            setLoadingInitial(false);
            throw error;
        }
    }, []);

    const getUpdateSizes = useCallback(async (code: string) => {
        try {
            setLoadingInitial(true);
            const sizes = await agent.Environment.getSizes(cloud_code, code);
            setRegionCode(code);
            setCodeChanged(false);
            setSizes(sizes.sizes);
            setSizeInfo(sizes);
            setLoadingInitial(false);
            return sizes.sizes;
        } catch (error) {
            throw error;
        }
    }, []);

    const getSizes = useCallback(async () => {
        try {
            setLoadingInitial(true);
            const sizes = await agent.Environment.getSizes(cloud_code, region_code);
            setCodeChanged(false);
            setSizes(sizes.sizes);
            setSizeInfo(sizes);
            setLoadingInitial(false);
            return sizes.sizes;
        } catch (error) {
            throw error;
        }
    }, []);

    const getIpAddresses = useCallback(async () => {
        try {
            const IPAddresses = await agent.Environment.getIpAddresses();
            setSavedIpAddresses(IPAddresses.ip_addresses);
        } catch (error) {
            throw error;
        }
    }, []);

    const deleteIPAddress = useCallback(async (id: number) => {
        try {
            await agent.Environment.deleteIPAddresses(id);
        } catch (error) {
            throw error;
        }
    }, []);

    const getActiveEnvironment = useCallback(async (id: string) => {
        try {
            if (id !== "None") {
                const environment = await agent.Environment.getEnvironmentDetails(id);
                setEnvironment(environment.details);
                return environment.details;
            }
        } catch (error) {
            throw error;
        }
    }, []);

    // Should this be async?
    const setUpgradeEnvironmentData = useCallback(async (env: IEnvironmentsData) => {
        try {
            setUpgradeEnv(env);
        } catch (error) {
            throw error;
        }
    }, []);

    const upgradeEnvironment = useCallback(async (id: string, body: IEnvironmentUpgradeBody) => {
        try {
            const response = await agent.Environment.upgradeEnviroment(id, body);
            return response;
        } catch (error) {
            throw error;
        }
    }, []);

    const getEnvironmentStatus = useCallback(async (id: string) => {
        try {
            const env_status = await agent.Environment.getEnvironmentStatus(id);
            setEnvironmentStatus(env_status);
            return env_status;
        } catch (error) {
            throw error;
        }
    }, []);

    const changeActiveEnvironment = useCallback(async (environment: SwitchEnvironment) => {
        try {
            const newActiveEnv = await agent.Environment.switchActiveEnvironment(environment);
            if (newActiveEnv.active_environment_id) {
                rootStore.resClientStore.client.disconnect();
                rootStore.userStore.activeenvironment = newActiveEnv.active_environment_id;
                rootStore.commonStore.setToken(newActiveEnv.jwt_token);
                rootStore.resClientStore.initialize(newActiveEnv.jwt_token);
            }
        } catch (error) {
            throw error;
        }
    }, []);

    const getEnvironmentUpgradeSizes = useCallback(async (id: string) => {
        try {
            const envUpgradeTypes = await agent.Environment.getEnvironmentUpgradeOptions(
                environmentUpgradeData!.filter((env) => id === env.instance_id)[0].cloud_provider,
                environmentUpgradeData!.filter((env) => id === env.instance_id)[0].region
            );
            setSizeUpgradeInfo(envUpgradeTypes);
            return envUpgradeTypes.sizes;
        } catch (error) {
            throw error;
        }
    }, []);

    const getEnviromentUpgradeData = useCallback(async () => {
        try {
            const environmentData = await agent.Environment.getEnviromentUpgradeData();
            if (environmentData.data.length > 0) {
                const envUpgradeTypes = await agent.Environment.getEnvironmentUpgradeOptions(
                    environmentData.data[0]?.cloud_provider,
                    environmentData.data[0]?.region
                );
                setEnvironmentUpgradeData(environmentData.data);
                setEnvironmentUpgradeOptions(envUpgradeTypes.sizes);
            }
        } catch (error) {
            throw error;
        }
    }, []);

    const loadEnvironments = useCallback(async () => {
        try {
            const envs = await agent.Environment.getEnvironments();
            let envRunning: boolean | null = null;
            let currentEnvCloudProvider: string | null = null;

            envs.data.forEach((item) => {
                if (item.id === rootStore.userStore.activeenvironment) {
                    envRunning = item.status === "running";
                    currentEnvCloudProvider = item.cloud_provider;
                }
            });

            setCurrentEnvRunning(envRunning ? envRunning : false);
            setCurrentEnvCloudProvider(currentEnvCloudProvider ? currentEnvCloudProvider : "");
        } catch (error) {
            throw error;
        }
    }, []);

    const getStatus = useCallback(async (id: string) => {
        try {
            setLoadingInitial(false);
            const env_status = await agent.Environment.getEnvironmentStatus(id);
            setEnvironmentStatus(env_status);

            if (
                env_status.details.current_step < env_status.details.number_of_steps ||
                (env_status.details.number_of_steps === null && environment_status !== null)
            ) {
                setTimeout(() => {
                    getStatus(id);
                }, 7000);
            } else {
                setEnvironmentStatus(env_status);

                if (
                    env_status.details.current_step < env_status.details.number_of_steps ||
                    (env_status.details.number_of_steps === null && environment_status !== null)
                ) {
                    setTimeout(() => {
                        getStatus(id);
                    }, 7000);
                } else {
                    setEnvironmentStatus(env_status);

                    if (env_status.details.jwt_token) {
                        rootStore.commonStore.setToken(env_status.details.jwt_token);
                    }
                    changeActiveEnvironment({ environment_id: id });
                    setLoadingInitial(true);
                }
                changeActiveEnvironment({ environment_id: id });
                setLoadingInitial(true);
            }
        } catch (error) {
            setLoadingInitial(false);
            throw error;
        }
    }, []);

    const getEnvironmentList = useCallback(async () => {
        try {
            const envList = await agent.Environment.getEnvironmentsList();
            setEnvironmentsList(envList);
        } catch (error) {
            throw error;
        }
    }, []);

    return {
        isLoading,
        deleteEnvironment,
        getEnvironmentsDetails,
        getProviders,
        getRegions,
        getUpdateRegions,
        getUpdateSizes,
        getSizes,
        getIpAddresses,
        deleteIPAddress,
        getActiveEnvironment,
        setUpgradeEnvironmentData,
        upgradeEnvironment,
        getEnvironmentStatus,
        changeActiveEnvironment,
        getEnvironmentUpgradeSizes,
        getEnviromentUpgradeData,
        loadEnvironments,
        getStatus,
        getEnvironmentList
    };
};
