import { Verified } from "@mui/icons-material";
import { Avatar, Badge, Button, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { IUpdateUserRequest, RootStoreContext, agent } from "api";
import { TextField } from "components/Forms";
import Content from "components/Layout/Content";
import RoleProvider from "components/RoleProvider";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Form } from "react-final-form";

const UserProfile: React.FC = () => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { user, updateUserPreferences, getUser } = rootStore.userStore;
    function stringAvatar(name: string) {
        return {
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        };
    }

    return (
        <Content>
            <Form
                onSubmit={(values: IUpdateUserRequest) =>
                    agent.User.updateUser(values)
                        .then((response) => {
                            console.log(response);
                            getUser();
                        })
                        .catch((error) => {
                            console.log("error", error);

                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        })

                }
                render={({ submitError, handleSubmit, values, invalid, submitting, dirtyFieldsSinceLastSubmit, dirty }) => (
                    <form onSubmit={handleSubmit}>
                        <Typography variant="h5" gutterBottom>
                            Name
                        </Typography>
                        <Stack direction="row" spacing={2} mb={2}>
                            <TextField
                                name="first_name"
                                label="First Name"
                                initialValue={user?.first_name}
                                sx={{ width: 200 }}
                                required
                                validate={(value) => {
                                    if (!value) {
                                        return "First Name is required";
                                    }
                                }}
                            />
                            <TextField
                                name="last_name"
                                label="Last Name"
                                initialValue={user?.last_name}
                                sx={{ width: 200 }}
                                required
                                validate={(value) => {
                                    if (!value) {
                                        return "Last Name is required";
                                    }
                                }}
                            />
                        </Stack>
                        <Button variant="contained" type="submit" disabled={(invalid && !dirtyFieldsSinceLastSubmit) || submitting || !dirty}>
                            Save Changes
                        </Button>

                        <Typography variant="h5" gutterBottom mt={4}>
                            Avatar
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "1" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "1" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/1.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "2" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "2" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/2.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "3" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "3" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/3.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "4" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "4" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/4.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "5" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "5" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/5.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "6" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "6" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/6.png"
                                />
                            </IconButton>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "7" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "7" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/7.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "8" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "8" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/8.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "9" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border: user?.user_preferences?.avatar === "9" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/9.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "10" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border:
                                            user?.user_preferences?.avatar === "10" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/10.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "11" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border:
                                            user?.user_preferences?.avatar === "11" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/11.png"
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    updateUserPreferences({ avatar: "12" });
                                }}>
                                <Avatar
                                    sx={{
                                        width: 60,
                                        height: 60,
                                        border:
                                            user?.user_preferences?.avatar === "12" ? "3px solid " + theme.palette.action.active : "none"
                                    }}
                                    src="../../assets/img/avatar/12.png"
                                />
                            </IconButton>
                        </Stack>
                    </form>
                )}
            />
        </Content>
    );
};

export default observer(UserProfile);
