import React from "react";
import { Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { agent, IHourlyBreakDownResponse, ServiceConsumptionResponseV2 } from "api";
import { startOfMonth, format } from "date-fns";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HourlyBreakdownRow from "./HourlyBreakdownRow";

interface Props {
    organisation_id: number;
}

/**
 * OrganisationBilling component displays the billing summary for an organisation.
 * The component uses a date range picker to allow the user to choose a period for the summary.
 *
 * @component
 * @param {Props} props - Props object.
 * @param {number} props.organisation_id - ID of the organisation to display the billing summary for.
 * @returns {JSX.Element} - Rendered component.
 *
 * @example
 * <OrganisationBilling organisation_id={123} />
 */

const OrganisationBilling: React.FC<Props> = ({ organisation_id }) => {
    // State for date range and service consumption
    const [dateRange, setDateRange] = React.useState<any>([startOfMonth(new Date()), new Date()]);
    const [serviceConsumption, setServiceConsumption] = React.useState<ServiceConsumptionResponseV2>();
    const [hourlyBreakdown, setHourlyBreakdown] = React.useState<IHourlyBreakDownResponse>();
    const [hourlyPage, setHourlyPage] = React.useState(0);
    const [hourlyRowsPerPage, setHourlyRowsPerPage] = React.useState(12);

    // Fetch service consumption data when component mounts or date range changes
    React.useEffect(() => {
        const getBilling = async () => {
            await agent.Billing.organisationServiceConsumption(organisation_id, {
                start_date: format(new Date(dateRange[0]!), "yyyy-MM-dd"),
                end_date: format(new Date(dateRange[1]!), "yyyy-MM-dd")
            }).then((response) => {
                setServiceConsumption(response);
                console.log(response);
            });
        };

        const getHourlyBreakdown = async () => {
            await agent.Billing.organisationServiceConsumptionHourlyBreakdown(organisation_id, {
                start_date: format(new Date(dateRange[0]!), "yyyy-MM-dd"),
                end_date: format(new Date(dateRange[1]!), "yyyy-MM-dd"),
                page_nr: hourlyPage + 1,
                items_per_page: hourlyRowsPerPage
            }).then((response) => {
                setHourlyBreakdown(response);
                console.log(response);
            });
        };

        getBilling();
        getHourlyBreakdown();
    }, [dateRange, hourlyPage, hourlyRowsPerPage, organisation_id]);

    return (
        <Box>
            {/* Date range picker */}
            <Card sx={{ backgroundColor: "rgba(160,160,160,0.05)", mb: 2 }}>
                <Box sx={{ maxWidth: 500, padding: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: "Start Date", end: "End Date" }}>
                        <DateRangePicker
                            value={dateRange}
                            onChange={(newValue) => {
                                setDateRange(newValue);
                            }}
                            format="dd/MM/yyyy"
                            calendars={1}
                            disableFuture
                            slotProps={{
                                textField: { variant: "outlined", size: "small" }
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            </Card>

            {/* Billing summary */}
            {serviceConsumption && (
                <Box>
                    <Typography variant="h5" gutterBottom>
                        Summary
                    </Typography>
                    <Card sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                        <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid
                                container
                                columns={10}
                                sx={{
                                    alignItems: "center"
                                }}>
                                <Grid
                                    container
                                    columns={10}
                                    xs={8}
                                    spacing={1}
                                    sx={{
                                        alignItems: "center"
                                    }}>
                                    {/* Agreements */}
                                    {/* <Box sx={{ marginLeft: 0, marginRight: "auto" }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Date
                                    </Typography>
                                    <Typography variant="h6" component="div">
                                        {format(new Date(dateRange[0]!), "dd/MM/yyyy")} to {format(new Date(dateRange[1]!), "dd/MM/yyyy")}
                                    </Typography>
                                </Box> */}
                                    <Grid item xs={2}>
                                        {/* Agreements */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Agreements
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_agreements_cost}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                        {/* Environments */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Environments
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_env_cost}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                        {/* Transcoders */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Transcoders
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_transcoder_cost_value}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                        {/* Multiviewers */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Multiviewers
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_mv_cost}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                        {/* Egress */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Egress
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_egress_cost}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                        {/* Push Packager Egress */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Push Packager Egress
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_push_packager_egress_cost}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {/* IP Addresses */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                IP Addresses
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_detached_ip_address_cost_value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {/* File Transcoding */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                File Transcoding
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_tf_cost_value}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2}>
                                        {/* Push Packagers */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Push Packagers
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_push_packager_cost_value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {/* Pull Packagers */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Pull Packagers
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_pull_packager_cost_value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {/* NEA Live */}
                                        <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                NEA Live
                                            </Typography>
                                            <Typography variant="h6" component="div">
                                                {serviceConsumption.customer_currency_symbol}
                                                {serviceConsumption.total_nea_live_cost_value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    {/* Total */}
                                    <Box sx={{ marginLeft: 2, paddingLeft: 2, borderLeft: "1px solid rgba(160,160,160,0.2)" }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Total
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            {serviceConsumption.customer_currency_symbol}
                                            {serviceConsumption.grand_total_cost}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Cloud Provider Breakdown
                        </Typography>
                        {serviceConsumption.cloud_provider_breakdown.cloud_providers.map((cloudProvider, i) => (
                            <Accordion key={i} sx={{ mb: 2 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", pr: 2 }}>
                                        <Box>{cloudProvider.cloud_provider_name}</Box>
                                        <Box>{cloudProvider.total_cloud_cost}</Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Permanent Agreements</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.permanent_agreements.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Environments</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.environments.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Transcoders</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.transcoders.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Multiviewers</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.multi_viewers.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Egress</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mb</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={i}>
                                                <TableCell>{cloudProvider.egress.mb}</TableCell>
                                                <TableCell align="right">{cloudProvider.egress.cost}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Push Packager Egress</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mb</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={i}>
                                                <TableCell>{cloudProvider.push_packager_egress.mb}</TableCell>
                                                <TableCell align="right">{cloudProvider.push_packager_egress.cost}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">IP Addresses</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.detached_ip_addresses.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">File Transcoding</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.titan_file.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Push Packagers</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.push_packagers.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">Pull Packagers</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.pull_packagers.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "rgba(160,160,160,0.2)" }}>
                                                    <Typography variant="h6">NEA Live</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider.nea_live?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">{env.cost}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Hourly Breakdown
                        </Typography>
                        <Card sx={{ backgroundColor: "rgba(160,160,160,0.2)", mb: 2 }}>
                            <CardContent>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Hour</TableCell>
                                            <TableCell align="right">Cost</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {hourlyBreakdown?.hours.map((hour, i) => (
                                            <HourlyBreakdownRow key={i} hour={hour} />
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[12, 24, 48, 72]}
                                    component="div"
                                    count={hourlyBreakdown?.number_of_items_on_page! * hourlyBreakdown?.pages! || 0}
                                    rowsPerPage={hourlyBreakdown?.number_of_items_on_page || 12}
                                    page={hourlyBreakdown?.current_page! - 1 || 0}
                                    onPageChange={(e, page) => {
                                        setHourlyPage(page);
                                    }}
                                    onRowsPerPageChange={(e) => {
                                        setHourlyRowsPerPage(parseInt(e.target.value, 10));
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default OrganisationBilling;
