import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Alert, Box, FormControlLabel, Button as MuiButton, Stack, Switch } from "@mui/material";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import Content from "components/Layout/Content";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { observer } from "mobx-react-lite";
import DestinationsV2 from "./Components/Destinations";
import SearchInput from "components/SearchInput";
import { useNavigate } from "react-router-dom";
import CreateOutputForm from "./destinationCreation/CreateOutputForm";

const Destinations: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { environment } = rootStore.environmentStore;
    const { activeEnvironment } = rootStore.userStore;
    const navigate = useNavigate();
    const { openDrawer } = rootStore.drawerStore;

    const isGuest = rootStore.userStore.activeorganisationId !== rootStore.userStore.managingOrganisationId;

    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [showGuestDestinations, setShowGuestDestinations] = React.useState<boolean>(false);

    return (
        <View>
            <ViewHeader title="Destinations" subtitle={environment?.name} />
            <ViewBody noPadding>
                <Content
                    noPadding
                    overflowX="auto"
                    toolbarContent={
                        <Box
                            sx={{
                                //p: 2,
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <Stack direction="row" spacing={1}>
                                <SearchInput
                                    name="Search"
                                    label="Search Destinations"
                                    value={searchTerm}
                                    onChange={(value) => setSearchTerm(value)}
                                    onClear={() => setSearchTerm("")}
                                    marginBottom="0px"
                                />
                                {!isGuest && (
                                <FormControlLabel control={<Switch
                                    checked={showGuestDestinations}
                                    onChange={() => setShowGuestDestinations(!showGuestDestinations)}
                                    name="showGuestDestinations"
                                    color="primary"
                                />} label="Show Guest Destinations" />
                                )}

                            </Stack>
                            <MuiButton
                                variant="contained"
                                onClick={() => {
                                    navigate('/destinations/create')
                                }}
                                disabled={!activeEnvironment || activeEnvironment === "None"}
                                startIcon={<AddCircleIcon />}>
                                Add Destination
                            </MuiButton>
                        </Box>
                    }>
                    {activeEnvironment && <DestinationsV2 searchTerm={searchTerm} showGuestDestinations={showGuestDestinations} />}
                    {!activeEnvironment && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <Alert severity="info" sx={{ width: 300 }}>Select an environment to view destinations</Alert>
                        </Box>
                    )}
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(Destinations);
