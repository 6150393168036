import React, { useEffect, useRef } from "react";
import {
    Box,
    Stack,
    Typography,
    IconButton,
    Tooltip,
    Button,
    Alert,
    AlertTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon
} from "@mui/material";
import { format } from "date-fns";
import { HomeOutlined, KeyboardBackspace } from "@mui/icons-material";
import { KnowledgeHubSection } from "../KnowledgeHubSection";
import useRoleProvider from "hooks/UseRoleProvider";
import useClientProvider from "hooks/UseClientProvider";
import {
    IKnowledgeHubArticleShort,
    RootStoreContext,
    IKnowledgeHubHistory,
    IKnowledgeSection,
    KnowledgeHubSelectorType,
    KnowledgeHubItemStatus,
    KnowledgeHubType
} from "api";
import { useKnowledgeHub } from "hooks";
import RoleProvider from "components/RoleProvider";
import InfoItemVertical from "components/InfoItemVertical";
import KnowledgeHubItemActions from "../KnowledgeHubItemActions";

export interface IKnowledgeHubItem {
    id: string;
    isSidebar: boolean;
    showBack?: boolean;
    history: IKnowledgeHubHistory[];
    setHistory: React.Dispatch<React.SetStateAction<IKnowledgeHubHistory[]>>;
    goBackHome: () => void;
    goBack: () => void;
    goToSelector: (id: string, type: KnowledgeHubSelectorType) => void;
    hideTitle?: boolean;
    editItem: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, type: KnowledgeHubType) => void;
    deleteItem: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string, isCategory?: boolean) => void;
}

/**
 * `KnowledgeHubItem` is a React functional component that displays detailed information about a specific
 * item within the Knowledge Hub. It fetches and presents data such as the item's title, description, image,
 * video, sections, and tags. The component also handles navigation within the Knowledge Hub, allowing users
 * to go back to the homepage or the previous page, and navigate to specific sections or items.
 *
 * Permissions and client-specific data visibility are managed using `useRoleProvider` and `useClientProvider`
 * hooks, ensuring that users can only access content they are authorized to view.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.id - The unique identifier of the Knowledge Hub item to display.
 * @param {boolean} props.isSidebar - Indicates if the component is being rendered in a sidebar context.
 * @param {boolean} [props.showBack=true] - Controls the visibility of the back navigation button.
 * @param {IKnowledgeHubHistory[]} props.history - An array of history objects for breadcrumb-like navigation.
 * @param {React.Dispatch<React.SetStateAction<IKnowledgeHubHistory[]>>} props.setHistory - Function to update the navigation history.
 * @param {Function} props.goBackHome - Callback function to navigate back to the Knowledge Hub home.
 * @param {Function} props.goBack - Callback function to navigate to the previous page.
 * @param {Function} props.goToSelector - Callback function to navigate to a specific item or section.
 * @param {boolean} [props.hideTitle=false] - Controls the visibility of the header section.
 * @param {Function} props.editItem - Callback function for editing the current item.
 * @param {Function} props.deleteItem - Callback function for deleting the current item.
 * @returns {React.ReactElement} A React element representing the detailed view of a Knowledge Hub item.
 *
 * @example
 * ```tsx
 * <KnowledgeHubItem
 *   id="123"
 *   isSidebar={false}
 *   showBack={true}
 *   history={history}
 *   setHistory={setHistory}
 *   goToSelector={goToSelector}
 *   goBackHome={() => console.log("Go back home")}
 *   goBack={() => console.log("Go back")}
 * />
 * ```
 */
export const KnowledgeHubItem: React.FC<IKnowledgeHubItem> = ({
    id,
    isSidebar,
    showBack = true,
    history,
    setHistory,
    goBackHome,
    goBack,
    goToSelector,
    hideTitle = false,
    editItem,
    deleteItem
}) => {
    const rootStore = React.useContext(RootStoreContext);
    const { items } = rootStore.knowledgeHubStore;
    const [item, setItem] = React.useState<any>(null);
    const canViewItem = useRoleProvider(item?.roles ?? [], true);
    const isCorrectClient = useClientProvider(item?.client ?? "");
    const [isLoading, setIsLoading] = React.useState(false);
    const { getItem } = useKnowledgeHub();

    const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

    const getData = async () => {
        try {
            await getItem(id, (data) => {
                console.log("getData", data);
            });
        } catch (error) {
            console.error("getData:", error);
        }
    };

    useEffect(() => {
        if (!items.length) {
            setIsLoading(true);
            getData();
            return;
        }

        let selectedArticle = items.find((article: IKnowledgeHubArticleShort) => article.id === id);

        if (!selectedArticle) {
            setIsLoading(true);
            getData();
            return;
        }

        getData();
    }, []);

    useEffect(() => {
        let selectedArticle = items.find((article: IKnowledgeHubArticleShort) => article.id === id);
        setItem(selectedArticle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const scrollToSection = (index: number) => {
        sectionRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
    };

    if (isLoading || !item) {
        return <></>;
    }

    if (!isLoading && !isCorrectClient) {
        return (
            <Box>
                <Typography variant="h3">Item not found</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Please return to the previous knowledge base homepage
                </Typography>
                <Button variant="contained" color="primary" onClick={goBackHome}>
                    Go back
                </Button>
            </Box>
        );
    }

    if (!canViewItem) {
        return (
            <Box>
                <Typography variant="h3">Permission Denied</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    You do not have permission to view this item
                </Typography>
                <Button variant="contained" color="primary" onClick={goBackHome}>
                    Go back
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%", padding: showBack ? 0 : 3, paddingRight: showBack ? 2 : 0 }}>
            <Stack spacing={4} sx={{ width: "100%", paddingBottom: 2 }}>
                {history.length > 1 && showBack && (
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Stack spacing={1} direction="row">
                            {isSidebar && (
                                <Tooltip title="Go back to knowledge hub home">
                                    <IconButton onClick={goBackHome}>
                                        <HomeOutlined />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Go back">
                                <IconButton onClick={goBack}>
                                    <KeyboardBackspace />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                )}

                {(!hideTitle || item?.description) && (
                    <Box>
                        {!hideTitle && <Typography variant="h4">{item.title}</Typography>}
                        {item?.description && <Typography variant="body2">{item?.description}</Typography>}
                    </Box>
                )}

                {item.status && item.status === KnowledgeHubItemStatus.Draft && (
                    <Alert severity="error" sx={{ mb: 2 }} title="ere">
                        <AlertTitle>This article is a draft</AlertTitle>
                        To publish this article, please edit the article and change the status to "Published".
                    </Alert>
                )}

                {item?.includeTOC && (
                    <div>
                        <List dense>
                            {item.sections
                                ?.filter((section: IKnowledgeSection) => section.title !== undefined)
                                .map((section: IKnowledgeSection, index: number) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton onClick={() => scrollToSection(index)}>
                                            <ListItemIcon sx={{ minWidth: 20 }}>
                                                {item.isNumbered ? `${index + 1}.` : <>&bull;</>}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={section.title}
                                                sx={{
                                                    color: (theme) => theme.palette.primary.main
                                                }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                        </List>
                    </div>
                )}

                {item.image && <img src={item.image} alt={item.title} style={{ maxWidth: "100%" }} />}

                {item.video && (
                    <video src={item.video} controls style={{ width: "100%" }}>
                        Your browser does not support the video tag.
                    </video>
                )}

                <Stack spacing={4} sx={{ flex: 1 }}>
                    {item.sections?.map((section: IKnowledgeSection, index: number) => (
                        <div key={index} ref={(el) => (sectionRefs.current[index] = el)}>
                            <KnowledgeHubSection
                                section={section}
                                history={history}
                                setHistory={setHistory}
                                number={item.isNumbered ? index + 1 : undefined}
                                goToSelector={goToSelector}
                            />
                        </div>
                    ))}

                    {!!item.tags.length && <Box>Tags: {item.tags.join(", ")}</Box>}
                </Stack>

                {item?.dateUpdated && (
                    <Typography variant="body2" color="text.secondary">
                        Last Updated: {format(new Date(item?.dateUpdated), "dd MMMM yyyy")}
                    </Typography>
                )}

                <RoleProvider roles={["is_dev"]}>
                    <Box
                        sx={{
                            p: 2,
                            backgroundColor: (theme) => theme.palette.action.hover,
                            borderRadius: 1
                        }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Dev Info
                        </Typography>
                        <Stack spacing={1}>
                            <InfoItemVertical title="ID" value={item.id} value_type="copy" />
                            <InfoItemVertical
                                title="Date Created"
                                value={item.dateCreated ? format(new Date(item.dateCreated), "dd/MM/yyyy HH:mm:ss") : "N/A"}
                            />
                            <InfoItemVertical
                                title="Date Updated"
                                value={item.dateUpdated ? format(new Date(item.dateUpdated), "dd/MM/yyyy HH:mm:ss") : "N/A"}
                            />
                            <InfoItemVertical title="Status" value={item.status} />
                            <InfoItemVertical title="Is Indexed" value={item.isIndexable ? "Yes" : "No"} />
                            {item.lastModifiedBy && <InfoItemVertical title="Last Modified By" value={item.lastModifiedBy} />}
                            <KnowledgeHubItemActions item={item} editItem={editItem} editText="Edit Article" />
                        </Stack>
                    </Box>
                </RoleProvider>
            </Stack>
        </Box>
    );
};

export default KnowledgeHubItem;
