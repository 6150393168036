import { ArrowDropDown, Delete, VolumeOff, VolumeUp } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { agent, RootStoreContext } from "api";
import ConfirmationDialog from "components/ConfirmationDialog";
import React, { useContext, useRef } from "react";

interface SelectedItemsMenuProps {
    selectedItems: string[];
    onAction: () => void;
}

const SelectedItemsMenu: React.FC<SelectedItemsMenuProps> = ({ selectedItems, onAction }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const unMuteUponRecovery = useRef(false);
    const setUnMuteUponRecovery = (newValue: boolean) => {
        unMuteUponRecovery.current = newValue;
    };

    const UnMuteOnRecoveryCheckbox: React.FC = () => {
        return (
            <Box mt={2}>
                <FormControlLabel
                    control={<Checkbox onChange={(e) => setUnMuteUponRecovery(e.target.checked)} />}
                    label={
                        <Box pl={1}>
                            <Typography>Unmute upon recovery</Typography>
                            <Typography variant="body2" color="textSecondary">
                                Unmute this alert when it recovers from the alerting state.
                            </Typography>
                        </Box>
                    }
                />
            </Box>
        );
    };

    return (
        <>
            <Button
                disabled={!selectedItems || selectedItems.length === 0}
                variant="contained"
                color="primary"
                onClick={handleClick}
                endIcon={<ArrowDropDown />}>
                {`Selected Alerts (${selectedItems.length})`}
            </Button>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}>
                <MenuItem
                    // onClick={() => {
                    //     agent.Alerts.muteMultipleAlerts(selectedItems, false).then(() => {
                    //         onAction();
                    //         handleClose();
                    //     });
                    // }}
                    onClick={() => {
                        handleClose();
                        openDialog(
                            <ConfirmationDialog
                                title={"Mute Alerts"}
                                message={"Are you sure you want to mute these alerts? Muted alerts will not trigger any notifications."}
                                additionalContent={<UnMuteOnRecoveryCheckbox />}
                                onConfirm={async () => {
                                    await agent.Alerts.muteMultipleAlerts(selectedItems, unMuteUponRecovery.current).then(() => {
                                        console.log("unMuteUponRecovery", unMuteUponRecovery.current);
                                        setUnMuteUponRecovery(false);
                                        onAction();
                                    });
                                }}
                            />
                        );
                    }}>
                    <ListItemIcon>
                        <VolumeOff />
                    </ListItemIcon>
                    Mute Selected Alerts
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        agent.Alerts.unMuteMultipleAlerts(selectedItems).then(() => {
                            onAction();
                            handleClose();
                        });
                    }}>
                    <ListItemIcon>
                        <VolumeUp />
                    </ListItemIcon>
                    Unmute Selected Alerts
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        openDialog(
                            <ConfirmationDialog
                                title="Delete Selected Alerts"
                                message="Are you sure you want to delete the selected alerts?"
                                onConfirm={async () => {
                                    await agent.Alerts.deleteMultipleAlerts(selectedItems).then(() => {
                                        onAction();
                                    });
                                }}
                            />
                        );
                    }}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    Delete Selected Alerts
                </MenuItem>
            </Menu>
        </>
    );
};

export default SelectedItemsMenu;
