import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React from "react";

interface Props {
    id: string;
}

const ConfirmStopDialog: React.FC<Props> = (props) => {
    const rootStore  = React.useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [error, setError] = React.useState<any>();
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const ConfirmSuccessDialog = ({ message }) => {
        return (
            <>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="text.secondary">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeDialog()}>Close</Button>
                </DialogActions>
            </>
        );
    };

    return (
        <>
            <DialogTitle>Confirm Stop</DialogTitle>
            <DialogContent>
                <Typography variant="body1" color="text.secondary">
                    Are you sure you want to stop this pull packager?
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error.data?.message}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog()}>Cancel</Button>
                <LoadingButton
                    variant="contained"
                    color="error"
                    loading={submitting}
                    onClick={() => {
                        setSubmitting(true);
                        agent.NeaLive.stopNeaLive(props.id)
                            .then(() => {
                                setSubmitting(false);
                                openDialog(<ConfirmSuccessDialog message="Packager is now stopping. This may take a few minutes." />);
                            })
                            .catch((err) => {
                                setSubmitting(false);
                                console.log(err);
                                setError(err);
                            });
                    }}>
                    Stop
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default ConfirmStopDialog;