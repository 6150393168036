import React, { useContext } from "react";
import {
    Box,
    Card,
    Checkbox,
    Fade,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import AlertsFormEdit from "features/alertsManagement/Components/AlertsFormEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import CreateIcon from "@mui/icons-material/Create";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { agent, IChannelAlertItemDetails, IDestinationAlertItemDetails, RootStoreContext } from "api";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ConfirmationDialog from "components/ConfirmationDialog";

interface IAlertTableProps {
    item: IDestinationAlertItemDetails | IChannelAlertItemDetails;
    envRunning: boolean;
    envRunningColor: "#ffffff" | "#606060";
    alertsSelected: string[];
    setAlertsSelected: React.Dispatch<React.SetStateAction<string[]>>;
    loadAlerts: () => void;
    unMuteUponRecovery: React.MutableRefObject<boolean>;
}

export const AlertTable = ({
    item,
    envRunning,
    envRunningColor,
    alertsSelected,
    setAlertsSelected,
    loadAlerts,
    unMuteUponRecovery
}: IAlertTableProps) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const setUnMuteUponRecovery = (newValue: boolean) => {
        unMuteUponRecovery.current = newValue;
    };

    const handleTickAlert = (alert_id: string) => {
        // shallow clone of the array. Super important. Pointing to the same variable does not create a new reference
        let newState = [...alertsSelected];
        const item_index = newState.indexOf(alert_id);
        if (item_index > -1) {
            newState.splice(item_index, 1);
        } else {
            newState.push(alert_id);
        }
        setAlertsSelected(newState);
    };
    const muteAlert = (alert_id: string, alert_name: string, is_alerting: boolean) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Mute Alert"
                message="Are you sure you want to mute this alert?"
                onConfirm={() => {
                    return agent.getCleanInstance().put(`/environments/alerts/${alert_id}/state`, {
                        action: "deactivate",
                        unmute_upon_recovery: is_alerting ? unMuteUponRecovery.current : false
                    });
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        );
    };

    const unMuteAlert = (alert_id: string, alert_name: string) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Un-Mute Alert"
                message="Are you sure you want to un-mute this alert?"
                onConfirm={() => {
                    return agent.getCleanInstance().put(`/environments/alerts/${alert_id}/state`, { action: "activate" });
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        )
    };

    const deleteSingleAlert = (alert_id: string, alert_name: string) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Delete Alert"
                message="Are you sure you want to delete this alert?"
                onConfirm={() => {
                    return agent.getCleanInstance().delete(`/environments/alerts/${alert_id}`);
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        )
    };
    return (
        <Card>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="4%">
                                <SubdirectoryArrowRightIcon
                                    style={{
                                        cursor: "default",
                                        color: "#7c7c7c"
                                    }}
                                />
                            </TableCell>
                            <TableCell width="18%" style={{ color: envRunningColor }}>
                                <Typography color={"text.primary"}>Name</Typography>
                            </TableCell>
                            <TableCell width="30%" style={{ color: envRunningColor }}>
                                <Typography color={"text.primary"}>Alert condition</Typography>
                            </TableCell>
                            <TableCell width="22%" style={{ color: envRunningColor }}>
                                <Typography color={"text.primary"}>Notification method</Typography>
                            </TableCell>
                            <TableCell width="5%" style={{ color: envRunningColor }}>
                                <Typography color={"text.primary"}>Status</Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.alerts?.map((alertRow) => {
                            return (
                                <TableRow key={alertRow.id} selected={alertsSelected.indexOf(alertRow.id) > -1}>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>
                                        {envRunning && (
                                            <Checkbox
                                                checked={alertsSelected.indexOf(alertRow.id) > -1}
                                                onClick={() => {
                                                    handleTickAlert(alertRow.id);
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell style={{ color: envRunningColor }}>
                                        <Typography color={"text.primary"}>{alertRow.alert_name}</Typography>
                                    </TableCell>
                                    <TableCell style={{ color: envRunningColor }}>
                                        <Typography color={"text.primary"}>{alertRow.condition_string}</Typography>
                                    </TableCell>
                                    <TableCell style={{ color: envRunningColor }}>
                                        <Typography color={"text.primary"}>{alertRow.alert_method_name}</Typography>
                                    </TableCell>
                                    <TableCell style={{ color: envRunningColor }}>
                                        {envRunning ? (
                                            alertRow.is_alerting && alertRow.status === "active" ? (
                                                <Box
                                                    sx={{
                                                        backgroundColor: "red",
                                                        borderRadius: 4,
                                                        width: "2rem",
                                                        height: "2rem",
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        justifyContent: "center",
                                                        alignContent: "center"
                                                    }}>
                                                    <NotificationsIcon />
                                                </Box>
                                            ) : (
                                                <Typography
                                                    color={"text.primary"}
                                                    sx={{ opacity: alertRow.status === "inactive" ? 0.5 : 1 }}>
                                                    {alertRow.status === "inactive" ? "muted" : alertRow.status}
                                                </Typography>
                                            )
                                        ) : (
                                            <>stopped</>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {envRunning && (
                                            <Stack
                                                direction={"row"}
                                                sx={{
                                                    justifyContent: "end",
                                                    mr: 1
                                                }}>
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600
                                                    }}
                                                    placement="bottom"
                                                    arrow
                                                    title="Edit"
                                                    sx={{ mr: 1 }}>
                                                    <Box>
                                                        <IconButton
                                                            onClick={() =>
                                                                openDialog(
                                                                    <AlertsFormEdit
                                                                        source={alertRow}
                                                                        successCallback={() => {
                                                                            loadAlerts();
                                                                        }}
                                                                    />,
                                                                    "Edit Alert",
                                                                )
                                                            }
                                                            aria-label="Edit">
                                                            <CreateIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600
                                                    }}
                                                    placement="bottom"
                                                    arrow
                                                    sx={{ mr: 1 }}
                                                    title={alertRow.status === "active" ? "Mute" : "Unmute"}>
                                                    <Box>
                                                        <IconButton
                                                            onClick={() => {
                                                                if (alertRow.status === "active") {
                                                                    muteAlert(alertRow.id, alertRow.alert_name, alertRow.is_alerting);
                                                                } else {
                                                                    unMuteAlert(alertRow.id, alertRow.alert_name);
                                                                }
                                                            }}
                                                            aria-label={alertRow.status === "active" ? "Mute" : "Unmute"}>
                                                            {alertRow.status === "active" ? <VolumeOffIcon /> : <VolumeUpIcon />}
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{
                                                        timeout: 600
                                                    }}
                                                    placement="bottom"
                                                    arrow
                                                    sx={{ mr: 1 }}
                                                    title="Delete">
                                                    <Box>
                                                        <IconButton
                                                            onClick={() => deleteSingleAlert(alertRow.id, alertRow.alert_name)}
                                                            aria-label="Delete">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                            </Stack>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};
