import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    ButtonBase,
    Card,
    CardContent,
    CardHeader,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {
    agent,
    IKnowledgeSection,
    KnowledgeHubItemStatus,
    KnowledgeHubSelectorType,
    KnowledgeHubType,
    RootStoreContext,
    UserModules
} from "api";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatLabel, getDiffFromArrays } from "../Components";
import { FORM_ERROR } from "final-form";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import {
    AddCircle,
    ArrowDownward,
    ArrowUpward,
    Code,
    ContentCopy,
    ContentCut,
    ContentPaste,
    DataObject,
    Delete,
    ExpandMore,
    Image,
    OndemandVideo,
    Preview,
    Redo,
    Save,
    Settings,
    Toc,
    Undo as UndoIcon,
    Visibility
} from "@mui/icons-material";
import {
    AutocompleteField,
    CheckboxField,
    MultiAutocompleteField,
    MultilineTextField,
    MultiSelectField,
    RadioGroupField,
    SelectField,
    TextField,
    WysiwygField
} from "components/Forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { NodeHeader } from "components/Nodes";
import SidebarTabs, { SidebarTab, SidebarTabPanel } from "components/SidebarTabs";
import KnowledgeHub from "..";
import { toast } from "react-toastify";
import IconSelector from "./Components/IconSelector";
import { Editor } from "@monaco-editor/react";
import { useKnowledgeHub } from "hooks";
import { Undo } from "components/FinalFormUndo";
import LivePreview from "./Components/LivePreview";
import { observer } from "mobx-react-lite";
import MediaGallery from "components/MediaGallery";
import OnChange from "components/FinalFormListener/OnChange";

interface KnowledgeHubEditorProps {
    mode?: "create" | "edit";
}

const KnowledgeHubEditor: React.FC<KnowledgeHubEditorProps> = ({ mode = "create" }) => {
    const rootStore = useContext(RootStoreContext);
    const { categories, archivedCategories, items, tags } = rootStore.knowledgeHubStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    let location = useLocation();
    const { user } = rootStore.userStore;
    let { returnUrl } = location.state || { returnUrl: "/knowledge-hub" };
    const { id } = useParams();
    const [initialValues, setInitialValues] = useState({
        roles: [],
        tags: [],
        sections: [] as { name: undefined; content: undefined }[],
        dateCreated: new Date().toISOString(),
        dateUpdated: new Date().toISOString(),
        status: KnowledgeHubItemStatus.Draft,
        isIndexable: true,
        includeTOC: false,
        lastModifiedBy: user?.email
    });
    const { createItem, updateItem, addItem } = useKnowledgeHub();

    const getInitialValues = async () => {
        if (mode !== "edit" || !id) {
            return;
        }

        await agent.KnowledgeHub.getArticle(id)
            .then((data) => {
                addItem(data);
                setInitialValues({
                    ...initialValues,
                    ...data,
                    tags: data.tags ?? [],
                    roles: data.roles ?? [],
                    dateUpdated: new Date().toISOString()
                });
            })
            .catch((error) => {
                console.log("knowledge hub get error:", error);
            });
    };

    // Fetch initial data
    useEffect(() => {
        getInitialValues();
    }, [id]);

    const isCategoryArchived = (categoryId: string) => {
        return archivedCategories.some((category) => category.id === categoryId);
    };

    return (
        <Form
            onSubmit={async (values, form) => {
                const newValues = {
                    ...values,
                    id:
                        mode === "edit"
                            ? values.id
                            : `kb_${values.type === KnowledgeHubType.Category ? "cat" : "item"}-${new Date().getTime()}`,
                    roles: Array.isArray(values?.roles) && values?.roles.includes("all") ? [] : values.roles,
                    category: values.type === KnowledgeHubType.Article ? values.category : "kb_category",
                    lastModifiedBy: user?.email
                };

                if (mode === "edit") {
                    try {
                        await updateItem(newValues, (data) => {
                            console.log("data", data);
                            toast.success("Article updated successfully!");
                            form.reset(values);
                        });
                    } catch (error) {
                        console.log("knowledge hub update error:", error);
                        // @ts-ignore
                        const errors = error.data.errors;
                        toast.error("An error occurred while updating the article.");
                        return { [FORM_ERROR]: error, ...errors };
                    }
                } else {
                    try {
                        await createItem(newValues, (data) => {
                            console.log("data", data);
                            navigate("/knowledge-hub/editV2/" + newValues.id);
                            toast.success("Article created successfully!");
                        });
                    } catch (error) {
                        console.log("knowledge hub create error:", error);
                        // @ts-ignore
                        const errors = error.data.errors;
                        toast.error("An error occurred while creating the article.");
                        return { [FORM_ERROR]: error, ...errors };
                    }
                }
            }}
            initialValues={initialValues}
            mutators={{
                ...arrayMutators,
                setSubmitErrors: (args, state, utils) => {
                    utils.changeValue(state, "submitErrors", () => args[0]);
                }
            }}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors,
                dirty,
                dirtySinceLastSubmit,
                pristine
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <View>
                            <ViewHeader
                                title="Knowledge Hub Editor"
                                subtitle={
                                    (values.title || "Untitled Article") + ((dirty && !submitSucceeded) || dirtySinceLastSubmit ? "*" : "")
                                }
                            />
                            <ViewBody noPadding>
                                <Content
                                    toolbarContent={
                                        <Stack direction="row" justifyContent="space-between" width="100%">
                                            <Stack direction="row" spacing={2}>
                                                <Undo>
                                                    {({ canUndo, canRedo, undo, redo }) => (
                                                        <ToggleButtonGroup size="small">
                                                            <ToggleButton
                                                                value="undo"
                                                                disabled={!canUndo}
                                                                onClick={() => {
                                                                    undo();
                                                                }}>
                                                                <UndoIcon fontSize="small" />
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                value="redo"
                                                                disabled={!canRedo}
                                                                onClick={() => {
                                                                    redo();
                                                                }}>
                                                                <Redo fontSize="small" />
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    )}
                                                </Undo>

                                                <ToggleButtonGroup size="small">
                                                    {/* cut, copy, paste */}
                                                    <ToggleButton
                                                        value="cut"
                                                        onClick={() => {
                                                            document.execCommand("cut");
                                                        }}>
                                                        <ContentCut fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value="copy"
                                                        onClick={() => {
                                                            document.execCommand("copy");
                                                        }}>
                                                        <ContentCopy fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value="paste"
                                                        onClick={(e) => {
                                                            navigator.clipboard.readText().then((text) => {
                                                                document.execCommand("insertText", false, text);
                                                            });
                                                        }}>
                                                        <ContentPaste fontSize="small" />
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Stack>
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                loading={submitting}
                                                disabled={invalid || (!dirtySinceLastSubmit && !dirty)}
                                                startIcon={<Save />}>
                                                Save
                                            </LoadingButton>
                                        </Stack>
                                    }
                                    headerContent={
                                        <Box sx={{ width: "100%" }}>
                                            <Stack direction="row" spacing={1} alignItems="center" width={"100%"}>
                                                <Box
                                                    onClick={() => {
                                                        openDialog(
                                                            <>
                                                                <IconSelector
                                                                    onSelect={(icon) => {
                                                                        form.change("icon", icon);
                                                                        closeDialog();
                                                                    }}
                                                                    currentIcon={values.icon ? values.icon : "book"}
                                                                />
                                                            </>
                                                        );
                                                    }}
                                                    sx={{
                                                        width: 60,
                                                        height: 60,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderRadius: 1,
                                                        border: "1px dashed",
                                                        borderColor: "transparent",
                                                        cursor: "pointer",
                                                        "&:hover": {
                                                            borderColor: (theme) => theme.palette.action.hover
                                                        }
                                                    }}>
                                                    <FontAwesomeIcon icon={["fas", values.icon ? values.icon : "book"]} size="2x" />
                                                </Box>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Field
                                                        name="title"
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return "Title is required";
                                                            }
                                                        }}>
                                                        {({ input }) => (
                                                            <InputBase
                                                                {...input}
                                                                placeholder="Title*"
                                                                fullWidth
                                                                required
                                                                sx={{
                                                                    px: 1,
                                                                    fontSize: "1.5rem",
                                                                    lineHeight: 1,
                                                                    fontWeight: 600,
                                                                    borderRadius: 1,
                                                                    border: "1px dashed",
                                                                    borderColor: "transparent",
                                                                    "&:hover": {
                                                                        borderColor: (theme) => theme.palette.action.hover
                                                                    },
                                                                    "&.Mui-focused": {
                                                                        borderColor: (theme) => theme.palette.action.focus
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field name="description">
                                                        {({ input }) => (
                                                            <InputBase
                                                                {...input}
                                                                placeholder="Description"
                                                                fullWidth
                                                                multiline
                                                                sx={{
                                                                    px: 1,
                                                                    color: "text.secondary",
                                                                    borderRadius: 1,
                                                                    border: "1px dashed",
                                                                    borderColor: "transparent",
                                                                    "&:hover": {
                                                                        borderColor: (theme) => theme.palette.action.hover
                                                                    },
                                                                    "&.Mui-focused": {
                                                                        borderColor: (theme) => theme.palette.action.focus
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    }
                                    rightContent={
                                        <>
                                            <SidebarTabs
                                                tabs={
                                                    <>
                                                        <SidebarTab
                                                            label="Article Settings"
                                                            icon={<Settings />}
                                                            active={activeTab === 0}
                                                            onClick={() => {
                                                                setActiveTab(0);
                                                            }}
                                                        />

                                                        <SidebarTab
                                                            label="Preview"
                                                            icon={<Visibility />}
                                                            active={activeTab === 1}
                                                            onClick={() => {
                                                                setActiveTab(1);
                                                            }}
                                                        />

                                                        <SidebarTab
                                                            label="Code View"
                                                            icon={<DataObject />}
                                                            active={activeTab === 2}
                                                            onClick={() => {
                                                                setActiveTab(2);
                                                            }}
                                                        />
                                                    </>
                                                }
                                                panels={
                                                    <>
                                                        <SidebarTabPanel active={activeTab === 0}>
                                                            <Box
                                                                sx={{
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column"
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                        width: "100%",
                                                                        overflowY: "auto",
                                                                        p: 0
                                                                    }}>
                                                                    <Box p={2} display="none">
                                                                        <RadioGroupField
                                                                            name="type"
                                                                            label="Select the Type"
                                                                            required
                                                                            row
                                                                            parse
                                                                            disabled={mode === "edit"}
                                                                            initialValue={KnowledgeHubType.Article}
                                                                            options={Object.entries(KnowledgeHubType).map(
                                                                                ([label, value]) => ({
                                                                                    label,
                                                                                    value
                                                                                })
                                                                            )}
                                                                            validate={(value) => {
                                                                                if (!value) {
                                                                                    return "Type is required";
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <NodeHeader label="Status" />
                                                                    <Box p={2} mb={2}>
                                                                        {values.type === KnowledgeHubType.Article && (
                                                                            <>
                                                                                <SelectField
                                                                                    label="Status"
                                                                                    options={[
                                                                                        { text: "Published", value: "published" },
                                                                                        { text: "Draft", value: "draft" },
                                                                                        { text: "Archived", value: "archived" }
                                                                                    ]}
                                                                                    disabled={isCategoryArchived(values.category)}
                                                                                    initialValue={
                                                                                        values.status
                                                                                            ? values.status
                                                                                            : KnowledgeHubItemStatus.Draft
                                                                                    }
                                                                                    name="status"
                                                                                    helperText={(meta) => meta.touched && meta.error}
                                                                                    error={(meta) => meta.touched && meta.error}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                    <NodeHeader label="Search Options" />
                                                                    <Box p={2} mb={2}>
                                                                        <SelectField
                                                                            required
                                                                            label="Category"
                                                                            options={[
                                                                                ...categories.map((category) => ({
                                                                                    text: category.title,
                                                                                    value: category.id
                                                                                })),
                                                                                ...archivedCategories.map((category) => ({
                                                                                    text: `${category.title} (archived)`,
                                                                                    value: category.id
                                                                                })),
                                                                                { text: "Hidden", value: "hidden" }
                                                                            ]}
                                                                            disabled={categories.length === 0}
                                                                            name="category"
                                                                            helperText={(meta) =>
                                                                                (meta.touched && meta.error) || (
                                                                                    <Box
                                                                                        sx={{ color: "primary.main", cursor: "pointer" }}
                                                                                        onClick={() => {
                                                                                            navigate("/knowledge-hub/create");
                                                                                        }}>
                                                                                        Create a new category
                                                                                    </Box>
                                                                                )
                                                                            }
                                                                            error={(meta) => meta.touched && meta.error}
                                                                            validate={(value) => {
                                                                                if (!value) {
                                                                                    return "Category is required";
                                                                                }
                                                                            }}
                                                                        />
                                                                        <OnChange name="category">
                                                                            {(value) => {
                                                                                if (isCategoryArchived(value)) {
                                                                                    form.change("status", KnowledgeHubItemStatus.Archived);
                                                                                }
                                                                            }}
                                                                        </OnChange>

                                                                        <TextField name="order" label="Order" parse />
                                                                        <MultiAutocompleteField
                                                                            name="tags"
                                                                            label="Tags"
                                                                            options={tags}
                                                                            creatable
                                                                        />
                                                                        <CheckboxField
                                                                            name="isIndexable"
                                                                            label="Include In Search Results?"
                                                                        />
                                                                        <CheckboxField
                                                                            name="showInCategory"
                                                                            label="Include In Category Listings"
                                                                        />
                                                                    </Box>

                                                                    <NodeHeader label="Display Options" />
                                                                    <Box p={2} mb={2}>
                                                                        <CheckboxField name="isNumbered" label="Numbered Sections?" />
                                                                    </Box>

                                                                    <NodeHeader label="Security" />
                                                                    <Box p={2}>
                                                                        <SelectField
                                                                            label="Client"
                                                                            options={[
                                                                                { text: "All", value: "" },
                                                                                { text: "Default", value: "default" },
                                                                                { text: "Ateme", value: "ateme" }
                                                                            ]}
                                                                            name="client"
                                                                            helperText={(meta) => meta.touched && meta.error}
                                                                            error={(meta) => meta.touched && meta.error}
                                                                        />
                                                                        <MultiSelectField
                                                                            label="Roles"
                                                                            options={[
                                                                                { text: "Unrestricted", value: "all" },
                                                                                { text: "Developers", value: "is_dev" },
                                                                                { text: "Admins", value: "is_admin" }
                                                                            ]}
                                                                            name="roles"
                                                                            helperText={(meta) => meta.touched && meta.error}
                                                                            error={(meta) => meta.touched && meta.error}
                                                                            onChange={(_, value: string[], previous: string[]) => {
                                                                                let diff = getDiffFromArrays(previous, value);

                                                                                if (diff.added.length) {
                                                                                    if (diff.added.includes("all")) {
                                                                                        return form.change("roles", ["all"]);
                                                                                    }

                                                                                    if (value.includes("all")) {
                                                                                        return form.change(
                                                                                            "roles",
                                                                                            value.filter((item) => item !== "all")
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                        <SelectField
                                                                            label="Module Access"
                                                                            options={[
                                                                                {
                                                                                    text: "All",
                                                                                    value: ""
                                                                                },
                                                                                ...Object.keys(UserModules).map((key) => ({
                                                                                    value: key,
                                                                                    text: UserModules[key as keyof typeof UserModules]
                                                                                }))
                                                                            ]}
                                                                            name="moduleAccess"
                                                                            helperText={(meta) => meta.touched && meta.error}
                                                                            error={(meta) => meta.touched && meta.error}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                <Box p={2}>
                                                                    <LoadingButton
                                                                        type="submit"
                                                                        variant="contained"
                                                                        loading={submitting}
                                                                        disabled={invalid || !dirty}
                                                                        fullWidth>
                                                                        Save
                                                                    </LoadingButton>
                                                                </Box>
                                                            </Box>
                                                        </SidebarTabPanel>
                                                        <SidebarTabPanel active={activeTab === 1}>
                                                            <Box
                                                                sx={{
                                                                    pt: 2,
                                                                    pr: 2,
                                                                    pb: 2,
                                                                    pl: 2,
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    overflowY: "auto"
                                                                }}>
                                                                {((dirty && !submitSucceeded) || dirtySinceLastSubmit) && (
                                                                    <Alert
                                                                        severity="warning"
                                                                        sx={{
                                                                            mb: 2,
                                                                            mr: 2
                                                                        }}>
                                                                        You have unsaved changes. Please save your changes before
                                                                        previewing.
                                                                    </Alert>
                                                                )}
                                                                <LivePreview values={values} />
                                                                {/* <KnowledgeHub
                                                                        key={id}
                                                                        entry={KnowledgeHubSelectorType.Item}
                                                                        isSidebar
                                                                        entryId={id}
                                                                        onNavigate={(history, setHistory) => {
                                                                            console.log("test...", history);
                                                                        }}
                                                                    /> */}
                                                            </Box>
                                                        </SidebarTabPanel>
                                                        <SidebarTabPanel active={activeTab === 2}>
                                                            <Editor
                                                                value={JSON.stringify(values, null, 2)}
                                                                defaultLanguage="json"
                                                                height={"100%"}
                                                                theme="vs-dark"
                                                                options={{
                                                                    autoIndent: "full",
                                                                    automaticLayout: true,
                                                                    minimap: {
                                                                        enabled: false
                                                                    },
                                                                    wordWrap: "on",
                                                                    formatOnPaste: true,
                                                                    formatOnType: true
                                                                }}
                                                                onChange={(value, event) => {
                                                                    //set the form values to the value of the editor
                                                                    //if the editor value is valid JSON
                                                                    try {
                                                                        form.change("", JSON.parse(value!));
                                                                    } catch (error) {
                                                                        console.log("error", error);
                                                                    }
                                                                }}
                                                            />
                                                        </SidebarTabPanel>
                                                    </>
                                                }
                                                collapsible={false}
                                            />
                                        </>
                                    }
                                    rightWidth={420}>
                                    <Box
                                        sx={{
                                            mb: 2,
                                            border: "1px dashed",
                                            borderColor: (theme) => theme.palette.action.selected,
                                            borderRadius: 1,
                                            display: "flex",
                                            flexDirection: "row",
                                            p: 1,
                                            pb: 2
                                        }}>
                                        <Toc sx={{ mt: 1 }} />
                                        <Tooltip
                                            placement="right"
                                            title="Note: Only sections with titles will be included in the TOC. Sections will be automatically numbered if 'Numbered Sections' is enabled.">
                                            <Box ml={2} flexGrow={1}>
                                                <CheckboxField name="includeTOC" label="Include Table of Contents?" />

                                                {values.includeTOC && (
                                                    <div>
                                                        <List dense>
                                                            {values.sections
                                                                ?.filter((section: IKnowledgeSection) => section.title !== undefined)
                                                                .map((section: IKnowledgeSection, index: number) => (
                                                                    <ListItem key={index} disablePadding>
                                                                        <ListItemButton onClick={() => {}}>
                                                                            <ListItemIcon sx={{ minWidth: 20 }}>
                                                                                {values.isNumbered ? `${index + 1}.` : <>&bull;</>}
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={section.title}
                                                                                sx={{
                                                                                    color: (theme) => theme.palette.primary.main
                                                                                }}
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                ))}
                                                        </List>
                                                    </div>
                                                )}
                                            </Box>
                                        </Tooltip>
                                    </Box>

                                    <Box
                                        sx={{
                                            mb: 2,
                                            border: "1px dashed",
                                            borderColor: (theme) => theme.palette.action.selected,
                                            borderRadius: 1,
                                            display: "flex",
                                            flexDirection: "row",
                                            p: 1
                                        }}>
                                        <Image sx={{ mt: 0.5 }} />
                                        <Box ml={2} flexGrow={1}>
                                            <Field name="image">
                                                {({ input }) => (
                                                    <InputBase
                                                        {...input}
                                                        placeholder="Cover Image URL (Optional)"
                                                        fullWidth
                                                        onClick={() => {
                                                            openDialog(
                                                                <MediaGallery onSelected={(url) => form.change("image", url)} />,
                                                                undefined,
                                                                "lg"
                                                            );
                                                        }}
                                                        sx={{
                                                            px: 1,
                                                            borderRadius: 1,
                                                            border: "1px dashed",
                                                            borderColor: "transparent",
                                                            mb: values.image ? 2 : 0,
                                                            "&:hover": {
                                                                borderColor: (theme) => theme.palette.action.hover
                                                            },
                                                            "&.Mui-focused": {
                                                                borderColor: (theme) => theme.palette.action.focus
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            {values.image && <Box component="img" src={values.image} alt="Cover Image" maxWidth="100%" />}
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            mb: 2,
                                            border: "1px dashed",
                                            borderColor: (theme) => theme.palette.action.selected,
                                            borderRadius: 1,
                                            display: "flex",
                                            flexDirection: "row",
                                            p: 1
                                        }}>
                                        <OndemandVideo sx={{ mt: 0.5 }} />
                                        <Box ml={2} flexGrow={1}>
                                            <Field name="video">
                                                {({ input }) => (
                                                    <InputBase
                                                        {...input}
                                                        placeholder="Cover Video URL (Optional)"
                                                        fullWidth
                                                        sx={{
                                                            px: 1,
                                                            borderRadius: 1,
                                                            border: "1px dashed",
                                                            borderColor: "transparent",
                                                            mb: values.video ? 2 : 0,
                                                            "&:hover": {
                                                                borderColor: (theme) => theme.palette.action.hover
                                                            },
                                                            "&.Mui-focused": {
                                                                borderColor: (theme) => theme.palette.action.focus
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            {values.video && (
                                                <video src={values.video} controls style={{ width: "100%" }}>
                                                    Your browser does not support the video tag.
                                                </video>
                                            )}
                                        </Box>
                                    </Box>
                                    <FieldArray
                                        name="sections"
                                        defaultValue={[
                                            {
                                                name: undefined,
                                                content: undefined
                                            }
                                        ]}
                                        validate={(value) => {
                                            if (!value) {
                                                return "Required";
                                            }
                                        }}>
                                        {({ fields }) => (
                                            <Box>
                                                {fields.map((name, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            mb: 2,
                                                            border: "1px dashed",
                                                            borderColor: (theme) => theme.palette.action.selected,
                                                            borderRadius: 1
                                                        }}>
                                                        <CardHeader
                                                            sx={{ p: 1 }}
                                                            //title={`Section ${index + 1}`}
                                                            title={
                                                                <Box sx={{ flexGrow: 1 }}>
                                                                    <Field name={`${name}.title`}>
                                                                        {({ input }) => (
                                                                            <InputBase
                                                                                {...input}
                                                                                placeholder={`Section Title ${index + 1}`}
                                                                                fullWidth
                                                                                startAdornment={
                                                                                    values.isNumbered && (
                                                                                        <Typography
                                                                                            variant="h5"
                                                                                            sx={{ mr: 1, color: "text.secondary" }}>
                                                                                            {index + 1}.
                                                                                        </Typography>
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    px: 1,
                                                                                    fontSize: "1.3392857142857144rem",
                                                                                    fontWeight: 600,
                                                                                    borderRadius: 1,
                                                                                    border: "1px dashed",
                                                                                    borderColor: "transparent",
                                                                                    "&:hover": {
                                                                                        borderColor: (theme) => theme.palette.action.hover
                                                                                    },
                                                                                    "&.Mui-focused": {
                                                                                        borderColor: (theme) => theme.palette.action.focus
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <Field name={`${name}.description`}>
                                                                        {({ input }) => (
                                                                            <InputBase
                                                                                {...input}
                                                                                placeholder="Description"
                                                                                fullWidth
                                                                                multiline
                                                                                sx={{
                                                                                    px: 1,
                                                                                    color: "text.secondary",
                                                                                    borderRadius: 1,
                                                                                    border: "1px dashed",
                                                                                    borderColor: "transparent",
                                                                                    "&:hover": {
                                                                                        borderColor: (theme) => theme.palette.action.hover
                                                                                    },
                                                                                    "&.Mui-focused": {
                                                                                        borderColor: (theme) => theme.palette.action.focus
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Box>
                                                            }
                                                            action={
                                                                <Stack direction="row" mr={1} mt={1} ml={1}>
                                                                    <IconButton
                                                                        disabled={index === 0}
                                                                        aria-label="move up"
                                                                        onClick={() => {
                                                                            fields.swap(index, index - 1);
                                                                        }}>
                                                                        <ArrowUpward />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        disabled={
                                                                            !!fields && !!fields.length && index === fields.length - 1
                                                                        }
                                                                        aria-label="move down"
                                                                        onClick={() => {
                                                                            fields.swap(index, index + 1);
                                                                        }}>
                                                                        <ArrowDownward />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => {
                                                                            fields.remove(index);
                                                                        }}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </Stack>
                                                            }
                                                        />
                                                        <CardContent sx={{ p: 1 }}>
                                                            <Stack spacing={2}>
                                                                <WysiwygField key={index} name={`${name}.content`} />

                                                                {/* <TextField name={`${name}.image`} label="Section Image URL" /> */}
                                                                <Box
                                                                    sx={{
                                                                        mb: 2,
                                                                        border: "1px dashed",
                                                                        borderColor: (theme) => theme.palette.action.selected,
                                                                        borderRadius: 1,
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        p: 1
                                                                    }}>
                                                                    <Image sx={{ mt: 0.5 }} />
                                                                    <Box ml={2} flexGrow={1}>
                                                                        <Field name={`${name}.image`}>
                                                                            {({ input }) => (
                                                                                <InputBase
                                                                                    {...input}
                                                                                    placeholder="Section Image URL (Optional)"
                                                                                    fullWidth
                                                                                    sx={{
                                                                                        px: 1,
                                                                                        borderRadius: 1,
                                                                                        border: "1px dashed",
                                                                                        borderColor: "transparent",
                                                                                        mb: values.sections[index]?.image ? 2 : 0,
                                                                                        "&:hover": {
                                                                                            borderColor: (theme) =>
                                                                                                theme.palette.action.hover
                                                                                        },
                                                                                        "&.Mui-focused": {
                                                                                            borderColor: (theme) =>
                                                                                                theme.palette.action.focus
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        {values.sections[index]?.image && (
                                                                            <Box
                                                                                component="img"
                                                                                src={values.sections[index]?.image}
                                                                                alt="Section Image"
                                                                                maxWidth="100%"
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        mb: 2,
                                                                        border: "1px dashed",
                                                                        borderColor: (theme) => theme.palette.action.selected,
                                                                        borderRadius: 1,
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        p: 1
                                                                    }}>
                                                                    <OndemandVideo sx={{ mt: 0.5 }} />
                                                                    <Box ml={2} flexGrow={1}>
                                                                        <Field name={`${name}.video`}>
                                                                            {({ input }) => (
                                                                                <InputBase
                                                                                    {...input}
                                                                                    placeholder="Section Video URL (Optional)"
                                                                                    fullWidth
                                                                                    sx={{
                                                                                        px: 1,
                                                                                        borderRadius: 1,
                                                                                        border: "1px dashed",
                                                                                        borderColor: "transparent",
                                                                                        mb: values.sections[index]?.video ? 2 : 0,
                                                                                        "&:hover": {
                                                                                            borderColor: (theme) =>
                                                                                                theme.palette.action.hover
                                                                                        },
                                                                                        "&.Mui-focused": {
                                                                                            borderColor: (theme) =>
                                                                                                theme.palette.action.focus
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        {values.sections[index]?.video && (
                                                                            <video
                                                                                src={values.sections[index]?.video}
                                                                                controls
                                                                                style={{ width: "100%" }}>
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        mb: 2,
                                                                        border: "1px dashed",
                                                                        borderColor: (theme) => theme.palette.action.selected,
                                                                        borderRadius: 1,
                                                                        p: 1
                                                                    }}>
                                                                    <FieldArray name={`${name}.accordions`}>
                                                                        {({ fields }) => (
                                                                            <Stack spacing={2}>
                                                                                {fields.map((name, accordion_index) => (
                                                                                    <Accordion key={accordion_index}>
                                                                                        <AccordionSummary expandIcon={<ExpandMore />}>
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    alignItems: "center",
                                                                                                    width: "100%"
                                                                                                }}>
                                                                                                <Field name={`${name}.title`}>
                                                                                                    {({ input }) => (
                                                                                                        <InputBase
                                                                                                            {...input}
                                                                                                            placeholder="Accordion Title"
                                                                                                            fullWidth
                                                                                                            sx={{
                                                                                                                px: 1,
                                                                                                                fontSize: "inherit",
                                                                                                                alignSelf: "flex-start",
                                                                                                                borderRadius: 1,
                                                                                                                border: "1px dashed",
                                                                                                                borderColor: "transparent",
                                                                                                                "&:hover": {
                                                                                                                    borderColor: (theme) =>
                                                                                                                        theme.palette.action
                                                                                                                            .hover
                                                                                                                },
                                                                                                                "&.Mui-focused": {
                                                                                                                    borderColor: (theme) =>
                                                                                                                        theme.palette.action
                                                                                                                            .focus
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <Stack direction="row">
                                                                                                    <IconButton
                                                                                                        aria-label="delete"
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            fields.remove(accordion_index);
                                                                                                        }}>
                                                                                                        <Delete />
                                                                                                    </IconButton>
                                                                                                </Stack>
                                                                                            </Box>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails sx={{ pl: 0 }}>
                                                                                            <WysiwygField
                                                                                                key={index}
                                                                                                name={`${name}.content`}
                                                                                                //label="Accordion Content"
                                                                                            />
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                ))}

                                                                                <Stack
                                                                                    flexDirection="row"
                                                                                    justifyContent="flex-end"
                                                                                    sx={{ ml: 2 }}>
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            fields.push({
                                                                                                title: undefined,
                                                                                                content: undefined
                                                                                            })
                                                                                        }
                                                                                        startIcon={<AddCircle />}
                                                                                        //variant="contained"
                                                                                    >
                                                                                        Add Accordion
                                                                                    </Button>
                                                                                </Stack>
                                                                            </Stack>
                                                                        )}
                                                                    </FieldArray>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        mb: 2,
                                                                        border: "1px dashed",
                                                                        borderColor: (theme) => theme.palette.action.selected,
                                                                        borderRadius: 1,
                                                                        p: 1
                                                                    }}>
                                                                    <FieldArray name={`${name}.relatedArticles.items`}>
                                                                        {({ fields }) => (
                                                                            <Stack spacing={2}>
                                                                                {!!fields.length && (
                                                                                    // <TextField
                                                                                    //     name={`${name}.relatedArticles.title`}
                                                                                    //     label="Group of Articles Title"
                                                                                    // />
                                                                                    <Field name={`${name}.relatedArticles.title`}>
                                                                                        {({ input }) => (
                                                                                            <InputBase
                                                                                                {...input}
                                                                                                placeholder="Related Articles Title (Optional)"
                                                                                                fullWidth
                                                                                                sx={{
                                                                                                    px: 1,
                                                                                                    fontSize: "1rem",
                                                                                                    fontWeight: 600,
                                                                                                    borderRadius: 1,
                                                                                                    border: "1px dashed",
                                                                                                    borderColor: "transparent",
                                                                                                    "&:hover": {
                                                                                                        borderColor: (theme) =>
                                                                                                            theme.palette.action.hover
                                                                                                    },
                                                                                                    "&.Mui-focused": {
                                                                                                        borderColor: (theme) =>
                                                                                                            theme.palette.action.focus
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                )}

                                                                                {fields.map((name, index) => (
                                                                                    <Paper sx={{ p: 2, ml: 2 }} key={index}>
                                                                                        <Stack spacing={2}>
                                                                                            <Stack
                                                                                                direction="row"
                                                                                                alignItems="center"
                                                                                                justifyContent="space-between"
                                                                                                spacing={2}>
                                                                                                <AutocompleteField
                                                                                                    label="Article"
                                                                                                    options={items.map((item) => ({
                                                                                                        title: item.title,
                                                                                                        value: item.id
                                                                                                    }))}
                                                                                                    name={`${name}.id`}
                                                                                                    validate={(value) => {
                                                                                                        if (!value) {
                                                                                                            return "Required";
                                                                                                        }
                                                                                                    }}
                                                                                                    helperText={(meta) =>
                                                                                                        meta.touched && meta.error
                                                                                                    }
                                                                                                    error={(meta) =>
                                                                                                        meta.touched && meta.error
                                                                                                    }
                                                                                                />

                                                                                                <IconButton
                                                                                                    aria-label="delete"
                                                                                                    onClick={() => {
                                                                                                        fields.remove(index);
                                                                                                    }}>
                                                                                                    <Delete />
                                                                                                </IconButton>
                                                                                            </Stack>
                                                                                        </Stack>
                                                                                    </Paper>
                                                                                ))}

                                                                                <Stack
                                                                                    flexDirection="row"
                                                                                    justifyContent="flex-end"
                                                                                    sx={{ ml: 2 }}>
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            fields.push({
                                                                                                title: undefined,
                                                                                                order: 0
                                                                                            })
                                                                                        }
                                                                                        startIcon={<AddCircle />}
                                                                                        //variant="contained"
                                                                                    >
                                                                                        Add Related Article
                                                                                    </Button>
                                                                                </Stack>
                                                                            </Stack>
                                                                        )}
                                                                    </FieldArray>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        mb: 2,
                                                                        border: "1px dashed",
                                                                        borderColor: (theme) => theme.palette.action.selected,
                                                                        borderRadius: 1,
                                                                        p: 1
                                                                    }}>
                                                                    <FieldArray name={`${name}.alerts`}>
                                                                        {({ fields }) => (
                                                                            <Stack spacing={2}>
                                                                                {fields.map((name, alert_index) => (
                                                                                    <Alert
                                                                                        key={alert_index}
                                                                                        severity={
                                                                                            values.sections[index].alerts[alert_index].type
                                                                                        }
                                                                                        sx={{
                                                                                            "& .MuiAlert-message": {
                                                                                                width: "100%"
                                                                                            }
                                                                                        }}>
                                                                                        <AlertTitle sx={{ width: "100%" }}>
                                                                                            <Box
                                                                                                sx={{
                                                                                                    width: "100%",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "space-between",
                                                                                                    alignItems: "center"
                                                                                                }}>
                                                                                                <Field name={`${name}.title`}>
                                                                                                    {({ input }) => (
                                                                                                        <InputBase
                                                                                                            {...input}
                                                                                                            placeholder="Alert Title"
                                                                                                            fullWidth
                                                                                                            sx={{
                                                                                                                px: 1,
                                                                                                                fontSize: "inherit",
                                                                                                                alignSelf: "flex-start",
                                                                                                                borderRadius: 1,
                                                                                                                border: "1px dashed",
                                                                                                                borderColor: "transparent",
                                                                                                                "&:hover": {
                                                                                                                    borderColor: (theme) =>
                                                                                                                        theme.palette.action
                                                                                                                            .hover
                                                                                                                },
                                                                                                                "&.Mui-focused": {
                                                                                                                    borderColor: (theme) =>
                                                                                                                        theme.palette.action
                                                                                                                            .focus
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        display: "flex",
                                                                                                        alignItems: "center",
                                                                                                        ml: 2,
                                                                                                        width: 200
                                                                                                    }}>
                                                                                                    <SelectField
                                                                                                        name={`${name}.type`}
                                                                                                        //label="Type"
                                                                                                        options={[
                                                                                                            {
                                                                                                                text: "Success",
                                                                                                                value: "success"
                                                                                                            },
                                                                                                            {
                                                                                                                text: "Information",
                                                                                                                value: "info"
                                                                                                            },
                                                                                                            {
                                                                                                                text: "Warning",
                                                                                                                value: "warning"
                                                                                                            },
                                                                                                            {
                                                                                                                text: "Error",
                                                                                                                value: "error"
                                                                                                            }
                                                                                                        ]}
                                                                                                    />
                                                                                                    <IconButton
                                                                                                        sx={{ ml: 1 }}
                                                                                                        aria-label="delete"
                                                                                                        onClick={() => {
                                                                                                            fields.remove(alert_index);
                                                                                                        }}>
                                                                                                        <Delete />
                                                                                                    </IconButton>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </AlertTitle>
                                                                                        <Field name={`${name}.content`}>
                                                                                            {({ input }) => (
                                                                                                <InputBase
                                                                                                    {...input}
                                                                                                    placeholder="Alert Content"
                                                                                                    fullWidth
                                                                                                    multiline
                                                                                                    sx={{
                                                                                                        px: 1,
                                                                                                        //color: "text.secondary",
                                                                                                        fontSize: "inherit",
                                                                                                        borderRadius: 1,
                                                                                                        border: "1px dashed",
                                                                                                        borderColor: "transparent",
                                                                                                        "&:hover": {
                                                                                                            borderColor: (theme) =>
                                                                                                                theme.palette.action.hover
                                                                                                        },
                                                                                                        "&.Mui-focused": {
                                                                                                            borderColor: (theme) =>
                                                                                                                theme.palette.action.focus
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </Alert>
                                                                                ))}

                                                                                <Stack
                                                                                    flexDirection="row"
                                                                                    justifyContent="flex-end"
                                                                                    sx={{ ml: 2 }}>
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            fields.push({
                                                                                                title: undefined,
                                                                                                content: undefined,
                                                                                                type: "info"
                                                                                            })
                                                                                        }
                                                                                        startIcon={<AddCircle />}
                                                                                        //variant="contained"
                                                                                    >
                                                                                        Add Alert
                                                                                    </Button>
                                                                                </Stack>
                                                                            </Stack>
                                                                        )}
                                                                    </FieldArray>
                                                                </Box>
                                                            </Stack>
                                                        </CardContent>
                                                    </Box>
                                                ))}

                                                <ButtonBase
                                                    onClick={() =>
                                                        fields.push({
                                                            name: undefined,
                                                            content: undefined
                                                        })
                                                    }
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        p: 2,
                                                        mt: 2,
                                                        width: "100%",
                                                        border: "1px dashed",
                                                        borderColor: (theme) => theme.palette.action.active,
                                                        borderRadius: 1,
                                                        cursor: "pointer",
                                                        opacity: 0.5,
                                                        "&:hover": {
                                                            opacity: 1
                                                        }
                                                    }}>
                                                    <AddCircle sx={{ fontSize: 40 }} />
                                                    <Typography variant="body1">Add Section</Typography>
                                                </ButtonBase>
                                            </Box>
                                        )}
                                    </FieldArray>
                                </Content>
                            </ViewBody>
                        </View>
                    </form>
                );
            }}
        />
    );
};

export default observer(KnowledgeHubEditor);
