import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import { Box, Button, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import SearchMenu from "features/search/Components/SearchMenu";
import { agent, KnowledgeHubSelectorType, RootStoreContext } from "api";
import { useConstants, useKnowledgeHub } from "hooks";
import { LoadingButton } from "@mui/lab";
import KnowledgeHub from "features/knowledgeHub";
import { EmailProvider } from "components";
import VideoPlayer from "features/multiviewers/Components/VideoPlayer";

const Developer: React.FC = () => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { client, clientConnected, initialize, destroy } = rootStore.resClientStore;
    const { user, updateUserPreferences, getUser } = rootStore.userStore;
    const { openDrawer } = rootStore.drawerStore;
    const { updateItemsWithNewField, isSubmitting } = useKnowledgeHub();
    const { items } = rootStore.knowledgeHubStore;
    const { constants } = useConstants();

    const jwtToken = rootStore.commonStore.token;

    useEffect(() => {}, [client.cache]);

    return (
        <View>
            <ViewHeader title="Developer" subtitle="UI Development" preview />
            <ViewBody noPadding>
                <Content
                    toolbarContent={
                        <Box>
                            <Button
                                onClick={() => {
                                    destroy();
                                }}>
                                Destroy resClient
                            </Button>
                        </Box>
                    }
                    headerContent={<Box>Header</Box>}
                    leftContent={<Box>Left</Box>}
                    rightContent={<Box>Right</Box>}
                    footerContent={<Box>Footer</Box>}>



                    <VideoPlayer multiviewer_id="ba2de8df-2569-493a-b076-da6247e6488d" />



                    <KnowledgeHub
                        key="kb_item-1720198144940"
                        entry={KnowledgeHubSelectorType.Item}
                        isSidebar
                        entryId="kb_item-1720198144940"
                        onNavigate={(history, setHistory) => {
                            console.log("test...", history);
                        }}
                    />
                    <Box sx={{ mb: 5, maxWidth: 600 }}>
                        <Typography variant="h5">Theme</Typography>
                        <Typography variant="body2" color="text.secondary" mb={3}>
                            {/* Describe the advantages of dark mode and light mode */}
                            Dark mode is easier on the eyes and can help reduce eye strain. Light mode can help improve focus and
                            productivity
                        </Typography>
                        <ToggleButtonGroup
                            value={user?.user_preferences?.theme}
                            exclusive
                            onChange={(e, value) => updateUserPreferences({ theme: value })}>
                            <ToggleButton value="light">Light Mode</ToggleButton>
                            <ToggleButton value="dark">Dark Mode</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Typography variant="h1">Heading (h1)</Typography>
                    <Typography variant="h2">Subheader (h2)</Typography>
                    <Typography variant="h3">Subheader (h3)</Typography>
                    <Typography variant="h4">Subheader (h4)</Typography>
                    <Typography variant="h5">Subheader (h5)</Typography>
                    <Typography variant="h6">Subheader (h6)</Typography>
                    <Divider />
                    <Typography variant="body1">Body (body1)</Typography>
                    <Typography variant="body2">Body (body2)</Typography>
                    <Divider />
                    <Typography variant="caption">Caption</Typography>
                    <Typography variant="overline">Overline</Typography>
                    <Typography variant="subtitle1">Subtitle (subtitle1)</Typography>
                    <Typography variant="subtitle2">Subtitle (subtitle2)</Typography>
                    <Divider />
                    <Stack spacing={1} direction="column" sx={{ mt: 4 }}>
                        <LoadingButton
                            variant="contained"
                            loading={isSubmitting}
                            color="primary"
                            onClick={async () => {
                                if (!items.length) {
                                    return;
                                }

                                const [categoryData, itemsData] = await Promise.all([
                                    agent.KnowledgeHub.getAllByCategory("kb_category", constants.data.KnowledgeHub.searchFields),
                                    agent.KnowledgeHub.getAllByCategory("kb_cat-*", constants.data.KnowledgeHub.allFields)
                                ]);

                                updateItemsWithNewField([...categoryData.articles, ...itemsData.articles], {
                                    fieldName: "showInCategory",
                                    value: true
                                });
                            }}
                            sx={{
                                mt: 2
                            }}>
                            Add Data to KB
                        </LoadingButton>

                        <EmailProvider acceptedEmails={constants.cerberus.emails.frontend}>
                            <Button
                                onClick={() => {
                                    openDrawer(
                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", display: "flex" }}>
                                            <KnowledgeHub entry={KnowledgeHubSelectorType.Homepage} isSidebar />
                                        </Box>
                                    );
                                }}>
                                NEW Help
                            </Button>
                            <Button
                                onClick={() => {
                                    openDrawer(
                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", display: "flex" }}>
                                            <KnowledgeHub entry={KnowledgeHubSelectorType.Homepage} isSidebar initSearch="vid" />
                                        </Box>
                                    );
                                }}>
                                LINK WITH SEARCH PARAM
                            </Button>
                            <Button
                                onClick={() => {
                                    openDrawer(
                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", display: "flex", p: 3 }}>
                                            <KnowledgeHub entry={KnowledgeHubSelectorType.Item} isSidebar entryId="kb_item-1" />
                                        </Box>
                                    );
                                }}>
                                DIRECT LINK TO ITEM
                            </Button>
                            <Button
                                onClick={() => {
                                    openDrawer(
                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3, display: "flex" }}>
                                            <KnowledgeHub entry={KnowledgeHubSelectorType.Category} isSidebar entryId="kb_cat-1" />
                                        </Box>
                                    );
                                }}>
                                DIRECT LINK TO CATEGORY
                            </Button>
                        </EmailProvider>
                    </Stack>
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(Developer);
