import { observable, action, makeObservable } from "mobx";
import { RootStore } from "./rootStore";

export default class SidebarStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable sidebarExpanded: boolean = false;

    @action setSidebarExpanded = (expanded: boolean): void => {
        this.sidebarExpanded = expanded;
    };
}
