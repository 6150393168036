import { IconName } from "@fortawesome/fontawesome-svg-core";
import useModuleAccess from "hooks/UseModuleAccess";

export interface IFeature {
    name: string;
    description: string;
    path: string;
    icon: IconName;
    type?: string;
    tags: string[];
    module?: string;
}

export const allFeatures: IFeature[] = [
    {
        name: "Create a new environment",
        description: "Environments store your content and settings",
        path: "/environments/create",
        icon: "server",
        type: "create",
        tags: ["environment", "environments", "create", "make", "new", "add", "live"]
    },
    {
        name: "Manage your environments",
        description: "View and manage your environments",
        path: "/environments",
        icon: "server",
        tags: ["environment", "environments", "manage", "view", "list", "live"]
    },
    {
        name: "Create a new source",
        description: "A sources ingests content into the platform",
        path: "/sources/create",
        icon: "video",
        type: "create",
        tags: [
            "source",
            "sources",
            "create",
            "make",
            "new",
            "add",
            "input",
            "inputs",
            "zixi",
            "srt",
            "rtmp",
            "rtsp",
            "rist",
            "listener",
            "caller",
            "live",
            "ingest",
            "stream",
            "streams",
            "transcoder",
            "multiviewer",
        ]
    },
    {
        name: "Manage your sources",
        description: "View and manage your sources",
        path: "/sources",
        icon: "video",
        tags: ["source", "sources", "inputs", "manage", "view", "list", "ingest", "stream", "streams"]
    },
    {
        name: "Create a new destination",
        description: "Destinations output content from the platform",
        path: "/destinations/create",
        icon: "tower-broadcast",
        type: "create",
        tags: [
            "destination",
            "destinations",
            "create",
            "make",
            "new",
            "add",
            "output",
            "outputs",
            "zixi pull",
            "push",
            "pull",
            "rist",
            "rtmp",
            "srt",
            "caller",
            "listener",
            "udp",
            "permanent"
        ]
    },
    {
        name: "Manage your destinations",
        description: "View and manage your destinations",
        path: "/destinations",
        icon: "tower-broadcast",
        tags: ["destination", "destinations", "manage", "view", "list", "output", "outputs"]
    },
    {
        name: "Create a new schedule",
        description: "Schedules automate your content workflows",
        path: "/scheduler",
        icon: "calendar-week",
        type: "create",
        tags: ["schedule", "schedules", "create", "make", "new", "add", "automate", "workflow", "workflows"]
    },
    {
        name: "Manage your schedules",
        description: "View and manage your schedules",
        path: "/scheduler",
        icon: "calendar-week",
        tags: ["schedule", "schedules", "manage", "view", "list", "workflow", "workflows"]
    },
    {
        name: "View today's schedule",
        description: "View today's scheduled events",
        path: "/scheduler",
        icon: "calendar-day",
        tags: ["schedule", "schedules", "view", "today", "day", "events"]
    },
    {
        name: "View this week's schedule",
        description: "View this week's scheduled events",
        path: "/scheduler/week",
        icon: "calendar-week",
        tags: ["schedule", "schedules", "view", "week", "events"]
    },
    {
        name: "View this month's schedule",
        description: "View this month's scheduled events",
        path: "/scheduler/month",
        icon: "calendar",
        tags: ["schedule", "schedules", "view", "month", "events"]
    },
    {
        name: "Edit your profile",
        description: "Update your personal information",
        path: "/user",
        icon: "user",
        tags: ["profile", "edit", "update", "personal", "information", "name", "email", "avatar"]
    },
    {
        name: "Change your password",
        description: "Update your account password",
        path: "/user/security",
        icon: "lock",
        tags: ["password", "change password", "update password", "account", "security"]
    },
    {
        name: "Two factor authentication (2FA)",
        description: "Enable two factor authentication for your account",
        path: "/user/security",
        icon: "shield-keyhole",
        tags: ["2fa", "two factor", "authentication", "enable", "security"]
    },
    {
        name: "Change your theme",
        description: "Change the theme of the platform",
        path: "/user/preferences",
        icon: "palette",
        tags: ["theme", "change", "dark", "light", "color", "colors"],
    },
    {
        name: "Notification preferences",
        description: "Change your notification settings",
        path: "/user/preferences",
        icon: "bell",
        tags: ["notification", "notifications", "change", "desktop notifications", "silence notifications", "settings"]
    },
    {
        name: "Notifications",
        description: "View your organisations notifications",
        path: "/notifications",
        icon: "bell",
        tags: ["notification", "notifications", "view", "list", "alerts"]
    },
    {
        name: "Homepage preferences",
        description: "Set your homepage to quickly access the page you use most often.",
        path: "/user/preferences",
        icon: "home",
        tags: ["homepage", "home", "landing", "set", "default"]
    },
    // {
    //     name: "Pull Packager",
    //     description: "Manage your pull packagers",
    //     path: "/pull-packager",
    //     icon: "arrow-left-long-to-line",
    //     tags: ["pull packager", "packager", "manage", "view", "list"],
    //     module: "nealive"
    // },
    // {
    //     name: "Push Packager",
    //     description: "Manage your push packagers",
    //     path: "/packager",
    //     icon: "arrow-right-long-to-line",
    //     tags: ["push packager", "packager", "manage", "view", "list"],
    //     module: "push_packager"
    // },
    // {
    //     name: "File Transcoding",
    //     description: "Transcode files to different formats",
    //     path: "/file",
    //     icon: "file-video",
    //     tags: ["file", "transcode", "transcoding"],
    //     module: "file_transcoding"
    // }
];

const features = (): IFeature[] => {

    let userFeatures: IFeature[] = [];

    allFeatures.forEach((feature) => {
        if (!feature.module || useModuleAccess(feature.module)) {
            userFeatures.push(feature);
        }
    });

    return userFeatures;
}

//export default Array.from(features());


