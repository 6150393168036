import { Alert, Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { agent, IGetLimitsResponseItem, RootStoreContext } from "api";
import React, { useContext, useEffect } from "react";

interface ConfirmUpdateLimitProps {
    limit: IGetLimitsResponseItem;
}

const ConfirmUpdateLimit: React.FC<ConfirmUpdateLimitProps> = ({ limit }) => {
    const [error, setError] = React.useState<any>(null);
    const [limitValue, setLimitValue] = React.useState<number>(limit.limit_value);
    const [startWarningsAt, setStartWarningsAt] = React.useState<number>(limit.start_warnings_at);
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);

    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const editLimit = async () => {
        if (!canSubmit) {
            return;
        }

        setSubmitting(true);
        await agent.OwnCloud.setLimits({
            cloud_provider: limit.cloud_provider,
            regions: [limit.region],
            limit_name: limit.limit_name,
            start_warnings_at: startWarningsAt
        })
        .then((response) => {
            console.log(response);
            setSubmitting(false);
            setError(null);
            // reload page
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
            setError(error?.data?.message || 'An error occurred');
            setSubmitting(false);
        });
    }

    useEffect(() => {
        if (limit.limit_value !== limitValue || limit.start_warnings_at !== startWarningsAt) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [limit.limit_value, limit.start_warnings_at, limitValue, startWarningsAt]);

    return (
        <>
            <DialogTitle>Update Limit</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    {error && (
                        <Alert severity="error">
                            {error}
                        </Alert>
                    )}

                    <TextField
                        variant="standard"
                        label="Start Warnings At"
                        value={startWarningsAt}
                        onChange={(e) => setStartWarningsAt(parseInt(e.target.value))}
                        type="number"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={!canSubmit}
                    onClick={() => {
                        editLimit();
                    }}>
                    Update limit
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmUpdateLimit;
