import { observer } from "mobx-react-lite";
import React from "react";
import { agent, IFileClusterJob, IFileClusterJobs, RootStoreContext } from "api";
import { Box, Chip, Pagination, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { format, intervalToDuration } from "date-fns";
import SearchInput from "components/SearchInput";

type FinishedJobsProps = {
    deployment_id: string;
    pageSize: number;
};

const FinishedJobs: React.FC<FinishedJobsProps> = observer(function FinishedJobs(props) {
    const rootStore = React.useContext(RootStoreContext);
    const { finishedJobsRequest, setFinishedJobsSort, setFinishedJobsOffset, setFinishedJobsSearch } =
        rootStore.fileClusterStore;

    const [jobs, setJobs] = React.useState<IFileClusterJobs>();

    const getJobs = async () => {
        await agent.FileCluster.getJobsByDeployment(
            props.deployment_id,
            finishedJobsRequest.offset,
            props.pageSize,
            finishedJobsRequest.sort,
            finishedJobsRequest.category,
            finishedJobsRequest.source_filename
        ).then((response) => {
            setJobs(response);
        });
    };

    const handleSearch = (searchTerm: string) => {
        setFinishedJobsSearch(searchTerm);
        getJobs();
    };

    const clearSearch = () => {
        setFinishedJobsSearch("");
        getJobs();
    };

    React.useEffect(() => {}, [
        props.deployment_id,
        finishedJobsRequest,
        setFinishedJobsSort,
        setFinishedJobsOffset,
        jobs,
        setFinishedJobsSearch
    ]);

    React.useEffect(() => {
        getJobs();

        const interval = setInterval(() => {
            getJobs();
        }, 5000);
        return () => {
            setFinishedJobsOffset(0);
            setFinishedJobsSort("id:desc");
            clearSearch();
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box className="tab-toolbar">
                <SearchInput
                    name="search"
                    label="Search Source Files..."
                    value={finishedJobsRequest.source_filename}
                    onChange={handleSearch}
                    onClear={clearSearch}
                />
                <Box className="tab-toolbar-actions"></Box>
            </Box>
            <Table>
                <TableHead>
                    <TableCell>
                        <TableSortLabel
                            active={finishedJobsRequest.sort === "name:asc" || finishedJobsRequest.sort === "name:desc"}
                            direction={finishedJobsRequest.sort === "name:asc" ? "asc" : "desc"}
                            onClick={() => {
                                finishedJobsRequest.sort === "name:asc"
                                    ? setFinishedJobsSort("name:desc")
                                    : setFinishedJobsSort("name:asc");
                                getJobs();
                            }}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={finishedJobsRequest.sort === "id:asc" || finishedJobsRequest.sort === "id:desc"}
                            direction={finishedJobsRequest.sort === "id:asc" ? "asc" : "desc"}
                            onClick={() => {
                                finishedJobsRequest.sort === "id:asc" ? setFinishedJobsSort("id:desc") : setFinishedJobsSort("id:asc");
                                getJobs();
                            }}>
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={finishedJobsRequest.sort === "start_time:asc" || finishedJobsRequest.sort === "start_time:desc"}
                            direction={finishedJobsRequest.sort === "start_time:asc" ? "asc" : "desc"}
                            onClick={() => {
                                finishedJobsRequest.sort === "start_time:asc"
                                    ? setFinishedJobsSort("start_time:desc")
                                    : setFinishedJobsSort("start_time:asc");
                                getJobs();
                            }}>
                            Start Time
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={finishedJobsRequest.sort === "end_time:asc" || finishedJobsRequest.sort === "end_time:desc"}
                            direction={finishedJobsRequest.sort === "end_time:asc" ? "asc" : "desc"}
                            onClick={() => {
                                finishedJobsRequest.sort === "end_time:asc"
                                    ? setFinishedJobsSort("end_time:desc")
                                    : setFinishedJobsSort("end_time:asc");
                                getJobs();
                            }}>
                            End Time
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Active Duration</TableCell>
                    <TableCell>Cost</TableCell>
                </TableHead>
                <TableBody>
                    {jobs?.data.map((job: IFileClusterJob, i: number) => (
                        <TableRow key={i}>
                            <TableCell>
                                <div style={{ wordBreak: "break-all" }}>{job.name}</div>
                                <div
                                    style={{
                                        fontSize: "10px",
                                        opacity: "0.7"
                                    }}>
                                    Source: {job.source_filename}
                                </div>
                            </TableCell>
                            <TableCell>{job.id}</TableCell>
                            <TableCell>{job.start_time ? format(new Date(job.start_time), "dd/MM/yyyy @ HH:mm") : null}</TableCell>
                            <TableCell>{job.end_time ? format(new Date(job.end_time), "dd/MM/yyyy @ HH:mm") : null}</TableCell>
                            <TableCell>
                                <Chip
                                    label={job.status}
                                    size="small"
                                    style={{
                                        textTransform: "capitalize"
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                {(intervalToDuration({ start: 0, end: job.active_duration * 1000 }).hours || 0) +
                                    "h " +
                                    (intervalToDuration({ start: 0, end: job.active_duration * 1000 }).minutes || 0) +
                                    "m " +
                                    (intervalToDuration({ start: 0, end: job.active_duration * 1000 }).seconds || 0) +
                                    "s"}
                            </TableCell>
                            <TableCell>
                                {job.currency === "gbp" ? "£" : ""}
                                {job.currency === "eur" ? "€" : ""}
                                {job.currency === "usd" ? "$" : ""}
                                {job.cost_calculated ? job.cost_calculated : ""}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {jobs && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={9}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <Pagination
                                        shape="rounded"
                                        count={Math.ceil(jobs?.totals.finished / props.pageSize)}
                                        page={finishedJobsRequest.offset / props.pageSize + 1}
                                        onChange={(event: any, page: number) => {
                                            setFinishedJobsOffset((page - 1) * props.pageSize);
                                            getJobs();
                                        }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
});

export default FinishedJobs;
