import { MenuItem, TextField } from "@mui/material";
import { RootStoreContext } from "api";
import { useOrganisation } from "hooks";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

const ManagingOrganisationSwitch: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, getUser } = rootStore.userStore;
    const { managingOrgList } = rootStore.organisationStore;
    const { getManagingOrgList, setManagingOrganisation } = useOrganisation();

    useEffect(() => {
        if (!managingOrgList) {
            getManagingOrgList();
        }
        console.log("managingOrgList", managingOrgList);
    }, [getManagingOrgList, managingOrgList]);

    useEffect(() => {
        console.log("user", user);
    }, [user]);

    if (!managingOrgList) {
        return null;
    }

    return (
        <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="organisation_id"
            placeholder="Organisation"
            label="Managing Organisation"
            select
            sx={{ mb: 0 }}
            value={user?.managing_organisation_id || 0}
            onChange={(e) => {
                setManagingOrganisation({ organisation_id: Number(e.target.value) });
                getUser();
            }}>
            {managingOrgList &&
                managingOrgList?.data.map((orgList) => (
                    <MenuItem key={orgList.id} value={orgList.id}>
                        {orgList.name}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default observer(ManagingOrganisationSwitch);
