import {
    Avatar,
    Card,
    CardHeader,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    CardActionArea,
    CardActions,
    CardContent,
    Typography,
    Tooltip,
    Box,
    Skeleton,
    Chip,
    Stack,
    Grid,
    DialogTitle,
    DialogContent,
    Icon,
    Alert,
    CircularProgress,
    Button,
    DialogActions
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import React from "react";
import SourceThumbnail from "./SourceThumbnail";
import ResClient, { ResCollection, ResModel } from "resclient";
import SourceDetails from "./SourceDetails";
import useOnScreen from "../../../hooks/UseOnScreen";
import GridViewIcon from "@mui/icons-material/GridView";
import MVTilesEditForm from "../../channels/createMultiChanl/MVTilesEditForm";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { agent, RootStoreContext } from "api";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import MultiChannelConfigEditForm from "../../channels/createMultiChanl/MultiChannelConfigEditForm";
import { MVAudioSwitch } from "../../channels/createMultiChanl/MVAudioSwitch";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ConfirmStartTranscoder } from "../../channels/createTranscoder/ConfirmStartTranscoder";
import StopIcon from "@mui/icons-material/Stop";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ConfirmStopTranscoder } from "../../channels/createTranscoder/ConfirmStopTranscoder";
import { ConfirmRestartDialogue } from "../../channels/restartTranscoder/ConfirmRestartDialogue";
import UpdateTranscoderAudioTracks from "../../channels/updateTranscoder/UpdateTranscoderAudioTracks";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import RoleProvider from "components/RoleProvider";
import CreateMultiScheduleForm from "features/scheduler/Components/CreateMultiScheduleForm";
import { useNavigate } from "react-router-dom";
import useSource from "../useSource";
import { Difference } from "@mui/icons-material";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useSources } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    //source: IInput;
    //onClick: () => void;
    rid: string;
    client: ResClient;
    display_status: string;
    display_type: string;
    display_alert_status: string;
}

const SourceCard: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    //const { client } = resClientStore;
    const ref = React.useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const [stateVisible, setStateVisible] = React.useState<boolean>(false);

    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { restartStoppedStream, deleteTranscoder } = useSources();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [wsData, setWsData] = React.useState<ResModel | ResCollection | any>({});

    const [loading, setLoading] = React.useState<boolean>(true);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const [type, setType] = React.useState<string>("source");

    const onUpdate = () => {
        setUpdateDate(new Date());
        setLoading(false);
    };

    const calculateIsAlerting = () => {
        return (
            wsData.active_alerts > 0 ||
            (wsData.is_analysis_on &&
                ((wsData.raise_p1_alert && wsData.p1_ok === false) || (wsData.raise_p2_alert && wsData.p2_ok === false)))
        );
    };

    const calculateIsHidden = () => {
        if (
            (props.display_status === "all" || props.display_status.includes(wsData.connection_status)) &&
            (props.display_type === "all" || props.display_type.includes(type)) &&
            (props.display_alert_status === "all" || calculateIsAlerting())
        ) {
            return false;
        } else {
            return true;
        }
    };

    const { getTypeText, getStatusText, getStatusColor, getStatusIcon } = useSource(wsData);

    React.useEffect(() => {
        setLoading(true);

        let data: any;

        //!wsData.connection_status &&
        (stateVisible || props.display_status !== "all" || props.display_type !== "all" || props.display_alert_status !== "all") &&
            props.rid &&
            props.client.get(props.rid).then((res: ResModel | ResCollection | any) => {
                data = res;

                if (data.transcoder_id) {
                    setType("transcoder");
                } else if (data.multiviewer_id) {
                    setType("multiviewer");
                } else {
                    setType("source");
                }

                data.on("change", () => {
                    onUpdate();
                });
                setWsData(data);
                onUpdate();
            });

        setLoading(false);
        onUpdate();

        return () => {
            setLoading(false);
            setWsData({});
        };
    }, [props.client, props.rid, stateVisible, props.display_status]);

    React.useEffect(() => {}, [updateDate]);

    React.useEffect(() => {
        if (isVisible) {
            setStateVisible(true);
        }
    }, [isVisible]);

    if (props.display_type === "all" && type === "multiviewer") {
        return <></>;
    }

    return (
        <Grid
            item
            xs={2}
            sx={{
                display: calculateIsHidden() ? "none" : undefined
            }}>
            <Card
                ref={ref}
                sx={{
                    backgroundColor: wsData.is_input_cutout || calculateIsAlerting() ? theme.palette.error.dark : undefined
                }}>
                <CardHeader
                    title={
                        loading === false && wsData.connection_status ? (
                            <span
                                style={{
                                    display: "block",
                                    width: "100%",
                                    textTransform: "capitalize",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>
                                {getStatusText()}
                            </span>
                        ) : (
                            <Skeleton>
                                <span>Connection Status</span>
                            </Skeleton>
                        )
                    }
                    subheader={
                        loading === false && wsData.type ? (
                            <span style={{ textTransform: "capitalize" }}>{getTypeText()}</span>
                        ) : (
                            <Skeleton>
                                <span>Source Type</span>
                            </Skeleton>
                        )
                    }
                    avatar={
                        loading === false ? (
                            <Avatar
                                sx={{
                                    bgcolor: getStatusColor(),
                                    color: "#fff"
                                }}
                                title={getStatusText()}
                                aria-label="recipe">
                                <Icon component={getStatusIcon()} />
                            </Avatar>
                        ) : (
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                        )
                    }
                    action={
                        <>
                            {loading === false ? (
                                <>
                                    <IconButton
                                        aria-label="settings"
                                        id="demo-customized-button"
                                        aria-controls={open ? "demo-customized-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={(e) => handleClick(e)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "demo-customized-button"
                                        }}
                                        sx={{ minWidth: 300 }}
                                        anchorEl={anchorEl}
                                        elevation={0}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right"
                                        }}
                                        open={open}
                                        onClose={handleClose}>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.is_input_cutout && (
                                                <MenuItem
                                                    onClick={() => {
                                                        restartStoppedStream(wsData.id);
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <RefreshIcon />
                                                    </ListItemIcon>
                                                    Restart Source
                                                </MenuItem>
                                            )}
                                        </RoleProvider>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {!wsData.multiviewer_id && !wsData.transcoder_id && (
                                                <MenuItem
                                                    onClick={() => {
                                                        navigate("/sources/edit/" + wsData.id);
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <EditIcon />
                                                    </ListItemIcon>
                                                    Edit Source
                                                </MenuItem>
                                            )}
                                        </RoleProvider>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {!wsData.multiviewer_id && !wsData.transcoder_id && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Delete Source"
                                                                message={`Are you sure you want to delete source "${wsData.name}"?`}
                                                                confirmButtonText="Delete Source"
                                                                confirmButtonColor="error"
                                                                additionalContent={
                                                                    <Alert severity="warning" sx={{ mt: 2 }}>
                                                                        Deleting this source will remove it for all users and cannot be
                                                                        undone.
                                                                    </Alert>
                                                                }
                                                                onConfirm={async () => {
                                                                    await agent.Channel.deleteInput(wsData.id);
                                                                }}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disabled={wsData.transcoder_count > 0}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <DeleteIcon />
                                                    </ListItemIcon>
                                                    Delete Source
                                                </MenuItem>
                                            )}
                                        </RoleProvider>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {!wsData.multiviewer_id && !wsData.transcoder_id && wsData.connection_status === "stopped" && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Start Source"
                                                                message="Are you sure you want to start this source?"
                                                                onConfirm={async () => {
                                                                    return agent.Channel.startInput(wsData.id);
                                                                }}
                                                                onSuccess={() => {
                                                                    openDialog(
                                                                        <>
                                                                            <DialogTitle>Success!</DialogTitle>
                                                                            <DialogContent>
                                                                                <Typography>
                                                                                    The source was successfully started.
                                                                                </Typography>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        closeDialog();
                                                                                    }}>
                                                                                    Close
                                                                                </Button>
                                                                            </DialogActions>
                                                                        </>
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <PlayArrowIcon />
                                                    </ListItemIcon>
                                                    Start Source
                                                </MenuItem>
                                            )}
                                        </RoleProvider>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {!wsData.multiviewer_id &&
                                                !wsData.transcoder_id &&
                                                wsData.connection_status !== "stopped" &&
                                                !wsData.is_input_cutout && (
                                                    <MenuItem
                                                        onClick={() => {
                                                            openDialog(
                                                                <ConfirmationDialog
                                                                    title="Stop Source"
                                                                    message="Are you sure you want to stop this source?"
                                                                    onConfirm={async () => {
                                                                        return agent.Channel.stopInput(wsData.id);
                                                                    }}
                                                                    onSuccess={() => {
                                                                        openDialog(
                                                                            <>
                                                                                <DialogTitle>Success!</DialogTitle>
                                                                                <DialogContent>
                                                                                    <Typography>
                                                                                        The source was successfully stopped.
                                                                                    </Typography>
                                                                                </DialogContent>
                                                                                <DialogActions>
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            closeDialog();
                                                                                        }}>
                                                                                        Close
                                                                                    </Button>
                                                                                </DialogActions>
                                                                            </>
                                                                        );
                                                                    }}
                                                                />
                                                            );
                                                            handleClose();
                                                        }}
                                                        disableRipple>
                                                        <ListItemIcon>
                                                            <StopIcon />
                                                        </ListItemIcon>
                                                        Stop Source
                                                    </MenuItem>
                                                )}
                                        </RoleProvider>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {!wsData.multiviewer_id && !wsData.transcoder_id && !wsData.is_input_cutout && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Restart Source"
                                                                message="Are you sure you want to restart this source?"
                                                                onConfirm={async () => {
                                                                    return agent.Channel.restartInput(wsData.id);
                                                                }}
                                                                onSuccess={() => {
                                                                    openDialog(
                                                                        <>
                                                                            <DialogTitle>Success!</DialogTitle>
                                                                            <DialogContent>
                                                                                <Typography>
                                                                                    The source was successfully restarted.
                                                                                </Typography>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        closeDialog();
                                                                                    }}>
                                                                                    Close
                                                                                </Button>
                                                                            </DialogActions>
                                                                        </>
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <RefreshIcon />
                                                    </ListItemIcon>
                                                    Restart Source
                                                </MenuItem>
                                            )}
                                        </RoleProvider>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.multiviewer_id && wsData.multiviewer_status === "stopped" && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Start Multiviewer"
                                                                message="Are you sure you want to start this multiviewer?"
                                                                onConfirm={async () => {
                                                                    return agent
                                                                        .getCleanInstance()
                                                                        .put(`/multi-viewers/${wsData.multiviewer_id}/start`);
                                                                }}
                                                                onSuccess={() => {
                                                                    openDialog(
                                                                        <>
                                                                            <DialogTitle>Success!</DialogTitle>
                                                                            <DialogContent>
                                                                                <Typography>
                                                                                    The multiviewer is now starting, please wait.
                                                                                </Typography>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        closeDialog();
                                                                                    }}>
                                                                                    Close
                                                                                </Button>
                                                                            </DialogActions>
                                                                        </>
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <PlayArrowIcon />
                                                    </ListItemIcon>
                                                    Start Multiviewer
                                                </MenuItem>
                                            )}
                                        </RoleProvider>
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.multiviewer_id && wsData.multiviewer_status === "running" && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Stop Multiviewer"
                                                                message="Are you sure you want to stop this multiviewer?"
                                                                onConfirm={async () => {
                                                                    return agent
                                                                        .getCleanInstance()
                                                                        .put(`/multi-viewers/${wsData.multiviewer_id}/stop`);
                                                                }}
                                                                onSuccess={() => {
                                                                    openDialog(
                                                                        <>
                                                                            <DialogTitle>Success!</DialogTitle>
                                                                            <DialogContent>
                                                                                <Typography>
                                                                                    The multiviewer is now stopping, please wait.
                                                                                </Typography>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        closeDialog();
                                                                                    }}>
                                                                                    Close
                                                                                </Button>
                                                                            </DialogActions>
                                                                        </>
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <StopIcon />
                                                    </ListItemIcon>
                                                    Stop Multiviewer
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.multiviewer_id && (
                                                <MenuItem
                                                    disabled={wsData.multiviewer_status !== "running"}
                                                    onClick={() => {
                                                        openDrawer(
                                                            <Box sx={{ width: 680, height: "100%", padding: 3 }}>
                                                                <MultiChannelConfigEditForm id={wsData.multiviewer_id} name={wsData.name} />
                                                            </Box>
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <EditIcon />
                                                    </ListItemIcon>
                                                    Edit Multiviewer
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.multiviewer_id && (
                                                <MenuItem
                                                    disabled={wsData.multiviewer_status !== "running"}
                                                    onClick={() => {
                                                        openDrawer(
                                                            <Box sx={{ width: 680, height: "100%", padding: 3 }}>
                                                                <MVTilesEditForm id={wsData.multiviewer_id} name={wsData.name} />
                                                            </Box>
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <GridViewIcon />
                                                        {/* <FontAwesomeIcon icon={["fal", "grid-2"]} /> */}
                                                    </ListItemIcon>
                                                    Edit Multiview Tiles
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.multiviewer_id && (
                                                <MenuItem
                                                    disabled={wsData.multiviewer_status !== "running"}
                                                    onClick={() => {
                                                        openDrawer(
                                                            <Box sx={{ width: 800, height: "100%", padding: 3 }}>
                                                                <MVAudioSwitch name={wsData.name} id={wsData.multiviewer_id} />
                                                            </Box>
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <VolumeUpIcon />
                                                    </ListItemIcon>
                                                    Switch Audio Stream
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.multiviewer_id && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Confirm Delete Multiviewer"
                                                                message=""
                                                                additionalContent={
                                                                    <>
                                                                        <Typography>
                                                                            Are you sure you want to delete multiviewer "{wsData.name}" ?
                                                                            <br />
                                                                            Deletion can not be un-done and it will be removed from all
                                                                            users.
                                                                        </Typography>
                                                                        <Alert severity="warning" sx={{ mt: 2 }}>
                                                                            Deleting this multiviewer will remove it for all users and
                                                                            cannot be undone.
                                                                        </Alert>
                                                                    </>
                                                                }
                                                                onConfirm={async () => {
                                                                    return agent
                                                                        .getCleanInstance()
                                                                        .delete(`/multi-viewers/${wsData.multiviewer_id}`);
                                                                }}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disabled={
                                                        wsData.multiviewer_status === "stopping" || wsData.multiviewer_status === "creating"
                                                    }
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <DeleteIcon />
                                                    </ListItemIcon>
                                                    Delete Multiviewer
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.transcoder_id && wsData.transcoder_status === "stopped" && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmStartTranscoder
                                                                transcoder_id={wsData.transcoder_id}
                                                                transcoder_name={wsData.name}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <PlayArrowIcon />
                                                    </ListItemIcon>
                                                    Start Transcoder
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.transcoder_id && wsData.transcoder_status === "running" && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmStopTranscoder
                                                                transcoder_id={wsData.transcoder_id}
                                                                transcoder_name={wsData.name}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <StopIcon />
                                                    </ListItemIcon>
                                                    Stop Transcoder
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.transcoder_id && wsData.transcoder_status === "running" && (
                                                <MenuItem
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmRestartDialogue
                                                                name={wsData.name}
                                                                transcoder_id={wsData.transcoder_id}
                                                            />
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <RefreshIcon />
                                                    </ListItemIcon>
                                                    Restart Transcoder
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.transcoder_id && wsData.is_motion_interpolation_framerate_converter && (
                                                <MenuItem
                                                    disabled={wsData.transcoder_status !== "running"}
                                                    onClick={() => {
                                                        navigate(`/transcoders/edit-mi/${wsData.transcoder_id}`);
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <EditIcon />
                                                    </ListItemIcon>
                                                    Edit MIFC Transcoder
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.transcoder_id && !wsData.is_motion_interpolation_framerate_converter && (
                                                <MenuItem
                                                    disabled={wsData.transcoder_status !== "running"}
                                                    onClick={() => {
                                                        navigate(`/transcoders/edit/${wsData.transcoder_id}`);
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <EditIcon />
                                                    </ListItemIcon>
                                                    Edit Transcoder
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.transcoder_id && (
                                                <MenuItem
                                                    disabled={wsData.transcoder_status !== "running"}
                                                    onClick={() => {
                                                        openDrawer(
                                                            <Box sx={{ width: 500, height: "100%", padding: 3 }}>
                                                                <UpdateTranscoderAudioTracks transcoder_id={wsData.transcoder_id} />
                                                            </Box>
                                                        );
                                                        handleClose();
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <QueueMusicIcon />
                                                    </ListItemIcon>
                                                    Edit Audio Tracks
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {wsData.transcoder_id &&
                                                (wsData.transcoder_status === "running" || wsData.transcoder_status === "stopped") && (
                                                    <MenuItem
                                                        onClick={() => {
                                                            openDialog(
                                                                <ConfirmationDialog
                                                                    title="Delete Transcoder"
                                                                    message={`Are you sure you want to delete transcoder "${wsData.name}"?`}
                                                                    confirmButtonText="Delete Transcoder"
                                                                    confirmButtonColor="error"
                                                                    additionalContent={
                                                                        <Alert severity="warning" sx={{ mt: 2 }}>
                                                                            Deleting this transcoder will remove it for all users and cannot
                                                                            be undone.
                                                                        </Alert>
                                                                    }
                                                                    onConfirm={async () => {
                                                                        await deleteTranscoder(wsData.transcoder_id);
                                                                    }}
                                                                />
                                                            );
                                                            handleClose();
                                                        }}
                                                        disableRipple>
                                                        <ListItemIcon>
                                                            <DeleteIcon />
                                                        </ListItemIcon>
                                                        Delete Transcoder
                                                    </MenuItem>
                                                )}
                                        </RoleProvider>

                                        <Divider sx={{ my: 0.5 }} />
                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {!wsData.transcoder_id && !wsData.multiviewer_id && (
                                                <MenuItem
                                                    onClick={() => {
                                                        navigate(`/transcoders/create/${wsData.id}`);
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <SwapHorizontalCircleIcon />
                                                    </ListItemIcon>
                                                    Create Transcoder
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            {!wsData.transcoder_id && !wsData.multiviewer_id && (
                                                <MenuItem
                                                    onClick={() => {
                                                        navigate(`/transcoders/create-mi/${wsData.id}`);
                                                    }}
                                                    disableRipple>
                                                    <ListItemIcon>
                                                        <SwapHorizontalCircleIcon />
                                                    </ListItemIcon>
                                                    Create MIFC Transcoder
                                                </MenuItem>
                                            )}
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            <MenuItem
                                                onClick={() => {
                                                    openDrawer(
                                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                            <SourceDetails item_id={wsData.id} tab="3" />
                                                        </Box>
                                                    );
                                                    handleClose();
                                                }}
                                                disableRipple>
                                                <ListItemIcon>
                                                    <NotificationsIcon />
                                                </ListItemIcon>
                                                Create Alert
                                            </MenuItem>
                                        </RoleProvider>

                                        <MenuItem
                                            onClick={() => {
                                                openDialog(
                                                    <CreateMultiScheduleForm source_id={wsData.id} />,
                                                    "Create Schedule",
                                                    "lg",
                                                    true
                                                );
                                                handleClose();
                                            }}
                                            disableRipple>
                                            <ListItemIcon>
                                                <EventIcon />
                                            </ListItemIcon>
                                            Schedule Source
                                        </MenuItem>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            <MenuItem
                                                onClick={() => {
                                                    navigate(`/sources/duplicate/${wsData.id}`);
                                                }}
                                                disableRipple>
                                                <ListItemIcon>
                                                    <Difference />
                                                </ListItemIcon>
                                                Duplicate Source
                                            </MenuItem>
                                        </RoleProvider>

                                        <RoleProvider roles={["is_allowed_to_see_settings"]}>
                                            <MenuItem
                                                onClick={() => {
                                                    openDrawer(
                                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                            <SourceDetails item_id={wsData.id} />
                                                        </Box>
                                                    );
                                                    handleClose();
                                                }}
                                                disableRipple>
                                                <ListItemIcon>
                                                    <MoreHorizIcon />
                                                </ListItemIcon>
                                                More
                                            </MenuItem>
                                        </RoleProvider>
                                    </Menu>
                                </>
                            ) : null}
                        </>
                    }
                />

                <CardActionArea
                    sx={
                        wsData.is_motion_interpolation_framerate_converter
                            ? {
                                  borderRadius: 0,
                                  backgroundColor: wsData.transcoder_id ? theme.palette.septenary?.main : undefined
                              }
                            : {
                                  borderRadius: 0,
                                  backgroundColor: wsData.transcoder_id
                                      ? theme.palette.primary.dark
                                      : wsData.multiviewer_id
                                      ? theme.palette.secondary.dark
                                      : undefined
                              }
                    }
                    onClick={() =>
                        openDrawer(
                            <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                <SourceDetails item_id={wsData.id} />
                            </Box>
                        )
                    }>
                    {!wsData.multiviewer_id && (
                        <CardContent sx={{ padding: 0, backgroundColor: theme.palette.background.default }}>
                            {loading === false &&
                            isVisible &&
                            wsData.id &&
                            (wsData.connection_status === "connected" || wsData.connection_status === "no-thumb-avail") ? (
                                <SourceThumbnail item_id={wsData.id} enableFullScreen />
                            ) : (
                                <Skeleton
                                    variant="rectangular"
                                    animation={loading === true ? "wave" : false}
                                    width="100%"
                                    sx={{ paddingBottom: "56.25%" }}
                                />
                            )}
                        </CardContent>
                    )}
                    {wsData.multiviewer_id && (
                        <CardContent sx={{ padding: 0, backgroundColor: theme.palette.background.default }}>
                            {loading === false && isVisible && wsData.id && wsData.multiviewer_status === "running" ? (
                                <SourceThumbnail item_id={wsData.id} enableFullScreen multiviewerId={wsData.multiviewer_id} />
                            ) : (
                                <Skeleton
                                    variant="rectangular"
                                    animation={loading === true ? "wave" : false}
                                    width="100%"
                                    sx={{ paddingBottom: "56.25%" }}
                                />
                            )}
                        </CardContent>
                    )}
                    <CardContent
                        sx={{
                            paddingY: wsData.transcoder_input_name ? "11.5px" : undefined,
                            //if transcoder or multiviewer, make text theme.pallette.primary.contrastText
                            color: wsData.transcoder_id || wsData.multiviewer_id ? theme.palette.primary.contrastText : undefined
                        }}>
                        {loading === false && wsData.name ? (
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    marginBottom: wsData.transcoder_input_name ? -1 : undefined
                                }}>
                                {wsData.name}
                            </Typography>
                        ) : (
                            <Skeleton>
                                <Typography variant="h6" component="div">
                                    Card Title Source Name
                                </Typography>
                            </Skeleton>
                        )}
                        {wsData.transcoder_input_name &&
                        (wsData?.transcoder_progress_status === "Complete" ||
                            wsData?.transcoder_progress_status === "Stopped" ||
                            wsData?.transcoder_progress_status === "Running") ? (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                component="div"
                                sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                Source: {wsData.transcoder_input_name}
                            </Typography>
                        ) : null}
                        {wsData.transcoder_input_name &&
                        wsData?.transcoder_progress_status !== "Complete" &&
                        wsData?.transcoder_progress_status !== "Stopped" &&
                        wsData?.transcoder_progress_status !== "Running" ? (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                component="div"
                                sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                {wsData?.transcoder_progress_status !== "Stopped" && <CircularProgress size={8} sx={{ mr: 0.5 }} />}
                                {wsData?.transcoder_progress_status}...
                            </Typography>
                        ) : null}
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {loading === false && wsData.id ? (
                        <Stack direction="row" spacing={1}>
                            <Tooltip title="Active Alerts" placement="top">
                                <Chip
                                    size="small"
                                    icon={<NotificationsActiveIcon />}
                                    label={wsData.active_alerts}
                                    className={wsData.active_alerts > 0 ? "pulse" : undefined}
                                    onClick={() =>
                                        openDrawer(
                                            <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                <SourceDetails item_id={wsData.id} tab="3" />
                                            </Box>
                                        )
                                    }
                                />
                            </Tooltip>
                            {wsData.is_input_cutout && (
                                <Tooltip title="Source Stopped" placement="top">
                                    <Chip
                                        size="small"
                                        icon={<VideocamOffIcon />}
                                        className={"pulse"}
                                        label="D/C"
                                        onClick={() =>
                                            openDrawer(
                                                <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                    <SourceDetails item_id={wsData.id} tab="3" />
                                                </Box>
                                            )
                                        }
                                    />
                                </Tooltip>
                            )}
                            {wsData.is_analysis_on && (
                                <Tooltip title="Analysis" placement="top">
                                    <Chip
                                        size="small"
                                        icon={<TroubleshootIcon />}
                                        className={
                                            (wsData.raise_p1_alert && wsData.p1_ok === false) ||
                                            (wsData.raise_p2_alert && wsData.p2_ok === false)
                                                ? "pulse"
                                                : undefined
                                        }
                                        label={
                                            <div>
                                                {wsData.raise_p1_alert && wsData.p1_ok === false && <span>P1</span>}
                                                {wsData.raise_p1_alert &&
                                                    wsData.raise_p2_alert &&
                                                    wsData.p1_ok === false &&
                                                    wsData.p2_ok === false && <span>&nbsp;|&nbsp;</span>}
                                                {wsData.raise_p2_alert && wsData.p2_ok === false && <span>P2</span>}
                                            </div>
                                        }
                                        onClick={() =>
                                            openDrawer(
                                                <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                    <SourceDetails item_id={wsData.id} tab="2" />
                                                </Box>
                                            )
                                        }
                                    />
                                </Tooltip>
                            )}
                            <Tooltip title="Transcoders" placement="top">
                                <Chip
                                    size="small"
                                    icon={<SwapHorizIcon />}
                                    label={wsData.transcoder_count}
                                    onClick={() =>
                                        openDrawer(
                                            <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                <SourceDetails item_id={wsData.id} tab="4" />
                                            </Box>
                                        )
                                    }
                                />
                            </Tooltip>
                            <Tooltip title="Scheduled | Permanent" placement="top">
                                <Chip
                                    size="small"
                                    icon={<EventIcon />}
                                    label={wsData.scheduled_to_destinations + " | " + wsData.permanently_scheduled_to_destinations}
                                    onClick={() => {
                                        navigate("/scheduler");
                                    }}
                                />
                            </Tooltip>
                        </Stack>
                    ) : (
                        <Skeleton>
                            <Stack direction="row" spacing={1}>
                                <Chip size="small" icon={<EventIcon />} label="loading" />
                            </Stack>
                        </Skeleton>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
};

export default SourceCard;
