import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { RootStoreContext } from "api";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { usePermanentSchedule } from "hooks";

export const AgreementConfirmed = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { getPermanentScheduleCommitments } = usePermanentSchedule();

    return (
        <>
            <DialogTitle>Agreement Confirmed</DialogTitle>
            <DialogContent>
                Your agreement has now been confirmed and will appear in the active commitments table in the settings menu Billing -
                Permanent Commitement. When the duration of your commitment is completed it will appear in the expired commitments section
                on the same page.
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                        getPermanentScheduleCommitments();
                    }}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};
