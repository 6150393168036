import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { IEnvironmentsData, IEnvironmentUpgradeTypes, RootStoreContext } from "api";
import { toJS } from "mobx";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import EnvironmentSelectProvider from "./EnvironmentSelectProvider";
import { ConfirmEnvironmentResizeNow } from "./ConfirmEnvironmentResizeNow";
import { ConfirmEnvironmentScheduleResize } from "./ConfirmEnvironmentScheduleResize";
import { observer } from "mobx-react-lite";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Alert, Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEnvironment } from "hooks";

interface ParentCompProps {
    environment: IEnvironmentsData;
    body: any;
    size?: number;
}

export const EnvironmentResize: React.FC<ParentCompProps> = observer(function EnvironmentResize(props) {
    const naviagte = useNavigate();
    const rootStore = useContext(RootStoreContext);
    const { setEnvironmentUpgradeDate } = rootStore.schedulerStore;
    const { sizeUpgradeInfo, sizes } = rootStore.environmentStore;
    const { setUpgradeEnvironmentData, getEnvironmentUpgradeSizes, getEnviromentUpgradeData } = useEnvironment();
    const { changeDialogBody, closeDialog, openDialog } = rootStore.dialogStore;

    const { envUpgradeOptions } = rootStore.environmentStore;
    const [radioSelected, setRadioSelected] = useState("now");
    const [upgradeDate, setUpgradeDate] = useState(new Date().toISOString());
    const [array, setArray] = useState<IEnvironmentUpgradeTypes[]>();
    const { environment, body, size } = props;
    const [Size, setSize] = useState(size ? size : environment.sched_upgrade_product_id);
    const [SizeName, setSizeName] = useState("");

    useEffect(() => {
        setUpgradeEnvironmentData(environment);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioSelected((event.target as HTMLInputElement).value);
    };

    let datePicker: JSX.Element | null;

    if (radioSelected === "schedule") {
        datePicker = (
            <Box
                sx={{
                    p: 2,
                    backgroundColor: (theme) => theme.palette.action.hover,
                    borderRadius: 1,
                    mt: 1
                }}>
                <DateTimePicker
                    label="Start Date and Time"
                    value={new Date(upgradeDate)}
                    onChange={(newValue: any) => setUpgradeDate(newValue?.toString())}
                    format="dd/MM/yyyy HH:mm"
                />
            </Box>
        );
    } else {
        datePicker = null;
    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (radioSelected === "schedule" && !!upgradeDate) {
            setEnvironmentUpgradeDate(upgradeDate);

            const confirmbody = {
                product_id: Size,
                region: body.region,
                upgrade_now: false,
                upgrade_start_time: new Date(upgradeDate).toISOString()
            };

            if (environment) {
                closeDialog();
                openDialog(
                    <ConfirmEnvironmentScheduleResize
                        environmentupgradeOptions={toJS(envUpgradeOptions)}
                        environment={environment}
                        upgradeData={upgradeDate}
                        body={confirmbody}
                        size={SizeName}
                    />
                );
            }
            return;
        }

        if (radioSelected === "now") {
            const confirmbody = {
                product_id: Size,
                region: body.region,
                upgrade_now: true
            };
            if (environment) {
                closeDialog();
                openDialog(
                    <ConfirmEnvironmentResizeNow
                        environmentupgradeOptions={toJS(envUpgradeOptions)}
                        environment={environment}
                        body={confirmbody}
                        size={SizeName}
                    />
                );
            }
            return;
        }
    };

    useEffect(() => {
        getEnviromentUpgradeData().then(() => {
            getEnvironmentUpgradeSizes(environment.id)
                .then((value) => {
                    if (value) {
                        setArray(value);
                    }
                })
                .catch((error) => console.log(error));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formOptions = array?.map((option) => ({
        ...option,
        key: option.product_id,
        text: option.name,
        value: option.product_id
    }));

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSize(event.target.value as number);
    };
    const handleSizeName = () => {
        setSizeName(document.getElementById("sizeselector")?.innerText!);
    };

    const notification =
        // eslint-disable-next-line max-len
        "Note, resizing will either increase or decrease the size of this environment depending on the size selected. The resize process can take a few minutes to complete and is likely to cause service disruption, you will be notified once complete.";

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Schedule Environment Resize</DialogTitle>
                    <DialogContent>
                        <FormControl variant="standard" component="fieldset" sx={{ width: "100%" }}>
                            <Alert severity="warning" sx={{ m: 2, mt: 0 }}>
                                {notification}
                            </Alert>
                            <Box sx={{ mx: 2 }}>
                                <FormControl variant="standard">
                                    <InputLabel>Select size</InputLabel>
                                    <Select
                                        variant="standard"
                                        id="sizeselector"
                                        value={Size}
                                        onChange={(event: any) => handleSelectChange(event)}
                                        onBlur={handleSizeName}>
                                        {sizes
                                            ? sizes?.map((form) => (
                                                  <MenuItem key={form.product_id} value={form.product_id}>
                                                      {form.name}
                                                  </MenuItem>
                                              ))
                                            : formOptions?.map((form) => (
                                                  <MenuItem key={form.product_id} value={form.product_id}>
                                                      {form.name}
                                                  </MenuItem>
                                              ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {sizeUpgradeInfo?.organisation_payment_method === "free_credits" && (
                                <Box
                                    sx={{
                                        margin: 4,
                                        background: "#1e2832",
                                        color: "#ffffff",
                                        alignItems: "center",
                                        textAlign: "justify",
                                        verticalAlign: "middle",
                                        padding: "0.8rem",
                                        cursor: "default"
                                    }}>
                                    You are using free credits, to select a bigger size enviroment please navigate to the{" "}
                                    <a
                                        href="/settings/billing"
                                        onClick={() => {
                                            closeDialog();
                                            naviagte("/settings/billing");
                                        }}
                                        style={{ cursor: "pointer" }}>
                                        billing
                                    </a>{" "}
                                    tab under settings to purchace credits or apply for your organisation to pay on account
                                </Box>
                            )}
                            <RadioGroup aria-label="upgrade" name="schedule upgrade" value={radioSelected} onChange={handleChange}>
                                <Box
                                    sx={{
                                        p: 2,
                                        backgroundColor: (theme) => theme.palette.action.hover,
                                        borderRadius: 1,
                                        mt: 3
                                    }}>
                                    <FormControlLabel value="now" control={<Radio />} label="Resize now" />
                                </Box>
                                <Box
                                    sx={{
                                        p: 2,
                                        backgroundColor: (theme) => theme.palette.action.hover,
                                        borderRadius: 1,
                                        mt: 1
                                    }}>
                                    <FormControlLabel value="schedule" control={<Radio />} label="Schedule a date and time" />
                                </Box>
                            </RadioGroup>
                            {datePicker}
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                changeDialogBody(<EnvironmentSelectProvider environment={environment} />);
                            }}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                (environment.sched_upgrade_product_id === Size || Size === environment.sched_upgrade_product_id) &&
                                environment.region === body.region
                            }
                            type="submit">
                            Next
                        </Button>
                    </DialogActions>
                </form>
            </>
        </LocalizationProvider>
    );
});
