import React from "react";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import { Box, Button, FormControl, InputAdornment, List, ListItem, ListItemButton, MenuItem, TextField } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { RootStoreContext } from "api";
import { ResCollection } from "resclient";
import PackagerItem from "./Components/PackagerItem";
import { Search } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import Content from "components/Layout/Content";
import { Outlet, useNavigate } from "react-router-dom";

const Packager: React.FC = () => {
    const navigate = useNavigate();
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;
    const [wsData, setWsData] = React.useState<ResCollection | any>(null);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [inputType, setInputType] = React.useState<string>("all");
    const [cloudProvider, setCloudProvider] = React.useState<string>("all");
    const [filterStatus, setFilterStatus] = React.useState<string>("all");

    React.useEffect(() => {
        clientConnected &&
            client.get(`pushpackagers.${rootStore.userStore.managingOrganisationId!}`).then((res) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });
                setWsData(res);
                //console.log(res);
            });
    }, [client, rootStore.userStore.managingOrganisationId, clientConnected]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <View>
            <ViewHeader title="Push Packagers" />
            <ViewBody noPadding>
                <Content
                    leftWidth={200}
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/packager"}
                                    onClick={() => {
                                        navigate("../packager");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Packagers</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/packager/file-servers"}
                                    onClick={() => {
                                        navigate("file-servers");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>File Servers</Box>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    noPadding>
                    <Outlet />
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(Packager);
