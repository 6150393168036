import React from "react";
import { RootStoreContext } from "api";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import renameCategory from "features/notifications/renameCategory";

const NotificationsMonitor = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const {
        unreadNotificationsLoaded,
        setUnreadNotificationsLoaded,
        setUnreadCriticalNotificationsCount,
        setUnreadNoneCriticalNotificationsCount,
        setUnreadNotificationsCountsLoaded
    } = rootStore.notificationStore;
    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const [gotData, setGotData] = React.useState(false);

    const { client, clientConnected } = rootStore.resClientStore;

    const Container = (props) => <div>{props.children}</div>;

    const triggerToast = (notification: any) => {
        if (user && !user.user_preferences.silent_notifications === true) {
            toast(
                <Container>
                    <Box>
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            {renameCategory(notification.category)}
                        </Typography>
                        <Typography variant="body2">{notification.notification}</Typography>
                    </Box>
                </Container>,
                {
                    autoClose: 5000,
                    hideProgressBar: true,
                    type: notification.is_critical
                        ? "error"
                        : notification.category === "input_alerts" || notification.category === "output_alerts"
                        ? "success"
                        : "info"
                }
            );

            if (user.user_preferences.desktop_notifications === true) {
                if (Notification.permission === "granted") {
                    const bnotification = new Notification(renameCategory(notification.category) + " Notification", {
                        body: notification.notification
                        //icon: require("../../assets/logo/icon-logo.png")
                    });
                }

                if (Notification.permission !== "granted") {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === "granted") {
                            const bnotification = new Notification(renameCategory(notification.category) + " Notification", {
                                body: notification.notification
                            });
                        }
                    });
                }
            }
        }
    };

    const fetchCounts = () => {
        client
            .get(`notificationtotals.${managing_org_id}`)
            .then((res: any) => {
                setUnreadNotificationsCountsLoaded(true);
                setUnreadCriticalNotificationsCount(res.total_critical_unread);
                setUnreadNoneCriticalNotificationsCount(res.total_none_critical_unread);
                res.on("change", () => {
                    setUnreadCriticalNotificationsCount(res.total_critical_unread);
                    setUnreadNoneCriticalNotificationsCount(res.total_none_critical_unread);
                });
            })
            .catch((err) => {
                console.log("Error fetching notification totals", err.message);
            });
    };

    const fetchData = () => {
        user &&
            managing_org_id &&
            client
                .get(`unreadnotifications.${managing_org_id}`)
                .then((res: any) => {
                    console.log("Connected to notifications service");
                    setGotData(true);
                    setUnreadNotificationsLoaded(true);
                    let count = Object.keys(res.toJSON()).length;
                    res.on("change", (data) => {
                        if (Object.keys(res.toJSON()).length > count) {
                            const key = Object.keys(data)[0];
                            client.get(`notifications.${managing_org_id}.notification.${key}`).then((res: any) => {
                                const notification = res.toJSON();

                                triggerToast(notification);
                            });
                        }
                        count = Object.keys(res.toJSON()).length;
                    });
                })
                .catch((err) => {
                    console.log("Error connecting to notifications service", err.message);
                    fetchData();
                });
    };

    React.useEffect(() => {
        user && !clientConnected && rootStore.resClientStore.initialize(rootStore.commonStore.token!);

        //wait until we have user and clientConnected
        //keep trying until we do

        if (user && clientConnected && !gotData && !unreadNotificationsLoaded) {
            setTimeout(() => {
                fetchCounts();
                fetchData();
            }, 500);
        }

        //clientConnected && fetchData();
    }, [clientConnected, managing_org_id, rootStore.commonStore.token]);

    return <></>;
};

export default observer(NotificationsMonitor);
