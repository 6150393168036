import { Box, ButtonBase, IconButton, Tooltip, useTheme } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
    tabs: React.ReactNode;
    panels: React.ReactNode;
    collapsible?: boolean;
    collapsed?: boolean;
    onCollapse?: () => void;
}

export const SidebarTab: React.FC<{ label: string; icon: React.ReactNode; active: boolean; onClick: () => void }> = (props) => {
    const theme = useTheme();
    return (
        <Tooltip title={props.label} placement="left">
        <ButtonBase
            onClick={props.onClick}
            sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: props.active ? theme.palette.action.selected : "transparent",
                mb: 1,
                "&:hover": { backgroundColor: theme.palette.action.hover }
            }}>
            {props.icon}
        </ButtonBase>
        </Tooltip>
    );
};

export const SidebarTabPanel: React.FC<{ active: boolean; children: React.ReactNode }> = (props) => {
    if (!props.active) {
        return null;
    }
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch"
            }}>
            {props.children}
        </Box>
    );
};

const SidebarTabs: React.FC<Props> = (props) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "stretch",
                borderLeft: `1px solid ${theme.palette.divider}`
            }}>
            {!props.collapsed && <Box sx={{ flexGrow: 1, borderRight: `1px solid ${theme.palette.divider}` }}>{props.panels}</Box>}
            <Box
                sx={{
                    width: 50,
                    py: 0.5,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexShrink: 0
                }}>
                <Box>{props.tabs}</Box>
                {props.collapsible && (
                    <Box
                        sx={{
                            width: 40,
                            height: 40,
                            borderRadius: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 1
                        }}>
                        <IconButton
                            onClick={() => {
                                props.onCollapse && props.onCollapse();
                            }}>
                            <ExpandMoreIcon
                                sx={{
                                    fontSize: 20,
                                    transform: props.collapsed ? "rotate(90deg)" : "rotate(270deg)"
                                }}
                            />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SidebarTabs;
