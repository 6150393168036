import { useState } from "react";
import { agent, IUser, IUserFormValues, IUserPasswordChange } from "api";
import { combineValidators, matchesField } from "revalidate";

interface IPasswordValidationRegex {
    length: {
        regex: RegExp;
        message: string;
    };
    uppercase: {
        regex: RegExp;
        message: string;
    };
    number: {
        regex: RegExp;
        message: string;
    };
    special: {
        regex: RegExp;
        message: string;
    };
}

interface IUsePasswordReset {
    /**
     * An object defining the validation rules for the password.
     */
    passwordValidationRegex: IPasswordValidationRegex;

    /**
     * An object with boolean values controlling the visibility of the password and confirm password fields.
     */
    showPassword: {
        old: boolean;
        password: boolean;
        confirm: boolean;
    };

    /**
     * A function to toggle the visibility of the password and confirm password fields. Takes an id string as a parameter.
     */
    togglePassword: (id: string) => void;

    /**
     * An async function to change the user's password. Takes an object of user form values as a parameter and returns a Promise that resolves with a user object.
     */
    changeUserPassword: (values: IUserPasswordChange) => Promise<IUser>;

    /**
     * An async function to reset the user's password. Takes an object of user form values as a parameter and returns a Promise that resolves with a user object.
     */
    resetPassword: (values: IUserFormValues) => Promise<IUser>;

    /**
     * A function to validate the password and confirm password fields.
     */
    validate: ReturnType<typeof combineValidators>;
}

/**
 * `usePasswordReset` is a custom hook for handling password reset functionality.
 *
 * @returns {object} An object containing:
 * - `passwordValidationRegex`: An object defining the validation rules for the password.
 * - `showPassword`: A state object controlling the visibility of the password and confirm password fields.
 * - `togglePassword`: A function to toggle the visibility of the password and confirm password fields.
 * - `changeUserPassword`: An async function to change the user's password.
 * - `resetPassword`: An async function to reset the user's password.
 *
 * @example
 * const { passwordValidationRegex, showPassword, togglePassword, resetPassword } = usePasswordReset();
 */
const usePasswordReset = (passwordFieldName: string, confirmPasswordFieldName: string, oldPasswordFieldName?: string): IUsePasswordReset => {
    const [showPassword, setShowPassword] = useState({
        old: false,
        password: false,
        confirm: false
    });

    const passwordValidationRegex = {
        length: {
            regex: /.{8,}/,
            message: "Password must be at least 8 characters long"
        },
        uppercase: {
            regex: /(?=.*[A-Z])/,
            message: "Password must contain at least one uppercase letter"
        },
        number: {
            regex: /(?=.*\d)/,
            message: "Password must contain at least one number"
        },
        special: {
            regex: /(?=.*[!@#$%^&*£?])/,
            message: "Password must contain at least one special character"
        }
    }

    const validatePassword = (value: string) => {
        if (!value) {
            return 'Password is required';
        }

        for (const key in passwordValidationRegex) {
            if (!passwordValidationRegex[key].regex.test(value)) {
                return passwordValidationRegex[key].message;
            }
        }

        return undefined;
    };

    const validate = combineValidators({
        [passwordFieldName]: validatePassword,
        [confirmPasswordFieldName]: matchesField(passwordFieldName, 'Password')({ message: 'Passwords do not match' }),
        ...(oldPasswordFieldName && { [oldPasswordFieldName]: (value: string) => !value && 'Current Password is required' })
    });

    const togglePassword = (id: string) => {
        setShowPassword({ ...showPassword, [id]: !showPassword[id] });
    }

    const changeUserPassword = async (values: IUserPasswordChange) => {
        try {
            const user = await agent.User.changePassword(values);
            return user;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const resetPassword = async (values: IUserFormValues) => {
        const user = await agent.User.resetPassword(values);
        return user;
    };

    return {
        passwordValidationRegex,
        showPassword,
        togglePassword,
        changeUserPassword,
        resetPassword,
        validate
    }
};

export default usePasswordReset;
