import React, { useContext, SyntheticEvent } from "react";
import { IMyGuestOrganisationsEntry, RootStoreContext } from "api";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useSourcePermissions } from "hooks";

interface IProps {
    org: IMyGuestOrganisationsEntry;
}

export const ConfirmLeaveOrganisation: React.FC<IProps> = ({ org }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { leaveOrganistion, loadMyGuestOrgs } = useSourcePermissions();
    const leaveOrg = (e: SyntheticEvent, token: string) => {
        leaveOrganistion(token)
            .then(() => loadMyGuestOrgs())
            .then(() => closeDialog());
    };

    return (
        <Box sx={{ p: 2 }}>
            <DialogTitle>
                <Typography variant="h4">Confirm Leave</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack direction={"row"} spacing={1} sx={{ mb: 1 }}>
                    <Typography>Are you sure you want to leave organisation </Typography>{" "}
                    <Typography style={{ fontWeight: 600, fontSize: "1rem" }}>{`"${org.organisation_name}"`}</Typography>
                </Stack>
                <Typography>
                    Leaving the organisation would mean that you would no longer have access to any of the content provided by this
                    organisation. If you change your mind later, you would have to ask for the organisation to re-invite you.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={(e) => {
                        leaveOrg(e, org.token);
                    }}>
                    Confirm
                </Button>
            </DialogActions>
        </Box>
    );
};
