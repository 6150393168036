import { RootStore } from "./rootStore";
import { action, observable, makeObservable } from "mobx";
import { IUserInOrg } from "api";

export default class AdminStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable users: IUserInOrg[] | null = null;

    @action setUsers = (users: IUserInOrg[]) => {
        this.users = users;
    };
}
