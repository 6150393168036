import React from "react";

interface Props {
    children: React.ReactNode;
    client: string;
}

const ClientProvider: React.FC<Props> = ({ client, children }) => {
    if (!client || client === process.env.REACT_APP_CLIENT_NAME) {
        return <>{children}</>;
    }
    return null;
}

export default ClientProvider;