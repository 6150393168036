import React, { useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Stack,
    Typography,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    Box,
    CardContent,
    IconButton,
    CardHeader,
    Card,
    Paper
} from "@mui/material";
import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    AutocompleteField,
    CheckboxField,
    MultiAutocompleteField,
    MultiSelectField,
    MultilineTextField,
    RadioGroupField,
    SelectField,
    TextField,
    WysiwygField
} from "components/Forms";
import StepperForm from "components/Forms/StepperForm";
import { RenderGlossary } from "components";
import { FORM_ERROR } from "final-form";
import { agent, RootStoreContext, KnowledgeHubType, KnowledgeHubItemStatus, UserModules } from "api";
import { formatLabel, getDiffFromArrays } from "../Components";
import { AddCircle, ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { FieldArray } from "react-final-form-arrays";
import { useKnowledgeHub } from "hooks";
import IconSelector from "../Editor/Components/IconSelector";

interface ICreateKnowledgeHubItem {
    mode?: "create" | "edit";
}

const CreateKnowledgeHubItem: React.FC<ICreateKnowledgeHubItem> = ({ mode = "create" }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const navigate = useNavigate();
    let location = useLocation();
    let { returnUrl } = location.state || { returnUrl: "/knowledge-hub" };
    const { id } = useParams();
    const { user } = rootStore.userStore;
    const [initialValues, setInitialValues] = useState({
        roles: [],
        tags: [],
        sections: [] as { name: undefined; content: undefined }[],
        dateCreated: new Date().toISOString(),
        dateUpdated: new Date().toISOString(),
        status: KnowledgeHubItemStatus.Published,
        isIndexable: true,
        showInCategory: true,
        type: KnowledgeHubType.Category
    });
    const { createCategory, updateCategory, addCategory } = useKnowledgeHub();

    const getInitialValues = async () => {
        if (mode !== "edit" || !id) {
            return;
        }

        await agent.KnowledgeHub.getArticle(id)
            .then((data) => {
                addCategory(data);

                setInitialValues({
                    ...initialValues,
                    ...data,
                    tags: data.tags ?? [],
                    roles: data.roles ?? [],
                    dateUpdated: new Date().toISOString()
                });
            })
            .catch((error) => {
                console.log("knowledge hub get error:", error);
            });
    };

    // Fetch initial data
    useEffect(() => {
        getInitialValues();
    }, []);

    return (
        <Form
            onSubmit={async (values) => {
                const newValues = {
                    ...values,
                    id:
                        mode === "edit"
                            ? values.id
                            : `kb_${values.type === KnowledgeHubType.Category ? "cat" : "item"}-${new Date().getTime()}`,
                    roles: Array.isArray(values?.roles) && values?.roles.includes("all") ? [] : values.roles,
                    category: "kb_category",
                    lastModifiedBy: user?.email
                };

                if (mode === "edit") {
                    try {
                        await updateCategory(newValues, (data) => {
                            console.log("updateCategory success data", data);
                        });
                    } catch (error) {
                        console.log("Caught error in form submission (edit):", error);
                        // @ts-ignore
                        const errors = error.data.errors;
                        return { [FORM_ERROR]: error, ...errors };
                    }
                } else {
                    try {
                        await createCategory(newValues, (data) => {
                            console.log("updateCategory success data", data);
                        });
                    } catch (error) {
                        console.log("Caught error in form submission (create):", error);
                        // @ts-ignore
                        const errors = error.data.errors;
                        return { [FORM_ERROR]: error, ...errors };
                    }
                }
            }}
            initialValues={initialValues}
            mutators={{
                ...arrayMutators,
                setSubmitErrors: (args, state, utils) => {
                    utils.changeValue(state, "submitErrors", () => args[0]);
                }
            }}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors,
                dirty
            }) => {
                const initSteps = [
                    {
                        label: "Details",
                        description: "Set the details of the item",
                        // helpText: getItemById(knowledgeHubContent, "item-1")?.sections[0]?.content,
                        hasError: Boolean(submitErrors?.title || submitErrors?.description || submitErrors?.icon),
                        infoContent: (
                            <Stack spacing={2}>
                                <Typography>
                                    <b>Name</b> - <RenderGlossary term="Set a title for the item" />
                                </Typography>
                                <Typography>
                                    <b>Description</b> - <RenderGlossary term="Describe what the item is about" />
                                </Typography>
                                <Typography>
                                    <b>Icon</b> - <RenderGlossary term="Please refer to font awesome for the icon name" />
                                </Typography>
                                <Typography>
                                    <b>Order</b> -{" "}
                                    <RenderGlossary term="Set the order for the item. undefined value will give the order a value of 10 by default" />
                                </Typography>
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                <TextField
                                    name="title"
                                    label="Title"
                                    required
                                    validate={(value) => {
                                        if (!value) {
                                            return "Title is required";
                                        }

                                        if (value.length > 128) {
                                            return "Title can not be above 128 characters";
                                        }
                                    }}
                                />
                                <MultilineTextField
                                    name="description"
                                    label="Description"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                    rows={4}
                                    maxRows={8}
                                    validate={(value) => {
                                        if (value && value.length > 255) {
                                            return "Description must be less than 255 characters";
                                        }
                                    }}
                                />
                                <TextField
                                    name="icon"
                                    label="Icon"
                                    helperText={(meta) =>
                                        (meta.touched && meta.error) || (
                                            <span>
                                                Icon References:{" "}
                                                <a href="https://fontawesome.com/search?o=r&m=free" target="_blank" rel="noreferrer">
                                                    Font Awesome
                                                </a>
                                            </span>
                                        )
                                    }
                                />

                                <TextField name="order" label="Order" parse />

                                <SelectField
                                    label="Status"
                                    options={[
                                        { text: "Published", value: "published" },
                                        // { text: "Draft", value: "draft" },
                                        { text: "Archived", value: "archived" }
                                    ]}
                                    initialValue={values.status ? values.status : KnowledgeHubItemStatus.Draft}
                                    name="status"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                />
                            </Stack>
                        )
                    },
                    {
                        label: "Security",
                        description: "Set any permissions for the item",
                        hasError: Boolean(submitErrors?.client || submitErrors?.roles),
                        infoContent: (
                            <Stack spacing={2}>
                                <Typography>
                                    <b>Client</b> -{" "}
                                    <RenderGlossary term="Set the client the item is available to. This is only needed when it needs restriction." />
                                </Typography>
                                <Typography>
                                    <b>Roles</b> -{" "}
                                    <RenderGlossary term="Set the roles the item is available to. This is only needed when it needs restriction." />
                                </Typography>
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                <SelectField
                                    label="Client"
                                    options={[
                                        { text: "All", value: "" },
                                        { text: "Default", value: "default" },
                                        { text: "Ateme", value: "ateme" }
                                    ]}
                                    name="client"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                />

                                <MultiSelectField
                                    label="Roles"
                                    options={[
                                        { text: "Unrestricted", value: "all" },
                                        { text: "Developers", value: "is_dev" },
                                        { text: "Admins", value: "is_admin" }
                                    ]}
                                    name="roles"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                    onChange={(_, value: string[], previous: string[]) => {
                                        let diff = getDiffFromArrays(previous, value);

                                        if (diff.added.length) {
                                            if (diff.added.includes("all")) {
                                                return form.change("roles", ["all"]);
                                            }

                                            if (value.includes("all")) {
                                                return form.change(
                                                    "roles",
                                                    value.filter((item) => item !== "all")
                                                );
                                            }
                                        }
                                    }}
                                />

                                <SelectField
                                    label="Module Access"
                                    options={[
                                        {
                                            text: "All",
                                            value: ""
                                        },
                                        ...Object.keys(UserModules).map((key) => ({
                                            value: key,
                                            text: UserModules[key as keyof typeof UserModules]
                                        }))
                                    ]}
                                    name="moduleAccess"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                />
                            </Stack>
                        )
                    }
                ];

                return (
                    <form onSubmit={handleSubmit}>
                        <StepperForm
                            formTitle={`${mode === "create" ? "Create" : "Edit"} Knowledge Hub Item`}
                            submitButtonText={`${mode === "create" ? "Create" : "Update"} Item`}
                            // includeGlossary
                            steps={initSteps}
                            values={values}
                            invalid={invalid}
                            submitError={submitError}
                            showSuccessStep={submitSucceeded}
                            mode={mode === "edit" || submitFailed ? "edit" : "create"}
                            successStep={
                                <Stack spacing={1}>
                                    {mode === "edit" ? (
                                        <Typography>Your Knowledge Hub Item has now been updated.</Typography>
                                    ) : (
                                        <Typography>Your Knowledge Hub Item has now been created.</Typography>
                                    )}
                                </Stack>
                            }
                            onCancel={() => {
                                openDialog(
                                    <>
                                        <DialogTitle>Are you sure?</DialogTitle>
                                        <DialogContent>You have unsaved changes. Are you sure you want to leave this page?</DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {
                                                    closeDialog();
                                                }}>
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    closeDialog();
                                                    navigate(returnUrl);
                                                }}>
                                                Leave
                                            </Button>
                                        </DialogActions>
                                    </>
                                );
                            }}
                            onFinish={() => navigate(returnUrl)}
                        />
                    </form>
                );
            }}
        />
    );
};
export default observer(CreateKnowledgeHubItem);
