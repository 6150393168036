import React from "react";
import { Typography, Box } from "@mui/material";
import { observer } from "mobx-react";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { IKnowledgeHubArticleShort } from "api";

export interface IRelatedArticle {
    item: IKnowledgeHubArticleShort;
    onClick: () => void;
}

/**
 * `RelatedArticle` is a React functional component that renders a clickable article item. It is designed to be used
 * within a sidebar or a list of related articles. The component displays the article's title with a bullet point
 * and changes the text color and underlines it on hover to indicate interactivity. The article's description is not
 * currently displayed but can be included by uncommenting the relevant code. This component uses Material-UI components
 * for styling and layout and is wrapped with MobX's `observer` to automatically re-render in response to relevant state changes.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.item - The article item to display.
 * @param {function(): void} props.onClick - The callback function to be called when the article is clicked.
 * @returns {React.ReactElement} A React element representing a clickable article item.
 *
 * @example
 * ```tsx
 * <RelatedArticle
 *   item={{ title: 'Introduction to React', description: 'Learn the basics of React.' }}
 *   onClick={() => console.log('Article clicked')}
 * />
 * ```
 */
export const RelatedArticle: React.FC<IRelatedArticle> = observer(function RelatedArticle({ item, onClick }) {
    return (
        <Box onClick={onClick}>
            <Typography variant="h6" color="text.primary" component="span" mr={1}>
                &bull;
            </Typography>
            <Typography
                component="span"
                fontSize={16}
                fontWeight={500}
                color="primary.light"
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        color: "primary.main",
                        textDecoration: "underline"
                    }
                }}>
                {item.title}
            </Typography>
            {/* <Typography variant="body2" color="text.secondary">{getSummary(item?.description, 150)}</Typography> */}
        </Box>
    );
});

export default RelatedArticle;
