import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import StepperForm from "components/Forms/StepperForm";
import { Form } from "react-final-form";
import { RadioGroupField, TextField } from "components/Forms";
import { agent, NeaLiveProduct, RootStoreContext } from "api";
import OnChange from "components/FinalFormListener/OnChange";
import { FORM_ERROR } from "final-form";
import { useNavigate } from "react-router-dom";
import { useEnvironment } from "hooks";

const CreatePullPackager: React.FC = () => {
    const navigate = useNavigate();

    const rootStore = useContext(RootStoreContext);
    const { providers, setCloudCode, regions, setRegionCode } = rootStore.environmentStore;
    const { getProviders, getRegions } = useEnvironment();
    const { openDialog, closeDialog } = rootStore.dialogStore;

    React.useEffect(() => {
        getProviders();
    }, []);

    const [products, setProducts] = React.useState<NeaLiveProduct[]>([]);

    const getProducts = async (provider: string, region: string) => {
        if (!provider || !region) {
            return;
        }
        await agent.NeaLive.getNeaLiveProducts(provider, region)
            .then((response) => {
                setProducts(response.sizes);
            })
            .catch((error) => {
                setProducts([]);
                console.log(error);
            });
    };

    return (
        <Form
            onSubmit={(values) => {
                return agent.NeaLive.createNeaLive({
                    name: values.name,
                    region: values.region,
                    product_id: values.product_id,
                    storage_hours: values.storage_hours
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        const errors = error.data.errors;
                        return Promise.resolve({ [FORM_ERROR]: error, ...errors });
                    });
            }}
            initialValues={{
                storage_hours: 1
            }}
            render={({ submitError, handleSubmit, submitting, values, invalid, form, submitSucceeded, submitErrors, dirty }) => (
                <form onSubmit={handleSubmit}>
                    <StepperForm
                        formTitle="Create Pull Packager"
                        steps={[
                            {
                                label: "Name",
                                description: "Name your packager instance",
                                hasError: Boolean(submitErrors?.name),
                                infoContent: (
                                    <Box>
                                        <Typography>
                                            Select the name of your packager instance. This will be used to identify your packager instance.
                                        </Typography>
                                    </Box>
                                ),
                                content: (
                                    <Box>
                                        <TextField
                                            name="name"
                                            label="Name"
                                            validate={(value: any) => {
                                                if (!value) {
                                                    return "Required";
                                                }
                                            }}
                                        />
                                    </Box>
                                )
                            },
                            {
                                label: "Cloud Provider",
                                description: "Select your cloud provider.",
                                hasError: Boolean(submitErrors?.cloud_provider),
                                infoContent: (
                                    <Box>
                                        <Typography>Select which cloud provider will host your packager instance.</Typography>
                                    </Box>
                                ),
                                content: (
                                    <Box>
                                        {providers && (
                                            <>
                                                <RadioGroupField
                                                    name="cloud_provider"
                                                    label="Hosting Provider"
                                                    hideLabel={true}
                                                    options={providers.map((provider) => {
                                                        return { label: provider.name, value: provider.code };
                                                    })}
                                                    validate={(value: any) => {
                                                        if (!value) {
                                                            return "Required";
                                                        }
                                                    }}
                                                />
                                                <OnChange name="cloud_provider">
                                                    {(value) => {
                                                        setCloudCode(value);
                                                        getRegions(value);
                                                    }}
                                                </OnChange>
                                            </>
                                        )}
                                    </Box>
                                )
                            },
                            {
                                label: "Region",
                                description: "Select your cloud provider region.",
                                hasError: Boolean(submitErrors?.region),
                                infoContent: (
                                    <Box>
                                        <Typography>
                                            Select which cloud provider region your packager instance will be hosted in.
                                        </Typography>
                                    </Box>
                                ),
                                content: (
                                    <Box>
                                        {regions && (
                                            <>
                                                <RadioGroupField
                                                    name="region"
                                                    label="Select Region"
                                                    hideLabel={true}
                                                    options={regions.map((region) => {
                                                        return { label: region.name, value: region.code };
                                                    })}
                                                    validate={(value: any) => {
                                                        if (!value) {
                                                            return "Required";
                                                        }
                                                    }}
                                                    error={(meta) => meta.touched && meta.error}
                                                    helperText={(meta) => (meta.touched && meta.error ? meta.error : "")}
                                                />
                                                <OnChange name="region">
                                                    {(value) => {
                                                        setRegionCode(value);
                                                        getProducts(values.cloud_provider, value);
                                                    }}
                                                </OnChange>
                                            </>
                                        )}
                                    </Box>
                                )
                            },
                            {
                                label: "Product",
                                description: "Select the size of your packager.",
                                hasError: Boolean(submitErrors?.product_id || submitErrors?.storage_hours),
                                infoContent: (
                                    <Box>
                                        <Typography>
                                            Select the size of your packager instance. The size of your packager instance will determine how
                                            many concurrent transcodes your packager instance can handle.
                                        </Typography>
                                    </Box>
                                ),
                                content: (
                                    <Box>
                                        {products && (
                                            <>
                                                <RadioGroupField
                                                    name="product_id"
                                                    label="Product"
                                                    parse
                                                    options={products.map((product) => {
                                                        return {
                                                            label: (
                                                                <Box>
                                                                    <Typography>{product.name}</Typography>
                                                                    <Typography variant="caption">{product.description}</Typography>
                                                                </Box>
                                                            ),
                                                            value: Number(product.product_id)
                                                        };
                                                    })}
                                                    validate={(value: any) => {
                                                        if (!value) {
                                                            return "Required";
                                                        }
                                                    }}
                                                />
                                                <Divider sx={{ my: 2 }} />
                                                <RadioGroupField
                                                    name="storage_hours"
                                                    label="Catchup (1 hour included)"
                                                    validate={(value: any) => {
                                                        if (!value && value !== 0) {
                                                            return "Required";
                                                        }
                                                    }}
                                                    row
                                                    initialValue={values.storage_hours ? values.storage_hours : 1}
                                                    parse
                                                    options={[
                                                        { label: "1h", value: 1 },
                                                        { label: "2h", value: 2 },
                                                        { label: "4h", value: 4 },
                                                        { label: "6h", value: 6 },
                                                        { label: "8h", value: 8 }
                                                    ]}
                                                />
                                            </>
                                        )}
                                    </Box>
                                )
                            }
                        ]}
                        values={values}
                        invalid={invalid}
                        submitError={submitError}
                        showSuccessStep={submitSucceeded}
                        successStep={
                            <>
                                <Typography>Your Pull Packager instance is now creating</Typography>
                            </>
                        }
                        onCancel={() => {
                            dirty
                                ? openDialog(
                                      <>
                                          <DialogTitle>Are you sure?</DialogTitle>
                                          <DialogContent>You have unsaved changes. Are you sure you want to leave this page?</DialogContent>
                                          <DialogActions>
                                              <Button
                                                  onClick={() => {
                                                      closeDialog();
                                                  }}>
                                                  Cancel
                                              </Button>
                                              <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() => {
                                                      closeDialog();
                                                      navigate(`/pull-packager`);
                                                  }}>
                                                  Leave
                                              </Button>
                                          </DialogActions>
                                      </>
                                  )
                                : navigate(`/pull-packager`);
                        }}
                        onFinish={() => navigate("/pull-packager")}
                    />
                </form>
            )}
        />
    );
};

export default observer(CreatePullPackager);
