import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
    agent,
    ICogsReportDataSummaryPerOrganisation,
    ICogsReportResponse,
    ICreateCogsReportRequest,
    ICreateCogsReportResponse,
    IOrganisationListManager,
    ManagingOrganisationListResponse,
    RootStoreContext
} from "api";
import { endOfDay, format, set, startOfMonth } from "date-fns";
import CogsHeader from "./Components/CogsHeader";
import CogsItem from "./Components/CogsItem";

const AdminCogs = observer(function AdminCogs() {
    
    //General
    const rootStore = useContext(RootStoreContext);
    const { setCurrentTheme } = rootStore.commonStore;
    const { user } = rootStore.userStore;
    const theme = useTheme();
    const [loading, setLoading] = React.useState<boolean>(false);

    //Organisations
    const [organisations, setOrganisations] = React.useState<IOrganisationListManager[]>();
    const [organisationId, setOrganisationId] = React.useState<number>(0);
    const getOrganisations = async () => {
        setLoading(true);
        await agent.Organisation.getManagingOrgListAdmin()
            .then((response: ManagingOrganisationListResponse) => {
                setOrganisations(response.data);
                console.log(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    const handleOrganisationChange = (id: number) => {
        setReport(undefined);
        setReportId(undefined);
        setMonthlyReport(undefined);
        setMonth("");
        setOrganisationId(id);
        getAvailableReports(id);
    }


    const [dateRange, setDateRange] = React.useState<any>([startOfMonth(new Date()), endOfDay(new Date())]);
    const [reportId, setReportId] = React.useState<number | undefined>();
    const [report, setReport] = React.useState<ICogsReportResponse | undefined>();
    const [monthlyReport, setMonthlyReport] = React.useState<any>();

    //Monthly Reports
    const [availableReports, setAvailableReports] = React.useState<any[]>([]);
    const [month, setMonth] = React.useState<string>("");
    const onMonthChange = (event: React.ChangeEvent<{ value: unknown }>, id: number) => {
        setMonth(event.target.value as string);
        setReport(undefined);
        setReportId(undefined);
        getMonthlyReport(event.target.value as string, id);
    };
    const getMonthlyReport = async (month: string, reportId: number) => {
        setReport(undefined);
        setLoading(true);

        await agent.Billing.getCogsMonthlyReport(organisationId, month)
            .then((response) => {
                console.log(response);

                setMonthlyReport(response);

                //setReport(response.data);
                //setReportId(reportId);
                //setSelectedMonth(new Date(month));
            })
            .catch((error) => {
                console.log("Error", error);
            });

        setLoading(false);
    };
    React.useEffect(() => {}, [monthlyReport]);
    const getAvailableReports = async (id: number) => {
        setLoading(true);

        await agent.Billing.getAvailableCogsMonthlyReports(id)
            .then((response) => {
                console.log("Available Reports", response.data);
                if (response.data.length > 0) {
                setAvailableReports(response.data);
                } else {
                    setAvailableReports([]);
                }
            })
            .catch((error) => {
                console.log("Error", error);
            });

        setLoading(false);
    };

    //New Reports

    const createReport = async () => {
        setMonth("");
        setMonthlyReport(undefined);
        setReport(undefined);

        setReportId(undefined);

        setLoading(true);

        const request: ICreateCogsReportRequest =
            organisationId === 0
                ? {
                      report_from_time: format(new Date(dateRange[0]!), "yyyy-MM-dd 00:00:00.00+00"),
                      report_to_time: format(new Date(dateRange[1]!), "yyyy-MM-dd 23:59:59.99+00")
                  }
                : {
                      report_from_time: format(new Date(dateRange[0]!), "yyyy-MM-dd 00:00:00.00+00"),
                      report_to_time: format(new Date(dateRange[1]!), "yyyy-MM-dd 23:59:59.99+00"),
                      only_organisation_id: organisationId
                  };

        console.log(request);

        await agent.Admin.createCogsReport(request).then((response: ICreateCogsReportResponse) => {
            setReportId(response.report_id);
        });
    };

    const getReport = async (reportId) => {
        await agent.Admin.getCogsReport(reportId).then((response: ICogsReportResponse) => {
            setReport(response);
            console.log(response);
            if (response.report_status === "finished") {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (reportId && report?.report_status !== "finished" && !monthlyReport) {
            setTimeout(() => {
                getReport(reportId);
            }, 500);
        }
    }, [report?.report_status, reportId, report]);

    React.useEffect(() => {
        if (organisationId !== null) {
            getAvailableReports(organisationId);
        } else {
            setAvailableReports([]);
        }
    }, [organisationId, loading]);

    useEffect(() => {
        setCurrentTheme("light");
        getOrganisations();
        return () => {
            if (user?.user_preferences?.theme) {
                setCurrentTheme(user.user_preferences.theme);
            } else {
                setCurrentTheme("dark");
            }
        };
    }, []);

    return (
        <View loading={loading} loadingText="Generating Report...">
            <ViewHeader title="Cost of Goods Sold" subtitle="Admin" />
            <ViewBody noPadding>
                <Content
                    headerContent={
                        <>
                            {!monthlyReport && report && report.report_status === "finished" && (
                                <CogsHeader
                                    id={report.report_id}
                                    startDate={report.report_from_time}
                                    endDate={report.report_to_time}
                                    breakdown={report.report_data.breakdown}
                                    orgSummary={report.report_data.summary_per_organisation}
                                    total_total_tag_licence_tile_costs={report.report_data.total_total_tag_licence_tile_costs}
                                    total_external_zixi_licence_egress_costs={report.report_data.total_external_zixi_licence_egress_costs}
                                    total_internal_zixi_licence_egress_costs={report.report_data.total_internal_zixi_licence_egress_costs}
                                />
                            )}
                            {monthlyReport && (
                                <CogsHeader
                                    id={1}
                                    startDate={monthlyReport.period_from}
                                    endDate={monthlyReport.period_to}
                                    breakdown={monthlyReport.data.breakdown}
                                    orgSummary={monthlyReport.data.summary_per_organisation}
                                    total_total_tag_licence_tile_costs={monthlyReport.data.total_total_tag_licence_tile_costs}
                                    total_external_zixi_licence_egress_costs={monthlyReport.data.total_external_zixi_licence_egress_costs}
                                    total_internal_zixi_licence_egress_costs={monthlyReport.data.total_internal_zixi_licence_egress_costs}
                                />
                            )}
                        </>
                    }
                    toolbarContent={
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <div>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Organisation</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Environemnt"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Please Select"
                                        value={organisationId}
                                        sx={{ minWidth: 300, maxWidth: 300 }}
                                        onChange={(event) => handleOrganisationChange(Number(event.target.value))}>
                                        <MenuItem value={0}>All Organisations</MenuItem>
                                        {organisations?.map((organisation) => (
                                            <MenuItem key={organisation.id} value={organisation.id}>
                                                {organisation.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <TextField
                                    label="Select Monthly Report"
                                    variant="outlined"
                                    size="small"
                                    value={month}
                                    sx={{
                                        width: 200,
                                        mb: 0
                                    }}
                                    disabled={!availableReports.length}
                                    select
                                    onChange={(e: any) => {
                                        let item = availableReports.find((report) => report.month === e.target.value);
                                        onMonthChange(e, item.id);
                                    }}>
                                    {availableReports
                                    .sort((a, b) => new Date(b.month).getTime() - new Date(a.month).getTime())
                                    .map((report, index) => (
                                        <MenuItem key={index} value={report.month}>
                                            {format(new Date(report.month), "MMMM yyyy")}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    OR
                                </Typography>
                            </Box>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: "Start Date", end: "End Date" }}>
                                    <DateRangePicker
                                        value={dateRange}
                                        onChange={(newValue) => {
                                            setDateRange(newValue);
                                        }}
                                        format="dd/MM/yyyy"
                                        calendars={1}
                                        minDate={startOfMonth(new Date())}
                                        maxDate={new Date()}
                                        slotProps={{
                                            textField: { variant: "outlined", size: "small" }
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <Button variant="contained" color="primary" onClick={() => createReport()}>
                                Create New Report
                            </Button>
                        </Stack>
                    }>
                    <Box
                        sx={{
                            width: "100%",
                            height: 20,
                            backgroundColor: theme.palette.background.default,
                            position: "sticky",
                            top: -20,
                            marginTop: "-20px",
                            zIndex: 2
                        }}
                    />

                    {report &&
                        report.report_status === "finished" &&
                        report.report_data.summary_per_organisation
                            .filter((organisation) => organisation.organisation_id !== null)
                            .map((organisation: ICogsReportDataSummaryPerOrganisation) => {
                                return (
                                    <CogsItem
                                        key={organisation?.organisation_id}
                                        organisation={organisation}
                                        breakdown={report?.report_data?.breakdown}
                                    />
                                );
                            })}

                    {/* {monthlyReport && (
                        <CogsItem organisation={monthlyReport.data.summary_per_organisation[0]} breakdown={monthlyReport.data.breakdown} />
                        
                    )} */}

                    {monthlyReport && monthlyReport.data && monthlyReport.data.summary_per_organisation.map((item, index) => {
                        return (
                            <CogsItem
                                key={index}
                                organisation={item}
                                breakdown={monthlyReport.data.breakdown}
                            />
                        );
                    })}
                </Content>
            </ViewBody>
        </View>
    );
});

export default AdminCogs;
