import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import {
    IKnowledgeHubHistory,
    IKnowledgeItem,
    KnowledgeHubItemStatus,
    KnowledgeHubSelectorType,
    KnowledgeHubType,
    RootStoreContext,
    agent
} from "api";
import { useLocation, useNavigate } from "react-router-dom";
import { KnowledgeHubHomepage, KnowledgeHubItem, KnowledgeHubCategory } from "./Components";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useConstants, useKnowledgeHub } from "hooks";
import { toast } from "react-toastify";

interface IKnowledgeHub {
    entry?: KnowledgeHubSelectorType;
    isSidebar?: boolean;
    width?: number;
    initSearch?: string;
    entryId?: string;
    hideTitle?: boolean;
    onNavigate?: (history: IKnowledgeHubHistory[], setHistory: React.Dispatch<React.SetStateAction<IKnowledgeHubHistory[]>>) => void;
}

/**
 * `KnowledgeHub` is a React functional component that serves as the main entry point for the Knowledge Hub feature.
 * It dynamically renders different components based on the current state of navigation within the Knowledge Hub,
 * such as the homepage, categories, or individual items. It supports features like creating new items, editing,
 * and deleting existing items, with confirmation dialogs for deletion. The component integrates with the application's
 * routing, state management via MobX, and UI components from Material-UI. It also demonstrates advanced React patterns
 * like `useCallback` for memoizing callbacks, `useEffect` for side effects, and `useMemo` for memoizing components.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {KnowledgeHubSelectorType} [props.entry] - The initial entry point for rendering the Knowledge Hub.
 * @param {boolean} [props.isSidebar=false] - Flag to indicate if the Knowledge Hub is being rendered in a sidebar.
 * @param {string} [props.initSearch] - Initial search term to filter the Knowledge Hub items.
 * @param {string} [props.entryId] - The ID of an entry (category or item) to directly navigate to upon initialization.
 * @param {boolean} [props.hideTitle=false] - Flag to indicate if the title should be hidden in the Knowledge Hub.
 * @param {Function} [props.onNavigate] - Callback function to execute when navigating within the Knowledge Hub.
 * @returns {React.ReactElement} A React element that represents the Knowledge Hub component.
 *
 * @example
 * ```tsx
 * <KnowledgeHub
 *   entry={KnowledgeHubSelectorType.Homepage}
 *   isSidebar={false}
 *   initSearch="React"
 *   entryId="123"
 * />
 * ```
 */
export const KnowledgeHub: React.FC<IKnowledgeHub> = observer(function KnowledgeHub({
    entry,
    isSidebar = false,
    initSearch,
    entryId,
    hideTitle = false,
    onNavigate,
    ...props
}) {
    const rootStore = useContext(RootStoreContext);
    const { environment } = rootStore.environmentStore;
    const { drawer, openDrawer, closeDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { categories, items, indexedItems } = rootStore.knowledgeHubStore;
    const [history, setHistory] = useState<IKnowledgeHubHistory[]>([
        { id: entryId ? entryId : "", type: entryId ? entry ?? KnowledgeHubSelectorType.Homepage : KnowledgeHubSelectorType.Homepage }
    ]);
    const currentItem = history[history.length - 1];
    const { type, id } = currentItem;
    const [openDraw, setOpenDraw] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [search, setSearch] = useState<string>(searchParams.get("search") || initSearch || "");
    const navigate = useNavigate();
    const { constants } = useConstants();
    const { deleteItem, updateItem, deleteCategory, updateCategory } = useKnowledgeHub();
    const { user } = rootStore.userStore;

    useEffect(() => {
        if (onNavigate) {
            onNavigate(history, setHistory);
        }
    }, [history]);

    const goBackHome = useCallback(() => {
        setHistory([history[0]]);

        if (!isSidebar) {
            setOpenDraw(false);
            return closeDrawer();
        }
    }, [history, isSidebar, closeDrawer]);

    const goBack = useCallback(() => {
        setHistory((prevHistory) => {
            const newHistory = [...prevHistory];
            newHistory.pop();

            if (!isSidebar && newHistory.length === 1) {
                setOpenDraw(false);
                closeDrawer();
            }

            return newHistory;
        });
    }, [isSidebar, closeDrawer]);

    const goToSelector = useCallback(
        (id: string, type: KnowledgeHubSelectorType) => {
            setHistory((prevHistory) => [...prevHistory, { id, type }]);

            if (!isSidebar) {
                setOpenDraw(true);
            }
        },
        [isSidebar]
    );

    const editItem = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, type: KnowledgeHubType) => {
            e.stopPropagation();
            setOpenDraw(false);
            closeDrawer();
            if (type === KnowledgeHubType.Category) {
                return navigate(`/knowledge-hub/edit/${id}`);
            }
            navigate(`/knowledge-hub/editv2/${id}`);
        },
        [closeDrawer, navigate]
    );

    const archiveItem = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string, isCategory?: boolean) => {
            e.stopPropagation();
            let itemsWithCategory;

            if (isCategory) {
                itemsWithCategory = items.filter((item) => item.category === id);
            }

            openDialog(
                <ConfirmationDialog
                    title={`Archive: ${title}`}
                    message={`Are you sure you want to archive this ${isCategory ? "category" : "article"}?`}
                    additionalContent={
                        <>
                            {isCategory && itemsWithCategory?.length > 0 && (
                                <Box>
                                    <Typography variant="body2">
                                        Articles with this category selected will have the status set to archived as well.
                                    </Typography>
                                    <Typography variant="body2">
                                        <ul>
                                            {itemsWithCategory.map((item: IKnowledgeItem) => (
                                                <li key={item.id}>{item.title}</li>
                                            ))}
                                        </ul>
                                    </Typography>
                                </Box>
                            )}
                        </>
                    }
                    onConfirm={async () => {
                        try {
                            if (isCategory) {
                                let currentItem = categories.find((cat) => cat.id === id);

                                if (!currentItem) {
                                    return;
                                }

                                let newValues = {
                                    ...currentItem,
                                    status: KnowledgeHubItemStatus.Archived,
                                    dateUpdated: new Date().toISOString(),
                                    lastModifiedBy: user?.email
                                };

                                await updateCategory(newValues, (data) => {
                                    console.log("updateCategory success data", data);
                                    itemsWithCategory.forEach(async (item) => {
                                        item.status = KnowledgeHubItemStatus.Archived;
                                        item.dateUpdated = new Date().toISOString();
                                        item.lastModifiedBy = user?.email;
                                        updateItem(item);
                                    });
                                });
                            } else {
                                let currentItem = items.find((item) => item.id === id);

                                if (!currentItem) {
                                    return;
                                }

                                let newValues = {
                                    ...currentItem,
                                    status: KnowledgeHubItemStatus.Archived,
                                    dateUpdated: new Date().toISOString(),
                                    lastModifiedBy: user?.email
                                };

                                await updateItem(newValues);
                            }
                        } catch {
                            console.error("Error archiving article or category");
                        } finally {
                            closeDialog();
                            closeDrawer();
                        }
                    }}
                    onSuccess={() => {
                        toast.success(`You Successfully archived this ${isCategory ? "category" : "article"}`);
                    }}
                />
            );
        },
        [openDialog, closeDialog, closeDrawer, indexedItems, categories]
    );

    const removeItem = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string, isCategory?: boolean) => {
            e.stopPropagation();
            let itemsWithCategory;

            if (isCategory) {
                itemsWithCategory = items.filter((item) => item.category === id);
            }

            openDialog(
                <ConfirmationDialog
                    title={`Confirm Delete: ${title}`}
                    message={`Are you sure you want to delete this ${isCategory ? "category" : "article"}?`}
                    additionalContent={
                        <>
                            {isCategory && itemsWithCategory?.length > 0 && (
                                <Box>
                                    <Typography variant="body2">
                                        Articles with this category selected will have the category removed and will be hidden (accessible
                                        via the hidden folder).
                                    </Typography>
                                    <Typography variant="body2">
                                        <ul>
                                            {itemsWithCategory.map((item: IKnowledgeItem) => (
                                                <li key={item.id}>{item.title}</li>
                                            ))}
                                        </ul>
                                    </Typography>
                                </Box>
                            )}
                        </>
                    }
                    onConfirm={async () => {
                        try {
                            if (isCategory) {
                                deleteCategory(id, (data) => {
                                    itemsWithCategory.forEach(async (item) => {
                                        deleteItem(item.id);
                                    });
                                });
                            } else {
                                deleteItem(id);
                            }
                        } catch {
                            console.error("Error deleting article or category");
                        } finally {
                            closeDialog();
                            closeDrawer();
                        }
                    }}
                    onSuccess={() => {
                        toast.success(`You Successfully deleted this ${isCategory ? "category" : "article"}`);
                    }}
                />
            );
        },
        [openDialog, closeDialog, closeDrawer, indexedItems, categories]
    );

    const handleSearch = useCallback((searchTerm: string) => {
        setSearch(searchTerm);
    }, []);

    const clearSearch = useCallback(() => {
        setSearch("");
    }, []);

    const MemoizedKnowledgeHubHomepage = useMemo(() => {
        return (
            <KnowledgeHubHomepage
                key={`homepage-${history.length}`}
                isSidebar={isSidebar}
                history={history}
                setHistory={setHistory}
                goToSelector={goToSelector}
                search={search}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
                {...props}
            />
        );
    }, [isSidebar, history, goToSelector, search, handleSearch, clearSearch, props]);

    useEffect(() => {
        if (!isSidebar && openDraw) {
            openDrawer(
                <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3, display: "flex" }}>{contentSwitch(true)}</Box>
            );
            setOpenDraw(true);
        }
    }, [openDraw, openDrawer, isSidebar, history]);

    useEffect(() => {
        if (!isSidebar && openDraw && !drawer.open) {
            setOpenDraw(false);
            setHistory([{ id: "", type: KnowledgeHubSelectorType.Homepage }]);
        }
    }, [drawer.open, isSidebar, openDraw]);

    const contentSwitch = useCallback(
        (sidebar = false) => {
            const localIsSidebar = sidebar || isSidebar;

            if (localIsSidebar) {
                switch (type) {
                    case KnowledgeHubSelectorType.Homepage:
                        return MemoizedKnowledgeHubHomepage;
                    case KnowledgeHubSelectorType.Category:
                        return (
                            <KnowledgeHubCategory
                                key={`category-${id}`}
                                id={currentItem.id}
                                isSidebar={localIsSidebar}
                                history={history}
                                goBackHome={goBackHome}
                                goBack={goBack}
                                goToSelector={goToSelector}
                                editItem={editItem}
                                deleteItem={removeItem}
                                archiveItem={archiveItem}
                            />
                        );
                    case KnowledgeHubSelectorType.Item:
                        return (
                            <KnowledgeHubItem
                                key={`item-${id}`}
                                id={currentItem.id}
                                isSidebar={localIsSidebar}
                                history={history}
                                setHistory={setHistory}
                                goBackHome={goBackHome}
                                goBack={goBack}
                                goToSelector={goToSelector}
                                hideTitle={hideTitle}
                                editItem={editItem}
                                deleteItem={removeItem}
                                // archiveItem={archiveItem}
                            />
                        );
                    default:
                        return MemoizedKnowledgeHubHomepage;
                }
            }

            return (
                <View>
                    <ViewHeader title="Knowledge Hub" />
                    <ViewBody noPadding>
                        <Content noPadding>{MemoizedKnowledgeHubHomepage}</Content>
                    </ViewBody>
                </View>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isSidebar,
            type,
            id,
            currentItem,
            history,
            goBackHome,
            goBack,
            goToSelector,
            editItem,
            deleteItem,
            archiveItem,
            environment,
            MemoizedKnowledgeHubHomepage
        ]
    );

    return contentSwitch();
});

export default KnowledgeHub;

// V2 Features
// Do the Category form in a modal
// Make table of contents a component and add to sandbox
// editor for a section - link to another article - when deleting an article scan articles for references to id
// editor for a section - glossary lookup
// for a category - export to pdf
// for a category / item open via url
// Items - subitems (item becoming a category)
// THINK ABOUT - multiple categories
// full screen draft when create/edit
// Ability to add subsections to sections
// Stepper form glossary sidebar
// Item selector sidebar for linking to other articles
// Be able to display a section instead of an article
// REFACTOR - Naming conventions - item -> article etc. KnowledgeHubItem = Either, Article = Item, Category is fine
// archived items should still show in the category but just faded out
// Un archiving a category should unarchive all articles / give an option to unarchive each article
