import React, { useMemo, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close, Search } from "@mui/icons-material";
import {
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
    useTheme
} from "@mui/material";
import { IEnvironmentListItem, RootStoreContext, agent, KnowledgeHubSelectorType } from "api";
import DestinationDetails from "features/destinations/Components/DestinationDetails";
import SourceDetails from "features/sources/Components/SourceDetails";
import SourceThumbnail from "features/sources/Components/SourceThumbnail";
import useSearch from "hooks/UseSearch";
import { IFeature, allFeatures } from "../Data/Features";
import RoleProvider from "components/RoleProvider";
import KnowledgeHub from "features/knowledgeHub";
import { useSources } from "hooks";

const highlightMatches = (text: string, searchTerm: string) => {
    // remove any ( or ) from the search term
    searchTerm = searchTerm.replace(/[()]/g, "");

    const terms = searchTerm.toLowerCase().split(/\s+/).filter(Boolean); // Split searchTerm into words and filter out empty strings

    if (terms.length === 0) {
        return text.split(" ").map((part, index) => (
            <React.Fragment key={index}>
                {part}
                {index < text.split(" ").length - 1 && <span>&nbsp;</span>}
            </React.Fragment>
        ));
    }

    const regex = new RegExp(`(${terms.join("|")})`, "gi"); // Create a regex to match any of the terms
    const parts = text?.split(regex); // Split text by the regex to separate matches and non-matches

    return parts?.map(
        (part, index) =>
            regex.test(part) ? (
                <Typography component="span" fontSize="inherit" fontWeight="bold" color="primary.light" key={index}>
                    {part}
                </Typography>
            ) : (
                part.split(" ").map((subPart, subIndex) => (
                    <React.Fragment key={`${index}-${subIndex}`}>
                        {subPart}
                        {subIndex < part.split(" ").length - 1 && <span>&nbsp;</span>}
                    </React.Fragment>
                ))
            ) // Highlight matched parts
    );
};

const SearchMenu: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { getInputsDropdownList } = useSources();

    const rootStore = useContext(RootStoreContext);
    const environment_id = rootStore.userStore.activeEnvironment;
    const { openDrawer } = rootStore.drawerStore;
    const { indexedItems } = rootStore.knowledgeHubStore;

    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [open, setOpen] = React.useState<boolean>(false);
    const [hasFocus, setHasFocus] = React.useState<boolean>(false);
    //const [featuresData, setFeaturesData] = React.useState<IFeature[]>(features);
    const [sources, setSources] = React.useState<any[]>([]);
    const [destinations, setDestinations] = React.useState<any[]>([]);
    const [environments, setEnvironments] = React.useState<IEnvironmentListItem[]>([]);

    const getSources = async (environment_id: string) => {
        const response = await getInputsDropdownList(environment_id);
        setSources(response.data);
    };

    const getDestinations = async (environment_id: string) => {
        const response = await agent.Destination.getDestinationsList(environment_id);
        setDestinations(response.data);
    };

    const getEnvironments = async () => {
        const response = await agent.Environment.getEnvironmentsList();
        let environments = response.data;
        environments = environments.filter((environment) => environment.status !== "deleted");
        setEnvironments(environments);
    };

    const searchFieldsFeatures = useMemo(
        () => [
            { field: "name", weight: 0.8 },
            { field: "description", weight: 0.2 },
            { field: "tags", weight: 1 }
        ],
        []
    );

    const searchFieldsArticles = useMemo(
        () => [
            { field: "title", weight: 2 },
            { field: "description", weight: 1 },
            { field: "tags", weight: 3 }
        ],
        []
    );

    const searchFieldsSources = useMemo(() => [{ field: "name", weight: 1 }], []);

    const searchFieldsDestinations = useMemo(() => [{ field: "name", weight: 1 }], []);

    const searchFieldsEnvironments = useMemo(() => [{ field: "name", weight: 1 }], []);

    const searchFeatures = useSearch(searchTerm, allFeatures, searchFieldsFeatures, 1, 0.7);
    const searchArticles = useSearch(searchTerm, indexedItems, searchFieldsArticles, 4, 0.3);
    const searchSources = useSearch(searchTerm, sources, searchFieldsSources, 4);
    const searchDestinations = useSearch(searchTerm, destinations, searchFieldsDestinations, 4);
    const searchEnvironments = useSearch(searchTerm, environments, searchFieldsEnvironments, 4);

    useEffect(() => {
        if (open) {
            if (environment_id) {
                getSources(environment_id);
                getDestinations(environment_id);
            }
            getEnvironments();
        }
    }, [environment_id, open]);

    const ListHeader: React.FC<{ title: string }> = ({ title }) => {
        return (
            <Box
                sx={{
                    borderBottomColor: theme.palette.divider,
                    padding: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row"
                }}>
                <Divider sx={{ width: 10 }} />
                <Box px={1}>
                    <Typography variant="body1" fontWeight="bold" color="text.secondary">
                        {title}
                    </Typography>
                </Box>
                <Divider sx={{ flexGrow: 1 }} />
            </Box>
        );
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(false);
                setHasFocus(false);
            }}>
            <Box
                sx={{
                    height: "100%",
                    width: open || hasFocus ? 360 : 260,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    transition: theme.transitions.create(["width"], {
                        easing: theme.transitions.easing.easeIn,
                        duration: theme.transitions.duration.shortest
                    })
                }}>
                <Paper
                    component="div"
                    sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: 360,
                        borderBottomLeftRadius: open ? 0 : 2,
                        borderBottomRightRadius: open ? 0 : 2
                    }}>
                    <Box
                        sx={{
                            height: 40,
                            width: 40,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                        <Search />
                    </Box>
                    <InputBase
                        id="search-input"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={"Search " + (process.env.REACT_APP_CLIENT_NAME === "ateme" ? "Ateme+" : "Livelink")}
                        inputProps={{ "aria-label": "search" }}
                        autoComplete="off"
                        onFocus={() => {
                            setHasFocus(true);
                            if (searchTerm.length > 2) {
                                setOpen(true);
                            }
                        }}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            if (e.target.value.length > 2) {
                                setOpen(true);
                            }
                        }}
                        value={searchTerm}
                    />
                    {searchTerm.length > 0 && (
                        <IconButton
                            type="button"
                            size="small"
                            aria-label="search"
                            onClick={() => {
                                setSearchTerm("");
                                setOpen(false);
                                //set the focus back to the search input
                                document.getElementById("search-input")?.focus();
                            }}>
                            <Close />
                        </IconButton>
                    )}
                </Paper>
                {open && searchTerm.length > 2 && (
                    <Paper
                        elevation={2}
                        sx={{
                            position: "absolute",
                            top: "calc(100% - 10px)",
                            left: 0,
                            width: "100%",
                            boxShadow: 1,
                            zIndex: 100,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            maxHeight: "calc(100vh - 64px)",
                            overflowY: "auto"
                        }}>
                        {searchFeatures.length > 0 && (
                            <List
                                sx={{
                                    "+ .no-results": {
                                        display: "none"
                                    }
                                }}>
                                <ListHeader title="Features" />
                                {searchFeatures.map((result, index) => {
                                    const { item, matches } = result;
                                    return (
                                        <ListItem key={index} disablePadding>
                                            <ListItemButton
                                                sx={{ py: 0 }}
                                                onClick={() => {
                                                    navigate(item.path);
                                                }}>
                                                <ListItemIcon>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            position: "relative",
                                                            width: 30
                                                        }}>
                                                        <FontAwesomeIcon icon={["fas", item.icon]} fontSize={24} />
                                                        {item.type === "create" && (
                                                            <Box
                                                                sx={{
                                                                    position: "absolute",
                                                                    top: -6,
                                                                    right: -6,
                                                                    backgroundColor: theme.palette.primary.dark,
                                                                    color: theme.palette.primary.contrastText,
                                                                    borderRadius: "50%",
                                                                    width: 17,
                                                                    height: 17,
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    fontWeight: "bold",
                                                                    paddingLeft: "1px"
                                                                }}>
                                                                +
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="body1">{highlightMatches(item.name, searchTerm)}</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        component="div"
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flexWrap: "wrap"
                                                        }}>
                                                        {highlightMatches(item.description, searchTerm)}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                        <RoleProvider roles={["is_dev"]}>
                            {searchArticles.length > 0 && (
                                <List
                                    sx={{
                                        "+ .no-results": {
                                            display: "none"
                                        }
                                    }}>
                                    <ListHeader title="Articles" />
                                    {searchArticles.map((result, index) => {
                                        const { item, matches } = result;

                                        if (index > 0) {
                                            return;
                                        }

                                        return (
                                            <ListItem key={index} disablePadding>
                                                <ListItemButton
                                                    onClick={() => {
                                                        openDrawer(
                                                            <Box
                                                                sx={{
                                                                    width: 680,
                                                                    maxWidth: "100vw",
                                                                    height: "100%",
                                                                    padding: 3,
                                                                    display: "flex"
                                                                }}>
                                                                <KnowledgeHub
                                                                    entry={
                                                                        item.category === "kb_category"
                                                                            ? KnowledgeHubSelectorType.Category
                                                                            : KnowledgeHubSelectorType.Item
                                                                    }
                                                                    isSidebar
                                                                    entryId={item.id}
                                                                />
                                                            </Box>
                                                        );
                                                    }}>
                                                    <ListItemIcon>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                position: "relative",
                                                                width: 30
                                                            }}>
                                                            <FontAwesomeIcon
                                                                icon={[
                                                                    "fas",
                                                                    item.icon || (item.category === "kb_category" ? "folder" : "book")
                                                                ]}
                                                                fontSize={24}
                                                            />
                                                        </Box>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography variant="body1">{highlightMatches(item.title, searchTerm)}</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            component="div"
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                flexWrap: "wrap"
                                                            }}>
                                                            {highlightMatches(item.description, searchTerm)}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            )}
                        </RoleProvider>

                        {searchSources.length > 0 && (
                            <List
                                sx={{
                                    "+ .no-results": {
                                        display: "none"
                                    }
                                }}>
                                <ListHeader title="Sources" />
                                {searchSources.map((result, index) => {
                                    const { item, matches } = result;
                                    return (
                                        <ListItem key={item.id} disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    openDrawer(
                                                        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                            <SourceDetails item_id={item.id} />
                                                        </Box>
                                                    );
                                                }}>
                                                <Box sx={{ width: 50, borderRadius: 1, overflow: "hidden", mr: 2 }}>
                                                    <SourceThumbnail item_id={item.id} disableLoading />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        flexWrap: "wrap"
                                                    }}>
                                                    {highlightMatches(item.name, searchTerm)}
                                                </Box>
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                        {searchDestinations.length > 0 && (
                            <List
                                sx={{
                                    "+ .no-results": {
                                        display: "none"
                                    }
                                }}>
                                <ListHeader title="Destinations" />
                                {searchDestinations.map((result, index) => {
                                    const { item, matches } = result;
                                    return (
                                        <ListItem key={item.id} disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    openDrawer(
                                                        <Box sx={{ width: 680, padding: 3, height: "100vh" }}>
                                                            <DestinationDetails destination_id={item.id} />
                                                        </Box>
                                                    );
                                                }}>
                                                {highlightMatches(item.name, searchTerm)}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                        {searchEnvironments.length > 0 && (
                            <List
                                sx={{
                                    "+ .no-results": {
                                        display: "none"
                                    }
                                }}>
                                <ListHeader title="Environments" />
                                {searchEnvironments.map((result, index) => {
                                    const { item, matches } = result;
                                    return (
                                        <ListItem key={item.id} disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    navigate(`/environments/${item.id}`);
                                                }}>
                                                {highlightMatches(item.name, searchTerm)}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                        <Box className="no-results" p={2}>
                            No results found for "{searchTerm}"
                        </Box>
                    </Paper>
                )}
            </Box>
        </ClickAwayListener>
    );
};

export default SearchMenu;
