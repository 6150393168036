import React, { useContext, useState } from "react";
import { RootStoreContext } from "api";
import Button from "@mui/material/Button";
import { Alert, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import { useSources } from "hooks";

interface IProps {
    transcoder_id: string;
    transcoder_name: string;
}

export const ConfirmStopTranscoder: React.FC<IProps> = ({ transcoder_id, transcoder_name }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { stopTranscodedStream } = useSources();
    const [error, setError] = useState("");

    const stopTranscoder = () => {
        stopTranscodedStream(transcoder_id)
            .then(() => {
                closeDialog();
                closeDialog();
            })
            .catch((error) => {
                setError(error.data.message);
            });
    };

    return (
        <>
            <DialogTitle>Stop Transcoder</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Transcoder: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{transcoder_name}</span>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Putting the transcoder in a stopped state will still allow you to schedule the item to a destination but will not output
                    a transcoded version of the source. You will be charged a lower rate for the stopped transcoder.
                </Typography>
                <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                    Note that the transcoder will not automatically start and a manual start is required for it to be available again.
                </Alert>
                <Typography variant="body1" gutterBottom>
                    Are you sure you want to stop the transcoder?
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        stopTranscoder();
                    }}
                    startIcon={<StopIcon />}>
                    Stop
                </Button>
            </DialogActions>
        </>
    );
};
