/* eslint-disable mobx/missing-observer */
import { Box } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

interface CurrentTimeIndicatorProps {
    zoom: number;
    header?: boolean;
}

const CurrentTimeIndicator: React.FC<CurrentTimeIndicatorProps> = (props) => {
    const today = new Date();
    const minutesOfDay = today.getHours() * 60 + today.getMinutes();
    const [currentMinute, setCurrentMinute] = React.useState<number>(Number(minutesOfDay));
    const theme = useTheme();

    React.useEffect(() => {
        const interval = setInterval(() => {
            const today = new Date();
            const minutesOfDay = today.getHours() * 60 + today.getMinutes();
            setCurrentMinute(Number(minutesOfDay));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return props.header ? (
        <Box
            sx={{
                backgroundColor: theme.palette.error.main,
                width: "1px",
                position: "absolute",
                top: "60px",
                left: currentMinute * props.zoom + 350,
                zIndex: 1,
                opacity: 0.8,
                "&::before": {
                    content: "''",
                    position: "absolute",
                    top: "-7px",
                    left: "-4px",
                    width: "7px",
                    height: "7px",
                    backgroundColor: theme.palette.error.main
                }
            }}></Box>
    ) : (
        <Box
            sx={{
                backgroundColor: theme.palette.error.main,
                width: "1px",
                position: "absolute",
                top: "0px",
                bottom: "-1px",
                left: currentMinute * props.zoom + 350,
                zIndex: 1,
                opacity: 0.8
            }}></Box>
    );
};

export default CurrentTimeIndicator;
