import {
    agent,
    IEnvironment,
    IEnvironmentsData,
    IEnvironmentUpgradeBody,
    ISchedulerFormValues,
    RootStoreContext,
    SwitchEnvironment
} from "api";
import { useState, useCallback, useContext } from "react";

export const useAlert = () => {
    const rootStore = useContext(RootStoreContext);
    const { setAlertsOverview, setInputAndOutputs } = rootStore.alertStore;
    const [isLoading, setIsLoading] = useState(false);

    const getAlertsOverview = useCallback(async () => {
        try {
            const alertsOv = await agent.Alerts.getAlertsOverview();
            setAlertsOverview(alertsOv);
        } catch (error) {
            throw error;
        }
    }, []);

    const getInputsAndOutputs = useCallback(async () => {
        try {
            const res = await agent.Alerts.getInputOutput();
            setInputAndOutputs(res);
        } catch (error) {
            throw error;
        }
    }, []);

    const getAlerts = useCallback(async () => {
        return await agent.Alerts.getAlertsOverview();
    }, []);

    return {
        isLoading,
        getAlertsOverview,
        getInputsAndOutputs,
        getAlerts
    };
};
