import { Box, Stack, Typography, Alert } from "@mui/material";
import { DynamicRange, HDRConformance, MasteringDisplayColorVolumeSettings } from "api";
import OnChange from "components/FinalFormListener/OnChange";
import { CheckboxField, RadioGroupField, SelectField, SliderField } from "components/Forms";
import { pruneEmptyErrors } from "features/common/PruneEmptyErrors";
import { FormApi } from "final-form";
import React from "react";
import { useFormState } from "react-final-form";

interface Props {
    values: any;
    submitError: any;
    form: FormApi;
    onFieldChange: (field: string, value: any) => void;
}

export const ColorimetryOptionsForm: React.FC<Props> = ({ values, submitError, form, onFieldChange }) => {
    React.useEffect(() => {}, [values, submitError]);

    const { submitErrors } = useFormState();

    // Render the form fields based on the selected options
    const renderHDRParameters = (hdrConformance: HDRConformance, values: any) => {
        const isHDR10 = hdrConformance === HDRConformance.DVB_HDR10 || hdrConformance === HDRConformance.ATSC_HDR10;

        if (!isHDR10) {
            return null;
        }

        return (
            <>
                <SliderField
                    name="output_colorimetry_parameters.hdr_parameters.content_light_level_information.max_fall"
                    label="Max Frame Average Light Level"
                    min={0}
                    max={8000}
                    step={100}
                    initialValue={values?.output_colorimetry_parameters?.hdr_parameters?.content_light_level_information?.max_fall || 0}
                    validate={(value: any) => {
                        if (value < 0 || value > 8000) {
                            return "Value must be an integer between 0 and 8000";
                        }
                    }}
                />

                <SliderField
                    name="output_colorimetry_parameters.hdr_parameters.content_light_level_information.max_cll"
                    label="Max Content Light Level"
                    min={0}
                    max={8000}
                    step={100}
                    initialValue={values?.output_colorimetry_parameters?.hdr_parameters?.content_light_level_information?.max_cll || 0}
                    validate={(value: any) => {
                        if (value < 0 || value > 8000) {
                            return "Value must be an integer between 0 and 8000";
                        }
                    }}
                />
                <Box sx={{ mb: 3 }}>
                    <CheckboxField
                        name="output_colorimetry_parameters.hdr_parameters.hdr_10_plus.enable"
                        label="Enable HDR 10+"
                        initialValue={values?.output_colorimetry_parameters?.hdr_parameters?.hdr_10_plus?.enable || false}
                    />
                </Box>
                
                <SelectField
                    name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.settings"
                    label="Mastering Display Color Volume Settings"
                    options={Object.values(MasteringDisplayColorVolumeSettings).map((setting) => ({
                        text: setting,
                        value: setting
                    }))}
                    required
                    validate={(value: any) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                />

                <OnChange name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.settings">
                    {() => {
                        if (
                            submitError?.data?.errors?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume
                                ?.settings
                        ) {
                            onFieldChange(
                                "output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.settings",
                                undefined
                            );
                        }
                    }}
                </OnChange>
                {renderCustomColorSettings()}
            </>
        );
    };

    const renderCustomColorSettings = () => {
        return (
            <Box sx={{ mt: 2 }}>
                <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                    <SliderField
                        name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.luminance_min"
                        label="Luminance Min"
                        min={0}
                        max={1000}
                        step={10}
                        initialValue={
                            values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.luminance_min || 0
                        }
                        validate={(value: any) => {
                            if (value < 0 || value > 1000) {
                                return "Value must be an integer between 0 and 1000";
                            }
                        }}
                    />

                    <SliderField
                        name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.luminance_max"
                        label="Luminance Max"
                        min={0}
                        max={10000}
                        step={10}
                        initialValue={
                            values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.luminance_max || 0
                        }
                        validate={(value: any) => {
                            if (value < 0 || value > 10000) {
                                return "Value must be an integer between 0 and 10000";
                            }
                        }}
                    />
                </Stack>
                {values.output_colorimetry_parameters.hdr_parameters?.mastering_display_color_volume?.settings ===
                    MasteringDisplayColorVolumeSettings.Custom && (
                    <Box sx={{ p: 2, mt: 2, backgroundColor: "rgba(160, 160, 160, 0.05)", borderRadius: 1 }}>
                        <Typography variant="h6" gutterBottom>
                            Custom Color Settings
                        </Typography>
                        <Stack sx={{ width: "100%", mb: 2 }} direction="row" spacing={3}>
                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.white_x"
                                label="White Point (x)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="white"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.white_x || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />
                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.white_y"
                                label="White Point (y)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="white"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.white_y || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />
                        </Stack>

                        <Stack sx={{ width: "100%", mb: 2 }} direction="row" spacing={3}>
                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.red_x"
                                label="Red Point (x)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="red"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.red_x || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />

                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.red_y"
                                label="Red Point (y)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="red"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.red_y || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />
                        </Stack>
                        <Stack sx={{ width: "100%", mb: 2 }} direction="row" spacing={3}>
                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.green_x"
                                label="Green Point (x)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="green"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.green_x || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />
                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.green_y"
                                label="Green Point (y)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="green"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.green_y || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />
                        </Stack>
                        <Stack sx={{ width: "100%" }} direction="row" spacing={3}>
                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.blue_x"
                                label="Blue Point (x)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="blue"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.blue_x || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />
                            <SliderField
                                name="output_colorimetry_parameters.hdr_parameters.mastering_display_color_volume.blue_y"
                                label="Blue Point (y)"
                                min={0}
                                max={1}
                                step={0.01}
                                color="blue"
                                initialValue={
                                    values?.output_colorimetry_parameters?.hdr_parameters?.mastering_display_color_volume?.blue_y || 0
                                }
                                validate={(value: any) => {
                                    if (value < 0 || value > 1) {
                                        return "Value must be a float between 0 and 1";
                                    }
                                }}
                            />
                        </Stack>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Box>
            {/* In the output colorimetry section, at the top, we should have a checkbox that says "Enable Dolby Vision?". If ticked, then the output colorimetry menu should change and should expose 4 inputs:
Content Type with options:
0: Default (no selection based on content type)
1: Cinema (movies)
2: Game
3: Sport
4: User Generated Content
Intended White Point (K) with options:
0: Default
1: 6879
2: 7254
3: 7629
4: 8004
5: 8379
6: 8754
7: 9129
8: 9504
9: 9879
10: 10254
11: 10629
12: 11004
13: 11379
14: 11754
15: 12129
Profile with options:
Profile 5
Profile 8.1
Profile 8.4
ST 2094-10
HDR Conformance with options dependent on the profile
Profile 5 / 8.1 / 10 options:
DVB PQ10
DVB HDR10
ATSC HDR10
Profile 8.4 options:
ARIB STD-B67
DVB HLG
ATSC HLG


API PAYLOAD */}

            {/* Enable Dolby Vision Field */}
            <Box sx={{ mb: 3 }}>
                <CheckboxField
                    name="output_colorimetry_parameters.enable_dolby_vision"
                    initialValue={values?.output_colorimetry_parameters?.enable_dolby_vision ? true : false}
                    label="Enable Dolby Vision"
                    disabled={
                        (values.videoCodec && values.videoCodec === "MPEG-4") ||
                        (values.video_codec && values.video_codec === "MPEG-4") ||
                        (values.individual_track_configuration &&
                            values.individual_track_configuration.video_tracks &&
                            values.individual_track_configuration.video_tracks.some((track: any) => track.codec === "MPEG-4"))
                    }
                />
                {(values.videoCodec && values.videoCodec === "MPEG-4") ||
                    (values.video_codec && values.video_codec === "MPEG-4") ||
                    (values.individual_track_configuration &&
                        values.individual_track_configuration.video_tracks &&
                        values.individual_track_configuration.video_tracks.some((track: any) => track.codec === "MPEG-4") && (
                            <Alert severity="warning" sx={{ my: 2 }}>
                                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                                    Dolby Vision is not supported for MPEG-4 video codec. Please select the HEVC video codec if you want to
                                    use Dolby Vision.
                                </Typography>
                            </Alert>
                        ))}
                <OnChange name="output_colorimetry_parameters.enable_dolby_vision">
                    {(value) => {
                        if (value) {
                            values.output_colorimetry_parameters.dynamic_range = DynamicRange.BT2020HDR;
                        } else {
                            values.output_colorimetry_parameters.dynamic_range = DynamicRange.None;
                        }
                    }}
                </OnChange>
            </Box>
            {/* Dynamic Range Field */}
            <div>
                <RadioGroupField
                    name="output_colorimetry_parameters.dynamic_range"
                    label="Dynamic Range"
                    disabled={values.output_colorimetry_parameters?.enable_dolby_vision}
                    row
                    initialValue={values?.output_colorimetry_parameters?.dynamic_range || DynamicRange.None}
                    options={Object.values(DynamicRange).map((range) => ({
                        label: range,
                        value: range
                    }))}
                />
            </div>

            {/* Conditional Dolby Vision parameters */}
            {values.output_colorimetry_parameters?.enable_dolby_vision && (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <SelectField
                        name="output_colorimetry_parameters.dolby_vision.content_type"
                        label="Content Type"
                        required
                        options={[
                            {
                                text: "Default (no selection based on content type)",
                                value: "0: Default (no selection based on content type)"
                            },
                            { text: "Cinema (movies)", value: "1: Cinema (movies)" },
                            { text: "Game", value: "2: Game" },
                            { text: "Sport", value: "3: Sport" },
                            { text: "User Generated Content", value: "4: User Generated Content" }
                        ]}
                        validate={(value: any) => {
                            if (values.output_colorimetry_parameters.enable_dolby_vision && !value) {
                                return "Required";
                            }
                        }}
                    />
                    <SelectField
                        name="output_colorimetry_parameters.dolby_vision.intended_white_point"
                        label="Intended White Point (K)"
                        required
                        options={[
                            { text: "Default", value: "0: Default" },
                            { text: "6879", value: "1: 6879" },
                            { text: "7254", value: "2: 7254" },
                            { text: "7629", value: "3: 7629" },
                            { text: "8004", value: "4: 8004" },
                            { text: "8379", value: "5: 8379" },
                            { text: "8754", value: "6: 8754" },
                            { text: "9129", value: "7: 9129" },
                            { text: "9504", value: "8: 9504" },
                            { text: "9879", value: "9: 9879" },
                            { text: "10254", value: "10: 10254" },
                            { text: "10629", value: "11: 10629" },
                            { text: "11004", value: "12: 11004" },
                            { text: "11379", value: "13: 11379" },
                            { text: "11754", value: "14: 11754" },
                            { text: "12129", value: "15: 12129" }
                        ]}
                        validate={(value: any) => {
                            if (values.output_colorimetry_parameters.enable_dolby_vision && !value) {
                                return "Required";
                            }
                        }}
                    />
                    <SelectField
                        name="output_colorimetry_parameters.dolby_vision.profile"
                        label="Profile"
                        required
                        options={[
                            { text: "Profile 5", value: "Profile 5" },
                            { text: "Profile 8.1", value: "Profile 8.1" },
                            { text: "Profile 8.4", value: "Profile 8.4" },
                            { text: "ST 2094-10", value: "ST 2094-10" }
                        ]}
                        validate={(value: any) => {
                            if (values.output_colorimetry_parameters.enable_dolby_vision && !value) {
                                return "Required";
                            }
                        }}
                    />
                </Box>
            )}

            {/* HDR Conformance Field, shown based on the selected dynamic range */}
            {values.output_colorimetry_parameters?.dynamic_range === DynamicRange.BT2020HDR && (
                <>
                    <SelectField
                        name="output_colorimetry_parameters.hdr_conformance"
                        label="HDR Conformance"
                        options={Object.values(HDRConformance)
                            .filter((conformance) => {
                                if (!values.output_colorimetry_parameters?.enable_dolby_vision) {
                                    //return everything
                                    return true;
                                }
                                if (
                                    values.output_colorimetry_parameters?.dolby_vision?.profile === "Profile 5" ||
                                    values.output_colorimetry_parameters?.dolby_vision?.profile === "Profile 8.1" ||
                                    values.output_colorimetry_parameters?.dolby_vision?.profile === "ST 2094-10"
                                ) {
                                    return (
                                        conformance === HDRConformance.DVB_HDR10 ||
                                        conformance === HDRConformance.ATSC_HDR10 ||
                                        conformance === HDRConformance.DVB_PQ10
                                    );
                                } else if (values.output_colorimetry_parameters?.dolby_vision?.profile === "Profile 8.4") {
                                    return (
                                        conformance === HDRConformance.DVB_HLG ||
                                        conformance === HDRConformance.ATSC_HLG ||
                                        conformance === HDRConformance.ARIBSTDB67
                                    );
                                }
                            })
                            .map((conformance) => ({ text: conformance, value: conformance }))}
                        validate={(value: any) => {
                            if (values.output_colorimetry_parameters.enable_dolby_vision && !value) {
                                return "Required";
                            }
                        }}
                    />

                    <OnChange name="output_colorimetry_parameters.hdr_conformance">
                        {() => {
                            if (submitError?.data?.errors?.output_colorimetry_parameters?.hdr_conformance) {
                                onFieldChange("output_colorimetry_parameters.hdr_conformance", undefined);
                            }
                            if (submitErrors?.output_colorimetry_parameters?.hdr_conformance) {
                                delete submitErrors.output_colorimetry_parameters.hdr_conformance;
                                form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                            }
                        }}
                    </OnChange>
                </>
            )}

            {/* Conditional HDR parameters */}
            {values.output_colorimetry_parameters?.dynamic_range === DynamicRange.BT2020HDR &&
                values.output_colorimetry_parameters?.hdr_conformance &&
                renderHDRParameters(values.output_colorimetry_parameters.hdr_conformance, values)}
        </Box>
    );
};

export default ColorimetryOptionsForm;
