import { observer } from "mobx-react-lite";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { RootStoreContext } from "api";

type GlobalDialogProps = {
    children?: React.ReactNode;
    title?: string;
    width?: string;
    disableBackdropClick?: boolean;
    disableEscPress?: boolean;
};

const GlobalDialog: React.FC<GlobalDialogProps> = observer(function GlobalDialog() {
    const rootStore = React.useContext(RootStoreContext);

    const { dialog, closeDialog } = rootStore.dialogStore;

    const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick" && dialog.disableBackdropClick) {
            null;
        } else if (reason === "escapeKeyDown" && dialog.disableEscPress) {
            null;
        } else {
            closeDialog();
        }
    };

    return (
        <Dialog open={dialog.open} onClose={handleClose} fullWidth maxWidth={dialog.width}>
            {dialog.title && (
                <DialogTitle>
                    {dialog.title}
                </DialogTitle>
            )}
            {dialog.content}
        </Dialog>
    );
});

export default GlobalDialog;
