import { CalendarToday, CopyAll, Delete, Edit, PlayArrow, SportsScore } from "@mui/icons-material";
import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material";
import { IScheduleOverviewEntry, RootStoreContext, agent } from "api";
import ConfirmationDialog from "components/ConfirmationDialog";
import { format } from "date-fns";
import BasicEditScheduleForm from "features/scheduler/Components/BasicEditScheduleForm";
import ItemTooltip from "features/scheduler/Components/ItemTooltip";
import React, { useContext } from "react";

interface CalendarItemProps {
    item: IScheduleOverviewEntry | any;
    onCopy: (item: any) => void;
}

const CalendarItem: React.FC<CalendarItemProps> = ({ item, onCopy }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };
    return (
        <>
            <Tooltip
                key={item.id}
                placement="right"
                title={
                    <ItemTooltip
                        source_id={item.source_id}
                        source_name={item.from_channel}
                        destination_id={item.destination_id}
                        destination_name={item.to_destination}
                        start_time={item.start_time}
                        end_time={item.end_time}
                        status={item.status}
                    />
                }>
                <Box
                    onClick={(e) => handleClick(e)}
                    sx={{
                        backgroundColor: open
                            ? theme.palette.action.selected
                            : item.status === "Finished"
                            ? theme.palette.background.paper
                            : item.status === "Live"
                            ? theme.palette.success.main
                            : theme.palette.primary.main,
                        borderRadius: 1,
                        fontSize: 12,
                        padding: "4px 5px",
                        lineHeight: 1,
                        mb: "3px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        color: item.status !== "Finished" ? theme.palette.primary.contrastText : theme.palette.text.secondary
                    }}>
                    {item.status === "Finished" ? <SportsScore sx={{ fontSize: 16, mr: "4px" }} /> : null}
                    {item.status === "Live" ? <PlayArrow sx={{ fontSize: 16, mr: "4px" }} /> : null}
                    {item.status === "Scheduled" ? <CalendarToday sx={{ fontSize: 16, mr: "4px" }} /> : null}
                    {format(new Date(item.start_time), "HH:mm")} - {format(new Date(item.end_time), "HH:mm")}
                </Box>
            </Tooltip>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button"
                }}
                sx={{ minWidth: 300, p: 0 }}
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={open}
                onClose={(e) => {
                    handleClose(e);
                }}>
                <Box
                    sx={{
                        pt: 0,
                        pr: 2,
                        pb: 1,
                        pl: 2,
                        fontWeight: "bold"
                    }}>
                    <Box>Start: {format(new Date(item.start_time), "dd/MM/yy @ HH:mm")}</Box>
                    <Box>End: {format(new Date(item.end_time), "dd/MM/yy @ HH:mm")}</Box>
                    <Box>Source: {item.from_channel}</Box>
                    <Box>Destination: {item.to_destination}</Box>
                </Box>
                <Divider />
                <MenuItem
                    sx={{
                        mt: 1
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        onCopy(item);
                        handleClose(event);
                    }}>
                    <ListItemIcon>
                        <CopyAll />
                    </ListItemIcon>
                    <ListItemText>Copy</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleClose(event);
                        openDialog(<BasicEditScheduleForm item={item} schedule_id={item.id} />);
                    }}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleClose(event);
                        openDialog(
                            <ConfirmationDialog
                                title="Delete Schedule"
                                message="Are you sure you want to delete this schedule?"
                                additionalContent={
                                    <Box
                                        sx={{
                                            mt: 1
                                        }}>
                                        <Typography variant="body1">
                                            Start: {format(new Date(item.start_time), "dd/MM/yy @ HH:mm")}
                                        </Typography>
                                        <Typography variant="body1">End: {format(new Date(item.end_time), "dd/MM/yy @ HH:mm")}</Typography>
                                        <Typography variant="body1">Source: {item.from_channel}</Typography>
                                        <Typography variant="body1">Destination: {item.to_destination}</Typography>
                                    </Box>
                                }
                                confirmButtonText="Delete"
                                confirmButtonColor="error"
                                onConfirm={async () => {
                                    await agent.Scheduler.deleteSchedule(item.id);
                                }}
                            />
                        );
                    }}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default CalendarItem;
