import React, { useContext, useEffect } from "react";
import { ICommitments, RootStoreContext } from "api";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { format } from "date-fns";

interface IProps {
    data: ICommitments[] | null;
}

export const CurrentCommitmentsTable: React.FC<IProps> = ({ data }) => {
    const rootStore = useContext(RootStoreContext);
    const { getGeoInformation, geoData } = rootStore.userStore;
    const [currency, setCurrency] = React.useState("$");

    const getCurrency = () => {
        getGeoInformation().then(() => {
            if (geoData?.countryCode === "GB") {
                setCurrency("£");
            } else if (geoData?.countryCode === "US") {
                setCurrency("$");
            } else if (geoData?.countryCode === "EUR") {
                setCurrency("€");
            }
        });
    };

    useEffect(() => {
        getGeoInformation().then((data) => {
            setTimeout(() => {
                if (data.countryCode === "GB") {
                    setCurrency("£");
                } else if (data.countryCode === "US") {
                    setCurrency("$");
                } else if (data.countryCode === "EUR") {
                    setCurrency("€");
                } else {
                    getCurrency();
                }
            }, 2000);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            sx={{
                width: "100%",
                height: "100%",
                marginBottom: 2
            }}>
            <CardContent>
                {data && (
                    <DataGridPro
                        sx={{
                            border: "none"
                        }}
                        columns={[
                            {
                                field: "cloud_provider",
                                headerName: "Cloud Provider",
                                flex: 1
                            },
                            {
                                field: "minimum_commitment_days",
                                headerName: "Term",
                                flex: 0.5,
                                valueGetter: (params) => {
                                    return params.value;
                                },
                                renderCell: (params) => {
                                    return <Typography>{`${params.value} ${params.value === 1 ? "day" : "days"}`}</Typography>;
                                }
                            },
                            {
                                field: "assigned_to_destination_name",
                                headerName: "Current Destination",
                                flex: 1
                            },
                            {
                                field: "status",
                                headerName: "Status",
                                flex: 0.5
                            },
                            {
                                field: "daily_cost",
                                headerName: "Daily Cost",
                                flex: 0.5,
                                valueGetter: (params) => {
                                    return `${currency}${params.value}`;
                                }
                            },
                            {
                                field: "signed_at",
                                headerName: "Commited Date",
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <Typography sx={{ py: 1 }}>
                                            {params.value ? format(new Date(params.value), "dd/MM/yyyy") : ""}
                                        </Typography>
                                    );
                                }
                            },
                            {
                                field: "agreement_ref_number",
                                headerName: "Commitment reference",
                                flex: 1
                            }
                        ]}
                        rows={data}
                        getRowId={(row) => row.agreement_id}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}
                        hideFooter
                        autoHeight={true}
                    />
                )}
            </CardContent>
        </Card>
    );
};
