import { CheckCircle, CrisisAlert, Delete, NotificationImportant } from "@mui/icons-material";
import { Box, Checkbox, IconButton, Stack, TableCell, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { agent, RootStoreContext } from "api";
import OrganisationRoleProvider from "components/OrganisationRoleProvider";
import { format } from "date-fns";
import React, { useContext, useEffect } from "react";
import { ResModel } from "resclient";

interface NotificationItemProps {
    id: number;
    selected: boolean;
    onCheckboxClick: (event) => void;
    onChange: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ id, selected, onCheckboxClick, onChange }) => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;
    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const [wsData, setWsData] = React.useState<any>();
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const rid = "notifications." + managing_org_id + ".notification." + id;

    useEffect(() => {
        let resdata: ResModel | any = {};

        const fetchData = () => {
            client
                .get(rid)
                .then((res) => {
                    resdata = res;
                    res.on("change", () => {
                        setWsData(res.toJSON());
                        setUpdateDate(new Date());
                        onChange();
                    });
                    setWsData(res.toJSON());
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        if (clientConnected) {
            fetchData();
        } else {
            const intervalId = setInterval(() => {
                if (clientConnected) {
                    clearInterval(intervalId);
                    fetchData();
                }
            }, 1000);
        }

        !clientConnected && rootStore.resClientStore.initialize(rootStore.commonStore.token!);

        return () => {
            if (resdata instanceof ResModel) {
                // resdata.off("change", () => {
                //     console.log("unsubscribed");
                // });
            }
        };
    }, [client, clientConnected, rid, rootStore.commonStore.token]);

    useEffect(() => {}, [selected, updateDate]);

    if (!wsData) {
        return null;
    }

    return (
        <TableRow
            sx={{
                backgroundColor: wsData.is_critical && !wsData.read ? theme.palette.error.dark : undefined,
            }}
            selected={selected}>
            <TableCell padding="checkbox">
                <Checkbox
                    checked={selected}
                    onClick={(event) => {
                        onCheckboxClick(event);
                    }}
                />
            </TableCell>
            <TableCell
                sx={{
                    opacity: wsData.read ? 0.6 : 1,
                    color: wsData.is_critical && !wsData.read ? theme.palette.error.contrastText : undefined
                }}>
                {format(new Date(wsData.created_on), "dd/MM/yyyy @ HH:mm:ss")}
            </TableCell>
            <TableCell
                sx={{
                    opacity: wsData.read ? 0.6 : 1,
                    color: wsData.is_critical && !wsData.read ? theme.palette.error.contrastText : undefined
                }}>
                {wsData.is_alerting && (
                    <Tooltip title="Alert Notification" placement="top">
                        <NotificationImportant />
                    </Tooltip>
                )}
            </TableCell>
            <TableCell
                sx={{
                    opacity: wsData.read ? 0.6 : 1,
                    color: wsData.is_critical && !wsData.read ? theme.palette.error.contrastText : undefined,
                    fontWeight: wsData.read ? "normal" : "bold"
                }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box>
                        {wsData.notification}
                        {wsData.environment_name && (
                            <Typography variant="body2" sx={{ opacity: 0.7 }}>
                                Environment: {wsData.environment_name}
                            </Typography>
                        )}
                        {wsData.transcoder_name && (
                            <Typography variant="body2" sx={{ opacity: 0.7 }}>
                                Transcoder: {wsData.transcoder_name}
                            </Typography>
                        )}
                        {wsData.marked_read_by_user_email && (
                            <Typography variant="body2" sx={{ opacity: 0.7 }}>
                                Marked as read by: {wsData.marked_read_by_user_email}
                            </Typography>
                        )}
                    </Box>
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    {!wsData.read && (
                        <Tooltip title="Mark as read" placement="top">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    agent.Notification.markAsRead(id).catch((err) => {
                                        console.warn(err);
                                    });
                                }}>
                                <CheckCircle />
                            </IconButton>
                        </Tooltip>
                    )}
                    <OrganisationRoleProvider roles={["admin", "operator"]}>
                        <Tooltip title="Delete" placement="top">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    agent.Notification.deleteNotification(id).catch((err) => {
                                        console.warn(err);
                                    });
                                }}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </OrganisationRoleProvider>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default NotificationItem;
