import React from "react";
import { RootStoreContext } from "api";

/**
 * Provides access control for a specific module based on the user's permissions.
 * If the user has access to the specified module, the hook will return true.
 * Otherwise, the hook will return false.
 *
 * @param {string} module - The name of the module to check access for.
 * @returns {boolean} True if the user has access to the module, false otherwise.
 *
 * @example
 * // Check if the user has access to the "packager" module
 * const hasAccess = useModuleAccess("packager");
 *
 * if (hasAccess) {
 *   return <h1>Tag Module</h1>;
 * } else {
 *   return null;
 * }
 */

const useModuleAccess = (module: string): boolean => {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    const canAccessModule = (module: string) => {
        if (user && user.modules_available && user.modules_available[module] !== undefined) {
            return user.modules_available[module];
        } else {
            return false;
        }
    };

    return Boolean(user && canAccessModule(module));
};

export default useModuleAccess;
