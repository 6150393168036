import React, { useContext } from "react";
import { useTheme, Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import KnowledgeHub from "features/knowledgeHub";
import { IKnowledgeHubHistory, KnowledgeHubSelectorType, RootStoreContext } from "api";
import { InsertDriveFile } from "@mui/icons-material";

interface IKnowledgeHubMarkdown {
    history?: IKnowledgeHubHistory[];
    setHistory?: React.Dispatch<React.SetStateAction<IKnowledgeHubHistory[]>>;
    content: string;
}

/**
 * `KnowledgeHubMarkdown` is a React functional component that renders markdown content within the context of the Knowledge Hub.
 * It supports custom link handling, where links can either navigate within the application, open a drawer with more content,
 * or open in a new browser tab. Links that are part of the Knowledge Hub are identified by a specific marker in their href attribute
 * and are handled differently from regular links. This component integrates with the application's theme and state management
 * for consistent styling and behavior.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.content - The markdown content to be rendered.
 * @param {IKnowledgeHubHistory[]} [props.history] - An optional array of history objects for navigation purposes.
 * @param {React.Dispatch<React.SetStateAction<IKnowledgeHubHistory[]>>} [props.setHistory] - An optional function to update the navigation history.
 * @returns {React.ReactElement} A React element that renders the provided markdown content with custom link handling.
 *
 * @example
 * ```tsx
 * const markdownContent = "This is a [Knowledge Hub item]({{kb}}/kb_item-123).";
 * <KnowledgeHubMarkdown
 *   content={markdownContent}
 *   history={history}
 *   setHistory={setHistory}
 * />
 * ```
 */
const KnowledgeHubMarkdownComponent: React.FC<IKnowledgeHubMarkdown> = ({ content, history, setHistory }) => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;

    const checkString = (str: string) => str.startsWith("%7B%7Bkb%7D%7D/");
    const removeMarker = (str: string) => str.replace("%7B%7Bkb%7D%7D/", "");
    const isCategory = (str: string) => str.startsWith("kb_cat-");

    const CustomLink = (props) => {
        const handleClick = (event) => {
            event.preventDefault();

            if (!checkString(props.href)) {
                return window.open(props.href, "_blank");
            }

            let id = removeMarker(props.href);

            if (history && setHistory) {
                return setHistory([
                    ...history,
                    {
                        id,
                        type: isCategory(id) ? KnowledgeHubSelectorType.Category : KnowledgeHubSelectorType.Item
                    }
                ]);
            }

            return openDrawer(
                <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3, display: "flex" }}>
                    <KnowledgeHub entry={KnowledgeHubSelectorType.Item} isSidebar entryId={id} />
                </Box>
            );
        };

        return (
            <span
                onClick={handleClick}
                style={{
                    color: theme.palette.primary.main,
                    cursor: "pointer"
                }}>
                {props.children}
            </span>
        );
    };

    const CustomImage = (props) => {
        const src = props.src;
        const isImage = (path: string) => {
            return path.match(/\.(jpeg|jpg|gif|png|bmp|tiff|tif|svg|webp|ico)$/) != null;
        };
        
        const isVideo = (path: string) => {
            return path.match(/\.(mp4|webm|ogg|ogv|avi|mkv|mov|wmv|flv|m4v)$/) != null;
        };

        const isAudio = (path: string) => {
            return path.match(/\.(mp3|wav|ogg|m4a|flac|aac|wma|alac|aiff|opus)$/) != null;
        };

        if (isVideo(src)) {
            return (
                <video controls style={{ maxWidth: "100%" }} src={src}>
                    Your browser does not support the video tag.
                </video>
            );
        }

        if (isAudio(src)) {
            return (
                <audio controls style={{ maxWidth: "100%" }} src={src}>
                    Your browser does not support the audio tag.
                </audio>
            );
        }

        if (!isVideo(src) && !isImage(src) && !isAudio(src)) {
            //then we are a file. Extract the file name and display it
            //the file name is the last part of the path. e.g. /path/to/file.txt -> file.txt
            const fileName = src.split("/").pop();
            return (
                <Box
                    component="a"
                    href={src}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        //fontSize: 16,
                        color: "text.secondary",
                        mt: 1,
                        borderRadius: 1,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "divider",
                        px: 1,
                        py: 0.5,
                        textDecoration: "none",
                        "&:hover": {
                            borderColor: theme => theme.palette.action.hover,
                            color: "text.primary"
                        }
                    }}>
                    <Box>
                        <InsertDriveFile sx={{ fontSize: 26, mt: "6px"}} />
                    </Box>
                    <Box>{fileName}</Box>
                </Box>
            );
        }

        const width = props.alt ? parseInt(props.alt.split("width=")[1]) : undefined;
        const height = props.alt ? parseInt(props.alt.split("height=")[1]) : undefined;
        const cleanAlt = props.alt ? props.alt.split("?")[0] : undefined;
        return <img src={src} alt={cleanAlt} style={{ maxWidth: "100%" }} width={width} height={height} />;
    };

    const CustomPre = (props) => {
        return (
            <pre
                style={{
                    whiteSpace: "break-spaces",
                    wordBreak: "break-word",
                    maxWidth: "100%",
                    overflowX: "auto"
                }}>
                {props.children}
            </pre>
        );
    };

    return (
        <ReactMarkdown
            components={{
                a: ({ node, ...props }) => <CustomLink {...props} />,
                img: ({ node, ...props }) => <CustomImage {...props} />,
                pre: ({ node, ...props }) => <CustomPre {...props} />
            }}>
            {content}
        </ReactMarkdown>
    );
};

KnowledgeHubMarkdownComponent.displayName = "KnowledgeHubMarkdown";
export const KnowledgeHubMarkdown = React.memo(KnowledgeHubMarkdownComponent);
