import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { agent, IEnvironmentAlertDetails, RootStoreContext } from "api";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Badge, Box, Checkbox, Collapse, Divider, Fade, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
    ConfirmDeleteMultipleAlerts,
    ConfirmMuteMultipleAlerts,
    ConfirmUnMuteMultipleAlerts
} from "features/alertsManagement/Components/AlertConfirmationQuestions";
import { ConfirmDialog } from "features/common/ConfirmDialog";
import AlertActionSelector from "features/alertsManagement/Components/AlertActionSelector";
import { AlertTable } from "./Components/AlertTable";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import CellTowerOutlinedIcon from "@mui/icons-material/CellTowerOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useAlert } from "hooks";

const AlertsManagementHome: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getAlerts } = useAlert();
    const { openDialog } = rootStore.dialogStore;
    const [alerts, setAlerts] = useState<IEnvironmentAlertDetails[]>();
    const [alertsSelected, setAlertsSelected] = useState<string[]>([]);
    const [itemsExpanded, setItemsExpanded] = useState<string[]>([]);
    const [itemsTicked, setItemsTicked] = useState<string[]>([]);

    const unMuteUponRecovery = useRef(false);
    const setUnMuteUponRecovery = (newValue: boolean) => {
        unMuteUponRecovery.current = newValue;
    };

    const loadAlerts = () => {
        getAlerts().then((response_data) => {
            let rows: IEnvironmentAlertDetails[] = response_data.alerts;
            setAlerts(rows);
        });
    };

    useEffect(() => {
        loadAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSelectedAlertNames = () => {
        let alert_names: string[] = [];
        alerts!.forEach((row) => {
            row.channel_alerts.forEach((channel_alerts) => {
                channel_alerts.alerts.forEach((alert_entry) => {
                    if (alertsSelected.indexOf(alert_entry.id) > -1) {
                        alert_names.push(`"${alert_entry.alert_name}"`);
                    }
                });
            });
            row.destination_alerts.forEach((destination_alerts) => {
                destination_alerts.alerts.forEach((alert_entry) => {
                    if (alertsSelected.indexOf(alert_entry.id) > -1) {
                        alert_names.push(`"${alert_entry.alert_name}"`);
                    }
                });
            });
        });
        return alert_names;
    };

    const applyChangeToSelected = (action: string) => {
        if (action === "delete") {
            openDialog(
                <ConfirmationDialog
                    title="Confirm Delete Alerts"
                    message="Are you sure you want to delete the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent
                            .getCleanInstance()
                            .delete("/environments/alerts/delete-multiple", { data: { alert_ids: alertsSelected } });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                    }}
                />
            );
        } else if (action === "mute") {
            let numberOfAlertsInOkState = 0;
            alertsSelected.forEach((alertId) => {
                alerts!.forEach((envAlerts) => {
                    envAlerts.channel_alerts.forEach((channel) => {
                        channel.alerts.forEach((alert) => {
                            if (alert.id === alertId && !alert.is_alerting) {
                                numberOfAlertsInOkState++;
                            }
                        });
                    });

                    envAlerts.destination_alerts.forEach((destination) => {
                        destination.alerts.forEach((alert) => {
                            if (alert.id === alertId && !alert.is_alerting) {
                                numberOfAlertsInOkState++;
                            }
                        });
                    });
                });
            });

            openDialog(
                <ConfirmationDialog
                    title="Confirm Mute Alerts"
                    message="Are you sure you want to mute the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent.getCleanInstance().put("/environments/alerts/mute-multiple", {
                            alert_ids: alertsSelected,
                            unmute_upon_recovery: unMuteUponRecovery.current
                        });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                    }}
                />
            );
        } else if (action === "un_mute") {
            openDialog(
                <ConfirmationDialog
                    title="Confirm Un-Mute Alerts"
                    message="Are you sure you want to un-mute the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent.getCleanInstance().put("/environments/alerts/unmute-multiple", { alert_ids: alertsSelected });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                    }}
                />
            );
        }
    };

    const expandItem = (item_name: string) => {
        const item_index = itemsExpanded.indexOf(item_name);
        if (item_index === -1) {
            let new_state = [...itemsExpanded, item_name];
            setItemsExpanded(new_state);
        }
    };

    const collapseItem = (item_name: string) => {
        const item_index = itemsExpanded.indexOf(item_name);
        if (item_index > -1) {
            let new_state = [...itemsExpanded];
            new_state.splice(item_index, 1);
            setItemsExpanded(new_state);
        }
    };

    const tickItem = (item_name: string) => {
        const item_index = itemsTicked.indexOf(item_name);
        if (item_index === -1) {
            let new_state = [...itemsTicked, item_name];
            setItemsTicked(new_state);
        }
    };

    const unTickItem = (item_name: string) => {
        const item_index = itemsTicked.indexOf(item_name);
        if (item_index > -1) {
            let new_state = [...itemsTicked];
            new_state.splice(item_index, 1);
            setItemsTicked(new_state);
        }
    };

    const getAlertIDsInEnv = (env_id: string) => {
        // RETURNS ONLY THE NONE-STOPPED ENV ALERT IDS
        let alert_ids: string[] = [];
        alerts!
            .filter((item) => item.environment_id === env_id && item.environment_status === "running")
            .forEach((item) => {
                //Chan alerts
                item.channel_alerts.forEach((channel) => {
                    channel.alerts.forEach((chanAlert) => {
                        alert_ids.push(chanAlert.id);
                    });
                });
                //Dest alerts
                item.destination_alerts.forEach((destination) => {
                    destination.alerts.forEach((destAlert) => {
                        alert_ids.push(destAlert.id);
                    });
                });
            });
        return alert_ids;
    };

    const getAlertIDsInChannel = (channel_id: string) => {
        // RETURNS ONLY THE NONE-STOPPED ENV ALERT IDS
        let alert_ids: string[] = [];
        alerts!
            .filter((item) => item.environment_status === "running")
            .forEach((item) => {
                //Chan alerts
                item.channel_alerts
                    .filter((channel) => channel.src_ref_code === channel_id)
                    .forEach((channel) => {
                        channel.alerts.forEach((chanAlert) => {
                            alert_ids.push(chanAlert.id);
                        });
                    });
            });
        return alert_ids;
    };

    const addAlertsSelectedByEnvID = (env_id: string) => {
        const c_env_alert_ids = getAlertIDsInEnv(env_id);
        const alert_ids_to_add = c_env_alert_ids.filter((alert_id) => alertsSelected.indexOf(alert_id) === -1);
        const newState = alert_ids_to_add.concat(alertsSelected);
        setAlertsSelected(newState);
    };

    const removeAlertsSelectedByEnvID = (env_id: string) => {
        const c_env_alert_ids = getAlertIDsInEnv(env_id);
        let newState = [...alertsSelected];
        c_env_alert_ids.forEach((alertId) => {
            const itemIndex = newState.indexOf(alertId);
            if (itemIndex > -1) {
                newState.splice(itemIndex, 1);
            }
        });
        setAlertsSelected(newState);
    };

    const addAlertsSelectedByChannelID = (channel_id: string) => {
        const alert_ids = getAlertIDsInChannel(channel_id);
        const alert_ids_to_add = alert_ids.filter((alert_id) => alertsSelected.indexOf(alert_id) === -1);
        const newState = alert_ids_to_add.concat(alertsSelected);
        setAlertsSelected(newState);
    };

    const removeAlertsSelectedByChannelId = (channel_id: string) => {
        const alert_ids = getAlertIDsInChannel(channel_id);
        let newState = [...alertsSelected];
        alert_ids.forEach((alertId) => {
            const itemIndex = newState.indexOf(alertId);
            if (itemIndex > -1) {
                newState.splice(itemIndex, 1);
            }
        });
        setAlertsSelected(newState);
    };

    const getAlertIDsInDestination = (destination_id: string) => {
        let alert_ids: string[] = [];
        alerts!
            .filter((item) => item.environment_status === "running")
            .forEach((item) => {
                //Chan alerts
                item.destination_alerts
                    .filter((destination) => destination.dst_ref_code === destination_id)
                    .forEach((dest) => {
                        dest.alerts.forEach((destAlert) => {
                            alert_ids.push(destAlert.id);
                        });
                    });
            });
        return alert_ids;
    };

    const addAlertsSelectedByDestinationID = (destination_id: string) => {
        const alert_ids = getAlertIDsInDestination(destination_id);
        const alert_ids_to_add = alert_ids.filter((alert_id) => alertsSelected.indexOf(alert_id) === -1);
        const newState = alert_ids_to_add.concat(alertsSelected);
        setAlertsSelected(newState);
    };

    const removeAlertsSelectedByDestinationId = (destination_id: string) => {
        const alert_ids = getAlertIDsInDestination(destination_id);
        let newState = [...alertsSelected];
        alert_ids.forEach((alertId) => {
            const itemIndex = newState.indexOf(alertId);
            if (itemIndex > -1) {
                newState.splice(itemIndex, 1);
            }
        });
        setAlertsSelected(newState);
    };

    const getChanAndDestTickboxNames = (env_id: string, only_running: boolean = true) => {
        // Returns none-stopped env chan and dest names like this: ["chan_0239021340o34", "dest_sidfuusdif"]
        // RETURNS ONLY THE NONE-STOPPED ENV ALERT IDS
        let return_list: string[] = [];
        alerts!
            .filter((item) => {
                return only_running
                    ? item.environment_id === env_id && item.environment_status === "running"
                    : item.environment_id === env_id;
            })
            .forEach((item) => {
                //Channels
                item.channel_alerts.forEach((channel) => {
                    return_list.push(`chan_${channel.src_ref_code}`);
                });
                //Destinations
                item.destination_alerts.forEach((destination) => {
                    return_list.push(`dest_${destination.dst_ref_code}`);
                });
            });
        return return_list;
    };

    const tickChannelsAndDestinationsOnEnvId = (env_id: string) => {
        const select_list = getChanAndDestTickboxNames(env_id);
        const items_to_add = select_list.filter((item_name) => itemsTicked.indexOf(item_name) === -1);
        const newState = items_to_add.concat(itemsTicked);
        newState.push(`env_${env_id}`);
        setItemsTicked(newState);
    };

    const unTickChannelsAndDestinationsOnEnvId = (env_id: string) => {
        const select_list = getChanAndDestTickboxNames(env_id);
        select_list.push(`env_${env_id}`);
        let newState = [...itemsTicked];
        select_list.forEach((item_name) => {
            const itemIndex = newState.indexOf(item_name);
            if (itemIndex > -1) {
                newState.splice(itemIndex, 1);
            }
        });
        setItemsTicked(newState);
    };

    const collapseEnvironmentItems = (env_id: string) => {
        // Here we close all channels and destinations expanded as well as env itself.
        const select_list = getChanAndDestTickboxNames(env_id, false);
        select_list.push(`env_${env_id}`);
        let newState = [...itemsExpanded];
        select_list.forEach((item_name) => {
            const itemIndex = newState.indexOf(item_name);
            if (itemIndex > -1) {
                newState.splice(itemIndex, 1);
            }
        });
        setItemsExpanded(newState);
    };

    return (
        <>
            <Box sx={{ width: "100%", paddingTop: 1, justifyContent: "space-between", display: "flex" }}>
                <Box>
                    {alertsSelected.length > 0 && (
                        // Show action selector if something has been selected
                        <AlertActionSelector
                            onChangeAction={(action) => {
                                applyChangeToSelected(action);
                            }}
                        />
                    )}
                </Box>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{
                        timeout: 600
                    }}
                    placement="bottom"
                    title="Refresh Alerts"
                    sx={{ mr: 2 }}>
                    <Box>
                        <IconButton onClick={loadAlerts} aria-label="Refresh Alerts">
                            <RefreshIcon sx={{ fontSize: "2rem" }} />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>

            {alerts && alerts.length > 0 && (
                <Grid container sx={{ padding: 2 }}>
                    {alerts.map((row: IEnvironmentAlertDetails) => {
                        const envKey = `env_${row.environment_id}`;
                        const envExpanded = itemsExpanded.indexOf(envKey) > -1;
                        const envTicked = itemsTicked.indexOf(envKey) > -1;
                        const envRunning = row.environment_status === "running";
                        const envRunningColor = envRunning ? "#ffffff" : "#606060";

                        return (
                            <Grid item key={row.environment_id} xs={12} sx={{ width: "100%", mb: 1 }}>
                                <Grid
                                    container
                                    xs={12}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (envExpanded) {
                                            collapseEnvironmentItems(row.environment_id);
                                        } else {
                                            expandItem(envKey);
                                        }
                                    }}>
                                    <Grid item xs={8}>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            {envRunning ? (
                                                <Checkbox
                                                    checked={envTicked}
                                                    onClick={() => {
                                                        if (envTicked) {
                                                            removeAlertsSelectedByEnvID(row.environment_id);
                                                            unTickChannelsAndDestinationsOnEnvId(row.environment_id);
                                                        } else {
                                                            addAlertsSelectedByEnvID(row.environment_id);
                                                            tickChannelsAndDestinationsOnEnvId(row.environment_id);
                                                            expandItem(envKey);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <i
                                                    className={"pause icon"}
                                                    style={{ color: "#606060", fontSize: "15px" }}
                                                    title="Environment stopped"
                                                />
                                            )}
                                            <FontAwesomeIcon
                                                icon={["fal", "server"]}
                                                style={{ fontSize: "1.8rem", marginRight: "10px", opacity: envRunning ? 1 : 0.5 }}
                                            />
                                            <Typography variant="h6">{row.environment_human_name}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid container xs justifyContent={"flex-end"} sx={{ alignItems: "center" }}>
                                        {row.total_alerting > 0 && (
                                            <Tooltip
                                                placement="left"
                                                arrow
                                                title={`${row.total_alerting} active alerts in this environment`}
                                                sx={{ mr: 2 }}>
                                                <Badge badgeContent={row.total_alerting} color="error">
                                                    <NotificationsIcon />
                                                </Badge>
                                            </Tooltip>
                                        )}

                                        {envExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </Grid>
                                </Grid>

                                <Collapse
                                    in={envExpanded}
                                    timeout={0}
                                    unmountOnExit
                                    style={{ margin: 0, padding: "0 0 20px 5px", width: "100%" }}>
                                    <Divider />
                                    {row.channel_alerts.map((item) => {
                                        const chanKey = `chan_${item.src_ref_code}`;
                                        const chanExpanded = itemsExpanded.indexOf(chanKey) > -1;
                                        const chanTicked = itemsTicked.indexOf(chanKey) > -1;
                                        return (
                                            <Fragment key={item.src_ref_code}>
                                                <Box sx={{ display: "flex" }}>
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <SubdirectoryArrowRightIcon
                                                            style={{ marginRight: "10px", cursor: "default", color: "#7c7c7c" }}
                                                        />
                                                        {envRunning && (
                                                            <Checkbox
                                                                checked={chanTicked}
                                                                onClick={() => {
                                                                    if (chanTicked) {
                                                                        unTickItem(chanKey);
                                                                        removeAlertsSelectedByChannelId(item.src_ref_code);
                                                                    } else {
                                                                        addAlertsSelectedByChannelID(item.src_ref_code);
                                                                        tickItem(chanKey);
                                                                        expandItem(chanKey);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            alignItems: "center"
                                                        }}
                                                        onClick={() => {
                                                            if (chanExpanded) {
                                                                collapseItem(chanKey);
                                                            } else {
                                                                expandItem(chanKey);
                                                            }
                                                        }}>
                                                        <Box sx={{ display: "flex" }}>
                                                            <VideocamOutlinedIcon
                                                                sx={{
                                                                    float: "left",
                                                                    fontSize: "1.8rem",
                                                                    mr: 1,
                                                                    opacity: envRunning ? 1 : 0.5
                                                                }}
                                                            />
                                                            <Typography variant="h6">{item.src_display_name}</Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex" }}>
                                                            {item.total_alerting > 0 && (
                                                                <Tooltip
                                                                    placement="left"
                                                                    arrow
                                                                    title={`${item.total_alerting} active alerts in this channel`}
                                                                    sx={{ mr: 2 }}>
                                                                    <Badge badgeContent={item.total_alerting} color="error">
                                                                        <NotificationsIcon />
                                                                    </Badge>
                                                                </Tooltip>
                                                            )}
                                                            {chanExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Collapse
                                                    in={chanExpanded}
                                                    timeout={0}
                                                    unmountOnExit
                                                    style={{ margin: 0, padding: "10px 0 20px 35px", width: "100%" }}>
                                                    <AlertTable
                                                        item={item}
                                                        envRunning={envRunning}
                                                        envRunningColor={envRunningColor}
                                                        alertsSelected={alertsSelected}
                                                        setAlertsSelected={setAlertsSelected}
                                                        loadAlerts={loadAlerts}
                                                        unMuteUponRecovery={unMuteUponRecovery}
                                                    />
                                                </Collapse>
                                            </Fragment>
                                        );
                                    })}

                                    {row.destination_alerts.map((item) => {
                                        const destKey = `dest_${item.dst_ref_code}`;
                                        const destExpanded = itemsExpanded.indexOf(destKey) > -1;
                                        const destTicked = itemsTicked.indexOf(destKey) > -1;
                                        return (
                                            <Fragment key={item.dst_ref_code}>
                                                <Box sx={{ display: "flex" }}>
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <SubdirectoryArrowRightIcon
                                                            style={{ marginRight: "10px", cursor: "default", color: "#7c7c7c" }}
                                                        />
                                                        {envRunning && (
                                                            <Checkbox
                                                                checked={destTicked}
                                                                onClick={() => {
                                                                    if (destTicked) {
                                                                        unTickItem(destKey);
                                                                        removeAlertsSelectedByDestinationId(item.dst_ref_code);
                                                                    } else {
                                                                        addAlertsSelectedByDestinationID(item.dst_ref_code);
                                                                        tickItem(destKey);
                                                                        expandItem(destKey);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            alignItems: "center"
                                                        }}
                                                        onClick={() => {
                                                            if (destExpanded) {
                                                                collapseItem(destKey);
                                                            } else {
                                                                expandItem(destKey);
                                                            }
                                                        }}>
                                                        <Box sx={{ display: "flex" }}>
                                                            <CellTowerOutlinedIcon
                                                                sx={{
                                                                    float: "left",
                                                                    fontSize: "1.8rem",
                                                                    mr: 1,
                                                                    opacity: envRunning ? 1 : 0.5
                                                                }}
                                                            />
                                                            <Typography variant="h6">{item.dst_display_name}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: "flex" }}>
                                                            {item.total_alerting > 0 && (
                                                                <Tooltip
                                                                    placement="right"
                                                                    arrow
                                                                    title={`${item.total_alerting} active alerts in this destination`}
                                                                    sx={{ mr: 2 }}>
                                                                    <Badge badgeContent={item.total_alerting} color="error">
                                                                        <NotificationsIcon />
                                                                    </Badge>
                                                                </Tooltip>
                                                            )}
                                                            {destExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Collapse
                                                    in={destExpanded}
                                                    timeout={0}
                                                    unmountOnExit
                                                    style={{ margin: 0, padding: "10px 0 20px 35px", width: "100%" }}>
                                                    <AlertTable
                                                        item={item}
                                                        envRunning={envRunning}
                                                        envRunningColor={envRunningColor}
                                                        alertsSelected={alertsSelected}
                                                        setAlertsSelected={setAlertsSelected}
                                                        loadAlerts={loadAlerts}
                                                        unMuteUponRecovery={unMuteUponRecovery}
                                                    />
                                                </Collapse>
                                            </Fragment>
                                        );
                                    })}
                                </Collapse>
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            <div style={{ textAlign: "center", padding: "40px 0 30px 0", color: "gray" }}>
                <i className="exclamation triangle icon" style={{ fontSize: "14px", marginRight: "10px" }} />
                To set up new alerts please go to alerts on the source or destination information page.
            </div>
        </>
    );
};

export default AlertsManagementHome;
