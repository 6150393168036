export const icons = [
    {
        name: "ad",
        terms: ["advertisement", "media", "newspaper", "promotion", "publicity"],
        label: "Ad"
    },
    {
        name: "address-book",
        terms: ["contact", "directory", "index", "little black book", "rolodex"],
        label: "Address Book"
    },
    {
        name: "address-card",
        terms: ["about", "contact", "id", "identification", "postcard", "profile"],
        label: "Address Card"
    },
    {
        name: "adjust",
        terms: ["contrast", "dark", "light", "saturation"],
        label: "adjust"
    },
    {
        name: "air-freshener",
        terms: ["car", "deodorize", "fresh", "pine", "scent"],
        label: "Air Freshener"
    },
    {
        name: "align-center",
        terms: ["format", "middle", "paragraph", "text"],
        label: "align-center"
    },
    {
        name: "align-justify",
        terms: ["format", "paragraph", "text"],
        label: "align-justify"
    },
    {
        name: "align-left",
        terms: ["format", "paragraph", "text"],
        label: "align-left"
    },
    {
        name: "align-right",
        terms: ["format", "paragraph", "text"],
        label: "align-right"
    },
    {
        name: "allergies",
        terms: ["allergy", "freckles", "hand", "hives", "pox", "skin", "spots"],
        label: "Allergies"
    },
    {
        name: "ambulance",
        terms: ["covid-19", "emergency", "emt", "er", "help", "hospital", "support", "vehicle"],
        label: "ambulance"
    },
    {
        name: "american-sign-language-interpreting",
        terms: ["asl", "deaf", "finger", "hand", "interpret", "speak"],
        label: "American Sign Language Interpreting"
    },
    {
        name: "anchor",
        terms: ["berth", "boat", "dock", "embed", "link", "maritime", "moor", "secure"],
        label: "Anchor"
    },
    {
        name: "angle-double-down",
        terms: ["arrows", "caret", "download", "expand"],
        label: "Angle Double Down"
    },
    {
        name: "angle-double-left",
        terms: ["arrows", "back", "caret", "laquo", "previous", "quote"],
        label: "Angle Double Left"
    },
    {
        name: "angle-double-right",
        terms: ["arrows", "caret", "forward", "more", "next", "quote", "raquo"],
        label: "Angle Double Right"
    },
    {
        name: "angle-double-up",
        terms: ["arrows", "caret", "collapse", "upload"],
        label: "Angle Double Up"
    },
    {
        name: "angle-down",
        terms: ["arrow", "caret", "download", "expand"],
        label: "angle-down"
    },
    {
        name: "angle-left",
        terms: ["arrow", "back", "caret", "less", "previous"],
        label: "angle-left"
    },
    {
        name: "angle-right",
        terms: ["arrow", "care", "forward", "more", "next"],
        label: "angle-right"
    },
    {
        name: "angle-up",
        terms: ["arrow", "caret", "collapse", "upload"],
        label: "angle-up"
    },
    {
        name: "angry",
        terms: ["disapprove", "emoticon", "face", "mad", "upset"],
        label: "Angry Face"
    },
    {
        name: "ankh",
        terms: ["amulet", "copper", "coptic christianity", "copts", "crux ansata", "egypt", "venus"],
        label: "Ankh"
    },
    {
        name: "apple-alt",
        terms: ["fall", "fruit", "fuji", "macintosh", "orchard", "seasonal", "vegan"],
        label: "Fruit Apple"
    },
    {
        name: "archive",
        terms: ["box", "package", "save", "storage"],
        label: "Archive"
    },
    {
        name: "archway",
        terms: ["arc", "monument", "road", "street", "tunnel"],
        label: "Archway"
    },
    {
        name: "arrow-alt-circle-down",
        terms: ["arrow-circle-o-down", "download"],
        label: "Alternate Arrow Circle Down"
    },
    {
        name: "arrow-alt-circle-left",
        terms: ["arrow-circle-o-left", "back", "previous"],
        label: "Alternate Arrow Circle Left"
    },
    {
        name: "arrow-alt-circle-right",
        terms: ["arrow-circle-o-right", "forward", "next"],
        label: "Alternate Arrow Circle Right"
    },
    {
        name: "arrow-alt-circle-up",
        terms: ["arrow-circle-o-up"],
        label: "Alternate Arrow Circle Up"
    },
    {
        name: "arrow-circle-down",
        terms: ["download"],
        label: "Arrow Circle Down"
    },
    {
        name: "arrow-circle-left",
        terms: ["back", "previous"],
        label: "Arrow Circle Left"
    },
    {
        name: "arrow-circle-right",
        terms: ["forward", "next"],
        label: "Arrow Circle Right"
    },
    {
        name: "arrow-circle-up",
        terms: ["upload"],
        label: "Arrow Circle Up"
    },
    {
        name: "arrow-down",
        terms: ["download"],
        label: "arrow-down"
    },
    {
        name: "arrow-left",
        terms: ["back", "previous"],
        label: "arrow-left"
    },
    {
        name: "arrow-right",
        terms: ["forward", "next"],
        label: "arrow-right"
    },
    {
        name: "arrow-up",
        terms: ["forward", "upload"],
        label: "arrow-up"
    },
    {
        name: "arrows-alt",
        terms: ["arrow", "arrows", "bigger", "enlarge", "expand", "fullscreen", "move", "position", "reorder", "resize"],
        label: "Alternate Arrows"
    },
    {
        name: "arrows-alt-h",
        terms: ["arrows-h", "expand", "horizontal", "landscape", "resize", "wide"],
        label: "Alternate Arrows Horizontal"
    },
    {
        name: "arrows-alt-v",
        terms: ["arrows-v", "expand", "portrait", "resize", "tall", "vertical"],
        label: "Alternate Arrows Vertical"
    },
    {
        name: "assistive-listening-systems",
        terms: ["amplify", "audio", "deaf", "ear", "headset", "hearing", "sound"],
        label: "Assistive Listening Systems"
    },
    {
        name: "asterisk",
        terms: ["annotation", "details", "reference", "star"],
        label: "asterisk"
    },
    {
        name: "at",
        terms: ["address", "author", "e-mail", "email", "handle"],
        label: "At"
    },
    {
        name: "atlas",
        terms: ["book", "directions", "geography", "globe", "map", "travel", "wayfinding"],
        label: "Atlas"
    },
    {
        name: "atom",
        terms: ["atheism", "chemistry", "electron", "ion", "isotope", "neutron", "nuclear", "proton", "science"],
        label: "Atom"
    },
    {
        name: "audio-description",
        terms: ["blind", "narration", "video", "visual"],
        label: "Audio Description"
    },
    {
        name: "award",
        terms: ["honor", "praise", "prize", "recognition", "ribbon", "trophy"],
        label: "Award"
    },
    {
        name: "baby",
        terms: ["child", "diaper", "doll", "human", "infant", "kid", "offspring", "person", "sprout"],
        label: "Baby"
    },
    {
        name: "baby-carriage",
        terms: ["buggy", "carrier", "infant", "push", "stroller", "transportation", "walk", "wheels"],
        label: "Baby Carriage"
    },
    {
        name: "backspace",
        terms: ["command", "delete", "erase", "keyboard", "undo"],
        label: "Backspace"
    },
    {
        name: "backward",
        terms: ["previous", "rewind"],
        label: "backward"
    },
    {
        name: "bacon",
        terms: ["blt", "breakfast", "ham", "lard", "meat", "pancetta", "pork", "rasher"],
        label: "Bacon"
    },
    {
        name: "bacteria",
        terms: ["antibiotic", "antibody", "covid-19", "health", "organism", "sick"],
        label: "Bacteria"
    },
    {
        name: "bacterium",
        terms: ["antibiotic", "antibody", "covid-19", "health", "organism", "sick"],
        label: "Bacterium"
    },
    {
        name: "bahai",
        terms: ["bahai", "bahá'í", "star"],
        label: "Bahá'í"
    },
    {
        name: "balance-scale",
        terms: ["balanced", "justice", "legal", "measure", "weight"],
        label: "Balance Scale"
    },
    {
        name: "balance-scale-left",
        terms: ["justice", "legal", "measure", "unbalanced", "weight"],
        label: "Balance Scale (Left-Weighted)"
    },
    {
        name: "balance-scale-right",
        terms: ["justice", "legal", "measure", "unbalanced", "weight"],
        label: "Balance Scale (Right-Weighted)"
    },
    {
        name: "ban",
        terms: ["abort", "ban", "block", "cancel", "delete", "hide", "prohibit", "remove", "stop", "trash"],
        label: "ban"
    },
    {
        name: "band-aid",
        terms: ["bandage", "boo boo", "first aid", "ouch"],
        label: "Band-Aid"
    },
    {
        name: "barcode",
        terms: ["info", "laser", "price", "scan", "upc"],
        label: "barcode"
    },
    {
        name: "bars",
        terms: ["checklist", "drag", "hamburger", "list", "menu", "nav", "navigation", "ol", "reorder", "settings", "todo", "ul"],
        label: "Bars"
    },
    {
        name: "baseball-ball",
        terms: ["foul", "hardball", "league", "leather", "mlb", "softball", "sport"],
        label: "Baseball Ball"
    },
    {
        name: "basketball-ball",
        terms: ["dribble", "dunk", "hoop", "nba"],
        label: "Basketball Ball"
    },
    {
        name: "bath",
        terms: ["clean", "shower", "tub", "wash"],
        label: "Bath"
    },
    {
        name: "battery-empty",
        terms: ["charge", "dead", "power", "status"],
        label: "Battery Empty"
    },
    {
        name: "battery-full",
        terms: ["charge", "power", "status"],
        label: "Battery Full"
    },
    {
        name: "battery-half",
        terms: ["charge", "power", "status"],
        label: "Battery 1/2 Full"
    },
    {
        name: "battery-quarter",
        terms: ["charge", "low", "power", "status"],
        label: "Battery 1/4 Full"
    },
    {
        name: "battery-three-quarters",
        terms: ["charge", "power", "status"],
        label: "Battery 3/4 Full"
    },
    {
        name: "bed",
        terms: ["lodging", "mattress", "rest", "sleep", "travel"],
        label: "Bed"
    },
    {
        name: "beer",
        terms: ["alcohol", "ale", "bar", "beverage", "brewery", "drink", "lager", "liquor", "mug", "stein"],
        label: "beer"
    },
    {
        name: "bell",
        terms: ["alarm", "alert", "chime", "notification", "reminder"],
        label: "bell"
    },
    {
        name: "bell-slash",
        terms: ["alert", "cancel", "disabled", "notification", "off", "reminder"],
        label: "Bell Slash"
    },
    {
        name: "bezier-curve",
        terms: ["curves", "illustrator", "lines", "path", "vector"],
        label: "Bezier Curve"
    },
    {
        name: "bible",
        terms: ["book", "catholicism", "christianity", "god", "holy"],
        label: "Bible"
    },
    {
        name: "bicycle",
        terms: ["bike", "gears", "pedal", "transportation", "vehicle"],
        label: "Bicycle"
    },
    {
        name: "biking",
        terms: ["bicycle", "bike", "cycle", "cycling", "ride", "wheel"],
        label: "Biking"
    },
    {
        name: "binoculars",
        terms: ["glasses", "magnify", "scenic", "spyglass", "view"],
        label: "Binoculars"
    },
    {
        name: "biohazard",
        terms: ["covid-19", "danger", "dangerous", "hazmat", "medical", "radioactive", "toxic", "waste", "zombie"],
        label: "Biohazard"
    },
    {
        name: "birthday-cake",
        terms: ["anniversary", "bakery", "candles", "celebration", "dessert", "frosting", "holiday", "party", "pastry"],
        label: "Birthday Cake"
    },
    {
        name: "blender",
        terms: ["cocktail", "milkshake", "mixer", "puree", "smoothie"],
        label: "Blender"
    },
    {
        name: "blender-phone",
        terms: ["appliance", "cocktail", "communication", "fantasy", "milkshake", "mixer", "puree", "silly", "smoothie"],
        label: "Blender Phone"
    },
    {
        name: "blind",
        terms: ["cane", "disability", "person", "sight"],
        label: "Blind"
    },
    {
        name: "blog",
        terms: ["journal", "log", "online", "personal", "post", "web 2.0", "wordpress", "writing"],
        label: "Blog"
    },
    {
        name: "bold",
        terms: ["emphasis", "format", "text"],
        label: "bold"
    },
    {
        name: "bolt",
        terms: ["electricity", "lightning", "weather", "zap"],
        label: "Lightning Bolt"
    },
    {
        name: "bomb",
        terms: ["error", "explode", "fuse", "grenade", "warning"],
        label: "Bomb"
    },
    {
        name: "bone",
        terms: ["calcium", "dog", "skeletal", "skeleton", "tibia"],
        label: "Bone"
    },
    {
        name: "bong",
        terms: ["aparatus", "cannabis", "marijuana", "pipe", "smoke", "smoking"],
        label: "Bong"
    },
    {
        name: "book",
        terms: ["diary", "documentation", "journal", "library", "read"],
        label: "book"
    },
    {
        name: "book-dead",
        terms: [
            "Dungeons & Dragons",
            "crossbones",
            "d&d",
            "dark arts",
            "death",
            "dnd",
            "documentation",
            "evil",
            "fantasy",
            "halloween",
            "holiday",
            "necronomicon",
            "read",
            "skull",
            "spell"
        ],
        label: "Book of the Dead"
    },
    {
        name: "book-medical",
        terms: ["diary", "documentation", "health", "history", "journal", "library", "read", "record"],
        label: "Medical Book"
    },
    {
        name: "book-open",
        terms: ["flyer", "library", "notebook", "open book", "pamphlet", "reading"],
        label: "Book Open"
    },
    {
        name: "book-reader",
        terms: ["flyer", "library", "notebook", "open book", "pamphlet", "reading"],
        label: "Book Reader"
    },
    {
        name: "bookmark",
        terms: ["favorite", "marker", "read", "remember", "save"],
        label: "bookmark"
    },
    {
        name: "border-all",
        terms: ["cell", "grid", "outline", "stroke", "table"],
        label: "Border All"
    },
    {
        name: "border-none",
        terms: ["cell", "grid", "outline", "stroke", "table"],
        label: "Border None"
    },
    {
        name: "border-style",
        terms: [],
        label: "Border Style"
    },
    {
        name: "bowling-ball",
        terms: ["alley", "candlepin", "gutter", "lane", "strike", "tenpin"],
        label: "Bowling Ball"
    },
    {
        name: "box",
        terms: ["archive", "container", "package", "storage"],
        label: "Box"
    },
    {
        name: "box-open",
        terms: ["archive", "container", "package", "storage", "unpack"],
        label: "Box Open"
    },
    {
        name: "box-tissue",
        terms: ["cough", "covid-19", "kleenex", "mucus", "nose", "sneeze", "snot"],
        label: "Tissue Box"
    },
    {
        name: "boxes",
        terms: ["archives", "inventory", "storage", "warehouse"],
        label: "Boxes"
    },
    {
        name: "braille",
        terms: ["alphabet", "blind", "dots", "raised", "vision"],
        label: "Braille"
    },
    {
        name: "brain",
        terms: ["cerebellum", "gray matter", "intellect", "medulla oblongata", "mind", "noodle", "wit"],
        label: "Brain"
    },
    {
        name: "bread-slice",
        terms: ["bake", "bakery", "baking", "dough", "flour", "gluten", "grain", "sandwich", "sourdough", "toast", "wheat", "yeast"],
        label: "Bread Slice"
    },
    {
        name: "briefcase",
        terms: ["bag", "business", "luggage", "office", "work"],
        label: "Briefcase"
    },
    {
        name: "briefcase-medical",
        terms: ["doctor", "emt", "first aid", "health"],
        label: "Medical Briefcase"
    },
    {
        name: "broadcast-tower",
        terms: ["airwaves", "antenna", "radio", "reception", "waves"],
        label: "Broadcast Tower"
    },
    {
        name: "broom",
        terms: ["clean", "firebolt", "fly", "halloween", "nimbus 2000", "quidditch", "sweep", "witch"],
        label: "Broom"
    },
    {
        name: "brush",
        terms: ["art", "bristles", "color", "handle", "paint"],
        label: "Brush"
    },
    {
        name: "bug",
        terms: ["beetle", "error", "insect", "report"],
        label: "Bug"
    },
    {
        name: "building",
        terms: ["apartment", "business", "city", "company", "office", "work"],
        label: "Building"
    },
    {
        name: "bullhorn",
        terms: ["announcement", "broadcast", "louder", "megaphone", "share"],
        label: "bullhorn"
    },
    {
        name: "bullseye",
        terms: ["archery", "goal", "objective", "target"],
        label: "Bullseye"
    },
    {
        name: "burn",
        terms: ["caliente", "energy", "fire", "flame", "gas", "heat", "hot"],
        label: "Burn"
    },
    {
        name: "bus",
        terms: ["public transportation", "transportation", "travel", "vehicle"],
        label: "Bus"
    },
    {
        name: "bus-alt",
        terms: ["mta", "public transportation", "transportation", "travel", "vehicle"],
        label: "Bus Alt"
    },
    {
        name: "business-time",
        terms: ["alarm", "briefcase", "business socks", "clock", "flight of the conchords", "reminder", "wednesday"],
        label: "Business Time"
    },
    {
        name: "calculator",
        terms: ["abacus", "addition", "arithmetic", "counting", "math", "multiplication", "subtraction"],
        label: "Calculator"
    },
    {
        name: "calendar",
        terms: ["calendar-o", "date", "event", "schedule", "time", "when"],
        label: "Calendar"
    },
    {
        name: "calendar-alt",
        terms: ["calendar", "date", "event", "schedule", "time", "when"],
        label: "Alternate Calendar"
    },
    {
        name: "calendar-check",
        terms: [
            "accept",
            "agree",
            "appointment",
            "confirm",
            "correct",
            "date",
            "done",
            "event",
            "ok",
            "schedule",
            "select",
            "success",
            "tick",
            "time",
            "todo",
            "when"
        ],
        label: "Calendar Check"
    },
    {
        name: "calendar-day",
        terms: ["date", "detail", "event", "focus", "schedule", "single day", "time", "today", "when"],
        label: "Calendar with Day Focus"
    },
    {
        name: "calendar-minus",
        terms: ["calendar", "date", "delete", "event", "negative", "remove", "schedule", "time", "when"],
        label: "Calendar Minus"
    },
    {
        name: "calendar-plus",
        terms: ["add", "calendar", "create", "date", "event", "new", "positive", "schedule", "time", "when"],
        label: "Calendar Plus"
    },
    {
        name: "calendar-times",
        terms: ["archive", "calendar", "date", "delete", "event", "remove", "schedule", "time", "when", "x"],
        label: "Calendar Times"
    },
    {
        name: "calendar-week",
        terms: ["date", "detail", "event", "focus", "schedule", "single week", "time", "today", "when"],
        label: "Calendar with Week Focus"
    },
    {
        name: "camera",
        terms: ["image", "lens", "photo", "picture", "record", "shutter", "video"],
        label: "camera"
    },
    {
        name: "camera-retro",
        terms: ["image", "lens", "photo", "picture", "record", "shutter", "video"],
        label: "Retro Camera"
    },
    {
        name: "campground",
        terms: ["camping", "fall", "outdoors", "teepee", "tent", "tipi"],
        label: "Campground"
    },
    {
        name: "candy-cane",
        terms: ["candy", "christmas", "holiday", "mint", "peppermint", "striped", "xmas"],
        label: "Candy Cane"
    },
    {
        name: "cannabis",
        terms: [
            "bud",
            "chronic",
            "drugs",
            "endica",
            "endo",
            "ganja",
            "marijuana",
            "mary jane",
            "pot",
            "reefer",
            "sativa",
            "spliff",
            "weed",
            "whacky-tabacky"
        ],
        label: "Cannabis"
    },
    {
        name: "capsules",
        terms: ["drugs", "medicine", "pills", "prescription"],
        label: "Capsules"
    },
    {
        name: "car",
        terms: ["auto", "automobile", "sedan", "transportation", "travel", "vehicle"],
        label: "Car"
    },
    {
        name: "car-alt",
        terms: ["auto", "automobile", "sedan", "transportation", "travel", "vehicle"],
        label: "Alternate Car"
    },
    {
        name: "car-battery",
        terms: ["auto", "electric", "mechanic", "power"],
        label: "Car Battery"
    },
    {
        name: "car-crash",
        terms: ["accident", "auto", "automobile", "insurance", "sedan", "transportation", "vehicle", "wreck"],
        label: "Car Crash"
    },
    {
        name: "car-side",
        terms: ["auto", "automobile", "sedan", "transportation", "travel", "vehicle"],
        label: "Car Side"
    },
    {
        name: "caravan",
        terms: ["camper", "motor home", "rv", "trailer", "travel"],
        label: "Caravan"
    },
    {
        name: "caret-down",
        terms: ["arrow", "dropdown", "expand", "menu", "more", "triangle"],
        label: "Caret Down"
    },
    {
        name: "caret-left",
        terms: ["arrow", "back", "previous", "triangle"],
        label: "Caret Left"
    },
    {
        name: "caret-right",
        terms: ["arrow", "forward", "next", "triangle"],
        label: "Caret Right"
    },
    {
        name: "caret-square-down",
        terms: ["arrow", "caret-square-o-down", "dropdown", "expand", "menu", "more", "triangle"],
        label: "Caret Square Down"
    },
    {
        name: "caret-square-left",
        terms: ["arrow", "back", "caret-square-o-left", "previous", "triangle"],
        label: "Caret Square Left"
    },
    {
        name: "caret-square-right",
        terms: ["arrow", "caret-square-o-right", "forward", "next", "triangle"],
        label: "Caret Square Right"
    },
    {
        name: "caret-square-up",
        terms: ["arrow", "caret-square-o-up", "collapse", "triangle", "upload"],
        label: "Caret Square Up"
    },
    {
        name: "caret-up",
        terms: ["arrow", "collapse", "triangle"],
        label: "Caret Up"
    },
    {
        name: "carrot",
        terms: ["bugs bunny", "orange", "vegan", "vegetable"],
        label: "Carrot"
    },
    {
        name: "cart-arrow-down",
        terms: ["download", "save", "shopping"],
        label: "Shopping Cart Arrow Down"
    },
    {
        name: "cart-plus",
        terms: ["add", "create", "new", "positive", "shopping"],
        label: "Add to Shopping Cart"
    },
    {
        name: "cash-register",
        terms: ["buy", "cha-ching", "change", "checkout", "commerce", "leaerboard", "machine", "pay", "payment", "purchase", "store"],
        label: "Cash Register"
    },
    {
        name: "cat",
        terms: ["feline", "halloween", "holiday", "kitten", "kitty", "meow", "pet"],
        label: "Cat"
    },
    {
        name: "certificate",
        terms: ["badge", "star", "verified"],
        label: "certificate"
    },
    {
        name: "chair",
        terms: ["furniture", "seat", "sit"],
        label: "Chair"
    },
    {
        name: "chalkboard",
        terms: ["blackboard", "learning", "school", "teaching", "whiteboard", "writing"],
        label: "Chalkboard"
    },
    {
        name: "chalkboard-teacher",
        terms: ["blackboard", "instructor", "learning", "professor", "school", "whiteboard", "writing"],
        label: "Chalkboard Teacher"
    },
    {
        name: "charging-station",
        terms: ["electric", "ev", "tesla", "vehicle"],
        label: "Charging Station"
    },
    {
        name: "chart-area",
        terms: ["analytics", "area", "chart", "graph"],
        label: "Area Chart"
    },
    {
        name: "chart-bar",
        terms: ["analytics", "bar", "chart", "graph"],
        label: "Bar Chart"
    },
    {
        name: "chart-line",
        terms: ["activity", "analytics", "chart", "dashboard", "gain", "graph", "increase", "line"],
        label: "Line Chart"
    },
    {
        name: "chart-pie",
        terms: ["analytics", "chart", "diagram", "graph", "pie"],
        label: "Pie Chart"
    },
    {
        name: "check",
        terms: [
            "accept",
            "agree",
            "checkmark",
            "confirm",
            "correct",
            "done",
            "notice",
            "notification",
            "notify",
            "ok",
            "select",
            "success",
            "tick",
            "todo",
            "yes"
        ],
        label: "Check"
    },
    {
        name: "check-circle",
        terms: ["accept", "agree", "confirm", "correct", "done", "ok", "select", "success", "tick", "todo", "yes"],
        label: "Check Circle"
    },
    {
        name: "check-double",
        terms: [
            "accept",
            "agree",
            "checkmark",
            "confirm",
            "correct",
            "done",
            "notice",
            "notification",
            "notify",
            "ok",
            "select",
            "success",
            "tick",
            "todo"
        ],
        label: "Double Check"
    },
    {
        name: "check-square",
        terms: ["accept", "agree", "checkmark", "confirm", "correct", "done", "ok", "select", "success", "tick", "todo", "yes"],
        label: "Check Square"
    },
    {
        name: "cheese",
        terms: ["cheddar", "curd", "gouda", "melt", "parmesan", "sandwich", "swiss", "wedge"],
        label: "Cheese"
    },
    {
        name: "chess",
        terms: ["board", "castle", "checkmate", "game", "king", "rook", "strategy", "tournament"],
        label: "Chess"
    },
    {
        name: "chess-bishop",
        terms: ["board", "checkmate", "game", "strategy"],
        label: "Chess Bishop"
    },
    {
        name: "chess-board",
        terms: ["board", "checkmate", "game", "strategy"],
        label: "Chess Board"
    },
    {
        name: "chess-king",
        terms: ["board", "checkmate", "game", "strategy"],
        label: "Chess King"
    },
    {
        name: "chess-knight",
        terms: ["board", "checkmate", "game", "horse", "strategy"],
        label: "Chess Knight"
    },
    {
        name: "chess-pawn",
        terms: ["board", "checkmate", "game", "strategy"],
        label: "Chess Pawn"
    },
    {
        name: "chess-queen",
        terms: ["board", "checkmate", "game", "strategy"],
        label: "Chess Queen"
    },
    {
        name: "chess-rook",
        terms: ["board", "castle", "checkmate", "game", "strategy"],
        label: "Chess Rook"
    },
    {
        name: "chevron-circle-down",
        terms: ["arrow", "download", "dropdown", "menu", "more"],
        label: "Chevron Circle Down"
    },
    {
        name: "chevron-circle-left",
        terms: ["arrow", "back", "previous"],
        label: "Chevron Circle Left"
    },
    {
        name: "chevron-circle-right",
        terms: ["arrow", "forward", "next"],
        label: "Chevron Circle Right"
    },
    {
        name: "chevron-circle-up",
        terms: ["arrow", "collapse", "upload"],
        label: "Chevron Circle Up"
    },
    {
        name: "chevron-down",
        terms: ["arrow", "download", "expand"],
        label: "chevron-down"
    },
    {
        name: "chevron-left",
        terms: ["arrow", "back", "bracket", "previous"],
        label: "chevron-left"
    },
    {
        name: "chevron-right",
        terms: ["arrow", "bracket", "forward", "next"],
        label: "chevron-right"
    },
    {
        name: "chevron-up",
        terms: ["arrow", "collapse", "upload"],
        label: "chevron-up"
    },
    {
        name: "child",
        terms: ["boy", "girl", "kid", "toddler", "young"],
        label: "Child"
    },
    {
        name: "church",
        terms: ["building", "cathedral", "chapel", "community", "religion"],
        label: "Church"
    },
    {
        name: "circle",
        terms: ["circle-thin", "diameter", "dot", "ellipse", "notification", "round"],
        label: "Circle"
    },
    {
        name: "circle-notch",
        terms: ["circle-o-notch", "diameter", "dot", "ellipse", "round", "spinner"],
        label: "Circle Notched"
    },
    {
        name: "city",
        terms: ["buildings", "busy", "skyscrapers", "urban", "windows"],
        label: "City"
    },
    {
        name: "clinic-medical",
        terms: ["covid-19", "doctor", "general practitioner", "hospital", "infirmary", "medicine", "office", "outpatient"],
        label: "Medical Clinic"
    },
    {
        name: "clipboard",
        terms: ["copy", "notes", "paste", "record"],
        label: "Clipboard"
    },
    {
        name: "clipboard-check",
        terms: ["accept", "agree", "confirm", "done", "ok", "select", "success", "tick", "todo", "yes"],
        label: "Clipboard with Check"
    },
    {
        name: "clipboard-list",
        terms: ["checklist", "completed", "done", "finished", "intinerary", "ol", "schedule", "tick", "todo", "ul"],
        label: "Clipboard List"
    },
    {
        name: "clock",
        terms: ["date", "late", "schedule", "time", "timer", "timestamp", "watch"],
        label: "Clock"
    },
    {
        name: "clone",
        terms: ["arrange", "copy", "duplicate", "paste"],
        label: "Clone"
    },
    {
        name: "closed-captioning",
        terms: ["cc", "deaf", "hearing", "subtitle", "subtitling", "text", "video"],
        label: "Closed Captioning"
    },
    {
        name: "cloud",
        terms: ["atmosphere", "fog", "overcast", "save", "upload", "weather"],
        label: "Cloud"
    },
    {
        name: "cloud-download-alt",
        terms: ["download", "export", "save"],
        label: "Alternate Cloud Download"
    },
    {
        name: "cloud-meatball",
        terms: ["FLDSMDFR", "food", "spaghetti", "storm"],
        label: "Cloud with (a chance of) Meatball"
    },
    {
        name: "cloud-moon",
        terms: ["crescent", "evening", "lunar", "night", "partly cloudy", "sky"],
        label: "Cloud with Moon"
    },
    {
        name: "cloud-moon-rain",
        terms: ["crescent", "evening", "lunar", "night", "partly cloudy", "precipitation", "rain", "sky", "storm"],
        label: "Cloud with Moon and Rain"
    },
    {
        name: "cloud-rain",
        terms: ["precipitation", "rain", "sky", "storm"],
        label: "Cloud with Rain"
    },
    {
        name: "cloud-showers-heavy",
        terms: ["precipitation", "rain", "sky", "storm"],
        label: "Cloud with Heavy Showers"
    },
    {
        name: "cloud-sun",
        terms: ["clear", "day", "daytime", "fall", "outdoors", "overcast", "partly cloudy"],
        label: "Cloud with Sun"
    },
    {
        name: "cloud-sun-rain",
        terms: ["day", "overcast", "precipitation", "storm", "summer", "sunshower"],
        label: "Cloud with Sun and Rain"
    },
    {
        name: "cloud-upload-alt",
        terms: ["cloud-upload", "import", "save", "upload"],
        label: "Alternate Cloud Upload"
    },
    {
        name: "cocktail",
        terms: ["alcohol", "beverage", "drink", "gin", "glass", "margarita", "martini", "vodka"],
        label: "Cocktail"
    },
    {
        name: "code",
        terms: ["brackets", "code", "development", "html"],
        label: "Code"
    },
    {
        name: "code-branch",
        terms: ["branch", "code-fork", "fork", "git", "github", "rebase", "svn", "vcs", "version"],
        label: "Code Branch"
    },
    {
        name: "coffee",
        terms: ["beverage", "breakfast", "cafe", "drink", "fall", "morning", "mug", "seasonal", "tea"],
        label: "Coffee"
    },
    {
        name: "cog",
        terms: ["gear", "mechanical", "settings", "sprocket", "wheel"],
        label: "cog"
    },
    {
        name: "cogs",
        terms: ["gears", "mechanical", "settings", "sprocket", "wheel"],
        label: "cogs"
    },
    {
        name: "coins",
        terms: ["currency", "dime", "financial", "gold", "money", "penny"],
        label: "Coins"
    },
    {
        name: "columns",
        terms: ["browser", "dashboard", "organize", "panes", "split"],
        label: "Columns"
    },
    {
        name: "comment",
        terms: ["bubble", "chat", "commenting", "conversation", "feedback", "message", "note", "notification", "sms", "speech", "texting"],
        label: "comment"
    },
    {
        name: "comment-alt",
        terms: ["bubble", "chat", "commenting", "conversation", "feedback", "message", "note", "notification", "sms", "speech", "texting"],
        label: "Alternate Comment"
    },
    {
        name: "comment-dollar",
        terms: [
            "bubble",
            "chat",
            "commenting",
            "conversation",
            "feedback",
            "message",
            "money",
            "note",
            "notification",
            "pay",
            "sms",
            "speech",
            "spend",
            "texting",
            "transfer"
        ],
        label: "Comment Dollar"
    },
    {
        name: "comment-dots",
        terms: [
            "bubble",
            "chat",
            "commenting",
            "conversation",
            "feedback",
            "message",
            "more",
            "note",
            "notification",
            "reply",
            "sms",
            "speech",
            "texting"
        ],
        label: "Comment Dots"
    },
    {
        name: "comment-medical",
        terms: [
            "advice",
            "bubble",
            "chat",
            "commenting",
            "conversation",
            "diagnose",
            "feedback",
            "message",
            "note",
            "notification",
            "prescription",
            "sms",
            "speech",
            "texting"
        ],
        label: "Alternate Medical Chat"
    },
    {
        name: "comment-slash",
        terms: [
            "bubble",
            "cancel",
            "chat",
            "commenting",
            "conversation",
            "feedback",
            "message",
            "mute",
            "note",
            "notification",
            "quiet",
            "sms",
            "speech",
            "texting"
        ],
        label: "Comment Slash"
    },
    {
        name: "comments",
        terms: ["bubble", "chat", "commenting", "conversation", "feedback", "message", "note", "notification", "sms", "speech", "texting"],
        label: "comments"
    },
    {
        name: "comments-dollar",
        terms: [
            "bubble",
            "chat",
            "commenting",
            "conversation",
            "feedback",
            "message",
            "money",
            "note",
            "notification",
            "pay",
            "sms",
            "speech",
            "spend",
            "texting",
            "transfer"
        ],
        label: "Comments Dollar"
    },
    {
        name: "compact-disc",
        terms: ["album", "bluray", "cd", "disc", "dvd", "media", "movie", "music", "record", "video", "vinyl"],
        label: "Compact Disc"
    },
    {
        name: "compass",
        terms: ["directions", "directory", "location", "menu", "navigation", "safari", "travel"],
        label: "Compass"
    },
    {
        name: "compress",
        terms: ["collapse", "fullscreen", "minimize", "move", "resize", "shrink", "smaller"],
        label: "Compress"
    },
    {
        name: "compress-alt",
        terms: ["collapse", "fullscreen", "minimize", "move", "resize", "shrink", "smaller"],
        label: "Alternate Compress"
    },
    {
        name: "compress-arrows-alt",
        terms: ["collapse", "fullscreen", "minimize", "move", "resize", "shrink", "smaller"],
        label: "Alternate Compress Arrows"
    },
    {
        name: "concierge-bell",
        terms: ["attention", "hotel", "receptionist", "service", "support"],
        label: "Concierge Bell"
    },
    {
        name: "cookie",
        terms: ["baked good", "chips", "chocolate", "eat", "snack", "sweet", "treat"],
        label: "Cookie"
    },
    {
        name: "cookie-bite",
        terms: ["baked good", "bitten", "chips", "chocolate", "eat", "snack", "sweet", "treat"],
        label: "Cookie Bite"
    },
    {
        name: "copy",
        terms: ["clone", "duplicate", "file", "files-o", "paper", "paste"],
        label: "Copy"
    },
    {
        name: "copyright",
        terms: ["brand", "mark", "register", "trademark"],
        label: "Copyright"
    },
    {
        name: "couch",
        terms: ["chair", "cushion", "furniture", "relax", "sofa"],
        label: "Couch"
    },
    {
        name: "credit-card",
        terms: ["buy", "checkout", "credit-card-alt", "debit", "money", "payment", "purchase"],
        label: "Credit Card"
    },
    {
        name: "crop",
        terms: ["design", "frame", "mask", "resize", "shrink"],
        label: "crop"
    },
    {
        name: "crop-alt",
        terms: ["design", "frame", "mask", "resize", "shrink"],
        label: "Alternate Crop"
    },
    {
        name: "cross",
        terms: ["catholicism", "christianity", "church", "jesus"],
        label: "Cross"
    },
    {
        name: "crosshairs",
        terms: ["aim", "bullseye", "gpd", "picker", "position"],
        label: "Crosshairs"
    },
    {
        name: "crow",
        terms: ["bird", "bullfrog", "fauna", "halloween", "holiday", "toad"],
        label: "Crow"
    },
    {
        name: "crown",
        terms: ["award", "favorite", "king", "queen", "royal", "tiara"],
        label: "Crown"
    },
    {
        name: "crutch",
        terms: ["cane", "injury", "mobility", "wheelchair"],
        label: "Crutch"
    },
    {
        name: "cube",
        terms: ["3d", "block", "dice", "package", "square", "tesseract"],
        label: "Cube"
    },
    {
        name: "cubes",
        terms: ["3d", "block", "dice", "package", "pyramid", "square", "stack", "tesseract"],
        label: "Cubes"
    },
    {
        name: "cut",
        terms: ["clip", "scissors", "snip"],
        label: "Cut"
    },
    {
        name: "database",
        terms: ["computer", "development", "directory", "memory", "storage"],
        label: "Database"
    },
    {
        name: "deaf",
        terms: ["ear", "hearing", "sign language"],
        label: "Deaf"
    },
    {
        name: "democrat",
        terms: ["american", "democratic party", "donkey", "election", "left", "left-wing", "liberal", "politics", "usa"],
        label: "Democrat"
    },
    {
        name: "desktop",
        terms: ["computer", "cpu", "demo", "desktop", "device", "imac", "machine", "monitor", "pc", "screen"],
        label: "Desktop"
    },
    {
        name: "dharmachakra",
        terms: ["buddhism", "buddhist", "wheel of dharma"],
        label: "Dharmachakra"
    },
    {
        name: "diagnoses",
        terms: ["analyze", "detect", "diagnosis", "examine", "medicine"],
        label: "Diagnoses"
    },
    {
        name: "dice",
        terms: ["chance", "gambling", "game", "roll"],
        label: "Dice"
    },
    {
        name: "dice-d20",
        terms: ["Dungeons & Dragons", "chance", "d&d", "dnd", "fantasy", "gambling", "game", "roll"],
        label: "Dice D20"
    },
    {
        name: "dice-d6",
        terms: ["Dungeons & Dragons", "chance", "d&d", "dnd", "fantasy", "gambling", "game", "roll"],
        label: "Dice D6"
    },
    {
        name: "dice-five",
        terms: ["chance", "gambling", "game", "roll"],
        label: "Dice Five"
    },
    {
        name: "dice-four",
        terms: ["chance", "gambling", "game", "roll"],
        label: "Dice Four"
    },
    {
        name: "dice-one",
        terms: ["chance", "gambling", "game", "roll"],
        label: "Dice One"
    },
    {
        name: "dice-six",
        terms: ["chance", "gambling", "game", "roll"],
        label: "Dice Six"
    },
    {
        name: "dice-three",
        terms: ["chance", "gambling", "game", "roll"],
        label: "Dice Three"
    },
    {
        name: "dice-two",
        terms: ["chance", "gambling", "game", "roll"],
        label: "Dice Two"
    },
    {
        name: "digital-tachograph",
        terms: ["data", "distance", "speed", "tachometer"],
        label: "Digital Tachograph"
    },
    {
        name: "directions",
        terms: ["map", "navigation", "sign", "turn"],
        label: "Directions"
    },
    {
        name: "disease",
        terms: ["bacteria", "cancer", "covid-19", "illness", "infection", "sickness", "virus"],
        label: "Disease"
    },
    {
        name: "divide",
        terms: ["arithmetic", "calculus", "division", "math"],
        label: "Divide"
    },
    {
        name: "dizzy",
        terms: ["dazed", "dead", "disapprove", "emoticon", "face"],
        label: "Dizzy Face"
    },
    {
        name: "dna",
        terms: ["double helix", "genetic", "helix", "molecule", "protein"],
        label: "DNA"
    },
    {
        name: "dog",
        terms: ["animal", "canine", "fauna", "mammal", "pet", "pooch", "puppy", "woof"],
        label: "Dog"
    },
    {
        name: "dollar-sign",
        terms: ["$", "cost", "dollar-sign", "money", "price", "usd"],
        label: "Dollar Sign"
    },
    {
        name: "dolly",
        terms: ["carry", "shipping", "transport"],
        label: "Dolly"
    },
    {
        name: "dolly-flatbed",
        terms: ["carry", "inventory", "shipping", "transport"],
        label: "Dolly Flatbed"
    },
    {
        name: "donate",
        terms: ["contribute", "generosity", "gift", "give"],
        label: "Donate"
    },
    {
        name: "door-closed",
        terms: ["enter", "exit", "locked"],
        label: "Door Closed"
    },
    {
        name: "door-open",
        terms: ["enter", "exit", "welcome"],
        label: "Door Open"
    },
    {
        name: "dot-circle",
        terms: ["bullseye", "notification", "target"],
        label: "Dot Circle"
    },
    {
        name: "dove",
        terms: ["bird", "fauna", "flying", "peace", "war"],
        label: "Dove"
    },
    {
        name: "download",
        terms: ["export", "hard drive", "save", "transfer"],
        label: "Download"
    },
    {
        name: "drafting-compass",
        terms: ["design", "map", "mechanical drawing", "plot", "plotting"],
        label: "Drafting Compass"
    },
    {
        name: "dragon",
        terms: ["Dungeons & Dragons", "d&d", "dnd", "fantasy", "fire", "lizard", "serpent"],
        label: "Dragon"
    },
    {
        name: "draw-polygon",
        terms: ["anchors", "lines", "object", "render", "shape"],
        label: "Draw Polygon"
    },
    {
        name: "drum",
        terms: ["instrument", "music", "percussion", "snare", "sound"],
        label: "Drum"
    },
    {
        name: "drum-steelpan",
        terms: ["calypso", "instrument", "music", "percussion", "reggae", "snare", "sound", "steel", "tropical"],
        label: "Drum Steelpan"
    },
    {
        name: "drumstick-bite",
        terms: ["bone", "chicken", "leg", "meat", "poultry", "turkey"],
        label: "Drumstick with Bite Taken Out"
    },
    {
        name: "dumbbell",
        terms: ["exercise", "gym", "strength", "weight", "weight-lifting"],
        label: "Dumbbell"
    },
    {
        name: "dumpster",
        terms: ["alley", "bin", "commercial", "trash", "waste"],
        label: "Dumpster"
    },
    {
        name: "dumpster-fire",
        terms: ["alley", "bin", "commercial", "danger", "dangerous", "euphemism", "flame", "heat", "hot", "trash", "waste"],
        label: "Dumpster Fire"
    },
    {
        name: "dungeon",
        terms: ["Dungeons & Dragons", "building", "d&d", "dnd", "door", "entrance", "fantasy", "gate"],
        label: "Dungeon"
    },
    {
        name: "edit",
        terms: ["edit", "pen", "pencil", "update", "write"],
        label: "Edit"
    },
    {
        name: "egg",
        terms: ["breakfast", "chicken", "easter", "shell", "yolk"],
        label: "Egg"
    },
    {
        name: "eject",
        terms: ["abort", "cancel", "cd", "discharge"],
        label: "eject"
    },
    {
        name: "ellipsis-h",
        terms: ["dots", "drag", "kebab", "list", "menu", "nav", "navigation", "ol", "reorder", "settings", "ul"],
        label: "Horizontal Ellipsis"
    },
    {
        name: "ellipsis-v",
        terms: ["dots", "drag", "kebab", "list", "menu", "nav", "navigation", "ol", "reorder", "settings", "ul"],
        label: "Vertical Ellipsis"
    },
    {
        name: "envelope",
        terms: ["e-mail", "email", "letter", "mail", "message", "notification", "support"],
        label: "Envelope"
    },
    {
        name: "envelope-open",
        terms: ["e-mail", "email", "letter", "mail", "message", "notification", "support"],
        label: "Envelope Open"
    },
    {
        name: "envelope-open-text",
        terms: ["e-mail", "email", "letter", "mail", "message", "notification", "support"],
        label: "Envelope Open-text"
    },
    {
        name: "envelope-square",
        terms: ["e-mail", "email", "letter", "mail", "message", "notification", "support"],
        label: "Envelope Square"
    },
    {
        name: "equals",
        terms: ["arithmetic", "even", "match", "math"],
        label: "Equals"
    },
    {
        name: "eraser",
        terms: ["art", "delete", "remove", "rubber"],
        label: "eraser"
    },
    {
        name: "ethernet",
        terms: ["cable", "cat 5", "cat 6", "connection", "hardware", "internet", "network", "wired"],
        label: "Ethernet"
    },
    {
        name: "euro-sign",
        terms: ["currency", "dollar", "exchange", "money"],
        label: "Euro Sign"
    },
    {
        name: "exchange-alt",
        terms: ["arrow", "arrows", "exchange", "reciprocate", "return", "swap", "transfer"],
        label: "Alternate Exchange"
    },
    {
        name: "exclamation",
        terms: ["alert", "danger", "error", "important", "notice", "notification", "notify", "problem", "warning"],
        label: "exclamation"
    },
    {
        name: "exclamation-circle",
        terms: ["alert", "danger", "error", "important", "notice", "notification", "notify", "problem", "warning"],
        label: "Exclamation Circle"
    },
    {
        name: "exclamation-triangle",
        terms: ["alert", "danger", "error", "important", "notice", "notification", "notify", "problem", "warning"],
        label: "Exclamation Triangle"
    },
    {
        name: "expand",
        terms: ["bigger", "enlarge", "fullscreen", "resize"],
        label: "Expand"
    },
    {
        name: "expand-alt",
        terms: ["arrows", "bigger", "enlarge", "fullscreen", "resize"],
        label: "Alternate Expand"
    },
    {
        name: "expand-arrows-alt",
        terms: ["bigger", "enlarge", "fullscreen", "move", "resize"],
        label: "Alternate Expand Arrows"
    },
    {
        name: "external-link-alt",
        terms: ["external-link", "new", "open", "share"],
        label: "Alternate External Link"
    },
    {
        name: "external-link-square-alt",
        terms: ["external-link-square", "new", "open", "share"],
        label: "Alternate External Link Square"
    },
    {
        name: "eye",
        terms: ["look", "optic", "see", "seen", "show", "sight", "views", "visible"],
        label: "Eye"
    },
    {
        name: "eye-dropper",
        terms: ["beaker", "clone", "color", "copy", "eyedropper", "pipette"],
        label: "Eye Dropper"
    },
    {
        name: "eye-slash",
        terms: ["blind", "hide", "show", "toggle", "unseen", "views", "visible", "visiblity"],
        label: "Eye Slash"
    },
    {
        name: "fan",
        terms: ["ac", "air conditioning", "blade", "blower", "cool", "hot"],
        label: "Fan"
    },
    {
        name: "fast-backward",
        terms: ["beginning", "first", "previous", "rewind", "start"],
        label: "fast-backward"
    },
    {
        name: "fast-forward",
        terms: ["end", "last", "next"],
        label: "fast-forward"
    },
    {
        name: "faucet",
        terms: ["covid-19", "drip", "house", "hygiene", "kitchen", "sink", "water"],
        label: "Faucet"
    },
    {
        name: "fax",
        terms: ["business", "communicate", "copy", "facsimile", "send"],
        label: "Fax"
    },
    {
        name: "feather",
        terms: ["bird", "light", "plucked", "quill", "write"],
        label: "Feather"
    },
    {
        name: "feather-alt",
        terms: ["bird", "light", "plucked", "quill", "write"],
        label: "Alternate Feather"
    },
    {
        name: "female",
        terms: ["human", "person", "profile", "user", "woman"],
        label: "Female"
    },
    {
        name: "fighter-jet",
        terms: ["airplane", "fast", "fly", "goose", "maverick", "plane", "quick", "top gun", "transportation", "travel"],
        label: "fighter-jet"
    },
    {
        name: "file",
        terms: ["document", "new", "page", "pdf", "resume"],
        label: "File"
    },
    {
        name: "file-alt",
        terms: ["document", "file-text", "invoice", "new", "page", "pdf"],
        label: "Alternate File"
    },
    {
        name: "file-archive",
        terms: [".zip", "bundle", "compress", "compression", "download", "zip"],
        label: "Archive File"
    },
    {
        name: "file-audio",
        terms: ["document", "mp3", "music", "page", "play", "sound"],
        label: "Audio File"
    },
    {
        name: "file-code",
        terms: ["css", "development", "document", "html"],
        label: "Code File"
    },
    {
        name: "file-contract",
        terms: ["agreement", "binding", "document", "legal", "signature"],
        label: "File Contract"
    },
    {
        name: "file-csv",
        terms: ["document", "excel", "numbers", "spreadsheets", "table"],
        label: "File CSV"
    },
    {
        name: "file-download",
        terms: ["document", "export", "save"],
        label: "File Download"
    },
    {
        name: "file-excel",
        terms: ["csv", "document", "numbers", "spreadsheets", "table"],
        label: "Excel File"
    },
    {
        name: "file-export",
        terms: ["download", "save"],
        label: "File Export"
    },
    {
        name: "file-image",
        terms: ["document", "image", "jpg", "photo", "png"],
        label: "Image File"
    },
    {
        name: "file-import",
        terms: ["copy", "document", "send", "upload"],
        label: "File Import"
    },
    {
        name: "file-invoice",
        terms: ["account", "bill", "charge", "document", "payment", "receipt"],
        label: "File Invoice"
    },
    {
        name: "file-invoice-dollar",
        terms: ["$", "account", "bill", "charge", "document", "dollar-sign", "money", "payment", "receipt", "usd"],
        label: "File Invoice with US Dollar"
    },
    {
        name: "file-medical",
        terms: ["document", "health", "history", "prescription", "record"],
        label: "Medical File"
    },
    {
        name: "file-medical-alt",
        terms: ["document", "health", "history", "prescription", "record"],
        label: "Alternate Medical File"
    },
    {
        name: "file-pdf",
        terms: ["acrobat", "document", "preview", "save"],
        label: "PDF File"
    },
    {
        name: "file-powerpoint",
        terms: ["display", "document", "keynote", "presentation"],
        label: "Powerpoint File"
    },
    {
        name: "file-prescription",
        terms: ["document", "drugs", "medical", "medicine", "rx"],
        label: "File Prescription"
    },
    {
        name: "file-signature",
        terms: ["John Hancock", "contract", "document", "name"],
        label: "File Signature"
    },
    {
        name: "file-upload",
        terms: ["document", "import", "page", "save"],
        label: "File Upload"
    },
    {
        name: "file-video",
        terms: ["document", "m4v", "movie", "mp4", "play"],
        label: "Video File"
    },
    {
        name: "file-word",
        terms: ["document", "edit", "page", "text", "writing"],
        label: "Word File"
    },
    {
        name: "fill",
        terms: ["bucket", "color", "paint", "paint bucket"],
        label: "Fill"
    },
    {
        name: "fill-drip",
        terms: ["bucket", "color", "drop", "paint", "paint bucket", "spill"],
        label: "Fill Drip"
    },
    {
        name: "film",
        terms: ["cinema", "movie", "strip", "video"],
        label: "Film"
    },
    {
        name: "filter",
        terms: ["funnel", "options", "separate", "sort"],
        label: "Filter"
    },
    {
        name: "fingerprint",
        terms: ["human", "id", "identification", "lock", "smudge", "touch", "unique", "unlock"],
        label: "Fingerprint"
    },
    {
        name: "fire",
        terms: ["burn", "caliente", "flame", "heat", "hot", "popular"],
        label: "fire"
    },
    {
        name: "fire-alt",
        terms: ["burn", "caliente", "flame", "heat", "hot", "popular"],
        label: "Alternate Fire"
    },
    {
        name: "fire-extinguisher",
        terms: ["burn", "caliente", "fire fighter", "flame", "heat", "hot", "rescue"],
        label: "fire-extinguisher"
    },
    {
        name: "first-aid",
        terms: ["emergency", "emt", "health", "medical", "rescue"],
        label: "First Aid"
    },
    {
        name: "fish",
        terms: ["fauna", "gold", "seafood", "swimming"],
        label: "Fish"
    },
    {
        name: "fist-raised",
        terms: ["Dungeons & Dragons", "d&d", "dnd", "fantasy", "hand", "ki", "monk", "resist", "strength", "unarmed combat"],
        label: "Raised Fist"
    },
    {
        name: "flag",
        terms: ["country", "notice", "notification", "notify", "pole", "report", "symbol"],
        label: "flag"
    },
    {
        name: "flag-checkered",
        terms: ["notice", "notification", "notify", "pole", "racing", "report", "symbol"],
        label: "flag-checkered"
    },
    {
        name: "flag-usa",
        terms: ["betsy ross", "country", "old glory", "stars", "stripes", "symbol"],
        label: "United States of America Flag"
    },
    {
        name: "flask",
        terms: ["beaker", "experimental", "labs", "science"],
        label: "Flask"
    },
    {
        name: "flushed",
        terms: ["embarrassed", "emoticon", "face"],
        label: "Flushed Face"
    },
    {
        name: "folder",
        terms: ["archive", "directory", "document", "file"],
        label: "Folder"
    },
    {
        name: "folder-minus",
        terms: ["archive", "delete", "directory", "document", "file", "negative", "remove"],
        label: "Folder Minus"
    },
    {
        name: "folder-open",
        terms: ["archive", "directory", "document", "empty", "file", "new"],
        label: "Folder Open"
    },
    {
        name: "folder-plus",
        terms: ["add", "archive", "create", "directory", "document", "file", "new", "positive"],
        label: "Folder Plus"
    },
    {
        name: "font",
        terms: ["alphabet", "glyph", "text", "type", "typeface"],
        label: "font"
    },
    {
        name: "font-awesome-logo-full",
        terms: [],
        label: "Font Awesome Full Logo"
    },
    {
        name: "football-ball",
        terms: ["ball", "fall", "nfl", "pigskin", "seasonal"],
        label: "Football Ball"
    },
    {
        name: "forward",
        terms: ["forward", "next", "skip"],
        label: "forward"
    },
    {
        name: "frog",
        terms: ["amphibian", "bullfrog", "fauna", "hop", "kermit", "kiss", "prince", "ribbit", "toad", "wart"],
        label: "Frog"
    },
    {
        name: "frown",
        terms: ["disapprove", "emoticon", "face", "rating", "sad"],
        label: "Frowning Face"
    },
    {
        name: "frown-open",
        terms: ["disapprove", "emoticon", "face", "rating", "sad"],
        label: "Frowning Face With Open Mouth"
    },
    {
        name: "funnel-dollar",
        terms: ["filter", "money", "options", "separate", "sort"],
        label: "Funnel Dollar"
    },
    {
        name: "futbol",
        terms: ["ball", "football", "mls", "soccer"],
        label: "Futbol"
    },
    {
        name: "gamepad",
        terms: ["arcade", "controller", "d-pad", "joystick", "video", "video game"],
        label: "Gamepad"
    },
    {
        name: "gas-pump",
        terms: ["car", "fuel", "gasoline", "petrol"],
        label: "Gas Pump"
    },
    {
        name: "gavel",
        terms: ["hammer", "judge", "law", "lawyer", "opinion"],
        label: "Gavel"
    },
    {
        name: "gem",
        terms: ["diamond", "jewelry", "sapphire", "stone", "treasure"],
        label: "Gem"
    },
    {
        name: "genderless",
        terms: ["androgynous", "asexual", "sexless"],
        label: "Genderless"
    },
    {
        name: "ghost",
        terms: ["apparition", "blinky", "clyde", "floating", "halloween", "holiday", "inky", "pinky", "spirit"],
        label: "Ghost"
    },
    {
        name: "gift",
        terms: ["christmas", "generosity", "giving", "holiday", "party", "present", "wrapped", "xmas"],
        label: "gift"
    },
    {
        name: "gifts",
        terms: ["christmas", "generosity", "giving", "holiday", "party", "present", "wrapped", "xmas"],
        label: "Gifts"
    },
    {
        name: "glass-cheers",
        terms: ["alcohol", "bar", "beverage", "celebration", "champagne", "clink", "drink", "holiday", "new year's eve", "party", "toast"],
        label: "Glass Cheers"
    },
    {
        name: "glass-martini",
        terms: ["alcohol", "bar", "beverage", "drink", "liquor"],
        label: "Martini Glass"
    },
    {
        name: "glass-martini-alt",
        terms: ["alcohol", "bar", "beverage", "drink", "liquor"],
        label: "Alternate Glass Martini"
    },
    {
        name: "glass-whiskey",
        terms: ["alcohol", "bar", "beverage", "bourbon", "drink", "liquor", "neat", "rye", "scotch", "whisky"],
        label: "Glass Whiskey"
    },
    {
        name: "glasses",
        terms: ["hipster", "nerd", "reading", "sight", "spectacles", "vision"],
        label: "Glasses"
    },
    {
        name: "globe",
        terms: [
            "all",
            "coordinates",
            "country",
            "earth",
            "global",
            "gps",
            "language",
            "localize",
            "location",
            "map",
            "online",
            "place",
            "planet",
            "translate",
            "travel",
            "world"
        ],
        label: "Globe"
    },
    {
        name: "globe-africa",
        terms: [
            "all",
            "country",
            "earth",
            "global",
            "gps",
            "language",
            "localize",
            "location",
            "map",
            "online",
            "place",
            "planet",
            "translate",
            "travel",
            "world"
        ],
        label: "Globe with Africa shown"
    },
    {
        name: "globe-americas",
        terms: [
            "all",
            "country",
            "earth",
            "global",
            "gps",
            "language",
            "localize",
            "location",
            "map",
            "online",
            "place",
            "planet",
            "translate",
            "travel",
            "world"
        ],
        label: "Globe with Americas shown"
    },
    {
        name: "globe-asia",
        terms: [
            "all",
            "country",
            "earth",
            "global",
            "gps",
            "language",
            "localize",
            "location",
            "map",
            "online",
            "place",
            "planet",
            "translate",
            "travel",
            "world"
        ],
        label: "Globe with Asia shown"
    },
    {
        name: "globe-europe",
        terms: [
            "all",
            "country",
            "earth",
            "global",
            "gps",
            "language",
            "localize",
            "location",
            "map",
            "online",
            "place",
            "planet",
            "translate",
            "travel",
            "world"
        ],
        label: "Globe with Europe shown"
    },
    {
        name: "golf-ball",
        terms: ["caddy", "eagle", "putt", "tee"],
        label: "Golf Ball"
    },
    {
        name: "gopuram",
        terms: ["building", "entrance", "hinduism", "temple", "tower"],
        label: "Gopuram"
    },
    {
        name: "graduation-cap",
        terms: ["ceremony", "college", "graduate", "learning", "school", "student"],
        label: "Graduation Cap"
    },
    {
        name: "greater-than",
        terms: ["arithmetic", "compare", "math"],
        label: "Greater Than"
    },
    {
        name: "greater-than-equal",
        terms: ["arithmetic", "compare", "math"],
        label: "Greater Than Equal To"
    },
    {
        name: "grimace",
        terms: ["cringe", "emoticon", "face", "teeth"],
        label: "Grimacing Face"
    },
    {
        name: "grin",
        terms: ["emoticon", "face", "laugh", "smile"],
        label: "Grinning Face"
    },
    {
        name: "grin-alt",
        terms: ["emoticon", "face", "laugh", "smile"],
        label: "Alternate Grinning Face"
    },
    {
        name: "grin-beam",
        terms: ["emoticon", "face", "laugh", "smile"],
        label: "Grinning Face With Smiling Eyes"
    },
    {
        name: "grin-beam-sweat",
        terms: ["embarass", "emoticon", "face", "smile"],
        label: "Grinning Face With Sweat"
    },
    {
        name: "grin-hearts",
        terms: ["emoticon", "face", "love", "smile"],
        label: "Smiling Face With Heart-Eyes"
    },
    {
        name: "grin-squint",
        terms: ["emoticon", "face", "laugh", "smile"],
        label: "Grinning Squinting Face"
    },
    {
        name: "grin-squint-tears",
        terms: ["emoticon", "face", "happy", "smile"],
        label: "Rolling on the Floor Laughing"
    },
    {
        name: "grin-stars",
        terms: ["emoticon", "face", "star-struck"],
        label: "Star-Struck"
    },
    {
        name: "grin-tears",
        terms: ["LOL", "emoticon", "face"],
        label: "Face With Tears of Joy"
    },
    {
        name: "grin-tongue",
        terms: ["LOL", "emoticon", "face"],
        label: "Face With Tongue"
    },
    {
        name: "grin-tongue-squint",
        terms: ["LOL", "emoticon", "face"],
        label: "Squinting Face With Tongue"
    },
    {
        name: "grin-tongue-wink",
        terms: ["LOL", "emoticon", "face"],
        label: "Winking Face With Tongue"
    },
    {
        name: "grin-wink",
        terms: ["emoticon", "face", "flirt", "laugh", "smile"],
        label: "Grinning Winking Face"
    },
    {
        name: "grip-horizontal",
        terms: ["affordance", "drag", "drop", "grab", "handle"],
        label: "Grip Horizontal"
    },
    {
        name: "grip-lines",
        terms: ["affordance", "drag", "drop", "grab", "handle"],
        label: "Grip Lines"
    },
    {
        name: "grip-lines-vertical",
        terms: ["affordance", "drag", "drop", "grab", "handle"],
        label: "Grip Lines Vertical"
    },
    {
        name: "grip-vertical",
        terms: ["affordance", "drag", "drop", "grab", "handle"],
        label: "Grip Vertical"
    },
    {
        name: "guitar",
        terms: ["acoustic", "instrument", "music", "rock", "rock and roll", "song", "strings"],
        label: "Guitar"
    },
    {
        name: "h-square",
        terms: ["directions", "emergency", "hospital", "hotel", "map"],
        label: "H Square"
    },
    {
        name: "hamburger",
        terms: ["bacon", "beef", "burger", "burger king", "cheeseburger", "fast food", "grill", "ground beef", "mcdonalds", "sandwich"],
        label: "Hamburger"
    },
    {
        name: "hammer",
        terms: ["admin", "fix", "repair", "settings", "tool"],
        label: "Hammer"
    },
    {
        name: "hamsa",
        terms: ["amulet", "christianity", "islam", "jewish", "judaism", "muslim", "protection"],
        label: "Hamsa"
    },
    {
        name: "hand-holding",
        terms: ["carry", "lift"],
        label: "Hand Holding"
    },
    {
        name: "hand-holding-heart",
        terms: ["carry", "charity", "gift", "lift", "package"],
        label: "Hand Holding Heart"
    },
    {
        name: "hand-holding-medical",
        terms: ["care", "covid-19", "donate", "help"],
        label: "Hand Holding Medical Cross"
    },
    {
        name: "hand-holding-usd",
        terms: ["$", "carry", "dollar sign", "donation", "giving", "lift", "money", "price"],
        label: "Hand Holding US Dollar"
    },
    {
        name: "hand-holding-water",
        terms: ["carry", "covid-19", "drought", "grow", "lift"],
        label: "Hand Holding Water"
    },
    {
        name: "hand-lizard",
        terms: ["game", "roshambo"],
        label: "Lizard (Hand)"
    },
    {
        name: "hand-middle-finger",
        terms: ["flip the bird", "gesture", "hate", "rude"],
        label: "Hand with Middle Finger Raised"
    },
    {
        name: "hand-paper",
        terms: ["game", "halt", "roshambo", "stop"],
        label: "Paper (Hand)"
    },
    {
        name: "hand-peace",
        terms: ["rest", "truce"],
        label: "Peace (Hand)"
    },
    {
        name: "hand-point-down",
        terms: ["finger", "hand-o-down", "point"],
        label: "Hand Pointing Down"
    },
    {
        name: "hand-point-left",
        terms: ["back", "finger", "hand-o-left", "left", "point", "previous"],
        label: "Hand Pointing Left"
    },
    {
        name: "hand-point-right",
        terms: ["finger", "forward", "hand-o-right", "next", "point", "right"],
        label: "Hand Pointing Right"
    },
    {
        name: "hand-point-up",
        terms: ["finger", "hand-o-up", "point"],
        label: "Hand Pointing Up"
    },
    {
        name: "hand-pointer",
        terms: ["arrow", "cursor", "select"],
        label: "Pointer (Hand)"
    },
    {
        name: "hand-rock",
        terms: ["fist", "game", "roshambo"],
        label: "Rock (Hand)"
    },
    {
        name: "hand-scissors",
        terms: ["cut", "game", "roshambo"],
        label: "Scissors (Hand)"
    },
    {
        name: "hand-sparkles",
        terms: ["clean", "covid-19", "hygiene", "magic", "soap", "wash"],
        label: "Hand Sparkles"
    },
    {
        name: "hand-spock",
        terms: ["live long", "prosper", "salute", "star trek", "vulcan"],
        label: "Spock (Hand)"
    },
    {
        name: "hands",
        terms: ["carry", "hold", "lift"],
        label: "Hands"
    },
    {
        name: "hands-helping",
        terms: ["aid", "assistance", "handshake", "partnership", "volunteering"],
        label: "Helping Hands"
    },
    {
        name: "hands-wash",
        terms: ["covid-19", "hygiene", "soap", "wash"],
        label: "Hands Wash"
    },
    {
        name: "handshake",
        terms: ["agreement", "greeting", "meeting", "partnership"],
        label: "Handshake"
    },
    {
        name: "handshake-alt-slash",
        terms: ["broken", "covid-19", "social distance"],
        label: "Handshake Alternate Slash"
    },
    {
        name: "handshake-slash",
        terms: ["broken", "covid-19", "social distance"],
        label: "Handshake Slash"
    },
    {
        name: "hanukiah",
        terms: ["candle", "hanukkah", "jewish", "judaism", "light"],
        label: "Hanukiah"
    },
    {
        name: "hard-hat",
        terms: ["construction", "hardhat", "helmet", "safety"],
        label: "Hard Hat"
    },
    {
        name: "hashtag",
        terms: ["Twitter", "instagram", "pound", "social media", "tag"],
        label: "Hashtag"
    },
    {
        name: "hat-cowboy",
        terms: ["buckaroo", "horse", "jackeroo", "john b.", "old west", "pardner", "ranch", "rancher", "rodeo", "western", "wrangler"],
        label: "Cowboy Hat"
    },
    {
        name: "hat-cowboy-side",
        terms: ["buckaroo", "horse", "jackeroo", "john b.", "old west", "pardner", "ranch", "rancher", "rodeo", "western", "wrangler"],
        label: "Cowboy Hat Side"
    },
    {
        name: "hat-wizard",
        terms: [
            "Dungeons & Dragons",
            "accessory",
            "buckle",
            "clothing",
            "d&d",
            "dnd",
            "fantasy",
            "halloween",
            "head",
            "holiday",
            "mage",
            "magic",
            "pointy",
            "witch"
        ],
        label: "Wizard's Hat"
    },
    {
        name: "hdd",
        terms: ["cpu", "hard drive", "harddrive", "machine", "save", "storage"],
        label: "HDD"
    },
    {
        name: "head-side-cough",
        terms: ["cough", "covid-19", "germs", "lungs", "respiratory", "sick"],
        label: "Head Side Cough"
    },
    {
        name: "head-side-cough-slash",
        terms: ["cough", "covid-19", "germs", "lungs", "respiratory", "sick"],
        label: "Head Side-cough-slash"
    },
    {
        name: "head-side-mask",
        terms: ["breath", "covid-19", "filter", "respirator", "virus"],
        label: "Head Side Mask"
    },
    {
        name: "head-side-virus",
        terms: ["cold", "covid-19", "flu", "sick"],
        label: "Head Side Virus"
    },
    {
        name: "heading",
        terms: ["format", "header", "text", "title"],
        label: "heading"
    },
    {
        name: "headphones",
        terms: ["audio", "listen", "music", "sound", "speaker"],
        label: "headphones"
    },
    {
        name: "headphones-alt",
        terms: ["audio", "listen", "music", "sound", "speaker"],
        label: "Alternate Headphones"
    },
    {
        name: "headset",
        terms: ["audio", "gamer", "gaming", "listen", "live chat", "microphone", "shot caller", "sound", "support", "telemarketer"],
        label: "Headset"
    },
    {
        name: "heart",
        terms: ["favorite", "like", "love", "relationship", "valentine"],
        label: "Heart"
    },
    {
        name: "heart-broken",
        terms: ["breakup", "crushed", "dislike", "dumped", "grief", "love", "lovesick", "relationship", "sad"],
        label: "Heart Broken"
    },
    {
        name: "heartbeat",
        terms: ["ekg", "electrocardiogram", "health", "lifeline", "vital signs"],
        label: "Heartbeat"
    },
    {
        name: "helicopter",
        terms: ["airwolf", "apache", "chopper", "flight", "fly", "travel"],
        label: "Helicopter"
    },
    {
        name: "highlighter",
        terms: ["edit", "marker", "sharpie", "update", "write"],
        label: "Highlighter"
    },
    {
        name: "hiking",
        terms: ["activity", "backpack", "fall", "fitness", "outdoors", "person", "seasonal", "walking"],
        label: "Hiking"
    },
    {
        name: "hippo",
        terms: ["animal", "fauna", "hippopotamus", "hungry", "mammal"],
        label: "Hippo"
    },
    {
        name: "history",
        terms: ["Rewind", "clock", "reverse", "time", "time machine"],
        label: "History"
    },
    {
        name: "hockey-puck",
        terms: ["ice", "nhl", "sport"],
        label: "Hockey Puck"
    },
    {
        name: "holly-berry",
        terms: ["catwoman", "christmas", "decoration", "flora", "halle", "holiday", "ororo munroe", "plant", "storm", "xmas"],
        label: "Holly Berry"
    },
    {
        name: "home",
        terms: ["abode", "building", "house", "main"],
        label: "home"
    },
    {
        name: "horse",
        terms: ["equus", "fauna", "mammmal", "mare", "neigh", "pony"],
        label: "Horse"
    },
    {
        name: "horse-head",
        terms: ["equus", "fauna", "mammmal", "mare", "neigh", "pony"],
        label: "Horse Head"
    },
    {
        name: "hospital",
        terms: ["building", "covid-19", "emergency room", "medical center"],
        label: "hospital"
    },
    {
        name: "hospital-alt",
        terms: ["building", "covid-19", "emergency room", "medical center"],
        label: "Alternate Hospital"
    },
    {
        name: "hospital-symbol",
        terms: ["clinic", "covid-19", "emergency", "map"],
        label: "Hospital Symbol"
    },
    {
        name: "hospital-user",
        terms: ["covid-19", "doctor", "network", "patient", "primary care"],
        label: "Hospital with User"
    },
    {
        name: "hot-tub",
        terms: ["bath", "jacuzzi", "massage", "sauna", "spa"],
        label: "Hot Tub"
    },
    {
        name: "hotdog",
        terms: ["bun", "chili", "frankfurt", "frankfurter", "kosher", "polish", "sandwich", "sausage", "vienna", "weiner"],
        label: "Hot Dog"
    },
    {
        name: "hotel",
        terms: ["building", "inn", "lodging", "motel", "resort", "travel"],
        label: "Hotel"
    },
    {
        name: "hourglass",
        terms: ["hour", "minute", "sand", "stopwatch", "time"],
        label: "Hourglass"
    },
    {
        name: "hourglass-end",
        terms: ["hour", "minute", "sand", "stopwatch", "time"],
        label: "Hourglass End"
    },
    {
        name: "hourglass-half",
        terms: ["hour", "minute", "sand", "stopwatch", "time"],
        label: "Hourglass Half"
    },
    {
        name: "hourglass-start",
        terms: ["hour", "minute", "sand", "stopwatch", "time"],
        label: "Hourglass Start"
    },
    {
        name: "house-damage",
        terms: ["building", "devastation", "disaster", "home", "insurance"],
        label: "Damaged House"
    },
    {
        name: "house-user",
        terms: ["covid-19", "home", "isolation", "quarantine"],
        label: "House User"
    },
    {
        name: "hryvnia",
        terms: ["currency", "money", "ukraine", "ukrainian"],
        label: "Hryvnia"
    },
    {
        name: "i-cursor",
        terms: ["editing", "i-beam", "type", "writing"],
        label: "I Beam Cursor"
    },
    {
        name: "ice-cream",
        terms: ["chocolate", "cone", "dessert", "frozen", "scoop", "sorbet", "vanilla", "yogurt"],
        label: "Ice Cream"
    },
    {
        name: "icicles",
        terms: ["cold", "frozen", "hanging", "ice", "seasonal", "sharp"],
        label: "Icicles"
    },
    {
        name: "icons",
        terms: ["bolt", "emoji", "heart", "image", "music", "photo", "symbols"],
        label: "Icons"
    },
    {
        name: "id-badge",
        terms: ["address", "contact", "identification", "license", "profile"],
        label: "Identification Badge"
    },
    {
        name: "id-card",
        terms: ["contact", "demographics", "document", "identification", "issued", "profile"],
        label: "Identification Card"
    },
    {
        name: "id-card-alt",
        terms: ["contact", "demographics", "document", "identification", "issued", "profile"],
        label: "Alternate Identification Card"
    },
    {
        name: "igloo",
        terms: ["dome", "dwelling", "eskimo", "home", "house", "ice", "snow"],
        label: "Igloo"
    },
    {
        name: "image",
        terms: ["album", "landscape", "photo", "picture"],
        label: "Image"
    },
    {
        name: "images",
        terms: ["album", "landscape", "photo", "picture"],
        label: "Images"
    },
    {
        name: "inbox",
        terms: ["archive", "desk", "email", "mail", "message"],
        label: "inbox"
    },
    {
        name: "indent",
        terms: ["align", "justify", "paragraph", "tab"],
        label: "Indent"
    },
    {
        name: "industry",
        terms: ["building", "factory", "industrial", "manufacturing", "mill", "warehouse"],
        label: "Industry"
    },
    {
        name: "infinity",
        terms: ["eternity", "forever", "math"],
        label: "Infinity"
    },
    {
        name: "info",
        terms: ["details", "help", "information", "more", "support"],
        label: "Info"
    },
    {
        name: "info-circle",
        terms: ["details", "help", "information", "more", "support"],
        label: "Info Circle"
    },
    {
        name: "italic",
        terms: ["edit", "emphasis", "font", "format", "text", "type"],
        label: "italic"
    },
    {
        name: "jedi",
        terms: ["crest", "force", "sith", "skywalker", "star wars", "yoda"],
        label: "Jedi"
    },
    {
        name: "joint",
        terms: ["blunt", "cannabis", "doobie", "drugs", "marijuana", "roach", "smoke", "smoking", "spliff"],
        label: "Joint"
    },
    {
        name: "journal-whills",
        terms: ["book", "force", "jedi", "sith", "star wars", "yoda"],
        label: "Journal of the Whills"
    },
    {
        name: "kaaba",
        terms: ["building", "cube", "islam", "muslim"],
        label: "Kaaba"
    },
    {
        name: "key",
        terms: ["lock", "password", "private", "secret", "unlock"],
        label: "key"
    },
    {
        name: "keyboard",
        terms: ["accessory", "edit", "input", "text", "type", "write"],
        label: "Keyboard"
    },
    {
        name: "khanda",
        terms: ["chakkar", "sikh", "sikhism", "sword"],
        label: "Khanda"
    },
    {
        name: "kiss",
        terms: ["beso", "emoticon", "face", "love", "smooch"],
        label: "Kissing Face"
    },
    {
        name: "kiss-beam",
        terms: ["beso", "emoticon", "face", "love", "smooch"],
        label: "Kissing Face With Smiling Eyes"
    },
    {
        name: "kiss-wink-heart",
        terms: ["beso", "emoticon", "face", "love", "smooch"],
        label: "Face Blowing a Kiss"
    },
    {
        name: "kiwi-bird",
        terms: ["bird", "fauna", "new zealand"],
        label: "Kiwi Bird"
    },
    {
        name: "landmark",
        terms: ["building", "historic", "memorable", "monument", "politics"],
        label: "Landmark"
    },
    {
        name: "language",
        terms: ["dialect", "idiom", "localize", "speech", "translate", "vernacular"],
        label: "Language"
    },
    {
        name: "laptop",
        terms: ["computer", "cpu", "dell", "demo", "device", "mac", "macbook", "machine", "pc"],
        label: "Laptop"
    },
    {
        name: "laptop-code",
        terms: ["computer", "cpu", "dell", "demo", "develop", "device", "mac", "macbook", "machine", "pc"],
        label: "Laptop Code"
    },
    {
        name: "laptop-house",
        terms: ["computer", "covid-19", "device", "office", "remote", "work from home"],
        label: "Laptop House"
    },
    {
        name: "laptop-medical",
        terms: ["computer", "device", "ehr", "electronic health records", "history"],
        label: "Laptop Medical"
    },
    {
        name: "laugh",
        terms: ["LOL", "emoticon", "face", "laugh", "smile"],
        label: "Grinning Face With Big Eyes"
    },
    {
        name: "laugh-beam",
        terms: ["LOL", "emoticon", "face", "happy", "smile"],
        label: "Laugh Face with Beaming Eyes"
    },
    {
        name: "laugh-squint",
        terms: ["LOL", "emoticon", "face", "happy", "smile"],
        label: "Laughing Squinting Face"
    },
    {
        name: "laugh-wink",
        terms: ["LOL", "emoticon", "face", "happy", "smile"],
        label: "Laughing Winking Face"
    },
    {
        name: "layer-group",
        terms: ["arrange", "develop", "layers", "map", "stack"],
        label: "Layer Group"
    },
    {
        name: "leaf",
        terms: ["eco", "flora", "nature", "plant", "vegan"],
        label: "leaf"
    },
    {
        name: "lemon",
        terms: ["citrus", "lemonade", "lime", "tart"],
        label: "Lemon"
    },
    {
        name: "less-than",
        terms: ["arithmetic", "compare", "math"],
        label: "Less Than"
    },
    {
        name: "less-than-equal",
        terms: ["arithmetic", "compare", "math"],
        label: "Less Than Equal To"
    },
    {
        name: "level-down-alt",
        terms: ["arrow", "level-down"],
        label: "Alternate Level Down"
    },
    {
        name: "level-up-alt",
        terms: ["arrow", "level-up"],
        label: "Alternate Level Up"
    },
    {
        name: "life-ring",
        terms: ["coast guard", "help", "overboard", "save", "support"],
        label: "Life Ring"
    },
    {
        name: "lightbulb",
        terms: ["energy", "idea", "inspiration", "light"],
        label: "Lightbulb"
    },
    {
        name: "link",
        terms: ["attach", "attachment", "chain", "connect"],
        label: "Link"
    },
    {
        name: "lira-sign",
        terms: ["currency", "money", "try", "turkish"],
        label: "Turkish Lira Sign"
    },
    {
        name: "list",
        terms: ["checklist", "completed", "done", "finished", "ol", "todo", "ul"],
        label: "List"
    },
    {
        name: "list-alt",
        terms: ["checklist", "completed", "done", "finished", "ol", "todo", "ul"],
        label: "Alternate List"
    },
    {
        name: "list-ol",
        terms: ["checklist", "completed", "done", "finished", "numbers", "ol", "todo", "ul"],
        label: "list-ol"
    },
    {
        name: "list-ul",
        terms: ["checklist", "completed", "done", "finished", "ol", "todo", "ul"],
        label: "list-ul"
    },
    {
        name: "location-arrow",
        terms: ["address", "compass", "coordinate", "direction", "gps", "map", "navigation", "place"],
        label: "location-arrow"
    },
    {
        name: "lock",
        terms: ["admin", "lock", "open", "password", "private", "protect", "security"],
        label: "lock"
    },
    {
        name: "lock-open",
        terms: ["admin", "lock", "open", "password", "private", "protect", "security"],
        label: "Lock Open"
    },
    {
        name: "long-arrow-alt-down",
        terms: ["download", "long-arrow-down"],
        label: "Alternate Long Arrow Down"
    },
    {
        name: "long-arrow-alt-left",
        terms: ["back", "long-arrow-left", "previous"],
        label: "Alternate Long Arrow Left"
    },
    {
        name: "long-arrow-alt-right",
        terms: ["forward", "long-arrow-right", "next"],
        label: "Alternate Long Arrow Right"
    },
    {
        name: "long-arrow-alt-up",
        terms: ["long-arrow-up", "upload"],
        label: "Alternate Long Arrow Up"
    },
    {
        name: "low-vision",
        terms: ["blind", "eye", "sight"],
        label: "Low Vision"
    },
    {
        name: "luggage-cart",
        terms: ["bag", "baggage", "suitcase", "travel"],
        label: "Luggage Cart"
    },
    {
        name: "lungs",
        terms: ["air", "breath", "covid-19", "organ", "respiratory"],
        label: "Lungs"
    },
    {
        name: "lungs-virus",
        terms: ["breath", "covid-19", "respiratory", "sick"],
        label: "Lungs Virus"
    },
    {
        name: "magic",
        terms: ["autocomplete", "automatic", "mage", "magic", "spell", "wand", "witch", "wizard"],
        label: "magic"
    },
    {
        name: "magnet",
        terms: ["Attract", "lodestone", "tool"],
        label: "magnet"
    },
    {
        name: "mail-bulk",
        terms: ["archive", "envelope", "letter", "post office", "postal", "postcard", "send", "stamp", "usps"],
        label: "Mail Bulk"
    },
    {
        name: "male",
        terms: ["human", "man", "person", "profile", "user"],
        label: "Male"
    },
    {
        name: "map",
        terms: [
            "address",
            "coordinates",
            "destination",
            "gps",
            "localize",
            "location",
            "map",
            "navigation",
            "paper",
            "pin",
            "place",
            "point of interest",
            "position",
            "route",
            "travel"
        ],
        label: "Map"
    },
    {
        name: "map-marked",
        terms: [
            "address",
            "coordinates",
            "destination",
            "gps",
            "localize",
            "location",
            "map",
            "navigation",
            "paper",
            "pin",
            "place",
            "point of interest",
            "position",
            "route",
            "travel"
        ],
        label: "Map Marked"
    },
    {
        name: "map-marked-alt",
        terms: [
            "address",
            "coordinates",
            "destination",
            "gps",
            "localize",
            "location",
            "map",
            "navigation",
            "paper",
            "pin",
            "place",
            "point of interest",
            "position",
            "route",
            "travel"
        ],
        label: "Alternate Map Marked"
    },
    {
        name: "map-marker",
        terms: [
            "address",
            "coordinates",
            "destination",
            "gps",
            "localize",
            "location",
            "map",
            "navigation",
            "paper",
            "pin",
            "place",
            "point of interest",
            "position",
            "route",
            "travel"
        ],
        label: "map-marker"
    },
    {
        name: "map-marker-alt",
        terms: [
            "address",
            "coordinates",
            "destination",
            "gps",
            "localize",
            "location",
            "map",
            "navigation",
            "paper",
            "pin",
            "place",
            "point of interest",
            "position",
            "route",
            "travel"
        ],
        label: "Alternate Map Marker"
    },
    {
        name: "map-pin",
        terms: [
            "address",
            "agree",
            "coordinates",
            "destination",
            "gps",
            "localize",
            "location",
            "map",
            "marker",
            "navigation",
            "pin",
            "place",
            "position",
            "travel"
        ],
        label: "Map Pin"
    },
    {
        name: "map-signs",
        terms: ["directions", "directory", "map", "signage", "wayfinding"],
        label: "Map Signs"
    },
    {
        name: "marker",
        terms: ["design", "edit", "sharpie", "update", "write"],
        label: "Marker"
    },
    {
        name: "mars",
        terms: ["male"],
        label: "Mars"
    },
    {
        name: "mars-double",
        terms: [],
        label: "Mars Double"
    },
    {
        name: "mars-stroke",
        terms: [],
        label: "Mars Stroke"
    },
    {
        name: "mars-stroke-h",
        terms: [],
        label: "Mars Stroke Horizontal"
    },
    {
        name: "mars-stroke-v",
        terms: [],
        label: "Mars Stroke Vertical"
    },
    {
        name: "mask",
        terms: ["carnivale", "costume", "disguise", "halloween", "secret", "super hero"],
        label: "Mask"
    },
    {
        name: "medal",
        terms: ["award", "ribbon", "star", "trophy"],
        label: "Medal"
    },
    {
        name: "medkit",
        terms: ["first aid", "firstaid", "health", "help", "support"],
        label: "medkit"
    },
    {
        name: "meh",
        terms: ["emoticon", "face", "neutral", "rating"],
        label: "Neutral Face"
    },
    {
        name: "meh-blank",
        terms: ["emoticon", "face", "neutral", "rating"],
        label: "Face Without Mouth"
    },
    {
        name: "meh-rolling-eyes",
        terms: ["emoticon", "face", "neutral", "rating"],
        label: "Face With Rolling Eyes"
    },
    {
        name: "memory",
        terms: ["DIMM", "RAM", "hardware", "storage", "technology"],
        label: "Memory"
    },
    {
        name: "menorah",
        terms: ["candle", "hanukkah", "jewish", "judaism", "light"],
        label: "Menorah"
    },
    {
        name: "mercury",
        terms: ["transgender"],
        label: "Mercury"
    },
    {
        name: "meteor",
        terms: ["armageddon", "asteroid", "comet", "shooting star", "space"],
        label: "Meteor"
    },
    {
        name: "microchip",
        terms: ["cpu", "hardware", "processor", "technology"],
        label: "Microchip"
    },
    {
        name: "microphone",
        terms: ["audio", "podcast", "record", "sing", "sound", "voice"],
        label: "microphone"
    },
    {
        name: "microphone-alt",
        terms: ["audio", "podcast", "record", "sing", "sound", "voice"],
        label: "Alternate Microphone"
    },
    {
        name: "microphone-alt-slash",
        terms: ["audio", "disable", "mute", "podcast", "record", "sing", "sound", "voice"],
        label: "Alternate Microphone Slash"
    },
    {
        name: "microphone-slash",
        terms: ["audio", "disable", "mute", "podcast", "record", "sing", "sound", "voice"],
        label: "Microphone Slash"
    },
    {
        name: "microscope",
        terms: ["covid-19", "electron", "lens", "optics", "science", "shrink"],
        label: "Microscope"
    },
    {
        name: "minus",
        terms: ["collapse", "delete", "hide", "minify", "negative", "remove", "trash"],
        label: "minus"
    },
    {
        name: "minus-circle",
        terms: ["delete", "hide", "negative", "remove", "shape", "trash"],
        label: "Minus Circle"
    },
    {
        name: "minus-square",
        terms: ["collapse", "delete", "hide", "minify", "negative", "remove", "shape", "trash"],
        label: "Minus Square"
    },
    {
        name: "mitten",
        terms: ["clothing", "cold", "glove", "hands", "knitted", "seasonal", "warmth"],
        label: "Mitten"
    },
    {
        name: "mobile",
        terms: ["apple", "call", "cell phone", "cellphone", "device", "iphone", "number", "screen", "telephone"],
        label: "Mobile Phone"
    },
    {
        name: "mobile-alt",
        terms: ["apple", "call", "cell phone", "cellphone", "device", "iphone", "number", "screen", "telephone"],
        label: "Alternate Mobile"
    },
    {
        name: "money-bill",
        terms: ["buy", "cash", "checkout", "money", "payment", "price", "purchase"],
        label: "Money Bill"
    },
    {
        name: "money-bill-alt",
        terms: ["buy", "cash", "checkout", "money", "payment", "price", "purchase"],
        label: "Alternate Money Bill"
    },
    {
        name: "money-bill-wave",
        terms: ["buy", "cash", "checkout", "money", "payment", "price", "purchase"],
        label: "Wavy Money Bill"
    },
    {
        name: "money-bill-wave-alt",
        terms: ["buy", "cash", "checkout", "money", "payment", "price", "purchase"],
        label: "Alternate Wavy Money Bill"
    },
    {
        name: "money-check",
        terms: ["bank check", "buy", "checkout", "cheque", "money", "payment", "price", "purchase"],
        label: "Money Check"
    },
    {
        name: "money-check-alt",
        terms: ["bank check", "buy", "checkout", "cheque", "money", "payment", "price", "purchase"],
        label: "Alternate Money Check"
    },
    {
        name: "monument",
        terms: ["building", "historic", "landmark", "memorable"],
        label: "Monument"
    },
    {
        name: "moon",
        terms: ["contrast", "crescent", "dark", "lunar", "night"],
        label: "Moon"
    },
    {
        name: "mortar-pestle",
        terms: ["crush", "culinary", "grind", "medical", "mix", "pharmacy", "prescription", "spices"],
        label: "Mortar Pestle"
    },
    {
        name: "mosque",
        terms: ["building", "islam", "landmark", "muslim"],
        label: "Mosque"
    },
    {
        name: "motorcycle",
        terms: ["bike", "machine", "transportation", "vehicle"],
        label: "Motorcycle"
    },
    {
        name: "mountain",
        terms: ["glacier", "hiking", "hill", "landscape", "travel", "view"],
        label: "Mountain"
    },
    {
        name: "mouse",
        terms: ["click", "computer", "cursor", "input", "peripheral"],
        label: "Mouse"
    },
    {
        name: "mouse-pointer",
        terms: ["arrow", "cursor", "select"],
        label: "Mouse Pointer"
    },
    {
        name: "mug-hot",
        terms: ["caliente", "cocoa", "coffee", "cup", "drink", "holiday", "hot chocolate", "steam", "tea", "warmth"],
        label: "Mug Hot"
    },
    {
        name: "music",
        terms: ["lyrics", "melody", "note", "sing", "sound"],
        label: "Music"
    },
    {
        name: "network-wired",
        terms: ["computer", "connect", "ethernet", "internet", "intranet"],
        label: "Wired Network"
    },
    {
        name: "neuter",
        terms: [],
        label: "Neuter"
    },
    {
        name: "newspaper",
        terms: ["article", "editorial", "headline", "journal", "journalism", "news", "press"],
        label: "Newspaper"
    },
    {
        name: "not-equal",
        terms: ["arithmetic", "compare", "math"],
        label: "Not Equal"
    },
    {
        name: "notes-medical",
        terms: ["clipboard", "doctor", "ehr", "health", "history", "records"],
        label: "Medical Notes"
    },
    {
        name: "object-group",
        terms: ["combine", "copy", "design", "merge", "select"],
        label: "Object Group"
    },
    {
        name: "object-ungroup",
        terms: ["copy", "design", "merge", "select", "separate"],
        label: "Object Ungroup"
    },
    {
        name: "oil-can",
        terms: ["auto", "crude", "gasoline", "grease", "lubricate", "petroleum"],
        label: "Oil Can"
    },
    {
        name: "om",
        terms: ["buddhism", "hinduism", "jainism", "mantra"],
        label: "Om"
    },
    {
        name: "otter",
        terms: ["animal", "badger", "fauna", "fur", "mammal", "marten"],
        label: "Otter"
    },
    {
        name: "outdent",
        terms: ["align", "justify", "paragraph", "tab"],
        label: "Outdent"
    },
    {
        name: "pager",
        terms: ["beeper", "cellphone", "communication"],
        label: "Pager"
    },
    {
        name: "paint-brush",
        terms: ["acrylic", "art", "brush", "color", "fill", "paint", "pigment", "watercolor"],
        label: "Paint Brush"
    },
    {
        name: "paint-roller",
        terms: ["acrylic", "art", "brush", "color", "fill", "paint", "pigment", "watercolor"],
        label: "Paint Roller"
    },
    {
        name: "palette",
        terms: ["acrylic", "art", "brush", "color", "fill", "paint", "pigment", "watercolor"],
        label: "Palette"
    },
    {
        name: "pallet",
        terms: ["archive", "box", "inventory", "shipping", "warehouse"],
        label: "Pallet"
    },
    {
        name: "paper-plane",
        terms: ["air", "float", "fold", "mail", "paper", "send"],
        label: "Paper Plane"
    },
    {
        name: "paperclip",
        terms: ["attach", "attachment", "connect", "link"],
        label: "Paperclip"
    },
    {
        name: "parachute-box",
        terms: ["aid", "assistance", "rescue", "supplies"],
        label: "Parachute Box"
    },
    {
        name: "paragraph",
        terms: ["edit", "format", "text", "writing"],
        label: "paragraph"
    },
    {
        name: "parking",
        terms: ["auto", "car", "garage", "meter"],
        label: "Parking"
    },
    {
        name: "passport",
        terms: ["document", "id", "identification", "issued", "travel"],
        label: "Passport"
    },
    {
        name: "pastafarianism",
        terms: ["agnosticism", "atheism", "flying spaghetti monster", "fsm"],
        label: "Pastafarianism"
    },
    {
        name: "paste",
        terms: ["clipboard", "copy", "document", "paper"],
        label: "Paste"
    },
    {
        name: "pause",
        terms: ["hold", "wait"],
        label: "pause"
    },
    {
        name: "pause-circle",
        terms: ["hold", "wait"],
        label: "Pause Circle"
    },
    {
        name: "paw",
        terms: ["animal", "cat", "dog", "pet", "print"],
        label: "Paw"
    },
    {
        name: "peace",
        terms: ["serenity", "tranquility", "truce", "war"],
        label: "Peace"
    },
    {
        name: "pen",
        terms: ["design", "edit", "update", "write"],
        label: "Pen"
    },
    {
        name: "pen-alt",
        terms: ["design", "edit", "update", "write"],
        label: "Alternate Pen"
    },
    {
        name: "pen-fancy",
        terms: ["design", "edit", "fountain pen", "update", "write"],
        label: "Pen Fancy"
    },
    {
        name: "pen-nib",
        terms: ["design", "edit", "fountain pen", "update", "write"],
        label: "Pen Nib"
    },
    {
        name: "pen-square",
        terms: ["edit", "pencil-square", "update", "write"],
        label: "Pen Square"
    },
    {
        name: "pencil-alt",
        terms: ["design", "edit", "pencil", "update", "write"],
        label: "Alternate Pencil"
    },
    {
        name: "pencil-ruler",
        terms: ["design", "draft", "draw", "pencil"],
        label: "Pencil Ruler"
    },
    {
        name: "people-arrows",
        terms: ["covid-19", "personal space", "social distance", "space", "spread", "users"],
        label: "People Arrows"
    },
    {
        name: "people-carry",
        terms: ["box", "carry", "fragile", "help", "movers", "package"],
        label: "People Carry"
    },
    {
        name: "pepper-hot",
        terms: ["buffalo wings", "capsicum", "chili", "chilli", "habanero", "jalapeno", "mexican", "spicy", "tabasco", "vegetable"],
        label: "Hot Pepper"
    },
    {
        name: "percent",
        terms: ["discount", "fraction", "proportion", "rate", "ratio"],
        label: "Percent"
    },
    {
        name: "percentage",
        terms: ["discount", "fraction", "proportion", "rate", "ratio"],
        label: "Percentage"
    },
    {
        name: "person-booth",
        terms: ["changing", "changing room", "election", "human", "person", "vote", "voting"],
        label: "Person Entering Booth"
    },
    {
        name: "phone",
        terms: ["call", "earphone", "number", "support", "telephone", "voice"],
        label: "Phone"
    },
    {
        name: "phone-alt",
        terms: ["call", "earphone", "number", "support", "telephone", "voice"],
        label: "Alternate Phone"
    },
    {
        name: "phone-slash",
        terms: ["call", "cancel", "earphone", "mute", "number", "support", "telephone", "voice"],
        label: "Phone Slash"
    },
    {
        name: "phone-square",
        terms: ["call", "earphone", "number", "support", "telephone", "voice"],
        label: "Phone Square"
    },
    {
        name: "phone-square-alt",
        terms: ["call", "earphone", "number", "support", "telephone", "voice"],
        label: "Alternate Phone Square"
    },
    {
        name: "phone-volume",
        terms: ["call", "earphone", "number", "sound", "support", "telephone", "voice", "volume-control-phone"],
        label: "Phone Volume"
    },
    {
        name: "photo-video",
        terms: ["av", "film", "image", "library", "media"],
        label: "Photo Video"
    },
    {
        name: "piggy-bank",
        terms: ["bank", "save", "savings"],
        label: "Piggy Bank"
    },
    {
        name: "pills",
        terms: ["drugs", "medicine", "prescription", "tablets"],
        label: "Pills"
    },
    {
        name: "pizza-slice",
        terms: [
            "cheese",
            "chicago",
            "italian",
            "mozzarella",
            "new york",
            "pepperoni",
            "pie",
            "slice",
            "teenage mutant ninja turtles",
            "tomato"
        ],
        label: "Pizza Slice"
    },
    {
        name: "place-of-worship",
        terms: ["building", "church", "holy", "mosque", "synagogue"],
        label: "Place of Worship"
    },
    {
        name: "plane",
        terms: ["airplane", "destination", "fly", "location", "mode", "travel", "trip"],
        label: "plane"
    },
    {
        name: "plane-arrival",
        terms: ["airplane", "arriving", "destination", "fly", "land", "landing", "location", "mode", "travel", "trip"],
        label: "Plane Arrival"
    },
    {
        name: "plane-departure",
        terms: ["airplane", "departing", "destination", "fly", "location", "mode", "take off", "taking off", "travel", "trip"],
        label: "Plane Departure"
    },
    {
        name: "plane-slash",
        terms: ["airplane mode", "canceled", "covid-19", "delayed", "grounded", "travel"],
        label: "Plane Slash"
    },
    {
        name: "play",
        terms: ["audio", "music", "playing", "sound", "start", "video"],
        label: "play"
    },
    {
        name: "play-circle",
        terms: ["audio", "music", "playing", "sound", "start", "video"],
        label: "Play Circle"
    },
    {
        name: "plug",
        terms: ["connect", "electric", "online", "power"],
        label: "Plug"
    },
    {
        name: "plus",
        terms: ["add", "create", "expand", "new", "positive", "shape"],
        label: "plus"
    },
    {
        name: "plus-circle",
        terms: ["add", "create", "expand", "new", "positive", "shape"],
        label: "Plus Circle"
    },
    {
        name: "plus-square",
        terms: ["add", "create", "expand", "new", "positive", "shape"],
        label: "Plus Square"
    },
    {
        name: "podcast",
        terms: ["audio", "broadcast", "music", "sound"],
        label: "Podcast"
    },
    {
        name: "poll",
        terms: ["results", "survey", "trend", "vote", "voting"],
        label: "Poll"
    },
    {
        name: "poll-h",
        terms: ["results", "survey", "trend", "vote", "voting"],
        label: "Poll H"
    },
    {
        name: "poo",
        terms: ["crap", "poop", "shit", "smile", "turd"],
        label: "Poo"
    },
    {
        name: "poo-storm",
        terms: ["bolt", "cloud", "euphemism", "lightning", "mess", "poop", "shit", "turd"],
        label: "Poo Storm"
    },
    {
        name: "poop",
        terms: ["crap", "poop", "shit", "smile", "turd"],
        label: "Poop"
    },
    {
        name: "portrait",
        terms: ["id", "image", "photo", "picture", "selfie"],
        label: "Portrait"
    },
    {
        name: "pound-sign",
        terms: ["currency", "gbp", "money"],
        label: "Pound Sign"
    },
    {
        name: "power-off",
        terms: ["cancel", "computer", "on", "reboot", "restart"],
        label: "Power Off"
    },
    {
        name: "pray",
        terms: ["kneel", "preach", "religion", "worship"],
        label: "Pray"
    },
    {
        name: "praying-hands",
        terms: ["kneel", "preach", "religion", "worship"],
        label: "Praying Hands"
    },
    {
        name: "prescription",
        terms: ["drugs", "medical", "medicine", "pharmacy", "rx"],
        label: "Prescription"
    },
    {
        name: "prescription-bottle",
        terms: ["drugs", "medical", "medicine", "pharmacy", "rx"],
        label: "Prescription Bottle"
    },
    {
        name: "prescription-bottle-alt",
        terms: ["drugs", "medical", "medicine", "pharmacy", "rx"],
        label: "Alternate Prescription Bottle"
    },
    {
        name: "print",
        terms: ["business", "copy", "document", "office", "paper"],
        label: "print"
    },
    {
        name: "procedures",
        terms: ["EKG", "bed", "electrocardiogram", "health", "hospital", "life", "patient", "vital"],
        label: "Procedures"
    },
    {
        name: "project-diagram",
        terms: ["chart", "graph", "network", "pert"],
        label: "Project Diagram"
    },
    {
        name: "pump-medical",
        terms: ["anti-bacterial", "clean", "covid-19", "disinfect", "hygiene", "medical grade", "sanitizer", "soap"],
        label: "Pump Medical"
    },
    {
        name: "pump-soap",
        terms: ["anti-bacterial", "clean", "covid-19", "disinfect", "hygiene", "sanitizer", "soap"],
        label: "Pump Soap"
    },
    {
        name: "puzzle-piece",
        terms: ["add-on", "addon", "game", "section"],
        label: "Puzzle Piece"
    },
    {
        name: "qrcode",
        terms: ["barcode", "info", "information", "scan"],
        label: "qrcode"
    },
    {
        name: "question",
        terms: ["help", "information", "support", "unknown"],
        label: "Question"
    },
    {
        name: "question-circle",
        terms: ["help", "information", "support", "unknown"],
        label: "Question Circle"
    },
    {
        name: "quidditch",
        terms: ["ball", "bludger", "broom", "golden snitch", "harry potter", "hogwarts", "quaffle", "sport", "wizard"],
        label: "Quidditch"
    },
    {
        name: "quote-left",
        terms: ["mention", "note", "phrase", "text", "type"],
        label: "quote-left"
    },
    {
        name: "quote-right",
        terms: ["mention", "note", "phrase", "text", "type"],
        label: "quote-right"
    },
    {
        name: "quran",
        terms: ["book", "islam", "muslim", "religion"],
        label: "Quran"
    },
    {
        name: "radiation",
        terms: ["danger", "dangerous", "deadly", "hazard", "nuclear", "radioactive", "warning"],
        label: "Radiation"
    },
    {
        name: "radiation-alt",
        terms: ["danger", "dangerous", "deadly", "hazard", "nuclear", "radioactive", "warning"],
        label: "Alternate Radiation"
    },
    {
        name: "rainbow",
        terms: ["gold", "leprechaun", "prism", "rain", "sky"],
        label: "Rainbow"
    },
    {
        name: "random",
        terms: ["arrows", "shuffle", "sort", "swap", "switch", "transfer"],
        label: "random"
    },
    {
        name: "receipt",
        terms: ["check", "invoice", "money", "pay", "table"],
        label: "Receipt"
    },
    {
        name: "record-vinyl",
        terms: ["LP", "album", "analog", "music", "phonograph", "sound"],
        label: "Record Vinyl"
    },
    {
        name: "recycle",
        terms: ["Waste", "compost", "garbage", "reuse", "trash"],
        label: "Recycle"
    },
    {
        name: "redo",
        terms: ["forward", "refresh", "reload", "repeat"],
        label: "Redo"
    },
    {
        name: "redo-alt",
        terms: ["forward", "refresh", "reload", "repeat"],
        label: "Alternate Redo"
    },
    {
        name: "registered",
        terms: ["copyright", "mark", "trademark"],
        label: "Registered Trademark"
    },
    {
        name: "remove-format",
        terms: ["cancel", "font", "format", "remove", "style", "text"],
        label: "Remove Format"
    },
    {
        name: "reply",
        terms: ["mail", "message", "respond"],
        label: "Reply"
    },
    {
        name: "reply-all",
        terms: ["mail", "message", "respond"],
        label: "reply-all"
    },
    {
        name: "republican",
        terms: ["american", "conservative", "election", "elephant", "politics", "republican party", "right", "right-wing", "usa"],
        label: "Republican"
    },
    {
        name: "restroom",
        terms: ["bathroom", "john", "loo", "potty", "washroom", "waste", "wc"],
        label: "Restroom"
    },
    {
        name: "retweet",
        terms: ["refresh", "reload", "share", "swap"],
        label: "Retweet"
    },
    {
        name: "ribbon",
        terms: ["badge", "cause", "lapel", "pin"],
        label: "Ribbon"
    },
    {
        name: "ring",
        terms: [
            "Dungeons & Dragons",
            "Gollum",
            "band",
            "binding",
            "d&d",
            "dnd",
            "engagement",
            "fantasy",
            "gold",
            "jewelry",
            "marriage",
            "precious"
        ],
        label: "Ring"
    },
    {
        name: "road",
        terms: ["highway", "map", "pavement", "route", "street", "travel"],
        label: "road"
    },
    {
        name: "robot",
        terms: ["android", "automate", "computer", "cyborg"],
        label: "Robot"
    },
    {
        name: "rocket",
        terms: ["aircraft", "app", "jet", "launch", "nasa", "space"],
        label: "rocket"
    },
    {
        name: "route",
        terms: ["directions", "navigation", "travel"],
        label: "Route"
    },
    {
        name: "rss",
        terms: ["blog", "feed", "journal", "news", "writing"],
        label: "rss"
    },
    {
        name: "rss-square",
        terms: ["blog", "feed", "journal", "news", "writing"],
        label: "RSS Square"
    },
    {
        name: "ruble-sign",
        terms: ["currency", "money", "rub"],
        label: "Ruble Sign"
    },
    {
        name: "ruler",
        terms: ["design", "draft", "length", "measure", "planning"],
        label: "Ruler"
    },
    {
        name: "ruler-combined",
        terms: ["design", "draft", "length", "measure", "planning"],
        label: "Ruler Combined"
    },
    {
        name: "ruler-horizontal",
        terms: ["design", "draft", "length", "measure", "planning"],
        label: "Ruler Horizontal"
    },
    {
        name: "ruler-vertical",
        terms: ["design", "draft", "length", "measure", "planning"],
        label: "Ruler Vertical"
    },
    {
        name: "running",
        terms: ["exercise", "health", "jog", "person", "run", "sport", "sprint"],
        label: "Running"
    },
    {
        name: "rupee-sign",
        terms: ["currency", "indian", "inr", "money"],
        label: "Indian Rupee Sign"
    },
    {
        name: "sad-cry",
        terms: ["emoticon", "face", "tear", "tears"],
        label: "Crying Face"
    },
    {
        name: "sad-tear",
        terms: ["emoticon", "face", "tear", "tears"],
        label: "Loudly Crying Face"
    },
    {
        name: "satellite",
        terms: ["communications", "hardware", "orbit", "space"],
        label: "Satellite"
    },
    {
        name: "satellite-dish",
        terms: ["SETI", "communications", "hardware", "receiver", "saucer", "signal", "space"],
        label: "Satellite Dish"
    },
    {
        name: "save",
        terms: ["disk", "download", "floppy", "floppy-o"],
        label: "Save"
    },
    {
        name: "school",
        terms: ["building", "education", "learn", "student", "teacher"],
        label: "School"
    },
    {
        name: "screwdriver",
        terms: ["admin", "fix", "mechanic", "repair", "settings", "tool"],
        label: "Screwdriver"
    },
    {
        name: "scroll",
        terms: ["Dungeons & Dragons", "announcement", "d&d", "dnd", "fantasy", "paper", "script"],
        label: "Scroll"
    },
    {
        name: "sd-card",
        terms: ["image", "memory", "photo", "save"],
        label: "Sd Card"
    },
    {
        name: "search",
        terms: ["bigger", "enlarge", "find", "magnify", "preview", "zoom"],
        label: "Search"
    },
    {
        name: "search-dollar",
        terms: ["bigger", "enlarge", "find", "magnify", "money", "preview", "zoom"],
        label: "Search Dollar"
    },
    {
        name: "search-location",
        terms: ["bigger", "enlarge", "find", "magnify", "preview", "zoom"],
        label: "Search Location"
    },
    {
        name: "search-minus",
        terms: ["minify", "negative", "smaller", "zoom", "zoom out"],
        label: "Search Minus"
    },
    {
        name: "search-plus",
        terms: ["bigger", "enlarge", "magnify", "positive", "zoom", "zoom in"],
        label: "Search Plus"
    },
    {
        name: "seedling",
        terms: ["flora", "grow", "plant", "vegan"],
        label: "Seedling"
    },
    {
        name: "server",
        terms: ["computer", "cpu", "database", "hardware", "network"],
        label: "Server"
    },
    {
        name: "shapes",
        terms: ["blocks", "build", "circle", "square", "triangle"],
        label: "Shapes"
    },
    {
        name: "share",
        terms: ["forward", "save", "send", "social"],
        label: "Share"
    },
    {
        name: "share-alt",
        terms: ["forward", "save", "send", "social"],
        label: "Alternate Share"
    },
    {
        name: "share-alt-square",
        terms: ["forward", "save", "send", "social"],
        label: "Alternate Share Square"
    },
    {
        name: "share-square",
        terms: ["forward", "save", "send", "social"],
        label: "Share Square"
    },
    {
        name: "shekel-sign",
        terms: ["currency", "ils", "money"],
        label: "Shekel Sign"
    },
    {
        name: "shield-alt",
        terms: ["achievement", "award", "block", "defend", "security", "winner"],
        label: "Alternate Shield"
    },
    {
        name: "shield-virus",
        terms: ["antibodies", "barrier", "covid-19", "health", "protect"],
        label: "Shield Virus"
    },
    {
        name: "ship",
        terms: ["boat", "sea", "water"],
        label: "Ship"
    },
    {
        name: "shipping-fast",
        terms: ["express", "fedex", "mail", "overnight", "package", "ups"],
        label: "Shipping Fast"
    },
    {
        name: "shoe-prints",
        terms: ["feet", "footprints", "steps", "walk"],
        label: "Shoe Prints"
    },
    {
        name: "shopping-bag",
        terms: ["buy", "checkout", "grocery", "payment", "purchase"],
        label: "Shopping Bag"
    },
    {
        name: "shopping-basket",
        terms: ["buy", "checkout", "grocery", "payment", "purchase"],
        label: "Shopping Basket"
    },
    {
        name: "shopping-cart",
        terms: ["buy", "checkout", "grocery", "payment", "purchase"],
        label: "shopping-cart"
    },
    {
        name: "shower",
        terms: ["bath", "clean", "faucet", "water"],
        label: "Shower"
    },
    {
        name: "shuttle-van",
        terms: ["airport", "machine", "public-transportation", "transportation", "travel", "vehicle"],
        label: "Shuttle Van"
    },
    {
        name: "sign",
        terms: ["directions", "real estate", "signage", "wayfinding"],
        label: "Sign"
    },
    {
        name: "sign-in-alt",
        terms: ["arrow", "enter", "join", "log in", "login", "sign in", "sign up", "sign-in", "signin", "signup"],
        label: "Alternate Sign In"
    },
    {
        name: "sign-language",
        terms: ["Translate", "asl", "deaf", "hands"],
        label: "Sign Language"
    },
    {
        name: "sign-out-alt",
        terms: ["arrow", "exit", "leave", "log out", "logout", "sign-out"],
        label: "Alternate Sign Out"
    },
    {
        name: "signal",
        terms: ["bars", "graph", "online", "reception", "status"],
        label: "signal"
    },
    {
        name: "signature",
        terms: ["John Hancock", "cursive", "name", "writing"],
        label: "Signature"
    },
    {
        name: "sim-card",
        terms: ["hard drive", "hardware", "portable", "storage", "technology", "tiny"],
        label: "SIM Card"
    },
    {
        name: "sink",
        terms: ["bathroom", "covid-19", "faucet", "kitchen", "wash"],
        label: "Sink"
    },
    {
        name: "sitemap",
        terms: ["directory", "hierarchy", "ia", "information architecture", "organization"],
        label: "Sitemap"
    },
    {
        name: "skating",
        terms: ["activity", "figure skating", "fitness", "ice", "person", "winter"],
        label: "Skating"
    },
    {
        name: "skiing",
        terms: ["activity", "downhill", "fast", "fitness", "olympics", "outdoors", "person", "seasonal", "slalom"],
        label: "Skiing"
    },
    {
        name: "skiing-nordic",
        terms: ["activity", "cross country", "fitness", "outdoors", "person", "seasonal"],
        label: "Skiing Nordic"
    },
    {
        name: "skull",
        terms: ["bones", "skeleton", "x-ray", "yorick"],
        label: "Skull"
    },
    {
        name: "skull-crossbones",
        terms: [
            "Dungeons & Dragons",
            "alert",
            "bones",
            "d&d",
            "danger",
            "dead",
            "deadly",
            "death",
            "dnd",
            "fantasy",
            "halloween",
            "holiday",
            "jolly-roger",
            "pirate",
            "poison",
            "skeleton",
            "warning"
        ],
        label: "Skull & Crossbones"
    },
    {
        name: "slash",
        terms: ["cancel", "close", "mute", "off", "stop", "x"],
        label: "Slash"
    },
    {
        name: "sleigh",
        terms: ["christmas", "claus", "fly", "holiday", "santa", "sled", "snow", "xmas"],
        label: "Sleigh"
    },
    {
        name: "sliders-h",
        terms: ["adjust", "settings", "sliders", "toggle"],
        label: "Horizontal Sliders"
    },
    {
        name: "smile",
        terms: ["approve", "emoticon", "face", "happy", "rating", "satisfied"],
        label: "Smiling Face"
    },
    {
        name: "smile-beam",
        terms: ["emoticon", "face", "happy", "positive"],
        label: "Beaming Face With Smiling Eyes"
    },
    {
        name: "smile-wink",
        terms: ["emoticon", "face", "happy", "hint", "joke"],
        label: "Winking Face"
    },
    {
        name: "smog",
        terms: ["dragon", "fog", "haze", "pollution", "smoke", "weather"],
        label: "Smog"
    },
    {
        name: "smoking",
        terms: ["cancer", "cigarette", "nicotine", "smoking status", "tobacco"],
        label: "Smoking"
    },
    {
        name: "smoking-ban",
        terms: ["ban", "cancel", "no smoking", "non-smoking"],
        label: "Smoking Ban"
    },
    {
        name: "sms",
        terms: ["chat", "conversation", "message", "mobile", "notification", "phone", "sms", "texting"],
        label: "SMS"
    },
    {
        name: "snowboarding",
        terms: ["activity", "fitness", "olympics", "outdoors", "person"],
        label: "Snowboarding"
    },
    {
        name: "snowflake",
        terms: ["precipitation", "rain", "winter"],
        label: "Snowflake"
    },
    {
        name: "snowman",
        terms: ["decoration", "frost", "frosty", "holiday"],
        label: "Snowman"
    },
    {
        name: "snowplow",
        terms: ["clean up", "cold", "road", "storm", "winter"],
        label: "Snowplow"
    },
    {
        name: "soap",
        terms: ["bubbles", "clean", "covid-19", "hygiene", "wash"],
        label: "Soap"
    },
    {
        name: "socks",
        terms: ["business socks", "business time", "clothing", "feet", "flight of the conchords", "wednesday"],
        label: "Socks"
    },
    {
        name: "solar-panel",
        terms: ["clean", "eco-friendly", "energy", "green", "sun"],
        label: "Solar Panel"
    },
    {
        name: "sort",
        terms: ["filter", "order"],
        label: "Sort"
    },
    {
        name: "sort-alpha-down",
        terms: ["alphabetical", "arrange", "filter", "order", "sort-alpha-asc"],
        label: "Sort Alphabetical Down"
    },
    {
        name: "sort-alpha-down-alt",
        terms: ["alphabetical", "arrange", "filter", "order", "sort-alpha-asc"],
        label: "Alternate Sort Alphabetical Down"
    },
    {
        name: "sort-alpha-up",
        terms: ["alphabetical", "arrange", "filter", "order", "sort-alpha-desc"],
        label: "Sort Alphabetical Up"
    },
    {
        name: "sort-alpha-up-alt",
        terms: ["alphabetical", "arrange", "filter", "order", "sort-alpha-desc"],
        label: "Alternate Sort Alphabetical Up"
    },
    {
        name: "sort-amount-down",
        terms: ["arrange", "filter", "number", "order", "sort-amount-asc"],
        label: "Sort Amount Down"
    },
    {
        name: "sort-amount-down-alt",
        terms: ["arrange", "filter", "order", "sort-amount-asc"],
        label: "Alternate Sort Amount Down"
    },
    {
        name: "sort-amount-up",
        terms: ["arrange", "filter", "order", "sort-amount-desc"],
        label: "Sort Amount Up"
    },
    {
        name: "sort-amount-up-alt",
        terms: ["arrange", "filter", "order", "sort-amount-desc"],
        label: "Alternate Sort Amount Up"
    },
    {
        name: "sort-down",
        terms: ["arrow", "descending", "filter", "order", "sort-desc"],
        label: "Sort Down (Descending)"
    },
    {
        name: "sort-numeric-down",
        terms: ["arrange", "filter", "numbers", "order", "sort-numeric-asc"],
        label: "Sort Numeric Down"
    },
    {
        name: "sort-numeric-down-alt",
        terms: ["arrange", "filter", "numbers", "order", "sort-numeric-asc"],
        label: "Alternate Sort Numeric Down"
    },
    {
        name: "sort-numeric-up",
        terms: ["arrange", "filter", "numbers", "order", "sort-numeric-desc"],
        label: "Sort Numeric Up"
    },
    {
        name: "sort-numeric-up-alt",
        terms: ["arrange", "filter", "numbers", "order", "sort-numeric-desc"],
        label: "Alternate Sort Numeric Up"
    },
    {
        name: "sort-up",
        terms: ["arrow", "ascending", "filter", "order", "sort-asc"],
        label: "Sort Up (Ascending)"
    },
    {
        name: "spa",
        terms: ["flora", "massage", "mindfulness", "plant", "wellness"],
        label: "Spa"
    },
    {
        name: "space-shuttle",
        terms: ["astronaut", "machine", "nasa", "rocket", "space", "transportation"],
        label: "Space Shuttle"
    },
    {
        name: "spell-check",
        terms: ["dictionary", "edit", "editor", "grammar", "text"],
        label: "Spell Check"
    },
    {
        name: "spider",
        terms: ["arachnid", "bug", "charlotte", "crawl", "eight", "halloween"],
        label: "Spider"
    },
    {
        name: "spinner",
        terms: ["circle", "loading", "progress"],
        label: "Spinner"
    },
    {
        name: "splotch",
        terms: ["Ink", "blob", "blotch", "glob", "stain"],
        label: "Splotch"
    },
    {
        name: "spray-can",
        terms: ["Paint", "aerosol", "design", "graffiti", "tag"],
        label: "Spray Can"
    },
    {
        name: "square",
        terms: ["block", "box", "shape"],
        label: "Square"
    },
    {
        name: "square-full",
        terms: ["block", "box", "shape"],
        label: "Square Full"
    },
    {
        name: "square-root-alt",
        terms: ["arithmetic", "calculus", "division", "math"],
        label: "Alternate Square Root"
    },
    {
        name: "stamp",
        terms: ["art", "certificate", "imprint", "rubber", "seal"],
        label: "Stamp"
    },
    {
        name: "star",
        terms: ["achievement", "award", "favorite", "important", "night", "rating", "score"],
        label: "Star"
    },
    {
        name: "star-and-crescent",
        terms: ["islam", "muslim", "religion"],
        label: "Star and Crescent"
    },
    {
        name: "star-half",
        terms: ["achievement", "award", "rating", "score", "star-half-empty", "star-half-full"],
        label: "star-half"
    },
    {
        name: "star-half-alt",
        terms: ["achievement", "award", "rating", "score", "star-half-empty", "star-half-full"],
        label: "Alternate Star Half"
    },
    {
        name: "star-of-david",
        terms: ["jewish", "judaism", "religion"],
        label: "Star of David"
    },
    {
        name: "star-of-life",
        terms: ["doctor", "emt", "first aid", "health", "medical"],
        label: "Star of Life"
    },
    {
        name: "step-backward",
        terms: ["beginning", "first", "previous", "rewind", "start"],
        label: "step-backward"
    },
    {
        name: "step-forward",
        terms: ["end", "last", "next"],
        label: "step-forward"
    },
    {
        name: "stethoscope",
        terms: ["covid-19", "diagnosis", "doctor", "general practitioner", "hospital", "infirmary", "medicine", "office", "outpatient"],
        label: "Stethoscope"
    },
    {
        name: "sticky-note",
        terms: ["message", "note", "paper", "reminder", "sticker"],
        label: "Sticky Note"
    },
    {
        name: "stop",
        terms: ["block", "box", "square"],
        label: "stop"
    },
    {
        name: "stop-circle",
        terms: ["block", "box", "circle", "square"],
        label: "Stop Circle"
    },
    {
        name: "stopwatch",
        terms: ["clock", "reminder", "time"],
        label: "Stopwatch"
    },
    {
        name: "stopwatch-20",
        terms: ["ABCs", "countdown", "covid-19", "happy birthday", "i will survive", "reminder", "seconds", "time", "timer"],
        label: "Stopwatch 20"
    },
    {
        name: "store",
        terms: ["building", "buy", "purchase", "shopping"],
        label: "Store"
    },
    {
        name: "store-alt",
        terms: ["building", "buy", "purchase", "shopping"],
        label: "Alternate Store"
    },
    {
        name: "store-alt-slash",
        terms: ["building", "buy", "closed", "covid-19", "purchase", "shopping"],
        label: "Alternate Store Slash"
    },
    {
        name: "store-slash",
        terms: ["building", "buy", "closed", "covid-19", "purchase", "shopping"],
        label: "Store Slash"
    },
    {
        name: "stream",
        terms: ["flow", "list", "timeline"],
        label: "Stream"
    },
    {
        name: "street-view",
        terms: ["directions", "location", "map", "navigation"],
        label: "Street View"
    },
    {
        name: "strikethrough",
        terms: ["cancel", "edit", "font", "format", "text", "type"],
        label: "Strikethrough"
    },
    {
        name: "stroopwafel",
        terms: ["caramel", "cookie", "dessert", "sweets", "waffle"],
        label: "Stroopwafel"
    },
    {
        name: "subscript",
        terms: ["edit", "font", "format", "text", "type"],
        label: "subscript"
    },
    {
        name: "subway",
        terms: ["machine", "railway", "train", "transportation", "vehicle"],
        label: "Subway"
    },
    {
        name: "suitcase",
        terms: ["baggage", "luggage", "move", "suitcase", "travel", "trip"],
        label: "Suitcase"
    },
    {
        name: "suitcase-rolling",
        terms: ["baggage", "luggage", "move", "suitcase", "travel", "trip"],
        label: "Suitcase Rolling"
    },
    {
        name: "sun",
        terms: ["brighten", "contrast", "day", "lighter", "sol", "solar", "star", "weather"],
        label: "Sun"
    },
    {
        name: "superscript",
        terms: ["edit", "exponential", "font", "format", "text", "type"],
        label: "superscript"
    },
    {
        name: "surprise",
        terms: ["emoticon", "face", "shocked"],
        label: "Hushed Face"
    },
    {
        name: "swatchbook",
        terms: ["Pantone", "color", "design", "hue", "palette"],
        label: "Swatchbook"
    },
    {
        name: "swimmer",
        terms: ["athlete", "head", "man", "olympics", "person", "pool", "water"],
        label: "Swimmer"
    },
    {
        name: "swimming-pool",
        terms: ["ladder", "recreation", "swim", "water"],
        label: "Swimming Pool"
    },
    {
        name: "synagogue",
        terms: ["building", "jewish", "judaism", "religion", "star of david", "temple"],
        label: "Synagogue"
    },
    {
        name: "sync",
        terms: ["exchange", "refresh", "reload", "rotate", "swap"],
        label: "Sync"
    },
    {
        name: "sync-alt",
        terms: ["exchange", "refresh", "reload", "rotate", "swap"],
        label: "Alternate Sync"
    },
    {
        name: "syringe",
        terms: ["covid-19", "doctor", "immunizations", "medical", "needle"],
        label: "Syringe"
    },
    {
        name: "table",
        terms: ["data", "excel", "spreadsheet"],
        label: "table"
    },
    {
        name: "table-tennis",
        terms: ["ball", "paddle", "ping pong"],
        label: "Table Tennis"
    },
    {
        name: "tablet",
        terms: ["apple", "device", "ipad", "kindle", "screen"],
        label: "tablet"
    },
    {
        name: "tablet-alt",
        terms: ["apple", "device", "ipad", "kindle", "screen"],
        label: "Alternate Tablet"
    },
    {
        name: "tablets",
        terms: ["drugs", "medicine", "pills", "prescription"],
        label: "Tablets"
    },
    {
        name: "tachometer-alt",
        terms: ["dashboard", "fast", "odometer", "speed", "speedometer"],
        label: "Alternate Tachometer"
    },
    {
        name: "tag",
        terms: ["discount", "label", "price", "shopping"],
        label: "tag"
    },
    {
        name: "tags",
        terms: ["discount", "label", "price", "shopping"],
        label: "tags"
    },
    {
        name: "tape",
        terms: ["design", "package", "sticky"],
        label: "Tape"
    },
    {
        name: "tasks",
        terms: ["checklist", "downloading", "downloads", "loading", "progress", "project management", "settings", "to do"],
        label: "Tasks"
    },
    {
        name: "taxi",
        terms: ["cab", "cabbie", "car", "car service", "lyft", "machine", "transportation", "travel", "uber", "vehicle"],
        label: "Taxi"
    },
    {
        name: "teeth",
        terms: ["bite", "dental", "dentist", "gums", "mouth", "smile", "tooth"],
        label: "Teeth"
    },
    {
        name: "teeth-open",
        terms: ["dental", "dentist", "gums bite", "mouth", "smile", "tooth"],
        label: "Teeth Open"
    },
    {
        name: "temperature-high",
        terms: ["cook", "covid-19", "mercury", "summer", "thermometer", "warm"],
        label: "High Temperature"
    },
    {
        name: "temperature-low",
        terms: ["cold", "cool", "covid-19", "mercury", "thermometer", "winter"],
        label: "Low Temperature"
    },
    {
        name: "tenge",
        terms: ["currency", "kazakhstan", "money", "price"],
        label: "Tenge"
    },
    {
        name: "terminal",
        terms: ["code", "command", "console", "development", "prompt"],
        label: "Terminal"
    },
    {
        name: "text-height",
        terms: ["edit", "font", "format", "text", "type"],
        label: "text-height"
    },
    {
        name: "text-width",
        terms: ["edit", "font", "format", "text", "type"],
        label: "Text Width"
    },
    {
        name: "th",
        terms: ["blocks", "boxes", "grid", "squares"],
        label: "th"
    },
    {
        name: "th-large",
        terms: ["blocks", "boxes", "grid", "squares"],
        label: "th-large"
    },
    {
        name: "th-list",
        terms: ["checklist", "completed", "done", "finished", "ol", "todo", "ul"],
        label: "th-list"
    },
    {
        name: "theater-masks",
        terms: ["comedy", "perform", "theatre", "tragedy"],
        label: "Theater Masks"
    },
    {
        name: "thermometer",
        terms: ["covid-19", "mercury", "status", "temperature"],
        label: "Thermometer"
    },
    {
        name: "thermometer-empty",
        terms: ["cold", "mercury", "status", "temperature"],
        label: "Thermometer Empty"
    },
    {
        name: "thermometer-full",
        terms: ["fever", "hot", "mercury", "status", "temperature"],
        label: "Thermometer Full"
    },
    {
        name: "thermometer-half",
        terms: ["mercury", "status", "temperature"],
        label: "Thermometer 1/2 Full"
    },
    {
        name: "thermometer-quarter",
        terms: ["mercury", "status", "temperature"],
        label: "Thermometer 1/4 Full"
    },
    {
        name: "thermometer-three-quarters",
        terms: ["mercury", "status", "temperature"],
        label: "Thermometer 3/4 Full"
    },
    {
        name: "thumbs-down",
        terms: ["disagree", "disapprove", "dislike", "hand", "social", "thumbs-o-down"],
        label: "thumbs-down"
    },
    {
        name: "thumbs-up",
        terms: ["agree", "approve", "favorite", "hand", "like", "ok", "okay", "social", "success", "thumbs-o-up", "yes", "you got it dude"],
        label: "thumbs-up"
    },
    {
        name: "thumbtack",
        terms: ["coordinates", "location", "marker", "pin", "thumb-tack"],
        label: "Thumbtack"
    },
    {
        name: "ticket-alt",
        terms: ["movie", "pass", "support", "ticket"],
        label: "Alternate Ticket"
    },
    {
        name: "times",
        terms: ["close", "cross", "error", "exit", "incorrect", "notice", "notification", "notify", "problem", "wrong", "x"],
        label: "Times"
    },
    {
        name: "times-circle",
        terms: ["close", "cross", "exit", "incorrect", "notice", "notification", "notify", "problem", "wrong", "x"],
        label: "Times Circle"
    },
    {
        name: "tint",
        terms: ["color", "drop", "droplet", "raindrop", "waterdrop"],
        label: "tint"
    },
    {
        name: "tint-slash",
        terms: ["color", "drop", "droplet", "raindrop", "waterdrop"],
        label: "Tint Slash"
    },
    {
        name: "tired",
        terms: ["angry", "emoticon", "face", "grumpy", "upset"],
        label: "Tired Face"
    },
    {
        name: "toggle-off",
        terms: ["switch"],
        label: "Toggle Off"
    },
    {
        name: "toggle-on",
        terms: ["switch"],
        label: "Toggle On"
    },
    {
        name: "toilet",
        terms: [
            "bathroom",
            "flush",
            "john",
            "loo",
            "pee",
            "plumbing",
            "poop",
            "porcelain",
            "potty",
            "restroom",
            "throne",
            "washroom",
            "waste",
            "wc"
        ],
        label: "Toilet"
    },
    {
        name: "toilet-paper",
        terms: ["bathroom", "covid-19", "halloween", "holiday", "lavatory", "prank", "restroom", "roll"],
        label: "Toilet Paper"
    },
    {
        name: "toilet-paper-slash",
        terms: ["bathroom", "covid-19", "halloween", "holiday", "lavatory", "leaves", "prank", "restroom", "roll", "trouble", "ut oh"],
        label: "Toilet Paper Slash"
    },
    {
        name: "toolbox",
        terms: ["admin", "container", "fix", "repair", "settings", "tools"],
        label: "Toolbox"
    },
    {
        name: "tools",
        terms: ["admin", "fix", "repair", "screwdriver", "settings", "tools", "wrench"],
        label: "Tools"
    },
    {
        name: "tooth",
        terms: ["bicuspid", "dental", "dentist", "molar", "mouth", "teeth"],
        label: "Tooth"
    },
    {
        name: "torah",
        terms: ["book", "jewish", "judaism", "religion", "scroll"],
        label: "Torah"
    },
    {
        name: "torii-gate",
        terms: ["building", "shintoism"],
        label: "Torii Gate"
    },
    {
        name: "tractor",
        terms: ["agriculture", "farm", "vehicle"],
        label: "Tractor"
    },
    {
        name: "trademark",
        terms: ["copyright", "register", "symbol"],
        label: "Trademark"
    },
    {
        name: "traffic-light",
        terms: ["direction", "road", "signal", "travel"],
        label: "Traffic Light"
    },
    {
        name: "trailer",
        terms: ["carry", "haul", "moving", "travel"],
        label: "Trailer"
    },
    {
        name: "train",
        terms: ["bullet", "commute", "locomotive", "railway", "subway"],
        label: "Train"
    },
    {
        name: "tram",
        terms: ["crossing", "machine", "mountains", "seasonal", "transportation"],
        label: "Tram"
    },
    {
        name: "transgender",
        terms: ["intersex"],
        label: "Transgender"
    },
    {
        name: "transgender-alt",
        terms: ["intersex"],
        label: "Alternate Transgender"
    },
    {
        name: "trash",
        terms: ["delete", "garbage", "hide", "remove"],
        label: "Trash"
    },
    {
        name: "trash-alt",
        terms: ["delete", "garbage", "hide", "remove", "trash-o"],
        label: "Alternate Trash"
    },
    {
        name: "trash-restore",
        terms: ["back", "control z", "oops", "undo"],
        label: "Trash Restore"
    },
    {
        name: "trash-restore-alt",
        terms: ["back", "control z", "oops", "undo"],
        label: "Alternative Trash Restore"
    },
    {
        name: "tree",
        terms: ["bark", "fall", "flora", "forest", "nature", "plant", "seasonal"],
        label: "Tree"
    },
    {
        name: "trophy",
        terms: ["achievement", "award", "cup", "game", "winner"],
        label: "trophy"
    },
    {
        name: "truck",
        terms: ["cargo", "delivery", "shipping", "vehicle"],
        label: "truck"
    },
    {
        name: "truck-loading",
        terms: ["box", "cargo", "delivery", "inventory", "moving", "rental", "vehicle"],
        label: "Truck Loading"
    },
    {
        name: "truck-monster",
        terms: ["offroad", "vehicle", "wheel"],
        label: "Truck Monster"
    },
    {
        name: "truck-moving",
        terms: ["cargo", "inventory", "rental", "vehicle"],
        label: "Truck Moving"
    },
    {
        name: "truck-pickup",
        terms: ["cargo", "vehicle"],
        label: "Truck Side"
    },
    {
        name: "tshirt",
        terms: ["clothing", "fashion", "garment", "shirt"],
        label: "T-Shirt"
    },
    {
        name: "tty",
        terms: ["communication", "deaf", "telephone", "teletypewriter", "text"],
        label: "TTY"
    },
    {
        name: "tv",
        terms: ["computer", "display", "monitor", "television"],
        label: "Television"
    },
    {
        name: "umbrella",
        terms: ["protection", "rain", "storm", "wet"],
        label: "Umbrella"
    },
    {
        name: "umbrella-beach",
        terms: ["protection", "recreation", "sand", "shade", "summer", "sun"],
        label: "Umbrella Beach"
    },
    {
        name: "underline",
        terms: ["edit", "emphasis", "format", "text", "writing"],
        label: "Underline"
    },
    {
        name: "undo",
        terms: ["back", "control z", "exchange", "oops", "return", "rotate", "swap"],
        label: "Undo"
    },
    {
        name: "undo-alt",
        terms: ["back", "control z", "exchange", "oops", "return", "swap"],
        label: "Alternate Undo"
    },
    {
        name: "universal-access",
        terms: ["accessibility", "hearing", "person", "seeing", "visual impairment"],
        label: "Universal Access"
    },
    {
        name: "university",
        terms: ["bank", "building", "college", "higher education - students", "institution"],
        label: "University"
    },
    {
        name: "unlink",
        terms: ["attachment", "chain", "chain-broken", "remove"],
        label: "unlink"
    },
    {
        name: "unlock",
        terms: ["admin", "lock", "password", "private", "protect"],
        label: "unlock"
    },
    {
        name: "unlock-alt",
        terms: ["admin", "lock", "password", "private", "protect"],
        label: "Alternate Unlock"
    },
    {
        name: "upload",
        terms: ["hard drive", "import", "publish"],
        label: "Upload"
    },
    {
        name: "user",
        terms: ["account", "avatar", "head", "human", "man", "person", "profile"],
        label: "User"
    },
    {
        name: "user-alt",
        terms: ["account", "avatar", "head", "human", "man", "person", "profile"],
        label: "Alternate User"
    },
    {
        name: "user-alt-slash",
        terms: ["account", "avatar", "head", "human", "man", "person", "profile"],
        label: "Alternate User Slash"
    },
    {
        name: "user-astronaut",
        terms: ["avatar", "clothing", "cosmonaut", "nasa", "space", "suit"],
        label: "User Astronaut"
    },
    {
        name: "user-check",
        terms: ["accept", "check", "person", "verified"],
        label: "User Check"
    },
    {
        name: "user-circle",
        terms: ["account", "avatar", "head", "human", "man", "person", "profile"],
        label: "User Circle"
    },
    {
        name: "user-clock",
        terms: ["alert", "person", "remind", "time"],
        label: "User Clock"
    },
    {
        name: "user-cog",
        terms: ["admin", "cog", "person", "settings"],
        label: "User Cog"
    },
    {
        name: "user-edit",
        terms: ["edit", "pen", "pencil", "person", "update", "write"],
        label: "User Edit"
    },
    {
        name: "user-friends",
        terms: ["group", "people", "person", "team", "users"],
        label: "User Friends"
    },
    {
        name: "user-graduate",
        terms: ["cap", "clothing", "commencement", "gown", "graduation", "person", "student"],
        label: "User Graduate"
    },
    {
        name: "user-injured",
        terms: ["cast", "injury", "ouch", "patient", "person", "sling"],
        label: "User Injured"
    },
    {
        name: "user-lock",
        terms: ["admin", "lock", "person", "private", "unlock"],
        label: "User Lock"
    },
    {
        name: "user-md",
        terms: ["covid-19", "job", "medical", "nurse", "occupation", "physician", "profile", "surgeon"],
        label: "Doctor"
    },
    {
        name: "user-minus",
        terms: ["delete", "negative", "remove"],
        label: "User Minus"
    },
    {
        name: "user-ninja",
        terms: ["assassin", "avatar", "dangerous", "deadly", "sneaky"],
        label: "User Ninja"
    },
    {
        name: "user-nurse",
        terms: ["covid-19", "doctor", "midwife", "practitioner", "surgeon"],
        label: "Nurse"
    },
    {
        name: "user-plus",
        terms: ["add", "avatar", "positive", "sign up", "signup", "team"],
        label: "User Plus"
    },
    {
        name: "user-secret",
        terms: ["clothing", "coat", "hat", "incognito", "person", "privacy", "spy", "whisper"],
        label: "User Secret"
    },
    {
        name: "user-shield",
        terms: ["admin", "person", "private", "protect", "safe"],
        label: "User Shield"
    },
    {
        name: "user-slash",
        terms: ["ban", "delete", "remove"],
        label: "User Slash"
    },
    {
        name: "user-tag",
        terms: ["avatar", "discount", "label", "person", "role", "special"],
        label: "User Tag"
    },
    {
        name: "user-tie",
        terms: ["avatar", "business", "clothing", "formal", "professional", "suit"],
        label: "User Tie"
    },
    {
        name: "user-times",
        terms: ["archive", "delete", "remove", "x"],
        label: "Remove User"
    },
    {
        name: "users",
        terms: ["friends", "group", "people", "persons", "profiles", "team"],
        label: "Users"
    },
    {
        name: "users-cog",
        terms: ["admin", "cog", "group", "person", "settings", "team"],
        label: "Users Cog"
    },
    {
        name: "users-slash",
        terms: ["disband", "friends", "group", "people", "persons", "profiles", "separate", "team", "ungroup"],
        label: "Users Slash"
    },
    {
        name: "utensil-spoon",
        terms: ["cutlery", "dining", "scoop", "silverware", "spoon"],
        label: "Utensil Spoon"
    },
    {
        name: "utensils",
        terms: ["cutlery", "dining", "dinner", "eat", "food", "fork", "knife", "restaurant"],
        label: "Utensils"
    },
    {
        name: "vector-square",
        terms: ["anchors", "lines", "object", "render", "shape"],
        label: "Vector Square"
    },
    {
        name: "venus",
        terms: ["female"],
        label: "Venus"
    },
    {
        name: "venus-double",
        terms: ["female"],
        label: "Venus Double"
    },
    {
        name: "venus-mars",
        terms: ["Gender"],
        label: "Venus Mars"
    },
    {
        name: "vest",
        terms: ["biker", "fashion", "style"],
        label: "vest"
    },
    {
        name: "vest-patches",
        terms: ["biker", "fashion", "style"],
        label: "vest-patches"
    },
    {
        name: "vial",
        terms: ["experiment", "lab", "sample", "science", "test", "test tube"],
        label: "Vial"
    },
    {
        name: "vials",
        terms: ["experiment", "lab", "sample", "science", "test", "test tube"],
        label: "Vials"
    },
    {
        name: "video",
        terms: ["camera", "film", "movie", "record", "video-camera"],
        label: "Video"
    },
    {
        name: "video-slash",
        terms: ["add", "create", "film", "new", "positive", "record", "video"],
        label: "Video Slash"
    },
    {
        name: "vihara",
        terms: ["buddhism", "buddhist", "building", "monastery"],
        label: "Vihara"
    },
    {
        name: "virus",
        terms: ["bug", "covid-19", "flu", "health", "sick", "viral"],
        label: "Virus"
    },
    {
        name: "virus-slash",
        terms: ["bug", "covid-19", "cure", "eliminate", "flu", "health", "sick", "viral"],
        label: "Virus Slash"
    },
    {
        name: "viruses",
        terms: ["bugs", "covid-19", "flu", "health", "multiply", "sick", "spread", "viral"],
        label: "Viruses"
    },
    {
        name: "voicemail",
        terms: ["answer", "inbox", "message", "phone"],
        label: "Voicemail"
    },
    {
        name: "volleyball-ball",
        terms: ["beach", "olympics", "sport"],
        label: "Volleyball Ball"
    },
    {
        name: "volume-down",
        terms: ["audio", "lower", "music", "quieter", "sound", "speaker"],
        label: "Volume Down"
    },
    {
        name: "volume-mute",
        terms: ["audio", "music", "quiet", "sound", "speaker"],
        label: "Volume Mute"
    },
    {
        name: "volume-off",
        terms: ["audio", "ban", "music", "mute", "quiet", "silent", "sound"],
        label: "Volume Off"
    },
    {
        name: "volume-up",
        terms: ["audio", "higher", "louder", "music", "sound", "speaker"],
        label: "Volume Up"
    },
    {
        name: "vote-yea",
        terms: ["accept", "cast", "election", "politics", "positive", "yes"],
        label: "Vote Yea"
    },
    {
        name: "vr-cardboard",
        terms: ["3d", "augment", "google", "reality", "virtual"],
        label: "Cardboard VR"
    },
    {
        name: "walking",
        terms: ["exercise", "health", "pedometer", "person", "steps"],
        label: "Walking"
    },
    {
        name: "wallet",
        terms: ["billfold", "cash", "currency", "money"],
        label: "Wallet"
    },
    {
        name: "warehouse",
        terms: ["building", "capacity", "garage", "inventory", "storage"],
        label: "Warehouse"
    },
    {
        name: "water",
        terms: ["lake", "liquid", "ocean", "sea", "swim", "wet"],
        label: "Water"
    },
    {
        name: "wave-square",
        terms: ["frequency", "pulse", "signal"],
        label: "Square Wave"
    },
    {
        name: "weight",
        terms: ["health", "measurement", "scale", "weight"],
        label: "Weight"
    },
    {
        name: "weight-hanging",
        terms: ["anvil", "heavy", "measurement"],
        label: "Hanging Weight"
    },
    {
        name: "wheelchair",
        terms: ["accessible", "handicap", "person"],
        label: "Wheelchair"
    },
    {
        name: "wifi",
        terms: ["connection", "hotspot", "internet", "network", "wireless"],
        label: "WiFi"
    },
    {
        name: "wind",
        terms: ["air", "blow", "breeze", "fall", "seasonal", "weather"],
        label: "Wind"
    },
    {
        name: "window-close",
        terms: ["browser", "cancel", "computer", "development"],
        label: "Window Close"
    },
    {
        name: "window-maximize",
        terms: ["browser", "computer", "development", "expand"],
        label: "Window Maximize"
    },
    {
        name: "window-minimize",
        terms: ["browser", "collapse", "computer", "development"],
        label: "Window Minimize"
    },
    {
        name: "window-restore",
        terms: ["browser", "computer", "development"],
        label: "Window Restore"
    },
    {
        name: "wine-bottle",
        terms: ["alcohol", "beverage", "cabernet", "drink", "glass", "grapes", "merlot", "sauvignon"],
        label: "Wine Bottle"
    },
    {
        name: "wine-glass",
        terms: ["alcohol", "beverage", "cabernet", "drink", "grapes", "merlot", "sauvignon"],
        label: "Wine Glass"
    },
    {
        name: "wine-glass-alt",
        terms: ["alcohol", "beverage", "cabernet", "drink", "grapes", "merlot", "sauvignon"],
        label: "Alternate Wine Glas"
    },
    {
        name: "won-sign",
        terms: ["currency", "krw", "money"],
        label: "Won Sign"
    },
    {
        name: "wrench",
        terms: ["construction", "fix", "mechanic", "plumbing", "settings", "spanner", "tool", "update"],
        label: "Wrench"
    },
    {
        name: "x-ray",
        terms: ["health", "medical", "radiological images", "radiology", "skeleton"],
        label: "X-Ray"
    },
    {
        name: "yen-sign",
        terms: ["currency", "jpy", "money"],
        label: "Yen Sign"
    },
    {
        name: "yin-yang",
        terms: ["daoism", "opposites", "taoism"],
        label: "Yin Yang"
    }
];
