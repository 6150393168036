import React from "react";
import { agent, IMTRReportHubEntry, IMTRReportResponse } from "api";
import DownloadXmlButton from "./DownloadXmlButton";
import { Button } from "@mui/material";
import { TabContext } from "@mui/lab";
import { SmallTab, SmallTabList, TabPanel } from "components";

interface IMTRReportProps {
    report_id: string;
}

const MTRReport: React.FC<IMTRReportProps> = (props) => {
    const [report, setReport] = React.useState<IMTRReportResponse>();
    const [tabValue, setTabValue] = React.useState<string>("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleDeleteReport = async () => {
        await agent.Reporting.deleteMtrReport(props.report_id)
            .then(() => {
                setReport(undefined);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        const getMtrReport = async (report_id: string = props.report_id) => {
            await agent.Reporting.getMtrReport(report_id)
                .then((value) => {
                    setReport(value);
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        getMtrReport();

        return () => {
            setReport(undefined);
        };
    }, [props.report_id]);

    return (
        <div style={{ color: "#fff" }}>
            <TabContext value={tabValue}>
                <SmallTabList
                    onChange={handleTabChange}
                    aria-label="Overview - Source Item Tabs"
                >
                    <SmallTab label="Report" value="1" />
                    <SmallTab label="Hubs" value="2" />
                    <SmallTab label="Delete Report" value="3" />
                </SmallTabList>

                <TabPanel value="1">
                    <div>
                        <div>src: {report?.report?.mtr?.src}</div>
                        <div>dst: {report?.report?.mtr?.dst}</div>
                        <div>tos: {report?.report?.mtr?.tos}</div>
                        <div>tests: {report?.report?.mtr?.tests}</div>
                        <div>psize: {report?.report?.mtr?.psize}</div>
                        <div style={{ marginBottom: 10 }}>bitpattern: {report?.report?.mtr?.bitpattern}</div>
                        <DownloadXmlButton data={report ? report : {}} />
                    </div>
                </TabPanel>
                <TabPanel value="2">
                    <div>
                        <div style={{ fontSize: "11px", marginBottom: 10 }}>
                            Location data is not available for all endpoints, we display only the hops where locational data is
                            available.
                        </div>
                        {report?.report?.hubs?.map((hub: IMTRReportHubEntry) => (
                            <div
                                key={hub.count}
                                style={{
                                    padding: 5,
                                    borderRadius: 4,
                                    marginBottom: 3,
                                    backgroundColor: "rgba(160,160,160,0.05)"
                                }}>
                                <div>
                                    Count: {hub.count}, Host: {hub.host}, Loss %: {hub["Loss%"]}, Snt: {hub.Snt}, Last:{" "}
                                    {hub.Last}, Avg: {hub.Avg}, Best: {hub.Best}, Worst: {hub.Wrst}, StDev: {hub.StDev}
                                </div>
                            </div>
                        ))}
                    </div>
                </TabPanel>
                <TabPanel value="3">
                    <Button onClick={handleDeleteReport} variant="contained" color="error">
                        Delete Report
                    </Button>
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default MTRReport;
