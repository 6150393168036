export interface IConstantsContent {
    cerberus: {
        emails: {
            general: string;
            frontend: string[];
            backend: string[];
            devs: string[];
            management: string[];
            Tom: string;
            Phil: string;
            Jurgis: string;
            Brad: string;
            Chris: string;
        };
    };
    data: {
        KnowledgeHub: {
            allFields: string;
            searchFields?: string;
        };
    };
}

const emails = {
    Tom: "tom.dowling@cerberus.tech",
    Phil: "phil.lavender@cerberus.tech",
    Jurgis: "jurgis.kalkis@cerberus.tech",
    Brad: "brad@cerberus.tech",
    Chris: "chris@cerberus.tech"
};

export const items = {
    cerberus: {
        emails: {
            general: "@cerberus.tech",
            frontend: [emails.Phil, emails.Tom],
            backend: [emails.Jurgis],
            devs: [emails.Phil, emails.Tom, emails.Jurgis],
            management: [emails.Brad, emails.Chris],
            ...emails
        }
    },
    data: {
        KnowledgeHub: {
            allFields:
                "client,dateCreated,dateUpdated,description,icon,image,includeTOC,isIndexable,isNumbered,showInCategory,lastModifiedBy,moduleAccess,order,roles,sections,status,tags,title,type,video",
            searchFields:
                "client,dateUpdated,description,icon,includeTOC,isIndexable,lastModifiedBy,order,showInCategory,roles,status,moduleAccess,tags,title,type"
        }
    }
} as IConstantsContent;

export interface IConstants {
    constants: IConstantsContent;
}

export const useConstants = (): IConstants => {
    return {
        constants: items
    };
};

export default useConstants;
