import { Alert, Box } from "@mui/material";
import DashVideoPlayer from "components/DashVideoPlayer";
import HlsVideoPlayer from "components/HlsVideoPlayer";
import React from "react";

interface VideoPlayersProps {
    outputs: any[];
}

const VideoPlayers: React.FC<VideoPlayersProps> = ({ outputs }) => {

    const [stateOutputs, setStateOutputs] = React.useState<any[]>(outputs);

    React.useEffect(() => {

        return () => {
            setStateOutputs([]);
        }
    }, [outputs]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch"
            }}>
            {outputs.length === 0 && <Alert severity="info">Please select an output node to view the player</Alert>}
            {outputs &&
                outputs.map((output: any, i: number) => {
                    //if output.url ends with .m3u8 then it is an hls output
                    if (output.url.endsWith(".m3u8")) {
                        return (
                            <>
                                <HlsVideoPlayer key={i} name={output.name} src={output.url} />
                            </>
                        );
                    }
                    //if output.url ends with .mpd then it is a dash output
                    if (output.url.endsWith(".mpd")) {
                        return (
                            <>
                                <DashVideoPlayer key={i} name={output.name} src={output.url} />
                            </>
                        );
                    }
                    return null;
                })}
        </Box>
    );
};

export default VideoPlayers;
