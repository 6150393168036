import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { ConfirmRestart } from "./ConfirmRestart";
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface IProps {
    name: string;
    transcoder_id: string;
}

export const ConfirmRestartDialogue: React.FC<IProps> = ({ name, transcoder_id }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog, changeDialogBody } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>Restart Transcoder</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Transcoder: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{name}</span>
                </Typography>
                <Alert severity="warning" sx={{ marginTop: 2 }}>
                    <p>
                        Restarting will cause a brief drop in connection to the transcoded source, that will last the duration of the
                        restart process.
                    </p>
                    <p>
                        Restarting is only intended to be used if experiencing issues with the transcoder such as unexpected connection
                        issues, if you have stopped and started the orginal input that is being transcoded, or if you feel the transcoder is
                        not properly connected.
                    </p>
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        changeDialogBody(<ConfirmRestart transcoder_id={transcoder_id} name={name} />);
                    }}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};
