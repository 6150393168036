import { CopyAll, Delete, Edit } from "@mui/icons-material";
import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import { RootStoreContext, agent } from "api";
import ConfirmationDialog from "components/ConfirmationDialog";
import { endOfDay, format, isAfter, isBefore, startOfDay } from "date-fns";
import BasicEditScheduleForm from "features/scheduler/Components/BasicEditScheduleForm";
import ItemTooltip from "features/scheduler/Components/ItemTooltip";
import React, { useContext } from "react";

interface ScheduleItemProps {
    item: any;
    destination_id: string;
    this_column_date: Date;
    onCopy: (item: any) => void;
}

const ScheduleItem: React.FC<ScheduleItemProps> = ({ item, destination_id, this_column_date, onCopy }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const calculateStart = () => {
        const startDateTime = new Date(item.start_time);
        const startDate = startOfDay(startDateTime);
        return isBefore(startDate, this_column_date) ? 0 : startDateTime.getHours() * 60 + startDateTime.getMinutes();
    };

    //boolean version of the above
    const calculateStartBoolean = () => {
        const startDateTime = new Date(item.start_time);
        const startDate = startOfDay(startDateTime);
        return isBefore(startDate, this_column_date);
    };

    const calculateEnd = () => {
        const endDateTime = new Date(item.end_time);
        const endDate = endOfDay(endDateTime);
        return isAfter(endDate, endOfDay(this_column_date)) ? 1440 : endDateTime.getHours() * 60 + endDateTime.getMinutes();
    };

    //boolean version of the above
    const calculateEndBoolean = () => {
        const endDateTime = new Date(item.end_time);
        const endDate = endOfDay(endDateTime);
        return isAfter(endDate, endOfDay(this_column_date));
    };

    return (
        <>
            <Tooltip
                placement="left"
                title={
                    <ItemTooltip
                        source_id={item.source_id}
                        source_name={item.source_name}
                        destination_id={item.destination_id}
                        destination_name={item.destination_name}
                        start_time={item.start_time}
                        end_time={item.end_time}
                        status={item.ui_status}
                    />
                }>
                <Paper
                    onClick={(e) => handleClick(e)}
                    elevation={anchorEl ? 6 : 2}
                    sx={{
                        background: "none",
                        pointerEvents: "auto",
                        width: "calc(100% - 15px)",
                        borderRadius: 1,
                        zIndex: anchorEl ? 100 : undefined,
                        borderTopLeftRadius: calculateStartBoolean() ? 0 : undefined,
                        borderTopRightRadius: calculateStartBoolean() ? 0 : undefined,
                        borderBottomLeftRadius: calculateEndBoolean() ? 0 : undefined,
                        borderBottomRightRadius: calculateEndBoolean() ? 0 : undefined,
                        backgroundColor: (theme) =>
                            item.ui_status === "finished"
                                ? theme.palette.background.paper
                                : item.ui_status === "on"
                                ? theme.palette.success.main
                                : theme.palette.primary.main,
                        color: (theme) =>
                            item.ui_status !== "finished" ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                        position: "absolute",
                        top: calculateStart(),
                        height: calculateEnd() - calculateStart(),
                        //p: 1,
                        textAlign: "left",
                        overflow: "hidden",
                        cursor: "pointer"
                    }}>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        p: 1,
                        backgroundColor: (theme) => anchorEl ? theme.palette.action.selected : undefined,
                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.action.hover
                        }
                    }}>
                        {calculateEnd() - calculateStart() > 24 ? (
                            <Typography variant="body2" lineHeight={1}>
                                {format(new Date(item.start_time), "HH:mm")} - {format(new Date(item.end_time), "HH:mm")}
                            </Typography>
                        ) : null}
                        {calculateEnd() - calculateStart() > 39 ? (
                            <Typography variant="body1" lineHeight={1} fontWeight="bold">
                                {item.source_name}
                            </Typography>
                        ) : null}
                    </Box>
                </Paper>
            </Tooltip>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button"
                }}
                sx={{ minWidth: 300, p: 0, mt: 1, ml: -1 }}
                anchorEl={anchorEl}
                elevation={2}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={open}
                onClose={(e) => {
                    handleClose(e);
                }}>
                <Box
                    sx={{
                        pt: 0,
                        pr: 2,
                        pb: 1,
                        pl: 2,
                        fontWeight: "bold"
                    }}>
                    <Box>Start: {format(new Date(item.start_time), "dd/MM/yy @ HH:mm")}</Box>
                    <Box>End: {format(new Date(item.end_time), "dd/MM/yy @ HH:mm")}</Box>
                    <Box>Source: {item.source_name}</Box>
                    <Box>Destination: {item.destination_name}</Box>
                </Box>
                <Divider />
                <MenuItem
                    sx={{
                        mt: 1
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        onCopy(item);
                        handleClose(event);
                    }}>
                    <ListItemIcon>
                        <CopyAll />
                    </ListItemIcon>
                    <ListItemText>Copy</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleClose(event);
                        openDialog(<BasicEditScheduleForm item={item} schedule_id={item.schedule_id} destination_id={destination_id} />);
                    }}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleClose(event);
                        openDialog(
                            <ConfirmationDialog
                                title="Delete Schedule"
                                message="Are you sure you want to delete this schedule?"
                                additionalContent={
                                    <Box
                                        sx={{
                                            mt: 1
                                        }}>
                                        <Typography variant="body1">
                                            Start: {format(new Date(item.start_time), "dd/MM/yy @ HH:mm")}
                                        </Typography>
                                        <Typography variant="body1">End: {format(new Date(item.end_time), "dd/MM/yy @ HH:mm")}</Typography>
                                        <Typography variant="body1">Source: {item.source_name}</Typography>
                                        <Typography variant="body1">Destination: {item.destination_name}</Typography>
                                    </Box>
                                }
                                confirmButtonText="Delete"
                                confirmButtonColor="error"
                                onConfirm={async () => {
                                    await agent.Scheduler.deleteSchedule(item.schedule_id);
                                }}
                            />
                        );
                    }}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default ScheduleItem;
