import React from "react";

import Content from "components/Layout/Content";
import { Alert, Box, Button, MenuItem, Stack, TextField, Typography, useTheme } from "@mui/material";
import RoleProvider from "components/RoleProvider";
import CogsHeader from "features/admin/Cogs/Components/CogsHeader";
import CogsItem from "features/admin/Cogs/Components/CogsItem";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { agent, IOrganisationListManager, ManagingOrganisationListResponse, RootStoreContext } from "api";
import useRoleProvider from "hooks/UseRoleProvider";
import UsageHeader from "features/admin/Cogs/Components/UsageHeader";

const BillingUsageReports: React.FC = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const theme = useTheme();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [organisationId, setOrganisationId] = React.useState<number>(user?.active_org || 0);
    const [availableReports, setAvailableReports] = React.useState<any[]>([]);
    const [selectedReport, setSelectedReport] = React.useState<any>(null);
    const [reportId, setReportId] = React.useState<number>(0);
    const [selectedMonth, setSelectedMonth] = React.useState<Date>(new Date());
    const [organisations, setOrganisations] = React.useState<any[]>();
    const [month, setMonth] = React.useState<string>("");
    const canSeeAllOrganisations = useRoleProvider(["is_superuser", "is_accounts_admin", "is_accounts_user"], false);

    const getAvailableReports = async (id: number) => {
        setLoading(true);

        await agent.Billing.getAvailableCogsMonthlyReports(id)
            .then((response) => {
                setAvailableReports(response.data);
            })
            .catch((error) => {
                console.log("Error", error);
            });

        setLoading(false);
    };

    const getReport = async (month: string, reportId: number) => {
        setLoading(true);

        await agent.Billing.getCogsMonthlyReport(organisationId, month)
            .then((response) => {
                setSelectedReport(response.data);
                setReportId(reportId);
                setSelectedMonth(new Date(month));
            })
            .catch((error) => {
                console.log("Error", error);
            });

        setLoading(false);
    };

    const onOrganisationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOrganisationId(Number(event.target.value));
        setSelectedReport(null);
        setMonth("");
        getAvailableReports(Number(event.target.value));
    };

    const onMonthChange = (event: React.ChangeEvent<{ value: unknown }>, id: number) => {
        setMonth(event.target.value as string);
        getReport(event.target.value as string, id);
    };

    const getOrganisations = async () => {
        setLoading(true);
        await agent.Organisation.getManagingOrgListAdmin()
            .then((response: ManagingOrganisationListResponse) => {
                setOrganisations(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (organisationId !== null) {
            getAvailableReports(organisationId);
        }
    }, [organisationId]);

    React.useEffect(() => {
        if (canSeeAllOrganisations) {
            getOrganisations();
        } else {
            setOrganisations([{ id: user?.active_org, name: user?.active_org_name }]);
        }
    }, []);

    return (
        <Content
            loading={loading}
            toolbarContent={
                <Stack direction="row" spacing={2} sx={{ py: 1 }}>
                    <RoleProvider roles={["is_superuser", "is_accounts_admin", "is_accounts_user"]}>
                        <TextField
                            label="Organisation"
                            variant="outlined"
                            size="small"
                            value={organisationId === 0 ? undefined : organisationId}
                            sx={{
                                width: 200
                            }}
                            select
                            onChange={(event) => {
                                onOrganisationChange(event);
                            }}>
                            {organisations?.map((organisation: IOrganisationListManager, index: number) => (
                                <MenuItem key={index} value={organisation.id}>
                                    {organisation.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </RoleProvider>

                    <TextField
                        label="Month"
                        variant="outlined"
                        size="small"
                        value={month}
                        sx={{
                            width: 200
                        }}
                        disabled={!availableReports.length}
                        select
                        onChange={(e: any) => {
                            let item = availableReports.find((report) => report.month === e.target.value);
                            onMonthChange(e, item.id);
                        }}>
                        {availableReports
                        .sort((a, b) => new Date(b.month).getTime() - new Date(a.month).getTime())
                        .map((report, index) => (
                            <MenuItem key={index} value={report.month}>
                                {format(new Date(report.month), "MMMM yyyy")}
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>
            }
            headerContent={
                selectedReport && (
                    <UsageHeader
                        breakdown={selectedReport?.breakdown}
                        id={reportId}
                        startDate={startOfMonth(selectedMonth)}
                        endDate={endOfMonth(selectedMonth)}
                        orgSummary={selectedReport?.summary_per_organisation}
                    />
                )
            }>
            <Box
                sx={{
                    width: "100%",
                    height: 20,
                    backgroundColor: theme.palette.background.default,
                    position: "sticky",
                    top: -20,
                    marginTop: "-20px",
                    zIndex: 2
                }}
            />

            {!!selectedReport && (
                <Box>
                    {selectedReport?.status !== "no_data" &&
                        selectedReport?.summary_per_organisation
                            .filter((organisation) => organisation.organisation_id !== null)
                            .map((organisation: any) => {
                                return (
                                    <CogsItem
                                        key={organisation.organisation_id}
                                        organisation={organisation}
                                        breakdown={selectedReport?.breakdown}
                                        usage_mode
                                    />
                                );
                            })}
                </Box>
            )}

            {!selectedReport && !!availableReports.length && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}>
                    <Alert severity="info" sx={{ width: 500 }}>
                        Please select an organisation and a month to view the COGS report.
                    </Alert>
                </Box>
            )}

            {!loading && !!organisationId && !selectedReport && !availableReports.length && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}>
                    <Alert severity="info" sx={{ width: 500 }}>
                        Sorry, no reports found
                    </Alert>
                </Box>
            )}
        </Content>
    );
};

export default BillingUsageReports;
