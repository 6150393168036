import React from "react";
import { observer } from "mobx-react-lite";
import { agent } from "api";
import {
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Tooltip,
    Button,
    Popover,
    Typography,
    Card,
    CardContent,
    CardActions
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

interface Props {
    retryMessage: any;
    onAction: () => void;
}

const RetryMessageRow: React.FC<Props> = observer(function RetryMessageRow({ retryMessage, onAction }) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState<string | null>(null);

    const handleClickOpen = (value: string) => {
        setOpen(true);
        setSelectedValue(value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const id = popoverOpen ? "simple-popover" : undefined;

    function renderTextWithNewLines(str) {
        const lines = str.split("\n");
        return lines.map((line, index) => (
            <div key={index} style={{ marginBottom: 5 }}>
                {line}
            </div>
        ));
    }

    const handleRetry = async (retryMessageId: number) => {
        await agent.Admin.retryMessage(retryMessageId)
            .then((response: any) => {
                console.log("Retry Message", response);
                onAction();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleDeleteMessage = async (retryMessageId: number) => {
        await agent.Admin.deleteMessage(retryMessageId)
            .then((response: any) => {
                console.log("Delete Message", response);
                setAnchorEl(null);
                onAction();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <TableRow key={retryMessage.id}>
                <TableCell>{retryMessage.id}</TableCell>
                <TableCell>{format(new Date(retryMessage.received_at), "dd/MM/yyyy @ HH:mm")}</TableCell>
                <TableCell>{retryMessage.queue}</TableCell>
                <TableCell>{retryMessage.is_retried ? format(new Date(retryMessage.retried_at), "dd/MM/yyyy @ HH:mm") : ""}</TableCell>
                <TableCell>{retryMessage.is_retried ? retryMessage.retried_by_user : ""}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Tooltip title="View Error Message">
                            <IconButton
                                onClick={() => {
                                    handleClickOpen(retryMessage.error_details);
                                }}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        {!retryMessage.is_retried ? (
                            <Tooltip title="Retry">
                                <IconButton onClick={() => handleRetry(retryMessage.id)}>
                                    <ReplayIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <IconButton disabled>
                                <ReplayIcon />
                            </IconButton>
                        )}
                        <Tooltip title="Delete">
                            <IconButton aria-describedby={id} onClick={handleClick}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            id={id}
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}>
                            <Card>
                                <CardContent sx={{ maxWidth: "300px" }}>
                                    <Typography variant="h6" color="text.primary">
                                        Delete Retry Message
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Are you sure you want to delete this retry message? This action can not be undone
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end" width={"100%"}>
                                        <Button onClick={handlePopoverClose}>Cancel</Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => {
                                                handleDeleteMessage(retryMessage.id);
                                                handlePopoverClose;
                                            }}>
                                            Delete Message
                                        </Button>
                                    </Stack>
                                </CardActions>
                            </Card>
                        </Popover>
                    </Stack>
                </TableCell>
            </TableRow>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="lg">
                <DialogTitle id="scroll-dialog-title">Error Message</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                        {selectedValue && renderTextWithNewLines(selectedValue)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default RetryMessageRow;
