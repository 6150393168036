import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MTRReport from "./MtrReport";
import { format } from "date-fns";
import { agent } from "api";

type MtrReportItemProps = {
    id: string;
    environment_id: string;
    hostname: string;
    finished_at: Date;
    active?: boolean;
    onClick?: () => void;
};

const MtrReportItem: React.FC<MtrReportItemProps> = ({ id, environment_id, hostname, finished_at, active, onClick }) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const [environmentName, setEnvironmentName] = React.useState<string>("");

    const getEnvironmentName = (environment_id: string) => {
        agent.Environment.getEnvironmentDetails(environment_id).then((response) => {
            setEnvironmentName(response.details.name);
        });
    };

    React.useEffect(() => {
        getEnvironmentName(environment_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, expanded]);

    return (
        <div className={active ? "data-panel-item active" : "data-panel-item"} key={id}>
            <div className={"data-panel-item-header"}>
                <div className="data-panel-item-header-body" onClick={onClick}>
                    <div>
                        <div style={{ fontWeight: 600 }}>{hostname}</div>
                        <div>{environmentName}</div>
                        <div>{format(new Date(finished_at), "dd/MM/yyyy @ HH:mm")}</div>
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon icon={["fal", expanded ? "chevron-up" : "chevron-down"]} onClick={() => setExpanded(!expanded)} />
                </div>
            </div>

            {expanded && (
                <div className="data-panel-item-body">
                    <div style={{ width: "100%", maxHeight: 300, overflowX: "hidden", overflowY: "auto", padding: 0 }}>
                        <MTRReport report_id={id} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MtrReportItem;
