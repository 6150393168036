import { Box, List, ListItem, ListItemButton } from "@mui/material";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

export const AlertsManagement: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <View>
            <ViewHeader title="Alerts Management" />
            <ViewBody noPadding>
                <Content
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/alerts-management"}
                                    onClick={() => {
                                        navigate("");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Home</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/alerts-management/muted-alerts"}
                                    onClick={() => {
                                        navigate("muted-alerts");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Muted Alerts</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/alerts-management/notification-methods"}
                                    onClick={() => {
                                        navigate("notification-methods");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Notification Methods</Box>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    noPadding>
                    <Outlet />
                </Content>
            </ViewBody>
        </View>
    );
};

export default AlertsManagement;
