import { Alert, AlertTitle, Box, Button, Checkbox, FormControlLabel, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { FieldArray } from "react-final-form-arrays";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import OnChange from "components/FinalFormListener/OnChange";
import { SelectField, TextField } from "components/Forms";
import { useFormState } from "react-final-form";

interface Props {
    values: any;
    onFieldChange: (field: string, value: any) => void;
    outputGroupIndex: number;
}

const DashOutputOptions: React.FC<Props> = ({ values, onFieldChange, outputGroupIndex }) => {
    const [enablePlaybackURLs, setEnablePlaybackURLs] = React.useState<boolean>(false);

    const { submitErrors } = useFormState();

    React.useEffect(() => {
        if (
            (submitErrors?.output_groups?.[outputGroupIndex] &&
                (submitErrors.output_groups[outputGroupIndex].base_url_1 || submitErrors.output_groups[outputGroupIndex].base_url_2)) ||
            values?.output_groups?.[outputGroupIndex].base_url_1 ||
            values?.output_groups?.[outputGroupIndex].base_url_2
        ) {
            setEnablePlaybackURLs(true);
        }
    }, [submitErrors, outputGroupIndex, values?.output_groups]);

    return (
        <Box sx={{ mt: 4 }}>
            {values?.individual_track_configuration?.video_tracks && values.individual_track_configuration.video_tracks.length > 0 && (
                <>
                    <Typography variant="h6" gutterBottom>
                        Video Tracks
                    </Typography>
                    <FieldArray
                        name={`output_groups[${outputGroupIndex}].video_track_indexes`}
                        defaultValue={[undefined]}
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}>
                        {({ fields }) => (
                            <Box>
                                {fields.map((name, idx) => (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        key={idx}
                                        sx={{
                                            mb: 1,
                                            backgroundColor: "rgba(160,160,160,0.05)",
                                            borderRadius: 1,
                                            padding: 1
                                        }}>
                                        <SelectField
                                            name={name}
                                            label={`Video Track Index ${idx + 1}`}
                                            required
                                            options={
                                                values?.individual_track_configuration?.video_tracks
                                                    ? values?.individual_track_configuration?.video_tracks?.map((track, index) => ({
                                                          value: index,
                                                          text: `${index}. ${track.frame_size} / ${track.frame_rate} / ${track.bitrate}`
                                                      }))
                                                    : []
                                            }
                                        />
                                        <OnChange name={name}>
                                            {() => {
                                                if (submitErrors?.output_groups?.[outputGroupIndex]?.video_track_indexes?.[idx]) {
                                                    onFieldChange(name, undefined);
                                                }
                                                if (submitErrors?.output_groups?.[outputGroupIndex]?.video_track_indexes) {
                                                    onFieldChange(`output_groups.${outputGroupIndex}.video_track_indexes`, undefined);
                                                }
                                            }}
                                        </OnChange>
                                        <IconButton onClick={() => fields.remove(idx)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                ))}
                                <Button onClick={() => fields.push(undefined)} startIcon={<AddCircleIcon />}>
                                    Add Video Track
                                </Button>
                            </Box>
                        )}
                    </FieldArray>
                    <OnChange name={`output_groups[${outputGroupIndex}].video_track_indexes`}>
                        {() => {
                            if (submitErrors?.output_groups?.[outputGroupIndex]?.video_track_indexes) {
                                try {
                                    onFieldChange(`output_groups.${outputGroupIndex}.video_track_indexes`, undefined);
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        }}
                    </OnChange>
                    {submitErrors?.output_groups?.[outputGroupIndex] &&
                        submitErrors.output_groups[outputGroupIndex].video_track_indexes && (
                            <Box sx={{ mt: 2 }}>
                                <Alert severity="error">
                                    <AlertTitle>{submitErrors.output_groups[outputGroupIndex].video_track_indexes}</AlertTitle>
                                </Alert>
                            </Box>
                        )}
                </>
            )}

            {values?.individual_track_configuration?.audio_tracks && values.individual_track_configuration.audio_tracks.length > 0 && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Audio Tracks
                    </Typography>
                    <FieldArray name={`output_groups[${outputGroupIndex}].audio_track_indexes`} defaultValue={[{}]}>
                        {({ fields }) => (
                            <Box>
                                {fields.map((name, idx) => (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        key={idx}
                                        sx={{
                                            mb: 2,
                                            backgroundColor: "rgba(160,160,160,0.05)",
                                            borderRadius: 1,
                                            padding: 1
                                        }}>
                                        <Box sx={{ flex: 1 }}>
                                            <SelectField
                                                name={`${name}.track_index`}
                                                label={`Audio Track Index ${idx + 1}`}
                                                required
                                                validate={(value) => {
                                                    if (value === undefined) {
                                                        return "Required";
                                                    }
                                                }}
                                                options={
                                                    values?.individual_track_configuration?.audio_tracks
                                                        ? values.individual_track_configuration.audio_tracks?.map((track, index) => ({
                                                              value: index,
                                                              text: `${index}. ${track.codec}`
                                                          }))
                                                        : []
                                                }
                                            />

                                            {/* if the previuos field is valid, then show the next field */}
                                            {values.output_groups?.[outputGroupIndex]?.audio_track_indexes?.[idx]?.track_index !==
                                                undefined && (
                                                <>
                                                    <SelectField
                                                        name={`${name}.variant_index`}
                                                        label={`Variant Index ${idx + 1}`}
                                                        required
                                                        options={values?.individual_track_configuration?.audio_tracks?.[
                                                            values.output_groups?.[outputGroupIndex]?.audio_track_indexes?.[idx]
                                                                ?.track_index!
                                                        ]?.variants?.map((variant, index) => ({
                                                            value: index,
                                                            text: `${index}. ${variant.bitrate}`
                                                        }))}
                                                        validate={(value) => {
                                                            if (value === undefined) {
                                                                return "Required";
                                                            }
                                                        }}
                                                    />
                                                    <OnChange name={`${name}.variant_index`}>
                                                        {() => {
                                                            if (
                                                                submitErrors?.output_groups?.[outputGroupIndex]?.audio_track_indexes?.[idx]
                                                                    ?.variant_index
                                                            ) {
                                                                onFieldChange(
                                                                    `output_groups.${outputGroupIndex}.audio_track_indexes.${idx}.variant_index`,
                                                                    undefined
                                                                );
                                                            }
                                                        }}
                                                    </OnChange>
                                                </>
                                            )}
                                        </Box>
                                        <IconButton onClick={() => fields.remove(idx)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                ))}
                                <Button startIcon={<AddCircleIcon />} onClick={() => fields.push({})}>
                                    Add Audio Track
                                </Button>
                            </Box>
                        )}
                    </FieldArray>
                    <OnChange name={`output_groups[${outputGroupIndex}].audio_track_indexes`}>
                        {() => {
                            if (submitErrors?.output_groups?.[outputGroupIndex]?.audio_track_indexes) {
                                onFieldChange(`output_groups.${outputGroupIndex}.audio_track_indexes`, undefined);
                            }
                        }}
                    </OnChange>
                    {submitErrors?.output_groups?.[outputGroupIndex] &&
                        submitErrors.output_groups[outputGroupIndex].audio_track_indexes && (
                            <Box sx={{ mt: 2 }}>
                                <Alert severity="error">
                                    <AlertTitle>{submitErrors.output_groups[outputGroupIndex].audio_track_indexes}</AlertTitle>
                                </Alert>
                            </Box>
                        )}
                </Box>
            )}

            {values?.individual_track_configuration?.data_tracks && values.individual_track_configuration.data_tracks.length > 0 && (
                <Box sx={{ mt: 4, mb: 4 }}>
                    <Typography variant="h6">Data Tracks</Typography>

                    <FieldArray name={`output_groups[${outputGroupIndex}].data_track_indexes`}>
                        {({ fields }) => (
                            <>
                                {fields.map((name, idx) => (
                                    <Box
                                        key={idx}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            mb: 2,
                                            backgroundColor: "rgba(160,160,160,0.05)",
                                            borderRadius: 1,
                                            padding: 1
                                        }}>
                                        <SelectField
                                            name={name}
                                            label={`Data Track Index ${idx + 1}`}
                                            options={
                                                values?.individual_track_configuration?.data_tracks
                                                    ? values?.individual_track_configuration?.data_tracks?.map((track, index) => ({
                                                          value: index,
                                                          text: `${index}. ${track.data_type} ${
                                                              track.data_type === "DVB Subtitles" ? " (Not Supported)" : ""
                                                          }`,
                                                          disabled: Boolean(track.data_type === "DVB Subtitles")
                                                      }))
                                                    : []
                                            }
                                            required
                                            validate={(value) => {
                                                if (value === undefined) {
                                                    return "Required";
                                                }
                                            }}
                                        />
                                        <IconButton onClick={() => fields.remove(idx)} sx={{ ml: 2 }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                                <Button onClick={() => fields.push(undefined)} startIcon={<AddCircleIcon />}>
                                    Add Data Track
                                </Button>
                            </>
                        )}
                    </FieldArray>
                    <OnChange name={`output_groups[${outputGroupIndex}].data_track_indexes`}>
                        {() => {
                            if (submitErrors?.output_groups?.[outputGroupIndex]?.data_track_indexes) {
                                onFieldChange(`output_groups.${outputGroupIndex}.data_track_indexes`, undefined);
                            }
                        }}
                    </OnChange>
                    {submitErrors?.output_groups?.[outputGroupIndex] && submitErrors.output_groups[outputGroupIndex].data_track_indexes && (
                        <Box sx={{ mt: 2 }}>
                            <Alert severity="error">
                                <AlertTitle>{submitErrors.output_groups[outputGroupIndex].data_track_indexes}</AlertTitle>
                            </Alert>
                        </Box>
                    )}
                </Box>
            )}

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Output URL
            </Typography>

            <TextField
                name={`output_groups[${outputGroupIndex}].output_url`}
                label="Output URL"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    //must start with https:// or http://
                    if (!value.startsWith("https://") && !value.startsWith("http://")) {
                        return "Output URL must start with https:// or http://";
                    }
                    if (!value.endsWith(".mpd")) {
                        return "Output URL must end with .mpd";
                    }
                }}
            />

            {values?.enable_backup && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                        Backup Output URL
                    </Typography>
                    <TextField
                        name={`output_groups[${outputGroupIndex}].backup_output_url`}
                        label="Backup Output URL"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            //must start with https:// or http://
                            if (!value.startsWith("https://") && !value.startsWith("http://")) {
                                return "Output URL must start with https:// or http://";
                            }
                            if (!value.endsWith(".mpd")) {
                                return "Output URL must end with .mpd";
                            }
                        }}
                    />
                </>
            )}

            <FormControlLabel
                control={
                    <Checkbox
                        checked={enablePlaybackURLs}
                        onChange={(event) => setEnablePlaybackURLs(event.target.checked)}
                        color="primary"
                    />
                }
                label="Enable Playback URLs"
                labelPlacement="end"
                sx={{ mt: 2 }}
            />
            {enablePlaybackURLs && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Main Playback URL
                    </Typography>
                    <TextField
                        name={`output_groups[${outputGroupIndex}].base_url_1`}
                        label="Main Playback URL"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            //must start with https:// or http://
                            if (!value.startsWith("https://") && !value.startsWith("http://")) {
                                return "URL must start with https:// or http://";
                            }
                            if (!value.endsWith(".mpd")) {
                                return "URL must end with '.mpd'";
                            }
                        }}
                    />
                    {values?.enable_backup && (
                        <>
                            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                                Backup Playback URL
                            </Typography>

                            <TextField
                                name={`output_groups[${outputGroupIndex}].base_url_2`}
                                label="Backup Playback URL"
                                required
                                validate={(value) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                    //must start with https:// or http://
                                    if (!value.startsWith("https://") && !value.startsWith("http://")) {
                                        return "URL must start with https:// or http://";
                                    }
                                    if (!value.endsWith(".mpd")) {
                                        return "URL must end with '.mpd'";
                                    }
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default DashOutputOptions;
