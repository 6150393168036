export const Types = [
  { key: "Admin", text: "Admin", value: "admin" },
  { key: "Operator", text: "Operator", value: "operator" },
  { key: "Monitor", text: "Monitor", value: "monitor" },
];

export const max_destinations = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
].map((value) => ({
  key: value,
  text: value,
  value,
}));
