import { RootStore } from "./rootStore";
import { action, observable, reaction, runInAction, computed, makeObservable } from "mobx";
import {
    IEnvironmentStatus,
    IProvider,
    IRegion,
    ISize,
    IEnvironments,
    IEnvironment,
    IEnvironmentUpgradeDetails,
    IEnvironmentUpgradeTypes,
    IEnvironmentsData,
    ISizes,
    IEnvironmentsUpgradeTypes,
    ISavedIPAddress,
    IEnvironmentsListResponse
} from "api";

export default class EnvironmentStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
            }
        );
    }

    @observable loadingInitial = false;
    @observable page = 0;
    @observable predicate = new Map();
    @observable cloud_code = "";
    @observable product_id = 0;
    @observable region_code = "";
    @observable ipAddressCode = "";
    @observable iPName = "";
    @observable codeChanged = false;
    @observable environment_status: IEnvironmentStatus | null = null;
    @observable environments: IEnvironments | null = null;
    @observable environmentsList: IEnvironmentsListResponse | null = null;
    @observable environment: IEnvironment | null = null;
    @observable providers: IProvider[] | null = null;
    @observable regions: IRegion[] | null = null;
    @observable savedIpAddresses: ISavedIPAddress[] | null = null;
    @observable sizes: ISize[] | null = null;
    @observable sizeInfo: ISizes | null = null;
    @observable sizeUpgradeInfo: IEnvironmentsUpgradeTypes | null = null;
    @observable contentLoaded = false;
    @observable environmentUpgradeData: IEnvironmentUpgradeDetails[] | null = null;
    @observable environmentUpgradeOptions: IEnvironmentUpgradeTypes[] | null = null;
    @observable allEnvironments: IEnvironmentsData[] | null = null;
    @observable upgradeEnv: IEnvironmentsData | null = null;
    @observable currentEnvRunning: boolean | null = null;
    @observable currentEnvStatus: string | null = null;
    @observable currentEnvCloudProvider: string | null = null;

    @action setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    };

    @action setAllEnvironments = (envs: IEnvironmentsData[]) => {
        this.allEnvironments = envs;
    };

    @computed
    get envUpgradeOptions() {
        return this.environmentUpgradeOptions;
    }

    @action setCurrentEnvStatus = (status: string) => {
        this.currentEnvStatus = status;
    };

    @action setCloudCode = (code: string) => {
        this.codeChanged = true;
        this.cloud_code = code;
    };

    @action setCodeChanged = (state: boolean) => {
        this.codeChanged = state;
    };

    @action setRegionCode = (code: string) => {
        this.codeChanged = true;
        this.region_code = code;
    };

    @action setIpAddress = (code: string) => {
        this.codeChanged = true;
        this.ipAddressCode = code;
    };

    @action setIPName = (name: string) => {
        this.iPName = name;
    };

    @action setInstanceCode = (code: number) => {
        this.codeChanged = true;
        this.product_id = code;
    };

    @action setContentLoaded = () => {
        this.contentLoaded = true;
    };

    @action setEnvironments = (envs: IEnvironments) => {
        this.environments = envs;
    };

    @action setEnvironment = (env: IEnvironment) => {
        this.environment = env;
    };

    @action setProviders = (providers: IProvider[]) => {
        this.providers = providers;
    };

    @action setRegions = (regions: IRegion[]) => {
        this.regions = regions;
    };

    @action setSizes = (sizes: ISize[]) => {
        this.sizes = sizes;
    };

    @action setSizeInfo = (sizeInfo: ISizes) => {
        this.sizeInfo = sizeInfo;
    };

    @action setSavedIpAddresses = (ipAddresses: ISavedIPAddress[]) => {
        this.savedIpAddresses = ipAddresses;
    };

    @action setEnvironmentStatus = (status: IEnvironmentStatus) => {
        this.environment_status = status;
    };

    @action setEnvironmentUpgradeData = (data: IEnvironmentUpgradeDetails[]) => {
        this.environmentUpgradeData = data;
    };

    @action setEnvironmentUpgradeOptions = (types: IEnvironmentUpgradeTypes[]) => {
        this.environmentUpgradeOptions = types;
    };

    @action setUpgradeEnv = (env: IEnvironmentsData) => {
        this.upgradeEnv = env;
    };

    @action setSizeUpgradeInfo = (info: IEnvironmentsUpgradeTypes) => {
        this.sizeUpgradeInfo = info;
    };

    @action setEnvironmentsList = (envs: IEnvironmentsListResponse) => {
        this.environmentsList = envs;
    };

    @action setCurrentEnvRunning = (running: boolean) => {
        this.currentEnvRunning = running;
    };

    @action setCurrentEnvCloudProvider = (provider: string) => {
        this.currentEnvCloudProvider = provider;
    };

    @action resetEnvInfo = async () => {
        runInAction(() => {
            this.cloud_code = "";
            this.regions = null;
            this.providers = null;
            this.sizes = null;
            this.region_code = "";
        });
    };

    @action setCurrentEnvironmentRunning = async (running: boolean) => {
        runInAction(() => {
            this.currentEnvRunning = running;
        });
    };
}
