import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Alert, AlertTitle, Box, Button, Card, CardContent, CardHeader, Link, Stack, Tab, Typography } from "@mui/material";
import { agent, IGetPolicyDocsResponseItem, IValidateCredentialsRequest, RootStoreContext } from "api";
import { TextField } from "components/Forms";
import Content from "components/Layout/Content";
import React, { useContext, useEffect } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ConfirmationDialog from "components/ConfirmationDialog";
import ConfirmValidateCredentials from "./Components/ConfirmValidateCredentials";
import CopyToClipboard from "react-copy-to-clipboard";

const ValidateOwnCloudCredentials: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { user } = rootStore.userStore;
    const awsStatus = user?.own_cloud_status?.aws || "unavailable";
    const [policyDocs, setPolicyDocs] = React.useState<IGetPolicyDocsResponseItem | null>(null);

    const getPolicyDocs = async () => {
        await agent.OwnCloud.getPolicyDocs()
            .then((response) => {
                setPolicyDocs(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // Get init data
    useEffect(() => {
        getPolicyDocs();
    }, []);

    return (
        <Content>
            <FinalForm
                onSubmit={(values: IValidateCredentialsRequest) => {
                    openDialog(
                        <ConfirmValidateCredentials
                            values={values}
                            cloudProvider="aws"
                        />
                    );
                }}
                render={({ handleSubmit, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        {awsStatus !== "unavailable" && (
                            <Card>
                                <CardHeader title="AWS" />
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Alert severity="info">
                                            <AlertTitle>Before setting up AWS credentials, please ensure:</AlertTitle>
                                            <ol>
                                                <li>
                                                    <Typography variant="body1">
                                                        Policy document attached to your AWS account user has this policy:
                                                    </Typography>
                                                    {policyDocs && (
                                                        <pre>{JSON.stringify(policyDocs, null, 2)}</pre>
                                                    )}
                                                    <CopyToClipboard
                                                        text={JSON.stringify(policyDocs, null, 2)}
                                                        onCopy={() => {
                                                            alert('Copied!');
                                                        }}
                                                    >
                                                        <Button variant="outlined" sx={{ mb: 2 }}>Copy</Button>
                                                    </CopyToClipboard>
                                                </li>
                                                <li>
                                                    <Typography variant="body1">
                                                        You enable the subscription for using ALMA images. To do this - log into your AWS account and <Link href="https://aws.amazon.com/marketplace/pp?sku=be714bpjscoj5uvqz0of5mscl" target="_blank">click here</Link>.
                                                    </Typography>
                                                </li>
                                            </ol>
                                        </Alert>
                                        <TextField
                                            name="aws.access_key_id"
                                            label="Access Key ID"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return 'Required';
                                                }

                                                if (!/^[A-Z0-9]{20}$/.test(value)) {
                                                    return 'Invalid format';
                                                }
                                            }}
                                        />

                                        <TextField
                                            name="aws.secret_access_key"
                                            label="Secret Access Key"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return 'Required';
                                                }

                                                if (!/^[A-Za-z0-9/+]{40}$/.test(value)) {
                                                    return 'Invalid format';
                                                }
                                            }}
                                        />

                                        <Stack spacing={1} direction="row" justifyContent="flex-end">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                            >
                                                Validate
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        )}

                        {submitError && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                {submitError}
                            </Alert>
                        )}
                    </form>
                )}
            />
        </Content>
    );
};

export default ValidateOwnCloudCredentials;