import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import { observer } from "mobx-react-lite";
import MenuItem from "@mui/material/MenuItem";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { RootStoreContext } from "api";
import Button from "@mui/material/Button";
import "../permanent-commitment.scss";
import { TermsAndCommitments } from "./termsAndCommitments/TermsAndCommitments";
import { Card } from "@mui/material";
import { usePermanentSchedule } from "hooks";

const AddTermsTable = () => {
    const rootStore = useContext(RootStoreContext);
    const { dropDownValues, commitmentValues, setClicked, checked, setClickedFalse } = rootStore.permanentScheduleStore;
    const { getDropDownValues, getPermanentScheduleTerms } = usePermanentSchedule();
    const { getGeoInformation, geoData } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;
    const [cp, setCP] = useState<string>("None");
    const [term, setTerm] = useState<number>(0);
    const [bitrate, setBR] = useState<number>(0);
    const [agreementQty, setAgreementQty] = useState<number>(0);
    const [prodID, setProId] = useState<number>(0);
    const [currency, setCurrency] = useState<string>("");
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    useEffect(() => {
        getDropDownValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getPermanentScheduleTerms({
            cloud_provider: cp === "None" ? "" : cp,
            bitrate_mbps: bitrate,
            commitment_term_days: term,
            number_of_agreements: agreementQty
        }).then(() => setClickedFalse());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cp, term, bitrate, agreementQty]);

    const getCurrency = () => {
        getGeoInformation().then(() => {
            if (geoData?.countryCode === "GB") {
                setCurrency("£");
            } else if (geoData?.countryCode === "US") {
                setCurrency("$");
            } else if (geoData?.countryCode === "EUR") {
                setCurrency("€");
            }
        });
    };

    useEffect(() => {
        getGeoInformation().then((data) => {
            setTimeout(() => {
                if (data.countryCode === "GB") {
                    setCurrency("£");
                } else if (data.countryCode === "US") {
                    setCurrency("$");
                } else if (data.countryCode === "EUR") {
                    setCurrency("€");
                } else {
                    getCurrency();
                }
            }, 2000);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloudProviderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCP(event.target.value as string);
    };

    const handleBitrateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBR(event.target.value as any);
    };

    const handleTermChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTerm(event.target.value as any);
    };

    const handleAgreementQtyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAgreementQty(event.target.value as any);
    };

    const setClickedRow = (prodId: number) => {
        setClickedFalse();
        setClicked(prodId);
        setProId(prodId);
    };

    const ppd = (product_id: number) => {
        const priceData = commitmentValues?.prices.filter((pr) => pr.product_id === product_id);
        if (geoData?.countryCode === "GB") {
            return priceData?.map(({ price_gbp }) => (parseFloat(price_gbp) * agreementQty).toFixed(2).toString());
        } else if (geoData?.countryCode === "US") {
            return priceData?.map(({ price_usd }) => (parseFloat(price_usd) * agreementQty).toFixed(2).toString());
        } else {
            return priceData?.map(({ price_eur }) => (parseFloat(price_eur) * agreementQty).toFixed(2).toString());
        }
    };

    const ppt = (product_id: number) => {
        const priceData = commitmentValues?.prices.filter((pr) => pr.product_id === product_id);
        if (geoData?.countryCode === "GB") {
            return priceData?.map(({ total_for_term_gbp }) => (parseFloat(total_for_term_gbp) * agreementQty).toFixed(2).toString());
        } else if (geoData?.countryCode === "US") {
            return priceData?.map(({ total_for_term_usd }) => (parseFloat(total_for_term_usd) * agreementQty).toFixed(2).toString());
        } else {
            return priceData?.map(({ total_for_term_eur }) => (parseFloat(total_for_term_eur) * agreementQty).toFixed(2).toString());
        }
    };

    const totalPerDayCost = ppd(prodID);
    const totalPerTermCost = ppt(prodID);

    return (
        <Card>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "40%" }}>
                                <Select
                                    variant="standard"
                                    labelId="destination-selector-label"
                                    id="destination-select"
                                    value={cp}
                                    onChange={(event: any) => handleCloudProviderChange(event)}
                                    sx={{
                                        width: "100%"
                                    }}>
                                    <MenuItem value="None">Cloud Provider</MenuItem>
                                    {dropDownValues?.cloud_provider_values.map((cp, i) => {
                                        return (
                                            <MenuItem key={i} value={cp.value}>
                                                {cp.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </TableCell>
                            <TableCell sx={{ width: "20%" }}>
                                <Select
                                    variant="standard"
                                    labelId="destination-selector-label"
                                    id="destination-select"
                                    value={term}
                                    onChange={(event: any) => handleTermChange(event)}
                                    style={{ width: "100%" }}>
                                    <MenuItem value={0}>Term</MenuItem>
                                    {dropDownValues?.commitment_term_values.map((ddv, i) => {
                                        return (
                                            <MenuItem key={i} value={ddv.value}>
                                                {ddv.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </TableCell>
                            <TableCell sx={{ width: "20%" }}>
                                <Select
                                    variant="standard"
                                    labelId="destination-selector-label"
                                    id="destination-select"
                                    value={bitrate}
                                    onChange={(event: any) => handleBitrateChange(event)}
                                    style={{ width: "100%" }}>
                                    <MenuItem value={0}>Max bitrates</MenuItem>
                                    {dropDownValues?.bitrate_values.map((bt, i) => {
                                        return (
                                            <MenuItem key={i} value={bt.value}>
                                                {bt.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </TableCell>
                            <TableCell sx={{ width: "20%" }}>
                                <Select
                                    variant="standard"
                                    labelId="destination-selector-label"
                                    id="destination-select"
                                    value={agreementQty}
                                    onChange={(event: any) => handleAgreementQtyChange(event)}
                                    style={{ width: "100%" }}>
                                    <MenuItem value={0}>Number of agreements</MenuItem>
                                    {numbers.map((noa, i) => {
                                        return (
                                            <MenuItem key={i} value={noa}>
                                                {noa}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {cp !== "none" &&
                        term !== 0 &&
                        bitrate !== 0 &&
                        agreementQty !== 0 &&
                        commitmentValues?.prices.map((commitment) => {
                            return (
                                <TableBody key={commitment.product_id}>
                                    <TableRow style={{ cursor: "pointer" }} className={commitment.clicked === true ? "trSelected" : ""}>
                                        <TableCell>{commitment.cloud_provider}</TableCell>
                                        <TableCell>{`${commitment.commitment_days} days`}</TableCell>
                                        <TableCell>{`${commitment.bitrate_mbps} Mbps`}</TableCell>
                                        <TableCell>{`${agreementQty} Agreements`}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    {!checked && (
                                        <TableRow>
                                            <TableCell colSpan={4} sx={{ border: "none" }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setClickedRow(commitment.product_id!);
                                                    }}>
                                                    View pricing
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {checked && (
                                        <TableRow>
                                            <TableCell rowSpan={3} />
                                            <TableCell colSpan={1}>Total Price per day</TableCell>
                                            <TableCell align="right">
                                                {currency} {totalPerDayCost}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {checked && (
                                        <TableRow>
                                            <TableCell colSpan={1}>Total Price per term</TableCell>
                                            <TableCell align="right">
                                                {currency} {totalPerTermCost}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            );
                        })}
                </Table>
            </TableContainer>
            {checked && (
                <div className="containerContextpushRight marginNone" style={{ padding: 10 }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            openDialog(
                                <TermsAndCommitments
                                    productId={prodID}
                                    currency={currency}
                                    totalPerTermCost={totalPerTermCost}
                                    totalPerDayCost={totalPerDayCost}
                                    agreementQty={agreementQty}
                                />
                            );
                        }}>
                        Proceed to T&C's
                    </Button>
                </div>
            )}
        </Card>
    );
};

export default observer(AddTermsTable);
