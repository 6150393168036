import React, { ReactNode } from "react";
import { Field, useForm } from "react-final-form";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { SelectFieldOption } from "api";

interface Props {
    name: string;
    label?: string;
    initialValue?: string | number;
    options: SelectFieldOption[];
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    validate?: (value: any) => string | undefined;
    onChange?: (field: string, value?: any, previous?: any) => void;
}

/**
 * TextField is a React Functional Component that renders a text field with a label.
 * It uses the Material-UI library for styling and layout.
 *
 * @component
 * @param {string} props.name - The name of the text field.
 * @param {string} props.label - The label of the text field.
 * @param {string} [props.initialValue] - The initial value of the text field.
 * @param {function} props.helperText - A function that returns the helper text.
 * @param {function} props.error - A function that returns a boolean indicating if there is an error.
 *
 * @example
 * <TextField name="name" label="Name" helperText={meta => meta.touched && meta.error} error={meta => meta.touched && meta.error} />
 *
 * @returns {ReactElement} The TextField component
 */

export const SelectField: React.FC<Props> = ({
    name,
    label,
    initialValue,
    helperText,
    error,
    validate,
    options,
    required,
    disabled,
    placeholder,
    onChange
}) => {
    const form = useForm();
    return (
        <>
            <Field
                name={name}
                initialValue={initialValue}
                validate={(value: any) => {
                    if (validate) {
                        return validate(value);
                    }
                }}>
                {({ input, meta }) => (
                    <TextField
                        required={required}
                        placeholder={placeholder}
                        {...input}
                        label={label ? label : undefined}
                        fullWidth
                        error={Boolean(
                            (meta.touched && meta.error) || meta.submitError || (meta.touched && meta.invalid) || (error && error(meta))
                        )}
                        helperText={(meta.touched && meta.error) || meta.submitError || (helperText && helperText(meta))}
                        variant="standard"
                        disabled={disabled}
                        select
                        sx={{
                            "#description": {
                                display: "none"
                            }
                        }}>
                        {options.map((option, i) => (
                            <MenuItem key={option.key || i} value={option.value} disabled={option.disabled ? true : false}>
                                <Box sx={{ width: '100%' }}>
                                    {option.text}
                                    {option.description && (
                                        <Typography component="div" variant="caption" color="textSecondary" id="description">
                                            {option.description}
                                        </Typography>
                                    )}
                                </Box>
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {
                    function removeEmpty(obj: any) {
                        Object.keys(obj).forEach((key) => {
                            if (obj[key] && typeof obj[key] === "object") {
                                removeEmpty(obj[key]);
                            }
                            if (obj[key] && Array.isArray(obj[key]) && !obj[key].length) {
                                delete obj[key];
                            }
                            if (obj[key] && typeof obj[key] === "object" && !Object.keys(obj[key]).length) {
                                delete obj[key];
                            }
                        });
                        return obj;
                    }

                    if (form.getState().submitErrors) {
                        const keys = name.split(".");
                        let submitErrors = form.getState().submitErrors;

                        for (let key of keys) {
                            if (submitErrors && typeof submitErrors === "object" && key in submitErrors) {
                                submitErrors = submitErrors[key];
                            } else {
                                submitErrors = undefined;
                                break;
                            }
                        }

                        if (submitErrors) {
                            console.log("Submit error on field", submitErrors);
                            // Deleting the error from the original object is a bit more complex because you need to navigate to the parent object
                            let parentObject = form.getState().submitErrors;
                            let grandParentObject;
                            let grandParentKey;
                            for (let i = 0; i < keys.length - 1; i++) {
                                if (parentObject && typeof parentObject === "object" && keys[i] in parentObject) {
                                    grandParentObject = parentObject;
                                    grandParentKey = keys[i];
                                    parentObject = parentObject[keys[i]];
                                } else {
                                    parentObject = undefined;
                                    break;
                                }
                            }
                            if (parentObject && typeof parentObject === "object") {
                                delete parentObject[keys[keys.length - 1]];
                                // Check if parentObject is now empty
                                if (Object.keys(parentObject).length === 0 && grandParentObject && grandParentKey) {
                                    delete grandParentObject[grandParentKey];
                                }
                            }
                        }

                        console.log(removeEmpty(form.getState().submitErrors));
                        //form.mutators.setSubmitErrors(removeEmpty(form.getState().submitErrors));
                    }

                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </>
    );
};

export default SelectField;
