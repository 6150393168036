import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";

export const TermsOfService = () => {
    return (
        <Card sx={{ background: "none", padding: 4 }}>
            <Box
                sx={{
                    m: 4,
                    color: "#ffffff",
                    py: "4rem",
                    px: "6rem",
                    cusor: "default",
                    backgroundColor: "background.paper"
                }}>
                <Stack spacing={2} direction={"row"} width="100%" justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h4">Livelink Platform Terms of Service</Typography>
                    <Typography variant="h4">Last Updated: February 2021</Typography>
                </Stack>
                <Typography variant="h5" sx={{ my: 1 }}>
                    Welcome to the Livelink platform (“Livelink”)
                </Typography>
                <Typography sx={{ my: 1 }}>
                    The following terms (“Platform Terms”) govern your access to Livelink platform and use of the Services (as defined
                    below) and is an agreement between Cerberus Tech Limited (also referred to as “Cerberus,” “we,” “us,” or “our”) and you
                    or the entity you represent (“you” or “your”). The Platform Terms take effect when you access the Livelink platform or
                    use any of the Services (the “Effective Date”). You represent to us that you are lawfully able to enter into contracts
                    and if entering into the Platform Terms for an entity, such as the company you work for, you represent to us that you
                    have legal authority to bind that entity. Please see Section 14 for definitions of terms used in Platform Terms.
                </Typography>
                <Typography sx={{ my: 1 }}>1. Use of the Services & Account.</Typography>
                <Typography sx={{ my: 1 }}>
                    1.1 You will comply with the Platform Terms and all Cerberus, rules and regulations applicable to your use of the
                    Services. To access the Services, you must have a Livelink account associated with a valid email address. You will only
                    create one account per email address.
                </Typography>
                <Typography sx={{ my: 1 }}>2. Changes to Services.</Typography>
                <Typography sx={{ my: 1 }}>
                    2.1 We may change or discontinue any of the Services from time to time. We will provide you with prior notice, except
                    that this notice will not be required if (a) it would pose a security or intellectual property issue to us or the
                    Services, (b) is economically or technically burdensome, or (c) would cause us to violate legal requirements.
                </Typography>
                <Typography sx={{ my: 1 }}>3. Security and Data Privacy.</Typography>
                <Typography sx={{ my: 1 }}>
                    3.1 Without limiting Section 10 or your obligations under Section 4.2, we will implement reasonable and appropriate
                    measures designed to help you secure Your Content against accidental or unlawful loss, access or disclosure.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    3.2 Data Privacy. You may specify the Livelink regions in which Your Content will be ingested/stored/transferred. You
                    consent to the ingest, storage and transfer of Your Content into the Livelink regions you select. We will not access or
                    use Your Content except as necessary to maintain or provide the Services, or as necessary to comply with the law or a
                    binding order of a governmental body. We will not (a) disclose Your Content to any government or third party (save any
                    third party to whom You authorise and provide access to Your Content or (b) subject to Section 3.3, move Your Content
                    from the Livelink regions selected by you; except in each case as necessary to comply with the law or a binding order of
                    a governmental body. Unless it would violate the law or a binding order of a governmental body, we will give you notice
                    of any legal requirement or order referred to in this Section 3.2. We will only use your Account Information in
                    accordance with the Cerberus Privacy Policy{" "}
                    <a href="https://www.cerberus.tech/privacy-policy" target="_blank" rel="noopener noreferrer">
                        https://www.cerberus.tech/privacy-policy
                    </a>{" "}
                    as updated from time to time. In addition, depending on the payment methods used we may collect payment transaction data
                    as may our third party payment processor, which is currently Stripe Inc. as we wish to ensure ‘best in class’ security
                    for payment transaction data (Stripe’s Privacy Policy is here:{" "}
                    <a href="https://stripe.com/en-gb/privacy" target="_blank" rel="noopener noreferrer">
                        https://stripe.com/en-gb/privacy
                    </a>
                    ). You consent to such usage.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    3.3 Service Data. To provide billing and administration services, we (or third payment payments processor) may process
                    Service Data in any of the Livelink region(s). To provide you with support services initiated by you and investigate
                    fraud, abuse or violations of Platform Terms, we may process Service Data where we maintain our support and
                    investigation personnel.
                </Typography>
                <Typography sx={{ my: 1 }}>4. Your Responsibilities.</Typography>
                <Typography sx={{ my: 1 }}>
                    4.1 Your Accounts. Except to the extent caused by our breach of Platform Terms, (a) you are responsible for all
                    activities that occur under your account, regardless of whether the activities are authorized by you or undertaken by
                    you, your employees or a third party (including your contractors, agents or End Users), and (b) we and our affiliates
                    are not responsible for unauthorized access to your account.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    4.2 Your Content. You will ensure that Your Content and your and End Users’ use of Your Content or the Services will not
                    violate any of the Policies or any applicable law. You are solely responsible for the development, content, operation,
                    maintenance, and use of Your Content.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    4.3 Your Security and Backup. You are responsible for properly configuring and using the Services and otherwise taking
                    appropriate action to secure, protect and backup your accounts and Your Content in a manner that will provide
                    appropriate security and protection, which might include use of encryption to protect Your Content from unauthorized
                    access and routinely archiving Your Content.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    4.4 Login Credentials and Account Keys. Livelink log-in credentials and private keys generated by the Services are for
                    your internal use only and you will not sell, transfer or sublicense them to any other entity or person, except that you
                    may disclose your private key to your agents and subcontractors performing work on your behalf.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    4.5 End Users. You will be deemed to have taken any action that you permit, assist or facilitate any person or entity to
                    take related to the Livelink platform, Your Content or use of the Services. You are responsible for End Users’ use of
                    Your Content and the Services. You will ensure that all End Users comply with your obligations under the Platform Terms
                    and that the terms of your agreement with each End User are consistent with the Platform Terms. If you become aware of
                    any violation of your obligations under Platform Terms caused by an End User, you will immediately suspend access to
                    Your Content and the Services by such End User. We do not provide any support or services to End Users unless we have a
                    separate agreement with you or an End User expressly obligating us to provide such support or services.
                </Typography>
                <Typography sx={{ my: 1 }}>5. Fees and Payment.</Typography>
                <Typography sx={{ my: 1 }}>
                    5.1 Service Credits/Fees. Credits for use of the Services can be purchased in advance and you are responsible for
                    ensuring adequate credits are purchased for the services you require. Credits not used or consumed shall not be
                    refunded. Please refer to Settings/Billings on the Livelink platform for more detail on ‘Credits’. If you apply for an
                    account billing arrangement then if such application is approved by Cerberus at its discretion then wee calculate usage
                    and bill fees and charges monthly. We may bill you more frequently for fees accrued if we suspect that your account is
                    fraudulent or at risk of non-payment. You will pay us the applicable fees and charges for use of the Services as
                    described on the Livelink usage report produced by the Livelink platform using one of the payment methods we support.
                    All amounts payable by you under Platform Terms will be paid to us without setoff or counterclaim, and without any
                    deduction or withholding. Fees and charges for any new Service or new feature of a Service will be effective when we
                    post updated fees and charges on the Livelink platform, unless we expressly state otherwise in a notice. We may increase
                    or add new fees and charges for any existing Services you are using by giving you at least 30 days’ prior notice but if
                    you do not accept such changes then you shall be entitled to cease use of the Livelink platform and use of the Services.
                    We may elect to charge you interest at the rate of 1.5% per month (or the highest rate permitted by law, if less) on all
                    late payments.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    5.2 Taxes. Each party will be responsible, as required under applicable law, for identifying and paying all taxes and
                    other governmental fees and charges (and any penalties, interest, and other additions thereto) that are imposed on that
                    party upon or with respect to the transactions and payments under the Platform Terms. All fees payable by you are
                    exclusive of Indirect Taxes, except where applicable law requires otherwise. We may charge and you will pay applicable
                    Indirect Taxes that we are legally obligated or authorized to collect from you. You will provide such information to us
                    as reasonably required to determine whether we are obligated to collect Indirect Taxes from you. We will not collect,
                    and you will not pay, any Indirect Tax for which you furnish us a properly completed exemption certificate or a direct
                    payment permit certificate for which we may claim an available exemption from such Indirect Tax. All payments made by
                    you to us under the Platform Terms will be made free and clear of any deduction or withholding, save as may be required
                    by law. If any such deduction or withholding (including but not limited to cross-border withholding taxes) is required
                    on any payment, you will pay such additional amounts as are necessary so that the net amount received by us is equal to
                    the amount then due and payable under Platform Terms. We will provide you with such tax forms as are reasonably
                    requested in order to reduce or eliminate the amount of any withholding or deduction for taxes in respect of payments
                    made under Platform Terms.
                </Typography>
                <Typography sx={{ my: 1 }}>6. Temporary Suspension.</Typography>
                <div>
                    6.1 We may suspend your or any End User’s right to access or use any portion or all of the Services immediately upon
                    notice to you if we determine:
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            (a) Your or an End User’s use of the Services (i) poses a security risk to the Services or any third party, (ii)
                            could adversely impact our systems, the Services or the systems or Content of any other Cerberus customer, (iii)
                            could subject us, our affiliates, or any third party to liability, or (iv) could be fraudulent;
                        </li>
                        <li>(b) You are, or any End User is, in breach of Platform Terms;</li>
                        <li>(c) You are in breach of your payment obligations; or</li>
                        <li>
                            (d) You have ceased to operate in the ordinary course, made an assignment for the benefit of creditors or
                            similar disposition of your assets, or become the subject of any bankruptcy, reorganization, liquidation,
                            dissolution or analogous proceeding in any jurisdiction.
                        </li>
                    </ul>
                </div>
                <Typography sx={{ my: 1 }}>
                    6.2. If we suspend your right to access or use any portion or all of the Services you remain responsible for all fees
                    and charges you incur during the period of suspension.
                </Typography>
                <Typography sx={{ my: 1 }}>7. Termination.</Typography>
                <Typography sx={{ my: 1 }}>
                    7.1 The term of this agreement will commence on the Effective Date and will remain in effect until terminated under this
                    Section 7. Any notice of termination by either party to the other must include a Termination Date that complies with the
                    notice periods in Section 7.2.
                </Typography>
                <div>
                    7.2 Termination.
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            (a) Termination for Convenience. Unless you have otherwise agreed a minimum term with Cerberus (in which case
                            the minimum term provisions shall apply), you may terminate this agreement for any reason by closing your
                            account and paying any charges for services to such point. We may terminate this agreement for any reason by
                            providing you at least 30 days’ advance notice.
                        </li>
                        <li>
                            (b) Termination for Cause.
                            <ul style={{ listStyle: "none" }}>
                                <li>
                                    (i) Either party may terminate this agreement for cause if the other party is in material breach of
                                    Platform Terms and the material breach remains uncured for a period of 14 days from receipt of notice by
                                    the other party. No later than the Termination Date, you will close your account.
                                </li>
                                <li>
                                    (ii) We may also terminate this agreement immediately upon notice to you (A) for cause if we have the
                                    right to suspend under Section 6, (B) if our relationship with a third-party partner who provides
                                    software or other technology we use to provide the Services expires, terminates or requires us to change
                                    the way we provide the software or other technology as part of the Services, or (C) in order to comply
                                    with the law or requests of governmental entities.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div>
                    7.3 Effect of Termination. Upon the Termination Date:
                    <ul style={{ listStyle: "none" }}>
                        <li>(i) all your rights under Platform Terms immediately terminate;</li>
                        <li>
                            (ii) you remain responsible for all fees and charges you have incurred through to and including the Termination
                            Date;
                        </li>
                        <li>
                            (iii) you will immediately return or, if instructed by us, destroy all Cerberus Content in your possession; and
                        </li>
                        <li>
                            (iv) Sections 4.1, 5, 7.3, 8 (except the license granted to you in Section 8.3), 9, 10, 11, 13 and 14 will
                            continue to apply in accordance with their terms.
                        </li>
                    </ul>
                    For any use of the Services after the Termination Date, the Platform Terms will apply and you will pay the applicable
                    fees at the rates referred to on Livelink platform.
                </div>
                <Typography sx={{ my: 1 }}>8. Proprietary Rights.</Typography>
                <Typography sx={{ my: 1 }}>
                    8.1 Your Content. Except as provided in this Section 8, we obtain no rights under Platform Terms from you (or your
                    licensors) to Your Content. You consent to our use of Your Content to provide the Services to you and any End Users.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    8.2 Adequate Rights. You represent and warrant to us that: (a) you or your licensors own all right, title, and interest
                    in and to Your Content and Suggestions; (b) you have all rights in Your Content and Suggestions necessary to grant the
                    rights contemplated by Platform Terms; and (c) none of Your Content or End Users’ use of Your Content or the Services
                    will violate any applicable laws.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    8.3 Services License. We, or our licensors, own all rights, title, and interest in and to the Services, and all related
                    technology and intellectual property rights. Subject to the terms of Platform Terms, we grant you a limited, revocable,
                    non-exclusive, non-sub licensable, non-transferable license to do the following: (a) access and use the Services solely
                    in accordance with Platform Terms; and (b) copy and use the Cerberus Content solely in connection with your permitted
                    use of the Services. Except as provided in this Section 8.3, you obtain no rights under the Platform Terms from us, our
                    affiliates or our licensors to the Services, including any related intellectual property rights.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    8.4 License Restrictions. Neither you nor any End User will use the Services in any manner or for any purpose other than
                    as expressly permitted by the Platform Terms. Neither you nor any End User will, or will attempt to (a) modify,
                    distribute, alter, tamper with, repair, or otherwise create derivative works of any Content included in the Livelink
                    platform or the Services, (b) reverse engineer, disassemble, or decompile the Livelink platform, the Services or apply
                    any other process or procedure to derive the source code of any software included in the Livelink platform or the
                    Services (except to the extent applicable law prohibits this restriction), (c) access or use of the Livelink platform or
                    the Services in a way intended to avoid incurring fees or exceeding usage limits or quotas, or (d) resell or sublicense
                    access to the Livelink platform or the Services
                </Typography>
                <Typography sx={{ my: 1 }}>
                    8.5 If you provide any Suggestions to us or our affiliates, we and our affiliates will be entitled to use the
                    Suggestions without restriction. You hereby irrevocably assign to us all right, title, and interest in and to the
                    Suggestions and agree to provide us any assistance we require to document, perfect, and maintain our rights in the
                    Suggestions.
                </Typography>
                <Typography sx={{ my: 1 }}>9. Indemnification.</Typography>
                <Typography sx={{ my: 1 }}>
                    9.1 You will defend, indemnify, and hold harmless us, our affiliates and licensors, and each of their respective
                    employees, officers, directors, and representatives from and against any Losses arising out of or relating to any
                    third-party claim concerning: (a) your or any End Users’ access of the Livelink platform or use of the Services
                    (including any activities under your Livelink account and use by your employees and personnel); (b) breach of Platform
                    Terms or violation of applicable law by you, End Users or Your Content; or (c) a dispute between you and any End User.
                    You will reimburse us for reasonable legal fees, as well as our employees’ and contractors’ time and materials spent
                    responding to any third party compulsory legal order or process associated with third party claims described in (a)
                    through (c) above at then-current hourly rates.
                </Typography>
                <div>
                    9.2 Intellectual Property.
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            (a) Subject to the limitations in this Section 9, Cerberus will defend you and your employees, officers, and
                            directors against any third-party claim alleging that the Livelink platform or the Services infringe or
                            misappropriate that third party’s intellectual property rights, and will pay the amount of any adverse final
                            judgment or settlement.
                        </li>
                        <li>
                            (b) Subject to the limitations in this Section 9, you will defend Cerberus, its affiliates, and their respective
                            employees, officers, and directors against any third-party claim alleging that any of Your Content infringes or
                            misappropriates that third party’s intellectual property rights, and will pay the amount of any adverse final
                            judgment or settlement.
                        </li>
                        <li>
                            (c) Neither party will have obligations or liability under this Section 9.2 arising from infringement by
                            combinations of the Livelink platform/Services or Your Content, as applicable, with any other product, service,
                            software, data, content or method. In addition, Cerberus will have no obligations or liability arising from your
                            or any End User’s access of Livelink platform or use of the Services after Cerberus has notified you to
                            discontinue such use. The remedies provided in this Section 9.2 are the sole and exclusive remedies for any
                            third-party claims of infringement or misappropriation of intellectual property rights by Livelink platform, the
                            Services or by Your Content.
                        </li>
                        <li>
                            (d) For any claim covered by Section 9.2(a), Cerberus will, at its election, either: (i) procure the rights to
                            use that portion of Livelink platform or the Services alleged to be infringing; (ii) replace the alleged
                            infringing portion of Livelink platform or the Services with a non-infringing alternative; (iii) modify the
                            alleged infringing portion of Livelink platform or the Services to make it non-infringing; or (iv) terminate the
                            allegedly infringing portion of Livelink platform, the Services or Platform Terms.
                        </li>
                    </ul>
                </div>
                <Typography sx={{ my: 1 }}>
                    9.3 The obligations under this Section 9 will apply only if the party seeking defence or indemnity: (a) gives the other
                    party prompt written notice of the claim; (b) reasonably cooperates with the other party (at the other party’s expense)
                    in the defence and settlement of the claim. In no event will a party agree to any settlement of any claim that involves
                    any commitment, other than the payment of money, without the written consent of the other party.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    9.4 All Cerberus Content included on, utilised by or supporting the function of Livelink, platform such as text,
                    graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property
                    of Cerberus or its suppliers and protected by copyright law. Trademarks: “Cerberus Tech”, “Livelink”, “IO”, “Network1”
                    and other Cerberus graphics, logos, page headers, button icons, scripts, and service names are trademarks, registered
                    trademarks or trade dress of Cerberus anywhere in the world.
                </Typography>
                <Typography sx={{ my: 1 }}>10. Disclaimers.</Typography>
                <Typography sx={{ my: 1 }}>
                    THE SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, OR TO THE EXTENT ANY STATUTORY RIGHTS APPLY
                    THAT CANNOT BE EXCLUDED, LIMITED OR WAIVED, WE AND OUR AFFILIATES AND LICENSORS (A) MAKE NO REPRESENTATIONS OR
                    WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE SERVICES OR THE THIRD-PARTY
                    CONTENT, AND (B) DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED OR EXPRESS WARRANTIES (I) OF MERCHANTABILITY,
                    SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR QUIET ENJOYMENT, (II) ARISING OUT OF ANY
                    COURSE OF DEALING OR USAGE OF TRADE, (III) THAT THE SERVICES OR THIRD-PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR
                    FREE OF HARMFUL COMPONENTS, AND (IV) THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR ALTERED.
                </Typography>
                <Typography sx={{ my: 1 }}>11. Limitations of Liability.</Typography>
                <Typography sx={{ my: 1 }}>
                    WE AND OUR AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                    EXEMPLARY DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, REVENUES, CUSTOMERS, OPPORTUNITIES, GOODWILL, USE, OR DATA),
                    EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER, NEITHER WE NOR ANY OF OUR AFFILIATES OR
                    LICENSORS WILL BE RESPONSIBLE FOR ANY COMPENSATION, REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION WITH: (A) YOUR
                    INABILITY TO USE THE SERVICES, INCLUDING AS A RESULT OF ANY (I) TERMINATION OR SUSPENSION OF THIS AGREEMENT OR YOUR USE
                    OF OR ACCESS TO THE PLATFORM OR THE SERVICES, (II) OUR DISCONTINUATION OF ANY OR ALL OF THE SERVICES, OR, (III), ANY
                    UNANTICIPATED OR UNSCHEDULED DOWNTIME OF ALL OR A PORTION OF PLATFORM OR THE SERVICES FOR ANY REASON; (B) THE COST OF
                    PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; (C) ANY INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY YOU IN CONNECTION WITH
                    THIS AGREEMENT OR YOUR USE OF OR ACCESS TO THE PLATFORM OR SERVICES; OR (D) ANY UNAUTHORIZED ACCESS TO, ALTERATION OF,
                    OR THE DELETION, DESTRUCTION, DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT OR OTHER DATA. IN ANY CASE, EXCEPT
                    FOR PAYMENT OBLIGATIONS UNDER SECTION 9.2, OUR AND OUR AFFILIATES’ AND LICENSORS’ AGGREGATE LIABILITY UNDER THE PLATFORM
                    TERMS WILL NOT EXCEED THE AMOUNT YOU ACTUALLY PAY US UNDER THE PLATFORM TERMS FOR THE SERVICE THAT GAVE RISE TO THE
                    CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE. THE LIMITATIONS IN THIS SECTION 11 APPLY ONLY TO THE MAXIMUM
                    EXTENT PERMITTED BY APPLICABLE LAW.
                </Typography>
                <Typography sx={{ my: 1 }}>12. Modifications to the Agreement.</Typography>
                <Typography sx={{ my: 1 }}>
                    We may modify Platform Terms (including any Policies) at any time by posting a revised version on the Livelink platform
                    or by otherwise notifying you in accordance with Section 13.10. The modified terms will become effective upon posting
                    or, if we notify you by email, as stated in the email message. By continuing to use Livelink platform or the Services
                    after the effective date of any modifications to Platform Terms, you agree to be bound by the modified terms. It is your
                    responsibility to check the Livelink platform regularly for modifications to Platform Terms.
                </Typography>
                <Typography sx={{ my: 1 }}>13. Miscellaneous.</Typography>
                <Typography sx={{ my: 1 }}>
                    13.1 Assignment. You will not assign or otherwise transfer this agreement or any of your rights and obligations under
                    Platform Terms, without our prior written consent. Any assignment or transfer in violation of this Section 13.1 will be
                    void. We may assign this agreement without your consent (a) in connection with a merger, acquisition or sale of all or
                    substantial part of our assets, or (b) to any affiliate or as part of a corporate reorganization; and effective upon
                    such assignment, the assignee is deemed substituted for Cerberus as a party to this agreement and Cerberus is fully
                    released from all of its obligations and duties to perform under Platform Terms. Subject to the foregoing, this
                    agreement will be binding upon, and inure to the benefit of the parties and their respective permitted successors and
                    assigns.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.2 Entire Agreement. Platform Terms incorporates the Policies by reference and is the entire agreement between you and
                    us regarding the subject matter of this agreement unless and to the extent that we have agreed terms which take
                    precedence and which expressly refer to this clause 13.2. Platform Terms supersedes all prior or contemporaneous
                    representations, understandings, agreements, or communications between you and us, whether written or verbal, regarding
                    the subject matter of this agreement (but does not supersede prior commitments to purchase Services) and save as
                    expressly provided to the contrary under express reference to this clause 13.2. We will not be bound by, and
                    specifically object to, any term, condition or other provision that is different from or in addition to the provisions
                    of Platform Terms (whether or not it would materially alter Platform Terms) including for example, any term, condition
                    or other provision (a) submitted by you in any order, receipt, acceptance, confirmation, correspondence or other
                    document, (b) related to any online registration, response to any Request for Bid, Request for Proposal, Request for
                    Information, or other questionnaire, or (c) related to any invoicing process that you submit or require us to complete.
                    If the terms of this document are inconsistent with the terms contained in any Policy, the terms contained in this
                    document will control.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.3 Force Majeure. We and our affiliates will not be liable for any delay or failure to perform any obligation under
                    Platform Terms where the delay or failure results from any cause beyond our reasonable control, including acts of God,
                    labour disputes or other industrial disturbances, electrical or power outages, utilities or other telecommunications
                    failures, earthquake, storms or other elements of nature, pandemics, blockages, embargoes, riots, acts or orders of
                    government, acts of terrorism, or war.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.4 Governing Law. The laws of England and Wales, without reference to conflict of law rules, govern this agreement and
                    any dispute of any sort that might arise between you and us. The United Nations Convention for the International Sale of
                    Goods does not apply to Platform Terms.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.5 Disputes. Any dispute or claim relating in any way to your use of the Services, or to any products or services sold
                    or distributed by Cerberus will be adjudicated in the Governing Courts, and you consent to exclusive jurisdiction and
                    venue in the Governing Courts
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.6 Trade Compliance. In connection with this agreement, each party will comply with all applicable import, re-import,
                    sanctions, anti-boycott, export, and re-export controls and regulations. For clarity, you are solely responsible for
                    compliance related to the manner in which you choose to use Livelink platform and the Services, including your transfer
                    and processing of Your Content, the provision of Your Content to End Users, and the Livelink region in which any of the
                    foregoing occurs. You represent and warrant that you and your financial institutions, or any party that owns or controls
                    you or your financial institutions, are not subject to sanctions or otherwise designated on any list of prohibited or
                    restricted parties, including but not limited to the lists maintained by the United Nations Security Council, the U.S.
                    Government (e.g., the Specially Designated Nationals List and Foreign Sanctions Evaders List of the U.S. Department of
                    Treasury, and the Entity List of the U.S. Department of Commerce), the European Union or its Member States, or other
                    applicable government authority.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.7 Independent Contractors; Non-Exclusive Rights. We and you are independent contractors, and this agreement will not
                    be construed to create a partnership, joint venture, agency, or employment relationship. Neither party, nor any of their
                    respective affiliates, is an agent of the other for any purpose or has the authority to bind the other.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.8 Language. All communications and notices made or given pursuant to Platform Terms must be in the English language.
                    If we provide a translation of the English language version of Platform Terms, the English language version of the
                    Agreement will control if there is any conflict.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.9 Confidentiality and Publicity. You may use Cerberus Confidential Information only in connection with your use of
                    the Services as permitted under Platform Terms. You will not disclose Cerberus Confidential Information during the Term
                    or at any time during the 5-year period following the end of the Term. You will take all reasonable measures to avoid
                    disclosure, dissemination or unauthorized use of Cerberus Confidential Information, including, at a minimum, those
                    measures you take to protect your own confidential information of a similar nature.
                </Typography>
                <div>
                    13.10 Notice.
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            (a) To You. We may provide any notice to you under this agreement by: (i) posting a notice on the Livelink
                            platform; or (ii) sending a message to the email address then associated with your account. Notices we provide
                            by posting on the Livelink platform will be effective upon posting and notices we provide by email will be
                            effective when we send the email. It is your responsibility to keep your email address current. You will be
                            deemed to have received any email sent to the email address then associated with your account when we send the
                            email, whether or not you actually receive the email.
                        </li>
                        <li>
                            (b) To Us. To give us notice under this agreement, you must contact Cerberus by personal delivery, overnight
                            courier or registered or certified mail or email to the mailing address, as applicable, listed for Cerberus{" "}
                            <a
                                style={{ cursor: "pointer" }}
                                href="https://www.cerberus.tech/contact"
                                target="_blank"
                                rel="noopener noreferrer">
                                www.cerberus.tech/contact
                            </a>
                            . We may update the address for notices to us by posting a notice on
                            <a
                                style={{ cursor: "pointer" }}
                                href="https://www.cerberus.tech/contact"
                                target="_blank"
                                rel="noopener noreferrer">
                                www.cerberus.tech/contact
                            </a>
                            . Notices provided by personal delivery will be effective immediately. Notices provided by overnight courier
                            will be effective one business day after they are sent. Notices provided registered or certified mail or email
                            will be effective three business days after they are sent.
                        </li>
                    </ul>
                </div>
                <Typography sx={{ my: 1 }}>
                    13.11 No Third-Party Beneficiaries. Except as set forth in Section 9, this agreement does not create any third-party
                    beneficiary rights in any individual or entity that is not a party to this agreement.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.13 No Waivers. The failure by us to enforce any provision of Platform Terms will not constitute a present or future
                    waiver of such provision nor limit our right to enforce such provision at a later time. All waivers by us must be in
                    writing to be effective.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    13.14 Severability. If any portion of Platform Terms is held to be invalid or unenforceable, the remaining portions of
                    Platform Terms will remain in full force and effect. Any invalid or unenforceable portions will be interpreted to effect
                    and intent of the original portion. If such construction is not possible, the invalid or unenforceable portion will be
                    severed from Platform Terms but the rest of Platform Terms will remain in full force and effect.
                </Typography>
                <Typography sx={{ my: 1 }}>14. Definitions.</Typography>
                <Typography sx={{ my: 1 }}>
                    “Account Country” is the country associated with your account. If you have provided a valid tax registration number for
                    your account, then your Account Country is the country associated with your tax registration. If you have not provided a
                    valid tax registration, then your Account Country is the country where your billing address is located, except if you
                    have a credit card associated with you Livelink account that is issued in a different country and your contact address
                    is also in that country, then your Account Country is that different country.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Account Information” means information about you that you provide to us in connection with the creation or
                    administration of your Livelink account. For example, Account Information includes names, usernames, phone numbers,
                    email addresses and billing information associated with your Livelink account.
                </Typography>
                <Typography sx={{ my: 1 }}>“API” means an application program interface.</Typography>
                <Typography sx={{ my: 1 }}>
                    “Cerberus Confidential Information” means all non-public information disclosed by us, our affiliates, business partners
                    or our or their respective employees, contractors or agents that is designated as confidential or that, given the nature
                    of the information or circumstances surrounding its disclosure, reasonably should be understood to be confidential.
                    Cerberus Confidential Information includes: (a) non-public information relating to our or our affiliates or business
                    partners’ technology, customers, business plans, promotional and marketing activities, finances and other business
                    affairs; (b) third-party information that we are obligated to keep confidential; and (c) the nature, content and
                    existence of any discussions or negotiations between you and us or our affiliates. Cerberus Confidential Information
                    does not include any information that: (i) is or becomes publicly available without breach of Platform Terms; (ii) can
                    be shown by documentation to have been known to you at the time of your receipt from us; (iii) is received from a third
                    party who did not acquire or disclose the same by a wrongful or tortious act; or (iv) can be shown by documentation to
                    have been independently developed by you without reference to the Cerberus Confidential Information.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Cerberus Content” means Content we or any of our affiliates make available in connection with the Services or access to
                    or on the Livelink platform to allow access to and use of the Services, including APIs; WSDLs; Documentation; sample
                    code; software libraries; command line tools; proofs of concept; templates; and other related technology (including any
                    of the foregoing that are provided by our personnel). Cerberus Content does not include the Services or Third-Party
                    Content.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Cerberus Marks” means any trademarks, service marks, service or trade names, logos, and other designations of Cerberus
                    and its affiliates that we may make available to you in connection with Platform Terms including Livelink.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Cerberus Site” means www.cerberus.tech (and any successor or related site designated by us), as may be updated by us
                    from time to time.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Content” means software (including machine images), data, text, audio, video or images ingested to, transported or
                    delivered by the Livelink platform.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Documentation” means the user guides and admin guides (in each case exclusive of content referenced via hyperlink) for
                    the Services located at or via the Livelink platform and any successor or related locations designated by us), as such
                    user guides and admin guides may be updated by Cerberus from time to time.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “End User” means any individual or entity that directly or indirectly through or authorised another user: (a) receives,
                    accesses or uses Your Content; or (b) otherwise accesses or uses the Services under your account.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Indirect Taxes” means applicable taxes and duties, including, without limitation, VAT, Service Tax, GST, excise taxes,
                    sales and transactions taxes, and gross receipts tax.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Losses” means any claims, damages, losses, liabilities, costs, and expenses (including reasonable legal fees).
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Policies” means the all restrictions described in the Cerberus Content and on the Cerberus Site, and any other policy
                    or terms referenced in or incorporated into Platform Terms, but does not include whitepapers or other marketing
                    materials referenced on the Cerberus Site.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Service” means each of the Livelink platform services made available by us or our affiliates. Services do not include
                    Third-Party Content.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Service Data” means Service usage data related to your account, such as resource identifiers, metadata tags, security
                    and access roles, rules, usage policies, permissions, usage statistics and analytics.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Services” means the Services (including associated APIs), the Cerberus Content, the Cerberus Marks, and any other
                    product or service provided by us under Platform Terms. Services do not include Third-Party Content.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Suggestions” means all suggested improvements to the Services that you provide to us.
                </Typography>
                <Typography sx={{ my: 1 }}>“Term” means the term of this agreement described in Section 7.1.</Typography>
                <Typography sx={{ my: 1 }}>
                    “Termination Date” means the effective date of termination provided in accordance with Section 7, in a notice from one
                    party to the other.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Third-Party Content” means Content made available to you by any third party on Livelink platform in conjunction with
                    the Services.
                </Typography>
                <Typography sx={{ my: 1 }}>
                    “Your Content” means Content that you or any End User ingests to or transfers via the Livelink platform to or by the
                    Services in connection with your Livelink account. Your Content does not include Account Information.
                </Typography>
            </Box>
        </Card>
    );
};
