import React from "react";
import { TextField, Button, IconButton } from "@mui/material";
import { agent, ICreateMTRHostGroupRequest, ICreateMTRHostGroupResponse, IMtrGroupsHostEntry } from "api";
import ErrorMessages from "components/ErrorMessages";
import { Delete } from "@mui/icons-material";

type CreateHostGroupFormProps = {
    id: string;
    onLoading: (loading: boolean) => void;
    onSuccess: () => void;
    onError: () => void;
};

const CreateHostGroupForm: React.FC<CreateHostGroupFormProps> = ({ id, onLoading, onSuccess, onError }) => {
    const [groupName, setGroupName] = React.useState("");
    const [hosts, setHosts] = React.useState<IMtrGroupsHostEntry[]>([]);
    const [error, setError] = React.useState<any>();

    const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupName(event.target.value);
        setError("");
    };

    const handleHostChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newHosts: any = [...hosts];
        newHosts[index][event.target.name] = event.target.value;
        setHosts(newHosts);
        setError("");
    };

    const handleAddHost = () => {
        setHosts([...hosts, { host: "", host_name: "" }]);
    };

    const handleRemoveHost = (index: number) => () => {
        setHosts(hosts.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        onLoading(true);

        const target = e.target as typeof e.target & {
            group_name: { value: string };
        };

        const request: ICreateMTRHostGroupRequest = {
            group_name: target.group_name.value,
            hosts: hosts
        };

        await agent.Reporting.createMtrGroup(request)
            .then((value: ICreateMTRHostGroupResponse) => {
                setHosts([]);
                target.group_name.value = "";
                onSuccess();
            })
            .catch((error: ICreateMTRHostGroupResponse | any) => {
                setError(error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred");
                console.log(error);
                onError();
            });
    };

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const CreateHostGroupFormStyles = {};

    return (
        <form id={id} onSubmit={handleSubmit} style={CreateHostGroupFormStyles}>
            <TextField
                variant="standard"
                id="group_name"
                name="group_name"
                label="Group Name"
                onChange={handleGroupNameChange}
                required
                style={{ marginBottom: "10px" }}
            />

            <br />
            {hosts.map((host, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "rgba(160,160,160,0.02",
                        borderRadius: 4,
                        marginBottom: 10,
                        padding: 10
                    }}>
                    <div style={{ flexGrow: 1, marginRight: 30 }}>
                        <TextField
                            variant="standard"
                            label="Host Name"
                            type="text"
                            name="host_name"
                            onChange={handleHostChange(index)}
                            required
                        />

                        <br />
                        <TextField
                            variant="standard"
                            label="IP/DNS Address"
                            type="text"
                            name="host"
                            onChange={handleHostChange(index)}
                            required
                        />
                    </div>
                    <IconButton size="small" onClick={handleRemoveHost(index)}>
                        <Delete />
                    </IconButton>
                </div>
            ))}
            <Button size="small" variant="contained" type="button" onClick={handleAddHost}>
                Add Host
            </Button>
            <div style={{ height: 10 }}></div>
            <ErrorMessages errors={error} />

            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                <Button variant="contained" type="submit" disabled={!groupName || !!error}>
                    Create Group
                </Button>
            </div>
        </form>
    );
};

export default CreateHostGroupForm;
