import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const ConfirmPasswordChange = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>Password Changed</DialogTitle>
            <DialogContent>Your password has been changed successfully.</DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={closeDialog}>
                    OK
                </Button>
            </DialogActions>
        </>
    );
};
