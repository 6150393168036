import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
    setOpen: (bool: boolean) => void;
    open: boolean;
}

export const PermissionInformationGrid: React.FC<IProps> = ({ setOpen, open }) => {
    return (
        <Box>
            <Box sx={{ textAlign: "right", width: "100%" }}>
                <IconButton sx={{ p: 0 }} onClick={() => setOpen(!open)} size="large">
                    <CloseIcon />
                </IconButton>
            </Box>
            <Typography sx={{ mb: 1 }}>Admin: All configuration and operation of member organisation.</Typography>
            <Typography sx={{ mb: 1 }}>
                Operator: Operation of the platform, transcoder configuration, source / destination configuration and scheduling. View only
                for settings menu, no configuration privileges.
            </Typography>
            <Typography>Monitor: Monitoring only, no configuration or operation of the platform.</Typography>
        </Box>
    );
};
