import { ContentCopy } from "@mui/icons-material";
import { Box, Chip, FormLabel, Tooltip, Typography } from "@mui/material";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

interface InfoItemVerticalProps {
    title: string;
    value: any;
    value_type?: "copy" | "chip";
    value_prefix?: string;
    value_suffix?: string;
    chip_color?: "primary" | "secondary" | "error" | "warning" | "info" | "success";
    containerSx?: any;
}

/**
 * `InfoItemVertical` is a React component that displays information based on the provided props.
 *
 * @component
 * @param {Object} props - The properties that define the InfoItemVertical component.
 * @param {string} props.title - The title of the information item.
 * @param {any} props.value - The value of the information item.
 * @param {("copy"|"chip")} [props.value_type] - The type of the value to be displayed.
 * @param {("primary"|"secondary"|"error"|"warning"|"info"|"success")} [props.chip_color] - The color of the chip, if `value_type` is "chip".
 *
 * @example
 * // To use this component, import it and use it in your JSX like this:
 * import InfoItemVertical from './InfoItemVertical';
 *
 * <InfoItemVertical
 *   title="Example Title"
 *   value={12345}
 *   value_type="chip"
 *   chip_color="primary"
 * />
 */

const InfoItemVertical: React.FC<InfoItemVerticalProps> = ({ title, value, value_type, value_prefix, value_suffix, chip_color, containerSx }) => {
    const [valueCopied, setValueCopied] = React.useState(false);
    return (
        <Box 
            sx={{
                display: "flex",
                flexDirection: "column",
                ...containerSx
            }}
        >
            <FormLabel sx={{ fontSize: 11 }}>{title}</FormLabel>
            <Box>
                {value_type === "chip" ? (
                    <Chip size="small" sx={{ textTransform: "capitalize" }} label={value} color={chip_color} />
                ) : (
                    <Box
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                        <Typography variant="body1">{value_prefix}{value}{value_suffix}</Typography>
                        {value_type === "copy" && (
                            <CopyToClipboard
                                text={value}
                                
                                onCopy={() => {
                                    setValueCopied(true);
                                    setTimeout(() => {
                                        setValueCopied(false);
                                    }, 2000);
                                }}>
                                <Box title={"Copy " + title + " to clipboard"}>
                                    <Tooltip open={valueCopied} title={title + " Copied"} placement="top-end">
                                        <ContentCopy
                                            sx={{
                                                cursor: "pointer",
                                                fontSize: "11px",
                                                marginLeft: "5px",
                                                marginBottom: "-1px",
                                                opacity: 0.7,
                                                ":hover": { opacity: 1 }
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                            </CopyToClipboard>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default InfoItemVertical;
