import React, { useContext, useState } from "react";
import { RootStoreContext } from "api";
import { IInput, IInputLiveStatics } from "api";
import { useEffect } from "react";
import { format } from "date-fns";
import { Box, Button, Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoItem from "components/InfoItem";
import { useSources } from "hooks";

interface IProps {
    input_details: IInput;
}

const SourceStats: React.FC<IProps> = ({ input_details }) => {
    const rootStore = useContext(RootStoreContext);
    const { resetLiveStats, getInputLiveStats } = useSources();
    const [inputLiveStats, setLiveStats] = useState<IInputLiveStatics | null>(null);

    useEffect(() => {
        let isMounted = true;

        const getInitialBitrateList = async () => {
            const value = await getInputLiveStats(input_details.id);
            if (value) {
                setLiveStats(value);
            }
        };

        getInitialBitrateList();

        const perpetual = setInterval(() => {
            isMounted &&
                getInputLiveStats(input_details.id).then((value) => {
                    if (value) {
                        setLiveStats(value);
                    }
                });
        }, 3000);

        return () => {
            isMounted = false;
            clearInterval(perpetual);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Box>
            {inputLiveStats
                ? inputLiveStats.data.map((input, i) => {
                      if (input.label === "time") {
                          return <InfoItem key={i} title={input.label} value={format(new Date(input.value), "eeee do MMMM h:mm a")} />;
                      }

                      if (input.label === "Bitrate (Kbps)") {
                          return (
                              <InfoItem
                                  key={i}
                                  title="Bitrate (Kbps)"
                                  value={input.value}
                                  value_type="line-chart"
                                  line_chart_max_items={200}
                              />
                          );
                      }

                      {
                          if (input.label === "Packet Rate (packets per second)") {
                              return (
                                  <InfoItem
                                      key={i}
                                      title="Packet Rate (packets per second)"
                                      value={input.value}
                                      value_type="line-chart"
                                      line_chart_color="secondary"
                                      line_chart_max_items={200}
                                  />
                              );
                          }
                      }
                      return <InfoItem key={i} title={input.label} value={input.value} />;
                  })
                : null}
            <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12} sx={{ textAlign: "center", opacity: 1 }}>
                    <Button variant="toolbar" onClick={() => resetLiveStats(input_details.id)} startIcon={<RefreshIcon />}>
                        Reset Statistics
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SourceStats;
