import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export const SourcePermissionTokenSuccessful = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <Box sx={{ p: 2 }}>
            <DialogTitle>
                <Typography variant="h4">Success</Typography>
            </DialogTitle>
            <DialogContent>
                You have successfully joined an organisation as a guest. You will be able to access this organisation in the organisation
                drop down menu on the nav bar at the top of the screen.
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeDialog();
                    }}>
                    Ok
                </Button>
            </DialogActions>
        </Box>
    );
};
