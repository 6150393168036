import React, { useContext, useEffect, useState } from "react";
import { agent, IScheduleOverviewEntry, RootStoreContext } from "api";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Chip,
    Fade,
    FormControlLabel,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { useNavigate } from "react-router-dom";
import { useSortableData } from "hooks/UseSortableData";
import DeleteIcon from "@mui/icons-material/Delete";
import { addMonths, addWeeks, endOfDay, format, startOfDay } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
//import { enGB } from "date-fns/locale";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import Content from "components/Layout/Content";
import { observer } from "mobx-react-lite";
import SchedulerViewSelect from "../Components/SchedulerViewSelect";
import { AddCircle, ExpandMore } from "@mui/icons-material";
import CreateRepeatScheduleForm from "features/scheduler/Components/CreateRepeatScheduleForm";
import BasicEditScheduleForm from "../Components/BasicEditScheduleForm";
import ConfirmationDialog from "components/ConfirmationDialog";
import SearchInput from "components/SearchInput";
import { useSources } from "hooks";

const ScheduleOverview: React.FC = () => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { activeEnvironment } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;
    const { currentEnvRunning, environment } = rootStore.environmentStore;
    const [schedules, setSchedules] = useState<IScheduleOverviewEntry[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [dateRange, setDateRange] = React.useState<any>([startOfDay(new Date()), addWeeks(endOfDay(new Date()), 1)]);
    const [update, setUpdate] = React.useState<Date>();
    const { client, clientConnected } = rootStore.resClientStore;
    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_env_id = rootStore.userStore.activeEnvironment;
    const { token } = rootStore.commonStore;
    const { getInputsDropdownList } = useSources();

    const [showFinished, setShowFinished] = useState(true);
    const [showOn, setShowOn] = useState(true);
    const [showScheduled, setShowScheduled] = useState(true);

    const [showDestinationsList, setShowDestinationsList] = useState<string[]>([]);
    const [searchDestinations, setSearchDestinations] = useState<string>("");
    const [showSourcesList, setShowSourcesList] = useState<string[]>([]);
    const [searchSources, setSearchSources] = useState<string>("");
    const [sources, setSources] = useState<any[]>([]);
    const [destinations, setDestinations] = useState<any[]>([]);

    //Fetch Sources and Destinations --------------------------------
    const fetchSources = async (environment_id) => {
        await getInputsDropdownList(environment_id)
            .then((response) => {
                setSources(response.data);
                //add all sources to showSourcesList
                let srcList: any = [];
                response.data.map((source) => {
                    srcList.push(source.id);
                });
                setShowSourcesList(srcList);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const fetchDestinations = async (environment_id) => {
        await agent.Destination.getDestinationsList(environment_id)
            .then((response) => {
                const destinations = response.data.filter((destination) => destination.is_permanent_schedule === false);
                //add all destinations to showDestinationsList
                let destList: any = [];
                destinations.map((destination) => {
                    destList.push(destination.id);
                });
                setShowDestinationsList(destList);

                setDestinations(destinations);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        fetchSources(activeEnvironment);
        fetchDestinations(activeEnvironment);
    }, [activeEnvironment]);

    const loadSchedules = () => {
        if (dateRange[0] && dateRange[1]) {
            console.log("Date Range", dateRange[0], dateRange[1]);
            agent.Scheduler.getScheduleOverview(startOfDay(dateRange[0]), dateRange[1])
                .then((response) => {
                    console.log("Scheduler Overview", response.results);
                    // Sort the list after every load (if sort order defined)
                    const sortedList = sortList(response.results, sortingConfig?.sortingColumn, sortingConfig?.sortingOrder);
                    setSchedules(sortedList);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        loadSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, activeEnvironment]);

    const { sortingConfig, changeSortConfig, sortList } = useSortableData();

    const onUpdate = () => {
        setUpdate(new Date());
    };

    useEffect(() => {
        setLoading(true);

        clientConnected &&
            client
                .get(`environments.outputs.${managing_org_id}.${active_env_id}`)
                .then((res: any) => {
                    res.on("add", () => {
                        onUpdate();

                        loadSchedules();
                    });

                    res.on("remove", () => {
                        onUpdate();
                        loadSchedules();
                    });
                    Array.from(res).map((destination: any) => {
                        destination.on("change", () => {
                            onUpdate();
                            loadSchedules();
                        });
                    });

                    setLoading(false);
                    onUpdate();
                    //console.log("Scheduler Data", res);
                })
                .catch((err) => {
                    console.error("Scheduler Error", err);
                    if (err.message === "No auth info provided") {
                        client.disconnect();
                        rootStore.resClientStore.initialize(token!);
                    }
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            //setData([]);
        };
    }, [managing_org_id, rootStore.commonStore.token, clientConnected, client, active_env_id, token, currentEnvRunning]);

    const statusSwitch = (param) => {
        switch (param) {
            case "Live":
                return <Chip label={param} size="small" sx={{ backgroundColor: "success.main" }} />;
            case "Scheduled":
                return <Chip label={param} size="small" sx={{ backgroundColor: "primary.main" }} />;
            case "Deleted":
                return <Chip label={param} size="small" sx={{ backgroundColor: "senary.main" }} />;
            case "Finished":
                return <Chip label={param} size="small" />;
            default:
                return <Chip label={param} size="small" />;
        }
    };

    return (
        <View>
            <ViewHeader title="Schedule" subtitle={"List"}></ViewHeader>
            <ViewBody noPadding>
                <Content
                    noPadding
                    loading={loading}
                    leftContent={
                        <>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticDateRangePicker
                                    //displayStaticWrapperAs="desktop"
                                    value={dateRange}
                                    onChange={(newValue) => {
                                        if (newValue[0] && newValue[1] && newValue[0] <= newValue[1]) {
                                            setDateRange(newValue);
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: "transparent",
                                        "& .MuiPickersToolbar-root": {
                                            display: "none"
                                        },
                                        "& .MuiDialogActions-root": {
                                            display: "none"
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            <Accordion
                                defaultExpanded
                                sx={{
                                    backgroundColor: "transparent",
                                    "&.MuiPaper-root": {
                                        backgroundColor: "transparent",
                                        background: "none"
                                    },
                                    "&.MuiAccordion-root": {
                                        backgroundColor: "transparent"
                                    },
                                    "&.MuiAccordion-root.Mui-expanded": {
                                        backgroundColor: "transparent"
                                    }
                                }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: "transparent",
                                        "&.MuiAccordionSummary-root": {
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                    expandIcon={<ExpandMore />}>
                                    Status
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" spacing={1}>
                                        <FormControlLabel
                                            control={<Checkbox checked={showScheduled} onChange={() => setShowScheduled(!showScheduled)} />}
                                            label="Show Scheduled Items"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox color="success" checked={showOn} onChange={() => setShowOn(!showOn)} />}
                                            label="Show Live Items"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        "&.MuiCheckbox-root.Mui-checked": {
                                                            "& .MuiSvgIcon-root": {
                                                                color: theme.palette.text.primary
                                                            }
                                                        }
                                                    }}
                                                    checked={showFinished}
                                                    onChange={() => setShowFinished(!showFinished)}
                                                />
                                            }
                                            label="Show Finished Items"
                                        />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    backgroundColor: "transparent",
                                    "&.MuiPaper-root": {
                                        backgroundColor: "transparent",
                                        background: "none"
                                    },
                                    "&.MuiAccordion-root": {
                                        backgroundColor: "transparent"
                                    },
                                    "&.MuiAccordion-root.Mui-expanded": {
                                        backgroundColor: "transparent"
                                    }
                                }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: "transparent",
                                        "&.MuiAccordionSummary-root": {
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                    expandIcon={<ExpandMore />}>
                                    Destinations {showDestinationsList.length > 0 && `(${showDestinationsList.length})`}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" spacing={1}>
                                        <SearchInput
                                            name="search_destinations"
                                            label="Search Destinations"
                                            onChange={(value) => {
                                                setSearchDestinations(value);
                                            }}
                                            onClear={() => {
                                                setSearchDestinations("");
                                            }}
                                            value={searchDestinations}
                                        />
                                        {/* select all checkboxes to show/hide all destinations */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showDestinationsList.length === destinations.length}
                                                    onChange={() => {
                                                        if (showDestinationsList.length === destinations.length) {
                                                            setShowDestinationsList([]);
                                                        } else {
                                                            let destList: any = [];
                                                            destinations?.map((destination) => {
                                                                destList.push(destination.id);
                                                            });
                                                            setShowDestinationsList(destList);
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Select All"
                                        />
                                        {destinations
                                            .filter((destination) =>
                                                destination.name.toLowerCase().includes(searchDestinations.toLowerCase())
                                            )
                                            .map((destination) => (
                                                <FormControlLabel
                                                    key={destination.id}
                                                    control={
                                                        <Checkbox
                                                            checked={showDestinationsList.includes(destination.id)}
                                                            onChange={() => {
                                                                if (showDestinationsList.includes(destination.id)) {
                                                                    setShowDestinationsList(
                                                                        showDestinationsList.filter((item) => item !== destination.id)
                                                                    );
                                                                } else {
                                                                    setShowDestinationsList([...showDestinationsList, destination.id]);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={destination.name}
                                                />
                                            ))}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    backgroundColor: "transparent",
                                    "&.MuiPaper-root": {
                                        backgroundColor: "transparent",
                                        background: "none"
                                    },
                                    "&.MuiAccordion-root": {
                                        backgroundColor: "transparent"
                                    },
                                    "&.MuiAccordion-root.Mui-expanded": {
                                        backgroundColor: "transparent"
                                    }
                                }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: "transparent",
                                        "&.MuiAccordionSummary-root": {
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                    expandIcon={<ExpandMore />}>
                                    Sources {showSourcesList.length > 0 && `(${showSourcesList.length})`}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" spacing={1}>
                                        <SearchInput
                                            name="search_sources"
                                            label="Search Sources"
                                            onChange={(value) => {
                                                setSearchSources(value);
                                            }}
                                            onClear={() => {
                                                setSearchSources("");
                                            }}
                                            value={searchSources}
                                        />
                                        {/* select all checkboxes to show/hide all sources */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showSourcesList.length === sources.length}
                                                    onChange={() => {
                                                        if (showSourcesList.length === sources.length) {
                                                            setShowSourcesList([]);
                                                        } else {
                                                            let srcList: any = [];
                                                            sources.map((source) => {
                                                                srcList.push(source.id);
                                                            });
                                                            setShowSourcesList(srcList);
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Select All"
                                        />
                                        {sources
                                            .filter((source) => source.name.toLowerCase().includes(searchSources.toLowerCase()))
                                            .map((source) => (
                                                <FormControlLabel
                                                    key={source.id}
                                                    control={
                                                        <Checkbox
                                                            checked={showSourcesList.includes(source.id)}
                                                            onChange={() => {
                                                                if (showSourcesList.includes(source.id)) {
                                                                    setShowSourcesList(
                                                                        showSourcesList.filter((item) => item !== source.id)
                                                                    );
                                                                } else {
                                                                    setShowSourcesList([...showSourcesList, source.id]);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={source.name}
                                                />
                                            ))}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                    leftWidth={315}
                    toolbarContent={
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Button variant="toolbar" onClick={() => setDateRange([new Date(), addMonths(new Date(), 1)])}>
                                        This Month
                                    </Button>
                                    <Typography variant="h5">
                                        {dateRange[0] &&
                                            dateRange[1] &&
                                            `${format(dateRange[0], "E MMMM do y")} to ${format(dateRange[1], "E MMMM do y")}`}
                                    </Typography>
                                </Stack>
                            </LocalizationProvider>
                            <Stack direction={"row"} spacing={2}>
                                <SchedulerViewSelect />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ whiteSpace: "nowrap" }}
                                    startIcon={<AddCircle />}
                                    onClick={() => {
                                        openDialog(<CreateRepeatScheduleForm />);
                                    }}>
                                    Add Schedule
                                </Button>
                            </Stack>
                        </Box>
                    }>
                    {environment && (
                        <>
                            <Table aria-label="collapsible table" stickyHeader>
                                <TableHead sx={{ borderBottom: "1px solid white" }}>
                                    <TableRow>
                                        <TableCell
                                            onClick={() => {
                                                changeSortConfig("start_time", setSchedules, schedules);
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <TableSortLabel
                                                active={sortingConfig?.sortingColumn === "start_time"}
                                                direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                                Start Time
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => {
                                                changeSortConfig("end_time", setSchedules, schedules);
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <TableSortLabel
                                                active={sortingConfig?.sortingColumn === "end_time"}
                                                direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                                End Time
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => {
                                                changeSortConfig("from_channel", setSchedules, schedules);
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <TableSortLabel
                                                active={sortingConfig?.sortingColumn === "from_channel"}
                                                direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                                From Source
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => {
                                                changeSortConfig("to_destination", setSchedules, schedules);
                                            }}
                                            style={{ cursor: "pointer" }}>
                                            <TableSortLabel
                                                active={sortingConfig?.sortingColumn === "to_destination"}
                                                direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                                To Destination
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => {
                                                changeSortConfig("status", setSchedules, schedules);
                                            }}>
                                            <TableSortLabel
                                                active={sortingConfig?.sortingColumn === "status"}
                                                direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                                Status
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {schedules
                                        .filter((item) => {
                                            if (showScheduled && item.status === "Scheduled") {
                                                return true;
                                            }
                                            if (showOn && item.status === "Live") {
                                                return true;
                                            }
                                            if (showFinished && item.status === "Finished") {
                                                return true;
                                            }
                                            return false;
                                        })
                                        .filter((item) => showDestinationsList.includes(item.destination_id))
                                        .filter((item) => showSourcesList.includes(item.source_id))
                                        .map((schedule) => {
                                            const startTime = new Date(schedule.start_time);
                                            const endTime = new Date(schedule.end_time);
                                            const startTimeLocal = format(startTime, "dd/MM/yyyy @ HH:mm");
                                            const endTimeLocal = format(endTime, "dd/MM/yyyy @ HH:mm");

                                            return (
                                                <TableRow key={schedule.id} sx={{ height: "45px" }}>
                                                    <TableCell>{startTimeLocal}</TableCell>
                                                    <TableCell>{endTimeLocal}</TableCell>
                                                    <TableCell>{schedule.from_channel}</TableCell>
                                                    <TableCell>{schedule.to_destination}</TableCell>
                                                    <TableCell>{statusSwitch(schedule.status)}</TableCell>
                                                    <TableCell style={{ textAlign: "right", paddingRight: 5 }}>
                                                        {schedule.status !== "Deleted" && schedule.status !== "Finished" && (
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="end"
                                                                spacing={1}
                                                                //sx={{ mr: 1 }}
                                                            >
                                                                <Tooltip placement="bottom" title="Update Schedule">
                                                                    <Box>
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                                openDialog(
                                                                                    <BasicEditScheduleForm
                                                                                        item={schedule}
                                                                                        schedule_id={schedule.id}
                                                                                        destination_id={schedule.destination_id}
                                                                                    />
                                                                                );
                                                                            }}
                                                                            aria-label="Update Schedule">
                                                                            <EditCalendarIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Tooltip>

                                                                <Tooltip placement="bottom" title="Delete Schedule">
                                                                    <Box>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                openDialog(
                                                                                    <ConfirmationDialog
                                                                                        title="Delete Schedule"
                                                                                        message="Are you sure you want to delete this schedule?"
                                                                                        additionalContent={
                                                                                            <Box
                                                                                                sx={{
                                                                                                    mt: 1
                                                                                                }}>
                                                                                                <Typography variant="body1">
                                                                                                    Start:{" "}
                                                                                                    {format(
                                                                                                        new Date(schedule.start_time),
                                                                                                        "dd/MM/yy @ HH:mm"
                                                                                                    )}
                                                                                                </Typography>
                                                                                                <Typography variant="body1">
                                                                                                    End:{" "}
                                                                                                    {format(
                                                                                                        new Date(schedule.end_time),
                                                                                                        "dd/MM/yy @ HH:mm"
                                                                                                    )}
                                                                                                </Typography>
                                                                                                <Typography variant="body1">
                                                                                                    Source: {schedule.from_channel}
                                                                                                </Typography>
                                                                                                <Typography variant="body1">
                                                                                                    Destination: {schedule.to_destination}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        }
                                                                                        confirmButtonText="Delete"
                                                                                        confirmButtonColor="error"
                                                                                        onConfirm={async () => {
                                                                                            await agent.Scheduler.deleteSchedule(
                                                                                                schedule.id
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                            aria-label="Update Schedule">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Tooltip>
                                                            </Stack>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                            <Box m={4}>
                                <Alert severity="info" sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    Schedules older than 90 days are automatically cleared.
                                </Alert>
                            </Box>
                        </>
                    )}
                    {!currentEnvRunning && (
                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <Alert severity="info" sx={{ width: "37rem" }}>
                                <AlertTitle>Environment Not Running</AlertTitle>
                                The active environment is not running.
                            </Alert>
                        </Box>
                    )}
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(ScheduleOverview);
