import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Stack, Switch, Box, Typography, DialogTitle, DialogContent, Button, DialogActions, Alert, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckboxField, MultilineTextField, RadioGroupField, SelectField, SliderField, TextField } from "components/Forms";
import StepperForm from "components/Forms/StepperForm";
import { RenderGlossary } from "components";
import { FORM_ERROR } from "final-form";
import useGlossary from "hooks/UseGlossary";
import { type } from "app/common/options/zixiType";
import { aesTypes } from "app/common/options/zixiAes";
import KeyIcon from "@mui/icons-material/Key";
import { inputports } from "app/common/options/zixiPorts";
import { agent, ITrasncoderReturnData, RootStoreContext } from "api";
import { toJS } from "mobx";
import { useSources } from "hooks";
import { makeRandomKey } from "helpers";

interface ICreateSource {
    mode?: "create" | "edit" | "duplicate";
}

const CreateSource: React.FC<ICreateSource> = ({ mode = "create" }) => {
    const navigate = useNavigate();
    let location = useLocation();
    let { returnUrl } = location.state || { returnUrl: "/sources" };
    const { id } = useParams();
    const package_id = id;
    const rootStore = useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { transcoderData } = rootStore.sourcesStore;
    const { getTranscoderData, createInput, editInput } = useSources();
    const { glossary } = useGlossary();
    const [state, setState] = useState({
        password: false,
        encryption: false,
        rist_remote_port: false,
        remoteConfigure: false
    });
    const [initialValues, setInitialValues] = useState({
        id: "",
        processing_client: "zixi",
        latency: 6000,
        max_allowed_bitrate_kbps: 8000,
        disconnect_if_inactive: false
    });

    const getInitialValues = async (id: string) => {
        await agent.Channel.getInput(id)
            .then((response: any) => {
                console.log("response", response);
                // @ts-ignore
                //if mode is duplicate, modify the response to append "Copy of" to the name
                if (mode === "duplicate") {
                    response.data.id = `Copy of ${response.data.id}`;
                }
                if (response.data.enc_type && !response.data.password) {
                    setState({ ...state, encryption: true });
                }
                if (response.data.enc_type && response.data.password) {
                    setState({ ...state, password: true, encryption: true });
                }
                if (!response.data.enc_type && response.data.password) {
                    setState({ ...state, password: true });
                }

                setInitialValues(response.data);
            })
            .catch((error) => {
                console.log("getInitialValues error: ", error);
            });
    };

    // Fetch initial data
    useEffect(() => {
        if ((mode === "edit" || mode === "duplicate") && package_id) {
            getInitialValues(package_id);
        }
    }, [mode, package_id]);

    useEffect(() => {
        getTranscoderData();
    }, [getTranscoderData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const checkType = (selectedType: string, acceptedValues: string[]) => {
        return acceptedValues.includes(selectedType);
    };

    let associatedTranscoders: ITrasncoderReturnData[] = [];

    if (transcoderData && mode === "edit") {
        const unfilteredTranscoders = toJS(transcoderData).data;
        if (unfilteredTranscoders) {
            associatedTranscoders = unfilteredTranscoders.filter((transcoder: ITrasncoderReturnData) => transcoder.input_source_id === id);
        }
    }

    return (
        <Form
            onSubmit={(values) => {
                if (mode === "edit") {
                    values.input_id = package_id;
                    //values.type !== "rtmp-pull" && values.type !== "rtmp-push" ? (values["password"] = values.p_a_s_s_wd) : null;
                    console.log("values", values);
                    return editInput(values)
                        .then(() => {})
                        .catch((error) => {
                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                } else {
                    return createInput(values)
                        .then(() => {})
                        .catch((error) => {
                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                }
            }}
            initialValues={initialValues}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors,
                dirty
            }) => {
                const initSteps = [
                    {
                        label: glossary.features.sources.form.steps.name.title,
                        description: glossary.features.sources.form.steps.name.description,
                        // helpText: 'Set the details of the item',
                        // helpText: getItemById(knowledgeHubContent, "item-5")?.sections[0]?.accordions[0]?.content,
                        hasError: Boolean(submitErrors?.id || submitErrors?.user_description),
                        infoContent: (
                            <Stack spacing={2}>
                                <Typography>
                                    <b>Name</b> - <RenderGlossary term={glossary.features.sources.form.fields?.name} />
                                </Typography>
                                <Typography>
                                    <b>Description</b> - <RenderGlossary term={glossary.features.sources.form.fields?.description} />
                                </Typography>
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                <TextField
                                    name="id"
                                    label="Source Name "
                                    disabled={mode === "edit"}
                                    required
                                    validate={(value) => {
                                        if (!value) {
                                            return "Name is required";
                                        }

                                        if (value.length > 128) {
                                            return "Name can not be above 128 characters";
                                        }
                                    }}
                                />
                                <MultilineTextField
                                    name="user_description"
                                    label="Source Description"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                    rows={4}
                                    maxRows={8}
                                    validate={(value) => {
                                        if (value) {
                                            if (value.length > 255) {
                                                return "Source Description must be less than 255 characters";
                                            }
                                        }
                                    }}
                                />
                            </Stack>
                        )
                    },
                    {
                        label: glossary.features.sources.form.steps.type.title,
                        description: glossary.features.sources.form.steps.type.description,
                        hasError: Boolean(
                            submitErrors?.type ||
                                submitErrors?.remoteConfigure ||
                                submitErrors?.latency ||
                                submitErrors?.max_allowed_bitrate_kbps ||
                                submitErrors?.port ||
                                submitErrors?.host ||
                                submitErrors?.stream ||
                                submitErrors?.host0 ||
                                submitErrors?.pull_port ||
                                submitErrors?.source ||
                                submitErrors?.host1 ||
                                submitErrors?.host2 ||
                                submitErrors?.disconnect_if_inactive ||
                                submitErrors?.muxing_bitrate ||
                                submitErrors?.smoothing_latency ||
                                submitErrors?.url ||
                                submitErrors?.stream_name ||
                                submitErrors?.user_name ||
                                submitErrors?.password ||
                                submitErrors?.ignore_certificate_errors ||
                                submitErrors?.remote_port ||
                                submitErrors?.rist_remote_port
                        ),
                        infoContent: (
                            <Stack spacing={2}>
                                <Typography>
                                    <b>Type</b> - <RenderGlossary term={glossary.features.sources.form.fields?.type} />
                                </Typography>

                                {values.type === "push" && (
                                    <Typography>
                                        <b>Configure Latency Remotely</b> -{" "}
                                        <RenderGlossary term={glossary.features.sources.form.fields?.configureLatencyRemotely} />
                                    </Typography>
                                )}

                                {checkType(values.type, ["push", "pull", "udp-rist", "srt-push", "srt-pull"]) && !state.remoteConfigure && (
                                    <Typography>
                                        <b>Latency</b> -{" "}
                                        <RenderGlossary
                                            term={
                                                values.type === "srt-pull"
                                                    ? glossary.features.sources.form.fields?.srtLatency
                                                    : glossary.fieldValues.latency
                                            }
                                        />
                                    </Typography>
                                )}

                                {checkType(values.type, ["push", "pull", "rtmp-push", "rtmp-pull", "udp-rist", "srt-push", "srt-pull"]) && (
                                    <Typography>
                                        <b>Max Allowed Bitrate (Kbps)</b> - <RenderGlossary term={glossary.fieldValues.maxBitrate} />
                                    </Typography>
                                )}

                                {checkType(values.type, ["push", "pull"]) && (
                                    <Typography>
                                        <b>Port</b> - <RenderGlossary term={glossary.features.sources.form.fields?.zixiPort} />
                                    </Typography>
                                )}

                                {checkType(values.type, ["udp-rist", "srt-push", "srt-pull"]) && (
                                    <Typography>
                                        <b>Port</b> - <RenderGlossary term={glossary.features.sources.form.fields?.port} />
                                    </Typography>
                                )}

                                {checkType(values.type, ["srt-pull"]) && (
                                    <Typography>
                                        <b>Host</b> - <RenderGlossary term={glossary.features.sources.form.fields?.host} />
                                    </Typography>
                                )}

                                {checkType(values.type, ["pull"]) && (
                                    <>
                                        <Typography>
                                            <b>Stream</b> - <RenderGlossary term={glossary.features.sources.form.fields?.stream} />
                                        </Typography>
                                        <Typography>
                                            <b>Host Backup</b> - <RenderGlossary term={glossary.features.sources.form.fields?.hostBackup} />
                                        </Typography>
                                        <Typography>
                                            <b>Host Tertiary Backup</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.hostTertiaryBackup} />
                                        </Typography>
                                    </>
                                )}

                                {checkType(values.type, ["rtmp-push", "rtmp-pull"]) && (
                                    <>
                                        <Typography>
                                            <b>Disconnect if inactive</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.disconnectIfInactive} />
                                        </Typography>
                                        <Typography>
                                            <b>Muxing Bitrate</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.muxingBitrate} />
                                        </Typography>
                                        <Typography>
                                            <b>Smoothing Latency</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.smoothingLatency} />
                                        </Typography>
                                    </>
                                )}

                                {checkType(values.type, ["rtmp-pull"]) && (
                                    <>
                                        <Typography>
                                            <b>URL</b> - <RenderGlossary term={glossary.features.sources.form.fields?.url} />
                                        </Typography>
                                        <Typography>
                                            <b>Stream Name</b> - <RenderGlossary term={glossary.features.sources.form.fields?.streamName} />
                                        </Typography>
                                        <Typography>
                                            <b>Username</b> - <RenderGlossary term={glossary.features.sources.form.fields?.username} />
                                        </Typography>
                                        <Typography>
                                            <b>Password</b> - <RenderGlossary term={glossary.features.sources.form.fields?.password} />
                                        </Typography>
                                        <Typography>
                                            <b>Ignore certificate errors</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.ignoreCertificateErrors} />
                                        </Typography>
                                    </>
                                )}

                                {checkType(values.type, ["udp-rist"]) && (
                                    <>
                                        <Typography>
                                            <b>Remote Port</b> - <RenderGlossary term={glossary.features.sources.form.fields?.remotePort} />
                                        </Typography>
                                        <Typography>
                                            <b>Rist Remote Port</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.ristRemotePort} />
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                <RadioGroupField
                                    name="type"
                                    label="Select the Type"
                                    required
                                    row
                                    parse
                                    options={
                                        type?.map((item) => ({
                                            label: item.text,
                                            value: item.value
                                        })) || []
                                    }
                                    validate={(value) => {
                                        if (!value) {
                                            return "Type is required";
                                        }
                                    }}
                                    onChange={() => {
                                        setState({
                                            ...state,
                                            remoteConfigure: false
                                        });
                                        values.latency = initialValues.latency;
                                    }}
                                />

                                {values.type === "push" && (
                                    <Box>
                                        <Typography>Configure Latency Remotely</Typography>
                                        <Switch
                                            checked={state.remoteConfigure}
                                            size="small"
                                            color="primary"
                                            name="remoteConfigure"
                                            onChange={(e) => {
                                                handleChange(e);
                                                if (!state.remoteConfigure) {
                                                    form.change("latency", -1);
                                                } else {
                                                    form.change("latency", 6000);
                                                }
                                            }}
                                            inputProps={{
                                                "aria-label": "primary checkbox"
                                            }}
                                        />
                                    </Box>
                                )}

                                {checkType(values.type, ["push", "pull", "udp-rist", "srt-push", "srt-pull"]) && !state.remoteConfigure && (
                                    <TextField
                                        required
                                        name="latency"
                                        label={values.type === "udp-rist" ? "Max Latency (ms)" : "Latency (ms)"}
                                        parse
                                        validate={(value) => {
                                            if (!value) {
                                                return "Latency is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["push", "pull", "rtmp-push", "rtmp-pull", "udp-rist", "srt-push", "srt-pull"]) && (
                                    <TextField
                                        required
                                        name="max_allowed_bitrate_kbps"
                                        label="Max Allowed Bitrate (Kbps)"
                                        parse
                                        validate={(value) => {
                                            if (!value) {
                                                return "Max Allowed Bitrate is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["push"]) && (
                                    <SelectField
                                        name="port"
                                        label="Port"
                                        options={inputports}
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                        validate={(value) => {
                                            if (!value) {
                                                return "Port is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["udp-rist", "srt-push", "srt-pull"]) && (
                                    <TextField
                                        name="port"
                                        label="Port"
                                        parse
                                        helperText={(meta) => meta.touched && meta.error}
                                        error={(meta) => meta.touched && meta.error}
                                        validate={(value) => {
                                            if (!value) {
                                                return "Port is required";
                                            }
                                        }}
                                    />
                                )}

                                {checkType(values.type, ["srt-pull"]) && (
                                    <TextField
                                        required
                                        name="host"
                                        label="Host"
                                        parse
                                        validate={(value) => {
                                            if (!value) {
                                                return "Host is required";
                                            }
                                        }}
                                    />
                                )}

                                {/* START: Zixi Pull Fields */}
                                {checkType(values.type, ["pull"]) && (
                                    <>
                                        <TextField
                                            required
                                            name="host0"
                                            label="Host"
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Host is required";
                                                }
                                            }}
                                        />

                                        <TextField
                                            name="pull_port"
                                            label="Port"
                                            parse
                                            helperText={(meta) => meta.touched && meta.error}
                                            error={(meta) => meta.touched && meta.error}
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Port is required";
                                                }
                                            }}
                                        />

                                        <TextField
                                            required
                                            name="source"
                                            label="Stream"
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Stream is required";
                                                }
                                            }}
                                        />

                                        <TextField name="host1" label="Host Backup" />

                                        <TextField name="host2" label="Host Tertiary Backup" />
                                    </>
                                )}
                                {/* END: Zixi Pull Fields */}

                                {/* START: RTMP Push */}
                                {checkType(values.type, ["rtmp-push", "rtmp-pull"]) && (
                                    <>
                                        <CheckboxField name="disconnect_if_inactive" label="Disconnect if inactive" />

                                        <TextField name="muxing_bitrate" label="Muxing Bitrate" parse />
                                        <TextField name="smoothing_latency" label="Smoothing Latency" parse />
                                    </>
                                )}
                                {/* END: RTMP Push */}

                                {/* START: RTMP Pull */}
                                {checkType(values.type, ["rtmp-pull"]) && (
                                    <>
                                        <TextField
                                            required
                                            name="url"
                                            label="URL"
                                            validate={(value) => {
                                                if (!value) {
                                                    return "URL is required";
                                                }
                                            }}
                                        />
                                        <TextField
                                            required
                                            name="stream_name"
                                            label="Stream Name"
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Stream Name is required";
                                                }
                                            }}
                                        />
                                        <TextField name="user_name" label="Username" />
                                        <TextField name="password" label="Password" />

                                        <CheckboxField name="ignore_certificate_errors" label="Ignore certificate errors" />
                                    </>
                                )}
                                {/* END: RTMP Pull */}

                                {/* START: RIST */}
                                {checkType(values.type, ["udp-rist"]) && (
                                    <>
                                        <Box>
                                            <Typography>Remote Port</Typography>
                                            <Switch
                                                checked={state.rist_remote_port}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                color="primary"
                                                name="rist_remote_port"
                                                inputProps={{
                                                    "aria-label": "primary checkbox"
                                                }}
                                            />
                                        </Box>

                                        {state.rist_remote_port && <TextField name="rist_remote_port" label="Rist Remote Port" />}
                                    </>
                                )}
                                {/* END: RIST */}

                                {/* START: Check for Associated Transcoders */}
                                {associatedTranscoders.length > 0 && (
                                    <Box sx={{ padding: "35px 10px 0px 0px" }}>
                                        <Alert severity="info" sx={{ mb: 2 }} title="ere">
                                            Changing the source details could effect the transcoded versions below
                                        </Alert>
                                        <label
                                            className="livelink-formlabel livelink-formlabelControl"
                                            style={{ margin: "0 0 0 10px", paddingTop: "10px" }}>
                                            Transcoded versions:
                                        </label>
                                        <ul
                                            style={{
                                                display: "block",
                                                padding: "0 10px 0px 53px",
                                                lineHeight: "25px"
                                            }}>
                                            {associatedTranscoders.map((transcoder, i) => (
                                                <Typography key={i}>{transcoder.transcoded_input_source_name}</Typography>
                                            ))}
                                        </ul>
                                    </Box>
                                )}
                                {/* END: Check for Associated Transcoders */}
                            </Stack>
                        )
                    }
                ];

                if (checkType(values.type, ["push", "pull", "udp-rist", "srt-push", "srt-pull"])) {
                    initSteps.push({
                        label: glossary.features.sources.form.steps.encryption.title,
                        description: glossary.features.sources.form.steps.encryption.description,
                        hasError: Boolean(
                            submitErrors?.p_a_s_s_wd || submitErrors?.password || submitErrors?.enc_type || submitErrors?.enc_key
                        ),
                        infoContent: (
                            <Stack spacing={1}>
                                {checkType(values.type, ["push", "pull", "srt-push", "srt-pull"]) && (
                                    <Typography>
                                        <b>Enable Password</b> -{" "}
                                        <RenderGlossary term={glossary.features.sources.form.fields?.enablePassword} />
                                    </Typography>
                                )}

                                {checkType(values.type, ["push", "pull"]) && (
                                    <>
                                        <Typography>
                                            <b>Enable Decryption</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.enableDecryption} />
                                        </Typography>
                                        <Typography>
                                            <b>Decryption Type</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.decryptionType} />
                                        </Typography>
                                        <Typography>
                                            <b>Decryption Key</b> -{" "}
                                            <RenderGlossary term={glossary.features.sources.form.fields?.decryptionKey} />
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        ),
                        content: (
                            <Stack spacing={2}>
                                {checkType(values.type, ["push", "pull", "srt-push", "srt-pull"]) && (
                                    <>
                                        <Box>
                                            <Typography>Enable Password</Typography>
                                            <Switch
                                                checked={state.password}
                                                onChange={(e) => {
                                                    if (!e.target.checked) {
                                                        form.change("password", undefined);
                                                    }
                                                    setState({ ...state, password: e.target.checked });
                                                }}
                                                color="primary"
                                                name="password_toggle"
                                                inputProps={{
                                                    "aria-label": "primary checkbox"
                                                }}
                                            />
                                        </Box>

                                        {state.password && values.type !== "udp-rist" && (
                                            <TextField name="password" id="password" label="Password" />
                                        )}
                                    </>
                                )}

                                {checkType(values.type, ["push", "pull"]) && (
                                    <>
                                        <Box>
                                            <Typography>Enable Decryption</Typography>
                                            <Switch
                                                checked={state.encryption}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    if (state.encryption) {
                                                        form.change("enc_type", undefined);
                                                        form.change("enc_key", undefined);
                                                    }
                                                }}
                                                color="primary"
                                                name="encryption"
                                                inputProps={{
                                                    "aria-label": "primary checkbox"
                                                }}
                                            />
                                        </Box>

                                        {state.encryption && values.type !== "udp-rist" && (
                                            <>
                                                <SelectField
                                                    label="Decryption type"
                                                    required={state.encryption}
                                                    options={aesTypes}
                                                    initialValue={values.enc_type ? values.enc_type : aesTypes[0].value}
                                                    name="enc_type"
                                                    placeholder="Select Decryption Type"
                                                    helperText={(meta) => meta.touched && meta.error}
                                                    error={(meta) => meta.touched && meta.error}
                                                    validate={(value) => {
                                                        if (!value) {
                                                            return "Decryption type is required";
                                                        }
                                                    }}
                                                />

                                                <Box sx={{ display: "flex" }}>
                                                    <TextField name="enc_key" label="Decryption Key" />
                                                    <IconButton
                                                        disabled={values.enc_type === undefined}
                                                        onClick={(e: any) => {
                                                            e.preventDefault();
                                                            if (values.enc_type === "aes128") {
                                                                const key = makeRandomKey(32);
                                                                form.change("enc_key", key);
                                                            } else if (values.enc_type === "aes192") {
                                                                const key = makeRandomKey(48);
                                                                form.change("enc_key", key);
                                                            } else if (values.enc_type === "aes256") {
                                                                const key = makeRandomKey(64);
                                                                form.change("enc_key", key);
                                                            } else {
                                                                (document.getElementById("enc_type") as HTMLInputElement).style.display =
                                                                    "block";
                                                            }
                                                        }}
                                                        aria-label="Refresh Alerts">
                                                        <KeyIcon sx={{ fontSize: "2rem" }} />
                                                    </IconButton>
                                                </Box>
                                            </>
                                        )}
                                    </>
                                )}
                            </Stack>
                        )
                    });
                }

                return (
                    <form onSubmit={handleSubmit}>
                        <StepperForm
                            formTitle={`${mode === "create" ? "Create" : mode === "edit" ? "Edit" : "Duplicate"} Source`}
                            submitButtonText={`${mode === "create" ? "Create" : mode === "edit" ? "Edit" : "Duplicate"} Source`}
                            steps={initSteps}
                            values={values}
                            invalid={invalid}
                            submitError={submitError}
                            showSuccessStep={submitSucceeded}
                            successStep={
                                <Stack spacing={1}>
                                    {mode === "edit" ? (
                                        <Typography>Your Source has now been updated.</Typography>
                                    ) : (
                                        <Typography>Your Source has now been created.</Typography>
                                    )}
                                </Stack>
                            }
                            onCancel={() => {
                                dirty
                                    ? openDialog(
                                          <>
                                              <DialogTitle>Are you sure?</DialogTitle>
                                              <DialogContent>
                                                  You have unsaved changes. Are you sure you want to leave this page?
                                              </DialogContent>
                                              <DialogActions>
                                                  <Button
                                                      onClick={() => {
                                                          closeDialog();
                                                      }}>
                                                      Cancel
                                                  </Button>
                                                  <Button
                                                      variant="contained"
                                                      color="primary"
                                                      onClick={() => {
                                                          closeDialog();
                                                          navigate(returnUrl);
                                                      }}>
                                                      Leave
                                                  </Button>
                                              </DialogActions>
                                          </>
                                      )
                                    : navigate(returnUrl);
                            }}
                            onFinish={() => navigate(returnUrl)}
                        />
                    </form>
                );
            }}
        />
    );
};
export default observer(CreateSource);
