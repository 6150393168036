import React, { ReactNode } from "react";
import { Field, useForm } from "react-final-form";
import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { SelectFieldOption } from "api";

interface IMultiSelectField {
    name: string;
    label: string;
    initialValue?: string[] | number[];
    options: SelectFieldOption[];
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    validate?: (value: any) => string | undefined;
    onChange?: (field: string, value?: any, previous?: any) => void;
}

/**
 * `MultiSelectField` is a React component that renders a multi-select dropdown field using Material-UI's `TextField` and `MenuItem` components. It is designed to work within forms managed by `react-final-form`.
 *
 * Props:
 * - `name` (string): The name of the form field. This is required for linking the dropdown with the form state.
 * - `label` (string): The label text displayed above the dropdown.
 * - `initialValue` (string[] | number[]): Initial selected values of the dropdown. It accepts an array of strings or numbers.
 * - `options` (IMultiSelectFieldOption[]): An array of options that the user can select from. Each option is an object with `key`, `text`, `value`, `description`, and `disabled` properties.
 * - `required` (boolean): If true, the field is marked as required, adding an asterisk to the label and validating accordingly.
 * - `disabled` (boolean): If true, the dropdown is disabled and cannot be interacted with.
 * - `placeholder` (string): A short hint that describes the expected value of the input field.
 * - `helperText` ((meta: any) => string | undefined): A function that returns helper text to be displayed below the dropdown based on the field's meta state.
 * - `error` ((meta: any) => boolean): A function that determines whether the field is in an error state based on the field's meta state.
 * - `validate` ((value: any) => string | undefined): A function for custom validation logic. It should return an error message string if validation fails, or undefined if validation is successful.
 * - `onChange` ((field: string, value?: any, previous?: any) => void): A callback function that is called whenever the value of the dropdown changes. It receives the field name, the new value, and the previous value as arguments.
 *
 * The component utilizes Material-UI's `TextField` component with the `select` prop enabled to render the dropdown. It supports multiple selections, indicated by the `multiple: true` setting in the `SelectProps`. The `renderValue` function in `SelectProps` is used to customize the display of selected options.
 *
 * Each option in the dropdown is rendered as a `MenuItem` component, with support for displaying an optional description via a `Typography` component.
 *
 * The `OnChange` component from `components/FinalFormListener/OnChange` is used to handle changes to the field's value, facilitating integration with `react-final-form`.
 *
 * Usage:
 * ```
 * <MultiSelectField
 *   name="example"
 *   label="Example Label"
 *   initialValue={['option1', 'option2']}
 *   options={[
 *     { key: '1', text: 'Option 1', value: 'option1' },
 *     { key: '2', text: 'Option 2', value: 'option2', description: 'Description for Option 2' },
 *     { key: '3', text: 'Option 3', value: 'option3', disabled: true }
 *   ]}
 *   required={true}
 *   onChange={(name, value) => console.log(`${name}: ${value}`)}
 * />
 * ```
 */
export const MultiSelectField: React.FC<IMultiSelectField> = ({
    name,
    label,
    initialValue,
    helperText,
    error,
    validate,
    options,
    required,
    disabled,
    placeholder,
    onChange,
}) => {
    return (
        <>
            <Field
                name={name}
                initialValue={initialValue}
                validate={(value: any) => {
                    if (validate) {
                        return validate(value);
                    }
                }}>
                {({ input, meta }) => (
                    <TextField
                        required={required}
                        placeholder={placeholder}
                        {...input}
                        label={label}
                        fullWidth
                        error={Boolean(
                            (meta.touched && meta.error) || meta.submitError || (meta.touched && meta.invalid) || (error && error(meta))
                        )}
                        helperText={(meta.touched && meta.error) || meta.submitError || (helperText && helperText(meta))}
                        variant="standard"
                        disabled={disabled}
                        select
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected: any) => {
                                const selectedOptions = options.filter((option) => selected.includes(option.value));
                                return (
                                    <Box>
                                        {selectedOptions.map((item, index) => (
                                            <span key={item.key || index}>
                                                {item.text}{index < selectedOptions.length - 1 && ', '}
                                            </span>
                                        ))}
                                    </Box>
                                );
                            }
                        }}
                        sx={{
                            "#description": {
                                display: "none"
                            }
                        }}
                    >
                        {options.map((option, i) => (
                            <MenuItem key={option.key || i} value={option.value} disabled={option.disabled ? true : false}>
                                <Box sx={{ width: '100%' }}>
                                    {option.text}
                                    {option.description && (
                                        <Typography component="div" variant="caption" color="textSecondary" id="description">
                                            {option.description}
                                        </Typography>
                                    )}
                                </Box>
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {
                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </>
    );
};

export default MultiSelectField;