import { RootStoreContext } from "api";
import OnChange from "components/FinalFormListener/OnChange";
import { RadioGroupField } from "components/Forms";
import { useEnvironment } from "hooks";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

const SelectRegion: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { regions, codeChanged, setRegionCode, cloud_code } = rootStore.environmentStore;
    const { getRegions } = useEnvironment();

    useEffect(() => {
        if (!regions || codeChanged === true) {
            getRegions(cloud_code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRegions, cloud_code]);

    const handleChange = (value: string) => {
        setRegionCode(value);
    };

    return (
        <>
            {regions && (
                <>
                    <RadioGroupField
                        name="region"
                        label="Select Region"
                        hideLabel={true}
                        options={regions.map((region) => {
                            return { label: region.name, value: region.code };
                        })}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        error={(meta) => meta.touched && meta.error}
                        helperText={(meta) => (meta.touched && meta.error ? meta.error : "")}
                    />
                    <OnChange name="region">
                        {(value) => {
                            handleChange(value);
                        }}
                    </OnChange>
                </>
            )}
        </>
    );
};

export default observer(SelectRegion);
