import { RootStoreContext } from "api";
import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

const Scheduler: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { setDate } = rootStore.schedulerStore;

    useEffect(() => {
        return () => {
            setDate(new Date());
        };
    }, [setDate]);

    return <Outlet />;
};

export default Scheduler;