import { formatLabel, isObject } from "features/knowledgeHub/Components";
import React from "react";

export interface IGlossaryItem { 
    terms: string[];
    definition: string;
}

export type GlossaryItemType = IGlossaryItem | string | undefined;

export interface IFeatureGlossary {
    title: string;
    description: string;
    form: {
        steps: {
            [key: string]: {
                title: string;
                description: string;
            },
        };
        fields?: {
            [key: string]: GlossaryItemType;
        }
    }

}

export interface IGlossaryTerms {
    global: {
        appName: string;
    },
    fieldValues: {
        [key: string]: GlossaryItemType;
    },
    features: {
        pullPackager: IFeatureGlossary;
        multiviewer: IFeatureGlossary,
        sources: IFeatureGlossary;
        destinations: IFeatureGlossary;
    }
}

const terms: IGlossaryTerms = {
    global: {
        appName: process.env.REACT_APP_CLIENT_NAME === "default" ? "LiveLink" : "Ateme+",
    },
    fieldValues: {
        loadPreset: {
            terms: ["Load a Preset", "Load Preset"],
            definition: "Select a previously saved preset to load the configuration settings."
        },
        savePreset: {
            terms: ["Save Preset", "Save a Preset"],
            definition: "Select to save the current configuration as a preset for future use."
        },
        updatePreset: {
            terms: ["Update Preset", "Update a Preset"],
            definition: "Select an existing preset to update with the current configuration."
        },
        saveNewPreset: {
            terms: ["Save New Preset", "Save a New Preset"],
            definition: "Enter a name to save the current configuration as a new preset."
        },
        videoFramerate: {
            terms: ["Video Frame Rate", "Frame Rate"],
            definition: "This is the number of frames displayed per second. A higher frame rate can make the video appear smoother, but it also requires more data. Common frame rates include 24, 30, and 60 frames per second."
        },
        maxBitrate: {
            terms: ["Maximum Bitrate", "Max Bitrate", "Max Allowed Bitrate"],
            definition: "Max bitrate refers to the total bitrate used to transmit audio, video, and other data streams within a single channel or transmission medium, ensuring efficient utilization of available bandwidth while maintaining the quality of each individual stream."
        },
        videoFrameType: {
            terms: ["Video Frame Type", "Frame Type"],
            definition: "Video frame type refers to the categorization of frames within a video sequence, distinguishing between different types based on their encoding and role in compression. Common types include I-frames (Intra-coded frames), P-frames (Predictive-coded frames), and B-frames (Bi-directional predictive-coded frames), each serving a distinct function in video compression and playback."
        },
        GOPSize: {
            terms: ["Group of Pictures Size", "GOP Size"],
            definition: "GOP stands for Group of Pictures. It's a group of successive pictures within a coded video stream where the first picture is a I-frame and the following pictures are P- or B-frames. A larger GOP size can provide more efficient compression, but it may also increase the latency and decrease the error resilience."
        },
        GOPSizeMode: {
            terms: ["Group of Pictures Size Mode", "GOP Size Mode"],
            definition: "GOP size mode determines the number of frames in a Group of Pictures (GOP) for video compression, affecting compression efficiency, latency, and error resilience."
        },
        programNumber: {
            terms: ["Program Number"],
            definition: "The program number in video broadcasting identifies a specific channel or service within a multiplexed digital television signal, facilitating channel selection for viewers."
        },
        PMTPID: {
            terms: ["Program Map Table PID", "PMT PID"],
            definition: "The Program Map Table (PMT) PID is a unique identifier within a digital television transmission that points to the Program Map Table, containing essential information such as audio and video stream details, enabling the decoder to properly interpret and decode the transmitted data."
        },
        videoPID: {
            terms: ["Video PID"],
            definition: "The Video PID (Packet Identifier) is a unique identifier within a digital television transmission that points to the packet containing the video stream data, enabling the decoder to extract and decode the video information for display."
        },
        AACAudioPID: {
            terms: ["AAC Audio PID"],
            definition: "The AAC (Advanced Audio Coding) Audio PID (Packet Identifier) is a unique identifier within a digital television transmission that points to the packet containing the AAC audio stream data, allowing the decoder to extract and decode the AAC audio information for playback."
        },
        latency: {
            terms: ["Latency"],
            definition: "Latency is the time delay between the input and output of a system, such as the time taken for a video signal to be processed and displayed on a screen. Low latency is essential for real-time applications like live streaming and video conferencing to ensure minimal delay and smooth interaction."
        },
    },
    features: {
        pullPackager: {
            title: "Pull Packager",
            description: "",
            form: {
                steps: {
                    name: {
                        title: "Name",
                        description: "Name your packager instance",
                    },
                    provider: {
                        title: "Cloud Provider",
                        description: "Select your cloud provider.",
                    },
                    region: {
                        title: "Region",
                        description: "Select your cloud provider region.",
                    },
                    product: {
                        title: "Product",
                        description: "Select the size of your packager.",
                    }
                },
                fields: {
                    name: "Select the name of your packager instance. This will be used to identify your packager instance.",
                    provider: "Select which cloud provider will host your packager instance.",
                    region: "Select which cloud provider region your packager instance will be hosted in.",
                    product: "SelSelect the size of your packager instance. The size of your packager instance will determine how many concurrent transcodes your packager instance can handle.",
                }
            }
        },
        multiviewer: {
            title: "Multiviewer",
            description: "",
            form: {
                steps: {
                    settings: {
                        title: "Settings",
                        description: "Define the settings for your multiviewer.",
                    },
                    tileSources: {
                        title: "Tile Sources",
                        description: "Select the titles and sources for your multiviewer.",
                    },
                    output: {
                        title: "Output",
                        description: "Define the config for your multiviewer."
                    }
                },
                fields: {
                    name: "This is will be the name that the new multiviewer is saved under.",
                    layout: "Define the number of sources and their layout in the multiviewer.",
                    title: "This is will be the name that the source is saved under.",
                    source: "Define the source for that tile."
                }
            }
        },
        sources: {
            title: "Source",
            description: "",
            form: {
                steps: {
                    name: {
                        title: "Name",
                        description: "Name your source",
                    },
                    type: {
                        title: "Type",
                        description: "Select your source type.",
                    },
                    encryption: {
                        title: "Encryption",
                        description: "Enable or disable encryption for your source.",
                    }
                },
                fields: {
                    name: "Set the name for your source. It needs to be unique and cannot be changed once saved. Note in the instance of Zixi Push, it is also used as the stream ID.",
                    description: "Set the description for your source",
                    type: "Select the type of source you want to add.",
                    configureLatencyRemotely: "This feature enables users to adjust latency settings for remote configuration, allowing for convenient management of latency parameters.",
                    port: "Local port in the platform to send the stream into from the sending device. Note ports 2057 to 2077 and 2088 to 2110 are reserved for Zixi services only, please use a port outside of these ranges.",
                    zixiPort: "Zixi Pull default is port 2088 for an input.",
                    host: "Specifies the host address or hostname where the source stream is located, enabling seamless connection to the desired streaming source.",
                    stream: "Identifies the specific name or identifier of the stream being pulled from the designated source, facilitating accurate stream retrieval.",
                    hostBackup: "Designates a backup host address or hostname to be used in case the primary host encounters issues, ensuring uninterrupted streaming availability.",
                    hostTertiaryBackup: "Specifies a tertiary backup host address or hostname to be utilized if both the primary and secondary hosts fail, enhancing redundancy and stream reliability.",
                    disconnectIfInactive: "This feature allows the system to automatically disconnect the source if it remains inactive for a specified duration, optimizing resource allocation and stream management.",
                    muxingBitrate: "Refers to the bitrate used for multiplexing the source stream, ensuring efficient data transmission and stream integration.",
                    smoothingLatency: "Specifies latency smoothing settings for the source stream, enhancing stream stability and reducing fluctuations in latency during transmission.",
                    url: "Uniform Resource Locator specifying the location of the stream to be pulled, facilitating accurate stream retrieval from the specified source.",
                    streamName: "Identifies the specific name or identifier of the stream to be pulled from the provided URL, ensuring precise stream selection.",
                    username: "Specifies the username required for authentication when accessing the stream, ensuring secure stream retrieval.",
                    password: "Specifies the password required for authentication when accessing the stream, ensuring secure access to protected streams.",
                    ignoreCertificateErrors: "Allows the system to bypass any certificate errors encountered during the connection process, ensuring uninterrupted stream retrieval in case of certificate validation issues.",
                    remotePort: "Specifies the network port used for remote communication with the specified source, facilitating remote management and configuration of streaming parameters.",
                    ristRemotePort: "Specify the remote port of the source device if required.",
                    enablePassword: "Enable password authentication on the incoming stream, ensure the incoming device is able to set a password for authentication.",
                    enableDecryption: "Decrypt incoming Zixi stream if Encryption is enabled on the sending device. Our platform does not require you to decrypt the stream but note that the end device must be able to.",
                    decryptionType: "Specifies the type or method of decryption to be applied to the encrypted data, ensuring compatibility and accuracy during the decryption process.",
                    decryptionKey: "Createa a decryption key for the incoming stream. This key is used to decrypt the incoming stream.",
                    srtLatency: "The end to end latency in milliseconds (ms) from the sending device to the platform. As a minimum the recommended value is 3x the round trip time, the more time allowed the greater chance for packet recovery.",
                }                  
            }
        },
        destinations: {
            title: "Destination",
            description: "",
            form: {
                steps: {
                    name: {
                        title: "Name",
                        description: "Name your destination",
                    },
                    type: {
                        title: "Type",
                        description: "Select your destination type.",
                    },
                    encryption: {
                        title: "Encryption",
                        description: "Enable or disable encryption for your destination.",
                    }
                },
                fields: {
                    name: "Destination name will appear in the destinations list. It needs to be unique and cannot be changed once saved.",
                    description: "Set the description for your destination",
                }
            }
        },
    }
};

export interface IGlossary {
    glossary: IGlossaryTerms;
    getFields: () => IGlossaryLookupTerm[];
}

export interface IGlossaryLookupTerm {
    key: string;
    label: string;
    value: string;
}

export const useGlossary = (): IGlossary => {
    const getFields = (): IGlossaryLookupTerm[] => {
        let fields = terms.fieldValues;

        let result: IGlossaryLookupTerm[] = [];
        for (const key in terms.fieldValues) {
            if (fields.hasOwnProperty(key)) {
                if (isObject(fields[key])) {
                    const element = fields[key] as IGlossaryItem;
                    result.push({
                        key,
                        label: element.terms[0],
                        value: element.definition
                    });
                } else {
                    result.push({
                        key,
                        label: formatLabel(key),
                        value: fields[key] as string
                    });
                }
            }
        }

        return result;
    }

    return {
        glossary: terms,
        getFields
    };
};

export default useGlossary;
