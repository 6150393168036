import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";

export default class DialogStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable.shallow dialog = {
        open: false,
        content: undefined,
        title: undefined,
        width: undefined,
        disableBackdropClick: undefined,
        disableEscPress: undefined
    };

    @action openDialog = (content: any, title?: any, width?: any, disableBackdropClick?: any, disableEscPress?: any): void => {
        this.dialog.open = true;
        this.dialog.content = content;
        this.dialog.title = title;
        this.dialog.width = width;
        this.dialog.disableBackdropClick = disableBackdropClick;
        this.dialog.disableEscPress = disableEscPress;
    };

    @action closeDialog = () => {
        this.dialog.open = false;
        this.dialog.disableBackdropClick = undefined;
        this.dialog.disableEscPress = undefined;
    };

    @action changeDialogBody = (content: any, title?: any, width?: any) => {
        this.dialog.content = content;
        this.dialog.title = title;
        this.dialog.width = width;
    };
}
