import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const ConfirmTwoFactorDisabled = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>2FA Disabled</DialogTitle>
            <DialogContent>Two-Factor Authentication has now been disabled.</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}
                    variant="contained"
                    color="primary">
                    OK
                </Button>
            </DialogActions>
        </>
    );
};
