import {
    Box,
    Chip,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import { agent, RootStoreContext, IOutputsStateEntry } from "api";
import React from "react";
import DestinationDetails from "./DestinationDetails";
import SourceThumbnail from "features/sources/Components/SourceThumbnail";
import SourceDetails from "features/sources/Components/SourceDetails";
import {
    Delete,
    Difference,
    Edit,
    EditCalendar,
    Event,
    MoreVert,
    NotificationAdd,
    NotificationsActive,
    PlayArrow,
    Refresh,
    Share,
    Stop
} from "@mui/icons-material";
import ConfirmationDialog from "components/ConfirmationDialog";
import PermanentScheduleForm from "../destinationScheduler/destinationScheduleForm/PermanentScheduleForm";
import CreateMultiScheduleForm from "features/scheduler/Components/CreateMultiScheduleForm";
import { useNavigate } from "react-router-dom";
import { AnimatedLineProps, SparkLineChart } from "@mui/x-charts";
import BitrateSparkline from "./BitrateSparkline";
import AlertsForm from "features/alertsManagement/Components/AlertsForm";
import useOnScreen from "hooks/UseOnScreen";

interface ScheduleItem {
    destination_name: string;
    end_time: string;
    environment_id: string;
    schedule_id: string;
    source_id: string;
    source_name: string;
    start_time: string;
    ui_status: string;
    wont_start: boolean;
}

interface DestinationItemProps {
    showGuestDestinations: boolean;
    destination: {
        active_alerts: number;
        destination_id: string;
        is_permanently_scheduled: boolean;
        permanent_schedule_input_id: string;
        permanent_schedule_input_name: string;
        name: string;
        connection_status: string;
        type: string;
        schedules: ScheduleItem[];
        read_only?: boolean;
    };
}

const DestinationItem: React.FC<DestinationItemProps> = ({ showGuestDestinations, destination }) => {
    //IS VISIBLE
    const ref = React.useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;

    const theme = useTheme();
    const navigate = useNavigate();

    const [apiData, setApiData] = React.useState<IOutputsStateEntry>();

    const [forceUpdate, setForceUpdate] = React.useState<number>(0);

    //MENU---------------
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        event.stopPropagation();
        setAnchorEl(null);
    };
    //------------------

    const formatType = (type: string) => {
        switch (type) {
            case "SRT_LISTENER":
                return "SRT Listener";
            case "SRT_PULL":
                return "SRT Listener";
            case "SRT_PUSH":
                return "SRT Caller";
            case "PULL":
                return "Zixi Pull";
            case "PUSH":
                return "Zixi Push";
            default:
                return type;
        }
    };

    const getApiData = async () => {
        await agent.Destination.getDestinationState(destination.destination_id)
            .then((res) => {
                //console.log(res);
                setApiData(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            destination.connection_status === "connected" && isVisible && getApiData();
            destination.connection_status === "connected" && isVisible && setForceUpdate((prev) => prev + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, [destination, isVisible]);

    //Get the api data on first load
    React.useEffect(() => {
        console.log("Destination", destination);
        getApiData();
    }, []);

    if (!showGuestDestinations && destination.read_only) {
        return null;
    }

    return (
        <TableRow
            hover={destination.active_alerts > 0 ? false : true}
            onClick={() => {
                openDrawer(
                    <Box sx={{ width: 680, padding: 3, height: "100vh" }}>
                        <DestinationDetails destination_id={destination.destination_id} />
                    </Box>
                );
            }}
            sx={{
                cursor: "pointer",
                backgroundColor: destination.active_alerts > 0 ? theme.palette.error.dark : undefined
            }}>
            <TableCell>
                <span ref={ref}></span>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1
                    }}>
                    {destination.active_alerts > 0 && (
                        <Box
                            sx={{ mt: "4px" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                openDrawer(
                                    <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                        <DestinationDetails destination_id={destination.destination_id} tab="2" />
                                    </Box>
                                );
                            }}>
                            <Tooltip title={destination.active_alerts + " active alert(s)"} placement="top">
                                <NotificationsActive fontSize="small" className="pulse" />
                            </Tooltip>
                        </Box>
                    )}
                    <Stack direction="row" spacing={1} alignItems="center">
                        {destination.read_only && (
                            <Tooltip title="Guest Destination">
                                <Share
                                    fontSize="small"
                                    sx={{
                                        color: theme.palette.text.secondary
                                    }}
                                />
                            </Tooltip>
                        )}

                        <Box
                            sx={{
                                fontWeight: "bold",
                                color: destination.read_only ? theme.palette.text.secondary : undefined
                            }}>
                            {destination.name}
                        </Box>
                    </Stack>
                </Box>
            </TableCell>
            <TableCell>
                <Chip
                    size="small"
                    label={destination.connection_status}
                    sx={{ textTransform: "capitalize" }}
                    color={
                        destination.connection_status === "connected"
                            ? "success"
                            : destination.connection_status === "stopped"
                            ? "error"
                            : undefined
                    }
                />
            </TableCell>
            <TableCell>{formatType(destination.type)}</TableCell>
            <TableCell sx={{ py: 0 }}>
                {
                    //apiData is an array. get the value of the item with the label "Bitrate(Kbps)"
                    //apiData?.bitrate_kbps || 0
                }
                <BitrateSparkline
                    value={apiData?.bitrate_kbps || 0}
                    width={160}
                    forceUpdate={forceUpdate}
                    color={
                        destination.active_alerts > 0
                            ? theme.palette.error.light
                            : destination.connection_status === "connected"
                            ? theme.palette.success.main
                            : theme.palette.action.selected
                    }
                />
            </TableCell>
            <TableCell>
                {
                    //apiData is an array. get the value of the item with the label "Packets Lost"
                    apiData?.current_uptime || "00:00:00"
                }
            </TableCell>
            <TableCell>
                {
                    //If there are schedules, display the source name of the item that is currently running based on current time being between start and end time
                    //Display a <Box> if there is an item that is currently running with the source name, start time, and end time
                    !destination.is_permanently_scheduled &&
                        destination.schedules.length > 0 &&
                        destination.schedules
                            .filter((schedule) => {
                                const startTime = new Date(schedule.start_time);
                                const endTime = new Date(schedule.end_time);
                                const currentTime = new Date();
                                return startTime <= currentTime && currentTime <= endTime;
                            })
                            .map((schedule) => (
                                <Box
                                    key={schedule.schedule_id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openDrawer(
                                            <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                <SourceDetails item_id={schedule.source_id} />
                                            </Box>
                                        );
                                    }}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        borderRadius: 1,
                                        backgroundColor: theme.palette.success.dark,
                                        color: theme.palette.primary.contrastText,
                                        padding: 1,
                                        "&:hover": {
                                            backgroundColor: theme.palette.success.main
                                        }
                                    }}>
                                    <Box
                                        sx={{
                                            width: 65,
                                            mr: 1,
                                            borderRadius: 1,
                                            overflow: "hidden",
                                            aspectRatio: "16:9",
                                            backgroundColor: theme.palette.action.selected
                                        }}>
                                        {isVisible && <SourceThumbnail item_id={schedule.source_id} disableLoading />}
                                    </Box>
                                    <Box>
                                        <Typography component="div" width={150} noWrap variant="body2" fontWeight="bold">
                                            {schedule.source_name}
                                        </Typography>
                                        <Typography component="div" width={150} noWrap variant="caption">
                                            {new Date(schedule.start_time).toLocaleTimeString()} -{" "}
                                            {new Date(schedule.end_time).toLocaleTimeString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                }
                {
                    //if there are schedules that are yet to start, display a <Box> with the source name, start time, and end time
                    !destination.is_permanently_scheduled &&
                        destination.schedules.length > 0 &&
                        destination.schedules
                            .filter((schedule) => {
                                const startTime = new Date(schedule.start_time);
                                const currentTime = new Date();
                                return startTime > currentTime;
                            })
                            .map((schedule, index) => (
                                <Box
                                    key={schedule.schedule_id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openDrawer(
                                            <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                                <SourceDetails item_id={schedule.source_id} />
                                            </Box>
                                        );
                                    }}
                                    sx={{
                                        //display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        borderRadius: 1,
                                        backgroundColor: theme.palette.action.selected,
                                        padding: 1,
                                        display: index === 0 ? "flex" : "none",
                                        mt: "2px",
                                        "&:hover": {
                                            backgroundColor: theme.palette.action.hover
                                        }
                                    }}>
                                    {/* <Box sx={{ width: 70, mr: 1, borderRadius: 1, overflow: "hidden" }}>
                                        <SourceThumbnail item_id={schedule.source_id} disableLoading />
                                    </Box> */}
                                    <Box>
                                        <Typography component="div" width={220} noWrap variant="body2">
                                            Up Next: <b>{schedule.source_name}</b>
                                        </Typography>
                                        <Typography component="div" width={150} noWrap variant="caption">
                                            {new Date(schedule.start_time).toLocaleDateString()}
                                            {": "}
                                            {new Date(schedule.start_time).toLocaleTimeString()} -{" "}
                                            {new Date(schedule.end_time).toLocaleTimeString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                }
                {destination.is_permanently_scheduled && (
                    <Box
                        onClick={(e) => {
                            e.stopPropagation();
                            openDrawer(
                                <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
                                    <SourceDetails item_id={destination.permanent_schedule_input_id} />
                                </Box>
                            );
                        }}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderRadius: 1,
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText,
                            padding: 1,
                            "&:hover": {
                                backgroundColor: theme.palette.primary.main
                            }
                        }}>
                        <Box
                            sx={{
                                width: 65,
                                mr: 1,
                                borderRadius: 1,
                                overflow: "hidden",
                                aspectRatio: 16 / 9,
                                backgroundColor: theme.palette.action.selected
                            }}>
                            {isVisible && <SourceThumbnail item_id={destination.permanent_schedule_input_id} disableLoading />}
                        </Box>
                        <Box>
                            <Typography component="div" width={150} noWrap variant="body2" fontWeight="bold">
                                {destination.permanent_schedule_input_name}
                            </Typography>
                            <Typography variant="caption" component="div" width={150} noWrap>
                                Permanent Schedule
                            </Typography>
                        </Box>
                    </Box>
                )}
            </TableCell>
            <TableCell>
                {!destination.read_only && (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(e);
                        }}>
                        <MoreVert />
                    </IconButton>
                )}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button"
                    }}>
                    {/* Switch Permanent Schedule Source */}
                    {destination.is_permanently_scheduled && (
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                openDialog(
                                    <PermanentScheduleForm
                                        id={destination.destination_id}
                                        permanent_schedule_input_id={destination.permanent_schedule_input_id}
                                        permanent_schedule_input_name={destination.permanent_schedule_input_name}
                                    />
                                );
                                handleClose(e);
                            }}>
                            <ListItemIcon>
                                <Refresh fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Change Scheduled Source" />
                        </MenuItem>
                    )}

                    {!destination.is_permanently_scheduled && (
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                openDialog(
                                    <CreateMultiScheduleForm destination_id={destination.destination_id} />,
                                    "Create Schedule",
                                    "lg",
                                    true
                                );
                                handleClose(e);
                            }}>
                            <ListItemIcon>
                                <Event fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Schedule a Source" />
                        </MenuItem>
                    )}
                    {!destination.is_permanently_scheduled && (
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClose(e);
                                navigate(`/scheduler`);
                            }}>
                            <ListItemIcon>
                                <EditCalendar fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Edit Schedule" />
                        </MenuItem>
                    )}
                    <Divider />
                    {/* Create an Alert */}
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            openDialog(
                                <AlertsForm
                                    item_id={destination.destination_id}
                                    type="output"
                                    successCallback={() => {
                                        console.log("Alert created");
                                    }}
                                />,
                                "New Alert"
                            );
                            handleClose(e);
                        }}>
                        <ListItemIcon>
                            <NotificationAdd fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Create Alert" />
                    </MenuItem>
                    {/* Manage Alerts */}
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            openDrawer(
                                <Box sx={{ width: 680, padding: 3, height: "100%" }}>
                                    <DestinationDetails destination_id={destination.destination_id} tab="2" />
                                </Box>
                            );
                            handleClose(e);
                        }}>
                        <ListItemIcon>
                            <NotificationsActive fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Manage Alerts" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/destinations/edit/${destination.destination_id}`);
                            handleClose(e);
                        }}>
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Edit Destination" />
                    </MenuItem>
                    <MenuItem
                        disabled={destination.connection_status !== "stopped"}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                            openDialog(
                                <ConfirmationDialog
                                    title="Start Destination"
                                    message={`Are you sure you want to start ${destination.name}?`}
                                    confirmButtonText="Start Destination"
                                    confirmButtonColor="success"
                                    onConfirm={async () => {
                                        await agent.Destination.startOutput(destination.destination_id);
                                        closeDialog();
                                    }}
                                />
                            );
                        }}>
                        <ListItemIcon>
                            <PlayArrow fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Start Destination" />
                    </MenuItem>
                    <MenuItem
                        disabled={destination.connection_status === "stopped"}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                            openDialog(
                                <ConfirmationDialog
                                    title="Stop Destination"
                                    message={`Are you sure you want to stop ${destination.name}?`}
                                    confirmButtonText="Stop Destination"
                                    confirmButtonColor="error"
                                    onConfirm={async () => {
                                        await agent.Destination.stopOutput(destination.destination_id);
                                        closeDialog();
                                    }}
                                />
                            );
                        }}>
                        <ListItemIcon>
                            <Stop fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Stop Destination" />
                    </MenuItem>
                    {/* Delete */}
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                            openDialog(
                                <ConfirmationDialog
                                    title="Delete Destination"
                                    message={`Are you sure you want to delete ${destination.name}?`}
                                    confirmationCheckbox
                                    confirmationCheckboxLabel="I understand that this action cannot be undone."
                                    confirmButtonText="Delete"
                                    confirmButtonColor="error"
                                    onConfirm={async () => {
                                        await agent.Destination.deleteDestination(destination.destination_id);
                                        closeDialog();
                                    }}
                                />
                            );
                        }}>
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete Destination" />
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/destinations/duplicate/${destination.destination_id}`);
                            handleClose(e);
                        }}>
                        <ListItemIcon>
                            <Difference fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Duplicate Destination" />
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
};

export default DestinationItem;
