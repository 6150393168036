import { Cancel, CheckCircle, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { agent, RootStoreContext } from "api";
import ConfirmationDialog from "components/ConfirmationDialog";
import Content from "components/Layout/Content";
import React from "react";
import FileServerForm from "./Create";

export interface FileServer {
    id: string;
    name: string;
    type: string;
    region: string;
    access_key: string;
    bucket_name: string;
    is_in_use: boolean;
    used_by_transcoder_ids: string[];
}

const FileServers: React.FC = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;

    const [fileServers, setFileServers] = React.useState<FileServer[]>([]);

    const listFileServers = async () => {
        await agent.Packager.listFileServers()
            .then((res) => {
                console.log(res);
                setFileServers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        listFileServers();
    }, []);

    return (
        <Content
            noPadding
            toolbarContent={
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end"
                    }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            openDialog(
                                <FileServerForm
                                    mode="create"
                                    onSuccess={() => {
                                        listFileServers();
                                    }} />
                            );
                        }}>
                        Add File Server
                    </Button>
                </Box>
            }>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Region</TableCell>
                        <TableCell>Access Key</TableCell>
                        <TableCell>Bucket Name</TableCell>
                        <TableCell>Is In Use?</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fileServers.map((fileServer: any) => (
                        <TableRow key={fileServer.id}>
                            <TableCell>{fileServer.name}</TableCell>
                            <TableCell>{fileServer.type}</TableCell>
                            <TableCell>{fileServer.region}</TableCell>
                            <TableCell>{fileServer.access_key}</TableCell>
                            <TableCell>{fileServer.bucket_name}</TableCell>
                            <TableCell>{fileServer.is_in_use ? <CheckCircle color="success" /> : <Cancel color="error" />}</TableCell>
                            <TableCell>
                                <Stack direction="row" spacing={1}>
                                    <IconButton
                                        onClick={() => {
                                            openDialog(
                                                <FileServerForm
                                                    mode="edit"
                                                    fileServer={fileServer}
                                                    onSuccess={() => {
                                                        listFileServers();
                                                    }} />
                                            );
                                        }}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        disabled={fileServer.is_in_use}
                                        onClick={() => {
                                            openDialog(
                                                <ConfirmationDialog
                                                    title="Delete File Server"
                                                    message={`Are you sure you want to delete ${fileServer.name}?`}
                                                    confirmButtonColor="error"
                                                    confirmButtonText="Delete File Server"
                                                    onConfirm={async () => {
                                                        await agent.Packager.deleteFileServer(fileServer.id);
                                                    }}
                                                    onSuccess={listFileServers}
                                                />
                                            );
                                        }}>
                                        <Delete />
                                    </IconButton>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Content>
    );
};

export default FileServers;
