import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useContext, useState, useEffect, Fragment } from "react";
import { Form as FinalForm } from "react-final-form";
import { TextField } from "mui-rff";
import { combineValidators, isRequired } from "revalidate";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import { PaymentSuccess } from "./PaymentSuccess";
import LoadingComponent from "app/layout/LoadingComponent";
import { RootStoreContext } from "api";
import { Alert, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InfoItem from "components/InfoItem";
import { useBilling } from "hooks";

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const rootStore = useContext(RootStoreContext);
    const { quantity, billingData } = rootStore.billingStore;
    const { setCreditData, getCreditsInformation } = useBilling();
    const [data, setData] = useState(null);
    const [currency, setCurrency] = useState("");
    const [processing, setProcessing] = useState(false);
    const { closeDrawer, changeDrawerContent } = rootStore.drawerStore;

    useEffect(() => {
        setCreditData(quantity).then((data) => {
            if (data.no_payment_required) {
                changeDrawerContent(<PaymentSuccess no_payment={true} />);
            }
            setProcessing(true);
            setData(data);
            getCurrency(data);
            setProcessing(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCreditData, quantity]);

    useEffect(() => {
        let timeOut = null;
        new Promise((resolve) => (timeOut = setTimeout(resolve, 5000))).then(() => {
            if (!billingData) {
                closeDrawer();
            }
        });
        return () => {
            clearTimeout(timeOut);
        };
    }, [billingData, closeDrawer]);

    const validate = combineValidators({
        name: isRequired("Name")
    });

    const getCurrency = (values) => {
        if (values.currency === "gbp") {
            setCurrency("£");
        }
        if (values.currency === "usd") {
            setCurrency("$");
        }
        if (values.currency === "eur") {
            setCurrency("€");
        }
    };

    const handleSubmit = async (values) => {
        // Block native form submission.
        // event.preventDefault();
        setProcessing(true);

        const billingDetails = {
            name: values.name
        };

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const paymentMethodReq = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails
        });

        const secret = data.stripe_client_secret;

        if (paymentMethodReq.error) {
            setProcessing(false);
            return { [FORM_ERROR]: paymentMethodReq.error.message };
        }

        if (secret) {
            const { error } = await stripe.confirmCardPayment(data.stripe_client_secret, {
                payment_method: paymentMethodReq.paymentMethod.id
            });
            if (error) {
                console.log(error);
                setProcessing(false);
                return { [FORM_ERROR]: error.message };
            }
        } else {
            setProcessing(false);
            return { [FORM_ERROR]: "Client secret is Incorrect please report to Cerberus Tech" };
        }

        setProcessing(false);
        getCreditsInformation();
        changeDrawerContent(<PaymentSuccess no_payment={false} />);
    };

    const CARDOPTIONS = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: 500,
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: "#fce883" },
                "::placeholder": { color: "#87bbfd" }
            },
            invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee"
            }
        },
        hidePostalCode: true
    };

    return (
        <Fragment>
            {data ? (
                <FinalForm
                    onSubmit={handleSubmit}
                    validate={validate}
                    render={({ handleSubmit, submitting, submitError, dirtySinceLastSubmit, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ width: 400, p: 3 }}>
                                <Typography variant="h3" sx={{ mb: 3 }}>
                                    Checkout
                                </Typography>
                                <Box>
                                    <Typography variant="h6">Price breakdown</Typography>
                                </Box>
                                <Box>
                                    <InfoItem title="Credits amount" value={quantity.quantity} />
                                    <InfoItem title="Credits Price" value={data ? currency + data.subtotal : "Processing..."} />
                                    <InfoItem title="Tax" value={data ? currency + data.tax : "Processing..."} />
                                    <InfoItem title="Total including tax" value={data ? currency + data.total : "Processing..."} />
                                </Box>
                                <Box sx={{ marginTop: 2, mb: 5 }}>
                                    <Typography variant="h6">Card Details</Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <TextField
                                            fullWidth
                                            name="name"
                                            label="Name on Card"
                                            placeholder="NAME AS APPEARS ON CARD"
                                            type="text"
                                        />
                                    </Box>
                                    <Box>
                                        <CardElement options={CARDOPTIONS} name="cardinfo" />
                                    </Box>
                                </Box>
                                {submitError && !dirtySinceLastSubmit && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {submitError}
                                    </Alert>
                                )}
                                <Box style={{ pt: 4, display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                    <LoadingButton type="submit" variant="contained" disabled={!stripe || !data} loading={submitting}>
                                        {processing
                                            ? "Processing..."
                                            : `Pay ${currency}${data ? data.total : "pending.."} for ${quantity.quantity} Credits`}
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </form>
                    )}
                />
            ) : (
                <LoadingComponent inverted={false} />
            )}
        </Fragment>
    );
};

export default observer(CheckoutForm);
