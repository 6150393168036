import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { AddTerm } from "./commitmentAddTerm/AddTerm";
import Commitments from "./commitmentTables/Commitments";
import { CommitmentTermSettings } from "./commitmentTermSettings/CommitmentTermSettings";
import { Box } from "@mui/material";

export const PermanentCommitment = () => {
    const rootStore = useContext(RootStoreContext);
    const { billingInformation } = rootStore.billingStore;
    return (
        <Box>
            {billingInformation && billingInformation.is_on_credits && (
                <Box>
                    <CommitmentTermSettings />
                </Box>
            )}
            {billingInformation && !billingInformation.is_on_credits && (
                <Box>
                    <AddTerm />

                    <Commitments />
                </Box>
            )}
        </Box>
    );
};
