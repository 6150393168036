import { ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import { IKnowledgeAccordion, IKnowledgeSection, RootStoreContext } from "api";
import { KnowledgeHubMarkdown, KnowledgeHubSection } from "features/knowledgeHub/Components";
import RelatedArticle from "features/knowledgeHub/Components/RelatedArticle";
import React, { useRef } from "react";

interface LivePreviewProps {
    values: any;
}

const LivePreview: React.FC<LivePreviewProps> = ({ values }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { indexedItems } = rootStore.knowledgeHubStore;
    const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const scrollToSection = (index: number) => {
        sectionRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <Stack spacing={4}>
            <Box>
                {values.title && <Typography variant="h4">{values?.title}</Typography>}
                {values.description && <Typography variant="body2">{values?.description}</Typography>}
            </Box>
            {values.includeTOC && (
                <div>
                    <List dense>
                        {values.sections
                            ?.filter((section: IKnowledgeSection) => section.title !== undefined)
                            .map((section: IKnowledgeSection, index: number) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton onClick={() => scrollToSection(index)}>
                                        <ListItemIcon sx={{ minWidth: 20 }}>
                                            {values.isNumbered ? `${index + 1}.` : <>&bull;</>}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={section.title}
                                            sx={{
                                                color: (theme) => theme.palette.primary.main
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                    </List>
                </div>
            )}
            {values.image && <img src={values.image} alt={values.title} style={{ maxWidth: "100%" }} />}
            {values.video && (
                <video src={values.video} controls style={{ width: "100%" }}>
                    Your browser does not support the video tag.
                </video>
            )}
            <Stack spacing={4} sx={{ flex: 1 }}>
                {values.sections?.map((section: IKnowledgeSection, index: number) => (
                    <div key={index} ref={(el) => (sectionRefs.current[index] = el)}>
                        <Stack spacing={2}>
                            <Stack spacing={2}>
                                {section?.title && (
                                    <Typography variant="h5">
                                        {values.isNumbered && `${index + 1}. `}
                                        {section?.title}
                                    </Typography>
                                )}

                                {section?.description && (
                                    <Typography fontSize={15} color="text.secondary">
                                        {section?.description}
                                    </Typography>
                                )}

                                <KnowledgeHubMarkdown content={section?.content} />
                            </Stack>

                            {section.image && <img src={section.image} alt={section?.title} style={{ width: "100%" }} />}

                            {section.video && (
                                <video src={section.video} controls style={{ width: "100%" }}>
                                    Your browser does not support the video tag.
                                </video>
                            )}

                            <Stack spacing={2}>
                                {section.accordions &&
                                    section.accordions.map((accordion: IKnowledgeAccordion, index: number) => (
                                        <Paper key={index} elevation={0}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        position: "sticky",
                                                        top: 0,
                                                        zIndex: 1,
                                                        backgroundColor: "rgba(255, 255, 255, 0.04)"
                                                    }}>
                                                    {accordion.title}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <KnowledgeHubMarkdown content={accordion.content} />
                                                </AccordionDetails>
                                            </Accordion>
                                        </Paper>
                                    ))}
                            </Stack>

                            {section.relatedArticles && (
                                <Stack spacing={1}>
                                    <Typography variant="h6">{section.relatedArticles?.title ?? "Related Articles"}</Typography>
                                    {section.relatedArticles.items.map((relatedArticle, index) => {
                                        const selectedArticle = indexedItems.find((item) => item.id === relatedArticle.id);

                                        if (!selectedArticle) {
                                            return <></>;
                                        }

                                        return <RelatedArticle key={index} item={selectedArticle} onClick={() => {}} />;
                                    })}
                                </Stack>
                            )}

                            {section.alerts && (
                                <Stack spacing={2}>
                                    {section.alerts.map((alert, index) => (
                                        <Alert key={index} severity={alert.type}>
                                            {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                                            {alert.content && alert.content}
                                        </Alert>
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                    </div>
                ))}

                {!!values.tags.length && <Box>Tags: {values.tags.join(", ")}</Box>}
            </Stack>
        </Stack>
    );
};

export default LivePreview;
