import React, { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "mui-rff";
import { IUserFormValues, RootStoreContext } from "api";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "app/common/form/ErrorMessage";
import { ForgottenPassword } from "../ForgottenPassword";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import ClientProvider from "components/ClientProvider";

const validate = combineValidators({
    email: isRequired("Email"),
    password: isRequired("Password")
});

const LoginForm = observer(function LoginForm(): JSX.Element {
    const rootStore = useContext(RootStoreContext);
    const { login } = rootStore.userStore;
    const { openDialog, changeDialogBody } = rootStore.dialogStore;

    return (
        <FinalForm
            onSubmit={(values: IUserFormValues) =>
                login(values)
                    .then(() => {
                        changeDialogBody(
                            <div
                                style={{
                                    padding: "100px 0 150px 0",
                                    color: "white",
                                    textAlign: "center"
                                }}>
                                loading ...
                            </div>
                        );
                    })
                    .catch((error) => ({ [FORM_ERROR]: error }))
            }
            validate={validate}
            render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Box sx={{ p: 2, my: 2 }}>
                        <Box>
                            <ClientProvider client="default">
                                <Stack direction={"row"} sx={{ alignItems: "center", mb: 2 }}>
                                    <EmailIcon sx={{ mr: 2, opacity: 0.5 }} />
                                    <TextField
                                        fullWidth
                                        required
                                        name="email"
                                        placeholder="Email"
                                        label="Email address"
                                        type="email"
                                        sx={{ mr: 3 }}
                                    />
                                </Stack>
                            </ClientProvider>
                            <ClientProvider client="ateme">
                                <Stack direction={"row"} sx={{ alignItems: "center", mb: 2 }}>
                                    <EmailIcon sx={{ mr: 2, color: "#FFFFFF", opacity: 0.8 }} />
                                    <TextField
                                        fullWidth
                                        required
                                        name="email"
                                        placeholder="Email"
                                        label="Email address"
                                        type="email"
                                        sx={{ mr: 3 }}
                                    />
                                </Stack>
                            </ClientProvider>
                            <ClientProvider client="default">
                                <Stack direction={"row"} sx={{ alignItems: "center", mb: 2 }}>
                                    <LockIcon sx={{ mr: 2, opacity: 0.5 }} />
                                    <TextField
                                        fullWidth
                                        required
                                        name="password"
                                        id="password"
                                        placeholder="Password"
                                        label="Password"
                                        type="password"
                                        sx={{ mr: 3 }}
                                    />
                                </Stack>
                            </ClientProvider>
                            <ClientProvider client="ateme">
                                <Stack direction={"row"} sx={{ alignItems: "center", mb: 2 }}>
                                    <LockIcon sx={{ mr: 2, color: "#ffffff", opacity: 0.8 }} />
                                    <TextField
                                        fullWidth
                                        required
                                        name="password"
                                        id="password"
                                        placeholder="Password"
                                        label="Password"
                                        type="password"
                                        sx={{ mr: 3 }}
                                    />
                                </Stack>
                            </ClientProvider>
                        </Box>
                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                        <Box sx={{ mt: 3, textAlign: "center" }}>
                            <ClientProvider client="default">
                                <LoadingButton
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    sx={{ width: 150 }}
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<FontAwesomeIcon icon={["fal", "right-to-bracket"]} />}>
                                    Login
                                </LoadingButton>
                            </ClientProvider>
                            <ClientProvider client="ateme">
                                <LoadingButton
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    sx={{ width: 150, borderRadius: "32px", backgroundColor: "#2ca95b" }}
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<FontAwesomeIcon icon={["fal", "right-to-bracket"]} />}>
                                    Login
                                </LoadingButton>
                            </ClientProvider>
                        </Box>

                        <Box sx={{ mt: 4, textAlign: "center" }}>
                            <Button
                                onClick={() => {
                                    openDialog(<ForgottenPassword />);
                                }}>
                                Forgotten your password?
                            </Button>
                        </Box>
                    </Box>
                </form>
            )}
        />
    );
});

export default LoginForm;
