export const alertType = [
  {
    text: "Email",
    value: "email",
    key: "email",
  },
  {
    text: "Slack",
    value: "slack",
    key: "slack",
  },
];

export const alert_on_query = [
  { key: "net_send.rtt", text: "Round Trip Time (ms)", value: "net_send.rtt" },
  { key: "net_recv.bitrate", text: "Bitrate (Kbps)", value: "net_recv.bitrate" },
  { key: "net_recv.jitter", text: "Jitter (ms)", value: "net_recv.jitter" },
  { key: "arq_recv.dropped", text: "Not Recovered Packets", value: "arq_recv.dropped" },
  { key: "is_connected", text: "Connection Status", value: "is_connected" },
];

export const query_param = [
  { key: "gt", text: "Greater Than", value: "gt" },
  { key: "lt", text: "Less Than", value: "lt" },
  {
    key: "outside_range",
    text: "Outside range of 2 Values",
    value: "outside_range",
  },
  {
    key: "within_range",
    text: "Within range of 2 Values",
    value: "within_range",
  },
];

export const query_param_connection_status = [
  { key: "gt", text: "Connection Made", value: "gt" },
  { key: "lt", text: "Connection Dropped", value: "lt" },
];

export const query_time_range = ["10s", "1m", "5m", "10m", "15m", "1h"].map(
  (value) => ({
    key: value,
    text: value,
    value,
  })
);

export const time_types = [
  { key: "seconds", text: "seconds", value: "s" },
  { key: "minutes", text: "minutes", value: "m" },
  { key: "hours", text: "hours", value: "h" },
];

export const alert_repeat = [
  "5m",
  "10m",
  "15m",
  "20m",
  "25m",
  "30m",
  "45m",
  "1h",
  "1h30m",
  "2h",
  "2h30m",
  "3h",
  "4h",
  "5h",
  "7m30m",
  "10h",
  "15h",
  "20h",
  "24h",
].map((value) => ({
  key: value,
  text: value,
  value,
}));
