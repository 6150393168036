import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { agent, IFileClusterJob, IFileClusterJobs, RootStoreContext } from "api";
import {
    Box,
    Checkbox,
    Chip,
    IconButton,
    Tooltip,
    Pagination,
    Table,
    TableBody,
    TableFooter,
    TableHead,
    TableCell,
    TableSortLabel,
    TableRow
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchInput from "components/SearchInput";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useFileCluster } from "hooks";

type AbortedJobsProps = {
    deployment_id: string;
    pageSize: number;
};

const AbortedJobs: React.FC<AbortedJobsProps> = observer(function AbortedJobs(props) {
    const rootStore = React.useContext(RootStoreContext);
    const { abortedJobsRequest, setAbortedJobsSort, setAbortedJobsOffset, setAbortedJobsSearch } = rootStore.fileClusterStore;
    const { deleteJob } = useFileCluster();

    const { openDialog } = rootStore.dialogStore;

    const [jobs, setJobs] = React.useState<IFileClusterJobs>();
    const defaultList = [0];
    const [selectedJobs, setSelectedJobs] = useState(defaultList);

    const handleDeleteMultiple = () => {
        selectedJobs.forEach((item) => {
            if (item > 0) {
                deleteJob(props.deployment_id, item);
            }
        });
        setSelectedJobs(defaultList);
        getJobs();
    };

    const handleSelectAllAbortedJobs = (e: any, data: any) => {
        const currentItems = new Set(selectedJobs);
        if (data === true) {
            jobs?.data.forEach((item) => {
                currentItems.add(item.id);
            });
        } else {
            currentItems.clear();
        }
        setSelectedJobs(Array.from(currentItems));
    };

    const handleRowSelect = (e: any, data: any, job_id: any) => {
        const currentItems = new Set(selectedJobs);
        if (data === true) {
            currentItems.add(job_id);
        } else {
            currentItems.delete(job_id);
        }
        setSelectedJobs(Array.from(currentItems));
    };

    const getJobs = async () => {
        await agent.FileCluster.getJobsByDeployment(
            props.deployment_id,
            abortedJobsRequest.offset,
            props.pageSize,
            abortedJobsRequest.sort,
            abortedJobsRequest.category,
            abortedJobsRequest.source_filename
        ).then((response) => {
            setJobs(response);
        });
    };

    const handleSearch = (searchTerm: string) => {
        setAbortedJobsSearch(searchTerm);
        getJobs();
    };

    const clearSearch = () => {
        setAbortedJobsSearch("");
        getJobs();
    };

    React.useEffect(() => {}, [
        props.deployment_id,
        abortedJobsRequest,
        setAbortedJobsSort,
        setAbortedJobsOffset,
        jobs,
        setAbortedJobsSearch
    ]);

    React.useEffect(() => {
        getJobs();

        const interval = setInterval(() => {
            getJobs();
        }, 5000);
        return () => {
            setAbortedJobsOffset(0);
            setAbortedJobsSort("id:desc");
            clearSearch();
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box className="tab-toolbar">
                <SearchInput
                    name="aborted-jobs-search"
                    label="Search Source Files..."
                    value={abortedJobsRequest.source_filename}
                    onChange={handleSearch}
                    onClear={clearSearch}
                />
                <Box className="tab-toolbar-actions">
                    <h4>Selected Jobs ({selectedJobs.length - 1}): </h4>
                    <Tooltip title="Delete Selected Jobs">
                        <span>
                            <IconButton
                                aria-label="delete"
                                disabled={selectedJobs.length < 2 ? true : false}
                                onClick={() => {
                                    openDialog(
                                        <ConfirmationDialog
                                            title="Delete Selected Jobs"
                                            message="Are you sure you want to delete the selected jobs?"
                                            confirmButtonText="Delete Jobs"
                                            confirmButtonColor="error"
                                            onConfirm={async () => {
                                                handleDeleteMultiple();
                                            }}
                                        />
                                    );
                                }}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Table>
                <TableHead>
                    <TableCell padding="checkbox">
                        <Checkbox
                            onChange={(e, data) => handleSelectAllAbortedJobs(e, data)}
                            checked={selectedJobs.length - 1 === jobs?.data.length}
                        />
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={abortedJobsRequest.sort === "name:asc" || abortedJobsRequest.sort === "name:desc"}
                            direction={abortedJobsRequest.sort === "name:asc" ? "asc" : "desc"}
                            onClick={() => {
                                abortedJobsRequest.sort === "name:asc" ? setAbortedJobsSort("name:desc") : setAbortedJobsSort("name:asc");
                                getJobs();
                            }}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={abortedJobsRequest.sort === "id:asc" || abortedJobsRequest.sort === "id:desc"}
                            direction={abortedJobsRequest.sort === "id:asc" ? "asc" : "desc"}
                            onClick={() => {
                                abortedJobsRequest.sort === "id:asc" ? setAbortedJobsSort("id:desc") : setAbortedJobsSort("id:asc");
                                getJobs();
                            }}>
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Cores</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell align="right"></TableCell>
                </TableHead>
                <TableBody>
                    {jobs?.data.map((job: IFileClusterJob, i: number) => (
                        <TableRow key={i}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedJobs.includes(job.id)}
                                    //id={job.id}
                                    onChange={(e, data) => handleRowSelect(e, data, job.id)}
                                />
                            </TableCell>
                            <TableCell>
                                <div style={{ wordBreak: "break-all" }}>{job.name}</div>
                                <div
                                    style={{
                                        fontSize: "10px",
                                        opacity: "0.7"
                                    }}>
                                    Source: {job.source_filename}
                                </div>
                            </TableCell>
                            <TableCell>{job.id}</TableCell>
                            <TableCell>
                                <Chip
                                    label={job.status}
                                    size="small"
                                    style={{
                                        textTransform: "capitalize"
                                    }}
                                />
                            </TableCell>
                            <TableCell>{job.cores}</TableCell>
                            <TableCell>
                                {job.currency === "gbp" ? "£" : ""}
                                {job.currency === "eur" ? "€" : ""}
                                {job.currency === "usd" ? "$" : ""}
                                {job.cost_calculated ? job.cost_calculated : ""}
                            </TableCell>
                            <TableCell align="right">
                                <Tooltip title="Delete Job">
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            openDialog(
                                                <ConfirmationDialog
                                                    title="Delete Job"
                                                    message={`Are you sure you want to delete job '${job.name}'?`}
                                                    confirmButtonText="Delete Job"
                                                    confirmButtonColor="error"
                                                    onConfirm={async () => {
                                                        deleteJob(props.deployment_id, job.id);
                                                    }}
                                                    onSuccess={getJobs}
                                                />
                                            );
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {jobs && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <Pagination
                                        shape="rounded"
                                        count={Math.ceil(jobs.totals.aborted / props.pageSize)}
                                        page={abortedJobsRequest.offset / props.pageSize + 1}
                                        onChange={(event: any, page: number) => {
                                            setAbortedJobsOffset((page - 1) * props.pageSize);
                                            getJobs();
                                        }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
});

export default AbortedJobs;
