export const MPEGAAC = [
    "8 kHz",
    "11.025 kHz",
    "12 kHz",
    "16 kHz",
    "22.05 kHz",
    "24 kHz",
    "32 kHz",
    "44.1 kHz",
    "48 kHz",
    "64 kHz",
    "88.2 kHz",
    "96 kHz",
]

export const MPEGLayer2 = [
    "32 kHz",
    "44.1 kHz",
    "48 kHz",
]

export const DolbyDigital = [
    "48 kHz",
]