import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import StepperForm from "components/Forms/StepperForm";
import { Form } from "react-final-form";
import { TextField } from "components/Forms";
import { Box, LinearProgress, Typography } from "@mui/material";
import SelectProvider from "../Components/SelectProvider";
import SelectRegion from "../Components/SelectRegion";
import SelectIpAddress from "../Components/SelectIpAddress";
import SelectEnvironmentSize from "../Components/SelectEnvironmentSize";
import { agent, IEnvironmentFormValues } from "api";
import { useNavigate } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import { Link } from "react-router-dom";

function CloudProviderSwitch(code: string) {
    switch (code) {
        case "aws":
            return "Amazon Web Services";
        case "gcp":
            return "Google CLoud Platform";
        case "do":
            return "Digital Ocean";
        default:
            return "";
    }
}

const CreateEnvironment: React.FC = () => {
    const navigate = useNavigate();
    const [success, setSuccess] = React.useState(false);
    const [environment_id, setEnvironmentId] = React.useState<string | null>(null);
    const [environment_status, setEnvironmentStatus] = React.useState<any>(null);

    const intervalId = React.useRef<NodeJS.Timeout | null>(null);

    const getEnvironmentStatus = async (environment_id: string) => {
        try {
            const response = await agent.Environment.getEnvironmentStatus(environment_id);
            console.log(response);
            setEnvironmentStatus(response);

            // Start the interval if it's not already running
            if (intervalId.current === null) {
                intervalId.current = setInterval(async () => {
                    const status = await agent.Environment.getEnvironmentStatus(environment_id);
                    setEnvironmentStatus(status);

                    // Clear the interval if the process is finished and number_of_steps is not null
                    if (status.details.process_finished && status.details.number_of_steps !== null) {
                        if (intervalId.current !== null) {
                            clearInterval(intervalId.current);
                            intervalId.current = null;
                        }
                    }
                }, 2000); // Check every second
            }

            return response;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        return () => {
            if (intervalId.current !== null) {
                clearInterval(intervalId.current);
                intervalId.current = null;
            }
        };
    }, []);

    useEffect(() => {}, [environment_id, environment_status]);

    return (
        <Form
            onSubmit={(values: IEnvironmentFormValues) =>
                agent.Environment.createEnvironment(values)
                    .then((response) => {
                        console.log(response);
                        setEnvironmentId(response.environment_id);
                        setSuccess(true);
                        getEnvironmentStatus(response.environment_id);
                    })
                    .catch((error) => ({
                        [FORM_ERROR]: error
                    }))
            }
            render={({ submitError, handleSubmit, values, invalid, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <StepperForm
                        formTitle="Create Environment"
                        steps={[
                            {
                                label: "Name",
                                description: "",
                                infoContent: <Typography>This will be the name referred to within the platform.</Typography>,
                                content: (
                                    <Box>
                                        <TextField
                                            label="Name"
                                            name="name"
                                            validate={(value: any) => {
                                                if (!value) {
                                                    return "Required";
                                                }
                                                if (value && value.length > 255) {
                                                    return "Must be 255 characters or less";
                                                }
                                                //Name can only contain letters, numbers, spaces, periods (.) underscores (_) and dashes (-)
                                                if (value && !/^[a-zA-Z0-9\s._-]+$/.test(value)) {
                                                    return "Name can only contain letters, numbers, spaces, periods (.) underscores (_) and dashes (-)";
                                                }
                                            }}
                                            error={(meta: any) => {
                                                return meta.touched && meta.error ? true : false;
                                            }}
                                            helperText={(meta) => {
                                                return meta.touched && meta.error
                                                    ? meta.error
                                                    : "Choose a name for your environment. Max 255 characters";
                                            }}
                                        />
                                    </Box>
                                )
                            },
                            {
                                label: "Hosting Provider",
                                description: "",
                                infoContent: (
                                    <>
                                        <Typography sx={{ mb: 1 }}>
                                            The Cloud Provider that the environment will be hosted on (we currently offer cloud hosting via
                                            AWS, Digital Ocean or Google Cloud Platform).
                                        </Typography>
                                        <Typography>
                                            If you change this in the future you will be issued a new IP address as IPs cannot be
                                            transferred between Cloud providers.
                                        </Typography>
                                    </>
                                ),
                                content: (
                                    <Box>
                                        <SelectProvider />
                                    </Box>
                                )
                            },
                            {
                                label: "Region",
                                description: ``,
                                infoContent: (
                                    <>
                                        <Typography sx={{ mb: 1 }}>
                                            This step involves selecting the geographical location where your environment will be hosted
                                            (the options populating the list will vary depending on the Cloud Hosting provider chosen at the
                                            previous step).
                                        </Typography>
                                        <Typography sx={{ mb: 1 }}>
                                            The choice of region can impact latency, cost, and availability. For example, choosing a region
                                            close to your users can reduce latency, while different regions may have different pricing for
                                            the same resources. Additionally, legal and regulatory considerations might influence your
                                            choice of region, as data protection laws vary by country.
                                        </Typography>
                                        <Typography>
                                            Once set, changing the region might involve migrating resources and potential downtime.
                                        </Typography>
                                    </>
                                ),
                                content: (
                                    <Box>
                                        <SelectRegion />
                                    </Box>
                                )
                            },
                            {
                                label: "IP Address",
                                description: ``,
                                infoContent: (
                                    <>
                                        <Typography sx={{ mb: 1 }}>
                                            If you have saved a reserved IP address already from a previous Environment and it is not
                                            already attached to an existing Environment then you may select it here or click 'New IP
                                            Address' for the platform to request a new IP address for you.
                                        </Typography>
                                        <Typography>You have the option of saving this new IP address for future use also.</Typography>
                                    </>
                                ),
                                content: (
                                    <Box>
                                        <SelectIpAddress values={values} />
                                    </Box>
                                )
                            },
                            {
                                label: "Size",
                                description: ``,
                                infoContent: (
                                    <>
                                        <Typography sx={{ mb: 1 }}>
                                            This step defines the input & output bandwidth limits of your environment (the size ratio
                                            between input and output is always 1:2 Mbps).
                                        </Typography>
                                        <Typography sx={{ mb: 1 }}>
                                            The size of your environment should be based on the expected workload and performance
                                            requirements.
                                        </Typography>
                                        <Typography>
                                            It's important to note that the size of your environment will directly impact the cost, so it
                                            should be chosen carefully to balance performance and cost.
                                        </Typography>
                                    </>
                                ),
                                content: (
                                    <Box>
                                        <SelectEnvironmentSize />
                                    </Box>
                                )
                            }
                        ]}
                        values={values}
                        invalid={invalid}
                        submitError={submitError}
                        onCancel={() => navigate("/environments")}
                        onFinish={() => navigate("/environments")}
                        showSuccessStep={success}
                        successStep={
                            <Box>
                                {environment_status?.details.process_finished ? (
                                    <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                                        Your environment has been created successfully
                                    </Typography>
                                ) : (
                                    <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                                        Your environment is now being created
                                    </Typography>
                                )}

                                <Typography variant="h6">{`Environment Name: ${environment_status?.details.name}`}</Typography>
                                {environment_status?.details?.cloud_provider && (
                                    <Typography variant="h6" sx={{ mb: 3 }}>{`Cloud Provider: ${CloudProviderSwitch(
                                        environment_status.details.cloud_provider
                                    )}`}</Typography>
                                )}

                                {environment_status?.details
                                    ? environment_status.details.current_step < environment_status.details.number_of_steps ||
                                      environment_status.details.number_of_steps === null
                                        ? environment_status.details.current_step &&
                                          environment_status.details.number_of_steps && (
                                              <>
                                                  <Box sx={{ mb: 1 }}>
                                                      Step {environment_status.details.current_step} of{" "}
                                                      {environment_status.details.number_of_steps}
                                                  </Box>
                                                  <LinearProgress
                                                      variant="determinate"
                                                      sx={{ height: "1rem", mb: 3 }}
                                                      value={
                                                          (environment_status.details.current_step /
                                                              environment_status.details.number_of_steps) *
                                                          100
                                                      }
                                                  />
                                              </>
                                          )
                                        : null
                                    : null}

                                {environment_status?.details.process_finished ? (
                                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
                                        You can now start deploying your application.
                                    </Typography>
                                ) : (
                                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
                                        Please be patient while we build your environment, some steps may take longer than others. Feel free
                                        to close this window, we will notify you when this is complete and you can view the environment in{" "}
                                        <Link to="/environments">Manage Environments</Link> once complete.
                                    </Typography>
                                )}
                            </Box>
                        }
                        successTabContent={
                            <>
                                <Typography sx={{ mb: 3 }}>Your environment is now building.</Typography>
                                <Typography sx={{ mb: 3 }}>This can take up to 15 minutes.</Typography>
                                <Typography>
                                    Once complete, you will receive a notification and can start creating Sources, transcoders, and
                                    destinations.
                                </Typography>
                            </>
                        }
                        successTabTitle={environment_status?.details.process_finished ? "Environment Created" : "Environment Creating"}
                        submitting={submitting}
                        submitButtonText="Create Environment"
                    />
                </form>
            )}
        />
    );
};

export default observer(CreateEnvironment);
