import { Alert } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { RadioGroupField, SelectField, SliderField } from "components/Forms";
import React from "react";
import { useFormState } from "react-final-form";

interface Props {
    dataTrackIndex: number;
    values: any;
    onFieldChange: (field: string, value: any) => void;
}

const DataTrackForm: React.FC<Props> = ({ dataTrackIndex, values, onFieldChange }) => {
    const { submitErrors } = useFormState();
    return (
        <>
            <RadioGroupField
                name={`individual_track_configuration.data_tracks.${dataTrackIndex}.data_type`}
                label="Data Type"
                row
                options={[
                    { label: "Closed Captions", value: "Closed Captions" },
                    { label: "DVB Subtitles", value: "DVB Subtitles" },
                    { label: "SCTE-35 pass-through", value: "SCTE-35 pass-through" }
                    // { label: "Timecode in SEI", value: "Timecode in SEI" }
                ]}
                required
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                }}
            />

            <OnChange name={`individual_track_configuration.data_tracks.${dataTrackIndex}.data_type`}>
                {() => {
                    if (values.individual_track_configuration.data_tracks[dataTrackIndex].data_type !== "Timecode in SEI") {
                        onFieldChange(`individual_track_configuration.data_tracks.${dataTrackIndex}.timecode_source`, undefined);
                    }
                }}
            </OnChange>

            {!!values?.individual_track_configuration?.data_tracks?.length &&
                values?.individual_track_configuration?.data_tracks[dataTrackIndex]?.data_type === "Timecode in SEI" && (
                    <SelectField
                        name={`individual_track_configuration.data_tracks.${dataTrackIndex}.timecode_source`}
                        label="Video Input"
                        required
                        options={[
                            { text: "Smpte-2038 input data track", value: "Smpte-2038 input data track" },
                            { text: "ARIB-B24", value: "ARIB-B24" },
                            { text: "Disable", value: "Disable" },
                            { text: "Generated from system clock", value: "Generated from system clock" }
                        ]}
                    />
                )}

            <OnChange name={`individual_track_configuration.data_tracks.${dataTrackIndex}.timecode_source`}>
                {() => {
                    if (
                        values.individual_track_configuration.data_tracks[dataTrackIndex].timecode_source !== "Generated from system clock"
                    ) {
                        onFieldChange(`individual_track_configuration.data_tracks.${dataTrackIndex}.insertion_period`, undefined);
                    }
                }}
            </OnChange>

            {!!values?.individual_track_configuration?.data_tracks?.length &&
                values?.individual_track_configuration?.data_tracks[dataTrackIndex]?.timecode_source === "Generated from system clock" && (
                    <SliderField
                        name={`individual_track_configuration.data_tracks.${dataTrackIndex}.insertion_period`}
                        label="Insertion Period"
                        min={1}
                        max={100000000}
                        required
                        step={100}
                    />
                )}

            {
                // If there is an error for the data track, display an alert if the error is type of string
                submitErrors?.individual_track_configuration?.data_tracks?.[dataTrackIndex] &&
                typeof submitErrors.individual_track_configuration.data_tracks?.[dataTrackIndex] === "string" ? (
                    <Alert severity="error" sx={{ my: 2 }}>
                        {submitErrors.individual_track_configuration.data_tracks[dataTrackIndex]}
                    </Alert>
                ) : null
            }
        </>
    );
};

export default DataTrackForm;
