export const UserModules = {
    file_transcoding: "File Transcoding",
    global_view: "Global View",
    nealive: "NEA live",
    push_packager: "Push Packager",
    tag: "Tag"
};

export interface IUser {
    user_id: number;
    jwt_token: string;
    first_name?: string;
    last_name?: string;
    fullname?: string;
    email: string;

    is_superuser: boolean;
    is_admin: boolean;
    is_accounts_admin: boolean;
    is_accounts_user: boolean;
    is_monitor: boolean;
    is_operator: boolean;
    is_tester: boolean;
    is_dev: boolean;
    registered: boolean;

    active_org: number;
    active_org_name: string;
    active_org_created_on: string;

    active_environment_id: string | null;
    environment_id?: string;
    secret?: string;
    application_name?: string;
    totp_code?: string;
    two_factor_enabled: boolean;
    token?: string;
    previousState: null;
    request_id: number;
    request_recieved_at: Date;
    status: string;
    actioned_by_user_email?: string;
    message_text: string;
    details: IUser;
    organisation_invitation_token: string;
    is_member_of_active_org: boolean;

    // Signup stage "finished" means that two_factor_auth_step_complete=true, email_verified=true
    // and has_org_step_complete=true
    signup_stage: string | null;

    recaptcha_token?: string;
    managing_organisation_id?: number;
    managing_org_name: string | null;
    is_managing_org_root: boolean | null;
    managing_org_permission: string | null;
    is_getting_started_seen?: boolean;
    is_allowed_to_see_settings?: boolean;
    is_invitation_suspended?: boolean;

    modules_available?: {
        [key in keyof typeof UserModules]: boolean;
    };

    missing_system_alerts: boolean;

    two_factor_auth_step_complete: boolean;
    email_verified: boolean;
    has_org_step_complete: boolean;

    needs_to_enter_2fa?: boolean;
    muted_notification_categories?: string[];

    user_preferences?: any;
    own_cloud_status?: any;
}

export interface IUserFormValues extends Partial<IUser> {
    time?: Date;
}

export interface IUsers {
    users: IUser[];
}

export class UserFormValues implements IUserFormValues {
    details?: IUser = undefined;
    id?: number = undefined;
    email = "";
    password = "";
    first_name?: string = "";
    last_name?: string = "";
    token?: string = "";
    rememberMe?: boolean = false;
    registered?: boolean = false;
    enableTwoFactor?: boolean = false;
    totp_code?: string = "";
    message_text?: string = "";
    request_id?: number = undefined;
    request_recieved_at?: Date = undefined;
    status = "inactive";
    actioned_by_user_email?: string = "";
    active_environment_id?: string = "";
    organisation_invitation_token?: string = "";
    recaptcha_token?: string = "";
    is_member_of_active_org?: boolean = undefined;
    signup_stage?: string = undefined;
    managing_organisation_id?: number = undefined;

    constructor(init?: IUserFormValues) {
        if (init && init.request_recieved_at) {
            init.time = init.request_recieved_at;
        }

        Object.assign(this, init);
    }
}

export interface IUserPasswordChange {
    old_password: string;
    new_password: string;
}

export interface IUserPersonalDetailsChange {
    first_name: string;
    last_name: string;
    email: string;
}

export interface IUserPermissions {
    permissions_type: string;
    created_on: Date;
    can_invite: boolean;
    active_organisation_permission: string;
    is_superuser: boolean;
}

export interface IUserPermissionsFormValues extends Partial<IUserPermissions> {
    time?: Date;
}

export class UserPermissionsFormValues implements IUserPermissionsFormValues {
    permissions_type?: string = "";
    organisation_id?: number = undefined;
    created_on?: Date = undefined;
    active_organisation_permission?: string = "";

    constructor(init?: IUserPermissionsFormValues) {
        if (init && init.created_on) {
            init.time = init.created_on;
        }

        Object.assign(this, init);
    }
}

export interface IUserTwoFactor {
    totp_code: string;
}

export interface IDisableTwoFactor {
    jwt_token: string;
}

export interface IEnableTwoFactor {
    jwt_token: string;
}

export interface IGeoData {
    country: string;
    countryCode: string;
}

export interface IProspect {
    first_name: string;
    last_name: string;
    email: string;
    recaptcha_token: string;
    message: string;
}

export interface IProspectFormValues extends Partial<IProspect> {
    time?: Date;
}

export class PropsectFormValues implements IProspectFormValues {
    first_name = "";
    last_name = "";
    email = "";
    recaptcha_token?: string = "";
    message?: string = "";
}

export interface IUserInOrg {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    created_on: Date;
    active_organisation_id?: number;
    two_factor_enabled?: boolean;
    is_superuser: boolean;
    is_admin: boolean;
    is_operator: boolean;
    is_monitor: boolean;
    permission_type: string;
    registered: boolean;
    environment_id?: string;
    is_root_user: boolean;
    locked_out_until: string;
}

export interface IUsersInOrg {
    users: IUserInOrg[];
}

export interface IUpdateUserRequest {
    first_name: string;
    last_name: string;
}
