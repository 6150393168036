import { agent, IUpdateOrgUserPermissions, RootStoreContext } from "api";
import { useState, useCallback, useContext } from "react";

export const useAdmin = () => {
    const rootStore = useContext(RootStoreContext);
    const { setUsers } = rootStore.adminStore;
    const [isLoading, setIsLoading] = useState(false);

    const updateUserPermissions = useCallback(async (permissions: IUpdateOrgUserPermissions) => {
        try {
            await agent.Admin.updateUserPermissions(permissions);
        } catch (error) {
            throw error;
        }
    }, []);

    const deleteUser = useCallback(async (permissions: IUpdateOrgUserPermissions) => {
        try {
            await agent.Admin.deleteUser(permissions);
        } catch (error) {
            throw error;
        }
    }, []);

    const loadUsers = useCallback(async (organisation_id: number) => {
        const users = await agent.Admin.getUsersByOrganisation(organisation_id);
        setUsers(users.users);

        return users;
    }, []);

    return {
        isLoading,
        updateUserPermissions,
        deleteUser,
        loadUsers
    };
};
