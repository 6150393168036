//Possible statuses for a source:
// connecting, connected, stopped, no-thumb-avail

//Possible statuses for a multiviewer source:
// creating, running, stopping, stopped, starting

//Possible statuses for a transcoder source:
// running, stopping, stopped

import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PlayIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

import { useTheme } from "@mui/material";
import React from "react";

const useSource = (source) => {
    const theme = useTheme();

    React.useEffect(() => {}, [source]);

    const getTypeText = () => {
        if (source.is_motion_interpolation_framerate_converter) {
            return "MIFC Transcoder";
        }
        if (source.transcoder_id) {
            return "Transcoder";
        }
        if (source.multiviewer_id) {
            return "Multiviewer";
        }
        return source.human_type;
    };

    const getStatusText = () => {
        if (source.is_input_cutout) {
            return "Stopped (Cutout)";
        }

        if (
            source.transcoder_status &&
            (source.active_alerts > 0 ||
                (source.is_analysis_on && source.raise_p1_alert && source.p1_ok === false) ||
                (source.is_analysis_on && source.raise_p2_alert && source.p2_ok === false))
        ) {
            return source.transcoder_status + " (Alerting)";
        }

        if (source.transcoder_status) {
            return source.transcoder_status;
        }

        if (
            source.multiviewer_status &&
            (source.active_alerts > 0 ||
                (source.is_analysis_on && source.raise_p1_alert && source.p1_ok === false) ||
                (source.is_analysis_on && source.raise_p2_alert && source.p2_ok === false))
        ) {
            return source.multiviewer_status + " (Alerting)";
        }

        if (source.multiviewer_status) {
            return source.multiviewer_status;
        }

        if (
            source.connection_status &&
            (source.active_alerts > 0 ||
                (source.is_analysis_on && source.raise_p1_alert && source.p1_ok === false) ||
                (source.is_analysis_on && source.raise_p2_alert && source.p2_ok === false))
        ) {
            return source.connection_status + " (Alerting)";
        }

        if (source.connection_status === "no-thumb-avail") {
            return "Connected (No Thumbnail)";
        } else {
            return source.connection_status;
        }
    };

    const getStatusColor = () => {
        if (
            source.is_input_cutout ||
            source.active_alerts > 0 ||
            (source.is_analysis_on && source.raise_p1_alert && source.p1_ok === false) ||
            (source.is_analysis_on && source.raise_p2_alert && source.p2_ok === false)
        ) {
            return theme.palette.error.main;
        }
        if (getStatusText() === "connected" || getStatusText() === "running" || source.connection_status === "no-thumb-avail") {
            return theme.palette.success.main;
        }
        if (getStatusText() === "stopped") {
            return theme.palette.action.disabled;
        }
        if (source.connection_status === "connecting" || getStatusText() === "creating" || getStatusText() === "starting" || getStatusText() === "stopping") {
            return theme.palette.warning.main;
        }
        return theme.palette.action.disabled;
    };

    const getStatusIcon = () => {
        if (source.is_input_cutout || getStatusText() === "stopped") {
            return StopIcon;
        }
        if ((source.raise_p1_alert && source.p1_ok === false) || (source.raise_p2_alert && source.p2_ok === false)) {
            return TroubleshootIcon;
        }
        if (source.active_alerts > 0) {
            return NotificationsActiveIcon;
        }
        if (getStatusText() === "connected" || getStatusText() === "running" || source.connection_status === "no-thumb-avail") {
            return PlayIcon;
        }
        return HourglassFullIcon;
    };
    return { getTypeText, getStatusText, getStatusColor, getStatusIcon };
};

export default useSource;
