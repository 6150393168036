import React, { useContext, SyntheticEvent, useState } from "react";
import { RootStoreContext } from "api";
import Button from "@mui/material/Button";
import { Alert, AlertTitle, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useSources } from "hooks";

interface IProps {
    transcoder_id: string;
    transcoder_name: string;
}

/**
 * A component that displays a confirmation dialog for starting a transcoder.
 * @param {Object} props - The component props.
 * @param {string} props.transcoder_id - The ID of the transcoder to start.
 * @param {string} props.transcoder_name - The name of the transcoder to start.
 * @returns {JSX.Element} - The rendered component.
 */

export const ConfirmStartTranscoder: React.FC<IProps> = ({ transcoder_id, transcoder_name }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { startTranscodedStream } = useSources();
    const [error, setError] = useState("");

    const startTranscoder = (e: SyntheticEvent) => {
        startTranscodedStream(transcoder_id)
            .then(() => {
                closeDialog();
            })
            .catch((error) => {
                setError(error.data.message);
            });
    };

    return (
        <>
            <DialogTitle>Start Transcoder</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Transcoder: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{transcoder_name}</span>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    This will put the transcoder into a live state making available the transcoded version of the source.
                </Typography>
                <Alert severity="info" sx={{ marginTop: 2 }}>
                    <AlertTitle>Note you will be charged full transcoding cost once started.</AlertTitle>
                </Alert>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PlayArrowIcon />}
                    onClick={(e) => {
                        startTranscoder(e);
                    }}>
                    Start
                </Button>
            </DialogActions>
        </>
    );
};
