export const type = [
  { key: "push", text: "Zixi Push", value: "push" },
  { key: "pull", text: "Zixi Pull", value: "pull" },
  { key: "rtmp-push", text: "RTMP Push", value: "rtmp-push" },
  { key: "rtmp-pull", text: "RTMP Pull", value: "rtmp-pull" },
  { key: "udp-rist", text: "RIST", value: "udp-rist" },
  { key: "srt-push", text: "SRT Listener", value: "srt-push" },
  { key: "srt-pull", text: "SRT Caller", value: "srt-pull" },
];

export const outputTypes = [
  { key: "push", text: "Zixi Push", value: "push" },
  { key: "pull", text: "Zixi Pull", value: "pull" },
  { key: "rist", text: "RIST", value: "udp-rist" },
  { key: "rtmp", text: "RTMP", value: "rtmp" },
  { key: "srt-push", text: "SRT Caller", value: "srt-push" },
  { key: "srt-pull", text: "SRT Listener", value: "srt-pull" },
  { key: "udp", text: "UDP", value: "udp" },
];

export const outputFilterTypes = [
  { key: "push", text: "Zixi Push", value: "PUSH" },
  { key: "pull", text: "Zixi Pull", value: "PUSH" },
  { key: "rist", text: "RIST", value: "RIST" },
  { key: "rtmp", text: "RTMP", value: "RTMP" },
  { key: "srt-push", text: "SRT Caller", value: "srt-push" },
  { key: "srt-pull", text: "SRT Listener", value: "srt-pull" },
];
