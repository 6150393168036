import React from "react";

import Content from "components/Layout/Content";
import { PermanentCommitment } from "features/billing/Components/PermanentCommitment/PermanentCommitment";

export const BillingPermanentCommitment: React.FC = () => {
    const [loading, setLoading] = React.useState<boolean>(false);

    return (
        <Content loading={loading}>
            <PermanentCommitment />
        </Content>
    );
};

export default BillingPermanentCommitment;
