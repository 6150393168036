import { Alert, Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext } from "react";

interface Props {
    cluster_id: string;
    cluster_name: string;
}

const ConfirmStopCluster: React.FC<Props> = (props) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const [error, setError] = React.useState<any>(null);

    const handleStopCluster = async () => {
        await agent.FileCluster.stopDeployment(props.cluster_id, {})
            .then(() => {
                closeDialog();
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to stop the cluster {props.cluster_name}?
                </DialogContentText>
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error.data?.message}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button onClick={handleStopCluster} color="error" variant="contained">
                    Stop
                </Button>
            </DialogActions>
        </>
    );
}

export default ConfirmStopCluster;