import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { ServiceConsumptionResponseV2 } from "api";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { observer } from "mobx-react-lite";

interface Props {
    serviceConsumptionData: ServiceConsumptionResponseV2;
    selectedCloudProvider: string;
}

export const CloudProviderBreakdown: React.FC<Props> = ({ serviceConsumptionData, selectedCloudProvider }) => {
    return (
        <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, pt: 0 }}>
            <CardContent>
                {serviceConsumptionData &&
                    serviceConsumptionData?.cloud_provider_breakdown?.cloud_providers
                        .filter((cloudProvider) =>
                            selectedCloudProvider ? cloudProvider.cloud_provider_id === selectedCloudProvider : true
                        )
                        .map((cloudProvider, i) => (
                            <Accordion key={i} sx={{ backgroundColor: "transparent", backgroundImage: "none" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "transparent" }}>
                                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", pr: 2 }}>
                                        <Box>{cloudProvider?.cloud_provider_name}</Box>
                                        <Box>{serviceConsumptionData?.customer_currency_symbol + cloudProvider.total_cloud_cost}</Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Permanent Agreements</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.permanent_agreements?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData?.customer_currency_symbol + env?.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Environments</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.environments?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Environments Egress</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mb</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={i}>
                                                <TableCell>{cloudProvider.egress.mb}</TableCell>
                                                <TableCell align="right">
                                                    {serviceConsumptionData.customer_currency_symbol + cloudProvider.egress.cost}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Transcoders</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.transcoders?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Multiviewers</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.multi_viewers?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Push Packager Egress</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mb</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={i}>
                                                <TableCell>{cloudProvider.push_packager_egress.mb}</TableCell>
                                                <TableCell align="right">
                                                    {serviceConsumptionData.customer_currency_symbol +
                                                        cloudProvider.push_packager_egress.cost}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">IP Addresses</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.detached_ip_addresses?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={3} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">File Transcoding</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.titan_file?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Push Packagers</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.push_packagers?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ backgroundColor: "headerBox.main" }}>
                                                    <Typography variant="h6">Pull Packagers</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={300}>Size</TableCell>
                                                <TableCell>Hours</TableCell>
                                                <TableCell>Running Status</TableCell>
                                                <TableCell align="right">Cost</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cloudProvider?.pull_packagers?.map((env, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{env.size}</TableCell>
                                                    <TableCell>{env.hours}</TableCell>
                                                    <TableCell>{env.running_status}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {cloudProvider?.nea_live && (
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={4} sx={{ backgroundColor: "headerBox.main" }}>
                                                        <Typography variant="h6">Pull Packager Instances</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell width={300}>Size</TableCell>
                                                    <TableCell>Hours</TableCell>
                                                    <TableCell>Running Status</TableCell>
                                                    <TableCell align="right">Cost</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cloudProvider?.nea_live?.map((env, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell>{env.size}</TableCell>
                                                        <TableCell>{env.hours}</TableCell>
                                                        <TableCell>{env.running_status}</TableCell>
                                                        <TableCell align="right">
                                                            {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                    {cloudProvider?.nea_live_storage && (
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={4} sx={{ backgroundColor: "headerBox.main" }}>
                                                        <Typography variant="h6">Pull Packager Storage</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell width={300}>Size</TableCell>
                                                    <TableCell>Hours</TableCell>
                                                    <TableCell>Running Status</TableCell>
                                                    <TableCell align="right">Cost</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cloudProvider?.nea_live_storage?.map((env, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell>{env.size}</TableCell>
                                                        <TableCell>{env.hours}</TableCell>
                                                        <TableCell>{env.running_status}</TableCell>
                                                        <TableCell align="right">
                                                            {serviceConsumptionData.customer_currency_symbol + env.cost}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                    {cloudProvider?.pull_packager_egress && (
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={2} sx={{ backgroundColor: "headerBox.main" }}>
                                                        <Typography variant="h6">Pull Packager Egress</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Mb</TableCell>
                                                    <TableCell align="right">Cost</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={i}>
                                                    <TableCell>{cloudProvider.pull_packager_egress.mb}</TableCell>
                                                    <TableCell align="right">
                                                        {serviceConsumptionData.customer_currency_symbol +
                                                            cloudProvider.pull_packager_egress.cost}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        ))}
            </CardContent>
        </Card>
    );
};

export default observer(CloudProviderBreakdown);
