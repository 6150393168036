/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, Fragment } from "react";
import { observer } from "mobx-react-lite";

import HomePage from "./client/default/home";

import { ToastContainer, toast } from "react-toastify";
import LoginForm from "./features/user/Login/LoginForm";
import { RootStoreContext } from "api";
import LoadingComponent from "./app/layout/LoadingComponent";
import PrivateRoute from "components/PrivateRoute";
import { SettingsDashboard } from "./features/settings/SettingsDashboard";
import TitanFile from "./features/titanFile";
import Destinations from "./features/destinations";
import Notifications from "./features/notifications";
import Reporting from "./features/reporting";
import Overview from "./features/overview";
import UserActivity from "./features/userActivity";
import AdminOrganisations from "./features/admin/Organisations";
import AdminActiveCloudServices from "./features/admin/ActiveCloudServices";
import AdminDefaultPrice from "./features/admin/DefaultPrice";
import Developer from "./features/developer";
import Sources from "./features/sources";
import Packager from "./features/packager";
import PackagersList from "./features/packager/Packagers";
import ResetPassword from "./features/user/Registration/RegistrationFormItems/ResetPassword";
import { TermsOfService } from "./features/termsOfService/TermsOfService";
import "./app/layout/fa-library";
import SetupHome from "./features/setup/SetupHome";
import { loadStripe } from "@stripe/stripe-js";
import Sidebar from "./components/Layout/Sidebar";
import HelpPanel from "./components/HelpPanel";
import Billing from "features/billing";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./styles/LivelinkMuiTheme";
import themeLight from "./styles/LivelinkMuiThemeLight";
import GlobalDialog from "./components/Dialog";
import AdminSystem from "./features/admin/System";
import AdminCogs from "./features/admin/Cogs";
import GlobalDrawer from "./components/Drawer";

import ActiveEnvironmentMonitor from "./components/ActiveEnvironmentMonitor";
import { Routes, useLocation, useNavigate, Route, Outlet } from "react-router-dom";
import BillingHome from "features/billing/Home";
import BillingPermanentCommitment from "features/billing/PermanentCommitment";
import BillingPriceList from "features/billing/PriceList";
import Dashboard from "features/dashboard";
import PackagerDetails from "features/packager/PackagerDetails";
import AlertsManagement from "features/alertsManagement";
import AlertsManagementHome from "features/alertsManagement/Home";
import MutedAlerts from "features/alertsManagement/MutedAlerts";
import NotificationMethods from "features/alertsManagement/NotificationMethods";
import PullPackager from "features/pullPackager";
import CreatePullPackager from "features/pullPackager/Create";
import PullPackagerDetails from "features/pullPackager/PullPackagerDetails";
import NotificationsMonitor from "components/NotificationsMonitor";
import { Box, useMediaQuery } from "@mui/material";
import SourcePermissions from "features/settings/sourcePermissions/SourcePermissions";
import ContentPermissions from "features/settings/sourcePermissions/contentPermissions/ContentPermissions";
import { Entitlements } from "features/settings/sourcePermissions/entitlements/Entitlements";
import Create from "features/environments/Create";
import CreateTranscoder from "features/transcoders/Create";
import CreatePushPackager from "features/packager/Create";
import CreatePullPackagerSource from "features/pullPackager/CreateSource";
import TfReleaseCandidates from "features/admin/TfReleaseCandidates";
import CreateMultiViewer from "features/multiviewers/Create";
import CreateSource from "features/sources/Create";
import BillingMonthlyReports from "features/billing/MonthlyReports";
import ReleaseNotes from "features/releaseNotes";
import OwnCloud from "features/ownCloud";
import EnableOwnCloud from "features/ownCloud/EnableOwnCloud";
import ValidateOwnCloudCredentials from "features/ownCloud/ValidateCredentials";
import SetOwnCloudRegions from "features/ownCloud/SetRegions";
import OwnCloudLimits from "features/ownCloud/Limits";
import BillingUsageReports from "features/billing/UsageReports";
import Environments from "features/environments";
import CreateDestination from "features/destinations/Create";
import { KnowledgeHub, Multiviewers } from "features";
import CreateKnowledgeHubItem from "features/knowledgeHub/Create";
import Scheduler from "features/scheduler";
import SchedulerDay from "features/scheduler/Day";
import SchedulerWeek from "features/scheduler/Week";
import SchedulerMonth from "features/scheduler/Month";
import SchedulerList from "features/scheduler/List";
import UserSettings from "features/user";
import UserProfile from "features/user/UserProfile";
import UserSecurity from "features/user/UserSecurity";
import UserPreferences from "features/user/UserPreferences";
import AccessDeniedFallback from "components/AccessDeniedFallback";
import { GlobalKnowledgeHub } from "features/knowledgeHub/Components";
import FileServers from "features/packager/FileServers";
import KnowledgeHubEditor from "features/knowledgeHub/Editor";
import { useBilling, useEnvironment, useOrganisation } from "hooks";
import Alerts from "features/alerts";
import AlertNotificationMethods from "features/alerts/NotificationMethods";
import { MultiviewersLayouts } from "features/multiviewers/Layouts";
import CreateMultiViewerLayout from "features/multiviewers/Layouts/Create";
import { MultiviewersHome } from "features/multiviewers/Home";

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        toolbar: true;
    }
}

// Build Override

const App: React.FC<any> = observer(function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const rootStore = useContext(RootStoreContext);
    const { setAppLoaded, token, appLoaded, axiosParams, pageInFocus, setPageInFocus, setPageScrolling, currentTheme, setCurrentTheme } =
        rootStore.commonStore;
    const { isLoggedIn, loadUserWithPermissions, user, activeEnvironment } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;
    const calledpath = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    const { setIsNotGuest, organisations } = rootStore.organisationStore;
    const { loadOrganisations } = useOrganisation();
    const { getActiveEnvironment, loadEnvironments } = useEnvironment();
    const { getCreditsInformation } = useBilling();
    const { helpPanelUrl } = rootStore.commonStore;
    const { initialize } = rootStore.resClientStore;
    const { updateUserPreferences } = rootStore.userStore;
    const { currentEnvCloudProvider } = rootStore.environmentStore;

    const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        //Dynamically load the clients theme file (scss)
        require(`./client/${process.env.REACT_APP_CLIENT_NAME}/theme/theme.scss`);

        window.name = "livelink";
        if (token) {
            loadUserWithPermissions()
                .then(() => {
                    initialize(token);
                    setAppLoaded();
                })
                .catch(() => {
                    setAppLoaded();
                });
        } else {
            // calledpath is undefined if on homepage, calledpath DOES NOT work locally
            if ((calledpath || (location.pathname && location.pathname !== "/")) && !isExternalPage(window.location.href)) {
                localStorage.clear();
                window.location.href = "/";
            }
            setAppLoaded();
        }
        // eslint-disable-next-line
    }, [token]);

    const isExternalPage = (url: string) => {
        // Is this a page that does not require auth token
        if (url.indexOf("/index.html?action=reset-password") > -1) {
            return true;
        }
        if (url.indexOf("/termsofservice") > -1) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (window.location.href.indexOf("/index.html?action=reset-password") > -1 && !isLoggedIn) {
            navigate("/");
            openDialog(<ResetPassword token={axiosParams.token} email={axiosParams.email} />, undefined, "xs");
        } else if (window.location.href.indexOf("/login") > -1 && !isLoggedIn) {
            navigate("/");
            openDialog(<LoginForm />, undefined, "xs");
        } else if (window.location.pathname !== "/" && !token && !isExternalPage(window.location.pathname)) {
            // Redirect none logged in people to homepage
            navigate("/");
        }

        // The code below is called on staging & prod deployment.
        // It basically allows you to open the same page you're on when you press refresh.
        // https://stackoverflow.com/questions/39783099/deploying-react-redux-app-in-aws-s3
        if (calledpath) {
            navigate(calledpath, { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axiosParams.token]);

    const onFocus = () => {
        setPageInFocus(true);
    };
    const onBlur = () => {
        setPageInFocus(false);
    };

    // Set the pageScrolling to True as soon as start scrolling, then set it to false 2 seconds after stopping scrolling.
    // Used as part of controlling thumbnail generation
    let scrollTimer: any = null;
    const onScroll = () => {
        setPageScrolling(true);
        if (scrollTimer !== null) {
            clearTimeout(scrollTimer);
        }
        scrollTimer = setTimeout(() => {
            setPageScrolling(false);
        }, 1500);
    };

    useEffect(() => {
        onFocus();
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
            window.removeEventListener("scroll", onScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stripePromise: any = useRef(null);

    useEffect(() => {
        if (
            stripePromise.current === null &&
            isLoggedIn &&
            user &&
            user.signup_stage === "finished" &&
            user.managing_organisation_id &&
            window.location.href.includes("billing")
        ) {
            const stripe_key: string = process.env.REACT_APP_STRIPE_KEY!;
            stripePromise.current = loadStripe(stripe_key);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, user, window.location.href]);

    const checkIsGuest = () => {
        return organisations?.data.map((ds) => {
            if (ds.is_member_of_active_org === false) {
                return setIsNotGuest(false);
            }
            return null;
        });
    };

    // API calls for getting information that is shared between many components - organisations / environment / user etc.
    useEffect(
        () => {
            if (isLoggedIn && user && user.signup_stage === "finished" && user.managing_organisation_id) {
                loadOrganisations();
                loadEnvironments().finally(() => {
                    checkIsGuest();
                    if (activeEnvironment) {
                        getActiveEnvironment(activeEnvironment);
                    }
                });
                getCreditsInformation();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeEnvironment, user, isLoggedIn]
    );

    useEffect(() => {
        if (isLoggedIn && user && user.signup_stage === "finished" && user.managing_organisation_id) {
            //startConsumingNotifications(user.managing_organisation_id);
            rootStore.resClientStore.initialize(token!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isLoggedIn, user?.muted_notification_categories]);

    //Initialise the current user settings
    useEffect(() => {
        if (user?.user_preferences?.theme) {
            setCurrentTheme(user.user_preferences.theme);
        }

        //check to see if user?.user_preferences?.silent_notifications is undefined, if so set it to false
        if (user && user.user_preferences?.silent_notifications === undefined) {
            updateUserPreferences({ silent_notifications: false });
        }

        if (user && user.user_preferences?.pinned_apps === undefined) {
            updateUserPreferences({
                pinned_apps: "dashboard:environments:global_view:sources:destinations_list:scheduler:push_packager:nealive:file_transcoding"
            });
        }

        //check user for desktop_notifications, if undefined set to false
        if (user && user.user_preferences?.desktop_notifications === undefined) {
            updateUserPreferences({ desktop_notifications: false });
        }
    }, [user]);

    if (!appLoaded) {
        return <LoadingComponent content="Loading app..." inverted={false} />;
    }

    return (
        <Fragment>
            <ThemeProvider theme={currentTheme === "light" ? themeLight : theme}>
                <CssBaseline enableColorScheme />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100vw" }}>
                    <div style={{ flex: "1 1 auto", width: "100%" }}>
                        {isLoggedIn && user ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: isSmDown ? "column" : "row",
                                    justifyContent: "flex-start",
                                    width: "100vw"
                                }}>
                                {isLoggedIn && user && location.pathname !== "/" && !isSmDown && <Sidebar />}
                                {isLoggedIn && user && (
                                    <Routes>
                                        <Route
                                            element={
                                                <div className="body-container">
                                                    {/* <ViewHeader title="test" /> */}
                                                    <Outlet />
                                                    <ActiveEnvironmentMonitor />
                                                    {user && rootStore.userStore.managingOrganisationId && rootStore.commonStore.token && (
                                                        <NotificationsMonitor />
                                                    )}
                                                </div>
                                            }>
                                            <Route path="/" element={<HomePage />} />
                                            <Route path="/dashboard" element={<Dashboard />} />
                                            <Route path="/termsofservice" element={<TermsOfService />} />
                                            <Route
                                                path="/overview"
                                                element={
                                                    <PrivateRoute>
                                                        <Overview />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/file"
                                                element={
                                                    <PrivateRoute>
                                                        <TitanFile />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/destinations"
                                                element={
                                                    <PrivateRoute>
                                                        <Destinations />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/destinations/create"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateDestination />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/destinations/edit/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateDestination mode="edit" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/destinations/duplicate/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateDestination mode="duplicate" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/destinations/list-view"
                                                element={
                                                    <PrivateRoute>
                                                        <Destinations />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/environments"
                                                element={
                                                    <PrivateRoute>
                                                        <Environments />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/environments/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <Environments />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/environments/create"
                                                element={
                                                    <PrivateRoute>
                                                        <Create />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/settings"
                                                element={
                                                    <PrivateRoute>
                                                        <SettingsDashboard />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/settings/environments"
                                                element={
                                                    <PrivateRoute>
                                                        <SettingsDashboard />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/settings/filecluster"
                                                element={
                                                    <PrivateRoute>
                                                        <SettingsDashboard />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/settings/sourcepermissions"
                                                element={
                                                    <PrivateRoute>
                                                        <SettingsDashboard />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/settings/sourcepermissions"
                                                element={
                                                    <PrivateRoute>
                                                        <SourcePermissions />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<ContentPermissions />} />
                                                <Route path="entitlements" element={<Entitlements />} />
                                            </Route>
                                            <Route
                                                path="/settings/organisations"
                                                element={
                                                    <PrivateRoute>
                                                        <SettingsDashboard />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/reporting"
                                                element={
                                                    <PrivateRoute>
                                                        <Reporting />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/reporting/file"
                                                element={
                                                    <PrivateRoute>
                                                        <Reporting />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/user-activity"
                                                element={
                                                    <PrivateRoute>
                                                        <UserActivity />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/user-activity/:email"
                                                element={
                                                    <PrivateRoute>
                                                        <UserActivity />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/notifications"
                                                element={
                                                    <PrivateRoute>
                                                        <Notifications />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/setup"
                                                element={
                                                    <PrivateRoute>
                                                        <SetupHome />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/admin/system"
                                                element={
                                                    <PrivateRoute admin>
                                                        <AdminSystem />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/admin/organisations"
                                                element={
                                                    <PrivateRoute admin>
                                                        <AdminOrganisations />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/admin/default-price"
                                                element={
                                                    <PrivateRoute admin>
                                                        <AdminDefaultPrice />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/admin/cogs"
                                                element={
                                                    <PrivateRoute admin>
                                                        <AdminCogs />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/admin/active-cloud-services"
                                                element={
                                                    <PrivateRoute admin>
                                                        <AdminActiveCloudServices />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/admin/tf-release-candidates"
                                                element={
                                                    <PrivateRoute admin>
                                                        <TfReleaseCandidates />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/developer"
                                                element={
                                                    <PrivateRoute dev>
                                                        <Developer />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/sources"
                                                element={
                                                    <PrivateRoute>
                                                        <Sources />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/sources/create"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateSource />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/sources/edit/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateSource mode="edit" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/sources/duplicate/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateSource mode="duplicate" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/multiviewers"
                                                element={
                                                    <PrivateRoute>
                                                        <Multiviewers />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<MultiviewersHome envProvider={currentEnvCloudProvider} />} />
                                                <Route
                                                    path="layouts"
                                                    element={<MultiviewersLayouts envProvider={currentEnvCloudProvider} />}
                                                />
                                            </Route>

                                            <Route
                                                path="/multiviewers/create"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateMultiViewer />
                                                    </PrivateRoute>
                                                }
                                            />

                                            {/* <Route
                                                path="/multiviewers/layouts/create"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateMultiViewerLayout />
                                                    </PrivateRoute>
                                                }
                                            /> */}

                                            {/* <Route
                                                path="/multiviewers/edit/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateMultiViewer mode="edit" />
                                                    </PrivateRoute>
                                                }
                                            /> */}

                                            <Route
                                                path="/release-notes"
                                                element={
                                                    <PrivateRoute dev>
                                                        <ReleaseNotes />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/packager"
                                                element={
                                                    <PrivateRoute>
                                                        <Packager />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/packager"
                                                element={
                                                    <PrivateRoute>
                                                        <Packager />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<PackagersList />} />
                                                <Route path="file-servers" element={<FileServers />} />
                                            </Route>
                                            <Route
                                                path="/packager/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <PackagerDetails />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/packager/create"
                                                element={
                                                    <PrivateRoute>
                                                        <CreatePushPackager mode="create" />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/packager/edit/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <CreatePushPackager mode="edit" />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/pull-packager"
                                                element={
                                                    <PrivateRoute>
                                                        <PullPackager />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/pull-packager/scrambling"
                                                element={
                                                    <PrivateRoute>
                                                        <PullPackager />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/pull-packager/:id"
                                                element={
                                                    <PrivateRoute>
                                                        <PullPackagerDetails />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/pull-packager/create"
                                                element={
                                                    <PrivateRoute>
                                                        <CreatePullPackager />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/pull-packager/:id/create-source"
                                                element={
                                                    <PrivateRoute>
                                                        <CreatePullPackagerSource mode="create" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/pull-packager/:id/edit-source/:sourceId"
                                                element={
                                                    <PrivateRoute>
                                                        <CreatePullPackagerSource mode="edit" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/transcoders/create"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateTranscoder mode="create" enable_motion_interpolation={false} />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/transcoders/create/:sourceId"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateTranscoder mode="create" enable_motion_interpolation={false} />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/transcoders/edit/:sourceId"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateTranscoder mode="edit" enable_motion_interpolation={false} />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/transcoders/create-mi"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateTranscoder mode="create" enable_motion_interpolation={true} />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/transcoders/create-mi/:sourceId"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateTranscoder mode="create" enable_motion_interpolation={true} />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/transcoders/edit-mi/:sourceId"
                                                element={
                                                    <PrivateRoute>
                                                        <CreateTranscoder mode="edit" enable_motion_interpolation={true} />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/billing"
                                                element={
                                                    <PrivateRoute>
                                                        <Billing />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<BillingHome />} />
                                                <Route path="permanent-commitment" element={<BillingPermanentCommitment />} />
                                                <Route path="price-list" element={<BillingPriceList />} />
                                                <Route
                                                    path="monthly-reports"
                                                    element={
                                                        <PrivateRoute org_admin fallback={<AccessDeniedFallback />}>
                                                            <BillingMonthlyReports />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                <Route
                                                    path="usage-reports"
                                                    element={
                                                        <PrivateRoute org_admin fallback={<AccessDeniedFallback />}>
                                                            <BillingUsageReports />
                                                        </PrivateRoute>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path="/alerts-management"
                                                element={
                                                    <PrivateRoute>
                                                        <AlertsManagement />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<AlertsManagementHome />} />
                                                <Route path="muted-alerts" element={<MutedAlerts />} />
                                                <Route path="notification-methods" element={<NotificationMethods />} />
                                            </Route>

                                            <Route
                                                path="/alerts"
                                                element={
                                                    <PrivateRoute>
                                                        <Alerts />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/alerts/notification-methods"
                                                element={
                                                    <PrivateRoute>
                                                        <AlertNotificationMethods />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/knowledge-hub"
                                                element={
                                                    <PrivateRoute dev>
                                                        <KnowledgeHub />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/knowledge-hub/create"
                                                element={
                                                    <PrivateRoute dev>
                                                        <CreateKnowledgeHubItem />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/knowledge-hub/edit/:id"
                                                element={
                                                    <PrivateRoute dev>
                                                        <CreateKnowledgeHubItem mode="edit" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/knowledge-hub/createv2"
                                                element={
                                                    <PrivateRoute dev>
                                                        <KnowledgeHubEditor />
                                                    </PrivateRoute>
                                                }
                                            />
                                            <Route
                                                path="/knowledge-hub/editv2/:id"
                                                element={
                                                    <PrivateRoute dev>
                                                        <KnowledgeHubEditor mode="edit" />
                                                    </PrivateRoute>
                                                }
                                            />

                                            <Route
                                                path="/settings/own-cloud"
                                                element={
                                                    <PrivateRoute>
                                                        <OwnCloud />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<EnableOwnCloud />} />
                                                <Route path="validate-credentials" element={<ValidateOwnCloudCredentials />} />
                                                <Route path="set-regions" element={<SetOwnCloudRegions />} />
                                                <Route path="limits" element={<OwnCloudLimits />} />
                                            </Route>
                                            <Route
                                                path="/scheduler"
                                                element={
                                                    <PrivateRoute>
                                                        <Scheduler />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<SchedulerDay />} />
                                                <Route path="week" element={<SchedulerWeek />} />
                                                <Route path="month" element={<SchedulerMonth />} />
                                                <Route path="list" element={<SchedulerList />} />
                                            </Route>
                                            <Route
                                                path="/user"
                                                element={
                                                    <PrivateRoute>
                                                        <UserSettings />
                                                    </PrivateRoute>
                                                }>
                                                <Route index element={<UserProfile />} />
                                                <Route path="security" element={<UserSecurity />} />
                                                <Route path="preferences" element={<UserPreferences />} />
                                            </Route>
                                        </Route>
                                    </Routes>
                                )}
                            </div>
                        ) : (
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/termsofservice" element={<TermsOfService />} />
                            </Routes>
                        )}
                        <HelpPanel url={helpPanelUrl} />
                        <GlobalDrawer />
                        <GlobalDialog />
                        <GlobalKnowledgeHub />
                        <ToastContainer position="bottom-right" theme="colored" />
                    </div>
                </div>
            </ThemeProvider>
        </Fragment>
    );
});

export default App;
