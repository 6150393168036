import { Add, AddCircle, ArrowDropDown, CellTower, PlaylistAdd, Videocam } from "@mui/icons-material";
import { Button, ButtonGroup, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { RootStoreContext } from "api";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateRepeatScheduleForm from "./CreateRepeatScheduleForm";
import CreateMultiScheduleForm from "./CreateMultiScheduleForm";
import { addMinutes, setSeconds } from "date-fns";
import RoleProvider from "components/RoleProvider";

interface CreateButtonProps {
    start_date?: Date;
    end_date?: Date;
    source_id?: string;
    destination_id?: string;
}

const CreateButton: React.FC<CreateButtonProps> = ({ start_date, end_date, source_id, destination_id }) => {
    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const navigate = useNavigate();
    const history = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };
    return (
        <>
            <ButtonGroup variant="contained">
                <Button
                    onClick={() => {
                        openDialog(
                            <CreateRepeatScheduleForm
                                start_date={start_date}
                                end_date={end_date}
                                source_id={source_id}
                                destination_id={destination_id}
                            />
                        );
                    }}
                    startIcon={<AddCircle />}>
                    Create Schedule
                </Button>
                <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="create-button"
                    aria-haspopup="menu"
                    sx={{ minWidth: "32px !important", width: 32 }}
                    onClick={handleClick}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Menu
                id="create-menu"
                MenuListProps={{
                    "aria-labelledby": "create-button"
                }}
                sx={{ minWidth: 300, p: 0 }}
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                open={open}
                onClose={(e) => {
                    handleClose(e);
                }}>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        openDialog(
                            <CreateRepeatScheduleForm
                                start_date={start_date}
                                end_date={end_date}
                                source_id={source_id}
                                destination_id={destination_id}
                            />
                        );
                        handleClose(event);
                    }}>
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText>Create Schedule</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        openDialog(
                            <CreateMultiScheduleForm start={addMinutes(setSeconds(start_date || new Date(), 0), 1)} end={end_date} />,
                            "Create Schedule",
                            "lg",
                            true
                        );
                        handleClose(event);
                    }}>
                    <ListItemIcon>
                        <PlaylistAdd />
                    </ListItemIcon>
                    <ListItemText>Create Multiple Schedules</ListItemText>
                </MenuItem>
                <Divider />
                <RoleProvider roles={["is_allowed_to_see_settings"]}>
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleClose(event);
                            navigate(`/sources/create`, { state: { returnUrl: history.pathname } });
                        }}>
                        <ListItemIcon>
                            <Videocam />
                        </ListItemIcon>
                        <ListItemText>Create Source</ListItemText>
                    </MenuItem>
                </RoleProvider>
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleClose(event);
                        navigate(`/destinations/create`, { state: { returnUrl: history.pathname } });
                    }}>
                    <ListItemIcon>
                        <CellTower />
                    </ListItemIcon>
                    <ListItemText>Create Destination</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default CreateButton;
