import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { RootStoreContext } from "api";
import React from "react";
import DestinationItem from "./DestinationItem";
import { agent } from "api";
import { observer } from "mobx-react-lite";
import { useDestinations } from "hooks";

interface DestinationsProps {
    searchTerm: string;
    showGuestDestinations: boolean;
}

export const DestinationsV2: React.FC<DestinationsProps> = ({ searchTerm, showGuestDestinations }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { token } = rootStore.commonStore;
    const { environment, currentEnvRunning } = rootStore.environmentStore;
    const { getDestinationsState } = useDestinations();

    const [update, setUpdate] = React.useState<Date>(new Date());
    const [loading, setLoading] = React.useState<boolean>(false);

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_env_id = rootStore.userStore.activeenvironment;

    const [data, setData] = React.useState<any>([]);

    const { client, clientConnected } = rootStore.resClientStore;

    const [sortBy, setSortBy] = React.useState("name:asc");

    const onUpdate = () => {
        setUpdate(new Date());
    };

    React.useEffect(() => {
        setLoading(true);

        clientConnected &&
            client
                .get(`environments.outputs.${managing_org_id}.${active_env_id}`)
                .then((res: any) => {
                    console.log(res);
                    res.on("add", () => {
                        onUpdate();
                    });

                    res.on("remove", () => {
                        onUpdate();
                    });
                    Array.from(res).map((destination: any) => {
                        destination.on("change", () => {
                            onUpdate();
                        });
                    });
                    setData(res);
                    setLoading(false);
                    onUpdate();
                })
                .catch((err) => {
                    console.error("Scheduler Error", err);
                    if (err.message === "No auth info provided") {
                        client.disconnect();
                        rootStore.resClientStore.initialize(token!);
                    }
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            setData([]);
        };
    }, [managing_org_id, rootStore.commonStore.token, clientConnected, client, active_env_id, token, currentEnvRunning]);

    React.useEffect(() => {}, [update]);

    React.useEffect(() => {
        const destinationsState = getDestinationsState();
        destinationsState.then((res) => {
            console.log(res);
        });
    }, []);

    return (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell sortDirection={sortBy === "name:asc" ? "asc" : sortBy === "name:desc" ? "desc" : false}>
                        <TableSortLabel
                            active={sortBy === "name:asc" || sortBy === "name:desc"}
                            direction={sortBy === "name:asc" ? "asc" : sortBy === "name:desc" ? "desc" : "asc"}
                            onClick={() => {
                                setSortBy(sortBy === "name:asc" ? "name:desc" : "name:asc");
                            }}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell
                        sortDirection={sortBy === "connection_status:asc" ? "asc" : sortBy === "connection_status:desc" ? "desc" : false}>
                        <TableSortLabel
                            active={sortBy === "connection_status:asc" || sortBy === "connection_status:desc"}
                            direction={sortBy === "connection_status:asc" ? "asc" : sortBy === "connection_status:desc" ? "desc" : "asc"}
                            onClick={() => {
                                setSortBy(sortBy === "connection_status:asc" ? "connection_status:desc" : "connection_status:asc");
                            }}>
                            Status
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={sortBy === "type:asc" ? "asc" : sortBy === "type:desc" ? "desc" : false}>
                        <TableSortLabel
                            active={sortBy === "type:asc" || sortBy === "type:desc"}
                            direction={sortBy === "type:asc" ? "asc" : sortBy === "type:desc" ? "desc" : "asc"}
                            onClick={() => {
                                setSortBy(sortBy === "type:asc" ? "type:desc" : "type:asc");
                            }}>
                            Type
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Bitrate</TableCell>
                    <TableCell width={106}>Uptime</TableCell>
                    <TableCell width={280}>Schedule</TableCell>
                    <TableCell width={70}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {currentEnvRunning &&
                    data &&
                    Array.from(data)
                        .filter((destination: any) => {
                            return destination.name.toLowerCase().includes(searchTerm.toLowerCase());
                        })
                        .sort((a: any, b: any) => {
                            const [field, order] = sortBy.split(":");
                            if (order === "asc") {
                                return a[field].localeCompare(b[field]);
                            } else {
                                return b[field].localeCompare(a[field]);
                            }
                        })
                        .map((destination: any) => {
                            return <DestinationItem showGuestDestinations={showGuestDestinations} key={destination.destination_id} destination={destination} />;
                        })}
            </TableBody>
        </Table>
    );
};

export default observer(DestinationsV2);
