import React, { useEffect } from "react";
import { Box, Stack, Typography, Button, Tooltip, IconButton } from "@mui/material";
import { observer } from "mobx-react";
import { HomeOutlined, KeyboardBackspace } from "@mui/icons-material";
import useRoleProvider from "hooks/UseRoleProvider";
import useClientProvider from "hooks/UseClientProvider";
import {
    IKnowledgeHubArticleShort,
    RootStoreContext,
    IKnowledgeHubHistory,
    KnowledgeHubSelectorType,
    KnowledgeHubItemStatus,
    KnowledgeHubType
} from "api";
import { useKnowledgeHub } from "hooks";
import KnowledgeHubListItem from "../KnowledgeHubListItem";
import KnowledgeHubItemActions from "../KnowledgeHubItemActions";

export interface IKnowledgeHubCategory {
    id: string;
    isSidebar: boolean;
    showBack?: boolean;
    history: IKnowledgeHubHistory[];
    goBackHome: () => void;
    goBack: () => void;
    goToSelector: (id: string, type: KnowledgeHubSelectorType) => void;
    editItem: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, type: KnowledgeHubType) => void;
    deleteItem: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string, isCategory?: boolean) => void;
    archiveItem: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, title: string, isCategory?: boolean) => void;
}

/**
 * Represents a category within the Knowledge Hub, displaying a list of articles or items
 * associated with the specified category. This component is responsible for fetching and
 * displaying the category details and its associated items, handling user interactions
 * for navigation, editing, and deleting items within the category.
 *
 * The component integrates with the application's role and client provider hooks to manage
 * access control and client-specific data visibility. It also supports displaying hidden
 * items for authorized users.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.id - The unique identifier of the category
 * @param {boolean} props.isSidebar - Indicates if the component is being rendered in a sidebar
 * @param {boolean} [props.showBack=true] - Controls the visibility of the back navigation button
 * @param {IKnowledgeHubHistory[]} props.history - Navigation history for breadcrumb-like functionality
 * @param {Function} props.goBackHome - Callback function to navigate back to the Knowledge Hub home
 * @param {Function} props.goBack - Callback function to navigate to the previous page
 * @param {Function} props.goToSelector - Callback function to navigate to a specific item or category
 * @param {Function} props.editItem - Callback function to initiate editing of an item or category
 * @param {Function} props.deleteItem - Callback function to delete an item or category
 * @param {Function} props.archiveItem - Callback function to archive an item or category
 * @returns {React.ReactElement} A React component that renders the category details and its associated items.
 *
 * @example
 * ```tsx
 * <KnowledgeHubCategory
 *   id="123"
 *   isSidebar={false}
 *   showBack={true}
 *   history={history}
 *   goBackHome={() => console.log("Go back home")}
 *   goBack={() => console.log("Go back")}
 *   goToSelector={goToSelector}
 *   editItem={(e, id, , type: KnowledgeHubType.Article) => console.log(`Edit ${id}`)}
 *   deleteItem={(e, id, title, isCategory) => console.log(`Delete ${title} with id ${id}, isCategory: ${isCategory}`)}
 *   archiveItem={(e, id, title, isCategory) => console.log(`Archive ${title} with id ${id}, isCategory: ${isCategory}`)}
 * />
 * ```
 */
export const KnowledgeHubCategory: React.FC<IKnowledgeHubCategory> = observer(function KnowledgeHubCategory({
    id,
    isSidebar,
    showBack = true,
    history,
    goBackHome,
    goBack,
    goToSelector,
    editItem,
    deleteItem,
    archiveItem
}) {
    const rootStore = React.useContext(RootStoreContext);
    const { categories, archivedCategories, items, listingItems, hiddenItems, unIndexedItems, archivedItems, draftItems } =
        rootStore.knowledgeHubStore;
    const [category, setCategory] = React.useState<IKnowledgeHubArticleShort | undefined>(undefined);
    const canViewItem = useRoleProvider(category?.roles ?? [], true);
    const isCorrectClient = useClientProvider(category?.client ?? "");
    const [isLoading, setIsLoading] = React.useState(false);
    const [categoryItems, setCategoryItems] = React.useState<IKnowledgeHubArticleShort[]>([]);
    const isCustom = id === "hidden" || id === "unIndexed" || id === "archived" || id === "draft";
    const { getCategory } = useKnowledgeHub();

    const getData = async () => {
        try {
            await getCategory(id, (data) => {
                console.log("getData", data);
            });
        } catch (error) {
            console.error("getData:", error);
        }
    };

    const getTitle = () => {
        switch (id) {
            case "hidden":
                return "Hidden Items";
            case "unIndexed":
                return "Un-Indexed Items";
            case "archived":
                return "Archived Items";
            case "draft":
                return "Draft Items";
            default:
                return category?.title;
        }
    };

    useEffect(() => {
        if (!categories.length || !listingItems.length) {
            return;
        }

        let currentItem = categories.find((c) => c.id === id);

        if (!currentItem) {
            currentItem = archivedCategories.find((c) => c.id === id);
        }

        let selectedItems = listingItems.filter((item) => item.category === id);

        if (id === "draft") {
            selectedItems = draftItems.filter((item) => item.category === id);
        }

        if (currentItem?.status === KnowledgeHubItemStatus.Archived) {
            selectedItems = archivedItems.filter((item) => item.category === id);
        }

        let isDifferent =
            selectedItems.length !== categoryItems.length ||
            selectedItems.some((item) => {
                let correspondingItem = categoryItems.find((ci) => ci.id === item.id);
                return !correspondingItem || correspondingItem.dateUpdated !== item.dateUpdated;
            });

        if (currentItem?.dateUpdated !== category?.dateUpdated) {
            setCategory(currentItem);
        }

        if (isDifferent) {
            setCategoryItems(selectedItems);
        }
    }, [listingItems, categories, items]);

    useEffect(() => {
        if (id === "hidden") {
            return setCategoryItems(hiddenItems);
        }

        if (id === "unIndexed") {
            return setCategoryItems(unIndexedItems);
        }

        if (id === "archived") {
            return setCategoryItems(archivedItems);
        }

        if (id === "draft") {
            return setCategoryItems(draftItems);
        }

        if (!categories.length) {
            setIsLoading(true);
            getData();
            return;
        }

        if (listingItems.length) {
            let selectedItems = listingItems.filter((item) => item.category === id);
            setCategoryItems(selectedItems);
        }

        let selectedCategory = categories.find((cat) => cat.id === id);

        if (!selectedCategory) {
            selectedCategory = archivedCategories.find((c) => c.id === id);
        }

        if (selectedCategory?.status === KnowledgeHubItemStatus.Archived) {
            setCategoryItems(archivedItems.filter((item) => item.category === id));
        }

        if (!selectedCategory) {
            setIsLoading(true);
            getData();
            return;
        }

        getData();
        setCategory(selectedCategory);
    }, []);

    if (isLoading) {
        return <></>;
    }

    if (!isLoading && (!category || !isCorrectClient) && !isCustom) {
        return (
            <Box>
                <Typography variant="h3">Category not found</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Please return to the knowledge base homepage
                </Typography>
                <Button variant="contained" color="primary" onClick={goBackHome}>
                    Go back
                </Button>
            </Box>
        );
    }

    if (!canViewItem && !isCustom) {
        return (
            <Box>
                <Typography variant="h3">Access Denied</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    You do not have access to view this category
                </Typography>
                <Button variant="contained" color="primary" onClick={goBackHome}>
                    Go back
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%", padding: showBack ? 0 : 3, paddingRight: showBack ? 3 : 0 }}>
            <Stack spacing={4} sx={{ width: "100%" }}>
                <Stack spacing={1} direction="row" alignItems="flex-start" justifyContent="space-between">
                    {history.length > 1 && showBack && (
                        <Stack spacing={1} direction="row">
                            {isSidebar && (
                                <Tooltip title="Go back to knowledge hub home">
                                    <IconButton onClick={goBackHome}>
                                        <HomeOutlined />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Go back">
                                <IconButton onClick={goBack}>
                                    <KeyboardBackspace />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {!isCustom && (
                            <KnowledgeHubItemActions
                                item={category}
                                editItem={editItem}
                                deleteItem={category?.status === KnowledgeHubItemStatus.Archived ? deleteItem : undefined}
                                archiveItem={category?.status === KnowledgeHubItemStatus.Archived ? undefined : archiveItem}
                            />
                        )}
                    </Stack>
                </Stack>

                <Box>
                    <Typography variant="h3">{getTitle()}</Typography>
                    <Typography variant="body2">
                        {id === "hidden" ? "Cerberus Tech Only: Items not assigned to a category" : category?.description}
                    </Typography>
                </Box>

                <Stack spacing={4}>
                    {categoryItems &&
                        categoryItems.map((item: IKnowledgeHubArticleShort) => (
                            <KnowledgeHubListItem
                                key={item.id}
                                isSidebar={isSidebar}
                                item={item}
                                onClick={() => goToSelector(item.id, KnowledgeHubSelectorType.Item)}
                                editItem={editItem}
                                deleteItem={deleteItem}
                                archiveItem={archiveItem}
                            />
                        ))}
                </Stack>
            </Stack>
        </Box>
    );
});

export default KnowledgeHubCategory;
