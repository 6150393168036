import React, { useContext } from "react";
import { OrganisationInvite } from "../../organisations/organisation-management/OrganisationInvite";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { agent, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { Box, Button, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LogoutIcon from "@mui/icons-material/Logout";
import ConfirmationDialog from "components/ConfirmationDialog";

interface IConfirmLeaveOrg {
    orgName: string;
}

const ConfirmLeaveOrg: React.FC<IConfirmLeaveOrg> = observer(function ConfirmLeaveOrg({ orgName }) {
    return (
        <div>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Confirm Leave
            </Typography>
            <Box sx={{ marginBottom: 1, textAlign: "center" }}>
                <Typography sx={{ color: "red", fontSize: "1.2em", mb: 1 }}>
                    Are you sure you want to leave organisation "{orgName}" ?
                </Typography>
                <Typography sx={{ fontSize: "1.2em", mb: 1 }}>
                    You will lose all access to this organisation and if this is your last organisation you belong to, you'll need to create
                    a new organisation in order to continue using the platform.
                </Typography>
            </Box>
        </div>
    );
});

interface OrganisationsToolbarProps {
    id?: string;
}

const OrganisationsToolbar: React.FC<OrganisationsToolbarProps> = observer(function OrganisationsToolbar() {
    const rootStore = useContext(RootStoreContext);
    const { user, logout } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;

    return (
        <div style={{ flex: "1 1 auto", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    title="Add a user to this organisation"
                    onClick={() => openDialog(<OrganisationInvite />)}
                    startIcon={<PersonAddIcon />}>
                    Add User
                </Button>
            </div>
            <div>
                {user && user.is_managing_org_root === false && (
                    <Button
                        variant="toolbar"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            openDialog(
                                <ConfirmationDialog
                                    title="Confirm Leave"
                                    message={`Are you sure you want to leave the organisation "${user!.active_org_name}"?`}
                                    onConfirm={async () => {
                                        await agent.getCleanInstance().delete("/organisations/leave-managing-organisation");
                                    }}
                                    onSuccess={() => {
                                        logout();
                                    }}

                                />
                            );
                        }}
                        startIcon={<LogoutIcon />}
                    >
                        Leave Organisation
                    </Button>
                )}
                {user && user.is_managing_org_root === true && <span style={{ color: "red" }}>You are root user of this organisation</span>}
            </div>
        </div>
    );
});

export default OrganisationsToolbar;
