import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { agent, IFileClusterJob, IFileClusterJobs, RootStoreContext } from "api";
import {
    Box,
    Checkbox,
    Chip,
    IconButton,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SearchInput from "components/SearchInput";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useFileCluster } from "hooks";

type StoppedJobsProps = {
    deployment_id: string;
    pageSize: number;
};

const StoppedJobs: React.FC<StoppedJobsProps> = observer(function StoppedJobs(props) {
    const rootStore = React.useContext(RootStoreContext);
    const { stoppedJobsRequest, setStoppedJobsSort, setStoppedJobsOffset, setStoppedJobsSearch } = rootStore.fileClusterStore;
    const { startJob, deleteJob } = useFileCluster();

    const [jobs, setJobs] = React.useState<IFileClusterJobs>();
    const defaultList = [0];
    const [selectedJobs, setSelectedJobs] = useState(defaultList);
    const { openDialog } = rootStore.dialogStore;

    const handleDeleteMultiple = () => {
        selectedJobs.forEach((item) => {
            if (item > 0) {
                deleteJob(props.deployment_id, item);
            }
        });
        setSelectedJobs(defaultList);
    };

    const handleStartMultiple = () => {
        selectedJobs.forEach((item) => {
            if (item > 0) {
                startJob(props.deployment_id, item);
            }
        });
        setSelectedJobs(defaultList);
    };

    const handleSelectAllStoppedJobs = (e: any, data: any) => {
        setSelectedJobs(defaultList);
        const currentItems = new Set(selectedJobs);
        if (data === true && jobs) {
            jobs.data.forEach((item) => {
                currentItems.add(item.id);
            });
            setSelectedJobs(Array.from(currentItems));
        } else {
            setSelectedJobs(defaultList);
        }
        getJobs();
    };

    const handleRowSelect = (e: any, data: any, job_id: any) => {
        const currentItems = new Set(selectedJobs);
        if (data === true) {
            currentItems.add(job_id);
        } else {
            currentItems.delete(job_id);
        }
        setSelectedJobs(Array.from(currentItems));
    };

    const getJobs = async () => {
        await agent.FileCluster.getJobsByDeployment(
            props.deployment_id,
            stoppedJobsRequest.offset,
            props.pageSize,
            stoppedJobsRequest.sort,
            stoppedJobsRequest.category,
            stoppedJobsRequest.source_filename
        ).then((response) => {
            setJobs(response);
        });
    };

    const handleSearch = (searchTerm: string) => {
        setStoppedJobsSearch(searchTerm);
        getJobs();
    };

    const clearSearch = () => {
        setStoppedJobsSearch("");
        getJobs();
    };

    React.useEffect(() => {}, [
        props.deployment_id,
        stoppedJobsRequest,
        setStoppedJobsSort,
        setStoppedJobsOffset,
        jobs,
        setStoppedJobsSearch
    ]);

    React.useEffect(() => {
        getJobs();

        const interval = setInterval(() => {
            getJobs();
        }, 5000);
        return () => {
            setStoppedJobsOffset(0);
            setStoppedJobsSort("id:desc");
            clearSearch();
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {}, [selectedJobs]);

    return (
        <div>
            <Box className="tab-toolbar">
                <SearchInput
                    name="search"
                    label="Search Source Files..."
                    value={stoppedJobsRequest.source_filename}
                    onChange={handleSearch}
                    onClear={clearSearch}
                />
                <Box className="tab-toolbar-actions">
                    <h4>Selected Jobs ({selectedJobs.length - 1}): </h4>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Start Selected Jobs">
                            <span>
                                <IconButton
                                    aria-label="start"
                                    onClick={handleStartMultiple}
                                    disabled={selectedJobs.length < 2 ? true : false}>
                                    <PlayArrowIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Selected Jobs">
                            <span>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        openDialog(
                                            <ConfirmationDialog
                                                title="Delete Jobs"
                                                message="Are you sure you want to delete the selected jobs?"
                                                confirmButtonText="Delete Jobs"
                                                confirmButtonColor="error"
                                                onConfirm={async () => {
                                                    handleDeleteMultiple();
                                                }}
                                            />
                                        );
                                    }}
                                    disabled={selectedJobs.length < 2 ? true : false}>
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </Box>
            </Box>
            <Table>
                <TableHead>
                    <TableCell padding="checkbox">
                        <Checkbox
                            onChange={(e, data) => handleSelectAllStoppedJobs(e, data)}
                            checked={selectedJobs.length - 1 === jobs?.data.length}
                        />
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={stoppedJobsRequest.sort === "name:asc" || stoppedJobsRequest.sort === "name:desc"}
                            direction={stoppedJobsRequest.sort === "name:asc" ? "asc" : "desc"}
                            onClick={() => {
                                stoppedJobsRequest.sort === "name:asc" ? setStoppedJobsSort("name:desc") : setStoppedJobsSort("name:asc");
                                getJobs();
                            }}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={stoppedJobsRequest.sort === "id:asc" || stoppedJobsRequest.sort === "id:desc"}
                            direction={stoppedJobsRequest.sort === "id:asc" ? "asc" : "desc"}
                            onClick={() => {
                                stoppedJobsRequest.sort === "id:asc" ? setStoppedJobsSort("id:desc") : setStoppedJobsSort("id:asc");
                                getJobs();
                            }}>
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Cores</TableCell>
                    <TableCell align="right"></TableCell>
                </TableHead>
                <TableBody>
                    {jobs?.data.map((job: IFileClusterJob, i: number) => (
                        <TableRow key={i} selected={selectedJobs.includes(job.id)}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedJobs.includes(job.id)}
                                    //id={job.id}
                                    onChange={(e, data) => handleRowSelect(e, data, job.id)}
                                />
                            </TableCell>
                            <TableCell>
                                <div style={{ wordBreak: "break-all" }}>{job.name}</div>
                                <div
                                    style={{
                                        fontSize: "10px",
                                        opacity: "0.7"
                                    }}>
                                    Source: {job.source_filename}
                                </div>
                            </TableCell>
                            <TableCell>{job.id}</TableCell>
                            <TableCell>
                                <Chip
                                    label={job.status}
                                    size="small"
                                    style={{
                                        textTransform: "capitalize"
                                    }}
                                />
                            </TableCell>
                            <TableCell>{job.cores}</TableCell>
                            <TableCell align="right">
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={1}>
                                        {job.status === "stopped" && (
                                            <Tooltip placement="bottom" arrow title="Restart job">
                                                <IconButton aria-label="restart" onClick={() => startJob(props.deployment_id, job.id)}>
                                                    <PlayArrowIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <Tooltip placement="bottom" arrow title="Delete job">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    openDialog(
                                                        <ConfirmationDialog
                                                            title="Delete Job"
                                                            message={`Are you sure you want to delete job '${job.name}'?`}
                                                            confirmButtonText="Delete Job"
                                                            confirmButtonColor="error"
                                                            onConfirm={async () => {
                                                                deleteJob(props.deployment_id, job.id);
                                                            }}
                                                        />
                                                    );
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {jobs && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <Pagination
                                        shape="rounded"
                                        count={Math.ceil(jobs.totals.stopped / props.pageSize)}
                                        page={stoppedJobsRequest.offset / props.pageSize + 1}
                                        onChange={(event: any, page: number) => {
                                            setStoppedJobsOffset((page - 1) * props.pageSize);
                                            getJobs();
                                        }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
});

export default StoppedJobs;
