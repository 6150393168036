import OnChange from "components/FinalFormListener/OnChange";
import { SelectField } from "components/Forms";
import React from "react";
import LoudnessControlForm from "./LoudnessControlForm";
import { useFormState } from "react-final-form";
import { FormApi } from "final-form";
import { pruneEmptyErrors } from "../../common/PruneEmptyErrors";

interface Props {
    values: any;
    submitError: any;
    onFieldChange: (field: string, value: any) => void;
    audioTrackIndex: number;
    form: FormApi;
}

const AudioTrackForm: React.FC<Props> = ({ values, submitError, onFieldChange, audioTrackIndex, form }) => {
    const { submitErrors } = useFormState();
    return (
        <>
            <SelectField
                name={`individual_track_configuration.audio_tracks[${audioTrackIndex}].codec`}
                options={[
                    { key: "MPEG AAC", text: "MPEG AAC", value: "MPEG AAC" },
                    { key: "MPEG Layer2", text: "MPEG Layer2", value: "MPEG Layer2" },
                    { key: "Dolby Digital", text: "Dolby Digital", value: "Dolby Digital" },
                    { key: "Dolby Digital Plus", text: "Dolby Digital Plus", value: "Dolby Digital Plus" },
                    { key: "Dolby AC-4", text: "Dolby AC-4", value: "Dolby AC-4" }
                ]}
                label="Codec"
                initialValue={
                    values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec
                        ? values.individual_track_configuration.audio_tracks[audioTrackIndex].codec
                        : "MPEG AAC"
                }
                required
                error={(meta: any) =>
                    (meta.touched && meta.error) ||
                    (!meta.touched && submitErrors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec)
                        ? true
                        : false
                }
                helperText={(meta: any) =>
                    meta.touched && meta.error
                        ? meta.error
                        : !meta.touched && submitErrors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec
                        ? submitErrors.individual_track_configuration.audio_tracks[audioTrackIndex].codec
                        : null
                }
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                }}
            />

            <OnChange name={`individual_track_configuration.audio_tracks[${audioTrackIndex}].codec`}>
                {() => {
                    if (submitErrors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec) {
                        delete submitErrors.individual_track_configuration.audio_tracks[audioTrackIndex].codec;
                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                    }

                    if (values.individual_track_configuration.audio_tracks[audioTrackIndex].codec !== "Dolby AC-4") {
                        form.change(`individual_track_configuration.audio_tracks[${audioTrackIndex}].ac4_loudness_control`, undefined);
                        form.change(`individual_track_configuration.audio_tracks[${audioTrackIndex}].enable_dolby_atmos`, undefined);
                    }
                }}
            </OnChange>

            {values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec && (
                <SelectField
                    required
                    name={`individual_track_configuration.audio_tracks[${audioTrackIndex}].encoding_mode_or_layout`}
                    options={
                        values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec &&
                        (values.individual_track_configuration.audio_tracks[audioTrackIndex].codec === "Dolby Digital"
                            ? [
                                  { key: "1.0", text: "1.0", value: "1.0" },
                                  { key: "2.0", text: "2.0", value: "2.0" },
                                  { key: "5.1", text: "5.1", value: "5.1" }
                                  //   { key: "Follow Input", text: "Follow Input", value: "Follow Input" }
                              ]
                            : values.individual_track_configuration.audio_tracks[audioTrackIndex].codec === "Dolby Digital Plus"
                            ? [
                                  { key: "1.0", text: "1.0", value: "1.0" },
                                  { key: "2.0", text: "2.0", value: "2.0" },
                                  { key: "5.1", text: "5.1", value: "5.1" },
                                  { key: "5.1.4", text: "5.1.4", value: "5.1.4" },
                                  { key: "7.1.4", text: "7.1.4", value: "7.1.4" }
                                  //   { key: "Follow Input", text: "Follow Input", value: "Follow Input" }
                              ]
                            : values.individual_track_configuration.audio_tracks[audioTrackIndex].codec === "Dolby AC-4"
                            ? [
                                  { key: "Stereo", text: "Stereo", value: "Stereo" },
                                  { key: "Surround 5.1", text: "Surround 5.1", value: "Surround 5.1" }
                              ]
                            : values.individual_track_configuration.audio_tracks[audioTrackIndex].codec === "MPEG Layer2"
                            ? [
                                  { key: "Mono", text: "Mono", value: "Mono" },
                                  { key: "Stereo", text: "Stereo", value: "Stereo" }
                                  // { key: "Follow Input", text: "Follow Input", value: "Follow Input" }
                              ]
                            : values.individual_track_configuration.audio_tracks[audioTrackIndex].codec === "MPEG AAC"
                            ? [
                                  { key: "Mono", text: "Mono", value: "Mono" },
                                  { key: "Stereo", text: "Stereo", value: "Stereo" },
                                  { key: "Surround 5.1", text: "Surround 5.1", value: "Surround 5.1" },
                                  { key: "Surround 7.1", text: "Surround 7.1", value: "Surround 7.1" }
                                  // { key: "Follow Input", text: "Follow Input", value: "Follow Input" }
                              ]
                            : [])
                    }
                    label={
                        values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec === "Dolby Digital" ||
                        values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.codec === "Dolby Digital Plus"
                            ? "Channel mode"
                            : "Layout"
                    }
                    error={(meta: any) =>
                        (meta.touched && meta.error) ||
                        (!meta.touched &&
                            submitErrors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.encoding_mode_or_layout)
                            ? true
                            : false
                    }
                    helperText={(meta: any) =>
                        meta.touched && meta.error
                            ? meta.error
                            : !meta.touched &&
                              submitErrors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.encoding_mode_or_layout
                            ? submitErrors.individual_track_configuration.audio_tracks[audioTrackIndex].encoding_mode_or_layout
                            : null
                    }
                    validate={(value: any) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                />
            )}

            {values?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.codec === "Dolby AC-4" && (
                <SelectField
                    name={`individual_track_configuration.audio_tracks[${audioTrackIndex}].ac4_loudness_control`}
                    options={[
                        { key: "ATSC A/85 Fixed", text: "ATSC A/85 Fixed", value: "ATSC A/85 Fixed" },
                        { key: "ATSC A/85 Manual", text: "ATSC A/85 Manual", value: "ATSC A/85 Manual" },
                        { key: "EBU R128", text: "EBU R128", value: "EBU R128" },
                        { key: "ARIB TR-B32", text: "ARIB TR-B32", value: "ARIB TR-B32" },
                        { key: "FreeTV OP-59", text: "FreeTV OP-59", value: "FreeTV OP-59" }
                    ]}
                    label="AC4 loudness control"
                    initialValue={
                        values?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.ac4_loudness_control
                            ? values.individual_track_configuration.audio_tracks[audioTrackIndex].ac4_loudness_control
                            : "ATSC A/85 Fixed"
                    }
                    required
                    error={(meta: any) =>
                        (meta.touched && meta.error) ||
                        (!meta.touched &&
                            submitErrors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.ac4_loudness_control)
                            ? true
                            : false
                    }
                    helperText={(meta: any) =>
                        meta.touched && meta.error
                            ? meta.error
                            : !meta.touched &&
                              submitErrors?.individual_track_configuration?.audio_tracks?.[audioTrackIndex]?.ac4_loudness_control
                            ? submitErrors.individual_track_configuration.audio_tracks[audioTrackIndex].ac4_loudness_control
                            : null
                    }
                    validate={(value: any) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                />
            )}

            <OnChange name={`individual_track_configuration.audio_tracks[${audioTrackIndex}].encoding_mode_or_layout`}>
                {() => {
                    if (submitErrors?.individual_track_configuration?.audio_tracks[audioTrackIndex]?.encoding_mode_or_layout) {
                        delete submitErrors.individual_track_configuration.audio_tracks[audioTrackIndex].encoding_mode_or_layout;
                        form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));
                    }
                }}
            </OnChange>

            <LoudnessControlForm
                values={values}
                submitError={submitError}
                onFieldChange={onFieldChange}
                audioTrackIndex={audioTrackIndex}
            />
        </>
    );
};

export default AudioTrackForm;
