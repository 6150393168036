import { Box, Stack } from "@mui/material";
import { SliderField, TextField } from "components/Forms";
import { observer } from "mobx-react-lite";
import React from "react";
import OnChange from "components/FinalFormListener/OnChange";
import { FormApi } from "final-form";

interface ILogoOverlayProps {
    values: any;
    form: FormApi<any, Partial<any>>;
}

const LogoOverlay: React.FC<ILogoOverlayProps> = ({ values, form }) => {
    return (
        <>
            <TextField
                name="overlay_logo.url"
                type="text"
                label="URL"
                validate={(value: any) => {
                    if (value) {
                        const urlRegex = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;
                        const pngRegex = /\.png$/;
                        if (!urlRegex.test(value)) {
                            return "Invalid URL";
                        }
                        if (!pngRegex.test(value)) {
                            return "URL must end with .png";
                        }
                    }
                    return undefined;
                }}
            />
            <OnChange name="overlay_logo.url">
                {(value) => {
                    if (!value) {
                        form.change("overlay_logo.top", undefined);
                        form.change("overlay_logo.left", undefined);
                        form.change("overlay_logo.size", undefined);
                    }
                }}
            </OnChange>

            {values?.overlay_logo?.url && (
                <>
                    <Stack direction="row" spacing={2} marginBottom={2} marginTop={1}>
                        <SliderField
                            name="overlay_logo.top"
                            label="Top"
                            min={0}
                            max={1000}
                            step={10}
                            initialValue={values?.overlay_logo?.top ? values.overlay_logo.top : 80}
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value: any) => {
                                if (!value && value !== 0) {
                                    return "Required";
                                }
                            }}
                        />

                        <SliderField
                            name="overlay_logo.left"
                            label="Left"
                            min={0}
                            max={1000}
                            step={10}
                            initialValue={values?.overlay_logo?.left ? values.overlay_image_left : 850}
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value: any) => {
                                if (!value && value !== 0) {
                                    return "Required";
                                }
                            }}
                        />

                        <SliderField
                            name="overlay_logo.size"
                            label="Size"
                            min={0}
                            max={1000}
                            step={10}
                            initialValue={values?.overlay_logo?.size ? values.overlay_image_size : 100}
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value: any) => {
                                if (!value && value !== 0) {
                                    return "Required";
                                }
                            }}
                        />
                    </Stack>
                    <Box
                        sx={{
                            paddingBottom: "56.25%",
                            position: "relative",
                            backgroundColor: "rgba(160,160,160,0.2)",
                            borderRadius: "4px",
                            overflow: "hidden"
                        }}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: `${values.overlay_logo.top / 10}%`,
                                left: `${values.overlay_logo.left / 10}%`,
                                width: `${values.overlay_logo.size / 10}%`,
                                border: "1px dashed rgba(255, 255, 255, 0.5)"
                            }}>
                            <Box
                                component={"img"}
                                src={values?.overlay_logo?.url}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                    objectPosition: "center"
                                }}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

export default observer(LogoOverlay);
