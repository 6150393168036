/* eslint-disable mobx/missing-observer */
import { Backdrop, Box, CircularProgress, Paper, Typography, useTheme } from "@mui/material";
import React from "react";

type ContentProps = {
    children?: React.ReactNode;
    leftContent?: React.ReactNode;
    /** Default: 280px */
    leftWidth?: number;
    rightContent?: React.ReactNode;
    /** Default: 280px */
    rightWidth?: number;
    headerContent?: React.ReactNode;
    footerContent?: React.ReactNode;
    /** Removes 20px padding from the main content div */
    noPadding?: boolean;
    paddingTop?: string;
    overflowX?: "auto" | "hidden" | "scroll";
    toolbarContent?: React.ReactNode;
    loading?: boolean;
    loadingText?: string;
    onLoadingClick?: () => void;
    content_id?: string;
};

/** The Content component contains a configurable layout with default styles applied */
const Content: React.FC<ContentProps> = ({
    children,
    leftContent,
    leftWidth,
    rightContent,
    rightWidth,
    headerContent,
    footerContent,
    noPadding,
    paddingTop,
    overflowX,
    toolbarContent,
    loading,
    loadingText,
    onLoadingClick,
    content_id
}) => {
    const theme = useTheme();

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    width: "100%",
                    height: "100%"
                }}>
                {toolbarContent && (
                    <Paper
                        //elevation={0}
                        square
                        sx={{
                            flex: "0 0 60px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            backgroundImage: "none",
                            backgroundColor: theme.palette.contentToolbar?.main || "rgba(0, 0, 0, 0.2)",
                            padding: "0 20px",
                            minHeight: "60px",
                            height: "60px",
                            zIndex: 1,
                            //color: "rgba(255, 255, 255, 0.6)"
                        }}>
                        {toolbarContent}
                    </Paper>
                )}
                <div
                    style={{
                        flex: "1 1 auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                        //color: "#fff",
                        width: "100%",
                        height: toolbarContent ? "calc(100% - 60px)" : "100%"
                    }}>
                    {leftContent && (
                        <div
                            style={{
                                width: leftWidth ? leftWidth : 280,
                                flex: leftWidth ? "0 0 " + leftWidth + "px" : "0 0 280px",
                                backgroundColor: theme.palette.contentSidebar?.main || "rgba(0, 0, 0, 0.2)",
                                overflowX: "hidden",
                                overflowY: "auto"
                            }}>
                            {leftContent}
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "stretch",
                            width: "100%",
                            overflowY: "auto"
                        }}>
                        {headerContent && (
                            <div style={{ backgroundColor: theme.palette.contentHeader?.main, padding: noPadding ? 0 : "6px 20px" }}>
                                {headerContent}
                            </div>
                        )}
                        <div
                            id={content_id || "content"}
                            style={{
                                flex: 1,
                                height: "100%",
                                padding: noPadding ? 0 : 20,
                                paddingTop: noPadding ? 0 : paddingTop || 20,
                                overflowX: overflowX ? overflowX : "hidden",
                                overflowY: "auto",
                                position: "relative"
                            }}>
                            {children}
                        </div>
                        {footerContent && (
                            <div
                                style={{
                                    position: "relative",
                                    backgroundColor: theme.palette.contentHeader?.main,
                                    padding: noPadding ? 0 : 20
                                }}>
                                {footerContent}
                            </div>
                        )}
                    </div>

                    {rightContent && (
                        <div
                            style={{
                                width: 280,
                                height: "100%",
                                maxHeight: "100%",
                                flex: rightWidth ? "0 0 " + rightWidth + "px" : "0 0 280px",
                                backgroundColor: theme.palette.contentSidebar?.main || "rgba(0, 0, 0, 0.2)",
                                overflowX: "hidden",
                                overflowY: "auto"
                            }}>
                            {rightContent}
                        </div>
                    )}
                </div>
            </div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex", flexDirection: "column" }}
                open={loading || false}
                onClick={onLoadingClick}>
                <CircularProgress color="primary" />
                {loadingText && <Typography sx={{ marginTop: 2 }}>{loadingText}</Typography>}
            </Backdrop>
        </>
    );
};

export default Content;
