import React from "react";
import * as transcodingOptions from "app/common/options/transcodingOptions";
import { RadioGroupField, SelectField, SliderField, TextField } from "components/Forms";
import { useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import OnChange from "components/FinalFormListener/OnChange";

const AudioTrack: React.FC = () => {
    const { values } = useFormState();

    return (
        <>
            {transcodingOptions.audioCodec && (
                <RadioGroupField
                    name="audio_codec"
                    label="Codec"
                    options={
                        transcodingOptions.audioCodec &&
                        transcodingOptions.audioCodec.map((option) => ({
                            label: option.text,
                            value: option.value
                        }))
                    }
                    row
                    required
                    helperText={(meta) => meta.touched && meta.error}
                    error={(meta) => meta.touched && meta.error}
                    validate={(value) => {
                        if (!value) {
                            return "Required";
                        }
                    }}
                />
            )}
            {values?.audio_codec === "MPEG AAC" && (
                <>
                    {transcodingOptions.audioSampleRateMpegAac && (
                        <SelectField
                            name="audio_sample_rate"
                            label="Sample Rate"
                            options={transcodingOptions.audioSampleRateMpegAac}
                            required
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />
                    )}
                    {transcodingOptions.audioBitrateMpegAac && (
                        <SelectField
                            name="audio_bitrate"
                            label="Bitrate"
                            options={transcodingOptions.audioBitrateMpegAac}
                            required
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />
                    )}
                </>
            )}
            {values?.audio_codec === "MPEG Layer2" && (
                <>
                    {transcodingOptions.audioSampleRateMpegLayerTwo && (
                        <SelectField
                            name="audio_sample_rate"
                            label="Sample Rate"
                            options={transcodingOptions.audioSampleRateMpegLayerTwo}
                            required
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />
                    )}
                    {transcodingOptions.audioBitrateMpegLayerTwo && (
                        <SelectField
                            name="audio_bitrate"
                            label="Bitrate"
                            options={transcodingOptions.audioBitrateMpegLayerTwo}
                            required
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />
                    )}
                </>
            )}
            <SliderField
                name="number_of_audio_tracks"
                label="Number of Audio Tracks Required"
                min={1}
                max={20}
                step={1}
                initialValue={values?.number_of_audio_tracks ? values?.number_of_audio_tracks : 1}
                helperText={(meta) => meta.touched && meta.error}
                error={(meta) => meta.touched && meta.error}
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}
            />
            <OnChange name="number_of_audio_tracks">
                {(value) => {
                    if (value) {
                        //set the audio_pid_overrides to an array of 0's but don't lose the existing values
                        //we must account for the array getting smaller as well as larger

                        //get the existing values
                        let existingValues = values?.audio_pid_overrides;

                        //if there are existing values
                        if (existingValues) {
                            //if the existing values are longer than the new value
                            if (existingValues.length > value) {
                                //remove the extra values
                                existingValues = existingValues.slice(0, value);
                            }
                            //if the existing values are shorter than the new value
                            else if (existingValues.length < value) {
                                //add new values
                                existingValues = [...existingValues, ...Array.from(Array(value - existingValues.length), () => 0)];
                            }
                        }
                        //if there are no existing values
                        else {
                            //create an array of 0's
                            existingValues = Array.from(Array(value), () => 0);
                        }

                        //set the values
                        values.audio_pid_overrides = existingValues;





                        // values.audio_pid_overrides = Array.from(Array(value), () => 0);
                    }
                }}
            </OnChange>

            <FieldArray
                name="audio_pid_overrides"
                initialValue={
                    //add a new field for each number_of_audio_tracks
                    values?.number_of_audio_tracks ? Array.from(Array(values?.number_of_audio_tracks), () => 0) : []
                }>
                {({ fields }) =>
                    fields.map((name, index) => (
                        <div key={name}>
                            <TextField
                                name={`${name}`}
                                label={`Audio PID Override ${index + 1}`}
                                initialValue={values?.audio_pid_overrides[index]}
                                required
                                type="number"
                                parse
                                helperText={(meta) => meta.touched && meta.error}
                                error={(meta) => meta.touched && meta.error}
                                validate={(value) => {
                                    if (!value && value !== 0) {
                                        return "Required";
                                    }
                                }}
                            />
                        </div>
                    ))
                }
            </FieldArray>
        </>
    );
};

export default AudioTrack;
