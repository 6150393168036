import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, List, ListItem, ListItemButton, Stack } from "@mui/material";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { RootStoreContext } from "api";
import AddDestinationOrganisation from "./AddOrganisationtoOrganisation/AddDestinationOrganisation";

const SourcePermissions = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;
    const { user } = rootStore.userStore;

    return (
        <View>
            <ViewHeader title="Source Permissions" />
            <ViewBody noPadding>
                <Content
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/settings/sourcepermissions"}
                                    onClick={() => {
                                        navigate("");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>My Content Permissions</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/settings/sourcepermissions/entitlements"}
                                    onClick={() => {
                                        navigate("entitlements");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>My Entitlements</Box>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    headerContent={
                        <Stack spacing={1} flexDirection="row" alignItems="center" justifyContent="flex-end" sx={{ py: 1, px: 3 }}>
                            <Button
                                variant="contained"
                                disabled={!user?.is_member_of_active_org}
                                onClick={() => openDialog(<AddDestinationOrganisation />)}>
                                Invite Organisation
                            </Button>
                        </Stack>
                    }
                    noPadding>
                    <Outlet />
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(SourcePermissions);
