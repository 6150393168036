import { useTheme } from "@mui/material";



// export const ChartPalette = [
//     theme.palette.primary.main, 
//     "#36b3a8", "#307abb", "#173a59", "#1e2832"
// ];


export const ChartPalette = () => {
    const theme = useTheme();
    return [
        theme.palette.primary.main, 
        theme.palette.secondary.main,
        theme.palette.tertiary?.main || "#307abb", 
        theme.palette.quaternary?.main || "#173a59",
        theme.palette.quinary?.main || "#1e2832",
        theme.palette.senary?.main || "#36b3a8",
        theme.palette.septenary?.main || "#c92d39",
    ];
}

export default ChartPalette;