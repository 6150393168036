import { AccountBox, Verified, Lock, DisplaySettings } from "@mui/icons-material";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import { RootStoreContext } from "api";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import RoleProvider from "components/RoleProvider";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UserAvatar from "./Components/UserAvatar";

const UserSettings: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    return (
        <View>
            <ViewHeader title="User Settings" />
            <ViewBody noPadding>
                <Content
                    headerContent={
                        <Stack direction="row" alignItems="center" spacing={2} py={3} px={3}>
                            <UserAvatar size={80} />
                            <Stack spacing={0}>
                                <Typography variant="h5">
                                    {user?.first_name} {user?.last_name}
                                </Typography>

                                <Typography variant="body1" color="text.secondary">
                                    {user?.email}
                                    {user?.email_verified && (
                                        <Tooltip title="Email verified" placement="top">
                                            <Verified sx={{ fontSize: 12, mb: -0.3, ml: 0.5, color: theme.palette.primary.main }} />
                                        </Tooltip>
                                    )}
                                </Typography>
                                <Typography variant="body1" color="text.secondary" textTransform="capitalize">
                                    {user?.managing_org_permission}
                                    <RoleProvider roles={["is_accounts_admin"]}>
                                        <>&nbsp;|&nbsp;Account Admin</>
                                    </RoleProvider>
                                    <RoleProvider roles={["is_superuser"]}>
                                        <>&nbsp;|&nbsp;Superuser</>
                                    </RoleProvider>
                                    <RoleProvider roles={["is_tester"]}>
                                        <>&nbsp;|&nbsp;Tester</>
                                    </RoleProvider>
                                    <RoleProvider roles={["is_dev"]}>
                                        <>&nbsp;|&nbsp;Developer</>
                                    </RoleProvider>
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/user"}
                                    onClick={() => {
                                        navigate("");
                                    }}>
                                    <AccountBox sx={{ mr: 2 }} />
                                    <ListItemText primary="Profile" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/user/security"}
                                    onClick={() => {
                                        navigate("security");
                                    }}>
                                    <Lock sx={{ mr: 2 }} />
                                    <ListItemText primary="Security" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/user/preferences"}
                                    onClick={() => {
                                        navigate("preferences");
                                    }}>
                                    <DisplaySettings sx={{ mr: 2 }} />
                                    <ListItemText primary="Preferences" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    noPadding>
                    <Outlet />
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(UserSettings);
