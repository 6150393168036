import React from "react";

import Content from "components/Layout/Content";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid,
    InputAdornment,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import { agent, IPricing } from "api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import SearchInput from "components/SearchInput";

export const BillingPriceList: React.FC = () => {
    const client = process.env.REACT_APP_CLIENT_NAME;
    const [prices, setPrices] = React.useState<IPricing>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = React.useState<string>(client === "ateme" ? "aws" : "");
    const [sectionFilter, setSectionFilter] = React.useState<string>("");

    const getPricing = async () => {
        setLoading(true);
        agent.Billing.getBillingPrices()
            .then((response) => {
                setPrices(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const renameCloudProvider = (provider?: string) => {
        switch (provider) {
            case "aws":
                return "Amazon Web Services";
            case "do":
                return "Digital Ocean";
            case "gcp":
                return "Google Cloud Platform";
            default:
                return "All Providers";
        }
    };

    React.useEffect(() => {
        getPricing();
    }, []);

    return (
        <Content
            noPadding
            toolbarContent={
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <SearchInput
                        value={sectionFilter}
                        onChange={(value) => setSectionFilter(value)}
                        onClear={() => setSectionFilter("")}
                        label="Search"
                        name="search"
                    />
                    {client !== "ateme" && (
                        <TextField
                            variant="outlined"
                            sx={{ width: 200 }}
                            size="small"
                            select
                            label="Cloud Provider"
                            value={selectedProvider}
                            onChange={(e) => setSelectedProvider(e.target.value)}>
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="aws">Amazon Web Services</MenuItem>
                            <MenuItem value="do">Digital Ocean</MenuItem>
                            <MenuItem value="gcp">Google Cloud Platform</MenuItem>
                        </TextField>
                    )}
                </Stack>
            }
            headerContent={
                <Box p={2}>
                    <Typography variant="h4" component="h1">
                        Pricing on {selectedProvider ? renameCloudProvider(selectedProvider) : "All Providers"}
                    </Typography>
                </Box>
            }
            loading={loading}>
            <Grid container spacing={2}>
                {prices &&
                    prices?.prices
                        .filter((price) => (sectionFilter ? price.section_title.toLowerCase().includes(sectionFilter.toLowerCase()) : true))
                        .map((price, i: any) => {
                            return (
                                <Grid item key={i} xs={12} xl={12}>
                                    <Box>
                                        {/* <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant="h6">{price.section_title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails> */}
                                        <Table sx={{ mb: 4 }} stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            backgroundColor: (theme) => theme.palette.background.paper
                                                        }}>
                                                        <Typography variant="h6">{price.section_title}</Typography>
                                                        {price.size_column_title}
                                                    </TableCell>
                                                    {price.cloud_providers
                                                        .filter((cp) =>
                                                            selectedProvider ? cp.cloud_provider_code === selectedProvider : true
                                                        )
                                                        .map((hd, i) => {
                                                            return (
                                                                <TableCell
                                                                    key={i}
                                                                    align="right"
                                                                    sx={{
                                                                        backgroundColor: (theme) => theme.palette.background.paper
                                                                    }}>
                                                                    <Box>
                                                                        {hd.cloud_provider_code.toUpperCase()} {price.cost_type}
                                                                    </Box>
                                                                    {price.sub_title && (
                                                                        <Typography variant="body2" color="textSecondary">{price.sub_title}</Typography>
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {price.entries.map((ent, i) => {
                                                    return (
                                                        <TableRow key={i} hover>
                                                            <TableCell>{ent.size}</TableCell>
                                                            {ent.prices
                                                                .filter((price) =>
                                                                    selectedProvider ? price.cloud_provider_code === selectedProvider : true
                                                                )
                                                                .map((p, i) => {
                                                                    return (
                                                                        <TableCell key={i} align="right">
                                                                            {p.usd || "N/A"}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        {/* </AccordionDetails>
                                        </Accordion> */}
                                    </Box>
                                </Grid>
                            );
                        })}
            </Grid>
        </Content>
    );
};

export default BillingPriceList;
