import { Box, Card, CardContent, useTheme } from "@mui/material";
import { agent } from "api";
import InfoItem from "components/InfoItem";
import React, { useEffect } from "react";

interface Transcoder {
    id: string;
    name: string;
}
interface StatsProps {
    nea_live_uuid: string;
    transcoders: Transcoder[];
}

interface Channel {
    name: string;
    applied_saf: string;
    status: string;
    details: {
        buffers: {
            message: string;
        };
        input: {
            message: string;
        };
        error: boolean;
        stats: {
            "RTT mean": string;
            "RTT standard deviation": string;
            Retransmissions: string;
            Connections: string;
        };
    };
}

const Stats: React.FC<StatsProps> = ({ nea_live_uuid, transcoders }) => {
    const theme = useTheme();

    const [isFetching, setIsFetching] = React.useState(false);
    const [stats, setStats] = React.useState<Channel[]>([]);

    const getData = async () => {
        if (isFetching) {
            return; // Exit if a request is already in progress
        }

        setIsFetching(true); // Set the flag to indicate a request is in progress

        try {
            const response = await agent.NeaLive.getNeaLiveStats(nea_live_uuid);
            const transcoderMap = new Map();
            transcoders.forEach((transcoder) => {
                transcoderMap.set(transcoder.id, transcoder.name);
            });

            // Replace channel names using the map
            const channels = response.data.channels;
            channels.forEach((channel) => {
                if (transcoderMap.has(channel.name)) {
                    channel.name = transcoderMap.get(channel.name);
                }
            });

            setStats(channels);
            console.log(response);
        } catch (error) {
            console.log(error);
        } finally {
            setIsFetching(false); // Reset the flag when the request completes
        }
    };

    useEffect(() => {
        let interval;

        const startFetching = async () => {
            await getData();
            interval = setInterval(getData, 2000);
        };

        startFetching();

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Box>
            {stats && stats.length > 0 && (
                <Box
                    sx={{
                        width: "100%",
                        display: "grid",
                        //2 columns
                        gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
                        gap: 2
                    }}>
                    {stats.map((channel: Channel, index: number) => (
                        <Card
                            key={index}
                            sx={{
                                width: "100%",
                                border: "1px solid",
                                borderColor: theme.palette.divider
                            }}>
                            <CardContent>
                                <InfoItem title="Name" value={channel.name} />
                                <InfoItem title="Applied SAF" value={channel.applied_saf} />
                                <InfoItem title="Status" value={channel.status} />
                                <InfoItem title="Buffers" value={channel.details?.buffers?.message} />
                                <InfoItem title="Input" value={channel.details?.input?.message} />
                                <InfoItem title="Error" value={channel.details?.error ? "Yes" : "No"} />
                                <InfoItem title="RTT mean" value={channel.details?.stats?.["RTT mean"]} value_type="line-chart" />
                                <InfoItem
                                    title="RTT standard deviation"
                                    value={channel.details?.stats?.["RTT standard deviation"]}
                                    value_type="line-chart"
                                    line_chart_color="secondary"
                                />
                                <InfoItem title="Retransmissions" value={channel.details?.stats?.Retransmissions} />
                                <InfoItem title="Connections" value={channel.details?.stats?.Connections} />
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default Stats;
