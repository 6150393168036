import React from "react";
import { observer } from "mobx-react-lite";
import { RadioGroupField } from "components/Forms";
import { agent, RootStoreContext } from "api";
import { Box, Typography } from "@mui/material";
import SourceThumbnail from "features/sources/Components/SourceThumbnail";

interface SourceSelectProps {}

const SourceSelect: React.FC = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const rootStore = React.useContext(RootStoreContext);
    const { activeEnvironment } = rootStore.userStore;

    const [sources, setSources] = React.useState<any>([]);

    const getSources = async () => {
        await agent.Channel.getInputs(activeEnvironment!)
            .then((res) => {
                setSources(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        setLoading(true);
        activeEnvironment && getSources();
    }, [activeEnvironment]);

    return (
        <>
            {!loading && sources.data && (
                <>
                    <RadioGroupField
                        name="input_source_id"
                        label="Select Source"
                        hideLabel={true}
                        
                        options={sources.data
                            .filter((source: any) => {
                                return !(source.is_transcoded_input || source.is_multi_viewer);
                            })
                            .map((source: any) => {
                                return {
                                    label: (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "1rem",
                                                py: 1
                                            }}>
                                            <Box sx={{ width: 80 }}>
                                                <SourceThumbnail item_id={source.id} disableLoading />
                                            </Box>
                                            <Box>
                                                <Typography fontWeight={"bold"}>{source.display_name}</Typography>
                                                <Typography sx={{ textTransform: "capitalize" }} variant={"caption"}>
                                                    Type: {source.human_type}, Status: {source.status}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ),
                                    value: source.id
                                };
                            })}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        error={(meta) => meta.touched && meta.error}
                        helperText={(meta) => (meta.touched && meta.error ? meta.error : "")}
                    />
                </>
            )}
        </>
    );
};

export default observer(SourceSelect);
