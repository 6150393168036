import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    DialogActions,
    DialogContent,
    Fab,
    FormControlLabel,
    IconButton,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { agent, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { addHours, addMinutes, format } from "date-fns";
import { useSources } from "hooks";

interface IProps {
    start?: Date;
    end?: Date;
    source_id?: string;
    destination_id?: string;
}

interface IScheduleRow {
    id: string;
    startNow: boolean;
    start_date: Date;
    end_date: Date;
    source_id: string | undefined;
    destination_id: string | undefined;
    submitting: boolean;
    success: boolean;
    isValid: boolean;
    errorMessage: string | undefined;
    errors: any;
}

const CreateMultiScheduleForm: React.FC<IProps> = (props) => {
    const rootStore = React.useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const [sources, setSources] = React.useState<any>();
    const [destinations, setDestinations] = React.useState<any>();
    const { activeenvironment } = rootStore.userStore;
    const { getInputsDropdownList } = useSources();

    //Fetch Sources and Destinations --------------------------------
    const fetchSources = async (environment_id) => {
        await getInputsDropdownList(environment_id)
            .then((response) => {
                setSources(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const fetchDestinations = async (environment_id) => {
        await agent.Destination.getDestinationsList(environment_id)
            .then((response) => {
                const destinations = response.data.filter((destination) => destination.is_permanent_schedule === false);
                setDestinations(destinations);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    //--------------------------------------------------------------

    const [scheduleRows, setScheduleRows] = React.useState<IScheduleRow[]>([
        {
            id: new Date().getTime().toString(),
            startNow: false,
            start_date: props.start ? props.start : addMinutes(new Date(), 5),
            end_date: props.end ? props.end : addMinutes(addHours(new Date(), 1), 5),
            source_id: props.source_id ? props.source_id : undefined,
            destination_id: props.destination_id ? props.destination_id : undefined,
            submitting: false,
            success: false,
            isValid: false,
            errorMessage: undefined,
            errors: undefined
        }
    ]);

    //Add a function to loop through the scheduleRows and create a schedule for each one
    const createSchedules = async () => {
        for (let i = 0; i < scheduleRows.length; i++) {
            const row = scheduleRows[i];
            if (!row.success) {
                //
                setScheduleRows((prevRows) => {
                    const newRows = [...prevRows];
                    newRows[i].submitting = true;
                    return newRows;
                });
                try {
                    await agent.Scheduler.createSchedule({
                        source_id: row.source_id,
                        destination_id: row.destination_id,
                        start_date: row.startNow ? "NOW" : row.start_date.toISOString(),
                        end_date: row.end_date.toISOString(),
                        start_time: row.startNow ? "NOW" : row.start_date.toISOString(),
                        end_time: row.end_date.toISOString()
                    });
                    setScheduleRows((prevRows) => {
                        const newRows = [...prevRows];
                        newRows[i].success = true;
                        newRows[i].submitting = false;
                        newRows[i].isValid = true;
                        newRows[i].errorMessage = undefined;
                        newRows[i].errors = undefined;
                        return newRows;
                    });
                } catch (error: any) {
                    console.log(error);
                    setScheduleRows((prevRows) => {
                        const newRows = [...prevRows];
                        newRows[i].errorMessage = error.data.message;
                        newRows[i].errors = error.data.errors;
                        newRows[i].submitting = false;
                        newRows[i].isValid = false;
                        return newRows;
                    });
                }
                await new Promise((resolve) => setTimeout(resolve, 500));
            }
        }
    };

    // Validate the schedule row to ensure all fields are filled in
    const validateScheduleRow = (row: IScheduleRow) => {
        if (!row.source_id || !row.destination_id) {
            return false;
        }
        return true;
    };

    React.useEffect(() => {
        let isMounted = true;

        isMounted && fetchSources(activeenvironment);
        // Load destinations for dropdown
        isMounted && fetchDestinations(activeenvironment);

        return () => {
            isMounted = false;
        };
    }, [activeenvironment]);

    return (
        <>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box>
                        {scheduleRows.map((row) => (
                            <Card
                                key={row.id}
                                sx={{
                                    marginBottom: 1
                                    //backgroundColor: (theme) => (row.errors ? theme.palette.error.dark : undefined)
                                }}>
                                <CardContent>
                                    <Stack direction="row" alignItems="flex-end" spacing={4}>
                                        <FormControlLabel
                                            sx={{ whiteSpace: "nowrap" }}
                                            control={
                                                <Switch
                                                    disabled={row.submitting || row.success}
                                                    onChange={(event) => {
                                                        setScheduleRows((prev) => {
                                                            return prev.map((r) => {
                                                                if (r.id === row.id) {
                                                                    return {
                                                                        ...r,
                                                                        startNow: event.target.checked
                                                                    };
                                                                }
                                                                return r;
                                                            });
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Start Now"
                                        />
                                        <DateTimePicker
                                            slotProps={{
                                                textField: {
                                                    error: row.errors && row.errors?.start_time ? true : false,
                                                    helperText: row.errors && row.errors?.start_time,
                                                    required: true,
                                                    sx: { width: 165 }
                                                }
                                            }}
                                            sx={{ width: 165 }}
                                            label="Start"
                                            value={row.start_date}
                                            format="dd/MM/yyyy HH:mm:ss"
                                            minDateTime={new Date()}
                                            disabled={row.startNow || row.submitting || row.success}
                                            timeSteps={{ minutes: 1, seconds: 1 }}
                                            views={["hours", "minutes", "seconds"]}
                                            onChange={(newValue) => {
                                                setScheduleRows((prev) => {
                                                    return prev.map((r) => {
                                                        if (r.id === row.id) {
                                                            return {
                                                                ...r,
                                                                start_date: newValue || new Date() // set default value to new Date() if newValue is null
                                                            };
                                                        }
                                                        return r;
                                                    });
                                                });
                                            }}
                                        />
                                        <DateTimePicker
                                            slotProps={{
                                                textField: {
                                                    error: row.errors && row.errors?.end_time ? true : false,
                                                    helperText: row.errors && row.errors?.end_time,
                                                    required: true,
                                                    sx: { width: 165 }
                                                }
                                            }}
                                            sx={{ width: 165 }}
                                            label="End"
                                            value={row.end_date}
                                            format="dd/MM/yyyy HH:mm:ss"
                                            minDateTime={new Date()}
                                            disabled={row.submitting || row.success}
                                            timeSteps={{ minutes: 1 }}
                                            onChange={(newValue) => {
                                                setScheduleRows((prev) => {
                                                    return prev.map((r) => {
                                                        if (r.id === row.id) {
                                                            return {
                                                                ...r,
                                                                end_date: newValue || new Date() // set default value to new Date() if newValue is null
                                                            };
                                                        }
                                                        return r;
                                                    });
                                                });
                                            }}
                                        />
                                        {sources && (
                                            <TextField
                                                variant="standard"
                                                name="source"
                                                id="source"
                                                label="Source"
                                                sx={{ width: 200, marginBottom: "10px" }}
                                                onChange={(e) => {
                                                    setScheduleRows((prev) => {
                                                        return prev.map((r) => {
                                                            if (r.id === row.id) {
                                                                return {
                                                                    ...r,
                                                                    source_id: e.target.value
                                                                };
                                                            }
                                                            return r;
                                                        });
                                                    });
                                                }}
                                                value={row.source_id ? row.source_id : ""}
                                                required
                                                disabled={row.submitting || row.success}
                                                select>
                                                {sources.map((source: any) => (
                                                    <MenuItem key={source.id} value={source.id}>
                                                        {source.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        {destinations && (
                                            <TextField
                                                variant="standard"
                                                name="destination"
                                                id="destination"
                                                label="Destination"
                                                sx={{ width: 200, marginBottom: "10px" }}
                                                onChange={(e) => {
                                                    setScheduleRows((prev) => {
                                                        return prev.map((r) => {
                                                            if (r.id === row.id) {
                                                                return {
                                                                    ...r,
                                                                    destination_id: e.target.value
                                                                };
                                                            }
                                                            return r;
                                                        });
                                                    });
                                                }}
                                                value={row.destination_id}
                                                required
                                                disabled={row.submitting || row.success}
                                                select>
                                                {destinations.map((destination: any, index: number) => (
                                                    <MenuItem key={index} value={destination.id}>
                                                        {destination.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        <IconButton
                                            disabled={row.submitting || row.success}
                                            onClick={() => {
                                                // Remove the current row
                                                const newScheduleRows = scheduleRows.filter((r) => r.id !== row.id);
                                                setScheduleRows(newScheduleRows);
                                            }}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={row.submitting || row.success}
                                            onClick={() => {
                                                // Copy the current row
                                                const newScheduleRows = [...scheduleRows];
                                                const newRow = { ...row, id: new Date().getTime().toString() };
                                                newScheduleRows.push(newRow);
                                                setScheduleRows(newScheduleRows);
                                            }}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Stack>
                                    {row.errorMessage && (
                                        <Alert severity="error" sx={{ marginTop: 2 }}>
                                            <AlertTitle>{row.errorMessage}</AlertTitle>
                                            {row.errors?.conflicting_schedules && (
                                                <>
                                                    <Typography fontWeight={"bold"}>Conflicting Schedules Detected:</Typography>
                                                    {row.errors?.conflicting_schedules.map((schedule: any) => (
                                                        <Typography key={schedule.id}>
                                                            {format(new Date(schedule.start_time), "dd/MM/yyyy HH:mm:ss")} -{" "}
                                                            {format(new Date(schedule.end_time), "dd/MM/yyyy HH:mm:ss")}
                                                        </Typography>
                                                    ))}
                                                </>
                                            )}
                                        </Alert>
                                    )}
                                    {row.success && (
                                        <Alert severity="success" sx={{ marginTop: 2 }}>
                                            <AlertTitle>Schedule Created Successfully</AlertTitle>
                                        </Alert>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </LocalizationProvider>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: 2 }}>
                    <Fab
                        color="primary"
                        aria-label="add"
                        size="medium"
                        onClick={() => {
                            // Add a new row
                            setScheduleRows([
                                ...scheduleRows,
                                {
                                    id: new Date().getTime().toString(),
                                    startNow: false,
                                    start_date: props.start ? props.start : addMinutes(new Date(), 5),
                                    end_date: props.end ? props.end : addMinutes(addHours(new Date(), 1), 5),
                                    source_id: props.source_id ? props.source_id : undefined,
                                    destination_id: props.destination_id ? props.destination_id : undefined,
                                    submitting: false,
                                    success: false,
                                    isValid: false,
                                    errorMessage: undefined,
                                    errors: undefined
                                }
                            ]);
                        }}>
                        <AddIcon />
                    </Fab>
                </Box>
            </DialogContent>
            <DialogActions>
                {!scheduleRows.every((row) => row.success) && (
                    <>
                        <Button
                            disabled={scheduleRows.some((row) => row.submitting)}
                            onClick={() => {
                                closeDialog();
                            }}>
                            Cancel
                        </Button>

                        <Button
                            onClick={() => {
                                createSchedules();
                            }}
                            disabled={scheduleRows.some((row) => !validateScheduleRow(row))}
                            variant="contained"
                            color="primary">
                            Create Schedule(s)
                        </Button>
                    </>
                )}
                {scheduleRows.every((row) => row.success) && (
                    <Button
                        onClick={() => {
                            closeDialog();
                        }}
                        disabled={scheduleRows.some((row) => !validateScheduleRow(row))}
                        variant="contained"
                        color="primary">
                        Finished
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default observer(CreateMultiScheduleForm);
