import { useContext, useCallback } from "react";
import { agent, IOutputFormValues, IOutput, IResModels, IResModel, RootStoreContext } from "api";

export const useDestinations = () => {
    const rootStore = useContext(RootStoreContext);
    const { activeenvironment } = rootStore.userStore;
    const { setOutput, setEditOutput, setLoading, setSetId, setStarting, setStopping, setDestination, setDestinationDeleted } =
        rootStore.destinationStore;

    const getDestinationsState = useCallback(async () => {
        return await agent.Destination.getDestinationsState();
    }, []);

    const createOutput = useCallback(async (values: IOutputFormValues) => {
        try {
            await agent.Destination.createOutput(values);
        } catch (error) {
            throw error;
        }
    }, []);

    const loadOutputsClean = useCallback(async () => {
        const outputs = await agent.Destination.getOutputs();
        return outputs;
    }, []);

    const deleteDestination = useCallback(async (id: string) => {
        try {
            await agent.Destination.deleteDestination(id);

            setDestinationDeleted(true);
        } catch (error) {
            throw error;
        }
    }, []);

    const getCurrentDestinationDetails = useCallback(async (id: string) => {
        try {
            const destination = await agent.Destination.getIndividualDestination(id);

            setDestination(destination);
        } catch (error) {
            throw error;
        }
    }, []);

    const startDestination = useCallback(async (id: string) => {
        try {
            await agent.Destination.startOutput(id);

            setSetId(id);
            setStarting(true);
        } catch (error) {
            throw error;
        }
    }, []);

    const stopDestination = useCallback(async (id: string) => {
        try {
            await agent.Destination.stopOutput(id);

            setSetId(id);
            setStopping(true);
        } catch (error) {
            throw error;
        }
    }, []);

    const getOutputLiveStats = useCallback(async (id: string) => {
        const liveStats = await agent.Destination.getDestinationLiveStats(id);

        setLoading(true);

        if (liveStats) {
            setLoading(false);

            return liveStats;
        }
    }, []);

    const loadOutput = useCallback(async (id: string) => {
        const output = await agent.Destination.getIndividualOutput(id);
        const outputData = output.data;

        if (outputData) {
            if (output) {
                setEditOutput(outputData);
            }
            if (outputData.technical_details.livelink_args.password) {
                outputData.hasPassword = true;
            }
            if (outputData.technical_details.livelink_args.enc_type) {
                outputData.hasEncryption = true;
            }
        }

        if (output) {
            return outputData;
        } else {
            return null;
        }
    }, []);

    const editDestination = useCallback(async (output: IOutputFormValues) => {
        try {
            if (output.type === "Zixi Push") {
                output.type = "push";
            }
            if (output.type === "Zixi Pull") {
                output.type = "pull";
            }
            if (output.type === "RIST") {
                output.type = "udp-rist";
            }
            if (output.type === "rist") {
                output.type = "udp-rist";
            }
            if (output.type === "SRT Listener") {
                output.type = "srt-pull";
            }
            if (output.type === "SRT Caller") {
                output.type = "srt-push";
            }
            if (output.type === "RTMP") {
                output.type = "rtmp";
            }

            const response = await agent.Destination.editDestination(output);

            if (response) {
                setOutput(output);
            }

            return response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }, []);

    const loadOutputs = useCallback(async (destinations?: IResModels | null, filtered?: boolean) => {
        // Strange, but that's what the original method did. See the commented out stuff below.
        if (destinations) {
            return destinations;
        }

        try {
            const destList = await agent.Destination.getOutputs();
            let returnList: IResModels = { _list: [] };

            for (let i = 0; i < destList.data.length; i++) {
                const oldDestEntry: IOutput = destList.data[i];

                if (filtered && oldDestEntry.environment_id !== activeenvironment) {
                    // excluded the destination not on active env
                    continue;
                }

                const newDestEntry: IResModel = {
                    connection_status: "dont-have-yet",
                    description: oldDestEntry.description,
                    destination_id: oldDestEntry.id,
                    environment_id: oldDestEntry.environment_id,
                    environment_human_name: oldDestEntry.environment_human_name,
                    is_permanently_scheduled: oldDestEntry.is_permanently_scheduled,
                    name: oldDestEntry.display_name,
                    permanent_schedule_input_id: oldDestEntry.permanent_schedule_input_id,
                    permanent_schedule_input_name: oldDestEntry.permanent_schedule_input_name,
                    schedules: [],
                    showingContent: false,
                    isActive: oldDestEntry.status === "active",
                    type: oldDestEntry.type,
                    rid: "dont-have-one-here"
                };
                returnList._list.push(newDestEntry);
            }

            return returnList;
        } catch (error) {
            console.log("Failed getting destinations in destinationStore");
            throw error;
        }
    }, []);

    return {
        getDestinationsState,
        createOutput,
        loadOutputsClean,
        deleteDestination,
        getCurrentDestinationDetails,
        startDestination,
        stopDestination,
        getOutputLiveStats,
        loadOutput,
        editDestination,
        loadOutputs
    };
};

export default useDestinations;
