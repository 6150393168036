import React, { useEffect, useRef, useState } from "react";
import * as dashjs from "dashjs";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import InfoItem from "components/InfoItem";

interface DashVideoPlayerProps {
    src: string; // URL of the DASH video stream
    name: string; // Name of the video
}

interface LastRequest {
    type: string;
    responsecode: number;
    trequest: Date;
    tresponse: Date;
    trace: Array<{ b: number[] }>;
}

const DashVideoPlayer: React.FC<DashVideoPlayerProps> = ({ src, name }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef<dashjs.MediaPlayerClass | null>(null); // Use a ref for the player
    const [bitrateList, setBitrateList] = useState<Array<{ bitrate: number; index: number }>>([]);
    const [audioTracks, setAudioTracks] = useState<Array<dashjs.MediaInfo>>([]);
    const [currentQualityIndex, setCurrentQualityIndex] = useState<number>(0);
    const [currentAudioTrackIndex, setCurrentAudioTrackIndex] = useState<number>(0);
    const [downloadSpeed, setDownloadSpeed] = useState<number>(0); // in kbps

    useEffect(() => {
        const playerInstance = dashjs.MediaPlayer().create();
        playerInstance.initialize(videoRef.current!, src, true);
        playerRef.current = playerInstance; // Store the player instance in the ref

        playerInstance.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, () => {
            const videoQualities = playerInstance.getBitrateInfoListFor("video");
            const videoStreams = playerInstance.getTracksFor("video");
            console.log("Streams", videoStreams);
            setBitrateList(videoQualities.map((q, index) => ({ bitrate: q.bitrate, index })));

            //setCurrentQualityIndex to the current quality index
            const currentQuality = playerInstance.getQualityFor("video");
            setCurrentQualityIndex(currentQuality);

            const audioTracks = playerInstance.getTracksFor("audio");
            setAudioTracks(audioTracks);
        });

        playerInstance.on(dashjs.MediaPlayer.events.METRIC_UPDATED, () => {
            const metrics = playerInstance.getDashMetrics();
            const lastRequest: any = metrics.getCurrentHttpRequest("video");

            if (lastRequest) {
                console.log("Last Request", lastRequest);
            }

            if (lastRequest && lastRequest.type === "MediaSegment" && lastRequest.responsecode >= 200 && lastRequest.responsecode < 300) {
                const duration = (lastRequest.tresponse.getTime() - lastRequest.trequest.getTime()) / 1000; // seconds
                const size = lastRequest.trace.reduce((acc, cur) => acc + cur.b[0], 0) / 1024; // KB
                if (duration > 0) {
                    const speed = size / duration; // KB/s
                    setDownloadSpeed(size); // Convert to kbps
                }
            }
        });

        return () => {
            if (videoRef.current) {
                videoRef.current.removeAttribute("src");
            }
            if (playerRef.current) {
                playerRef.current.setAutoPlay(false);
                playerRef.current.destroy();
                playerRef.current = null; // Clear the ref
            }
        };
    }, [src]);

    const handleChangeQuality = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newIndex = event.target.value as number;
        if (playerRef.current) {
            playerRef.current.setQualityFor("video", newIndex, true);
            setCurrentQualityIndex(newIndex);
        }
    };

    const handleChangeAudioTrack = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newIndex = event.target.value as number;
        if (playerRef.current) {
            playerRef.current.setQualityFor("audio", newIndex);
            setCurrentAudioTrackIndex(newIndex);
        }
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", mb: 3 }}>
            <Box sx={{ width: 360, flexShrink: 0, mr: 2, overflow: "hidden", borderRadius: 1 }}>
                <video ref={videoRef} id={new Date().toDateString()} controls style={{ width: "100%" }} muted />
            </Box>
            <Box
                sx={{
                    width: "100%",
                    flexGrow: 1
                }}>
                <Typography variant="body1" fontWeight="bold" mb={2}>
                    {name}
                </Typography>
                <TextField
                    label="Video Quality"
                    variant="outlined"
                    value={currentQualityIndex}
                    select
                    size="small"
                    onChange={handleChangeQuality}>
                    {bitrateList.map(({ bitrate, index }) => (
                        <MenuItem key={index} value={index}>
                            {Math.round(bitrate / 1000)} kbps
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Audio Track"
                    variant="outlined"
                    value={currentAudioTrackIndex}
                    select
                    size="small"
                    onChange={handleChangeAudioTrack}>
                    {audioTracks.map((track, index) => (
                        <MenuItem key={index} value={index}>
                            {track.lang} - {track.codec}
                        </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ mt: 1 }}>
                    <InfoItem title="URL" value={src} value_type="copy" />
                    <InfoItem title="Download Speed" value={downloadSpeed.toFixed(2) + " kbps"} />
                </Box>
            </Box>
        </Box>
    );
};

export default DashVideoPlayer;
