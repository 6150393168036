import React from "react";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import { Box, Button, FormControl, InputAdornment, MenuItem, TextField } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { RootStoreContext } from "api";
import { ResCollection } from "resclient";
import PackagerItem from "./../Components/PackagerItem";
import { Search } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import Content from "components/Layout/Content";
import { useNavigate } from "react-router-dom";

const PackagersList: React.FC = () => {
    const navigate = useNavigate();
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;
    const [wsData, setWsData] = React.useState<ResCollection | any>(null);
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [inputType, setInputType] = React.useState<string>("all");
    const [cloudProvider, setCloudProvider] = React.useState<string>("all");
    const [filterStatus, setFilterStatus] = React.useState<string>("all");

    React.useEffect(() => {
        clientConnected &&
            client.get(`pushpackagers.${rootStore.userStore.managingOrganisationId!}`).then((res) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                });
                setWsData(res);
                //console.log(res);
            });
    }, [client, rootStore.userStore.managingOrganisationId, clientConnected]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <Content
            toolbarContent={
                <Box
                    sx={{
                        flex: "1 1 auto",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        py: 1
                    }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FormControl sx={{ mr: 1, width: 200 }}>
                            <TextField
                                id="search"
                                label="Search"
                                variant="outlined"
                                size="small"
                                sx={{ mb: 0 }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>

                        <FormControl sx={{ mr: 1, width: 120 }}>
                            <TextField
                                id="status"
                                label="Status"
                                variant="outlined"
                                size="small"
                                sx={{ mb: 0 }}
                                select
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="running">Running</MenuItem>
                                <MenuItem value="stopped">Stopped</MenuItem>
                                <MenuItem value="creating">Creating</MenuItem>
                            </TextField>
                        </FormControl>

                        {/* another formcontrol containing a select with options "all", "direct", "environment" */}
                        <FormControl sx={{ mr: 1, width: 120 }}>
                            <TextField
                                id="input_type"
                                label="Source Type"
                                variant="outlined"
                                size="small"
                                sx={{ mb: 0 }}
                                select
                                value={inputType}
                                onChange={(e) => setInputType(e.target.value)}>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="direct">Direct</MenuItem>
                                <MenuItem value="environment">Environment</MenuItem>
                            </TextField>
                        </FormControl>

                        {/* Cloud Provider */}
                        <FormControl sx={{ width: 180 }}>
                            <TextField
                                id="cloud_provider"
                                label="Cloud Provider"
                                variant="outlined"
                                size="small"
                                sx={{ mb: 0 }}
                                select
                                value={cloudProvider}
                                onChange={(e) => setCloudProvider(e.target.value)}>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="aws">Amazon Web Services</MenuItem>
                                <MenuItem value="do">Digital Ocean</MenuItem>
                                <MenuItem value="gcp">Google Cloud Platform</MenuItem>
                            </TextField>
                        </FormControl>
                    </Box>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate("/packager/create")}
                            startIcon={<AddCircleIcon />}>
                            Create New Packager
                        </Button>
                    </div>
                </Box>
            }>
            {wsData &&
                Object.entries(wsData.toJSON())
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([key, value]: [string, any]) => (
                        <PackagerItem
                            rid={value.rid}
                            key={key}
                            display_search={searchTerm}
                            display_type={inputType}
                            display_cloud_provider={cloudProvider}
                            display_status={filterStatus}
                        />
                    ))}
        </Content>
    );
};

export default observer(PackagersList);
