import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { IOutputLiveStatistics, RootStoreContext, agent } from "api";
import { Box, Button, Grid } from "@mui/material";
import InfoItem from "components/InfoItem";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import { useDestinations } from "hooks";

interface IProps {
    destination_id: string;
}

const DestinationStatistics: React.FC<IProps> = ({ destination_id }) => {
    const rootStore = useContext(RootStoreContext);
    const { destinationDeleted } = rootStore.destinationStore;
    const { getOutputLiveStats } = useDestinations();
    const [outputLiveStats, setLiveStats] = useState<IOutputLiveStatistics | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const getInitialOutputLiveStats = async () => {
        getOutputLiveStats(destination_id).then((value) => {
            if (value) {
                setLiveStats(value);
            }
        });
    };

    const resetStats = async (id: string) => {
        setLoading(true);
        await agent.Destination.resetLiveStats(id)
            .then((res) => {
                console.log(res);
                getInitialOutputLiveStats();
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        let isMounted = true;

        if (!destinationDeleted) {
            getInitialOutputLiveStats();

            const perpetual = setInterval(() => {
                isMounted &&
                    getOutputLiveStats(destination_id).then((value) => {
                        if (value) {
                            setLiveStats(value);
                        }
                    });
            }, 3000);

            return () => {
                isMounted = false;
                clearInterval(perpetual);
            };
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Box>
            {outputLiveStats &&
                outputLiveStats.data.map((output, i) => {
                    if (output.label === "time") {
                        return (
                            <InfoItem
                                key={i}
                                title={output.label ? output.label : ""}
                                value={output.value ? format(new Date(output.value), "eeee do MMMM h:mm a") : ""}
                            />
                        );
                    }

                    if (output.label === "Bitrate (Kbps)") {
                        return (
                            <InfoItem
                                key={i}
                                title="Bitrate (Kbps)"
                                value={output.value}
                                value_type="line-chart"
                                line_chart_max_items={200}
                            />
                        );
                    }

                    if (output.label === "Packet Rate (packets per second)") {
                        return (
                            <InfoItem
                                title="Packet Rate (packets per second)"
                                value={output.value}
                                value_type="line-chart"
                                line_chart_max_items={200}
                                line_chart_color="secondary"
                                key={i}
                            />
                        );
                    }

                    return <InfoItem key={i} title={output.label || ""} value={output.value} />;
                })}

            <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12} sx={{ textAlign: "center", opacity: 1 }}>
                    <LoadingButton
                        loading={loading}
                        variant="toolbar"
                        onClick={() => resetStats(destination_id)}
                        startIcon={<RefreshIcon />}>
                        Reset Statistics
                    </LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(DestinationStatistics);
