/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from "google-map-react";
import "./styles.scss";
import RouteMarker from "./Components/RouteMarker";
import Panel from "./Components/Panel";
import RegionMarker from "./Components/RegionMarker";
import GroupMarker from "./Components/GroupMarker";
import SourceMarker from "./Components/SourceMarker";
import SourceItem from "./Components/SourceItem";
import RegionAccordion from "./Components/RegionAccordion";
import EnvironmentAccordion from "./Components/EnvironmentAccordion";
import ItemAccordion from "./Components/ItemAccordion";
import GroupAccordion from "./Components/GroupAccordion";
import { mapStyles } from "./MapStyles";
import ResClient, { ResModel, ResCollection } from "resclient";
import {
    agent,
    IMtrGroupsListResponse,
    IMTRReportHubEntry,
    IMTRReportResponse,
    IMtrReportsList,
    IMtrReportsListEntry,
    RootStoreContext
} from "api";
import MtrReportItem from "./Components/MtrReportItem";
import GenerateMtrReportForm from "./Components/GenerateMtrReportForm";
import { Button, DialogActions, DialogContent, Menu, MenuItem, TextField } from "@mui/material";
import GroupMarkerItem from "./Components/GroupMarkerItem";
import Draggable from "react-draggable";
import MtrGroupAccordion from "./Components/MtrGroupAccordion";
import CreateHostGroupForm from "./Components/CreateHostGroupForm";
import CreateHostForm from "./Components/CreateHostForm";
import EditHostGroupForm from "./Components/EditHostGroupForm";
import { NavLink } from "react-router-dom";
import ErrorMessages from "components/ErrorMessages";
import { Button as MuiButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ConfirmationDialog from "components/ConfirmationDialog";
import { TabContext, TabList } from "@mui/lab";
import { SmallTab, SmallTabList, TabPanel } from "components";
import {
    Add,
    InsertDriveFileOutlined,
    InfoOutlined,
    AccountTree
} from '@mui/icons-material';

export const Overview = observer(function Overview() {
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;

    //const { client, clientConnected } = resClientStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;

    const [mapApiLoaded, setMapApiLoaded] = useState<boolean>(false);
    const [mapInstance, setMapInstance] = useState<google.maps.Map>();

    const [regions, setRegions] = useState<ResModel | ResCollection | any>({});
    const [hiddenRegions, setHiddenRegions] = useState([-1]);
    const [hiddenEnvironments, setHiddenEnvironments] = useState([""]);
    const [hiddenItems, setHiddenItems] = useState([""]);
    const [hiddenGroups, setHiddenGroups] = useState([""]);
    const [groups, setGroups] = useState<ResModel | ResCollection | any>({});
    const [triggerState, setTriggerState] = useState("");

    const [showTraceReports, setShowTraceReports] = useState<boolean>(false);
    const [showCreateMtrGroup, setShowCreateMtrGroup] = useState<boolean>(false);
    const [showCreateHost, setShowCreateHost] = useState<boolean>(false);
    const [createHostGroupId, setCreateHostGroupId] = useState<number>(0);
    const [showCreateHostReport, setShowCreateHostReport] = useState<boolean>(false);
    const [createHostReportHostId, setCreateHostReportHostId] = useState<number>(0);

    const [activeItem, setActiveItem] = useState("");
    const [activeItemType, setActiveItemType] = useState("");
    const [activeGroup, setActiveGroup] = useState("");
    const [activeRegion, setActiveRegion] = useState(0);
    const [activeEnvironment, setActiveEnvironment] = useState("");

    const [mtrReports, setMtrReports] = useState<IMtrReportsList>();
    const [mtrReportGroups, setMtrReportGroups] = useState<IMtrGroupsListResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>("Loading...");
    const [focusedPanel, setFocusedPanel] = useState<string>("");
    const [tabValue, setTabValue] = React.useState<string>("1");
    const [innerTabValue, setInnerTabValue] = React.useState<string>("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleInnerTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setInnerTabValue(newValue);
    };

    //const [ showNotes, setShowNotes ] = useState<boolean>(false);
    const { setShowNotes, showNotes } = rootStore.overviewStore;
    const { setNotes, notes } = rootStore.overviewStore;

    //TEMP
    const [activeReport, setActiveReport] = useState<string>();
    const [report, setReport] = React.useState<IMTRReportResponse>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let client = React.useRef(new ResClient(process.env.REACT_APP_WEBSOCKET_URL));

    const handleNoEnvironments = () => {
        openDialog(
            <div
                style={{
                    padding: 20,
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 200
                }}>
                <h3>No Environments</h3>
                <p>You have no environments to display. Please create an environment to continue.</p>
                <NavLink to="/environments">
                    <Button variant="contained">Manage Environments</Button>
                </NavLink>
            </div>,
            undefined,
            "sm"
        );
    };

    const onUpdate = (items: any, message: any) => {
        if (rootStore.userStore.isLoggedIn) {
            getMtrReportGroups();
            setTriggerState(Math.random().toString());
            console.log("Update: ", message, items);
            setLoading(false);
        }
    };

    const getMtrReports = async () => {
        const items = await agent.Reporting.getMtrReportsList();
        setMtrReports(items);
    };

    const getMtrReportGroups = async () => {
        const items = await agent.Reporting.getMtrGroupsList();
        setMtrReportGroups(items);
    };

    const [deleteHostErrors, setDeleteHostErrors] = useState<any>();

    const handleDeleteHost = async (host_id: number) => {
        setLoading(true);
        setLoadingMessage("Deleting Endpoint...");
        await agent.Reporting.deleteMtrHost(host_id)
            .then(() => {
                onUpdate("", "Endpoint Deleted");
            })
            .catch((error) => {
                setDeleteHostErrors(
                    error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                );
                setLoading(false);
            });
    };

    useEffect(() => {
        let currentClient = client.current;

        rootStore.userStore.isLoggedIn &&
            client.current
                .authenticate("auth", "jwtHeader", { "jwt-token": rootStore.commonStore.token })
                .then(() => {
                    client.current
                        .get(`mtr.${managing_org_id}`)
                        .then((mtr: ResModel | ResCollection | any) => {
                            console.log("MTR Data: ", mtr);
                            mtr.on("", (event: any) => {
                                onUpdate(event, "MTR Root Event");
                                getMtrReports();
                                getMtrReportGroups();
                                const jsonObject = mtr.toJSON();

                                //console.log("MTR JSON: ", jsonObject, Object.keys(jsonObject).length);
                                Array.from(Object.values(jsonObject)).map((item: any) => {
                                    client.current
                                        .get(`mtr.${managing_org_id}.report.${item.id}`)
                                        .then((mtrItem: ResModel | ResCollection | any) => {
                                            //console.log("MTR Item: ", mtrItem);
                                            mtrItem.on("", (event: any) => {
                                                onUpdate(event, "MTR Item Event");
                                                getMtrReports();
                                                getMtrReportGroups();
                                            });
                                            //mtrItem.off("delete remove", (event: any) => console.log("Remove", event));
                                            return null;
                                        });
                                    return null;
                                });
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    client.current
                        .get(`dashboard_groups.${managing_org_id}`)
                        .then((groups: ResModel | ResCollection | any) => {
                            console.log("Groups Data: ", groups);
                            groups.on("", (event: any) => onUpdate(event, "Groups Root Event"));
                            //groups.off('delete remove', (event: any) => console.log("Remove", event))

                            Array.from(groups).map((group: ResModel | ResCollection | any) => {
                                group.on("", (event: any) => onUpdate(event, "Groups Item Event"));
                                //group.off("delete remove", (event: any) => console.log("Remove", event));
                                return null;
                            });

                            setGroups(groups);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    client.current
                        .get(`env_dashboard.${managing_org_id}`)
                        .then((resregions: ResModel | ResCollection | any) => {
                            console.log("Data: ", resregions);
                            resregions.on("", (event: any) => onUpdate(event, "Root Event"));

                            Array.from(resregions).length === 0
                                ? handleNoEnvironments()
                                : Array.from(resregions).map((resregion: ResModel | ResCollection | any) => {
                                      resregion.on("", (event: any) => console.log("Update:  Region Event", event));

                                      Array.from(resregion.environments).map((resenvironment: ResModel | ResCollection | any) => {
                                          resenvironment.items.on("", (event: any) => onUpdate(event, "Environment Event"));
                                          resenvironment.items.on("change add", () => onUpdate(resenvironment.items, "Change event")); //THIS WORKS
                                          //resenvironment.items.off("delete remove", (event: any) => console.log("Remove", event));

                                          Array.from(resenvironment.items).map((resitem: ResModel | ResCollection | any) => {
                                              resitem.on("", (event: any) => onUpdate(event, "Item Event"));
                                              //resitem.off("delete remove", (event: any) => console.log("Remove", event));

                                              resitem.alerts.on("", (event: any) => onUpdate(resitem.alerts, event));

                                              resitem.alerts &&
                                                  Array.from(resitem.alerts).map((resalert: ResModel | ResCollection | any) => {
                                                      resalert.on("change", (event: any) => onUpdate(event, "Alert Change Event"));
                                                      return null;
                                                  });

                                              return null;
                                          });
                                          return null;
                                      });
                                      return null;
                                  });
                            setRegions(resregions);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });

        getMtrReports();
        getMtrReportGroups();

        client.current.on("connect", () => {
            console.log("ResClient connected", client.current.hostUrl);
        });
        client.current.on("disconnect", () => {
            console.log("ResClient disconnected");
        });

        if (!rootStore.userStore.isLoggedIn) {
            currentClient.disconnect();
        }

        return () => {
            currentClient.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managing_org_id, rootStore.commonStore.token, rootStore.userStore.isLoggedIn, rootStore.userStore]);

    useEffect(() => {}, [triggerState]);

    const defaultProps = {
        center: {
            lat: 20,
            lng: 0
        },
        zoom: 2.8
    };

    let contextMenu: any = document.getElementById("contextMenu");

    const handleApiLoaded = (map: google.maps.Map) => {
        setMapApiLoaded(true);
        setMapInstance(map);

        contextMenu.style.display = "none";

        map.controls[google.maps.ControlPosition.TOP_CENTER].push(contextMenu);

        google.maps.event.addListener(map, "rightclick", (e: any) => {
            const currentLat = e.latLng?.lat() ? e.latLng.lat() : 0;
            const currentLng = e.latLng?.lng() ? e.latLng.lng() : 0;

            contextMenu.style.top = e.pixel?.y + "px";
            contextMenu.style.left = e.pixel?.x + "px";
            contextMenu.style.display = "block";
            contextMenu.setAttribute("lat", currentLat);
            contextMenu.setAttribute("lng", currentLng);
        });

        google.maps.event.addListener(map, "click", () => {
            contextMenu.style.display = "none";
        });

        google.maps.event.addListener(map, "dragstart", () => {});

        google.maps.event.addListener(map, "dragend", () => {});

        if (contextMenu) {
            contextMenu.addEventListener("click", () => {
                contextMenu.style.display = "none";
            });
        }

        setMapApiLoaded(true);
        setMapInstance(map);
    };

    const toggleRegionVisibility = (region_id: number) => {
        if (!hiddenRegions.includes(region_id)) {
            setHiddenRegions((oldHiddenRegions) => [...oldHiddenRegions, region_id]);
        } else {
            setHiddenRegions((oldHiddenRegions) => oldHiddenRegions.filter((item) => item !== region_id));
        }
    };

    const toggleEnvironmentVisibility = (environment_id: string) => {
        if (!hiddenEnvironments.includes(environment_id)) {
            setHiddenEnvironments((oldHiddenEnvironments) => [...oldHiddenEnvironments, environment_id]);
        } else {
            setHiddenEnvironments((oldHiddenEnvironments) => oldHiddenEnvironments.filter((item) => item !== environment_id));
        }
    };

    const toggleGroupVisibility = (group_id: string) => {
        if (!hiddenGroups.includes(group_id)) {
            setHiddenGroups((oldHiddenGroups) => [...oldHiddenGroups, group_id]);
        } else {
            setHiddenGroups((oldHiddenGroups) => oldHiddenGroups.filter((item) => item !== group_id));
        }
    };

    const toggleItemVisibility = (item_id: string) => {
        if (!hiddenItems.includes(item_id)) {
            setHiddenItems((oldHiddenItems) => [...oldHiddenItems, item_id]);
        } else {
            setHiddenItems((oldHiddenItems) => oldHiddenItems.filter((item) => item !== item_id));
        }
    };

    interface NewGroupFormProps {
        lat: number;
        lng: number;
        type: string;
    }

    const NewGroupForm: React.FC<NewGroupFormProps> = observer(function NewGroupForm(props) {
        const [errors, setErrors] = React.useState<any>();

        const handleSubmit = async (e: React.SyntheticEvent) => {
            e.preventDefault();

            setLoadingMessage("Creating new " + props.type + " group...");
            setLoading(true);
            const target = e.target as typeof e.target & {
                name: { value: string };
                description: { value: string };
            };

            await agent.Environment.createGroup({
                name: target.name.value,
                description: target.description.value,
                latitude: props.lat,
                longitude: props.lng,
                group_type: props.type
            })
                .then((value: any) => {
                    setErrors(undefined);
                    setActiveGroup(value.id);
                    closeDialog();
                })
                .catch((error) => {
                    setErrors(
                        error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An Unknown Error Occurred"
                    );
                    setLoading(false);
                });
        };
        return (
            <>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <div
                            style={{
                                textTransform: "capitalize",
                                color: "#fff",
                                fontWeight: 600,
                                fontSize: 24
                            }}>
                            New {props.type} group
                        </div>
                        <div
                            style={{
                                textTransform: "capitalize",
                                color: "#fff",
                                fontSize: 14,
                                opacity: 0.8,
                                marginBottom: 20
                            }}>
                            <span style={{ fontWeight: 600 }}>Lat:</span> {props.lat}, <span style={{ fontWeight: 600 }}>Lng:</span>{" "}
                            {props.lng}
                        </div>
                        <TextField variant="standard" id="name" name="name" label="Name" required style={{ marginBottom: "10px" }} />
                        <TextField
                            variant="standard"
                            id="description"
                            name="description"
                            label="Description"
                            rows={4}
                            placeholder="Description"
                            multiline
                            style={{ marginBottom: "10px" }}
                        />
                        <ErrorMessages errors={errors} />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button variant="contained" type="submit">
                        Create Group
                    </Button>
                </DialogActions>
            </>
        );
    });

    const handleAddGroup = (lat: number, lng: number, type: string) => {
        openDialog(<NewGroupForm lat={lat} lng={lng} type={type} />, undefined, "sm");
    };

    const handleDeleteGroup = (id: string) => {
        setLoadingMessage("Deleting group...");
        setLoading(true);
        agent.Environment.deleteGroup(id);
        console.log("Delete group " + id);
    };

    const handleMuteAlert = (id: string, withRecovery: boolean) => {
        setLoadingMessage("Muting alert...");
        setLoading(true);
        agent.Scheduler.deactivateAlert(id, { action: "deactivate", unmute_upon_recovery: withRecovery });
    };

    const createMapOptions = () => {
        return {
            center: {
                lat: 0.0,
                lng: 0.0
            },
            zoom: 4,
            minZoom: 3,
            maxZoom: 10,
            styles: mapStyles,
            fullscreenControl: false
        };
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setMapApiLoaded, mapApiLoaded, mapInstance, setMapInstance]);

    //TEMP
    useEffect(() => {
        const getMtrReport = async () => {
            activeReport ? setReport(await agent.Reporting.getMtrReport(activeReport)) : setReport(undefined);
        };

        getMtrReport();

        return () => {
            setReport(undefined);
        };
    }, [activeReport]);

    useEffect(() => {
        return () => {
            closeDialog();
        };
    }, [closeDialog]);

    return (
        <View className="global-view" loading={loading || !mapApiLoaded} loadingText={loadingMessage}>
            <ViewHeader title="Global View">
                <div
                    style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div></div>
                    <div>
                        <MuiButton
                            id="demo-customized-button"
                            aria-controls={open ? "demo-customized-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            variant="toolbar"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}>
                            Tools
                        </MuiButton>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                                "aria-labelledby": "demo-customized-button"
                            }}
                            sx={{ minWidth: 300 }}
                            anchorEl={anchorEl}
                            elevation={0}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={open}
                            onClose={handleClose}>
                            <MenuItem
                                disableRipple
                                onClick={() => {
                                    setShowTraceReports(true);
                                    setFocusedPanel("trace-reports");
                                    handleClose();
                                }}>
                                Trace Reports
                            </MenuItem>
                            <MenuItem
                                disableRipple
                                onClick={() => {
                                    setShowNotes(true);
                                    setFocusedPanel("notes");
                                    handleClose();
                                }}>
                                Notes
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </ViewHeader>
            <ViewBody noPadding>
                <div style={{ position: "relative", width: "100%", height: "100%", backgroundColor: "#1d2932" }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: "AIzaSyB5HL0JvzMCe6Mq5M8zY3AgPf7eUcB58ng",
                            libraries: ["drawing"]
                        }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
                        options={() => createMapOptions()}
                        onClick={() => {
                            setActiveItem("");
                            setActiveItemType("none");
                            setActiveGroup("");
                            setActiveRegion(0);
                            setActiveEnvironment("");
                            setActiveReport(undefined);
                            setReport(undefined);
                        }}>
                        {/* <ClickLayer id='click-layer' lat={0} lng={0}  /> */}

                        {report &&
                            report.report?.hubs
                                ?.filter((hub: IMTRReportHubEntry) => !!hub.latitude)
                                .map((hub: IMTRReportHubEntry, i, arr) => {
                                    const previousItem = arr[i - 1];
                                    return (
                                        mapInstance && (
                                            <RouteMarker
                                                key={i}
                                                map={mapInstance}
                                                lat={hub.latitude}
                                                lng={hub.longitude}
                                                prevLat={previousItem && previousItem.latitude ? previousItem.latitude : undefined}
                                                prevLng={previousItem && previousItem.longitude ? previousItem.longitude : undefined}
                                                data={hub}
                                                text={hub.host}
                                                count={hub.count}
                                            />
                                        )
                                    );
                                })}

                        {Array.from(regions).map(
                            (region: any) =>
                                !hiddenRegions.includes(region.id) &&
                                Array.from(region.environments).map(
                                    (environment: any) =>
                                        !hiddenEnvironments.includes(environment.id) &&
                                        Array.from(environment.items).map((item: any) => {
                                            if (
                                                !item.dashboard_group_id &&
                                                (item.type === "channel" || item.type === "destination") &&
                                                item.latitude &&
                                                !hiddenItems.includes(item.id) &&
                                                mapInstance
                                            ) {
                                                return (
                                                    <SourceMarker
                                                        key={item.id}
                                                        id={item.id}
                                                        type={item.type}
                                                        onLineClick={() => {
                                                            //Create a timeout function
                                                            setTimeout(() => {
                                                                setActiveItem(item.id);
                                                                setActiveGroup("");
                                                                setActiveRegion(region.id);
                                                                setActiveEnvironment(environment.id);
                                                            }, 100);
                                                        }}
                                                        onClick={() => {
                                                            setActiveItem(item.id);
                                                            setActiveItemType(item.type);
                                                            setActiveGroup("");
                                                            setActiveRegion(region.id);
                                                            setActiveEnvironment(environment.id);
                                                        }}
                                                        active={activeItem === item.id}
                                                        name={item.name}
                                                        map={mapInstance}
                                                        environmentLat={region.latitude}
                                                        environmentLng={region.longitude}
                                                        lat={item.latitude}
                                                        lng={item.longitude}
                                                        hasError={
                                                            Array.from(item.alerts).filter((alert: any) => alert.is_alerting === true)
                                                                .length > 0
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                )
                        )}

                        {Array.from(groups).map(
                            (group: any) =>
                                !hiddenGroups.includes(group.id) &&
                                mapInstance && (
                                    <GroupMarker
                                        icon={group.type === "destination_group" ? "broadcast-tower" : "video"}
                                        activeItem={activeItem}
                                        onItemClick={() => console.log("item click")}
                                        type={group.type}
                                        key={group.id}
                                        id={group.id}
                                        onClick={() => {
                                            setActiveGroup(group.id);
                                            setActiveItem("");
                                            setActiveItemType("");
                                            setActiveRegion(0);
                                            setActiveEnvironment("");
                                        }}
                                        active={activeGroup === group.id}
                                        name={group.name}
                                        description={group.description}
                                        map={mapInstance}
                                        lat={group.latitude}
                                        lng={group.longitude}
                                        items={Array.from(regions).map(
                                            (region: any) =>
                                                !hiddenRegions.includes(region.id) &&
                                                Array.from(region.environments).map(
                                                    (environment: any) =>
                                                        !hiddenEnvironments.includes(environment.id) &&
                                                        Array.from(environment.items)
                                                            .filter((item: any) => item.dashboard_group_id === group.id)
                                                            .map((item: any, i: number) => (
                                                                <GroupMarkerItem
                                                                    index={i}
                                                                    onLineClick={() => {
                                                                        //Create a timeout function
                                                                        setTimeout(() => {
                                                                            setActiveItem(item.id);
                                                                            setActiveGroup(group.id);
                                                                            setActiveRegion(region.id);
                                                                            setActiveEnvironment(environment.id);
                                                                        }, 100);
                                                                    }}
                                                                    onClick={() => {
                                                                        setActiveItem(item.id);
                                                                        setActiveItemType(item.type);
                                                                        setActiveRegion(region.id);
                                                                        setActiveEnvironment(environment.id);
                                                                    }}
                                                                    active={activeItem === item.id}
                                                                    key={item.id}
                                                                    id={item.id}
                                                                    name={item.name}
                                                                    type={item.type}
                                                                    lat={group.latitude}
                                                                    lng={group.longitude}
                                                                    environmentLat={region.latitude}
                                                                    environmentLng={region.longitude}
                                                                    environmentName={environment.environment_name}
                                                                    map={mapInstance}
                                                                    hasError={
                                                                        Array.from(item.alerts).filter(
                                                                            (alert: any) => alert.is_alerting === true
                                                                        ).length > 0
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            ))
                                                )
                                        )}
                                    />
                                )
                        )}

                        {Array.from(regions).map(
                            (region: any) =>
                                !hiddenRegions.includes(region.id) && (
                                    <RegionMarker
                                        region_name={region.region_human_name}
                                        provider={region.cloud_provider_human_name}
                                        key={region.id}
                                        lat={region.latitude}
                                        lng={region.longitude}
                                        environments={Array.from(region.environments)}
                                        count={Array.from(region.environments).length}
                                        hasError={false}
                                        active={activeRegion === region.id}
                                        onClick={() => {
                                            setActiveRegion(region.id);
                                            setActiveItem("");
                                            setActiveItemType("");
                                            setActiveGroup("");
                                        }}
                                    />
                                )
                        )}
                    </GoogleMapReact>

                    <div id="contextMenu" className="context-menu">
                        <div
                            className="context-menu-item"
                            onClick={(event) =>
                                handleAddGroup(
                                    Number(event.currentTarget.parentElement?.getAttribute("lat")),
                                    Number(event.currentTarget.parentElement?.getAttribute("lng")),
                                    "source"
                                )
                            }>
                            <FontAwesomeIcon icon={["fal", "video"]} /> Create Source Group Here...
                        </div>
                        <div
                            className="context-menu-item"
                            onClick={(event) =>
                                handleAddGroup(
                                    Number(event.currentTarget.parentElement?.getAttribute("lat")),
                                    Number(event.currentTarget.parentElement?.getAttribute("lng")),
                                    "destination"
                                )
                            }>
                            <FontAwesomeIcon icon={["fal", "broadcast-tower"]} /> Create Destination Group Here...
                        </div>
                    </div>

                    {Array.from(regions).length > 0 && (
                        <>
                            {showNotes && (
                                <Draggable
                                    defaultPosition={{ x: -500, y: -250 }}
                                    bounds="parent"
                                    handle=".panel-header"
                                    onMouseDown={() => setFocusedPanel("notes")}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            right: "50%",
                                            top: "50%",
                                            width: 500,
                                            transform: "translate3d(-200px, -500px, 0)",
                                            fontSize: "12px",
                                            color: "#fff",
                                            zIndex: focusedPanel === "create-mtr-group" ? 998 : 999
                                        }}>
                                        <Panel
                                            id="notes"
                                            draggable={true}
                                            minHeight="400px"
                                            maxHeight="500px"
                                            title="Notes"
                                            defaultCollapsed={false}
                                            closable={true}
                                            onCloseClick={() => setShowNotes(false)}>
                                            <div style={{ padding: 20 }}>
                                                <textarea
                                                    value={notes}
                                                    onChange={(e) => {
                                                        setNotes(e.target.value);
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                        height: 400,
                                                        backgroundColor: "rgba(0,0,0,0.5)",
                                                        color: "#fff",
                                                        border: "1px solid rgba(255,255,255,0.1",
                                                        borderRadius: 4,
                                                        padding: 10,
                                                        resize: "none",
                                                        outline: "none !important",
                                                        fontSize: 14
                                                    }}></textarea>
                                            </div>
                                        </Panel>
                                    </div>
                                </Draggable>
                            )}
                            {showCreateMtrGroup && (
                                <Draggable
                                    defaultPosition={{ x: -300, y: -180 }}
                                    bounds="parent"
                                    handle=".panel-header"
                                    onMouseDown={() => setFocusedPanel("create-mtr-group")}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            right: "50%",
                                            top: "50%",
                                            width: 500,
                                            transform: "translate3d(-200px, -500px, 0)",
                                            fontSize: "12px",
                                            color: "#fff",
                                            zIndex: focusedPanel === "create-mtr-group" ? 998 : 999
                                        }}>
                                        <Panel
                                            id="create-mtr-group_panel"
                                            draggable={true}
                                            minHeight="400px"
                                            maxHeight="500px"
                                            title="Create Endpoint Group"
                                            defaultCollapsed={false}
                                            closable={true}
                                            onCloseClick={() => setShowCreateMtrGroup(false)}>
                                            <div style={{ padding: 20 }}>
                                                <CreateHostGroupForm
                                                    id="test"
                                                    onLoading={(loading: boolean) => {
                                                        setLoadingMessage("Creating Endpoint Group...");
                                                        if (loading) {
                                                            setLoading(true);
                                                        } else {
                                                            setLoading(false);
                                                        }
                                                    }}
                                                    onSuccess={() => {
                                                        onUpdate("", "Endpoint group created");
                                                        setShowCreateMtrGroup(false);
                                                    }}
                                                    onError={() => {
                                                        onUpdate("", "Endpoint group created");
                                                    }}
                                                />
                                            </div>
                                        </Panel>
                                    </div>
                                </Draggable>
                            )}

                            {showCreateHost && (
                                <Draggable
                                    defaultPosition={{ x: -300, y: -180 }}
                                    bounds="parent"
                                    handle=".panel-header"
                                    onMouseDown={() => setFocusedPanel("create-host")}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            right: "50%",
                                            top: "50%",
                                            width: 500,
                                            transform: "translate3d(-200px, -500px, 0)",
                                            fontSize: "12px",
                                            color: "#fff",
                                            zIndex: focusedPanel === "create-host" ? 998 : 999
                                        }}>
                                        <Panel
                                            id="create-host_panel"
                                            draggable={true}
                                            minHeight="400px"
                                            maxHeight="500px"
                                            title="Create Endpoint"
                                            defaultCollapsed={false}
                                            closable={true}
                                            onCloseClick={() => setShowCreateHost(false)}>
                                            <div style={{ padding: 20 }}>
                                                <CreateHostForm
                                                    id="test"
                                                    group_id={createHostGroupId}
                                                    onLoading={(loading: boolean) => {
                                                        setLoadingMessage("Creating Endpoint...");
                                                        if (loading) {
                                                            setLoading(true);
                                                        } else {
                                                            setLoading(false);
                                                        }
                                                    }}
                                                    onSuccess={() => {
                                                        onUpdate("", "Endpoint created");
                                                        setShowCreateHost(false);
                                                    }}
                                                    onError={() => {
                                                        onUpdate("", "Failed to create endpoint");
                                                    }}
                                                />
                                            </div>
                                        </Panel>
                                    </div>
                                </Draggable>
                            )}

                            {showCreateHostReport && (
                                <Draggable
                                    defaultPosition={{ x: -300, y: -180 }}
                                    bounds="parent"
                                    handle=".panel-header"
                                    onMouseDown={() => setFocusedPanel("create-host-report")}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            right: "50%",
                                            top: "50%",
                                            width: 500,
                                            transform: "translate3d(-200px, -500px, 0)",
                                            fontSize: "12px",
                                            color: "#fff",
                                            zIndex: focusedPanel === "create-host-report" ? 998 : 999
                                        }}>
                                        <Panel
                                            id="create-host-report_panel"
                                            draggable={true}
                                            minHeight="400px"
                                            maxHeight="500px"
                                            title="Create Endpoint Report"
                                            defaultCollapsed={false}
                                            closable={true}
                                            onCloseClick={() => setShowCreateHostReport(false)}>
                                            <div style={{ padding: 20 }}>
                                                <GenerateMtrReportForm
                                                    id="test"
                                                    group_mode={false}
                                                    host_mode={true}
                                                    mtr_host_id={createHostReportHostId}
                                                    onLoading={(loading: boolean) => {
                                                        setLoadingMessage("Submitting new report...");
                                                        if (loading) {
                                                            setLoading(true);
                                                        } else {
                                                            setLoading(false);
                                                        }
                                                    }}
                                                    onSuccess={() => {
                                                        setShowCreateHostReport(false);
                                                        setCreateHostReportHostId(0);
                                                    }}
                                                    onError={() => setLoading(false)}
                                                />
                                            </div>
                                        </Panel>
                                    </div>
                                </Draggable>
                            )}

                            {showTraceReports && (
                                <Draggable
                                    defaultPosition={{ x: -250, y: -200 }}
                                    bounds="parent"
                                    handle=".panel-header"
                                    onMouseDown={() => setFocusedPanel("trace-reports")}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            right: "50%",
                                            top: "50%",
                                            width: 500,
                                            transform: "translate3d(-200px, -500px, 0)",
                                            fontSize: "12px",
                                            color: "#fff",
                                            zIndex: focusedPanel === "trace-reports" ? 998 : 999
                                        }}>
                                        <Panel
                                            id="trace-reports_panel"
                                            draggable={true}
                                            minHeight="400px"
                                            maxHeight="500px"
                                            title="Trace Reports"
                                            defaultCollapsed={false}
                                            closable={true}
                                            onCloseClick={() => setShowTraceReports(false)}>
                                            <div>
                                                <TabContext value={tabValue}>
                                                    <SmallTabList
                                                        onChange={handleTabChange}
                                                        aria-label="Overview - Source Item Tabs"
                                                    >
                                                        <SmallTab label="Add New Endpoint" value="1" icon={<Add fontSize="small" sx={{ marginRight: '0.2rem !important' }} />} />
                                                        <SmallTab label="Endpoints" value="2" icon={<AccountTree fontSize="small" sx={{ marginRight: '0.2rem !important' }} />} />
                                                        <SmallTab label="Reports" value="3" icon={<InsertDriveFileOutlined fontSize="small" sx={{ marginRight: '0.2rem !important' }} />} />
                                                        <SmallTab label="About" value="4" icon={<InfoOutlined fontSize="small" sx={{ marginRight: '0.2rem !important' }} />} />
                                                    </SmallTabList>

                                                    <TabPanel value="1">
                                                        <div>
                                                            <GenerateMtrReportForm
                                                                id="test"
                                                                group_mode={false}
                                                                host_mode={false}
                                                                onLoading={(loading: boolean) => {
                                                                    setLoadingMessage("Submitting new report...");
                                                                    if (loading) {
                                                                        setLoading(true);
                                                                    } else {
                                                                        setLoading(false);
                                                                    }
                                                                }}
                                                                onError={() => setLoading(false)}
                                                            />
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignContent: "stretch",
                                                                fontSize: "12px"
                                                            }}>
                                                            <div>
                                                                <Button
                                                                    onClick={() => {
                                                                        setShowCreateMtrGroup(true);
                                                                        setFocusedPanel("create-mtr-group");
                                                                    }}
                                                                    style={{ marginBottom: "10px" }}
                                                                    startIcon={<Add />}>
                                                                    Create Endpoint Group
                                                                </Button>
                                                            </div>
                                                            {mtrReportGroups?.data
                                                                .filter((group: any) => !!group.group_id || !group.group_id)
                                                                .map((group: any, i: number) => (
                                                                    <MtrGroupAccordion
                                                                        id={"mtr-group-" + group.group_id}
                                                                        key={i}
                                                                        disableActions={true}
                                                                        icon="folder"
                                                                        name={group.group_name}
                                                                        defaultExpanded={false}
                                                                        item_count={group.group_hosts.length}
                                                                        active={false}>
                                                                        <>
                                                                            <TabContext value={innerTabValue}>
                                                                                <SmallTabList
                                                                                    onChange={handleInnerTabChange}
                                                                                    aria-label="Overview - Report Groups Inner Tabs"
                                                                                >
                                                                                    {group.group_id && (
                                                                                        <SmallTab label="Create Group Report" value="1" />
                                                                                    )}
                                                                                    <SmallTab label="Endpoints" value="2" />
                                                                                    <SmallTab label="Reports" value="3" />
                                                                                    {group.group_id && (
                                                                                        <SmallTab label="Settings" value="4" />
                                                                                    )}
                                                                                </SmallTabList>

                                                                                <TabPanel value="1">
                                                                                    <div>
                                                                                        <GenerateMtrReportForm
                                                                                            id="test"
                                                                                            group_mode={true}
                                                                                            host_mode={false}
                                                                                            mtr_host_group_id={group.group_id}
                                                                                            onLoading={(loading: boolean) => {
                                                                                                setLoadingMessage("Submitting new report...");
                                                                                                if (loading) {
                                                                                                    setLoading(true);
                                                                                                } else {
                                                                                                    setLoading(false);
                                                                                                }
                                                                                            }}
                                                                                            onError={() => setLoading(false)}
                                                                                        />
                                                                                    </div>
                                                                                </TabPanel>
                                                                                <TabPanel value="2">
                                                                                    <div>
                                                                                        <div>
                                                                                            <Button
                                                                                                onClick={() => {
                                                                                                    setCreateHostGroupId(
                                                                                                        group.group_id
                                                                                                    );
                                                                                                    setShowCreateHost(
                                                                                                        true
                                                                                                    );
                                                                                                    setFocusedPanel(
                                                                                                        "create-host"
                                                                                                    );
                                                                                                }}
                                                                                                size="small"
                                                                                                color="secondary"
                                                                                                variant="contained"
                                                                                                startIcon={
                                                                                                    <Add />
                                                                                                }>
                                                                                                Add
                                                                                                Endpoint
                                                                                            </Button>
                                                                                        </div>
                                                                                        <ErrorMessages
                                                                                            errors={
                                                                                                deleteHostErrors
                                                                                            }
                                                                                        />
                                                                                        <div
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "12px",
                                                                                                marginTop: 10
                                                                                            }}>
                                                                                            {group.group_hosts.map(
                                                                                                (
                                                                                                    host: any,
                                                                                                    i: number
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                        style={{
                                                                                                            padding:
                                                                                                                "5px 0",
                                                                                                            borderBottom:
                                                                                                                "1px solid rgba(160,160,160,0.2)",
                                                                                                            display:
                                                                                                                "flex",
                                                                                                            flexDirection:
                                                                                                                "row",
                                                                                                            justifyContent:
                                                                                                                "space-between",
                                                                                                            alignItems:
                                                                                                                "center"
                                                                                                        }}>
                                                                                                        <div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    fontWeight: 600
                                                                                                                }}>
                                                                                                                {
                                                                                                                    host.host_name
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    host.host
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <FontAwesomeIcon
                                                                                                                title="Generate Report"
                                                                                                                icon={[
                                                                                                                    "fal",
                                                                                                                    "play"
                                                                                                                ]}
                                                                                                                style={{
                                                                                                                    cursor: "pointer",
                                                                                                                    marginRight: 10
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    setCreateHostReportHostId(
                                                                                                                        host.host_id
                                                                                                                    );
                                                                                                                    setShowCreateHostReport(
                                                                                                                        true
                                                                                                                    );
                                                                                                                    setFocusedPanel(
                                                                                                                        "create-host-report"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                            <FontAwesomeIcon
                                                                                                                icon={[
                                                                                                                    "fal",
                                                                                                                    "trash"
                                                                                                                ]}
                                                                                                                style={{
                                                                                                                    cursor: "pointer"
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    openDialog(
                                                                                                                        <ConfirmationDialog
                                                                                                                            title="Delete Endpoint"
                                                                                                                            message="Are you sure you want to delete this endpoint?"
                                                                                                                            onConfirm={async () => {
                                                                                                                                await handleDeleteHost(
                                                                                                                                    host.host_id
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </TabPanel>
                                                                                <TabPanel value="3">
                                                                                    <div
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "12px"
                                                                                        }}>
                                                                                        {mtrReports?.data
                                                                                            .filter(
                                                                                                (
                                                                                                    report: any
                                                                                                ) =>
                                                                                                    report.mtr_host_group_id ===
                                                                                                    group.group_id
                                                                                            )
                                                                                            .sort(
                                                                                                (a, b) =>
                                                                                                    a.finished_at >
                                                                                                    b.finished_at
                                                                                                        ? -1
                                                                                                        : 1
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    report: IMtrReportsListEntry
                                                                                                ) => (
                                                                                                    <MtrReportItem
                                                                                                        environment_id={
                                                                                                            report.environment_id
                                                                                                        }
                                                                                                        key={
                                                                                                            report.id
                                                                                                        }
                                                                                                        id={
                                                                                                            report.id
                                                                                                        }
                                                                                                        hostname={
                                                                                                            report.mtr_host_name ||
                                                                                                            report.hostname
                                                                                                        }
                                                                                                        finished_at={
                                                                                                            report.finished_at
                                                                                                        }
                                                                                                        onClick={() =>
                                                                                                            activeReport ===
                                                                                                            report.id
                                                                                                                ? setActiveReport(
                                                                                                                    undefined
                                                                                                                )
                                                                                                                : setActiveReport(
                                                                                                                    report.id
                                                                                                                )
                                                                                                        }
                                                                                                        active={
                                                                                                            activeReport ===
                                                                                                            report.id
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            )}
                                                                                    </div>
                                                                                </TabPanel>
                                                                                <TabPanel value="4">
                                                                                    <div
                                                                                        style={{
                                                                                            fontSize:
                                                                                                "12px"
                                                                                        }}>
                                                                                        <EditHostGroupForm
                                                                                            id={
                                                                                                group.group_id
                                                                                            }
                                                                                            group_id={
                                                                                                group.group_id
                                                                                            }
                                                                                            current_group_name={
                                                                                                group.group_name
                                                                                            }
                                                                                            onLoading={(
                                                                                                loading: boolean
                                                                                            ) => {
                                                                                                setLoadingMessage(
                                                                                                    "Updating Group..."
                                                                                                );
                                                                                                if (
                                                                                                    loading
                                                                                                ) {
                                                                                                    setLoading(
                                                                                                        true
                                                                                                    );
                                                                                                } else {
                                                                                                    setLoading(
                                                                                                        false
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            onSuccess={() => {
                                                                                                onUpdate(
                                                                                                    "",
                                                                                                    "Group updated"
                                                                                                );
                                                                                                setShowCreateHost(
                                                                                                    false
                                                                                                );
                                                                                            }}
                                                                                            onError={() => {
                                                                                                onUpdate(
                                                                                                    "",
                                                                                                    "Failed to update group"
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </TabPanel>
                                                                            </TabContext>
                                                                        </>
                                                                    </MtrGroupAccordion>
                                                                ))}
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignContent: "stretch",
                                                                fontSize: "12px"
                                                            }}>
                                                            {mtrReports?.data
                                                                .sort((a, b) => (a.finished_at > b.finished_at ? -1 : 1))
                                                                .map((report: IMtrReportsListEntry) => (
                                                                    <MtrReportItem
                                                                        environment_id={report.environment_id}
                                                                        key={report.id}
                                                                        id={report.id}
                                                                        hostname={report.mtr_host_name || report.hostname}
                                                                        finished_at={report.finished_at}
                                                                        onClick={() =>
                                                                            activeReport === report.id
                                                                                ? setActiveReport(undefined)
                                                                                : setActiveReport(report.id)
                                                                        }
                                                                        active={activeReport === report.id}
                                                                    />
                                                                ))}
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel value="4">
                                                        <div style={{ padding: 10, opacity: 0.8 }}>
                                                            Trace reports allow you test round trip time (rrt), jitter and
                                                            packet loss for every hop from your environments to any endpoint
                                                            (dns name or IP address). You can run group reports on multiple
                                                            endpoints or individually to a single one.
                                                        </div>
                                                    </TabPanel>
                                                </TabContext>
                                            </div>
                                        </Panel>
                                    </div>
                                </Draggable>
                            )}

                            <Draggable bounds="parent" handle=".panel-header" onMouseDown={() => setFocusedPanel("alerts")}>
                                <div
                                    style={{
                                        position: "absolute",
                                        overflow: "hidden",
                                        left: 25,
                                        bottom: 15,
                                        fontSize: "12px",
                                        width: 380,
                                        maxHeight: "calc(100vh - 100px)",
                                        color: "#fff",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "4px",
                                        zIndex: focusedPanel === "alerts" ? 998 : 999
                                    }}>
                                    <Panel
                                        draggable
                                        id="alerts-panel"
                                        minHeight="0px"
                                        maxHeight="100%"
                                        title="Alerts"
                                        defaultCollapsed={false}>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                alignContent: "stretch"
                                            }}>
                                            {Array.from(regions).map((region: any) =>
                                                Array.from(region.environments).map((environment: any) =>
                                                    Array.from(environment.items).map((item: any) =>
                                                        Array.from(item.alerts)
                                                            .filter((alert: any) => alert.is_alerting === true)
                                                            .map((alert: any, i: number) => (
                                                                <div
                                                                    className="alert-item"
                                                                    key={item.id + i}
                                                                    onClick={() => {
                                                                        setActiveItem(item.id);
                                                                        setActiveGroup(item.dashboard_group_id);
                                                                        setActiveRegion(region.id);
                                                                        setActiveEnvironment(environment.id);
                                                                    }}
                                                                    style={{
                                                                        padding: 10,
                                                                        backgroundColor: "crimson",
                                                                        borderRadius: 4,
                                                                        margin: 10,
                                                                        marginRight: 0
                                                                    }}>
                                                                    <div
                                                                        style={{ fontSize: "13px", fontWeight: 600, whiteSpace: "nowrap" }}>
                                                                        <span style={{ textTransform: "capitalize" }}>{item.type}</span> -{" "}
                                                                        {item.name}
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            fontSize: "10px",
                                                                            fontWeight: 600,
                                                                            opacity: 0.8,
                                                                            whiteSpace: "nowrap"
                                                                        }}>
                                                                        {environment.environment_name}
                                                                    </div>
                                                                    <div key={i} style={{ whiteSpace: "nowrap", fontSize: "14px" }}>
                                                                        {alert.condition}
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexWrap: "nowrap",
                                                                            borderTop: "1px solid rgba(160,160,160,0.2)",
                                                                            paddingTop: 5,
                                                                            marginTop: 5
                                                                        }}>
                                                                        <Button
                                                                            size="small"
                                                                            sx={{
                                                                                whiteSpace: "nowrap",
                                                                                backgroundColor: "rgba(255,255,255,0.5)"
                                                                            }}
                                                                            onClick={() => handleMuteAlert(alert.id, false)}>
                                                                            Mute
                                                                        </Button>
                                                                        <Button
                                                                            size="small"
                                                                            sx={{
                                                                                whiteSpace: "nowrap",
                                                                                backgroundColor: "rgba(255,255,255,0.5)",
                                                                                ml: 1
                                                                            }}
                                                                            onClick={() => handleMuteAlert(alert.id, true)}>
                                                                            Mute with Recovery
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            ))
                                                    )
                                                )
                                            )}
                                            <div className="no-alerts" style={{ padding: 10 }}>
                                                Active alerts will appear in this window - currently there are no active alerts.
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            </Draggable>
                            <Draggable bounds="parent" handle=".panel-header" onMouseDown={() => setFocusedPanel("main")}>
                                <div
                                    className="panels-container"
                                    style={{
                                        position: "absolute",
                                        overflow: "hidden",
                                        top: 25,
                                        right: 25,
                                        width: 380,
                                        maxHeight: "calc(100vh - 100px)",
                                        color: "#fff",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "4px",
                                        fontSize: 12,
                                        zIndex: focusedPanel === "main" ? 998 : 999
                                    }}>
                                    <Panel
                                        draggable
                                        id="groups-panel"
                                        minHeight="100px"
                                        maxHeight="400px"
                                        title="Groups"
                                        defaultCollapsed={activeGroup ? false : true}>
                                        <div>
                                            <div style={{ padding: "3px 12px", fontSize: "12px", backgroundColor: "rgba(0,0,0,0.1)" }}>
                                                Source Groups
                                            </div>
                                            {Array.from(groups)
                                                .filter((group: any) => group.type === "source_group")
                                                .map((group: any) => (
                                                    <GroupAccordion
                                                        onHideClick={() => toggleGroupVisibility(group.id)}
                                                        hidden={hiddenGroups.includes(group.id)}
                                                        onDeleteClick={() => handleDeleteGroup(group.id)}
                                                        key={group.id}
                                                        id={group.id}
                                                        name={group.name}
                                                        description={group.description}
                                                        defaultExpanded={false}
                                                        lat={group.latitude}
                                                        lng={group.longitude}
                                                        item_count={group.associated_ids.length}
                                                        active={group.id === activeGroup}>
                                                        <div style={{ padding: "5px 15px" }}>
                                                            {Array.from(regions).map((region: any) =>
                                                                Array.from(region.environments).map((environment: any) =>
                                                                    Array.from(environment.items)
                                                                        .filter((item: any) => item.dashboard_group_id === group.id)
                                                                        .map((item: any) => (
                                                                            <SourceItem
                                                                                id={item.id}
                                                                                key={item.id}
                                                                                name={item.name}
                                                                                type="source"
                                                                                onClick={() => {
                                                                                    activeItem === item.id
                                                                                        ? setActiveItem("")
                                                                                        : setActiveItem(item.id);
                                                                                    setActiveEnvironment(environment.id);
                                                                                    setActiveRegion(region.id);
                                                                                    setActiveGroup(item.dashboard_group_id);
                                                                                }}
                                                                                onHideClick={() => toggleItemVisibility(item.id)}
                                                                                hidden={hiddenItems.includes(item.id)}
                                                                                onUpdateGroup={() => {
                                                                                    setLoadingMessage("Updating group...");
                                                                                    setLoading(true);
                                                                                }}
                                                                                onUpdateGeo={() => {
                                                                                    setLoadingMessage("Updating location...");
                                                                                    setLoading(true);
                                                                                }}
                                                                                active={item.id === activeItem}
                                                                                group_id={item.dashboard_group_id || ""}
                                                                                group_name={item.dashboard_group_name || ""}
                                                                                alerts={Array.from(item.alerts)}
                                                                                lat={item.latitude}
                                                                                lng={item.longitude}
                                                                                environmentLat={environment.latitude}
                                                                                environmentLng={environment.longitude}
                                                                                description={item.description}
                                                                            />
                                                                        ))
                                                                )
                                                            )}
                                                        </div>
                                                    </GroupAccordion>
                                                ))}
                                            <div style={{ padding: "3px 12px", fontSize: "12px", backgroundColor: "rgba(0,0,0,0.1)" }}>
                                                Destination Groups
                                            </div>
                                            {Array.from(groups)
                                                .filter((group: any) => group.type === "destination_group")
                                                .map((group: any) => (
                                                    <GroupAccordion
                                                        onHideClick={() => toggleGroupVisibility(group.id)}
                                                        hidden={hiddenGroups.includes(group.id)}
                                                        onDeleteClick={() => handleDeleteGroup(group.id)}
                                                        key={group.id}
                                                        id={group.id}
                                                        name={group.name}
                                                        description={group.description}
                                                        defaultExpanded={false}
                                                        lat={group.latitude}
                                                        lng={group.longitude}
                                                        item_count={group.associated_ids.length}
                                                        active={group.id === activeGroup}>
                                                        <div style={{ padding: "5px 15px" }}>
                                                            {Array.from(regions).map((region: any) =>
                                                                Array.from(region.environments).map((environment: any) =>
                                                                    Array.from(environment.items)
                                                                        .filter((item: any) => item.dashboard_group_id === group.id)
                                                                        .map((item: any) => (
                                                                            <SourceItem
                                                                                onClick={() => {
                                                                                    activeItem === item.id
                                                                                        ? setActiveItem("")
                                                                                        : setActiveItem(item.id);
                                                                                    setActiveEnvironment(environment.id);
                                                                                    setActiveRegion(region.id);
                                                                                    setActiveGroup(item.dashboard_group_id);
                                                                                }}
                                                                                onUpdateGroup={() => {
                                                                                    setLoadingMessage("Updating group...");
                                                                                    setLoading(true);
                                                                                }}
                                                                                onUpdateGeo={() => {
                                                                                    setLoadingMessage("Updating location...");
                                                                                    setLoading(true);
                                                                                }}
                                                                                active={item.id === activeItem}
                                                                                key={item.id}
                                                                                id={item.id}
                                                                                group_id={item.dashboard_group_id || ""}
                                                                                group_name={item.dashboard_group_name || ""}
                                                                                name={item.name}
                                                                                type="destination"
                                                                                alerts={Array.from(item.alerts)}
                                                                                lat={item.latitude}
                                                                                lng={item.longitude}
                                                                                environmentLat={environment.latitude}
                                                                                environmentLng={environment.longitude}
                                                                                description={item.description}
                                                                                onHideClick={() => toggleItemVisibility(item.id)}
                                                                                hidden={hiddenItems.includes(item.id)}
                                                                            />
                                                                        ))
                                                                )
                                                            )}
                                                        </div>
                                                    </GroupAccordion>
                                                ))}
                                        </div>
                                    </Panel>

                                    <Panel
                                        draggable
                                        id="data-panel"
                                        minHeight="0px"
                                        maxHeight="100%"
                                        title="Resources"
                                        defaultCollapsed={false}>
                                        <Fragment>
                                            {Array.from(regions).map((region: any, i) => (
                                                <RegionAccordion
                                                    key={i}
                                                    onHideClick={() => toggleRegionVisibility(region.id)}
                                                    hidden={hiddenRegions.includes(region.id)}
                                                    id={region.id}
                                                    region={region.region}
                                                    human_name={region.region_human_name}
                                                    provider={region.cloud_provider_human_name}
                                                    lng={region.longitude}
                                                    lat={region.latitude}
                                                    defaultExpanded={true}>
                                                    <Fragment>
                                                        {Array.from(region.environments).map((environment: any) => (
                                                            <EnvironmentAccordion
                                                                onHideClick={() => toggleEnvironmentVisibility(environment.id)}
                                                                hidden={hiddenEnvironments.includes(environment.id)}
                                                                key={environment.id}
                                                                id={environment.id}
                                                                name={environment.environment_name}
                                                                defaultExpanded={activeEnvironment === environment.id ? true : false}
                                                                itemCount={Array.from(environment.items).length}
                                                                status={environment.status}
                                                                active={activeEnvironment === environment.id}>
                                                                <ItemAccordion
                                                                    id="sources"
                                                                    icon="video"
                                                                    name={
                                                                        "Sources (" +
                                                                        Array.from(environment.items).filter(
                                                                            (item: any) => item.type === "channel"
                                                                        ).length +
                                                                        ")"
                                                                    }
                                                                    defaultExpanded={activeItemType === "channel" ? true : false}>
                                                                    <>
                                                                        {Array.from(environment.items)
                                                                            .filter((item: any) => item.type === "channel")
                                                                            .map((item: any) => (
                                                                                <SourceItem
                                                                                    id={item.id}
                                                                                    key={item.id}
                                                                                    name={item.name}
                                                                                    type="source"
                                                                                    onClick={() => {
                                                                                        activeItem === item.id
                                                                                            ? setActiveItem("")
                                                                                            : setActiveItem(item.id);
                                                                                        setActiveEnvironment(environment.id);
                                                                                        setActiveRegion(region.id);
                                                                                        setActiveGroup(item.dashboard_group_id);
                                                                                    }}
                                                                                    onHideClick={() => toggleItemVisibility(item.id)}
                                                                                    hidden={hiddenItems.includes(item.id)}
                                                                                    onUpdateGroup={() => {
                                                                                        setLoadingMessage("Updating group...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    onUpdateGeo={() => {
                                                                                        setLoadingMessage("Updating location...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    active={item.id === activeItem}
                                                                                    group_id={item.dashboard_group_id || ""}
                                                                                    group_name={item.dashboard_group_name || ""}
                                                                                    alerts={Array.from(item.alerts)}
                                                                                    lat={item.latitude}
                                                                                    lng={item.longitude}
                                                                                    environmentLat={environment.latitude}
                                                                                    environmentLng={environment.longitude}
                                                                                    description={item.description}
                                                                                />
                                                                            ))}
                                                                    </>
                                                                </ItemAccordion>

                                                                <ItemAccordion
                                                                    id="destinations"
                                                                    icon="broadcast-tower"
                                                                    name={
                                                                        "Destinations (" +
                                                                        Array.from(environment.items).filter(
                                                                            (item: any) => item.type === "destination"
                                                                        ).length +
                                                                        ")"
                                                                    }
                                                                    defaultExpanded={activeItemType === "destination" ? true : false}>
                                                                    <>
                                                                        {Array.from(environment.items)
                                                                            .filter((item: any) => item.type === "destination")
                                                                            .map((item: any) => (
                                                                                <SourceItem
                                                                                    onClick={() => {
                                                                                        activeItem === item.id
                                                                                            ? setActiveItem("")
                                                                                            : setActiveItem(item.id);
                                                                                        setActiveEnvironment(environment.id);
                                                                                        setActiveRegion(region.id);
                                                                                        setActiveGroup(item.dashboard_group_id);
                                                                                    }}
                                                                                    onUpdateGroup={() => {
                                                                                        setLoadingMessage("Updating group...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    onUpdateGeo={() => {
                                                                                        setLoadingMessage("Updating location...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    active={item.id === activeItem}
                                                                                    key={item.id}
                                                                                    id={item.id}
                                                                                    group_id={item.dashboard_group_id || ""}
                                                                                    group_name={item.dashboard_group_name || ""}
                                                                                    name={item.name}
                                                                                    type="destination"
                                                                                    alerts={Array.from(item.alerts)}
                                                                                    lat={item.latitude}
                                                                                    lng={item.longitude}
                                                                                    environmentLat={environment.latitude}
                                                                                    environmentLng={environment.longitude}
                                                                                    description={item.description}
                                                                                    onHideClick={() => toggleItemVisibility(item.id)}
                                                                                    hidden={hiddenItems.includes(item.id)}
                                                                                />
                                                                            ))}
                                                                    </>
                                                                </ItemAccordion>

                                                                <ItemAccordion
                                                                    id="multiviewers"
                                                                    icon="th-large"
                                                                    name={
                                                                        "Multiviewers (" +
                                                                        Array.from(environment.items).filter(
                                                                            (item: any) => item.type === "multiviewer"
                                                                        ).length +
                                                                        ")"
                                                                    }
                                                                    defaultExpanded={false}>
                                                                    <>
                                                                        {Array.from(environment.items)
                                                                            .filter((item: any) => item.type === "multiviewer")
                                                                            .map((item: any) => (
                                                                                <SourceItem
                                                                                    onClick={() => {
                                                                                        activeItem === item.id
                                                                                            ? setActiveItem("")
                                                                                            : setActiveItem(item.id);
                                                                                    }}
                                                                                    onUpdateGroup={() => {
                                                                                        setLoadingMessage("Updating group...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    onUpdateGeo={() => {
                                                                                        setLoadingMessage("Updating location...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    active={activeItem === item.id}
                                                                                    key={item.id}
                                                                                    id={item.id}
                                                                                    name={item.name}
                                                                                    type="multiviewer"
                                                                                    alerts={Array.from(item.alerts)}
                                                                                    lat={item.latitude}
                                                                                    lng={item.longitude}
                                                                                    environmentLat={environment.latitude}
                                                                                    environmentLng={environment.longitude}
                                                                                    description={item.description}
                                                                                    onHideClick={() => toggleItemVisibility(item.id)}
                                                                                    hidden={hiddenItems.includes(item.id)}
                                                                                />
                                                                            ))}
                                                                    </>
                                                                </ItemAccordion>

                                                                <ItemAccordion
                                                                    id="transcoders"
                                                                    icon="exchange"
                                                                    name={
                                                                        "Transcoders (" +
                                                                        Array.from(environment.items).filter(
                                                                            (item: any) => item.type === "transcoder"
                                                                        ).length +
                                                                        ")"
                                                                    }
                                                                    defaultExpanded={false}>
                                                                    <>
                                                                        {Array.from(environment.items)
                                                                            .filter((item: any) => item.type === "transcoder")
                                                                            .map((item: any) => (
                                                                                <SourceItem
                                                                                    onClick={() => {
                                                                                        activeItem === item.id
                                                                                            ? setActiveItem("")
                                                                                            : setActiveItem(item.id);
                                                                                    }}
                                                                                    onUpdateGroup={() => {
                                                                                        setLoadingMessage("Updating group...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    onUpdateGeo={() => {
                                                                                        setLoadingMessage("Updating location...");
                                                                                        setLoading(true);
                                                                                    }}
                                                                                    active={activeItem === item.id}
                                                                                    key={item.id}
                                                                                    id={item.id}
                                                                                    name={item.name}
                                                                                    type="transcoder"
                                                                                    alerts={Array.from(item.alerts)}
                                                                                    lat={item.latitude}
                                                                                    lng={item.longitude}
                                                                                    environmentLat={environment.latitude}
                                                                                    environmentLng={environment.longitude}
                                                                                    description={item.description}
                                                                                    onHideClick={() => toggleItemVisibility(item.id)}
                                                                                    hidden={hiddenItems.includes(item.id)}
                                                                                />
                                                                            ))}
                                                                    </>
                                                                </ItemAccordion>
                                                            </EnvironmentAccordion>
                                                        ))}
                                                    </Fragment>
                                                </RegionAccordion>
                                            ))}
                                        </Fragment>
                                    </Panel>
                                </div>
                            </Draggable>
                        </>
                    )}
                </div>
            </ViewBody>
        </View>
    );
});

export default Overview;
