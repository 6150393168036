import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, SxProps } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import React from "react";
import { Field, useForm } from "react-final-form";

interface Props {
    name: string;
    label: string;
    sx?: SxProps;
    initialValue?: boolean;
    disabled?: boolean;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    onChange?: (field: string, value?: any, previous?: any) => void;
}

/**
 * CheckboxField is a React Functional Component that renders a checkbox field with a label.
 * It uses the Material-UI library for styling and layout.
 *
 * @component
 * @param {object} props - The properties that define the CheckboxField component.
 * @param {string} props.name - The name of the checkbox field.
 * @param {string} props.label - The label of the checkbox field.
 * @param {SxProps} [props.sx] - The system properties used for styling.
 * @param {boolean} [props.initialValue] - The initial value of the checkbox field.
 * @param {function} props.helperText - A function that returns the helper text.
 * @param {function} props.error - A function that returns a boolean indicating if there is an error.
 *
 * @example
 * <CheckboxField name="agree" label="I agree to the terms and conditions" helperText={meta => meta.touched && meta.error} error={meta => meta.touched && meta.error} />
 *
 * @returns {ReactElement} The CheckboxField component
 */

export const CheckboxField: React.FC<Props> = ({ name, label, sx, initialValue, disabled, helperText, error, onChange }) => {
    const form = useForm();
    return (
        <Box sx={sx}>
            <Field name={name} type="checkbox" initialValue={initialValue}>
                {({ input, meta }) => (
                    <FormControl>
                        <FormControlLabel control={<Checkbox disabled={disabled} {...input} color="primary" />} label={label} />
                        {/* {meta.touched && meta.error && !helperText && (
                            <FormHelperText sx={{ ml: "28px", mt: "-10px" }}>{meta.error}</FormHelperText>
                        )}
                        {helperText && helperText(meta) && error && error(meta) && (
                            <FormHelperText sx={{ ml: "28px", mt: "-10px" }}>{helperText(meta)}</FormHelperText>
                        )} */}
                        <FormHelperText
                            error={Boolean(
                                (meta.touched && meta.error) || meta.submitError || (meta.touched && meta.invalid) || (error && error(meta))
                            )}
                            sx={{ ml: "28px", mt: "-10px" }}>
                            {(meta.touched && meta.error) || meta.submitError || (helperText && helperText(meta))}
                        </FormHelperText>
                    </FormControl>
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {
                    function removeEmpty(obj: any) {
                        Object.keys(obj).forEach((key) => {
                            if (obj[key] && typeof obj[key] === "object") {
                                removeEmpty(obj[key]);
                            }
                            if (obj[key] && Array.isArray(obj[key]) && !obj[key].length) {
                                delete obj[key];
                            }
                            if (obj[key] && typeof obj[key] === "object" && !Object.keys(obj[key]).length) {delete obj[key];}
                        });
                        return obj;
                    }

                    if (form.getState().submitErrors) {
                        const keys = name.split(".");
                        let submitErrors = form.getState().submitErrors;

                        for (let key of keys) {
                            if (submitErrors && typeof submitErrors === "object" && key in submitErrors) {
                                submitErrors = submitErrors[key];
                            } else {
                                submitErrors = undefined;
                                break;
                            }
                        }

                        if (submitErrors) {
                            console.log("Submit error on field", submitErrors);
                            // Deleting the error from the original object is a bit more complex because you need to navigate to the parent object
                            let parentObject = form.getState().submitErrors;
                            let grandParentObject;
                            let grandParentKey;
                            for (let i = 0; i < keys.length - 1; i++) {
                                if (parentObject && typeof parentObject === "object" && keys[i] in parentObject) {
                                    grandParentObject = parentObject;
                                    grandParentKey = keys[i];
                                    parentObject = parentObject[keys[i]];
                                } else {
                                    parentObject = undefined;
                                    break;
                                }
                            }
                            if (parentObject && typeof parentObject === "object") {
                                delete parentObject[keys[keys.length - 1]];
                                // Check if parentObject is now empty
                                if (Object.keys(parentObject).length === 0 && grandParentObject && grandParentKey) {
                                    delete grandParentObject[grandParentKey];
                                }
                            }
                        }

                        console.log(removeEmpty(form.getState().submitErrors));
                        //form.mutators.setSubmitErrors(removeEmpty(form.getState().submitErrors));
                    }

                    //form.mutators.setSubmitErrors(pruneEmptyErrors(submitErrors));

                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </Box>
    );
};

export default CheckboxField;
