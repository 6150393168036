import { Alert, Button, Checkbox, DialogActions, DialogContent, DialogContentText, FormControlLabel } from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext } from "react";

interface Props {
    cluster_id: string;
    cluster_name: string;
}

const ConfirmDeleteCluster: React.FC<Props> = (props) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const [error, setError] = React.useState<any>(null);
    const [confirm, setConfirm] = React.useState<boolean>(false);

    //async handleDeleteCluster()

    const handleDeleteCluster = async () => {
        await agent.FileCluster.deleteDeployment(props.cluster_id)
            .then(() => {
                closeDialog();
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the cluster {props.cluster_name}? This action cannot be undone.
                </DialogContentText>
                <FormControlLabel
                    control={<Checkbox checked={confirm} onChange={() => setConfirm(!confirm)} />}
                    label="I understand that this action cannot be undone."
                />
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error.data?.message}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <Button disabled={!confirm} onClick={handleDeleteCluster} color="error" variant="contained">
                    Delete
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmDeleteCluster;
