import { Typography } from "@mui/material";

import React from "react";

interface ISourceInfoProps {
    values: any;
}
const SourceInfo: React.FC<ISourceInfoProps> = ({ values }) => {
    return (
        <>
            {!values.input_feed_source || values.input_feed_source === "direct" ? (
                <Typography sx={{ mb: 1 }}>
                    <b>Direct Source</b> - A direct source refers to a video feed that is directly input into the streaming system. This
                    could be a live feed from a camera or a pre-recorded video file. The direct source is often used in live streaming
                    scenarios where the video content is being produced in real-time. The streaming system takes this direct input, encodes
                    it into a suitable format, and then delivers it to the viewers.
                </Typography>
            ) : null}
            {!values.input_feed_source || values.input_feed_source === "environment" ? (
                <Typography sx={{ mb: 1 }}>
                    <b>Environment Source</b> - An environment source, refers to a video feed that is obtained from an active environment.
                    The environment source is often used when the streaming system needs to re-stream existing video content or when the
                    video content is produced in a different location from the streaming system. The streaming system retrieves the video
                    content from the environment source, possibly re-encodes it, and then delivers it to the viewers.
                </Typography>
            ) : null}
            {values?.input_feed_source === "direct" ? (
                <>
                    <Typography sx={{ mb: 1 }}>
                        <b>Cloud Provider</b> - The Cloud Provider that the packager will be streamed from (we currently offer cloud hosting
                        via AWS, Digital Ocean or Google Cloud Platform).
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        <b>Region</b> - The geographical location where your packager will be streamed from (the options populating the list
                        will vary depending on the Cloud Hosting provider chosen at the previous step).
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        <b>Direct Source Type</b> - This is the method used to establish the connection between the video source and the
                        streaming system. The choice between them depends on your specific network conditions and requirements.
                    </Typography>

                    {!values?.direct_feed_input_details?.input_type || values?.direct_feed_input_details?.input_type === "srt_caller" ? (
                        <Typography sx={{ mb: 1 }}>
                            <b>SRT Caller</b> - SRT (Secure Reliable Transport) is an open-source video transport protocol that enables the
                            delivery of high-quality and secure, low-latency video across the public internet. In an SRT connection, the
                            Caller is the initiating party, similar to the client in a client-server architecture. The Caller typically
                            initiates the connection to the Listener by sending a call request.
                        </Typography>
                    ) : null}

                    {!values?.direct_feed_input_details?.input_type || values?.direct_feed_input_details?.input_type === "srt_listener" ? (
                        <Typography sx={{ mb: 1 }}>
                            <b>SRT Listener</b> - The SRT Listener is the receiving party in an SRT connection. It waits for an incoming
                            call from the SRT Caller. Once the call is received and accepted, the Listener can then receive the video stream
                            sent by the Caller.
                        </Typography>
                    ) : null}

                    {!values?.direct_feed_input_details?.input_type || values?.direct_feed_input_details?.input_type === "zixi_pull" ? (
                        <Typography sx={{ mb: 1 }}>
                            <b>Zixi Pull</b> - Zixi is another protocol for high-quality video streaming over the internet. In a Zixi Pull
                            scenario, the receiver (or puller) initiates the connection and pulls the stream from the sender. This is
                            typically used when the receiver is behind a firewall or other network configuration that prevents incoming
                            connections.
                        </Typography>
                    ) : null}

                    {values?.direct_feed_input_details?.input_type === "srt_caller" ? (
                        <>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input Label</b> - This is a user-defined identifier for the input source. It helps in distinguishing
                                between different sources, especially when dealing with multiple streams.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>TS Input Smoothing</b> - This refers to a feature that smooths out the input data rate for Transport
                                Stream (TS) inputs. It can help to reduce the impact of network jitter and improve the quality of the
                                stream.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input Smoothing Extra Delay</b> - This is an additional buffer time added to the Transport Stream (TS)
                                input smoothing process. The extra delay provides additional buffering time, which can further smooth out
                                variations in the data rate. However, adding extra delay can also increase the latency of the stream, which
                                might not be desirable in real-time streaming scenarios.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input URL</b> - This is the URL of the SRT stream that the Caller will connect to. It typically includes
                                the IP address or hostname of the Listener, and the port number.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Connection Timeout</b> - This is the maximum amount of time that the Caller will wait for a response from
                                the Listener when trying to establish a connection. If the Listener does not respond within this time, the
                                connection attempt will be aborted.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Stream Timeout</b> - This is the maximum amount of time that the Caller will wait for a response from the
                                Listener during the streaming process. If the Listener does not respond within this time, the stream will be
                                considered lost.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Latency</b> - This is the amount of time that the Caller will buffer the data before sending it.
                                Increasing the latency can help to improve the reliability of the stream in poor network conditions, but it
                                also increases the delay.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Encryption Mode</b> - SRT supports AES encryption for secure streaming. The encryption mode determines
                                how the encryption keys are exchanged between the Caller and the Listener. Options include "pre-shared key"
                                (where the same key is manually configured on both sides) and "key exchange" (where the keys are
                                automatically exchanged using a secure protocol).
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Password</b> - If encryption is enabled, this is the password that will be used to generate the
                                encryption key. The same password must be configured on both the Caller and the Listener.
                            </Typography>
                        </>
                    ) : null}

                    {values?.direct_feed_input_details?.input_type === "srt_listener" ? (
                        <>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input Label</b> - This is a user-defined identifier for the input source. It helps in distinguishing
                                between different sources, especially when dealing with multiple streams.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>TS Input Smoothing</b> - This refers to a feature that smooths out the input data rate for Transport
                                Stream (TS) inputs. It can help to reduce the impact of network jitter and improve the quality of the
                                stream.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input Smoothing Extra Delay</b> - This is an additional buffer time added to the Transport Stream (TS)
                                input smoothing process. The extra delay provides additional buffering time, which can further smooth out
                                variations in the data rate. However, adding extra delay can also increase the latency of the stream, which
                                might not be desirable in real-time streaming scenarios.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Port</b> - This is the network port that the SRT Listener will use to receive the incoming SRT stream.
                                The port number needs to be open on any firewalls and must not be used by any other services on the same
                                machine.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Timeout</b> - This is the maximum amount of time that the Listener will wait for a packet from the Caller
                                during the streaming process. If the Caller does not send a packet within this time, the stream will be
                                considered lost.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Latency</b> - This is the amount of time that the Caller will buffer the data before sending it.
                                Increasing the latency can help to improve the reliability of the stream in poor network conditions, but it
                                also increases the delay.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Encryption Mode</b> - SRT supports AES encryption for secure streaming. The encryption mode determines
                                how the encryption keys are exchanged between the Caller and the Listener. Options include "pre-shared key"
                                (where the same key is manually configured on both sides) and "key exchange" (where the keys are
                                automatically exchanged using a secure protocol).
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Password</b> - If encryption is enabled, this is the password that will be used to generate the
                                encryption key. The same password must be configured on both the Caller and the Listener.
                            </Typography>
                        </>
                    ) : null}

                    {values?.direct_feed_input_details?.input_type === "zixi_pull" ? (
                        <>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input Label</b> - This is a user-defined identifier for the input source. It helps in distinguishing
                                between different sources, especially when dealing with multiple streams.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>TS Input Smoothing</b> - This refers to a feature that smooths out the input data rate for Transport
                                Stream (TS) inputs. It can help to reduce the impact of network jitter and improve the quality of the
                                stream.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input Smoothing Extra Delay</b> - This is an additional buffer time added to the Transport Stream (TS)
                                input smoothing process. The extra delay provides additional buffering time, which can further smooth out
                                variations in the data rate. However, adding extra delay can also increase the latency of the stream, which
                                might not be desirable in real-time streaming scenarios.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Input URL</b> - This is the URL of the SRT stream that the Caller will connect to. It typically includes
                                the IP address or hostname of the Listener, and the port number.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Channel Name</b> - The channel name is used to identify the specific stream on the sender that the
                                receiver should pull.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Password</b> - If encryption is enabled, this is the password that will be used to generate the
                                encryption key.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Encryption Mode</b> - The channel name is used to identify the specific stream on the sender that the
                                receiver should pull.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Encryption Key</b> - If the "pre-shared key" encryption mode is used, this is the encryption key that
                                will be used to decrypt the stream. The same key must be configured on both the sender and the receiver.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Max Latency</b> - This is the maximum amount of time that the receiver will buffer the data before
                                playing it. Increasing the max latency can help to improve the reliability of the stream in poor network
                                conditions, but it also increases the delay.
                            </Typography>
                            <Typography sx={{ mb: 1 }}>
                                <b>Remote ID</b> - This is a unique identifier for the receiver. It is used by the sender to identify the
                                receiver and ensure that the stream is being sent to the correct destination.
                            </Typography>
                        </>
                    ) : null}
                </>
            ) : null}

            {values?.input_feed_source === "environment" ? (
                <>
                    <Typography sx={{ mb: 1 }}>
                        <b>Environment</b> - Choose which environment you would like to use as the source for your push packager.
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        <b>Permanent Destination Source</b> - If you would like to set this packager as a permanent source, you can do so by
                        selecting the input source from the dropdowns.
                    </Typography>
                </>
            ) : null}
        </>
    );
};

export default SourceInfo;
