import { Badge, Box, Button, IconButton, Menu, Tooltip, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import EnvironmentsList from "features/environments/Components/EnvironmentsList";
import { RootStoreContext } from "api";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import BlockIcon from "@mui/icons-material/Block";

const EnvironmentsMenu: React.FC = () => {
    const rootStore = React.useContext(RootStoreContext);

    const isGuest = rootStore.userStore.activeorganisationId !== rootStore.userStore.managingOrganisationId;

    const { environment, currentEnvStatus } = rootStore.environmentStore;

    const theme = useTheme();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {}, [currentEnvStatus, environment]);

    return (
        <>
            <Tooltip
                placement="bottom"
                title={
                    environment?.name && environment?.status !== "deleted"
                        ? "Active Environment: " + environment.name
                        : "No Active Environment"
                }>
                <IconButton
                    size="large"
                    onClick={handleClick}
                    sx={{
                        backgroundColor: open ? theme.palette.action.selected : undefined,
                        color: theme.palette.topbar?.contrastText
                    }}>
                    {/* <DnsIcon /> */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="18"
                        width="18"
                        fill={theme.palette.topbar?.contrastText}
                        viewBox="0 0 512 512">
                        <path d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
                    </svg>
                    {environment?.status === "deleted" && !open && (
                        <BlockIcon sx={{ position: "absolute", color: theme.palette.topbar?.main, fontSize: 42 }} />
                    )}
                    {environment && environment.status !== "deleted" && (
                        <Badge
                            color={currentEnvStatus === "running" ? "success" : currentEnvStatus === "stopped" ? "error" : "warning"}
                            variant="dot"
                            sx={{ position: "absolute", top: 11, right: 11 }}
                        />
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                id="notifications-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
                sx={{
                    p: "0px !important",
                    display: "flex",
                    flexDirection: "column",
                    "& .MuiMenu-paper": {
                        width: 320,
                        p: "0px !important"
                        //height: "100%"
                    },
                    "& .MuiList-root": {
                        pt: "0px !important",
                        pb: "0px !important"
                        //height: "100%"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}>
                <Box>
                    <EnvironmentsList />
                </Box>
                {!isGuest && (
                    <Box sx={{ padding: 1 }}>
                        <Button
                            fullWidth
                            variant="toolbar"
                            startIcon={<SettingsIcon />}
                            onClick={() => {
                                navigate("/environments");
                                handleClose();
                            }}>
                            Manage Environments
                        </Button>
                    </Box>
                )}
            </Menu>
        </>
    );
};

export default observer(EnvironmentsMenu);
