import { Grid, Tab } from "@mui/material";
import { ChartsTooltip, PieChart, PiePlot, ResponsiveChartContainer } from "@mui/x-charts";
import { IEnvironment, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import React from "react";
import { ChartPalette } from "styles/ChartPalette";

import { ResCollection, ResModel } from "resclient";
import { TabContext, TabList, TabPanel } from "@mui/lab";

interface EnvironmentResourcesProps {
    environment: IEnvironment;
}

const EnvironmentResources: React.FC<EnvironmentResourcesProps> = ({ environment }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;

    // "env_dashboard.790.region.aws-eu-west-1.env.66da0c3e-957e-4b33-91b9-19bd16be3dd1";

    const rid = `env_dashboard.${managing_org_id}.region.${environment.cloud_provider}-${environment.region}.env.${environment.id}`;

    const [wsData, setWsData] = React.useState<ResModel | ResCollection | any>({});

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const [activeTab, setActiveTab] = React.useState("1");
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    React.useEffect(() => {
        clientConnected &&
            client
                .get(rid)
                .then((res) => {
                    setWsData(res);
                    console.log("wsData", res);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setWsData(res);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [client, clientConnected, rid, environment]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <Grid container>
            <Grid item xs={3}>
                {wsData && (
                <ResponsiveChartContainer
                    disableAxisListener
                    height={300}
                    colors={ChartPalette}
                    series={[
                        {
                            type: "pie",
                            data: [
                                { id: 1, value: wsData.source_count, label: "Sources" },
                                { id: 2, value: wsData.destination_count, label: "Destinations" },
                                { id: 3, value: wsData.transcoder_count, label: "Transcoders" },
                                { id: 4, value: wsData.mv_count, label: "Multiviewers" }
                            ],
                            innerRadius: 55,
                            cornerRadius: 3,
                            paddingAngle: 5,
                            highlightScope: { faded: "global", highlighted: "item" }
                        }
                    ]}>
                    <PiePlot
                        skipAnimation
                        // onItemClick={(event, d) => {
                        //     setActiveTab(String(d.dataIndex + 1));
                        // }}
                    />
                    <ChartsTooltip trigger="item" />
                </ResponsiveChartContainer>
                )}
            </Grid>
            <Grid item xs={9}>
                <TabContext value={activeTab}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="Sources" value="1" />
                        <Tab label="Destinations" value="2" />
                        <Tab label="Transcoders" value="3" />
                        <Tab label="Multiviewers" value="4" />
                    </TabList>
                    <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        {wsData && wsData.items && Array.from(wsData.items)
                            .filter((item: any) => item.type === "channel")
                            .map((source: any) => (
                                <div key={source.id}>{source.name}</div>
                            ))}
                    </TabPanel>
                    <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        {wsData && wsData.items && Array.from(wsData.items)
                            .filter((item: any) => item.type === "destination")
                            .map((destination: any) => (
                                <div key={destination.id}>{destination.name}</div>
                            ))}
                    </TabPanel>
                    <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        3
                    </TabPanel>
                    <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                        4
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    );
};

export default observer(EnvironmentResources);
