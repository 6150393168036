import { useContext, useCallback } from "react";
import {
    agent,
    IUpdateTranscoderAudioTracks,
    IUpdateTranscoder,
    IRestartTranscoder,
    RootStoreContext,
    ICreateTranscoder,
    IInputFormValues,
    IInputAnalysisResponse
} from "api";

export const useSources = () => {
    const rootStore = useContext(RootStoreContext);
    const { activeenvironment } = rootStore.userStore;
    const {
        setTranscoderData,
        setTranscoder,
        setPresets,
        updateInputRegistry,
        setInputs,
        setEnvironmentInputs,
        setCurrentImageData,
        setInputAnalysisData,
        setInput
    } = rootStore.sourcesStore;

    const switchMVActiveAudioTrack = useCallback(async (id: string, values: any) => {
        return await agent.Channel.changeMultiChanAudio(id, values);
    }, []);

    const listMultiViewerAudioTracks = useCallback(async (id: string) => {
        return await agent.Channel.getMultiChanAudio(id);
    }, []);

    const getMultiViewerInfo = useCallback(async (id: string | undefined) => {
        return await agent.Channel.getMultiChanInfo(id);
    }, []);

    const editMultiChannel = useCallback(async (id: string | undefined, values: any) => {
        try {
            return await agent.Channel.editMultiChannel(id, values);
        } catch (error) {
            throw error;
        }
    }, []);

    const loadMultiViewerLayouts = useCallback(async () => {
        return await agent.Channel.getMultiChanLayouts();
    }, []);

    const editMultiChannelSources = useCallback(async (id: string | undefined, values: any) => {
        try {
            return await agent.Channel.editMultiChannelSources(id, values);
        } catch (error) {
            throw error;
        }
    }, []);

    const getInputsDropdownList = useCallback(
        async (id?: string) => {
            const envId = id || activeenvironment;

            if (!envId) {
                throw Error("Environment ID not populated");
            }

            return await agent.Channel.getInputsDropdownList(envId);
        },
        [activeenvironment]
    );

    const createMultiChannel = useCallback(async (values: any) => {
        try {
            return await agent.Channel.createMultiChannel(values);
        } catch (error) {
            throw error;
        }
    }, []);

    const updateTranscodersPidList = useCallback(async (id: string, body: IUpdateTranscoderAudioTracks) => {
        try {
            await agent.Channel.updateTranscoderPidList(id, body);
        } catch (error) {
            throw error;
        }
    }, []);

    const updateStandardTranscoder = useCallback(async (id: string, body: IUpdateTranscoder) => {
        try {
            await agent.Channel.updateStandardTranscoder(id, body);
        } catch (error) {
            throw error;
        }
    }, []);

    const stopTranscodedStream = useCallback(async (id: string) => {
        try {
            await agent.Channel.stopTranscoder(id);
        } catch (error) {
            throw error;
        }
    }, []);

    const startTranscodedStream = useCallback(async (id: string) => {
        try {
            await agent.Channel.startTranscoder(id);
        } catch (error) {
            throw error;
        }
    }, []);

    const getSingleTranscoderData = useCallback(async (id: string) => {
        return await agent.Channel.getSingleTranscoderData(id);
    }, []);

    const resetLiveStats = useCallback(async (id: string) => {
        try {
            const stats = await agent.Channel.resetLiveStats(id);
            return stats;
        } catch (error) {
            throw error;
        }
    }, []);

    const resetInputAnalysis = useCallback(async (id: string) => {
        try {
            const stats = await agent.Channel.resetInputAnalysis(id);
            return stats;
        } catch (error) {
            throw error;
        }
    }, []);

    const restartStoppedStream = useCallback(async (id: string) => {
        try {
            await agent.Channel.startStoppedInput(id);
        } catch (error) {
            throw error;
        }
    }, []);

    const deletePreset = useCallback(async (id: number) => {
        try {
            await agent.Channel.deletePreset(id);
        } catch (error) {
            throw error;
        }
    }, []);

    const getMultiViewerPresetsClean = useCallback(async () => {
        return await agent.Channel.getMultiViewerPresets();
    }, []);

    const restartTranscoder = useCallback(async (body: IRestartTranscoder) => {
        try {
            await agent.Channel.restartTranscoder(body);
        } catch (error) {
            throw error;
        }
    }, []);

    const deleteTranscoder = useCallback(async (id: string) => {
        try {
            await agent.Channel.deleteTranscoder(id);
        } catch (error) {
            throw error;
        }
    }, []);

    const probeTranscoderAudioTracks = useCallback(async (transcoder_id: string) => {
        return await agent.Channel.probeTranscoderAudioTracks(transcoder_id);
    }, []);

    const getInputLiveStats = useCallback(async (id: string) => {
        try {
            const liveStats = await agent.Channel.getInputLiveStats(id);

            if (liveStats) {
                return liveStats;
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const loadInputClean = useCallback(async (id: string) => {
        const Input = await agent.Channel.getInput(id);
        return Input.data;
    }, []);

    const getTranscoderData = useCallback(async () => {
        try {
            const transcoderData = await agent.Channel.getTranscoderData();

            if (transcoderData) {
                setTranscoderData(transcoderData);
            }
            return transcoderData;
        } catch (error) {
            throw error;
        }
    }, [setTranscoderData]);

    // TODO: This isn't working - even if i revert the create function fails so its not related to this but still need to fix.
    const createTranscoder = useCallback(
        async (data: ICreateTranscoder) => {
            try {
                const newTranscoder = await agent.Channel.createTranscoder(data);

                if (newTranscoder) {
                    setTranscoder(newTranscoder);
                }

                return newTranscoder;
            } catch (error) {
                throw error;
            }
        },
        [setTranscoder]
    );

    // TODO: Untested - as need the above working to create a preset and then delete for this to run correctly.
    const getPresets = useCallback(async () => {
        console.log("getPresets");
        try {
            const presets = await agent.Channel.getPresets();

            if (presets) {
                setPresets(presets);
            }

            return presets;
        } catch (error) {
            throw error;
        }
    }, [setPresets]);

    const createInput = useCallback(
        async (values: IInputFormValues) => {
            try {
                rootStore.sourcesStore.submitting = true;
                const input = await agent.Channel.createInput(values);

                if (input) {
                    updateInputRegistry(input);
                }

                return input;
            } catch (error) {
                throw error;
            }
        },
        [updateInputRegistry, rootStore.sourcesStore]
    );

    const editInput = useCallback(
        async (values: IInputFormValues) => {
            try {
                let newValues = { ...values };

                if (newValues.type === "Zixi Push") {
                    newValues.type = "push";
                }
                if (newValues.type === "Zixi Pull") {
                    newValues.type = "pull";
                }
                if (newValues.type === "RIST") {
                    newValues.type = "udp-rist";
                }
                if (newValues.type === "SRT Listener") {
                    newValues.type = "srt-push";
                }
                if (newValues.type === "SRT Caller") {
                    newValues.type = "srt-pull";
                }

                const input = await agent.Channel.editInput(newValues);

                if (input) {
                    updateInputRegistry(input);
                    setInput(input);
                }

                return input;
            } catch (error) {
                throw error;
            }
        },
        [updateInputRegistry, rootStore.sourcesStore]
    );

    const loadInputs = useCallback(
        async (environment_id: string) => {
            try {
                const inputs = await agent.Channel.getInputs(environment_id);

                if (inputs) {
                    setInputs(inputs);
                }

                return inputs;
            } catch (error) {
                throw error;
            }
        },
        [setInputs]
    );

    const loadInputsByEnvid = useCallback(
        async (envId?: string) => {
            try {
                const environmentId = envId || activeenvironment;

                if (!environmentId) {
                    throw Error("Environment ID not populated");
                }

                const inputs = await agent.Channel.getInputsById(environmentId);

                if (inputs) {
                    setEnvironmentInputs(inputs);
                }

                return inputs;
            } catch (error) {
                throw error;
            }
        },
        [activeenvironment, setEnvironmentInputs]
    );

    const getInputImage = useCallback(
        async (id: string) => {
            try {
                const imageData = await agent.Channel.getInputImage(id);

                if (imageData) {
                    setCurrentImageData(imageData);
                }

                return imageData;
            } catch (error) {
                throw error;
            }
        },
        [setCurrentImageData]
    );

    const getInputAnalysisData = useCallback(
        async (id: string): Promise<IInputAnalysisResponse> => {
            try {
                const response = await agent.Channel.fetchInputAnalysisData(id);

                let return_data: IInputAnalysisResponse = {
                    http_status_code: response.status,
                    status: "unknown",
                    message: "unknown",
                    data_group_1: [],
                    data_group_2: [],
                    got_errors: false
                };

                if (response.data) {
                    return_data.status = response.data.status;
                    return_data.message = response.data.status;
                }
                if (response.status === 200) {
                    return_data.data_group_1 = response.data.data_group_1;
                    return_data.data_group_2 = response.data.data_group_2;
                    return_data.got_errors = response.data.got_errors;
                }
                return return_data;
            } catch (error) {
                throw error;
            }
        },
        [setInputAnalysisData]
    );

    return {
        editMultiChannel,
        editMultiChannelSources,
        getMultiViewerInfo,
        listMultiViewerAudioTracks,
        loadMultiViewerLayouts,
        switchMVActiveAudioTrack,
        getInputsDropdownList,
        createMultiChannel,
        updateTranscodersPidList,
        updateStandardTranscoder,
        stopTranscodedStream,
        startTranscodedStream,
        getSingleTranscoderData,
        resetLiveStats,
        resetInputAnalysis,
        restartStoppedStream,
        deletePreset,
        getMultiViewerPresetsClean,
        restartTranscoder,
        deleteTranscoder,
        probeTranscoderAudioTracks,
        getInputLiveStats,
        loadInputClean,
        getTranscoderData,
        createTranscoder,
        getPresets,
        createInput,
        editInput,
        loadInputs,
        loadInputsByEnvid,
        getInputImage,
        getInputAnalysisData
    };
};

export default useSources;
