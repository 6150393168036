import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

interface ICreateItemSuccess {
    mode: "create" | "edit";
    type: string;
    name: string;
    progressStatus: string;
    currentStep: number;
    totalSteps: number;
    retries: number;
    onRetry: () => void;
}

export const CreateItemSuccess: React.FC<ICreateItemSuccess> = ({
    mode,
    type,
    name,
    progressStatus,
    currentStep,
    totalSteps,
    retries,
    onRetry
}) => {
    const calculateProgress = (currentStep: number, totalSteps: number) => {
        if (currentStep === 0) {
            return 0;
        }
        return Math.floor((currentStep / totalSteps) * 100);
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                {mode === "create" ? `Your ${type} is now being created` : `Your ${type} is now updating`}
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
                {`${type} Name: ${name}`}
            </Typography>
            {mode === "create" && (
                <Box mb={3}>
                    <LinearProgress
                        sx={{ borderRadius: 1, height: 10 }}
                        variant="determinate"
                        color={totalSteps > 0 && currentStep === totalSteps ? "success" : "primary"}
                        value={calculateProgress(currentStep, totalSteps)}
                    />
                    <Typography variant="body2" mt={1}>
                        {progressStatus} - {calculateProgress(currentStep, totalSteps)}%
                    </Typography>
                </Box>
            )}
            <Typography sx={{ mb: 1 }}>
                {mode === "create"
                    ? `The new ${type} is being created with the required settings. Please be patient as this process may take a while.`
                    : `The ${type} is being updated with the required settings. Please be patient as this process may take a while.`}
            </Typography>
            <Typography sx={{ mb: 1 }}>
                You can close this window and continue using the platform, you will be notified when the {type} is ready.
            </Typography>
            <Typography>
                You can make a change to the settings of the {type} when the creation is complete and you have been notified that it is
                running.
            </Typography>
        </Box>
    );
};

export default CreateItemSuccess;
