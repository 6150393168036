import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import { format, subMonths } from "date-fns";
import { agent, IActivityLogRequest, IActivityLogResponse } from "api";
import { Select, FormControl, InputLabel, Pagination, Stack, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DataGridPro, GridSortModel, gridClasses } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Content from "components/Layout/Content";

export interface DataRowModel {
    id: number;
    [time: string]: number | string;
    user_email: string;
    action: string;
    action_tag: string;
    environment_name: string;
    transcoder_name: string;
    source_name: string;
    destination_name: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UserActivity = observer(function UserActivity() {

    const { email } = useParams();

    const handleParamsChange = (email) => {
        setRequest({ ...request, user_email: email });
    }

    useEffect(() => {
        handleParamsChange(email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);


    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "=") {
            event.preventDefault();
        }
    };

    const [request, setRequest] = useState<IActivityLogRequest>({
        page: 1,
        sort: "time:desc",
        date_from: format(subMonths(new Date(), 1), "yyyy-MM-dd") + " 00:00:00",
        date_to: format(new Date(), "yyyy-MM-dd") + " 23:59:59",
        user_email: email
    });

    const ref = React.useRef<HTMLDivElement>(null);

    const handlePagination = (event: React.MouseEvent<HTMLAnchorElement>, newPage: number) => {
        const newActivePage: any = newPage || 1;
        setRequest({ ...request, page: newActivePage ? newActivePage : 1 });
        if (ref.current) {
            ref.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    const handlePagination2 = (newPage: number) => {
        const newActivePage: any = newPage || 1;
        setRequest({ ...request, page: newActivePage ? newActivePage : 1 });
        if (ref.current) {
            ref.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: "time",
            sort: "desc"
        }
    ]);

    const handleSort = async (sortModel: GridSortModel) => {
        if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
            setRequest({
                ...request,
                sort:
                    (sortModel[0].field ? sortModel[0].field : "id") +
                    ":" +
                    (sortModel[0].sort && sortModel[0].field ? sortModel[0].sort : "desc")
            });
        } else {
            setRequest({ ...request, sort: "time:desc" });
        }
        setSortModel(sortModel && sortModel[0] && sortModel[0].field ? sortModel : [{ field: "id", sort: "desc" }]);
        setTimeout(() => {}, 1000);

        if (ref.current) {
            ref.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setRequest((request: IActivityLogRequest) => {
                const copy = { ...request };
                delete copy["search"];
                return copy;
            });
        } else {
            setRequest({ ...request, search: event.target.value });
        }
    };

    const handleCreatedDateGtChange = (date: any) => {
        setRequest({ ...request, date_from: format(new Date(date), "yyyy-MM-dd HH:mm:ss") });
    };

    const handleCreatedDateLtChange = (date: any) => {
        setRequest({ ...request, date_to: format(new Date(date), "yyyy-MM-dd HH:mm:ss") });
    };

    const handleUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setRequest((request: IActivityLogRequest) => {
                const copy = { ...request };
                delete copy["user_email"];
                return copy;
            });
        } else {
            setRequest({ ...request, user_email: event.target.value });
        }
    };

    const handleEnvironmentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setRequest((request: IActivityLogRequest) => {
                const copy = { ...request };
                delete copy["environment_name"];
                return copy;
            });
        } else {
            setRequest({ ...request, environment_name: event.target.value });
        }
    };

    const handleTranscoderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setRequest((request: IActivityLogRequest) => {
                const copy = { ...request };
                delete copy["transcoder_name"];
                return copy;
            });
        } else {
            setRequest({ ...request, transcoder_name: event.target.value });
        }
    };

    const handleSourceNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setRequest((request: IActivityLogRequest) => {
                const copy = { ...request };
                delete copy["source_name"];
                return copy;
            });
        } else {
            setRequest({ ...request, source_name: event.target.value });
        }
    };

    const handleDestinationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "") {
            setRequest((request: IActivityLogRequest) => {
                const copy = { ...request };
                delete copy["destination_name"];
                return copy;
            });
        } else {
            setRequest({ ...request, destination_name: event.target.value });
        }
    };

    const handleActionTagChange = (event: React.ChangeEvent<{ name?: string | undefined; value: string }>) => {
        if (event.target.value === "") {
            setRequest((request: IActivityLogRequest) => {
                const copy = { ...request };
                delete copy["action_tag"];
                return copy;
            });
        } else {
            setRequest({ ...request, action_tag: event.target.value || "" });
        }
    };

    const [activity, setActivity] = useState<IActivityLogResponse>();

    const [rows, setRows] = useState<DataRowModel[]>([]);
    const getActivity = async () => {
        await agent.Users.getUsersActivity(request)
            .then((response) => {
                setActivity(response);
                setRows(
                    response.data.map((item: any) => {
                        return {
                            id: item.id,
                            time: item.time,
                            user_email: item.user_email,
                            action: item.action,
                            action_tag: item.action_tag,
                            environment_name: item.environment_name,
                            transcoder_name: item.transcoder_name,
                            source_name: item.source_name,
                            destination_name: item.destination_name
                        };
                    })
                );
            })
            .catch((err: any) => {
                console.log(err);
                toast.error(err.message);
            });
    };

    useEffect(() => {
        getActivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request, sortModel]);

    {
        console.log("activity", activity);
    }

    return (
        <View>
            <ViewHeader title="User Activity" />
            <ViewBody noPadding>
                <Content
                    noPadding
                    headerContent={
                        <Stack direction="row" spacing={2} sx={{ py: 2, px: 1 }} justifyContent="flex-end">
                            <Pagination
                                page={request.page}
                                count={activity?.total_pages || 1}
                                shape="rounded"
                                onChange={(event: any, page: number) => {
                                    handlePagination(event, page);
                                }}
                            />
                        </Stack>
                    }
                    leftContent={
                        <Box sx={{ p: 3 }}>
                            <TextField
                                variant="standard"
                                name="search"
                                id="search"
                                label="Search"
                                onChange={handleSearchChange}
                                onKeyPress={handleKeyPress}
                                style={{ marginBottom: 10 }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date From"
                                    value={new Date(request.date_from!)}
                                    onChange={handleCreatedDateGtChange}
                                    disableFuture={true}
                                    format="dd/MM/yyyy"
                                />
                                <TimePicker label="Time From" value={new Date(request.date_from!)} onChange={handleCreatedDateGtChange} />
                                <DatePicker
                                    label="Date To"
                                    value={new Date(request.date_to!)}
                                    onChange={handleCreatedDateLtChange}
                                    disableFuture={true}
                                    format="dd/MM/yyyy"
                                />
                                <TimePicker label="Time To" value={new Date(request.date_to!)} onChange={handleCreatedDateLtChange} />
                            </LocalizationProvider>
                            <TextField
                                variant="standard"
                                name="user_email"
                                id="user_email"
                                label="User Email"
                                defaultValue={request.user_email || ""}
                                onChange={handleUserEmailChange}
                                style={{ marginBottom: 10 }}
                            />
                            <TextField
                                variant="standard"
                                name="environment_name"
                                id="environment_name"
                                label="Environment Name"
                                onChange={handleEnvironmentNameChange}
                                style={{ marginBottom: 10 }}
                            />
                            <TextField
                                variant="standard"
                                name="transcoder_name"
                                id="transcoder_name"
                                label="Transcoder Name"
                                onChange={handleTranscoderNameChange}
                                style={{ marginBottom: 10 }}
                            />
                            <TextField
                                variant="standard"
                                name="source_name"
                                id="source_name"
                                label="Source Name"
                                onChange={handleSourceNameChange}
                                style={{ marginBottom: 10 }}
                            />
                            <TextField
                                variant="standard"
                                name="destination_name"
                                id="destination_name"
                                label="Destination Name"
                                onChange={handleDestinationNameChange}
                                style={{ marginBottom: 10 }}
                            />
                            <FormControl fullWidth variant="standard">
                                <InputLabel id="action-tag-label">Action Tag</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="action-tag-label"
                                    label="Action Tag"
                                    id="action_tag"
                                    value={request.action_tag || ""}
                                    onChange={(event: any) => handleActionTagChange(event)}>
                                    <MenuItem value="added_user_to_organisation">
                                        <div>added_user_to_organisation</div>
                                    </MenuItem>
                                    <MenuItem value="add_host_to_mtr_host_group">add_host_to_mtr_host_group</MenuItem>
                                    <MenuItem value="amended_schedule">amended_schedule</MenuItem>
                                    <MenuItem value="create_alert">create_alert</MenuItem>
                                    <MenuItem value="create_dashboard_group">create_dashboard_group</MenuItem>
                                    <MenuItem value="create_environment">create_environment</MenuItem>
                                    <MenuItem value="create_environment_image">create_environment_image</MenuItem>
                                    <MenuItem value="create_mtr_host_group">create_mtr_host_group</MenuItem>
                                    <MenuItem value="create_multi_viewer">create_multi_viewer</MenuItem>
                                    <MenuItem value="create_pull_input">create_pull_input</MenuItem>
                                    <MenuItem value="create_pull_output">create_pull_output</MenuItem>
                                    <MenuItem value="create_push_input">create_push_input</MenuItem>
                                    <MenuItem value="create_push_output">create_push_output</MenuItem>
                                    <MenuItem value="create_srtp_push_output">create_srtp_push_output</MenuItem>
                                    <MenuItem value="create_srtpull_output">create_srtpull_output</MenuItem>
                                    <MenuItem value="create_tf_deployment">create_tf_deployment</MenuItem>
                                    <MenuItem value="create_transcoder">create_transcoder</MenuItem>
                                    <MenuItem value="create_transcoder_image">create_transcoder_image</MenuItem>
                                    <MenuItem value="create_udprist_input">create_udprist_input</MenuItem>
                                    <MenuItem value="delete_alert">delete_alert</MenuItem>
                                    <MenuItem value="delete_dashboard_group">delete_dashboard_group</MenuItem>
                                    <MenuItem value="deleted_destination">deleted_destination</MenuItem>
                                    <MenuItem value="deleted_schedule">deleted_schedule</MenuItem>
                                    <MenuItem value="delete_environment">delete_environment</MenuItem>
                                    <MenuItem value="delete_environment_image">delete_environment_image</MenuItem>
                                    <MenuItem value="delete_input">delete_input</MenuItem>
                                    <MenuItem value="delete_mtr_host">delete_mtr_host</MenuItem>
                                    <MenuItem value="delete_mtr_host_group">delete_mtr_host_group</MenuItem>
                                    <MenuItem value="delete_mtr_report">delete_mtr_report</MenuItem>
                                    <MenuItem value="delete_multi_viewer">delete_multi_viewer</MenuItem>
                                    <MenuItem value="delete_notification">delete_notification</MenuItem>
                                    <MenuItem value="delete_organisation">delete_organisation</MenuItem>
                                    <MenuItem value="delete_tf_deployment">delete_tf_deployment</MenuItem>
                                    <MenuItem value="delete_transcoder">delete_transcoder</MenuItem>
                                    <MenuItem value="delete_transcoder_image">delete_transcoder_image</MenuItem>
                                    <MenuItem value="generate_mtr_report">generate_mtr_report</MenuItem>
                                    <MenuItem value="geolocate_mtr_report">geolocate_mtr_report</MenuItem>
                                    <MenuItem value="mark_notification_read">mark_notification_read</MenuItem>
                                    <MenuItem value="mute_alert">mute_alert</MenuItem>
                                    <MenuItem value="remove_user_from_org">remove_user_from_org</MenuItem>
                                    <MenuItem value="remove_user_from_system">remove_user_from_system</MenuItem>
                                    <MenuItem value="request_alert_refresh">request_alert_refresh</MenuItem>
                                    <MenuItem value="request_schedule_refresh">request_schedule_refresh</MenuItem>
                                    <MenuItem value="scheduled_item">scheduled_item</MenuItem>
                                    <MenuItem value="schedule_upgrade">schedule_upgrade</MenuItem>
                                    <MenuItem value="skip_2fa">skip_2fa</MenuItem>
                                    <MenuItem value="soft_restart_environment">soft_restart_environment</MenuItem>
                                    <MenuItem value="start_c_env">start_c_env</MenuItem>
                                    <MenuItem value="start_input">start_input</MenuItem>
                                    <MenuItem value="start_tf_deployment">start_tf_deployment</MenuItem>
                                    <MenuItem value="start_transcoder">start_transcoder</MenuItem>
                                    <MenuItem value="stop_c_env">stop_c_env</MenuItem>
                                    <MenuItem value="stop_tf_deployment">stop_tf_deployment</MenuItem>
                                    <MenuItem value="stop_transcoder">stop_transcoder</MenuItem>
                                    <MenuItem value="store_2fa_token">store_2fa_token</MenuItem>
                                    <MenuItem value="switch_active_environment">switch_active_environment</MenuItem>
                                    <MenuItem value="switch_managing_organisation">switch_managing_organisation</MenuItem>
                                    <MenuItem value="tf_delete_credentials">tf_delete_credentials</MenuItem>
                                    <MenuItem value="tf_insert_credentials">tf_insert_credentials</MenuItem>
                                    <MenuItem value="tf_update_credentials">tf_update_credentials</MenuItem>
                                    <MenuItem value="un_mute_alert">un_mute_alert</MenuItem>
                                    <MenuItem value="update_alert">update_alert</MenuItem>
                                    <MenuItem value="update_dashboard_group">update_dashboard_group</MenuItem>
                                    <MenuItem value="update_input_dashboard_group">update_input_dashboard_group</MenuItem>
                                    <MenuItem value="update_input_latlong">update_input_latlong</MenuItem>
                                    <MenuItem value="update_mtr_host_group">update_mtr_host_group</MenuItem>
                                    <MenuItem value="update_multi_viewer">update_multi_viewer</MenuItem>
                                    <MenuItem value="update_output_dashboard_group">update_output_dashboard_group</MenuItem>
                                    <MenuItem value="update_output_latlong">update_output_latlong</MenuItem>
                                    <MenuItem value="update_password">update_password</MenuItem>
                                    <MenuItem value="update_pull_input">update_pull_input</MenuItem>
                                    <MenuItem value="update_push_input">update_push_input</MenuItem>
                                    <MenuItem value="update_region_latlong">update_region_latlong</MenuItem>
                                    <MenuItem value="update_user_permission">update_user_permission</MenuItem>
                                    <MenuItem value="upgrade_tf_deployment">upgrade_tf_deployment</MenuItem>
                                    <MenuItem value="user_added_to_org">user_added_to_org</MenuItem>
                                    <MenuItem value="user_login">user_login</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    }
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }} ref={ref}>
                            {activity && (
                                <DataGridPro
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 2
                                        },
                                        border: 0
                                    }}
                                    columns={[
                                        { field: "id", headerName: "ID", width: 100, type: "string", hideable: true, hide: true },
                                        {
                                            field: "time",
                                            headerName: "Time (UTC)",
                                            width: 160,
                                            type: "dateTime",
                                            valueFormatter: (params) => {
                                                return new Date(params.value).toLocaleString();
                                            }
                                        },
                                        {
                                            field: "user_email",
                                            headerName: "User",
                                            width: 300,
                                            type: "string",
                                            renderCell: (params) => {
                                                return params.value ? <Box>{params.value}</Box> : "System";
                                            }
                                        },
                                        { field: "action", headerName: "Action", type: "string", flex: 1 },
                                        {
                                            field: "environment_name",
                                            headerName: "Environment",
                                            type: "string",
                                            cellClassName: "cell-wrap-text"
                                        },
                                        { field: "transcoder_name", headerName: "Transcoder", type: "string" },
                                        { field: "source_name", headerName: "Source", type: "string" }
                                    ]}
                                    getRowHeight={() => "auto"}
                                    rows={rows}
                                    rowCount={activity.total_entries}
                                    rowsPerPageOptions={[100]}
                                    sortingMode="server"
                                    sortModel={sortModel}
                                    onSortModelChange={(newSortModel) => {
                                        handleSort(newSortModel);
                                    }}
                                    disableColumnFilter={true}
                                    page={request.page ? request.page - 1 : 0}
                                    pageSize={100}
                                    paginationMode="server"
                                    pagination
                                    onPageChange={(newPage) => handlePagination2(newPage + 1)}
                                    isRowSelectable={() => false}
                                />
                            )}
                        </Box>
                    </Box>
                </Content>
            </ViewBody>
        </View>
    );
});

export default UserActivity;
