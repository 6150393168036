import { observable, action, makeObservable } from "mobx";
import { RootStore } from "./rootStore";
import { ISourcePermissions, ISourcePermission, IMyGuestOrganisations, IContacts } from "api";

export default class SourcePermissionsStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable sourcePermissions: ISourcePermissions | null = null;
    @observable sourcePermission: ISourcePermission | null = null;
    @observable myGuestOrganisationsData: IMyGuestOrganisations | null = null;
    @observable nOfActiveDestOrgs = 0;
    @observable contacts: IContacts | null = null;

    @action setSourcePermissions = (permissions: ISourcePermissions) => {
        this.sourcePermissions = permissions;
    };

    @action setSourcePermission = (permission: ISourcePermission) => {
        this.sourcePermission = permission;
    };

    @action setMyGuestOrganisationsData = (data: IMyGuestOrganisations) => {
        this.myGuestOrganisationsData = data;
    };

    @action setNOfActiveDestOrgs = (n: number) => {
        this.nOfActiveDestOrgs = n;
    };

    @action setContacts = (contacts: IContacts) => {
        this.contacts = contacts;
    };
}
