import { Divider } from "@mui/material";
import { RadioGroupField, SelectField, SliderField, TextField } from "components/Forms";
import { FormApi } from "final-form";
import React from "react";
import { useFormState } from "react-final-form";

interface Props {
    form: FormApi;
    videoTrackIndex: number;
}

export const VideoTrackForm: React.FC<Props> = ({ form, videoTrackIndex }) => {
    const { values, submitErrors } = useFormState();
    return (
        <>
            <RadioGroupField
                name={`individual_track_configuration.video_tracks.${videoTrackIndex}.codec`}
                label="Codec"
                row
                options={[
                    { label: "MPEG-4", value: "MPEG-4" },
                    { label: "HEVC", value: "HEVC" }
                ]}
                required
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                }}
                onChange={(field, value) => {
                    values.individual_track_configuration.video_tracks[videoTrackIndex].profile = "Main";
                    if (value === "MPEG-4" && values?.output_colorimetry_parameters?.enable_dolby_vision) {
                        values.output_colorimetry_parameters.enable_dolby_vision = false;
                    }
                }}
            />
            <Divider sx={{ my: 2 }} />
            <SelectField
                name={`individual_track_configuration.video_tracks.${videoTrackIndex}.frame_size`}
                label="Frame Size"
                required
                options={[
                    { key: "426x240", text: "426x240", value: "426x240" },
                    { key: "640x360", text: "640x360", value: "640x360" },
                    { key: "720x480", text: "720x480", value: "720x480" },
                    { key: "720x576", text: "720x576", value: "720x576" },
                    { key: "854x480", text: "854x480", value: "854x480" },
                    { key: "960x540", text: "960x540", value: "960x540" },
                    { key: "1280x720", text: "1280x720", value: "1280x720" },
                    { key: "1920x1080", text: "1920x1080", value: "1920x1080" },
                    { key: "3840x2160", text: "3840x2160", value: "3840x2160" }
                ]}
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                }}
            />
            <SelectField
                name={`individual_track_configuration.video_tracks.${videoTrackIndex}.frame_rate`}
                label="Frame Rate"
                required
                options={[
                    { key: "23.98", text: "23.98", value: "23.98" },
                    { key: "24", text: "24", value: "24" },
                    { key: "25", text: "25", value: "25" },
                    { key: "29.97", text: "29.97", value: "29.97" },
                    { key: "30", text: "30", value: "30" },
                    { key: "50", text: "50", value: "50" },
                    { key: "59.94", text: "59.94", value: "59.94" },
                    { key: "60", text: "60", value: "60" }
                ]}
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                }}
            />
            <SliderField
                name={`individual_track_configuration.video_tracks.${videoTrackIndex}.bitrate`}
                label="Bitrate (kbps)"
                initialValue={
                    values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.bitrate
                        ? values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.bitrate
                        : 5000
                }
                min={0}
                max={
                    values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.codec === "MPEG-4"
                        ? 240000
                        : values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.codec === "HEVC"
                        ? 800000
                        : 800000
                }
                step={500}
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                    if (
                        values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.codec === "MPEG-4" &&
                        (value < 1 || value > 240000)
                    ) {
                        return "Bitrate must be between 1 and 240000 for MPEG-4";
                    }
                    if (
                        values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.codec === "HEVC" &&
                        (value < 1 || value > 800000)
                    ) {
                        return "Bitrate must be between 1 and 800000 for HEVC";
                    }
                }}
            />
            <SliderField
                name={`individual_track_configuration.video_tracks.${videoTrackIndex}.gop_size`}
                label="GOP Size"
                initialValue={
                    values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.gop_size
                        ? values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.gop_size
                        : 50
                }
                min={1}
                max={600}
                step={1}
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value < 1 || value > 600) {
                        return "GOP Size must be between 1 and 600";
                    }
                }}
            />

            <RadioGroupField
                name={`individual_track_configuration.video_tracks.${videoTrackIndex}.profile`}
                label="Profile"
                initialValue={values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.profile ? values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.profile : "Main"}
                row
                options={
                    values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.codec === "MPEG-4"
                        ? [
                              { label: "Baseline", value: "Baseline" },
                              { label: "Main", value: "Main" },
                              { label: "High", value: "High" },
                              { label: "High 4:2:2 10", value: "High 4:2:2 10" }
                          ]
                        : [
                              { label: "Main", value: "Main" },
                              { label: "Main 10", value: "Main 10" },
                              { label: "Main 4:2:2 10", value: "Main 4:2:2 10" }
                          ]
                }
                required
                validate={(value: any) => {
                    if (!value) {
                        return "Required";
                    }
                }}
            />

{/* "individual_track_configuration" -> "video_tracks" -> "level":
-> Options for "MPEG-4": "Auto", "1.0", "1.1", "1.2", "1.3", "1.b", "2.0", "2.1", "2.2", "3.0", "3.1", "3.2", "4.0", "4.1", "4.2", "5.0", "5.1", "5.2"
-> Options for "HEVC": "Auto", "1.0 Main Tier", "2.0 Main Tier", "2.1 Main Tier", "3.0 Main Tier", "3.1 Main Tier", "4.0 Main Tier", "4.1 Main Tier", "5.0 Main Tier", "5.1 Main Tier", "5.2 Main Tier", "6.0 Main Tier", "6.1 Main Tier", "6.2 Main Tier", "4.0 High Tier", "4.1 High Tier", "5.0 High Tier", "5.1 High Tier", "5.2 High Tier", "6.0 High Tier", "6.1 High Tier", "6.2 High Tier" */}

            <SelectField
                name={`individual_track_configuration.video_tracks.${videoTrackIndex}.level`}
                label="Level"
                initialValue={values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.level ? values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.level : "Auto"}
                options={
                    values.individual_track_configuration?.video_tracks?.[videoTrackIndex]?.codec === "MPEG-4"
                        ? [
                              { key: "Auto", text: "Auto", value: "Auto" },
                              { key: "1.0", text: "1.0", value: "1.0" },
                              { key: "1.1", text: "1.1", value: "1.1" },
                              { key: "1.2", text: "1.2", value: "1.2" },
                              { key: "1.3", text: "1.3", value: "1.3" },
                              { key: "1.b", text: "1.b", value: "1.b" },
                              { key: "2.0", text: "2.0", value: "2.0" },
                              { key: "2.1", text: "2.1", value: "2.1" },
                              { key: "2.2", text: "2.2", value: "2.2" },
                              { key: "3.0", text: "3.0", value: "3.0" },
                              { key: "3.1", text: "3.1", value: "3.1" },
                              { key: "3.2", text: "3.2", value: "3.2" },
                              { key: "4.0", text: "4.0", value: "4.0" },
                              { key: "4.1", text: "4.1", value: "4.1" },
                              { key: "4.2", text: "4.2", value: "4.2" },
                              { key: "5.0", text: "5.0", value: "5.0" },
                              { key: "5.1", text: "5.1", value: "5.1" },
                              { key: "5.2", text: "5.2", value: "5.2" }
                          ]
                        : [
                              { key: "Auto", text: "Auto", value: "Auto" },
                              { key: "1.0 Main Tier", text: "1.0 Main Tier", value: "1.0 Main Tier" },
                                { key: "2.0 Main Tier", text: "2.0 Main Tier", value: "2.0 Main Tier" },
                                { key: "2.1 Main Tier", text: "2.1 Main Tier", value: "2.1 Main Tier" },
                                { key: "3.0 Main Tier", text: "3.0 Main Tier", value: "3.0 Main Tier" },
                                { key: "3.1 Main Tier", text: "3.1 Main Tier", value: "3.1 Main Tier" },
                                { key: "4.0 Main Tier", text: "4.0 Main Tier", value: "4.0 Main Tier" },
                                { key: "4.1 Main Tier", text: "4.1 Main Tier", value: "4.1 Main Tier" },
                                { key: "5.0 Main Tier", text: "5.0 Main Tier", value: "5.0 Main Tier" },
                                { key: "5.1 Main Tier", text: "5.1 Main Tier", value: "5.1 Main Tier" },
                                { key: "5.2 Main Tier", text: "5.2 Main Tier", value: "5.2 Main Tier" },
                                { key: "6.0 Main Tier", text: "6.0 Main Tier", value: "6.0 Main Tier" },
                                { key: "6.1 Main Tier", text: "6.1 Main Tier", value: "6.1 Main Tier" },
                                { key: "6.2 Main Tier", text: "6.2 Main Tier", value: "6.2 Main Tier" },
                                { key: "4.0 High Tier", text: "4.0 High Tier", value: "4.0 High Tier" },
                                { key: "4.1 High Tier", text: "4.1 High Tier", value: "4.1 High Tier" },
                                { key: "5.0 High Tier", text: "5.0 High Tier", value: "5.0 High Tier" },
                                { key: "5.1 High Tier", text: "5.1 High Tier", value: "5.1 High Tier" },
                                { key: "5.2 High Tier", text: "5.2 High Tier", value: "5.2 High Tier" },
                                { key: "6.0 High Tier", text: "6.0 High Tier", value: "6.0 High Tier" },
                                { key: "6.1 High Tier", text: "6.1 High Tier", value: "6.1 High Tier" },
                                { key: "6.2 High Tier", text: "6.2 High Tier", value: "6.2 High Tier" }
                            ]
                }
            />
        </>
    );
};

export default VideoTrackForm;
