import React, { useContext } from "react";
import { Switch } from "@mui/material";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";

export const GroupNotificationsSwitch: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, updateUserPreferences } = rootStore.userStore;

    return (
        <Switch
            edge="end"
            size="small"
            checked={user?.user_preferences?.group_notifications}
            onChange={(event) => {
                user?.user_preferences?.group_notifications
                    ? updateUserPreferences({ group_notifications: false })
                    : updateUserPreferences({ group_notifications: true });
            }}
        />
    );
};

export default observer(GroupNotificationsSwitch);
