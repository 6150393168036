import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "api";
import { Box, Button, Typography } from "@mui/material";
import { useBilling } from "hooks";

interface IProps {
    no_payment: boolean;
}

export const PaymentSuccess: React.FC<IProps> = ({ no_payment }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDrawer } = rootStore.drawerStore;
    const { getCreditsInformation } = useBilling();

    useEffect(() => {
        const polling = setTimeout(() => getCreditsInformation(), 3000);
        return () => {
            clearTimeout(polling);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box>
                <Typography variant="h3" mb={3}>
                    Payment Successful
                </Typography>
                <Box>
                    {no_payment && <Typography mb={3}>Your payment was taken from your existing stripe balance.</Typography>}
                    <Typography mb={3}>Your Credits have now been added!</Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            closeDrawer();
                        }}>
                        Close
                    </Button>
                </Box>
            </Box>
        </div>
    );
};
