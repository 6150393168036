import React from "react";
import Content from "components/Layout/Content";
import { Alert, AlertTitle, Box, Button, DialogActions, DialogContent, IconButton, Stack } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import CreatePresetForm from "./Components/CreatePresetForm";
import { agent, ScramblingPreset, RootStoreContext } from "api";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

const Scrambling: React.FC = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [scramblingPresets, setScramblingPresets] = React.useState<ScramblingPreset[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchScramblingPresets = () => {
        agent.NeaLive.listScramblingPresets()
            .then((response) => {
                console.log("response", response.presets);
                // filter out presets where nea_instance_uuid is not null

                const presets: ScramblingPreset[] = response.presets.filter((preset: ScramblingPreset) => !preset.pull_packager_uuid);

                setScramblingPresets(presets);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    React.useEffect(() => {
        fetchScramblingPresets();
    }, []);

    React.useEffect(() => {}, [scramblingPresets]);

    const ConfirmDelete: React.FC<{ id: number }> = ({ id }) => {
        const [error, setError] = React.useState<string | null>();

        return (
            <>
                <DialogContent>
                    Are you sure you want to delete this preset?
                    {error && (
                        <Alert severity="error" sx={{ mt: 3 }}>
                            <AlertTitle>Error Deleting Preset</AlertTitle>
                            {error}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button
                        onClick={() => {
                            agent.NeaLive.deleteScramblingPreset(id)
                                .then((response) => {
                                    console.log("response", response);
                                    fetchScramblingPresets();
                                    closeDialog();
                                })
                                .catch((error) => {
                                    setError(error.data?.message || "An unknown error occurred");
                                    console.log("error", error);
                                });
                        }}
                        variant="contained"
                        color="error">
                        Delete
                    </Button>
                </DialogActions>
            </>
        );
    };

    const RowActions: React.FC<{ row: ScramblingPreset }> = ({ row }) => {
        return (
            <Stack direction="row" spacing={1}>
                <IconButton
                    onClick={() => {
                        openDialog(
                            <CreatePresetForm
                                mode="edit"
                                id={row.id}
                                onSuccess={() => {
                                    setLoading(true);
                                    //delay to allow the backend to update the preset
                                    setTimeout(() => {
                                        fetchScramblingPresets();
                                        setLoading(false);
                                    }, 500);
                                }}
                            />,
                            "Edit Preset"
                        );
                    }}>
                    <Edit />
                </IconButton>
                <IconButton
                    onClick={() => {
                        openDialog(<ConfirmDelete id={row.id} />, "Delete Preset");
                    }}>
                    <Delete />
                </IconButton>
            </Stack>
        );
    };

    return (
        <Content
            loading={loading}
            headerContent={
                <Box
                    sx={{
                        py: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <Box></Box>
                    <Button
                        variant="contained"
                        onClick={() =>
                            openDialog(
                                <CreatePresetForm
                                    mode="create"
                                    onSuccess={() => {
                                        setLoading(true);
                                        //delay to allow the backend to update the preset
                                        setTimeout(() => {
                                            fetchScramblingPresets();
                                            setLoading(false);
                                        }, 500);
                                    }}
                                />,
                                "Create Preset"
                            )
                        }
                        startIcon={<AddCircle />}>
                        Add Preset
                    </Button>
                </Box>
            }>
            <Box sx={{ height: "100%" }}>
                <DataGridPro
                    sx={{
                        [`& .${gridClasses.cell}`]: {
                            py: 2
                        },
                        border: 0
                    }}
                    rows={scramblingPresets}
                    columns={[
                        { field: "id", headerName: "ID", flex: 0 },
                        { field: "name", headerName: "Name", flex: 1 },
                        { field: "type", headerName: "Type", flex: 0 },
                        {
                            field: "key",
                            headerName: "Key",
                            flex: 1,
                            valueGetter: (params) => {
                                if (params.row.parameters) {
                                    return params.row.parameters.key;
                                }
                            }
                        },
                        {
                            field: "key_id",
                            headerName: "Key ID",
                            flex: 1,
                            valueGetter: (params) => {
                                if (params.row.parameters) {
                                    return params.row.parameters.key_id;
                                }
                            }
                        },
                        {
                            field: "actions",
                            headerName: "",
                            flex: 0,
                            renderCell: (params) => {
                                return <RowActions row={params.row as ScramblingPreset} />;
                            }
                        }
                    ]}
                />
            </Box>
        </Content>
    );
};

export default Scrambling;
