import { Box, Typography } from "@mui/material";
import React from "react";
import { CheckboxField, RadioGroupField, SliderField, TextField } from "components/Forms";

interface Props {
    values: any;
    submitError: any;
    onFieldChange: (field: string, value: any) => void;
}

const SrtCallerOptionsForm: React.FC<Props> = ({ values, submitError, onFieldChange }) => {
    return (
        <Box sx={{ p: 2, backgroundColor: "rgba(160,160,160,0.05)" }}>
            <Typography variant="h6" gutterBottom component="div">
                SRT Caller Options
            </Typography>

            <TextField
                name="direct_feed_input_details.srt_caller_options.input_label"
                label="Input Label"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value.length < 1 || value.length > 150) {
                        return "Name must be between 1 and 150 characters";
                    }
                    if (!/^[a-zA-Z0-9._-\s]*$/.test(value)) {
                        return "Name must contain only valid characters of: a-z, A-Z, 0-9, ., _, -, and space";
                    }
                }}
            />

            <CheckboxField
                name="direct_feed_input_details.srt_caller_options.enable_ts_input_smoothing"
                label="Enable TS Input Smoothing"
                initialValue={values?.direct_feed_input_details?.srt_caller_options?.enable_ts_input_smoothing ? true : false}
            />
            <Box sx={{ my: 2 }}></Box>

            {values?.direct_feed_input_details?.srt_caller_options?.enable_ts_input_smoothing && (
                <>
                    <SliderField
                        name="direct_feed_input_details.srt_caller_options.input_smoothing_extra_delay"
                        label="Input Smoothing Extra Delay"
                        min={0}
                        max={1250}
                        step={10}
                        required
                        initialValue={
                            values?.direct_feed_input_details?.srt_caller_options?.input_smoothing_extra_delay
                                ? values?.direct_feed_input_details?.srt_caller_options?.input_smoothing_extra_delay
                                : 0
                        }
                        validate={(value) => {
                            if (!value && value !== 0) {
                                return "Required";
                            }
                            if (value < 0 || value > 1250) {
                                return "Input Smoothing Extra Delay must be between 0 and 1250";
                            }
                        }}
                    />
                </>
            )}
            <TextField
                name="direct_feed_input_details.srt_caller_options.input_url"
                label="Input URL"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                    if (!value.startsWith("srt://")) {
                        return "Input URL must start with srt://";
                    }
                    const regex = /:\d{1,5}$/;
                    if (!regex.test(value)) {
                        return "Input URL must end with a port number";
                    }
                }}
            />
            {values.enable_backup === true && (
                <>
                    <TextField
                        name="direct_feed_input_details.srt_caller_options.backup_input_url"
                        label="Backup Input URL"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            if (!value.startsWith("srt://")) {
                                return "Backup Input URL must start with srt://";
                            }
                            const regex = /:\d{1,5}$/;
                            if (!regex.test(value)) {
                                return "Backup Input URL must end with a port number";
                            }
                            if (value === values?.direct_feed_input_details?.srt_caller_options?.input_url) {
                                return "Backup Input URL must be different from Input URL";
                            }
                        }}
                    />
                </>
            )}
            <TextField
                name="direct_feed_input_details.srt_caller_options.connection_timeout"
                label="Connection Timeout"
                type="number"
                required
                initialValue={
                    values?.direct_feed_input_details?.srt_caller_options?.connection_timeout
                        ? values?.direct_feed_input_details?.srt_caller_options?.connection_timeout
                        : 0
                }
                validate={(value) => {
                    if (!value && value !== 0) {
                        return "Required";
                    }
                    if (value < 0) {
                        return "Connection Timeout must be 0 or greater";
                    }
                }}
                parse
            />
            <SliderField
                name="direct_feed_input_details.srt_caller_options.timeout"
                label="Stream Timeout (ms)"
                min={150}
                max={5000}
                step={50}
                required
                initialValue={
                    values?.direct_feed_input_details?.srt_caller_options?.timeout
                        ? values?.direct_feed_input_details?.srt_caller_options?.timeout
                        : 1000
                }
                validate={(value) => {
                    if (!value && value !== 0) {
                        return "Required";
                    }
                    if (value < 150 || value > 5000) {
                        return "Timeout must be between 150 and 5000";
                    }
                }}
            />
            <SliderField
                name="direct_feed_input_details.srt_caller_options.latency"
                label="Latency"
                min={0}
                max={7500}
                step={50}
                required
                initialValue={
                    values?.direct_feed_input_details?.srt_caller_options?.latency
                        ? values?.direct_feed_input_details?.srt_caller_options?.latency
                        : 120
                }
                validate={(value) => {
                    if (!value && value !== 0) {
                        return "Required";
                    }
                    if (value < 0 || value > 7500) {
                        return "Latency must be between 0 and 7500";
                    }
                }}
            />
            <RadioGroupField
                name="direct_feed_input_details.srt_caller_options.encryption_mode"
                label="Encryption Mode"
                row
                required
                initialValue={
                    values?.direct_feed_input_details?.srt_caller_options?.encryption_mode
                        ? values?.direct_feed_input_details?.srt_caller_options?.encryption_mode
                        : "None"
                }
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }
                }}
                options={[
                    { label: "None", value: "None" },
                    { label: "AES 128", value: "AES 128" },
                    { label: "AES 192", value: "AES 192" },
                    { label: "AES 256", value: "AES 256" }
                ]}
            />
            {values?.direct_feed_input_details?.srt_caller_options?.encryption_mode !== "None" && (
                <>
                    <TextField
                        name="direct_feed_input_details.srt_caller_options.password"
                        label="Password"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            if (value.length < 10 || value.length > 79) {
                                return "Password must be between 10 and 79 characters";
                            }
                        }}
                    />
                </>
            )}
        </Box>
    );
};

export default SrtCallerOptionsForm;
