import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import CurrentTimeIndicator from "./CurrentTimeIndicator";
import { RootStoreContext } from "api";
import { isEqual, startOfDay } from "date-fns";
import ScheduleRowStart from "./ScheduleRowStart";
import ScheduleRowHour from "./ScheduleRowHour";

interface ScheduleRowProps {
    children: React.ReactNode;
    zoom: number;
    isPermanent: boolean;
    permanent_schedule_input_id: string;
    permanent_schedule_input_name?: string;
    destination: string;
    destination_id: string;
    connection_status: string;
    active_alerts?: number;
}

/**
 * A single row of a schedule containing one hour blocks with optional icons and text.
 * @param {ScheduleRowProps} props - The props for the ScheduleRow component.
 * @param {ReactNode} props.children - The content to be rendered within the ScheduleRow component.
 * @param {number} props.zoom - The zoom factor for the ScheduleRow component.
 * @param {boolean} [props.isPermanent] - Optional. Determines if the ScheduleRow represents permanent schedules.
 * @param {string} [props.permanent_schedule_input_name] - Optional. The name of the input representing permanent schedules.
 * @param {string} props.destination - The name of the destination the ScheduleRow represents.
 * @param {string} props.destination_id - The id of the destination the ScheduleRow represents.
 * @param {string} props.connection_status - The status of the connection the ScheduleRow represents.
 * @returns {React.FC<ScheduleRowProps>} - Returns a ScheduleRow component.
 * @example
 * <ScheduleRow zoom={1} isPermanent={false} destination={"123"} destination_id={"123"} connection_status={"connected"}>
 */

const ScheduleRow: React.FC<ScheduleRowProps> = observer(function ScheduleRow(props) {
    const { date } = React.useContext(RootStoreContext).schedulerStore;

    const hours = [...Array(24).keys()]; // create an array with numbers from 0 to 23 for the hours

    const isToday = () => {
        const startDate = startOfDay(new Date(date));
        const today = startOfDay(new Date());

        return isEqual(startDate, today);
    };

    return (
        <Box
            sx={{
                width: 1440 * props.zoom + 400,
                maxWidth: 1440 * props.zoom + 400,
                height: "120px",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid rgba(160,160,160,0.2)"
            }}>
            <ScheduleRowStart
                zoom={props.zoom}
                hour={0}
                destination_id={props.destination_id}
                destination_name={props.destination}
                isPermanent={props.isPermanent}
                connection_status={props.connection_status}
                permanent_schedule_input_id={props.permanent_schedule_input_id}
                permanent_schedule_input_name={props.permanent_schedule_input_name}
                active_alerts={props.active_alerts}
            />
            <Box
                sx={{
                    height: "100%",
                    width: 50,
                    flexShrink: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRight: "1px dashed rgba(160,160,160,0.1)"
                }}></Box>
            {hours.map((hour) => (
                <ScheduleRowHour
                    key={hour}
                    hour={hour}
                    zoom={props.zoom}
                    destination_id={props.destination_id}
                    is_permanent={props.isPermanent}
                />
            ))}
            <Box
                sx={{
                    height: "100%",
                    width: 50,
                    flexShrink: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}></Box>
            {props.children}
            {isToday() && <CurrentTimeIndicator zoom={props.zoom} />}
        </Box>
    );
});

export default ScheduleRow;
