import { Box, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Groups, GroupsOutlined, Loop } from "@mui/icons-material";

interface Props {
    label: string;
    id?: string;
    menuItems?: any[];
    color?: string;
}

export const NodeHeader: React.FC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };
    return (
        <Box
            className="drag-handle"
            sx={{
                height: 30,
                lineHeight: "30px",
                borderBottom: props.color ? "1px solid " + props.color + "aa" : "1px solid rgba(160,160,160,0.2)",
                fontSize: 11,
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
            <Box
                sx={{
                    pl: 2,
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}>
                {/* <Groups sx={{ fontSize: 14, ml: -0.5, opacity: 0.7, mr: 0.5 }} /> */}
                <span style={{ 
                    opacity: 0.7,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                 }}>{props.label}</span>
            </Box>
            <Box>
                {props.menuItems && (
                    <>
                        <IconButton
                            aria-label="settings"
                            size="small"
                            id="demo-customized-button"
                            aria-controls={open ? "demo-customized-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => handleClick(e)}>
                            <MoreVertIcon fontSize="small" />
                        </IconButton>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                                "aria-labelledby": "demo-customized-button"
                            }}
                            sx={{ minWidth: 300, p: 0 }}
                            anchorEl={anchorEl}
                            elevation={0}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={open}
                            onClose={(e) => {handleClose(e) }}>
                            {props.menuItems.map((item: any, i: number) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            item.onClick();
                                            handleClose(event);
                                        }}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default NodeHeader;
