import { Box } from "@mui/material";
import InfoItem from "components/InfoItem";
import React from "react";

interface PidErrors {
    id: number;
    errors: number;
}

interface TranscoderAnalysisProps {
    data: {
        continuity_count_errors: number;
        continuity_count_failover: number;
        pat_missing_errors: number;
        pid_errors: PidErrors[];
        pmt_missing_errors: number;
        sync_byte_loss_errors: number;
        ts_sync_losserrors: number;
        total_continuity_count_errors: number;
    };
}

const TranscoderAnalysis: React.FC<TranscoderAnalysisProps> = ({ data }) => {
    return (
        <Box>
            <InfoItem title="Continuity Count Errors" value={data.continuity_count_errors} />
            <InfoItem title="Continuity Count Failover" value={data.continuity_count_failover} />
            <InfoItem title="Total Continuity Count Errors" value={data.total_continuity_count_errors} />
            <InfoItem title="PAT Missing Errors" value={data.pat_missing_errors} />
            <InfoItem title="PMT Missing Errors" value={data.pmt_missing_errors} />
            <InfoItem title="Sync Byte Loss Errors" value={data.sync_byte_loss_errors} />
            <InfoItem title="TS Sync Loss Errors" value={data.ts_sync_losserrors} />
            {data.pid_errors.map((pidError) => (
                <InfoItem key={pidError.id} title={`PID ${pidError.id} Errors`} value={pidError.errors} />
            ))}
        </Box>
    );
};

export default TranscoderAnalysis;
