import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import View, { ViewHeader, ViewBody } from "components/Layout/View";
import Content from "components/Layout/Content";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {
    addDays,
    addHours,
    addMonths,
    endOfMonth,
    endOfWeek,
    format,
    isSameDay,
    startOfMonth,
    startOfWeek,
    sub,
    subMonths
} from "date-fns";
import { AddCircle, ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import { RootStoreContext, agent } from "api";
import DayCell from "./Components/DayCell";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CreateRepeatScheduleForm from "features/scheduler/Components/CreateRepeatScheduleForm";
import ClipboardMenu from "./Components/ClipboardMenu";
import SearchInput from "components/SearchInput";
import SchedulerViewSelect from "../Components/SchedulerViewSelect";
import CreateButton from "../Components/CreateButton";
import { useSources } from "hooks";

const SchedulerMonth: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(new Date());

    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_env_id = rootStore.userStore.activeEnvironment;

    const { environment, currentEnvRunning } = rootStore.environmentStore;
    const active_environment = rootStore.userStore.activeEnvironment;

    const { client, clientConnected } = rootStore.resClientStore;

    const { token } = rootStore.commonStore;

    const { date, setDate } = rootStore.schedulerStore;

    const theme = useTheme();
    const { getInputsDropdownList } = useSources();

    //const [currentDate, setCurrentDate] = useState(new Date());
    const [schedule, setSchedule] = useState<any[]>([]);

    const [copiedItem, setCopiedItem] = useState<any>(null);

    const [showFinished, setShowFinished] = useState(true);
    const [showOn, setShowOn] = useState(true);
    const [showScheduled, setShowScheduled] = useState(true);

    const [showDestinationsList, setShowDestinationsList] = useState<string[]>([]);
    const [searchDestinations, setSearchDestinations] = useState<string>("");
    const [showSourcesList, setShowSourcesList] = useState<string[]>([]);
    const [searchSources, setSearchSources] = useState<string>("");
    const [sources, setSources] = useState<any[]>([]);
    const [destinations, setDestinations] = useState<any[]>([]);

    const fetchSchedule = async (environment_id) => {
        await agent.Scheduler.getScheduleOverview(subMonths(new Date(), 3), addMonths(new Date(), 6))
            .then((response) => {
                console.log(response.results);
                setSchedule(response.results);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const renderDays = () => {
        const dateFormat = "EEEE";
        const days: any = [];

        let startDate = startOfWeek(date, { weekStartsOn: 0 });

        for (let i = 0; i < 7; i++) {
            days.push(
                <Grid item key={i} xs={1} textAlign="center" padding={1}>
                    {format(addDays(startDate, i), dateFormat)}
                </Grid>
            );
        }

        return (
            <Grid container columns={7} width={"100%"}>
                {days}
            </Grid>
        );
    };

    const renderCells = () => {
        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 0 });
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 0 });
        const rows: any = [];
        let days: any = [];
        let day = startDate;

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const cloneDay = day;
                days.push(
                    <DayCell
                        key={day.toString()}
                        day={day}
                        currentDate={date}
                        schedule={schedule
                            .filter((item) => isSameDay(new Date(item.start_time), day))
                            .filter((item) => item.status !== "deleted")
                            .filter((item) => showDestinationsList.includes(item.destination_id))
                            .filter((item) => showSourcesList.includes(item.source_id))}
                        onItemCopy={(item) => {
                            setCopiedItem(item);
                        }}
                        copiedItem={copiedItem}
                        showScheduledItems={showScheduled}
                        showOnItems={showOn}
                        showFinishedItems={showFinished}
                    />
                );
                day = addDays(day, 1);
            }
            rows.push(
                <Grid
                    container
                    columns={7}
                    width={"100%"}
                    height={
                        //calculate height as a percentage  based on number of items
                        100 / 5 + "%"
                    }
                    key={day.toString()}
                    sx={{
                        borderTop: `1px solid ${theme.palette.divider}`,
                        "&:first-child": {
                            borderTop: "none"
                        }
                    }}>
                    {days}
                </Grid>
            );
            days = [];
        }
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "stretch",
                    alignItems: "stretch",
                    width: "100%",
                    height: "100%"
                }}>
                {rows}
            </Box>
        );
    };

    //Fetch Sources and Destinations --------------------------------
    const fetchSources = async (environment_id) => {
        await getInputsDropdownList(environment_id)
            .then((response) => {
                setSources(response.data);
                //add all sources to showSourcesList
                let srcList: any = [];
                response.data.map((source) => {
                    srcList.push(source.id);
                });
                setShowSourcesList(srcList);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const fetchDestinations = async (environment_id) => {
        await agent.Destination.getDestinationsList(environment_id)
            .then((response) => {
                const destinations = response.data.filter((destination) => destination.is_permanent_schedule === false);
                //add all destinations to showDestinationsList
                let destList: any = [];
                destinations.map((destination) => {
                    destList.push(destination.id);
                });
                setShowDestinationsList(destList);

                setDestinations(destinations);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        fetchSources(active_environment);
        fetchDestinations(active_environment);
    }, [active_environment]);
    //--------------------------------------------------------------

    useEffect(() => {
        fetchSchedule(active_env_id);
    }, [active_env_id]);

    const onUpdate = () => {
        setUpdate(new Date());
    };

    useEffect(() => {
        setLoading(true);

        clientConnected &&
            client
                .get(`environments.outputs.${managing_org_id}.${active_env_id}`)
                .then((res: any) => {
                    res.on("add", () => {
                        onUpdate();

                        fetchSchedule(active_env_id);
                    });

                    res.on("remove", () => {
                        onUpdate();
                        fetchSchedule(active_env_id);
                    });
                    Array.from(res).map((destination: any) => {
                        destination.on("change", () => {
                            onUpdate();
                            fetchSchedule(active_env_id);
                        });
                    });
                    //setData(res);
                    setLoading(false);
                    onUpdate();
                    //console.log("Scheduler Data", res);
                })
                .catch((err) => {
                    console.error("Scheduler Error", err);
                    if (err.message === "No auth info provided") {
                        client.disconnect();
                        rootStore.resClientStore.initialize(token!);
                    }
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            //setData([]);
        };
    }, [managing_org_id, rootStore.commonStore.token, clientConnected, client, active_env_id, token, currentEnvRunning]);

    useEffect(() => {}, [update]);

    return (
        <View>
            <ViewHeader title={"Scheduler"} subtitle="Calendar" />
            <ViewBody noPadding>
                <Content
                    loading={loading}
                    noPadding
                    leftWidth={320}
                    leftContent={
                        <>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <StaticDatePicker
                                    value={date}
                                    displayStaticWrapperAs="desktop"
                                    onChange={(date) => setDate(date as Date)}
                                    disablePast
                                    sx={{
                                        backgroundColor: "transparent"
                                    }}
                                />
                            </LocalizationProvider>
                            <Divider />
                            <Accordion
                                defaultExpanded
                                sx={{
                                    backgroundColor: "transparent",
                                    "&.MuiPaper-root": {
                                        backgroundColor: "transparent",
                                        background: "none"
                                    },
                                    "&.MuiAccordion-root": {
                                        backgroundColor: "transparent"
                                    },
                                    "&.MuiAccordion-root.Mui-expanded": {
                                        backgroundColor: "transparent"
                                    }
                                }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: "transparent",
                                        "&.MuiAccordionSummary-root": {
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                    expandIcon={<ExpandMore />}>
                                    Status
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" spacing={1}>
                                        <FormControlLabel
                                            control={<Checkbox checked={showScheduled} onChange={() => setShowScheduled(!showScheduled)} />}
                                            label="Show Scheduled Items"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox color="success" checked={showOn} onChange={() => setShowOn(!showOn)} />}
                                            label="Show Live Items"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        "&.MuiCheckbox-root.Mui-checked": {
                                                            "& .MuiSvgIcon-root": {
                                                                color: theme.palette.text.primary
                                                            }
                                                        }
                                                    }}
                                                    checked={showFinished}
                                                    onChange={() => setShowFinished(!showFinished)}
                                                />
                                            }
                                            label="Show Finished Items"
                                        />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    backgroundColor: "transparent",
                                    "&.MuiPaper-root": {
                                        backgroundColor: "transparent",
                                        background: "none"
                                    },
                                    "&.MuiAccordion-root": {
                                        backgroundColor: "transparent"
                                    },
                                    "&.MuiAccordion-root.Mui-expanded": {
                                        backgroundColor: "transparent"
                                    }
                                }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: "transparent",
                                        "&.MuiAccordionSummary-root": {
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                    expandIcon={<ExpandMore />}>
                                    Destinations {showDestinationsList.length > 0 && `(${showDestinationsList.length})`}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" spacing={1}>
                                        <SearchInput
                                            name="search_destinations"
                                            label="Search Destinations"
                                            onChange={(value) => {
                                                setSearchDestinations(value);
                                            }}
                                            onClear={() => {
                                                setSearchDestinations("");
                                            }}
                                            value={searchDestinations}
                                        />
                                        {/* select all checkboxes to show/hide all destinations */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showDestinationsList.length === destinations.length}
                                                    onChange={() => {
                                                        if (showDestinationsList.length === destinations.length) {
                                                            setShowDestinationsList([]);
                                                        } else {
                                                            let destList: any = [];
                                                            destinations?.map((destination) => {
                                                                destList.push(destination.id);
                                                            });
                                                            setShowDestinationsList(destList);
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Select All"
                                        />
                                        {destinations
                                            .filter((destination) =>
                                                destination.name.toLowerCase().includes(searchDestinations.toLowerCase())
                                            )
                                            .map((destination) => (
                                                <FormControlLabel
                                                    key={destination.id}
                                                    control={
                                                        <Checkbox
                                                            checked={showDestinationsList.includes(destination.id)}
                                                            onChange={() => {
                                                                if (showDestinationsList.includes(destination.id)) {
                                                                    setShowDestinationsList(
                                                                        showDestinationsList.filter((item) => item !== destination.id)
                                                                    );
                                                                } else {
                                                                    setShowDestinationsList([...showDestinationsList, destination.id]);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={destination.name}
                                                />
                                            ))}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                sx={{
                                    backgroundColor: "transparent",
                                    "&.MuiPaper-root": {
                                        backgroundColor: "transparent",
                                        background: "none"
                                    },
                                    "&.MuiAccordion-root": {
                                        backgroundColor: "transparent"
                                    },
                                    "&.MuiAccordion-root.Mui-expanded": {
                                        backgroundColor: "transparent"
                                    }
                                }}>
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: "transparent",
                                        "&.MuiAccordionSummary-root": {
                                            backgroundColor: "transparent"
                                        }
                                    }}
                                    expandIcon={<ExpandMore />}>
                                    Sources {showSourcesList.length > 0 && `(${showSourcesList.length})`}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction="column" spacing={1}>
                                        <SearchInput
                                            name="search_sources"
                                            label="Search Sources"
                                            onChange={(value) => {
                                                setSearchSources(value);
                                            }}
                                            onClear={() => {
                                                setSearchSources("");
                                            }}
                                            value={searchSources}
                                        />
                                        {/* select all checkboxes to show/hide all sources */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showSourcesList.length === sources.length}
                                                    onChange={() => {
                                                        if (showSourcesList.length === sources.length) {
                                                            setShowSourcesList([]);
                                                        } else {
                                                            let srcList: any = [];
                                                            sources.map((source) => {
                                                                srcList.push(source.id);
                                                            });
                                                            setShowSourcesList(srcList);
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Select All"
                                        />
                                        {sources
                                            .filter((source) => source.name.toLowerCase().includes(searchSources.toLowerCase()))
                                            .map((source) => (
                                                <FormControlLabel
                                                    key={source.id}
                                                    control={
                                                        <Checkbox
                                                            checked={showSourcesList.includes(source.id)}
                                                            onChange={() => {
                                                                if (showSourcesList.includes(source.id)) {
                                                                    setShowSourcesList(
                                                                        showSourcesList.filter((item) => item !== source.id)
                                                                    );
                                                                } else {
                                                                    setShowSourcesList([...showSourcesList, source.id]);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={source.name}
                                                />
                                            ))}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                    toolbarContent={
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Button variant="toolbar" onClick={() => setDate(new Date())}>
                                    Today
                                </Button>
                                <Stack direction="row" spacing={0} alignItems="center">
                                    <IconButton onClick={() => setDate(subMonths(date, 1))} size="small">
                                        <ChevronLeft />
                                    </IconButton>
                                    <IconButton onClick={() => setDate(addMonths(date, 1))} size="small">
                                        <ChevronRight />
                                    </IconButton>
                                </Stack>
                                <Typography variant="h5">{format(date, "MMMM yyyy")}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <SchedulerViewSelect />
                                <ClipboardMenu
                                    selectedDate={date}
                                    copiedItem={copiedItem}
                                    onPaste={() => {
                                        const startTime = new Date(
                                            new Date(date).getFullYear(),
                                            new Date(date).getMonth(),
                                            new Date(date).getDate(),
                                            new Date(copiedItem.start_time).getHours(),
                                            new Date(copiedItem.start_time).getMinutes()
                                        );
                                        const endTime = new Date(
                                            new Date(date).getFullYear(),
                                            new Date(date).getMonth(),
                                            new Date(date).getDate(),
                                            new Date(copiedItem.end_time).getHours(),
                                            new Date(copiedItem.end_time).getMinutes()
                                        );
                                        openDialog(
                                            <CreateRepeatScheduleForm
                                                source_id={copiedItem.source_id}
                                                destination_id={copiedItem.destination_id}
                                                start_date={startTime}
                                                end_date={endTime}
                                            />
                                        );
                                    }}
                                    onClear={() => {
                                        setCopiedItem(null);
                                    }}
                                />
                                <CreateButton start_date={date} end_date={addHours(date, 1)} />
                            </Stack>
                        </Box>
                    }
                    headerContent={renderDays()}>
                    {renderCells()}
                </Content>
            </ViewBody>
        </View>
    );
};

export default observer(SchedulerMonth);
