import { Button, DialogActions, DialogContent, DialogContentText, Stack, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { agent, ICreateCustomPriceRequest, RootStoreContext } from "api";

interface Props {
    cloud_provider: string;
    eur: number;
    gbp: number;
    last_updated: string;
    notes: string;
    onSubmitted: () => void;
    organisationId: number;
    product_id: number;
    section: string;
    size: string;
    usd: number;
}

const CreateCustomPriceForm: React.FC<Props> = observer(function CreateCustomPriceForm(props) {
    const rootStore = React.useContext(RootStoreContext);

    const { closeDialog } = rootStore.dialogStore;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            usd: { value: number };
            gbp: { value: number };
            eur: { value: number };
            notes: { value: string };
        };

        const request: ICreateCustomPriceRequest = {
            organisation_id: props.organisationId,
            product_id: props.product_id,
            usd: target.usd.value,
            gbp: target.gbp.value,
            eur: target.eur.value,
            notes: target.notes.value
        };

        await agent.Admin.createCustomPrice(request)
            .then(() => {
                console.log("Custom price created successfully");
                props.onSubmitted();
            })
            .catch((error) => console.log(error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <DialogContentText>
                    {props.section} &bull; {props.cloud_provider} &bull; {props.size}
                    <br />
                    Last Updated: {props.last_updated ? format(new Date(props.last_updated), "dd/MM/yyyy @ HH:mm") : "Never"}
                </DialogContentText>
                <Stack direction={"row"} spacing={2} marginBottom={2} marginTop={3}>
                    <TextField id="usd" name="usd" label="USD" fullWidth defaultValue={props.usd} required />
                    <TextField id="gbp" name="gbp" label="GBP" fullWidth defaultValue={props.gbp} required />
                    <TextField id="eur" name="eur" label="EUR" fullWidth defaultValue={props.eur} required />
                </Stack>

                <TextField id="outlined-multiline-static" label="Notes" name="notes" multiline rows={2} defaultValue={props.notes} />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button variant="contained" color="primary" type="submit" startIcon={<EditIcon />}>
                    Update Price
                </Button>
            </DialogActions>
        </form>
    );
});

export default CreateCustomPriceForm;
