import { Typography } from "@mui/material";

import React from "react";

const GlobalConfigInfo = () => {
    return (
        <>
            <Typography sx={{ mb: 1 }}>
                <b>Latency Mode</b> - This setting determines how the system handles latency in the stream. Lower latency modes prioritise
                reducing the delay in the stream, which can be important for real-time applications but can come at the cost of quality.
                Higher quality modes prioritise buffering and can provide a more higher quality stream but with a higher delay.
            </Typography>
            <Typography sx={{ mb: 1 }}>
                <b>Chunk Period</b> - This refers to the duration of the chunks into which the stream is divided for processing. A shorter
                chunk period can reduce latency and make the stream more responsive, but it may increase the processing load. A longer chunk
                period can reduce the processing load, but it may increase latency.
            </Typography>
            <Typography sx={{ mb: 1 }}>
                <b>Multiple Instances Synchronization</b> - This setting is used when multiple instances of the stream are being processed
                in parallel. It ensures that all instances process the same chunks at the same time, which can be important for maintaining
                synchronization between the instances.
            </Typography>
        </>
    );
};

export default GlobalConfigInfo;
