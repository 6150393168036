import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import { Collapse } from "@mui/material";
import { IMyGuestOrganisationsEnvInfo } from "api";
import { InputSourceAccessEntryRow } from "../contentPermissions/organisationPermissions/InputSourceAccessEntryRow";

const GuestOrgTableBody = (props: { env: IMyGuestOrganisationsEnvInfo }) => {
    const { env } = props;
    //const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow className={open ? `active` : undefined}>
                <TableCell align="left" style={{ maxWidth: "0.2rem" }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell
                    align="center"
                    style={{
                        cursor: "pointer",
                        maxWidth: "0.5rem"
                    }}
                    component="td"
                    scope="row"
                    onClick={() => setOpen(!open)}>{`${env.environment_human_name}`}</TableCell>
                <TableCell align="center" style={{ maxWidth: "0.5rem" }}>{`${env.max_destinations}`}</TableCell>
            </TableRow>
            <TableRow style={{ borderTop: "none" }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <span
                            style={{
                                flex: "auto",
                                padding: "1rem 0",
                                color: "white",
                                fontWeight: "bold",
                                display: "block"
                            }}>
                            Allowed channels on this enviroment
                        </span>
                        {env.allowed_channels
                            ? env.allowed_channels.map((channels, i) => (
                                  <InputSourceAccessEntryRow key={i} row={channels} token={channels} type={"guest"} />
                              ))
                            : null}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default GuestOrgTableBody;
