import { Card, CardContent, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { agent, IHourlyBreakDownResponse, RootStoreContext } from "api";
import HourlyBreakdownRow from "./HourlyBreakdownRow";
import React, { useContext } from "react";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";

interface Props {
    start_date: Date;
    end_date: Date;
    currency_symbol: string;
}

export const HourlyBreakdown: React.FC<Props> = ({ start_date, end_date, currency_symbol }) => {
    const [hourlyBreakdown, setHourlyBreakdown] = React.useState<IHourlyBreakDownResponse>();
    const [hourlyPage, setHourlyPage] = React.useState(0);
    const [hourlyRowsPerPage, setHourlyRowsPerPage] = React.useState(12);
    const rootStore = useContext(RootStoreContext);


    const { selectedEnvironment } = rootStore.billingStore;

    const getHourlyBreakdown = async () => {
        await agent.Billing.serviceConsumptionHourlyBreakdown({
            start_date: start_date.toDateString(),
            end_date: end_date.toDateString(),
            page_nr: hourlyPage + 1,
            items_per_page: hourlyRowsPerPage,
            environment_id: selectedEnvironment
        })
            .then((response) => {
                setHourlyBreakdown(response);
            })
            .catch((error) => {
                setHourlyBreakdown(undefined);
                console.log(error);
            });
    };

    React.useEffect(() => {
        getHourlyBreakdown();

    }, [start_date, end_date, hourlyPage, hourlyRowsPerPage, selectedEnvironment]);

    React.useEffect(() => {
        setHourlyPage(0);

        return () => {
            setHourlyPage(0);
        }

    }, [start_date, end_date]);


    return (
        <Card>
            <CardContent>
                <Typography fontSize={15} fontWeight="bold" gutterBottom>
                {format(new Date(start_date), "dd/MM/yyyy HH:mm")} to 
                {
                    //check to see if end_date is today, if so, display "Now" instead of the time
                    format(new Date(end_date), "dd/MM/yyyy") === format(new Date(), "dd/MM/yyyy") ? " Now" : format(new Date(end_date), " dd/MM/yyyy HH:59")
                }
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Hour</TableCell>
                            <TableCell align="right">Cost</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hourlyBreakdown?.hours.map((hour, i) => (
                            <HourlyBreakdownRow key={i} hour={hour} currency_symbol={currency_symbol} />
                        ))}
                    </TableBody>
                </Table>
                {hourlyBreakdown && (
                    <TablePagination
                        rowsPerPageOptions={[12, 24, 48, 72]}
                        component="div"
                        count={hourlyBreakdown.total_number_of_items}
                        rowsPerPage={hourlyRowsPerPage}
                        page={hourlyBreakdown?.current_page! - 1 || 0}
                        onPageChange={(e, page) => {
                            setHourlyPage(page);
                        }}
                        onRowsPerPageChange={(e) => {
                            setHourlyRowsPerPage(parseInt(e.target.value, 10));
                        }}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default observer(HourlyBreakdown);
