import { Button, DialogActions, DialogContent, Divider } from "@mui/material";
import { agent, CreateStreamAdaptationRequest, RootStoreContext } from "api";
import { RadioGroupField, SelectField, SliderField, TextField } from "components/Forms";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";

interface Props {
    mode: "create" | "edit";
    initialValues?: CreateStreamAdaptationRequest | any;
    onSubmitted: (values: CreateStreamAdaptationRequest) => void;
}

const StreamAdaptationForm: React.FC<Props> = (props) => {
    const rootStore = React.useContext(RootStoreContext);

    const [scramblingPresets, setScramblingPresets] = React.useState<any[]>([]);

    const { closeDialog } = rootStore.dialogStore;

    React.useEffect(() => {
        props.initialValues && console.log("props.initialValues", props.initialValues);
    }, [props.initialValues]);

    React.useEffect(() => {
        agent.NeaLive.listScramblingPresets()
            .then((response) => {
                console.log("response", response.presets);
                // filter out presets where nea_instance_uuid is not null

                const presets = response.presets.filter((preset: any) => !preset.pull_packager_uuid);

                setScramblingPresets(presets);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, []);

    return (
        <Form
            onSubmit={(values: CreateStreamAdaptationRequest | any) => {
                console.log("values", values);

                if (props.mode === "create") {
                    return agent.NeaLive.createStreamAdaptation(values)
                        .then((response) => {
                            console.log("response", response);
                            props.onSubmitted(response);
                            closeDialog();
                        })
                        .catch((error) => {
                            console.log("error", error);
                            const errors = error.data?.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                }
                return agent.NeaLive.updateStreamAdaptation(props.initialValues?.id!, values)
                    .then((response) => {
                        console.log("response", response);
                        props.onSubmitted(response);
                        closeDialog();
                    })
                    .catch((error) => {
                        console.log("error", error);
                        const errors = error.data?.errors;
                        return { [FORM_ERROR]: error, ...errors };
                    });
            }}
            initialValues={props.initialValues}
            render={({
                handleSubmit,
                form,
                submitting,
                values,
                invalid,
                dirty,
                submitError,
                submitErrors,
                submitSucceeded,
                submitFailed,
                hasSubmitErrors,
                dirtySinceLastSubmit
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <TextField
                                name="name"
                                label="Name"
                                required
                                disabled={props.mode === "edit"}
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                            />
                            <RadioGroupField
                                name="parameters.type"
                                label="Type"
                                row
                                options={[
                                    { label: "Apple HLS", value: "Apple HLS" },
                                    { label: "MPEG-DASH", value: "MPEG-DASH" }
                                ]}
                                required
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                                onChange={(field, value) => {
                                    form.change("parameters.scrambling", undefined);
                                }}
                            />
                            <Divider sx={{ my: 2 }} />
                            {values?.parameters?.type === "Apple HLS" && (
                                <SelectField
                                    name="parameters.apple_hls_settings.protocol_version"
                                    label="Apple HLS Protocol Version"
                                    initialValue={values?.parameters?.apple_hls_settings?.protocol_version || 7}
                                    options={[
                                        { text: "Auto", value: 0 },
                                        { text: "1", value: 1 },
                                        { text: "2", value: 2 },
                                        { text: "3", value: 3 },
                                        { text: "4", value: 4 },
                                        { text: "5", value: 5 },
                                        { text: "6", value: 6 },
                                        { text: "7", value: 7 },
                                        { text: "8", value: 8 },
                                        { text: "9", value: 9 },
                                        { text: "10", value: 10 }
                                    ]}
                                    required
                                    validate={(value: any) => {
                                        if (!value && value !== 0) {
                                            return "Required";
                                        }
                                    }}
                                />
                            )}
                            <SelectField
                                name="parameters.segment_duration"
                                label="Segment Duration"
                                initialValue={values?.parameters?.segment_duration || 6}
                                options={[
                                    { text: "2", value: 2 },
                                    { text: "4", value: 4 },
                                    { text: "6", value: 6 },
                                    { text: "8", value: 8 },
                                    { text: "10", value: 10 }
                                ]}
                                required
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                            />
                            <SliderField
                                name="parameters.live_window_length"
                                label="Live Window Length"
                                min={1}
                                max={10000}
                                step={10}
                                initialValue={values?.parameters?.live_window_length || 60}
                                validate={(value: any) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                            />

                            <RadioGroupField
                                name="parameters.splicing_method"
                                label="Splicing Method"
                                row
                                options={[
                                    { label: "None", value: "None" },
                                    { label: "ESAM MCC", value: "ESAM MCC" },
                                    {
                                        label: "CUE-IN/OUT tags",
                                        value: "CUE-IN/OUT tags",
                                        disabled: values?.parameters?.type === "MPEG-DASH"
                                    },
                                    {
                                        label: "EXT-X-DATERANGE tags",
                                        value: "EXT-X-DATERANGE tags",
                                        disabled: values?.parameters?.type === "MPEG-DASH"
                                    }
                                ]}
                            />
                            <Divider sx={{ my: 2 }} />
                            <SelectField
                                name="parameters.scrambling_preset_id"
                                label="Scrambling Preset"
                                options={
                                    //prepend an empty option
                                    [{ text: "None", value: "" }].concat(
                                        scramblingPresets
                                            .filter((preset) =>
                                                values?.parameters?.type === "MPEG-DASH"
                                                    ? preset.type === "DASH"
                                                    : values?.parameters?.type === "Apple HLS"
                                                    ? preset.type === "HLS"
                                                    : false
                                            )
                                            .map((preset) => ({
                                                text: preset.name + " (" + preset.type + ")",
                                                value: preset.id
                                            }))
                                    )
                                }
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog}>Cancel</Button>
                            <Button type="submit" disabled={submitting || (invalid && !dirtySinceLastSubmit)} color="primary" variant="contained">
                                {props.mode === "create" ? "Create" : "Update"}
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        />
    );
};

export default StreamAdaptationForm;
