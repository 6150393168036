import React from "react";
import { GlossaryItemType } from "hooks";

/**
 * `RenderGlossary` is a functional component that renders a glossary term.
 *
 * @component
 * @param {GlossaryItemType} term - The term to be rendered. It can be a string or an object with a 'definition' property.
 *
 * @example
 * <RenderGlossary term="example term" />
 * <RenderGlossary term={{definition: "example definition"}} />
 *
 * @returns {JSX.Element} The rendered term or its definition if it's an object, or an empty fragment if the term is neither a string nor an object with a 'definition' property.
 */
export const RenderGlossary: React.FC<{ term: GlossaryItemType }> = ({ term }) => {
    if (!term) {
        return <></>;
    }

    if (typeof term === 'string') {
        return <>{term}</>;
    }

    if (typeof term.definition === 'string') {
        return (
            <>{term.definition}</>
        );
    }

    return <></>;
};

export default RenderGlossary;