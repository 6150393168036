/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { agent, IFileClusterTemplates, IFileClusterJobTemplates, IFileClusterJobTemplatePayload, RootStoreContext } from "api";
import { Editor, useMonaco } from "@monaco-editor/react";
import { Box, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tab, TextField } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Add, ChevronRightRounded, Close, Edit, IntegrationInstructions, VideoFile } from "@mui/icons-material";

interface JobCreatorProps {
    deployment_id: string;
    onJobCreated: () => void;
}

const JobCreator: React.FC<JobCreatorProps> = observer(function JobCreator(props) {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const [activeIndex, setActiveIndex] = useState<any>(0);

    const [invalidJson, setInvalidJson] = useState<boolean>(true);

    const [invalidConfigJson, setInvalidConfigJson] = useState<boolean>(true);

    const [tabValue, setTabValue] = useState("1");

    const [showSaveTemplateOverlay, setShowSaveTemplateOverlay] = useState<boolean>(false);

    const [activeTemplates, setActiveTemplates] = useState<string>("jobs");

    const initialTemplate: IFileClusterJobTemplatePayload = {
        name: "",
        configuration: {
            using: "",
            assets: {},
            outputs: {}
        }
    };

    const [, setConfigPlaceholder] = useState({});
    const [jobJsonText, setJobJsonText] = useState<string>();
    const [newJobJson, setNewJobJson] = useState(initialTemplate);

    const [configJsonText, setConfigJsonText] = useState<string>();

    const [newConfigJson, setNewConfigJson] = useState(initialTemplate);

    const [configErrorMessage, setConfigErrorMessage] = useState<string>();

    const [jobErrorMessage, setJobErrorMessage] = useState<string>();

    const [templates, setTemplates] = useState<IFileClusterTemplates>();
    const [jobTemplates, setJobTemplates] = useState<IFileClusterJobTemplates>();
    const [activeConfigTemplateId, setActiveConfigTemplateId] = useState("");
    const [activeJobTemplateId, setActiveJobTemplateId] = useState("");
    //const [ activeTemplate, setActiveTemplate] = useState<IFileClusterTemplate>({id: '', name: '', payload: {}});

    const [templateName, setTemplateName] = useState<string>("");
    const [templateDescription, setTemplateDescription] = useState<string>("");

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<SVGSVGElement>(null);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleCreateNewJob = async () => {
        if (newJobJson.hasOwnProperty("configuration")) {
            await agent.FileCluster.createJob(props.deployment_id, newJobJson)
                .then(() => {
                    props.onJobCreated();
                    setJobErrorMessage("");
                    closeDialog();
                })
                .catch((err) => {
                    console.log(err);
                    setJobErrorMessage(err.data.message);
                });
        } else {
            setJobErrorMessage("Name and configuration are required fields.");
        }
    };

    const handleSaveConfigTemplate = async () => {
        if (newConfigJson.hasOwnProperty("name")) {
            await agent.FileCluster.createTemplate(props.deployment_id, {
                name: newConfigJson.name,
                payload: newConfigJson
            })
                .then(() => {
                    console.log("Template saved");
                    getTemplates(props.deployment_id);
                })
                .catch((err) => {
                    const message: any = err;
                    setConfigErrorMessage(message.data.message);
                });
        }
    };

    const handleDeleteConfigTemplate = async () => {
        if (activeConfigTemplateId !== "") {
            await agent.FileCluster.deleteTemplate(activeConfigTemplateId)
                .then(() => {
                    setActiveIndex(0);
                })
                .catch((err) => {
                    const message: any = err;
                    setConfigErrorMessage(message.data.message);
                });
        }
    };

    const handleDeleteJobTemplate = async () => {
        if (activeJobTemplateId !== "") {
            await agent.FileCluster.deleteJobTemplate(activeJobTemplateId)
                .then(() => {
                    setActiveIndex(0);
                    setActiveJobTemplateId("");
                })
                .catch((err) => {
                    const message: any = err;
                    setJobErrorMessage(message.data.message);
                    console.log(jobErrorMessage);
                });
        }
    };

    const handleCreateJobTemplate = async (name: string, description?: string) => {
        const payload = {
            name: name,
            description: description,
            payload: newJobJson
        };
        await agent.FileCluster.createJobTemplate(payload)
            .then(() => {
                getJobTemplates();
                setShowSaveTemplateOverlay(false);
            })
            .catch((err) => {
                const message: any = err;
                setJobErrorMessage(message.data.message);
            });
    };

    const getTemplates = async (deployment_id: string) => {
        await agent.FileCluster.getTemplates(deployment_id).then((values) => {
            setTemplates(values);
        });
    };

    const getJobTemplates = async () => {
        const values = await agent.FileCluster.getJobTemplates();
        setJobTemplates(values);
    };

    const applyTemplate = (template: any) => {
        //setActiveTemplate(template);
        setActiveConfigTemplateId(template.id);
        setActiveIndex(1);
        setConfigJsonText(JSON.stringify(template.payload, null, 2));
        setNewConfigJson(template.payload);
        setInvalidConfigJson(false);
    };

    const applyJobTemplate = (template: any) => {
        setActiveJobTemplateId(template.id);
        setActiveIndex(0);
        setJobJsonText(JSON.stringify(template.payload, null, 2));
        setNewJobJson(template.payload);
        setInvalidJson(false);
    };

    useEffect(() => {
        getJobTemplates();
        if (props.deployment_id) {
            getTemplates(props.deployment_id);
        }
        // eslint-disable-next-line
    }, []);

    const monaco = useMonaco();

    monaco?.editor.defineTheme("my-dark", {
        base: "vs-dark",
        inherit: true,
        rules: [],
        colors: {
            "editor.background": "#0F1419"
        }
    });

    return (
        <div style={{ width: "100%" }} className="create-job-container">
            <div className="create-job-header">
                <div className="create-job-header-left">
                    <span>
                        Job Editor
                        {newJobJson?.name && (
                            <span style={{ fontWeight: 300 }}>{newJobJson?.name ? " - " + newJobJson.name + "*" : ""}</span>
                        )}
                    </span>
                    <div></div>
                </div>
                <div className="create-job-header-right">
                    <Close name="close" style={{ fontSize: 14, cursor: "pointer" }} onClick={closeDialog} />
                </div>
            </div>

            <div className="create-job-container-inner">
                <div className="create-job-toggle-bar">
                    <div
                        className={activeTemplates === "jobs" ? "create-job-toggle-bar-item active" : "create-job-toggle-bar-item"}
                        title="Job Templates"
                        onClick={() => setActiveTemplates("jobs")}>
                        <VideoFile name="file video outline" style={{ fontSize: 18 }} />
                    </div>
                    <div
                        className={activeTemplates === "config" ? "create-job-toggle-bar-item active" : "create-job-toggle-bar-item"}
                        title="Config Templates"
                        onClick={() => setActiveTemplates("config")}>
                        <IntegrationInstructions name="file code outline" style={{ fontSize: 18 }} />
                    </div>
                </div>
                <div className="create-job-sidebar">
                    {activeTemplates === "config" && (
                        <>
                            <div className="template-list-header">
                                Config Templates{" "}
                                <Add
                                    name="plus"
                                    style={{ fontSize: 12, cursor: "pointer" }}
                                    onClick={() => {
                                        setConfigPlaceholder({});
                                        setActiveIndex(1);
                                    }}
                                />
                            </div>
                            <div className="template-list">
                                {templates?.data.map((template, i) => (
                                    <div className="template-list-item" key={i}>
                                        <div className="template-list-item-label">{template.name}</div>
                                        <div className="template-list-item-icon">
                                            <IconButton size="small" onClick={() => applyTemplate(template)}>
                                                <Edit
                                                    name="pencil"
                                                    style={{ fontSize: "14px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setTabValue("2");
                                                        applyTemplate(template);
                                                    }}
                                                    //title="Edit Config Template"
                                                />
                                            </IconButton>
                                            <IconButton size="small" onClick={() => applyTemplate(template)}>
                                                <ChevronRightRounded
                                                    name="chevron circle right"
                                                    style={{ fontSize: "14px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setTabValue("1");
                                                        applyTemplate(template);
                                                    }}
                                                    //title="Use Config Template"
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {activeTemplates === "jobs" && (
                        <>
                            <div className="template-list-header">Job Templates</div>
                            <div className="template-list">
                                {jobTemplates?.data.map((template, i) => (
                                    <div
                                        className="template-list-item"
                                        key={i}
                                        onClick={() => applyJobTemplate(template)}
                                        title="Load Job Template">
                                        <div className="template-list-item-label">{template.name}</div>
                                        <div className="template-list-item-icon">
                                            <ChevronRightRounded
                                                name="chevron circle right"
                                                style={{ fontSize: "14px", cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className="create-job-body">
                    <TabContext value={tabValue}>
                        <Box>
                            <TabList onChange={handleTabChange} aria-label="source details tabs">
                                <Tab label="Job" value="1" />
                                <Tab label="Config" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                            <Editor
                                value={jobJsonText}
                                defaultLanguage="json"
                                height={"25rem"}
                                theme="my-dark"
                                options={{
                                    autoIndent: "full",
                                    automaticLayout: true,
                                    minimap: {
                                        enabled: false
                                    },
                                    wordWrap: "on",
                                    formatOnPaste: true,
                                    formatOnType: true
                                }}
                                onChange={(value) => {
                                    setJobJsonText(value);
                                    try {
                                        if (value && JSON.parse(value)) {
                                            setNewJobJson(JSON.parse(value));
                                            setInvalidJson(false);
                                        }
                                    } catch (error) {
                                        setInvalidJson(true);
                                    }
                                }}
                            />
                            <div className="create-job-footer">
                                <Button
                                    variant="contained"
                                    sx={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                                    disabled={invalidJson}
                                    onClick={handleCreateNewJob}>
                                    Create Job
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={invalidJson}
                                    sx={{
                                        minWidth: 0,
                                        px: 1,
                                        borderBottomLeftRadius: 0,
                                        borderTopLeftRadius: 0,
                                        borderLeft: `1px solid #0F1419`
                                    }}
                                    aria-controls={open ? "split-button-menu" : undefined}
                                    aria-expanded={open ? "true" : undefined}
                                    aria-label="more options"
                                    aria-haspopup="menu"
                                    onClick={handleToggle}>
                                    <ArrowDropDownIcon ref={anchorRef} />
                                </Button>
                                <Popper
                                    sx={{
                                        zIndex: 1
                                    }}
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                    disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                                            }}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList id="split-button-menu" autoFocusItem>
                                                        <MenuItem key={1} onClick={() => setShowSaveTemplateOverlay(true)}>
                                                            Save as Template
                                                        </MenuItem>
                                                        <MenuItem key={2} onClick={handleDeleteJobTemplate}>
                                                            Delete Template
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        </TabPanel>
                        <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                            <Editor
                                value={configJsonText}
                                defaultLanguage="json"
                                height={"25rem"}
                                theme="my-dark"
                                options={{
                                    autoIndent: "full",
                                    automaticLayout: true,
                                    minimap: {
                                        enabled: false
                                    },
                                    wordWrap: "on",
                                    formatOnPaste: true,
                                    formatOnType: true
                                }}
                                onChange={(value) => {
                                    setConfigJsonText(value);
                                    try {
                                        if (value && JSON.parse(value)) {
                                            setNewConfigJson(JSON.parse(value));
                                            setInvalidConfigJson(false);
                                        }
                                    } catch (error) {
                                        setInvalidConfigJson(true);
                                    }
                                }}
                            />
                            {configErrorMessage && (
                                <div
                                    style={{
                                        backgroundColor: "red",
                                        height: "20px",
                                        display: "flex",
                                        zIndex: 100,
                                        position: "absolute",
                                        left: "25px",
                                        right: "25px",
                                        marginTop: "-30px",
                                        color: "#fff",
                                        padding: "0 10px",
                                        borderRadius: "3px",
                                        fontSize: "13px",
                                        alignItems: "center"
                                    }}>
                                    {configErrorMessage}
                                </div>
                            )}
                            <div className="create-job-footer">
                                {activeConfigTemplateId && <Button onClick={handleDeleteConfigTemplate}>Delete Template</Button>}
                                <Button onClick={handleSaveConfigTemplate} disabled={invalidConfigJson}>
                                    Save New Template
                                </Button>
                            </div>
                        </TabPanel>
                    </TabContext>
                </div>
            </div>

            <div className={showSaveTemplateOverlay ? "modal-overlay active" : "modal-overlay"}>
                <div className="modal-overlay-inner">
                    <div className="modal-overlay-header">
                        <div className="modal-overlay-title">Save As Template</div>
                    </div>
                    <div className="modal-overlay-body">
                        <form>
                            <TextField label="Name" onChange={(event) => setTemplateName(event.target.value)} />
                            <TextField label="Description" onChange={(event) => setTemplateDescription(event.target.value)} />
                        </form>
                    </div>
                    <div className="modal-overlay-footer">
                        <Button onClick={() => setShowSaveTemplateOverlay(false)}>Cancel</Button>
                        <Button variant="contained" onClick={() => handleCreateJobTemplate(templateName, templateDescription)}>
                            Save Template
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default JobCreator;
