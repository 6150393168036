import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "api";
import LoginForm from "features/user/Login/LoginForm";
import { observer } from "mobx-react-lite";
import LoginTwoFactor from "features/user/Login/LoginTwoFactor";
import ManagingOrganisationForm from "features/settings/organisations/organisation-management/managingOrganisation/ManagingOrganisationForm";
import { Box, Card, CardContent, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useOrganisation } from "hooks";

const HomePage = observer(function HomePage() {
    const navigate = useNavigate();
    const rootStore = useContext(RootStoreContext);
    const { user, isLoggedIn } = rootStore.userStore;
    const { openDialog, closeDialog, dialog, changeDialogBody } = rootStore.dialogStore;
    const { getManagingOrgList } = useOrganisation();

    const bgImage = "url('/assets/runner.jpg')";

    const navigateToHomepage = () => {
        if (user && user.user_preferences && user.user_preferences.homepage) {
            navigate("/" + user.user_preferences.homepage);
        } else {
            navigate("/dashboard");
        }
    };

    useEffect(
        () => {
            // Open all the popups (to make sure users can refresh the page at any point)
            // isLoggedIn means - has JWT token, has user object and does not have to enter 2fa code
            if (isLoggedIn) {
                if (user && user.signup_stage === "finished" && user.managing_organisation_id) {
                    if (dialog.open) {
                        closeDialog();
                    }
                    navigateToHomepage();
                }
                if (user && user.signup_stage === "finished" && !user.managing_organisation_id) {
                    getManagingOrgList().then(() => {
                        if (dialog.open) {
                            changeDialogBody(<ManagingOrganisationForm />);
                        } else {
                            openDialog(<ManagingOrganisationForm />);
                        }
                    });
                } else if (user && user.signup_stage !== "finished") {
                    if (dialog.open) {
                        closeDialog();
                    }
                    navigate("/setup");
                }
            } else if (!isLoggedIn && user && user.needs_to_enter_2fa) {
                if (dialog.open) {
                    changeDialogBody(<LoginTwoFactor />);
                } else {
                    openDialog(<LoginTwoFactor />, undefined, undefined, true, true);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoggedIn, user, openDialog]
    );

    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundImage: bgImage,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                "&:before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "linear-gradient(148deg,rgba(30,40,50,0.8) 10%,rgba(48,122,187,0.1) 83%)"
                }
            }}>
            <Box></Box>
            <Card elevation={3} sx={{ width: "100%", maxWidth: "400px", zIndex: 2 }}>
                <CardContent>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "20px 0" }}>
                        <img src="/assets/LiveLinkLogo.png" alt="logo" style={{ width: "228px" }} />
                    </Box>
                    <LoginForm />
                    <Box sx={{ textAlign: "center", padding: "20px 0 0 0", opacity: 0.8 }}>
                        <span style={{ color: "#fff" }}>Don't have an account? </span>
                        <Link href="https://www.cerberus.tech/livelink/" underline="hover">
                            Speak to the team
                        </Link>
                    </Box>
                </CardContent>
            </Card>
            <Box sx={{ textAlign: "center", padding: "20px", opacity: 0.8 }}>
                <a
                    style={{ color: "#fff", textDecoration: "underline" }}
                    href="https://www.cerberus.tech/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer">
                    Privacy Policy
                </a>
                <span style={{ color: "#fff" }}> &nbsp;| &nbsp;</span>
                <a
                    href="/termsofservice"
                    style={{
                        color: "#fff",
                        cursor: "pointer",
                        textDecoration: "underline"
                    }}
                    onClick={() => {
                        navigate("/termsofservice");
                    }}>
                    Terms of Service
                </a>{" "}
                <span style={{ color: "#fff" }}> &nbsp;| &nbsp;</span>
                <span style={{ color: "#fff" }}> © Copyright Cerberus Tech 2023</span>
            </Box>
        </Box>
    );
});

export default HomePage;
