import React from "react";
import { Field } from "react-final-form";
import { TextField as MuiTextField } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";

interface Props {
    name: string;
    label: string;
    initialValue?: string;
    rows?: number;
    maxRows?: number;
    validate?: (value: any) => string | undefined;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    onChange?: (field: string, value?: any, previous?: any) => void;
}

/**
 * `MultilineTextField` is a React Functional Component that renders a multiline text field with a label.
 * It uses the Material-UI library for styling and layout, and react-final-form for form state management.
 *
 * @component
 * @param {Object} props - The properties that define the text field.
 * @param {string} props.name - The name of the text field.
 * @param {string} props.label - The label of the text field.
 * @param {string} [props.initialValue] - The initial value of the text field.
 * @param {number} [props.rows] - The number of rows in the text field.
 * @param {number} [props.maxRows] - The maximum number of rows in the text field.
 * @param {function} [props.validate] - A function that validates the text field's value.
 * @param {function} [props.helperText] - A function that returns the helper text.
 * @param {function} [props.error] - A function that returns a boolean indicating if there is an error.
 * @param {function} [props.onChange] - A function that is called when the text field's value changes.
 *
 * @example
 * <MultilineTextField name="name" label="Name" helperText={meta => meta.touched && meta.error} error={meta => meta.touched && meta.error} />
 *
 * @returns {ReactElement} The MultilineTextField component
 */

export const MultilineTextField: React.FC<Props> = ({
    name,
    label,
    initialValue,
    rows,
    maxRows,
    validate,
    helperText,
    error,
    onChange
}) => {
    return (
        <>
            <Field
                name={name}
                initialValue={initialValue}
                validate={(value: any) => {
                    if (validate) {
                        return validate(value);
                    }
                }}>
                {({ input, meta }) => (
                    <MuiTextField
                        {...input}
                        label={label}
                        fullWidth
                        error={Boolean(
                            ((meta.dirty || meta.touched) && meta.error) || meta.submitError || (meta.touched && meta.invalid) || (error && error(meta))
                        )}
                        helperText={((meta.dirty || meta.touched) && meta.error) || meta.submitError || (helperText && helperText(meta))}
                        multiline
                        rows={rows}
                        maxRows={!rows ? maxRows : undefined}
                        //variant="outlined"
                    />
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {
                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </>
    );
};

export default MultilineTextField;
