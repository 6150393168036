import { Avatar, Badge, Box, Button, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsList from "features/notifications/Components/NotificationsList";
import { RootStoreContext, agent } from "api";
import { useNavigate } from "react-router-dom";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsSettingsMenu from "./NotificationsSettingsMenu";
import { observer } from "mobx-react-lite";
import { Delete } from "@mui/icons-material";
import ConfirmationDialog from "components/ConfirmationDialog";

interface Props {
    menuPosition?: string;
}

const NotificationsMenu: React.FC<Props> = ({ menuPosition }) => {
    const navigate = useNavigate();
    const rootStore = React.useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;

    const {
        unreadCriticalNotificationsCount,
        unreadNoneCriticalNotificationsCount,
        unreadNotificationsLoaded,
        unreadNotificationsCountsLoaded
    } = rootStore.notificationStore;

    const { client } = rootStore.resClientStore;
    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const [newNotifications, setNewNotifications] = React.useState<any>(0);

    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {}, [
        newNotifications,
        unreadCriticalNotificationsCount,
        unreadNoneCriticalNotificationsCount,
        unreadNotificationsLoaded,
        unreadNotificationsCountsLoaded
    ]);

    React.useEffect(() => {
        setUpdateDate(new Date());
    }, [unreadCriticalNotificationsCount, unreadNoneCriticalNotificationsCount]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <>
            <Tooltip
                title={`${newNotifications} New Notification${newNotifications > 0 ? "s" : ""}`}
                placement="bottom"
                open={newNotifications > 0 && !open}>
                <IconButton
                    size="large"
                    onClick={handleClick}
                    sx={{
                        backgroundColor: open ? theme.palette.action.selected : undefined,
                        color: theme.palette.topbar?.contrastText
                    }}>
                    {unreadCriticalNotificationsCount > 0 ? <NotificationImportantIcon /> : <NotificationsIcon />}
                    {unreadCriticalNotificationsCount + unreadNoneCriticalNotificationsCount > 0 && (
                        <Badge
                            badgeContent={unreadCriticalNotificationsCount + unreadNoneCriticalNotificationsCount}
                            color={unreadCriticalNotificationsCount > 0 ? "error" : "primary"}
                            sx={{ position: "absolute", top: 5, right: 5 }}
                            className={unreadCriticalNotificationsCount > 0 && !open ? "pulse" : undefined}
                        />
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                id="notifications-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={
                    menuPosition === "right" ? { vertical: "bottom", horizontal: "left" } : { vertical: "top", horizontal: "right" }
                }
                open={open}
                onClose={handleClose}
                sx={{
                    p: "0px !important",
                    display: "flex",
                    flexDirection: "column",
                    "& .MuiMenu-paper": {
                        width: 380,
                        p: "0px !important",
                        height: "100%"
                    },
                    "& .MuiList-root": {
                        pt: "0px !important",
                        pb: "0px !important",
                        height: "100%"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        minHeight: 400,
                        maxHeight: "calc(100vh - 100px)"
                    }}>
                    <MenuItem
                        sx={{
                            p: 2,
                            backgroundColor: theme.palette.action.hover,
                            display: "flex",
                            alignItems: "center"
                            //mb: 2
                        }}>
                        <Avatar
                            sx={{
                                mr: 2,
                                backgroundColor:
                                    unreadCriticalNotificationsCount > 0 ? theme.palette.error.main : theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                width: 45,
                                height: 45
                            }}>
                            {unreadCriticalNotificationsCount > 0 ? <NotificationImportantIcon /> : <NotificationsIcon />}
                        </Avatar>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="body1" fontWeight={600}>
                                Unread Notifications
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {unreadCriticalNotificationsCount + unreadNoneCriticalNotificationsCount || "0"} unread,{" "}
                                {unreadCriticalNotificationsCount || "0"} critical
                            </Typography>
                        </Box>
                        <Stack direction="row">
                            <NotificationsSettingsMenu />
                            <Tooltip title="Clear All">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        handleClose();
                                        openDialog(
                                            <ConfirmationDialog
                                                title="Clear All Notifications"
                                                message="Are you sure you want to clear all notifications?"
                                                onConfirm={async () => {
                                                    await agent.Notification.deleteAllNotifications();
                                                }}
                                            />
                                        );
                                    }}>
                                    <Delete fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </MenuItem>
                    <Box sx={{ height: "100%", flexShrink: 1, overflowY: "auto", p: 0 }}>
                        <NotificationsList />
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            backgroundColor: theme.palette.action.hover
                        }}>
                        <Button
                            fullWidth
                            variant="toolbar"
                            //startIcon={<SettingsIcon />}
                            onClick={() => {
                                navigate("/notifications");
                                handleClose();
                            }}>
                            View All
                        </Button>
                    </Box>
                </Box>
            </Menu>
        </>
    );
};

export default observer(NotificationsMenu);
