import React, { useContext } from "react";
import { FORM_ERROR } from "final-form";
import { Form as FinalForm } from "react-final-form";
import { TextField } from "mui-rff";
import ErrorMessage from "app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";
import { combineValidators, composeValidators, createValidator, isRequired } from "revalidate";
import { IOrganisationFormValues, RootStoreContext } from "api";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { useOrganisation } from "hooks";

const OrgStepCompleteMessage = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    return (
        <>
            <DialogContent>
                <Typography>
                    You have successfully completed the setup. <br />
                    You can now close this window and start using the platform. Thank you for your patience and good luck!
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}
                    color="primary"
                    variant="contained">
                    OK
                </Button>
            </DialogActions>
        </>
    );
};

const CreateOrg = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, getUser } = rootStore.userStore;
    const { openDialog } = rootStore.dialogStore;
    const { createOrganisation } = useOrganisation();

    const pageDisabled = !user!.two_factor_auth_step_complete;

    const isValidName = createValidator(
        (message) => (value) => {
            if (value) {
                if (value.length > 32) {
                    return message;
                }
            }
        },
        "Name can not be above 32 characters"
    );

    const validate = combineValidators({
        name: composeValidators(isValidName, isRequired("name"))()
    });

    return (
        <Box>
            <Typography variant="h3" gutterBottom>
                Create a new organisation
            </Typography>

            <FinalForm
                onSubmit={(values: IOrganisationFormValues) =>
                    createOrganisation(values)
                        .then(() => {
                            // Refresh the user so that the first step gets marked complete
                            getUser().then(() => {
                                openDialog(<OrgStepCompleteMessage />, "Setup Complete!");
                            });
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                            name: error.data.errors ? error.data.errors.name : null
                        }))
                }
                validate={validate}
                render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ width: 350 }}>
                            <TextField
                                fullWidth
                                required
                                name="name"
                                type="text"
                                placeholder="Name your Organisation"
                                label="Organisation Name"
                            />
                            {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                            <LoadingButton
                                disabled={(invalid && !dirtySinceLastSubmit) || pristine || submitting || pageDisabled}
                                loading={submitting}
                                variant="contained"
                                type="submit"
                                color="primary">
                                Create Organisation
                            </LoadingButton>
                        </Box>
                    </form>
                )}
            />
        </Box>
    );
};

export default observer(CreateOrg);
