import React, { useState, useEffect } from "react";
import { InputSourceAccess } from "api";
import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useSourcePermissions } from "hooks";

export const InputSourceAccessEntryRow = (props: { row: InputSourceAccess; token: any; type?: string }) => {
    const { grantChannelAccess, removeChannelAccess, loadSourcePermissions } = useSourcePermissions();
    const { row, token, type } = props;
    const [hasAccess, setHasAccess] = useState(row.enabled);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        if (hasLoaded) {
            return;
        }

        setHasLoaded(true);
    }, []);

    useEffect(() => {
        if (!hasLoaded) {
            return;
        }

        if (hasAccess) {
            grantChannelAccess(
                {
                    source_id: row.id
                },
                token
            ).then(() => loadSourcePermissions());
        } else {
            removeChannelAccess(token, row.id).then(() => loadSourcePermissions());
        }
    }, [hasAccess]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasAccess(event.target.checked);
    };

    return (
        <TableContainer component={Paper} className="source-table-container">
            <Table style={{ tableLayout: "fixed" }}>
                <TableBody>
                    <TableRow className="source-row">
                        <TableCell>
                            <Typography style={{ marginLeft: 20 }}>Source "{row.name}"</Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: "right", paddingRight: "0px !important" }}>
                            {type !== "guest" && (
                                <Switch checked={hasAccess} onChange={handleChange} color="primary" name="channelAccess" />
                            )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
