import { Box, Chip, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { RootStoreContext, agent } from "api";
import React from "react";
import { ResCollection, ResModel } from "resclient";
import { format } from "date-fns";

interface DestinationScheduleProps {
    destination_id: string;
}

const DestinationSchedule: React.FC<DestinationScheduleProps> = ({ destination_id }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { client, clientConnected } = rootStore.resClientStore;

    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const active_env_id = rootStore.userStore.activeenvironment;

    const rid = `environments.outputs.${managing_org_id}.${active_env_id}.output.${destination_id}`;

    const [wsData, setWsData] = React.useState<ResModel | ResCollection | any>({});

    const [historyData, setHistoryData] = React.useState<any>();

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    const getScheduleHistory = async (destination_id: string) => {
        await agent.Scheduler.getOutputSchedules(destination_id)
            .then((response) => {
                console.log("historyData", response);
                setHistoryData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getScheduleHistory(destination_id);
        clientConnected &&
            client
                .get(rid)
                .then((res) => {
                    setWsData(res);
                    console.log("wsData", res);
                    res.on("change", () => {
                        getScheduleHistory(destination_id);
                        setUpdateDate(new Date());
                        setWsData(res);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [client, clientConnected, rid, destination_id]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Source</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Typography variant="h6">
                                <Box
                                    component="span"
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        display: "inline-block",
                                        borderRadius: "50%",
                                        mr: 1,
                                        backgroundColor: (theme) => theme.palette.success.main
                                    }}></Box>
                                Live
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {historyData &&
                        historyData.data &&
                        historyData.data
                            .filter((history: any) => history.ui_status === "on")
                            .sort((a: any, b: any) => new Date(b.sched_start_time).getTime() - new Date(a.sched_start_time).getTime())
                            .map((history: any) => (
                                <TableRow key={history.id}>
                                    <TableCell>{history.source_name}</TableCell>
                                    <TableCell>
                                        <Chip color="success" size="small" label="Live" />
                                    </TableCell>
                                    <TableCell>{format(new Date(history.sched_start_time), "dd/MM/yyyy HH:mm")}</TableCell>
                                    <TableCell>{format(new Date(history.sched_end_time), "dd/MM/yyyy HH:mm")}</TableCell>
                                </TableRow>
                            ))}
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Typography variant="h6">
                                <Box
                                    component="span"
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        display: "inline-block",
                                        borderRadius: "50%",
                                        mr: 1,
                                        backgroundColor: (theme) => theme.palette.primary.main
                                    }}></Box>
                                Scheduled
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {historyData &&
                        historyData.data &&
                        historyData.data
                            .filter((history: any) => history.ui_status === "scheduled")
                            .sort((a: any, b: any) => new Date(a.sched_start_time).getTime() - new Date(b.sched_start_time).getTime())
                            .map((history: any) => (
                                <TableRow key={history.id}>
                                    <TableCell>{history.source_name}</TableCell>
                                    <TableCell>
                                        <Chip color="primary" size="small" label="Scheduled" />
                                    </TableCell>
                                    <TableCell>{format(new Date(history.sched_start_time), "dd/MM/yyyy HH:mm")}</TableCell>
                                    <TableCell>{format(new Date(history.sched_end_time), "dd/MM/yyyy HH:mm")}</TableCell>
                                </TableRow>
                            ))}
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Typography variant="h6">
                                <Box
                                    component="span"
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        display: "inline-block",
                                        borderRadius: "50%",
                                        mr: 1,
                                        backgroundColor: (theme) => theme.palette.action.hover
                                    }}></Box>
                                Finished
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {historyData &&
                        historyData.data &&
                        historyData.data
                            .filter((history: any) => history.ui_status === "finished")
                            .sort((a: any, b: any) => new Date(b.sched_start_time).getTime() - new Date(a.sched_start_time).getTime())
                            .map((history: any) => (
                                <TableRow key={history.id}>
                                    <TableCell>{history.source_name}</TableCell>
                                    <TableCell>
                                        <Chip size="small" label="Finished" />
                                    </TableCell>
                                    <TableCell>{format(new Date(history.sched_start_time), "dd/MM/yyyy HH:mm")}</TableCell>
                                    <TableCell>{format(new Date(history.sched_end_time), "dd/MM/yyyy HH:mm")}</TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default DestinationSchedule;
