import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { format, parseISO, subDays } from "date-fns";
import { agent, IActivityLogResponse } from "api";
import { toast } from "react-toastify";
import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";

export interface DataRowModel {
    id: number;
    time: string;
    user_email: string;
    action: string;
    action_tag: string;
    environment_name: string;
    transcoder_name: string;
    source_name: string;
    destination_name: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UserActivityWidget = observer(function UserActivityWidget() {
    const navigate = useNavigate();

    const request = {
        page: 1,
        sort: "time:desc",
        date_from: format(subDays(new Date(), 3), "yyyy-MM-dd")
    };

    const [activity, setActivity] = useState<IActivityLogResponse>();

    const [rows, setRows] = useState<DataRowModel[]>([]);
    const getActivity = async () => {
        await agent.Users.getUsersActivity(request)
            .then((response) => {
                setActivity(response);
                setRows(
                    response.data.map((item: any) => {
                        return {
                            id: item.id,
                            time: item.time,
                            user_email: item.user_email,
                            action: item.action,
                            action_tag: item.action_tag,
                            environment_name: item.environment_name,
                            transcoder_name: item.transcoder_name,
                            source_name: item.source_name,
                            destination_name: item.destination_name
                        };
                    })
                );
            })
            .catch((err: any) => {
                console.log(err);
                toast.error(err.message);
            });
    };

    useEffect(() => {
        getActivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {activity && (
                <Box sx={{ width: "100%" }}>
                    <nav aria-label="secondary mailbox folders">
                        <List>
                            <Divider sx={{ color: "text.primary" }} />
                            {rows.map((row, i) => (
                                <ListItem disablePadding key={i} divider>
                                    <ListItemButton onClick={() => navigate("/user-activity")}>
                                        <Stack>
                                            <Typography variant="body2">{format(parseISO(row.time), "dd/LL/yyyy @ HH:mm:ss ")}</Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {row.user_email}
                                            </Typography>
                                            <ListItemText primary={row.action} />
                                        </Stack>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </nav>
                </Box>
            )}
        </>
    );
});

export default UserActivityWidget;
