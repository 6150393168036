import { Box, List, ListItem, ListItemButton } from "@mui/material";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import RoleProvider from "components/RoleProvider";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

export const OwnCloud: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <View>
            <ViewHeader title="Own Cloud" />
            <ViewBody noPadding>
                <Content
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/settings/own-cloud"}
                                    onClick={() => {
                                        navigate("");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Enable / Disable</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/settings/own-cloud/validate-credentials"}
                                    onClick={() => {
                                        navigate("validate-credentials");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Validate Credentials</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/settings/own-cloud/set-regions"}
                                    onClick={() => {
                                        navigate("set-regions");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Set Regions</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/settings/own-cloud/limits"}
                                    onClick={() => {
                                        navigate("limits");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Limits</Box>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    leftWidth={220}
                    noPadding>
                    <Outlet />
                </Content>
            </ViewBody>
        </View>
    );
};

export default OwnCloud;
