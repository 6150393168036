import { RootStore } from "./rootStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import { IPermanentSchedules, IPermanentSchedulesDropDownValues, IPermanentScheduleCommitments } from "api";

export default class PermanentScheduleStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable dropDownValues: IPermanentSchedulesDropDownValues | null = null;
    @observable commitmentValues: IPermanentSchedules | null = null;
    @observable permCommitments: IPermanentScheduleCommitments | null = null;
    @observable checked: boolean = false;

    @action setDropDownValues = (values: IPermanentSchedulesDropDownValues) => {
        this.dropDownValues = values;
    };

    @action setCommitmentValues = (values: IPermanentSchedules) => {
        this.commitmentValues = values;
    };

    @action setPermCommitments = (values: IPermanentScheduleCommitments) => {
        this.permCommitments = values;
    };

    @action setChecked = (value: boolean) => {
        this.checked = value;
    };

    @action setClicked = (productId: number) => {
        const prices = this.commitmentValues?.prices!.find((prod) => prod.product_id === productId);
        prices!.clicked = !prices?.clicked;
        runInAction(() => {
            this.checked = true;
        });
    };

    @action setClickedFalse = () => {
        runInAction(() => {
            this.commitmentValues!.prices.map((pr) => (pr.clicked = false));
            this.checked = false;
        });
    };
}
