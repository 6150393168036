import { Box, Button, Chip, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { agent, TranscoderPresetsResult, RootStoreContext } from "api";
import { DeletePackagerPreset } from "features/pullPackager/Components/DeletePackagerPreset";
import { FORM_ERROR } from "final-form";
import React, { SyntheticEvent, useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface PresetSelectProps {
    values: any;
    loadPreset: (name: string, preset: any) => void;
}

export const PresetSelect: React.FC<PresetSelectProps> = ({ values, loadPreset }) => {
    const [presets, setPresets] = useState<TranscoderPresetsResult>();
    const rootStore = useContext(RootStoreContext);
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [chosenPreset, setChosenPreset] = useState<number>();

    const getPresets = async () => {
        await agent.Packager.getPackagerPresets("push")
            .then((response) => {
                console.log(response);
                if (response) {
                    setPresets(response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handlePresetDelete = (e: SyntheticEvent, id: number) => {
        agent.Packager.deletePackagerPreset("push", id)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                const errors = error.data.errors;
                return Promise.resolve({ [FORM_ERROR]: error, ...errors });
            })
            .finally(() => {
                closeDialog();
                closeDialog();
                getPresets();
            });
    };

    React.useEffect(() => {
        getPresets();
    }, []);

    return (
        <>
            {presets && presets.data && presets.data.length > 0 && (
                <Box>
                    <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                        Preset
                    </Typography>
                    <Select name="preset" placeholder="Preset" label="Preset" fullWidth>
                        <MenuItem value={0}>No Preset</MenuItem>
                        {presets?.data.map((preset) => (
                            <MenuItem
                                key={preset.id}
                                value={preset.id}
                                onClick={() => {
                                    loadPreset(values.name, preset.parameters);
                                    setChosenPreset(preset.id);
                                }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                        <Typography>{preset.name}</Typography>
                                        {preset.service_config_version && (
                                            <Tooltip title="Service Config Version">
                                                <Chip size="small" label={"v" + preset.service_config_version} />
                                            </Tooltip>
                                        )}
                                    </Stack>

                                    <Box sx={{ justifyContent: "flex-end", display: chosenPreset === preset.id ? "none" : "" }}>
                                        <Tooltip title="Delete preset">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openDialog(<DeletePackagerPreset id={preset.id} handleDelete={handlePresetDelete} />);
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            )}
        </>
    );
};

export const PresetSave: React.FC = () => {
    return <></>;
};

export default PresetSelect;
