import { Box, useTheme } from "@mui/material";
import {
    agent,
    NeaLive,
    StreamAdaptation,
    StreamAdaptationFamily,
    StreamAdaptationFamilySource,
    RootStoreContext
} from "api";
import React, { useContext } from "react";
import { Edge, Node, Position } from "reactflow";

interface useConstructNodesProps {
    values: NeaLive;
    setActiveSidebarPanel: React.Dispatch<React.SetStateAction<string>>;
    setActiveSidebarTab: React.Dispatch<React.SetStateAction<string>>;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
    setCollapseSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveFormPath: React.Dispatch<React.SetStateAction<string>>;
    setActiveFamily: React.Dispatch<React.SetStateAction<number>>;
    setActiveFamilyId: React.Dispatch<React.SetStateAction<number>>;
    setOutputs: React.Dispatch<React.SetStateAction<any[]>>;
    droppableType: string;
    setDroppableElementType: React.Dispatch<React.SetStateAction<string>>;
    setDroppableElementId: React.Dispatch<React.SetStateAction<string>>;
    onMenuItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    onDeleteFamily: (index: number) => void;
    onRemoveStreamAdaptation: (family_id: number, stream_adaptation_id: number) => void;
    onRemoveTranscoder: (family_id: number, transcoder_id: string) => void;
    onTranscoderClick: (id: string, rid: string) => void;
}

export const useConstructNodes = ({
    values,
    setActiveSidebarPanel,
    setActiveSidebarTab,
    setActiveTab,
    setCollapseSidebar,
    setActiveFormPath,
    setActiveFamily,
    setActiveFamilyId,
    setOutputs,
    droppableType,
    setDroppableElementType,
    setDroppableElementId,
    onMenuItemClick,
    onDeleteFamily,
    onRemoveStreamAdaptation,
    onRemoveTranscoder,
    onTranscoderClick
}: useConstructNodesProps) => {
    const gridSize = 15;
    const theme = useTheme();

    const rootStore = useContext(RootStoreContext);

    const { openDrawer } = rootStore.drawerStore;

    //console.log(JSON.stringify(values));

    let nodes: Node[] = [];
    let edges: Edge[] = [];

    values?.stream_adaptation_families?.forEach((streamAdaptationFamily: StreamAdaptationFamily, index: number) => {
        //get the details of the previous streamAdaptationFamily
        const previousStreamAdaptationFamily = nodes.filter((node: Node) => node.id === `streamAdaptationFamily.${index - 1}`)[0];
        const previousStreamAdaptationFamilyY = previousStreamAdaptationFamily?.position?.y || 0;
        const previousStreamAdaptationFamilyHeight = previousStreamAdaptationFamily?.height || 0;

        nodes.push(
            {
                id: `streamAdaptationFamily.${index}`,
                type: "customgroup",
                position: {
                    x: 45,
                    y:
                        previousStreamAdaptationFamilyY > 0
                            ? previousStreamAdaptationFamilyY + previousStreamAdaptationFamilyHeight + 15
                            : 45
                },
                width: gridSize * 66,
                height: 360,
                draggable: false,
                data: {
                    label: streamAdaptationFamily.name,
                    type: "streamAdaptationFamily",
                    width: gridSize * 66,
                    height: 360,
                    hideSource: true,
                    hideTarget: true,
                    isDroppable: () => (droppableType === "family" ? true : false),
                    onDragOver: (event: React.DragEvent<HTMLDivElement>, element: any) => {
                        setDroppableElementType("family");
                        setActiveFamily(index);
                        setActiveFamilyId(streamAdaptationFamily.id);
                    },
                    onDragLeave: () => {
                        setDroppableElementType("");
                        setActiveFamily(-1);
                    },
                    onClick: () => {
                        setActiveFamily(index);
                    },
                    menuItems: [
                        {
                            label: "Delete",
                            onClick: () => {
                                console.log("delete");
                                agent.NeaLive.deleteStreamAdaptationFamily(streamAdaptationFamily.id)
                                    .then((res) => {
                                        console.log(res);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });

                                onDeleteFamily(index);
                            }
                        }
                    ]
                }
            },
            {
                id: `streamAdaptationFamily.${index}.sources`,
                type: "customgroup",
                sourcePosition: Position.Right,
                position: { x: 15, y: 45 },
                width: gridSize * 19,
                height: streamAdaptationFamily?.transcoders?.length * 110 + 45,
                draggable: false,
                data: {
                    label: "Transcoders",
                    type: "sources",
                    width: gridSize * 19,
                    height: streamAdaptationFamily?.transcoders?.length * 110 + 45,
                    hideTarget: true,
                    isDroppable: () => (droppableType === "sources" ? true : false),
                    onDragOver: (event: React.DragEvent<HTMLDivElement>, element: any) => {
                        setDroppableElementType("family");
                        setActiveFamily(index);
                        setActiveFamilyId(streamAdaptationFamily.id);
                    },
                    onClick: () => {
                        setActiveSidebarTab("3");
                        setCollapseSidebar(false);
                    }
                },
                parentNode: `streamAdaptationFamily.${index}`
            },
            // add stream adaptations here
            {
                id: `streamAdaptationFamily.${index}.streamAdaptations`,
                type: "customgroup",
                targetPosition: Position.Left,
                width: gridSize * 19,
                height: streamAdaptationFamily?.stream_adaptations?.length * 75 + 45,
                draggable: false,
                position: { x: gridSize * 25, y: 45 },
                data: {
                    label: "Stream Adaptations",
                    type: "streamAdaptations",
                    width: gridSize * 19,
                    height: streamAdaptationFamily?.stream_adaptations?.length * 75 + 45,
                    hideSource: true,
                    isDroppable: () => (droppableType === "family" ? true : false),
                    onDragOver: (event: React.DragEvent<HTMLDivElement>, element: any) => {
                        setDroppableElementType("family");
                        setActiveFamily(index);
                        setActiveFamilyId(streamAdaptationFamily.id);
                    },
                    onClick: () => {
                        setActiveSidebarTab("2");
                        setCollapseSidebar(false);
                    }
                },
                parentNode: `streamAdaptationFamily.${index}`
            }
        );

        //For each values?.stream_adaptation_families?.stream_adaptations, add a node to streamAdaptationFamily.${index}.streamAdaptations
        streamAdaptationFamily?.stream_adaptations?.forEach((streamAdaptation: StreamAdaptation, adaptation_index: number) => {
            //get the details of the previous streamAdaptation
            const previousStreamAdaptation = nodes.filter(
                (node: Node) => node.id === `streamAdaptationFamily.${index}.streamAdaptations.${adaptation_index - 1}`
            )[0];
            const previousStreamAdaptationY = previousStreamAdaptation?.position?.y || 0;
            const previousStreamAdaptationHeight = previousStreamAdaptation?.height || 0;

            nodes.push(
                {
                    id: `streamAdaptationFamily.${index}.streamAdaptations.${adaptation_index}`,
                    type: "details",
                    position: {
                        x: 15,
                        y: previousStreamAdaptationY > 0 ? previousStreamAdaptationY + previousStreamAdaptationHeight + 15 : 45
                    },
                    width: gridSize * 17,
                    draggable: false,
                    sourcePosition: Position.Right,
                    height: 60,
                    data: {
                        label: streamAdaptation.name,
                        type: "streamAdaptation",
                        width: gridSize * 17,
                        height: 60,
                        hideSource: false,
                        hideTarget: true,
                        items: [{ label: "Type", value: streamAdaptation.parameters.type }],
                        menuItems: [
                            {
                                label: "Remove",
                                onClick: () => {
                                    onRemoveStreamAdaptation(streamAdaptationFamily.id, streamAdaptation.id);
                                }
                            }
                        ]
                    },
                    parentNode: `streamAdaptationFamily.${index}.streamAdaptations`
                },
                //add the output node for each stream adaptation
                {
                    id: `streamAdaptationFamily.${index}.streamAdaptations.${adaptation_index}.outputs`,
                    type: "details",
                    targetPosition: Position.Left,
                    position: { x: gridSize * 20, y: 0 },
                    width: gridSize * 19,
                    height: 60,
                    draggable: false,
                    data: {
                        label: "Outputs",
                        type: "outputs",
                        width: gridSize * 19,
                        height: 60,
                        hideTarget: false,
                        hideSource: true,
                        items: [{ label: "Number of Outputs", value: streamAdaptationFamily?.transcoders?.length }],
                        isDroppable: () => (droppableType === "outputs" ? true : false),
                        onDragOver: (event: React.DragEvent<HTMLDivElement>, element: any) => {
                            setDroppableElementType("outputs");
                            setDroppableElementId(event.currentTarget.id);
                        },
                        onClick: () => {
                            setActiveTab("5");
                            setOutputs(streamAdaptationFamily?.stream_adaptations[adaptation_index]?.outputs);
                        }
                    },
                    parentNode: `streamAdaptationFamily.${index}.streamAdaptations.${adaptation_index}`
                }
            );

            //add the edge between the streamAdaptationFamily.streamAdaptations node and the streamAdaptationFamily.streamAdaptations.outputs node
            edges.push({
                id: `streamAdaptationFamily.${index}.streamAdaptations.${adaptation_index}.streamAdaptations.outputs`,
                source: `streamAdaptationFamily.${index}.streamAdaptations.${adaptation_index}`,
                target: `streamAdaptationFamily.${index}.streamAdaptations.${adaptation_index}.outputs`,
                animated: false,
                data: {
                    label: "streamAdaptationFamily.streamAdaptations.streamAdaptations.outputs"
                },
                style: { stroke: theme.palette.tertiary?.main, strokeWidth: 2, strokeDasharray: 3 }
            });
        });

        //set the height of the streamAdaptationFamily.${index} to be the height of whichever is bigger, the sources or the streamAdaptations
        //plus 45 for the header

        const streamAdaptationsHeight = streamAdaptationFamily?.stream_adaptations?.length * 75 + 45;
        const sourcesHeight = streamAdaptationFamily?.transcoders?.length * 110 + 45;
        const height = streamAdaptationsHeight > sourcesHeight ? streamAdaptationsHeight : sourcesHeight;

        nodes = nodes.map((node: Node) => {
            if (node.id === `streamAdaptationFamily.${index}`) {
                return {
                    ...node,
                    height: height + 60,
                    data: {
                        ...node.data,
                        height: height + 60
                    }
                };
            }
            return node;
        });

        //add the edge between the streamAdaptationFamily.sources node and the streamAdaptationFamily.streamAdaptations node
        edges.push({
            id: `streamAdaptationFamily.${index}.sources-streamAdaptationFamily.${index}.streamAdaptations`,
            source: `streamAdaptationFamily.${index}.sources`,
            target: `streamAdaptationFamily.${index}.streamAdaptations`,
            animated: false,
            data: {
                label: "streamAdaptationFamily.sources-streamAdaptationFamily.streamAdaptations"
            }
        });

        //For each values?.stream_adaptation_families?.sources, add a node to streamAdaptationFamily.${index}.sources
        streamAdaptationFamily?.transcoders?.forEach((source: StreamAdaptationFamilySource, source_index: number) => {
            //get the details of the previous source
            const previousSource = nodes.filter(
                (node: Node) => node.id === `streamAdaptationFamily.${index}.sources.${source_index - 1}`
            )[0];
            const previousSourceY = previousSource?.position?.y || 0;
            const previousSourceHeight = previousSource?.height || 0;

            nodes.push({
                id: `streamAdaptationFamily.${index}.sources.${source_index}`,
                type: "details",
                position: {
                    x: 15,
                    y: previousSourceY > 0 ? previousSourceY + previousSourceHeight + 15 : 45
                },
                width: gridSize * 17,
                height: 95,
                draggable: false,
                data: {
                    //limit the label to 20 characters
                    label: source.name,
                    type: "source",
                    width: gridSize * 17,
                    height: 95,
                    hideSource: true,
                    hideTarget: true,
                    color:
                        source.status === "running"
                            ? theme.palette.success.main
                            : source.status === "deleted"
                            ? theme.palette.error.main
                            : theme.palette.warning.main,
                    items: [
                        {
                            label: "Input Feed Source",
                            value: source.input_feed_source
                                ? source.input_feed_source.charAt(0).toUpperCase() + source.input_feed_source.slice(1)
                                : "Unknown"
                        },
                        {
                            label: "Status",
                            value: source.status ? source.status.charAt(0).toUpperCase() + source.status.slice(1) : "Unknown"
                        }
                    ],
                    menuItems: [
                        {
                            label: "Remove",
                            onClick: () => {
                                onRemoveTranscoder(streamAdaptationFamily.id, source.id);
                            }
                        }
                    ],
                    onClick: () => {
                        onTranscoderClick(source.id, source.rid);
                    }
                },

                parentNode: `streamAdaptationFamily.${index}.sources`
            });
        });
    });

    const getNodes = () => {
        return nodes;
    };

    const getEdges = () => {
        return edges;
    };

    return {
        getNodes,
        getEdges
    };
};
