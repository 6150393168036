import React, { useContext, useEffect, useState } from "react";

import Content from "components/Layout/Content";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { ArrowOutward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SourceCard from "features/sources/Components/SourceCard";
import SourcesMenu, { SourceMenuArea } from "features/sources/Components/SourcesMenu";

interface IMultiviewersHome {
    envProvider: string | null;
}

export const MultiviewersHome: React.FC<IMultiviewersHome> = ({ envProvider }) => {
    // Hooks
    const navigate = useNavigate();
    const theme = useTheme();

    // Global State
    const rootStore = useContext(RootStoreContext);
    const { currentEnvRunning } = rootStore.environmentStore;
    const { user, activeEnvironment, updateUserPreferences } = rootStore.userStore;
    const { client, clientConnected } = rootStore.resClientStore;
    const managing_org_id = rootStore.userStore.managingOrganisationId;
    const activeorganisationId = rootStore.userStore.activeorganisationId;
    const { token } = rootStore.commonStore;

    // Local State
    const [displayStatus, setDisplayStatus] = useState<string>("all");
    const [displayAlertStatus, setDisplayAlertStatus] = useState<string>("all");
    const [columns, setColumns] = useState<number>(
        user?.user_preferences?.sources_item_size ? user.user_preferences.sources_item_size - 2 : 8
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = React.useState<any>([]);
    const numberOfColumns = useMediaQuery(theme.breakpoints.down("sm")) ? 1 : columns;
    const [updateDate, setUpdateDate] = useState<Date>(new Date());

    useEffect(() => {
        setLoading(true);

        let resData: any = {};

        clientConnected &&
            client
                .get(`environments.mvs.${managing_org_id}.${activeEnvironment}.${activeorganisationId}`)
                .then((res) => {
                    resData = res;
                    const jsonObject = res.toJSON();
                    console.log("jsonObject", jsonObject);
                    setData(jsonObject);
                    res.on("change", () => {
                        setData(res.toJSON());
                        setLoading(false);
                        setUpdateDate(new Date());
                    });
                    setLoading(false);
                    setUpdateDate(new Date());
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });

        !clientConnected && rootStore.resClientStore.initialize(token!);

        return () => {
            setData([]);
        };
    }, [managing_org_id, activeorganisationId, clientConnected, currentEnvRunning]);

    React.useEffect(() => {}, [updateDate]);

    if (envProvider !== "aws") {
        return <></>;
    }

    return (
        <Content
            loading={loading}
            headerContent={
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", minHeight: "52px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <FormControl sx={{ width: 120, marginRight: 1 }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={displayStatus}
                                label="Status"
                                onChange={(e) => {
                                    setDisplayStatus(e.target.value as string);
                                }}
                                variant="outlined"
                                size="small"
                                placeholder="Please Select"
                                sx={{ width: 120 }}>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="connected">Connected</MenuItem>
                                <MenuItem value="connecting">Connecting</MenuItem>
                                <MenuItem value="stopped">Stopped</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: 120 }}>
                            <InputLabel id="alert-status-label">Alert Status</InputLabel>
                            <Select
                                labelId="alert-status-label"
                                id="demo-simple-select"
                                value={displayAlertStatus}
                                label="Alert Status"
                                onChange={(e) => {
                                    setDisplayAlertStatus(e.target.value as string);
                                }}
                                variant="outlined"
                                size="small"
                                placeholder="Please Select"
                                sx={{ width: 120 }}>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="alerting">Alerting</MenuItem>
                            </Select>
                        </FormControl>

                        <Box sx={{ width: "2px", backgroundColor: "rgba(160,160,160,0.6)", height: "20px", margin: 2 }}></Box>
                        <FormControl sx={{ width: 100 }}>
                            <InputLabel id="demo-simple-select-label">Item Size</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={columns}
                                label="Item Size"
                                onChange={(e) => {
                                    setColumns(Number(e.target.value));
                                    updateUserPreferences({ sources_item_size: Number(e.target.value) + 2 });
                                }}
                                variant="outlined"
                                size="small"
                                placeholder="Please Select"
                                sx={{ minWidth: 100 }}>
                                <MenuItem value={10}>Small</MenuItem>
                                <MenuItem value={8}>Medium</MenuItem>
                                <MenuItem value={6}>Large</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Stack spacing={1} flexDirection="row" alignItems="center">
                        <Button
                            onClick={() => {
                                navigate("/sources");
                            }}
                            startIcon={<ArrowOutward />}
                            variant="text"
                            color="primary">
                            View Sources
                        </Button>
                        <Box
                            sx={{
                                marginTop: "0 !important",
                                marginLeft: "0.5rem !important"
                            }}>
                            <SourcesMenu area={SourceMenuArea.MULTIVIEWERS} />
                        </Box>
                    </Stack>
                </Box>
            }>
            <Grid container spacing={3} columns={numberOfColumns}>
                {currentEnvRunning &&
                    data &&
                    Object.entries(data)
                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                        .map(([key, source]: [string, any]) => (
                            <SourceCard
                                key={key}
                                rid={source.rid}
                                client={client}
                                display_status={displayStatus}
                                display_type={"multiviewer"}
                                display_alert_status={displayAlertStatus}
                            />
                        ))}
            </Grid>
            {currentEnvRunning && data && Object.entries(data).length === 0 && (
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <Alert severity="info">
                        <AlertTitle>No Multiviewers Found</AlertTitle>
                        No multiviewers were found on this environment. Please click on the <strong>Create</strong> button to add a new
                        multiviewers.
                    </Alert>
                </Box>
            )}
            {!currentEnvRunning && (
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <Alert severity="info" sx={{ width: "37rem" }}>
                        <AlertTitle>Environment Not Running</AlertTitle>
                        The active environment is not running. Please start the environment to view sources.
                    </Alert>
                </Box>
            )}
        </Content>
    );
};

export default observer(MultiviewersHome);
