import React from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "api";

interface Props {
    roles?: string[];
    children: React.ReactNode;
    matchAll?: boolean;
}

/**
 * This component is used to check if the user has the required role to access a specific page.
 *
 * If the user has the required role, the children will be rendered. If the user does not have the required role,
 * nothing will be rendered. It can be used to compare any boolean value in the user object.
 *
 * Current values are:
 * - email_verified
 * - has_org_step_complete
 * - is_accounts_admin
 * - is_accounts_user
 * - is_allowed_to_see_settings
 * - is_dev
 * - is_getting_started_seen
 * - is_in_guest_org
 * - is_invitation_suspended
 * - is_managing_org_root
 * - is_member_of_active_org
 * - is_superuser
 * - is_tester
 * - needs_to_enter_2fa
 * - two_factor_auth_step_complete
 * - two_factor_enabled
 *
 * Example usage:
 *
 * ```jsx
 * <RoleProvider roles={["is_accounts_admin", "is_accounts_user"]} matchAll={true}>
 *   // your content here
 * </RoleProvider>
 * ```
 */

const RoleProvider: React.FC<Props> = observer(function RoleProvider({ roles, children, matchAll }) {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    if (!roles || !roles.length) {
        return <>{children}</>;
    }

    if (user) {
        if (matchAll) {
            if (roles.every((role) => user[role])) {
                return <>{children}</>;
            }
        } else if (roles.some((role) => user[role])) {
            return <>{children}</>;
        }
    }

    return null;
});

export default RoleProvider;
