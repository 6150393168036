import { ContentCopy } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { agent, RootStoreContext } from "api";
import ErrorMessages from "components/ErrorMessages";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

interface CreateCredentialsProps {
    deployment_id: string;
    oidc: string;
    oidc_url: string;
}

interface IAddToClipboard {
    text: string;
}

const AddToClipboard2: React.FC<IAddToClipboard> = observer(function AddToClipboard({ text }) {
    const [copied, setCopied] = useState(false);
    const contextRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    return (
        <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
            <div className="copy-to-clipboard-container" style={{ color: "#fff", fontSize: 13 }}>
                <span style={{ color: "#fff" }}>{text}</span>
                <ContentCopy color="primary" sx={{ fontSize: 12 }} />
                <span ref={contextRef}></span>
            </div>
        </CopyToClipboard>
    );
});

const CreateCredentialsForm: React.FC<CreateCredentialsProps> = observer(function CreateCredentialsForm(props) {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const [protocol, setProtocol] = useState("s3-role");

    const [errors, setErrors] = useState<any>();

    const handleProtocolChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setProtocol(event.target.value as string);
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            name: { value: string };
            region: { value: string };
            access_key: { value: string };
            secret_key: { value: string };
            role_arn: { value: string };
            session_token_timeout: { value: number };
        };

        const name = target.name.value.toLowerCase();

        let body;

        if (protocol === "s3") {
            const region = target.region.value;
            const access_key = target.access_key.value;
            const secret_key = target.secret_key.value;

            if (region) {
                body = {
                    name: name,
                    protocol: protocol,
                    access_key: access_key,
                    secret_key: secret_key,
                    buckets_creation_options: { region: region }
                };
            } else {
                body = { name: name, protocol: protocol, access_key: access_key, secret_key: secret_key };
            }
        } else {
            const role_arn = target.role_arn.value;
            const session_token_timeout = target.session_token_timeout.value;
            body = { name: name, protocol: protocol, role_arn: role_arn, session_token_timeout: session_token_timeout };
        }

        await agent.FileCluster.createCredentials(props.deployment_id, name, body)
            .then(() => {
                setErrors(null);
                closeDialog();
            })
            .catch((error) => {
                error && setErrors(error.data?.errors ? error.data.errors : error.data?.message ? error.data.message : "An error occurred");
            });
    };

    return (
        <>
            <DialogTitle>Create New Credentials</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        variant="standard"
                        id="new-credential-name"
                        name="name"
                        label="Name"
                        required
                        style={{ marginBottom: "10px", textTransform: "lowercase" }}
                        InputLabelProps={{ style: { textTransform: "capitalize" } }}
                        inputProps={{ style: { textTransform: "lowercase" } }}
                    />
                    <FormControl variant="standard" sx={{ marginBottom: "10px" }}>
                        <InputLabel shrink id="protocol-select-label">
                            Protocol
                        </InputLabel>

                        <Select
                            variant="standard"
                            labelId="protocol-select-label"
                            id="protocol-select"
                            fullWidth
                            name="protocol"
                            value={protocol}
                            onChange={(event: any) => handleProtocolChange(event)}
                            required
                            placeholder="Region">
                            <MenuItem key="s3" value="s3">
                                S3
                            </MenuItem>
                            <MenuItem key="s3-role" value="s3-role">
                                S3-Role
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {protocol === "s3" && (
                        <TextField
                            variant="standard"
                            id="new-credential-region"
                            name="region"
                            label="Region"
                            style={{ marginBottom: "10px" }}
                        />
                    )}
                    {protocol === "s3" && (
                        <TextField
                            variant="standard"
                            id="new-credential-access-key"
                            name="access_key"
                            label="Access Key"
                            required
                            style={{ marginBottom: "10px" }}
                        />
                    )}
                    {protocol === "s3" && (
                        <TextField
                            variant="standard"
                            id="new-credential-secret-key"
                            name="secret_key"
                            label="Secret Key"
                            required
                            style={{ marginBottom: "10px" }}
                        />
                    )}

                    {protocol === "s3-role" && (
                        <div
                            style={{
                                margin: "10px 0",
                                color: "#fff",
                                borderBottom: "1px solid rgba(255,255,255,0.3)",
                                paddingBottom: 10
                            }}>
                            <div style={{ color: "#6299c8", fontSize: 10, marginBottom: 10 }}>OIDC</div>
                            <div style={{ marginBottom: 5 }}>{props.oidc}</div>
                            <AddToClipboard2 text={props.oidc_url} />
                            <div style={{ color: "#fff", fontSize: 13, margin: "10px 0 0 0" }}>
                                Prior to setup the S3 Role, you will need to execute the following steps in your AWS account:
                                <ul>
                                    <li>Add the OIDC URL as Identity providers in IAM.</li>
                                    <li>Create an IAM role (web identity) based on the identity providers previously created.</li>
                                    <li>create the S3-role credentials with ARN of the IAM role.</li>
                                </ul>
                            </div>
                        </div>
                    )}

                    {protocol === "s3-role" && (
                        <TextField
                            variant="standard"
                            id="new-credential-role-arn"
                            name="role_arn"
                            label="Role ARN"
                            required
                            style={{ marginBottom: "10px" }}
                        />
                    )}

                    {protocol === "s3-role" && (
                        <TextField
                            variant="standard"
                            InputProps={{ inputProps: { min: 3600, max: 43200 } }}
                            id="new-credential-session-timeout"
                            type="number"
                            name="session_token_timeout"
                            label="Session Token Timeout (3600 - 43200)"
                            required
                            style={{ marginBottom: "10px" }}
                            defaultValue="3600"
                        />
                    )}

                    <ErrorMessages errors={errors} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">
                        Create
                    </Button>
                </DialogActions>
            </form>
        </>
    );
});

export default CreateCredentialsForm;