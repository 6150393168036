import { DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";

interface IProps {
    name: string;
    id: string;
}

export const ConfirmEnvironmentDelete: React.FC<IProps> = ({ name }) => {
    return (
        <>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Delete environment: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{name}</span>
                </Typography>
            </DialogContent>
        </>
    );
};
