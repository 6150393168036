import UserStore from "./userStore";
import SchedulerStore from "./schedulerStore";
import { createContext } from "react";
import { configure } from "mobx";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import DialogStore from "./dialogStore";
import DrawerStore from "./drawerStore";
import OrganisationStore from "./organisationStore";
import AdminStore from "./adminStore";
import EnvironmentStore from "./environmentStore";
import SourcesStore from "./sourcesStore";
import DestinationStore from "./destinationStore";
import NotificationStore from "./notificationStore";
import SourcePermissionsStore from "./sourcePermissionsStore";
import BillingStore from "./billingStore";
import PermanentScheduleStore from "./permanentScheduleStore";
import AlertStore from "./alertStore";
import ErrorStore from "./errorStore";
import FileClusterStore from "./fileClusterStore";
import SidebarStore from "./sidebarStore";
import OverviewStore from "./overviewStore";
import KnowledgeHubStore from "./knowledgeHubStore";
import ResClientStore from "./resClientStore";

configure({ enforceActions: "always" });

export class RootStore {
    userStore: UserStore;
    billingStore: BillingStore;
    commonStore: CommonStore;
    modalStore: ModalStore;
    dialogStore: DialogStore;
    drawerStore: DrawerStore;
    organisationStore: OrganisationStore;
    adminStore: AdminStore;
    schedulerStore: SchedulerStore;
    environmentStore: EnvironmentStore;
    sourcesStore: SourcesStore;
    destinationStore: DestinationStore;
    notificationStore: NotificationStore;
    sourcePermissionsStore: SourcePermissionsStore;
    permanentScheduleStore: PermanentScheduleStore;
    alertStore: AlertStore;
    errorStore: ErrorStore;
    fileClusterStore: FileClusterStore;
    sidebarStore: SidebarStore;
    overviewStore: OverviewStore;
    knowledgeHubStore: KnowledgeHubStore;
    resClientStore: ResClientStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.commonStore = new CommonStore(this);
        this.modalStore = new ModalStore(this);
        this.dialogStore = new DialogStore(this);
        this.drawerStore = new DrawerStore(this);
        this.organisationStore = new OrganisationStore(this);
        this.adminStore = new AdminStore(this);
        this.schedulerStore = new SchedulerStore(this);
        this.environmentStore = new EnvironmentStore(this);
        this.sourcesStore = new SourcesStore(this);
        this.destinationStore = new DestinationStore(this);
        this.notificationStore = new NotificationStore(this);
        this.sourcePermissionsStore = new SourcePermissionsStore(this);
        this.billingStore = new BillingStore(this);
        this.permanentScheduleStore = new PermanentScheduleStore(this);
        this.alertStore = new AlertStore(this);
        this.errorStore = new ErrorStore(this);
        this.fileClusterStore = new FileClusterStore(this);
        this.sidebarStore = new SidebarStore(this);
        this.overviewStore = new OverviewStore(this);
        this.knowledgeHubStore = new KnowledgeHubStore(this);
        this.resClientStore = new ResClientStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());
