import { observable, action, makeObservable } from "mobx";
import { IFileClusterRegions, IFileClusterDeployments, IFileClusterJobs, IFileClusterJobsRequest } from "api";
import { RootStore } from "./rootStore";

export default class FileClusterStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable regions: IFileClusterRegions | null = null;
    @observable deployments: IFileClusterDeployments | null = null;
    @observable activeDeploymentId: string = "";
    @observable activeDeploymentName: string = "";
    @observable activeDeploymentStatus: string = "";
    @observable newJob: any;
    @observable transcodingJobsOffset: number = 0;
    @observable transcodingJobsSort: string = "";
    @observable transcodingJobsSearch: string = "";
    @observable transcodingJobsRequest: IFileClusterJobsRequest = {
        "Deployment-Id": this.activeDeploymentId,
        offset: 0,
        limit: 50,
        sort: "id:desc",
        category: "transcoding",
        source_filename: this.transcodingJobsSearch
    };
    @observable pendingJobs: IFileClusterJobs | null = null;
    @observable pendingJobsOffset: number = 0;
    @observable pendingJobsSort: string = "priority:asc";
    @observable pendingJobsSearch: string = "";
    @observable pendingJobsRequest: IFileClusterJobsRequest = {
        "Deployment-Id": this.activeDeploymentId,
        offset: 0,
        limit: 50,
        sort: "id:desc",
        category: "pending",
        source_filename: this.transcodingJobsSearch
    };
    @observable abortedJobs: IFileClusterJobs | null = null;
    @observable abortedJobsOffset: number = 0;
    @observable abortedJobsSort: string = "id:desc";
    @observable abortedJobsSearch: string = "";
    @observable abortedJobsRequest: IFileClusterJobsRequest = {
        "Deployment-Id": this.activeDeploymentId,
        offset: 0,
        limit: 50,
        sort: "id:desc",
        category: "aborted",
        source_filename: this.abortedJobsSearch
    };
    @observable stoppedJobs: IFileClusterJobs | null = null;
    @observable stoppedJobsOffset: number = 0;
    @observable stoppedJobsSort: string = "id:desc";
    @observable stoppedJobsSearch: string = "";
    @observable stoppedJobsRequest: IFileClusterJobsRequest = {
        "Deployment-Id": this.activeDeploymentId,
        offset: 0,
        limit: 50,
        sort: "id:desc",
        category: "stopped",
        source_filename: this.stoppedJobsSearch
    };
    @observable failedJobs: IFileClusterJobs | null = null;
    @observable failedJobsOffset: number = 0;
    @observable failedJobsSort: string = "id:desc";
    @observable failedJobsSearch: string = "";
    @observable failedJobsRequest: IFileClusterJobsRequest = {
        "Deployment-Id": this.activeDeploymentId,
        offset: 0,
        limit: 50,
        sort: "id:desc",
        category: "failed",
        source_filename: this.failedJobsSearch
    };
    @observable finishedJobsOffset: number = 0;
    @observable finishedJobsSort: string = "id:desc";
    @observable finishedJobsSearch: string = "";
    @observable finishedJobsRequest: IFileClusterJobsRequest = {
        "Deployment-Id": this.activeDeploymentId,
        offset: 0,
        limit: 50,
        sort: "id:desc",
        category: "finished",
        source_filename: this.finishedJobsSearch
    };
    @observable searchTerm: string = "";

    @action setActiveDeploymentName = (name: string) => {
        this.activeDeploymentName = name;
    };

    @action setActiveDeploymentStatus = (status: string) => {
        this.activeDeploymentStatus = status;
    };

    @action setTranscodingJobsOffset = (offset: number) => {
        this.transcodingJobsOffset = offset;
    };

    @action setTranscodingJobsSort = (sort: string) => {
        this.transcodingJobsSort = sort;
    };

    @action setTranscodingJobsSearch = (searchTerm: string) => {
        this.transcodingJobsSearch = searchTerm;
        this.transcodingJobsRequest.source_filename = searchTerm;
    };

    @action setFailedJobsOffset = (offset: number) => {
        this.failedJobsOffset = offset;
        this.failedJobsRequest.offset = offset;
    };

    @action setFailedJobsSort = (sort: string) => {
        this.failedJobsSort = sort;
        this.failedJobsRequest.sort = sort;
    };

    @action setFailedJobsSearch = (searchTerm: string) => {
        this.failedJobsSearch = searchTerm;
        this.failedJobsRequest.source_filename = searchTerm;
    };

    @action setAbortedJobsOffset = (offset: number) => {
        this.abortedJobsOffset = offset;
        this.abortedJobsRequest.offset = offset;
    };

    @action setAbortedJobsSort = (sort: string) => {
        this.abortedJobsSort = sort;
        this.abortedJobsRequest.sort = sort;
    };

    @action setAbortedJobsSearch = (searchTerm: string) => {
        this.abortedJobsSearch = searchTerm;
        this.abortedJobsRequest.source_filename = searchTerm;
    };

    @action setStoppedJobsOffset = (offset: number) => {
        this.stoppedJobsOffset = offset;
        this.stoppedJobsRequest.offset = offset;
    };

    @action setStoppedJobsSort = (sort: string) => {
        this.stoppedJobsSort = sort;
        this.stoppedJobsRequest.sort = sort;
    };

    @action setStoppedJobsSearch = (searchTerm: string) => {
        this.stoppedJobsSearch = searchTerm;
        this.stoppedJobsRequest.source_filename = searchTerm;
    };

    @action setPendingJobsOffset = (offset: number) => {
        this.pendingJobsOffset = offset;
        this.pendingJobsRequest.offset = offset;
    };

    @action setPendingJobsSort = (sort: string) => {
        this.pendingJobsSort = sort;
        this.pendingJobsRequest.sort = sort;
    };

    @action setPendingJobsSearch = (searchTerm: string) => {
        this.pendingJobsSearch = searchTerm;
        this.pendingJobsRequest.source_filename = searchTerm;
    };

    @action setFinishedJobsOffset = (offset: number) => {
        this.finishedJobsOffset = offset;
        this.finishedJobsRequest.offset = offset;
    };

    @action setFinishedJobsSort = (sort: string) => {
        console.log("setFinishedJobsSort", sort);
        this.finishedJobsSort = sort;
        this.finishedJobsRequest.sort = sort;
    };

    @action setFinishedJobsSearch = (searchTerm: string) => {
        this.finishedJobsSearch = searchTerm;
        this.finishedJobsRequest.source_filename = searchTerm;
    };

    @action setRegions = (regions: IFileClusterRegions) => {
        this.regions = regions;
    };

    @action setFailedJobs = (failedJobs: IFileClusterJobs) => {
        this.failedJobs = failedJobs;
    };

    @action setAbortedJobs = (abortedJobs: IFileClusterJobs) => {
        this.abortedJobs = abortedJobs;
    };

    @action setStoppedJobs = (stoppedJobs: IFileClusterJobs) => {
        this.stoppedJobs = stoppedJobs;
    };

    @action setPendingJobs = (pendingJobs: IFileClusterJobs) => {
        this.pendingJobs = pendingJobs;
    };

    @action setDeployments = (deployments: IFileClusterDeployments) => {
        this.deployments = deployments;
    };

    @action setActiveDeploymentId = (deployment_id: string) => {
        this.activeDeploymentId = deployment_id;
    };

    @action setNewJob = (job: any) => {
        this.newJob = job;
    };

    @action setSearchTerm = (searchTerm: string) => {
        this.searchTerm = searchTerm;
    };
}
