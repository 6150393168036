import React from "react";
import { Field } from "react-final-form";
import {
    Box,
    MenuItem,
    Autocomplete as MuiAutocomplete,
    TextField as MuiTextField,
    Typography,
    createFilterOptions,
} from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { IAutocompleteOption } from "api";
import AutocompleteOption from "../AutocompleteOption";

interface IMultiAutocompleteField {
    name: string;
    label: string;
    options: IAutocompleteOption[];
    initialValue?: any;
    required?: boolean;
    disabled?: boolean;
    helperText?: (meta: any) => string | undefined;
    error?: (meta: any) => boolean;
    validate?: (value: any) => string | undefined;
    onChange?: (name: string, value?: any, previous?: any) => void;
    creatable?: boolean;
    limitTags?: number;
}

const filter = createFilterOptions<any>();
  
export const MultiAutocompleteField: React.FC<IMultiAutocompleteField> = ({
    name,
    label,
    options,
    initialValue,
    required,
    disabled = false,
    helperText,
    error,
    validate,
    onChange,
    creatable = false,
    limitTags,
}) => {
    return (
        <>
            <Field
                name={name}
                initialValue={initialValue}
                validate={(value: any) => {
                    if (validate) {
                        return validate(value);
                    }
                }}
            >
                {({ input, meta }) => (
                    <MuiAutocomplete
                        multiple
                        options={options}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            
                            if (option.inputValue) {
                                return option.inputValue;
                            }

                            return option.title;
                        }}
                        value={input.value.map((val: any) =>
                            options.find((option) => option.value === val) || { title: val, value: val }
                        )}
                        disabled={disabled}
                        selectOnFocus={creatable}
                        clearOnBlur={creatable}
                        freeSolo={creatable}
                        ChipProps={{
                            size: 'small',
                        }}
                        renderInput={(params) => (
                            <MuiTextField
                                {...params}
                                label={label}
                                required={required}
                                error={Boolean(
                                    ((meta.dirty || meta.touched) && meta.error) || meta.submitError || (meta.touched && meta.invalid) || (error && error(meta))
                                )}
                                helperText={((meta.dirty || meta.touched) && meta.error) || meta.submitError || (helperText && helperText(meta))}
                                variant="standard"
                            />
                        )}
                        onChange={(_, newValue) => {
                            let newValues = newValue.map((value) => {
                                if (typeof value === 'string') {
                                    return value;
                                }

                                return value.value;
                            });

                            input.onChange(newValues ?? []);
                        }}
                        fullWidth
                        disableCloseOnSelect
                        renderOption={(props, option, { selected }) => (
                            <AutocompleteOption option={option} {...props} selected={selected} multiple />
                        )}
                        limitTags={limitTags}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option.title);
                            
                            if (inputValue !== '' && !isExisting && creatable) {
                                filtered.push({
                                    inputValue,
                                    title: `Add "${inputValue}"`,
                                    value: inputValue,
                                });
                            }
                    
                            return filtered;
                        }}
                    />
                )}
            </Field>
            <OnChange name={name}>
                {(value: any, previous: any) => {
                    onChange && onChange(name, value ? value : undefined, previous ? previous : undefined);
                }}
            </OnChange>
        </>
    );
};

export default MultiAutocompleteField;
