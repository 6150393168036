import React, { useContext, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import Button from "@mui/material/Button";
import { ConfirmEnvironmentDelete } from "./ConfirmEnvironmentDelete";
import { IEnvironment, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import ReserveIpContent from "./ReserveIpContent";
import { Alert, Box, DialogActions, DialogContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FORM_ERROR } from "final-form";
import { useEnvironment } from "hooks";

interface IProps {
    name: string;
    id: string;
}

const ConfirmDelete: React.FC<IProps> = ({ name, id }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { deleteEnvironment, getEnvironmentsDetails, loadEnvironments } = useEnvironment();
    const [page, setPage] = useState(0);
    const [showIp, setShowIP] = useState(false);

    const pages = [
        // eslint-disable-next-line react/jsx-key
        <ReserveIpContent name={name} id={id} setShowIP={setShowIP} showIp={showIp} />,
        // eslint-disable-next-line react/jsx-key
        <ConfirmEnvironmentDelete name={name} id={id} />
    ];

    return (
        <FinalForm
            onSubmit={(values: IEnvironment) =>
                deleteEnvironment(id, values)
                    .then(() => {
                        getEnvironmentsDetails();
                        loadEnvironments();
                        closeDialog();
                    })
                    .catch((error) => {
                        console.log(error);
                        return { [FORM_ERROR]: error };
                    })
            }
            render={({ handleSubmit, submitError, invalid, dirtySinceLastSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Box>{pages[page]}</Box>
                    <DialogContent>{submitError && <Alert severity="error">{submitError?.data?.message}</Alert>}</DialogContent>
                    <DialogActions>
                        {page === 0 ? (
                            <>
                                <Button
                                    onClick={() => {
                                        closeDialog();
                                    }}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setPage(1);
                                    }}>
                                    Next
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    onClick={() => {
                                        setPage(0);
                                    }}>
                                    Back
                                </Button>
                                <LoadingButton
                                    disabled={invalid && !dirtySinceLastSubmit}
                                    loading={submitting}
                                    variant="contained"
                                    color="error"
                                    type={"submit"}>
                                    Delete
                                </LoadingButton>
                            </>
                        )}
                    </DialogActions>
                </form>
            )}
        />
    );
};

export default observer(ConfirmDelete);
