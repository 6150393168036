import { ContentPaste, ContentPasteOff } from "@mui/icons-material";
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

interface ClipboardMenuProps {
    selectedDate: Date;
    copiedItem?: any;
    onPaste: () => void;
    onClear: () => void;
}

const ClipboardMenu: React.FC<ClipboardMenuProps> = ({ selectedDate, copiedItem, onPaste, onClear }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Tooltip title="Clipboard">
                <span>
                    <IconButton
                        sx={{
                            backgroundColor: open ? (theme) => theme.palette.action.selected : undefined
                        }}
                        disabled={!copiedItem}
                        onClick={handleClick}>
                        <ContentPaste />
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                id="clipboard-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                sx={{
                    "& .MuiList-root": {
                        padding: 0
                    }
                }}
                onClose={handleClose}>
                {copiedItem && (
                    <Box sx={{ p: 2 }}>
                        <Typography variant="caption">Source</Typography>
                        <Typography variant="body1">
                            {copiedItem?.source_name}
                        </Typography>
                        <Typography variant="caption">Destination</Typography>
                        <Typography variant="body1">
                            {copiedItem?.destination_name}
                        </Typography>
                        <Typography variant="caption">Start Time</Typography>
                        <Typography variant="body1">
                            {copiedItem?.start_time && format(new Date(copiedItem?.start_time), "HH:mm")}
                        </Typography>
                        <Typography variant="caption">End Time</Typography>
                        <Typography variant="body1">
                            {copiedItem?.end_time && format(new Date(copiedItem?.end_time), "HH:mm")}
                        </Typography>
                    </Box>
                )}
                <Divider />
                <MenuItem
                    onClick={() => {
                        onPaste();
                        handleClose();
                    }}>
                    <ListItemIcon>
                        <ContentPaste />
                    </ListItemIcon>
                    <ListItemText primary="Paste to selected date" />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onClear();
                        handleClose();
                    }}>
                    <ListItemIcon>
                        <ContentPasteOff />
                    </ListItemIcon>
                    <ListItemText primary="Clear Clipboard" />
                </MenuItem>
            </Menu>
        </>
    );
};

export default ClipboardMenu;
