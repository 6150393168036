import React from "react";

interface Props {
    env: "staging" | "production";
    children: React.ReactNode;
}
export const EnvironmentProvider: React.FC<Props> = ({ env, children }) => {
    if (env === "staging" && process.env.REACT_APP_API_URL === "https://uat-api-v2.livelink.video/api") {
        return <>{children}</>;
    }

    if (env === "production" && process.env.REACT_APP_API_URL !== "https://uat-api-v2.livelink.video/api") {
        return <>{children}</>;
    }

    return null;
};

export default EnvironmentProvider;
