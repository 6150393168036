import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import React from "react";
import { useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import OnChange from "components/FinalFormListener/OnChange";
import { SelectField, TextField } from "components/Forms";
import { FileServer } from "../FileServers";
import { agent } from "api";

interface Props {
    values: any;
    onFieldChange: (field: string, value: any) => void;
    outputGroupIndex: number;
    fileMode?: boolean;
}

const HlsOutputOptions: React.FC<Props> = ({ values, onFieldChange, outputGroupIndex, fileMode }) => {
    const [enablePlaybackURLs, setEnablePlaybackURLs] = React.useState<boolean>(false);
    const { submitErrors } = useFormState();
    const [fileServers, setFileServers] = React.useState<FileServer[]>([]);

    const fetchFileServers = async () => {
        await agent.Packager.listFileServers()
            .then((response) => {
                setFileServers(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    React.useEffect(() => {
        fileMode && fetchFileServers();
    }, [fileMode]);

    React.useEffect(() => {
        if (
            (submitErrors?.output_groups?.[outputGroupIndex] &&
                (submitErrors.output_groups[outputGroupIndex].playback_url_1 ||
                    submitErrors.output_groups[outputGroupIndex].playback_url_2)) ||
            values?.output_groups?.[outputGroupIndex].playback_url_1 ||
            values?.output_groups?.[outputGroupIndex].playback_url_2
        ) {
            setEnablePlaybackURLs(true);
        }
    }, [submitErrors, outputGroupIndex, values?.output_groups]);

    return (
        <Box>
            <FieldArray name={`output_groups[${outputGroupIndex}].hls_muxer_playlists`} defaultValue={[{}]}>
                {({ fields }) => (
                    <Box sx={{ mt: 2, mb: 2 }}>
                        {fields.map((name, idx) => (
                            <Box
                                key={idx}
                                sx={{
                                    padding: 2,
                                    backgroundColor: "rgba(160,160,160,0.05)",
                                    mb: 2
                                }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        mb: 2
                                    }}>
                                    <Typography variant="h6" gutterBottom>
                                        Playlist {idx + 1}
                                    </Typography>
                                    <IconButton onClick={() => fields.remove(idx)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>

                                {fileMode && (
                                    // -> "playlist_name": String. Mandatory for "hls-file"
                                    // -> "sub_directory_path": String. TODO - GET tooltip wording from Titan
                                    // -> "media_files_name": String. Mandatory for "hls-file" TODO - GET tooltip wording from Titan
                                    // -> "media_files_extension": String. TODO - GET tooltip wording from Titan

                                    <Box mb={3}>
                                        <Typography variant="h6" gutterBottom>
                                            File Settings
                                        </Typography>
                                        <TextField
                                            name={`${name}.playlist_name`}
                                            label="Playlist Name"
                                            required
                                            validate={(value) => {
                                                if (!value) {
                                                    return "Required";
                                                }
                                            }}
                                        />
                                        <TextField name={`${name}.sub_directory_path`} label="Sub Directory Path" />
                                        <TextField
                                            name={`${name}.media_files_name`}
                                            label="Media Files Name"
                                        />
                                        {/* <TextField
                                            name={`${name}.media_files_extension`}
                                            label="Media Files Extension"
                                        /> 
                                            Change this to a SelectField with options ".ts" and ".m4s"
                                        */}
                                        <SelectField
                                            name={`${name}.media_files_extension`}
                                            label="Media Files Extension"
                                            options={[
                                                { value: ".ts", text: ".ts" },
                                                { value: ".m4s", text: ".m4s" }
                                            ]}
                                        />
                                    </Box>
                                )}

                                {values?.individual_track_configuration?.video_tracks &&
                                    values.individual_track_configuration.video_tracks.length > 0 && (
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Video Track
                                            </Typography>

                                            <SelectField
                                                name={`${name}.video_track_index`}
                                                label={`Video Track Index ${idx + 1}`}
                                                required
                                                options={
                                                    values?.individual_track_configuration?.video_tracks
                                                        ? values?.individual_track_configuration?.video_tracks?.map((track, index) => ({
                                                              value: index,
                                                              text: `${index}. ${track.frame_size} / ${track.frame_rate} / ${track.bitrate}`
                                                          }))
                                                        : []
                                                }
                                                validate={(value) => {
                                                    if (value === undefined) {
                                                        return "Required";
                                                    }
                                                }}
                                            />
                                            <OnChange name={`${name}.video_track_index`}>
                                                {() => {
                                                    if (
                                                        submitErrors?.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[idx]
                                                            ?.video_track_index
                                                    ) {
                                                        onFieldChange(
                                                            `output_groups.${outputGroupIndex}.hls_muxer_playlists.${idx}.video_track_index`,
                                                            undefined
                                                        );
                                                    }
                                                }}
                                            </OnChange>
                                            {submitErrors?.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[idx]
                                                ?.video_track_index && (
                                                <Alert severity="error" sx={{ mb: 1 }}>
                                                    {
                                                        submitErrors?.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[idx]
                                                            ?.video_track_index
                                                    }
                                                </Alert>
                                            )}
                                        </>
                                    )}

                                {values?.individual_track_configuration?.audio_tracks &&
                                    values.individual_track_configuration.audio_tracks.length > 0 && (
                                        <>
                                            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                                Audio Tracks
                                            </Typography>
                                            <FieldArray name={`${name}.audio_track_indexes`} defaultValue={[{}]}>
                                                {({ fields }) => (
                                                    <Box>
                                                        {fields.map((name, at_idx) => (
                                                            <Box
                                                                key={at_idx}
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    mb: 2,
                                                                    backgroundColor: "rgba(160,160,160,0.05)",
                                                                    padding: 1
                                                                }}>
                                                                <Box
                                                                    sx={{
                                                                        width: "100%",
                                                                        mr: 2
                                                                    }}>
                                                                    <SelectField
                                                                        name={`${name}.track_index`}
                                                                        label={`Audio Track Index ${idx + 1}`}
                                                                        required
                                                                        validate={(value) => {
                                                                            if (value === undefined) {
                                                                                return "Required";
                                                                            }
                                                                        }}
                                                                        options={
                                                                            values?.individual_track_configuration?.audio_tracks
                                                                                ? values.individual_track_configuration.audio_tracks?.map(
                                                                                      (track, index) => ({
                                                                                          value: index,
                                                                                          text: `${index}. ${track.codec}`
                                                                                      })
                                                                                  )
                                                                                : []
                                                                        }
                                                                    />
                                                                    <OnChange name={`${name}.track_index`}>
                                                                        {() => {
                                                                            if (
                                                                                submitErrors?.output_groups?.[outputGroupIndex]
                                                                                    ?.hls_muxer_playlists?.[idx]?.audio_track_indexes?.[
                                                                                    at_idx
                                                                                ]?.track_index
                                                                            ) {
                                                                                onFieldChange(
                                                                                    `output_groups[${outputGroupIndex}].hls_muxer_playlists[${idx}].audio_track_indexes[${at_idx}].track_index`,
                                                                                    undefined
                                                                                );
                                                                            }
                                                                        }}
                                                                    </OnChange>
                                                                    {/* if the previuos field is valid, then show the next field */}

                                                                    {values.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[idx]
                                                                        .audio_track_indexes?.[at_idx]?.track_index !== undefined && (
                                                                        <>
                                                                            <SelectField
                                                                                name={`${name}.variant_index`}
                                                                                label={`Variant Index ${at_idx + 1}`}
                                                                                required
                                                                                validate={(value) => {
                                                                                    if (value === undefined) {
                                                                                        return "Required";
                                                                                    }
                                                                                }}
                                                                                options={
                                                                                    values?.output_groups[outputGroupIndex] !== undefined &&
                                                                                    values?.output_groups[outputGroupIndex]
                                                                                        ?.hls_muxer_playlists !== undefined &&
                                                                                    values?.individual_track_configuration?.audio_tracks !==
                                                                                        undefined &&
                                                                                    values?.individual_track_configuration?.audio_tracks?.[
                                                                                        values.output_groups?.[outputGroupIndex]
                                                                                            ?.hls_muxer_playlists?.[idx]
                                                                                            ?.audio_track_indexes?.[at_idx]?.track_index ??
                                                                                            -1
                                                                                    ] !== undefined &&
                                                                                    values?.individual_track_configuration?.audio_tracks?.[
                                                                                        values.output_groups?.[outputGroupIndex]
                                                                                            ?.hls_muxer_playlists?.[idx]
                                                                                            ?.audio_track_indexes?.[at_idx]?.track_index ??
                                                                                            -1
                                                                                    ].variants!.map((track, index) => ({
                                                                                        value: index,
                                                                                        text: `${index}. ${track.bitrate}`
                                                                                    }))
                                                                                }
                                                                            />

                                                                            <OnChange name={`${name}.variant_index`}>
                                                                                {() => {
                                                                                    if (
                                                                                        submitErrors?.output_groups?.[outputGroupIndex]
                                                                                            ?.hls_muxer_playlists?.[idx]
                                                                                            ?.audio_track_indexes?.[at_idx]?.variant_index
                                                                                    ) {
                                                                                        onFieldChange(
                                                                                            `output_groups.${outputGroupIndex}..hls_muxer_playlists.${idx}.audio_track_indexes.${at_idx}.variant_index`,
                                                                                            undefined
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            </OnChange>
                                                                        </>
                                                                    )}
                                                                </Box>
                                                                <IconButton onClick={() => fields.remove(at_idx)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        ))}
                                                        <Button onClick={() => fields.push({})} startIcon={<AddCircleIcon />}>
                                                            Add Audio Track Index
                                                        </Button>
                                                    </Box>
                                                )}
                                            </FieldArray>
                                            <OnChange name={`${name}.audio_track_indexes`}>
                                                {() => {
                                                    if (
                                                        submitErrors?.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[idx]
                                                            ?.audio_track_indexes
                                                    ) {
                                                        onFieldChange(
                                                            `output_groups.${outputGroupIndex}.hls_muxer_playlists.${idx}.audio_track_indexes`,
                                                            undefined
                                                        );
                                                    }
                                                }}
                                            </OnChange>
                                        </>
                                    )}

                                {values?.individual_track_configuration?.data_tracks &&
                                    values.individual_track_configuration.data_tracks.length > 0 && (
                                        <Box>
                                            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                                Data Tracks
                                            </Typography>
                                            <FieldArray name={`${name}.data_track_indexes`}>
                                                {({ fields }) => (
                                                    <>
                                                        {fields.map((name, idx) => (
                                                            <Box
                                                                key={idx}
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    mb: 2
                                                                }}>
                                                                <SelectField
                                                                    name={name}
                                                                    label={`Data Track Index ${idx + 1}`}
                                                                    options={
                                                                        values?.individual_track_configuration?.data_tracks
                                                                            ? values?.individual_track_configuration?.data_tracks?.map(
                                                                                  (track, index) => ({
                                                                                      value: index,
                                                                                      text: `${index}. ${track.data_type} `
                                                                                  })
                                                                              )
                                                                            : []
                                                                    }
                                                                    required
                                                                    validate={(value) => {
                                                                        if (value === undefined) {
                                                                            return "Required";
                                                                        }
                                                                    }}
                                                                />

                                                                <OnChange name={name}>
                                                                    {() => {
                                                                        if (
                                                                            submitErrors?.output_groups?.[outputGroupIndex]
                                                                                ?.hls_muxer_playlists?.[idx]?.data_track_indexes?.[idx]
                                                                        ) {
                                                                            onFieldChange(
                                                                                `output_groups[${outputGroupIndex}].hls_muxer_playlists[${idx}].data_track_indexes[${idx}]`,
                                                                                undefined
                                                                            );
                                                                        }
                                                                    }}
                                                                </OnChange>

                                                                <IconButton onClick={() => fields.remove(idx)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        ))}
                                                        {submitErrors?.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[idx]
                                                            ?.data_track_indexes && (
                                                            <Alert severity="error" sx={{ mb: 1 }}>
                                                                {
                                                                    submitErrors?.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[
                                                                        idx
                                                                    ]?.data_track_indexes
                                                                }
                                                            </Alert>
                                                        )}
                                                        <Button onClick={() => fields.push(undefined)} startIcon={<AddCircleIcon />}>
                                                            Add Data Track Index
                                                        </Button>
                                                    </>
                                                )}
                                            </FieldArray>
                                            <OnChange name={`${name}.data_track_indexes`}>
                                                {() => {
                                                    if (
                                                        submitErrors?.output_groups?.[outputGroupIndex]?.hls_muxer_playlists?.[idx]
                                                            ?.data_track_indexes
                                                    ) {
                                                        onFieldChange(
                                                            `output_groups.${outputGroupIndex}.hls_muxer_playlists.${idx}.data_track_indexes`,
                                                            undefined
                                                        );
                                                    }
                                                }}
                                            </OnChange>
                                        </Box>
                                    )}
                            </Box>
                        ))}
                        <Button onClick={() => fields.push({})} startIcon={<AddCircleIcon />}>
                            Add Playlist
                        </Button>
                    </Box>
                )}
            </FieldArray>

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Output URL
            </Typography>

            <TextField
                name={`output_groups[${outputGroupIndex}].output_url`}
                label="Output URL"
                required
                validate={(value) => {
                    if (!value) {
                        return "Required";
                    }

                    if (!fileMode && !value.startsWith("http") && !value.startsWith("https") && !value.startsWith("ftp")) {
                        return "Output URL must begin with http, https or ftp";
                    }

                    //"URL needs to end with .m3u8 or /"
                    if (!value.endsWith(".m3u8") && !value.endsWith("/")) {
                        return "Output URL must end with .m3u8 or /";
                    }
                }}
            />
            <OnChange name={`output_groups[${outputGroupIndex}].output_url`}>
                {() => {
                    if (submitErrors?.output_groups?.[outputGroupIndex]?.output_url) {
                        onFieldChange(`output_groups.${outputGroupIndex}.output_url`, undefined);
                    }
                }}
            </OnChange>

            {fileMode && (
                <Box mb={2} mt={2}>
                    <Typography variant="h6" gutterBottom>
                        File Settings
                    </Typography>
                    <SelectField
                        name={`output_groups[${outputGroupIndex}].file_server_id`}
                        label="File Server"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        options={fileServers.map((server) => ({
                            value: server.id,
                            text: server.name,
                            description: "https://s3." + server.region + ".amazonaws.com/" + server.bucket_name
                        }))}
                    />
                    <TextField
                        name={`output_groups[${outputGroupIndex}].relative_path`}
                        label="Relative Path"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    <SelectField
                        name={`output_groups[${outputGroupIndex}].control_by`}
                        label="Control By"
                        required
                        initialValue={
                            values?.output_groups?.[outputGroupIndex]?.control_by
                                ? values?.output_groups?.[outputGroupIndex]?.control_by
                                : "None"
                        }
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        options={[
                            { value: "None", text: "None" },
                            { value: "SCTE-35", text: "SCTE-35" }
                        ]}
                    />
                </Box>
            )}

            {values?.enable_backup && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                        Backup Output URL
                    </Typography>

                    <TextField
                        name={`output_groups[${outputGroupIndex}].backup_output_url`}
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }

                            if (!fileMode && !value.startsWith("http") && !value.startsWith("https") && !value.startsWith("ftp")) {
                                return "Backup URL must begin with http, https or ftp";
                            }

                            //"URL needs to end with .m3u8 or /"
                            if (!value.endsWith(".m3u8") && !value.endsWith("/")) {
                                return "Backup URL must end with .m3u8 or /";
                            }
                        }}
                        label="Backup Output URL"
                        required
                    />
                    <OnChange name={`output_groups[${outputGroupIndex}].backup_output_url`}>
                        {() => {
                            if (submitErrors?.output_groups?.[outputGroupIndex]?.backup_output_url) {
                                onFieldChange(`output_groups.${outputGroupIndex}.backup_output_url`, undefined);
                            }
                        }}
                    </OnChange>
                    {fileMode && (
                <Box mb={2} mt={2}>
                    <Typography variant="h6" gutterBottom>
                        Backup File Settings
                    </Typography>
                    <SelectField
                        name={`output_groups[${outputGroupIndex}].backup_file_server_id`}
                        label="Backup File Server"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        options={fileServers.map((server) => ({
                            value: server.id,
                            text: server.name,
                            description: "https://s3." + server.region + ".amazonaws.com/" + server.bucket_name
                        }))}
                    />
                    <TextField
                        name={`output_groups[${outputGroupIndex}].backup_relative_path`}
                        label="Backup Relative Path"
                        required
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    <SelectField
                        name={`output_groups[${outputGroupIndex}].backup_control_by`}
                        label="Backup Control By"
                        required
                        initialValue={
                            values?.output_groups?.[outputGroupIndex]?.backup_control_by
                                ? values?.output_groups?.[outputGroupIndex]?.backup_control_by
                                : "None"
                        }
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                        options={[
                            { value: "None", text: "None" },
                            { value: "SCTE-35", text: "SCTE-35" }
                        ]}
                    />
                </Box>
            )}
                </>
            )}

            <FormControlLabel
                control={
                    <Checkbox
                        checked={enablePlaybackURLs}
                        onChange={(event) => setEnablePlaybackURLs(event.target.checked)}
                        color="primary"
                    />
                }
                label="Enable Playback URLs"
                labelPlacement="end"
                sx={{ mt: 2 }}
            />
            {enablePlaybackURLs && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Main Playback URL
                    </Typography>

                    <TextField
                        name={`output_groups[${outputGroupIndex}].playback_url_1`}
                        validate={(value) => {
                            if (!value) {
                                return "Required";
                            }
                            //must start with https:// or http://
                            if (value && !value.startsWith("https://") && !value.startsWith("http://")) {
                                return "URL must start with https:// or http://";
                            }

                            if (value && !value.endsWith(".m3u8")) {
                                return "URL must end with '.m3u8'";
                            }
                        }}
                        label="Main Playback URL"
                    />

                    <OnChange name={`output_groups[${outputGroupIndex}].playback_url_1`}>
                        {() => {
                            if (submitErrors?.output_groups?.[outputGroupIndex]?.playback_url_1) {
                                onFieldChange(`output_groups.${outputGroupIndex}.playback_url_1`, undefined);
                            }
                        }}
                    </OnChange>

                    {values?.enable_backup && (
                        <>
                            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                                Backup Playback URL
                            </Typography>

                            <TextField
                                name={`output_groups[${outputGroupIndex}].playback_url_2`}
                                validate={(value) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                    //must start with https:// or http://
                                    if (value && !value.startsWith("https://") && !value.startsWith("http://")) {
                                        return "URL must start with https:// or http://";
                                    }

                                    if (value && !value.endsWith(".m3u8")) {
                                        return "URL must end with '.m3u8'";
                                    }
                                }}
                                label="Backup Playback URL"
                            />

                            <OnChange name={`output_groups[${outputGroupIndex}].playback_url_2`}>
                                {() => {
                                    if (submitErrors?.output_groups?.[outputGroupIndex]?.playback_url_2) {
                                        onFieldChange(`output_groups.${outputGroupIndex}.playback_url_2`, undefined);
                                    }
                                }}
                            </OnChange>
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default HlsOutputOptions;
