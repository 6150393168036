import {
    Box,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tab,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import { agent, RootStoreContext } from "api";
import React, { useContext, useEffect } from "react";
import { ResModel } from "resclient";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Delete, Edit, Input, Output, PlayArrow, RestartAlt, Stop, SwapHoriz } from "@mui/icons-material";
import { format } from "date-fns";
import TranscoderStatistics from "./TranscoderStatistics";
import TranscoderAnalysis from "./TranscoderAnalysis";
import TranscoderInfo from "./TranscoderInfo";
import { useNavigate } from "react-router-dom";

interface TranscoderDetailsProps {
    id: string;
    rid: string;
    pull_packager_uuid: string;
}

interface Alarm {
    description: string;
    end_time: Date;
    is_open: boolean;
    level: string;
    name: string;
    section: "output" | "input" | "transcoding";
    start_time: Date;
}

const TranscoderDetails: React.FC<TranscoderDetailsProps> = ({ id, rid, pull_packager_uuid }) => {
    const navigate = useNavigate();

    const rootStore = useContext(RootStoreContext);

    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { closeDrawer } = rootStore.drawerStore;

    const theme = useTheme();
    const [thumbnailData, setThumbnailData] = React.useState<any>(null);
    const [apiData, setApiData] = React.useState<any>(null);
    const [liveData, setLiveData] = React.useState<any>(null);
    const [wsData, setWsData] = React.useState<any>(null);
    const [updateDate, setUpdateDate] = React.useState<Date | null>(null);
    const [tabValue, setTabValue] = React.useState("1");
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const calculate16x9 = (width: number) => {
        return (width * 9) / 16;
    };

    const getThumbnail = async (id: string) => {
        await agent
            .getCleanInstance()
            .get(`source_transcoder/${id}/thumbnail`)
            .then((data: any) => {
                setLiveData(data.data);
                setThumbnailData(data.data.thumbnail);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getOpenAlarmsCount = () => {
        if (liveData && liveData.alarms) {
            return liveData.alarms.filter((alarm: any) => alarm.is_open).length;
        }
        return 0;
    };

    const getApiData = async (id: string) => {
        await agent.NeaLive.getTranscoder(id)
            .then((data) => {
                console.log("API Data", data.data);
                setApiData(data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        //let intervalId: NodeJS.Timeout;

        rootStore.resClientStore.clientConnected &&
            rootStore.resClientStore.client.get(rid).then((res: ResModel | any) => {
                res.on("change", () => {
                    setUpdateDate(new Date());
                    setWsData(res.toJSON());
                    getApiData(id);
                });
                console.log(res.toJSON());
                setWsData(res.toJSON());
            });

        getApiData(id);

        // intervalId = setInterval(() => {
        //     wsData && wsData.status === "running" && getThumbnail(id);
        // }, 2000);

        //return () => clearInterval(intervalId);
    }, [id, rid]);

    useEffect(() => {
        getThumbnail(id);
    }, [id]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        intervalId = setInterval(() => {
            wsData && wsData.status === "running" && getThumbnail(id);
        }, 2000);
        return () => clearInterval(intervalId);
    }, [id, wsData]);

    useEffect(() => {}, [updateDate, apiData]);

    //useEffect to get api data every 10 seconds
    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        intervalId = setInterval(() => {
            getApiData(id);
        }, 10000);
        return () => clearInterval(intervalId);
    }, [id]);

    return (
        <Box sx={{ width: 660, height: "100vh", display: "flex", flexDirection: "column" }}>
            {wsData && liveData && (
                <>
                    <Box sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                        {wsData.status === "running" ? (
                            <Box
                                component={"img"}
                                src={thumbnailData}
                                alt="Thumbnail"
                                sx={{ flexShrink: 0, width: 260, height: calculate16x9(260), borderRadius: 2, mr: 3 }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    flexShrink: 0,
                                    width: 260,
                                    height: calculate16x9(260),
                                    borderRadius: 2,
                                    mr: 3,
                                    backgroundColor: theme.palette.action.hover
                                }}></Box>
                        )}
                        <Box
                            sx={{
                                flexGrow: 1,
                                flexShrink: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {wsData.name}
                                    </Typography>
                                    <Chip
                                        sx={{ ml: 1, mb: 0.8, textTransform: "capitalize" }}
                                        label={wsData.status}
                                        size="small"
                                        color={wsData.status === "running" ? "success" : undefined}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        mt: 1
                                    }}>
                                    <Box
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            mr: 1,
                                            borderRadius: "50%",
                                            backgroundColor: liveData?.input?.status_ok
                                                ? theme.palette.success.main
                                                : theme.palette.error.main
                                        }}></Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {liveData?.input?.status_ok ? "Input OK" : "Input Error"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        mt: 0.5
                                    }}>
                                    <Box
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            mr: 1,
                                            borderRadius: "50%",
                                            backgroundColor: liveData?.transcoding?.status_ok
                                                ? theme.palette.success.main
                                                : theme.palette.error.main
                                        }}></Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {liveData?.transcoding?.status_ok ? "Transcoding OK" : "Transcoding Error"}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        mt: 0.5
                                    }}>
                                    <Box
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            mr: 1,
                                            borderRadius: "50%",
                                            backgroundColor: liveData?.output?.status_ok
                                                ? theme.palette.success.main
                                                : theme.palette.error.main
                                        }}></Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {liveData?.output?.status_ok ? "Output OK" : "Output Error"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack direction="row">
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            navigate(`/pull-packager/${pull_packager_uuid}/edit-source/${id}`);
                                            closeDrawer();
                                        }}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Start">
                                    <span>
                                        <IconButton
                                            disabled={wsData.status !== "stopped"}
                                            onClick={() => {
                                                agent.NeaLive.startPullPackagerSource(id)
                                                    .then((res) => {
                                                        console.log(res);
                                                        openDialog(
                                                            <>
                                                                <DialogContent>
                                                                    Transcoder is now starting. This may take several minutes.
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={closeDialog} color="primary" variant="contained">
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </>,
                                                            "Transcoder Starting"
                                                        );
                                                    })
                                                    .catch((err) => {
                                                        console.log(err);
                                                        openDialog(
                                                            <>
                                                                <DialogContent>
                                                                    {err.data?.message || err.message || "Unknown Error"}
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={closeDialog} color="primary" variant="contained">
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </>,
                                                            "Error Starting Transcoder"
                                                        );
                                                    });
                                            }}>
                                            <PlayArrow />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Stop">
                                    <span>
                                        <IconButton
                                            disabled={wsData.status !== "running"}
                                            onClick={() => {
                                                agent.NeaLive.stopPullPackagerSource(id)
                                                    .then((res) => {
                                                        console.log(res);
                                                        openDialog(
                                                            <>
                                                                <DialogContent>
                                                                    Transcoder is now stopping. This may take several minutes.
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={closeDialog} color="primary" variant="contained">
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </>,
                                                            "Transcoder Stopping"
                                                        );
                                                    })
                                                    .catch((err) => {
                                                        console.log(err);
                                                        openDialog(
                                                            <>
                                                                <DialogContent>
                                                                    {err.data?.message || err.message || "Unknown Error"}
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={closeDialog} color="primary" variant="contained">
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </>,
                                                            "Error Stopping Transcoder"
                                                        );
                                                    });
                                            }}>
                                            <Stop />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Restart">
                                    <span>
                                        <IconButton
                                            disabled={wsData.status !== "running"}
                                            onClick={() => {
                                                agent.NeaLive.restartTranscoder(id)
                                                    .then((res) => {
                                                        console.log(res);
                                                        openDialog(
                                                            <>
                                                                <DialogContent>
                                                                    Transcoder is now restarting. This may take several minutes.
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={closeDialog} color="primary" variant="contained">
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </>,
                                                            "Transcoder Restarting"
                                                        );
                                                    })
                                                    .catch((err) => {
                                                        console.log(err);
                                                        openDialog(
                                                            <>
                                                                <DialogContent>
                                                                    {err.data?.message || err.message || "Unknown Error"}
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={closeDialog} color="primary" variant="contained">
                                                                        OK
                                                                    </Button>
                                                                </DialogActions>
                                                            </>,
                                                            "Error Restarting Transcoder"
                                                        );
                                                    });
                                            }}>
                                            <RestartAlt />
                                        </IconButton>
                                    </span>
                                </Tooltip>

                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => {
                                            openDialog(
                                                <>
                                                    <DialogContent>Are you sure you want to delete this transcoder?</DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={closeDialog} color="primary">
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => {
                                                                agent.NeaLive.deletePullPackagerSource(id)
                                                                    .then(() => {
                                                                        closeDialog();
                                                                        closeDrawer();
                                                                    })
                                                                    .catch((err) => {
                                                                        console.log(err);
                                                                        openDialog(
                                                                            <>
                                                                                <DialogContent>
                                                                                    {err.data?.message || err.message || "Unknown Error"}
                                                                                </DialogContent>
                                                                                <DialogActions>
                                                                                    <Button
                                                                                        onClick={closeDialog}
                                                                                        color="primary"
                                                                                        variant="contained">
                                                                                        OK
                                                                                    </Button>
                                                                                </DialogActions>
                                                                            </>,
                                                                            "Error Deleting Transcoder"
                                                                        );
                                                                    });
                                                            }}>
                                                            Delete
                                                        </Button>
                                                    </DialogActions>
                                                </>,
                                                "Delete Transcoder"
                                            );
                                        }}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            flexShrink: 1,
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            maxHeight: "calc(100vh - 179px)"
                        }}>
                        {/* <pre>{JSON.stringify(liveData, null, 2)}</pre> */}
                        <TabContext value={tabValue}>
                            <Box>
                                <TabList onChange={handleTabChange} aria-label="source details tabs">
                                    <Tab label="Details" value="1" />
                                    <Tab label="Analysis" value="2" />
                                    <Tab label="Statistics" value="3" />
                                    <Tab label={"Alarms (" + getOpenAlarmsCount() + ")"} value="4" />
                                    <Tab label="Alarms History" value="5" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                {apiData && liveData && wsData && (
                                    <TranscoderInfo api_data={apiData} live_data={liveData} ws_data={wsData} />
                                )}
                            </TabPanel>
                            <TabPanel value="2" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                {wsData.status === "running" && liveData.input?.etr_statistics && (
                                    <TranscoderAnalysis data={liveData.input.etr_statistics} />
                                )}
                            </TabPanel>
                            <TabPanel value="3" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                {wsData.status === "running" && liveData?.input?.statistics && (
                                    <TranscoderStatistics data={liveData?.input?.statistics} />
                                )}
                            </TabPanel>
                            <TabPanel value="4" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                {wsData.status === "running" && liveData.alarms && liveData.alarms.length > 0 && (
                                    <List>
                                        {liveData.alarms
                                            .filter((alarm: any) => alarm.is_open)
                                            .map((alarm: Alarm, i: number) => (
                                                <ListItem
                                                    key={i}
                                                    sx={{
                                                        borderLeftWidth: 4,
                                                        borderLeftStyle: "solid",
                                                        borderLeftColor:
                                                            alarm.level === "critical"
                                                                ? theme.palette.error.main
                                                                : alarm.level === "warning"
                                                                ? theme.palette.warning.main
                                                                : theme.palette.info.main
                                                    }}>
                                                    <ListItemIcon sx={{ mr: -2 }}>
                                                        {alarm.section === "input" ? (
                                                            <Input />
                                                        ) : alarm.section === "output" ? (
                                                            <Output />
                                                        ) : (
                                                            <SwapHoriz />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={format(new Date(alarm.start_time), "dd/MM/yy @ HH:mm") + ": " + alarm.name}
                                                        secondary={alarm.description}
                                                    />
                                                </ListItem>
                                            ))}
                                    </List>
                                )}
                            </TabPanel>
                            <TabPanel value="5" sx={{ flexGrow: 1, overflowY: "scroll" }}>
                                <List>
                                    {wsData.status === "running" &&
                                        liveData.alarms &&
                                        liveData.alarms.length > 0 &&
                                        liveData.alarms
                                            .filter((alarm: any) => !alarm.is_open)
                                            .map((alarm: Alarm, i: number) => (
                                                <ListItem
                                                    key={i}
                                                    sx={{
                                                        borderLeftWidth: 4,
                                                        borderLeftStyle: "solid",
                                                        borderLeftColor:
                                                            alarm.level === "critical"
                                                                ? theme.palette.error.main
                                                                : alarm.level === "warning"
                                                                ? theme.palette.warning.main
                                                                : theme.palette.info.main
                                                    }}>
                                                    <ListItemIcon sx={{ mr: -2 }}>
                                                        {alarm.section === "input" ? (
                                                            <Input />
                                                        ) : alarm.section === "output" ? (
                                                            <Output />
                                                        ) : (
                                                            <SwapHoriz />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={format(new Date(alarm.start_time), "dd/MM/yy @ HH:mm") + ": " + alarm.name}
                                                        secondary={
                                                            alarm.description +
                                                            ". Ended: " +
                                                            format(new Date(alarm.end_time), "dd/MM/yy @ HH:mm")
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                </List>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default TranscoderDetails;
