import React, { useContext, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import * as alertOptions from "app/common/options/alertsOptions";
import { IAlertMethod, ICreateAlertMethod, RootStoreContext } from "api";
import { Alert, Box, Button, Stack, Switch, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import { observer } from "mobx-react-lite";
import { SelectField, TextField } from "components/Forms";
import { useScheduler } from "hooks";

const emailsToList = (emails: string) => emails?.split(/[,;]/).map((item: string) => item.trim());

interface IAlertFormProperties {
    edit: boolean;
    alertValues?: IAlertMethod;
    handleAction: (body: ICreateAlertMethod, id: string) => void;
}

const AlertsMethodsForm: React.FC<IAlertFormProperties> = observer(function AlertsMethodsForm({ edit, alertValues }) {
    const rootStore = useContext(RootStoreContext);
    const [error, setError] = useState<any | null>(null);
    const { createOrganisationAlertMethod, updateOrganisationAlertMethod } = useScheduler();
    const [systemAlert, setSystemAlert] = useState(edit && alertValues ? alertValues.is_system_alert : false);
    const { getUser } = rootStore.userStore;

    const handleFinalFormSubmit = (values: { type: string; email: string; slack: string; name: string; is_system_alert: boolean }) => {
        const emails = emailsToList(values.email);
        const slacks = emailsToList(values.slack);
        const body = {
            alert_type: values.type,
            notification_parameters: values.email ? emails : slacks,
            name: values.name,
            is_system_alert: values.is_system_alert
        };

        //The getUser() is to refresh "missing_system_alerts" variable on user for the warning of no system alerts
        if (edit && alertValues) {
            updateOrganisationAlertMethod(body, alertValues.id.toString())
                .then(() => {
                    getUser();
                })
                .then(() => {})
                .catch((error: any) => setError(error));
        } else {
            createOrganisationAlertMethod(body)
                .then(() => {
                    getUser();
                })
                .then(() => {})
                .catch((error: any) => setError(error));
        }
    };

    const checkEmails = (value: string) => {
        if (!value) {
            //if value is empty return an error message
            return "Please enter at least one email address";
        }

        //split the entered value by comma, and after that trim the spaces from each email and then validate using regex
        const emails = value
            .toString()
            .split(",")
            .map((email) => email.trim())
            .filter(Boolean);

        const invalidEmail = emails.find((email) => !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email));

        if (invalidEmail) {
            return `Invalid email: ${invalidEmail}`;
        }
    };

    const validateUrls = (value: string) => {
        if (!value) {
            //if value is empty return an error message
            return "Please enter at least one URL";
        }

        //split the entered value by comma, and after that trim the spaces from each email and then validate using regex
        const emails = value
            .toString()
            .split(",")
            .map((email) => email.trim())
            .filter(Boolean);

        const invalidEmail = emails.find(
            (email) => !/^(http(s)??:\/\/)?[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+((\/[a-zA-Z0-9_-]*)+)?(\/)?(\?.*)?$/i.test(email)
        );

        if (invalidEmail) {
            return `Invalid URL: ${invalidEmail}`;
        }
    };

    let initialValues = {
        type: "",
        email: "",
        slack: "",
        name: "",
        is_system_alert: false
    };

    if (edit && alertValues) {
        initialValues = {
            type: alertValues.alert_type,
            email: alertValues.notification_parameters.email?.join(", "),
            slack: alertValues.notification_parameters.slack?.join(", "),
            name: alertValues.name,
            is_system_alert: alertValues.is_system_alert
        };
    }

    return (
        <FinalForm
            onSubmit={(values: { type: string; email: string; slack: string; name: string; is_system_alert: boolean }) =>
                handleFinalFormSubmit(values)
            }
            initialValues={initialValues}
            render={({ handleSubmit, submitError, invalid, pristine, dirtySinceLastSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                    <Box sx={{ p: 2, mb: 2 }}>
                        <Stack>
                            <Typography variant="h5" gutterBottom>
                                {edit ? "Update Alert Method" : "Create Alert Method"}
                            </Typography>
                            <TextField
                                label="Name"
                                name="name"
                                type="text"
                                required
                                validate={(value) => {
                                    if (!value) {
                                        return "Required";
                                    }
                                }}
                            />
                            {edit ? (
                                <TextField label="Alert Method" name="type" type="text" disabled />
                            ) : (
                                <SelectField
                                    label="Alert Method*"
                                    options={alertOptions.alertType}
                                    name="type"
                                    helperText={(meta) => meta.touched && meta.error}
                                    error={(meta) => meta.touched && meta.error}
                                />
                            )}
                            {values.type === "email" && (
                                <TextField
                                    label="Email Address (for multiple use a comma to separate)"
                                    name="email"
                                    type="text"
                                    required
                                    validate={(value) => {
                                        return checkEmails(value);
                                    }}
                                />
                            )}
                            {values.type === "slack" && (
                                <TextField
                                    label="Slack Notification Webhook URL (for multiple use a comma to separate)"
                                    name="slack"
                                    type="text"
                                    required
                                    validate={(value) => {
                                        return validateUrls(value);
                                    }}
                                />
                            )}

                            <Tooltip
                                style={{ fontSize: "1rem" }}
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                placement="left"
                                arrow
                                title="Enable for system level notifications, ie. environment or transcoder build status notifications, maximum bitrate exceed warnings etc.">
                                <Box>
                                    <label className="livelink-formlabel livelink-formlabelControl" style={{ margin: "20px 0 0 0" }}>
                                        System Notification Method
                                    </label>
                                    <Switch
                                        checked={systemAlert}
                                        color="primary"
                                        name="is_system_alert_switcher"
                                        onChange={() => {
                                            const newValue = !systemAlert;
                                            setSystemAlert(newValue);
                                            form.change("is_system_alert", newValue);
                                        }}
                                    />
                                    {/* Your example will work if you provide a non-rendering Field component for the field you want to modify using */}
                                    <Field name="is_system_alert">{() => null}</Field>
                                </Box>
                            </Tooltip>

                            {(submitError && !dirtySinceLastSubmit) || (error && <Alert severity="error">{error.data.message}</Alert>)}
                            <Box sx={{ mt: 1, ml: 2 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}>
                                    {edit ? "Update" : "Create"}
                                </Button>
                            </Box>
                            {values.type === "slack" && (
                                <Box
                                    sx={{
                                        my: 2
                                    }}>
                                    <Typography sx={{ padding: "15px 0 10px 0", fontWeight: "bold" }}>
                                        How to create a slack webhook:
                                    </Typography>

                                    <ol style={{ paddingLeft: 0 }}>
                                        <li>Create a new channel in slack if required.</li>
                                        <li>
                                            Go to{" "}
                                            <a href="https://api.slack.com/apps" target="_blank" rel="noopener noreferrer">
                                                api.slack.com/apps
                                            </a>{" "}
                                            and sign in.
                                        </li>
                                        <li>Select "Create an App".</li>
                                        <li>From the popup select "From scratch".</li>
                                        <li>Enter an App Name.</li>
                                        <li>Pick the workspace with the required channel.</li>
                                        <li>Navigate to Features {">"} "Incoming Webhooks" and turn them on.</li>
                                        <li>Scroll down and click "Add New Webhook To Workspace".</li>
                                        <li>Select the relevant channel and click "Allow".</li>
                                        <li>Copy the "Webhook URL" and paste it in "Slack Notification Webhook" field above.</li>
                                        <li>Click on "Features" {">"} "Interactivity & Shortcuts"</li>
                                        <li>Turn Interactivity on</li>
                                        <li>Change "Request URL" field to:</li>
                                        <li style={{ fontWeight: "bold" }}>{process.env.REACT_APP_API_URL}/webhooks/slack</li>
                                        <li>Click "Save Changes".</li>
                                    </ol>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                </form>
            )}
        />
    );
});

export default AlertsMethodsForm;
