import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";

const themeLight = createTheme({
    shape: {
        borderRadius: 4
    },
    typography: {
        fontFamily: ["proxima-nova"].join(","),
        fontSize: 12.5,
        h1: {
            fontWeight: 600,
            fontSize: 48
        },
        h2: {
            fontWeight: 600,
            fontSize: 36
        },
        h3: {
            fontWeight: 600,
            fontSize: 28
        },
        h4: {
            fontWeight: 600,
            fontSize: 24
        },
        h5: {
            fontWeight: 600
        }
    },
    palette: {
        mode: "light",
        // sidebar: {
        //     main: "#173a59",
        //     light: "#28537b",
        //     contrastText: "#fff"
        // },
        sidebar: {
            //main: "#e3e3e3",
            main: "#fafafa",
            light: "#28537b",
            contrastText: "#000"
        },
        sidebarPopup: {
            main: "#EEEEEE",
            light: "#1E2832",
            contrastText: "#000"
        },
        contentToolbar: {
            //main: "#eceef0"
            main: "rgba(0, 0, 0, 0.07)"
        },
        contentHeader: {
            main: "#fafafa"
        },
        contentSidebar: {
            main: "#F4F4F4",
            light: "#00000005",
            contrastText: "#000"
        },
        scheduleRowStart: {
            main: "#fafafa",
            dark: "#eeeeee",
            light: "#13181d",
            contrastText: "#fff"
        },
        topbar: {
            main: "#f1f1f1",
            light: "#ffffff",
            contrastText: "#000"
        },
        body: {
            main: "#D8D8D8"
        },
        background: {
            default: "#fff",
            paper: "#f5f5f5"
        },
        text: {
            primary: "#000"
        },
        primary: {
            main: "#307abb"
        },
        secondary: {
            main: "#36b3a8",
        },
        tertiary: {
            main: "#9e2fb2"
        },
        quaternary: {
            main: "#c0326e"
        },
        quinary: {
            main: "#f9ac17"
        },
        senary: {
            main: "#b3462f"
        },
        septenary: {
            main: "#623be4"
        },
        success: {
            main: "#2eb242"
        },
        error: {
            main: "#ed143d",
            contrastText: "#fff"
        },
        headerBox: {
            main: "#a0a0a033"
        }
    },
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    backgroundColor: "#e4e4e4",
                    borderRadius: 4
                },
                content: {
                    fontWeight: 600,
                    fontSize: 15
                }
            }
        },
        MuiAccordion: {
            defaultProps: {
                elevation: 0
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f1f1f1 !important",
                    color: "#000 !important",
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    "& .MuiBadge-badge": {
                        paddingTop: 1,
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    lineHeight: 1
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: 600,
                        fontSize: 14
                    }
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard"
            },
            styleOverrides: {
                root: {
                    marginBottom: "10px",
                    "& .MuiInput-underline:before": {
                        // style for the underline before it's active (e.g., on hover)
                        borderBottom: "1px solid #00000088"
                    },
                    "& .MuiInput-underline:after": {
                        // style for the underline when it's active (e.g., on focus)
                        borderBottom: "2px solid #00000088"
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        // style for the underline when hovered
                        borderBottom: "2px solid #00000088"
                    },
                    "& .MuiInput-underline.Mui-focused:after": {
                        // style for the underline when it's in focus
                        borderBottom: "2px solid #307abb"
                    }
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard"
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: 14,
                    padding: "7px 20px"
                },
                startIcon: {
                    fontSize: 16,
                    opacity: 0.8,
                    svg: {
                        fontSize: "17px !important"
                    }
                },
                endIcon: {
                    fontSize: 16,
                    svg: {
                        fontSize: "17px !important"
                    }
                }
            },
            variants: [
                {
                    props: { variant: "toolbar" },
                    style: {
                        backgroundColor: "#5b5b5b",
                        color: "#fff"
                    }
                }
            ]
        },
        MuiButtonGroup: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                grouped: {
                    padding: "7px 20px"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: 20
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 13
                }
            },
            defaultProps: {
                arrow: true
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: "#fff",
                        color: "#000",
                        "&:hover": {
                            backgroundColor: "#fff",
                            color: "#000"
                        }
                    }
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    //backgroundColor: "#aeaeae",
                    textTransform: "uppercase",
                    fontWeight: 600,
                    letterSpacing: "1.28px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    "&.MuiTableCell-head": {
                        fontWeight: 600,
                        fontSize: 14
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#173b5b",
                    fontSize: 14
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: 15,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    minHeight: 48,
                    "&.Mui-selected": {
                        color: "#000",
                        backgroundColor: "#f5f5f5",
                        borderLeft: "1px solid #00000033",
                        borderRight: "1px solid #00000033",
                        "&:hover": {
                            backgroundColor: "#f5f5f5",
                            color: "#000"
                        }
                    }
                }
            },
            defaultProps: {
                iconPosition: "start"
            }
        },
        MuiTabs: {
            defaultProps: {
                TabIndicatorProps: {
                    style: {
                        height: 4,
                        top: 0,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4
                    }
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    backgroundColor: "#f5f5f5",
                    borderRadius: 4,
                    border: "1px solid #00000033",
                }
            }
        }
    }
});

declare module "@mui/material/styles" {
    interface Palette {
        sidebar?: Palette["primary"];
        sidebarPopup?: Palette["primary"];
        contentSidebar?: Palette["primary"];
        scheduleRowStart?: Palette["primary"];
        topbar?: Palette["primary"];
        body?: Palette["primary"];
        headerBox?: Palette["primary"];
        tertiary?: Palette["primary"];
        quaternary?: Palette["primary"];
        quinary?: Palette["primary"];
        senary?: Palette["primary"];
        septenary?: Palette["primary"];
        contentToolbar?: Palette["primary"];
        contentHeader?: Palette["primary"];
    }

    interface PaletteOptions {
        sidebar?: PaletteOptions["primary"];
        sidebarPopup?: PaletteOptions["primary"];
        contentSidebar?: PaletteOptions["primary"];
        scheduleRowStart?: PaletteOptions["primary"];
        topbar?: PaletteOptions["primary"];
        body?: PaletteOptions["primary"];
        headerBox?: PaletteOptions["primary"];
        tertiary?: PaletteOptions["primary"];
        quaternary?: PaletteOptions["primary"];
        quinary?: PaletteOptions["primary"];
        senary?: PaletteOptions["primary"];
        septenary?: PaletteOptions["primary"];
        contentToolbar?: PaletteOptions["primary"];
        contentHeader?: PaletteOptions["primary"];
    }
}

export default themeLight;
