import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

type GroupMarkerProps = {
    id: string;
    lat: any;
    lng: any;
    hasError?: boolean;
    map: google.maps.Map;
    name: string;
    description: string;
    active?: boolean;
    activeItem?: any;
    onClick?: () => void;
    onItemClick?: (event: React.MouseEvent<HTMLElement>) => void;
    type: string;
    icon: IconName;
    items: any;
};

const GroupMarker: React.FC<GroupMarkerProps> = ({ lat, hasError, name, description, active, onClick, type, icon, items }) => {
    //const [pathState, setPathState] = React.useState<google.maps.Polyline>()

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat]);

    React.useEffect(() => {}, []);

    const activeColor = "#307abb";
    const GroupMarkerStyles: any = {
        position: "relative",
        // width: active ? 50 : 40,
        // height: active ? 50 : 40,
        marginTop: active ? -25 : -20,
        marginLeft: active ? -25 : -20,
        backgroundColor: active ? activeColor : "#333",
        color: "#fff",
        border: active ? activeColor : hasError ? "2px solid crimson" : "2px solid #33BD4E",
        borderRadius: active ? 25 : 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
        fontSize: 14,
        cursor: "default",
        transition: "all 0.3s ease",
        zIndex: active ? 100000 : "auto"
    };

    return (
        <>
            <div
                onClick={onClick}
                className={active ? "group-marker active" : hasError ? "group-marker has-error" : "group-marker"}
                style={GroupMarkerStyles}
                title={name}>
                <div
                    style={{
                        position: "absolute",
                        width: 16,
                        height: 16,
                        borderRadius: 8,
                        backgroundColor: hasError ? "crimson" : "#33BD4E",
                        color: "#fff",
                        fontSize: 9,
                        top: -6,
                        right: -6,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <FontAwesomeIcon icon={["fal", icon]} />
                </div>
                <div>
                    <FontAwesomeIcon icon={["fal", active ? "folder-open" : "folder"]} />
                </div>
                <div className="group-marker-popup">
                    <div style={{ paddingBottom: 10, marginBottom: 10, borderBottom: "1px solid rgba(160,160,160,0.2)" }}>
                        <div style={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}>{name}</div>
                        {description && <div style={{ fontSize: "10px", fontWeight: 600, opacity: 0.8 }}>{description}</div>}
                        <div style={{ fontSize: "10px", fontWeight: 600, opacity: 0.8, whiteSpace: "nowrap" }}>
                            {type === "source_group" ? "Source Group" : "Destination Group"}
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: "14px", fontWeight: 600 }}>Items</div>
                        <div style={{ maxHeight: 150, overflowX: "visible", overflowY: "scroll" }}>
                            <div style={{ marginRight: 10 }}>{items}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "none" }}>{items}</div>
        </>
    );
};

export default GroupMarker;
