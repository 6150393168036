import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "./app/layout/styles.scss";
import "./app/layout/mainAppStyles.scss";
import App from "./App";
import ErrorBoundary from "./app/common/errorBoundary/ErrorBoundary";
import { LicenseInfo } from "@mui/x-license-pro";
import { createRoot } from "react-dom/client";


LicenseInfo.setLicenseKey("c03be3a65cdc189bc8c90ee0bf92e5adTz04NTg3NCxFPTE3NDEzNDQ5MjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <ErrorBoundary>
        <Router>
            <App />
        </Router>
    </ErrorBoundary>
);
