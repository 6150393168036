import React, { useContext, useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { agent, IAlertsEntry, IEnvironmentAlertDetails, RootStoreContext } from "api";
import { Box, Card, Checkbox, Fade, IconButton, Stack, TableSortLabel, Tooltip, Typography } from "@mui/material";
import { useSortableData } from "features/common/SortableTable";
import { ConfirmDialog } from "features/common/ConfirmDialog";
import {
    ConfirmDeleteMultipleAlerts,
    ConfirmDeleteSingleAlert,
    ConfirmUnMuteMultipleAlerts,
    ConfirmUnMuteSingleAlert
} from "features/alertsManagement/Components/AlertConfirmationQuestions";
import AlertActionSelector from "features/alertsManagement/Components/AlertActionSelector";
import AlertsFormEdit from "features/alertsManagement/Components/AlertsFormEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useAlert } from "hooks";

interface IAlertTableEntry {
    alertId: string;
    alertName: string;
    conditionString: string;
    environmentHumanName: string;
    isChannelAlert: boolean;
    itemName: string; // channel or destination name
    alertObj: IAlertsEntry;
    unmuteUponRecovery: string;
}

const MutedAlerts: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { getAlerts } = useAlert();
    const { openDialog } = rootStore.dialogStore;
    const { openDrawer } = rootStore.drawerStore;
    const [alertsSelected, setAlertsSelected] = useState<string[]>([]);
    const [normalizedAlerts, setNormalizedAlerts] = useState<IAlertTableEntry[]>([]);

    // Sortable Table
    const { sortingConfig, changeSortConfig, sortList } = useSortableData();

    const loadAlerts = () => {
        getAlerts().then((response_data) => {
            const rows: IEnvironmentAlertDetails[] = response_data.alerts;
            let newArray: IAlertTableEntry[] = [];
            rows.filter((env) => env.environment_status === "running").forEach((env: IEnvironmentAlertDetails) => {
                // ChannelAlerts
                env.channel_alerts.forEach((channel) => {
                    channel.alerts
                        .filter((chanAlert) => chanAlert.status === "inactive")
                        .forEach((chanAlert) => {
                            newArray.push({
                                alertId: chanAlert.id,
                                alertName: chanAlert.alert_name,
                                conditionString: chanAlert.condition_string,
                                environmentHumanName: env.environment_human_name,
                                isChannelAlert: true,
                                itemName: channel.src_display_name,
                                alertObj: chanAlert,
                                unmuteUponRecovery: chanAlert.unmute_upon_recovery ? "yes" : "no"
                            });
                        });
                });

                // DestinationAlerts
                env.destination_alerts.forEach((destination) => {
                    destination.alerts
                        .filter((destAlert) => destAlert.status === "inactive")
                        .forEach((destAlert) => {
                            newArray.push({
                                alertId: destAlert.id,
                                alertName: destAlert.alert_name,
                                conditionString: destAlert.condition_string,
                                environmentHumanName: env.environment_human_name,
                                isChannelAlert: false,
                                itemName: destination.dst_display_name,
                                alertObj: destAlert,
                                unmuteUponRecovery: destAlert.unmute_upon_recovery ? "yes" : "no"
                            });
                        });
                });
            });

            // Sort the list after every load (if sort order defined)
            const sortedList = sortList(newArray, sortingConfig?.sortingColumn, sortingConfig?.sortingOrder);
            setNormalizedAlerts(sortedList);
        });
    };

    useEffect(() => {
        loadAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTickAlert = (alert_id: string) => {
        // shallow clone of the array. Super important. Pointing to the same variable does not create a new reference
        let newState = [...alertsSelected];
        const item_index = newState.indexOf(alert_id);
        if (item_index > -1) {
            newState.splice(item_index, 1);
        } else {
            newState.push(alert_id);
        }
        setAlertsSelected(newState);
    };

    const unMuteAlert = (alert_id: string, alert_name: string) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Un-Mute Alert"
                message={`Are you sure you want to un-mute the alert "${alert_name}"?`}
                onConfirm={() => {
                    return agent.getCleanInstance().put(`/environments/alerts/${alert_id}/state`, { action: "activate" });
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        );
    };

    const deleteSingleAlert = (alert_id: string, alert_name: string) => {
        openDialog(
            <ConfirmationDialog
                title="Confirm Delete Alert"
                message={`Are you sure you want to delete the alert "${alert_name}"?`}
                onConfirm={() => {
                    return agent.getCleanInstance().delete(`/environments/alerts/${alert_id}`);
                }}
                onSuccess={() => {
                    loadAlerts();
                }}
            />
        );
    };

    const getSelectedAlertNames = () => {
        let alert_names: string[] = [];
        normalizedAlerts.forEach((row) => {
            if (alertsSelected.indexOf(row.alertId) > -1) {
                alert_names.push(`"${row.alertName}"`);
            }
        });
        return alert_names;
    };

    const applyChangeToSelected = (action: string) => {
        if (action === "delete") {
            openDialog(
                <ConfirmationDialog
                    title="Confirm Delete Alerts"
                    message="Are you sure you want to delete the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent.getCleanInstance().delete("/environments/alerts/delete-multiple", {
                            data: { alert_ids: alertsSelected }
                        });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                        setAlertsSelected([]);
                    }}
                />
            );
        } else if (action === "un_mute") {
            openDialog(
                <ConfirmationDialog
                    title="Confirm Un-Mute Alerts"
                    message="Are you sure you want to un-mute the following alerts?"
                    additionalContent={<Typography>{getSelectedAlertNames().join(", ")}</Typography>}
                    onConfirm={async () => {
                        return agent.getCleanInstance().put("/environments/alerts/unmute-multiple", { alert_ids: alertsSelected });
                    }}
                    onSuccess={() => {
                        loadAlerts();
                    }}
                />
            );
        }
    };

    return (
        <>
            <Box sx={{ width: "100%", paddingTop: 1, justifyContent: "space-between", display: "flex" }}>
                <Box>
                    {alertsSelected.length > 0 && (
                        // Show action selector if something has been selected
                        <AlertActionSelector
                            onChangeAction={(action) => {
                                applyChangeToSelected(action);
                            }}
                            showMute={false}
                        />
                    )}
                </Box>
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{
                        timeout: 600
                    }}
                    placement="bottom"
                    title="Refresh Alerts"
                    sx={{ mr: 2 }}>
                    <Box>
                        <IconButton onClick={loadAlerts} aria-label="Refresh Alerts">
                            <RefreshIcon sx={{ fontSize: "2rem" }} />
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>
            <Card sx={{ p: 2, m: 2 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell
                                    width={"12%"}
                                    onClick={() => {
                                        changeSortConfig("alertName", setNormalizedAlerts, normalizedAlerts);
                                    }}
                                    style={{ cursor: "pointer" }}>
                                    <TableSortLabel
                                        active={sortingConfig?.sortingColumn === "alertName"}
                                        direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    width={"18%"}
                                    onClick={() => {
                                        changeSortConfig("conditionString", setNormalizedAlerts, normalizedAlerts);
                                    }}
                                    style={{ cursor: "pointer" }}>
                                    <TableSortLabel
                                        active={sortingConfig?.sortingColumn === "conditionString"}
                                        direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                        Alert Condition
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    width={"18%"}
                                    onClick={() => {
                                        changeSortConfig("environmentHumanName", setNormalizedAlerts, normalizedAlerts);
                                    }}
                                    style={{ cursor: "pointer" }}>
                                    <TableSortLabel
                                        active={sortingConfig?.sortingColumn === "environmentHumanName"}
                                        direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                        Environment Name
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell
                                    width={"18%"}
                                    onClick={() => {
                                        changeSortConfig("itemName", setNormalizedAlerts, normalizedAlerts);
                                    }}
                                    style={{ cursor: "pointer" }}>
                                    <TableSortLabel
                                        active={sortingConfig?.sortingColumn === "itemName"}
                                        direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                        Item name
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell
                                    width={"24%"}
                                    onClick={() => {
                                        changeSortConfig("unmuteUponRecovery", setNormalizedAlerts, normalizedAlerts);
                                    }}
                                    style={{ cursor: "pointer" }}>
                                    <TableSortLabel
                                        active={sortingConfig?.sortingColumn === "unmuteUponRecovery"}
                                        direction={sortingConfig?.sortingOrder === "asc" ? "asc" : "desc"}>
                                        Unmute On Recovery
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={"5%"} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {normalizedAlerts.map((row) => (
                                <TableRow key={row.alertId}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={alertsSelected.indexOf(row.alertId) > -1}
                                            onClick={() => {
                                                handleTickAlert(row.alertId);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{row.alertName}</TableCell>
                                    <TableCell>{row.conditionString}</TableCell>
                                    <TableCell>{row.environmentHumanName}</TableCell>
                                    <TableCell>
                                        <Typography>{row.itemName}</Typography>
                                        <Typography variant="caption">{row.isChannelAlert ? "Source" : "Destination"}</Typography>
                                    </TableCell>
                                    <TableCell>{row.unmuteUponRecovery}</TableCell>
                                    <TableCell>
                                        <Stack
                                            direction={"row"}
                                            sx={{
                                                justifyContent: "end",
                                                mr: 1
                                            }}>
                                            <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                    timeout: 600
                                                }}
                                                placement="bottom"
                                                arrow
                                                title="Edit"
                                                sx={{ mr: 1 }}>
                                                <Box>
                                                    <IconButton
                                                        onClick={() =>
                                                            openDialog(
                                                                <AlertsFormEdit
                                                                    source={row.alertObj}
                                                                    successCallback={() => {
                                                                        loadAlerts();
                                                                    }}
                                                                />,
                                                                "Edit Alert"
                                                            )
                                                        }
                                                        aria-label="Edit">
                                                        <CreateIcon />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                            <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                    timeout: 600
                                                }}
                                                placement="bottom"
                                                arrow
                                                sx={{ mr: 1 }}
                                                title={"Unmute"}>
                                                <Box>
                                                    <IconButton
                                                        onClick={() => {
                                                            unMuteAlert(row.alertId, row.alertName);
                                                        }}
                                                        aria-label={"Unmute"}>
                                                        {<VolumeUpIcon />}
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                            <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                    timeout: 600
                                                }}
                                                placement="bottom"
                                                arrow
                                                sx={{ mr: 1 }}
                                                title="Delete">
                                                <Box>
                                                    <IconButton
                                                        onClick={() => deleteSingleAlert(row.alertId, row.alertName)}
                                                        aria-label="Delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <div style={{ textAlign: "center", padding: "40px 0 30px 0", color: "gray" }}>
                <i className="exclamation triangle icon" style={{ fontSize: "14px", marginRight: "10px" }} />
                To set up new alerts please go to alerts on the source or destination information page.
            </div>
        </>
    );
};

export default MutedAlerts;
