import React from "react";


interface CardProps {
    id?: string;
    style?: any;
    children?: React.ReactNode;
}

const Card: React.FC<CardProps> = (props) => {

    return (
        <div style={Object.assign(props.style ? props.style : {}, { backgroundColor: '#161e25', borderRadius: '.28571429rem', padding: '1em', color: '#fff'  })}>
            {props.children}
        </div>
    )
}

export default Card;