import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { agent, IFileClusterJob, IFileClusterJobs, RootStoreContext } from "api";
import {
    Box,
    Checkbox,
    Chip,
    IconButton,
    Table,
    Tooltip,
    Pagination,
    TableHead,
    TableCell,
    TableSortLabel,
    TableBody,
    TableRow,
    TableFooter,
    Stack,
    TextField,
    MenuItem
} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchInput from "components/SearchInput";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useFileCluster } from "hooks";

type PendingJobsProps = {
    deployment_id: string;
    pageSize: number;
};

const PendingJobs: React.FC<PendingJobsProps> = observer(function PendingJobs(props) {
    const rootStore = React.useContext(RootStoreContext);
    const { pendingJobsRequest, setPendingJobsSort, setPendingJobsOffset, setPendingJobsSearch } = rootStore.fileClusterStore;
    const { deleteJob, stopJob, setJobPriority } = useFileCluster();

    const [jobs, setJobs] = React.useState<IFileClusterJobs>();
    const defaultList = [0];
    const [selectedJobs, setSelectedJobs] = useState(defaultList);
    const { openDialog } = rootStore.dialogStore;

    const handleSelectAllPendingJobs = (e: any, data: any) => {
        setSelectedJobs(defaultList);
        const currentItems = new Set(selectedJobs);
        if (data === true && jobs) {
            jobs.data.forEach((item) => {
                currentItems.add(item.id);
            });
            setSelectedJobs(Array.from(currentItems));
        } else {
            setSelectedJobs(defaultList);
        }
        getJobs();
    };

    const handleRowSelect = (e: any, data: any, job_id: any) => {
        const currentItems = new Set(selectedJobs);
        if (data === true) {
            currentItems.add(job_id);
        } else {
            currentItems.delete(job_id);
        }
        setSelectedJobs(Array.from(currentItems));
    };

    const getJobs = async () => {
        await agent.FileCluster.getJobsByDeployment(
            props.deployment_id,
            pendingJobsRequest.offset,
            props.pageSize,
            pendingJobsRequest.sort,
            pendingJobsRequest.category,
            pendingJobsRequest.source_filename
        ).then((response) => {
            setJobs(response);
        });
    };

    const handleSearch = (searchTerm: string) => {
        setPendingJobsSearch(searchTerm);
        getJobs();
    };

    const clearSearch = () => {
        setPendingJobsSearch("");
        getJobs();
    };

    React.useEffect(() => {}, [
        props.deployment_id,
        pendingJobsRequest,
        setPendingJobsSort,
        setPendingJobsOffset,
        jobs,
        setPendingJobsSearch
    ]);

    React.useEffect(() => {
        getJobs();

        const interval = setInterval(() => {
            getJobs();
        }, 3000);
        return () => {
            setPendingJobsOffset(0);
            setPendingJobsSort("id:desc");
            clearSearch();
            clearInterval(interval);
            setSelectedJobs(defaultList);
            setJobs(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Box className="tab-toolbar">
                <SearchInput
                    name="search"
                    label="Search Source Files..."
                    value={pendingJobsRequest.source_filename}
                    onChange={handleSearch}
                    onClear={clearSearch}
                />
                <Box className="tab-toolbar-actions">
                    <h4>Selected Jobs ({selectedJobs.length - 1}): </h4>
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="Pause Selected Jobs">
                            <span>
                                <IconButton
                                    aria-label="pause"
                                    onClick={() => {
                                        openDialog(
                                            <ConfirmationDialog
                                                title="Pause Selected Jobs"
                                                message={`Are you sure you want to pause the selected jobs?`}
                                                confirmButtonText="Pause Jobs"
                                                onConfirm={async () => {
                                                    selectedJobs.forEach((item) => {
                                                        if (item > 0) {
                                                            stopJob(props.deployment_id, item);
                                                        }
                                                    });
                                                    setSelectedJobs(defaultList);
                                                }}
                                                onSuccess={() => getJobs()}
                                            />
                                        );
                                    }}
                                    disabled={selectedJobs.length < 2 ? true : false}>
                                    <PauseIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Selected Jobs">
                            <span>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        openDialog(
                                            <ConfirmationDialog
                                                title="Delete Selected Jobs"
                                                message={`Are you sure you want to delete the selected jobs?`}
                                                confirmButtonText="Delete Jobs"
                                                confirmButtonColor="error"
                                                onConfirm={async () => {
                                                    selectedJobs.forEach((item) => {
                                                        if (item > 0) {
                                                            deleteJob(props.deployment_id, item);
                                                        }
                                                    });
                                                    setSelectedJobs(defaultList);
                                                }}
                                                onSuccess={() => getJobs()}
                                            />
                                        );
                                    }}
                                    disabled={selectedJobs.length < 2 ? true : false}>
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </Box>
            </Box>
            <Table>
                <TableHead>
                    <TableCell padding="checkbox">
                        <Checkbox
                            onChange={(e, data) => handleSelectAllPendingJobs(e, data)}
                            checked={jobs?.data && jobs.data.length > 0 && selectedJobs.length - 1 === jobs?.data.length}
                        />
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={pendingJobsRequest.sort === "name:asc" || pendingJobsRequest.sort === "name:desc"}
                            direction={pendingJobsRequest.sort === "name:asc" ? "asc" : "desc"}
                            onClick={() => {
                                pendingJobsRequest.sort === "name:asc" ? setPendingJobsSort("name:desc") : setPendingJobsSort("name:asc");
                                getJobs();
                            }}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={pendingJobsRequest.sort === "id:asc" || pendingJobsRequest.sort === "id:desc"}
                            direction={pendingJobsRequest.sort === "id:asc" ? "asc" : "desc"}
                            onClick={() => {
                                pendingJobsRequest.sort === "id:asc" ? setPendingJobsSort("id:desc") : setPendingJobsSort("id:asc");
                                getJobs();
                            }}>
                            ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Priority</TableCell>
                    <TableCell align="right"></TableCell>
                </TableHead>
                <TableBody>
                    {jobs?.data?.map((job: IFileClusterJob, i: number) => (
                        <TableRow key={i} selected={selectedJobs.includes(job.id)}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedJobs.includes(job.id)}
                                    //id={job.id}
                                    onChange={(e, data) => handleRowSelect(e, data, job.id)}
                                />
                            </TableCell>
                            <TableCell>
                                <div style={{ wordBreak: "break-all" }}>{job.name}</div>
                                <div
                                    style={{
                                        fontSize: "10px",
                                        opacity: "0.7"
                                    }}>
                                    Source: {job.source_filename}
                                </div>
                            </TableCell>
                            <TableCell>{job.id}</TableCell>
                            <TableCell>
                                <Chip
                                    label={job.status}
                                    size="small"
                                    style={{
                                        textTransform: "capitalize"
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="standard-basic"
                                    variant="outlined"
                                    size="small"
                                    value={job.priority}
                                    sx={{ width: "80px" }}
                                    select>
                                    {Array.from({ length: jobs.totals.pending }, (_, i) => i + 1).map((item, i) => (
                                        <MenuItem
                                            key={i}
                                            value={item}
                                            onClick={(e) => {
                                                setJobPriority(job.id, item);
                                            }}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TableCell>
                            <TableCell align="right">
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Stack direction="row" spacing={1}>
                                        <Tooltip placement="bottom" arrow title="Pause job">
                                            <Box>
                                                <IconButton
                                                    aria-label="pause"
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Pause Job"
                                                                message={`Are you sure you want to pause job '${job.name}'?`}
                                                                confirmButtonText="Pause Job"
                                                                onConfirm={() => stopJob(props.deployment_id, job.id)}
                                                                onSuccess={() => getJobs()}
                                                            />
                                                        );
                                                    }}>
                                                    <PauseIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>

                                        <Tooltip placement="bottom" arrow title="Delete job">
                                            <Box>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        openDialog(
                                                            <ConfirmationDialog
                                                                title="Delete Job"
                                                                message={`Are you sure you want to delete job '${job.name}'?`}
                                                                confirmButtonText="Delete Job"
                                                                confirmButtonColor="error"
                                                                onConfirm={() => deleteJob(props.deployment_id, job.id)}
                                                                onSuccess={() => getJobs()}
                                                            />
                                                        );
                                                    }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </Stack>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {jobs && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%"
                                    }}>
                                    <Pagination
                                        shape="rounded"
                                        count={Math.ceil(jobs.totals.pending / props.pageSize)}
                                        page={pendingJobsRequest.offset / props.pageSize + 1}
                                        onChange={(event: any, page: number) => {
                                            setPendingJobsOffset((page - 1) * props.pageSize);
                                            getJobs();
                                        }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </div>
    );
});

export default PendingJobs;
