import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ListItem, ListItemButton, Typography, useTheme } from "@mui/material";
import { ResCollection, ResModel } from "resclient";
import { RootStoreContext } from "api";
import { useEnvironment } from "hooks";

interface IProps {
    rid: string;
    onMouseOut?: () => void;
}

const EnvironmentsListItem: React.FC<IProps> = ({ rid, onMouseOut }) => {
    const theme = useTheme();
    const rootStore = React.useContext(RootStoreContext);
    const { changeActiveEnvironment } = useEnvironment();
    const { activeEnvironment } = rootStore.userStore;

    const { client, clientConnected } = rootStore.resClientStore;

    const [wsData, setWsData] = React.useState<ResModel | ResCollection | any>({});

    const [updateDate, setUpdateDate] = React.useState<Date>(new Date());

    React.useEffect(() => {
        clientConnected &&
            client
                .get(rid)
                .then((res) => {
                    setWsData(res);
                    res.on("change", () => {
                        setUpdateDate(new Date());
                        setWsData(res);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
    }, [client, clientConnected, rid]);

    React.useEffect(() => {}, [updateDate]);

    return (
        <ListItem disablePadding>
            <ListItemButton
                sx={{
                    backgroundColor: activeEnvironment === wsData.id ? theme.palette.primary.main : undefined,
                    color: activeEnvironment === wsData.id ? theme.palette.primary.contrastText : undefined
                }}
                disabled={wsData.status !== "running"}
                onClick={() => {
                    changeActiveEnvironment({ environment_id: wsData.id });
                    onMouseOut && onMouseOut();
                }}>
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                            sx={{
                                width: 10,
                                height: 10,
                                borderRadius: "50%",
                                marginRight: 1,
                                flexShrink: 0,
                                backgroundColor:
                                    wsData.status === "stopped"
                                        ? theme.palette.error.main
                                        : wsData.status === "running"
                                        ? theme.palette.success.main
                                        : theme.palette.warning.main
                            }}></Box>
                        <Box>
                            <Typography
                                component={"div"}
                                sx={{
                                    width: "100%",
                                    maxWidth: "260px",
                                    fontWeight: "bold",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}>
                                {wsData.name} {activeEnvironment === wsData.id && " (Active)"}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="body2">
                        Status: <span style={{ textTransform: "capitalize" }}>{wsData.status}</span>
                    </Typography>
                    <Typography variant="body2">
                        Provider: <span style={{ textTransform: "uppercase" }}>{wsData.cloud_provider}</span> ({wsData.region})
                    </Typography>
                    {/* <Typography variant="body2">Region: {wsData.region}</Typography> */}
                    <Typography variant="body2">DNS Name: {wsData.dns_name}</Typography>
                    <Typography variant="body2">Public IP: {wsData.public_ip}</Typography>
                </Box>
            </ListItemButton>
        </ListItem>
    );
};

export default observer(EnvironmentsListItem);
