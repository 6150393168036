import React from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "api";

interface Props {
    module?: string;
    children: React.ReactNode;
}

/**
 * Provides access control for a specific module based on the user's permissions.
 * If the user has access to the specified module, the component will render its children.
 * Otherwise, the component will not render anything.
 *
 * @param {string} module - The name of the module to check access for.
 * @param {React.ReactNode} children - The child elements to render if the user has access to the module.
 * @returns {React.ReactNode} The child elements if the user has access to the module, or null otherwise.
 *
 * @example
 * <ModuleAccessProvider module="tag">
 *   <TagEditor />
 * </ModuleAccessProvider>
 *
 * @remarks
 * This component uses MobX to observe changes to the user's permissions and re-renders
 * the child elements when the user's permissions change.
 *
 * The `module` prop should be a string representing the name of the module to check access for.
 *
 * The `children` prop should be one or more React elements to render if the user has access to the module.
 *
 * If the user is not logged in or does not have access to the specified module, the component will not render anything.
 */

const ModuleAccessProvider: React.FC<Props> = observer(function ModuleAccessProvider({ module, children }) {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    if (!module) {
        return <>{children}</>;
    }

    const canAccessModule = (module: string) => {
        if (user && user.modules_available && user.modules_available[module] !== undefined) {
            return user.modules_available[module];
        } else {
            return false;
        }
    };

    if (user) {
        if (canAccessModule(module)) {
            return <>{children}</>;
        }
    }

    return null;
});

export default ModuleAccessProvider;
