import React, { useContext, useEffect, useRef } from "react";
import { agent, RootStoreContext, ServiceConsumptionResponseV2, TotalsBreakdownItem } from "api";
import { Box, Card, Typography } from "@mui/material";
import { ChartsTooltip, PiePlot, ResponsiveChartContainer } from "@mui/x-charts";
import { endOfDay, startOfDay } from "date-fns";
import { ChartPalette } from "styles/ChartPalette";
import { observer } from "mobx-react-lite";

interface Props {
    chartTitle?: string;
    datesScope: any;
}

interface IChartData {
    value: number;
    label: string;
}

export const BillingSummaryPie: React.FC<Props> = ({ chartTitle, datesScope }) => {
    const [chartData, setChartData] = React.useState<IChartData[]>([]);
    const ref = useRef<HTMLDivElement>(null);
    const [currencySymbol, setCurrencySymbol] = React.useState<string>("");
    const [serviceConsumptionData, setServiceConsumptionData] = React.useState<ServiceConsumptionResponseV2>();
    const rootStore = useContext(RootStoreContext);
    const { selectedEnvironment, selectedDestination } = rootStore.billingStore;

    const getBillingData = async () => {
        await agent.Billing.serviceConsumption({
            start_date: startOfDay(new Date(datesScope[0])).toDateString(),
            end_date: endOfDay(new Date(datesScope[1])).toDateString(),

            environment_id: selectedEnvironment,
            destination_id: selectedDestination
        })
            .then((response) => {
                //console.log(response);
                setServiceConsumptionData(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getBillingData();
    }, [selectedEnvironment, selectedDestination, datesScope]);

    useEffect(() => {
        const newChartData: IChartData[] = [];
        if (serviceConsumptionData?.totals_breakdown) {
            serviceConsumptionData.totals_breakdown.forEach((breakdownItem: TotalsBreakdownItem) => {
                if (breakdownItem.item && breakdownItem.total > 0) {
                    newChartData.push({
                        value: breakdownItem.total,
                        label: breakdownItem.item
                    });
                }
            });
            setCurrencySymbol(serviceConsumptionData.customer_currency_symbol);
            setChartData(newChartData);
        }
    }, [serviceConsumptionData]);

    return (
        <Card ref={ref} sx={{ width: "100%", height: "100%", p: 0 }}>
            {serviceConsumptionData && chartData.length > 0 ? (
                <Box
                    sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%"
                    }}>
                    {chartTitle && <Typography sx={{ display: "flex", justifyContent: "center" }}>{chartTitle}</Typography>}
                    <ResponsiveChartContainer
                        disableAxisListener
                        height={300}
                        colors={ChartPalette}
                        series={[
                            {
                                type: "pie",
                                data: chartData,
                                innerRadius: 55,
                                cornerRadius: 3,
                                paddingAngle: 5,
                                highlightScope: { faded: "global", highlighted: "item" },
                                valueFormatter: (item) => `${currencySymbol}${item.value}`
                            }
                        ]}>
                        <PiePlot skipAnimation colors={ChartPalette} />
                        <ChartsTooltip trigger="item" />
                    </ResponsiveChartContainer>
                    <Box
                        sx={{
                            position: "absolute",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <Typography fontSize={13} fontWeight={600} lineHeight={1}>
                            Total
                        </Typography>
                        <Typography fontSize={16} fontWeight={600}>
                            {serviceConsumptionData.customer_currency_symbol + "" + serviceConsumptionData.grand_total_cost}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Typography sx={{ display: "flex", justifyContent: "center" }}>No Data to display</Typography>
            )}
        </Card>
    );
};

export default observer(BillingSummaryPie);
