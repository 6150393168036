import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { agent, RootStoreContext } from "api";
import { TextField } from "components/Forms";
import { FORM_ERROR } from "final-form";
import React, { useContext } from "react";
import { Form } from "react-final-form";

interface RenameInstanceFormProps {
    id: string;
}

const RenameClusterForm: React.FC<RenameInstanceFormProps> = ({ id }) => {
    const rootStore = useContext(RootStoreContext);
    return (
        <Form
            onSubmit={(values: any) => {
                return agent.FileCluster.renameCluster(id, { name: values.name })
                    .then(() => {
                        rootStore.dialogStore.closeDialog();
                    })
                    .catch((error) => {
                        const errors = error.data.errors;
                        return Promise.resolve({ [FORM_ERROR]: error, ...errors });
                    });
            }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Rename Instance</DialogTitle>
                    <DialogContent>
                        <TextField
                            name="name"
                            label="Name"
                            validate={(value: any) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={rootStore.dialogStore.closeDialog}>Cancel</Button>
                        <Button variant="contained" type="submit">
                            Rename
                        </Button>
                    </DialogActions>
                </form>
            )}
        />
    );
};

export default RenameClusterForm;
