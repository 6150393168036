import React from "react";
import { Alert, Box } from "@mui/material";
import { IMultiChanLayout } from "api";

export const MultiViewerLayoutGraphic: React.FC<{ item?: IMultiChanLayout; highlightedIndex?: number }> = ({ item, highlightedIndex }) => {
    if (!item || !item.tile_setup) {
        return <Alert severity="error">No layout data found</Alert>;
    }

    const width = parseInt(item.width);
    const height = parseInt(item.height);

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                maxWidth: "30rem",
                aspectRatio: `${width} / ${height}`,
                border: "2px solid #2F7ABB",
                boxSizing: "border-box",
                overflow: "hidden"
            }}>
            {item.tile_setup.map((tile) => {
                const isHighlighted = highlightedIndex === undefined || tile.video_tile_number === highlightedIndex;

                if (!isHighlighted) {
                    return <></>;
                }

                return (
                    <Box
                        key={tile.tile_type_uuid + tile.video_tile_number}
                        sx={{
                            position: "absolute",
                            left: `${(tile.left / width) * 100}%`,
                            top: `${(tile.top / height) * 100}%`,
                            width: `${(tile.width / width) * 100}%`,
                            height: `${(tile.height / height) * 100}%`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "10px",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            textAlign: "center",
                            border: "2px solid #2F7ABB"
                        }}>
                        {!highlightedIndex && (
                            <>
                                {tile.tile_type} Tile {tile.video_tile_number}
                            </>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default MultiViewerLayoutGraphic;
