import React, { useState, useContext, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { EnvironmentPermissionsEntry, ISourcePermission, RootStoreContext, agent } from "api";
import MaxDestinations from "./MaxDestinations";
import { InputSourceAccessEntryRow } from "./InputSourceAccessEntryRow";
import { Fade, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useSourcePermissions } from "hooks";

const EnvironmentPermissionsTable = (props: { env: EnvironmentPermissionsEntry; row: ISourcePermission }) => {
    const { env, row } = props;
    const rootStore = useContext(RootStoreContext);
    const { setChannelVisibility, loadSourcePermissions } = useSourcePermissions();
    const { openDialog } = rootStore.dialogStore;
    const [open, setOpen] = React.useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [AllChannelsVisible, setAllChannelsVisible] = useState(env.all_channels_enabled);

    const EnvironID = env.environment_id;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChannelsVisible(event.target.checked);
        setLoadingInitial(false);
    };

    useEffect(() => {
        const values = {
            all_channels_visible: AllChannelsVisible,
            environment_id: EnvironID
        };
        if (!loadingInitial) {
            setChannelVisibility(values, row?.token).finally(() => loadSourcePermissions());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AllChannelsVisible, loadSourcePermissions]);

    return (
        <>
            <TableRow>
                <TableCell
                    style={{
                        cursor: "pointer",
                        fontWeight: "bold"
                    }}
                    onClick={() => setOpen(!open)}>
                    {env.environment_human_name}
                </TableCell>
                <TableCell align="center">
                    <MaxDestinations token={row.token} env={env} />
                </TableCell>
                <TableCell align="center">
                    <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{
                            timeout: 600
                        }}
                        placement="bottom"
                        title="Delete">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                openDialog(
                                    <ConfirmationDialog
                                        title="Confirm Delete Destinations"
                                        message=""
                                        additionalContent={
                                            <>
                                                <Typography sx={{ mb: 1 }}>
                                                    Organisation: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{row.name}</span>
                                                </Typography>
                                                <Typography sx={{ mb: 1 }}>
                                                    Environment:{" "}
                                                    <span style={{ fontWeight: 600, fontSize: "1rem" }}>
                                                        {row.environment_permissions![0].environment_human_name}
                                                    </span>
                                                </Typography>
                                                <Typography>
                                                    This action will remove all of this organisations destinations from this environment and
                                                    cannot be undone, You will need to reset the max number of destinations and the
                                                    organisation you are sharing content with will need to create new destinations in this
                                                    environment.
                                                </Typography>
                                            </>
                                        }
                                        onConfirm={async () => {
                                            await agent.SourcePermissions.resetMaxDestinationstoken(row.token!, env.environment_id!).then(
                                                () => loadSourcePermissions()
                                            );
                                        }}
                                    />
                                );
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    <span
                        style={{
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}>
                        Share all
                    </span>
                    <span>
                        <Switch
                            checked={AllChannelsVisible}
                            onChange={handleChange}
                            color="primary"
                            name="AllChannelsVisible"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    </span>
                </TableCell>
            </TableRow>
            {!AllChannelsVisible && (
                <TableRow style={{ borderTop: "none" }}>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderTop: "none"
                        }}
                        className="input-source-access-row"
                        colSpan={4}>
                        {row.input_sources_access
                            ? row.input_sources_access
                                  .filter((ds) => ds.environment_id === env.environment_id)
                                  .map((inputRow) => <InputSourceAccessEntryRow key={inputRow.id} row={inputRow} token={row.token} />)
                            : null}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default EnvironmentPermissionsTable;
