import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";

export default class ModalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable.shallow modal = {
        open: false,
        body: null,
        size: undefined,
        close: undefined,
        getStarted: undefined,
        closeOnDimmerClick: true,
        closeOnEscape: true
    };

    @action closeModal = () => {
        this.modal.open = false;
        this.modal.body = null;
    };
}
