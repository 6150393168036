import { Box, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import { endOfMonth, endOfWeek, format, isSameDay, isSameMonth, startOfMonth, startOfWeek } from "date-fns";
import React, { useContext } from "react";
import CalendarItem from "./CalendarItem";
import { RootStoreContext } from "api";
import { Add, CalendarToday, ContentPaste } from "@mui/icons-material";
import CreateRepeatScheduleForm from "features/scheduler/Components/CreateRepeatScheduleForm";
import { useNavigate } from "react-router-dom";

interface DayCellProps {
    day: Date;
    currentDate: Date;
    schedule: any[];
    onItemCopy: (item: any) => void;
    copiedItem: any;
    showScheduledItems: boolean;
    showOnItems: boolean;
    showFinishedItems: boolean;
}

const DayCell: React.FC<DayCellProps> = ({
    day,
    currentDate,
    schedule,
    onItemCopy,
    copiedItem,
    showScheduledItems,
    showOnItems,
    showFinishedItems
}) => {
    const navigate = useNavigate();

    const rootStore = useContext(RootStoreContext);
    const { openDialog } = rootStore.dialogStore;

    const { setDate } = rootStore.schedulerStore;

    const theme = useTheme();
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 0 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 0 });
    const dateFormat = "d";
    const formattedDate = format(day, dateFormat);

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        const parentRect = event.currentTarget.getBoundingClientRect();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6
                  }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const isPastDate = () => {
        day.setHours(23, 59, 59, 0);
        return day.toISOString() < new Date().toISOString();
    };

    return (
        <>
            <Grid
                item
                onContextMenu={handleContextMenu}
                onClick={handleContextMenu}
                xs={1}
                key={day.toString()}
                sx={{
                    height: "100%",
                    padding: 1,
                    pt: 0.5,
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    backgroundColor: contextMenu
                        ? theme.palette.action.selected
                        : isSameDay(day, new Date(currentDate))
                        ? theme.palette.action.focus
                        : "inherit",
                    color: !isSameMonth(day, monthStart) ? theme.palette.text.disabled : theme.palette.text.primary,
                    "&:first-child": {
                        borderLeft: "none"
                    },
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover
                    },
                    overflowY: "auto"
                }}>
                <Box>{formattedDate}</Box>
                {schedule &&
                    schedule.length > 0 &&
                    schedule
                        .filter((item) => {
                            if (showScheduledItems && item.status === "Scheduled") {
                                return true;
                            }
                            if (showOnItems && item.status === "Live") {
                                return true;
                            }
                            if (showFinishedItems && item.status === "Finished") {
                                return true;
                            }
                            return false;
                        })
                        .map((item) => <CalendarItem key={item.id} item={item} onCopy={(item) => onItemCopy(item)} />)}
            </Grid>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <Box
                    sx={{
                        pt: 0,
                        pr: 2,
                        pb: 1,
                        pl: 2,
                        fontWeight: "bold"
                    }}>
                    {format(day, "EEEE, MMMM d, yyyy")}
                </Box>
                <Divider />
                <MenuItem
                    disabled={isPastDate()}
                    onClick={() => {
                        handleClose();
                        const startTime = new Date(new Date(day).getFullYear(), new Date(day).getMonth(), new Date(day).getDate());
                        const endTime = new Date(new Date(day).getFullYear(), new Date(day).getMonth(), new Date(day).getDate());
                        // add 1 hour to the end_time
                        endTime.setHours(endTime.getHours() + 1);

                        openDialog(<CreateRepeatScheduleForm start_date={startTime} end_date={endTime} />);
                    }}>
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText>Add Schedule</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={isPastDate() || !copiedItem}
                    onClick={() => {
                        handleClose();
                        // start_time takes the day from the cell and the time from the copied item
                        const startTime = new Date(
                            new Date(day).getFullYear(),
                            new Date(day).getMonth(),
                            new Date(day).getDate(),
                            new Date(copiedItem.start_time).getHours(),
                            new Date(copiedItem.start_time).getMinutes()
                        );
                        const endTime = new Date(
                            new Date(day).getFullYear(),
                            new Date(day).getMonth(),
                            new Date(day).getDate(),
                            new Date(copiedItem.end_time).getHours(),
                            new Date(copiedItem.end_time).getMinutes()
                        );

                        openDialog(
                            <CreateRepeatScheduleForm
                                source_id={copiedItem.source_id}
                                destination_id={copiedItem.destination_id}
                                start_date={startTime}
                                end_date={endTime}
                            />
                        );
                    }}>
                    <ListItemIcon>
                        <ContentPaste />
                    </ListItemIcon>
                    <ListItemText>Paste</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setDate(day);
                        navigate("/scheduler");
                    }}>
                    <ListItemIcon>
                        <CalendarToday />
                    </ListItemIcon>
                    <ListItemText>View Day</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export default DayCell;
