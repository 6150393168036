import { Box } from "@mui/material";
import React from "react";

interface RowStartProps {
    hour: number;
}

const RowStart: React.FC<RowStartProps> = ({ hour }) => {
    return (
        <Box
            sx={{
                width: "60px",
                height: "60px",
                flexGrow: 0,
                flexShrink: 0,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: (theme) => theme.palette.action.hover,
                p: 0.5,
                position: "relative"
            }}>
            {hour < 10 && "0"}
            {hour}:00
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    width: "10px",
                    height: "1px",
                    backgroundColor: (theme) => theme.palette.action.hover,
                    opacity: 0.8,
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    top: "25%",
                    right: 0,
                    width: "7px",
                    height: "1px",
                    backgroundColor: (theme) => theme.palette.action.hover,
                    opacity: 0.5,
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    top: "75%",
                    right: 0,
                    width: "7px",
                    height: "1px",
                    backgroundColor: (theme) => theme.palette.action.hover,
                    opacity: 0.5,
                }}
            />
        </Box>
    );
};

export default RowStart;
