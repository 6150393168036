import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Stack,
    Box,
    Typography,
    Pagination,
    TextField,
    MenuItem,
    IconButton,
    Alert,
    AlertTitle,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import { agent, IReleaseNotesEntry, RootStoreContext } from "api";
import Content from "components/Layout/Content";
import { format, parseISO, set } from "date-fns";
import MDEditor from "@uiw/react-md-editor";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ChevronRight, Edit } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from "@mui/lab";

interface IReleaseNotes {}

const ReleaseNotes: React.FC<IReleaseNotes> = () => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const { environment } = rootStore.environmentStore;
    const [data, setData] = useState<IReleaseNotesEntry[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [paginationConfig, setPaginationConfig] = useState({
        page: 1,
        count: 1,
        totalItems: 1,
    });
    const [appValue, setAppValue] = useState("all");
    const options = [
        { value: 'all', text: 'All' },
        { value: 'api', text: 'API' },
        { value: 'workers', text: 'Workers' },
        { value: 'ui', text: 'UI' },
        { value: 'websockets', text: 'Websockets' }
    ];

    const scrollToTop = () => {
        const contentElement = document.getElementById("release-notes");
        if (contentElement) {
            contentElement.scrollTop = 0;
        }
    };

    const getData = async (page: number) => {
        let releaseNotesFunction;
        scrollToTop();

        if (appValue && appValue !== "all") {
            releaseNotesFunction = () => agent.ReleaseNotes.getAppReleaseNotes(page, appValue);
        } else {
            releaseNotesFunction = () => agent.ReleaseNotes.getReleaseNotes(page);
        }

        await releaseNotesFunction()
            .then((response) => {
                setData(response.data);
                setPaginationConfig({
                    page: response.page,
                    count: response.total_pages,
                    totalItems: response.total_entries,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getData(1);
    }, [appValue]);

    const handlePagination = (event: any, page: number) => {
        getData(page);
        scrollToTop();
    }

    const handleDataRefresh = () => {
        getData(paginationConfig.page);
    };

    return (
        <View>
            <ViewHeader title="Release Notes" />
            <ViewBody noPadding>
                <Content
                    content_id="release-notes"
                    headerContent={
                        <Stack direction="row" spacing={2} sx={{ py: 1 }} justifyContent="space-between">
                            <TextField
                                label="App"
                                variant="outlined"
                                size="small"
                                value={appValue}
                                sx={{
                                    width: 200
                                }}
                                select
                                onChange={(event) => {
                                    setAppValue(event.target.value);
                                }}>
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.text}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Pagination
                                page={paginationConfig.page}
                                count={paginationConfig.count}
                                shape="rounded"
                                onChange={(event: any, page: number) => {
                                    handlePagination(event, page);
                                }}
                            />
                        </Stack>
                    }
                    // footerContent={
                    //     <Stack spacing={2} direction="row" justifyContent="flex-end">
                    //         <Box>{paginationConfig.page * paginationConfig.count}-10 of {paginationConfig.totalItems}</Box>
                    //         {paginationConfig.count}
                    //         <Stack spacing={1} direction="row">
                    //             <Box><ChevronLeftIcon /></Box>
                    //             <Box><ChevronRightIcon /></Box>
                    //         </Stack>
                    //     </Stack>
                    // }
                >
                    <Box>
                        {data && data.map((item) => (
                            <Box key={item.id}>
                                <Box sx={{ py: 2 }}>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 1, mr: 1 }}>
                                        <Typography variant="h5" style={{ textTransform: "uppercase"}}>
                                            {item.app} - {format(parseISO(item.time), "dd/LL/yyyy, HH:mm:ss")}
                                        </Typography>

                                        <Stack direction="row" spacing={1}>
                                            <IconButton onClick={() => {
                                                openDrawer(<EditReleaseNotes item={item} onDataRefresh={handleDataRefresh} />);
                                            }}>
                                                <EditIcon />
                                            </IconButton>
                                            {/* <IconButton onClick={() => {
                                                openDialog(<DeleteReleaseNotes item={item} onDataRefresh={handleDataRefresh} />);
                                            }}>
                                                <DeleteIcon />
                                            </IconButton> */}
                                        </Stack>
                                    </Stack>
                                    <MDEditor.Markdown
                                        source={item.log}
                                        style={{ whiteSpace: item.app === "ui" ? undefined : "pre-wrap", padding: 20, fontSize: 15 }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Content>
            </ViewBody>
        </View>
    );
};

interface IEditReleaseNotes {
    item: any;
    onDataRefresh: () => void;
}

const EditReleaseNotes: React.FC<IEditReleaseNotes> = ({ item, onDataRefresh }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer, closeDrawer } = rootStore.drawerStore;
    const [newLog, setNewLog] = useState(item.log);
    const [isLoading, setIsLoading] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const updateReleaseNotes = async () => {
        setIsLoading(true);
        await agent.ReleaseNotes.updateReleaseNotes(item.id, { log: newLog })
            .then((response) => {
                onDataRefresh();
                closeDrawer();
            })
            .catch((error) => {
                console.log('error', error);
                setSubmitError(true);
            });
        setIsLoading(false);
    }

    return (
        <Box sx={{ width: 680, maxWidth: "100vw", height: "100%", padding: 3 }}>
            <Stack spacing={4} justifyContent="space-between" sx={{ height: '100%' }}>
                <Stack spacing={4}>
                    <Typography variant="h3">Edit Release Notes</Typography>
                    <MDEditor
                        value={newLog}
                        onChange={(value, _) => setNewLog(value)}
                        height='500px'
                    />

                    {submitError && (
                        <Alert severity="error" sx={{ mb: 2, mt: 4 }}>
                            <AlertTitle>An Error Occurred</AlertTitle>
                            Please try again later.
                        </Alert>
                    )}
                </Stack>

                <Stack spacing={2} direction="row" justifyContent="flex-end">
                    <Button onClick={() => closeDrawer()}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        disabled={!submitError && newLog === item.log}
                        variant="contained"
                        onClick={updateReleaseNotes}
                    >
                        Update
                    </LoadingButton>
                </Stack>
            </Stack>
        </Box>
    );
}

interface IDeleteReleaseNotes {
    item: any;
    onDataRefresh: () => void;
}

const DeleteReleaseNotes: React.FC<IDeleteReleaseNotes> = ({ item, onDataRefresh }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;
    const { openDialog, closeDialog } = rootStore.dialogStore;
    const [error, setError] = useState("");
    const [deleting, setDeleting] = useState(false);

    const deleteReleaseNotes = async () => {
        setDeleting(true);
        await agent.ReleaseNotes.deleteReleaseNotes(item.id)
            .then(() => {
                closeDialog();
                onDataRefresh();
            })
            .catch((error) => {
                setError(error.data.message);
            })
            .finally(() => {
                setDeleting(false);
            });
    }
    
    return (
        <>
            <DialogTitle>Confirm Release Note Deletion</DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ marginBottom: 10 }}>
                    Release Note: {item.app} - {format(parseISO(item.time), "dd/LL/yyyy, HH:mm:ss")}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 10 }}>
                    Deleting this release note will remove it for all users and cannot be undone. Are you sure you want to delete this?
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={deleting}
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <LoadingButton
                    disabled={deleting}
                    loading={deleting}
                    variant="contained"
                    color="error"
                    onClick={(e) => {
                        deleteReleaseNotes();
                    }}
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </LoadingButton>
            </DialogActions>
        </>
    )
}

export default observer(ReleaseNotes);
