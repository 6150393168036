import React from "react";
import { TextField, Button } from "@mui/material";
import { agent, ICreateMTRHostGroupResponse } from "api";
import ErrorMessages from "components/ErrorMessages";

interface CreateHostFormProps {
    id: string;
    onLoading: (loading: boolean) => void;
    onSuccess: () => void;
    onError: () => void;
    group_id: number;
}

const CreateHostForm: React.FC<CreateHostFormProps> = ({ group_id, onLoading, onSuccess, onError }) => {
    const [host_name, setHostName] = React.useState("");
    const [host, setHost] = React.useState("");
    const [error, setError] = React.useState<any>();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onLoading(true);

        await agent.Reporting.createMtrGroupHost(group_id, { host_name, host })
            .then((value: ICreateMTRHostGroupResponse) => {
                onSuccess();
            })
            .catch((error) => {
                console.log(error);
                error && setError(error.data?.errors ? error.data.errors : "An unknown error occurred");
                onError();
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                variant="standard"
                label="Endpoint Name"
                value={host_name}
                onChange={(event) => setHostName(event.target.value)}
                fullWidth
            />
            <TextField variant="standard" label="IP/DNS Address" value={host} onChange={(event) => setHost(event.target.value)} fullWidth />
            <ErrorMessages errors={error} />
            <Button variant="contained" type="submit" disabled={!host_name || !host} style={{ marginTop: 10 }}>
                Add Endpoint
            </Button>
        </form>
    );
};

export default CreateHostForm;
