import React, { useContext, useState, useEffect } from "react";
import { Form as FinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { combineValidators, isRequired, createValidator, composeValidators } from "revalidate";
import { TextField } from "mui-rff";
import { IMultiChanDetail, IMultiChanDetails, RootStoreContext } from "api";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import * as multiChannelOptions from "app/common/options/multiChannelOptions";
import { MultiChanEditedMessage } from "./MultiChanEditedMessage";
import OnChange from "components/FinalFormListener/OnChange";
import { SelectField } from "components/Forms";
import { Alert, AlertTitle, Box, Button, Stack, Typography } from "@mui/material";
import { useSources } from "hooks";

interface IProps {
    id: string | undefined;
    name: string;
}

const MultiChannelConfigEditForm: React.FC<IProps> = ({ id, name }) => {
    const rootStore = useContext(RootStoreContext);
    const { editMultiChannel, getMultiViewerInfo } = useSources();
    const { closeDrawer } = rootStore.drawerStore;
    const { openDialog } = rootStore.dialogStore;
    const [isInterlaced, setisInterlaced] = useState(false);
    const [error, setError] = useState<any>("");
    const [multiViewerDetails, setMultiViewerDetails] = useState<IMultiChanDetails | null>(null);

    const parse = (value: any) => (isNaN(parseFloat(value)) ? "" : parseFloat(value));

    const handleInterlacedChange = (value: string) => {
        setisInterlaced(value === "true");
    };

    useEffect(() => {
        getMultiViewerInfo(id).then((values) => {
            setMultiViewerDetails(values);
            setisInterlaced(values.encoder_details.is_interlaced === "true");
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isValidMaxBitrate = createValidator(
        (message) => (value) => {
            if (value < 1500 || value > 30000) {
                return message;
            }
        },
        "Mux bitrate (Kbps) must be between 1500 and 30000"
    );

    const isValidTransportNumber = createValidator(
        (message) => (value) => {
            if (value < 1 || value > 65535) {
                return message;
            }
        },
        "Transport stream program number must be between 1 and 65535"
    );

    const is_valid_pid_number = (value: number) => {
        return value >= 33 && value <= 8190;
    };

    const isValidPmtPid = createValidator(
        (message) => (value, allValues) => {
            if (!is_valid_pid_number(value)) {
                return message;
            }
            if (value === allValues.video_pid) {
                return "This pid number is used also in Video PID field. Please choose another PID number to make them unique.";
            }
            if (value === allValues.transcoded_audio_pid) {
                return "This pid number is used also in AAC Audio PID field. Please choose another PID number to make them unique.";
            }
        },
        "PID number must be between 33 and 8190."
    );

    const isValidVideoPid = createValidator(
        (message) => (value, allValues) => {
            if (!is_valid_pid_number(value)) {
                return message;
            }
            if (value === allValues.pmt_pid) {
                return "This pid number is used also in PMT PID field. Please choose another PID number to make them unique.";
            }
            if (value === allValues.transcoded_audio_pid) {
                return "This pid number is used also in AAC Audio PID field. Please choose another PID number to make them unique.";
            }
        },
        "PID number must be between 33 and 8190."
    );

    const isValidTranscodedAudioPid = createValidator(
        (message) => (value, allValues) => {
            if (!is_valid_pid_number(value)) {
                return message;
            }
            if (value === allValues.pmt_pid) {
                return "This pid number is used also in PMT PID field. Please choose another PID number to make them unique.";
            }
            if (value === allValues.video_pid) {
                return "This pid number is used also in Video PID field. Please choose another PID number to make them unique.";
            }
        },
        "PID number must be between 33 and 8190."
    );

    const validate = combineValidators({
        max_allowed_bitrate_kbps: composeValidators(isValidMaxBitrate, isRequired("max bitrate"))(),
        encoder_gop_size_id: isRequired("GOP size"),
        encoder_frame_rate_id: isRequired("frame size"),
        encoder_gop_size_mode_id: isRequired("GOP size mode"),
        program_number: composeValidators(isValidTransportNumber, isRequired("transport stream program number"))(),
        pmt_pid: composeValidators(isValidPmtPid, isRequired("transport Stream PMT PID"))(),
        video_pid: composeValidators(isValidVideoPid, isRequired("transport Stream Video PID"))(),
        transcoded_audio_pid: composeValidators(isValidTranscodedAudioPid, isRequired("Transport Stream AAC Audio PID"))()
    });

    const initialValues = {
        max_allowed_bitrate_kbps: multiViewerDetails?.encoder_details.max_allowed_bitrate_kbps,
        is_interlaced: multiViewerDetails?.encoder_details.is_interlaced,
        encoder_gop_size_id: multiViewerDetails?.encoder_details.encoder_gop_size_id,
        encoder_frame_rate_id: multiViewerDetails?.encoder_details.encoder_frame_rate_id,
        encoder_gop_size_mode_id: multiViewerDetails?.encoder_details.encoder_gop_size_mode_id,
        program_number: multiViewerDetails?.encoder_details.program_number,
        pmt_pid: multiViewerDetails?.encoder_details.pmt_pid,
        video_pid: multiViewerDetails?.encoder_details.video_pid,
        transcoded_audio_pid: multiViewerDetails?.encoder_details.transcoded_audio_pid,
        passthrough_audio_pid: multiViewerDetails?.encoder_details.passthrough_audio_pid
    };

    return (
        <div>
            <FinalForm
                onSubmit={(values: IMultiChanDetail) => {
                    editMultiChannel(id, values)
                        .then(() => {
                            openDialog(<MultiChanEditedMessage />, undefined, "xs");
                            closeDrawer();
                        })
                        .then(() => {
                            closeDrawer();
                        })
                        .catch((error) => {
                            if (error.data.hasOwnProperty("errors")) {
                                const errorMessage = Object.values(error.data.errors);
                                setError(errorMessage[0]);
                            } else if (error.data.hasOwnProperty("message")) {
                                setError(error.data.message);
                            } else {
                                setError("Unexpected error when updating multi-viewer");
                            }
                            //  {"status": "not_running", "message": "Multi-viewer is not in a running state"}
                            // return {
                            //   [FORM_ERROR]: error
                            // }
                        });
                }}
                initialValues={initialValues}
                validate={validate}
                mutators={{
                    ...arrayMutators
                }}
                render={({ handleSubmit, submitting, invalid, pristine, dirtySinceLastSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ p: 2, pt: 0 }}>
                            <div style={{ padding: 16, marginBottom: 30 }}>
                                <div>
                                    <div style={{ width: "75px", margin: "0 20px 20px auto" }}>
                                        <div
                                            style={{
                                                color: "#ffffff",
                                                fontSize: "12px",
                                                textAlign: "left"
                                            }}>
                                            Powered by
                                        </div>
                                        <img
                                            src="/assets/img/tag_500px.png"
                                            width="75"
                                            style={{ margin: "0", padding: "0" }}
                                            alt="Powered by TAG"
                                            title="Powered by TAG"
                                        />
                                    </div>
                                    <Typography variant="h4" sx={{ textAlign: "left", mb: 2 }}>
                                        Edit Multiviewer Settings
                                    </Typography>
                                    <Typography variant="h5" sx={{ textAlign: "left", mb: 1 }}>
                                        Name: {name}
                                    </Typography>
                                    <Stack spacing={1}>
                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Please set the required Mux bitrate (Kbps), range: 1500 - 30000">
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name="max_allowed_bitrate_kbps"
                                                    type="text"
                                                    fieldProps={{
                                                        parse: parse
                                                    }}
                                                    label="Mux bitrate (Kbps)"
                                                />
                                            </div>
                                        </Tooltip>

                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select video frame type">
                                            <div>
                                                <SelectField
                                                    label="Video frame type"
                                                    required
                                                    options={multiChannelOptions.videoFrameType}
                                                    name="is_interlaced"
                                                    helperText={(meta) => meta.touched && meta.error}
                                                    error={(meta) => meta.touched && meta.error}
                                                />
                                                <OnChange name={"is_interlaced"}>
                                                    {(value) => {
                                                        handleInterlacedChange(value);
                                                    }}
                                                </OnChange>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select video frame rate">
                                            <div>
                                                {isInterlaced ? (
                                                    <SelectField
                                                        label="Video frame rate"
                                                        required
                                                        options={multiChannelOptions.interlacedEncoderFrameRate}
                                                        name="encoder_frame_rate_id"
                                                        placeholder="Select video frame rate"
                                                        helperText={(meta) => meta.touched && meta.error}
                                                        error={(meta) => meta.touched && meta.error}
                                                    />
                                                ) : (
                                                    <SelectField
                                                        label="Video frame rate"
                                                        required
                                                        options={multiChannelOptions.nonInterlacedEncoderFrameRate}
                                                        name="encoder_frame_rate_id"
                                                        placeholder="Select video frame rate"
                                                        helperText={(meta) => meta.touched && meta.error}
                                                        error={(meta) => meta.touched && meta.error}
                                                    />
                                                )}
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select GOP size">
                                            <div>
                                                <SelectField
                                                    label="GOP size"
                                                    required
                                                    options={multiChannelOptions.encoderGOPSize}
                                                    name="encoder_gop_size_id"
                                                    placeholder="Select GOP size"
                                                    helperText={(meta) => meta.touched && meta.error}
                                                    error={(meta) => meta.touched && meta.error}
                                                />
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select GOP mode, Static - configured GOP size is fixed, Dynamic - configured GOP size is max value">
                                            <div>
                                                <SelectField
                                                    label="GOP size mode"
                                                    required
                                                    options={multiChannelOptions.encoderGOPSizeMode}
                                                    name="encoder_gop_size_mode_id"
                                                    placeholder="Select GOP size mode"
                                                    helperText={(meta) => meta.touched && meta.error}
                                                    error={(meta) => meta.touched && meta.error}
                                                />
                                            </div>
                                        </Tooltip>

                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select program number between 1 (default) and 6535.">
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name="program_number"
                                                    type="text"
                                                    fieldProps={{
                                                        parse: parse
                                                    }}
                                                    label="Program Number"
                                                />
                                            </div>
                                        </Tooltip>

                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select PMT PID number between 33 and 8190, default is 100.">
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name="pmt_pid"
                                                    type="text"
                                                    fieldProps={{
                                                        parse: parse
                                                    }}
                                                    label="PMT PID"
                                                />
                                            </div>
                                        </Tooltip>

                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select Video PID number between 33 and 8190, default is 101">
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name="video_pid"
                                                    type="text"
                                                    label="Video PID"
                                                    fieldProps={{
                                                        parse: parse
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            style={{ fontSize: "1rem" }}
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            placement="left"
                                            arrow
                                            title="Select audio PID number between 33 and 8190, default is 102">
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name="transcoded_audio_pid"
                                                    type="text"
                                                    label="AAC Audio PID"
                                                    fieldProps={{
                                                        parse: parse
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </Stack>
                                </div>
                            </div>
                            {error && (
                                <Box sx={{ width: "100%", px: 2, mb: 3 }}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {error}
                                    </Alert>
                                </Box>
                            )}
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ mx: 2, mb: 4 }}>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        closeDrawer();
                                    }}
                                    style={{ margin: 0, width: 130 }}
                                    type="button">
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    sx={{ margin: 0, width: 130 }}>
                                    Update
                                </Button>
                            </Stack>
                        </Box>
                    </form>
                )}
            />
        </div>
    );
};
export default MultiChannelConfigEditForm;
