import React, { useContext, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "app/common/form/ErrorMessage";
import { combineValidators, composeValidators, createValidator, isRequired } from "revalidate";
import { IOrganisationSourceInvitation, RootStoreContext, agent } from "api";
import CopyToClipboard from "react-copy-to-clipboard";
import FileDownload from "js-file-download";
import { Box, Button, Card, DialogActions, DialogContent, DialogTitle, Fade, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TextField } from "components/Forms";
import { useSourcePermissions } from "hooks";

const characterLimit = createValidator(
    (message) => (value) => {
        if (value) {
            if (value.length > 30) {
                return message;
            }
        }
    },
    "Name should not exceed 30 characters"
);

const validate = combineValidators({
    destination_temporary_name: composeValidators(characterLimit, isRequired("Organisation Name"))()
});

const AddDestinationOrganisation = () => {
    const rootStore = useContext(RootStoreContext);
    const { loadSourcePermissions } = useSourcePermissions();
    const { closeDialog } = rootStore.dialogStore;
    const [copied, setCopied] = useState(false);
    const [invitationToken, setInvitationToken] = useState<string | null>(null);

    return (
        <FinalForm
            onSubmit={async (values: IOrganisationSourceInvitation) => {
                setInvitationToken(null);
                return await agent.SourcePermissions.getSourceInvitationToken(values)
                    .then((response) => {
                        setInvitationToken(response.source_invitation_token);
                        loadSourcePermissions();
                    })
                    .catch((error) => ({
                        [FORM_ERROR]: error,
                        destination_temporary_name: error.data.errors ? error.data.errors.destination_temporary_name : null
                    }));
            }}
            validate={validate}
            render={({ handleSubmit, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Invite Guest Organisation</DialogTitle>
                    <DialogContent>
                        <TextField
                            name="destination_temporary_name"
                            label="Organisation Name"
                            required
                            validate={(value) => {
                                if (!value) {
                                    return "Required";
                                }

                                if (value.length > 30) {
                                    return "Name should not exceed 30 characters";
                                }
                            }}
                        />

                        {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}

                        {invitationToken && (
                            <Box>
                                <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{ my: 1 }}>
                                    <Typography>
                                        Please share the invitation token visible below with the organisation you wish to invite to consume
                                        your content. Please ask them to log in the platform and go to Settings &raquo; Source Permissions
                                        &raquo; My Entitlements and paste the token in Organisation Invitation Token box to join.
                                    </Typography>
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{
                                            timeout: 600
                                        }}
                                        placement="bottom"
                                        title="Download invitation token">
                                        <Box alignItems={"center"} sx={{ display: "flex" }}>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    FileDownload(invitationToken!, "sourceinvite.txt");
                                                }}
                                                aria-label="Download">
                                                <DownloadIcon sx={{ fontSize: "2rem" }} />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </Stack>
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    Organisation Invitation Token
                                </Typography>
                                <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{ mb: 1 }}>
                                    <Box width={"100%"}>
                                        <Box
                                            sx={{
                                                p: 1,
                                                borderBottom: "1px solid",
                                                borderColor: "text.primary"
                                            }}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 600
                                                }}>
                                                {invitationToken}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <CopyToClipboard
                                        text={invitationToken!}
                                        onCopy={() => {
                                            setCopied(true);
                                        }}>
                                        <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600
                                            }}
                                            placement="bottom"
                                            title="Copy to clipboard">
                                            <Box alignItems={"center"} sx={{ display: "flex" }}>
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    aria-label="Copy to clipboard">
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </CopyToClipboard>
                                    {copied ? (
                                        <Box alignItems={"center"} sx={{ display: "flex" }}>
                                            <Typography sx={{ opacity: 0.5 }}>Copied</Typography>
                                        </Box>
                                    ) : null}
                                </Stack>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeDialog()}>Cancel</Button>

                        <Button variant="contained" color="primary" type="submit" disabled={(invalid && !dirtySinceLastSubmit) || pristine}>
                            Generate Invitation Token
                        </Button>
                    </DialogActions>
                </form>
            )}
        />
    );
};

export default AddDestinationOrganisation;
