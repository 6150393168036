import React, { useContext } from "react";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import IconButton from "@mui/material/IconButton";
import { ThankyouPassword } from "../../ThankyouPassword";
import { IUserFormValues, RootStoreContext } from "api";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, DialogActions, DialogContent, DialogTitle, Stack, Typography, useTheme } from "@mui/material";
import { TextField } from "components/Forms";
import ValidationIcon from "components/Forms/Validation/ValidationIcon";
import PasswordStrengthBar from "react-password-strength-bar";
import VisibilityIcon from "components/Forms/VisibilityIcon";
import usePasswordReset from "hooks/UsePasswordReset";

interface IProps {
    token: any;
    email: any;
}

const ResetPassword: React.FC<IProps> = observer(function ResetPassword({ token, email }) {
    const rootStore = useContext(RootStoreContext);
    const { changeDialogBody } = rootStore.dialogStore;
    const {
        passwordValidationRegex,
        showPassword,
        togglePassword,
        resetPassword,
        validate
    } = usePasswordReset('password', 'confirmpassword');
    const theme = useTheme();

    return (
            <FinalForm
                onSubmit={(values: IUserFormValues | any) =>
                    resetPassword(values)
                        .then((user) => {
                            if (user) {
                                changeDialogBody(<ThankyouPassword />);
                            }
                        })
                        .catch((error) => ({
                            [FORM_ERROR]: error
                        }))
                }
                initialValues={{
                    token: token,
                    email: email
                }}
                validate={validate}
                render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>
                            Change Password
                        </DialogTitle>

                        <DialogContent>
                            <Box>
                                <Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Box sx={{flex: 1}}>
                                            <TextField
                                                name="password"
                                                label="New Password"
                                                helperText={() => {
                                                    return "Enter your new password";
                                                }}
                                                type={showPassword.password ? "text" : "password"}
                                            />
                                        </Box>

                                        <IconButton
                                            onClick={() => {
                                                togglePassword("password");
                                            }}
                                            sx={{ marginTop: 1.5 }}
                                        >
                                            <VisibilityIcon isVisible={!showPassword.password} />
                                        </IconButton>
                                    </Stack>

                                    <PasswordStrengthBar
                                        password={values.password}
                                        scoreWords={['Very Weak', 'Weak', 'Okay', 'Good', 'Strong']}
                                        minLength={8}
                                        shortScoreWord="Min 8 characters"
                                        barColors={[theme.palette.grey[400], theme.palette.error.main, theme.palette.warning.main, theme.palette.success.light, theme.palette.success.main]}
                                    />

                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Box sx={{flex: 1}}>
                                            <TextField
                                                name="confirmpassword"
                                                label="Confirm New Password"
                                                helperText={() => {
                                                    return "This must match your new password";
                                                }}
                                                type={showPassword.confirm ? "text" : "password"}
                                            />
                                        </Box>

                                        <IconButton
                                            onClick={() => {
                                                togglePassword("confirm");
                                            }}
                                            sx={{ marginTop: 1.5 }}
                                        >
                                            <VisibilityIcon isVisible={!showPassword.confirm} />
                                        </IconButton>
                                    </Stack>
                                </Stack>

                                <Alert severity="info">
                                    <Box>
                                        <Typography variant="body1">
                                            Create a password that contains at least:
                                        </Typography>
                                        <Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <ValidationIcon isValid={passwordValidationRegex.length.regex.test(values.password) && passwordValidationRegex.uppercase.regex.test(values.password)} />
                                                <Typography variant="body2">8 Characters (min 1 uppercase)</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <ValidationIcon isValid={passwordValidationRegex.number.regex.test(values.password)} />
                                                <Typography variant="body2">1 Number</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <ValidationIcon isValid={passwordValidationRegex.special.regex.test(values.password)} />
                                                <Typography variant="body2">1 Special Character (e.g. !, @, £, $, %)</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </Alert>
                            </Box>

                            {submitError && !dirtySinceLastSubmit && <Alert severity="error">{submitError.data.message}</Alert>}
                        </DialogContent>

                        <DialogActions>
                            <LoadingButton
                                disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                loading={submitting}
                                id="changePasswords"
                                onClick={handleSubmit}
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                Change Password
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            />
    );
});

export default ResetPassword;
