import View, { ViewBody, ViewHeader } from "components/Layout/View";
import NotificationMethods from "features/alertsManagement/NotificationMethods";
import React from "react";

const AlertNotificationMethods: React.FC = () => {
    return (
        <View>
            <ViewHeader title="Alert Notification Methods" />
            <ViewBody>
                <NotificationMethods />
            </ViewBody>
        </View>
    );
};

export default AlertNotificationMethods;
