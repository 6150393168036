import React from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "api";

interface Props {
    roles: string[];
    children: React.ReactNode;
    matchAll?: boolean;
}

/**
 * This component is used to check if the user has the required role to access a specific component.
 *
 * If the user has the required role, the children will be rendered. If the user does not have the required role,
 * nothing will be rendered. It can be used to compare any boolean value in the user object.
 *
 * Current roles are:
 * - admin
 * - operator
 * - monitor
 *
 * Example usage:
 *
 * ```jsx
 * <OrganisationRoleProvider roles={["admin", "operator"]}>
 *   // your content here
 * </OrganisationRoleProvider>
 * ```
 */

const OrganisationRoleProvider: React.FC<Props> = observer(function OrganisationRoleProvider({ roles, children }) {
    const rootStore = React.useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const userRole = user?.managing_org_permission;

    if (user) {
        if (roles.some((role) => userRole === role)) {
            return <>{children}</>;
        }
    }

    return null;
});

export default OrganisationRoleProvider;
