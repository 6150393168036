import { Box, Divider, Grid } from "@mui/material";
import OnChange from "components/FinalFormListener/OnChange";
import { CheckboxField, RadioGroupField, SelectField, SliderField, TextField } from "components/Forms";
import { observer } from "mobx-react-lite";
import React from "react";
import * as transcodingOptions from "app/common/options/transcodingOptions";
import { FormApi } from "final-form";

interface IVideoCodecProps {
    values: any;
    form: FormApi<any, Partial<any>>;
}

const VideoCodec: React.FC<IVideoCodecProps> = ({ values, form }) => {
    return (
        <>
            {transcodingOptions.videoCodec && (
                <>
                    <RadioGroupField
                        name="video_codec"
                        label="Codec"
                        description="Please note that Dolby Vision is only supported with HEVC"
                        hideLabel={false}
                        row
                        options={
                            transcodingOptions.videoCodec &&
                            transcodingOptions.videoCodec.map((option: any) => {
                                return {
                                    label: option.text,
                                    value: option.value
                                };
                            })
                        }
                        required
                        helperText={(meta) => meta.touched && meta.error}
                        error={(meta) => meta.touched && meta.error}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    <OnChange name="video_codec">
                        {(value) => {
                            // Set enable_dolby_vision to false if we change the video codec to something else than HEVC.
                            if (value !== "HEVC") {
                                form.change("output_colorimetry_parameters.enable_dolby_vision", false);
                            }
                        }}
                    </OnChange>
                </>
            )}
            {transcodingOptions.videoFrameType && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <RadioGroupField
                        name="video_frame_type"
                        label="Frame Type"
                        hideLabel={false}
                        row
                        options={
                            transcodingOptions.videoFrameType &&
                            transcodingOptions.videoFrameType.map((option: any) => {
                                return {
                                    label: option.text,
                                    value: option.value
                                };
                            })
                        }
                        required
                        helperText={(meta) => meta.touched && meta.error}
                        error={(meta) => meta.touched && meta.error}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    <Divider sx={{ my: 2 }} />
                </>
            )}
            {values?.video_frame_type === "Interlaced" && (
                <>
                    <RadioGroupField
                        name="video_frame_rate"
                        label="Frame Rate"
                        hideLabel={false}
                        row
                        options={
                            transcodingOptions.videoIntFrameRate &&
                            transcodingOptions.videoIntFrameRate.map((option: any) => {
                                return {
                                    label: option.text,
                                    value: option.value
                                };
                            })
                        }
                        required
                        helperText={(meta) => meta.touched && meta.error}
                        error={(meta) => meta.touched && meta.error}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    <Divider sx={{ my: 2 }} />
                </>
            )}
            {values?.video_frame_type !== "Interlaced" && (
                <>
                    <RadioGroupField
                        name="video_frame_rate"
                        label="Frame Rate"
                        hideLabel={false}
                        row
                        options={
                            transcodingOptions.videoFrameRate &&
                            transcodingOptions.videoFrameRate.map((option: any) => {
                                return {
                                    label: option.text,
                                    value: option.value
                                };
                            })
                        }
                        required
                        helperText={(meta) => meta.touched && meta.error}
                        error={(meta) => meta.touched && meta.error}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    <Divider sx={{ my: 2 }} />
                </>
            )}
            {transcodingOptions.mpeg4AvcEncoderProfileValues && (
                <>
                    <SelectField
                        name="mpeg4_avc_encoder_profile"
                        label="MPEG4 AVC Encoder Profile"
                        options={transcodingOptions.mpeg4AvcEncoderProfileValues}
                        required
                        helperText={(meta) => meta.touched && meta.error}
                        error={(meta) => meta.touched && meta.error}
                        validate={(value: any) => {
                            if (!value) {
                                return "Required";
                            }
                        }}
                    />
                    <Divider sx={{ my: 3 }} />
                </>
            )}
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SliderField
                            name="video_gop"
                            label="Video GOP"
                            min={1}
                            max={599}
                            step={1}
                            initialValue={values?.video_gop ? values?.video_gop : 1}
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                            validate={(value: any) => {
                                if (!value) {
                                    return "Required";
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ mt: 1 }}>
                        <CheckboxField
                            name="fixed_gop"
                            label="Fixed GOP"
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ mt: 1 }}>
                        <CheckboxField
                            name="enable_open_gop"
                            label="Enable Open GOP"
                            initialValue={values?.enable_open_gop ? values?.enable_open_gop : true}
                            helperText={(meta) => meta.touched && meta.error}
                            error={(meta) => meta.touched && meta.error}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 3 }} />
            </Box>
            <TextField
                name="video_track_pid"
                label="Video Track PID"
                type="number"
                initialValue={values?.video_track_pid ? values?.video_track_pid : 0}
                helperText={(meta) => meta.touched && meta.error}
                error={(meta) => meta.touched && meta.error}
                parse
                validate={(value: any) => {
                    if (!value && value !== 0) {
                        return "Required";
                    }
                }}
            />
            <Divider sx={{ my: 2 }} />
            <SelectField
                name="global_config_latency_mode"
                label="Latency Mode"
                initialValue={values?.global_config_latency_mode ? values?.global_config_latency_mode : "High Quality"}
                options={[
                    { key: "1", text: "Ultra Low Latency", value: "Ultra Low Latency" },
                    { key: "2", text: "Low Latency", value: "Low Latency" },
                    { key: "3", text: "Reduced Latency", value: "Reduced Latency" },
                    { key: "4", text: "High Quality", value: "High Quality" },
                    { key: "5", text: "Best Quality", value: "Best quality" }
                ]}
                required
                helperText={(meta) => meta.touched && meta.error}
                error={(meta) => meta.touched && meta.error}
            />

            <Divider sx={{ my: 2 }} />

            <SliderField name="video_delay_ms" label="Video Delay (ms)" min={0} max={2000} step={50} />
        </>
    );
};

export default observer(VideoCodec);
