import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
    IProviders,
    IRegions,
    ISizes,
    IEnvironmentFormValues,
    ICustomerEnvironment,
    IEnvironmentStatus,
    IEnvironments,
    SwitchEnvironment,
    IEnvironmentDetails,
    IEnvironmentsUpgradeDetails,
    IEnvironmentsUpgradeTypes,
    IEnvironmentUpgradeBody,
    IOutputsInEnvironmentsResponse,
    ISavedIPAddresses,
    IEnvironment,
    IDashboardGroups,
    IDashboardGroup,
    IDasboardGroupUpdRequest,
    IDasboardGroupUpdResponse,
    IEnvironmentsListResponse,
    IScheduler,
    IInputSources,
    IOutputSources,
    ISchedules,
    ISchedulerFormValues,
    IScheduleOverviewResponse,
    IRequests,
    IRequestActions,
    IAlertsListResponse,
    IChanDestResponse,
    IUser,
    IUserFormValues,
    IUsers,
    IUserPermissions,
    IUserPasswordChange,
    IUserTwoFactor,
    IGeoData,
    IProspect,
    IProspectFormValues,
    IDisableTwoFactor,
    IEnableTwoFactor,
    IUsersInOrg,
    IInputFormValues,
    IInput,
    IInputs,
    IInputImageData,
    IInputLiveStatics,
    ICreateTranscoder,
    IUpdateTranscoder,
    TranscoderPresetsResult,
    IRestartTranscoder,
    IMultiChanLayouts,
    IMultiChanLayoutForm,
    IMultiChanPresetResults,
    IListenToPayload,
    ITileInputs,
    IGenericStatusResponse,
    IInputDropdownList,
    ITranscoderTracks,
    IUpdateTranscoderAudioTracks,
    IMuteNotificationsRequest,
    ISourcePermissions,
    ISourcePermission,
    AddInputSourcePermissionRequest,
    AddEventSourcePermissionRequest,
    IMyGuestOrganisations,
    IMaxDestinations,
    IChannelVisibility,
    IBilling,
    IBillingCreditsReport,
    IBillingCustomerInformation,
    IBillingCustomerResponse,
    IBillingQuantity,
    ICreateCogsReportRequest,
    ICreateCogsReportResponse,
    ICreateCustomPriceRequest,
    ICreditResponse,
    IHourlyBreakDownResponse,
    IHourlyRequest,
    IPricing,
    IServiceConsumption,
    IServiceConsumptionRequest,
    IServiceConsumptionResponse,
    IUpdateDefaultPriceRequest,
    ServiceConsumptionResponseV2,
    IDropDownSelectors,
    IPermanentScheduleCommitments,
    IPermanentSchedules,
    IPermanentSchedulesDropDownValues,
    dummy,
    IContactFormValues,
    IContacts,
    ITiers,
    IOrganisation,
    IOrganisationFormValues,
    IOrganisationEnvelope,
    IAddUserToYourOrgRequest,
    IOrganisationInvites,
    IOrganisations,
    SwitchOrganisation,
    IOrganisationConnection,
    IOrganisationInvite,
    IOrganisationSourceInvitation,
    ManagingOrganisationListResponse,
    IChangeManagingOrganisationRequest,
    IUpdateOrgUserPermissions,
    IAddUserToYourOrgResponse,
    IOrganisationSourceInvitationResponse,
    IUpdateOrganisationRequest,
    ICreateModule,
    IOutputFormValues,
    IOutput,
    IOutputs,
    IOutputLiveStatistics,
    IOutputStatusResponse,
    IDestination,
    IOutputsState,
    IFileClusterDeployments,
    IFileClusterCredentials,
    IFileClusterRegions,
    IFileClusterCreateDeploymentRequest,
    IFileClusterCreateDeploymentResponse,
    IFileClusterJobs,
    IFileClusterTemplates,
    IFileClusterDeploymentResponse,
    TFJobCostsListResponse,
    IMtrReportsList,
    IMtrReportRequest,
    IMtrReportTicketResponse,
    IMTRReportResponse,
    IMtrGroupsListResponse,
    IMtrHostsListResponse,
    ICreateMTRHostGroupRequest,
    ICreateMTRHostGroupResponse,
    IAddMTRHostToGroupRequest,
    IAddMTRHostToGroupResponse,
    IActivityLogRequest,
    IActivityLogResponse,
    CreatePushPackagerRequest,
    UpdatePushPackagerRequest,
    CreateNeaLiveRequest,
    CreateNeaLiveResponse,
    CreateScramblingPresetRequest,
    CreateStreamAdaptationFamilyRequest,
    CreateStreamAdaptationRequest,
    NeaLiveResponse,
    NeaLiveSizesResponse,
    UpdateStreamAdaptationFamilyRequest,
    IReleaseNotesPost,
    IReleaseNotesPostResponse,
    IReleaseNotesResponse,
    IEnableOwnCloudRequest,
    IGetLimitTypesResponse,
    IGetLimitsRequest,
    IGetLimitsResponse,
    ISetLimitsRequest,
    ISetRegionsRequest,
    IValidateCredentialsRequest,
    IGetPolicyDocsResponse,
    IGetMetricsRequest,
    IOutputsStateEntry,
    IGetEnvironmentMetricsRequest,
    IGetEnvironmentMetricsResponse,
    IGetKnowledgeHubResponse,
    IDestinationsListResponse,
    IUpdateUserRequest,
    CreateFileServerRequest,
    UpdateFileServerRequest,
    IUploadXMLResponse,
    IConfirmXmlImportRequest,
    RenameNeaLiveRequest
} from "api";
import { create } from "@mui/material/styles/createTransitions";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("jwt");
        if (token) {
            config.headers["access-token"] = `${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// TODO - DISCUSS IF WE CAN DO THIS?
// axios.defaults.validateStatus = function (status) {return status >= 100 && status < 599;};

//Don't forget to remove this when launching, this is for testing only
// const sleep = (ms: number) => (response: AxiosResponse) =>
//     new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms));

axios.interceptors.response.use(undefined, (error) => {
    if (error.message === "Network Error" && !error.response) {
        console.log("Network error - make sure API is running!");
        //toast.error("Network error - make sure API is running!");
    }
    if (error && error.response) {
        const { status, data, headers, config } = error.response;
        if (status === 401 && headers["access-token"] === 'status="invalid_token", description="Invalid JWT token"') {
            toast.info("Your session has expired, please login again", {
                onClick: () => {
                    window.localStorage.removeItem("jwt");
                    window.location.reload();
                }
            });
        }
        if (status === 401 && data.status === "jwt_expired") {
            window.localStorage.removeItem("jwt");
            window.location.reload();
            setTimeout(() => toast.info("Your session has expired, please login again"), 3000);
        }
        if (status === 401 && error.status === "already_registered") {
            const navigate = useNavigate();
            navigate("/login");
        }
        if (status === 403 && data.status === "jwt_expired") {
            window.localStorage.removeItem("jwt");
            window.location.reload();
            toast.info("Your session has expired, please login again");
        }
        // eslint-disable-next-line no-prototype-builtins
        // if(status === 400 && !data.errors && !data.problems) {
        // }
        if (status === 500 && !config.url.includes("https://articles.livelink.video")) {
            toast.error(data.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
        if (status === 422 && error.message.substr(0, 25) === "No input source connected") {
            toast.error(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
        throw error.response;
    }
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string, headers?: any) => axios.get(url, headers).then(responseBody),
    post: (url: string, body: unknown, headers?: any) => axios.post(url, body, headers).then(responseBody),
    put: (url: string, body: unknown, headers?: any) => axios.put(url, body, headers).then(responseBody),
    del: (url: string, headers?: any) => axios.delete(url, headers).then(responseBody),
    delBod: (url: string, body: unknown) => axios.delete(url, { data: body }).then(responseBody),
    test: async () => {
        try {
            return dummy;
        } catch (error) {
            throw error;
        }
    }
};

const getCleanInstance = () => {
    // RETURNS an instance that does not throw exceptions upon receiving 400 or 500 series responses
    return axios.create({
        validateStatus: function (status) {
            return status >= 100 && status < 599;
        },
        headers: {
            // This is for API
            "access-token": window.localStorage.getItem("jwt"),
            "Jwt-Token": window.localStorage.getItem("jwt")
        }
    });
};

const User = {
    current: (): Promise<IUser> => requests.get("/user"),
    permissions: (): Promise<IUserPermissions> => requests.get("/user_permissions"),
    getEmail: (): Promise<IUser> => requests.get("/user/email"),
    sendSecrect: (token: unknown): Promise<IUser> => requests.post("/google/2fa/store-token", token),
    login: (user: IUserFormValues): Promise<IUser> => requests.post(`/login`, user),
    resetPasswordRequest: (user: IUserFormValues): Promise<IUser> => requests.post("/reset-password", user),
    register: (user: IUserFormValues): Promise<IUser> => requests.post(`/register`, user),
    enable2FA: (user: IUserFormValues): Promise<IEnableTwoFactor> => requests.post("/google/2fa/toggle", user),
    authenticateTwoFactor: (values: IUserFormValues): Promise<IUser> => requests.post("/google/2fa/verify", values),
    requestOrganisation: (user: IUserFormValues): Promise<IUser> => requests.post("/request_to_be_added_to_existing_org", user),
    resetPassword: (user: IUserFormValues): Promise<IUser> => requests.put("/reset-password", user),
    emailVerify: (user: any): Promise<IUser> => requests.put(`/email-confirm`, user),

    resendVerifyEmail: (user: any): Promise<IUser> => requests.put(`/register`, user),
    resendVerifyEmailAlternative: (user: any): Promise<IUser> => requests.put(`/resend-confirmation-email-alternative`, user),

    disableTwoFactor: (body: IUserTwoFactor): Promise<IDisableTwoFactor> => requests.put(`/google/2fa/toggle`, body),
    changePassword: (user: IUserPasswordChange): Promise<IUser> => requests.put(`/change-password`, user),
    isGettingStartedSeen: (): Promise<IUser> => axios.put("/user/mark-getting-started-seen").then(responseBody),
    getGeoInfo: (): Promise<IGeoData> => requests.get("https://extreme-ip-lookup.com/json/"),
    bookDemo: (body: IProspectFormValues): Promise<IProspect> => requests.post("/request-a-demo", body),
    updateUserPreferences: (body: any): Promise<any> => requests.put("/user/preferences", body),
    updateUser: (body: IUpdateUserRequest): Promise<any> => requests.put("/user", body)
};

const Users = {
    getUsersActivity: (request: IActivityLogRequest): Promise<IActivityLogResponse> =>
        requests.get(
            "/activity-log?" +
                (request.page ? `page=${request.page}` : "page=1") +
                (request.transcoder_id ? `&transcoder_id=${request.transcoder_id}` : "") +
                (request.environment_id ? `&environment_id=${request.environment_id}` : "") +
                (request.source_id ? `&source_id=${request.source_id}` : "") +
                (request.destination_id ? `&destination_id=${request.destination_id}` : "") +
                (request.date_from ? `&date_from=${request.date_from}` : "") +
                (request.date_to ? `&date_to=${request.date_to}` : "") +
                (request.search ? `&search=${request.search}` : "") +
                (request.user_email ? `&user_email=${request.user_email}` : "") +
                (request.environment_name ? `&environment_name=${request.environment_name}` : "") +
                (request.transcoder_name ? `&transcoder_name=${request.transcoder_name}` : "") +
                (request.source_name ? `&source_name=${request.source_name}` : "") +
                (request.destination_name ? `&destination_name=${request.destination_name}` : "") +
                (request.action_tag ? `&action_tag=${request.action_tag}` : "") +
                (request.sort ? `&sort=${request.sort}` : "")
        )
};

const Organisation = {
    addUserToOrganisation: (invite: IAddUserToYourOrgRequest): Promise<IAddUserToYourOrgResponse> =>
        requests.post("/organisation/add-user", invite),
    joinOrganisation: (token: IOrganisationConnection): Promise<IUser> => requests.put("/organisations/source-invitation", token),
    getOutstandingInvites: (): Promise<IOrganisationInvites> => requests.get("/organisations/invitation"),
    removeOrganisationInvite: (email: IOrganisationInvite) => requests.post("/organisations/invitation/remove", email),
    createOrganisation: (organisation: IOrganisationFormValues): Promise<IUser> => requests.post("/organisations", organisation),
    list: (): Promise<IOrganisationEnvelope> => axios.get("/organisations").then(responseBody),
    getOrgansisations: (): Promise<IOrganisations> => requests.get("/organisations"),
    getRequests: (id: number): Promise<IRequests> => requests.get(`/requests_to_be_added_to_existing_org/${id}`),
    grantRequest: (request: IRequestActions): Promise<IRequests> =>
        requests.put(`/request_to_be_added_to_existing_org/${request.id}`, request),
    switchActiveOrganisation: (id: SwitchOrganisation): Promise<IUser> => requests.put("organisations/switch_active_organisation", id),
    getManagingOrgList: (): Promise<ManagingOrganisationListResponse> => requests.get("/managing_organisation"),
    getManagingOrgListAdmin: (): Promise<ManagingOrganisationListResponse> => requests.get("/management/organisations"),
    setManagingOrg: (organisation: IChangeManagingOrganisationRequest): Promise<IOrganisation> =>
        requests.put("/managing_organisation", organisation),
    getOrgEnvironments: (organisation_id: number): Promise<IEnvironments> => requests.get(`/management/environments/${organisation_id}`),
    getOrgTranscoders: (organisation_id: number): Promise<any> => requests.get(`/management/source_transcoders/${organisation_id}`),
    toggleMonthlyReporting: (organisation_id: number, report_on: boolean): Promise<any> =>
        requests.put(`/management/organisation/${organisation_id}/monthly_report_state`, { report_on: report_on }),
    getMonthlyReport: (organisation_id: number, month: string): Promise<any> =>
        requests.get(`/billing/monthly-report/${organisation_id}/${month}`),
    switchToAccount: (organisation_id: number): Promise<any> =>
        requests.put(`/management/organisation/${organisation_id}/switch_to_account`, {})
};

const Admin = {
    getUsersByOrganisation: (organisation_id: number): Promise<IUsersInOrg> => requests.get(`/organisation/${organisation_id}/users`),
    updateUserPermissions: (permissions: IUpdateOrgUserPermissions): Promise<IUsers> =>
        requests.put(`organisation/${permissions.organisation_id}/user/${permissions.user_id}/permissions`, permissions),
    deleteUser: (permissions: IUpdateOrgUserPermissions): Promise<IUsers> =>
        requests.del(`/organisation/${permissions.organisation_id}/user/${permissions.user_id}`),
    create: (user: IUser) => requests.post("/register", user),
    update: (user: IUser) => requests.put(`/user/${user.user_id}`, user),
    userDetails: (id: number) => requests.get(`/users/${id}`),
    getRetryMessages: () => requests.get("/management/retry-messages"),
    retryMessage: (message_id: number) => requests.put(`/management/retry-messages/${message_id}`, {}),
    deleteMessage: (message_id: number) => requests.del(`/management/retry-messages/${message_id}`),
    updateOrganisation: (organisation_id: number, request: IUpdateOrganisationRequest) =>
        requests.put(`/organisations/${organisation_id}`, request),
    getOrganisation: (organisation_id: number): Promise<any> => requests.get(`/management/organisation/${organisation_id}`),
    createCustomPrice: (request: ICreateCustomPriceRequest) => requests.post("/billing/pricing/create_custom_price", request),
    updateDefaultPrice: (request: IUpdateDefaultPriceRequest) => requests.put("/billing/pricing/update_default_price", request),
    createCogsReport: (request: ICreateCogsReportRequest): Promise<ICreateCogsReportResponse> => requests.post("/cogs_reports", request),
    getCogsReport: (report_id: number): Promise<any> => requests.get(`/cogs_reports/${report_id}`),
    listModules: (): Promise<any> => requests.get("/management/modules"),
    createModule: (request: ICreateModule) => requests.post("/management/modules", request),
    deleteModule: (module_id: number) => requests.del(`/management/modules/${module_id}`),
    getTfReleaseCandidates: () => requests.get("/management/tf-release-candidates"),
    approveTfReleaseCandidate: (id: number) => requests.put(`/management/tf-release-candidates/${id}`, { action: "approve" }),
    rejectTfReleaseCandidate: (id: number) => requests.put(`/management/tf-release-candidates/${id}`, { action: "reject" })
};

const Scheduler = {
    getInputSchedules: (id: string) => requests.get(`environments/inputs/${id}/schedules`),
    getOutputSchedules: (id: string) => requests.get(`environments/outputs/${id}/schedules`),
    getScheduleInfo: (id: string) => requests.get(`/schedules/${id}`),
    getSourceInputs: (): Promise<IInputSources> => requests.get(`environments/inputs`),
    getSourceOutputs: (): Promise<IOutputSources> => requests.get(`environments/outputs`),
    getSchedules: (): Promise<ISchedules> => requests.get(`schedules`),
    getInputAlerts: (id: string) => requests.get(`/environments/inputs/${id}/alerts`),
    getOutputAlerts: (id: string) => requests.get(`/environments/outputs/${id}/alerts`),
    getAlertMethods: () => requests.get("organisation/alert-methods"),
    createSchedule: (schedule: ISchedulerFormValues): Promise<IScheduler> => requests.post(`/schedules`, schedule),
    updateSchedule: (schedule: any, id: string): Promise<IScheduler> => requests.put(`/schedules/${id}`, schedule),
    createAlertMethod: (body: any) => requests.post(`organisation/alert-methods`, body),
    createAlert: (body: any) => requests.post("environments/alerts", body),
    createPermanentSchedule: (body: ISchedulerFormValues, id: string): Promise<IScheduler> =>
        requests.post(`/environments/outputs/${id}/permanent_schedule`, body),
    updateAlertMethod: (id: string, body: any) => requests.put(`organisation/alert-methods/${id}`, body),
    updateAlert: (id: string, body: any) => requests.put(`environments/alerts/${id}`, body),
    deactivateAlert: (id: string, body: any) => requests.put(`environments/alerts/${id}/state`, body),
    deleteSchedule: (id: string): Promise<IScheduler> => requests.del(`schedules/${id}`),
    deleteAlertMethod: (id: string) => requests.del(`organisation/alert-methods/${id}`),
    deleteAlert: (id: string) => requests.del(`environments/alerts/${id}`),
    getScheduleOverview: (start_date: Date, end_date: Date): Promise<IScheduleOverviewResponse> => {
        return requests.post(`/schedules/overview`, { start_date: start_date, end_date: end_date });
    }
};

const Environment = {
    getProviders: (): Promise<IProviders> => requests.get("/cloud_providers"),
    getRegions: (code: string): Promise<IRegions> => requests.get(`/cloud_providers/${code}/regions`),
    getSizes: (code: string, region_code: string): Promise<ISizes> => requests.get(`/cloud_providers/${code}/region/${region_code}/sizes`),
    createEnvironment: (values: IEnvironmentFormValues): Promise<ICustomerEnvironment> => requests.post(`/customer_environment`, values),
    getEnvironmentStatus: (id: string): Promise<IEnvironmentStatus> => requests.get(`/customer_environment/${id}`),
    getEnvironments: (): Promise<IEnvironments> => requests.get("/environments"),
    getEnvironmentsList: (): Promise<IEnvironmentsListResponse> => requests.get("/environments_list"),
    getEnvironmentDetails: (id: string): Promise<IEnvironmentDetails> => requests.get(`/customer_environment/${id}`),
    switchActiveEnvironment: (id: SwitchEnvironment): Promise<IUser> => requests.put("/environments/switch_active_environment", id),
    getEnviromentUpgradeData: (): Promise<IEnvironmentsUpgradeDetails> => requests.get(`/scheduled_env_upgrades`),
    getEnvironmentUpgradeOptions: (provider: string, region: string): Promise<IEnvironmentsUpgradeTypes> =>
        requests.get(`/cloud_providers/${provider}/region/${region}/sizes`),
    upgradeEnviroment: (id: string, body: IEnvironmentUpgradeBody) => requests.put(`/scheduled_env_upgrades/${id}`, body),
    getEnvironmentsDetails: () => requests.get("/environments"),
    deleteEnvironment: (id: string, body: IEnvironment): Promise<IEnvironment> => requests.delBod(`customer_environment/${id}`, body),
    getSpecificDestinations: (): Promise<IOutputsInEnvironmentsResponse> => requests.get("environments/outputs_in_environments"),
    restartEnvironment: (id: string): Promise<ICustomerEnvironment> => requests.put(`/customer_environment/${id}/do-soft-restart`, id),
    startEnvironment: (env_id: string): Promise<any> => requests.put(`/customer_environment/${env_id}/start`, env_id),
    stopEnvironment: (env_id: string): Promise<any> => requests.put(`/customer_environment/${env_id}/stop`, env_id),
    getIpAddresses: (): Promise<ISavedIPAddresses> => requests.get(`/ip_addresses`),
    deleteIPAddresses: (id: number) => requests.del(`/ip_address/${id}`),
    getGroups: (): Promise<IDashboardGroups> => requests.get("/dashboard_groups"),
    getGroup: (group_id: string): Promise<IDashboardGroup> => requests.get(`/dashboard_groups/${group_id}`),
    createGroup: (body: any) => requests.post(`/dashboard_groups`, body),
    deleteGroup: (group_id: string) => requests.del(`/dashboard_groups/${group_id}`),
    updateGroup: (group_id: string, request: IDasboardGroupUpdRequest): Promise<IDasboardGroupUpdResponse> =>
        requests.put(`/dashboard_groups/${group_id}`, request),
    // https://uat-api.livelink.video:443/api/statistics/instances/66da0c3e-957e-4b33-91b9-19bd16be3dd1/metrics?time_from=2024-05-28T12%3A00%3A00
    getMetrics: (unit_uuid: string, request: IGetEnvironmentMetricsRequest): Promise<IGetEnvironmentMetricsResponse> =>
        requests.get(`/statistics/instances/${unit_uuid}/metrics?time_from=${request.time_from}&time_to=${request.time_to}`, {})
};

const Source = {};

const Multiviewers = {
    deleteLayout: (id: number) => requests.del(`/multi-viewer-layouts/${id}`)
};

// TODO: Move this to source / multiviewer
const Channel = {
    createInput: (input: IInputFormValues): Promise<IInput> => requests.post(`/environments/inputs/${input.type}`, input),
    deleteInput: (id: string) => requests.del(`/environments/inputs/${id}`),
    editInput: (input: IInputFormValues) => requests.put(`/environments/inputs/${input.type}`, input),
    getInput: (input_id: string) => requests.get(`/environments/input/${input_id}`),
    getInputs: (environment_id: string): Promise<IInputs> => requests.get(`/environments/inputs/${environment_id}`),
    getInputsById: (id: string): Promise<IInputs> => requests.get(`/environments/inputs/${id}`),
    getSource: (source_id: string): Promise<{ status: string; data: IInput }> => requests.get(`/environments/source/${source_id}`),
    getInputsDropdownList: (environment_id: string): Promise<IInputDropdownList> =>
        requests.get(`/environments/inputs_list/${environment_id}`),
    getInputImage: (id: string): Promise<IInputImageData> => requests.get(`/environments/inputs/${id}/thumbnail`),
    getInputLiveStats: (id: string): Promise<IInputLiveStatics> => requests.get(`/environments/inputs/${id}/live-stats`),
    createTranscoder: (body: ICreateTranscoder) => requests.post("/source_transcoder", body),
    probeTranscoderAudioTracks: (transcoder_id: string): Promise<ITranscoderTracks> =>
        requests.get(`/source_transcoder/${transcoder_id}/input_probe`),
    deleteTranscoder: (id: string) => requests.del(`/source_transcoder/${id}`),
    getTranscoderData: () => requests.get("/source_transcoder"),
    getSingleTranscoderData: (id: string) => requests.get(`/source_transcoder/${id}`),
    updateTranscoder: (id: string, body: IUpdateTranscoder) => requests.put(`/source_transcoder/${id}`, body),
    updateStandardTranscoder: (id: string, body: IUpdateTranscoder) => requests.put(`/source_transcoder/${id}/transcoding_info`, body),
    updateTranscoderPidList: (id: string, body: IUpdateTranscoderAudioTracks) => requests.put(`/source_transcoder/${id}/track_info`, body),
    stopTranscoder: (id: string) => requests.put(`/source_transcoder/${id}/stop`, id),
    startTranscoder: (id: string) => requests.put(`/source_transcoder/${id}/start`, id),
    resetLiveStats: (output_id: string): Promise<IInputLiveStatics> =>
        requests.put(`/environments/inputs/${output_id}/reset-stats`, output_id),
    resetInputAnalysis: (input_id: string) => requests.put(`/environments/inputs/${input_id}/reset-analysis`, input_id),
    startStoppedInput: (input_id: string): Promise<IInput> =>
        requests.put(`/environments/inputs/${input_id}/start_input_on_c_env`, input_id),
    getPresets: (): Promise<TranscoderPresetsResult> => requests.get("source_transcoder/presets"),
    deletePreset: (id: number) => requests.del(`source_transcoder/presets/${id}`),
    restartTranscoder: (body: IRestartTranscoder) => requests.put(`/source_transcoder/${body.transcoder_id}/service/0/restart`, body),
    getMultiChanLayouts: (): Promise<IMultiChanLayouts> => requests.get("/multi-viewer-layouts"),

    uploadMultiViewerConfig: (file: File): Promise<IUploadXMLResponse> => {
        const formData = new FormData();
        formData.append("filename", file);
        return axios.post("upload-multi-viewer-config", formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },
    confirmMultiViewerLayoutImport: (body: IConfirmXmlImportRequest) => requests.put("/confirm-multi-viewer-layout-import", body),

    createMultiChannel: (values: IMultiChanLayoutForm) => requests.post(`/multi-viewers`, values),
    getMultiViewerPresets: (): Promise<IMultiChanPresetResults> => requests.get("/multi-viewers/presets"),
    deleteMultiChannel: (id: string | undefined) => requests.del(`multi-viewers/${id}`),
    editMultiChannel: (id: string | undefined, values: IMultiChanLayoutForm) => requests.put(`multi-viewers/${id}`, values),
    editMultiChannelSources: (id: string | undefined, values: ITileInputs) =>
        requests.put(`multi-viewers/${id}/update-tile-video-sources`, values),
    getMultiChanInfo: (id: string | undefined) => requests.get(`/multi-viewers/${id}`),
    getMultiChanAudio: (id: string | undefined) => requests.get(`/multi-viewers/${id}/list-audio-tracks`),
    changeMultiChanAudio: (id: string | undefined, values: IListenToPayload) =>
        requests.put(`/multi-viewers/${id}/switch-active-audio-track`, values),
    startInput: (input_id: string) => requests.put(`/environments/inputs/${input_id}/start_input_on_c_env`, {}),
    stopInput: (input_id: string) => requests.put(`/environments/inputs/${input_id}/stop_input_on_c_env`, {}),
    restartInput: (input_id: string) => requests.put(`/environments/inputs/${input_id}/restart_input_on_c_env`, {}),

    fetchInputAnalysisData: (input_id: string) => {
        // TODO - NEED TO DISCUSS ALLOWING ANY RESPONSE CODE. ALSO, NEED TO RESEARCH OTHER CLIENTS.
        // AXIOS MIGHT BE BAD CHOICE. THIS EXAMPLE HOWEVER ALLOWS ME TO GET REGARDLESS WHAT COMES BACK.
        const instance = axios.create({
            validateStatus: function (status) {
                return status >= 100 && status < 599;
            },
            headers: { "access-token": window.localStorage.getItem("jwt") }
        });
        return instance.get(`/environments/inputs/${input_id}/analysis`);
    },

    startAnalysis: (input_id: string, body?: any): Promise<IGenericStatusResponse> =>
        requests.put(`/environments/inputs/${input_id}/start_analysis`, body ? body : {}),
    stopAnalysis: (input_id: string): Promise<IGenericStatusResponse> => requests.put(`/environments/inputs/${input_id}/stop_analysis`, {}),
    updateLatLng: (input_id: string, lat: number | null, lng: number | null) =>
        requests.put(`/environments/inputs/${input_id}/update_lat_long`, { latitude: lat, longitude: lng }),
    updateGroup: (input_id: string, group_id?: string) =>
        requests.put(`/environments/inputs/${input_id}/update_dashboard_group`, group_id ? { group_id: group_id } : {})
};

const Destination = {
    getDestinationsList: (environment_id: string): Promise<IDestinationsListResponse> =>
        requests.get(`/environments/outputs_list/${environment_id}`),
    createOutput: (Output: IOutputFormValues): Promise<IOutput> => requests.post(`/environments/outputs/${Output.type}`, Output),
    getOutputs: (): Promise<IOutputs> => requests.get("/environments/outputs"),
    getDestinationLiveStats: (id: string): Promise<IOutputLiveStatistics> => requests.get(`/environments/outputs/${id}/live-stats`),
    editDestination: (output: IOutputFormValues): Promise<IOutput> =>
        requests.put(`/environments/outputs/${output.type}/${output.output_id}`, output),
    deleteDestination: (id: string): Promise<IOutput> => requests.del(`/environments/outputs/${id}`),
    getOutputStatus: (id: string): Promise<IOutputStatusResponse> => requests.get(`/environments/outputs/${id}/status`),
    resetLiveStats: (input_id: string): Promise<IOutputLiveStatistics> =>
        requests.put(`/environments/outputs/${input_id}/reset-stats`, input_id),
    startOutput: (output_id: string) => requests.put(`/environments/outputs/${output_id}/start_output_on_c_env`, output_id),
    stopOutput: (output_id: string) => requests.put(`/environments/outputs/${output_id}/stop_output_on_c_env`, output_id),
    getIndividualDestination: (output_id: string): Promise<IDestination> => requests.get(`/environments/outputs/${output_id}`),
    getIndividualOutput: (output_id: string): Promise<IOutput> => requests.get(`/environments/outputs/${output_id}`),
    getDestinationsState: (): Promise<IOutputsState> => requests.get(`/environments/outputs/outputs_state`),
    getDestinationState: (output_id: string): Promise<any> => requests.get(`/environments/outputs/outputs_state/${output_id}`),
    updateLatLng: (output_id: string, lat: number | null, lng: number | null) =>
        requests.put(`/environments/outputs/${output_id}/update_lat_long`, { latitude: lat, longitude: lng }),
    updateGroup: (output_id: string, group_id?: string) =>
        requests.put(`/environments/outputs/${output_id}/update_dashboard_group`, group_id ? { group_id: group_id } : {})
};

const Notification = {
    changeNotificationStatus: (id: number) => requests.put(`/notifications/${id}`, id),
    getMutedCategories: () => requests.get("/notifications/muted-categories"),
    muteCategories: (request: IMuteNotificationsRequest) => requests.put("/notifications/mute-categories", request),
    muteUserCategories: (request: IMuteNotificationsRequest) => requests.put("/user/mute-notification-categories", request),
    deleteNotification: (id: string | number) => requests.del(`/notifications/${id}`),
    deleteMultipleNotifications: (ids: Number[]) => requests.del("/notifications/delete-multiple", { data: { notification_ids: ids } }),
    markAsRead: (id: string | number) => requests.put(`/notifications/${id}`, {}),
    markMultipleAsRead: (ids: Number[]) => requests.put("/notifications/mark-read-multiple", { notification_ids: ids }),
    deleteAllNotifications: () => requests.del("/notifications/delete-all")
};

const SourcePermissions = {
    getSourceInvitationToken: (name: IOrganisationSourceInvitation): Promise<IOrganisationSourceInvitationResponse> =>
        requests.post("organisations/source-invitation", name),
    getSourcePermissions: (): Promise<ISourcePermissions> => requests.get("/source-permissions"),
    acceptSourceInvitation: (token: string) => requests.put("/organisations/source-invitation", token),
    updateSourcePermission: (values: ISourcePermission): Promise<ISourcePermission> =>
        requests.put(`/source-permissions/${values.token}`, values),
    addInputSourcePermission: (values: AddInputSourcePermissionRequest, token: string) =>
        requests.post(`/source-permissions/${token}/inputs`, values),
    removeInputSourcePermission: (token: string, sourceId: string) => requests.del(`/source-permissions/${token}/inputs/${sourceId}`),
    addEventSourcePermission: (values: AddEventSourcePermissionRequest, token: string) =>
        requests.post(`/event-access-permissions/${token}`, values),
    removeEventSourcePermission: (token: string, labelId: number) => requests.del(`/event-access-permissions/${token}/${labelId}`),
    getMyGuestOrgs: (): Promise<IMyGuestOrganisations> => requests.get("/organisations-im-guest-off"),
    deleteOrgInvitation: (token: string) => requests.del(`/source-permissions/${token}`),
    addContact: (values: IContactFormValues) => requests.post(`/shared-contacts`, values),
    getContacts: (): Promise<IContacts> => requests.get(`/shared-contacts`),
    updateContact: (values: IContactFormValues, id: number) => requests.put(`/shared-contacts/${id}`, values),
    deleteContact: (id: number) => requests.del(`/shared-contacts/${id}`),
    addTier: (label: string) => requests.post(`/access-permission-labels`, label),
    getTiers: (): Promise<ITiers> => requests.get(`/access-permission-labels`),
    updateTier: (id: number) => requests.del(`/access-permission-labels/${id}`),
    updateMaxDestinations: (values: IMaxDestinations, token: string) =>
        requests.put(`/source-permissions/${token}/max-destinations`, values),
    resetMaxDestinationstoken: (token: string, envId: string) =>
        requests.del(`/source-permissions/${token}/delete-all-destinations/${envId}`),
    updateChannelVisibility: (values: IChannelVisibility, token: string) =>
        requests.put(`/source-permissions/${token}/channel-visibility`, values)
};

const Billing = {
    setCreditQuantity: (quantity: IBillingQuantity): Promise<IBilling> => requests.post("/billing/credits/purchase", quantity),
    customerBillingDetails: (): Promise<IBillingCustomerResponse> => requests.get("/billing/customer_billing_details"),
    updateBillingDetails: (billing: IBillingCustomerInformation): Promise<IBillingCustomerInformation> =>
        requests.put("/billing/customer_billing_details", billing),
    setQuantityData: (quantity: IBillingQuantity): Promise<IBillingCreditsReport> => requests.post("/billing/credits/purchase", quantity),
    getCreditInformation: (): Promise<ICreditResponse> => requests.get("/billing/credits"),
    serviceConsumptionRequest: (dates: IServiceConsumptionRequest): Promise<IServiceConsumptionResponse> =>
        requests.post("/billing/service_consumption", dates),
    getDefaultBillingPrices: (): Promise<IPricing> => requests.get("/billing/pricing/default"),
    getBillingByOrg: (org_id: number): Promise<IPricing> => requests.get(`/billing/pricing/${org_id}`), //this is pricing not billing
    getBillingPrices: (): Promise<IPricing> => requests.get("/billing/pricing"),
    serviceConsumption: (data: IServiceConsumption): Promise<ServiceConsumptionResponseV2> =>
        requests.post("/billing/service_consumption_v2", data),
    serviceConsumptionHourlyBreakdown: (data: IHourlyRequest): Promise<IHourlyBreakDownResponse> =>
        requests.post("/billing/service_consumption_hourly_breakdown", data),
    organisationServiceConsumption: (organisation_id: number, data: IServiceConsumption): Promise<ServiceConsumptionResponseV2> =>
        requests.post(`/management/billing/service_consumption_v2/${organisation_id}`, data),
    organisationServiceConsumptionHourlyBreakdown: (organisation_id: number, data: IHourlyRequest): Promise<IHourlyBreakDownResponse> =>
        requests.post(`/management/billing/service_consumption_hourly_breakdown/${organisation_id}`, data),
    getAvailableBillingMonthlyReports: (organisation_id: number): Promise<any> =>
        requests.get(`/billing/monthly-reports-available/${organisation_id}/billing`),
    getAvailableCogsMonthlyReports: (organisation_id: number): Promise<any> =>
        requests.get(`/billing/monthly-reports-available/${organisation_id}/cogs`),
    getBillingMonthlyReport: (organisation_id: number, month: string): Promise<any> =>
        requests.get(`/billing/historic-monthly-report/${organisation_id}/billing/${month}`),
    getCogsMonthlyReport: (organisation_id: number, month: string): Promise<any> =>
        requests.get(`/billing/historic-monthly-report/${organisation_id}/cogs/${month}`)
};

const PermanentSchedules = {
    getDropDownValues: (): Promise<IPermanentSchedulesDropDownValues> => requests.get("/agreements/dropdown_values_for_price_selectors"),
    getPermanentScheduleTerms: (data: IDropDownSelectors): Promise<IPermanentSchedules> =>
        requests.post("/billing/pricing/permanent_schedules", data),
    getPermanentScheduleCommitments: (): Promise<IPermanentScheduleCommitments> => requests.get("/agreements/permanent_schedules"),
    submitAgreement: (prodId: number, agrmntQty: number): Promise<IPermanentSchedules> =>
        requests.post("/agreements/permanent_schedules", {
            product_id: prodId,
            number_of_agreements: agrmntQty
        })
};

const Alerts = {
    getAlertsOverview: (): Promise<IAlertsListResponse> => requests.get("/environments/alerts"),
    setAlertStatus: (alert_id: string, body: {}) => requests.put(`/environments/alerts/${alert_id}/state`, body),
    getInputOutput: (): Promise<IChanDestResponse> => requests.get("/environments/alerts/inputs-and-outputs"),
    muteAlert: (alert_id: string, unmute_on_recovery: boolean) =>
        requests.put(`/environments/alerts/${alert_id}/state`, { action: "deactivate", unmute_upon_recovery: unmute_on_recovery }),
    muteMultipleAlerts: (alert_ids: string[], unmute_on_recovery: boolean) =>
        requests.put(`/environments/alerts/mute-multiple`, { alert_ids: alert_ids, unmute_upon_recovery: unmute_on_recovery }),
    unMuteAlert: (alert_id: string) => requests.put(`/environments/alerts/${alert_id}/state`, { action: "activate" }),
    unMuteMultipleAlerts: (alert_ids: string[]) => requests.put(`/environments/alerts/unmute-multiple`, { alert_ids: alert_ids }),
    deleteAlert: (alert_id: string) => requests.del(`/environments/alerts/${alert_id}`),
    deleteMultipleAlerts: (alert_ids: string[]) => requests.del(`/environments/alerts/delete-multiple`, { data: { alert_ids: alert_ids } })
};

const UiErrors = {
    submitUiErrors: (body: {}) => requests.post(`/frontend_errors/slack`, body)
};

const FileCluster = {
    getRegions: (cloud_provider_code: string = "aws"): Promise<IFileClusterRegions> =>
        requests.get(`/titanfile/cloud_providers/${cloud_provider_code}/regions`),
    getDeployments: (): Promise<IFileClusterDeployments> => requests.get(`/titanfile/deployment`),
    getDeployment: (deployment_id: string): Promise<IFileClusterDeploymentResponse> =>
        requests.get(`/titanfile/single-deployment/${deployment_id}`),
    getCurrentDeployments: (): Promise<IFileClusterDeployments> => requests.get(`/titanfile/deployment/current`),
    getDeletedDeployments: (): Promise<IFileClusterDeployments> => requests.get(`/titanfile/deployment/deleted`),
    getCredentials: (deployment_id: string): Promise<IFileClusterCredentials> =>
        requests.get(`/titanfile/credentials`, { headers: { "Deployment-Id": deployment_id } }),
    createCredentials: (deployment_id: string, name: string, body: {}) =>
        requests.put(`/titanfile/credentials/${name}`, body, { headers: { "Deployment-Id": deployment_id } }),
    deleteCredentials: (deployment_id: string, name: string): Promise<any> =>
        requests.del(`/titanfile/credentials/${name}`, { headers: { "Deployment-Id": deployment_id } }),
    createDeployment: (body: IFileClusterCreateDeploymentRequest): Promise<IFileClusterCreateDeploymentResponse> =>
        requests.post("/titanfile/deployment", body),
    startDeployment: (deployment_id: string, body: {}) => requests.put(`/titanfile/deployment/${deployment_id}/start`, body),
    stopDeployment: (deployment_id: string, body: {}) => requests.put(`/titanfile/deployment/${deployment_id}/stop`, body),
    upgradeDeployment: (deployment_id: string, body: {}) => requests.put(`/titanfile/deployment/${deployment_id}/upgrade`, body),
    deleteDeployment: (deployment_id: string) => requests.del(`/titanfile/deployment/${deployment_id}`),
    getJobsByDeployment: (
        deployment_id: string,
        offset: any = 0,
        limit: number = 200,
        sort: string = "id:asc",
        category?: string,
        source_filename?: string
    ): Promise<IFileClusterJobs> =>
        requests.get(
            `titanfile/jobs?dont_include_payload=true&offset=${offset}&limit=${limit}&sort=${sort}` +
                (category ? `&category=${category}` : "") +
                (source_filename ? `&source_filename=${source_filename}` : ""),
            { headers: { "Deployment-Id": deployment_id } }
        ),
    deleteJob: (deployment_id: string, job_id: number) =>
        requests.del(`/titanfile/jobs/${job_id}`, { headers: { "Deployment-Id": deployment_id } }),
    setJobPriority: (job_id: number, deployment_id: string, body: {}) =>
        requests.put(`/titanfile/jobs/${job_id}/change_priority`, body, { headers: { "Deployment-Id": deployment_id } }),
    createJob: (deployment_id: string, body: object): Promise<any> =>
        requests.post("/titanfile/jobs", body, { headers: { "Deployment-Id": deployment_id } }),
    startJob: (deployment_id: string, job_id: number): Promise<any> =>
        requests.post(`/titanfile/jobs/${job_id}/start`, undefined, { headers: { "Deployment-Id": deployment_id } }),
    stopJob: (deployment_id: string, job_id: number): Promise<any> =>
        requests.post(`/titanfile/jobs/${job_id}/stop`, undefined, { headers: { "Deployment-Id": deployment_id } }),
    restartJob: (deployment_id: string, job_id: number) =>
        requests.put(`/titanfile/jobs/${job_id}/restart`, {}, { headers: { "Deployment-Id": deployment_id } }),
    getTemplates: (
        deployment_id: string,
        local_only?: boolean,
        offset: number = 0,
        limit: number = 0,
        sort: string = "id:asc"
    ): Promise<IFileClusterTemplates> =>
        requests.get(
            `/titanfile/templates?&offset=${offset}&limit=${limit}&sort=${sort}` + (local_only ? `&local_only=${local_only}` : ""),
            { headers: { "Deployment-Id": deployment_id } }
        ),
    createTemplate: (deployment_id: string, body: {}) =>
        requests.post(`/titanfile/templates`, body, { headers: { "Deployment-Id": deployment_id } }),
    deleteTemplate: (template_id: string) => requests.del(`/titanfile/templates/${template_id}`),
    getJobTemplates: (offset: number = 0, limit: number = 0, sort: string = "id:asc"): Promise<any> =>
        requests.get(`/titanfile/job_templates?&offset=${offset}&limit=${limit}&sort=${sort}`),
    createJobTemplate: (body: any): Promise<any> => requests.post("/titanfile/job_templates", body),
    deleteJobTemplate: (template_id: string) => requests.del(`/titanfile/job_templates/${template_id}`),
    renameCluster: (deployment_id: string, body: { name: string }) => requests.put(`/titanfile/deployment/${deployment_id}`, body),
};

const Reporting = {
    getJobsReport: (body: object): Promise<TFJobCostsListResponse> => requests.post("/titanfile/job_report", body),
    getMtrReportsList: (): Promise<IMtrReportsList> => requests.get("/mtr-reports"),
    generateMtrReport: (environment_id: string, request: IMtrReportRequest): Promise<IMtrReportTicketResponse> =>
        requests.post(`/customer_environment/${environment_id}/start-generating-mtr-report`, request),
    getMtrReport: (report_id: string): Promise<IMTRReportResponse> => requests.get(`/mtr-reports/${report_id}`),
    deleteMtrReport: (report_id: string) => requests.del(`/mtr-reports/${report_id}`),
    createMtrGroup: (request: ICreateMTRHostGroupRequest): Promise<ICreateMTRHostGroupResponse> =>
        requests.post(`/mtr-report-host-groups`, request),
    createMtrGroupHost: (mtr_host_group_id: number, request: IAddMTRHostToGroupRequest): Promise<IAddMTRHostToGroupResponse> =>
        requests.post(`/mtr-report-host-group/${mtr_host_group_id}/add-host`, request),
    getMtrGroupsList: (): Promise<IMtrGroupsListResponse> => requests.get("/mtr-report-host-groups"),
    deleteMtrHost: (mtr_host_id: number) => requests.del(`/mtr-report-host/${mtr_host_id}`),
    deleteMtrHostGroup: (mtr_host_group_id: number) => requests.del(`/mtr-report-host-group/${mtr_host_group_id}`),
    editMtrHostGroup: (mtr_host_group_id: number, request: any) => requests.put(`/mtr-report-host-group/${mtr_host_group_id}`, request),
    getMtrHostsList: (): Promise<IMtrHostsListResponse> => requests.get("/mtr-report-hosts")
};

const Packager = {
    createPushPackager: (body: CreatePushPackagerRequest): Promise<any> => requests.post("/push_packagers", body),
    updatePushPackager: (id: string, body: UpdatePushPackagerRequest): Promise<any> => requests.put(`/push_packagers/${id}`, body),
    deletePushPackager: (id: string): Promise<any> => requests.del(`/source_transcoder/${id}`),
    startPushPackager: (id: string): Promise<any> => requests.put(`/source_transcoder/${id}/start`, {}),
    stopPushPackager: (id: string): Promise<any> => requests.put(`/source_transcoder/${id}/stop`, {}),
    getPackagerPresets: (type: string): Promise<any> => requests.get(`/packager-presets/${type}`),
    deletePackagerPreset: (type: string = "push", id: number): Promise<any> => requests.del(`/packager-presets/${type}/${id}`),
    resetAlarms: (id: string): Promise<any> => requests.put(`/source_transcoder/${id}/reset-alarms`, {}),
    listFileServers: (): Promise<any> => requests.get("/source_transcoder/file_servers"),
    createFileServer: (body: CreateFileServerRequest): Promise<any> => requests.post("/source_transcoder/file_servers", body),
    updateFileServer: (id: string, body: UpdateFileServerRequest): Promise<any> =>
        requests.put(`/source_transcoder/file_servers/${id}`, body),
    deleteFileServer: (id: string): Promise<any> => requests.del(`/source_transcoder/file_servers/${id}`),
    //     /api/source_transcoder/<string:transcoder_id>/service/<string:service_uid>/stop
    //  /api/source_transcoder/<string:transcoder_id>/service/<string:service_uid>/start
    stopService: (transcoder_id: string): Promise<any> => requests.put(`/source_transcoder/${transcoder_id}/service/0/stop`, {}),
    startService: (transcoder_id: string): Promise<any> => requests.put(`/source_transcoder/${transcoder_id}/service/0/start`, {})
};

const NeaLive = {
    //Instances
    getNeaLiveProducts: (provider: string, region: string): Promise<NeaLiveSizesResponse> =>
        requests.get(`/pull_packager/sizes/cloud_provider/${provider}/region/${region}`),
    createNeaLive: (body: CreateNeaLiveRequest): Promise<CreateNeaLiveResponse> => requests.post("/pull_packager", body),
    deleteNeaLive: (id: string): Promise<any> => requests.del(`/pull_packager/${id}`),
    renameNeaLive: (id: string, body: RenameNeaLiveRequest): Promise<any> => requests.put(`/pull_packager/${id}`, body),
    stopNeaLive: (id: string): Promise<any> => requests.put(`/pull_packager/${id}/stop`, {}),
    startNeaLive: (id: string): Promise<any> => requests.put(`/pull_packager/${id}/start`, {}),
    getNeaLive: (id: string): Promise<NeaLiveResponse> => requests.get(`/pull_packager/${id}`),
    getNeaLiveStats: (id: string): Promise<any> => requests.get(`/pull_packager/${id}/stats`),

    //family
    getStreamAdaptationFamilies: (): Promise<any> => requests.get(`/pull_packager/families`),
    createStreamAdaptationFamily: (body: CreateStreamAdaptationFamilyRequest): Promise<any> =>
        requests.post(`/pull_packager/families`, body),
    updateStreamAdaptationFamily: (id: string, body: UpdateStreamAdaptationFamilyRequest): Promise<any> =>
        requests.put(`/pull_packager/families/${id}`, body),
    deleteStreamAdaptationFamily: (id: number): Promise<any> => requests.del(`/pull_packager/families/${id}`),

    //adaptations
    getStreamAdaptations: (): Promise<any> => requests.get(`/pull_packager/adaptations`),
    createStreamAdaptation: (body: CreateStreamAdaptationRequest): Promise<any> => requests.post(`/pull_packager/adaptations`, body),
    updateStreamAdaptation: (id: string, body: CreateStreamAdaptationRequest): Promise<any> =>
        requests.put(`/pull_packager/adaptations/${id}`, body),
    addStreamAdaptationToFamily: (family_id: number, adaptation_id: number): Promise<any> =>
        requests.post(`/pull_packager/families/${family_id}/add/${adaptation_id}`, {}),
    removeStreamAdaptationFromFamily: (family_id: number, adaptation_id: number): Promise<any> =>
        requests.del(`/pull_packager/families/${family_id}/remove/${adaptation_id}`),
    deleteStreamAdaptation: (id: number): Promise<any> => requests.del(`/pull_packager/adaptations/${id}`),

    //Transcoders
    addSourceToFamily: (family_id: number, source_id: string): Promise<any> =>
        requests.put(`/pull_packager/families/${family_id}/add_transcoder/${source_id}`, {}),
    removeSourceFromFamily: (family_id: number, source_id: string): Promise<any> =>
        requests.del(`/pull_packager/families/${family_id}/delete_transcoder/${source_id}`),
    createPullPackagerSource: (body: any): Promise<any> => requests.post("/pull_packager/transcoders", body),
    updatePullPackagerSource: (id: string, body: any): Promise<any> => requests.put(`/pull_packager/transcoders/${id}`, body),
    getPullPackagerSources: (nea_live_id: string): Promise<any> => requests.get(`/pull_packager/${nea_live_id}/transcoders`),
    startPullPackagerSource: (id: string): Promise<any> => requests.put(`/pull_packager/transcoders/${id}/start`, {}),
    stopPullPackagerSource: (id: string): Promise<any> => requests.put(`/pull_packager/transcoders/${id}/stop`, {}),
    deletePullPackagerSource: (id: string): Promise<any> => requests.del(`/pull_packager/transcoders/${id}`),
    getAvailablePullPackagerEnvironments: (nea_live_id: string): Promise<any> => requests.get(`/pull_packager/${nea_live_id}/environments`),
    getTranscoder: (id: string): Promise<any> => requests.get(`/pull_packager/transcoders/${id}`),
    restartTranscoder: (id: string) => requests.put(`/source_transcoder/${id}/service/0/restart`, {}),

    //Scrambling
    listScramblingPresets: (): Promise<any> => requests.get(`/pull_packager/scrambling`),
    getScramblingPreset: (id: number): Promise<any> => requests.get(`/pull_packager/scrambling/${id}`),
    createScramblingPreset: (body: CreateScramblingPresetRequest): Promise<any> => requests.post(`/pull_packager/scrambling`, body),
    updateScramblingPreset: (id: number, body: CreateScramblingPresetRequest): Promise<any> =>
        requests.put(`/pull_packager/scrambling/${id}`, body),
    deleteScramblingPreset: (id: number): Promise<any> => requests.del(`/pull_packager/scrambling/${id}`)
};

const ReleaseNotes = {
    getReleaseNotes: (page: number): Promise<IReleaseNotesResponse> => requests.get(`/deployment-log?page=${page}`),
    getAppReleaseNotes: (page: number, app: string): Promise<IReleaseNotesResponse> =>
        requests.get(`/deployment-log?page=${page}&app=${app}`),
    getUIReleaseNotes: (page: number): Promise<IReleaseNotesResponse> => requests.get(`/deployment-log?app=ui&page=${page}`),
    updateReleaseNotes: (id: number, body: IReleaseNotesPost): Promise<IReleaseNotesPostResponse> =>
        requests.put(`/deployment-log/${id}`, body),
    deleteReleaseNotes: (id: string): Promise<any> => requests.del(`/deployment-log/${id}`)
};

const OwnCloud = {
    adminEnableOwnCloud: (request: IEnableOwnCloudRequest): Promise<any> =>
        requests.put(`/own_cloud/admin/${request.organisation_id}/enable/${request.cloud_provider}`, {}),
    adminDisableOwnCloud: (request: IEnableOwnCloudRequest): Promise<any> =>
        requests.put(`/own_cloud/admin/${request.organisation_id}/disable/${request.cloud_provider}`, {}),
    enableOwnCloud: (cloud_provider: string): Promise<any> => requests.put(`/own_cloud/${cloud_provider}/enable`, {}),
    disableOwnCloud: (cloud_provider: string): Promise<any> => requests.put(`/own_cloud/${cloud_provider}/disable`, {}),
    validateCredentials: (request: IValidateCredentialsRequest, cloud_provider: string) =>
        requests.put(`/own_cloud/${cloud_provider}/validate-credentials`, request),
    getRegions: (cloud_provider: string) => requests.get(`/own_cloud/${cloud_provider}/regions`),
    setRegions: (request: ISetRegionsRequest, cloud_provider: string) => requests.put(`/own_cloud/${cloud_provider}/regions`, request),
    getLimits: (request: IGetLimitsRequest): Promise<IGetLimitsResponse> => requests.get(`/own_cloud/limits`, request),
    setLimits: (request: ISetLimitsRequest): Promise<any> => requests.put(`/own_cloud/limits`, request),
    getLimitsMetricsAll: (): Promise<any> => requests.get(`/statistics/own_cloud/metrics`),
    getLimitsMetrics: (params: IGetMetricsRequest): Promise<any> => {
        const baseUrl = "/statistics/own_cloud/metrics";
        const queryParams = new URLSearchParams();

        if (params.cloud_provider) {
            queryParams.append("cloud_provider", params.cloud_provider);
        }
        if (params.region) {
            queryParams.append("region", params.region);
        }
        if (params.limit_name) {
            queryParams.append("limit_name", params.limit_name);
        }
        if (params.api_key) {
            queryParams.append("api_key", params.api_key);
        }
        if (typeof params.start_date === "object" && params.start_date) {
            queryParams.append("datetime_from", params.start_date.toISOString());
        }
        if (typeof params.end_date === "object" && params.end_date) {
            queryParams.append("datetime_to", params.end_date.toISOString());
        }

        const url = `${baseUrl}?${queryParams.toString()}`;
        return requests.get(url);
    },
    deleteLimit: (limit_id: number): Promise<any> => requests.del(`/own_cloud/limits/${limit_id}`),
    getLimitTypes: (): Promise<IGetLimitTypesResponse> => requests.get(`/own_cloud/limits/types?cloud_provider=aws`),
    getPolicyDocs: (): Promise<IGetPolicyDocsResponse> => requests.get(`/own_cloud/aws/policy-document`)
};

const articles_domain = "articles.livelink.video";
const KnowledgeHub = {
    getAll: (return_extra_fields?: string): Promise<IGetKnowledgeHubResponse> => {
        return requests.get(
            `https://${articles_domain}/articles?return_extra_fields=${return_extra_fields ? `,${return_extra_fields}` : "title"}`
        );
    },
    getAllByCategory: (category: string, return_extra_fields?: string): Promise<IGetKnowledgeHubResponse> => {
        return requests.get(
            `https://${articles_domain}/articles?category=${category}&return_extra_fields=${
                return_extra_fields ? `,${return_extra_fields}` : "title"
            }`
        );
    },
    getArticle: (id: string): Promise<any> => requests.get(`https://${articles_domain}/articles/${id}`),
    updateArticle: (id: string, body: any): Promise<any> => requests.put(`https://${articles_domain}/articles/${id}`, body),
    createArticle: (body: any): Promise<any> => requests.post(`https://${articles_domain}/articles`, body),
    deleteArticle: (id: string): Promise<any> => requests.del(`https://${articles_domain}/articles/${id}`),
    listMedia: (): Promise<any> => requests.get(`https://${articles_domain}/list-media-bucket-content`)
};

export const agent = {
    Admin,
    Alerts,
    Billing,
    Channel,
    Destination,
    Environment,
    FileCluster,
    NeaLive,
    Notification,
    Organisation,
    Packager,
    PermanentSchedules,
    Reporting,
    Scheduler,
    SourcePermissions,
    UiErrors,
    User,
    Users,
    ReleaseNotes,
    OwnCloud,
    KnowledgeHub,
    Multiviewers,
    getCleanInstance
};

export default agent;
