import { RootStore } from "./rootStore";
import { action, makeObservable } from "mobx";
import { agent } from "api";

export class ErrorStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action submitUiErrors = async (message: string, error: string) => {
        await agent.UiErrors.submitUiErrors({ error: error, message: message }).catch((error) => {
            throw error;
        });
    };
}

export default ErrorStore;
