import { Button } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { RootStoreContext } from "api";
import CreditQuantityForm from "./creditsPurchase/CreditQuantityForm";
import PayOnAccountForm from "./payOnAccount/PayOnAccountForm";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const BillingInformationButton = () => {
    const rootStore = useContext(RootStoreContext);
    const { openDrawer } = rootStore.drawerStore;

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={() => openDrawer(<PayOnAccountForm />)}
                startIcon={<AddCircleIcon />}
                style={{ marginRight: 10 }}>
                Pay by Invoice
            </Button>
            <Button
                variant="contained"
                color="secondary"
                title="Add Credits to your organisation"
                onClick={() => openDrawer(<CreditQuantityForm />)}
                startIcon={<AddCircleIcon />}>
                Add Credits
            </Button>
        </Fragment>
    );
};
