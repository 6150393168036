import { action, computed, observable, runInAction, makeObservable } from "mobx";
import {
    agent,
    IGeoData,
    IProspectFormValues,
    IUser,
    IUserFormValues,
    IUserPasswordChange,
    IUserPermissions,
    IUserTwoFactor,
    IOrganisation
} from "api";
import { RootStore } from "./rootStore";
//import { resClientStore } from "./resClientStore";
import zxcvbn from "zxcvbn";
import jwt_decode from "jwt-decode";

export default class UserStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @observable user: IUser | null = null;
    @observable userPermissions: IUserPermissions | null = null;
    @observable activeorganisationId = 0;
    @observable activeenvironment: string | null = "";
    @observable geoData: IGeoData | null = null;

    @action setActiveorganisationId = (id: number) => {
        this.activeorganisationId = id;
    };

    @computed
    get managingOrganisationId(): any {
        let decoded: any;
        if (this.rootStore.commonStore.token) {
            decoded = jwt_decode<IUser>(this.rootStore.commonStore.token);
            if (decoded && decoded.managing_organisation_id) {
                return decoded.managing_organisation_id;
            }
        }
        return undefined;
    }

    @computed
    get isLoggedIn() {
        return this.user && this.user.needs_to_enter_2fa !== true;
    }

    @computed
    get activeOrganisation() {
        return this.activeorganisationId;
    }

    @computed
    get activeEnvironment() {
        return this.activeenvironment;
    }

    @action getGeoInformation = async () => {
        try {
            const data = await agent.User.getGeoInfo();
            runInAction(() => {
                this.geoData = data;
            });
            return data;
        } catch (error) {
            throw error;
        }
    };

    @action login = async (values: IUserFormValues) => {
        try {
            const login_result = await agent.User.login(values);
            this.rootStore.commonStore.setToken(login_result.jwt_token);
            return login_result;
        } catch (error) {
            throw error;
        }
    };

    @action updateUserPreferences = async (values: any) => {
        await agent.User.updateUserPreferences(values)
            .then((response) => {
                runInAction(() => {
                    this.getUser();
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    @action loadUserWithPermissions = async () => {
        //This allows me to catch the 401. where the current axios implementation does not
        agent
            .getCleanInstance()
            .get("/user")
            .then((response) => {
                if (response.status === 200 || response.status === 401) {
                    runInAction(() => {
                        if (response.status === 401 && response.data.status === "jwt_expired") {
                            // JWT expired. Let's clear everything and push them to homepage
                            this.logout();
                        } else {
                            // We have some data for the user
                            this.user = response.data;
                            this.rootStore.commonStore.token && this.rootStore.resClientStore.initialize(this.rootStore.commonStore.token);
                            this.activeorganisationId = response.data.active_org;
                            this.activeenvironment = response.data.active_environment_id;
                            if (response.status === 401 && response.data.status === "2fa_missing") {
                                this.user!.needs_to_enter_2fa = true;
                            } else {
                                this.user!.needs_to_enter_2fa = false;
                            }
                        }
                    });

                    if (response.status === 200) {
                        // If user is good, we can load permissions
                        agent.User.permissions().then((values) => {
                            runInAction(() => {
                                this.userPermissions = values;
                            });
                        });
                    }

                    //  return {"status": "jwt_expired", "message": "JWT expired"}, 401
                } else {
                    throw new Error(`Unexpected response code ${response.status} when getting user`);
                }
            });

        return "ok";
    };

    @action loginTwoFactor = async (values: IUserFormValues) => {
        try {
            const response = await agent.User.authenticateTwoFactor(values);
            this.rootStore.commonStore.setToken(response.jwt_token);
            // When token changes - app calls load user with permissions
            return response;
        } catch (error) {
            throw error;
        }
    };

    @action resetPasswordRequest = async (values: IUserFormValues) => {
        const user = await agent.User.resetPasswordRequest(values);
        const params = new URLSearchParams();
        const email = params.get("token");
        const token = params.get("email");
        if (email && token) {
            user.email = email;
            user.token = token;
        }
        //history.push("/");
        return user;
    };

    @action verifyEmail = async (values: any) => {
        const user = await agent.User.emailVerify(values);
        runInAction(() => {
            this.rootStore.commonStore.setToken(user.jwt_token);
        });
        return user;
    };

    resendEmail = async (value: string) => {
        await agent.User.resendVerifyEmail({ email: value });
    };

    resendEmailAlternative = async (value: string) => {
        await agent.User.resendVerifyEmailAlternative({ email: value });
    };

    @action disableTwoFactor = async (values: IUserTwoFactor) => {
        try {
            return await agent.User.disableTwoFactor(values);
        } catch (error) {
            throw error;
        }
    };

    @action sendGoogleToken = async (secret: string) => {
        const data = await agent.User.sendSecrect({ token: secret });
        return data;
    };

    @action enable2FA = async (values: IUserFormValues) => await agent.User.enable2FA(values);

    @action getUser = async () => {
        const user = await agent.User.current();
        runInAction(() => {
            this.user = user;
            this.activeorganisationId = user.active_org;
            if (user.active_environment_id) {
                this.activeenvironment = user.active_environment_id;
            }
        });
    };

    @action getUserPermissions = async () => {
        const userPermissions = await agent.User.permissions();
        runInAction(() => {
            this.userPermissions = userPermissions;
        });
    };

    @action logout = () => {
        this.rootStore.resClientStore.client.disconnect();
        this.rootStore.commonStore.setToken(null);
        runInAction(() => {
            this.rootStore.resClientStore.client.disconnect();
            this.activeenvironment = "";
            this.user = null;
            this.userPermissions = null;
            this.rootStore.sourcesStore.Inputs = null;
            this.rootStore.sourcesStore.EnvironmentInputs = null;
            this.rootStore.destinationStore.outputs = null;
            this.rootStore.destinationStore.filteredOutputs = null;
            this.rootStore.billingStore.selectedEnvironment = undefined;
            this.rootStore.billingStore.selectedDestination = undefined;
            this.rootStore.fileClusterStore.activeDeploymentId = "";
            this.rootStore.fileClusterStore.activeDeploymentName = "";
            this.rootStore.fileClusterStore.activeDeploymentStatus = "";
            this.rootStore.fileClusterStore.deployments = null;
        });
        localStorage.clear();
        //history.push("/");
    };
}
