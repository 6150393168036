import React, { useContext } from "react";
import { RootStoreContext } from "api";
import { Box, Button, Typography } from "@mui/material";

export const EmailSent = () => {
    const rootStore = useContext(RootStoreContext);
    const { closeDrawer } = rootStore.drawerStore;
    return (
        <Box>
            <Typography variant="h3" sx={{ mb: 2 }}>
                Request Sent
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
                A member of the accounts team will be in touch shortly on the details provided. Please note that business hours are 9am to
                5pm GMT Monday to Friday, if the request is submitted outside of these hours please be patient and a member of the team will
                deal with the request when possible.
            </Typography>
            <Button
                onClick={() => {
                    closeDrawer();
                }}>
                Close
            </Button>
        </Box>
    );
};
