import { Alert, AlertTitle, Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { agent, IOrganisationFormValues, RootStoreContext } from "api";

interface Props {
    onSubmitted: () => void;
}

const CreateOrganisationForm: React.FC<Props> = observer(function CreateOrganisationForm(props) {
    const rootStore = React.useContext(RootStoreContext);

    const { closeDialog } = rootStore.dialogStore;

    const [errors, setErrors] = React.useState<any>();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            name: { value: string };
        };

        const request: IOrganisationFormValues = {
            name: target.name.value
        };

        await agent.Organisation.createOrganisation(request)
            .then(() => {
                setErrors(undefined);
                props.onSubmitted();
            })
            .catch((error: any) => {
                setErrors(error);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <TextField name="name" id="name" label="Name" required error={errors?.data?.errors?.name} />
                {errors && errors.data && errors.data.message && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        <AlertTitle>{errors.data.message}</AlertTitle>
                        {errors.data.errors?.name && errors.data.errors.name}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button type="submit" variant="contained" color="primary">
                    Create Organisation
                </Button>
            </DialogActions>
        </form>
    );
});

export default CreateOrganisationForm;
