import React, { useContext, useState } from "react";
import { IUserInOrg, RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { Alert, AlertTitle, Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAdmin } from "hooks";

interface IProps {
    user: IUserInOrg;
}

export const UsersDeleteConfirm: React.FC<IProps> = observer(function UsersDeleteConfirm({ user }) {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { user: activeUser } = rootStore.userStore;
    const { loadUsers, deleteUser } = useAdmin();
    const [error, setError] = useState("");

    const handleDeleteUser = () => {
        deleteUser({ organisation_id: activeUser!.managing_organisation_id!, user_id: user.id })
            .then(() => {
                loadUsers(activeUser!.managing_organisation_id!).then(() => {
                    closeDialog();
                });
            })
            .catch((error) => {
                setError(error.data.message);
            });
    };

    return (
        <>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <Box>Are you sure you want to delete user "{user.email}" ?</Box>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleDeleteUser}>
                    Delete
                </Button>
            </DialogActions>
        </>
    );
});
