import { agent, IBillingCustomerInformation, IBillingQuantity, IHourlyRequest, IUpdateOrgUserPermissions, RootStoreContext } from "api";
import { format } from "date-fns";
import { useState, useCallback, useContext } from "react";

export const useBilling = () => {
    const rootStore = useContext(RootStoreContext);
    const { setUsers } = rootStore.adminStore;
    const [isLoading, setIsLoading] = useState(false);
    const {
        convertedStart,
        convertedEnd,
        selectedEnvironment,
        hourlyBreakdownData,
        billingData,
        billingInformation,
        setConvertedEnd,
        setConvertedStart,
        setHourlyBreakdownData,
        setBillingData,
        setBillingInformation,
        setSelectedEnvironment: setSelectedEnvironmentValue
    } = rootStore.billingStore;

    const getHourlyBreakdown = useCallback(async (data: IHourlyRequest, thenFunc?: (response) => void) => {
        try {
            const hourlyBreakdown = await agent.Billing.serviceConsumptionHourlyBreakdown(data);
            setHourlyBreakdownData(hourlyBreakdown);

            if (thenFunc) {
                thenFunc(hourlyBreakdown);
            }

            return hourlyBreakdown;
        } catch (error) {
            throw error;
        }
    }, []);

    const getBillingInformaiton = useCallback(() => {
        try {
            const billingRsponse = agent.Billing.customerBillingDetails();
            return billingRsponse;
        } catch (error) {
            throw error;
        }
    }, []);

    const updateBillingInfo = useCallback((billing: IBillingCustomerInformation) => {
        try {
            const data = agent.Billing.updateBillingDetails(billing);
            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const setCreditData = useCallback(async (quantity: IBillingQuantity) => {
        try {
            const response = await agent.Billing.setQuantityData(quantity);
            setBillingData(response);
            return response;
        } catch (error) {
            throw error;
        }
    }, []);

    const getCreditsInformation = useCallback(async () => {
        try {
            const information = await agent.Billing.getCreditInformation();
            setBillingInformation(information);
        } catch (error) {
            throw error;
        }
    }, []);

    const setSelectedEnvironment = useCallback(async (environement_id: string) => {
        setSelectedEnvironmentValue(environement_id);
        if (environement_id === "None") {
            setSelectedEnvironmentValue(undefined);
        }
    }, []);

    return {
        isLoading,
        getHourlyBreakdown,
        getBillingInformaiton,
        updateBillingInfo,
        setCreditData,
        getCreditsInformation,
        setSelectedEnvironment
    };
};
