import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "api";
import { CreateItemSuccess } from "components";

interface ICreatePackagerSuccess {
    packager_id: string;
    mode: "create" | "edit";
    values: any;
}

export const CreatePackagerSuccess: React.FC<ICreatePackagerSuccess> = ({ packager_id, mode, values }) => {
    const rootStore = useContext(RootStoreContext);
    const { client } = rootStore.resClientStore;
    const [wsData, setWsData] = React.useState<any>(null);
    const [updateDate, setUpdateDate] = React.useState<Date | null>(null);
    const [retries, setRetries] = React.useState<number>(0);

    const getWsData = async () => {
        const rid = `pushpackagers.${rootStore.userStore.managingOrganisationId!}.packager.${packager_id}`;
        await client
            .get(rid)
            .then((res) => {
                console.log("ws res", res);
                res.on("change", () => {
                    setUpdateDate(new Date());
                });
                setWsData(res);
            })
            .catch((err) => {
                console.log("Error", err);
                if (retries < 5) {
                    setTimeout(() => {
                        setRetries(retries + 1);
                        getWsData();
                    }, 1000);
                }
            });
    };

    useEffect(() => {
        getWsData();
    }, [updateDate, packager_id]);

    return (
        <CreateItemSuccess
            mode={mode}
            type={"Push Packager"}
            name={values?.name}
            progressStatus={wsData?.progress_status || ""}
            currentStep={wsData?.current_step || 0}
            totalSteps={wsData?.number_of_steps || 0}
            retries={retries}
            onRetry={() => getWsData()}
        />
    );
};

export default CreatePackagerSuccess;
