import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { RootStoreContext } from "api";
import { ConfirmAgreement } from "../confirm/ConfirmAgreement";
import { DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import InfoItem from "components/InfoItem";

interface IProps {
    productId: number;
    currency: string;
    totalPerDayCost: (string | undefined)[] | undefined;
    totalPerTermCost: (string | undefined)[] | undefined;
    agreementQty: number;
}

export const TermsAndCommitments: React.FC<IProps> = ({ productId, currency, totalPerDayCost, totalPerTermCost, agreementQty }) => {
    const rootStore = useContext(RootStoreContext);
    const { commitmentValues } = rootStore.permanentScheduleStore;
    const { closeDialog, openDialog } = rootStore.dialogStore;

    return (
        <>
            <DialogTitle>Terms and Conditions</DialogTitle>
            <DialogContent>
                <Typography mb={3}>
                    Please carefully read the below Terms, once commited you enter a minimum commitment of the period selected, you cannot
                    change or delete this commitment until the minumum term is finished. You will be billed regardless of if this commitment
                    is attached to a destination or not. Once attached to a destination the egress cost is replace with the rate agreed
                    below.
                </Typography>
                <Typography mb={3}>
                    {commitmentValues?.prices.filter((pr) => pr.product_id === productId).map(({ agreement_wording }) => agreement_wording)}
                </Typography>
                <InfoItem
                    title="Cloud provider"
                    value={commitmentValues?.prices.filter((pr) => pr.product_id === productId).map(({ cloud_provider }) => cloud_provider)}
                />
                <InfoItem
                    title="Bitrate"
                    value={commitmentValues?.prices.filter((pr) => pr.product_id === productId).map(({ bitrate_mbps }) => bitrate_mbps)}
                />
                <InfoItem
                    title="Minimum term"
                    value={commitmentValues?.prices
                        .filter((pr) => pr.product_id === productId)
                        .map(({ commitment_days }) => commitment_days)}
                />
                <InfoItem title="Daily cost" value={`${currency}${totalPerDayCost}`} />
                <InfoItem title="Total commitment over time" value={`${currency}${totalPerTermCost}`} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog()}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeDialog();
                        openDialog(
                            <ConfirmAgreement
                                productId={productId}
                                currency={currency}
                                totalPerTermCost={totalPerTermCost}
                                totalPerDayCost={totalPerDayCost}
                                agreementQty={agreementQty}
                            />
                        );
                    }}>
                    Next
                </Button>
            </DialogActions>
        </>
    );
};
