import React from "react";
import { FileServer } from "..";
import { Form } from "react-final-form";
import { SelectField, TextField } from "components/Forms";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { agent, RootStoreContext } from "api";
import { FORM_ERROR } from "final-form";
import { LoadingButton } from "@mui/lab";

interface FileServerFormProps {
    mode: "create" | "edit";
    fileServer?: FileServer;
    onSuccess: () => void;
}

const FileServerForm: React.FC<FileServerFormProps> = ({ mode, fileServer, onSuccess }) => {
    const rootStore = React.useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const initialValues = fileServer
        ? {
              name: fileServer.name,
              type: fileServer.type,
              region: fileServer.region,
              access_key: fileServer.access_key,
              bucket_name: fileServer.bucket_name
          }
        : {
              name: "",
              type: "",
              region: "",
              access_key: "",
              bucket_name: ""
          };

    return (
        <Form
            onSubmit={(values: any) => {
                console.log(values);
                if (mode === "edit" && fileServer && fileServer.id) {
                    return agent.Packager.updateFileServer(fileServer.id, values)
                        .then(() => {
                            onSuccess();
                            closeDialog();
                        })
                        .catch((error) => {
                            console.log("error", error);
                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                } else {
                    return agent.Packager.createFileServer(values)
                        .then(() => {
                            onSuccess();
                            closeDialog();
                        })
                        .catch((error) => {
                            console.log("error", error);
                            const errors = error.data.errors;
                            return { [FORM_ERROR]: error, ...errors };
                        });
                }
            }}
            initialValues={initialValues}
            render={({ handleSubmit, invalid, submitting, dirtySinceLastSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{mode === "create" ? "Create" : "Edit"} File Server</DialogTitle>
                    <DialogContent>
                        <TextField
                            name="name"
                            label="Name"
                            required
                            helperText={() => {
                                return "Name of the file server. This is for internal use only.";
                            }}
                            validate={(value) => {
                                if (!value) {
                                    return "Name is required";
                                }
                            }}
                        />
                        <SelectField
                            name="type"
                            label="Type"
                            initialValue="s3"
                            options={[{ text: "S3", value: "s3" }]}
                            required
                            validate={(value) => {
                                if (!value) {
                                    return "Type is required";
                                }
                            }}
                        />
                        <SelectField
                            name="region"
                            label="Region"
                            options={[
                                { text: "us-east-1", value: "us-east-1" },
                                { text: "us-east-2", value: "us-east-2" },
                                { text: "us-west-1", value: "us-west-1" },
                                { text: "us-west-2", value: "us-west-2" },
                                { text: "af-south-1", value: "af-south-1" },
                                { text: "ap-east-1", value: "ap-east-1" },
                                { text: "ap-south-1", value: "ap-south-1" },
                                { text: "ap-northeast-3", value: "ap-northeast-3" },
                                { text: "ap-northeast-2", value: "ap-northeast-2" },
                                { text: "ap-southeast-1", value: "ap-southeast-1" },
                                { text: "ap-southeast-2", value: "ap-southeast-2" },
                                { text: "ap-northeast-1", value: "ap-northeast-1" },
                                { text: "ca-central-1", value: "ca-central-1" },
                                { text: "eu-central-1", value: "eu-central-1" },
                                { text: "eu-west-1", value: "eu-west-1" },
                                { text: "eu-west-2", value: "eu-west-2" },
                                { text: "eu-south-1", value: "eu-south-1" },
                                { text: "eu-west-3", value: "eu-west-3" },
                                { text: "eu-north-1", value: "eu-north-1" },
                                { text: "me-south-1", value: "me-south-1" },
                                { text: "sa-east-1", value: "sa-east-1" },
                                { text: "ap-south-2", value: "ap-south-2" },
                                { text: "ap-southeast-3", value: "ap-southeast-3" },
                                { text: "ap-southeast-4", value: "ap-southeast-4" },
                                { text: "ca-west-1", value: "ca-west-1" },
                                { text: "eu-south-2", value: "eu-south-2" },
                                { text: "eu-central-2", value: "eu-central-2" },
                                { text: "me-central-1", value: "me-central-1" },
                                { text: "il-central-1", value: "il-central-1" }
                            ]}
                            required
                            validate={(value) => {
                                if (!value) {
                                    return "Region is required";
                                }
                            }}
                        />
                        <TextField
                            name="access_key"
                            label="Access Key"
                            validate={(value) => {
                                if (!value) {
                                    return "Access Key is required";
                                }
                            }}
                            required
                        />
                        <TextField
                            name="secret_key"
                            label="Secret Key"
                            validate={(value) => {
                                if (!value) {
                                    return "Secret Key is required";
                                }
                            }}
                            required
                        />
                        <TextField
                            name="bucket_name"
                            label="Bucket Name"
                            validate={(value) => {
                                if (!value) {
                                    return "Bucket Name is required";
                                }
                            }}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                closeDialog();
                            }}>
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={submitting}
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={invalid && !dirtySinceLastSubmit}>
                            {mode === "create" ? "Create File Server" : "Update File Server"}
                        </LoadingButton>
                    </DialogActions>
                </form>
            )}
        />
    );
};

export default FileServerForm;
