import {
    Box,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import { IActivityLogResponse, IEnvironment, agent } from "api";
import React from "react";
import { format, startOfDay, startOfWeek, startOfMonth } from "date-fns";

interface EnvironmentActivityProps {
    environment: IEnvironment;
}

const EnvironmentActivity: React.FC<EnvironmentActivityProps> = ({ environment}) => {
    const [activity, setActivity] = React.useState<IActivityLogResponse>();
    const [dateFrom, setDateFrom] = React.useState(startOfDay(new Date()).toISOString());
    const [page, setPage] = React.useState(1);

    const getActivity = async () => {
        // Fetch activity data
        await agent.Users.getUsersActivity({
            sort: "time:desc",
            date_from: dateFrom,
            page: page,
            environment_id: environment.id
        })
            .then((response) => {
                setActivity(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(() => {
        getActivity();
    }, [dateFrom, page]);

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2
                }}>
                <ToggleButtonGroup value={dateFrom} size="small">
                    <ToggleButton
                        value={startOfDay(new Date()).toISOString()}
                        onClick={() => setDateFrom(startOfDay(new Date()).toISOString())}>
                        Today
                    </ToggleButton>
                    <ToggleButton
                        value={startOfWeek(new Date()).toISOString()}
                        onClick={() => setDateFrom(startOfWeek(new Date()).toISOString())}>
                        This Week
                    </ToggleButton>
                    <ToggleButton
                        value={startOfMonth(new Date()).toISOString()}
                        onClick={() => setDateFrom(startOfMonth(new Date()).toISOString())}>
                        This Month
                    </ToggleButton>
                </ToggleButtonGroup>
                <Pagination shape="rounded" count={activity?.total_pages} page={page} onChange={(event, value) => setPage(value)} />
            </Box>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date/Time</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell>Destination</TableCell>
                        <TableCell>Transcoder</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activity &&
                        activity.data?.map((entry) => (
                            <TableRow key={entry.id} hover>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>{format(new Date(entry.time), "dd/MM/yyyy HH:mm:ss")}</TableCell>
                                <TableCell>{entry.user_email}</TableCell>
                                <TableCell>
                                    <Typography>{entry.action}</Typography>
                                    <Typography variant="caption">{entry.action_tag}</Typography>
                                </TableCell>
                                <TableCell>{entry.source_name}</TableCell>
                                <TableCell>{entry.destination_name}</TableCell>
                                <TableCell>{entry.transcoder_name}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default EnvironmentActivity;
