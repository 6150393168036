import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


type EnvironmentAccordionProps = {
    id: string;
    name: string;
    children?: any;
    defaultExpanded: boolean;
    itemCount: number;
    status: string;
    hidden?: boolean;
    onHideClick: ()=> void;
    active?: boolean;
}



const EnvironmentAccordion: React.FC<EnvironmentAccordionProps> = ({ id, name, children, defaultExpanded, itemCount, hidden, onHideClick, status, active }) => {

    const [ expanded, setExpanded ] = React.useState<boolean>(defaultExpanded);
    
    React.useEffect(() => {

        if (expanded || active) {
            setExpanded(true);
        }
        else {
        setExpanded(defaultExpanded);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ defaultExpanded
    ]);


    const EnvironmentAccordionHeaderStyles = {
        fontSize: 13, 
        fontWeight: 500, 
        backgroundColor: 'rgba(160,160,160,0.1)', 
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        top: 0,
        cursor: 'pointer',
    }

    const EnvironmentAccordionBodyStyles = {
        overflow: 'hidden',
        height: (expanded || active) ? 'auto' : '0px',
    }

    return (
        <div className={active ? "environment-accordion active" : "environment-accordion"}>
            <div className="environment-accordion-header" style={EnvironmentAccordionHeaderStyles} title={name + " (" + status + ")"}>
                <div style={{width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 5}} onClick={() => setExpanded(!expanded)}>
                    <FontAwesomeIcon icon={["fal", "server"]} />
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ display: 'inline-block', width: 10, height: 10, borderRadius: 5, backgroundColor: status === 'running' ? '#33BD4E' : '#666666'}}></span>
                    &nbsp;&nbsp;
                    {name + " (" + itemCount + ")"}
                </div>
                <div style={{flexShrink: 0}}>
                    <FontAwesomeIcon title={hidden ? "Show on map" : "Hide on map"} icon={["fal", hidden ? "eye-slash" : "eye"]} onClick={() => onHideClick()} />
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={["fal", expanded ? "chevron-up" : "chevron-down"]} onClick={() => setExpanded(!expanded)} />
                </div>
            </div>
            <div className="environment-accordion-body" style={EnvironmentAccordionBodyStyles}>
                {expanded && children}
            </div>
        </div>
    )
}

export default EnvironmentAccordion;