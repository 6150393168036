import { Box, Card, CardHeader, Typography } from "@mui/material";
import ColorEnhancementForm from "features/packager/Components/ColorEnhancementForm";
import ColorimetryOptionsForm from "features/packager/Components/ColorimetryOptionsForm";
import React from "react";
import { Form } from "react-final-form";
import { Handle, Node, Position } from "reactflow";

interface Props {
    data: any;
}

export const PackagerSourceNode: React.FC<Props> = (props) => {
    console.log(props);
    return (
        <Card sx={{ width: 250 }}>
            <Handle type="target" position={Position.Bottom} />
            <Box
                className="drag-handle"
                sx={{ px: 2, py: 1, borderBottom: "1px solid rgba(160,160,160,0.2)", fontWeight: 600, textTransform: "capitalize" }}>
                Source
            </Box>
            <Box sx={{ px: 2, py: 1, borderBottom: "1px solid rgba(160,160,160,0.2)" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                        Type
                    </Typography>
                    <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
                        {props.data.label}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                        Cloud Provider
                    </Typography>
                    <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
                        AWS
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                    <Typography variant="body1" sx={{ opacity: 0.7 }}>
                        Region
                    </Typography>
                    <Typography variant="body1">eu-west-1</Typography>
                </Box>
            </Box>
            <Box sx={{ px: 2, py: 1 }}>
                <Form
                    onSubmit={(values) => {
                        console.log(values);
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <ColorimetryOptionsForm
                                form={form}
                                values={values}
                                submitError={undefined}
                                onFieldChange={(field, value) => {
                                    form.change(field, value);
                                }}
                            />
                        </form>
                    )}
                />
            </Box>
            <Handle type="source" position={Position.Right} />
        </Card>
    );
};

export default PackagerSourceNode;
