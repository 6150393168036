export const encoderUHD = [
    { key: "1", text: "HD-SDI(Interlaced)", value: "1" },
    { key: "2", text: "HD/3G(Progressive)", value: "2" },
    { key: "4", text: "Quad Split (progressive)", value: "4" },
    { key: "5", text: "Quad Split (interlaced)", value: "5" },
    { key: "6", text: "2SI (progressive)", value: "6" },
    { key: "7", text: "2SI (interlaced)", value: "7" },
    { key: "8", text: "6G/12G-SDI", value: "8" }
];
export const encoderGOPSize = [
    { key: 4, text: "1", value: 4 },
    { key: 3, text: "12", value: 3 },
    { key: 1, text: "24", value: 1 },
    { key: 2, text: "50", value: 2 }
];

export const interlacedEncoderFrameRate = [
    { key: 1, text: "25 fps", value: 1 },
    { key: 2, text: "29.97 fps", value: 9 }
];

export const nonInterlacedEncoderFrameRate = [
    { key: 1, text: "25 fps", value: 1 },
    { key: 2, text: "29.97 fps", value: 9 },
    { key: 3, text: "30 fps", value: 10 },
    { key: 4, text: "50 fps", value: 11 },
    { key: 5, text: "59.94 fps", value: 12 },
    { key: 6, text: "60 fps", value: 13 }
];

export const encoderGOPSizeMode = [
    { key: 1, text: "Static, Open (IPB)", value: 1 },
    { key: 2, text: "Dynamic, Open (IPB)", value: 2 },
    { key: 3, text: "Static, Closed (IP)", value: 3 },
    { key: 4, text: "Dynamic, Closed (IP)", value: 4 }
];

export const videoFrameType = [
    { key: "1", text: "Progressive", value: "false" },
    { key: "2", text: "Interlaced", value: "true" }
];
