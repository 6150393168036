import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Fuse from "fuse.js";
import { icons } from "./icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStoreContext } from "api";
import SearchInput from "components/SearchInput";

interface IIconSelectorProps {
    currentIcon: string;
    onSelect: (icon: string) => void;
}

/**
 * `IconSelector` is a React functional component that renders a list of icons for selection.
 * This component is used in the Knowledge Hub Editor to allow users to select an icon for a Knowledge Hub item.
 *
 * @component
 * @returns {React.ReactElement} A React element that renders a list of icons for selection.
 */

const IconSelector: React.FC<IIconSelectorProps> = ({ currentIcon, onSelect }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;

    const [searchTerm, setSearchTerm] = useState<string>(currentIcon);
    const [results, setResults] = useState<any[]>([]);
    const [selectedIcon, setSelectedIcon] = useState<string>(currentIcon);

    const fuse = useMemo(() => {
        const options = {
            keys: ["name", "terms"],
            threshold: 0.4,
            includeMatches: true,
            minMatchCharLength: 0,
            findAllMatches: true,
            isCaseSensitive: false,
            shouldSort: true,
            location: 0,
            distance: 100
        };
        return new Fuse(icons, options);
    }, []);

    useEffect(() => {
        // if (searchTerm.trim() === "") {
        //     setResults([]);
        //     return;
        // }

        const searchResults = fuse
            .search(searchTerm ? searchTerm : " ")
            .slice(0, 16)
            .map((result) => {
                return {
                    item: result.item,
                    matches: result.matches
                };
            });
        console.log(searchResults);
        setResults(searchResults);
    }, [searchTerm, fuse]);

    return (
        <>
            <DialogTitle>Select an Icon</DialogTitle>
            <DialogContent>
                <Box py={2} width="100%">
                    <SearchInput
                        name="Search Icons"
                        label="Search Icons"
                        value={searchTerm}
                        size="medium"
                        sx={{ width: "100%" }}
                        fullWidth
                        onChange={(value) => {
                            setSearchTerm(value);
                        }}
                        onClear={() => setSearchTerm("")}
                    />
                </Box>
                <Box sx={{ width: "100%", display: "grid", 
                    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
                    gap: 2,
                    flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {results.map((result) => (
                        <Box
                            onClick={() => setSelectedIcon(result.item.name)}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                //width: "20%",
                                aspectRatio: "1",
                                //mr: 1,
                                //mb: 2,
                                border: "1px solid",
                                borderColor: (theme) =>
                                    selectedIcon === result.item.name ? theme.palette.primary.main : theme.palette.action.hover,
                                backgroundColor: (theme) => (selectedIcon === result.item.name ? theme.palette.primary.dark : undefined),
                                padding: 1,
                                borderRadius: 1,
                                "&:hover": {
                                    cursor: "pointer",
                                    borderColor: (theme) =>
                                        selectedIcon === result.item.name ? theme.palette.primary.main : theme.palette.action.active
                                }
                            }}
                            key={result.item.name}>
                            <FontAwesomeIcon icon={["fas", result.item.name]} fontSize={30} />
                            <Typography sx={{ mt: 2 }} component="div" textAlign="center" variant="body2">
                                {result.item.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onSelect(selectedIcon);
                        closeDialog();
                    }}
                    disabled={selectedIcon === ""}>
                    Select
                </Button>
            </DialogActions>
        </>
    );
};

export default IconSelector;
