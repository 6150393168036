import React, { useContext, SyntheticEvent, useState } from "react";
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IDestData, RootStoreContext } from "api";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDestinations } from "hooks";
interface IProps {
    id: string;
    details: IDestData;
}

export const ConfirmDelete: React.FC<IProps> = ({ id, details }) => {
    const rootStore = useContext(RootStoreContext);
    const { closeDialog } = rootStore.dialogStore;
    const { closeDrawer } = rootStore.drawerStore;
    const { deleteDestination } = useDestinations();
    const [error, setError] = useState("");
    const [deleting, setDeleting] = useState(false);

    const deleteChannel = (e: SyntheticEvent, id: string) => {
        setDeleting(true);
        deleteDestination(id)
            .then(() => {
                closeDrawer();
                closeDialog();
            })
            .catch((error) => {
                setError(error.data.message);
            })
            .finally(() => {
                // closeDialog();
                setDeleting(false);
            });
    };

    return (
        <>
            <DialogTitle>Confirm Destination Deletion</DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ marginBottom: 10 }}>
                    Destination: <span style={{ fontWeight: 600, fontSize: "1rem" }}>{details.display_name}</span>
                </Typography>
                <Typography variant="body1" style={{ marginBottom: 10 }}>
                    Deleting this destination will remove it for all users and cannot be undone. Are you sure you want to delete this
                    destination?
                </Typography>
                {error && (
                    <Alert severity="error" style={{ marginBottom: 10 }}>
                        {error}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={deleting}
                    onClick={() => {
                        closeDialog();
                    }}>
                    Cancel
                </Button>
                <LoadingButton
                    disabled={deleting || error !== ""}
                    loading={deleting}
                    onClick={(e) => {
                        deleteChannel(e, id);
                    }}
                    variant="contained"
                    color="error"
                    startIcon={<DeleteIcon />}>
                    Delete
                </LoadingButton>
            </DialogActions>
        </>
    );
};
