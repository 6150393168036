import { Box } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import Content from "components/Layout/Content";
import View, { ViewBody, ViewHeader } from "components/Layout/View";
import { List, ListItem, ListItemButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

export const Multiviewers: React.FC = observer(function Multiviewers() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <View>
            <ViewHeader title="Multiviewers" />
            <ViewBody noPadding>
                <Content
                    leftContent={
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/multiviewers"}
                                    onClick={() => {
                                        navigate("");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Multiviewers</Box>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    selected={location.pathname === "/multiviewers/layouts"}
                                    onClick={() => {
                                        navigate("/multiviewers/layouts");
                                    }}>
                                    <Box sx={{ padding: "5px 0" }}>Layouts</Box>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    }
                    leftWidth={220}
                    noPadding>
                    <Outlet />
                </Content>
            </ViewBody>
        </View>
    );
});

export default Multiviewers;
