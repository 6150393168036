import React, { useState, MouseEvent, useContext } from "react";
import {
    Drawer,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Menu,
    useTheme,
    Box,
    Divider,
    ListSubheader,
    ButtonBase,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { useLocation, useNavigate } from "react-router-dom";
import ModuleAccessProvider from "components/ModuleAccessProvider";
import RoleProvider from "components/RoleProvider";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import ClientProvider from "components/ClientProvider";
import { RootStoreContext } from "api";
import { observer } from "mobx-react-lite";
import { is } from "date-fns/locale";

interface MenuItemData {
    id: string;
    text: string;
    icon_name: IconName;
    path?: string;
    wildcard_path?: boolean;
    type?: string;
    module?: string;
    roles?: string[];
    children?: MenuItemData[];
    pinnable?: boolean;
    hideFromGuest?: boolean;
}

const menuItems: MenuItemData[] = [
    {
        id: "dashboard",
        text: "Dashboard",
        icon_name: "dashboard",
        path: "/dashboard",
        pinnable: true
    },
    {
        id: "dashboard_divider",
        type: "divider",
        text: "Dashboard Divider",
        icon_name: "dashboard"
    },
    {
        id: "live",
        type: "group",
        text: "Live",
        icon_name: "video"
    },
    {
        id: "environments",
        text: "Manage Environments",
        icon_name: "server",
        path: "/environments",
        pinnable: true,
        hideFromGuest: true
    },
    {
        id: "global_view",
        text: "Global View",
        icon_name: "chart-network",
        path: "/overview",
        module: "global_view",
        pinnable: true,
        hideFromGuest: true
    },
    {
        id: "sources",
        text: "Sources",
        icon_name: "video",
        path: "/sources",
        pinnable: true
    },
    {
        id: "multiviewers",
        text: "Multiviewers",
        icon_name: "grid-2",
        path: "/multiviewers",
        module: "tag",
        pinnable: true
    },
    {
        id: "destinations_list",
        text: "Destinations List",
        icon_name: "broadcast-tower",
        path: "/destinations/list-view",
        pinnable: true
    },
    {
        id: "scheduler",
        text: "Scheduler",
        icon_name: "calendar-week",
        path: "/scheduler",
        pinnable: true,
        wildcard_path: true
    },
    {
        id: "live_divider",
        type: "divider",
        text: "Other",
        icon_name: "video"
    },
    {
        id: "ott",
        type: "group",
        text: "OTT",
        icon_name: "video",
        hideFromGuest: true
    },
    {
        id: "push_packager",
        text: "Push Packagers",
        icon_name: "arrow-right-long-to-line",
        path: "/packager",
        wildcard_path: true,
        module: "push_packager",
        pinnable: true,
        hideFromGuest: true
    },
    {
        id: "nealive",
        text: "Pull Packagers",
        icon_name: "arrow-left-long-to-line",
        path: "/pull-packager",
        wildcard_path: true,
        module: "nealive",
        pinnable: true,
        hideFromGuest: true
    },
    {
        id: "ott_divider",
        type: "divider",
        text: "Other",
        icon_name: "video",
        hideFromGuest: true
    },
    {
        id: "file",
        type: "group",
        text: "File",
        icon_name: "video",
        module: "file_transcoding",
        hideFromGuest: true
    },
    {
        id: "file_transcoding",
        text: "File Transcoding",
        icon_name: "file-video",
        path: "/file",
        pinnable: true,
        module: "file_transcoding",
        hideFromGuest: true
    },
    {
        id: "file_reporting",
        text: "Reporting",
        icon_name: "file-chart-line",
        path: "/reporting",
        pinnable: true,
        module: "file_transcoding",
        hideFromGuest: true
    },
    {
        id: "file_divider",
        type: "divider",
        text: "Other",
        icon_name: "video",
        module: "file_transcoding",
        hideFromGuest: true
    },
    {
        id: "billing",
        text: "Billing",
        icon_name: "dollar-sign",
        path: "/billing",
        pinnable: true
    },
    {
        id: "settings",
        text: "Settings",
        icon_name: "cog",
        children: [
            {
                id: "alerts_management",
                text: "Alerts Management",
                icon_name: "bell",
                path: "/alerts",
                pinnable: true
            },
            {
                id: "my_organisation",
                text: "My Organisation",
                icon_name: "sitemap",
                path: "/settings/organisations",
                pinnable: true
            },
            {
                id: "own_cloud",
                text: "Own Cloud",
                icon_name: "cloud-arrow-up",
                path: "/settings/own-cloud",
                pinnable: false,
                roles: ["has_any_own_cloud_available"]
            },
            {
                id: "source_permissions",
                text: "Source Permissions",
                icon_name: "user-check",
                path: "/settings/sourcepermissions",
                pinnable: true
            },
            {
                id: "user_activity",
                text: "User Activity",
                icon_name: "user-clock",
                path: "/user-activity",
                pinnable: true
            }
        ]
    },
    {
        id: "developer",
        text: "Developer",
        icon_name: "code",
        roles: ["is_dev"],
        children: [
            {
                id: "sandbox",
                text: "Sandbox",
                icon_name: "code",
                path: "/developer",
                pinnable: true
            }
        ]
    },
    {
        id: "knowledge_hub",
        text: "Knowledge Hub",
        icon_name: "book",
        roles: ["is_dev"],
        path: "/knowledge-hub",
        pinnable: true
    }
];

export const SidebarMenu: React.FC = observer(function SidebarMenu() {
    const rootStore = useContext(RootStoreContext);
    const user_pinned_apps = rootStore.userStore.user?.user_preferences?.pinned_apps;
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const isGuest = rootStore.userStore.managingOrganisationId !== rootStore.userStore.activeorganisationId;

    //Build the menu by taking the menuItems array and filtering it based on the userpinneditems (which is a comma separated list of ids)
    //and where type is not group or divider

    const userPinnedItemsArray = user_pinned_apps?.split(":");
    const filteredMenuItems = menuItems.filter(
        (item) => userPinnedItemsArray?.includes(item.id) && item.type !== "group" && item.type !== "divider" && (!isGuest || !item.hideFromGuest)
    );

    //also add children to the filteredMenuItems array
    menuItems.forEach((item) => {
        if (item.children) {
            item.children.forEach((childItem) => {
                if (userPinnedItemsArray?.includes(childItem.id)) {
                    filteredMenuItems.push(childItem);
                }
            });
        }
    });

    return (
        <Stack spacing={1} sx={{ width: "100%", alignItems: "center" }}>
            {filteredMenuItems.map((item, index) => (
                <RoleProvider roles={item.roles} key={item.id}>
                    <ModuleAccessProvider module={item.module}>
                        <Tooltip title={item.text} placement="right">
                            <ButtonBase
                                onClick={() => {
                                    item.path && navigate(item.path);
                                }}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    display: "flex",
                                    borderRadius: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 18,
                                    backgroundColor:
                                        (item.wildcard_path && location.pathname.startsWith(item.path!)) || location.pathname === item.path
                                            ? theme.palette.action.selected
                                            : undefined,
                                    "&:hover": {
                                        backgroundColor: theme.palette.action.hover
                                    }
                                }}>
                                <FontAwesomeIcon icon={["fal", item.icon_name]} />
                            </ButtonBase>
                        </Tooltip>
                    </ModuleAccessProvider>
                </RoleProvider>
            ))}
        </Stack>
    );
});

const NestedMenuItem: React.FC<{ item: MenuItemData; onNavigate: (path: string) => void; isGuest: boolean }> = observer(function NestedMenuItem({
    item,
    onNavigate,
    isGuest
}) {
    const rootStore = useContext(RootStoreContext);
    const { updateUserPreferences } = rootStore.userStore;
    const user_pinned_apps = rootStore.userStore.user?.user_preferences?.pinned_apps;
    const userPinnedItemsArray = user_pinned_apps?.split(":");
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMouseEnter = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const pinApp = (id: string) => {
        //take the userPinnedItemsArray and add the id to it
        //then convert it back to a string and updateUserPreferences
        userPinnedItemsArray.push(id);
        const newPinnedApps = userPinnedItemsArray.join(":");
        updateUserPreferences({ pinned_apps: newPinnedApps });
    };

    const unpinApp = (id: string) => {
        //take the userPinnedItemsArray and remove the id from it
        //then convert it back to a string and updateUserPreferences
        const newPinnedApps = userPinnedItemsArray.filter((item) => item !== id).join(":");
        updateUserPreferences({ pinned_apps: newPinnedApps });
    };

    if (item.type === "group") {
        return (
            isGuest && item.hideFromGuest ? null :
            <RoleProvider roles={item.roles}>
                <ModuleAccessProvider module={item.module}>
                    <>
                        <ListSubheader disableSticky>{item.text}</ListSubheader>
                    </>
                </ModuleAccessProvider>
            </RoleProvider>
        );
    }

    if (item.type === "divider") {
        return (
            isGuest && item.hideFromGuest ? null :
            <RoleProvider roles={item.roles}>
                <ModuleAccessProvider module={item.module}>
                    <>
                        <Divider />
                    </>
                </ModuleAccessProvider>
            </RoleProvider>
        );
    }

    return (
        isGuest && item.hideFromGuest ? null :
        <>
            <RoleProvider roles={item.roles}>
                <ModuleAccessProvider module={item.module}>
                    <MenuItem
                        onClick={(event) => {
                            if (item.children) {
                                handleMouseEnter(event);
                                return;
                            }

                            item.path && navigate(item.path);
                            item.path && onNavigate(item.path);
                            item.path && handleMouseLeave();
                        }}
                        aria-haspopup={!!item.children}
                        aria-controls={open ? "submenu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        selected={
                            location.pathname.startsWith(item.path!) ||
                            location.pathname === item.path ||
                            (item.children && item.children.some((childItem) => location.pathname === childItem.path))
                        }
                        sx={{
                            backgroundColor: anchorEl ? theme.palette.action.hover : undefined,
                            "#pinned": {
                                opacity: 0.2
                            },
                            "#unpinned": {
                                opacity: 0
                            },
                            "&:hover": {
                                "#pinned": {
                                    opacity: 1
                                },
                                "#unpinned": {
                                    opacity: 1
                                }
                            }
                        }}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={["fal", item.icon_name]} />
                        </ListItemIcon>
                        <ListItemText>{item.text}</ListItemText>
                        {
                            // if the item is pinnable and the user has it pinned, show the pushpin icon
                            // if the item is pinnable and the user does not have it pinned, show the pushpin outline icon
                            // if the item is not pinnable, show nothing
                            item.pinnable && userPinnedItemsArray?.includes(item.id) ? (
                                <Tooltip title="Unpin from sidebar" placement="right">
                                    <PushPinIcon
                                        id="pinned"
                                        sx={{ fontSize: 12 }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            unpinApp(item.id);
                                        }}
                                    />
                                </Tooltip>
                            ) : item.pinnable ? (
                                <Tooltip title="Pin to sidebar" placement="right">
                                    <PushPinOutlinedIcon
                                        id="unpinned"
                                        sx={{ fontSize: 12 }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            pinApp(item.id);
                                        }}
                                    />
                                </Tooltip>
                            ) : null
                        }
                        {item.children && <ChevronRightIcon sx={{ mr: -1 }} />}
                    </MenuItem>
                    {item.children && (
                        <Menu
                            id="submenu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMouseLeave}
                            MenuListProps={{ onMouseLeave: handleMouseLeave }}
                            sx={{
                                "& .MuiMenu-paper": {
                                    minWidth: 220,
                                    p: "0px !important"
                                }
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}>
                            {item.children.map((childItem, index) => (
                                <NestedMenuItem
                                    key={index}
                                    isGuest={isGuest}
                                    item={childItem}
                                    onNavigate={() => {
                                        childItem.path && onNavigate(childItem.path);
                                    }}
                                />
                            ))}
                        </Menu>
                    )}
                </ModuleAccessProvider>
            </RoleProvider>
        </>
    );
});

const MainMenu: React.FC = () => {
    const theme = useTheme();
    const rootStore = useContext(RootStoreContext);
    const { sidebarExpanded, setSidebarExpanded } = rootStore.sidebarStore;
    //const [drawerOpen, setDrawerOpen] = useState(false);
    const isGuest = rootStore.userStore.managingOrganisationId !== rootStore.userStore.activeorganisationId;

    const handleDrawerOpen = () => {
        setSidebarExpanded(true);
    };

    const handleDrawerClose = () => {
        setSidebarExpanded(false);
    };

    return (
        <>
            <IconButton onClick={handleDrawerOpen}>
                <MenuIcon sx={{ color: theme.palette.topbar?.contrastText }} />
            </IconButton>
            <Drawer anchor="left" open={sidebarExpanded} onClose={handleDrawerClose}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.sidebar?.main,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <ClientProvider client="default">
                        <Box
                            component="img"
                            src={require("../../client/default/assets/logo/full-logo.png")}
                            sx={{ width: 200, margin: "20px auto" }}
                        />
                    </ClientProvider>
                    <ClientProvider client="ateme">
                        <Box
                            component="img"
                            src={require("../../client/ateme/assets/logo/full-logo.png")}
                            sx={{ width: 180, margin: "20px auto" }}
                        />
                    </ClientProvider>
                </Box>
                <MenuList sx={{ width: 260 }}>
                    {menuItems.map((item, index) => (
                        <NestedMenuItem
                            key={index}
                            item={item}
                            isGuest={isGuest}
                            onNavigate={() => {
                                setSidebarExpanded(false);
                            }}
                        />
                    ))}
                </MenuList>
            </Drawer>
        </>
    );
};

export default observer(MainMenu);
